/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../../../../styles/themes'
import config from '../../../../../middleware/config'
import * as moment from 'moment'
/* COMPONENTS */
import Header from '../../../Header'
import Main from '../../../../components/Main'
import Body from '../../../../components/Body'
import Room from './Room'
import Textarea from '../../../../components/ui/Textarea'
import Icons from '../../../../components/ui/Icons'
import Card from '../../../../components/ui/Card'
import Button from '../../../../components/ui/Button'
import RoundButton from '../../../../components/ui/RoundButton'
import Alert from '../../../../components/ui/Alert'
import Loader from '../../../../components/Loader'
import BreadCrumb from '../../../../components/BreadCrumb'
import GerenciaFluxoEdicao from '../../../../components/GerenciaFluxoEdicao';
import { Get, Post, Put } from '../../../../../utils/request'
import { formatDate } from '../../../../../utils/dates'
import ServicosSolicitados from '../../../../components/ServicosSolicitados';
import { retornaUrlFluxoEdicao, retornaUrlFluxoCriacao } from '../../../../../utils/fluxoCriacaoEvento';
import { ehChainUser } from '../../../../../utils/tiposUsuario'
const serialize = require('dom-form-serializer').serialize

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #fff;
  font-size: 14px;
`

const Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  font-family: ${defaultTheme.fonts.secondary};
  padding: 3px 10px 13px 10px;
  color: #484848;
`

const FormContent = styled.form`
  padding: 10px 10px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
`

const BtnAdd = styled.span`
  padding: 5px 0;
  margin-top: 10px;
  display: block;
  cursor: pointer;
  color: ${defaultTheme.colors.whiteLabel.primary};
  font-size: 14px;
  font-family: ${defaultTheme.fonts.secondary};
`

const Label = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  box-sizing: border-box;
  padding: 5px 5px 5px 0px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
`

const Strong = styled.strong`
  color: ${props => props.error ? 'red' : defaultTheme.colors.primary.default};
  font-weight: bold;
`

const Group = styled.div`
`

const FixedDiv = styled.div`
  position: fixed;
  top: 46px;
  bottom: 0;
  width: 25%;
  overflow-y: scroll;
  margin-top: 14px;
`
class Rooms extends Component {
  state = {
    rooms: [],
    observations: '',
    errorMessage: false,
    isLoading: false,
    event: {},
    eventStartDate: '',
    eventEndDate: '',
    eventName: '',
    eventLocation: {},
    eventStatus: '',
    startTime: '',
    endTime: '',
    services: '',
    numberOfParticipants: 0,
    salvaEdicao: false,
    briefing: {}
  }

  componentDidMount() {
    const { briefing, id } = this.props.match.params
    if (ehChainUser()) {
      alert('Você não tem permissão para acessar esta página.')
      this.props.history.push(`/organizer`)
    }
    if (briefing) this.fetchBriefing()

    this.setState({ isLoading: true }, () => {
      const { api: { base, version } } = config
      const userType = window.localStorage.getItem('user_type')
      const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
      const newEndpoint = `${base}${version}${eventEndpoint}/${id}`
      Get(newEndpoint).then((response) => {
        this.setState({ isLoading: false })
        if (!response) {
          this.setState({ errorMessage: response.message })
          return Promise.reject(response)
        } else {
          this.setState({
            event: {...response},
            eventStartDate: response.start,
            eventEndDate: response.end,
            eventName: response.name,
            eventLocation: response.location,
            eventFriendlyId: response.internalId || response.friendlyId,
            startTime: response.startTime,
            endTime: response.endTime,
            numberOfParticipants: response.numberOfParticipants,
            services: response.services,
            eventStatus: response.status
          }, () => {
            if (!briefing) {
              const { rooms } = this.state
              rooms.push({
                id: 0,
                startDate: this.state.eventStartDate,
                endDate: this.state.eventEndDate,
                startTime: this.state.startTime,
                endTime: this.state.endTime,
                mounting: false,
                roomType: '',
                roomFormat: '',
                amountOfPeople: this.state.numberOfParticipants,
                roomItems: [],
                errors: {
                  startDate: '',
                  endDate: '',
                  startTime: '',
                  endTime: '',
                  mounting: '',
                  roomType: '',
                  roomFormat: '',
                  amountOfPeople: '',
                }
              })
              this.setState({ rooms: rooms })
            }
          })
        }
      }).catch(err => {
        this.setState({ isLoading: false })
        console.log('Error: ', err)
      })
    })
  }

  checkEdicao = (e) => {
    e.preventDefault()
    this.setState({ errorMessage: false })
    const forms = serialize(this.form)
    forms.rooms = Object.keys(forms.rooms).map(i => {
      forms.rooms[i].startTime = (forms.rooms[i].startTime || '00:00') + ':00'
      forms.rooms[i].endTime = (forms.rooms[i].endTime || '00:00') + ':00'
      forms.rooms[i].roomItems = Object.keys(forms.rooms[i].roomItems).reduce((p, c) => {
        if (forms.rooms[i].roomItems[c]) p.push(forms.rooms[i].roomItems[c])
        return p
      }, [])
      return forms.rooms[i]
    })
    this.cleanErrors()
    if (this.isValidTime(forms) && this.isValidForm(forms) && this.isValidDates(forms) && this.isValidEqualTime(forms)) {
      this.setState({ salvaEdicao: true }, () => this.setState({ salvaEdicao: false }))
    } else {
      !this.isValidDates(forms) && this.setState({ errorMessage: 'O período das datas só pode ser entre as datas do evento (podendo ser um dia antes / um dia depois)' })
      !this.isValidTime(forms) && this.setState({ errorMessage: 'Se o aluguel da sala for em apenas um único dia o horário de fim não pode ser menor que o horário de início' })
      !this.isValidEqualTime(forms) && this.setState({ errorMessage: 'Horário de início e fim não podem ser o mesmos' })
      !this.isValidForm(forms) && this.setState({ errorMessage: 'Preencha todos os campos obrigatórios.' })
    }
  }

  addRoom = () => {
    const { rooms } = this.state
    rooms.push({
      startDate: this.state.eventStartDate,
      endDate: this.state.eventEndDate,
      startTime: this.state.startTime,
      endTime: this.state.endTime,
      mounting: false,
      roomType: '',
      roomFormat: '',
      amountOfPeople: this.state.numberOfParticipants,
      roomItems: [],
      errors: {
        startDate: '',
        endDate: '',
        startTime: '',
        endTime: '',
        mounting: '',
        roomType: '',
        roomFormat: '',
        amountOfPeople: ''
      }
    })
    this.setState({ rooms: rooms })
  }

  removeRoom = () => {
    const { rooms } = this.state
    rooms.pop()
    this.setState({ rooms: rooms })
  }

  removeRoomFromIndex = (input, index) => {
    input && input.preventDefault()
    const { rooms } = this.state
    rooms.splice(index, 1);
    this.setState({ rooms: rooms })
  }

  submit = (e) => {
    e && e.preventDefault()
    this.setState({ errorMessage: false })
    const forms = serialize(this.form)
    forms.rooms = Object.keys(forms.rooms).map(i => {
      forms.rooms[i].startTime = (forms.rooms[i].startTime || '00:00') + ':00'
      forms.rooms[i].endTime = (forms.rooms[i].endTime || '00:00') + ':00'
      forms.rooms[i].roomItems = Object.keys(forms.rooms[i].roomItems).reduce((p, c) => {
        if (forms.rooms[i].roomItems[c]) p.push(forms.rooms[i].roomItems[c])
        return p
      }, [])
      return forms.rooms[i]
    })
    this.cleanErrors()
    if (this.isValidTime(forms) && this.isValidForm(forms) && this.isValidDates(forms) && this.isValidEqualTime(forms)) {
      // if(this.haveDifferentAmount(forms)) {
      //   if (window.confirm('A quantidade de pessoas é diferente da quantidade de pessoas no evento, deseja prosseguir mesmo assim?')) {
      //     this.fetchUpdateRooms(forms)
      //   }
      // } else {
      this.fetchUpdateRooms(forms)
      // }
    } else {
      !this.isValidDates(forms) && this.setState({ errorMessage: 'O período das datas só pode ser entre as datas do evento (podendo ser um dia antes / um dia depois)' })
      !this.isValidTime(forms) && this.setState({ errorMessage: 'Se o aluguel da sala for em apenas um único dia o horário de fim não pode ser menor que o horário de início' })
      !this.isValidEqualTime(forms) && this.setState({ errorMessage: 'Horário de início e fim não podem ser o mesmos' })
      !this.isValidForm(forms) && this.setState({ errorMessage: 'Preencha todos os campos obrigatórios.' })
    }
  }

  cleanErrors = () => {
    var rooms = this.state.rooms
    rooms.map(room => {
      room.errors = {
        startDate: '',
        endDate: '',
        startTime: '',
        endTime: '',
        mounting: '',
        roomType: '',
        roomFormat: '',
        amountOfPeople: ''
      }
      return room
    })
    this.setState({ rooms: rooms })
  }

  isValidForm = (form) => {
    let isValid = true
    const ItemsToValidate = ['startDate', 'endDate', 'startTime', 'endTime', 'mounting', 'roomType', 'roomFormat', 'amountOfPeople']
    var rooms = this.state.rooms
    form.rooms.forEach((e, index) => {
      Object.keys(e).forEach(i => {
        if (ItemsToValidate.includes(i) && e[i] === '') {
          isValid = false
          rooms[index].errors[i] = true
        } else if (i === 'amountOfPeople' && e[i] === '0') {
          isValid = false
          rooms[index].errors[i] = true
        }
      })
    })

    this.setState({ rooms: rooms })

    return isValid
  }

  haveDifferentAmount = (form) => {
    let isDifferent = false
    const ItemsToValidate = ['amountOfPeople']
    form.rooms.forEach(e => {
      Object.keys(e).forEach(i => {
        if (ItemsToValidate.includes(i) && e[i] !== this.state.numberOfParticipants.toString()) {
          isDifferent = true
        }
      })
    })

    return isDifferent
  }

  isValidDates = (form) => {
    let isValid = true
    var rooms = this.state.rooms
    form.rooms.forEach((room, index) => {
      if (room.startDate &&
        !(moment(room.startDate, 'YYYY-MM-DD[T]HH:mm:ssZ').diff(moment(this.state.eventStartDate, 'YYYY-MM-DD[T]HH:mm:ssZ'), 'days') >= 0 &&
          moment(room.startDate, 'YYYY-MM-DD[T]HH:mm:ssZ').diff(moment(this.state.eventEndDate, 'YYYY-MM-DD[T]HH:mm:ssZ'), 'days') <= 1 &&
          moment(room.endDate, 'YYYY-MM-DD[T]HH:mm:ssZ').diff(moment(this.state.eventStartDate, 'YYYY-MM-DD[T]HH:mm:ssZ'), 'days') >= 0 &&
          moment(room.endDate, 'YYYY-MM-DD[T]HH:mm:ssZ').diff(moment(this.state.eventEndDate, 'YYYY-MM-DD[T]HH:mm:ssZ'), 'days') <= 1)) {
        isValid = false
        rooms[index].errors.startDate = true
        rooms[index].errors.endDate = true
      }
    })
    this.setState({ rooms: rooms })

    return isValid
  }

  isValidTime = (form) => {
    let isValid = true
    var rooms = this.state.rooms
    form.rooms.forEach((room, index) => {
      if (moment(room.startDate, 'YYYY-MM-DD[T]HH:mm:ssZ').diff(moment(room.endDate, 'YYYY-MM-DD[T]HH:mm:ssZ'), 'days') === 0 &&
        room.startTime > room.endTime) {
        isValid = false
        rooms[index].errors.startDate = true
        rooms[index].errors.endDate = true
      }
    })
    this.setState({ rooms: rooms })
    return isValid
  }

  isValidEqualTime = (form) => {
    let isValid = true
    var rooms = this.state.rooms
    form.rooms.forEach((room, index) => {
      if (room.startTime === room.endTime && room.startTime !== '00:00:00') {
        isValid = false
        rooms[index].errors.startTime = true
        rooms[index].errors.endTime = true
      }
    })
    this.setState({ rooms: rooms })
    return isValid
  }

  fetchUpdateRooms = (formData) => {
    this.setState({ isLoading: true })
    const { id, briefing } = this.props.match.params
    const { api: { base, version } } = config
    const userType = window.localStorage.getItem('user_type')
    const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
    const BriefingRoomEndpoint = `${base}${version}${eventEndpoint}/${id}/briefing/room`
    if (briefing) {
      const EditEndPoint = `${base}${version}${eventEndpoint}/${id}/briefing/room/${briefing}`
      Put(EditEndPoint, this.generateJSON()).then(() => {
        const newEndpoint = `${base}${version}${eventEndpoint}/${id}`
        Get(newEndpoint).then((response) => {
          this.setState({ isLoading: false })
          if (!response) {
            this.setState({ errorMessage: response.message })
            return Promise.reject(response)
          } else {
            this.props.history.push(retornaUrlFluxoEdicao(response.services, id, 'room'))
          }
        }).catch(err => {
          this.setState({ isLoading: false })
          console.log('Error: ', err)
        })
      }).catch(err => {
        this.setState({ isLoading: false })
        console.log('Error: ', err)
      })
    } else {
      Post(BriefingRoomEndpoint, formData).then((response) => {
        if (!response.success) {
          this.setState({ errorMessage: response.message, isLoading: false })
          return Promise.reject(response)
        } else {
          const newEndpoint = `${base}${version}${eventEndpoint}/${id}`
          Get(newEndpoint).then((response) => {
            this.setState({ isLoading: false })
            if (!response) {
              this.setState({ errorMessage: response.message })
              return Promise.reject(response)
            } else {
              this.props.history.push(retornaUrlFluxoCriacao(response.services, id, this.state.eventStatus, 'room'))
            }
          }).catch(err => {
            this.setState({ isLoading: false })
            console.log('Error: ', err)
          })
        }
      }).catch(err => {
        this.setState({ isLoading: false })
        console.log('Error: ', err)
      })
    }
  }

  fetchBriefing = () => {
    this.setState({ isLoading: true })
    const token = window.localStorage.getItem('user_token')
    const { briefing } = this.props.match.params
    const { api: { base, version } } = config
    const endpoint = `${base}${version}/briefing/room/${briefing}`
    const configFetch = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    }

    window.fetch(endpoint, configFetch).then(response => {
      if (response.status >= 401 && response.status <= 403) window.localStorage.clear()
      if (response.ok) return response.json()
      else throw new Error(response.statusText)
    }).then((response) => {
      this.setState({ isLoading: false })
      if (!response.success) {
        this.setState({ errorMessage: response.message })
        return Promise.reject(response)
      } else {
        var { rooms, observations } = response.data
        rooms = rooms.map(room => {
          room.errors = {
            startDate: '',
            endDate: '',
            startTime: '',
            endTime: '',
            mounting: '',
            roomType: '',
            roomFormat: '',
            amountOfPeople: ''
          }
          return room
        })
        this.setState({ rooms, observations, briefing: response.data })
      }
    }).catch(err => {
      this.setState({ isLoading: false })
      console.log('Error: ', err)
    })
  }

  handleObservation = ({ target: { name, value } }) => {
    this.setState({ [name]: value })
  }

  generateJSON = () => {
    const { briefing } = this.state
    if(!this.checkIfObjectIsEmpty(briefing) && !this.checkIfObjectIsEmpty(serialize(this.form))) {
      const forms = serialize(this.form)
      forms.rooms = forms.rooms && Object.keys(forms.rooms).map(i => {
        forms.rooms[i].startTime = (forms.rooms[i].startTime || '00:00') + ':00'
        forms.rooms[i].endTime = (forms.rooms[i].endTime || '00:00') + ':00'
        forms.rooms[i].roomItems = Object.keys(forms.rooms[i].roomItems).reduce((p, c) => {
          if (forms.rooms[i].roomItems[c]) p.push(forms.rooms[i].roomItems[c])
          return p
        }, [])
        return forms.rooms[i]
      })
      briefing.rooms = forms.rooms
      briefing.observations = forms.observations
      return briefing
    }
  }

  checkIfObjectIsEmpty = (obj) => {
    return Object.entries(obj).length === 0 && obj.constructor === Object
  }

  gerenciaResponsividade = () => {
    if (window.innerWidth < 600) return this.paginaMobile()
    else return this.paginaDesktop()
  }

  paginaMobile = () => {
    const { rooms, errorMessage, isLoading, observations, services } = this.state
    const { id, briefing } = this.props.match.params
    const lodging = services.lodging && services.lodging.requested && (services.lodging.id ? `/organizer/event/lodging/${id}/${services.lodging.id}` : `/organizer/event/lodging/${id}`)
    return <Content>
      <Header />
      {lodging && <BreadCrumb options={[
        { link: '/organizer', name: 'Home' },
        { link: `/organizer/event/${id}`, name: 'Dados gerais do evento' },
        { link: `/organizer/event/services/${id}`, name: 'O que você deseja contratar para este evento' },
        { link: lodging, name: 'Hospedagem' },
        { name: 'Salas' }
      ]} />
      }
      {!lodging && <BreadCrumb options={[
        { link: '/organizer', name: 'Home' },
        { link: `/organizer/event/${id}`, name: 'Dados gerais do evento' },
        { link: `/organizer/event/services/${id}`, name: 'O que você deseja contratar para este evento' },
        { name: 'Salas' }
      ]} />
      }
      <FormContent method={'POST'} onSubmit={(e) => this.submit(e)}>
        <Title>Salas</Title>
        {isLoading ? <Loader /> : null}
        <RenderRooms rooms={rooms} error={errorMessage} numberOfParticipants={this.state.numberOfParticipants} removeRoomFromIndex={this.removeRoomFromIndex} />
        <BtnAdd onClick={this.addRoom}>+ Adicionar mais uma sala</BtnAdd>
        <Textarea
          type={'text'}
          label={'Observações'}
          placeholder={'Caso tenha alguma observação, escreva aqui.'}
          name={'observations'}
          value={observations}
          onChange={this.handleObservation}
        />
        {errorMessage && <Alert type={'negative'}>{errorMessage}</Alert>}
        <Button
          type='submit'
          category={'secondary'}
          fontWeight={800}
          full>
          {briefing ? 'Ok' : 'Próximo'}
        </Button>
      </FormContent>
    </Content>
  }

  paginaDesktop = () => {
    const { rooms, errorMessage, isLoading, observations, services } = this.state
    const { id, briefing } = this.props.match.params
    const lodging = services.lodging && services.lodging.requested && (services.lodging.id ? `/organizer/event/lodging/${id}/${services.lodging.id}` : `/organizer/event/lodging/${id}`)
    const listaDeRedirecionamento = JSON.parse(window.localStorage.getItem('redirecionamento_edicao_fluxo'))
    return <>
      <Header />
      <Main>
        <Body {...this.props} pagina={this.state.eventStatus === 'INACTIVE' ? 'create-evento' : 'editar-evento'} menuLateralDireito={this.menuLateralDireito()}>
          <GerenciaFluxoEdicao pagina={'salas'} eventId={id} briefingId={briefing} form={this.generateJSON()} salvaEdicao={this.state.salvaEdicao} onSubmit={this.submit} />
          <Content>
            {(lodging && this.state.eventStatus === 'INACTIVE') && <BreadCrumb options={[
              { link: '/organizer', name: 'Home' },
              { link: `/organizer/event/${id}`, name: 'Dados gerais do evento' },
              { link: lodging, name: 'Hospedagem' },
              { name: 'Salas' }
            ]} />
            }
            {(!lodging && this.state.eventStatus === 'INACTIVE') && <BreadCrumb options={[
              { link: '/organizer', name: 'Home' },
              { link: `/organizer/event/${id}`, name: 'Dados gerais do evento' },
              { name: 'Salas' }
            ]} />
            }
            <FormContent ref={form => this.form = form} id={'form'}>
              <Title style={{ width: '100%' }}>{this.state.eventStatus === 'INACTIVE' ? 'Salas' : 'Editar salas'}</Title>
              {(listaDeRedirecionamento && listaDeRedirecionamento.length > 0) && <Alert type={'edition'} style={{ display: 'flex', marginBottom: '40px' }}>
                <Icons
                  icon={'exclamation'}
                  color={'white'}
                  width={30}
                  style={{ margin: 'auto' }}
                  height={15} />
                  <p style={{ textAlign: 'justify' }}>
                    Você alterou a <span style={{ fontWeight: 'bold' }}>data</span> e/ou o <span style={{ fontWeight: 'bold' }}>número de pessoas</span> nos dados de seu evento. Acreditamos que você queira validar os dados abaixo para eventualmente alterar este mesmo aspecto também abaixo.
                  </p>
              </Alert>}
              {isLoading ? <Loader /> : null}
              <Group style={{ display: 'flex' }}>
                <Label>Quantas salas deseja para este evento? <Strong>*</Strong></Label>
                <RoundButton onClick={this.removeRoom} disabled={rooms.length <= 1} simbolo={'-'} />
                <Title style={{ width: '50px', marginTop: '5px', textAlign: 'center', color: defaultTheme.colors.whiteLabel.primary }}>{rooms.length}</Title>
                <RoundButton onClick={this.addRoom} simbolo={'+'} />
              </Group>
              <RenderRooms
                rooms={rooms}
                error={errorMessage}
                numberOfParticipants={this.state.numberOfParticipants}
                removeRoomFromIndex={this.removeRoomFromIndex}
                listaDeRedirecionamento={listaDeRedirecionamento} />
              <Textarea
                type={'text'}
                label={'Observações'}
                placeholder={'Caso tenha alguma observação, escreva aqui.'}
                name={'observations'}
                value={observations}
                onChange={this.handleObservation}
              />
              {errorMessage && <Alert type={'negative'}>{errorMessage}</Alert>}
            </FormContent>
          </Content>
        </Body>
      </Main>
    </>
  }

  menuLateralDireito = () => {
    const { briefing } = this.props.match.params
    return <FixedDiv>
      <Card style={{ width: '100%' }}>
        <Label style={{ textTransform: 'uppercase' }}>Resumo do Evento <span style={{ float: 'right', paddingRight: '10px' }}>ID:{this.state.eventFriendlyId}</span></Label>
        <Group>
          <Label style={{ fontWeight: 600, color: '#484848' }}>{this.state.eventName}</Label>
          <Group style={{ display: 'flex' }}>
            <Icons
              icon={'calendario'}
              color={'gray'}
              width={40}
              height={20}
              style={{ marginRight: '-5px', marginTop: '5px' }} />
            <Label>{formatDate(this.state.eventStartDate)} - {formatDate(this.state.eventEndDate)}</Label>
          </Group>
          <Group style={{ display: 'flex' }}>
            <Icons
              icon={'map-pin'}
              color={'gray'}
              width={40}
              height={20}
              style={{ marginRight: '-5px', marginTop: '5px', marginLeft: '2px' }} />
            <Label>{this.state.eventLocation.address} - {this.state.eventLocation.city}</Label>
          </Group>
          <Group style={{ display: 'flex' }}>
            <Icons
              icon={'pessoas'}
              color={'gray'}
              width={40}
              height={20}
              style={{ marginTop: '5px', marginLeft: '-3px' }} />
            <Label>{this.state.numberOfParticipants} pessoas</Label>
          </Group>
        </Group>
      </Card>
      <Card style={{ width: '100%' }}>
        <ServicosSolicitados services={this.state.services} selected={'room'} />
        <Button
          type='submit'
          category={'secondary'}
          form={'form'}
          fontWeight={800}
          onClick={this.state.eventStatus === 'INACTIVE' ? this.submit.bind(this) : this.checkEdicao}
          full>
          {this.state.eventStatus === 'INACTIVE' ? (briefing ? 'Ok' : 'Próximo') : 'Salvar alterações'}
        </Button>
      </Card>
    </FixedDiv>
  }

  render() {
    return this.gerenciaResponsividade()
  }
}

const RenderRooms = ({ rooms, error, numberOfParticipants, removeRoomFromIndex, listaDeRedirecionamento }) => {
  return rooms.map((room, i) => {
    return <Card style={{ width: '100%' }}>
      <Button
        type='submit'
        category={'secondary'}
        fontWeight={800}
        onClick={(input) => removeRoomFromIndex(input, i)}
        style={{ position: 'absolute', right: '0', top: '10px', width: '100px' }}
        ghost
        small
        full>
        Excluir
      </Button>
      <Room
        index={i}
        roomId={room.id}
        key={room.id ? room.id : i}
        room={room}
        error={error}
        numberOfParticipants={numberOfParticipants}
        listaDeRedirecionamento={listaDeRedirecionamento} />
    </Card>
  })
}

export default Rooms
