import React from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../../styles/themes'

const InputText = styled.input`
  font-size: 14px;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  color: #767676;
  background-color: ${props => props.edited ? '#fdf5e8' : '#FFF'};
  border-radius: 1px;
  width: ${props => props.type_placeholder === 'side' ? '60%' : '100%'};
  padding: 6px 10px;
  box-sizing: border-box;
  border-top: 1px solid #CCC;
  border-right: 1px solid #CCC;
  border-left: 1px solid #CCC;
  border-bottom: ${props => props.error ? '2px solid red' : `2px solid ${defaultTheme.colors.primary.default}`};
  transition: all 0.2s linear;
`
const Label = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  box-sizing: border-box;
  padding: 5px 5px 5px 0px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
  `

const InputControl = styled.div`
  padding: 10px 10px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: ${props => props.type_placeholder === 'side' ? 'nowrap' : 'wrap'};
  align-items: center;
`
const Strong = styled.strong`
  color: ${props => props.error ? 'red' : defaultTheme.colors.whiteLabel.primary};
  font-weight: bold;
`

const Group = styled.div`
  display: flex;
  width: 100%;
`

const RightBox = styled.div`
  background: ${props => props.rightBoxNoBg ? 'unset' : '#e8e8e8'};
  width: auto;
  padding: 0 10px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${props => props.rightBoxNoBg ? 'unset' : '1px solid #e8e8e8'};
  font-size: 14px;
  font-family: "Open Sans",sans-serif;
  font-weight: 300;
  color: #727272;
`

const LeftBox = styled.div`
  background: ${props => props.leftBoxNoBg ? 'unset' : '#e8e8e8'};
  width: auto;
  padding: 0 10px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${props => props.leftBoxNoBg ? 'unset' : '1px solid #e8e8e8'};
  font-size: 14px;
  font-family: "Open Sans",sans-serif;
  font-weight: 300;
  color: #727272;
`

export default function (props) {
  const handleNumber = (input) => {
    if (input.target.type === 'number') {
      var invalidChars = [
        '-',
        '+',
        'e',
        'E'
      ]
      if (invalidChars.includes(input.key)) {
        input.preventDefault();
      }
    }
    if (props.onChange) {
      return props.onChange(input)
    }
  }
  const id = props.id || props.name
  return (
    <InputControl isDisabled={props.disabled} style={props.containerStyle || props.style} placeholder={props.placeholder}>
      {props.label && <Label theme={defaultTheme} htmlFor={id} error={props.error} type_placeholder={props.type_placeholder}>{props.label}
        <Strong theme={defaultTheme} error={props.error}> {props.isRequired ? '*' : ''}</Strong>
      </Label>}
      <Group>
        {props.leftBox && <LeftBox leftBoxNoBg={props.leftBoxNoBg}>{props.leftBox}</LeftBox>}
        <InputText {...props} theme={defaultTheme} onKeyDown={handleNumber} id={id} />
        {props.rightBox && <RightBox rightBoxNoBg={props.rightBoxNoBg}>{props.rightBox}</RightBox>}
      </Group>
    </InputControl>
  )
}
