/* LIBS */
import React, { Component } from 'react'
import Button from '../../../components/ui/Button'
import Title from '../../../components/ui/Title'
import Modal from '../../../components/Modal/index'
import styled from 'styled-components'
import { defaultTheme } from '../../../styles/themes'
import { formatCurrency } from '../../../../utils/money'

const Group = styled.div`
  display: flex;
  width: 100%;
`

const LabelBlack = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  box-sizing: border-box;
  padding: 30px 5px 5px 0px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 600;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
`

const Label = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  box-sizing: border-box;
  padding: 5px 5px 5px 0px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 200;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
`

const Span = styled.span`
  font-weight: 600;
`

class CondicoesGerais extends Component {
  constructor(props) {
    super()
    this.state = {
      paymentPolicies: props.paymentPolicies || {},
      isOpen: props.isOpen
    }
  }

  componentDidMount() {
    this.setState({isOpen: this.props.isOpen})
  }

  componentWillReceiveProps(props) {
    if(!props.paymentPolicies && props.isOpen) {
      this.props.submit && this.props.submit()
    }
    this.setState({isOpen: props.isOpen, paymentPolicies: props.paymentPolicies || {} })
  }

  submit = () => {
    this.props.submit && this.props.submit()
  }

  close = () => {
    this.props.onClose && this.props.onClose()
  }

  render() {
    return (
      <Modal maxWidth={'80%'} isOpen={this.state.isOpen} hasCloseButton>
        <Title>Condições gerais</Title>
        <LabelBlack>Política de cancelamento</LabelBlack>
        <Label><Span>{this.state.paymentPolicies.daysBeforeEvent} dias</Span> antes do início do evento. Após {this.state.paymentPolicies.daysBeforeEvent} dias, será cobrada uma <Span>multa de {formatCurrency(this.state.paymentPolicies.finePrice && this.state.paymentPolicies.finePrice.amount)}</Span>.</Label>

        {this.state.paymentPolicies.allowChanges && <>
          <LabelBlack>Possibilidade de alterações</LabelBlack>
          <Label>É possível fazer alterações em seu pedido <Span>até {this.state.paymentPolicies.upToHowManyDays} dias antes</Span> da data de início do seu evento.</Label>
        </>}

        <LabelBlack>Modalidade de pagamento</LabelBlack>
        <Label>É necessário fazer o pagamento antecipado de <Span>{this.state.paymentPolicies.antecipationPercentage}%</Span> do valor total. O restante do valor deve ser recebido em <Span>até {this.state.paymentPolicies.daysAfterEventToReceiveTheRest} dias</Span> após o término do evento.</Label>

        {this.state.paymentPolicies.otherPolicies &&
          <>
            <LabelBlack>Outras políticas</LabelBlack>
            <Label>{this.state.paymentPolicies.otherPolicies}</Label>
          </>
        }

        {this.state.paymentPolicies.generalInfo && <>
          <LabelBlack>Informações gerais</LabelBlack>
          <Label>{this.state.paymentPolicies.generalInfo}</Label>
        </>}
        <Group style={{ paddingTop: '20px' }}>
          <Button
            type={'button'}
            onClick={this.close}
            category={'secondary'}
            fontWeight={800}
            ghost
            full>
            Rejeitar
          </Button>
          <Button
            type={'button'}
            onClick={this.submit}
            category={'secondary'}
            fontWeight={800}
            full>
            Aceitar
          </Button>
        </Group>
      </Modal>
    )
  }
}

export default CondicoesGerais
