import moment from  'moment'

const formatDate = (date) => {
  return moment(date).format('DD MMM YYYY')
}

const formatDateWithTime = (date) => {
  return moment(date).format('DD MMM YYYY - HH:mm')
}

const getDateRange = (start, end) => {
  var range = []
  var current = start
  while(current <= end) {
    range.push(new Date(current))
    let newDate = current.setDate(current.getDate() + 1)
    current = new Date(newDate)
  }
  return range
}

const dateInInterval = (date, startDate, endDate) => {
  date = moment(date)
  startDate = moment(startDate)
  endDate = moment(endDate)
  return date >= startDate && date <= endDate
}

const timeInInterval = (time, startTime, endTime) => {
  const date = '2019-01-01'
  time = moment(`${date}T${time}.000Z`)
  startTime = moment(`${date}T${startTime}.000Z`)
  endTime = moment(`${date}T${endTime}.000Z`)
  return (time >= startTime && time <= endTime) ||
    (time > startTime && moment.duration(time.diff(endTime)).asMinutes() < 60) ||
    (time <= startTime && moment.duration(time.diff(startTime)).asMinutes() > -60)
}

const conflictOnDates = (interval1, interval2) => {
  if(!interval1 || !interval2) return false
  return dateInInterval(interval1.startDate, interval2.startDate, interval2.endDate)
    || dateInInterval(interval1.endDate, interval2.startDate, interval2.endDate)
    || dateInInterval(interval2.startDate, interval1.startDate, interval1.endDate)
    || dateInInterval(interval2.endDate, interval1.startDate, interval1.endDate)
}

const conflictOnTime = (interval1, interval2) => {
  if(!interval1 || !interval2) return false
  return timeInInterval(interval1.startTime, interval2.startTime, interval2.endTime)
    || timeInInterval(interval1.endTime, interval2.startTime, interval2.endTime)
    || timeInInterval(interval2.startTime, interval1.startTime, interval1.endTime)
    || timeInInterval(interval2.endTime, interval1.startTime, interval1.endTime)
}

// let start = "2019-01-31T02:00:00.000Z"
// let end = "2019-02-15T02:00:00.000Z"
// let date = "2019-02-18T02:00:00.000Z"

// let interval1 = {
//   startDate: '2019-01-05T02:00:00.000Z',
//   endDate: '2019-01-18T02:00:00.000Z',
//   startTime: '06:00:00',
//   endTime: '08:00:00'
// }

// let interval2 = {
//   startDate: '2019-01-10T02:00:00.000Z',
//   endDate: '2019-01-12T02:00:00.000Z',
//   startTime: '09:00:00',
//   endTime: '10:00:00'
// }

// console.log(dateInInterval(date, start, end))
// console.log(conflictOnDates(interval1, interval2))
// // console.log(timeInInterval(interval1.endTime, interval2.startTime, interval2.endTime))
// // console.log(conflictOnTime(interval1, interval2))
// console.log(conflictOnTime(interval1, interval2) && conflictOnDates(interval1, interval2))

export {
  formatDate,
  getDateRange,
  dateInInterval,
  timeInInterval,
  conflictOnDates,
  conflictOnTime,
  formatDateWithTime
}
