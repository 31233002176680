/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../../../../styles/themes'
/* COMPONENTS */
import Header from '../../../../Organizer/Header'
import Main from '../../../../components/Main'
import Room from './Room'
import Textarea from '../../../../components/ui/Textarea'
import Card from '../../../../components/ui/Card'
import Button from '../../../../components/ui/Button'
import RoundButton from '../../../../components/ui/RoundButton'
import Alert from '../../../../components/ui/Alert'
import Loader from '../../../../components/Loader'
import BreadCrumb from '../../../../components/BreadCrumb'
import {
  validaCriacaoDoEvento,
  validaCricaoDeHospedagem,
  validaCriacaoDeSalas,
  validateSalasTime,
  validateSalasForm,
  validateSalasDates
  } from '../../../utils/validacoes'
import moment from 'moment'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'
const serialize = require('dom-form-serializer').serialize

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #fff;
  font-size: 14px;
`

const Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  font-family: ${defaultTheme.fonts.secondary};
  padding: 3px 10px 13px 10px;
  color: #484848;
`

const FormContent = styled.form`
  padding: 10px 10px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
`

const Label = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  box-sizing: border-box;
  padding: 5px 5px 5px 0px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
`

const Strong = styled.strong`
  color: ${props => props.error ? 'red' : defaultTheme.colors.primary.default};
  font-weight: bold;
`

const Group = styled.div`
`

class Salas extends Component {
  state = {
    rooms: [],
    observations: '',
    errorMessage: false,
    isLoading: false,
    event: {},
    eventStartDate: '',
    eventEndDate: '',
    eventName: '',
    eventLocation: {},
    eventStatus: '',
    startTime: '',
    endTime: '',
    services: '',
    numberOfParticipants: 0,
    deuSubmit: false,
    hotelName: '',
    hotelRooms: []
  }

  componentDidMount() {
    const { supplierChainId, hotelId } = this.props.match.params
    document.title = "Espaços para Eventos"
    ReactGA.initialize(window.localStorage.getItem('googleAnalyticsCode'))
    if(hotelId) {
      ReactGA.pageview('hotelRooms')
    } else {
      ReactGA.pageview('chainRooms')
    }
    const eventosChain = window.localStorage.getItem('criarEventoChain') && JSON.parse(window.localStorage.getItem('criarEventoChain'))
    if(eventosChain && eventosChain[supplierChainId] && eventosChain[supplierChainId].event) {
      const { event, lodging } = eventosChain[supplierChainId]
      const { start, end } = event
      event.start = [moment(event.start, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br')]
      event.end = [moment(event.end, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br')]
      if(lodging) {
        lodging.eventStartDate = [moment(event.start, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br')]
        lodging.eventEndDate = [moment(event.end, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br')]
      }
      if(!validaCriacaoDoEvento(event, hotelId)) {
        alert('Verifique as suas informações do evento!')
        return this.props.history.push(`/espacos-para-eventos/${supplierChainId}${hotelId ? `/${hotelId}` : ''}/event`)
      } else if (this.verificaSeSelecionouHospedagem() && !validaCricaoDeHospedagem(lodging)) {
        alert('Verifique as suas informações de hospedagem!')
        return this.props.history.push(`/espacos-para-eventos/${supplierChainId}${hotelId ? `/${hotelId}` : ''}/lodging`)
      }
      const hoteisChain = (window.localStorage.getItem('hoteisChain') && JSON.parse(window.localStorage.getItem('hoteisChain'))) || []
      const hotelName = hoteisChain.find(hotel => hotel.id === hotelId) && hoteisChain.find(hotel => hotel.id === hotelId).name
      const hotelRooms = (hoteisChain.find(hotel => hotel.id === hotelId) && hoteisChain.find(hotel => hotel.id === hotelId).rooms) || []
      this.setState({
        eventStartDate: start[0],
        eventEndDate: end[0],
        hotelName,
        hotelRooms
      }, () => {
        if(eventosChain[supplierChainId].rooms) {
          this.setState({
            rooms: eventosChain[supplierChainId].rooms.rooms
          })
        } else {
          const { rooms } = this.state
          rooms.push({
            id: 0,
            startDate: this.state.eventStartDate,
            endDate: this.state.eventEndDate,
            startTime: this.state.startTime,
            endTime: this.state.endTime,
            mounting: false,
            roomType: '',
            roomFormat: '',
            amountOfPeople: this.state.numberOfParticipants,
            roomItems: [],
            errors: {
              startDate: '',
              endDate: '',
              startTime: '',
              endTime: '',
              mounting: '',
              roomType: '',
              roomFormat: '',
              amountOfPeople: '',
            }
          })
          this.setState({ rooms: rooms })
        }
      })
    } else {
      alert('Verifique as suas informações do evento!')
      this.props.history.push(`/espacos-para-eventos/${supplierChainId}${hotelId ? `/${hotelId}` : ''}/event`)
    }
  }

  addRoom = () => {
    const { rooms } = this.state
    rooms.push({
      startDate: this.state.eventStartDate,
      endDate: this.state.eventEndDate,
      startTime: this.state.startTime,
      endTime: this.state.endTime,
      mounting: false,
      roomType: '',
      roomFormat: '',
      amountOfPeople: this.state.numberOfParticipants,
      roomItems: [],
      errors: {
        startDate: '',
        endDate: '',
        startTime: '',
        endTime: '',
        mounting: '',
        roomType: '',
        roomFormat: '',
        amountOfPeople: ''
      }
    })
    this.setState({ rooms: rooms, deuSubmit: false })
  }

  removeRoom = () => {
    const { rooms } = this.state
    rooms.pop()
    this.setState({ rooms: rooms, deuSubmit: false })
  }

  removeRoomFromIndex = (input, index) => {
    input && input.preventDefault()
    const { rooms } = this.state
    rooms.splice(index, 1);
    this.setState({ rooms: rooms, deuSubmit: false })
  }

  verificaSeSelecionouHospedagem = () => {
    const { supplierChainId } = this.props.match.params
    const eventosChain = window.localStorage.getItem('criarEventoChain') && JSON.parse(window.localStorage.getItem('criarEventoChain'))
    if(eventosChain && eventosChain[supplierChainId] && eventosChain[supplierChainId].event) {
      return eventosChain[supplierChainId].event.services && eventosChain[supplierChainId].event.services.lodging && eventosChain[supplierChainId].event.services.lodging.requested
    } else {
      return false
    }
  }

  handleObservation = ({ target: { name, value } }) => {
    this.setState({ [name]: value })
  }

  submit = (e) => {
    e && e.preventDefault()
    this.setState({ deuSubmit: true })
    const forms = serialize(this.form)
    const { supplierChainId, hotelId } = this.props.match.params
    const eventosChain = window.localStorage.getItem('criarEventoChain') && JSON.parse(window.localStorage.getItem('criarEventoChain'))
    if(eventosChain && eventosChain[supplierChainId] && eventosChain[supplierChainId].event) {
      const { event } = eventosChain[supplierChainId]
      forms.eventStartDate = event.start[0]
      forms.eventEndDate = event.end[0]
    }
    forms.rooms = Object.keys(forms.rooms).map(i => {
      forms.rooms[i].startTime = (forms.rooms[i].startTime || '00:00') + ':00'
      forms.rooms[i].endTime = (forms.rooms[i].endTime || '00:00') + ':00'
      return forms.rooms[i]
    })
    if(!validaCriacaoDeSalas(forms)) return alert('Verifique todos os campos para continuar')
    if(eventosChain && eventosChain[supplierChainId] && eventosChain[supplierChainId].event) {
      eventosChain[supplierChainId].rooms = this.generateJSON()
      window.localStorage.setItem('criarEventoChain', JSON.stringify(eventosChain))
      this.props.history.push(`/espacos-para-eventos/${supplierChainId}${hotelId ? `/${hotelId}` : ''}/summary`)
    }
  }

  checkIfObjectIsEmpty = (obj) => {
    return Object.entries(obj).length === 0 && obj.constructor === Object
  }

  generateJSON = () => {
    const briefing = {}
    if(!this.checkIfObjectIsEmpty(serialize(this.form))) {
      const forms = serialize(this.form)
      const { supplierChainId } = this.props.match.params
      const eventosChain = window.localStorage.getItem('criarEventoChain') && JSON.parse(window.localStorage.getItem('criarEventoChain'))
      if(eventosChain && eventosChain[supplierChainId] && eventosChain[supplierChainId].event) {
        const { event } = eventosChain[supplierChainId]
        forms.eventStartDate = event.start[0]
        forms.eventEndDate = event.end[0]
      }
      forms.rooms = forms.rooms && Object.keys(forms.rooms).map(i => {
        forms.rooms[i].startTime = (forms.rooms[i].startTime || '00:00') + ':00'
        forms.rooms[i].endTime = (forms.rooms[i].endTime || '00:00') + ':00'
        return forms.rooms[i]
      })
      briefing.rooms = forms.rooms
      briefing.observations = this.state.observations
      return briefing
    } else {
      return briefing
    }
  }

  gerenciaResponsividade = () => {
    if (window.innerWidth < 600) return this.paginaMobile()
    else return this.paginaDesktop()
  }

  paginaMobile = () => {
    const { rooms, errorMessage, isLoading, observations } = this.state
    const { supplierChainId, hotelId } = this.props.match.params
    const forms = this.form ? serialize(this.form) : {}
    const eventosChain = window.localStorage.getItem('criarEventoChain') && JSON.parse(window.localStorage.getItem('criarEventoChain'))
    const gtmId = window.localStorage.getItem('gtmId')
    if(eventosChain && eventosChain[supplierChainId] && eventosChain[supplierChainId].event) {
      const { event } = eventosChain[supplierChainId]
      forms.eventStartDate = event.start[0]
      forms.eventEndDate = event.end[0]
    }
    forms.rooms = forms.rooms ? Object.keys(forms.rooms).map(i => {
      forms.rooms[i].startTime = (forms.rooms[i].startTime || '00:00') + ':00'
      forms.rooms[i].endTime = (forms.rooms[i].endTime || '00:00') + ':00'
      return forms.rooms[i]
    }) : []
    return <Content>
      {gtmId && TagManager.initialize({ gtmId })}
      <Header />
      <Card style={{ marginTop: '50px', maxWidth: '1000px' }}>
        <Content>
          {this.verificaSeSelecionouHospedagem() && <BreadCrumb options={
            [
              { link: `/espacos-para-eventos/${supplierChainId}${hotelId ? `/${hotelId}` : ''}/event`, name: 'Dados gerais do evento' },
              { link: `/espacos-para-eventos/${supplierChainId}${hotelId ? `/${hotelId}` : ''}/lodging`, name: 'Hospedagem' },
              { name: 'Salas' }
            ]} />}
          {!this.verificaSeSelecionouHospedagem() && <BreadCrumb options={
            [
              { link: `/espacos-para-eventos/${supplierChainId}${hotelId ? `/${hotelId}` : ''}/event`, name: 'Dados gerais do evento' },
              { name: 'Salas' }
            ]} />}
          <FormContent ref={form => this.form = form} id={'form'}>
            <Label style={{ fontSize: '13px ', marginLeft: '10px' }}>{this.verificaSeSelecionouHospedagem() ? 'Etapa 3 de 4' : 'Etapa 2 de 3'}</Label>
            <Title style={{ width: '100%' }}>Salas {hotelId && `em ${this.state.hotelName}`}</Title>
            {isLoading ? <Loader /> : null}
            <Group style={{ display: 'flex' }}>
              <Label style={{ marginLeft: '10px' }}>Quantas salas deseja para este evento? <Strong>*</Strong></Label>
              <RoundButton onClick={this.removeRoom} disabled={rooms.length <= 1} simbolo={'-'} />
              <Title style={{ width: '50px', marginTop: '5px', textAlign: 'center', color: defaultTheme.colors.whiteLabel.primary }}>{rooms.length}</Title>
              <RoundButton onClick={this.addRoom} disabled={hotelId && (this.state.hotelRooms.length <= rooms.length)} simbolo={'+'} />
            </Group>
            {hotelId && (this.state.hotelRooms.length <= rooms.length) && <Label style={{ marginLeft: '10px' }}><Strong>*</Strong> Não é possível solicitar salas além daquelas disponíveis neste hotel.</Label>}
            {!validateSalasForm(forms) && this.state.deuSubmit ? <Alert type={'negative'}>Preencha todos os campos obrigatórios para continuar</Alert> : null}
            {!validateSalasTime(forms) && validateSalasForm(forms) && this.state.deuSubmit ? <Alert type={'negative'}>Se o aluguel da sala for em apenas um único dia o horário de fim não pode ser menor que o horário de início.</Alert> : null}
            {!validateSalasDates(forms) && validateSalasTime(forms) && validateSalasForm(forms) && this.state.deuSubmit ? <Alert type={'negative'}>O período das datas só pode ser entre as datas do evento (podendo ser um dia antes / um dia depois).</Alert> : null}
            <RenderRooms
              rooms={rooms}
              error={errorMessage}
              deuSubmit={this.state.deuSubmit}
              numberOfParticipants={this.state.numberOfParticipants}
              removeRoomFromIndex={this.removeRoomFromIndex} />
            <Textarea
              type={'text'}
              label={'Observações'}
              placeholder={'Caso tenha alguma observação, escreva aqui.'}
              name={'observations'}
              value={observations}
              onChange={this.handleObservation}
            />
            {errorMessage && <Alert type={'negative'}>{errorMessage}</Alert>}
            <Button
              type='submit'
              category={'secondary'}
              fontWeight={800}
              onClick={this.submit}
              style={{ width: '200px', marginLeft: 'auto' }}
              full>
              Próximo
            </Button>
          </FormContent>
        </Content>
      </Card>
    </Content>
  }

  paginaDesktop = () => {
    const { rooms, errorMessage, isLoading, observations } = this.state
    const { supplierChainId, hotelId } = this.props.match.params
    const forms = this.form ? serialize(this.form) : {}
    const eventosChain = window.localStorage.getItem('criarEventoChain') && JSON.parse(window.localStorage.getItem('criarEventoChain'))
    const gtmId = window.localStorage.getItem('gtmId')
    if(eventosChain && eventosChain[supplierChainId] && eventosChain[supplierChainId].event) {
      const { event } = eventosChain[supplierChainId]
      forms.eventStartDate = event.start[0]
      forms.eventEndDate = event.end[0]
    }
    forms.rooms = forms.rooms ? Object.keys(forms.rooms).map(i => {
      forms.rooms[i].startTime = (forms.rooms[i].startTime || '00:00') + ':00'
      forms.rooms[i].endTime = (forms.rooms[i].endTime || '00:00') + ':00'
      return forms.rooms[i]
    }) : []
    return <>
      {gtmId && TagManager.initialize({ gtmId })}
      <Header />
      <Main>
        <Card style={{ marginTop: '50px', maxWidth: '1000px' }}>
          <Content>
            {this.verificaSeSelecionouHospedagem() && <BreadCrumb options={
              [
                { link: `/espacos-para-eventos/${supplierChainId}${hotelId ? `/${hotelId}` : ''}/event`, name: 'Dados gerais do evento' },
                { link: `/espacos-para-eventos/${supplierChainId}${hotelId ? `/${hotelId}` : ''}/lodging`, name: 'Hospedagem' },
                { name: 'Salas' }
              ]} />}
            {!this.verificaSeSelecionouHospedagem() && <BreadCrumb options={
              [
                { link: `/espacos-para-eventos/${supplierChainId}/event`, name: 'Dados gerais do evento' },
                { name: 'Salas' }
              ]} />}
            <FormContent ref={form => this.form = form} id={'form'}>
              <Label style={{ fontSize: '13px ', marginLeft: '10px' }}>{this.verificaSeSelecionouHospedagem() ? 'Etapa 3 de 4' : 'Etapa 2 de 3'}</Label>
              <Title style={{ width: '100%' }}>Salas {hotelId && `em ${this.state.hotelName}`}</Title>
              {isLoading ? <Loader /> : null}
              <Group style={{ display: 'flex' }}>
                <Label style={{ marginLeft: '10px' }}>Quantas salas deseja para este evento? <Strong>*</Strong></Label>
                <RoundButton onClick={this.removeRoom} disabled={rooms.length <= 1} simbolo={'-'} />
                <Title style={{ width: '50px', marginTop: '5px', textAlign: 'center', color: defaultTheme.colors.whiteLabel.primary }}>{rooms.length}</Title>
                <RoundButton onClick={this.addRoom} disabled={hotelId && (this.state.hotelRooms.length <= rooms.length)} simbolo={'+'} />
              </Group>
              {hotelId && (this.state.hotelRooms.length <= rooms.length) && <Label style={{ marginLeft: '10px' }}><Strong>*</Strong> Não é possível solicitar salas além daquelas disponíveis neste hotel.</Label>}
              {!validateSalasForm(forms) && this.state.deuSubmit ? <Alert type={'negative'}>Preencha todos os campos obrigatórios para continuar</Alert> : null}
              {!validateSalasTime(forms) && validateSalasForm(forms) && this.state.deuSubmit ? <Alert type={'negative'}>Se o aluguel da sala for em apenas um único dia o horário de fim não pode ser menor que o horário de início.</Alert> : null}
              {!validateSalasDates(forms) && validateSalasTime(forms) && validateSalasForm(forms) && this.state.deuSubmit ? <Alert type={'negative'}>O período das datas só pode ser entre as datas do evento (podendo ser um dia antes / um dia depois).</Alert> : null}
              <RenderRooms
                rooms={rooms}
                error={errorMessage}
                deuSubmit={this.state.deuSubmit}
                numberOfParticipants={this.state.numberOfParticipants}
                removeRoomFromIndex={this.removeRoomFromIndex} />
              <Textarea
                type={'text'}
                label={'Observações'}
                placeholder={'Caso tenha alguma observação, escreva aqui.'}
                name={'observations'}
                value={observations}
                onChange={this.handleObservation}
              />
              {errorMessage && <Alert type={'negative'}>{errorMessage}</Alert>}
              <Button
                type='submit'
                category={'secondary'}
                fontWeight={800}
                onClick={this.submit}
                style={{ width: '200px', marginLeft: 'auto' }}
                full>
                Próximo
              </Button>
            </FormContent>
          </Content>
        </Card>
      </Main>
    </>
  }

  render() {
    return this.gerenciaResponsividade()
  }
}

const RenderRooms = ({ rooms, error, numberOfParticipants, removeRoomFromIndex, deuSubmit }) => {
  return rooms.map((room, i, array) => {
    return <Card style={{ width: '100%', margin: '10px' }}>
      {(array.length > 1) && <Button
        disabled={array.length === 1}
        type='submit'
        category={'secondary'}
        fontWeight={800}
        onClick={(input) => removeRoomFromIndex(input, i)}
        style={{ position: 'absolute', right: '0', top: '10px', width: '100px' }}
        ghost
        small
        full>
        Excluir
      </Button>}
      <Room
        index={i}
        roomId={room.id}
        key={room.id ? room.id : i}
        room={room}
        error={error}
        deuSubmit={deuSubmit}
        numberOfParticipants={numberOfParticipants} />
    </Card>
  })
}

export default Salas
