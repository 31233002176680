import { 
  FETCH_ROOMS_REQUEST,
  FETCH_ROOMS_SUCCESS,
  FETCH_ROOMS_FAILURE
} from '../actions/rooms'
 
export default function rooms (state = {
  isFetching: false,
  rooms: false
}, action) {
  switch (action.type) {
    case FETCH_ROOMS_REQUEST:
    return Object.assign({}, state, {
      isFetching: true,
      data: action.data
    })
    case FETCH_ROOMS_SUCCESS:
    return Object.assign({}, state, {
      isFetching: false,
      rooms: action.rooms
    })
    case FETCH_ROOMS_FAILURE:
    return Object.assign({}, state, {
      isFetching: false,      
      errorMessage: action.message
    })
  default:
    return state
  }
}
