import React, { Component } from 'react'
import styled from 'styled-components'
import Dropdown from './SidebarItems/Dropdown'
import ItemsMenuLateral from './SidebarItems/ItemsMenuLateral'
import Icons from './ui/Icons'
import Subtitle from './ui/Subtitle'
import Button from './ui/Button'
import Modal from './Modal'
import { defaultTheme } from '../styles/themes'
import { VERSION } from './VersionBuild'
import { ehChainUser, ehTipo, temFeatureFlag } from '../../utils/tiposUsuario'

const Container = styled.div`
  display: none;
  @media (min-width: 600px) {
    display: block;
    position: fixed;
    height: calc(100vh - 75px);
    width: calc(18% - 20px);
    margin-right: 20px;
    margin-top: 1px;
    background-color: #FAFAFA;
    min-height: calc(100vh - 120px);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
    background: #fff;
  }
`

const VersionBuildStyled = styled.span`
  color: #C0C0C0;
  padding: 2px 2px 2px 2px;
  display: block;
  font-size: 11px;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  width:100%;
  text-align: center;
`
const DivVersion = styled.div`
  position: absolute;
  bottom: 0;
  text-align: center;
  display: block;
  padding: 2px 2px 2px 2px;
`

const Title = styled.h1`
  font-family: ${defaultTheme.fonts.secondary};
  line-height: 1.2;
  font-size: 1.3em;
  margin-top: auto;
  margin-bottom: 20px;
`

const Group = styled.div`
  height: inherit;
  overflow-y: auto;
`

class Sidebar extends Component {
  constructor () {
    super()
    this.state = {
      chainModal: false
    }
  }

  verifaRole = (nivel) => {
    const roles = window.localStorage.getItem('user_roles') !== 'undefined' && JSON.parse(window.localStorage.getItem('user_roles'))
    return roles && roles.find(role => role === nivel)
  }

  render () {
    const EXIBE_DASHBOARD_REDASH = (temFeatureFlag('dashboard') || temFeatureFlag('dashboards')) && ehTipo('ADMIN')
    const EXIBE_ADMIN = (ehTipo('HEADQUARTER_ADMIN') || ehTipo('BRANCH_ADMIN') || ehTipo('COSTCENTER_ADMIN')) && !ehChainUser()
    const EXIBE_DASHBOARD = EXIBE_ADMIN && !EXIBE_DASHBOARD_REDASH

    return <>
      <Modal isOpen={this.state.chainModal}>
        <Icons
          icon='blocked'
          color={defaultTheme.colors.whiteLabel.secondary}
          width={150}
          height={150}
        />
        <Title style={{ paddingTop: '20px', fontWeight: 600 }}>Opa, não dá pra criar um novo evento por aqui</Title>
        <Subtitle>É necessário voltar à página da rede para solicitar um Novo Evento.</Subtitle>
        <Group>
          <Button
            onClick={() => this.setState({ chainModal: false })}
            category='secondary'
            fontWeight={800}
            ghost
            width='50%'
            full>
            Ok
          </Button>
        </Group>
      </Modal>
      <Container {...this.props}>
        <Dropdown title='Eventos'>
          <ul>
            <ItemsMenuLateral
              title='Meus eventos'
              onClick={() => this.props.history.push('/organizer')}
              selected={this.props.pagina === 'inicio' || this.props.pagina === 'editar-evento'}>
              <Icons
                icon='icon_meus_eventos'
                width={36}
                height={20}
                color='#484848'
                style={{ verticalAlign: 'middle', margin: 'auto 5px auto 0' }}
              />
              {this.verifaRole('APPROVER') ? 'Fornecedores para aprovação' : 'Meus eventos'}
            </ItemsMenuLateral>
            <ItemsMenuLateral
              title='Novo evento'
              onClick={() => {
                if (ehChainUser()) this.setState({ chainModal: true })
                else this.props.history.push('/organizer/event')
              }}
              selected={this.props.pagina === 'create-evento'}>
              <Icons
                icon='icon_novo_evento'
                width={36}
                height={20}
                color='#484848'
                style={{ verticalAlign: 'middle', margin: 'auto 3px auto 2px' }}
              />
              Novo evento
            </ItemsMenuLateral>
            {EXIBE_DASHBOARD_REDASH &&
              <ItemsMenuLateral
                title='Dashboard'
                onClick={() => this.props.history.push('/organizer/dashboard')}
                selected={this.props.pagina === 'dashboard'}>
                <Icons
                  icon='info-circle'
                  width={36}
                  height={20}
                  color='#484848'
                  style={{ verticalAlign: 'middle', margin: 'auto 2px auto 3px' }}
                />
                Dashboard
              </ItemsMenuLateral>}
            {EXIBE_DASHBOARD &&
              <ItemsMenuLateral
                title='Dashboard'
                onClick={() => this.props.history.push('/organizer/dashboard/gastos')}
                selected={this.props.pagina === 'dashboard-gastos'}>
                <Icons
                  icon='icon_dashboard'
                  width={36}
                  height={20}
                  color='#484848'
                  style={{ verticalAlign: 'middle', margin: 'auto 3px auto 2px' }}
                />
                Dashboard
              </ItemsMenuLateral>}
            {EXIBE_ADMIN &&
              <ItemsMenuLateral
                title='Administrador'
                onClick={() => this.props.history.push('/organizer/dashboard/administrator')}
                selected={this.props.pagina === 'administrator'}>
                <Icons
                  icon='icon_admin'
                  width={36}
                  height={20}
                  color='#484848'
                  style={{ verticalAlign: 'middle', margin: 'auto 5px auto 0' }}
                />
                Administrador
              </ItemsMenuLateral>}
          </ul>
        </Dropdown>
        <DivVersion onClick={() => { window.location.href = 'https://www.evnts.com.br/' }} style={{ cursor: 'pointer', width: '100%' }}>
          <VersionBuildStyled>powered by EVNTS</VersionBuildStyled>
          <VersionBuildStyled>versao: {VERSION}</VersionBuildStyled>
        </DivVersion>
      </Container>
    </>
  }
}

export default Sidebar
