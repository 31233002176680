/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
/* UTILS */
import config from '../../../middleware/config'
import { Get } from '../../../utils/request'
import { defaultTheme } from '../../styles/themes'
/* COMPONENTS */
import Header from '../../Organizer/Header'
import Main from '../../components/Main'
import MoedaIcon from '../../components/ui/MoedaIcon'
import Title from '../../components/ui/Title'
import Subtitle from '../../components/ui/Subtitle'
import Slider from '../../components/ui/Slider'
import Card from '../../components/ui/Card'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'
import { renderToStaticMarkup } from 'react-dom/server'

const FallBackImage = () => <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 231.12 155.21" style={{ fill: defaultTheme.colors.whiteLabel.primary }}>
  <title>nao_encontrado_</title>
  <g id="CEmoIY.tif">
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M68.87,152.63H76v-1.38q0-47.32,0-94.65a2,2,0,0,1,2.32-2.29q20.11,0,40.25,0c1.64,0,2.29.66,2.29,2.31q0,23.08,0,50V108h1.3q15.93,0,31.86,0c1.89,0,2.68.5,2.68,2.73v41.87h9.45V95.65h-9.42v8.89h-3.15V95.66h-9.42v7h-2.92V86.79c-1.61,0-4,0-5.48,0a5.19,5.19,0,0,1-1.41-.22,1.47,1.47,0,0,1-1.1-1.54c0-4.89,0-9.78,0-14.67,0-1.09.81-1.75,2.51-1.76,2.23,0,5.72,0,10.49,0h1.27V63.73c0-1.57.69-2.26,2.28-2.28h2.94l.33-.09V49.07h3.38V61.34a1.33,1.33,0,0,0,.32.09l2.89,0c1.67,0,2.29.64,2.29,2.29s0,3.18,0,4.84h1.27c3.46,0,6.91,0,10.37,0a2.93,2.93,0,0,1,2.63,1V85.75a2.68,2.68,0,0,1-2.32,1h-4.76v65.84h7.08V155H68.87Zm9.83-.06h75.38V110.64H120.8v35h-2.34V56.87H78.74v5.61h25.88v2.76H78.7Zm57.09-81.32V84.42h37.94V71.25Zm8.36,21.36h22V86.86h-22Zm15.28-28.85H150v4.75h9.42Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M111.83,75.9c0,1.22,0,2.44,0,3.66a1.6,1.6,0,0,1-1.8,1.77c-2.41,0-4.81,0-7.22,0A1.74,1.74,0,0,1,101,79.56q0-3.66,0-7.32a1.74,1.74,0,0,1,1.84-1.77q3.6,0,7.22,0a1.6,1.6,0,0,1,1.8,1.77C111.84,73.46,111.83,74.68,111.83,75.9Zm-8.72,3.23h6.53V72.67h-6.53Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M110.09,147.54H86.77v-2.4h23.32Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M149.54,147.53H144v-3.62h5.52Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M131.72,143.89v3.64h-5.5v-3.64Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M144,140.37v-3.58h5.54v3.58Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M131.69,140.42h-5.46v-3.64h5.46Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M149.52,133.3H144v-3.66h5.48Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M126.17,133.25v-3.59h5.55v3.59Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M144,122.29h5.5v3.62H144Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M131.69,126h-5.47V122.3h5.47Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M144,118.79v-3.6h5.54v3.6Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M126.17,118.78V115.2h5.54v3.58Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M140.5,147.55h-5.26V143.9h5.26Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M140.55,136.78v3.6h-5.33v-3.6Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M140.56,129.66v3.59h-5.34v-3.59Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M140.53,122.3v3.63h-5.29V122.3Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M140.51,118.83h-5.28v-3.66h5.28Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M145.74,79.61h-3.63V76h3.63Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M151.2,76V79.6h-3.62V76Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M153,76h3.64v3.62H153Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M158.24,79.6V76h3.62V79.6Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M167.33,79.61H163.7V76h3.63Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M95.73,75.9c0,1.22,0,2.44,0,3.66a1.59,1.59,0,0,1-1.8,1.77c-2.4,0-4.81,0-7.21,0a1.73,1.73,0,0,1-1.84-1.77q-.06-3.66,0-7.32a1.73,1.73,0,0,1,1.83-1.77q3.61,0,7.22,0a1.59,1.59,0,0,1,1.8,1.77C95.74,73.46,95.73,74.68,95.73,75.9ZM87,79.13h6.53V72.67H87Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M111.83,90.15c0,1.22,0,2.44,0,3.66a1.61,1.61,0,0,1-1.8,1.78h-7.22A1.74,1.74,0,0,1,101,93.82q0-3.66,0-7.32a1.75,1.75,0,0,1,1.84-1.78q3.6,0,7.22,0a1.61,1.61,0,0,1,1.8,1.77C111.84,87.71,111.83,88.93,111.83,90.15Zm-8.72,3.23h6.53V86.93h-6.53Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M95.73,90.15c0,1.22,0,2.44,0,3.66a1.61,1.61,0,0,1-1.8,1.78H86.71a1.73,1.73,0,0,1-1.84-1.77q-.06-3.66,0-7.32a1.74,1.74,0,0,1,1.83-1.78q3.61,0,7.22,0a1.6,1.6,0,0,1,1.8,1.77C95.74,87.71,95.73,88.93,95.73,90.15ZM87,93.38h6.53V86.93H87Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M111.83,104.64c0,1.22,0,2.44,0,3.66a1.6,1.6,0,0,1-1.8,1.77c-2.41,0-4.81,0-7.22,0A1.74,1.74,0,0,1,101,108.3q0-3.66,0-7.32a1.76,1.76,0,0,1,1.84-1.78c2.4,0,4.81,0,7.22,0a1.6,1.6,0,0,1,1.8,1.77C111.84,102.2,111.83,103.42,111.83,104.64Zm-8.72,3.23h6.53v-6.46h-6.53Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M95.73,104.64c0,1.22,0,2.44,0,3.66a1.6,1.6,0,0,1-1.8,1.77c-2.4,0-4.81,0-7.21,0a1.73,1.73,0,0,1-1.84-1.77q-.06-3.66,0-7.32A1.74,1.74,0,0,1,86.7,99.2c2.41,0,4.82,0,7.22,0a1.59,1.59,0,0,1,1.8,1.77C95.74,102.2,95.73,103.42,95.73,104.64ZM87,107.87h6.53v-6.46H87Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M111.83,118.89c0,1.22,0,2.44,0,3.66a1.61,1.61,0,0,1-1.8,1.78c-2.41,0-4.81,0-7.22,0a1.75,1.75,0,0,1-1.84-1.77c0-2.44,0-4.89,0-7.33a1.74,1.74,0,0,1,1.84-1.77H110a1.61,1.61,0,0,1,1.8,1.77C111.84,116.45,111.83,117.67,111.83,118.89Zm-8.72,3.23h6.53v-6.46h-6.53Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M95.73,118.89c0,1.22,0,2.44,0,3.66a1.61,1.61,0,0,1-1.8,1.78c-2.4,0-4.81,0-7.21,0a1.74,1.74,0,0,1-1.84-1.77c0-2.44,0-4.89,0-7.33a1.73,1.73,0,0,1,1.83-1.77h7.22a1.6,1.6,0,0,1,1.8,1.77C95.74,116.45,95.73,117.67,95.73,118.89ZM87,122.12h6.53v-6.46H87Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M111.83,133.11c0,1.22,0,2.44,0,3.66a1.61,1.61,0,0,1-1.8,1.78h-7.22a1.75,1.75,0,0,1-1.84-1.77c0-2.44,0-4.89,0-7.33a1.74,1.74,0,0,1,1.84-1.77q3.6,0,7.22,0a1.61,1.61,0,0,1,1.8,1.77C111.84,130.67,111.83,131.89,111.83,133.11Zm-8.72,3.23h6.53v-6.46h-6.53Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M95.73,133.11c0,1.22,0,2.44,0,3.66a1.61,1.61,0,0,1-1.8,1.78H86.71a1.74,1.74,0,0,1-1.84-1.77c0-2.44,0-4.89,0-7.33a1.73,1.73,0,0,1,1.83-1.77q3.61,0,7.22,0a1.6,1.6,0,0,1,1.8,1.77C95.74,130.67,95.73,131.89,95.73,133.11ZM87,136.34h6.53v-6.46H87Z" />
  </g>
  <circle style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} cx="56.91" cy="5.84" r="3.85" />
  <circle style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} cx="208.47" cy="32.86" r="3.85" />
  <circle style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} cx="21.63" cy="150.08" r="3.85" />
  <line style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} x1="170.01" y1="4.85" x2="160.31" y2="4.85" />
  <line style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} x1="165.16" x2="165.16" y2="9.71" />
  <line style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} x1="16.17" y1="31.72" x2="6.46" y2="31.72" />
  <line style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} x1="11.31" y1="26.87" x2="11.31" y2="36.58" />
  <line style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} x1="218.07" y1="147.37" x2="208.36" y2="147.37" />
  <line style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} x1="213.21" y1="142.52" x2="213.21" y2="152.23" />
  <line style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} x1="9.71" y1="102.6" y2="102.6" />
  <line style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} x1="4.85" y1="97.75" x2="4.85" y2="107.46" />
  <path style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} d="M136.68,21.59c-4.65-1.13-3.13-7.42-7.78-8.55s-6.17,5.16-10.82,4S115,9.66,110.31,8.53s-6.18,5.16-10.83,4" />
  <path style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} d="M50.85,67c-4.79,0-4.79-6.47-9.57-6.47S36.49,67,31.71,67s-4.78-6.47-9.57-6.47S17.35,67,12.57,67" />
  <path style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} d="M230.87,103.41c-4.15-2.39-.91-8-5.05-10.38s-7.38,3.21-11.52.82-.91-8-5.06-10.39-7.38,3.21-11.52.81" />
</svg>


const Cardizinho = (props) => {
  return (<RoomItem>
    <RoomPhoto style={{ backgroundImage: `url(${props.picture}), url(data:image/svg+xml,${encodeURIComponent(renderToStaticMarkup(<FallBackImage />))})` }} />
    <RoomDescription>
      <RoomTitle>{props.name}</RoomTitle>
    </RoomDescription>
  </RoomItem>)
}


const MainMobile = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #FAFAFA;
`

const Group = styled.div`
  margin: auto;
  margin-top: 80px;
`

const Label = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  font-family: ${defaultTheme.fonts.primary};
  font-size: 14px;
  box-sizing: border-box;
  padding: 5px 0px;
  display: block;
  font-weight: 300;
`
const RoomItem = styled.div`
  height: 220px;
  background-color: #fff;
  margin: 5px 10px;
  min-width: calc((100% / 3) - 20px);
  cursor: pointer;
  border: none;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  @media (max-width: 768px) {
    width: 100%;
  }
`

const RoomTitle = styled.span`
  color: #333;
  font-size: 16px;
  width: 100%;
  display: block;
  font-family: ${defaultTheme.fonts.secondary};
`
const RoomPhoto = styled.div`
  cursor: default;
  height: 150px;
  width: 100%;
  background-position: center;
  background-size: cover;
`
const RoomDescription = styled.div`
  cursor: default;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const Carousel = styled.div`
  .slick-next:before, .slick-prev:before {
    color: ${defaultTheme.colors.whiteLabel.primary};
  }
`

class Confirmacao extends Component {
  constructor () {
    super()
    this.state = {
      suppliers: []
    }
  }

  componentDidMount() {
    const { hotelId } = this.props.match.params
    document.title = "Espaços para Eventos"
    ReactGA.initialize(window.localStorage.getItem('googleAnalyticsCode'))
    if(hotelId) {
      ReactGA.pageview('hotelConfirmation')
    } else {
      ReactGA.pageview('chainConfirmation')
    }

    if(this.props.location.state.eventId) {
      const { api: { base, version } } = config
      const endpointEvent = `${base}${version}/chain/event/${this.props.location.state.eventId}/info`
      Get(endpointEvent).then((data) => {
        this.setState({ suppliers: data.suppliers })
      }).catch(err => console.error(err))
    }
  }

  gerenciaResponsividade = () => {
    if (window.innerWidth < 600) return this.paginaMobile()
    else return this.paginaDesktop()
  }

  paginaMobile = () => {
    const gtmId = window.localStorage.getItem('gtmId')
    return <MainMobile>
      {gtmId && TagManager.initialize({ gtmId })}
      <Header />
      <Group>
        <MoedaIcon style={{ margin: 'auto', display: 'block' }} />
        <Title style={{ textAlign: 'center', fontSize: '26px' }}>Orçamento solicitado</Title>
        <Subtitle style={{ width: '400px', textAlign: 'center', marginTop: '20px' }}>Em breve você receberá um email para se cadastrar na plataforma e receber seus orçamentos.</Subtitle>
      </Group>
    </MainMobile>
  }

  paginaDesktop = () => {
    const gtmId = window.localStorage.getItem('gtmId')
    return <>
      {gtmId && TagManager.initialize({ gtmId })}
      <Header />
      <Main>
        <Group>
          <Group style = {{marginTop: '5px'}}>
            <MoedaIcon style={{ margin: 'auto', display: 'block' }} />
            <Title style={{ textAlign: 'center', fontSize: '26px' }}>Orçamento solicitado</Title>
            <Subtitle style={{ width: '400px', textAlign: 'center', margin: 'auto', marginTop: '20px' }}>Em breve você receberá um email para se cadastrar na plataforma e receber seus orçamentos.</Subtitle>
          </Group>
          <Carousel>
            {this.state.suppliers.length > 0 && <Card style={{ marginTop: '50px', maxWidth: '1000px', paddingBottom: '50px' }}>
              <Label style={{ marginLeft: '20px' }}>Os seguintes hotéis lhe enviarão orçamentos:</Label>
                <Slider maxWidth={'100%'} style={{ marginBottom: '1px', marginTop: '0px', padding: '0 35px' }}>
                  {this.state.suppliers.reduce((grupo, supplier, index) => {
                    (index % 3) === 0 && grupo.push(<Group style={{ display: 'flex', marginTop: '8px' }}>
                        <Cardizinho
                          picture={supplier.pictures && supplier.pictures.main}
                          name={supplier.name}
                        />
                      {this.state.suppliers[index+1] &&
                        <Cardizinho
                          picture={this.state.suppliers[index+1].pictures && this.state.suppliers[index+1].pictures.main}
                          name={this.state.suppliers[index+1].name}
                        />}
                      {this.state.suppliers[index+2] &&
                        <Cardizinho
                          picture={this.state.suppliers[index+2].pictures && this.state.suppliers[index+2].pictures.main}
                          name={this.state.suppliers[index+2].name}
                        />}
                    </Group>)
                    return grupo
                  }, [])}
                </Slider>
              </Card>}
          </Carousel>
          </Group>
      </Main>
    </>
  }

  render () {
    return this.gerenciaResponsividade()
  }
}

export default Confirmacao
