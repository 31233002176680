import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import Icon from './Icons'
import styled from 'styled-components'
import { defaultTheme } from '../../styles/themes'

const Texto = styled.div`
  font-family: ${defaultTheme.fonts.primary};
  line-height: 1.3;
  font-size: 1rem;
  width: 250px;
  color: ${defaultTheme.colors.text.dark};
  & strong {
    color: ${defaultTheme.colors.primary.default}
  }
`

const iconStyle = {
  width: '45px',
  height: '45px',
  fill: defaultTheme.colors.primary.default,
  margin: '0 10px',
  padding: '0'
}

const style = {
  width: '100%',
  border: `2px dashed ${defaultTheme.colors.primary.default}`,
  justifyContent: 'center',
  padding: '40px 20px',
  borderRadius: '5px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  marginTop: '10px'
}

const Label = styled.label`
  color: #484848;
  box-sizing: border-box;
  padding: 5px 5px 5px 0px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
  `


export default class DragnDrop extends Component {
  constructor () {
    super()
    this.state = {
      currentText: <span>Arraste seus arquivos aqui ou <strong>clique para buscar</strong> em seu computador</span>
    }
  }

  onClick = () => {
    this.setState({
      currentText: <span>Carregando arquivo...</span>
    })
  }

  onFileDialogCancel = () => {
    this.setState({
      currentText: <span>Arraste seus arquivos aqui ou <strong>clique para buscar</strong> em seu computador</span>
    }, () => this.props.onFileDialogCancel())
  }

  onDrop = files => {
    if(files.length === 0) return alert('Selecione um arquivo válido!')
    var fileNames = files.map(f => <li key={f.name}>{f.name} - {f.size} bytes</li>)
    this.setState({
      currentText: <ul>{fileNames}</ul>
    }, () => this.props.onDrop(files))
  }

  render () {
    return <div style={{ padding: '10px' }}>
      <Label>Adicionar arquivo(s) em .pdf, .jpg ou .png</Label>
      <Dropzone
        {...this.props}
        onClick={this.onClick}
        onFileDialogCancel={this.onFileDialogCancel}
        onDrop={this.onDrop}>
        {({getRootProps, getInputProps}) => <div {...getRootProps()} style={style}>
          <input {...getInputProps()} />
          <Icon icon='file-pdf-o' style={iconStyle}/>
          <Texto>{this.props.uploadedText || this.props.rejectedText || this.state.currentText}</Texto>
        </div>
        }
      </Dropzone>
    </div>
  }
}
