/* LIBS */
import React from 'react'
import styled from 'styled-components'
/* STYLES */
import { defaultTheme } from '../../styles/themes'

const List = styled.div`
  padding: 0px 10px;
  color: #7b7b7b;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
`

const FoodItem = styled.div`
  width: 100%;
  margin: 10px 0px;
  cursor: pointer;
  align-items: center;
  background-color: #FFF;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  color: #484848;
  font-family: ${defaultTheme.fonts.secondary};
  font-weight: 500;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  border: none;
      border-radius: 2px;
      box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
`
const FoodItemDescription = styled.p`
  color: #7b7b7b;
  font-size: 12px;
  width: 100%;
`

const titles = {
  welcome_coffee: 'Welcome coffee',
  welcome_drink: 'Welcome drink',
  coffee_break_morning: 'Coffee break manhã',
  brunch: 'Brunch',
  lunch: 'Almoço',
  coffee_break_afternoon: 'Coffee break tarde',
  dinner: 'Jantar',
  thematic_dinner: 'Jantar temático',
  rooms_service: 'Serviço de sala',
  tray: 'Bandeja',
  cocktail: 'Coquetel'
}
const servicesTitles = {
  water: 'Água',
  coffee: 'Térmica de café',
  tea: 'Térmica de chá',
  milk:'Térmica de leite',
  juice: 'Jarra de suco',
  soda: 'Refrigerante'
}


const FoodsAndBeveragesList = ({ foodsAndBeverages, history, url }) => {
  const menus = formatMenus(foodsAndBeverages).filter(menu => menu.enabled)
  const services = foodsAndBeverages['rooms_service'].items
  const roomsService =  services ? services
                          .filter(s => s.quantity && s.price.amount > 0) : []
  const formatServices = roomsService.map(i => servicesTitles[i.name]).join(', ')
  const tray = foodsAndBeverages['tray'] ? foodsAndBeverages['tray'].items.filter(menu => menu.price) : []
  return (
    <List>
      {
        menus.map(menu => {
          return <Item key={menu.id} history={history} url={url} item={menu.title} description={menu.description}/>
        })
      }
      {
        foodsAndBeverages['rooms_service'].enabled
        ? <Item item={'Serviços de sala'} history={history} url={url}
            description={`${formatServices}, ${tray.length} Bandeja(s)`}/>
        : null
      }

    </List>
  )
}

const Item = ({item, description, history, url}) => {
  return (
    <FoodItem onClick={() => history.push(url)}>
      <span>{item}</span>
      <FoodItemDescription>{description}</FoodItemDescription>
    </FoodItem>
  )
}

const formatMenus = (foodsAndBeverages) => {
  const filtered = Object.keys(foodsAndBeverages).filter(removeItems)
  return filtered.map(menu => {
    return {
      enabled: foodsAndBeverages[menu] && foodsAndBeverages[menu].enabled,
      id: menu,
      title: titles[menu],
      description: foodsAndBeverages[menu] && foodsAndBeverages[menu].items ? `${foodsAndBeverages[menu].items.length} cardápio(s)`: '0'
    }
  })
}

const removeItems = (item) => {
  const toRemove = ['rooms_service', 'tray', 'taxes']
  return !toRemove.includes(item)
}

export default FoodsAndBeveragesList
