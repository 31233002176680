/* LIBS */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
/* ACTIONS */
import { fetchFoodsAndBeverages } from '../../../redux/actions/forms'
/* STYLES */
import { defaultTheme } from '../../styles/themes'
/* COMPONENTS */
import Header from '../../components/Header'
import Loader from '../../components/Loader'
import FoodsAndBeveragesList from './FoodsAndBeveragesList'
/* UI */
import Button from '../../components/ui/Button'
import BreadCrumb from '../../components/BreadCrumb'

const Main = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #FFF;
  @media (min-width: 768px) {
    background-color: #FAFAFA;
  }
`
const Content = styled.div`
  min-height: 90vh;
  padding: 30px 0px;
  font-family: ${defaultTheme.fonts.secondary};
  width: 90%;
  margin: 0 5%;
`
const Title = styled.h1`
  width: 100%;
  text-align: left;
  font-size: 20px;
  padding: 20px 10px;
`

const NoFoodsAndBeverages = styled.p`
  padding: 0px 10px;
  color: #7b7b7b;
`

class FoodsAndBeverages extends Component {
  componentDidMount() {
    this.props.fetchFoodsAndBeverages()
    .catch(err => {
      alert(`Error (${err.message})`)
      console.error(err.message)
    })
  }

  render() {
    const { isFetching, data } = this.props
    const url = `${data.id
                ? `/foods-and-beverages/edit/${data.id}`
                : '/foods-and-beverages/edit'}`
    return (
      <Main>
        {isFetching ? <Loader /> : null}
        <Header />
        <BreadCrumb options={
          [
            {link: '/', name: 'Home'},
            {name: 'Alimentos e bebidas'}
          ]
        } />
        <Content>
          <Button
            onClick={() =>
              this.props.history.push(url)}
            type='button'
            category={'secondary'}
            fontWeight={800}
            full
            ghost>
            Cadastrar novos cardápios
        </Button>
          <Title>
            Alimentos e bebidas
        </Title>
          {data.id
            ? <FoodsAndBeveragesList foodsAndBeverages={data.menus} history={this.props.history} url={url} />
            : <EmptyFoodsAndBeverages />}
        </Content>
      </Main>
    )
  }
}

const EmptyFoodsAndBeverages = () => {
  return <NoFoodsAndBeverages>Nenhum cardápio cadastrado até o momento</NoFoodsAndBeverages>
}

function mapStateToProps (state) {
  const { forms } = state
  const { foodsAndBeverages } = forms
  const { isFetching, error, data } = foodsAndBeverages
  return {
    isFetching,
    error,
    data
  }
}

export default connect(mapStateToProps, { fetchFoodsAndBeverages })(FoodsAndBeverages)
