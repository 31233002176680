export function pegaObjetoVazioDeRequisicao () {
  return {
    _id: null,
    name: '',
    type: '', // TRASLADO_SO_IDA, TRASLADO_IDA_VOLTA, VEICULO_DISPOSICAO
    vehicleTime: '', // pode ser null, só apresenta quando for o VEICULO_DISPOSICAO
    passengers: '',
    luggage: '',
    origin: {},
    originDate: null,
    stops: [],
    destination: {},
    destinationDate: null,
    driverLanguage: '', // BILINGUE_PT_EN, BILINGUE_PT_ES
    hasCoordinator: '',
    coordinatorLanguage: '', // BILINGUE_PT_EN, BILINGUE_PT_ES --- Esse campo pode ser null
    hasOnboardServices: '',
    observation: null
  }
}
