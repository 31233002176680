import { 
  CREATE_LOGIN_REQUEST,
  CREATE_LOGIN_SUCCESS,
  CREATE_LOGIN_FAILURE,
  USERS_REQUEST,
  USERS_SUCCESS,
  USERS_FAILURE } from '../actions/user'
  
export default function user (state = {
  user: false,
  isFetching: false,
  users: false
}, action) {
  switch (action.type) {
    case CREATE_LOGIN_REQUEST:
    return Object.assign({}, state, {
      isFetching: true,
      user: action.form
    })
  case CREATE_LOGIN_SUCCESS:
    return Object.assign({}, state, {
      isFetching: false,
      errorMessage: ''
    })
  case CREATE_LOGIN_FAILURE:
    return Object.assign({}, state, {
      isFetching: false,
      errorMessage: action.message
    })
  case USERS_REQUEST:
    return Object.assign({}, state, {
      isFetching: true
    })
  case USERS_SUCCESS:
    return Object.assign({}, state, {
      isFetching: false,
      users: action.users,
      errorMessage: ''
    })
  case USERS_FAILURE:
    return Object.assign({}, state, {
      isFetching: false,
      errorMessage: action.message
    })
  default:
      return state
  }
}
