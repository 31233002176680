/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../../../../styles/themes'
/* Header */
import Header from '../../../Header'
/* COMPONENTS */
import Button from '../../../../components/ui/Button'
import Textarea from '../../../../components/ui/Textarea'
import CheckboxButton from '../../../../components/ui/CheckboxButton'
import RoomSingle from './RoomSingle'
import RoomDouble from './RoomDouble'
import RoomTriple from './RoomTriple'

const Content = styled.div`

`
const Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  font-family: ${defaultTheme.fonts.secondary};
  padding: 3px 10px 13px 10px;
  color: #484848;
`
const FormContent = styled.form`
  padding: 10px 10px;
  box-sizing: border-box;
  width: 100%;
  max-width: 650px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
`
const RoomsList = styled.div`
  padding: 0 10px;
  width: 100%;
`

class Accommodation extends Component {
  render() {
    return (
      <Content>
        <Header />
        <FormContent method={'POST'} onSubmit={() => this.props.history.push('/organizer/event/rooms')}>
          <Title>Hospedagem</Title>
          <RoomsList>
            <RoomSingle />
            <RoomDouble />
            <RoomTriple />
          </RoomsList>
          <CheckboxButton
            placeholder={'Entrega de brindes no apto'}
            name={'gifts'}
            width={40} height={20} />
          <Textarea
            type={'text'}
            label={'Observações'}
            placeholder={'Avise-nos aqui caso precise de early check-in ou late check-out, por exemplo'}
            name={'observacoes'}
          />
          <Button
            type='submit'
            category={'secondary'}
            fontWeight={800}
            full>
            Próximo
          </Button>
        </FormContent>
      </Content>
    )
  }
}

export default Accommodation
