/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../../styles/themes'

/* COMPONENTS */
import Header from '../../Organizer/Header'
import Main from '../../components/Main'
import Body from '../../components/Body'
import { ehAdmin, linkDashboard } from '../../../utils/tiposUsuario'

const Group = styled.div`
  height: inherit;
  overflow-y: scroll;
`

const Title = styled.h1`
  font-family: ${defaultTheme.fonts.secondary};
  font-size: 1.3em;
  line-height: 1.2;
  margin-bottom: 20px;
  margin-top: auto;
  text-transform: uppercase;
`

export default class Dashboard extends Component {
  render () {
    const dashboardLink = linkDashboard()
    const EXIBE_DASHBOARD = (!!dashboardLink) && ehAdmin()
    if (!EXIBE_DASHBOARD) this.props.history.push('/organizer')
    const multiplosDashboards = Array.isArray(dashboardLink)
    return <Group>
      <Header />
      <Main>
        <Body {...this.props} pagina='dashboard'>
          <Title>Dashboards</Title>
          {!multiplosDashboards && <iframe
            src={dashboardLink}
            title='dashboards'
            width='100%'
            height='100%'
            style={{
              border: 'none',
              height: '100vh'
            }}
          />}
          {multiplosDashboards && dashboardLink.map(link =>
          <>
            <br/>
            <br/>
            <br/>
            <iframe
              src={link}
              title='dashboards'
              width='100%'
              height='1150'
              style={{
                paddingTop: '10px',
                border: 'none',
                height: '791'
              }}
            />
          </>
          )}
        </Body>
      </Main>
    </Group>
  }
}
