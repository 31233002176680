import React, { Component } from 'react'
import { defaultTheme } from '../../styles/themes'
import { ReactComponent as IconSVG } from '../../styles/img/parking-icon.svg'

const color = fill => {
  return {
    fill: fill || defaultTheme.colors.whiteLabel.secondary
  }
}

export default class ParkingIcon extends Component {
  render () {
    const { fill, width } = this.props
    return <IconSVG width={width || '100%'} style={color(fill)} />
  }
}
