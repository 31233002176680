/* LIBS */
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { defaultTheme } from "../../../styles/themes"
/* COMPONENTS */
import Header from '../../../components/Header'
import Icons from '../../../components/ui/Icons'
import Button from '../../../components/ui/Button'
import Modal from '../../../components/Modal'
import Card from '../../../components/ui/Card'
import CheckBox from '../../../components/ui/CheckBox'
import Input from '../../../components/ui/Input'
import Select from '../../../components/ui/Select'
import Title from '../../../components/ui/Title'
import Subtitle from '../../../components/ui/Subtitle'
import Textarea from '../../../components/ui/Textarea'
import MoneyInput from '../../../components/ui/MoneyInput'
import BreadCrumb from '../../../components/BreadCrumb'
import Loader from '../../../components/Loader'
import config from '../../../../middleware/config'
import { Get, Post, Put } from '../../../../utils/request'
import { formatFluid } from '../../../../utils/fluid'
import CommisedRate from '../Rooms/CommisedRate'
import { getNameFromKey } from '../../../../utils/fabTexts'
import { checkIfItemWasEdited } from '../../../../utils/checkEdition'
import moment from 'moment'
import FormFoodsAndBeveragesModal from '../../FoodsAndBeverages/FormFoodsAndBeveragesModal'
import { formatTime } from '../utils'
import formatCurrency from '../../../../utils/money';

const CRITICAL = 'CRITICAL'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #FAFAFA;
  & * { font-family: 'Open Sans', sans-serif }
`

const Main = styled.div`
  width: 80%;
  margin-top: '15px';
  padding: 20px 30px;
  background: #FAFAFA;
  flex: 1 0 auto;
`

const Group = styled.div`
`

const Label = styled.label`
  align-items: center;
  background-color: #FFF;
  cursor: pointer;
  display: flex;
  color: ${props => props.error ? 'red' : (props.edited ? '#F39C12' : '#484848')};
  font-family: ${defaultTheme.fonts.secondary};
  font-weight: 500;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
`
const Tag = styled.div`
  padding: 3px 5px;
  font-size: 12px;
  font-family: ${defaultTheme.fonts.primary};
  color: #FAFAFA;
  background-color: ${props => props.edited ? '#F39C12' : (props.contraproposta ? '#8800dd' : '#009688')};
  border: none;
  border-radius: 15px;
  position: absolute;
  right: 10px;
  top: 10px;
  text-transform: uppercase;
  z-index: 9;
`

class BriefingFoodsAndBeverage extends Component {
  constructor() {
    super()
    this.state = {
      isLoading: false,
      briefings: [],
      observations: '',
      observationsValue: '',
      briefingId: '',
      CommisedOpen: false,
      commission: {},
      counterProposals: [],
      foodsAndBeveragesId: '',
      foodsAndBeveragesModal: false,
      foodsAndBeveragesModalSelected: '',
      reseller: undefined,
      differences: {},
      lastUpdateType: '',
      event: { client: {} }
    }
  }

  componentDidMount() {
    const { match: { params: { event } } } = this.props
    if (event) {
      this.fetchBriefing()
      this.fetchEvent()
    }
  }

  fetchEvent () {
    const { api: { base, version, routes: { events, supplier } } } = config
    const { match: { params: { event } } } = this.props
    const endpoint = `${base}${version}${supplier}${events}/${event}`

    Get(endpoint).then(dados => {
      this.setState({ event: dados.event })
    }).catch(err => {
      console.log('Error: ', err)
    })
  }

  fetchEditDifferences () {
    const { api: { base, version } } = config
    const id = this.state.briefingId
    const endpoint = `${base}${version}/briefing/foodsAndBeverages/${id}/rollback/difference`

    Get(endpoint).then((differences) => {
      this.setState({ differences: differences, isLoading: false })
    }).catch(err => {
      console.log('Error: ', err)
    })
  }

  fetchBriefing () {
    this.setState({ isLoading: true })
    const { api: { base, version, routes: { events } } } = config
    const { match: { params: { event } } } = this.props
    const endpoint = `${base}${version}${events}/${event}/infoToBudget/foodsAndBeverages`

    Get(endpoint)
    .then((response) => {
      const { match: { params: { budget } } } = this.props
      if(budget) {
        const newEndpoint = `${base}${version}/budget/foodsAndBeverages/${budget}`
        Get(newEndpoint)
        .then((budgetInfo) => {
          const briefings = []
          Object.keys(response.briefing).filter(key => response.briefing[key] && response.briefing[key].requests && response.briefing[key].requests.length > 0).map((key) => {
            let briefing = response.briefing[key]
            briefing.selected = !!budgetInfo.menusBudgets[key]
            if(key === 'roomService') {
              let tray = briefing.tray
              tray.supplierOptions.items.map((item) => {
                if(budgetInfo.menusBudgets.tray && budgetInfo.menusBudgets.tray.budgets[item.keyName]) {
                  item.selected = budgetInfo.menusBudgets.tray.budgets[item.keyName]
                  item.new_price = budgetInfo.menusBudgets.tray.budgets[item.keyName].price.amount || item.price.amount || 0
                  item.new_quantity = budgetInfo.menusBudgets.tray.budgets[item.keyName].quantity || item.quantity || 0
                  item.new_type = budgetInfo.menusBudgets.tray.budgets[item.keyName].type || item.type
                  item.new_total_quantity = budgetInfo.menusBudgets.tray.budgets[item.keyName].numberOfItems || 0
                } else {
                  item.selected = false
                  item.new_price = item.price.amount || 0
                  item.new_quantity = item.quantity || 0
                  item.new_type = item.type
                  item.new_total_quantity = 0
                }
                return item
              })
              briefings.tray = tray
            }
            briefing.supplierOptions && briefing.supplierOptions.items.map((item) => {
              if(key === 'roomService' && budgetInfo.menusBudgets[key] && budgetInfo.menusBudgets[key].budgets[item.name]) {
                item.selected = budgetInfo.menusBudgets[key].budgets[item.name].supplierMenuId === item.id
                item.new_price = budgetInfo.menusBudgets[key].budgets[item.name].price.amount  || item.price.amount || 0
                item.new_quantity = budgetInfo.menusBudgets[key].budgets[item.name].quantity || item.quantity || 0
                item.new_type = budgetInfo.menusBudgets[key].budgets[item.name].type || item.type
                item.new_total_quantity = budgetInfo.menusBudgets[key].budgets[item.name].numberOfItems || 0

              } else if(budgetInfo.menusBudgets[key] && budgetInfo.menusBudgets[key].budgets.length > 0) {
                var itemSelected = budgetInfo.menusBudgets[key].budgets.find(itemInfo => itemInfo.item.id === item.id)
                item.selected = budgetInfo.menusBudgets[key].budgets.length > 0 && !!itemSelected
                item.new_price = (itemSelected && itemSelected.price.amount) || item.price.amount || 0
                item.new_quantity = (itemSelected && itemSelected.quantity) || item.quantity || 0
                item.new_type = (itemSelected && itemSelected.type) || item.type
                item.new_total_quantity = (itemSelected && itemSelected.numberOfItems) || 0
              } else {
                item.selected = false
                item.new_price = item.price.amount || 0
                item.new_quantity = item.quantity || 0
                item.new_type = item.type
                item.new_total_quantity = 0
              }
              return item
            })
            return briefings.push(briefing)
          })
          this.setState({
            isLoading: budgetInfo.status === 'REVIEW',
            lastUpdateType: budgetInfo.lastUpdateType,
            briefings,
            observations: response.briefing.observations,
            counterProposals: budgetInfo.counterProposals,
            briefingId: response.briefing.id,
            foodsAndBeveragesId: response.supplierFoodsAndBeverages && (response.supplierFoodsAndBeverages.id || response.supplierFoodsAndBeverages._id)
          }, () => budgetInfo.status === 'REVIEW' && this.fetchEditDifferences())
          // this.setState({ isLoading: false, briefings, observations: response.briefing.observations, briefingId: response.briefing.id, foodsAndBeveragesId: response.supplierFoodsAndBeverages && (response.supplierFoodsAndBeverages.id || response.supplierFoodsAndBeverages._id) })
        }).catch(err => {
          this.setState({ isLoading: false })
          console.log('Error: ', err)
        })
      } else {
        const briefings = []
        Object.keys(response.briefing).filter(key => response.briefing[key] && response.briefing[key].requests && response.briefing[key].requests.length > 0).map((key) => {
          let briefing = response.briefing[key]
          briefing.selected = false
          briefing.supplierOptions && briefing.supplierOptions.items.map((item) => {
            item.selected = true
            item.new_price = item.price.amount || 0
            item.new_quantity = item.quantity || 0
            item.new_type = item.type
            item.new_total_quantity = 0
            return item
          })
          if(briefing.name === 'roomService') {
            let tray = briefing.tray
            tray.supplierOptions.items.map((item) => {
              item.selected = true
              item.new_price = item.price.amount || 0
              item.new_quantity = item.quantity || 0
              item.new_type = item.type
              item.new_total_quantity = 0
              return item
            })
            briefings.tray = tray
          }

          return briefings.push(briefing)
        })
        this.setState({ isLoading: false, briefings, observations: response.briefing.observations, briefingId: response.briefing.id, foodsAndBeveragesId: response.supplierFoodsAndBeverages.id || response.supplierFoodsAndBeverages._id })
      }
    }).catch(err => {
      this.setState({ isLoading: false })
      console.log('Error: ', err)
    })
  }

  sessionClick = (session) => {
    const briefings = this.state.briefings
    const index = briefings.findIndex(briefing => briefing.name === session)
    if (index !== -1) {
      briefings[index].selected = !briefings[index].selected
      this.setState({ briefings })
    }
  }

  sessionClick = (session) => {
    const briefings = this.state.briefings
    const index = briefings.findIndex(briefing => briefing.name === session)
    if (index !== -1) {
      briefings[index].selected = !briefings[index].selected
      this.setState({ briefings })
    }
  }

  itemClick = (id, session) => {
    const briefings = this.state.briefings
    const index = briefings.findIndex(briefing => briefing.name === session)
    if (index !== -1) {
      const itemIndex = briefings[index].supplierOptions.items.findIndex(item => item.id === id)
      if (itemIndex !== -1) {
        briefings[index].supplierOptions.items[itemIndex].selected = !briefings[index].supplierOptions.items[itemIndex].selected
        this.setState({ briefings })
      }
    }
  }

  trayItemClick = (id) => {
    const briefings = this.state.briefings
    const index = briefings.findIndex(briefing => briefing.name === 'roomService')
    if (index !== -1) {
      const itemIndex = briefings[index].tray.supplierOptions.items.findIndex(item => item.id === id)
      if (itemIndex !== -1) {
        briefings[index].tray.supplierOptions.items[itemIndex].selected = !briefings[index].tray.supplierOptions.items[itemIndex].selected
        this.setState({ briefings })
      }
    }
  }

  itemHandlerValue = (input, id, session, inputType) => {
    const { value } = input.target
    const briefings = this.state.briefings
    const index = briefings.findIndex(briefing => briefing.name === session)
    if (index !== -1) {
      const itemIndex = briefings[index].supplierOptions.items.findIndex(item => item.id === id)
      if (itemIndex !== -1) {
        briefings[index].supplierOptions.items[itemIndex][inputType] = value
        this.setState({ briefings })
      }
    }
  }

  itemFluidHandlerValue = (input, id, session, inputType) => {
    const { value } = input.target
    const briefings = this.state.briefings
    const index = briefings.findIndex(briefing => briefing.name === session)
    if (index !== -1) {
      const itemIndex = briefings[index].supplierOptions.items.findIndex(item => item.id === id)
      if (itemIndex !== -1) {
        briefings[index].supplierOptions.items[itemIndex][inputType] = formatFluid(value)
        this.setState({ briefings })
      }
    }
  }

  trayItemHandlerValue = (input, id, inputType) => {
    const { value } = input.target
    const briefings = this.state.briefings
    const index = briefings.findIndex(briefing => briefing.name === 'roomService')
    if (index !== -1) {
      const itemIndex = briefings[index].tray.supplierOptions.items.findIndex(item => item.id === id)
      if (itemIndex !== -1) {
        briefings[index].tray.supplierOptions.items[itemIndex][inputType] = value
        this.setState({ briefings })
      }
    }
  }

  observationsHandlerValue = (input) => {
    const { value } = input.target
    this.setState({ observationsValue: value })
  }

  submitCommisedRate = ({ commised, reason, value }) => {
    let { commission } = this.state
    commission = {
      fee: {
        amount: value,
        type: 'PERCENTAGE'
      },
      reason: reason,
      hasCommission: commised === 'yes'
    }
    this.setState({
      isLoading: true,
      commission,
      CommisedOpen: false
    }, () => {
      const { api: { base, version } } = config
      const { match: { params: { budget } } } = this.props
      if(this.props.location.state && (this.props.location.state.counterProposal === 'ACCEPTED' || this.props.location.state.counterProposal === 'NEW')) {
        const endpoint = `${base}${version}/budget/foodsAndBeverages/${this.props.location.state.counterProposal === 'ACCEPTED' ? 'counter-proposal' : 'supplier-counter-proposal'}/${budget}`
        Put(endpoint, this.generateJSON())
        .then(() => {
          const { match: { params: { event } } } = this.props
          this.props.history.push(`/budget/resume/${event}`)
        }).catch(err => {
          console.error(err.message)
          this.setState({
            isLoading: false
          })
          alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
        })
      }
      else if(budget) {
        const endpoint = `${base}${version}/budget/foodsAndBeverages/${budget}`
        Put(endpoint, this.generateJSON())
        .then(() => {
          const { match: { params: { event } } } = this.props
          this.props.history.push(`/budget/resume/${event}`)
        }).catch(err => {
          console.error(err.message)
          this.setState({
            isLoading: false
          })
          alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
        })
      } else {
        const endpoint = `${base}${version}/budget/foodsAndBeverages/`
        Post(endpoint, this.generateJSON())
        .then(() => {
          const { match: { params: { event } } } = this.props
          this.props.history.push(`/budget/resume/${event}`)
        }).catch(err => {
          console.error(err.message)
          this.setState({
            isLoading: false
          })
          alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
        })
      }
    })
  }

  submitForm = () => {
    if (this.isValid() && this.isCounterProposalValid() && this.isNewCounterProposalValid()) {
      if(this.state.event.supplierChainId) {
        let { commission } = this.state
        commission = {
          fee: {
            amount: 0,
            type: 'PERCENTAGE'
          },
          reason: '',
          hasCommission: false
        }
        this.setState({
          isLoading: true,
          commission,
          CommisedOpen: false
        }, () => {
          const { api: { base, version } } = config
          const { match: { params: { budget } } } = this.props
          if(this.props.location.state && (this.props.location.state.counterProposal === 'ACCEPTED' || this.props.location.state.counterProposal === 'NEW')) {
            const endpoint = `${base}${version}/budget/foodsAndBeverages/${this.props.location.state.counterProposal === 'ACCEPTED' ? 'counter-proposal' : 'supplier-counter-proposal'}/${budget}`
            Put(endpoint, this.generateJSON())
            .then(() => {
              const { match: { params: { event } } } = this.props
              this.props.history.push(`/budget/resume/${event}`)
            }).catch(err => {
              console.error(err.message)
              this.setState({
                isLoading: false
              })
              alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
            })
          }
          else if(budget) {
            const endpoint = `${base}${version}/budget/foodsAndBeverages/${budget}`
            Put(endpoint, this.generateJSON())
            .then(() => {
              const { match: { params: { event } } } = this.props
              this.props.history.push(`/budget/resume/${event}`)
            }).catch(err => {
              console.error(err.message)
              this.setState({
                isLoading: false
              })
              alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
            })
          } else {
            const endpoint = `${base}${version}/budget/foodsAndBeverages/`
            Post(endpoint, this.generateJSON())
            .then(() => {
              const { match: { params: { event } } } = this.props
              this.props.history.push(`/budget/resume/${event}`)
            }).catch(err => {
              console.error(err.message)
              this.setState({
                isLoading: false
              })
              alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
            })
          }
        })
      } else {
        this.setState({ CommisedOpen: true })
      }
    } else {
      !this.isValid() && alert('Você deve preencher todos os cardápios solicitados para enviar seu orçamento.')
      !this.isCounterProposalValid() && alert('Você aceitou a contraproposta, por favor preencha os campos para que o valor da nova oferta seja menor ou igual a contraproposta solicitada.')
      !this.isNewCounterProposalValid() && alert('Você não pode criar uma contraproposta com o valor acima do valor inicial enviado.')
    }
  }

  generateJSON = () => {
    const budgets = {}

    this.state.briefings.forEach((briefing) => {
      budgets[briefing.name] = briefing.supplierOptions.items.filter(item => item.selected).map((item) => {
        return {
          supplierMenuId: item.id,
          price: item.new_price,
          numberOfItems: item.new_total_quantity,
          quantity: item.new_quantity,
          type: item.new_type,
          item: {
            id: item.id,
            name: item.name,
            price: item.price,
            quantity: item.quantity,
            description: item.details,
            type: item.type
          }
        }
      })
    })

    if (budgets.roomService) {
      budgets.roomService = budgets.roomService.reduce((roomServices, service) => {
        roomServices[service.item.name] = service
        return roomServices
      }, {})
      budgets.roomService.tray = this.state.briefings.find(briefing => briefing.name === 'roomService').tray ? this.state.briefings.find(briefing => briefing.name === 'roomService').tray.supplierOptions.items : null
      const tray = {}
      budgets.roomService.tray.filter(item => item.selected).forEach((item) => {
        tray[item.keyName] = {
          supplierMenuId: item.id,
          price: item.new_price,
          numberOfItems: item.new_total_quantity,
          quantity: item.new_quantity,
          type: item.new_type,
          item: {
            id: item.id,
            name: item.name,
            price: item.price,
            quantity: item.quantity,
            description: item.details,
            type: item.type
          }
        }
      })
      budgets.roomService.tray = tray
    }

    return {
      briefingId: this.state.briefingId,
      commission: this.state.commission,
      counterProposals: this.state.counterProposals,
      menusBudgets: budgets,
      observations: this.state.observationsValue
    }
  }

  splitDates = (requests) => {
    return requests.map((info) => {
      return " " + moment(info.start, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br').format('DD-MM-YYYY') + " ,"
    })
  }

  isValid = () => {
    var isValid = Object.keys(this.state.briefings)
    .filter((key) => key !== 'tray' && this.state.briefings[key].supplierOptions.items
      .filter(item => (item.selected && parseFloat(item.new_price) <= 0) || (item.selected && parseFloat(item.new_total_quantity) <= 0 && !!item.keyName) || (item.selected && item.new_quantity === '' && !!item.keyName)).length > 0).length === 0
    if (isValid && this.state.briefings.tray && this.state.briefings.tray.supplierOptions.items.length > 0) {
        isValid = this.state.briefings.tray.supplierOptions.items
          .filter(item => item.selected && !(parseFloat(item.new_total_quantity) > 0 && parseFloat(item.new_price) > 0 && item.keyName)).length === 0
    }
    if(isValid) {
      isValid = Object.keys(this.state.briefings)
      .filter((key) => key !== 'tray' && !this.state.briefings[key].supplierOptions.items
        .some(item => item.selected)).length === 0
      if (isValid && this.state.briefings.tray && this.state.briefings.tray.supplierOptions.items.length > 0) {
          isValid = this.state.briefings.tray.supplierOptions.items
            .some(item => item.selected)
      }
    }
    return isValid
  }

  isCounterProposalValid = () => {
    if(this.props.location.state && this.props.location.state.counterProposal === 'ACCEPTED') {
      return this.props.location.state.requestedPrice >= this.total()
    } else return true
  }

  isNewCounterProposalValid = () => {
    if(this.props.location.state && this.props.location.state.counterProposal === 'NEW') {
      return this.props.location.state.initialPrice >= this.total()
    } else return true
  }

  checkRoomService = (briefing) => {
    var isValid = true
    var items = ['coffee', 'juice', 'milk', 'soda', 'tea', 'water']
    items.forEach(item => {
      if (briefing[item] && (!briefing.supplierOptions.enabled || !briefing.supplierOptions.items.find(supOption => supOption.name === item))) {
        isValid = false
      }
    })
    var trayItems = ['cake', 'cupCakes', 'cheeseBread', 'fruitPlatter', 'miniSandwich', 'petitFourCandy', 'petitFourSalty']
    trayItems.forEach(item => {
      if (briefing.tray[item] && (!briefing.tray.supplierOptions.enabled || !briefing.tray.supplierOptions.items.find(supOption => supOption.keyName === item))) {
        isValid = false
      }
    })
    return isValid
  }

  createServiceRoomItem = (item, briefing, index) => {
    switch (item.name) {
      case 'water':
        return <CheckBox
        onClick={() => this.itemClick(item.id, briefing.name)}
        label={getNameFromKey(item.name)} key={`item_${index}`}
        style={{width: 'auto', margin: '15px'}}
        checked={item.selected}>

          {item.selected && <Group>
            <Select
              label={'Tipo'}
              onChange={input => this.itemHandlerValue(input, item.id, briefing.name, 'new_type')}
              value={item.new_type}>
              <option value={'glass'}>Copo</option>
              <option value={'bottle'}>Garrafa</option>
              <option value={'gallon'}>Galão</option>
            </Select>
            <Input
              type={'text'}
              label={'Quantidade (em ml)'}
              placeholder={'0 ml'}
              onChange={input => this.itemFluidHandlerValue(input, item.id, briefing.name, 'new_quantity')}
              value={formatFluid(item.new_quantity)}
              error={item.new_quantity <= 0}
            />
            <MoneyInput
              label="Valor (por unidade)"
              value={item.new_price}
              onChange={input => this.itemHandlerValue(input, item.id, briefing.name, 'new_price')}
              isRequired
              error={item.new_price <= 0}
            />
            <Input
              type={'number'}
              name={'amountOfPeople'}
              label={'Quantidade por dia'}
              onChange={input => this.itemHandlerValue(input, item.id, briefing.name, 'new_total_quantity')}
              value={item.new_total_quantity}
              error={item.new_total_quantity <= 0}
              isRequired />
            <Subtitle style={{ padding: '15px'}}>{item.details}</Subtitle>
          </Group>}
        </CheckBox>

      case 'soda':
        return <CheckBox
        onClick={() => this.itemClick(item.id, briefing.name)}
        label={getNameFromKey(item.name)} key={`item_${index}`}
        style={{width: 'auto', margin: '15px'}}
        checked={item.selected}>
          {item.selected && <Group>
            <Select
              label={'Tipo'}
              onChange={input => this.itemHandlerValue(input, item.id, briefing.name, 'new_type')}
              value={item.new_type}>
              <option value={'can'}>Lata</option>
              <option value={'bottle'}>Garrafa</option>
            </Select>
            <Input
              type={'text'}
              label={'Quantidade (em ml)'}
              placeholder={'0 ml'}
              onChange={input => this.itemFluidHandlerValue(input, item.id, briefing.name, 'new_quantity')}
              value={formatFluid(item.new_quantity)}
              error={item.new_quantity <= 0}
            />
            <MoneyInput
              label="Valor (por unidade)"
              value={item.new_price}
              onChange={input => this.itemHandlerValue(input, item.id, briefing.name, 'new_price')}
              isRequired
              error={item.new_price <= 0}
            />
            <Input
              type={'number'}
              name={'amountOfPeople'}
              label={'Quantidade por dia'}
              onChange={input => this.itemHandlerValue(input, item.id, briefing.name, 'new_total_quantity')}
              value={item.new_total_quantity}
              error={item.new_total_quantity <= 0}
              isRequired />
            <Subtitle style={{ padding: '15px'}}>{item.details}</Subtitle>
          </Group>}
        </CheckBox>

      default:
        return <CheckBox
        onClick={() => this.itemClick(item.id, briefing.name)}
        label={getNameFromKey(item.name)} key={`item_${index}`}
        style={{width: 'auto', margin: '15px'}}
        checked={item.selected}>
          {item.selected && <Group>
            <Input
              type={'text'}
              label={'ml (por jarra)'}
              placeholder={'Quantos ml?'}
              onChange={input => this.itemFluidHandlerValue(input, item.id, briefing.name, 'new_quantity')}
              value={formatFluid(item.new_quantity)}
              error={item.new_quantity <= 0}
            />
            <MoneyInput
              label="Valor (por unidade)"
              value={item.new_price}
              onChange={input => this.itemHandlerValue(input, item.id, briefing.name, 'new_price')}
              isRequired
              error={item.new_price <= 0}
            />
            <Input
              type={'number'}
              name={'amountOfPeople'}
              label={'Quantidade por dia'}
              onChange={input => this.itemHandlerValue(input, item.id, briefing.name, 'new_total_quantity')}
              value={item.new_total_quantity}
              error={item.new_total_quantity <= 0}
              isRequired />
            <Subtitle style={{ padding: '15px'}}>{item.details}</Subtitle>
          </Group>}
        </CheckBox>
    }
  }

  verificaSePossuiOItemEditado = (requests, item) => {
    const indexItem = requests && Object.keys(requests).findIndex(index => requests[index][item])
    return indexItem !== -1 ? requests && requests[indexItem] : requests && requests[0]
  }

  onFinished = () => {
    this.setState({
      foodsAndBeveragesModal: false
    }, () => window.location.reload())
  }

  setModalSelected = (newSelected) => {
    this.setState({
      foodsAndBeveragesModalSelected: newSelected,
      foodsAndBeveragesModal: true
    })
  }
  total = () => {
      var total = Object.keys(this.state.briefings)
      .reduce((soma, key) => {
        if(key !== 'tray' && this.state.briefings[key].name !== 'roomService') {
          soma += this.state.briefings[key].requests.length * this.state.briefings[key].supplierOptions.items
            .reduce((items, item) => {
              if(item.selected && items <= (parseFloat(item.new_price) * parseFloat(item.new_total_quantity))) {
                items = (parseFloat(item.new_price) * (parseFloat(item.new_total_quantity) || 1))
              }
              return items
            }, 0) * (this.state.briefings[key].requests.length > 0 ? this.state.briefings[key].requests[0].amountOfPeople: 1)
        } else if (this.state.briefings[key].name === 'roomService') {
          soma += this.state.briefings[key].requests.length * this.state.briefings[key].supplierOptions.items
            .reduce((items, item) => {
              if(item.selected) {
                items += (parseFloat(item.new_price) * (parseFloat(item.new_total_quantity) || 1))
              }
              return items
            }, 0)
        }
        return soma
      }, 0)
      total += this.state.briefings.tray ? (this.state.briefings[0].requests.length * this.state.briefings.tray.supplierOptions.items
            .reduce((soma, item) => {
              if(item.selected) {
                soma += parseFloat(item.new_total_quantity) * parseFloat(item.new_price)
              }
              return soma
            }, 0)) : 0
      return total
  }

  render() {
    const { match: { params: { event, foodsandbeverage, budget } } } = this.props
    const temQueEnviarContraproposta = this.props.location.state && (this.props.location.state.counterProposal === 'ACCEPTED' || this.props.location.state.counterProposal === 'NEW')
    return (
      <Container>
        <Header />
        <BreadCrumb options={
          [
            {link: '/', name: 'Home'},
            {link: `/budget/resume/${event}`, name: 'Resumo'},
            {name: 'Alimentos e bebidas'}
          ]
        } />
        <CommisedRate
          isOpen={this.state.CommisedOpen}
          onClose={() => this.setState({ CommisedOpen: false })}
          onSubmit={this.submitCommisedRate}
          client={this.state.event && this.state.event.client}
        />
        <Main>
          <Modal maxWidth= '800px' isOpen={this.state.foodsAndBeveragesModal} hasCloseButton>
            <FormFoodsAndBeveragesModal
              id={this.state.foodsAndBeveragesId}
              foodsandbeverage={foodsandbeverage}
              event={event}
              selected={this.state.foodsAndBeveragesModalSelected}
              onFinished={this.onFinished}
            />
          </Modal>
          {this.state.isLoading ? <Loader /> : null}
          <Group style={{width: '80%', margin: 'auto', position: 'relative'}}>
            <Title>Alimentos e bebidas</Title>
            {this.props.location.state && (this.props.location.state.counterProposal === 'ACCEPTED' || this.props.location.state.counterProposal === 'NEW') && <Tag contraproposta>Contraproposta</Tag>}
            {
              this.props.location.state && (this.props.location.state.counterProposal === 'ACCEPTED' || this.props.location.state.counterProposal === 'NEW') && <Group style={{ textAlign: 'right', padding: '0px 15px' }}>
                <Subtitle>Valor da contraproposta <span style={{ fontWeight: 'bold' }}>{formatCurrency(this.props.location.state.requestedPrice)}</span>&nbsp;&nbsp;&nbsp;&nbsp;Valor da nova oferta <span style={{ fontWeight: 'bold', color: 'red' }}>{formatCurrency(this.total())}</span></Subtitle>
              </Group>
            }
            <Group style={{ display: 'flex', marginTop: '5px', marginLeft: '-8px' }}>
              <Icons
                icon={'exclamation-circle'}
                color={'#a5a5a5'}
                width={30}
                height={15} />
              <Subtitle style={{ color: '#a5a5a5', fontSize: '13px', textAlign: 'justify', marginBottom: '10px' }}>
                  Caso esteja com dificuldades para enviar este orçamento, veja este nosso <a href={'https://www.youtube.com/watch?v=qBs9HRKBzvA'} rel='noopener noreferrer' target='_blank'>vídeo tutorial</a>.
                  Fique a vontade também para entrar em contato no 11 95082 2158
              </Subtitle>
            </Group>
            <Card style={{paddingLeft: '25px'}}>
              <Title style={{ margin: '0 0 5px 0', fontWeight: '800', fontSize: '12px', textTransform: 'uppercase' }} >Observações do cliente:</Title>
              <Subtitle style={{ marginBottom: '0px' }}>{this.state.observations || 'Sem observações'}</Subtitle>
            </Card>
            {(this.state.briefings.filter(item => !!item.supplierOptions).length === 0) && <Subtitle style={{ color: 'red', margin: '20px' }}>Você não possui nenhum cardápio cadastrado. <Link to={`/foods-and-beverages/edit/${this.state.foodsAndBeveragesId}/${event}/${foodsandbeverage}`}>Clique aqui</Link> para cadastrar e continuar o briefing</Subtitle>}
            {this.state.briefings.filter(item => !!item.supplierOptions)
            .sort((a, b) => {
              if((((!a.supplierOptions.enabled || a.supplierOptions.items.length === 0) && a.name !== 'roomService') || (a.name === 'roomService' && !this.checkRoomService(a))) && (((!b.supplierOptions.enabled || b.supplierOptions.items.length === 0) && b.name !== 'roomService') || (b.name === 'roomService' && !this.checkRoomService(b)))) {
                if(a.name < b.name) return -1
                if(a.name > b.name) return 1;
                return 0
              } else if (!(((!a.supplierOptions.enabled || a.supplierOptions.items.length === 0) && a.name !== 'roomService') || (a.name === 'roomService' && !this.checkRoomService(a))) && !(((!b.supplierOptions.enabled || b.supplierOptions.items.length === 0) && b.name !== 'roomService') || (b.name === 'roomService' && !this.checkRoomService(b)))) {
                if(a.name < b.name) return -1
                if(a.name > b.name) return 1;
                return 0
              }
              else {
                return ((!a.supplierOptions.enabled || a.supplierOptions.items.length === 0) && a.name !== 'roomService') || (a.name === 'roomService' && !this.checkRoomService(a)) ? -1 : 1
              }
            })
            .map((briefing, index) =>
              <Card
              // onClick={() => this.sessionClick(briefing.name)}
              label={briefing.supplierOptions.name} key={`session_${index}`}>
                <Group style={{paddingBottom: '5px'}}>
                  <Label edited={this.state.differences[briefing.name] && checkIfItemWasEdited(this.verificaSePossuiOItemEditado(this.state.differences[briefing.name].requests, 'start'), 'start')}><b style={{ color: '#484848' }}>Datas: </b>{this.splitDates(briefing.requests)} ({briefing.requests.length} dia(s))</Label>
                  <Label edited={this.state.differences[briefing.name] && checkIfItemWasEdited(this.verificaSePossuiOItemEditado(this.state.differences[briefing.name].requests, 'startTime'), 'startTime')}><b style={{ color: '#484848' }}>Horário de Início: </b>{briefing.requests[0].startTime === '00:00:00' ? 'Horário não informado' : formatTime(briefing.requests[0].startTime)}</Label>
                  <Label edited={this.state.differences[briefing.name] && checkIfItemWasEdited(this.verificaSePossuiOItemEditado(this.state.differences[briefing.name].requests, 'amountOfPeople'), 'amountOfPeople')}><b style={{ color: '#484848' }}>Número de pessoas: </b>{briefing.requests[0].amountOfPeople}</Label>
                  {(!briefing.isPrivate && (briefing.name === 'lunch' || briefing.name === 'dinner' || briefing.name === 'thematicDinner')) && <Label edited={this.state.differences[briefing.name] && checkIfItemWasEdited(this.state.differences[briefing.name].requests[0], 'isPrivate')}><b style={{ color: '#484848' }}>Espaço privativo: </b>Não</Label>}
                  {briefing.requests[0].beverages && <Label edited={this.state.differences[briefing.name] && checkIfItemWasEdited(this.state.differences[briefing.name].requests[0], 'beverages')}><b style={{ color: '#484848' }}>Tipo de bebida: </b>{getNameFromKey(briefing.requests[0].beverages)}</Label>}
                  {(briefing.isPrivate && (briefing.name === 'lunch' || briefing.name === 'dinner' || briefing.name === 'thematicDinner')) && <Subtitle style={{ color: '#8a6d3b', margin: '20px', textAlign: 'center' }}>
                    <Icons
                      icon={'exclamation-circle'}
                      color={'#8a6d3b'}
                      width={30}
                      height={15} />
                      Importante: Deve ser realizado em espaço privativo
                  </Subtitle>}
                  {(!briefing.supplierOptions.enabled || briefing.supplierOptions.items.length === 0) && briefing.name !== 'roomService' && <Subtitle style={{ color: 'red', margin: '20px' }}>Há alguns cardápios pendentes de cadastro. <Link to={{ pathname: `/foods-and-beverages/edit/${this.state.foodsAndBeveragesId}/${event}/${foodsandbeverage}`, query: { url:`/budget/briefing/foodsandbeverage/${event}/${foodsandbeverage}${!!budget ? `/${budget}` : ''}`}}}>Clique aqui</Link> para cadastrá-los e concluir o envio do orçamento.</Subtitle>}
                  {(briefing.name === 'roomService' && !this.checkRoomService(briefing)) && <Subtitle style={{ color: 'red', margin: '20px' }}>Há alguns cardápios e/ou bandejas pendentes de cadastro. <Link to={{ pathname: `/foods-and-beverages/edit/${this.state.foodsAndBeveragesId}/${event}/${foodsandbeverage}`, query: { url:`/budget/briefing/foodsandbeverage/${event}/${foodsandbeverage}${!!budget ? `/${budget}` : ''}`}}}>Clique aqui</Link> para cadastrá-los e concluir o envio do orçamento.</Subtitle>}
                  {
                    !(!briefing.supplierOptions.enabled || briefing.supplierOptions.items.length === 0) && briefing.supplierOptions.items.map((item, i) => {
                      if (briefing.name === 'roomService') {
                        return this.checkRoomService(briefing) && this.createServiceRoomItem(item, briefing, i)
                      } else {
                        return <CheckBox
                        onClick={() => !(temQueEnviarContraproposta && item.selected) && this.itemClick(item.id, briefing.name)}
                        label={getNameFromKey(item.name)} key={`item_${i}`}
                        style={{width: 'auto', margin: '15px'}}
                        checked={item.selected}>
                          {item.selected && <Group>
                            <MoneyInput
                              label="Valor (por pessoa)"
                              onChange={input => this.itemHandlerValue(input, item.id, briefing.name, 'new_price')}
                              value={item.new_price}
                              error={item.new_price <= 0}
                              isRequired
                              criticalUpdate={this.state.lastUpdateType === CRITICAL}
                              criticalPreviouslyValue={item.new_price}
                            />
                            <Subtitle style={{ padding: '15px'}}>{item.details}</Subtitle>
                          </Group>}
                        </CheckBox>
                      }
                    })
                  }
                  {
                    briefing.tray && briefing.tray.supplierOptions.items.length > 0 && this.checkRoomService(briefing) &&
                      <Card label='Bandeja' style={{width: 'auto', margin: '15px'}}>
                        {
                          briefing.tray.supplierOptions.items.map((item, i) => {
                            return <CheckBox
                            onClick={() => this.trayItemClick(item.id)}
                            label={getNameFromKey(item.name)} key={`tray_${i}`}
                            style={{width: 'auto', margin: '15px'}}
                            checked={item.selected}>
                              {item.selected && <Group>
                                <MoneyInput
                                  label="Valor (por bandeja)"
                                  onChange={input => this.trayItemHandlerValue(input, item.id, 'new_price')}
                                  value={item.new_price}
                                  error={item.new_price <= 0}
                                  isRequired
                                  criticalUpdate={this.state.lastUpdateType === CRITICAL}
                                  criticalPreviouslyValue={item.new_price}
                                />
                                <Input
                                  type={'number'}
                                  label={'Quantidade por dia'}
                                  onChange={input => this.trayItemHandlerValue(input, item.id, 'new_total_quantity')}
                                  value={item.new_total_quantity}
                                  error={item.new_total_quantity <= 0}
                                  isRequired
                                  criticalUpdate={this.state.lastUpdateType === CRITICAL}
                                  criticalPreviouslyValue={item.new_total_quantity} />
                                <Subtitle style={{ padding: '15px'}}>{item.details}</Subtitle>
                              </Group>}
                            </CheckBox>
                          })
                        }
                      </Card>
                  }
                </Group>
              </Card>
            )}
            <Textarea
              type={'text'}
              label={'Observações'}
              placeholder={'Caso tenha alguma observação, escreva aqui.'}
              name={'observations'}
              value={this.state.observationsValue}
              style={{ width: 'calc(100% - 20px)' }}
              onChange={this.observationsHandlerValue}
              criticalUpdate={this.state.lastUpdateType === CRITICAL}
              criticalPreviouslyValue={this.state.observationsValue} />
          </Group>
        </Main>
        <Button
          type='submit'
          category={'secondary'}
          fontWeight={800}
          onClick={this.submitForm}
          style={{ width: '80%'}}
          full>
          Confirmar
        </Button>
      </Container>
    )
  }
}

export default BriefingFoodsAndBeverage
