/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
/* CONFIG */
import config from '../../../middleware/config'
/* STYLES */
import { defaultTheme } from '../../styles/themes'
/* UI */
import Header from '../../components/Header'
import BreadCrumb from '../../components/BreadCrumb'
import UploadField from '../../components/UploadField'
import Loader from '../../components/Loader'

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #FAFAFA;
  font-family: ${defaultTheme.fonts.secondary};
  width: 100%;
`
const BoxContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 10%;
  width: 100%;
`
const Form = styled.form`
  margin-left: 10px;
  padding: 20px 0px;
  width: 100%;
`
const Title = styled.h1`
  width: 100%;
  text-align: left;
  font-size: 20px;
  padding: 20px 10px;
`
const PictureBox = styled.div`
  width: 320px;
`
const PictureImg = styled.img`
  background-color: #CCC;
  border-radius: 3px;
  display: block;
  height: 200px;
  width: 100%;
`

class EditCompany extends Component {
  state = { isLoading: false, picture: '' }

  componentDidMount() {
    this.fetchAccountData()
  }

  fetchAccountData = () => {
    const {base, version, routes: {accounts}} = config.api
    const token = window.localStorage.getItem('user_token')
    this.setState({isLoading: true})
    fetch(`${base}${version}${accounts}`, {
      method: 'GET',
      headers: {
        'Authorization': token,
        'Content-type': 'application/json'
      }
    })
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error(response)
      }
    })
    .then(response => {
      this.setState({isLoading: false})
      if (response.success) {
        this.setState({picture: response.data.pictures ? response.data.pictures.main : null})
      } else {
        console.error(response)
      }
    })
    .catch(e => {
      this.setState({isLoading: false})
      console.error(e)
    })
  }

  fetchNewPhoto = (file) => {
    const {base, version, routes: {accounts}} = config.api
    const token = window.localStorage.getItem('user_token')
    this.setState({isLoading: true})
    fetch(`${base}${version}${accounts}`, {
      method: 'PUT',
      headers: {
        'Authorization': token,
        'Content-type': 'application/json'
      },
      body: JSON.stringify({pictures: {main: file}})
    })
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error(response)
      }
    })
    .then(response => {
      this.setState({isLoading: false})
      if (response.success) {
        this.setState({picture: ''}, () => {
          this.setState({picture: file})
        })
      } else {
        console.error(response)
      }
    })
    .catch(e => {
      this.setState({isLoading: false})
      console.error(e)
    })
  }

  render() {
    const { picture, isLoading } = this.state
    return (
      <Content>
        { isLoading ? <Loader /> : null }
        <Header />
        <BreadCrumb options={
          [
            {link: '/', name: 'Home'},
            {name: 'Editar foto de capa '}
          ]
        } />
        <BoxContent>
          <Title>Editar foto de capa</Title>
          <Form>
            <PictureBox>
              <PictureImg src={picture}/>
              <UploadField
                placeholder={''}
                full
                icon={picture !== '' ? '' : 'add-outline'}
                margin={'0px'}
                text={picture !== '' ? 'Alterar foto' : 'Anexar foto' }
                onFileUpload={this.fetchNewPhoto.bind(this)} />
            </PictureBox>
          </Form>
        </BoxContent>
      </Content>
    )
  }
}


export default EditCompany
