export const formatPercentage = (num = '0', haveDecimals = true) => {
  num = removePercentage(num)
  if (Number(num) === 0) return ''
  if (!haveDecimals) {
    if (Number(num) > 100) {
      return `100`
    }
    return `${Math.round(num)}`
  }
  num = num + '';
  num = parseInt(num.replace(/[\D]+/g,''), 10);
  num = num + '';
  if (num.length === 1) {
    num = '0' + num
  }
  num = num.replace(/([0-9]{2})$/g, ".$1");
  if (Number(num) > 100) {
    return `100.00`
  }
  if (Number(num) < 1){
    return `0${num}`
  }
 return `${num}`
}

export const removePercentage = (num) => {
  return num.replace(/[^0-9.]/g, '')
}

export default formatPercentage
