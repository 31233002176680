import React, { Component } from 'react'
import UserIcon from '../../../components/ui/UserIcon'
import Button from '../../../components/ui/Button'
import Title from '../../../components/ui/Title'
import SelectFilter from '../../../components/ui/SelectFilter'
import Loader from '../../../components/Loader'
import { Get, Post } from '../../../../utils/request'
import config from '../../../../middleware/config'
import Modal from '../../../components/Modal/index'

export default class ModalDelegarConsultor extends Component {
  constructor (props) {
    super(props)
    this.state = {
      consultants: [],
      formatedConsultants: [],
      chosenConsultant: props.chosenConsultant,
      eventId: props.eventId,
      isLoading: true,
      isOpen: props.isOpen
    }
  }

  componentDidMount () {
    this.fetchConsultants()
  }

  UNSAFE_componentWillReceiveProps (props) {
    this.setState({ ...props },
      () => document.querySelector('body').style.overflow = this.state.isOpen ? 'hidden' : 'auto'
    )
  }

  fetchConsultants () {
    this.setState({ isLoading: true })
    const { api: { base, version } } = config
    const endpoint = `${base}${version}/consultant/admin/my-consultants`
    return Get(endpoint)
      .then(response => {
        this.setState({
          consultants: response,
          formatedConsultants: this.formatConsultants(response),
          isLoading: false
        })
      })
      .catch(e => { this.setState({ isLoading: false }, console.error(e)) })
  }

  chooseConsultant (consultantId) {
    this.setState({ selectedConsultant: consultantId })
  }

  formatConsultants (consultants) {
    return consultants.map(item => {
      return {
        value: item.id,
        label: `${item.profile.full_name} - ${(item.profile && item.profile.email)}`
      }
    })
  }

  handlerSelectValue (value) {
    const chosenConsultant = value
    this.setState({
      chosenConsultant
    })
  }

  submit = () => {
    const { api: { base, version } } = config
    const eventId = this.state.eventId
    const consultantId = this.state.chosenConsultant.value
    const endpoint = `${base}${version}/consultant/admin/delegate/event/${eventId}/consultant/${consultantId}`
    this.setState({ isLoading: true })
    Post(endpoint)
      .then(() => {
        alert('Delegado com sucesso!')
        this.setState({
          isOpen: false,
          isLoading: false
        })
        this.props.onClose && this.props.onClose()
      }).catch(err => {
        console.error(err.message)
        this.setState({ isLoading: false })
        alert(`Infelizmente não conseguimos realizar esta ação. ${err.message}`)
      })
  }

  render () {
    const eventName = this.props.eventName
    return <Modal onClick={(event) => {
      event.preventDefault && event.preventDefault()
      event.stopPropagation && event.stopPropagation()
    }} isOpen={this.state.isOpen} onClose={() => {
      this.setState({isOpen:false})
      this.props.onClose && this.props.onClose()
      }} hasCloseButton style={{zIndex:999999}}>
      {this.state.isLoading ? <Loader /> : null}
      <UserIcon width={'150px'} />
      <Title secondaryFont style={{color:'#484848'}}>Delegar consultor para evento {eventName}</Title>
      {this.state.consultants.length > 0 && <SelectFilter
        value={this.state.chosenConsultant}
        label={'Consultores'}
        isRequired
        onChange={(input) => this.handlerSelectValue(input)}
        placeholder={'Selecione o consultor'}
        options={this.state.formatedConsultants}
      />}
      <Button
        type={'button'}
        onClick={this.submit}
        category={'secondary'}
        fontWeight={800}
        full>
        Delegar
      </Button>
    </Modal>
  }
}
