import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import 'core-js'
import * as Sentry from '@sentry/browser'
import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
/* SERVICE WORKER */
import * as serviceWorker from './serviceWorker'
/* MIDDLEWARE */
import Root from './middleware/Routes'
import configureStore from './redux/store/configureStore'
/* STYLE */
import DefaultStyles from './screens/styles'
if (process.env.REACT_APP_SENTRY_DSN) Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN })
const store = configureStore()

// Polyfill para forEach em querySelectorAll
if (typeof NodeList !== 'undefined' && NodeList.prototype && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach
}

const App = () => {
  return <>
    <DefaultStyles />
    <Router>
      <Root store={store} />
    </Router>
  </>
}

render(
  <App />,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
