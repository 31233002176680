export default Dinheiro

/** Classe de auxílio de formatação de moeda
 * @param {String} codigoMoeda Código da moeda
 */
function Dinheiro (codigoMoeda) {
  const MOEDA = moedas(codigoMoeda)
  this.moeda = MOEDA.moeda
  this.idioma = MOEDA.idioma
  this.antes = MOEDA.antes
  this.simbolo = MOEDA.simbolo
  this.milhar = MOEDA.milhar
  this.decimal = MOEDA.decimal
  this.formata = formata
}

/** Retorna a moeda selecionada ou a moeda padrão
 * se nenhuma for selecionada
 * @param {String} codigoMoeda Código ISO da moeda desejada
 */
function moedas (codigoMoeda) {
  const MOEDA_PREFERENCIAL = 'BRL'
  const MOEDA_PADRAO = 'USD'
  const MOEDAS = {
    BRL: {
      moeda: 'BRL',
      idioma: 'pt-BR',
      simbolo: 'R$',
      antes: true,
      decimal: ',',
      milhar: '.'
    },
    USD: {
      moeda: 'USD',
      idioma: 'en-US',
      simbolo: '$',
      antes: true,
      decimal: '.',
      milhar: ','
    },
    EUR: {
      moeda: 'EUR',
      idioma: 'pt-PT',
      simbolo: '€',
      antes: false,
      decimal: ',',
      milhar: '.'
    },
    GBP: {
      moeda: 'GBP',
      idioma: 'en-GB',
      simbolo: '£',
      antes: true,
      decimal: '.',
      milhar: ','
    }
  }
  if (!codigoMoeda) codigoMoeda = MOEDA_PREFERENCIAL
  return MOEDAS[codigoMoeda.toUpperCase()] || MOEDAS[MOEDA_PADRAO]
}

/** Função de formatação monetária
 * @param {Number} valor Valor a ser formatado
 * @param {Boolean} simboloAntes Se informado, força o símbolo monetário antes do valor
 * @param {Boolean} semSimbolo Se informado, não apresenta símbolo monetário
 * @returns {String}
 */
function formata (valor, simboloAntes, semSimbolo) {
  let [ inteiro, decimal ] = valor.toFixed(2).split('.')
  let antes = simboloAntes ? true : this.antes
  let simbolo = semSimbolo || false
  let formatado = formataMilhar(inteiro, this.milhar)
  formatado = uneDecimal(formatado, this.decimal, decimal)
  formatado = !simbolo ? posicionaSimbolo(formatado, antes, this.simbolo) : formatado
  return formatado
}


/** Devolve um valor formatado com separadores de milhar
 * @param {String} inteiro Parte inteira do numeral a ser dividido em milhares
 * @param {String} separador Separador de milhar a ser utilizado na formatação
 * @returns {Array}
 */
function formataMilhar (inteiro, separador) {
  return divideMilhar(inteiro).join(separador)
}

/** Quebrar um numeral em blocos de milhar
 * @param {String} inteiro Parte inteira do numeral a ser dividido em milhares
 * @param {String} separador Separador de milhar a ser utilizado na formatação
 * @returns {Array}
 */
function divideMilhar (inteiro, dividido = []) {
  if (inteiro.length === 0) return dividido
  let inteiroArray = Array.from(inteiro)
  dividido = Array.prototype.concat(inteiroArray.splice(-3).join(''), dividido)
  return divideMilhar(inteiroArray.join(''), dividido)
}

/** Une as partes inteira e decimal da moeda formatada
 * @param {String} inteiro Parte inteira do valor
 * @param {String} decimal Parte decimal do valor
 */
function uneDecimal (inteiro, separador, decimal) {
  return `${inteiro}${separador}${decimal}`
}

/** Função que aplica o símbolo na moeda formatada
 * respeitando a posição padrão
 * @param {String} valor Valor formatado
 * @param {Boolean} antes Flag que indica se o símbolo vem antes ou depois do valor
 * @param {String} simbolo Símbolo da moeda
 * @returns {String}
 */
function posicionaSimbolo (valor, antes, simbolo) {
  if (antes) return `${simbolo} ${valor}`
  return `${valor} ${simbolo}`
}
