/* LIBS */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
/* ACTION */
import { getUsers } from '../../../redux/actions/user'
/* STYLES */
import { defaultTheme } from '../../styles/themes'
/* UI */
import Button from '../../components/ui/Button'
import BreadCrumb from '../../components/BreadCrumb'
/* COMPONENTS */
import Header from '../../components/Header'
import Loader from '../../components/Loader'
const ATIVO = 'ACTIVE'

const Main = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #FFF;
  @media (min-width: 768px) {
    background-color: #FAFAFA;
  }
`

const Content = styled.div`
  min-height: 90vh;
  padding: 30px 0px;
  font-family: ${defaultTheme.fonts.secondary};
  width: 90%;
  margin: 0 5%;
`

const Title = styled.h1`
  width: 100%;
  text-align: left;
  font-size: 20px;
  padding: 20px 10px;
`

const NoUsers = styled.p`
  padding: 0px 10px;
  color: #7b7b7b;
`

const UsersList = styled.div`
  padding: 0px 10px;
  color: #7b7b7b;
`

const UserItem = styled.div`
  border: 1px solid #7b7b7b;
  background-color: #fff;
  border-radius: 3px;
  margin: 5px 0px;
  padding: 5px;
`
const UserId = styled.span`
  font-size: 10px;
  width: 100%;
  display: block;
  color: #7b7b7b;
`

const UserTitle = styled.span`
  color: #6c6c6c;
  font-size: 16px;
  width: 100%;
  display: block;
`

class Users extends Component {
  componentDidMount() {
    this.props.getUsers()
    .catch(err => {
      alert(`Error (${err.message})`)
      console.error(err.message)
    })
  }

  apenasAtivos (users) {
    return users.filter(usuario => usuario.status === ATIVO)
  }

  render() {
    const { isFetching, users } = this.props
    return (
      <Main>
        {isFetching ? <Loader /> : null}
        <Header />
        <BreadCrumb options={
          [
            {link: '/', name: 'Home'},
            {name: 'Usuários'}
          ]
        } />
        <Content>
        <Button
          onClick={() => this.props.history.push('/users/add')}
          type='button'
          category={'secondary'}
          fontWeight={800}
          full
          ghost>
            + Cadastrar novo usuário
        </Button>
        <Title>
          Usuários
        </Title>
          {users
            ? <UsersRender users={this.apenasAtivos(users)}/>
            : <EmptyUsers/>}
        </Content>
      </Main>
    )
  }
}

const EmptyUsers = () => {
  return <NoUsers>Nenhum usuário cadastrado até o momento</NoUsers>
}

const UsersRender = ({users}) => {
  return <UsersList>
            {users.map(user =>
                <UserItem key={user.id}>
                  <UserId>{user.id}</UserId>
                  <UserTitle>{user.name}</UserTitle>
                  <UserTitle>{user.profile.email}</UserTitle>
                </UserItem>
              )}
        </UsersList>
}

function mapStateToProps (state) {
  const { app, user } = state
  const { language } = app
  const { isFetching, users } = user
  return {
    language,
    isFetching,
    users
  }
}

export default connect(mapStateToProps, { getUsers } )(Users)
