/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
import moment from 'moment'

/* API */
import config from '../../../../middleware/config'
import { Get } from '../../../../utils/request'

/* COMPONENTS */
import Header from '../../../Organizer/Header'
import Loader from '../../../components/Loader'
import Main from '../../../components/Main'
import Body from '../../../components/Body'
import Title from '../../../components/ui/Title'
import Select from '../../../components/ui/Select'
import Container from '../../../components/Dashboard/Container'
import GraficoBarra from '../../../components/ui/GraficoBarra'
import InputDatepicker from '../../../components/InputDatepicker'
import GraficoDeLinha from '../Gastos/GraficoLinha'
import CardDeGastos from '../Gastos/CardGastos/CardGastos'
import ModalCardGastos from '../Gastos/CardGastos/ModalGastos'
import Barra from '../Gastos/CardGastos/Barra'
import formatCurrency from '../../../../utils/money'
import { ehTipo } from '../../../../utils/tiposUsuario'
import { defaultTheme } from '../../../styles/themes'

const Titulo = styled(Title)`
  font-weight: 400;
  font-family: "Pluto",sans-serif;
`

const Group = styled.div`
`

const Label = styled.p`
  font-family: ${defaultTheme.fonts.primary};
  line-height: 1.2;
  font-Weight: 400;
  color: ${defaultTheme.colors.grey.light};
  font-size: 12px;
`

const PERIODO = {
  "dia": "YYYY-MM-DD",
  "mes": "YYYY-MM",
  "ano": "YYYY",
}

class DashBoardGastos extends Component {
  constructor () {
    super()
    this.state = {
      isLoading: false,
      dataGastos: [],
      dataSavings: [],
      dataLinhaLabels: [],
      lista: [],
      listaFiltrada: [],
      empresas: [],
      centroDeCustos: [],
      gastosPorServico: {},
      gastosPorTipoDeEvento: [],
      gastosPorFornecedor: [],
      filtro: {
        empresa: '',
        centroDeCusto: '',
        periodo: {
          start: '',
          end: ''
        }
      },
      modalGastosUm: false,
      modalGastosDois: false,
      modalGastosTres: false
    }
  }

  abreModal = (modal) => { this.setState({ [modal]: true }) }
  fechaModal = (modal) => { this.setState({ [modal]: false }) }

  componentDidMount () {
    const NAO_PODE_ACESSAR = !(ehTipo('HEADQUARTER_ADMIN') || ehTipo('BRANCH_ADMIN') || ehTipo('COSTCENTER_ADMIN'))
    if (NAO_PODE_ACESSAR) this.props.history.push('/organizer')
    else this.setState({ isLoading: true }, () => {
      Promise.all([
        this.renderizaListaEmpresas(),
        this.renderizaListaCentroDeCusto()
      ]).then(([empresas, centroDeCustos]) => {
        const state = {
          isLoading: false,
          empresas,
          centroDeCustos
        }
        if (!ehTipo('HEADQUARTER_ADMIN') && empresas && empresas[0]) {
          state.filtro = {
            ...this.state.filtro,
            empresa: empresas[0].id
          }
        }
        return this.setState(state)
      }).catch(erro => {
        this.setState({ isLoading: false })
        console.error(erro)
        alert(erro)
      })
    })
  }

  renderizaListaEmpresas = () => {
    return new Promise((resolve, reject) => {
      const { api: { base, version } } = config
      var endpoint = `${base}${version}/accounts/headquarters`
      return Get(endpoint).then((result) => {
        const lista = []
        lista.push(result)
        if (ehTipo('HEADQUARTER_ADMIN')) result.branchOffices.map(item => lista.push(item))
        resolve(lista)
      }).catch(erro => {
        reject(`Erro ao obter os suas empresas, favor entrar em contato com o suporte técnico (received: ${erro})`)
      })
    })
  }

  renderizaListaCentroDeCusto = () => {
    return new Promise((resolve, reject) => {
      const { api: { base, version } } = config
      var endpoint = `${base}${version}/accounts/cost-centers`
      return Get(endpoint).then(resolve).catch(erro => {
        reject(`Erro ao obter os seus centros de custo, favor entrar em contato com o suporte técnico (received: ${erro})`)
      })
    })
  }

  handlerDate = ({ target: { value: { from, to } } }) => {
    const { filtro } = this.state
    filtro.periodo.start = from && to ? moment(from, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br') : ''
    filtro.periodo.end = from && to ? moment(to, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br') : ''
    this.setState({ filtro: filtro }, () => this.atualizaFiltro())
  }

  handleFiltro = ({ target: { value, name } }) => {
    const { filtro } = this.state
    filtro[name] = value
    this.setState({ filtro }, () => this.atualizaFiltro())
  }

  filtraDados = (dados) => {
    return dados.filter(dado =>
      (dado.costcenterId === this.state.filtro.centroDeCusto || !this.state.filtro.centroDeCusto)
    )
  }

  atualizaFiltro = () => {
    const { filtro } = this.state
    if ((filtro.empresa || !ehTipo('HEADQUARTER_ADMIN')) && filtro.periodo && filtro.periodo.start && filtro.periodo.end) {
      const { api: { base, version } } = config
      var endpoint = `${base}${version}/dashboard` +
        `?start=${filtro.periodo.start.format('YYYY-MM-DD')}` +
        `&end=${filtro.periodo.end.format('YYYY-MM-DD')}` +
        `&branch_id=${filtro.empresa || ''}` +
        `&cost_center_id=${filtro.centroDeCusto || ''}` +
        `&limit=100000&page=1`
      this.setState({ isLoading: true }, () => {
        return Get(endpoint)
          .then((result) => this.setState({
            lista: result.data,
            listaFiltrada: this.filtraDados(result.data),
            gastosPorServico: this.geraGastosPorServico(this.filtraDados(result.data)),
            gastosPorTipoDeEvento: this.geraGastosPorTipoDeEvento(this.filtraDados(result.data)),
            gastosPorFornecedor: this.geraGastosPorFornecedor(this.filtraDados(result.data)),
            isLoading: false
          }))
          .catch(err => {
            this.setState({ isLoading: false })
            console.error(err)
            alert(`Erro ao obter os seus eventos, favor entrar em contato com o suporte técnico (received: ${err})`)
          })
      })
    }
  }

  geraGraficoGastosNoPeriodo = () => {
    return this.state.listaFiltrada.reduce((listagem, item) => {
      let centroDeCusto = listagem.findIndex(centro => centro.id === item.costcenterId)
      if (centroDeCusto === -1) {
        listagem.push({
          id: item.costcenterId,
          titulo: item.costcenter || 'Centro de custo indefinido',
          valor: item.finalPrice ? item.finalPrice.amount : 0
        })
      } else {
        listagem[centroDeCusto].valor += item.finalPrice ? item.finalPrice.amount : 0
      }
      return listagem
    }, []).sort((a, b) => b.valor - a.valor)
  }

  geraGraficoSavingsNoPeriodo = () => {
    return this.state.listaFiltrada.reduce((listagem, item) => {
      let centroDeCusto = listagem.findIndex(centro => centro.id === item.costcenterId)
      if (centroDeCusto === -1) {
        listagem.push({
          id: item.costcenterId,
          titulo: item.costcenter || 'Centro de custo indefinido',
          valor: Object.keys(item.services).reduce((soma, servico) => {
            soma += item.services[servico].savings ? item.services[servico].savings.amount : 0
            return soma
          }, 0)
        })
      } else {
        listagem[centroDeCusto].valor += Object.keys(item.services).reduce((soma, servico) => {
          soma += item.services[servico].savings ? item.services[servico].savings.amount : 0
          return soma
        }, 0)
      }
      return listagem
    }, []).sort((a, b) => b.valor - a.valor)
  }

  geraGraficoEventoNoPeriodo = () => {
    return this.state.listaFiltrada.reduce((listagem, item) => {
      let evento = listagem.findIndex(supplier => supplier.id === item.eventId)
      if (evento === -1) {
        listagem.push({
          id: item.eventId,
          titulo: item.eventName,
          valor: item.finalPrice ? item.finalPrice.amount : 0
        })
      } else {
        listagem[evento].valor += item.finalPrice ? item.finalPrice.amount : 0
      }
      return listagem
    }, []).sort((a, b) => b.valor - a.valor)
  }

  renderizaGraficos = () => {
    const obj = [
      {
        id: 0,
        title: 'dia',
        content: () => {
          return (<p></p>)
        }
      },
      {
        id: 1,
        title: 'mês',
        content: () => {
          return (<p></p>)
        }
      },
      {
        id: 2,
        title: 'ano',
        content: () => {
          return (<p></p>)
        }
      }
    ]
    return [
      this.state.filtro.centroDeCusto ? <GraficoDeLinha
        init={this.atualizarGraficoLinha}
        labels={this.state.dataLinhaLabels}
        dataGastos={this.state.dataGastos}
        dataSavings={this.state.dataSavings}
        onToggleChange={(indice) => this.atualizarGraficoLinha(indice)}
        obj={obj} /> : <GraficoBarra grafico={this.geraGraficoGastosNoPeriodo()} />,
      <GraficoBarra grafico={this.geraGraficoSavingsNoPeriodo()} />,
      <GraficoBarra grafico={this.geraGraficoEventoNoPeriodo()} />
    ]
  }

  formatarSavings = (lista) => {
    return lista.map(item => {
      return ((Object.keys(item.services).reduce((soma, servico) => {
        soma += item.services[servico].savings ? item.services[servico].savings.amount : 0
        return soma
      }, 0)) / 100).toFixed(2)
    })
  }

  formatarGastos = (lista) => {
    return lista.reduce((result, item) => {
      result += (item.finalPrice ? item.finalPrice.amount : 0) / 100
      return result
    }, 0)
  }

  /**
   * Agrupa em um objeto de gastos no período por dia, mês ou ano
   * @param {array} lista - lista filtrada de registros de gastos no período.
   * @param {string} periodo - "dia", "mes", "ano".
   * @return {Object} Um objeto com chaves representando o período e, por sua vez, contendo os seus respectivos valores.
  */
  agruparPorPeriodo = (lista, periodo) => {
    const periodoSelecionado = PERIODO[periodo] || PERIODO.dia

    return lista.reduce((result, item) => {
      const index = moment(item.day).format(periodoSelecionado)
      if (!result[index]) result[index] = []
      result[index].push(item)
      return result
    }, {})
  }

  atualizarGraficoLinha = (indice) => {
    const resultadoPorMes = this.agruparPorPeriodo(this.state.listaFiltrada, Object.keys(PERIODO)[indice])
    const labels = Object.keys(resultadoPorMes).sort()
    const result = {}

    Object.entries(resultadoPorMes).forEach(([key, item]) => {
      result[key] = {}
      result[key].gastos = this.formatarGastos(item).toFixed(2)
      result[key].savings = this.formatarSavings(item)
        .reduce((acc, item) => (acc + parseFloat(item)), 0)
        .toFixed(2)
    })

    this.setState({
      dataGastos: labels.map(key => result[key].gastos),
      dataSavings: labels.map(key => result[key].savings),
      dataLinhaLabels: labels
    })
  }

  gastosNoPeriodoTotal = () => {
    return this.geraGraficoGastosNoPeriodo().reduce((soma, item) => {
      soma += item.valor
      return soma
    }, 0)
  }

  savingsNoPeriodoTotal = () => {
    return this.geraGraficoSavingsNoPeriodo().reduce((soma, item) => {
      soma += item.valor
      return soma
    }, 0)
  }

  eventosRealizadosNoPeriodoTotal = () => {
    return this.geraGraficoEventoNoPeriodo().length
  }

  geraGraficoDeLinhaLabels = () => {
    return this.state.listaFiltrada.reduce((lista, item) => {
      const eventoIndex = lista.findIndex(dado => dado.day === item.day)
      if (eventoIndex !== -1) {
        Object.keys(lista[eventoIndex].services).map(servico => {
          if (item.services[servico]) {
            lista[eventoIndex].services[servico].finalPrice.amount += item.services[servico].finalPrice ? item.services[servico].finalPrice.amount : 0
            lista[eventoIndex].services[servico].savings.amount += item.services[servico].savings.amount
          }
          return lista[eventoIndex]
        })
      } else {
        lista.push(item)
      }
      return lista
    }, []).map(item => item.day)
  }

  geraGraficoDeLinhaDataGastos = () => {
    return this.state.listaFiltrada.reduce((lista, item) => {
      const eventoIndex = lista.findIndex(dado => dado.day === item.day)
      if (eventoIndex !== -1) {
        lista[eventoIndex].finalPrice.amount += item.finalPrice ? item.finalPrice.amount : 0
        Object.keys(lista[eventoIndex].services).map(servico => {
          if (item.services[servico]) {
            lista[eventoIndex].services[servico].finalPrice.amount += item.services[servico].finalPrice ? item.services[servico].finalPrice.amount : 0
            lista[eventoIndex].services[servico].savings.amount += item.services[servico].savings.amount
          }
          return lista[eventoIndex]
        })
      } else {
        lista.push(item)
      }
      return lista
    }, []).map(item => ((item.finalPrice ? item.finalPrice.amount : 0) / 100).toFixed(2))
  }

  geraGraficoDeLinhaDataSavings = () => {
    return this.state.listaFiltrada.reduce((lista, item) => {
      const eventoIndex = lista.findIndex(dado => dado.day === item.day)
      if (eventoIndex !== -1) {
        Object.keys(lista[eventoIndex].services).map(servico => {
          if (item.services[servico]) {
            lista[eventoIndex].services[servico].finalPrice.amount += item.services[servico].finalPrice ? item.services[servico].finalPrice.amount : 0
            lista[eventoIndex].services[servico].savings.amount += item.services[servico].savings.amount
          }
          return lista[eventoIndex]
        })
      } else {
        lista.push(item)
      }
      return lista
    }, []).map(item => {
      return ((Object.keys(item.services).reduce((soma, servico) => {
        soma += item.services[servico].savings ? item.services[servico].savings.amount : 0
        return soma
      }, 0)) / 100).toFixed(2)
    })
  }

  renderizaGastosPorServico = () => {
    const servicos = this.state.gastosPorServico
    return Object.keys(servicos).sort((a, b) => (servicos[b].finalPrice ? servicos[b].finalPrice.amount : 0) - (servicos[a].finalPrice ? servicos[a].finalPrice.amount : 0)).reduce((lista, item, index) => {
      if (item === 'otherServices') {
        lista = lista.concat(Object.keys(servicos[item].services).map(servico => <Barra
          key={index}
          nome={this.mostraNomeDoServico(servico)}
          porcentagem={this.geraPorcentagem((servicos[item].services[servico].finalPrice ? servicos[item].services[servico].finalPrice.amount : 0), this.valorMaximoDeGastosPorServico()) + '%'}
          valorBarra={formatCurrency((servicos[item].services[servico].finalPrice ? servicos[item].services[servico].finalPrice.amount : 0))}
          descricao={`Savings: ${formatCurrency(servicos[item].services[servico].savings ? servicos[item].services[servico].savings.amount : 0)}`} />))
      } else {
        lista.push(<Barra
          key={index}
          nome={this.mostraNomeDoServico(item)}
          porcentagem={this.geraPorcentagem((servicos[item].finalPrice ? servicos[item].finalPrice.amount : 0), this.valorMaximoDeGastosPorServico()) + '%'}
          valorBarra={formatCurrency((servicos[item].finalPrice ? servicos[item].finalPrice.amount : 0))}
          descricao={`Savings: ${formatCurrency(servicos[item].savings.amount)}`} />)
      }
      return lista
    }, [])
  }

  valorMaximoDeGastosPorServico = () => {
    return Object.keys(this.state.gastosPorServico).reduce((max, item) => {
      if (this.state.gastosPorServico[item].finalPrice && this.state.gastosPorServico[item].finalPrice.amount > max) {
        return this.state.gastosPorServico[item].finalPrice.amount
      } else return max
    }, 0)
  }

  geraGastosPorServico = (lista) => {
    return lista.reduce((servicos, item) => {
      Object.keys(item.services).forEach((servico) => {
        if (!servicos[servico]) {
          servicos[servico] = {
            finalPrice: {
              amount: 0,
              currency: 'BRL'
            },
            savings: {
              amount: 0,
              currency: 'BRL'
            },
          }
        }
        if (servico === 'otherServices') {
          servicos[servico].services = {}
          Object.keys(item.services[servico].services).forEach(outrosServicos => {
            servicos[servico].services[outrosServicos] = item.services[servico].services[outrosServicos]
          })
        }
        servicos[servico].finalPrice.amount += parseFloat(item.services[servico].finalPrice ? item.services[servico].finalPrice.amount : 0)
        servicos[servico].savings.amount += parseFloat(item.services[servico].savings.amount)
      })
      return servicos
    }, {})
  }

  geraPorcentagem = (valor, max) => {
    return (100 * valor) / max
  }

  mostraNomeDoServico = (servico) => {
    switch (servico) {
      case 'room':
        return 'Salas'
      case 'equipments':
        return 'Equipamentos'
      case 'transfer':
        return 'Transfer'
      case 'foodsAndBeverages':
        return 'Alimentos e Bebidas'
      case 'lodging':
        return 'Hospedagem'
      case 'otherServices':
        return 'Outros Serviços'
      case 'gifts':
        return 'Brindes'
      case 'printShop':
        return 'Gráfica'
      case 'additionalServices':
        return 'Serviço Adicional'
      default:
        return ''
    }
  }

  renderizaGastosPorTipoDeEvento = () => {
    return this.state.gastosPorTipoDeEvento.sort((a, b) => (b.finalPrice ? b.finalPrice.amount : 0) - (a.finalPrice ? a.finalPrice.amount : 0)).map((item, index) => {
      return <Barra
        key={index}
        nome={`${item.eventType} (${item.count})`}
        porcentagem={this.geraPorcentagem((item.finalPrice ? item.finalPrice.amount : 0), this.valorMaximoDeGastosPorTipoDeEvento()) + '%'}
        valorBarra={formatCurrency((item.finalPrice ? item.finalPrice.amount : 0))}
        descricao={`Ticket médio por evento: ${formatCurrency((((item.finalPrice ? item.finalPrice.amount : 0) / 100) / item.count).toFixed(2))}`}
        descricaoColor={defaultTheme.colors.grey.medium} />
    })
  }

  geraGastosPorTipoDeEvento = (lista) => {
    return lista.reduce((lista, item) => {
      let tipoDeEvento = lista.findIndex(evento => evento.eventType === item.eventType)
      if (tipoDeEvento === -1) {
        lista.push({
          eventType: item.eventType,
          finalPrice: {
            amount: (item.finalPrice ? item.finalPrice.amount : 0),
            currency: 'BRL'
          },
          count: 1
        })
      } else {
        lista[tipoDeEvento].finalPrice.amount += (item.finalPrice ? item.finalPrice.amount : 0)
        lista[tipoDeEvento].count++
      }
      return lista
    }, [])
  }

  valorMaximoDeGastosPorTipoDeEvento = () => {
    return this.state.gastosPorTipoDeEvento.reduce((max, item) => {
      if (item.finalPrice && item.finalPrice.amount > max) {
        return item.finalPrice.amount
      } else return max
    }, 0)
  }

  renderizaGastosPorFornecedor = () => {
    return this.state.gastosPorFornecedor.sort((a, b) => (b.finalPrice ? b.finalPrice.amount : 0) - (a.finalPrice ? a.finalPrice.amount : 0)).map((item, index) => {
      return <Barra
        key={index}
        nome={`${item.name} (${item.count})`}
        porcentagem={this.geraPorcentagem((item.finalPrice ? item.finalPrice.amount : 0), this.valorMaximoDeGastosPorFornecedor()) + '%'}
        valorBarra={formatCurrency((item.finalPrice ? item.finalPrice.amount : 0))}
        descricao={`Ticket médio por evento: ${formatCurrency((((item.finalPrice ? item.finalPrice.amount : 0) / 100) / item.count).toFixed(2))}`}
        descricaoColor={defaultTheme.colors.grey.medium} />
    })
  }

  geraGastosPorFornecedor = (lista) => {
    return lista.reduce((lista, item) => {
      Object.keys(item.services).forEach(servico => {
        let fornecedor = lista.findIndex(fornecedor => fornecedor.id === item.services[servico].supplierId)
        if (fornecedor === -1) {
          lista.push({
            events: [item.eventId],
            id: item.services[servico].supplierId,
            name: item.services[servico].supplierName,
            finalPrice: item.services[servico].finalPrice,
            count: 1
          })
        } else {
          lista[fornecedor].finalPrice.amount += item.services[servico].finalPrice ? item.services[servico].finalPrice.amount : 0
          if (!lista[fornecedor].events.find(evento => evento === item.eventId)) {
            lista[fornecedor].events.push(item.eventId)
            lista[fornecedor].count++
          }
        }
      })
      return lista
    }, [])
  }

  valorMaximoDeGastosPorFornecedor = () => {
    return this.state.gastosPorFornecedor.reduce((max, item) => {
      if (item.finalPrice && item.finalPrice.amount > max) {
        return item.finalPrice.amount
      } else return max
    }, 0)
  }

  render () {
    const gastosPorServico = this.renderizaGastosPorServico()
    const gastosPorTipoDeEvento = this.renderizaGastosPorTipoDeEvento().slice(0, 6)
    const gastosPorFornecedor = this.renderizaGastosPorFornecedor().slice(0, 6)
    return (
      <div style={{ overflowY: 'scroll' }}>
        {this.state.isLoading ? <Loader /> : null}
        <ModalCardGastos conteudoModal={this.renderizaGastosPorTipoDeEvento()} isOpen={this.state.modalGastosDois} onClose={() => this.fechaModal('modalGastosDois')} titulo={'Gastos por Evento'} />
        <ModalCardGastos conteudoModal={this.renderizaGastosPorFornecedor()} isOpen={this.state.modalGastosTres} onClose={() => this.fechaModal('modalGastosTres')} titulo={'Gastos por Fornecedor'} />
        <Header />
        <Main>
          <Body {...this.props} pagina={'dashboard-gastos'}>
            <Titulo style={{ margin: '0 0 20px' }}> Dashboard </Titulo>
            <Group style={{ display: 'flex', width: '70%' }}>
              {ehTipo('HEADQUARTER_ADMIN') && <Select
                label={'Empresa'}
                isRequired
                value={this.state.filtro.empresa}
                onChange={this.handleFiltro}
                name='empresa'>
                <option value={''}>Selecione uma empresa</option>
                {this.state.empresas.map((item, index) => <option key={index} value={item.id}>{item.name}</option>)}
              </Select>}
              <Select
                label={'Área / centro de custo'}
                isRequired
                value={this.state.filtro.centroDeCusto}
                onChange={this.handleFiltro}
                name='centroDeCusto'>
                <option value={''}>Todos</option>
                {this.state.centroDeCustos.filter(item => !this.state.filtro.empresa || (item.accountId === this.state.filtro.empresa)).map((item, index) => <option key={index} value={item.id}>{item.name}</option>)}
              </Select>
              <InputDatepicker
                type={'range'}
                label={'Período'}
                placeholder={'Selecione um período'}
                dates={{ from: this.state.filtro.periodo.start, to: this.state.filtro.periodo.end }}
                onChange={this.handlerDate}
                name={'start'}
                isRequired />
            </Group>
            <Container
              gastosNoPeriodoTotal={this.gastosNoPeriodoTotal()}
              savingsNoPeriodoTotal={this.savingsNoPeriodoTotal()}
              eventosRealizadosNoPeriodoTotal={this.eventosRealizadosNoPeriodoTotal()}
              blocos={this.renderizaGraficos()}
              podeClicarEmSavings={!this.state.filtro.centroDeCusto} />
            <Group style={{ display: 'flex', justifyContent: 'space-between' }}>
              <CardDeGastos
                tituloCard={'Gastos por serviço'}
                conteudoCard={gastosPorServico.length > 0 ? gastosPorServico : <Label>Não há dados para exibir</Label>}
                statusModal={() => this.abreModal('modalGastosUm')} />
              <CardDeGastos
                mostraVerTodos={this.renderizaGastosPorTipoDeEvento().length > 6}
                tituloCard={'Gastos por evento'}
                conteudoCard={gastosPorTipoDeEvento.length > 0 ? gastosPorTipoDeEvento : <Label>Não há dados para exibir</Label>}
                statusModal={() => this.abreModal('modalGastosDois')} />
              <CardDeGastos
                mostraVerTodos={this.renderizaGastosPorFornecedor().length > 6}
                tituloCard={'Gastos por fornecedor'}
                conteudoCard={gastosPorFornecedor.length > 0 ? gastosPorFornecedor : <Label>Não há dados para exibir</Label>}
                statusModal={() => this.abreModal('modalGastosTres')} />
            </Group>
          </Body>
        </Main>
      </div>
    )
  }
}

export default DashBoardGastos
