/* LIBS */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
/* ACTION */
import { createUser } from '../../../redux/actions/user'
/* STYLES */
import { defaultTheme } from '../../styles/themes'
/* UI */
import Button from '../../components/ui/Button'
import Input from '../../components/ui/Input'
import Select from '../../components/ui/Select'
import Alert from '../../components/ui/Alert'
import Loader from '../../components/Loader'
import BreadCrumb from '../../components/BreadCrumb'
/* COMPONENTS */
import Header from '../../components/Header'

const Main = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #FFF;
  @media (min-width: 768px) {
    background-color: #FAFAFA;
  }
`

const Content = styled.div`
  min-height: 90vh;
  padding: 30px 0px;
  font-family: ${defaultTheme.fonts.secondary};
  width: 90%;
  margin: 0 5%;
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  align-content: start;
`

const Title = styled.h1`
  width: 100%;
  text-align: left;
  font-size: 20px;
  padding: 20px 10px;
`

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  padding: 5px 10px;
  box-sizing: border-box;
  width: 100%;
`
class FormUser extends Component {
  constructor() {
    super()
    this.state = {
      error: false,
      errors: {
        full_name: '',
        email: '',
      },
      user: {
        user_level: 'SUPPLIER',
        full_name: '',
        email: '',
        status: 'active'
      }
    }
  }

  handlerValue (input) {
    const { user } = this.state
    user[input.target.name] = input.target.value
    this.setState({user: user})
  }

  submit (e) {
    e.preventDefault()
    const { user } = this.state
    let error = false
    this.setState({error: false, errors: {
      full_name: '',
      email: '',
    }}, () => {
      var handlerErrors = this.state.errors
      Object.keys(user).forEach(key => {
        if (user[key] === '') {
          error = true
          handlerErrors[key] = true
        }
      })

      if (error) {
        this.setState({error: 'Preencha todos os campos obrigatórios.'})
      } else {
        this.props.createUser(this.state.user)
      }
    })
  }

  render() {
    const { isFetching } = this.props
    return (
      <Main>
        {isFetching ? <Loader /> : null}
        <Header />
        <BreadCrumb options={
          [
            {link: '/', name: 'Home'},
            {link: '/users', name: 'Usuários'},
            {name: 'Cadastro de novo usuário'}
          ]
        } />
        <Content>
        <Title>
          Cadastro de novo usuário
        </Title>
        <Form onSubmit={this.submit.bind(this)} method={'POST'}>
            {this.state.error
              ? <Alert type={'negative'}>
                {this.state.error}
              </Alert> : null}
          <Select
            label={'Nível de usuário'}
            name={'user_level'}
            defaultValue={this.state.user.user_level}
            onChange={this.handlerValue.bind(this)}
            isRequired>
            <option value='user'>User</option>
          </Select>
          <Input
            type={'text'}
            label={'Nome completo'}
            placeholder={'Digite o nome completo'}
            name={'full_name'}
            onChange={this.handlerValue.bind(this)}
            error={this.state.errors.full_name}
            value={this.state.user.full_name}
            isRequired
          />
          <Input
            type={'email'}
            label={'Email'}
            placeholder={'Digite o email'}
            name={'email'}
            value={this.state.user.email}
            onChange={this.handlerValue.bind(this)}
            error={this.state.errors.email}
            isRequired
          />
          <Select
            label={'Status'}
            name={'status'}
            onChange={this.handlerValue.bind(this)}
            defaultValue={this.state.user.status}
            isRequired>
          <option value='active'>Ativo</option>
          <option value='inactive'>Inativo</option>
         </Select>
          <Button
            type='submit'
            category={'secondary'}
            fontWeight={800}
            full>
            Cadastrar
          </Button>
        </Form>
        </Content>
      </Main>
    )
  }
}

function mapStateToProps (state) {
  const { app, user } = state
  const { language } = app
  const { isFetching } = user
  return {
    language,
    isFetching
  }
}

export default connect(mapStateToProps, {createUser} )(FormUser)
