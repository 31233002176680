/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
/* UTILS */
import { formataCnpj } from '../../../utils/formataCnpj'
import config from '../../../middleware/config'
import { Get } from '../../../utils/request'
/* STYLES */
import { defaultTheme } from '../../styles/themes'
/* COMPONENTS */
import Modal from '../../components/Modal/index'
import Loader from '../../components/Loader'
import MoedaIcon from '../../components/ui/MoedaIcon'
import RightBoxInput from '../../components/ui/RightBoxInput'
import Button from '../../components/ui/Button'
import Alert from '../../components/ui/Alert'
import Subtitle from '../../components/ui/Subtitle'

const Title = styled.h1`
  font-family: ${defaultTheme.fonts.secondary};
  font-weight: 800;
  line-height: 1.2;
  font-size: 1.3em;
  margin-top: 20px;
  color: #484848;
`

const Label = styled.label`
  align-items: center;
  background-color: #FFF;
  cursor: pointer;
  display: flex;
  color: ${props => props.error ? 'red' : '#484848'};
  font-family: ${defaultTheme.fonts.secondary};
  font-weight: 500;
  box-sizing: border-box;
  font-size: 13px;
  padding-left: 10px;
  width: 100%;
`

const Cliente = ({ client }) => {
  const estilo = {
    fontFamily: defaultTheme.fonts.primary,
    fontSize: '0.8em',
    color: defaultTheme.colors.subtitle,
    margin: '0'
  }
  return (<>
    <Subtitle style={estilo}>
      Cliente: <b style={{fontWeight: 800}}> {client.name}</b>
    </Subtitle>
    <Subtitle style={{fontSize: '0.8em'}}>
      CNPJ para faturamento: <span style={{fontWeight: 800}}>{formataCnpj(client.document)}</span>
    </Subtitle>
  </>)
}

const Group = styled.div`
  display: flex;
  width: 100%;
`


class BillingConditions extends Component {
  static defaultProps = {
    onSubmit: () => {}
  }

  state = {
    deadLine: 45,
    isLoading: false,
    newBillingConditions: false,
    percentage: 0,
    days: 0,
    errorMessage: ''
  }

  componentDidMount () {
    this.loadBillingConditions()
  }

  loadBillingConditions = () => {
    const { api: { base, version } } = config
    const accountId = window.localStorage.getItem('account')
    const endpoint = `${base}${version}/organizer/billing/${accountId}`

    this.setState({ isLoading: true }, () => {
      Get(endpoint).then(response => {
        this.setState({
          deadLine: response.realBillingDeadline ? response.realBillingDeadline.numberOfDays : 45,
          isLoading: false
        })
      }).catch(e => {
        console.error(e)
        this.setState({ isLoading: false })
      })
    })
  }

  handleValue = ({target: {name, value}}) => {
    this.setState({[name]: value})
  }

  submit = () => {
    this.setState({errorMessage: ''})
    if (this.validate()) {
      this.props.onSubmit({newBillingConditions: this.state.newBillingConditions, percentage: this.state.percentage, days: this.state.days})
    } else {
      this.setState({errorMessage: 'Preencha todos os campos obrigatórios.'})
    }
  }

  validate = () => {
    return !this.state.newBillingConditions || (this.state.newBillingConditions && (this.state.percentage <= 0 || this.state.days))
  }

  newBillingConditions = () => {
    this.setState({
      newBillingConditions: !this.state.newBillingConditions
    })
  }

  render() {
    const { errorMessage, isLoading, deadLine } = this.state
    const { isOpen, client } = this.props
    return (
      <Modal isOpen={isOpen} hasCloseButton onClose={() => {
        this.setState({ newBillingConditions: false})
        return this.props.onClose && this.props.onClose()
        }}>
        {isLoading && <Loader />}
        <MoedaIcon />
        {errorMessage && <Alert type={'negative'}>{errorMessage}</Alert>}
        <Title>Condições para faturamento</Title>
        <Cliente client={client || {}} />
        {!this.state.newBillingConditions && <Label style={{ padding: '20px 0px' }}>Pagamento deste cliente deve ser faturado, pagamento integral {deadLine} dias após emissão da fatura</Label>}
        {!this.state.newBillingConditions && <Group>
          <Button
            type={'button'}
            onClick={this.newBillingConditions}
            category={'secondary'}
            fontWeight={800}
            ghost
            full>
            Propor outro
          </Button>
          <Button
            type={'button'}
            onClick={this.submit}
            category={'secondary'}
            fontWeight={800}
            full>
            De acordo
          </Button>
        </Group>}
        {this.state.newBillingConditions && <Group style={{ display: 'block' }}>
          <RightBoxInput
            placeholder={'0'}
            label={'Porcentagem de antecipação do valor total'}
            rightBox='%'
            type='number'
            name={'percentage'}
            isRequired
            value={this.state.percentage}
            onChange={this.handleValue}
            style={{ width: '100%' }}/>
          <RightBoxInput
            placeholder={'0'}
            label={'Restante deve ser recebido em até quantos dias após o evento?'}
            rightBox='dia(s)'
            type='number'
            name={'days'}
            isRequired
            value={this.state.days}
            onChange={this.handleValue}
            style={{ width: '100%' }}/>
          <Button
            type={'button'}
            onClick={this.submit}
            category={'secondary'}
            fontWeight={800}
            full>
            Confirmar
          </Button>
        </Group>}
      </Modal>
    )
  }
}

export default BillingConditions
