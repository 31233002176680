/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
/* UTILS */
import config from '../../../../middleware/config'
import { formatCurrency } from '../../../../utils/money'
import { formatDate } from '../../../../utils/dates'
import { Get, Post } from '../../../../utils/request'
import { getNameFromKey } from '../../../../utils/fabTexts'
import moment from 'moment'
/* STYLES */
import { defaultTheme } from '../../../styles/themes'
/* COMPONENTS */
import Header from '../../Header'
import Loader from '../../../components/Loader'
import Icon from '../../../components/ui/Icons'
import Card from '../../../components/ui/Card'
import Button from '../../../components/ui/Button'
import Money from '../../../components/ui/Money'
import Title from '../../../components/ui/Title'

const FABIcon = styled(() => (<Icon icon={'food-beverage-outline'} />))`
  min-width: 55px;
  color: ${defaultTheme.colors.grey.medium};
`

const Main = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #FFF;
  @media (min-width: 768px) {
    background-color: #FAFAFA;
  }
`
const Content = styled.div`
  min-height: 90vh;
  padding: 10px 20px;
  font-family: ${defaultTheme.fonts.secondary};
  width: 80%;
  font-size: 14px;
`

const Observations = styled.p`
  color: ${defaultTheme.colors.grey.light};
  font-family: 'Open sans';
`

const BriefingData = styled.ul``

const BriefingInfo = styled.li`
  color: ${defaultTheme.colors.grey.light};
  font-weight: normal;
  font-size: 0.9em;
  & b {
    font-weight: bold;
    color: ${defaultTheme.colors.text.dark};
  }
`

const Group = styled.div`
`

const Conclusion = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 30px;
  width: 80%;
  & div  {
    width: 50%;
  }
`
const Span = styled.span`
  font-weight: bold;
`

const Total = (props) => {
  return (
    <div style = {props.style}>
      <Money style={{ width: '100%', textAlign: 'center', display: 'inline-block' }}>{props.children}</Money>
      <p style={{
        color: defaultTheme.colors.grey.medium,
        fontFamily: defaultTheme.fonts.primary,
        fontSize: '1.6 em',
        textAlign: 'center', width: '100%',
        fontWeight: 100}}>Valor total sem taxas</p>
    </div>
  )
}

function formatTime(time) {
  time = time.split(':')
  if(time[0] === '00' && time[1] === '00') return 'Indefinido'
  return `${time[0]}:${time[1]} `
}

class FoodAndBeverageDetails extends Component {
  state = {
    budgets: [],
    isLoading: false,
    budgetId: ''
  }

  FABCard = (item) => {
    return (
      <Card style={{padding: '10px', width: 'calc((100% / 3) - 30px)'}}>
        <BriefingData style={{padding: '10px'}}>
          <Title style={{ margin: '0', color: '#727272'}}>{getNameFromKey(item.name)}</Title>
          <br />
          <BriefingInfo>Datas: <b>{
            item.briefing && item.briefing.requests.map((briefing, index) => {
              if (index !== 0) return ' - ' + formatDate(briefing.start)
              return formatDate(briefing.start)
            })
          }</b></BriefingInfo>
          <BriefingInfo>Horário de início: <b>{item.briefing && (item.briefing.requests[0].startTime === '00:00:00' ? 'Horário não informado' : formatTime(item.briefing.requests[0].startTime))}</b></BriefingInfo>
          <BriefingInfo>Número de pessoas: <b>{item.amountOfPeople}</b></BriefingInfo>
          {item.briefing && item.briefing.requests[0].beverages && <BriefingInfo>Pacote de bebida solicitado: <b>{getNameFromKey(item.briefing && item.briefing.requests[0].beverages)}</b></BriefingInfo>}
        </BriefingData>
          {
            item.budgets.length > 0 && item.budgets.map((budget, index) => {
              return <label htmlFor={`${item.name}_${budget.item.name}${index}`}>
                <Card key={`item_${index}`} style={{ paddingBottom: '10px'}} >
                  <Group style={{ display: 'flex', paddingBottom: '20px' }}>
                    <Group style={{ width: '90%', display: 'flex' }}>
                      <Title style={{ margin: '0', width: '100%', color: '#727272', fontSize: '15px'}}>{budget.item.name}</Title>
                      <br />
                      <Money style={{ fontSize: '8px', width: '100%', textAlign: 'end' }}>{formatCurrency(budget.totalWithoutFee.amount, false)}</Money>
                    </Group>
                    <input name={`${item.name}_${budget.item.name}${index}`} id={`${item.name}_${budget.item.name}${index}`} type="radio" value="option" checked={budget.selected} onChange={() => this.itemClick(budget.id, item.name)} />
                  </Group>
                  <BriefingInfo style={{ listStyle: 'none', textAlign: 'justify', fontStyle: 'italic' }}>{budget.item.description}</BriefingInfo>
                  <BriefingInfo style={{ listStyle: 'none', paddingTop: '10px', fontStyle: 'italic', fontSize: '11px' }}>{formatCurrency(budget.price.amount, false)} / por pessoa / dia</BriefingInfo>
                </Card>
              </label>
            })
          }
          {
            item.name === 'roomService' &&
              Object.keys(item.budgets).filter(key => item.budgets[key]).map((key, index) => {
                return <Card key={`roomServiceItem_${index}`} style={{ paddingBottom: '10px' }}>
                  <Group style={{ display: 'flex', paddingTop: '0px', paddingBottom: '20px' }}>
                    <Title style={{ margin: '0', maxWidth: '100px', color: '#727272', fontSize: '15px'}}>{getNameFromKey(item.budgets[key].item.name)}</Title>
                    <label style={{position: 'absolute', right: '30px'}}>
                      <Money style={{ fontSize: '13px', minWidth: '68px' }}>{formatCurrency(item.budgets[key].price.amount, false)}</Money>
                      <BriefingInfo style={{ fontSize: '10px', listStyle: 'none' }}>valor por unidade</BriefingInfo>
                    </label>
                  </Group>
                  <BriefingInfo style={{ listStyle: 'none', paddingLeft: '15px' }}>{item.budgets[key].numberOfItems} {item.budgets[key].numberOfItems > 1 ? 'unidades' : 'unidade'} por dia</BriefingInfo>
                  <BriefingInfo style={{ listStyle: 'none', paddingLeft: '15px', wordBreak: 'break-all' }}>{getNameFromKey(item.budgets[key].type)}{item.budgets[key].type ? ' - ' : ''}{item.budgets[key].quantity}</BriefingInfo>
                  <BriefingInfo style={{ listStyle: 'none', paddingLeft: '15px' }}>R$ {formatCurrency(item.budgets[key].totalWithoutFee.amount / item.numberOfDays, false)} por dia</BriefingInfo>
                  <BriefingInfo style={{ listStyle: 'none', paddingLeft: '15px' }}><b>Valor total: R$ {formatCurrency(item.budgets[key].totalWithoutFee.amount, false)}</b> ({item.numberOfDays} dias)</BriefingInfo>
                </Card>
              })
          }

          {
            item.name === 'roomService' &&
            Object.keys(this.state.budgets[this.state.budgets.findIndex(item => item.name === 'tray')].budgets).filter(key => this.state.budgets[this.state.budgets.findIndex(item => item.name === 'tray')].budgets[key]).length > 0 &&
            <Card key={`roomServiceItemTray`} style={{ paddingBottom: '10px' }}>
              <Group>
                <Title style={{ margin: '0', color: '#727272'}}>Bandeja</Title>
              </Group>
              {
                Object.keys(this.state.budgets[this.state.budgets.findIndex(item => item.name === 'tray')].budgets).filter(key => this.state.budgets[this.state.budgets.findIndex(item => item.name === 'tray')].budgets[key]).map((key, index) => {
                  return <Card key={`roomServiceTrayItem_${index}`} style={{ paddingBottom: '10px' }}>
                    <Group style={{ display: 'flex', paddingTop: '0px', paddingBottom: '20px' }}>
                      <Title style={{ margin: '0', maxWidth: '100px', fontSize: '15px', color: '#727272'}}>{getNameFromKey(this.state.budgets[this.state.budgets.findIndex(item => item.name === 'tray')].budgets[key].item.name)}</Title>
                      <label style={{position: 'absolute', right: '30px'}}>
                        <Money style={{ fontSize: '13px' }}>{formatCurrency(this.state.budgets[this.state.budgets.findIndex(item => item.name === 'tray')].budgets[key].price.amount, false)}</Money>
                        <BriefingInfo style={{ fontSize: '10px', listStyle: 'none' }}>valor por bandeja</BriefingInfo>
                      </label>
                    </Group>
                    <BriefingInfo style={{ listStyle: 'none', paddingLeft: '15px' }}>{this.state.budgets[this.state.budgets.findIndex(item => item.name === 'tray')].budgets[key].numberOfItems} bandejas por dia</BriefingInfo>
                    <BriefingInfo style={{ listStyle: 'none', paddingLeft: '15px' }}>R$ {formatCurrency(this.state.budgets[this.state.budgets.findIndex(item => item.name === 'tray')].budgets[key].totalWithoutFee.amount / item.numberOfDays, false)} por dia</BriefingInfo>
                    <BriefingInfo style={{ listStyle: 'none', paddingLeft: '15px' }}><b>Valor total: R$ {formatCurrency(this.state.budgets[this.state.budgets.findIndex(item => item.name === 'tray')].budgets[key].totalWithoutFee.amount, false)}</b> ({item.numberOfDays} dias)</BriefingInfo>
                  </Card>
                })
              }
            </Card>
          }
      </Card>
    )
  }

  isExpired(date) {return moment(date) <= moment()}

  getTotalPrice = () => {
    return this.state.budgets.reduce((total, budget) => {
      if (budget.budgets.length > 0) {
        budget.budgets.forEach(item => {
          if (item.selected) {total += item.totalWithoutFee.amount}
        })
      } else {
        Object.keys(budget.budgets)
          .filter(key => budget.budgets[key])
          .forEach(key => {total += budget.budgets[key].totalWithoutFee.amount})
      }
      return total
    }, 0)
  }

  componentDidMount() {this.loadData()}

  goBack = () => {
    if(this.isValid()) {
      this.setState({
        isLoading: true
      })
      const { history } = this.props
      const { eventId, accountId } = this.props.match.params
      const { api: { base, version, routes: { events } } } = config
      const endpoint = `${base}${version}/budget/foodsAndBeverages/${this.state.budgetId}/accept`
      Post(endpoint, this.generateJSON())
      .then(() => {
        history.push(`/organizer${events}/${eventId}/budget/account/${accountId}`)
      }).catch(err => {
        console.error(err.message)
        this.setState({
          isLoading: false
        })
        alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
      })
    } else {
      alert('Você deve selecionar ao menos um cardápio para cada categoria.')
    }
  }

  generateJSON = () => {
    var chosenBudgets = {}
    this.state.budgets.filter(item => item.budgets.length > 0).forEach(budget => {
      chosenBudgets[budget.name] = {
        budgetId: budget.budgets.filter(item => item.selected)[0].id
      }
    })

    if(this.state.budgets.find(item => item.name === 'roomService')) {
      chosenBudgets.roomService = {
        budgetId: true
      }
      chosenBudgets.tray = {
        budgetId: true
      }
      // if (this.state.budgets.find(item => item.name === 'roomService').budgets.tray) {
      //   chosenBudgets.tray = {
      //     budgetId: true
      //   }
      // }
    }
    return { chosenBudgets }
  }

  isValid = () => {
    let isValid = true
    this.state.budgets.map(budget => {
      let haveSelected = false
      budget.budgets.length > 0 && budget.budgets.map(item => {
        if(item.selected) {
          haveSelected = true
        }
        return item
      })
      if (!haveSelected && budget.budgets.length > 0) {
        isValid = false
      }
      return budget
    })
    return isValid
  }

  itemClick = (id, session) => {
    const budgets = this.state.budgets
    const index = budgets.findIndex(budget => budget.name === session)
    if (index !== -1) {
      const itemIndex = budgets[index].budgets.findIndex(item => item.id === id)
      if (itemIndex !== -1) {
        budgets[index].budgets = budgets[index].budgets.map(item => {
          item.selected = false
          return item
        })
        budgets[index].budgets[itemIndex].selected = true
        this.setState({ budgets })
      }
    }
  }

  render() {
    const {isLoading, budgets} = this.state
    return (
      <Main>
        {isLoading && <Loader />}
        <Header />
        <Content>
          <Title>{this.state.name}</Title>

          <Card style={{padding: '10px', margin: '10px'}}>
            <FABIcon /> Alimentos e bebidas
            <Observations><Span>Observações:</Span> {this.state.observations}</Observations>
          </Card>
          <div style={{ display: 'flex', flexFlow: 'row wrap', alignContent: 'space-between', justifyContent: 'space-between'}}>
            {budgets.filter(item => item.name !== 'tray').map((budget, index) => this.FABCard({ ...budget, key:index }))}
          </div>
        </Content>
        <Conclusion>
          <Total>{formatCurrency(this.getTotalPrice(), false)}</Total>
          <div><Button full onClick={this.goBack}>OK</Button></div>
        </Conclusion>
      </Main>
    )
  }

  loadData = () => {
    this.setState({ isLoading: true })
    const { api: { base, version } } = config
    const { eventId, accountId } = this.props.match.params
    const userType = window.localStorage.getItem('user_type')
    const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
    const endpoint = `${base}${version}${eventEndpoint}/${eventId}/budget/account/${accountId}`
    return Get(endpoint)
      .then(response => {
        const budgets = []
        Object.keys(response.budgets.foodsAndBeverages.mostRecent.menusBudgets).map((key) => {
          let budget = response.budgets.foodsAndBeverages.mostRecent.menusBudgets[key]
          budget.name = key
          if (budget.budgets.length > 0) {
            budget.budgets = budget.budgets.map((item) => {
              item.selected = item.chosen || false
              return item
            })
          }
          Object.keys(response.briefings.foodsAndBeverages).filter(briefingKey => response.briefings.foodsAndBeverages[briefingKey] && response.briefings.foodsAndBeverages[briefingKey].name && response.briefings.foodsAndBeverages[briefingKey].name === key).map((briefingKey) => {
            return budget.briefing = response.briefings.foodsAndBeverages[briefingKey]
          })
          return budgets.push(budget)
        })
        this.setState({
          budgets,
          expireDate: response.budgets.foodsAndBeverages.expireDate,
          name: response.name,
          observations: response.budgets.foodsAndBeverages.mostRecent.observations,
          isLoading: false,
          budgetId: response.budgets.foodsAndBeverages.mostRecent.id
        })
       })
      .catch(e => { this.setState({ isLoading: false }, console.error(e)) })
  }
}

export default FoodAndBeverageDetails
