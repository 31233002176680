function getPrimaryColor () {
  return window.localStorage.getItem('theme_color_primary') || '#009688'
}

function getSecondaryColor () {
  return window.localStorage.getItem('theme_color_secondary') || '#762123'
}

export const defaultTheme = {
  fonts: {
    primary: '"Open Sans", sans-serif',
    secondary: '"Pluto", sans-serif'
  },
  colors: {
    whiteLabel: {
      primary: getPrimaryColor(),
      secondary: getSecondaryColor()
    },
    primary: {
      default: '#009688',
      dark: '#00675B',
      light: '#52C7B8'
    },
    secondary: {
      default: '#762123',
      dark: '#460000',
      light: '#A94E4B'
    },
    neutro: {
      default: '#969696',
      dark: '#212121',
      light: '#F5F5F5'
    },
    ui: {
      positive: '#009688',
      negative: '#E74C3C',
      warning: '#F39C12',
      information: '#C6C6C6',
      edition: '#F39C12'
    },
    text: {
      dark: '#727272',
      darker: '#484848',
      light: '#fff'
    },
    grey: {
      lightest: '#e8e8e8',
      light: '#a5a5a5',
      medium: '#767676'
    },
    orange: { // Usado para warnings, aguardando e outras formas de aviso
      medium: '#F39C12' // TODO: O nome dessas cores deveriam ficar dentro de um contexto;
    },
    validation: {
      valid: '#6495c9',
      invalid: 'red'
    },
    backgrounds: {
      grayish: '#fafafa',
      light: '#fff'
    }
  }
}

export const screens = {
  smallExtra: '480px',
  small: '768px',
  medium: '992px',
  large: '1200px'
}

export const images = {
  fallbackHotel: 'https://storage.googleapis.com/images.evnts.rocks/img/ecommerce/nao_encontrado_hotel.svg',
  fallbackRooms: 'https://storage.googleapis.com/images.evnts.rocks/img/ecommerce/nao_encontrado_hotel.svg'
}
