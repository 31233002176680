/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
/* ACTIONS */
import { loginUser, loginError } from '../../../redux/actions/auth'
/* UTILS */
import { isValidEmail } from '../../../utils/email'
/* I18n */
import i18n from '../../../i18n'
/* UI */
import Input from '../../components/ui/Input'
import Alert from '../../components/ui/Alert'
import Button from '../../components/ui/Button'
/* COMPONENTS */
import Loader from '../../components/Loader'
import VersionBuild from '../../components/VersionBuild'

const FormContent = styled.form`
  display: flex;
  flex-wrap: wrap;
  padding: 10px 10px;
  box-sizing: border-box;
  width: 100%;
`

const PasswordRecovery = styled.p`
  cursor: pointer;
  color: #52C7B8;
  font-size: 14px;
  width: 100%;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  padding: 10px 0px 20px 0px;
  &:hover {
    font-weight: bold;
  }
`

class Form extends Component {
  constructor () {
    super()
    this.state = {
      username: '',
      password: ''
    }
  }

  handlerValue (input) {
    this.setState({
      [input.target.name]: input.target.value.trim()
    })
  }

  validate () {
    return isValidEmail(this.state.username) && this.state.password
  }

  submit (event) {
    event.preventDefault()

    const { username, password } = this.state
    if (this.validate()) {
      this.props.loginUser({ username, password })
      .catch(err => {
        alert(err.errors?.user || 'Usuário ou senhas inválidos!')
      })
    } else {
      this.props.loginError(i18n.t('login.error_empty', this.props.language))
    }
  }

  render () {
    const { language, errorMessage, isFetching, vendors } = this.props
    const { username, password } = this.state
    return (
      <FormContent novalidate onSubmit={this.submit.bind(this)} method='POST'>
        {isFetching ? <Loader /> : null}
        {errorMessage
          ? <Alert type={'negative'}>
            {errorMessage}
          </Alert> : null}
        <Input
          type={'email'}
          label={i18n.t('login.email', { language })}
          placeholder={i18n.t('login.email_placeholder', { language })}
          name={'username'}
          value={username}
          error={(username && !isValidEmail(username)) || errorMessage}
          onChange={this.handlerValue.bind(this)}
        />
        <Input
          type={'password'}
          label={i18n.t('login.password', { language })}
          name={'password'}
          value={password}
          error={errorMessage}
          onChange={this.handlerValue.bind(this)}
        />
        <PasswordRecovery onClick={() => this.props.history.push('/forgot_password')}>
          {i18n.t('login.password_recovery_action', { language })}
        </PasswordRecovery>
        <Button
          type='submit'
          category={'secondary'}
          fontWeight={800}
          full>
          {i18n.t('login.singin_action', { language })}
        </Button>
        {!vendors ? <Button
          onClick={() => this.props.history.push('/signup')}
          type='button'
          category={'secondary'}
          fontWeight={800}
          full
          ghost>
          {i18n.t('login.SIGNUP_action', { language })}
        </Button> : null}
        <VersionBuild />
      </FormContent>
    )
  }
}

function mapStateToProps (state) {
  const { app, auth } = state
  const { language } = app
  const { isAuthenticated, errorMessage, isFetching } = auth
  return {
    language,
    isAuthenticated,
    errorMessage,
    isFetching
  }
}

export default connect(mapStateToProps, { loginUser, loginError })(Form)
