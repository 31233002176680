import React, { Component } from 'react'
import styled from 'styled-components'
import ReactLoading from 'react-loading'
import CardMensagem from './CardMensagem'
import { defaultTheme } from '../styles/themes'
import { Get } from '../../utils/request'

const Container = styled.div`
  background: #fff;
  font-size: 0.85em;
  line-height: 1.6em;
  border-radius: 3px;
  width: 350px;
  max-height: 490px;
  overflow-y: scroll;
  color: #fff;
  box-shadow: 2px 2px 8px #767676;
  border: 1px solid rgba(100, 100, 100, .4);
  position: absolute;
  right: ${props => props.supplier ? '18px' : '55px'};
  top: 50px;
  z-index: 9999;

  &:after {
    content: '';
    width: 0;
    height: 0;
    position: fixed;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
    right: ${props => props.supplier ? '70px' : '110px'};
    top: 41px;
  }
  ::-webkit-scrollbar-track { background-color: #f4f4f4; }
  ::-webkit-scrollbar {
    width: 6px;
    background: #F4F4F4;
  }
  ::-webkit-scrollbar-thumb { background: #dad7d7; }
`
const VerTodos = styled.div`
  height: 100%;
  line-height: 1;
  align-items: center;
  display: flex;
  justify-content: center;
`

const Label = styled.p`
  font-size: 12px;
  font-family: ${defaultTheme.fonts.primary};
  color: ${defaultTheme.colors.text.darker};
  line-height: 1.8;
  padding: 30px;
`

export default class ListagemNotificacoes extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: false,
      incrementaMensagens: 6,
      listaMensagens: [],
      visualizouNovasMensagens: false,
      isLoading: false,
      acabouMensagens: false
    }
  }

  componentDidMount () {
    this.fetchMensagens()
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.onClose && this.props.onClose()
    }
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node
  }

  fetchMensagens = () => {
    Get(`/api/v1/chat/notifications?size=${this.state.incrementaMensagens}`).then((mensagens) => {
      this.setState({ listaMensagens: mensagens, isLoading: false, acabouMensagens: this.state.listaMensagens.length === mensagens.length })
    }).catch(err => {
      console.error('Error: ', err)
    })
  }

  mostraMaisMensagens = (div) => {
    const listagem = div.target
    if ((listagem.scrollHeight - listagem.scrollTop) < (listagem.clientHeight + 2)) {
      if (!this.state.isLoading && !this.state.acabouMensagens) {
        this.setState({ incrementaMensagens: this.state.incrementaMensagens + 6, visualizouNovasMensagens: true, isLoading: true }, () => this.fetchMensagens())
      }
    }
  }

  render () {
    const userType = window.localStorage.getItem('user_type')
    const mensagens = this.state.listaMensagens && this.state.listaMensagens.messages

    return (
      this.props.isOpen && <Container ref={this.setWrapperRef} className='listagem__notificacoes' onScroll={this.mostraMaisMensagens} isOpen={this.state.isOpen} supplier={userType === 'SUPPLIER'}>
        {mensagens && mensagens.length !== 0
          ? mensagens.map((chat, i) => <CardMensagem {...chat} key={i} />)
          : <Label>Não há novas mensagens</Label>}
        {this.state.isLoading && <VerTodos><ReactLoading type={'bubbles'} color='#e8e8e8' width={40} height={40} /></VerTodos>}
      </Container>
    )
  }
}
