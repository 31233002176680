/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
/* STYLES */
import { defaultTheme } from '../../styles/themes'
/* UI */
import Alert from '../../components/ui/Alert'
import Title from '../../components/ui/Title'
import Icons from '../../components/ui/Icons'
import Card from '../../components/ui/Card'
import Button from '../../components/ui/Button'
import MoneyInput from '../../components/ui/MoneyInput'
import Input from '../../components/ui/Input'
import Textarea from '../../components/ui/Textarea'
import Select from '../../components/ui/Select'
import RightBoxInput from '../../components/ui/RightBoxInput'
import Loader from '../../components/Loader'
import BreadCrumb from '../../components/BreadCrumb'
/* COMPONENTS */
import Header from '../../components/Header'
/* UTILS */
import { getNameFromKey } from '../../../utils/transferTexts'
import { Get, Post, Put } from '../../../utils/request'
import config from '../../../middleware/config'

const Main = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #FFF;
  @media (min-width: 768px) {
    background-color: #FAFAFA;
  }
`

const Content = styled.div`
  min-height: 90vh;
  padding: 30px 0px;
  font-family: ${defaultTheme.fonts.secondary};
  width: 70%;
  margin: 0 5%;
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  align-content: start;
`

const SubTitle = styled.h1`
  text-align: left;
  font-size: 15px;
  padding: 20px 10px;
`

const Label = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  box-sizing: border-box;
  padding: 5px 5px 5px 0px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
`

const FlexGroup = styled.div`
  display: flex;
`

const ListagemDividida = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-content: space-between;
  justify-content: space-between;
`

class FormTransfer extends Component {
  state = {
    isLoading: false,
    vehicles: [{
      type: 'EXECUTIVE_CAR',
      capacityWithLuggage: 0,
      capacityWithoutLuggage: 0
    }, {
      type: 'ARMORED_CAR',
      capacityWithLuggage: 0,
      capacityWithoutLuggage: 0
    }, {
      type: 'MINI_VAN',
      capacityWithLuggage: 0,
      capacityWithoutLuggage: 0
    }, {
      type: 'ARMORED_MINI_VAN',
      capacityWithLuggage: 0,
      capacityWithoutLuggage: 0
    }, {
      type: 'VAN',
      capacityWithLuggage: 0,
      capacityWithoutLuggage: 0
    }, {
      type: 'ARMORED_VAN',
      capacityWithLuggage: 0,
      capacityWithoutLuggage: 0
    }, {
      type: 'MINIBUS',
      capacityWithLuggage: 0,
      capacityWithoutLuggage: 0
    }, {
      type: 'BUS',
      capacityWithLuggage: 0,
      capacityWithoutLuggage: 0
    }],
    coordinatorPrices: [{
      languages: ['pt-BR'],
      type: 'MONOLINGUE_PT',
      pricePerDay: {
        amount: 0,
        currency: 'BRL'
      },
      overtimePrice: {
        amount: 0,
        currency: 'BRL'
      }
    }, {
      languages: ['pt-BR', 'en-US'],
      type: 'BILINGUE_PT_EN',
      pricePerDay: {
        amount: 0,
        currency: 'BRL'
      },
      overtimePrice: {
        amount: 0,
        currency: 'BRL'
      }
    }, {
      languages: ['pt-BR', 'es-ES'],
      type: 'BILINGUE_PT_ES',
      pricePerDay: {
        amount: 0,
        currency: 'BRL'
      },
      overtimePrice: {
        amount: 0,
        currency: 'BRL'
      }
    }],
    onboardServices: {
      services: [{
        name: 'water',
        type: '', // BOTTLE / CUP
        quantity: 0,
        price: {
          amount: 0,
          currency: 'BRL'
        }
      }],
      lunchBoxes: [{
        name: '',
        price: {
          amount: 0,
          currency: 'BRL'
        },
        description: ''
      }]
    },
    fees: [{
      name: 'serviceFee',
      amount: 0,
      type: 'PERCENTAGE'
    }, {
      name: 'otherFees',
      amount: 0,
      type: 'PERCENTAGE'
    }],
    termsAndConditions: [{
      type: 'GENERAL_CONDITIONS',
      description: ''
    }, {
      type: 'NO_SHOW_POLICIES',
      description: ''
    }, {
      type: 'CANCELLATION_POLICIES',
      description: ''
    }]
  }

  componentDidMount () {
    this.loadData()
  }

  loadData = () => {
    this.setState({
      isLoading: true
    }, () => {
      const { api: { base, version, routes: { transfer } } } = config
      const token = window.localStorage.getItem('user_token')
      const id = jwtDecode(token).accountId
      const endpoint = `${base}${version}${transfer}/account/${id}`
      Get(endpoint).then((result) => {
        var transfer = result[0]
        this.setState({
          ...transfer,
          isLoading: false
        })
      }).catch(err => {
        console.error(err)
        this.setState({
          isLoading: false
        })
      })
    })
  }

  submit = () => {
    if (this.verificaSeFaltaAlgumCampo()) return alert('Preencha todos os campos obrigatórios.')
    this.setState({
      isLoading: true
    }, () => {
      const { history } = this.props
      const { api: { base, version, routes: { transfer } } } = config
      var data = this.state
      if (this.state.id) {
        const endpointPut = `${base}${version}${transfer}/${this.state.id}`
        Put(endpointPut, data).then(() => {
          return history.push(`/`)
        }).catch(err => {
          console.error(err.message)
          this.setState({
            isLoading: false
          })
          alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
        })
      } else {
        const endpoint = `${base}${version}${transfer}`
        Post(endpoint, data).then(() => {
          return history.push(`/`)
        }).catch(err => {
          console.error(err.message)
          this.setState({
            isLoading: false
          })
          alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
        })
      }
    })
  }

  handleVehicle = (input, type) => {
    const { vehicles } = this.state
    var vehicleIndex = vehicles.findIndex((obj => obj.type === input.target.name))
    if (vehicleIndex !== -1) {
      vehicles[vehicleIndex][type] = input.target.value
      this.setState({ vehicles })
    }
  }

  handleCoordinator = (input, type) => {
    const { coordinatorPrices } = this.state
    var coordinatorIndex = coordinatorPrices.findIndex((obj => obj.type === input.target.name))
    if (coordinatorIndex !== -1) {
      coordinatorPrices[coordinatorIndex][type].amount = input.target.value
      this.setState({ coordinatorPrices })
    }
  }

  handleServices = (input, type) => {
    const { onboardServices } = this.state
    var serviceIndex = onboardServices.services.findIndex((obj => obj.name === input.target.name))
    if (serviceIndex !== -1) {
      onboardServices.services[serviceIndex][type] = input.target.value
      this.setState({ onboardServices })
    }
  }

  handleServicesAmount = (input, type) => {
    const { onboardServices } = this.state
    var serviceIndex = onboardServices.services.findIndex((obj => obj.name === input.target.name))
    if (serviceIndex !== -1) {
      onboardServices.services[serviceIndex][type].amount = input.target.value
      this.setState({ onboardServices })
    }
  }

  handleLunchBox = (input, type, index) => {
    const { onboardServices } = this.state
    onboardServices.lunchBoxes[index][type] = input.target.value
    this.setState({ onboardServices })
  }

  handleLunchBoxAmount = (input, type, index) => {
    const { onboardServices } = this.state
    onboardServices.lunchBoxes[index][type].amount = input.target.value
    this.setState({ onboardServices })
  }

  handleFees = (input, type) => {
    const { fees } = this.state
    var feeIndex = fees.findIndex((obj => obj.name === input.target.name))

    if (feeIndex !== -1) {
      fees[feeIndex][type] = input.target.value
      this.setState({ fees })
    }
  }

  handleTerms = (input, type) => {
    const { termsAndConditions } = this.state
    var termsIndex = termsAndConditions.findIndex((obj => obj.type === input.target.name))
    if (termsIndex !== -1) {
      termsAndConditions[termsIndex][type] = input.target.value
      this.setState({ termsAndConditions })
    }
  }

  verificaSeFaltaAlgumCampo = () => {
    const isNotValidVehicles = this.state.vehicles.some(vehicle => !(vehicle.capacityWithLuggage > 0) || !(vehicle.capacityWithoutLuggage > 0))
    const isNotValidCoordinatorPrices = this.state.coordinatorPrices.some(coordinator => !(coordinator.pricePerDay.amount > 0) || !(coordinator.overtimePrice.amount > 0))
    const isNotValidServices = this.state.onboardServices.services.some(service => !service.type || !(service.quantity > 0) || !(service.price.amount > 0))
    const isNotValidLunchBoxes = this.state.onboardServices.lunchBoxes.some(lunchBox => !lunchBox.description || !lunchBox.name || !(lunchBox.price.amount > 0))
    return (isNotValidVehicles || isNotValidCoordinatorPrices || isNotValidServices || isNotValidLunchBoxes)
  }

  novoLunchBox = () => {
    const { onboardServices } = this.state
    onboardServices.lunchBoxes.push({
      name: '',
      price: {
        amount: 0,
        currency: 'BRL'
      },
      description: ''
    })
    this.setState({ onboardServices })
  }

  removeLunchBoxPelaIndex = (input, index) => {
    input.preventDefault()
    const { onboardServices } = this.state
    onboardServices.lunchBoxes.splice(index, 1);
    this.setState({ onboardServices })
  }

  render () {
    const { isLoading } = this.state
    return (
      <Main>
        {isLoading ? <Loader /> : null}
        <Header />
        <BreadCrumb options={
          [
            { link: '/', name: 'Home' },
            { name: 'Cadastro de transfer' }
          ]
        } />
        <Content>
          <Title style={{ padding: '20px 10px' }}>
            Cadastro de transfer
          </Title>
          <FlexGroup>
            <Icons
              icon={'exclamation-circle'}
              color={defaultTheme.colors.grey.medium}
              width={30}
              style={{ margin: 'auto' }}
              height={15} />
            <Label>
              Os valores informados aqui são apenas para referência. Valores reais serão enviados por você evento a evento, de acordo com os trechos selecionados pelos solicitantes.
            </Label>
          </FlexGroup>
          {this.state.error
            ? <Alert type={'negative'}>
              {this.state.error}
            </Alert> : null}
          <Card>
            <SubTitle>
              Quais veículos você oferece e a capacidade?
            </SubTitle>
            <ListagemDividida>
              {this.state.vehicles.map(veiculo => (
                <Card key={veiculo.type} style={{ width: 'calc((100% / 2) - 10px)' }} label={getNameFromKey(veiculo.type)}>
                  <FlexGroup>
                    <RightBoxInput
                      placeholder={'0'}
                      label={'Capacidade com bagagem'}
                      rightBox='pessoas'
                      type='number'
                      isRequired
                      name={veiculo.type}
                      error={!(veiculo.capacityWithLuggage > 0)}
                      defaultValue={veiculo.capacityWithLuggage}
                      value={veiculo.capacityWithLuggage}
                      onChange={(input) => this.handleVehicle(input, 'capacityWithLuggage')}
                      style={{ width: '100%' }} />

                    <RightBoxInput
                      placeholder={'0'}
                      label={'Capacidade sem bagagem'}
                      rightBox='pessoas'
                      type='number'
                      isRequired
                      name={veiculo.type}
                      error={!(veiculo.capacityWithoutLuggage > 0)}
                      defaultValue={veiculo.capacityWithoutLuggage}
                      value={veiculo.capacityWithoutLuggage}
                      onChange={(input) => this.handleVehicle(input, 'capacityWithoutLuggage')}
                      style={{ width: '100%' }} />
                  </FlexGroup>
                </Card>
              ))
              }
            </ListagemDividida>

            <SubTitle>
              Coordenador / receptivo
            </SubTitle>
            <ListagemDividida>
              {this.state.coordinatorPrices.map(coordinator => (
                <Card key={coordinator.type} style={{ width: 'calc((100% / 2) - 10px)' }} label={'Coordenador / receptivo ' + getNameFromKey(coordinator.type)}>
                  <FlexGroup>
                    <MoneyInput
                      type={'text'}
                      label={'Valor do profissional (por dia)'}
                      isRequired
                      placeholder={'0,00'}
                      error={!(coordinator.pricePerDay.amount > 0)}
                      value={coordinator.pricePerDay.amount}
                      onChange={(input) => this.handleCoordinator(input, 'pricePerDay')}
                      name={coordinator.type}
                    />
                    <MoneyInput
                      type={'text'}
                      label={'Valor da hora extra (por hora)'}
                      isRequired
                      placeholder={'0,00'}
                      error={!(coordinator.overtimePrice.amount > 0)}
                      value={coordinator.overtimePrice.amount}
                      onChange={(input) => this.handleCoordinator(input, 'overtimePrice')}
                      name={coordinator.type}
                    />
                  </FlexGroup>
                </Card>
              ))
              }
            </ListagemDividida>

            <SubTitle>
              Serviço de bordo
            </SubTitle>

            {this.state.onboardServices.services.map(servico => (
              <Card key={servico.name} label={getNameFromKey(servico.name)}>
                <FlexGroup>
                  {servico.name === 'water' && <Select
                    isRequired
                    label={'Tipo'}
                    name={servico.name}
                    value={servico.type}
                    error={!servico.type}
                    onChange={(input) => this.handleServices(input, 'type')}>
                    <option value={''}>Selecione</option>
                    <option value={'BOTTLE'}>{getNameFromKey('BOTTLE')}</option>
                    <option value={'CUP'}>{getNameFromKey('CUP')}</option>
                  </Select>}
                  <RightBoxInput
                    placeholder={'0'}
                    label={'Quantidade (em ml)'}
                    rightBox='ml'
                    type='number'
                    isRequired
                    name={servico.name}
                    error={!(servico.quantity > 0)}
                    defaultValue={servico.quantity}
                    onChange={(input) => this.handleServices(input, 'quantity')}
                    value={servico.quantity}
                    style={{ width: '100%' }} />
                  <MoneyInput
                    type={'text'}
                    label={'Valor (por unidade)'}
                    isRequired
                    placeholder={'0,00'}
                    name={servico.name}
                    error={!(servico.price.amount > 0)}
                    value={servico.price.amount}
                    onChange={(input) => this.handleServicesAmount(input, 'price')} />
                </FlexGroup>
              </Card>
            ))}
            <Card label={'Lunchbox'}>
              {this.state.onboardServices.lunchBoxes.map((lunchBox, index) => (
                <Card key={index} label={'Lunchbox ' + ('0' + (index + 1)).slice(-2)}>
                  {this.state.onboardServices.lunchBoxes.length > 1 && <Button
                    type='submit'
                    category={'secondary'}
                    fontWeight={800}
                    onClick={(input) => this.removeLunchBoxPelaIndex(input, index)}
                    style={{ position: 'absolute', right: '0', top: '10px', width: '100px' }}
                    ghost
                    small
                    full>
                    Excluir
                    </Button>}
                  <FlexGroup>
                    <Input
                      type={'text'}
                      label={'Nome do lunchbox'}
                      placeholder={'Digite o nome do seu lunchbox'}
                      name={'name'}
                      width={'70%'}
                      error={!lunchBox.name}
                      value={lunchBox.name}
                      onChange={(input) => this.handleLunchBox(input, 'name', index)}
                      isRequired />
                    <MoneyInput
                      type={'text'}
                      label={'Valor (por unidade)'}
                      isRequired
                      containerStyle={{ width: '30%' }}
                      placeholder={'0,00'}
                      name={'price'}
                      error={!(lunchBox.price.amount > 0)}
                      value={lunchBox.price.amount}
                      onChange={(input) => this.handleLunchBoxAmount(input, 'price', index)} />
                  </FlexGroup>
                  <Textarea
                    type={'text'}
                    label={'Descrição dos itens do lunchbox'}
                    placeholder={'Digite todos os itens inclusos neste lunchbox'}
                    error={!lunchBox.description}
                    value={lunchBox.description}
                    isRequired
                    onChange={(input) => this.handleLunchBox(input, 'description', index)}
                    name={'description'} />
                </Card>
              ))
              }
              <Link style={{ color: defaultTheme.colors.primary.default, fontSize: '15px', fontFamily: defaultTheme.fonts.primary, margin: 'auto', paddingRight: '5px' }} to={'#'} onClick={this.novoLunchBox}>
                + adicionar mais um lunchbox
              </Link>
            </Card>

            <SubTitle>
              Taxas
            </SubTitle>
            <ListagemDividida style={{ justifyContent: 'start' }}>
              {this.state.fees.map(taxa => (
                <Card key={taxa.name} style={{ width: 'calc((100% / 4) - 10px)', margin: '0 10px' }} label={getNameFromKey(taxa.name)}>
                  <RightBoxInput
                    placeholder={'0'}
                    label={'% da taxa'}
                    rightBox='%'
                    type='number'
                    name={taxa.name}
                    style={{ width: '100%' }}
                    defaultValue={taxa.amount}
                    value={taxa.amount}
                    onChange={(input) => this.handleFees(input, 'amount')}
                  />
                </Card>
              ))
              }
            </ListagemDividida>
          </Card>
          <Card label={'Condições gerais para contratação'}>
            {this.state.termsAndConditions.map(termos => (
              <Textarea
                key={termos.type}
                type={'text'}
                label={getNameFromKey(termos.type)}
                placeholder={getNameFromKey('DESCRIPTION_' + termos.type)}
                name={termos.type}
                defaultValue={termos.description}
                value={termos.description}
                onChange={(input) => this.handleTerms(input, 'description')}
              />
            ))}
          </Card>
          <Button
            type='submit'
            category={'secondary'}
            fontWeight={800}
            onClick={this.submit}
            full>
            Cadastrar
          </Button>
        </Content>
      </Main>
    )
  }
}

export default FormTransfer
