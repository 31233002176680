import { SIGNUP_REQUEST, SIGNUP_FAILURE, SIGNUP_SUCCESS } from '../actions/signUp'
export default function signUp (state = {
  company: false
}, action) {
  switch (action.type) {
    case SIGNUP_REQUEST:
    return Object.assign({}, state, {
      isFetching: true,
      company: action.company
    })
    case SIGNUP_FAILURE:
    return Object.assign({}, state, {
      isFetching: false,
      errorMessage: action.message
    })
    case SIGNUP_SUCCESS:
    return Object.assign({}, state, {
      isFetching: false,
      company: action.company
    })
    default:
      return state
  }
}
