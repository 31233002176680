/* API */
import config from '../../../../middleware/config'
/* LIBS */
import React, { Component } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { formatCurrency } from '../../../../utils/money'
import { defaultTheme } from '../../../styles/themes'
import { Get } from '../../../../utils/request'
import Loader from '../../../components/Loader'
import * as moment from 'moment'

/* COMPONENTS */
import Header from '../../Header'
import Body from '../../../components/Body'
import Options from '../../../components/ui/Options'
import Select from '../../../components/ui/Select'
import Main from '../../../components/Main'
import Money from '../../../components/ui/Money'
import Button from '../../../components/ui/Button'
import Icons from '../../../components/ui/Icons'
import Card from '../../../components/ui/Card'
import Tabs from '../../../components/ui/Tabs'
import ItemsMenuLateral from '../../../components/SidebarItems/ItemsMenuLateral'
import DetalheIndisponibilidade from './DetalheIndisponibilidade'
import Modal from '../../../components/Modal'
import TabelaComparativa from '../../../Organizer/Events/TabelaComparativa'
import TabelaAuditoria from '../../../Organizer/Events/TabelaComparativa/TabelaAuditoria'
import TabelaComparativaTransfer from '../../../Organizer/Events/TabelaComparativa/TabelaComparativaTransfer'
import TabelaComparativaOutrosServicos from '../../../Organizer/Events/TabelaComparativa/TabelaComparativaOutrosServicos'
import { formatDate, formatDateWithTime } from '../../../../utils/dates'
import { ehConsultor, ehAdmin } from '../../../../utils/tiposUsuario'

import IndicacaoFornecedor from '../../../../screens/Organizer/Events/IndicacaoFornecedor/index'
import Subtitle from '../../../components/ui/Subtitle'

const FallBackImage = () => <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 231.12 155.21" style={{ fill: defaultTheme.colors.whiteLabel.primary }}>
  <title>nao_encontrado_</title>
  <g id="CEmoIY.tif">
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M68.87,152.63H76v-1.38q0-47.32,0-94.65a2,2,0,0,1,2.32-2.29q20.11,0,40.25,0c1.64,0,2.29.66,2.29,2.31q0,23.08,0,50V108h1.3q15.93,0,31.86,0c1.89,0,2.68.5,2.68,2.73v41.87h9.45V95.65h-9.42v8.89h-3.15V95.66h-9.42v7h-2.92V86.79c-1.61,0-4,0-5.48,0a5.19,5.19,0,0,1-1.41-.22,1.47,1.47,0,0,1-1.1-1.54c0-4.89,0-9.78,0-14.67,0-1.09.81-1.75,2.51-1.76,2.23,0,5.72,0,10.49,0h1.27V63.73c0-1.57.69-2.26,2.28-2.28h2.94l.33-.09V49.07h3.38V61.34a1.33,1.33,0,0,0,.32.09l2.89,0c1.67,0,2.29.64,2.29,2.29s0,3.18,0,4.84h1.27c3.46,0,6.91,0,10.37,0a2.93,2.93,0,0,1,2.63,1V85.75a2.68,2.68,0,0,1-2.32,1h-4.76v65.84h7.08V155H68.87Zm9.83-.06h75.38V110.64H120.8v35h-2.34V56.87H78.74v5.61h25.88v2.76H78.7Zm57.09-81.32V84.42h37.94V71.25Zm8.36,21.36h22V86.86h-22Zm15.28-28.85H150v4.75h9.42Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M111.83,75.9c0,1.22,0,2.44,0,3.66a1.6,1.6,0,0,1-1.8,1.77c-2.41,0-4.81,0-7.22,0A1.74,1.74,0,0,1,101,79.56q0-3.66,0-7.32a1.74,1.74,0,0,1,1.84-1.77q3.6,0,7.22,0a1.6,1.6,0,0,1,1.8,1.77C111.84,73.46,111.83,74.68,111.83,75.9Zm-8.72,3.23h6.53V72.67h-6.53Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M110.09,147.54H86.77v-2.4h23.32Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M149.54,147.53H144v-3.62h5.52Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M131.72,143.89v3.64h-5.5v-3.64Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M144,140.37v-3.58h5.54v3.58Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M131.69,140.42h-5.46v-3.64h5.46Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M149.52,133.3H144v-3.66h5.48Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M126.17,133.25v-3.59h5.55v3.59Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M144,122.29h5.5v3.62H144Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M131.69,126h-5.47V122.3h5.47Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M144,118.79v-3.6h5.54v3.6Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M126.17,118.78V115.2h5.54v3.58Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M140.5,147.55h-5.26V143.9h5.26Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M140.55,136.78v3.6h-5.33v-3.6Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M140.56,129.66v3.59h-5.34v-3.59Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M140.53,122.3v3.63h-5.29V122.3Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M140.51,118.83h-5.28v-3.66h5.28Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M145.74,79.61h-3.63V76h3.63Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M151.2,76V79.6h-3.62V76Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M153,76h3.64v3.62H153Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M158.24,79.6V76h3.62V79.6Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M167.33,79.61H163.7V76h3.63Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M95.73,75.9c0,1.22,0,2.44,0,3.66a1.59,1.59,0,0,1-1.8,1.77c-2.4,0-4.81,0-7.21,0a1.73,1.73,0,0,1-1.84-1.77q-.06-3.66,0-7.32a1.73,1.73,0,0,1,1.83-1.77q3.61,0,7.22,0a1.59,1.59,0,0,1,1.8,1.77C95.74,73.46,95.73,74.68,95.73,75.9ZM87,79.13h6.53V72.67H87Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M111.83,90.15c0,1.22,0,2.44,0,3.66a1.61,1.61,0,0,1-1.8,1.78h-7.22A1.74,1.74,0,0,1,101,93.82q0-3.66,0-7.32a1.75,1.75,0,0,1,1.84-1.78q3.6,0,7.22,0a1.61,1.61,0,0,1,1.8,1.77C111.84,87.71,111.83,88.93,111.83,90.15Zm-8.72,3.23h6.53V86.93h-6.53Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M95.73,90.15c0,1.22,0,2.44,0,3.66a1.61,1.61,0,0,1-1.8,1.78H86.71a1.73,1.73,0,0,1-1.84-1.77q-.06-3.66,0-7.32a1.74,1.74,0,0,1,1.83-1.78q3.61,0,7.22,0a1.6,1.6,0,0,1,1.8,1.77C95.74,87.71,95.73,88.93,95.73,90.15ZM87,93.38h6.53V86.93H87Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M111.83,104.64c0,1.22,0,2.44,0,3.66a1.6,1.6,0,0,1-1.8,1.77c-2.41,0-4.81,0-7.22,0A1.74,1.74,0,0,1,101,108.3q0-3.66,0-7.32a1.76,1.76,0,0,1,1.84-1.78c2.4,0,4.81,0,7.22,0a1.6,1.6,0,0,1,1.8,1.77C111.84,102.2,111.83,103.42,111.83,104.64Zm-8.72,3.23h6.53v-6.46h-6.53Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M95.73,104.64c0,1.22,0,2.44,0,3.66a1.6,1.6,0,0,1-1.8,1.77c-2.4,0-4.81,0-7.21,0a1.73,1.73,0,0,1-1.84-1.77q-.06-3.66,0-7.32A1.74,1.74,0,0,1,86.7,99.2c2.41,0,4.82,0,7.22,0a1.59,1.59,0,0,1,1.8,1.77C95.74,102.2,95.73,103.42,95.73,104.64ZM87,107.87h6.53v-6.46H87Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M111.83,118.89c0,1.22,0,2.44,0,3.66a1.61,1.61,0,0,1-1.8,1.78c-2.41,0-4.81,0-7.22,0a1.75,1.75,0,0,1-1.84-1.77c0-2.44,0-4.89,0-7.33a1.74,1.74,0,0,1,1.84-1.77H110a1.61,1.61,0,0,1,1.8,1.77C111.84,116.45,111.83,117.67,111.83,118.89Zm-8.72,3.23h6.53v-6.46h-6.53Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M95.73,118.89c0,1.22,0,2.44,0,3.66a1.61,1.61,0,0,1-1.8,1.78c-2.4,0-4.81,0-7.21,0a1.74,1.74,0,0,1-1.84-1.77c0-2.44,0-4.89,0-7.33a1.73,1.73,0,0,1,1.83-1.77h7.22a1.6,1.6,0,0,1,1.8,1.77C95.74,116.45,95.73,117.67,95.73,118.89ZM87,122.12h6.53v-6.46H87Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M111.83,133.11c0,1.22,0,2.44,0,3.66a1.61,1.61,0,0,1-1.8,1.78h-7.22a1.75,1.75,0,0,1-1.84-1.77c0-2.44,0-4.89,0-7.33a1.74,1.74,0,0,1,1.84-1.77q3.6,0,7.22,0a1.61,1.61,0,0,1,1.8,1.77C111.84,130.67,111.83,131.89,111.83,133.11Zm-8.72,3.23h6.53v-6.46h-6.53Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M95.73,133.11c0,1.22,0,2.44,0,3.66a1.61,1.61,0,0,1-1.8,1.78H86.71a1.74,1.74,0,0,1-1.84-1.77c0-2.44,0-4.89,0-7.33a1.73,1.73,0,0,1,1.83-1.77q3.61,0,7.22,0a1.6,1.6,0,0,1,1.8,1.77C95.74,130.67,95.73,131.89,95.73,133.11ZM87,136.34h6.53v-6.46H87Z" />
  </g>
  <circle style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} cx="56.91" cy="5.84" r="3.85" />
  <circle style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} cx="208.47" cy="32.86" r="3.85" />
  <circle style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} cx="21.63" cy="150.08" r="3.85" />
  <line style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} x1="170.01" y1="4.85" x2="160.31" y2="4.85" />
  <line style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} x1="165.16" x2="165.16" y2="9.71" />
  <line style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} x1="16.17" y1="31.72" x2="6.46" y2="31.72" />
  <line style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} x1="11.31" y1="26.87" x2="11.31" y2="36.58" />
  <line style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} x1="218.07" y1="147.37" x2="208.36" y2="147.37" />
  <line style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} x1="213.21" y1="142.52" x2="213.21" y2="152.23" />
  <line style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} x1="9.71" y1="102.6" y2="102.6" />
  <line style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} x1="4.85" y1="97.75" x2="4.85" y2="107.46" />
  <path style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} d="M136.68,21.59c-4.65-1.13-3.13-7.42-7.78-8.55s-6.17,5.16-10.82,4S115,9.66,110.31,8.53s-6.18,5.16-10.83,4" />
  <path style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} d="M50.85,67c-4.79,0-4.79-6.47-9.57-6.47S36.49,67,31.71,67s-4.78-6.47-9.57-6.47S17.35,67,12.57,67" />
  <path style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} d="M230.87,103.41c-4.15-2.39-.91-8-5.05-10.38s-7.38,3.21-11.52.82-.91-8-5.06-10.39-7.38,3.21-11.52.81" />
</svg>

const FormContent = styled.form`
  padding: 10px 10px;
  box-sizing: border-box;
  flex-wrap: wrap;
`

const Title = styled.h1`
  font-family: ${defaultTheme.fonts.secondary};
  line-height: 1.2;
  font-size: 1.3em;
  padding-top: 10px;
`

const FixedDiv = styled.div`
  position: fixed;
  top: 46px;
  bottom: 0;
  width: 25%;
  overflow-y: scroll;
  margin-top: 14px;
`

const TitleDisabled = styled.h1`
  font-family: 'Pluto';
  color: #727272;
  line-height: 1.2;
  font-size: 1.3em;
  padding-top: 10px;
`

const CardHotel = styled.div`
  cursor: pointer;
  border-radius: 2px;
  -webkit-box-shadow: 0px 0px 19px 4px rgba(0,0,0,0.22);
  -moz-box-shadow: 0px 0px 19px 4px rgba(0,0,0,0.22);
  box-shadow: 0px 0px 19px 4px rgba(0,0,0,0.22);
  margin-bottom: 20px;
  margin-top: 20px;
  margin-right: 10px;
  position: relative;
  border: ${(props) => props.selected && `2px solid ${defaultTheme.colors.primary.default}`};
  width: calc((100% / 2) - 20px);
  @media (min-width: 600px) {
    width: calc((100% / 2) - 10px);
  }
`
const CardHotelDisabled = styled(CardHotel)`
  -webkit-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.14);
  -moz-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.14);
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.08), 0 0 0 1px rgba(0,0,0,0.14);
  cursor: default;
`

const CapaHotel = styled.div`
  height: 150px;
  background-repeat:   no-repeat;
  background-position: center center;
  background-image: ${props => `url(${props.capa}), url(data:image/svg+xml,${encodeURIComponent(renderToStaticMarkup(<FallBackImage />))})`};
  background-size: ${props => props.capa ? `cover` : `auto`};
  `

const CapaHotelDisabled = styled.div`
  height: 150px;
  opacity: 0.5;
  background-repeat:   no-repeat;
  background-position: center center;
  background-image: ${props => `url(${props.capa}), url(data:image/svg+xml,${encodeURIComponent(renderToStaticMarkup(<FallBackImage />))})`};
  background-size: ${props => props.capa ? `cover` : `auto`};
`


const Action = styled.div`
  text-align: right;
  line-height: 1.2;
  font-size: 0.755rem;
`

const Label = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  box-sizing: border-box;
  /* padding: 5px 5px 5px 0px; */
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
  line-height: 1.5;
`
const LabelDisabled = styled(Label)`
  color: #727272;
  cursor: default;
`

const Linha = styled.div`
  border-top: 1px solid #7272;
  margin: 0 auto;
  margin: 0 10px;
  `

// const Fee = styled.span`
//   color: ${defaultTheme.colors.grey.medium};
//   font-weight: 100;
// `

const LinkShowDetails = styled.a`
  width: 100%;
  text-align: right;
  font-size: 13px;
  color: #009688;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  text-decoration: underline;
  cursor: pointer;
  padding-left: 5px;
`

const Group = styled.div`
padding: 10px;
`
const renderBorderColor = (status) => {
  switch (status) {
    case 'REVIEW':
      return '#F39C12'

    case 'INDISPONIVEL':
      return '#a5a5a5'

    case 'DESCONTO':
      return 'red'

    case 'EDITAVEL':
      return '#2288bb'

    default:
      return '#009688'
  }
}

const Tag = styled.div`
  cursor: pointer;
  padding: 5px 10px;
  font-size: 12px;
  font-family: ${defaultTheme.fonts.primary};
  color: #FAFAFA;
  background-color: ${props => renderBorderColor(props.status)};
  border: none;
  border-radius: 15px;
  position: absolute;
  right: 10px;
  top: 10px;
  text-transform: ${props => props.status === 'DESCONTO' ? 'initial' : 'uppercase'};
`

const PriceRangeTag = styled.div`
  cursor: pointer;
  padding: 5px 10px;
  margin-right: 5px;
  font-size: 12px;
  font-family: ${defaultTheme.fonts.primary};
  color: gray;
  border: 2px solid gray;
  border-radius: 15px;
  width: fit-content;
  text-transform: ${props => props.status === 'DESCONTO' ? 'initial' : 'uppercase'};
`

const isUserReseller = () => {
  return window.localStorage.getItem('user_type') === 'RESELLER'
}

const renderCardAguardandoAprovacao = (hotel) => {
  return <CardHotelDisabled key={hotel.id}>
    <CapaHotelDisabled capa={hotel.capa} style={{ filter: 'grayscale(1)' }} />
    <Group>
      <TitleDisabled>{hotel.nome}</TitleDisabled>
      <LabelDisabled>{hotel.location.address}</LabelDisabled>
      <LabelDisabled>{hotel.location.city} - {hotel.location.province}</LabelDisabled>
    </Group>
    <Linha />
    <Group>
      <LabelDisabled style={{ textAlign: 'center', padding: '10px 0' }}> aguardando aprovação</LabelDisabled>
    </Group>
  </CardHotelDisabled>
}

// }

const renderCardAguardandoOrcamento = (hotel) => {
  return <CardHotelDisabled key={hotel.id}>
    <CapaHotelDisabled capa={hotel.capa} style={{ filter: 'grayscale(1)' }} />
    <Group>
      <TitleDisabled>{hotel.nome}</TitleDisabled>
      <LabelDisabled>{hotel.location.address}</LabelDisabled>
      <LabelDisabled>{hotel.location.city} - {hotel.location.province}</LabelDisabled>
    </Group>
    <Linha />
    <Group>
      <LabelDisabled style={{ textAlign: 'center', padding: '10px 0' }}> aguardando orçamento</LabelDisabled>
    </Group>
  </CardHotelDisabled>
}

const renderCardIndisponibilidade = (hotel, visualizaDetalhe) => {
  var textoCard = (hotel.unavailability.reason === 'OTHER')
    ? hotel.unavailability.details
    : reasons(hotel.unavailability.reason)
  var verDetalhes = textoCard.length > 55 || (hotel.unavailability.reason !== 'OTHER' && hotel.unavailability.details)

  if (verDetalhes) textoCard = textoCard.substr(0, 55) + '...'
  var motivo = reasons(hotel.unavailability.reason)
  var detalhes = hotel.unavailability.details || 'Não informado'

  return <CardHotelDisabled key={hotel.id}>
    <CapaHotelDisabled capa={hotel.capa} />
    <Tag status={'INDISPONIVEL'}>Indisponível</Tag>

    <Group>
      <TitleDisabled>{hotel.nome}</TitleDisabled>
      <LabelDisabled>{hotel.location.address}</LabelDisabled>
      <LabelDisabled>{hotel.location.city} - {hotel.location.province}</LabelDisabled>
    </Group>
    <Linha />
    <Group>
      <LabelDisabled style={{ fontWeight: 'bold', display: 'inline', fontSize: '14px', marginRight: '5px' }}>Motivo:</LabelDisabled>
      <LabelDisabled style={{ display: 'inline', fontSize: '14px' }}>
        {textoCard}
        {verDetalhes && <LinkShowDetails onClick={() => visualizaDetalhe(hotel.nome, motivo, detalhes)}>ver detalhes</LinkShowDetails>}
      </LabelDisabled>
    </Group>
  </CardHotelDisabled>
}

const pegaValorDefaultTransfer = (budget) => {
  return budget.transfer && budget.transfer[0] && budget.transfer[0][0] && budget.transfer[0][0].requests.filter(item => item.hasAvailability).reduce((soma, budget) => {
    soma += parseInt(budget.vehicles.reduce((somaVeiculo, veiculo) => {
      somaVeiculo += veiculo.price.amount
      return somaVeiculo
    }, 0))
    soma += parseInt(budget.onboardServices.services.reduce((somaServico, servico) => {
      somaServico += servico.price.amount
      return somaServico
    }, 0))
    soma += parseInt(budget.onboardServices.lunchBoxes.reduce((somaServico, lunchBox) => {
      somaServico += lunchBox.price.amount
      return somaServico
    }, 0))
    soma += parseInt(budget.coordinatorPrices ? budget.coordinatorPrices.pricePerDay.amount : 0)
    return soma
  }, 0)
}

const permiteExibirTagEdicao = (hotel) => {
  return hotel && hotel.editable && !hotel.statusReview && !(hotel.descontoDaContraProposta > 0)
}

const renderCardComOrcamento = (hotel) => {
  return <CardHotel key={hotel.id} selected={hotel.selected} onClick={hotel.onClick}>
    {hotel.selected && <Group style={{ marginLeft: '-2px', marginRight: '-2px', marginTop: '-22px', fontSize: '14px', backgroundColor: defaultTheme.colors.primary.default, height: '30px' }}>
      <Label style={{ marginTop: '-6px', fontWeight: 400, color: 'white' }}>Fornecedor selecionado</Label>
    </Group>}
    {!hotel.selected && permiteExibirTagEdicao(hotel) && <Tag status={'EDITAVEL'}>Habilitado para edição</Tag> }
    {hotel.statusReview && <Tag status={'REVIEW'}>Revisão</Tag>}
    {hotel.descontoDaContraProposta > 0 && <Tag status={'DESCONTO'}>
      <span style={{ fontWeight: 600 }}>{hotel.descontoDaContraProposta}%</span> de desconto
          </Tag>}
    <CapaHotel capa={hotel.capa} />
    <Group style={{ padding: '10px' }}>
      <Title style={{ fontSize: '18px' }}>{hotel.nome}</Title>
      <Label>{hotel.location.address}</Label>
      <Label>{hotel.location.city} - {hotel.location.province}</Label>
      {hotel.tags && hotel.tags.filter(tag => tag.type === 'PRICE_RANGE').length > 0 && <Group style={{ display: 'flex', padding: '10px 0px' }}>
        {hotel.tags.filter(tag => tag.type === 'PRICE_RANGE').map(tag => (
          <PriceRangeTag>
            <span style={{ fontWeight: 600 }}>{tag.name}</span>
          </PriceRangeTag>
        ))}
      </Group>}
      <Action>
        <Label style={{ textAlign: 'right' }}>a partir de</Label>
        {!hotel.mostraTransfer && hotel.precoAntesDaContraProposta && <Label style={{ textAlign: 'right', color: 'red', textDecoration: 'line-through', fontSize: '13px' }}>{hotel.precoAntesDaContraProposta}</Label>}
        {!hotel.mostraTransfer && <Money>{hotel.preco}</Money>}
        {hotel.mostraTransfer && <Money>{formatCurrency(precoSalvoNaMemoria(hotel.id, hotel.eventId) || pegaValorDefaultTransfer(hotel.budgets), false)}</Money>}
      </Action>
      <Action>
        <Label style={{ textAlign: 'right' }}></Label>
      </Action>
    </Group>
  </CardHotel>
}

const precoSalvoNaMemoria = (supplierId, eventId) => {
  var transfer_info = JSON.parse(window.localStorage.getItem('transfer_info'))
  return (transfer_info && transfer_info[eventId] && transfer_info[eventId][supplierId] && transfer_info[eventId][supplierId].total) || 0
}

const reasons = (chave) => {
  const valores = {
    APT_CAPACITY: 'Não temos capacidade de apartamentos',
    ROOMS_PERIOD: 'Não temos salas disponíveis para o período',
    APT_ROOMS_PERIOD: 'Não temos disponibilidade para a data solicitada em sala e/ou hospedagem',
    SEGMENT: 'Já temos outro cliente do mesmo segmento no período',
    NOT_INTERESTED: 'Nao tenho interesse em atender esse evento',
    OTHER: 'Outros'
  }
  return (valores[String(chave).toUpperCase()] || '')
}

function Hoteis (props) {

  return props.listaDeHoteis.sort((a, b) => {
    if (a.precoNum && b.precoNum) return a.precoNum - b.precoNum
    if (a.precoNum && !b.precoNum) return -1
    if (!a.precoNum && b.precoNum) return 1

    if ((a.auditStatus === null || a.auditStatus === false)) return -1
    if (a.unavailability && b.waitingBudget) return -1
    if (a.waitingBudget && b.unavailability) return 1
    if (a.unavailability && b.unavailability) {
      return new Date(a.unavailability.created) - new Date(b.unavailability.created)
    }

    return 0
  }).sort(item => item.selected && -1).map(hotel => {
    if (hotel.unavailability) {
      return renderCardIndisponibilidade(hotel, props.visualizaDetalhe)
    } else if (hotel.waitingBudget) {
      return renderCardAguardandoOrcamento(hotel)
    } else if (!hotel.waitingBudget) {
      if (props.isOrganizerResellerBudget() && !hotel.auditStatus) {
        if (isUserReseller()) {
          return renderCardComOrcamento(hotel) //visão - reseller
        } else {
          return renderCardAguardandoAprovacao(hotel)// visão - organizer de um reseller
        }
      }
    }
    return renderCardComOrcamento(hotel)
  })
}

class ListagemHoteis extends Component {
  constructor () {
    super()
    this.state = {
      owner: {},
      hoteisSelecionados: [],
      hotels: [],
      isLoading: true,
      modalState: false,
      verDetalhes: false,
      historicoModal: false,
      fornecedorSelecionado: '',
      modalComparativoStatus: false,
      modalComparativoTransfer: false,
      modalComparativoOutrosServicos: false,
      toggleSelected: 0,
      hasReceivedTransferBudget: false,
      hasReceivedOtherServicesBudget: false
    }
  }

  componentDidMount () {
    this.fetchHotelsBudgets()
    this.fetchEventData()
    this.loadHistory()
  }

  verificaSeOEventoPertenceAoConsultor = (consultantId, userId) => {
    const loggedUserId = window.localStorage.getItem('user')
    return consultantId !== userId && loggedUserId === consultantId
  }

  isOrganizerResellerBudget = () => {
    return this.state.organizerReseller
  }

  fetchEventData = () => {
    const { eventId } = this.props.match.params
    const { api: { base, version } } = config
    const userType = window.localStorage.getItem('user_type')
    const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
    const newEndpoint = `${base}${version}${eventEndpoint}/${eventId}`
    Get(newEndpoint).then((response) => {
      if (!response) {
        this.setState({ errorMessage: response.message })
        return Promise.reject(response)
      } else {
        this.setState({
          readOnly: this.verificaSeOEventoPertenceAoConsultor(response.consultantId, response.userId),
          eventStartDate: response.start,
          eventEndDate: response.end,
          eventName: response.name,
          eventLocation: response.location,
          eventFriendlyId: response.internalId || response.friendlyId,
          eventCreated: response.created,
          eventEmergency: response.emergency,
          startTime: response.startTime,
          endTime: response.endTime,
          numberOfParticipants: response.numberOfParticipants,
          services: response.services,
          organizerReseller: !!response.resellerId
        })
      }
    })
  }

  loadHistory = () => {
    const { api: { base, version } } = config
    const { eventId } = this.props.match.params
    const userType = window.localStorage.getItem('user_type')
    const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
    const endpoint = `${base}${version}${eventEndpoint}/${eventId}/counter-proposals`

    return Get(endpoint).then(response => {
      response.map(services => services.map(service => {
        service.counterProposals = service.counterProposals.reduce((counterProposals, item) => {
          if (item.requester === 'ORGANIZER' && (item.supplierStatus === 'ACCEPTED' || item.supplierStatus === 'REFUSED')) {
            counterProposals.push(item)
            var duplicata = {
              acceptedDate: item.acceptedDate,
              date: item.acceptedDate || item.date,
              initialPrice: item.initialPrice,
              justification: item.justification,
              requestedPrice: item.requestedPrice,
              requester: 'SUPPLIER',
              duplicata: true,
              supplierStatus: item.supplierStatus,
              valid: item.valid
            }
            counterProposals.push(duplicata)
          } else {
            counterProposals.push(item)
          }
          return counterProposals
        }, []).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
        return service
      }))
      this.setState({ counterProposals: response })
    }).catch(e => {
      console.error(e)
    })
  }

  getObservationsForCheapest (supplier) {
    return Object.keys(supplier.budgets)
      .reduce((observations, service) => {
        const budgets = supplier.budgets[service]
        const cheapestBudget = this.getCheapestBudgetFromBudgets(budgets)
        if (cheapestBudget) observations.push(cheapestBudget.observations)
        return observations
      }, [])
  }

  getCheapestBudget (supplier) {
    return Object.keys(supplier.budgets).reduce((total, service) => {
      const budgets = supplier.budgets[service]
      const cheapestBudget = this.getCheapestBudgetFromBudgets(budgets)
      const cheapestBudgetValue = (cheapestBudget && cheapestBudget.cheapestTotalPrice && cheapestBudget.cheapestTotalPrice.amount) || 0
      return total + cheapestBudgetValue
    }, 0)
  }

  getHigherInitialPrice (budgets) {
    return budgets.reduce((cheapest, current) => {
      if (!current.counterProposals || current.counterProposals.length === 0) return cheapest
      return current.counterProposals[0].initialPrice >= cheapest
        ? current.counterProposals[0].initialPrice
        : cheapest
    }, 0)
  }

  pegaDescontosDeTodosOsServicos = (supplier) => {
    return Object.keys(supplier.budgets)
      .reduce((soma, service) => {
        const budgets = supplier.budgets[service]
        const cheapestBudget = this.getCheapestBudgetFromBudgets(budgets)
        soma += (this.getHigherInitialPrice(budgets) > 0 ? (this.getHigherInitialPrice(budgets) - (cheapestBudget ? cheapestBudget.cheapestTotalPrice.amount : 0)) : 0)
        return soma
      }, 0)
  }

  getCheapestBudgetFromBudgets (budgets) {
    return budgets.reduce((cheapest, current) => {
      if (!cheapest) return current
      if (!current.cheapestTotalPrice) return cheapest
      return cheapest.cheapestTotalPrice.amount >= current.cheapestTotalPrice.amount
        ? current
        : cheapest
    }, undefined)
  }

  verificaSeTodosOsServicosTemDisponibilidade = (supplier) => {
    return Object.keys(supplier.budgets).some(service => {
      const budgets = supplier.budgets[service]
      if (budgets.length === 0) return true
      const cheapestBudget = this.getCheapestBudgetFromBudgets(budgets)
      return cheapestBudget && cheapestBudget.hasAvailability
    })
  }

  getSupplierNames () {
    const { services } = this.state.event || {}
    if (!services) return ''
    if (services.room.choosedSupplier) return services.room.choosedSupplier.name
    if (services.foodsAndBeverages.choosedSupplier) return services.foodsAndBeverages.choosedSupplier.name
    if (services.lodging.choosedSupplier) return services.lodging.choosedSupplier.name
    return ''
  }

  getTransferSupplierName () {
    const { services } = this.state.event || {}
    if (!services) return ''
    if (services.transfer.choosedSupplier) return services.transfer.choosedSupplier.name
    return ''
  }

  getOutrosServicosSupplierName () {
    const { services } = this.state.event || {}
    if (!services) return ''
    if (services.otherServices.choosedSupplier) return services.otherServices.choosedSupplier.name
    return ''
  }

  // isExisteHotelComOrcamentoAprovado () {
  //   return this.state.hotels.some(hotel => (hotel.auditStatus))
  // }

  filtraFornecedores = (hotel, mostraTransfer, mostraOutrosServicos) => {
    if(mostraTransfer) {
      return hotel.services.some(item => item === 'TRANSFER')
    } else if(mostraOutrosServicos) {
      return hotel.services.some(item => item === 'OTHER_SERVICES')
    } else {
      return !hotel.services.some(item => item === 'TRANSFER') &&
        !hotel.services.some(item => item === 'OTHER_SERVICES') &&
        Object.keys(Object.keys(this.state.services).filter(service =>
          (this.state.services[service].requested) &&
          (mostraTransfer ? hotel.services.some(item => item === 'TRANSFER') : true) &&
          (mostraOutrosServicos ? hotel.services.some(item => item === 'OTHER_SERVICES') : true))
        .some(service => hotel.budgets[service] && hotel.budgets[service].length > 0))
    }
  }

  verificaSeFoiSolicitadoAprovacao = (supplierId) => {
    const { event } = this.state
    return event && event.approval && Object.keys(event.approval.services).some(service => event.approval.services[service].supplierId === supplierId)
  }

  formatSuppliers = (mostraTransfer, mostraOutrosServicos) => {
    const { history } = this.props
    const { api: { routes: { events } } } = config
    const { eventId } = this.props.match.params
    return this.state.services
      ? this.state.hotels.filter(hotel => this.filtraFornecedores(hotel, mostraTransfer, mostraOutrosServicos)).map(supplier => {
        return {
          editable: this.existeOrcamentoHabilitadoEdicao(supplier.budgets),
          statusReview: this.verificaSePossuiServicoEmReview(supplier.budgets),
          location: supplier.location,
          nome: supplier.name,
          capa: supplier.pictures ? supplier.pictures.main : undefined,
          preco: formatCurrency(this.getCheapestBudget(supplier), false),
          precoAntesDaContraProposta: this.pegaDescontosDeTodosOsServicos(supplier) > 0 ? formatCurrency((this.pegaDescontosDeTodosOsServicos(supplier) + this.getCheapestBudget(supplier))) : undefined,
          descontoDaContraProposta: parseFloat((100 * this.pegaDescontosDeTodosOsServicos(supplier)) / (this.pegaDescontosDeTodosOsServicos(supplier) + this.getCheapestBudget(supplier))).toFixed(2),
          precoNum: this.getCheapestBudget(supplier),
          id: supplier.id,
          descricao: this.getObservationsForCheapest(supplier).join('\n'),
          onClick: () => {
            mostraTransfer ?
              (!this.listaDeFornecedoresSelecionados().some(item => item.service === 'transfer' && item.id) &&
                history.push(`/organizer${events}/${eventId}/budget/transfer/${supplier.id}`))
              : (mostraOutrosServicos ? (!this.listaDeFornecedoresSelecionados().some(item => item.service === 'otherServices' && item.id) &&
                history.push(`/organizer${events}/${eventId}/budget/other-services/${supplier.id}`))
                : history.push(`/organizer${events}/${eventId}/budget/account/${supplier.id}`))
          },
          waitingBudget: supplier.waitingBudget,
          unavailability: !supplier.waitingBudget && (
            supplier.unavailability ||
            !this.verificaSeTodosOsServicosTemDisponibilidade(supplier)
          ),
          auditStatus: Object.keys(supplier.budgets).some(servico => {
            if (servico === 'transfer') {
              var transfer = supplier.budgets[servico] && supplier.budgets[servico].length > 0 && supplier.budgets[servico][0]
              var transferAudited = transfer && (transfer.some(budget => budget.auditStatus || budget.auditStatus === null || budget.auditStatus === undefined))
              return transferAudited
            }
            return supplier.budgets[servico].some(budget => budget.auditStatus)
          }),
          mostraTransfer,
          eventId,
          budgets: supplier.budgets,
          organizerResellerBudget: this.isOrganizerResellerBudget(),
          selected: this.listaDeFornecedoresSelecionados().some(item => item.id === supplier.id) || this.verificaSeFoiSolicitadoAprovacao(supplier.id)
        }
      }) : []
  }

  verificaSePossuiServicoEmReview = (budgets) => {
    return Object.keys(budgets).some(service => budgets[service].some(budget => budget.status === 'REVIEW'))
  }

  existeOrcamentoHabilitadoEdicao = (budgets) => {
    return budgets && Object.keys(budgets).some(service => budgets[service].some(budget => budget.editable))
  }

  fetchHotelsBudgets = () => {
    const { api: { base, version } } = config
    const { eventId } = this.props.match.params
    const token = window.localStorage.getItem('user_token')
    const userType = window.localStorage.getItem('user_type')
    const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
    const endpoint = `${base}${version}${eventEndpoint}/${eventId}/budget/place?all_suppliers=true`
    const configFetch = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    }

    window.fetch(endpoint, configFetch)
      .then(response => {
        if (response.status >= 401 && response.status <= 403) window.localStorage.clear()
        if (response.ok) {
          return response.json()
        } else {
          throw new Error(response.statusText)
        }
      }).then((hotels) => {
        if (!hotels.success) {
          return Promise.reject(hotels.message)
        } else {
          var hasReceivedTransferBudget = hotels.data.some(supplier => supplier.budgets && supplier.budgets.transfer && supplier.budgets.transfer.length > 0)
          var hasReceivedOtherServicesBudget = hotels.data.some(supplier => supplier.budgets && supplier.budgets.otherServicesBudgets && supplier.budgets.otherServicesBudgets.length > 0)
          this.setState({ hotels: hotels.data, hasReceivedTransferBudget, hasReceivedOtherServicesBudget })
        }
      }).catch(err => console.error('Error: ', err))

    const newEndPoint = `${base}${version}${eventEndpoint}/${eventId}/budget/fullInfo`
    Get(newEndPoint)
      .then((event) => {
        this.setState({ event: event, isLoading: false, owner: event.owner })
      }).catch(err => {
        this.setState({ isLoading: false })
        console.error(err)
        alert(`Falha ao buscar os dados do evento, favor entrar em contato com nosso suporte`)
      })
  }

  toggle (ev) {
    var checkboxes = document.querySelectorAll('input[type="checkbox"]');
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i] !== ev.target)
        checkboxes[i].checked = ev.target.checked;
    }
  }

  getTitle = () => {
    var services = []
    const { event } = this.state
    const requestedServices = event && event.services ? Object.keys(event.services).filter(service => event.services[service].requested) : []

    if (requestedServices.includes('room')) {
      return 'Local do evento'
    }

    if (requestedServices.includes('lodging')) {
      services.push('Hospedagem')
    }
    if (requestedServices.includes('foodsAndBeverages')) {
      services.push('A&B')
    }
    if (requestedServices.includes('equipments')) {
      services.push('Equipamentos')
    }
    var title = ''
    services.forEach((service, index) => {
      if (index <= (services.length - 3)) {
        title += service + ', '
      } else if (index === (services.length - 2)) {
        title += service + ' e '
      } else {
        title += service
      }
    });
    return title
  }

  eventoCriadoPeloResellerLogado () {
    const evento = this.state && this.state.event
    const loggedUserId = window.localStorage.getItem('user')
    const idResellerEvento = evento.owner && evento.owner.id
    return loggedUserId === idResellerEvento
  }

  obterTextoBotaoCompararAprovar = () => {
    return (isUserReseller() && !this.eventoCriadoPeloResellerLogado())
      ? 'Aprovar Fornecedores'
      : 'Comparar'
  }

  pegaJustificativaDaAprovacao = (servicos) => {
    const { event } = this.state
    return event.approval && Object.keys(event.approval.services).filter(service => servicos.find(item => item === service)).reduce((ultimaJustificativa, service) => {
      if(event.approval.services[service].refusalReason.length > 0) {
        return event.approval.services[service].refusalReason[event.approval.services[service].refusalReason.length - 1].reason
      } else {
        return ultimaJustificativa
      }
    }, '')
  }

  getTabs = () => {
    const obj = []
    const { event } = this.state
    const requestedServices = event && event.services ? Object.keys(event.services).filter(service => event.services[service].requested) : []

    if (requestedServices.includes('room') || requestedServices.includes('lodging') || requestedServices.includes('foodsAndBeverages') || requestedServices.includes('equipments')) {
      obj.push({
        id: obj.length,
        title: this.getTitle(),
        content: () => {
          return <>
            <Group style={{ zIndex: 10, position: 'absolute', right: '0px' }}>
              <Button
                style={{ marginRight: '20px', padding: '10px 30px' }}
                category={'secondary'}
                disabled={this.formatSuppliers().length === 0}
                ghost
                onClick={this.tabelaComparativa}
                fontWeight={800}>
                {this.obterTextoBotaoCompararAprovar()}
              </Button>
            </Group>
            <Subtitle style={{ width: '100%' }} >{this.possuiSala() ? 'Selecione um local para o seu evento' : 'Selecione um fornecedor'}</Subtitle>
            {this.pegaJustificativaDaAprovacao(['lodging', 'room', 'foodsAndBeverages', 'equipments', 'transfer', 'otherServices']) && <Card style={{ border: 'red solid 1px' }}>
              <Group style={{ display: 'flex', padding: '0px' }}>
                <Icons
                  icon={'exclamation-circle'}
                  color={'red'}
                  style={{ margin: 'auto' }}
                  width={30}
                  height={15} />
                <Subtitle style={{ width: '100%', color: 'red', marginBottom: '0px', fontWeight: 600 }}>Reavaliar fornecedor</Subtitle>
              </Group>
              {this.pegaJustificativaDaAprovacao(['lodging', 'room', 'foodsAndBeverages', 'equipments']) && <Subtitle style={{ width: '100%', paddingTop: '10px' }}><span style={{ fontWeight: 600 }}>Justificativa de compras:</span> {this.pegaJustificativaDaAprovacao(['lodging', 'room', 'foodsAndBeverages', 'equipments'])}</Subtitle>}
              {this.pegaJustificativaDaAprovacao(['transfer']) && <Subtitle style={{ width: '100%', paddingTop: '10px' }}><span style={{ fontWeight: 600 }}>Justificativa de compras:</span> {this.pegaJustificativaDaAprovacao(['transfer'])}</Subtitle>}
              {this.pegaJustificativaDaAprovacao(['otherServices']) && <Subtitle style={{ width: '100%', paddingTop: '10px' }}><span style={{ fontWeight: 600 }}>Justificativa de compras:</span> {this.pegaJustificativaDaAprovacao(['otherServices'])}</Subtitle>}
            </Card>}
            <Hoteis listaDeHoteis={this.formatSuppliers()} visualizaDetalhe={this.visualizaDetalhe} isOrganizerResellerBudget={this.isOrganizerResellerBudget} />
          </>
        }
      })
    }


    if (requestedServices.includes('transfer')) {
      obj.push({
        id: obj.length,
        title: 'Transfer',
        content: () => {
          return <>
            <Group style={{ zIndex: 10, position: 'absolute', right: '0px' }}>
              <Button
                disabled={!this.state.hasReceivedTransferBudget}
                style={{ marginRight: '20px', padding: '10px 30px' }}
                category={'secondary'}
                ghost
                onClick={this.tabelaComparativaTransfer}
                fontWeight={800}>
                Comparar
              </Button>
            </Group>
            <Subtitle style={{ width: '100%' }}>Selecione um fornecedor de transfer para o seu evento</Subtitle>
            {this.pegaJustificativaDaAprovacao(['lodging', 'room', 'foodsAndBeverages', 'equipments', 'transfer', 'otherServices']) && <Card style={{ border: 'red solid 1px' }}>
              <Group style={{ display: 'flex', padding: '0px' }}>
                <Icons
                  icon={'exclamation-circle'}
                  color={'red'}
                  style={{ margin: 'auto' }}
                  width={30}
                  height={15} />
                <Subtitle style={{ width: '100%', color: 'red', marginBottom: '0px', fontWeight: 600 }}>Reavaliar fornecedor</Subtitle>
              </Group>
              {this.pegaJustificativaDaAprovacao(['lodging', 'room', 'foodsAndBeverages', 'equipments']) && <Subtitle style={{ width: '100%', paddingTop: '10px' }}><span style={{ fontWeight: 600 }}>Justificativa de compras:</span> {this.pegaJustificativaDaAprovacao(['lodging', 'room', 'foodsAndBeverages', 'equipments'])}</Subtitle>}
              {this.pegaJustificativaDaAprovacao(['transfer']) && <Subtitle style={{ width: '100%', paddingTop: '10px' }}><span style={{ fontWeight: 600 }}>Justificativa de compras:</span> {this.pegaJustificativaDaAprovacao(['transfer'])}</Subtitle>}
              {this.pegaJustificativaDaAprovacao(['otherServices']) && <Subtitle style={{ width: '100%', paddingTop: '10px' }}><span style={{ fontWeight: 600 }}>Justificativa de compras:</span> {this.pegaJustificativaDaAprovacao(['otherServices'])}</Subtitle>}
            </Card>}
            <Hoteis listaDeHoteis={this.formatSuppliers(true)} visualizaDetalhe={this.visualizaDetalhe} isOrganizerResellerBudget={this.isOrganizerResellerBudget} />
          </>
        }
      })
    }
    if (requestedServices.includes('otherServices')) {
      obj.push({
        id: obj.length,
        title: 'Outros serviços',
        content: () => {
          return <>
            <Group style={{ zIndex: 10, position: 'absolute', right: '0px' }}>
              <Button
                disabled={!this.state.hasReceivedOtherServicesBudget}
                style={{ marginRight: '20px', padding: '10px 30px' }}
                category={'secondary'}
                ghost
                onClick={this.tabelaComparativaOutrosServicos}
                fontWeight={800}>
                Comparar
              </Button>
            </Group>
            <Subtitle style={{ width: '100%' }}>Selecione um fornecedor para ver o detalhe de seus orçamentos</Subtitle>
            {this.pegaJustificativaDaAprovacao(['lodging', 'room', 'foodsAndBeverages', 'equipments', 'transfer', 'otherServices']) && <Card style={{ border: 'red solid 1px' }}>
              <Group style={{ display: 'flex', padding: '0px' }}>
                <Icons
                  icon={'exclamation-circle'}
                  color={'red'}
                  style={{ margin: 'auto' }}
                  width={30}
                  height={15} />
                <Subtitle style={{ width: '100%', color: 'red', marginBottom: '0px', fontWeight: 600 }}>Reavaliar fornecedor</Subtitle>
              </Group>
              {this.pegaJustificativaDaAprovacao(['lodging', 'room', 'foodsAndBeverages', 'equipments']) && <Subtitle style={{ width: '100%', paddingTop: '10px' }}><span style={{ fontWeight: 600 }}>Justificativa de compras:</span> {this.pegaJustificativaDaAprovacao(['lodging', 'room', 'foodsAndBeverages', 'equipments'])}</Subtitle>}
              {this.pegaJustificativaDaAprovacao(['transfer']) && <Subtitle style={{ width: '100%', paddingTop: '10px' }}><span style={{ fontWeight: 600 }}>Justificativa de compras:</span> {this.pegaJustificativaDaAprovacao(['transfer'])}</Subtitle>}
              {this.pegaJustificativaDaAprovacao(['otherServices']) && <Subtitle style={{ width: '100%', paddingTop: '10px' }}><span style={{ fontWeight: 600 }}>Justificativa de compras:</span> {this.pegaJustificativaDaAprovacao(['otherServices'])}</Subtitle>}
            </Card>}
            <Hoteis listaDeHoteis={this.formatSuppliers(false, true)} visualizaDetalhe={this.visualizaDetalhe} isOrganizerResellerBudget={this.isOrganizerResellerBudget} />
          </>
        }
      })
    }
    return obj
  }

  possuiSala = () => {
    const { event } = this.state
    return event && event.services && event.services.room.requested
  }

  visualizaDetalhe = (nome, motivo, detalhes) => {
    this.setState({ nome, motivo, detalhes, verDetalhes: true })
  }

  tabelaComparativa = (event) => {
    event.preventDefault()
    this.setState({ modalComparativoStatus: !this.state.modalComparativoStatus })
  }

  tabelaComparativaTransfer = (event) => {
    event.preventDefault()
    this.setState({ modalComparativoTransfer: !this.state.modalComparativoTransfer })
  }

  tabelaComparativaOutrosServicos = (event) => {
    event.preventDefault()
    this.setState({ modalComparativoOutrosServicos: !this.state.modalComparativoOutrosServicos })
  }

  mostraNomeDoServico = (servico) => {
    switch (servico) {
      case 'room':
        return 'Salas'
      case 'foodsAndBeverages':
        return 'Alimentos e Bebidas'
      case 'lodging':
        return 'Hospedagem'
      case 'equipments':
        return 'Equipamentos'
      default:
        return ''
    }
  }

  listaDeFornecedoresSelecionados = () => {
    const { event } = this.state
    return event && event.services ? Object.keys(event.services).map(service => {
      return {
        service,
        id: event.services[service].choosedSupplier && event.services[service].choosedSupplier.id
      }
    }) : []
  }

  verificaSePossuiOrcamentosNaoSelecionados = () => {
    const { event } = this.state
    return event && event.services && Object.keys(event.services).some(service => {
      return event.services[service].requested && !event.services[service].choosedSupplier
    })
  }

  verificaSeTodosOsServicosForamSelecionados = () => {
    const { services } = this.state.event || {}
    return services && !Object.keys(services).some(item => services[item].requested && !services[item].choosedSupplier)
  }

  getTotalPriceLodging = () => {
    const { services } = this.state.event || {}
    return (services && services.lodging && services.lodging.requested && services.lodging.totalChoosedWithFees) ? services.lodging.totalChoosedWithFees.amount : 0
  }

  getTotalPriceRoom = () => {
    const { services } = this.state.event || {}
    return (services && services.room && services.room.requested && services.room.totalChoosedWithFees) ? services.room.totalChoosedWithFees.amount : 0
  }

  getTotalPriceFoodsAndBeverages = () => {
    const { services } = this.state.event || {}
    return (services && services.foodsAndBeverages && services.foodsAndBeverages.requested && services.foodsAndBeverages.totalChoosedWithFees) ? services.foodsAndBeverages.totalChoosedWithFees.amount : 0
  }

  getTotalPriceEquipments = () => {
    const { services } = this.state.event || {}
    return (services && services.equipments && services.equipments.requested && services.equipments.totalChoosedWithFees) ? services.equipments.totalChoosedWithFees.amount : 0
  }

  getTotalPriceTransfer = () => {
    const { services } = this.state.event || {}
    return (services && services.transfer.requested && services.transfer.totalChoosedPrice) ? services.transfer.totalChoosedPrice.amount : 0
  }

  getTotalPriceTransferWithFee = () => {
    const { services } = this.state.event || {}
    return (services && services.transfer.requested && services.transfer.totalChoosedWithFees) ? services.transfer.totalChoosedWithFees.amount : 0
  }

  getTotalPriceOutrosServicos = () => {
    const { services } = this.state.event || {}
    return (services && services.otherServices.requested && services.otherServices.totalChoosedPrice) ? services.otherServices.totalChoosedPrice.amount : 0
  }

  getTotalPriceOutrosServicosWithFee = () => {
    const { services } = this.state.event || {}
    return (services && services.otherServices.requested && services.otherServices.totalChoosedWithFees) ? services.otherServices.totalChoosedWithFees.amount : 0
  }

  getTotal = () => {
    return this.getTotalPriceLodging() + this.getTotalPriceRoom() + this.getTotalPriceFoodsAndBeverages() + this.getTotalPriceEquipments()
  }

  getTotalWithFee = () => {
    return this.applyFee(this.getManagementFee(), this.getTotal())
  }

  getTransferTotalWithFee = () => {
    return this.applyFee(this.getManagementFee(), this.getTotalPriceTransferWithFee())
  }

  getTransferTotalFee = () => {
    return this.applyFee(this.getManagementFee(), this.getTotalPriceTransfer())
  }

  getManagementFee () {
    return this.state.event
      ? this.state.event.managementFee
      : {
        amount: 0,
        type: 'PERCENTAGE'
      }
  }

  applyFee (fee, value) {
    if (!fee) return 0
    return (fee.type === 'PERCENTAGE')
      ? Math.round(Number((value * (1 + (fee.amount / 100)))))
      : (value + fee.amount)
  }



  ordenaContraPropostas = () => {
    var lista = []
    this.state.counterProposals && this.state.counterProposals.forEach(services => {
      services.forEach(service => service.counterProposals.forEach(counterProposal => {
        counterProposal.service = service.service
        counterProposal.supplier = service.supplier
        lista.push(counterProposal)
      }))
    })
    return lista.sort((a, b) => {
      var primeiraData = new Date(a.date)
      if (a.supplierStatus !== 'SENDED' && !a.duplicata && !(a.supplierStatus === 'ACCEPTED' && a.requester === 'SUPPLIER')) {
        primeiraData = new Date(a.acceptedDate)
      }
      var segundaData = new Date(b.date)
      if (b.supplierStatus !== 'SENDED' && !b.duplicata && !(b.supplierStatus === 'ACCEPTED' && b.requester === 'SUPPLIER')) {
        segundaData = new Date(b.acceptedDate)
      }
      return segundaData.getTime() - primeiraData.getTime()
    })
  }

  verificaQualAbaEstaSelecionada = () => {
    const { event } = this.state
    const requestedServices = event && event.services ? Object.keys(event.services).filter(service => event.services[service].requested) : []
    if (this.state.toggleSelected === 0) {
      if (requestedServices.includes('room') || requestedServices.includes('lodging') ||
        requestedServices.includes('foodsAndBeverages') || requestedServices.includes('equipments')) return 'LOCAIS'
      else if (requestedServices.includes('transfer')) return 'TRANSFER'
      else return 'OTHER_SERVICES'
    } else if (this.state.toggleSelected === 1) {
      if (requestedServices.includes('transfer')) return 'TRANSFER'
      else return 'OTHER_SERVICES'
    } if (this.state.toggleSelected === 2) return 'OTHER_SERVICES'
  }

  gerenciaResponsividade = () => {
    if (window.innerWidth < 600) return this.paginaMobile()
    else return this.paginaDesktop()
  }

  verificaSePossuiOrcamentosNaoSelecionados = () => {
    const { services } = this.state.event || {}
    const { room, lodging, foodsAndBeverages, equipments, transfer } = services || {}
    return services && ((room.requested && !room.choosedSupplier) ||
      (!room.requested && lodging.requested && !lodging.choosedSupplier) ||
      (!room.requested && foodsAndBeverages.requested && !foodsAndBeverages.choosedSupplier) ||
      (!room.requested && equipments.requested && !equipments.choosedSupplier) ||
      (transfer.requested && !transfer.choosedSupplier))
  }

  // isHabilitarBotaoAuditarComparar = () => {
  //   return (this.isExisteHotelComOrcamentoAprovado() && !isUserReseller()) || isUserReseller()
  // }

  paginaMobile = () => {
    const { eventId } = this.props.match.params
    return <>
      <Header />
      <FormContent>
        <IndicacaoFornecedor isOpen={this.state.modalState} onClose={() => this.setState({ modalState: false })} eventId={eventId} />
        <Group style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Group>
            <Title>Orçamentos</Title>
          </Group>
          <Group style={{ width: '50%', textAlign: 'end' }}>
            <Link to='#' onClick={() => this.setState({ modalState: true })} style={{ color: defaultTheme.colors.primary.default, fontSize: '15px' }}>indicar fornecedores</Link>
            <Link to={{ pathname: `/organizer/event/${eventId}/more-suppliers/select`, state: { from: 'listagem' } }} style={{ color: defaultTheme.colors.primary.default, fontSize: '15px', paddingLeft: '10px' }}>solicitar mais fornecedores</Link>
            <Link to={`/organizer/event/edit/${eventId}`} style={{ color: defaultTheme.colors.primary.default, fontSize: '15px', paddingLeft: '10px' }}>editar evento</Link>
          </Group>
        </Group>
        {/* <Filtro /> */} {/* Tiramos o filtro pq o alê mandou */}
        <br />
        <br />}
        <Subtitle>{this.possuiSala() ? 'Selecione um local para o seu evento' : 'Selecione um fornecedor'}</Subtitle>
        <div style={{ display: 'flex', flexFlow: 'row wrap', alignContent: 'space-between', justifyContent: 'space-between' }}>
          <Hoteis listaDeHoteis={this.formatSuppliers()} />
        </div>
      </FormContent>
    </>
  }

  paginaDesktop = () => {
    const { eventId } = this.props.match.params
    const userType = window.localStorage.getItem('user_type')
    return <>
      {this.state.isLoading && <Loader />}
      <TabelaComparativaTransfer dadosTransfer={this.state.hotels} event={this.state} isOpen={this.state.modalComparativoTransfer} onClose={() => this.setState({ modalComparativoTransfer: false })} listaDeHoteis={this.formatSuppliers()} />
      <TabelaComparativaOutrosServicos dadosOutrosServicos={this.state.hotels} event={this.state} isOpen={this.state.modalComparativoOutrosServicos} onClose={() => this.setState({ modalComparativoOutrosServicos: false })} listaDeHoteis={this.formatSuppliers()} />
      {userType !== 'RESELLER' && <TabelaComparativa event={this.state} isOpen={this.state.modalComparativoStatus} onClose={() => this.setState({ modalComparativoStatus: false })} listaDeHoteis={this.formatSuppliers()} />}
      {userType === 'RESELLER' && <TabelaAuditoria history={this.props.history} event={this.state} isOpen={this.state.modalComparativoStatus} onClose={() => this.setState({ modalComparativoStatus: false })} listaDeHoteis={this.formatSuppliers()} />}
      <DetalheIndisponibilidade
        isOpen={this.state.verDetalhes}
        onClose={() => this.setState({ verDetalhes: false })}
        motivo={this.state.motivo}
        detalhes={this.state.detalhes}
        nome={this.state.nome}
      />
      <Header />
      <Main>
        <Body {...this.props} pagina={'inicio'} menuLateralDireito={this.menuLateralDireito()}>
          <Options
            eventId={eventId}
            history={this.props.history}
            delegarConsultor={ehAdmin() && ehConsultor()}
            suppliersDeadline={this.state.event && this.state.event.suppliersDeadline}
            status={this.state.event && this.state.event.status}
            name={this.state.event && this.state.event.name}
            naoMostraEdicao={this.state.event && this.state.event.supplierChainId}
          />
          <FormContent>
            <Modal maxWidth={'80%'} isOpen={this.state.historicoModal} onClick={() => this.setState({ historicoModal: false } )} hasCloseButton>
              <Title style={{ textAlign: 'left' }}>Histórico</Title>
              <Select
                label={'Filtrar por fornecedor'}
                name='category'
                onChange={(input) => this.setState({ fornecedorSelecionado: input.target.value })}>
                <option value={''}>Selecione o fornecedor</option>
                {this.ordenaContraPropostas().filter(counterProposal => counterProposal.supplier).reduce((lista, counterProposal) => {
                  !lista.find(supplier => supplier.id === counterProposal.supplier.id) && lista.push(counterProposal.supplier)
                  return lista
                }, []).map((supplier, index) => <option value={supplier.id} key={index}>{supplier.name}</option>)}
              </Select>
              {this.state.counterProposals && this.ordenaContraPropostas().filter(counterProposal => this.state.fornecedorSelecionado ? (counterProposal.supplier && counterProposal.supplier.id === this.state.fornecedorSelecionado) : true).map((counterProposal, index) =>
                <Group key={index} style={{ fontSize: '13px', marginTop: '10px' }}>
                  <Label style={{ width: '150px' }}>{formatDateWithTime(counterProposal.date)}</Label>
                  <Label style={{ marginTop: '-7px' }}>
                    {counterProposal.requester === 'ORGANIZER' ? 'Você' : counterProposal.supplier && counterProposal.supplier.name}
                    <span style={{ fontWeight: 500, color: defaultTheme.colors.primary.default }}>{counterProposal.requester === 'ORGANIZER'
                      ? ' solicitou uma contraproposta '
                      : `${counterProposal.supplierStatus === 'ACCEPTED' && counterProposal.duplicata ? ' aceitou sua contraproposta ' : ''}
                        ${counterProposal.supplierStatus === 'REFUSED' ? ' recusou sua contraproposta ' : ''}
                        ${counterProposal.supplierStatus === 'ACCEPTED' && !counterProposal.duplicata ? ' enviou uma contraproposta ' : ''}
                        `}</span>
                    de <span style={{ fontWeight: 600 }}>{formatCurrency(counterProposal.requestedPrice)} </span> para {this.mostraNomeDoServico(counterProposal.service)} {counterProposal.requester === 'ORGANIZER' && counterProposal.supplier && 'do ' + counterProposal.supplier.name}.
                  </Label>
                </Group>
              )}
              <Group>
                <Button
                  onClick={() => this.setState({ historicoModal: false })}
                  category={'secondary'}
                  fontWeight={800}
                  width={'50%'}
                  full>
                  Fechar histórico
                </Button>
              </Group>
            </Modal>
            <IndicacaoFornecedor isOpen={this.state.modalState} onClose={() => this.setState({ modalState: false })} eventId={eventId} />
            <Group style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Group>
                <Title>Orçamentos</Title>
              </Group>
              <Group style={{ width: '50%', textAlign: 'end' }}>
                <Link to='#' onClick={() => this.setState({ modalState: true })} style={{ color: defaultTheme.colors.whiteLabel.primary, fontSize: '15px', display: 'block' }}>indicar fornecedores</Link>
                {this.verificaQualAbaEstaSelecionada() !== 'TRANSFER' && this.verificaQualAbaEstaSelecionada() !== 'OTHER_SERVICES' && <Link to={{ pathname: `/organizer/event/${eventId}/more-suppliers/select`, state: { from: 'listagem' } }} style={{ color: defaultTheme.colors.whiteLabel.primary, fontSize: '15px', paddingLeft: '10px', marginTop: '10px', display: 'block' }}>solicitar mais fornecedores</Link>}
              </Group>
            </Group>
            {/* <Filtro /> */} {/* Tiramos o filtro pq o alê mandou */}
            <Tabs selected={this.state.toggleSelected} tabs={this.getTabs()} onToggleChange={(tab) => this.setState({ toggleSelected: tab })} />
          </FormContent>
        </Body>
      </Main>
    </>
  }

  menuLateralDireito = () => {
    const { services } = this.state.event || {}
    const { eventId } = this.props.match.params
    return this.state.eventName ? <FixedDiv>
      <Card style={{ width: '100%' }}>
        <Label style={{ textTransform: 'uppercase' }}>Resumo do Evento <span style={{ float: 'right', paddingRight: '10px' }}>ID:{this.state.eventFriendlyId}</span></Label>
        <Group>
          <Label style={{ fontWeight: 600, color: '#484848' }}>{this.state.eventName}</Label>
          <Label>Solicitado há {this.state.eventCreated ? moment().diff(moment(this.state.eventCreated, 'YYYY-MM-DD[T]HH:mm:ssZ'), 'days') : 0} dias</Label>
          <Group style={{ display: 'flex' }}>
            <Icons
              icon={'calendario'}
              color={'gray'}
              width={40}
              height={20}
              style={{ marginRight: '-5px', marginTop: '5px' }} />
            <Label>{formatDate(this.state.eventStartDate)} - {formatDate(this.state.eventEndDate)}</Label>
          </Group>
          <Group style={{ display: 'flex' }}>
            <Icons
              icon={'map-pin'}
              color={'gray'}
              width={40}
              height={20}
              style={{ marginRight: '-5px', marginTop: '5px', marginLeft: '2px' }} />
            <Label>{this.state.eventLocation.address} - {this.state.eventLocation.city}</Label>
          </Group>
          {this.state.numberOfParticipants > 0 && <Group style={{ display: 'flex' }}>
            <Icons
              icon={'pessoas'}
              color={'gray'}
              width={40}
              height={20}
              style={{ marginTop: '5px', marginLeft: '-3px' }} />
            <Label>{this.state.numberOfParticipants} pessoas</Label>
          </Group>}
        </Group>
      </Card>
      <Card style={{ width: '100%' }}>
        <Label style={{ textTransform: 'uppercase' }}>Histórico do evento</Label>
        {this.state.counterProposals && this.ordenaContraPropostas().slice(0, 5).map((counterProposal, index) =>
          <Group key={index} style={{ fontSize: '13px', marginTop: '10px' }}>
            <Label style={{ width: '150px' }}>{formatDateWithTime(counterProposal.date)}</Label>
            <Label style={{ marginTop: '-7px' }}>
              {counterProposal.requester === 'ORGANIZER' ? 'Você' : counterProposal.supplier && counterProposal.supplier.name}
              <span style={{ fontWeight: 500, color: defaultTheme.colors.primary.default }}>{counterProposal.requester === 'ORGANIZER'
                ? ' solicitou uma contraproposta '
                : `${counterProposal.supplierStatus === 'ACCEPTED' && counterProposal.duplicata ? ' aceitou sua contraproposta ' : ''}
                  ${counterProposal.supplierStatus === 'REFUSED' ? ' recusou sua contraproposta ' : ''}
                  ${counterProposal.supplierStatus === 'ACCEPTED' && !counterProposal.duplicata ? ' enviou uma contraproposta ' : ''}
                  `}</span>
              de <span style={{ fontWeight: 600 }}>{formatCurrency(counterProposal.requestedPrice)} </span> para {this.mostraNomeDoServico(counterProposal.service)} {counterProposal.requester === 'ORGANIZER' && counterProposal.supplier && 'do ' + counterProposal.supplier.name}.
            </Label>
          </Group>
        )}
        <Link to="#" onClick={() => this.setState({ historicoModal: true })} style={{ fontFamily: defaultTheme.fonts.primary, color: defaultTheme.colors.primary.default, fontSize: '15px', paddingLeft: '10px', display: 'block', textAlign: 'center' }}>ver mais</Link>
      </Card>

      {(this.state.eventEmergency && this.state.eventEmergency.isEmergency) && <Card style={{ width: '100%' }}>
        <Label style={{ textTransform: 'uppercase', color: 'red' }}>Evento Emergencial</Label>
        <Tag style={{ right: '15px', top:'15px', textTransform: 'uppercase' }} status={'DESCONTO'}>Emergencial</Tag>
        <Label style={{ marginTop: '10px' }}>
          <span style={{ fontWeight: 500 }}>Justificativa: </span> {this.state.eventEmergency.reason}
        </Label>
      </Card>}

      {isUserReseller()
        && <Card style={{ width: '100%' }}>
          <Label style={{ textTransform: 'uppercase' }}>Solicitação do orçamento</Label>
          <Group>
            <Label>{this.state.owner.fullName}</Label>
            <Label>{this.state.owner.email}</Label>
            <Label>{this.state.owner.phone}</Label>
          </Group>
        </Card>
      }

      <Card style={{ width: '100%' }}>
        <Label style={{ textTransform: 'uppercase' }}>Resumo do pedido</Label>

        {this.getSupplierNames() && <>
          <Title style={{ fontSize: '14px', marginTop: '20px', marginBottom: '10px' }}>{this.getSupplierNames()}</Title>
          {services && services.lodging.requested && services.lodging.choosedSupplier && <ItemsMenuLateral selected={false}>
            <Group style={{ padding: '0', display: 'flex' }}>
              <Icons
                icon={'bed-outline'}
                viewBox='0 0 1536 1024'
                width={50}
                height={20}
                color={'#484848'}
                style={{ verticalAlign: 'middle', margin: 'auto', marginRight: '10px' }} />
              <Group style={{ padding: '0', display: 'flex', width: '100%' }}>
                <Label>Hospedagem</Label>
                <Label style={{ textAlign: 'right', color: defaultTheme.colors.primary.default }}>{formatCurrency(this.getTotalPriceLodging())}</Label>
              </Group>
            </Group>
          </ItemsMenuLateral>}
          {services && services.room.requested && services.room.choosedSupplier && <ItemsMenuLateral selected={false}>
            <Group style={{ padding: '0', display: 'flex' }}>
              <Icons
                icon={'room-outline'}
                viewBox='0 0 1536 1024'
                width={50}
                height={20}
                color={'#484848'}
                style={{ verticalAlign: 'middle', margin: 'auto', marginRight: '10px' }} />
              <Group style={{ padding: '0', display: 'flex', width: '100%' }}>
                <Label>Salas</Label>
                <Label style={{ textAlign: 'right', color: defaultTheme.colors.primary.default }}>{formatCurrency(this.getTotalPriceRoom())}</Label>
              </Group>
            </Group>
          </ItemsMenuLateral>}
          {services && services.foodsAndBeverages.requested && services.foodsAndBeverages.choosedSupplier && <ItemsMenuLateral selected={false}>
            <Group style={{ padding: '0', display: 'flex' }}>
              <Icons
                icon={'food-beverage-outline'}
                width={50}
                height={20}
                color={'#484848'}
                style={{ verticalAlign: 'middle', margin: 'auto', marginRight: '10px' }} />
              <Group style={{ padding: '0', display: 'flex', width: '100%' }}>
                <Label>Alimentos e bebidas</Label>
                <Label style={{ textAlign: 'right', color: defaultTheme.colors.primary.default }}>{formatCurrency(this.getTotalPriceFoodsAndBeverages())}</Label>
              </Group>
            </Group>
          </ItemsMenuLateral>}
          {services && services.equipments.requested && services.equipments.choosedSupplier && <ItemsMenuLateral selected={false}>
            <Group style={{ padding: '0', display: 'flex' }}>
              <Icons
                icon={'computer-outline'}
                viewBox='0 0 1280 1024'
                width={50}
                height={20}
                color={'#484848'}
                style={{ verticalAlign: 'middle', margin: 'auto', marginRight: '10px' }} />
              <Group style={{ padding: '0', display: 'flex', width: '100%' }}>
                <Label>Equipamentos</Label>
                <Label style={{ textAlign: 'right', color: defaultTheme.colors.primary.default }}>{formatCurrency(this.getTotalPriceEquipments())}</Label>
              </Group>
            </Group>
          </ItemsMenuLateral>}
          <ItemsMenuLateral selected={false}>
            <Group style={{ padding: '0', display: 'flex' }}>
              <Label>Service Fee</Label>
              <Label style={{ textAlign: 'right', color: defaultTheme.colors.primary.default }}>{formatCurrency(this.getTotalWithFee() - this.getTotal())}</Label>
            </Group>
          </ItemsMenuLateral>
        </>}

        {this.getTransferSupplierName() && <>
          <Title style={{ fontSize: '14px', marginTop: '20px', marginBottom: '10px' }}>{this.getTransferSupplierName()}</Title>
          {services && services.transfer.requested && services.transfer.choosedSupplier && <ItemsMenuLateral selected={false}>
            <Group style={{ padding: '0', display: 'flex' }}>
              <Icons
                icon={'bus-outline'}
                width={50}
                height={20}
                color={'#484848'}
                style={{ verticalAlign: 'middle', margin: 'auto', marginRight: '10px' }} />
              <Group style={{ padding: '0', display: 'flex', width: '100%' }}>
                <Label>Transfer</Label>
                <Label style={{ textAlign: 'right', color: defaultTheme.colors.primary.default }}>{formatCurrency(this.getTotalPriceTransfer())}</Label>
              </Group>
            </Group>
          </ItemsMenuLateral>}
          <ItemsMenuLateral selected={false}>
            <Group style={{ padding: '0', display: 'flex' }}>
              <Label>Service Fee</Label>
              <Label style={{ textAlign: 'right', color: defaultTheme.colors.primary.default }}>{formatCurrency(this.getTransferTotalFee() - this.getTotalPriceTransfer())}</Label>
            </Group>
          </ItemsMenuLateral>
        </>}

        {this.getOutrosServicosSupplierName() && <>
          <Title style={{ fontSize: '14px', marginTop: '20px', marginBottom: '10px' }}>{this.getOutrosServicosSupplierName()}</Title>
          {services && services.otherServices.requested && services.otherServices.choosedSupplier && <ItemsMenuLateral selected={false}>
            <Group style={{ padding: '0', display: 'flex' }}>
              <Icons
                icon={'outros-servicos-outline'}
                width={50}
                height={20}
                color={'#484848'}
                style={{ verticalAlign: 'middle', margin: 'auto', marginRight: '10px' }} />
              <Group style={{ padding: '0', display: 'flex', width: '100%' }}>
                <Label>Outros serviços</Label>
                <Label style={{ textAlign: 'right', color: defaultTheme.colors.primary.default }}>{formatCurrency(this.getTotalPriceOutrosServicos())}</Label>
              </Group>
            </Group>
          </ItemsMenuLateral>}
        </>}

        {this.verificaSePossuiOrcamentosNaoSelecionados() && <Title style={{ fontSize: '17px', marginBottom: '20px' }}>Orçamentos não selecionados</Title>}
        {services && services.room.requested && !services.room.choosedSupplier && <ItemsMenuLateral>
          <Icons
            icon={'locais'}
            viewBox='0 0 1280 1024'
            width={36}
            height={20}
            color={'#484848'}
            style={{ verticalAlign: 'middle', margin: 'auto 5px' }} />
          Locais do evento
        </ItemsMenuLateral>}
        {services && !services.room.requested && services.lodging.requested && !services.lodging.choosedSupplier && <ItemsMenuLateral>
          <Icons
            icon={'bed-outline'}
            viewBox='0 0 1536 1024'
            width={36}
            height={20}
            color={'#484848'}
            style={{ verticalAlign: 'middle', margin: 'auto 5px' }} />
          Hospedagem
        </ItemsMenuLateral>}
        {services && !services.room.requested && services.foodsAndBeverages.requested && !services.foodsAndBeverages.choosedSupplier && <ItemsMenuLateral>
          <Icons
            icon={'food-beverage-outline'}
            width={36}
            height={20}
            color={'#484848'}
            style={{ verticalAlign: 'middle', margin: 'auto 5px' }} />
          Alimentos e bebidas
        </ItemsMenuLateral>}
        {services && !services.room.requested && services.equipments.requested && !services.equipments.choosedSupplier && <ItemsMenuLateral>
          <Icons
            icon={'computer-outline'}
            viewBox='0 0 1280 1024'
            width={36}
            height={20}
            color={'#484848'}
            style={{ verticalAlign: 'middle', margin: 'auto 5px' }} />
          Equipamentos
        </ItemsMenuLateral>}
        {services && services.transfer.requested && !services.transfer.choosedSupplier && <ItemsMenuLateral>
          <Icons
            icon={'bus-outline'}
            width={36}
            height={20}
            color={'#484848'}
            style={{ verticalAlign: 'middle', margin: 'auto 5px' }} />
          Transfer
        </ItemsMenuLateral>}
        {services && services.otherServices.requested && !services.otherServices.choosedSupplier && <ItemsMenuLateral>
          <Icons
            icon={'outros-servicos-outline'}
            width={36}
            height={20}
            color={'#484848'}
            style={{ verticalAlign: 'middle', margin: 'auto 5px' }} />
          Outros serviços
        </ItemsMenuLateral>}
        <ItemsMenuLateral selected={true}>
          <Group style={{ padding: '0', display: 'flex' }}>
            <Group style={{ padding: '0', width: '100%' }}>
              <Label>Valor total</Label>
              <Label style={{ fontSize: '12px', color: defaultTheme.colors.grey.light }}>(com taxas)</Label>
            </Group>
            <Money>{formatCurrency(this.getTotal() + this.getTotalPriceTransferWithFee() + this.getTotalPriceOutrosServicosWithFee(), false)}</Money>
          </Group>
        </ItemsMenuLateral>
        <Button
          type='submit'
          category={'secondary'}
          fontWeight={800}
          disabled={!this.verificaSeTodosOsServicosForamSelecionados()}
          onClick={() => this.props.history.push(`/organizer/event/${eventId}/resume/chosen`)}
          full>
          Visualizar pedido completo
        </Button>
      </Card>
    </FixedDiv> : ''
  }

  render () {
    return this.gerenciaResponsividade()
  }
}

export default ListagemHoteis
