import React from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../styles/themes'
import Sidebar from './Sidebar'

const Container = styled.div`
  padding: 10px 20px;
  font-family: ${defaultTheme.fonts.secondary};
  width: 100%;
  position: relative;
  height: inherit;
  margin-top: 1px;
  margin-right: 1px;
  overflow-y: auto;
  @media (min-width: 600px) {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
    background: #fff;
    padding: 30px;
    width: 100%;
  }
`

const Body = styled.div`
  width: 100%;
  margin-top: 49px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: inherit;
  overflow-y: auto;
`

const Group = styled.div`
  height: inherit;
  overflow-y: auto;
`

export default function (props) {
  return (
    <Body {...props}>
      <Sidebar pagina={props.pagina} history={props.history} />
      <Group style={{ width: `${props.menuLateralDireito ? '90%' : '100%'}`, marginLeft: 'calc(18% - 20px)', marginTop: '-20px', padding: '20px', paddingRight: `${props.menuLateralDireito ? '20px' : '0px'}`,display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        <Container style={props.containerStyle}>
          {props.children}
        </Container>
      </Group>
      {props.menuLateralDireito && <Group style={{ width: '36%', height: 'calc(100vh - 75px)', margin: '1px 10px 0 0', padding: '0px' }}>
        {props.menuLateralDireito}
      </Group>}
    </Body>
  )
}
