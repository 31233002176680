import React from 'react'
import styled from 'styled-components'
import Icon from './Icons'

import { defaultTheme } from '../../styles/themes'

const Checkbox = styled.input`
  font-size: 16px;
  background-color: #FFF;
  border-radius: 1px;
  cursor: pointer;
  padding: 6px 10px;
  border-bottom: ${props => props.error ? '2px solid red' : `2px solid ${defaultTheme.colors.primary.default}`};
  transition: all 0.2s linear;
  height: 20px;
  width: 20px;
`
const Label = styled.label`
  align-items: center;
  background-color: #FFF;
  cursor: pointer;
  justify-content: space-between;
  display: flex;
  color: ${props => props.error ? 'red' : (props.edited ? '#F39C12' : '#484848')};
  font-family: ${defaultTheme.fonts.secondary};
  font-weight: 500;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  white-space: nowrap;
`
const Box = styled.div`
  background-color: #FFF;
  margin: 10px 10px;
  width: 100%;
  border: none;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
`
const LabelInfo = styled.span`
  align-items: center;
  display: flex;
`
const Observation = styled.span`
  color: #484848;
  font-family: ${defaultTheme.fonts.secondary};
  font-size: 12px;
  width: 100%;
  padding-right: 5px;
  text-align: right;
`

export default function (props) {
  return (
    <Box style={props.style}>
      <Label htmlFor={props.id || props.name} error={props.error} edited={props.edited}>{props.label}
        <LabelInfo>
          {props.icon ? <i style={{'minWidth': '55px'}}><Icon
            icon={props.icon}
            color={'#333'}
            width={props.width}
            height={props.height}/><span style={{ fontSize: '13px', marginTop: '-13px', display:'inline-block', verticalAlign: 'middle' }}>{props.iconLabel}</span></i> : null}
          {props.placeholder}
        </LabelInfo>
        {props.observation && <Observation>{props.observation}</Observation>}
        <Checkbox
          checked={props.checked}
          name={props.name}
          error={props.error}
          id={props.id || props.name}
          value={props.value}
          onClick={props.onClick}
          type='checkbox' />
      </Label>
      {props.children}
    </Box>
  )
}
