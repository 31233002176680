import React, { Component } from 'react'
import styled from 'styled-components'
import * as moment from 'moment'
import { defaultTheme } from '../../styles/themes'
import Icons from './Icons'
import Title from './Title'
import Subtitle from './Subtitle'
import Select from './Select'
import Button from './Button'
import AlertIcon from './AlertIcon'
import ClockIcon from './ClockIcon'
import Modal from '../Modal'
import Loader from '../Loader'
import config from '../../../middleware/config'
import { Delete, Post } from '../../../utils/request'
import ModalDelegarConsultor from '../../Organizer/Events/ModalDelegarConsultor'

const CircleButton = styled.p`
  background: #E6E6E6;
  border-radius: 50%;
  color: ${defaultTheme.colors.grey.medium};
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  height: 25px;
  line-height: 1.3;
  margin-right: 5px;
  position: absolute;
  right: 10px;
  text-align: center;
  top: 10px;
  width: 25px;
`

const Card = styled.div`
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  line-height: 1.3;
  position: absolute;
  right: 10px;
  top: 10px;
  width: fit-content;
  z-index: 9px;
`

const Label = styled.p`
  color: ${defaultTheme.colors.grey.medium};
  font-family: ${defaultTheme.fonts.primary};
  font-size: 15px;
  font-weight: 300;
`

const Group = styled.div`
  display: flex;
  padding: 15px;
`

const TitleSLA = styled.h1`
  font-family: ${defaultTheme.fonts.secondary};
  color: ${defaultTheme.colors.text.darker};
  line-height: 1.2;
  font-size: 30px;
  margin-top: auto;
  margin-bottom: 20px;
`

const Bold = styled.span`
  font-weight: bold;
`

const preventAndStop = event => {
  if (event) {
    if (event.preventDefault && (typeof event.preventDefault) === 'function') event.preventDefault()
    if (event.stopPropagation && (typeof event.stopPropagation) === 'function') event.stopPropagation()
  }
}

class ModalAtencao extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: false
    }
  }

  UNSAFE_componentWillReceiveProps (props) {
    this.setState({ ...props },
      () => document.querySelector('body').style.overflow = this.state.isOpen ? 'hidden' : 'auto'
    )
  }

  render () {
    return (
      <Modal isOpen={this.state.isOpen} onClick={preventAndStop}>
        <AlertIcon width={'150px'} />
        <Title>Atenção!</Title>
        <Subtitle>Cancelar este evento anulará qualquer possibilidade de receber orçamentos. Deseja continuar?</Subtitle>
        <Group>
          <Button
            onClick={(event) => {
              preventAndStop(event)
              this.setState({ isOpen: false }, () => this.props.onCancel())
            }}
            category={'secondary'}
            fontWeight={800}
            width={'50%'}
            ghost
            full>
            Não
          </Button>
          <Button
            onClick={(event) => {
              preventAndStop(event)
              this.setState({ isOpen: false }, () => this.props.onSubmit(event))
            }}
            category={'secondary'}
            fontWeight={800}
            width={'50%'}
            full>
            Sim
          </Button>
        </Group>
      </Modal>
    )
  }
}

class ModalRenovarSLA extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: false,
      hours: '',
      backendDeadline: '',
      modalDoFDS: false,
      isLoading: false
    }
  }

  UNSAFE_componentWillReceiveProps (props) {
    this.setState({ ...props },
      () => document.querySelector('body').style.overflow = this.state.isOpen ? 'hidden' : 'auto'
    )
  }

  retornaRestanteDoTempoEmHorasEMinutos = (deadline) => {
    var diferenca = deadline.diff(moment((new Date()).toISOString(), 'YYYY-MM-DD[T]HH:mm:ssZ'))
    var duracao = moment.duration(diferenca)
    return Math.floor(duracao.asHours()) + moment.utc(diferenca).format('HH') > 0 ? Math.floor(duracao.asHours()) + moment.utc(diferenca).format('/mm') : '0h 0'
  }

  verificaSeEhMenosQue24Horas = (deadline) => {
    var diferenca = deadline.diff(moment((new Date()).toISOString(), 'YYYY-MM-DD[T]HH:mm:ssZ'))
    var duracao = moment.duration(diferenca)
    return Math.floor(duracao.asHours()) + moment.utc(diferenca).format('HH') < 2400
  }

  renovarSLA = (event) => {
    preventAndStop(event)
    if (!this.state.hours) return alert('Selecione as horas para efetuar essa operação.')
    const { api: { base, version, routes: { events } } } = config
    var endpoint = `${base}${version}${events}/${this.props.eventId}/renew-deadline`
    this.setState({ isLoading: true, modalDoFds: false, abrirModalSLA: false }, () => {
      Post(endpoint, { hours: parseInt(this.state.hours) }).then(() => {
        window.location.reload()
      }).catch(erro => {
        this.setState({ isLoading: false })
        console.error(erro)
        alert(`Erro ao renovar o SLA, favor entrar em contato com o suporte técnico (received: ${erro})`)
      })
    })
  }

  verificaSeCaiNoFDS = (event) => {
    preventAndStop(event)
    if (!this.state.hours) return alert('Selecione as horas para efetuar essa operação.')
    this.setState({ isLoading: true })
    const { api: { base, version } } = config
    const endpoint = `${base}${version}/sla/simulation`
    var obj = {
      hours: this.state.hours,
      baseDate: this.props.suppliersDeadline
    }
    Post(endpoint, obj).then((resposta) => {
      if (resposta.isWeekend) {
        this.setState({
          modalDoFDS: true,
          backendDeadline: resposta.suppliersDeadline
        })
      } else {
        return this.renovarSLA(event)
      }
      this.setState({ isLoading: false })
    }).catch(erro => {
      console.error(erro)
      this.setState({ isLoading: false })
      return this.renovarSLA(event)
    })
  }

  renovarEstender = renovar => {
    return renovar ? 'Renovar' : 'Estender'
  }

  render () {
    const oldDeadline = moment(this.props.suppliersDeadline, 'YYYY-MM-DD[T]HH:mm:ssZ')
    const renovar = oldDeadline.diff(moment(), 'minutes') <= 0
    const newDeadline = renovar
      ? moment().add(this.state.hours, 'hours')
      : oldDeadline.clone().add(this.state.hours, 'hours')
    const backendDeadline = this.state.backendDeadline && moment(this.state.backendDeadline, 'YYYY-MM-DD[T]HH:mm:ssZ')

    return (
      <Modal
        hasCloseButton
        onClose={() => this.setState({ isOpen: false }, () => this.props.onCancel())}
        isOpen={this.state.isOpen}
        onClick={preventAndStop}>
        {this.state.isLoading ? <Loader /> : null}
        <Modal isOpen={this.state.modalDoFDS} onClose={preventAndStop}>
          <AlertIcon width={'150px'} />
          <Title>Atenção!</Title>
          <Subtitle>
            As horas de SLA contabilizam apenas dias da semana. Por isto, os fornecedores poderão enviar orçamentos até <Bold>{backendDeadline && backendDeadline.format('DD/MM/YYYY')}</Bold> às <Bold>{backendDeadline && backendDeadline.format('HH')}h{backendDeadline && backendDeadline.format('mm')}min</Bold>
          </Subtitle>
          <Group>
            <Button onClick={this.renovarSLA.bind(this)} category={'secondary'} fontWeight={800} width={'50%'} full>
              Ok
            </Button>
          </Group>
        </Modal>
        <ClockIcon width={'150px'} />
        <TitleSLA>{this.renovarEstender(renovar)} SLA</TitleSLA>
        <Subtitle style={{ marginBottom: '5px', fontWeight: 600 }}>{this.props.name}</Subtitle>
        {renovar
          ? <Subtitle style={{ color: 'red' }}>SLA expirado</Subtitle>
          : <Subtitle>SLA expira em: <span style={{ color: this.verificaSeEhMenosQue24Horas(oldDeadline) && 'red' }}>{`${this.retornaRestanteDoTempoEmHorasEMinutos(oldDeadline).replace('/', 'h ')}min`}</span></Subtitle>}
        <Select
          isRequired
          label={`${this.renovarEstender(renovar)} evento por mais`}
          name={'type'}
          value={this.state.hours}
          onChange={input => { this.setState({ hours: input.target.value }) }}>
          <option value={''}>Selecione uma opção</option>
          <option value={12}>12 horas</option>
          <option value={24}>24 horas</option>
          <option value={36}>36 horas</option>
          <option value={48}>48 horas</option>
          <option value={72}>72 horas</option>
        </Select>
        <Button
          onClick={this.verificaSeCaiNoFDS}
          category={'secondary'}
          fontWeight={800}
          width={'100%'}
          full
          children={this.renovarEstender(renovar)}
        />
        {((!renovar) || this.state.hours) && <Subtitle style={{ color: '#727272', marginBottom: '5px', marginTop: '20px' }}>
          Os fornecedores terão até dia <Bold>{newDeadline.format('DD/MM/YYYY')}</Bold> às <Bold>{newDeadline.format('HH')}h{newDeadline.format('mm')}min</Bold>.
        </Subtitle>}
      </Modal>
    )
  }
}

class Options extends Component {
  state = {
    openModal: false,
    abrirAlerta: false,
    isLoading: false,
    delegarConsultor: false
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.state.openModal) {
      this.setState({ openModal: false })
    }
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node
  }

  buttonClick = (event) => {
    preventAndStop(event)
    this.setState({ openModal: !this.state.openModal })
  }

  edicaoClick = (event) => {
    preventAndStop(event)
    this.props.history.push(`/organizer/event/edit/${this.props.eventId}`)
  }

  historicoClick = (event) => {
    preventAndStop(event)
    this.props.clickHistorico()
  }

  cancelarClick = (event) => {
    preventAndStop(event)
    const { api: { base, version, routes: { events } } } = config
    var endpoint = `${base}${version}${events}/${this.props.eventId}`
    this.setState({ isLoading: true, abrirAlerta: false }, () => {
      Delete(endpoint).then(() => {
        this.setState({ isLoading: false })
        this.props.history.push(`/`)
        this.props.history.push(`/organizer/`)
      }).catch(erro => {
        this.setState({ isLoading: false })
        console.error(erro)
        alert(`Erro ao cancelar o seu evento, favor entrar em contato com o suporte técnico (received: ${erro})`)
      })
    })
  }

  render () {
    return <>
      {this.state.isLoading ? <Loader /> : null}
      <ModalAtencao
        isOpen={this.state.abrirAlerta}
        onSubmit={this.cancelarClick}
        onCancel={() => this.setState({ abrirAlerta: false })}
        onClose={() => this.setState({ abrirAlerta: false })}
        onClick={preventAndStop}
      />
      <ModalRenovarSLA
        isOpen={this.state.abrirModalSLA}
        onSubmit={() => this.setState({ abrirModalSLA: false })}
        onCancel={() => this.setState({ abrirModalSLA: false })}
        onClose={() => this.setState({ abrirModalSLA: false })}
        suppliersDeadline={this.props.suppliersDeadline}
        name={this.props.name}
        eventId={this.props.eventId}
        onClick={preventAndStop}
      />
      <ModalDelegarConsultor
        isOpen={this.state.delegarConsultor}
        eventId={this.props.eventId}
        onCancel={() => this.setState({ delegarConsultor: false })}
        onClose={() => this.setState({ delegarConsultor: false })}
        onClick={preventAndStop}
      />
      <CircleButton style={this.props.style} onClick={this.buttonClick}>...</CircleButton>
      {this.state.openModal && <Card ref={this.setWrapperRef} style={{ zIndex: 999 }}>
        {!this.props.naoMostraEdicao && <Group onClick={this.edicaoClick}>
          <Icons
            icon={'pencil'}
            color={defaultTheme.colors.grey.medium}
            width={40}
            height={20}
          />
          <Label>
            {this.props.readOnly ? 'Visualizar evento' : 'Editar evento'}
          </Label>
        </Group>}
        {(this.props.status === 'ACTIVE' || this.props.status === 'REVIEW') && <Group onClick={(event) => {
          preventAndStop(event)
          this.setState({ abrirModalSLA: true })
        }}>
          <Icons
            icon={'clock-o'}
            color={defaultTheme.colors.grey.medium}
            width={40}
            height={20}
          />
          <Label>
            {moment(this.props.suppliersDeadline, 'YYYY-MM-DD[T]HH:mm:ssZ').diff(moment(), 'minutes') <= 0 ? 'Renovar SLA' : 'Estender SLA'}
          </Label>
          <Icons
            title='SLA: Tempo de resposta do fornecedor'
            icon={'question-circle-o'}
            color={defaultTheme.colors.grey.medium}
            style={{ marginTop: '2px' }}
            width={30}
            height={15}
          />
        </Group>}
        <Group onClick={(event) => {
          preventAndStop(event)
          this.setState({ abrirAlerta: true })
        }}>
          <Icons
            icon={'close'}
            color={defaultTheme.colors.grey.medium}
            width={40}
            height={20}
          />
          <Label>
            Cancelar evento
          </Label>
        </Group>
        {this.props.visualizarHistorico && <Group onClick={this.historicoClick}>
          <Icons
            icon={'file-pdf-o'}
            color={defaultTheme.colors.grey.medium}
            width={40}
            height={20}
          />
          <Label>
            Ver histórico
          </Label>
        </Group>}
        {this.props.delegarConsultor && <Group onClick={(event) => {
          preventAndStop(event)
          this.setState({ delegarConsultor: true })
        }}>
          <Icons
            icon={'icon_admin'}
            color={defaultTheme.colors.grey.medium}
            width={40}
            height={20}
          />
          <Label>
            Delegar para consultor
          </Label>
        </Group>}
      </Card>}
    </>
  }
}

export default Options
