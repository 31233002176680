/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
/* UTILS */
import config from '../../../../middleware/config'
import { formatCurrency } from '../../../../utils/money'
import { formatDate, formatDateWithTime } from '../../../../utils/dates'
import { Get, Post } from '../../../../utils/request'
import { getNameFromKey } from '../../../../utils/fabTexts'
import { temFeatureFlag } from '../../../../utils/tiposUsuario'
import ButtonIconExtra from '../../../components/ui/ButtonIconExtra'
import moment from 'moment'
/* STYLES */
import { defaultTheme, images } from '../../../styles/themes'
/* COMPONENTS */

import SingleChat from '../../../components/Chat/SingleChat'
import Header from '../../Header'
import Loader from '../../../components/Loader'
import Main from '../../../components/Main'
import ModalConfirmacao from '../../../components/Aprovacao/ModalConfirmacao'
import ModalFornecedorAprovado from '../../../components/Aprovacao/ModalFornecedorAprovado'
import CheckBox from '../../../components/ui/CheckBox'
import Textarea from '../../../components/ui/Textarea'
import Body from '../../../components/Body'
import Modal from '../../../components/Modal'
import BreadCrumb from '../../../components/BreadCrumb'
import ItemsMenuLateral from '../../../components/SidebarItems/ItemsMenuLateral'
import Options from '../../../components/ui/Options'
import MoedaIcon from '../../../components/ui/MoedaIcon'
import Icon from '../../../components/ui/Icons'
import Card from '../../../components/ui/Card'
import Icons from '../../../components/ui/Icons'
import Button from '../../../components/ui/Button'
import Slider from '../../../components/ui/Slider'
import Money from '../../../components/ui/Money'
import Subtitle from '../../../components/ui/Subtitle'
import ContraPropostaModal from '../ContraProposta'
import AddressText from '../../../components/AddressText'
import ButtonChat from '../../../components/ui/ButtonChat'

const LodgingIcon = styled(() => (<Icon style={{ margin: 'auto', marginLeft: '-10px', width: '40px' }} icon={'bed-outline'} />))`
  min-width: 55px;
  color: ${defaultTheme.colors.grey.medium};
`

const RoomIcon = styled(() => (<Icon style={{ margin: 'auto', marginLeft: '-10px', width: '40px' }} icon={'room-outline'} />))`
  min-width: 55px;
  color: ${defaultTheme.colors.grey.medium};
`

const FoodsAndBeveragesIcon = styled(() => (<Icon style={{ margin: 'auto', marginLeft: '-10px', width: '40px' }} icon={'food-beverage-outline'} />))`
  min-width: 55px;
  color: ${defaultTheme.colors.grey.medium};
`

const EquipmentsIcon = styled(() => (<Icon style={{ margin: 'auto', marginLeft: '-10px', width: '40px' }} icon={'computer-outline'} />))`
  min-width: 55px;
  color: ${defaultTheme.colors.grey.medium};
`

// const Agreement = styled.div`
//   padding: 20px;
//   font-family: ${defaultTheme.fonts.primary};
//   & a {
//     text-decoration: none;
//     color: ${defaultTheme.colors.primary.default};
//   }
//   & [type="checkbox"] {
//       display: none;
//   }

//   & [type="checkbox"]:checked+label:before,
//   & [type="checkbox"]:not(:checked)+label:before {
//       content: "";
//       position: absolute;
//       left: 10px;
//       top: 0;
//       width: 20px;
//       height: 20px;
//       border: 1px solid #ddd;
//       border-radius: 5px;
//       background: #fff;
//       -webkit-transition: all .2s ease;
//       -o-transition: all .2s ease;
//       transition: all .2s ease;
//   }

//   & [type="checkbox"]:checked+label,
//   & [type="checkbox"]:not(:checked)+label {
//       position: relative;
//       cursor: pointer;
//       line-height: 20px;
//       padding-left: 40px;
//       display: inline-block;
//       color: #666;
//       width: 100%;
//       -webkit-transition: all .2s ease;
//       -o-transition: all .2s ease;
//       transition: all .2s ease;
//   }

//   & [type="checkbox"]:checked+label:after {
//       content: "";
//       width: 12px;
//       height: 12px;
//       background: ${defaultTheme.colors.primary.default};
//       position: absolute;
//       left: 15px;
//       top: 5px;
//       border-radius: 2px;
//       -webkit-transition: all .2s ease;
//       -o-transition: all .2s ease;
//       transition: all .2s ease;
//   }
// `

const Title = styled.h1`
  font-family: ${defaultTheme.fonts.secondary};
  line-height: 1.2;
  font-size: 1.3em;
  margin-bottom: 20px;
`

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #fff;
`

const Titulo = styled.h1`
  font-family: ${defaultTheme.fonts.secondary};
  color: #333;
  line-height: 1.2;
  font-size: 1.3em;
`

const Content = styled.div`
  min-height: 90vh;
  padding: 10px 20px;
  font-family: ${defaultTheme.fonts.secondary};
  font-size: 14px;
`

const BriefingInfo = styled.li`
  color: ${defaultTheme.colors.grey.light};
  font-weight: normal;
  font-size: 0.9em;
  & b {
    font-weight: bold;
    color: ${defaultTheme.colors.text.dark};
  }
`

const Group = styled.div`
  padding: 20px;
`

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  td {
    vertical-align: top;
  }
`

const Conclusion = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 30px;
  width: 80%;
  & div  {
    width: 50%;
  }
`

const SubTitulo = styled.h2`
  color: #ccc;
  font-family: ${defaultTheme.fonts.primary};
  line-height: 1.6;
  padding: 10px 10px 0 10px;
`

const Label = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  box-sizing: border-box;
  padding: 5px 5px 5px 0px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
`

const LabelBillingConditions = styled.label`
  background-color: #e8e8e8;
  border-radius: 15px;
  box-sizing: border-box;
  color: ${props => props.error ? 'red' : '#484848'};
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 500;
  min-height: 25px;
  padding: 10px;
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
  width: fit-content;
  height: 100%;
`

const FixedDiv = styled.div`
  position: fixed;
  top: 46px;
  bottom: 0;
  width: 25%;
  overflow-y: scroll;
  margin-top: 15px;
`

const LinkShowDetails = styled.a`
  display: block;
  width: 100%;
  text-align: right;
  font-size: 13px;
  color: ${defaultTheme.colors.grey.medium};
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  color: #ccc;
  text-decoration: underline;
  cursor: pointer;
  padding-left: 10px;
`

const Background = styled.div`
  ${props => props.background
    ? `background: url(${props.background}) center top no-repeat`
    : `
      background: url(${images.fallbackRooms}) center top no-repeat
      background-repeat:   no-repeat;
      background-position: center center;
      background-size: auto; };
    `}
  background-size: 100%;
  background-color: #fff;
  height: 150px;
  width: 100%;
  cursor: pointer;
`

const Link = styled.a`
  font-size: 13px;
  cursor: pointer;
  font-family: ${defaultTheme.fonts.primary};
  text-decoration: underline;
  font-weight: 300;
  color: ${defaultTheme.colors.primary.default};
`

const Tag = styled.div`
  cursor: pointer;
  padding: 2px 3px;
  font-size: 10px;
  font-family: ${defaultTheme.fonts.primary};
  color: ${props => props.status === 'COMISSIONAMENTO' ? '#484848' : '#FAFAFA'};
  background-color: ${props => props.status === 'REVIEW' ? '#F39C12' : (props.status === 'COMISSIONAMENTO' ? '#E8E8E8' : '#009688')};
  border: none;
  border-radius: 15px;
  position: absolute;
  right: ${props => props.status === 'REVIEW' ? '10px' : 'auto'};
  bottom: 10px;
  text-transform: uppercase;
  z-index: 9;
`

function formatTime (time) {
  time = time.split(':')
  if (time[0] === '00' && time[1] === '00') return 'Indefinido'
  return `${time[0]}:${time[1]} `
}

const BluePrint = (props) => {
  const { url } = props
  return (
    <div onClick={() => window.open(url, "_blank")} style={{ width: '45%', cursor: 'pointer' }}>
      <div style={{ display: 'flex', margin: 'auto', justifyContent: 'space-between' }}>
        <Icons style={{ margin: 'auto' }} width={'20px'} height={'20px'} icon={'paperclip'} color={defaultTheme.colors.grey.medium} />
        <Label style={{ width: '100%', textDecoration: 'underline' }}>ver planta baixa</Label>
      </div>
    </div>
  )
}

const MeetingRoomDetails = (props) => {
  const { maxCapacity, roomHeight, footage, bluePrint, name } = props
  return (
    <div style={{ padding: '20px', paddingRight: '0px' }}>
      <h3>{name}</h3>
      <div style={{ display: 'flex', width: '100%' }}>
        <Group style={{ display: 'flex', width: '60%', padding: '0px' }}>
          <Label style={{ margin: 'auto' }}>Área: <br />{footage}m²</Label>
          <Label style={{ margin: 'auto' }}>Pé direito: <br />{roomHeight}m</Label>
          <Label style={{ margin: 'auto' }}>Cap. máx: <br />{maxCapacity} pessoas</Label>
        </Group>
        {bluePrint && (<BluePrint url={bluePrint} />)}
      </div>
    </div>
  )
}

//verifica se possui room type igual e coloca numeração
const colocaNumeracao = (rooms, id, format) => {
  const lista = rooms.filter(item => item.roomFormat === format)
  if (lista && lista.length > 1) {
    return `(${lista.findIndex(item => item.id === id) + 1})`
  } else return ''
}

const RoomCard = (props) => {
  const room = props.budget && props.budget.room
  function getMaxCapacity (capacities) {
    return Object.keys(capacities).reduce((maxCap, roomFormat) => {
      return maxCap <= capacities[roomFormat]
        ? capacities[roomFormat]
        : maxCap
    }, 0)
  }
  if (props.budget && props.budget.hasAvailability) {
    return <Card style={{ width: '100%' }}>
      <Title style={{ fontSize: '15px', marginBottom: '0px' }}>{props.roomFormat} {colocaNumeracao(props.rooms, props.id, props.roomFormat)}</Title>
      <Link style={{ marginLeft: 'auto' }} onClick={() => props.showItem(props.id)}>{props.showItems.find(item => item.id === props.id) ? 'ocultar informações solicitadas para esta sala' : 'ver informações solicitadas para esta sala'}</Link>
      <Group style={{ padding: '0px', pointerEvents: 'none' }}>
        <label style={{ position: 'absolute', right: '10px', top: '20px' }}>
          <Money>{formatCurrency(props.budget.totalPriceWithoutFees.amount, false)}</Money>
        </label>
        <Label style={{ fontSize: '12px', listStyle: 'none', position: 'absolute', right: '10px', top: '40px', textAlign: 'right' }}>valor total da sala</Label>
        <Label style={{ fontSize: '12px', listStyle: 'none', position: 'absolute', right: '10px', top: '55px', textAlign: 'right' }}>+{(props.budget.room.taxes.amount)}% em taxas</Label>
      </Group>
      {props.showItems.find(item => item.id === props.id) && <>
        <Table style={{ marginTop: '20px' }}>
          <tr>
            <th>
              <Label>Datas:</Label>
            </th>
            <th>
              <Label>Formato de sala:</Label>
            </th>
          </tr>
          <tr>
            <td>
              <Label style={{ fontWeight: '600', color: '#484848' }}><b>{formatDate(props.startDate)} - {formatDate(props.endDate)}</b></Label>
            </td>
            <td>
              <Label style={{ fontWeight: '600', color: '#484848' }}><b>{props.roomType}</b></Label>
            </td>
          </tr>
        </Table>
        <Table>
          <tr>
            <th>
              <Label>Número de pessoas:</Label>
            </th>
            <th>
              <Label>Observações do fornecedor:</Label>
            </th>
          </tr>
          <tr>
            <td>
              <Label style={{ fontWeight: '600', color: '#484848' }}><b>{props.amountOfPeople} pessoas</b></Label>
            </td>
            <td>
              <Label style={{ fontWeight: '600', color: '#484848' }}><b>{props.budget.observations || 'Sem observações'}</b></Label>
            </td>
          </tr>
        </Table>
      </>}
      {props.budget ? <>
        {props.budget && props.budget.room && <Group style={{ padding: '0px', display: 'flex' }}>
          <Group style={{ padding: '0px', width: '30%' }}>
            <Slider fullScreenImages={props.budget.room.photos}>
              {props.budget.room.photos.map(photo => {
                return <div>
                  <Background background={photo} />
                </div>
              })}
            </Slider>
          </Group>
          <Group style={{ padding: '0px', width: '70%', margin: 'auto' }}>
            <MeetingRoomDetails
              maxCapacity={getMaxCapacity(room.capacity)}
              roomHeight={room.rooms_height}
              footage={room.footage}
              bluePrint={room.blue_print}
              name={room.name}
            />
          </Group>
        </Group>}
      </>
        : <p><center> Nenhum orçamento recebido para este pedido de sala </center></p>
      }
    </Card>
  } else {
    return <Card style={{ width: '100%', opacity: 0.5 }}>
      <Title style={{ fontSize: '15px', marginBottom: '0px' }}>{props.roomFormat} {colocaNumeracao(props.rooms, props.id, props.roomFormat)}</Title>
      <Link style={{ marginLeft: 'auto' }} onClick={() => props.showItem(props.id)}>{props.showItems.find(item => item.id === props.id) ? 'ocultar informações solicitadas para esta sala' : 'ver informações solicitadas para esta sala'}</Link>
      <Group style={{ padding: '0px', pointerEvents: 'none' }}>
        <label style={{ position: 'absolute', right: '10px', top: '20px' }}>
          <Money>{formatCurrency(props.budget ? props.budget.totalPriceWithoutFees.amount : 0, false)}</Money>
        </label>
        <Label style={{ fontSize: '12px', listStyle: 'none', position: 'absolute', right: '10px', top: '40px', textAlign: 'right' }}>valor total da sala sem taxas</Label>
        <Label style={{ fontSize: '12px', listStyle: 'none', position: 'absolute', right: '10px', top: '55px', textAlign: 'right' }}>+{((props.budget && props.budget.room) ? props.budget.room.taxes.amount : 0)}% em taxas</Label>
      </Group>
      {props.showItems.find(item => item.id === props.id) && <>
        <Table style={{ marginTop: '20px' }}>
          <tr>
            <th>
              <Label>Datas:</Label>
            </th>
            <th>
              <Label>Formato de sala:</Label>
            </th>
          </tr>
          <tr>
            <td>
              <Label style={{ fontWeight: '600', color: '#484848' }}><b>{formatDate(props.startDate)} - {formatDate(props.endDate)}</b></Label>
            </td>
            <td>
              <Label style={{ fontWeight: '600', color: '#484848' }}><b>{props.roomType}</b></Label>
            </td>
          </tr>
        </Table>
        <Table>
          <tr>
            <th>
              <Label>Número de pessoas:</Label>
            </th>
            <th>
              <Label>Observações do fornecedor:</Label>
            </th>
          </tr>
          <tr>
            <td>
              <Label style={{ fontWeight: '600', color: '#484848' }}><b>{props.amountOfPeople} pessoas</b></Label>
            </td>
            <td>
              <Label style={{ fontWeight: '600', color: '#484848' }}><b>{(props.budget && props.budget.observations) || 'Sem observações'}</b></Label>
            </td>
          </tr>
        </Table>
      </>}
      <Label>Sala indisponível neste hotel</Label>
    </Card>
  }
}


const Total = (props) => {
  return (
    <div style={props.style}>
      <p style={{
        color: defaultTheme.colors.grey.medium,
        fontFamily: defaultTheme.fonts.primary,
        fontSize: '13px',
        margin: 'auto', textAlign: 'center',
        fontWeight: 100
      }}>{props.aPartirDe ? 'a partir de' : 'valor total sem taxas'}</p>
      <Money style={{ marginRight: 'auto', textAlign: 'center' }}>{props.children}</Money>
    </div>
  )
}

const NUMERO_HORAS_AVISAR_DEADLINE = 3

class ResumoComAgreement extends Component {
  state = {
    showMessageIcon: false,
    unseeMessages: 0,
    isLoading: true,
    todosAprovados: false,
    verMais: false,
    modalTemCerteza: false,
    modalRecusarStatus: '',
    modalAprovarFornecedor: false,
    modalRecusarFornecedor: false,
    justification: '',
    verMaisDetalhes: '',
    agreedWithTerms: false,
    visualizaHistorico: false,
    isDeadline: false,
    selected: '',
    toEnd: '',
    lodging: {
      budgets: [],
      budgetId: '',
      includedItems: {}
    },
    room: {
      showItems: [],
      hotel: false,
      budget: false,
      briefing: false,
    },
    foodsAndBeverages: {
      showItems: [],
      budgets: [],
      budgetId: ''
    },
    equipments: {
      showItems: [],
      briefing: {},
      budget: {}
    },
    event: {},
    hotel: {}
  }

  isExpired (date) { return moment(date) <= moment() }

  getTotalPriceLodging = () => {
    return this.state.lodging.requested ? this.state.lodging.budgets.reduce((total, budget) => {
      total += budget.totalPrice.amount + (this.state.lodging.itemsOnApartmentPrice && budget.hasAvailability ? this.state.lodging.itemsOnApartmentPrice.amount * budget.numberOfApartments : 0)
      return total
    }, 0) : 0
  }

  getTotalPriceRoom = () => {
    return (this.state.room.requested && this.state.room.budget)
      ? this.state.room.budget.roomsBudget.reduce((total, budget) => total + budget.totalPriceWithoutFees.amount, 0)
      : 0
  }

  getTotalPriceFoodsAndBeverages = () => {
    return this.state.foodsAndBeverages.requested ? this.state.foodsAndBeverages.budgets.reduce((total, budget) => {
      if (budget.budgets.length > 0) {
        budget.budgets.forEach(item => {
          if (item.selected) { total += item.totalWithoutFee.amount }
        })
      } else {
        Object.keys(budget.budgets)
          .filter(key => budget.budgets[key])
          .forEach(key => { total += budget.budgets[key].totalWithoutFee.amount })
      }
      return total
    }, 0) : 0
  }

  getTotalPriceEquipments = () => {
    return (this.state.equipments.requested && this.state.equipments.budget && this.state.equipments.budget.total) ? this.state.equipments.budget.total.amount : 0
  }

  getTotal = () => {
    return this.getTotalPriceLodging() + this.getTotalPriceRoom() + this.getTotalPriceFoodsAndBeverages() + this.getTotalPriceEquipments()
  }

  getTotalWithFee = () => {
    return this.applyFee(this.getManagementFee(), this.getTotal())
  }

  getManagementFee () {
    return this.state.event
      ? this.state.event.managementFee
      : {
        amount: 0,
        type: 'PERCENTAGE'
      }
  }

  applyFee (fee, value) {
    if (!fee) return 0
    return (fee.type === 'PERCENTAGE')
      ? Math.round(Number((value * (1 + (fee.amount / 100)))))
      : (value + fee.amount)
  }

  componentDidMount () {
    this.loadDataServices()
      .then(() => this.loadDataEvent())
      .then(() => this.loadHistory())
      .then(() => this.getUnseenCountMessages())

  }

  goBack = () => {
    this.setState({
      isLoading: true
    })
    const { history } = this.props
    const { eventId, accountId } = this.props.match.params
    const { api: { base, version, routes: { events } } } = config
    const endpoint = `${base}${version}/budget/lodging/${this.state.lodging.budgetId}/accept`
    Post(endpoint)
      .then(() => {
        history.push(`/organizer${events}/${eventId}/budget/account/${accountId}`)
      }).catch(err => {
        console.error(err.message)
        this.setState({
          isLoading: false
        })
        alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
      })
  }

  getIncludedItems = () => {
    var included = ''
    var count = 0
    Object.keys(this.state.lodging.includedItems).forEach(key => {
      if (this.state.lodging.includedItems[key] && key !== 'others') {
        if (count === 0) {
          included += getNameFromKey(key)
        } else {
          included += ', ' + getNameFromKey(key)
        }
        count++
      } else if (key === 'others' && this.state.lodging.includedItems[key].sent) {
        if (count === 0) {
          included += this.state.lodging.includedItems[key].description
        } else {
          included += ', ' + this.state.lodging.includedItems[key].description
        }
        count++
      }
    })
    return included
  }

  getBudgetByBriefingId (roomBriefingId) {
    const budget = this.state.room.budget
    return budget && budget.roomsBudget.find(singleRoomBudget => {
      return singleRoomBudget.roomBriefingId === roomBriefingId
    })
  }

  showItem = (id) => {
    var room = this.state.room
    var item = room.briefing.rooms.find(item => item.id === id)
    if (item) {
      var showItem = room.showItems.findIndex(item => item.id === id)
      if (showItem !== -1) {
        room.showItems.splice(showItem, 1);
      } else {
        room.showItems.push({
          id: id
        })
      }
      this.setState({ room: room })
    }
  }

  showItemFAB = (id) => {
    var foodsAndBeverages = this.state.foodsAndBeverages
    var showItem = foodsAndBeverages.showItems.findIndex(item => item.id === id)
    if (showItem !== -1) {
      foodsAndBeverages.showItems.splice(showItem, 1);
    } else {
      foodsAndBeverages.showItems.push({
        id: id
      })
    }
    this.setState({ foodsAndBeverages: foodsAndBeverages })
  }

  itemClickFAB = (id, session) => {
    const budgets = this.state.foodsAndBeverages.budgets
    const index = budgets.findIndex(budget => budget.name === session)
    if (index !== -1) {
      const itemIndex = budgets[index].budgets.findIndex(item => item.id === id)
      if (itemIndex !== -1) {
        budgets[index].budgets = budgets[index].budgets.map(item => {
          item.selected = false
          return item
        })
        budgets[index].budgets[itemIndex].selected = true
        this.setState({ budgets })
      }
    }
  }

  visualizaDetalhe = (detalhes) => {
    this.setState({ verMais: true, verMaisDetalhes: detalhes })
  }

  calculaValorTotalRoomService = () => {
    return this.state.foodsAndBeverages.requested ? this.state.foodsAndBeverages.budgets.filter(item => item.name === 'roomService' || item.name === 'tray').reduce((total, budget) => {
      if (budget.budgets.length > 0) {
        budget.budgets.forEach(item => {
          if (item.selected) { total += item.totalWithoutFee.amount }
        })
      } else {
        Object.keys(budget.budgets)
          .filter(key => budget.budgets[key])
          .forEach(key => { total += budget.budgets[key].totalWithoutFee.amount })
      }
      return total
    }, 0) : 0
  }

  FABCard = (item) => {
    const fee = this.state.foodsAndBeverages && this.state.foodsAndBeverages.fee ? this.state.foodsAndBeverages.fee.amount : null
    const feeText = (fee > 0) ? `+ ${fee}% em taxas` : '- sem taxas'
    return <Card style={{ padding: '10px', width: '100%', position: 'relative' }}>
      <Title style={{ fontSize: '15px', marginBottom: '0px' }}>{getNameFromKey(item.name)}</Title>
      <Link style={{ marginLeft: 'auto' }} onClick={() => this.showItemFAB(item.name)}>{this.state.foodsAndBeverages.showItems.find(showItem => showItem.id === item.name) ? 'ocultar informações solicitadas para este cardápio' : 'ver informações solicitadas para este cardápio'}</Link>
      {item.valoresParaReferencia && <Group style={{ display: 'flex', paddingBottom: '0px' }}>
        <Icons
          color={defaultTheme.colors.whiteLabel.secondary}
          width={20}
          height={20}
          style={{ margin: 'auto 1px' }}
          icon='exclamation-circle' />
        <Label style={{ color: defaultTheme.colors.whiteLabel.secondary, fontWeight: 600 }}>ATENÇÃO! Itens e valores apenas para referência, favor contatar o hotel para mais detalhes sobre o cardapio e seguir para fechamento.</Label>
      </Group>}
      <Group style={{ padding: '0px', pointerEvents: 'none' }}>
        <label style={{ position: 'absolute', right: '10px', top: '20px' }}>
          {item.name !== 'roomService' && <Money>{formatCurrency(
            item.budgets.length > 0 && item.budgets.find(budget => budget.selected) ?
              (item.budgets.find(budget => budget.selected).totalWithoutFee.amount > 0 ? item.budgets.find(budget => budget.selected).totalWithoutFee.amount : '-') :
              '-', false)}</Money>}
          {item.name === 'roomService' && <Money>{formatCurrency(this.calculaValorTotalRoomService() > 0 ? this.calculaValorTotalRoomService() : '-', false)}</Money>}
        </label>
        <Label style={{ fontSize: '12px', listStyle: 'none', position: 'absolute', right: '10px', top: '40px', textAlign: 'right' }}>valor do cardápio {feeText}</Label>
        {item.name !== 'roomService' && <Label style={{ fontSize: '12px', listStyle: 'none', position: 'absolute', right: '10px', top: '55px', textAlign: 'right' }}>({item.amountOfPeople} pessoas) x {item.briefing.requests.length} dias</Label>}
      </Group>
      <br />
      <br />
      {this.state.foodsAndBeverages.showItems.find(showItem => showItem.id === item.name) && <>
        <Table style={{ marginTop: '10px' }}>
          <tr>
            <th style={{ verticalAlign: 'middle' }}>
              <Label>Datas:</Label>
            </th>
            <th style={{ verticalAlign: 'middle' }}>
              <Label>Horário de início:</Label>
            </th>
          </tr>
          <tr>
            <td>
              <Label style={{ fontWeight: '600', color: '#484848' }}><b>{
                item.briefing && item.briefing.requests.map((briefing, index) => {
                  if (index !== 0) return ' - ' + formatDate(briefing.start)
                  return formatDate(briefing.start)
                })
              }</b></Label>
            </td>
            <td>
              <Label style={{ fontWeight: '600', color: '#484848' }}><b>{item.briefing && item.briefing.requests.length > 0 && (item.briefing.requests[0].startTime === '00:00:00' ? 'Horário não informado' : formatTime(item.briefing.requests[0].startTime))}</b></Label>
            </td>
          </tr>
        </Table>
        <Table style={{ marginTop: '10px' }}>
          <tr>
            <th style={{ verticalAlign: 'middle' }}>
              <Label>Número de pessoas:</Label>
            </th>
            {(item.name === 'dinner' || item.name === 'lunch' || item.name === 'thematicDinner') && <th style={{ verticalAlign: 'middle' }}>
              <Label>Privativo:</Label>
            </th>}
            {(item.briefing && item.briefing.requests.length > 0 && item.briefing.requests[0].beverages) && <th style={{ verticalAlign: 'middle' }}>
              <Label>Pacote de bebida solicitado:</Label>
            </th>}
          </tr>
          <tr>
            <td>
              <Label style={{ fontWeight: '600', color: '#484848' }}><b>{item.amountOfPeople} pessoas</b></Label>
            </td>
            {(item.name === 'dinner' || item.name === 'lunch' || item.name === 'thematicDinner') && <td>
              <Label style={{ fontWeight: '600', color: '#484848' }}><b>{(item.briefing && item.briefing.isPrivate) ? 'Sim' : 'Não'}</b></Label>
            </td>}
            {(item.briefing && item.briefing.requests.length > 0 && item.briefing.requests[0].beverages) && <td>
              <Label style={{ fontWeight: '600', color: '#484848' }}><b>{getNameFromKey(item.briefing && item.briefing.requests.length > 0 && item.briefing.requests[0].beverages)}</b></Label>
            </td>}
          </tr>
        </Table>
      </>
      }
      {item.name !== 'roomService' && <Label>Selecione uma das opções de cardápio abaixo</Label>}
      <div style={{ display: 'flex', flexFlow: 'row wrap', alignContent: 'space-between', justifyContent: 'space-between' }}>
        {
          item.budgets.length > 0 && item.budgets.map((budget, index) => {
            return <label style={{ width: 'calc((100% / 3) - 10px)' }} htmlFor={`${item.name}_${budget.item.name}${index}`}>
              <Card onClick={() => this.itemClickFAB(budget.id, item.name)} key={`item_${index}`} style={{ paddingBottom: '10px', width: '100%' }} >
                <Group style={{ display: 'flex', padding: '0px' }}>
                  <Label style={{ fontWeight: '600', color: '#484848' }}><b>{budget.item.name}</b></Label>
                  <input style={{ width: '25px', height: '20px' }} name={`${item.name}_${budget.item.name}${index}`} id={`${item.name}_${budget.item.name}${index}`} type="radio" value="option" checked={budget.selected} onChange={() => this.itemClickFAB(budget.id, item.name)} />
                </Group>
                <Label style={{ marginTop: '-10px' }}>{formatCurrency(budget.price.amount)} / pessoa / dia</Label>
                <Label style={{
                  minHeight: '100px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 4
                }}>{budget.item.description}</Label>
                {budget.item.description.length > 150 && <LinkShowDetails onClick={() => this.visualizaDetalhe(budget.item.description)}>ver mais</LinkShowDetails>}
              </Card>
            </label>
          })
        }
      </div>
      <div style={{ display: 'flex', flexFlow: 'row wrap', alignContent: 'space-between', justifyContent: 'space-between' }}>
        {
          item.name === 'roomService' &&
          Object.keys(item.budgets).filter(key => item.budgets[key]).map((key, index) => {
            return <Card key={`roomServiceItem_${index}`} style={{ paddingBottom: '10px', width: 'calc((100% / 3) - 10px)' }}>
              <Label style={{ fontWeight: '600', color: '#484848', paddingBottom: '20px' }}><b>{getNameFromKey(item.budgets[key].item.name)}</b></Label>
              <Label style={{ marginTop: '-25px' }}>{formatCurrency(item.budgets[key].price.amount)} x {item.budgets[key].numberOfItems} {item.budgets[key].numberOfItems > 1 ? 'unidades' : 'unidade'} por dia</Label>
              <Label>{getNameFromKey(item.budgets[key].type)}{item.budgets[key].type ? ' - ' : ''}{item.budgets[key].quantity}</Label>
            </Card>
          })
        }
      </div>

      {
        item.name === 'roomService' &&
        Object.keys(this.state.foodsAndBeverages.budgets[this.state.foodsAndBeverages.budgets.findIndex(item => item.name === 'tray')].budgets).filter(key => this.state.foodsAndBeverages.budgets[this.state.foodsAndBeverages.budgets.findIndex(item => item.name === 'tray')].budgets[key]).length > 0 &&
        <Card key={`roomServiceItemTray`} style={{ paddingBottom: '10px' }}>
          <Title style={{ fontSize: '15px', marginBottom: '0px' }}>Bandeja</Title>
          <div style={{ display: 'flex', flexFlow: 'row wrap', alignContent: 'space-between', justifyContent: 'space-between' }}>
            {
              Object.keys(this.state.foodsAndBeverages.budgets[this.state.foodsAndBeverages.budgets.findIndex(item => item.name === 'tray')].budgets).filter(key => this.state.foodsAndBeverages.budgets[this.state.foodsAndBeverages.budgets.findIndex(item => item.name === 'tray')].budgets[key]).map((key, index) => {
                return <Card key={`roomServiceTrayItem_${index}`} style={{ paddingBottom: '10px', width: 'calc((100% / 3) - 10px)' }}>
                  <Label style={{ fontWeight: '600', color: '#484848', paddingBottom: '20px' }}><b>{getNameFromKey(this.state.foodsAndBeverages.budgets[this.state.foodsAndBeverages.budgets.findIndex(item => item.name === 'tray')].budgets[key].item.name)}</b></Label>
                  <Label style={{ marginTop: '-25px' }}>{formatCurrency(this.state.foodsAndBeverages.budgets[this.state.foodsAndBeverages.budgets.findIndex(item => item.name === 'tray')].budgets[key].price.amount)} x {this.state.foodsAndBeverages.budgets[this.state.foodsAndBeverages.budgets.findIndex(item => item.name === 'tray')].budgets[key].numberOfItems} bandejas por dia</Label>
                </Card>
              })
            }
          </div>
        </Card>
      }
    </Card>
  }

  clickedAgreement = () => { this.setState({ agreedWithTerms: !this.state.agreedWithTerms }) }

  selectService = (service) => {
    var state = this.state
    state.selected = service
    this.setState(state)
  }

  redirect = (service) => {
    const { api: { routes: { events } } } = config
    const { eventId, accountId } = this.props.match.params
    var win = window.open(`/organizer${events}/${eventId}/budget/account/${accountId}/${service}`);
    win.focus();
  }

  hasChosenAllServices () {
    const { hotel } = this.state
    const requestedRooms = hotel && hotel.briefings.rooms
    const requestedFoodsAndBeverages = hotel && hotel.briefings.foodsAndBeverages
    const requestedLodging = hotel && hotel.briefings.lodging
    return (
      (!requestedRooms || this.hasChosen(hotel.budgets.rooms))
      && (!requestedFoodsAndBeverages || this.hasChosen(hotel.budgets.foodsAndBeverages))
      && (!requestedLodging || this.hasChosen(hotel.budgets.lodging))
    )
  }

  hasChosen (serviceBudget) {
    return serviceBudget.all.some(budget => budget.status === 'ACCEPTED')
  }

  choosedSupplier = () => {

    // if(!this.state.agreedWithTerms) {
    //   alert('Você precisa ler e concordar com a política de fornecedor.')
    //   return
    // }

    if (!this.hasChosenAllServices()) {
      alert('Você precisa escolher/aceitar os orçamentos de cada serviço pedido para continuar')
      return
    }

    this.setState({ isLoading: true })
    const { history } = this.props
    const { api: { base, version, routes: { events } } } = config
    const { eventId, accountId } = this.props.match.params
    const services = {
      room: { id: this.state.hotel.budgets.rooms.mostRecent && this.state.hotel.budgets.rooms.mostRecent.id },
      foodsAndBeverages: { id: this.state.hotel.budgets.foodsAndBeverages.mostRecent && this.state.hotel.budgets.foodsAndBeverages.mostRecent.id },
      lodging: { id: this.state.hotel.budgets.lodging.mostRecent && this.state.hotel.budgets.lodging.mostRecent.id }
    }

    const chooseSupplier = `${base}${version}${events}/${eventId}/budget/place`
    Post(chooseSupplier, { services })
      .then((data) => {
        this.setState({ isLoading: false })
        history.push(`/organizer${events}/${eventId}/resume/budget/${accountId}`)
      })
      .catch(error => {
        alert(`Falha ao escolher o fornecedor, favor entrar em contato com a gente através do email: corp@evnts.com.br `)
        console.error(`Erro ao tentar escolher o fornecedor: ${error}`)
        this.setState({ isLoading: false })
      })
  }

  submitAll = () => {
    if (!this.isValidFAB()) return alert('Você deve selecionar ao menos um cardápio para cada categoria em Alimentos e Bebidas.')
    if (this.hasInValidStatus()) return alert('Você não pode concluir o orçamento pois o fornecedor ainda está revisando as mudanças editadas.')
    this.setState({
      isLoading: true
    })
    const { history } = this.props
    const { eventId, accountId } = this.props.match.params
    const { api: { base, version, routes: { events } } } = config

    const endpointLodging = this.state.lodging.requested && `${base}${version}/budget/lodging/${this.state.lodging.budgetId}/accept`
    const endpointRoom = this.state.room.requested && `${base}${version}/budget/room/${this.state.room.budget.id}/accept`
    const endpointEquipments = (this.state.equipments.requested && this.state.equipments.budget) ? `${base}${version}/budget/equipments/${this.state.equipments.budget.id}/accept` : `${base}${version}/budget/equipments/${eventId}/refuseAccepted`
    const endpointFAB = this.state.foodsAndBeverages.requested && `${base}${version}/budget/foodsAndBeverages/${this.state.foodsAndBeverages.budgetId}/accept`

    var listaDePromises = []
    this.state.lodging.requested && listaDePromises.push(Post(endpointLodging))
    this.state.room.requested && listaDePromises.push(Post(endpointRoom))
    this.state.equipments.requested && listaDePromises.push(Post(endpointEquipments))
    this.state.foodsAndBeverages.requested && listaDePromises.push(Post(endpointFAB, this.generateJSONFAB()))

    Promise.all(listaDePromises)
      .then(() => {
        const services = {
          room: { id: this.state.room.requested ? this.state.room.budget.id : null },
          foodsAndBeverages: { id: this.state.foodsAndBeverages.requested ? this.state.foodsAndBeverages.budgetId : null },
          lodging: { id: this.state.lodging.requested ? this.state.lodging.budgetId : null },
          equipments: { id: (this.state.equipments.requested && this.state.equipments.budget) ? this.state.equipments.budget.id : null }
        }
        const userType = window.localStorage.getItem('user_type')
        const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
        const chooseSupplier = `${base}${version}${eventEndpoint}/${eventId}/budget/place`
        Post(chooseSupplier, { services })
          .then(() => {
            this.setState({ isLoading: false })
            history.push(`/organizer${events}/${eventId}/resume/budget/${accountId}`)
          })
      }).catch(err => {
        console.error(err.message)
        this.setState({ isLoading: false })
        alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
      })
  }

  generateJSONFAB = () => {
    var chosenBudgets = {}
    this.state.foodsAndBeverages.budgets.filter(item => item.budgets.length > 0).forEach(budget => {
      chosenBudgets[budget.name] = {
        budgetId: budget.budgets.filter(item => item.selected)[0].id
      }
    })

    if (this.state.foodsAndBeverages.budgets.find(item => item.name === 'roomService')) {
      chosenBudgets.roomService = {
        budgetId: true
      }
      chosenBudgets.tray = {
        budgetId: true
      }
    }
    return { chosenBudgets }
  }

  isValidFAB = () => {
    let isValid = true
    this.state.foodsAndBeverages.budgets.map(budget => {
      let haveSelected = false
      budget.budgets.length > 0 && budget.budgets.map(item => {
        if (item.selected) {
          haveSelected = true
        }
        return item
      })
      if (!haveSelected && budget.budgets.length > 0) {
        isValid = false
      }
      return budget
    })
    return isValid
  }

  solicitarContraproposta = () => {
    this.setState({ abrirContraproposta: true })
  }

  isDeadline = () => {
    if (this.state.event && this.state.event.suppliersDeadline) {
      const deadline = Date.parse(this.state.event.suppliersDeadline.slice(0, -1))
      return (Date.now() > deadline)
    } else {
      return false
    }
  }

  calcHoursLeftDeadline = () => {
    if (this.state.event && this.state.event.suppliersDeadline) {
      const deadline = new Date(Date.parse(this.state.event.suppliersDeadline.slice(0, -1))).getTime()
      return this.diffHours(new Date().getTime(), deadline) || 1
    }
    return 1
  }

  isDeadlineCloser = () => {
    return this.calcHoursLeftDeadline() <= NUMERO_HORAS_AVISAR_DEADLINE
  }

  diffHours = (fromDate, toDate) => {
    const diff = (fromDate - toDate) / 36e5
    return Math.abs(Math.round(diff))
  }

  hasInValidStatus = () => {
    return (this.state.lodging.requested && this.state.lodging.status === 'REVIEW') ||
      (this.state.room.requested && this.state.room.budget && this.state.room.budget.status === 'REVIEW') ||
      (this.state.foodsAndBeverages.requested && this.state.foodsAndBeverages.status === 'REVIEW') ||
      (this.state.equipments.requested && this.state.equipments.budget && this.state.equipments.budget.status === 'REVIEW')
  }

  numeroDeServicosRequisitados = () => {
    return this.state.event.services && Object.keys(this.state.event.services).filter(item => this.state.event.services[item].requested).length
  }

  gerenciaResponsividade = () => {
    if (window.innerWidth < 600) return this.paginaMobile()
    else return this.paginaDesktop()
  }

  mostraNomeDoServico = (servico) => {
    switch (servico) {
      case 'room':
        return 'Salas'
      case 'foodsAndBeverages':
        return 'Alimentos e Bebidas'
      case 'lodging':
        return 'Hospedagem'
      case 'equipments':
        return 'Equipamentos'
      default:
        return ''
    }
  }

  verifaRole = (nivel) => {
    const roles = window.localStorage.getItem('user_roles') !== 'undefined' && JSON.parse(window.localStorage.getItem('user_roles'))
    return roles && roles.find(role => role === nivel)
  }

  getBillingConditions = (budgets) => {
    return Object.keys(budgets).filter(item => budgets[item] && budgets[item].mostRecent).reduce((obj, item) => {
      if (budgets[item].mostRecent.billingConditions) {
        obj = budgets[item].mostRecent.billingConditions
      }
      return obj
    }, false)
  }

  getRequestedServices = () => {
    return {
      supplierName: this.state.hotel.name,
      lodging: {
        id: this.state.lodging.requested ? this.state.lodging.budgetId : null,
        total: this.getTotalPriceLodging()
      },
      room: {
        id: this.state.room.requested ? (this.state.room.budget && this.state.room.budget.id) : null,
        total: this.getTotalPriceRoom()
      },
      foodsAndBeverages: {
        id: this.state.foodsAndBeverages.requested ? this.state.foodsAndBeverages.budgetId : null,
        total: this.getTotalPriceFoodsAndBeverages()
      },
      equipments: {
        id: this.state.equipments.requested ? (this.state.equipments.budget && this.state.equipments.budget.id) : null,
        total: this.getTotalPriceEquipments()
      },
      totalServices: formatCurrency(this.getTotalWithFee(), false)
    }
  }

  geraComissao = (fee) => {
    if (fee.type === 'PERCENTAGE') {
      return `${fee.amount}%`
    } else if (fee.type === 'ABSOLUTE') {
      return formatCurrency(fee.amount)
    } else {
      return ''
    }
  }

  ordenaContraPropostas = () => {
    var lista = []
    this.state.counterProposals && this.state.counterProposals.forEach(services => {
      services.forEach(service => service.counterProposals.forEach(counterProposal => {
        counterProposal.service = service.service
        lista.push(counterProposal)
      }))
    })
    return lista.sort((a, b) => {
      var primeiraData = new Date(a.date)
      if (a.supplierStatus !== 'SENDED' && !a.duplicata && !(a.supplierStatus === 'ACCEPTED' && a.requester === 'SUPPLIER')) {
        primeiraData = new Date(a.acceptedDate)
      }
      var segundaData = new Date(b.date)
      if (b.supplierStatus !== 'SENDED' && !b.duplicata && !(b.supplierStatus === 'ACCEPTED' && b.requester === 'SUPPLIER')) {
        segundaData = new Date(b.acceptedDate)
      }
      return segundaData.getTime() - primeiraData.getTime()
    })
  }

  pegaValorInicialAntesDaContraPropostaPorServico = (servico) => {
    return this.state.counterProposals ? this.state.counterProposals.reduce((total, services) => {
      total += services.reduce((soma, service) => {
        if (service.service === servico) {
          var lista = service.counterProposals.filter(cp => !cp.duplicata)
          if (lista.length > 0) {
            soma += lista[lista.length - 1].initialPrice
          }
        }
        return soma
      }, 0)
      return total
    }, 0) : 0
  }

  pegaDescontoDaContraProposta = (servico) => {
    var valorDoServico = 0
    if (servico === 'room') {
      valorDoServico = this.getTotalPriceRoom()
    } else if (servico === 'foodsAndBeverages') {
      valorDoServico = this.getTotalPriceFoodsAndBeverages()
    } else if (servico === 'lodging') {
      valorDoServico = this.getTotalPriceLodging()
    } else if (servico === 'equipments') {
      valorDoServico = this.getTotalPriceEquipments()
    }
    return this.pegaValorInicialAntesDaContraPropostaPorServico(servico) > 0 ? (this.pegaValorInicialAntesDaContraPropostaPorServico(servico) - valorDoServico) : 0
  }

  pegaDescontosDeTodosOsServicos = () => {
    var servicos = ['room', 'foodsAndBeverages', 'lodging', 'equipments']
    return servicos.reduce((soma, servico) => {
      soma += this.pegaDescontoDaContraProposta(servico)
      return soma
    }, 0)
  }

  verificaSeOServicoEstaDisponivel = (servico) => {
    switch (servico) {
      case 'lodging':
        return this.state.lodging.budgets.some(budget => budget.hasAvailability)
      case 'room':
        return this.state.room.budget && this.state.room.budget.hasAvailability
      case 'equipments':
        return this.state.equipments.budget && this.state.equipments.budget.hasAvailability
      default:
        return true;
    }
  }

  ativaModalTemCerteza = () => {
    this.setState({ modalTemCerteza: true })
  }

  aprovarFornecedor = () => {
    this.setState({
      isLoading: true,
      modalTemCerteza: false
    }, () => {
      const { eventId } = this.props.match.params
      const { api: { base, version, routes: { events } } } = config
      const endpoint = `${base}${version}${events}/${eventId}/approval/approve`
      Post(endpoint, this.generateJSONAprovacaoModal())
        .then(() => {
          if(this.verificaSeTodosOsServicosForamAprovados()) return this.setState({isLoading: false, todosAprovados: true })
          else return this.setState({ isLoading: false, modalAprovarFornecedor: true })
        }).catch(err => {
          console.error(err.message)
          this.setState({
            isLoading: false
          })
          alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
        })
    })
  }

  verificaSeTodosOsServicosForamAprovados = () => {
    const { event } = this.state
    const transfer = event && event.services.transfer.requested ? (event.approval && event.approval.services.transfer.status === 'AWAITING_REQUEST_CODE') : true
    const otherServices = event && event.services.otherServices.requested ? (event.approval && event.approval.services.otherServices.status === 'AWAITING_REQUEST_CODE') : true
    return transfer && otherServices
  }

  generateJSONAprovacaoModal = () => {
    const { accountId } = this.props.match.params
    const { lodging, room, foodsAndBeverages, equipments } = this.state
    const services = {}

    if (accountId) {
      if (lodging && (Array.isArray(lodging.budgets) ? lodging.budgets.length : lodging.budgets)) services.lodging = accountId
      if (room && (Array.isArray(room.budget) ? room.budget.length : room.budget)) services.room = accountId
      if (foodsAndBeverages && (Array.isArray(foodsAndBeverages.budgets) ? foodsAndBeverages.budgets.length : foodsAndBeverages.budgets)) services.foodsAndBeverages = accountId
      if (equipments && (Array.isArray(equipments.budget) ? equipments.budget.length : equipments.budget)) services.equipments = accountId
    }

    return { services }
  }

  recusarFornecedor = () => {
    this.setState({ modalRecusarFornecedor: true })
  }

  confirmarRecusarFornecedor = () => {
    this.setState({
      isLoading: true,
      modalRecusarFornecedor: false
    }, () => {
      const { history } = this.props
      const { eventId } = this.props.match.params
      const { api: { base, version, routes: { events } } } = config
      const endpoint = this.state.modalRecusarStatus === 'REJEITAR' ? `${base}${version}${events}/${eventId}/approval/refuse` : `${base}${version}${events}/${eventId}/approval/review`
      Post(endpoint, this.generateJSONRecusar())
        .then(() => {
          this.state.modalRecusarStatus === 'REJEITAR' ? history.push(`/organizer`) : history.push(`/organizer/event/${eventId}/budget/place`)
        }).catch(err => {
          console.error(err.message)
          this.setState({
            isLoading: false,
            modalRecusarFornecedor: true
          })
          alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
        })
    })
  }

  generateJSONRecusar = () => {
    const { lodging, room, foodsAndBeverages, equipments, justification } = this.state
    const services = {}

    if (justification) {
      if (lodging && (Array.isArray(lodging.budgets) ? lodging.budgets.length : lodging.budgets)) services.lodging = justification
      if (room && (Array.isArray(room.budget) ? room.budget.length : room.budget)) services.room = justification
      if (foodsAndBeverages && (Array.isArray(foodsAndBeverages.budgets) ? foodsAndBeverages.budgets.length : foodsAndBeverages.budgets)) services.foodsAndBeverages = justification
      if (equipments && (Array.isArray(equipments.budget) ? equipments.budget.length : equipments.budget)) services.equipments = justification
    }

    return { services }
  }

  verificaSeFoiSolicitadoAprovacao = (supplierId) => {
    const { event } = this.state
    return event && event.approval && Object.keys(event.approval.services).some(service => event.approval.services[service].supplierId === supplierId && event.approval.services[service].status === 'AWAITING_APPROVAL')
  }

  paginaMobile = () => {
    const isLoading = this.state.isLoading
    const { hotel, event } = this.state
    if (!hotel) return <> </>
    const hasRooms = hotel && hotel.budgets && hotel.budgets.rooms && hotel.budgets.rooms.all && hotel.budgets.rooms.all.length > 0
    const hasFaB = hotel && hotel.budgets && hotel.budgets.foodsAndBeverages && hotel.budgets.foodsAndBeverages.all && hotel.budgets.foodsAndBeverages.all.length > 0
    const hasLodging = hotel && hotel.budgets && hotel.budgets.lodging && hotel.budgets.lodging.all && hotel.budgets.lodging.all.length > 0
    const requestedLodging = hotel && hotel.briefings && hotel.briefings.lodging
    const requestedRooms = hotel && hotel.briefings && hotel.briefings.rooms
    const requestedFaB = hotel && hotel.briefings && hotel.briefings.foodsAndBeverages
    const requestedServiceLodging = event && event.services && event.services.lodging.requested
    const requestedServiceRooms = event && event.services && event.services.room.requested
    const requestedServiceFaB = event && event.services && event.services.foodsAndBeverages.requested
    return (
      <Container>
        {isLoading && <Loader />}
        <Header />
        {(!isLoading && hotel.budgets) && <Main>
          <Titulo>{hotel.name}</Titulo>
          {(hasLodging || (requestedLodging && requestedServiceLodging)) &&
            <ButtonIconExtra
              onClick={() => hasLodging && this.redirect('lodging')}
              placeholder={'Hospedagem'}
              description={hasLodging
                ? (<span>Bloqueio disponível até <b>{hotel.budgets.lodging ? formatDate(hotel.budgets.lodging.expireDate) : ''}</b></span>)
                : (<span> Fornecedor não enviou orçamento para este pedido</span>)
              }
              icon={'bed-outline'}>
              {!this.state.lodging.requested ? ('') : (<p>valor total escolhido</p>)}
              {hasLodging && <Total>{formatCurrency(this.getTotalPriceLodging())}</Total>}
            </ButtonIconExtra>
          }
          {requestedRooms && requestedServiceRooms &&
            <ButtonIconExtra
              onClick={() => hasRooms && this.redirect('rooms')}
              placeholder={'Salas'}
              description={hasRooms
                ? (<span>Bloqueio disponível até <b>{hotel.budgets.rooms ? formatDate(hotel.budgets.rooms.expireDate) : ''}</b></span>)
                : (<span> Fornecedor não enviou orçamento para este pedido</span>)
              }
              icon={'room-outline'}>
              {!this.state.room.requested ? ('') : (<p>valor total escolhido</p>)}
              {hasRooms && <Total>{formatCurrency(this.getTotalPriceRoom())}</Total>}
            </ButtonIconExtra>
          }
          {requestedFaB && requestedServiceFaB &&
            <ButtonIconExtra
              onClick={() => hasFaB && this.redirect('food-and-beverage')}
              placeholder={'Alimentos e bebidas'}
              icon={'food-beverage-outline'}>
              {!this.state.foodsAndBeverages.requested ? ('') : (<p>valor total escolhido</p>)}
              {hasFaB && <Total>{formatCurrency(this.getTotalPriceFoodsAndBeverages())}</Total>}
            </ButtonIconExtra>
          }
          {/* <Agreement>
            <input type='checkbox' name='agreement' id='agreement' />
            <label htmlFor='agreement' onClick = {this.clickedAgreement}>Li e concordo com a <a href="!#">política de fornecedor</a>.</label>
          </Agreement> */}
        </Main>}
        <Conclusion>
          <div>
            <Money>{formatCurrency(this.getTotalWithFee(), false)}</Money>
            <p>Valor total sem taxas</p>
          </div>
          <div>
            <Button
              type='submit'
              category={'secondary'}
              fontWeight={800}
              // disabled = {!this.state.agreedWithTerms}
              onClick={() => { this.choosedSupplier() }}
              full>
              Próximo
            </Button>
          </div>
        </Conclusion>
      </Container>
    )
  }

  getUnseenCountMessages = () => {
    const { api: { base, version, routes: { chat } } } = config
    const { eventId, accountId } = this.props.match.params
    const counterEndpoint = `${base}${version}${chat}/event/${eventId}/supplier/${accountId}/unseen-messages-count`
    Get(counterEndpoint)
      .then(resposta => this.handleOnUnseeMessage((resposta && resposta.unseenMessages) || 0))
      .catch(erro => { console.error(erro) })
  }

  checkSeenMessage = () => {
    const { api: { base, version, routes: { chat } } } = config
    const { eventId, accountId } = this.props.match.params
    const endpoint = `${base}${version}${chat}/event/${eventId}/supplier/${accountId}/check-seen-messages`
    this.handleOnUnseeMessage(0)

    Post(endpoint).then(() => { }).catch(err => {
      console.error(err.message)
    })
  }

  handleOnUnseeMessage = (count) => {
    this.setState({ showMessageIcon: (count > 0), unseeMessages: count })
  }

  paginaDesktop = () => {
    const { history } = this.props
    const { eventId, accountId } = this.props.match.params
    const eventName = this.state.event && this.state.event.name
    const eventStatus = this.state.event && this.state.event.status
    const DISPLAY_CHAT = !this.verifaRole('APPROVER') && eventId && accountId && eventName && eventStatus
    const EH_CHAIN = this.state.event && this.state.event.supplierChainId
    const NAO_EH_CHAIN = (!EH_CHAIN)
    const DISPLAY_BILLING_CONDITIONS = this.state.billingConditions && NAO_EH_CHAIN
    const isLoading = this.state.isLoading

    return <>
      <Header />
      <Main>
        <Body {...this.props} containerStyle={{ padding: '10px' }} pagina={'inicio'} menuLateralDireito={this.menuLateralDireito()}>
          {isLoading && <Loader />}
          <Options
            readOnly={this.state.readOnly}
            visualizarHistorico
            style={{ marginTop: '70px' }}
            clickHistorico={() => this.setState({ visualizaHistorico: true })}
            eventId={eventId}
            history={this.props.history}
            suppliersDeadline={this.state.event && this.state.event.suppliersDeadline}
            status={eventStatus}
            name={eventName}
            naoMostraEdicao={EH_CHAIN} />
          <ContraPropostaModal hoursLeft={() => this.calcHoursLeftDeadline()} isDeadlineCloser={() => this.isDeadlineCloser()} isDeadline={() => this.isDeadline()} requestedServices={this.getRequestedServices()} isOpen={this.state.abrirContraproposta} onClose={() => this.setState({ abrirContraproposta: false })} />
          <Content>
            <BreadCrumb options={
              [
                { link: '/organizer', name: 'Home' },
                { link: `/organizer/event/${eventId}/budget/place`, name: 'Local do evento' },
                { name: this.state.lodging.name }
              ]
            } />
            <Modal isOpen={this.state.todosAprovados} >
              <MoedaIcon />
              <Titulo>Fornecedores aprovados!</Titulo>
              <SubTitulo>Aguarde o número de requisição.</SubTitulo>
              <Button
                onClick={() => this.props.history.push(`/organizer`)}
                category={'secondary'}
                fontWeight={800}
                full>
                Ok!
              </Button>
            </Modal>
            <Modal isOpen={this.state.verMais} onClose={() => this.setState({ verMais: !this.state.verMais })} hasCloseButton>
              <Subtitle>{this.state.verMaisDetalhes}</Subtitle>
            </Modal>
            <ModalConfirmacao
              isOpen={this.state.modalTemCerteza}
              onCancel={() => this.setState({ modalTemCerteza: false })}
              onSubmit={this.aprovarFornecedor} />
            <ModalFornecedorAprovado
              isOpen={this.state.modalAprovarFornecedor}
              onSubmit={() => history.push(`/organizer/event/${eventId}/budget/place`)} />
            <Modal isOpen={this.state.modalRecusarFornecedor} onClose={() => this.setState({ modalRecusarFornecedor: !this.state.modalRecusarFornecedor })} hasCloseButton>
              <Icons
                color={defaultTheme.colors.secondary.default}
                width={100}
                height={100}
                style={{ margin: 'auto 1px' }}
                icon='blocked' />
              <Title style={{ paddingTop: '10px' }}>Recusar fornecedor</Title>
              <Subtitle style={{ marginBottom: '0px' }}>Escolha uma das opções a seguir:</Subtitle>
              <CheckBox
                onClick={() => this.setState({ modalRecusarStatus: 'REJEITAR', justification: '' })}
                checked={this.state.modalRecusarStatus === 'REJEITAR'}
                label={'Rejeitar orçamento'}>
                  {this.state.modalRecusarStatus === 'REJEITAR' && <Group style={{ textAlign: 'left' }}>
                    <Textarea
                      type={'text'}
                      label={'Justificativa'}
                      isRequired
                      placeholder={'Escreva aqui o motivo.'}
                      name={'justification'}
                      value={this.state.justification}
                      onChange={(input) => this.setState({ justification: input.target.value })} />
                  </Group>}
              </CheckBox>
              <CheckBox
                onClick={() => this.setState({ modalRecusarStatus: 'EDITAR', justification: '' })}
                checked={this.state.modalRecusarStatus === 'EDITAR'}
                label={'Editar orçamento'}>
                  {this.state.modalRecusarStatus === 'EDITAR' && <Group style={{ textAlign: 'left' }}>
                    <Textarea
                      type={'text'}
                      label={'Justificativa'}
                      isRequired
                      placeholder={'Escreva aqui o motivo.'}
                      name={'justification'}
                      value={this.state.justification}
                      onChange={(input) => this.setState({ justification: input.target.value })} />
                  </Group>}
              </CheckBox>
              <Button
                onClick={this.confirmarRecusarFornecedor}
                category={'secondary'}
                fontWeight={800}
                width={'50%'}
                full>
                Confirmar
              </Button>
            </Modal>
            <Title style={{ marginBottom: '0px', marginTop: '20px' }}>{this.state.hotel.name}</Title>
            {this.state.hotel.location && <AddressText address={this.state.hotel.location.address} number={this.state.hotel.location.number} city={this.state.hotel.location.city} province={this.state.hotel.location.province} />}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {DISPLAY_BILLING_CONDITIONS && <LabelBillingConditions>
                {this.state.billingConditions.percentage === 0 ?
                  `Pagamento faturado (${this.state.billingConditions.days} dias após emissão da fatura)` :
                  `Pagamento faturado - ${this.state.billingConditions.percentage}% devem ser pagos antecipadamente, e o restante em até ${this.state.billingConditions.days} dias após a emissão da fatura`}
              </LabelBillingConditions>}
              {DISPLAY_CHAT &&
                <ButtonChat
                  unreadCount={this.state.unseeMessages}
                  showIcon={this.state.showMessageIcon}
                  onClick={this.checkSeenMessage}
                  style={{ marginTop: '-30px', marginRight: '-8px', position: 'relative' }}
                />
              }
            </div>
            {!this.state.visualizaHistorico && this.hasInValidStatus() && <p style={{ textAlign: 'justify', color: '#F39C12', padding: '10px', fontFamily: defaultTheme.fonts.primary }}>
              Os items destacados na cor <span style={{ fontWeight: 'bold' }}>laranja</span> estão <span style={{ fontWeight: 'bold' }}>em revisão</span> pelo fornecedor.
            </p>}
            {!this.state.visualizaHistorico && this.numeroDeServicosRequisitados() < 4 && <Group style={{ display: 'flex', flexFlow: 'row wrap', alignContent: 'space-between', justifyContent: 'space-between', padding: '0px', paddingBottom: '20px' }}>
              {this.state.lodging.requested && <Card onClick={() => this.selectService('lodging')} style={{ minHeight: '150px', cursor: 'pointer', width: `calc((100% / ${this.numeroDeServicosRequisitados() > 4 ? 4 : this.numeroDeServicosRequisitados()}) - 10px)`, border: `${this.state.selected === 'lodging' ? `1px solid ${defaultTheme.colors.whiteLabel.primary}` : ''}` }}>
                {this.state.lodging.status === 'REVIEW' && <Tag status={'REVIEW'}>Revisão</Tag>}
                {temFeatureFlag('showCommission') && this.verificaSeOServicoEstaDisponivel('lodging') && this.state.lodging.commission && this.state.lodging.commission.hasCommission && <Tag status={'COMISSIONAMENTO'}>Comissão: {this.geraComissao(this.state.lodging.commission.fee)}</Tag>}
                <Group style={{ display: 'flex', padding: '0px' }}>
                  <LodgingIcon />
                  <Group style={{ width: '80%', padding: '0px' }}>
                    <Title style={{ marginBottom: '0px', fontSize: '15px' }}>Hospedagem</Title>
                    {this.verificaSeOServicoEstaDisponivel('lodging') && (this.state.lodging && this.isExpired(this.state.lodging.expireDate)
                      ? (<Subtitle style={{ marginBottom: '0px' }} expired>proposta sujeita a disponibilidade</Subtitle>)
                      : (<Subtitle style={{ marginBottom: '0px' }}>proposta válida até <b>{this.state.lodging ? formatDate(this.state.lodging.expireDate) : ''}</b></Subtitle>)
                    )}
                    {!this.verificaSeOServicoEstaDisponivel('lodging') && <Subtitle style={{ marginBottom: '0px', fontStyle: 'italic' }} expired>serviço indisponível</Subtitle>}
                  </Group>
                </Group>
                {this.verificaSeOServicoEstaDisponivel('lodging') && <Group style={{ padding: '0px', position: 'absolute', left: '0', right: '0', bottom: '15px', marginBottom: '10px' }}>
                  <Group style={{ display: 'flex', padding: '0px' }}>
                    <Total style={{ margin: 'auto' }}>{formatCurrency(this.getTotalPriceLodging(), false)}</Total>
                  </Group>
                </Group>}
              </Card>}
              {(this.state.room.requested && this.state.room.budget) && <Card onClick={() => this.selectService('room')} style={{ minHeight: '150px', cursor: 'pointer', width: `calc((100% / ${this.numeroDeServicosRequisitados() > 4 ? 4 : this.numeroDeServicosRequisitados()}) - 10px)`, border: `${this.state.selected === 'room' ? `1px solid ${defaultTheme.colors.whiteLabel.primary}` : ''}` }}>
                {this.state.room.budget.status === 'REVIEW' && <Tag status={'REVIEW'}>Revisão</Tag>}
                {temFeatureFlag('showCommission') && this.verificaSeOServicoEstaDisponivel('room') && this.state.room && this.state.room.budget && this.state.room.budget.commission && this.state.room.budget.commission.hasCommission && <Tag status={'COMISSIONAMENTO'}>Comissão: {this.geraComissao(this.state.room.budget.commission.fee)}</Tag>}
                <Group style={{ display: 'flex', padding: '0px' }}>
                  <RoomIcon />
                  <Group style={{ width: '80%', padding: '0px' }}>
                    <Title style={{ marginBottom: '0px', fontSize: '15px' }}>Salas</Title>
                    {this.verificaSeOServicoEstaDisponivel('room') && (this.state.room.budget && this.isExpired(this.state.room.budget.expireDate)
                      ? (<Subtitle style={{ marginBottom: '0px' }} expired>proposta sujeita a disponibilidade</Subtitle>)
                      : (<Subtitle style={{ marginBottom: '0px' }}>proposta válida até <b>{this.state.room.budget ? formatDate(this.state.room.budget.expireDate) : ''}</b></Subtitle>)
                    )}
                    {!this.verificaSeOServicoEstaDisponivel('room') && <Subtitle style={{ marginBottom: '0px', fontStyle: 'italic' }} expired>serviço indisponível</Subtitle>}
                  </Group>
                </Group>
                {this.verificaSeOServicoEstaDisponivel('room') && <Group style={{ padding: '0px', position: 'absolute', left: '0', right: '0', bottom: '15px', marginBottom: '10px' }}>
                  <Group style={{ display: 'flex', padding: '0px' }}>
                    <Total style={{ margin: 'auto' }}>{formatCurrency(this.getTotalPriceRoom(), false)}</Total>
                  </Group>
                </Group>}
              </Card>}
              {this.state.foodsAndBeverages.requested && <Card onClick={() => this.selectService('foodsAndBeverages')} style={{ minHeight: '150px', cursor: 'pointer', width: `calc((100% / ${this.numeroDeServicosRequisitados() > 4 ? 4 : this.numeroDeServicosRequisitados()}) - 10px)`, border: `${this.state.selected === 'foodsAndBeverages' ? `1px solid ${defaultTheme.colors.whiteLabel.primary}` : ''}` }}>
                {this.state.foodsAndBeverages.status === 'REVIEW' && <Tag status={'REVIEW'}>Revisão</Tag>}
                {temFeatureFlag('showCommission') && this.state.foodsAndBeverages.commission && this.state.foodsAndBeverages.commission.hasCommission && <Tag status={'COMISSIONAMENTO'}>Comissão: {this.geraComissao(this.state.foodsAndBeverages.commission.fee)}</Tag>}
                <Group style={{ display: 'flex', padding: '0px' }}>
                  <FoodsAndBeveragesIcon />
                  <Group style={{ width: '80%', padding: '0px' }}>
                    <Title style={{ marginBottom: '0px', fontSize: '15px' }}>Alimentos e bebidas</Title>
                  </Group>
                </Group>
                {EH_CHAIN && <Group
                  style={{
                    display: 'flex',
                    padding: '0px',
                    position: 'absolute',
                    left: 'auto',
                    right: 'auto',
                    bottom: '70px'
                  }}>
                  <Icons
                    color={defaultTheme.colors.whiteLabel.secondary}
                    width={20}
                    height={20}
                    style={{ margin: 'auto 1px' }}
                    icon='exclamation-circle' />
                  <Label style={{ color: defaultTheme.colors.whiteLabel.secondary, fontWeight: 600 }}>valor apenas para referência</Label>
                </Group>}
                <Group style={{ padding: '0px', position: 'absolute', left: '0', right: '0', bottom: '15px', marginBottom: '10px' }}>
                  <Group style={{ display: 'flex', padding: '0px' }}>
                    <Total aPartirDe style={{ margin: 'auto' }}>{EH_CHAIN ? '-' : formatCurrency(this.getTotalPriceFoodsAndBeverages(), false)}</Total>
                  </Group>
                </Group>
              </Card>}
              {this.state.equipments.requested && <Card onClick={() => this.selectService('equipments')} style={{ minHeight: '150px', cursor: 'pointer', width: `calc((100% / ${this.numeroDeServicosRequisitados() > 4 ? 4 : this.numeroDeServicosRequisitados()}) - 10px)`, border: `${this.state.selected === 'equipments' ? `1px solid ${defaultTheme.colors.whiteLabel.primary}` : ''}` }}>
                {(this.state.equipments.budget && this.state.equipments.budget.status === 'REVIEW') && <Tag status={'REVIEW'}>Revisão</Tag>}
                {temFeatureFlag('showCommission') && this.verificaSeOServicoEstaDisponivel('equipments') && this.state.equipments && this.state.equipments.budget && this.state.equipments.budget.commission && this.state.equipments.budget.commission.hasCommission && <Tag status={'COMISSIONAMENTO'}>Comissão: {this.geraComissao(this.state.equipments.budget.commission.fee)}</Tag>}
                <Group style={{ display: 'flex', padding: '0px' }}>
                  <EquipmentsIcon />
                  <Group style={{ width: '80%', padding: '0px' }}>
                    <Title style={{ marginBottom: '0px', fontSize: '15px' }}>Equipamentos</Title>
                    {!this.verificaSeOServicoEstaDisponivel('equipments') && <Subtitle style={{ marginBottom: '0px', fontStyle: 'italic' }} expired>serviço indisponível</Subtitle>}
                  </Group>
                </Group>
                {EH_CHAIN && <Group
                  style={{
                    display: 'flex',
                    padding: '0px',
                    position: 'absolute',
                    left: 'auto',
                    right: 'auto',
                    bottom: '70px'
                  }}>
                  <Icons
                    color={defaultTheme.colors.whiteLabel.secondary}
                    width={20}
                    height={20}
                    style={{ margin: 'auto 1px' }}
                    icon='exclamation-circle' />
                  <Label style={{ color: defaultTheme.colors.whiteLabel.secondary, fontWeight: 600 }}>valor apenas para referência</Label>
                </Group>}
                {this.verificaSeOServicoEstaDisponivel('equipments') && <Group style={{ padding: '0px', position: 'absolute', left: '0', right: '0', bottom: '15px', marginBottom: '10px' }}>
                  <Group style={{ display: 'flex', padding: '0px' }}>
                    <Total style={{ margin: 'auto' }}>{EH_CHAIN ? '-' : formatCurrency(this.getTotalPriceEquipments(), false)}</Total>
                  </Group>
                </Group>}
              </Card>}
            </Group>}

            {!this.state.visualizaHistorico && this.numeroDeServicosRequisitados() >= 4 && <Group style={{ display: 'flex', flexFlow: 'row wrap', alignContent: 'space-between', justifyContent: 'space-between', padding: '0px', paddingBottom: '20px' }}>
              {this.state.lodging.requested && <Card onClick={() => this.selectService('lodging')} style={{ minHeight: '150px', cursor: 'pointer', width: `calc((100% / ${this.numeroDeServicosRequisitados() > 4 ? 4 : this.numeroDeServicosRequisitados()}) - 10px)`, border: `${this.state.selected === 'lodging' ? `1px solid ${defaultTheme.colors.whiteLabel.primary}` : ''}` }}>
                {this.state.lodging.status === 'REVIEW' && <Tag status={'REVIEW'}>Revisão</Tag>}
                {temFeatureFlag('showCommission') && this.verificaSeOServicoEstaDisponivel('lodging') && this.state.lodging.commission && this.state.lodging.commission.hasCommission && <Tag status={'COMISSIONAMENTO'}>Comissão: {this.geraComissao(this.state.lodging.commission.fee)}</Tag>}
                <Group style={{ width: '80%', padding: '0px' }}>
                  <Title style={{ marginBottom: '0px', fontSize: '15px' }}>Hospedagem</Title>
                  {this.verificaSeOServicoEstaDisponivel('lodging') && (this.state.lodging && this.isExpired(this.state.lodging.expireDate)
                    ? (<Subtitle style={{ marginBottom: '0px' }} expired>proposta sujeita a disponibilidade</Subtitle>)
                    : (<Subtitle style={{ marginBottom: '0px' }}>proposta válida até <b>{this.state.lodging ? formatDate(this.state.lodging.expireDate) : ''}</b></Subtitle>)
                  )}
                  {!this.verificaSeOServicoEstaDisponivel('lodging') && <Subtitle style={{ marginBottom: '0px', fontStyle: 'italic' }} expired>serviço indisponível</Subtitle>}
                </Group>
                {this.verificaSeOServicoEstaDisponivel('lodging') && <Group style={{ padding: '0px', position: 'absolute', left: '0', right: '0', bottom: '15px', marginBottom: '10px' }}>
                  <Group style={{ display: 'flex', padding: '0px' }}>
                    <Total style={{ margin: 'auto' }}>{formatCurrency(this.getTotalPriceLodging(), false)}</Total>
                  </Group>
                </Group>}
              </Card>}
              {this.state.room.requested && <Card onClick={() => this.selectService('room')} style={{ minHeight: '150px', cursor: 'pointer', width: `calc((100% / ${this.numeroDeServicosRequisitados() > 4 ? 4 : this.numeroDeServicosRequisitados()}) - 10px)`, border: `${this.state.selected === 'room' ? `1px solid ${defaultTheme.colors.whiteLabel.primary}` : ''}` }}>
                {(this.state.room.budget && this.state.room.budget.status === 'REVIEW') && <Tag status={'REVIEW'}>Revisão</Tag>}
                {temFeatureFlag('showCommission') && this.verificaSeOServicoEstaDisponivel('room') && this.state.room && this.state.room.budget && this.state.room.budget.commission && this.state.room.budget.commission.hasCommission && <Tag status={'COMISSIONAMENTO'}>Comissão: {this.geraComissao(this.state.room.budget.commission.fee)}</Tag>}
                <Group style={{ width: '80%', padding: '0px' }}>
                  <Title style={{ marginBottom: '0px', fontSize: '15px' }}>Salas</Title>
                  {this.verificaSeOServicoEstaDisponivel('room') && (this.state.room.budget && this.isExpired(this.state.room.budget.expireDate)
                    ? (<Subtitle style={{ marginBottom: '0px' }} expired>proposta sujeita a disponibilidade</Subtitle>)
                    : (<Subtitle style={{ marginBottom: '0px' }}>proposta válida até <b>{this.state.room.budget ? formatDate(this.state.room.budget.expireDate) : ''}</b></Subtitle>)
                  )}
                  {!this.verificaSeOServicoEstaDisponivel('room') && <Subtitle style={{ marginBottom: '0px', fontStyle: 'italic' }} expired>serviço indisponível</Subtitle>}
                </Group>
                {this.verificaSeOServicoEstaDisponivel('room') && <Group style={{ padding: '0px', position: 'absolute', left: '0', right: '0', bottom: '15px', marginBottom: '10px' }}>
                  <Group style={{ display: 'flex', padding: '0px' }}>
                    <Total style={{ margin: 'auto' }}>{formatCurrency(this.getTotalPriceRoom(), false)}</Total>
                  </Group>
                </Group>}
              </Card>}
              {this.state.foodsAndBeverages.requested && <Card onClick={() => this.selectService('foodsAndBeverages')} style={{ minHeight: '150px', cursor: 'pointer', width: `calc((100% / ${this.numeroDeServicosRequisitados() > 4 ? 4 : this.numeroDeServicosRequisitados()}) - 10px)`, border: `${this.state.selected === 'foodsAndBeverages' ? `1px solid ${defaultTheme.colors.whiteLabel.primary}` : ''}` }}>
                {this.state.foodsAndBeverages.status === 'REVIEW' && <Tag status={'REVIEW'}>Revisão</Tag>}
                {temFeatureFlag('showCommission') && this.state.foodsAndBeverages.commission && this.state.foodsAndBeverages.commission.hasCommission && <Tag status={'COMISSIONAMENTO'}>Comissão: {this.geraComissao(this.state.foodsAndBeverages.commission.fee)}</Tag>}
                <Group style={{ width: '80%', padding: '0px' }}>
                  <Title style={{ marginBottom: '0px', fontSize: '15px' }}>Alimentos e bebidas</Title>
                </Group>
                {EH_CHAIN && <Group
                  style={{
                    display: 'flex',
                    padding: '0px',
                    position: 'absolute',
                    left: 'auto',
                    right: 'auto',
                    bottom: '70px'
                  }}>
                  <Icons
                    color={defaultTheme.colors.whiteLabel.secondary}
                    width={20}
                    height={20}
                    style={{ margin: 'auto 1px' }}
                    icon='exclamation-circle' />
                  <Label style={{ color: defaultTheme.colors.whiteLabel.secondary, fontWeight: 600 }}>valor apenas para referência</Label>
                </Group>}
                <Group style={{ padding: '0px', position: 'absolute', left: '0', right: '0', bottom: '15px', marginBottom: '10px' }}>
                  <Group style={{ display: 'flex', padding: '0px' }}>
                    <Total aPartirDe style={{ margin: 'auto' }}>{EH_CHAIN ? '-' : formatCurrency(this.getTotalPriceFoodsAndBeverages(), false)}</Total>
                  </Group>
                </Group>
              </Card>}
              {this.state.equipments.requested && <Card onClick={() => this.selectService('equipments')} style={{ minHeight: '150px', cursor: 'pointer', width: `calc((100% / ${this.numeroDeServicosRequisitados() > 4 ? 4 : this.numeroDeServicosRequisitados()}) - 10px)`, border: `${this.state.selected === 'equipments' ? `1px solid ${defaultTheme.colors.whiteLabel.primary}` : ''}` }}>
                {(this.state.equipments.budget && this.state.equipments.budget.status === 'REVIEW') && <Tag status={'REVIEW'}>Revisão</Tag>}
                {temFeatureFlag('showCommission') && this.verificaSeOServicoEstaDisponivel('equipments') && this.state.equipments.budget && this.state.equipments.budget.commission && this.state.equipments.budget.commission.hasCommission && <Tag status={'COMISSIONAMENTO'}>Comissão: {this.geraComissao(this.state.equipments.budget.commission.fee)}</Tag>}
                <Group style={{ display: 'flex', padding: '0px' }}>
                  <Group style={{ width: '80%', padding: '0px' }}>
                    <Title style={{ marginBottom: '0px', fontSize: '15px' }}>Equipamentos</Title>
                    {!this.verificaSeOServicoEstaDisponivel('equipments') && <Subtitle style={{ marginBottom: '0px', fontStyle: 'italic' }} expired>serviço indisponível</Subtitle>}
                  </Group>
                </Group>
                {EH_CHAIN && <Group
                  style={{
                    display: 'flex',
                    padding: '0px',
                    position: 'absolute',
                    left: 'auto',
                    right: 'auto',
                    bottom: '70px'
                  }}>
                  <Icons
                    color={defaultTheme.colors.whiteLabel.secondary}
                    width={20}
                    height={20}
                    style={{ margin: 'auto 1px' }}
                    icon='exclamation-circle' />
                  <Label style={{ color: defaultTheme.colors.whiteLabel.secondary, fontWeight: 600 }}>valor apenas para referência</Label>
                </Group>}
                {this.verificaSeOServicoEstaDisponivel('equipments') && <Group style={{ padding: '0px', position: 'absolute', left: '0', right: '0', bottom: '15px', marginBottom: '10px' }}>
                  <Group style={{ display: 'flex', padding: '0px' }}>
                    <Total style={{ margin: 'auto' }}>{EH_CHAIN ? '-' : formatCurrency(this.getTotalPriceEquipments(), false)}</Total>
                  </Group>
                </Group>}
              </Card>}
            </Group>}

            {!this.state.visualizaHistorico && this.state.selected === 'lodging' && <>
              <Label>Suas observações: {(this.state.hotel && this.state.hotel.briefings && this.state.hotel.briefings.lodging.observations) || 'Sem observações.'}</Label>
              <Label>Observações: {this.state.lodging.observations || 'Sem observações.'}</Label>
              <Label>Inclui: <b>{this.getIncludedItems()}.</b></Label>
              {this.state.lodging.budgets.map(budget => {
                if (budget.hasAvailability) {
                  return <Card key={`roomServiceItem_${0}`} style={{ width: '100%', paddingBottom: '10px' }}>
                    <Group style={{ padding: '0px' }}>
                      <Label style={{ fontWeight: 600, fontSize: '15px' }}>{budget.numberOfApartments}x {getNameFromKey(budget.item.keyName)} ({budget.category})</Label>
                      <label style={{ position: 'absolute', right: '5px', top: '20px' }}>
                        <Money>{formatCurrency(budget.totalPrice.amount, false)}</Money>
                      </label>
                      <Label style={{ fontSize: '12px', listStyle: 'none', position: 'absolute', right: '10px', top: '40px', textAlign: 'right' }}>{formatCurrency(budget.price ? budget.price.amount : 0)} / dia {(budget.fee && budget.fee.amount > 0) ? `+ ${budget.fee.amount}% taxas` : '- sem taxas'}</Label>
                    </Group>
                    <Group style={{ padding: '0px', display: 'flex', marginTop: '15px' }}>
                      <Icons
                        icon={'calendario'}
                        color={'gray'}
                        width={40}
                        height={20}
                        style={{ marginRight: '-5px', marginTop: '5px' }} />
                      <Label>
                        {formatDate(budget.item.dates[0])} - {formatDate(budget.item.dates[budget.item.dates.length - 1])}
                        - ({budget.numberOfDays} diárias) |
                            {(budget.item.keyName === 'single' || budget.item.keyName === 'double') && '1 cama de casal'}
                        {budget.item.keyName === 'twin' && '2 camas de solteiro'}
                        {budget.item.keyName === 'triple' && '3 camas de solteiro'}
                      </Label>
                    </Group>
                  </Card>
                } else {
                  return <Card key={`roomServiceItem_${0}`} style={{ paddingBottom: '10px', opacity: 0.5 }}>
                    <Group>
                      <Subtitle style={{ fontSize: '15px' }}>{budget.numberOfApartments}x {getNameFromKey(budget.item.keyName)}</Subtitle>
                      <label style={{ position: 'absolute', right: '50px', top: '30px' }}>
                        <BriefingInfo style={{ listStyle: 'none', color: 'black' }}>Quarto indisponível neste hotel</BriefingInfo>
                      </label>
                    </Group>
                    <BriefingInfo style={{ listStyle: 'none', paddingLeft: '15px', marginTop: '-20px' }}>{budget.category}</BriefingInfo>
                    <BriefingInfo style={{ listStyle: 'none', paddingLeft: '15px' }}><b>{budget.numberOfDays} diárias</b></BriefingInfo>
                    <BriefingInfo style={{ listStyle: 'none', paddingLeft: '15px' }}><b>Check-in: </b>{moment(budget.item.dates[0], 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br').format('DD-MM-YYYY')} <b style={{ paddingLeft: '10px' }}>Check-out: </b>{moment(budget.item.dates[budget.item.dates.length - 1], 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br').format('DD-MM-YYYY')}</BriefingInfo>
                    <BriefingInfo style={{ listStyle: 'none', paddingLeft: '15px' }}>{formatCurrency(budget.price ? budget.price.amount : 0)} / dia + {budget.fee ? budget.fee.amount : 0}% taxas</BriefingInfo>
                    {budget.item.keyName === 'single' && <Group style={{ width: '50%', margin: 'auto', display: 'flex', paddingLeft: '20px', alignItems: 'center' }}>
                      <Icons style={{ width: '30%' }} height='35px' icon='double-bed' />
                      <Label style={{ width: '70%' }}>1 cama de casal</Label>
                    </Group>}
                    {budget.item.keyName === 'double' && <Group style={{ width: '50%', margin: 'auto', display: 'flex', paddingLeft: '20px', alignItems: 'center' }}>
                      <Icons style={{ width: '30%' }} height='35px' icon='double-bed' />
                      <Label style={{ width: '70%' }}>1 cama de casal</Label>
                    </Group>}
                    {budget.item.keyName === 'twin' && <Group style={{ width: '50%', margin: 'auto', display: 'flex', paddingLeft: '20px', alignItems: 'center' }}>
                      <Icons style={{ width: '15%' }} height='35px' icon='single-bed' />
                      <Icons style={{ width: '15%' }} height='35px' icon='single-bed' />
                      <Label style={{ width: '70%', paddingLeft: '10px' }}>2 camas de solteiro</Label>
                    </Group>}
                    {budget.item.keyName === 'triple' && <Group style={{ width: '50%', margin: 'auto', display: 'flex', paddingLeft: '20px', alignItems: 'center' }}>
                      <Icons style={{ width: '15%' }} height='35px' icon='single-bed' />
                      <Icons style={{ width: '15%' }} height='35px' icon='single-bed' />
                      <Icons style={{ width: '15%' }} height='35px' icon='single-bed' />
                      <Label style={{ width: '55%', paddingLeft: '10px' }}>3 camas de solteiro</Label>
                    </Group>}
                  </Card>
                }
              })}
              {(this.state.lodging && this.state.lodging.itemsOnApartmentPrice && this.state.lodging.itemsOnApartmentPrice.amount > 0) && <Card style={{ width: '100%', paddingBottom: '10px' }}>
                <Group style={{ padding: '0px' }}>
                  <Label style={{ fontWeight: 600, fontSize: '15px' }}>Entrega de brindes</Label>
                  <label style={{ position: 'absolute', right: '5px', top: '20px' }}>
                    <Money>{formatCurrency(this.state.lodging.itemsOnApartmentPrice.amount * this.state.lodging.budgets.reduce((total, item) => total + item.numberOfApartments, 0), false)}</Money>
                  </label>
                  <Label style={{ fontSize: '12px', listStyle: 'none', position: 'absolute', right: '10px', top: '40px', textAlign: 'right' }}>{formatCurrency(this.state.lodging.itemsOnApartmentPrice.amount)} / quarto</Label>
                </Group>
                <Group style={{ display: 'flex', padding: '0px', marginTop: '15px' }}>
                  <Label style={{ width: 'fit-content', whiteSpace: 'nowrap' }}>{this.state.lodging.budgets.reduce((total, item) => total + item.numberOfApartments, 0)} quartos</Label>
                </Group>
              </Card>}
            </>
            }

            {!this.state.visualizaHistorico && this.state.selected === 'room' && <>
              <Label>Suas observações: {(this.state.hotel && this.state.hotel.briefings && this.state.hotel.briefings.rooms.observations) || 'Sem observações.'}</Label>
              <Group style={{ padding: '0px' }}>
                {this.state.room.briefing.rooms.map((roomBriefing, index) => <RoomCard
                  {...roomBriefing}
                  index={index}
                  rooms={this.state.room.briefing.rooms}
                  showItems={this.state.room.showItems}
                  showItem={(id) => this.showItem(id)}
                  budget={this.getBudgetByBriefingId(roomBriefing.id)}
                  key={roomBriefing.id} />)}
              </Group>
            </>
            }

            {!this.state.visualizaHistorico && this.state.selected === 'foodsAndBeverages' && <>
              <Label>Suas observações: {(this.state.hotel && this.state.hotel.briefings && this.state.hotel.briefings.foodsAndBeverages.observations) || 'Sem observações.'}</Label>
              <Label>Observações: {this.state.foodsAndBeverages.observations || 'Sem observações.'}</Label>
              <div>
                {this.state.foodsAndBeverages.budgets.filter(item => item.name !== 'tray').map((budget, index) => this.FABCard({...budget, valoresParaReferencia: !!EH_CHAIN, key:index }))}
              </div>
            </>
            }
            {!this.state.visualizaHistorico && this.state.selected === 'equipments' && <>
              <Label>Suas observações: {(this.state.hotel && this.state.hotel.briefings && this.state.hotel.briefings.equipments && this.state.hotel.briefings.equipments.observations) || 'Sem observações.'}</Label>
              {this.state.equipments.briefing && Object.keys(this.state.equipments.briefing)
                .filter(item => this.state.equipments.briefing[item] && this.state.equipments.briefing[item].requested).map((budgetItem, index) => {
                  let item = this.state.equipments.budget ? this.state.equipments.budget.equipments[budgetItem] : { hasAvailability: false }
                  const fee = this.state.equipments && this.state.equipments.budget && this.state.equipments.budget.fee ? this.state.equipments.budget.fee.amount : null
                  const feeText = (fee > 0) ? `+ ${fee}% em taxas` : '- sem taxas'

                  if (item.hasAvailability) {
                    return <Card key={`equipments_${index}`} style={{ width: '100%', paddingBottom: '10px' }}>
                      <Group style={{ padding: '0px' }}>
                        <Label style={{ fontWeight: 600, fontSize: '15px' }}>{budgetItem !== 'internet' ? getNameFromKey(budgetItem) : 'Internet'}</Label>
                        <label style={{ position: 'absolute', right: '5px', top: '20px' }}>
                          <Money>{formatCurrency(item.total.amount, false)}</Money>
                        </label>
                        <Label style={{ fontSize: '12px', listStyle: 'none', position: 'absolute', right: '10px', top: '40px', textAlign: 'right' }}>{budgetItem !== 'microphone' && `${formatCurrency(item.price.amount)} / ${budgetItem !== 'internet' ? 'unidade' : 'ponto de acesso'} / dia ${feeText}`}</Label>
                      </Group>
                      {EH_CHAIN && <Group style={{ display: 'flex', paddingBottom: '0px' }}>
                        <Icons
                          color={defaultTheme.colors.whiteLabel.secondary}
                          width={20}
                          height={20}
                          style={{ margin: 'auto 1px' }}
                          icon='exclamation-circle' />
                        <Label style={{ color: defaultTheme.colors.whiteLabel.secondary, fontWeight: 600 }}>ATENÇÃO! Itens e valores apenas para referência, favor contatar o hotel para mais detalhes sobre o cardapio e seguir para fechamento.</Label>
                      </Group>}
                      <Group style={{ padding: '0px', marginTop: '15px' }}>
                        <Label>
                          Observações: {item.observation || 'Sem observações'}
                        </Label>
                        <Label>Suas observações: {this.state.equipments.briefing[budgetItem].observation || 'Sem observações.'}</Label>
                        {budgetItem !== 'microphone' && item.requests.map(requestItem => <>
                          <Label style={{ marginTop: '10px' }}> Datas: {requestItem.dates.map(date => formatDate(date)).join(' - ')} </Label>
                          {requestItem.amount > 0 && <Label> {requestItem.amount} {requestItem.amount > 1 ? (budgetItem !== 'internet' ? 'unidades' : 'pontos de acesso') : (budgetItem !== 'internet' ? 'unidade' : 'ponto de acesso')}</Label>}
                          <Label>Descrição: {item.description}</Label>
                        </>
                        )}
                        {budgetItem === 'microphone' && item.requests.map(microphoneItem => <>
                          <Label style={{ marginTop: '10px' }}> Datas: {microphoneItem.dates.map(date => formatDate(date)).join(' - ')} </Label>
                          {microphoneItem.numberOfLapel > 0 && <Label style={{ marginTop: '10px' }}> Lapela: {formatCurrency(item.lapelPrice.amount)} x {microphoneItem.numberOfLapel} {microphoneItem.numberOfLapel > 1 ? 'unidades' : 'unidade'} / dia</Label>}
                          {microphoneItem.numberOfLapel > 0 && <Label>Descrição: {item.lapelDescription}</Label>}
                          {microphoneItem.numberOfGooseneck > 0 && <Label style={{ marginTop: '10px' }}> Gooseneck: {formatCurrency(item.gooseneckPrice.amount)} x {microphoneItem.numberOfGooseneck} {microphoneItem.numberOfGooseneck > 1 ? 'unidades' : 'unidade'} / dia</Label>}
                          {microphoneItem.numberOfGooseneck > 0 && <Label>Descrição: {item.gooseneckDescription}</Label>}
                          {microphoneItem.numberOfHeadSet > 0 && <Label style={{ marginTop: '10px' }}> Head Set: {formatCurrency(item.headSetPrice.amount)} x {microphoneItem.numberOfHeadSet} {microphoneItem.numberOfHeadSet > 1 ? 'unidades' : 'unidade'} / dia</Label>}
                          {microphoneItem.numberOfHeadSet > 0 && <Label>Descrição: {item.headSetDescription}</Label>}
                          {microphoneItem.numberOfStick > 0 && <Label style={{ marginTop: '10px' }}> Bastão: {formatCurrency(item.stickPrice.amount)} x {microphoneItem.numberOfStick} {microphoneItem.numberOfStick > 1 ? 'unidades' : 'unidade'} / dia</Label>}
                          {microphoneItem.numberOfStick > 0 && <Label>Descrição: {item.stickDescription}</Label>}
                        </>
                        )}
                        {this.state.equipments.briefing[budgetItem].type && <Label> Tipo: {getNameFromKey(this.state.equipments.briefing[budgetItem].type)}</Label>}
                        {this.state.equipments.briefing[budgetItem].languages && <Label> Idiomas: {getNameFromKey(this.state.equipments.briefing[budgetItem].languages[0])}</Label>}
                        {this.state.equipments.briefing[budgetItem].interpreter && <Label> Necessita intérprete: Sim</Label>}
                        {this.state.equipments.briefing[budgetItem].mbSpeed && <Label> Velocidade da internet: {this.state.equipments.briefing[budgetItem].mbSpeed}MB</Label>}
                      </Group>
                    </Card>
                  } else {
                    return <Card key={`equipments_${index}`} style={{ paddingBottom: '10px', opacity: 0.5 }}>
                      <Group>
                        <Subtitle style={{ fontSize: '15px' }}>{budgetItem !== 'internet' ? getNameFromKey(budgetItem) : 'Internet'}</Subtitle>
                        <label style={{ position: 'absolute', right: '50px', top: '30px' }}>
                          <BriefingInfo style={{ listStyle: 'none', color: 'black' }}>Equipamento indisponível</BriefingInfo>
                        </label>
                      </Group>
                    </Card>
                  }
                })}
            </>
            }
            {this.state.visualizaHistorico && <Group>
              <Label style={{ fontWeight: 600, color: '#484848', fontSize: '18px' }}>Histórico</Label>
              {this.state.counterProposals && this.ordenaContraPropostas().map(counterProposal => <Group style={{ display: 'flex' }}>
                <Label style={{ width: '150px' }}>{formatDateWithTime(counterProposal.date)}</Label>
                <Label>
                  {counterProposal.requester === 'ORGANIZER' ? 'Você' : this.state.hotel.name}
                  <span style={{ fontWeight: 500, color: defaultTheme.colors.primary.default }}>{counterProposal.requester === 'ORGANIZER'
                    ? ' solicitou uma contraproposta '
                    : `${counterProposal.supplierStatus === 'ACCEPTED' && counterProposal.duplicata ? ' aceitou sua contraproposta ' : ''}
                              ${counterProposal.supplierStatus === 'REFUSED' ? ' recusou sua contraproposta ' : ''}
                              ${counterProposal.supplierStatus === 'ACCEPTED' && !counterProposal.duplicata ? ' enviou uma contraproposta ' : ''}
                              `}</span>
                  de <span style={{ fontWeight: 600 }}>{formatCurrency(counterProposal.requestedPrice)} </span> para {this.mostraNomeDoServico(counterProposal.service)}.
                        </Label>
              </Group>
              )}
              <Button
                id={'btnVoltar'}
                type='submit'
                category={'secondary'}
                fontWeight={800}
                ghost
                onClick={() => this.setState({ visualizaHistorico: false })}
                full>
                {'Fechar histórico'}
              </Button>
            </Group>
            }
          </Content>
          {DISPLAY_CHAT && <Card><SingleChat display={DISPLAY_CHAT} onUnseeMessage={this.handleOnUnseeMessage} eventName={eventName} eventId={eventId} eventStatus={eventStatus} supplierId={accountId} /></Card>}
        </Body>
      </Main>
    </>
  }


  menuLateralDireito = () => {
    const { history } = this.props
    const { eventId, accountId } = this.props.match.params
    const userType = window.localStorage.getItem('user_type')
    return <FixedDiv>
      <Card style={{ width: '100%' }}>
        <Label style={{ textTransform: 'uppercase' }}>Resumo do Evento<span style={{ float: 'right', paddingRight: '10px' }}>ID:{this.state.event.internalId || this.state.event.friendlyId}</span></Label>
        <Group style={{ padding: '0px' }}>
          <Label style={{ fontWeight: 600, color: '#484848' }}>{this.state.event.name}</Label>
          <Label>Solicitado há {this.state.event.created ? moment().diff(moment(this.state.event.created, 'YYYY-MM-DD[T]HH:mm:ssZ'), 'days') : 0} dias</Label>
          {userType === 'RESELLER' && <Label style={{ paddingBottom: '10px', color: defaultTheme.colors.grey.light, fontWeight: 400 }}>{this.state.event.organizer && `CLIENTE: ${this.state.event.organizer.name}`}</Label>}
          {/* {userType === 'ORGANIZER' &&<Label style={{ paddingBottom: '10px', color: defaultTheme.colors.grey.light, fontWeight: 400 }}>{this.state.event.resellerId && `AGÊNCIA: ${this.state.event.reseller.name}`}</Label>} */}
          <Group style={{ display: 'flex', padding: '0px' }}>
            <Icons
              icon={'calendario'}
              color={'gray'}
              width={30}
              height={20}
              style={{ marginTop: '5px' }} />
            <Label>{formatDate(this.state.event.start)} - {formatDate(this.state.event.end)}</Label>
          </Group>
          <Group style={{ display: 'flex', padding: '0px' }}>
            <Icons
              icon={'map-pin'}
              color={'gray'}
              width={30}
              height={20}
              style={{ marginTop: '5px', marginLeft: '2px' }} />
            {this.state.event.location && <Label>{this.state.event.location.address} - {this.state.event.location.city}</Label>}
          </Group>
          {this.state.event.numberOfParticipants > 0 && <Group style={{ display: 'flex', padding: '0px' }}>
            <Icons
              icon={'pessoas'}
              color={'gray'}
              width={30}
              height={20}
              style={{ marginTop: '5px', marginLeft: '-3px' }} />
            <Label>{this.state.event.numberOfParticipants} pessoas</Label>
          </Group>}
        </Group>
      </Card>
      <Card style={{ width: '100%' }}>
        <Label style={{ textTransform: 'uppercase' }}>Resumo do pedido</Label>
        <Title style={{ fontSize: '14px', marginTop: '20px', marginBottom: '10px' }}>{this.state.hotel.name}</Title>
        {this.state.lodging.requested && <ItemsMenuLateral selected={false}>
          <Group style={{ padding: '0', display: 'flex' }}>
            <Icons
              icon={'bed-outline'}
              viewBox='0 0 1536 1024'
              width={50}
              height={20}
              color={'#484848'}
              style={{ verticalAlign: 'middle', margin: 'auto 10px auto auto' }}
            />
            <Group style={{ padding: '0', display: 'flex', width: '100%' }}>
              <Label>Hospedagem</Label>
              <Label style={{ textAlign: 'right', color: (this.pegaDescontoDaContraProposta('lodging') > 0 ? 'red' : defaultTheme.colors.primary.default), textDecoration: (this.pegaDescontoDaContraProposta('lodging') > 0 ? 'line-through' : 'none') }}>{this.pegaDescontoDaContraProposta('lodging') > 0 ? formatCurrency(this.pegaValorInicialAntesDaContraPropostaPorServico('lodging')) : formatCurrency(this.getTotalPriceLodging())}</Label>
            </Group>
          </Group>
          {this.pegaDescontoDaContraProposta('lodging') > 0 && <Group style={{ padding: '0', display: 'flex', width: '100%' }}>
            <Label style={{ textAlign: 'right', color: defaultTheme.colors.primary.default }}>{formatCurrency(this.getTotalPriceLodging())}</Label>
          </Group>}
        </ItemsMenuLateral>}
        {this.state.room.requested && <ItemsMenuLateral selected={false}>
          <Group style={{ padding: '0', display: 'flex' }}>
            <Icons
              icon={'room-outline'}
              viewBox='0 0 1536 1024'
              width={50}
              height={20}
              color={'#484848'}
              style={{ verticalAlign: 'middle', margin: 'auto 10px auto auto' }}
            />
            <Group style={{ padding: '0', display: 'flex', width: '100%' }}>
              <Label>Salas</Label>
              <Label style={{ textAlign: 'right', color: (this.pegaDescontoDaContraProposta('room') > 0 ? 'red' : defaultTheme.colors.primary.default), textDecoration: (this.pegaDescontoDaContraProposta('room') > 0 ? 'line-through' : 'none') }}>{this.pegaDescontoDaContraProposta('room') > 0 ? formatCurrency(this.pegaValorInicialAntesDaContraPropostaPorServico('room')) : formatCurrency(this.getTotalPriceRoom())}</Label>
            </Group>
          </Group>
          {this.pegaDescontoDaContraProposta('room') > 0 && <Group style={{ padding: '0', display: 'flex', width: '100%' }}>
            <Label style={{ textAlign: 'right', color: defaultTheme.colors.primary.default }}>{formatCurrency(this.getTotalPriceRoom())}</Label>
          </Group>}
        </ItemsMenuLateral>}
        {this.state.foodsAndBeverages.requested && <ItemsMenuLateral selected={false}>
          <Group style={{ padding: '0', display: 'flex' }}>
            <Icons
              icon={'food-beverage-outline'}
              width={50}
              height={20}
              color={'#484848'}
              style={{ verticalAlign: 'middle', margin: 'auto 10px auto auto' }}
            />
            <Group style={{ padding: '0', display: 'flex', width: '100%' }}>
              <Label>Alimentos e bebidas</Label>
              <Label style={{ textAlign: 'right', color: (this.pegaDescontoDaContraProposta('foodsAndBeverages') > 0 ? 'red' : defaultTheme.colors.primary.default), textDecoration: (this.pegaDescontoDaContraProposta('foodsAndBeverages') > 0 ? 'line-through' : 'none') }}>{this.pegaDescontoDaContraProposta('foodsAndBeverages') > 0 ? formatCurrency(this.pegaValorInicialAntesDaContraPropostaPorServico('foodsAndBeverages')) : formatCurrency(this.getTotalPriceFoodsAndBeverages())}</Label>
            </Group>
          </Group>
          {this.pegaDescontoDaContraProposta('foodsAndBeverages') > 0 && <Group style={{ padding: '0', display: 'flex', width: '100%' }}>
            <Label style={{ textAlign: 'right', color: defaultTheme.colors.primary.default }}>{formatCurrency(this.getTotalPriceFoodsAndBeverages())}</Label>
          </Group>}
        </ItemsMenuLateral>}
        {this.state.equipments.requested && <ItemsMenuLateral selected={false}>
          <Group style={{ padding: '0', display: 'flex' }}>
            <Icons
              icon={'computer-outline'}
              viewBox='0 0 1280 1024'
              width={50}
              height={20}
              color={'#484848'}
              style={{ verticalAlign: 'middle', margin: 'auto 10px auto auto' }}
            />
            <Group style={{ padding: '0', display: 'flex', width: '100%' }}>
              <Label>Equipamentos</Label>
              <Label style={{ textAlign: 'right', color: (this.pegaDescontoDaContraProposta('equipments') > 0 ? 'red' : defaultTheme.colors.primary.default), textDecoration: (this.pegaDescontoDaContraProposta('equipments') > 0 ? 'line-through' : 'none') }}>{this.pegaDescontoDaContraProposta('equipments') > 0 ? formatCurrency(this.pegaValorInicialAntesDaContraPropostaPorServico('equipments')) : formatCurrency(this.getTotalPriceEquipments())}</Label>
            </Group>
          </Group>
          {this.pegaDescontoDaContraProposta('equipments') > 0 && <Group style={{ padding: '0', display: 'flex', width: '100%' }}>
            <Label style={{ textAlign: 'right', color: defaultTheme.colors.primary.default }}>{formatCurrency(this.getTotalPriceEquipments())}</Label>
          </Group>}
        </ItemsMenuLateral>}
        <ItemsMenuLateral selected={false}>
          <Group style={{ padding: '0', display: 'flex' }}>
            <Label>Service Fee</Label>
            <Label style={{ textAlign: 'right', color: defaultTheme.colors.primary.default }}>{formatCurrency(this.getTotalWithFee() - this.getTotal())}</Label>
          </Group>
        </ItemsMenuLateral>
        <ItemsMenuLateral selected={true}>
          <Group style={{ padding: '0', display: 'flex' }}>
            <Label>Valor total</Label>
            <Money>{formatCurrency(this.getTotalWithFee(), false)}</Money>
            {/* <Label style={{ textAlign: 'right', color: defaultTheme.colors.primary.default }}>{formatCurrency(this.getTotalWithFee())}</Label> */}
          </Group>
          {this.pegaDescontosDeTodosOsServicos() > 0 && <Group style={{ padding: '0', display: 'flex', width: '100%' }}>
            <Label style={{ textAlign: 'right', color: defaultTheme.colors.primary.default }}>(Savings: <span style={{ fontWeight: 600 }}>{formatCurrency(this.pegaDescontosDeTodosOsServicos())}</span>)</Label>
          </Group>}
        </ItemsMenuLateral>
        {/* <Agreement>
          <input type='checkbox' name='agreement' id='agreement' />
          <label htmlFor='agreement' onClick = {this.clickedAgreement}>Li e concordo com a <a href={`/organizer/event/${eventId}/resume/budget`}>política do fornecedor</a>.</label>
        </Agreement> */}
        {this.verifaRole('APPROVER') && this.verificaSeFoiSolicitadoAprovacao(accountId) && <>
          <Button
            type='submit'
            category={'secondary'}
            onClick={this.ativaModalTemCerteza}
            fontWeight={800}
            full>
            {'Aprovar fornecedor'}
          </Button>
          <Button
            type='submit'
            category={'secondary'}
            onClick={this.recusarFornecedor}
            fontWeight={800}
            ghost
            red
            full>
            {'Recusar fornecedor'}
          </Button>
        </>}
        {!this.verifaRole('APPROVER') && <>
          {!this.state.readOnly && <Button
            type='submit'
            category={'secondary'}
            onClick={() => this.submitAll()}
            // disabled = {!this.state.agreedWithTerms}
            fontWeight={800}
            full>
            {'Ver resumo do fornecedor'}
          </Button>}
          {!this.state.readOnly && <Button
            type='submit'
            category={'secondary'}
            onClick={() => this.solicitarContraproposta()}
            fontWeight={800}
            ghost
            full>
            {'Solicitar Contraproposta'}
          </Button>}
        </>}
        <Button
          id={'btnVoltar'}
          type='submit'
          category={'secondary'}
          fontWeight={800}
          ghost
          onClick={() => history.push(`/organizer/event/${eventId}/budget/place`)}
          full>
          {'Voltar para Fornecedores'}
        </Button>
      </Card>
    </FixedDiv>
  }

  render () {
    return this.gerenciaResponsividade()
  }

  verificaSeOEventoPertenceAoConsultor = (consultantId, userId) => {
    const loggedUserId = window.localStorage.getItem('user')
    return consultantId !== userId && loggedUserId === consultantId
  }

  loadDataEvent = () => {
    const { api: { base, version } } = config
    const { eventId } = this.props.match.params
    const userType = window.localStorage.getItem('user_type')
    const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
    const endpoint = `${base}${version}${eventEndpoint}/${eventId}/budget/fullInfo`

    return Get(endpoint)
      .then((event) => {
        var lodging = this.state.lodging
        lodging.requested = event.services.lodging.requested
        var room = this.state.room
        room.requested = event.services.room.requested
        var foodsAndBeverages = this.state.foodsAndBeverages
        foodsAndBeverages.requested = event.services.foodsAndBeverages.requested
        var equipments = this.state.equipments
        equipments.requested = event.services.equipments.requested
        this.setState({
          event: {
            ...event
          },
          readOnly: this.verificaSeOEventoPertenceAoConsultor(event.consultantId, event.userId),
          isLoading: false,
          lodging,
          room,
          foodsAndBeverages,
          equipments
        }, () => document.getElementById("btnVoltar") && document.getElementById("btnVoltar").scrollIntoView({ behavior: "smooth" }))
      }).catch(e => {
        this.setState({ isLoading: false }, console.error(e))
      })
  }

  loadHistory = () => {
    const { api: { base, version } } = config
    const { eventId, accountId } = this.props.match.params
    const userType = window.localStorage.getItem('user_type')
    const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
    const endpoint = `${base}${version}${eventEndpoint}/${eventId}/counter-proposals/account/${accountId}`

    return Get(endpoint)
      .then(response => {
        response.map(services => services.map(service => {
          service.counterProposals = service.counterProposals.reduce((counterProposals, item) => {
            if (item.requester === 'ORGANIZER' && (item.supplierStatus === 'ACCEPTED' || item.supplierStatus === 'REFUSED')) {
              counterProposals.push(item)
              var duplicata = {
                acceptedDate: item.acceptedDate,
                date: item.acceptedDate || item.date,
                initialPrice: item.initialPrice,
                justification: item.justification,
                requestedPrice: item.requestedPrice,
                requester: 'SUPPLIER',
                duplicata: true,
                supplierStatus: item.supplierStatus,
                valid: item.valid
              }
              counterProposals.push(duplicata)
            } else {
              counterProposals.push(item)
            }
            return counterProposals
          }, []).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
          return service
        })
        )
        this.setState({ counterProposals: response })
      }).catch(e => {
        console.error(e)
      })
  }

  loadDataServices = () => {
    this.setState({ isLoading: true })
    const { api: { base, version } } = config
    const { eventId, accountId } = this.props.match.params
    const userType = window.localStorage.getItem('user_type')
    const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
    const endpoint = `${base}${version}${eventEndpoint}/${eventId}/budget/account/${accountId}`
    return Get(endpoint)
      .then(response => {
        const budgets = []
        response.budgets.foodsAndBeverages.mostRecent && Object.keys(response.budgets.foodsAndBeverages.mostRecent.menusBudgets).map((key) => {
          let budget = response.budgets.foodsAndBeverages.mostRecent.menusBudgets[key]
          budget.name = key
          if (budget.budgets.length > 0) {
            budget.budgets = budget.budgets.map((item) => {
              item.selected = item.chosen || false
              return item
            })
          }
          if (budget.budgets.length > 0 && !budget.budgets.some(item => item.chosen)) {
            const valMin = Math.min(...budget.budgets.map(item => item.totalWithoutFee.amount))
            const itemMin = budget.budgets.findIndex(item => item.totalWithoutFee.amount === valMin)
            if (itemMin > -1) budget.budgets[itemMin].selected = true
          }
          Object.keys(response.briefings.foodsAndBeverages).filter(briefingKey => response.briefings.foodsAndBeverages[briefingKey] && response.briefings.foodsAndBeverages[briefingKey].name && response.briefings.foodsAndBeverages[briefingKey].name === key).map((briefingKey) => {
            return budget.briefing = response.briefings.foodsAndBeverages[briefingKey]
          })
          return budgets.push(budget)
        })
        this.setState({
          billingConditions: this.getBillingConditions(response.budgets),
          hotel: response,
          briefing: response.briefings.rooms,
          budget: response.budgets.rooms.mostRecent,
          lodging: response.budgets.lodging.mostRecent ? {
            budgets: response.budgets.lodging.mostRecent.apartmentsBudget,
            budgetId: response.budgets.lodging.mostRecent.id,
            observations: response.budgets.lodging.mostRecent.observations,
            itemsOnApartmentPrice: response.budgets.lodging.mostRecent.itemsOnApartmentPrice,
            includedItems: response.budgets.lodging.mostRecent.includedItems,
            expireDate: response.budgets.lodging.expireDate,
            name: response.name,
            location: response.location,
            start: response.start,
            end: response.end,
            numberOfParticipants: response.numberOfParticipants,
            status: response.budgets.lodging.mostRecent.status,
            commission: response.budgets.lodging.mostRecent.commission
          } : {
              budgets: [],
              budgetId: '',
              includedItems: {}
            },
          room: {
            showItems: [],
            hotel: response,
            briefing: response.briefings.rooms,
            budget: response.budgets.rooms.mostRecent, // Como hoje nós temos mais de um budget de quarto e vamos só mostrar um aqui agora vamos pegar o mais recente
          },
          foodsAndBeverages: response.budgets.foodsAndBeverages.mostRecent ? {
            showItems: [],
            budgets,
            expireDate: response.budgets.foodsAndBeverages.expireDate,
            name: response.name,
            observations: response.budgets.foodsAndBeverages.mostRecent.observations,
            status: response.budgets.foodsAndBeverages.mostRecent.status,
            commission: response.budgets.foodsAndBeverages.mostRecent.commission,
            budgetId: response.budgets.foodsAndBeverages.mostRecent.id,
            fee: response.budgets.foodsAndBeverages.mostRecent.fee
          } : {
              showItems: [],
              budgets: [],
              budgetId: ''
            },
          equipments: {
            showItems: [],
            briefing: response.briefings.equipments,
            budget: response.budgets.equipments.mostRecent
          }
        })
      })
      .catch(e => {
        this.setState({ isLoading: false }, console.error(e))
      })
  }
}

export default ResumoComAgreement
