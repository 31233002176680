/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
import Card from '../../../components/ui/Card'
import Button from '../../../components/ui/Button'
import { defaultTheme } from '../../../styles/themes'

const Content = styled.div`
  padding: 10px;
  width: 100%;
  margin-top: 40px;
`

const Title = styled.h1`
  font-family: ${defaultTheme.fonts.secondary};
  line-height: 1.2;
  font-size: 1.3em;
  margin-bottom: 20px;
  font-weight: 600;
  text-align: center;
`

const Group = styled.div`
`

const Img = styled.img`
  width: 100%;
  border-radius: 15px;
`

const Label = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  font-family: ${defaultTheme.fonts.primary};
  box-sizing: border-box;
  padding: 5px 0px;
  display: block;
  font-size: 18px;
  text-align: center;
  font-weight: 600;
`

const Descricao = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  font-family: ${defaultTheme.fonts.primary};
  box-sizing: border-box;
  padding: 5px 0px;
  display: block;
  font-size: 16px;
  text-align: center;
  font-weight: 300;
`


class TelaInicial extends Component {
  state = {
  }


  render() {
    const mobile = window.innerWidth < 600
    return (
      <Content>
        <Card>
          <Title>Escolha o tipo de evento</Title>
          <Group style={{ display: !mobile && 'flex' }}>
            <Group style={{ width: mobile ? '100%' : 'calc(100% / 3)', marginTop: mobile && '50px', padding: '0 20px' }}>
              <Img style={{ height: 'calc(100% - 216px)' }} src='https://s3.amazonaws.com/files.evnts.com.br/corporate/Atlantica/photo_2020-12-18_21-05-13.jpg' />
              <Label>Presencial</Label>
              <Descricao>Respeitando todas as regras e boas práticas, evento será realizado em estúdio, sem transmissão.</Descricao>
              <Button
                ghost
                onClick={this.props.selecaoPresencial}
                type='submit'
                category={'secondary'}
                fontWeight={800}
                style={{ color: '#ec7d31', border: '2px solid #ec7d31' }}
                full>
                Escolher
              </Button>
            </Group>
            <Group style={{ width: mobile ? '100%' : 'calc(100% / 3)', marginTop: mobile && '50px', padding: '0 20px' }}>
              <Img src='https://s3.amazonaws.com/files.evnts.com.br/corporate/Atlantica/evento-digital-presencial-min.png' />
              <Label>Digital + Presencial (Híbrido)</Label>
              <Descricao>A maioria dos participantes estará remoto, mas será necessária estrutura de estúdio para palestrantes e outros.</Descricao>
              <Button
                ghost
                onClick={this.props.selecaoHibrido}
                type='submit'
                category={'secondary'}
                fontWeight={800}
                style={{ color: '#ec7d31', border: '2px solid #ec7d31' }}
                full>
                Escolher
              </Button>
            </Group>
            <Group style={{ width: mobile ? '100%' : 'calc(100% / 3)', marginTop: mobile && '50px', padding: '0 20px' }}>
              <Img src='https://s3.amazonaws.com/files.evnts.com.br/corporate/Atlantica/evento-digital-min.png' />
              <Label>Evento Digital</Label>
              <Descricao>Todos os palestrantes e participantes estarão remotos.</Descricao>
              <Button
                ghost
                onClick={this.props.selecaoDigital}
                type='submit'
                category={'secondary'}
                fontWeight={800}
                style={{ color: '#ec7d31', border: '2px solid #ec7d31', marginTop: '30px' }}
                full>
                Escolher
              </Button>
            </Group>
          </Group>
        </Card>
      </Content>
    )
  }
}

export default TelaInicial
