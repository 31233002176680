import React, { Component } from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../../styles/themes'
import Icons from '../../components/ui/Icons'

const TdTitulo = styled.td`
  color: #767676;
  font-weight: 600;
`

const TdIcons = styled.td`
  color: #767676;
  width: 40px;
  text-align: right;
  cursor: pointer;
`

const Tabela = styled.table`
  font-family: ${defaultTheme.fonts.primary};
  border-bottom: 1px solid #c3c3c3;
  font-size: 14px;
  width: 100%;
  color: #a5a5a5;
  td {
    border-bottom: 1px solid #c3c3c3;
    vertical-align:middle;
    padding: 10px 2px;
    @media(max-width: 1024px) {
     padding: 5px 5px;
    }
  }`

class TabelaCentroDeCusto extends Component {
  constructor () {
    super()
    this.state = {
    }
  }

  render () {
    const dados = this.props.dados[0]
    return (<>
      <Tabela>
        <tbody>
          <tr>
            <TdTitulo style={{width:  '350px'}}>Área / Centro de custo</TdTitulo>
            <TdTitulo>ID</TdTitulo>
            <TdTitulo>Empresa</TdTitulo>
            <TdIcons></TdIcons>
            <TdIcons></TdIcons>
          </tr>
          {dados && dados.map((item) => {
            return <tr key={item.id}>
              <td>{item.name}</td>
              <td>{item.externalId}</td>
              <td>{item.officeBranchName}</td>
              <td></td>
              <TdIcons>
                <span>
                  <Icons
                    onClick={() => this.props.edicao(item)}
                    icon={'pencil'}
                    color={'#767676'}
                    width={16}
                    height={16} />
                </span>
              </TdIcons>
              {/* <TdIcons>
                <span >
                  <Icons
                    onClick={() => alert('Excluído com sucesso!')}
                    icon={'trash'}
                    color={'#767676'}
                    width={16}
                    height={16} />
                </span>
              </TdIcons> */}
            </tr>
          })
          }
        </tbody>
      </Tabela>
    </>
    )
  }
}

export default TabelaCentroDeCusto
