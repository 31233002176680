import React, { Component } from 'react'
import styled from 'styled-components'
import Modal from '../../../../components/Modal'
import Title from '../../../../components/ui/Title'

const Group = styled.div`
  text-align: start;
`

const Titulo = styled(Title)`
  font-size: 16px;
  margin: 0;
`

class ModalCardGastos extends Component {
  constructor () {
    super()
    this.state = {
      isOpen: false
    }
  }

  render () {
    return (
      <Modal maxWidth={'800px'} isOpen={this.props.isOpen} hasCloseButton onClose={() => this.props.onClose && this.props.onClose()}>
        <Group style={{ padding: '30px' }}>
          <Group style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '20px' }}>
            <Titulo>{this.props.titulo}</Titulo>
          </Group>
          <Group>
            {this.props.conteudoModal}
          </Group>
        </Group>
      </Modal>
    )
  }
}

export default ModalCardGastos
