import React, { Component } from 'react'
import Input from './Input'
/* UTILS */
import { formatPercentage } from '../../../utils/percentage'

export default class PercentageInput extends Component {
  constructor (props) {
    super(props)
    this.state = {
      amount: '',
      criticalPreviouslyValue: '',
      passingEvent: null
    }
  }

  componentDidMount () {
    this.setState({
      amount: this.props.value,
      criticalPreviouslyValue: this.props.criticalPreviouslyValue
    })
  }

  UNSAFE_componentWillReceiveProps (props) {
    this.setState({
      amount: props.value,
      criticalPreviouslyValue: props.criticalPreviouslyValue
    })
  }

  handlerAmount = (e) => {
    e.persist()
    this.setState({
      amount: e.target.value,
      passingEvent: e
    }, () => {
      const passingEvent = this.state.passingEvent
      this.props.onChange(passingEvent)
    })
  }

  render () {
    return (
      <Input
        {...this.props}
        value={formatPercentage(this.state.amount, this.props.haveDecimals || false)}
        criticalPreviouslyValue={formatPercentage(this.state.criticalPreviouslyValue, this.props.haveDecimals || false)}
        onChange={this.handlerAmount}
        style={{ textAlign: 'right' }}
        rightIcon={'%'}
      />
    )
  }
}
