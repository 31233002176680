/* LIBS */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import InputMask from 'react-input-mask'
/* ACTIONS */
import { singUpInsert } from '../../../redux/actions/signUp'
/* STYLES */
import { defaultTheme } from '../../styles/themes'
/* I18n */
import i18n from '../../../i18n'
/* UI */
import Input from '../../components/ui/Input'
import Select from '../../components/ui/Select'
import Button from '../../components/ui/Button'
import Alert from '../../components/ui/Alert'
import InputAddress from '../../components/InputAddress'

import STATES from '../../../utils/uf'

const Content = styled.main`
  background-color: #FAFAFA;
  min-height: 100vh;
  width: 100%;
  padding: 0 3%;
  box-sizing: border-box;
  @media (min-width: 768px) {
    padding: 0 20%;
  }
`

const Steps = styled.span`
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  font-size: 14px;
  display: flex;
  padding: 15px 10px 3px 10px;
  color: #767676;
`
const Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  font-family: ${defaultTheme.fonts.secondary};
  padding: 3px 10px 13px 10px;
  color: #484848;
`

const Stepper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  box-sizing: border-box;
`

const StepperDescription = styled.div`
`
const Form = styled.form`
  flex-wrap: wrap;
  display: flex;
  padding: 0px 10px;
`

const Row = styled.div`
  display: flex;
  width: 100%;
`

class Address extends Component {
  constructor() {
    super()
    this.state = {
      address: {
        address: '',
        number: '',
        zipcode: '',
        city: '',
        province: '',
        country: ''
      },
      errors: {
        address: '',
        number: '',
        zipcode: '',
        city: '',
        province: '',
        country: ''
      },
      error_empty: false
    }
  }

  handlerValue (input) {
    const { address } = this.state
    address[input.target.name] = input.target.value
    this.setState({address: address})
  }

  handleAddress = (location) => {
    const { address, city, province, country, lat, lng } = location
    const newAddress = {
      ...this.state.address,
      address: address,
      city,
      province,
      country,
      lat,
      lng
    }
    this.setState({address: newAddress})
  }

  submit(e) {
    e.preventDefault()
    this.setState({error_empty: false, errors: {
      address: '',
      number: '',
      zipcode: '',
      city: '',
      province: '',
      country: ''
    }}, () => {
      const { address } = this.state
      const { company } = this.props
      const errors = {
        empty: false
      }
      var handleErrors = this.state.errors
      Object.keys(address).forEach(key => {
        if (address[key] === '') {
          errors.empty = true
          handleErrors[key] = true
        }
      })
      if (errors.empty) {
        this.setState({error_empty: true, errors: handleErrors})
      } else {
        company.location = this.state.address
        this.props.singUpInsert(company)
        const { history } = this.props
        history.push('/signup/services')
      }
    })
  }

  componentDidMount () {
    const { company, history } = this.props
    if (!company) history.push('/signup')
  }

  render() {
    const { language, company, history } = this.props
    const { city, province, country } = this.state.address
    if (company.location) history.push('/signup/services')
    return (
      <Content>
        <Stepper>
          <StepperDescription>
            <Steps>Etapa 2 de 3</Steps>
            <Title>Endereço completo do fornecedor</Title>
          </StepperDescription>
        </Stepper>
        <Form onSubmit={this.submit.bind(this)} method='POST'>
        {this.state.error_empty
          ? <Alert type={'negative'}>
            {i18n.t('signUp.empty_fields', { language })}
          </Alert> : null}
        <form autoComplete='false' style={{ width: '100%' }}>
          <InputAddress
              type={'text'}
              label={i18n.t('signUp.address', { language })}
              placeholder={i18n.t('signUp.address_placeholder', { language })}
              name={'address'}
              error={this.state.errors.address || this.state.locationErrorMessage}
              addressSelected={this.handleAddress}
              isRequired
            />
        </form>
          <Row>
            <Input
              type={'text'}
              label={i18n.t('signUp.number', { language })}
              placeholder={i18n.t('signUp.number_placeholder', { language })}
              name={'number'}
              error={this.state.errors.number}
              onChange={this.handlerValue.bind(this)}
              isRequired
            />
            <InputMask mask="99999-999"
              value={this.state.address.zipcode}
              onChange={this.handlerValue.bind(this)}>
              {(inputProps) => <Input
                type={'text'}
                label={i18n.t('signUp.zipcode', { language })}
                placeholder={i18n.t('signUp.zipcode_placeholder', { language })}
                name={'zipcode'}
                error={this.state.errors.zipcode}
                isRequired
            />}
          </InputMask>
          </Row>
          <Row>
            <Input
              type={'text'}
              label={i18n.t('signUp.city', { language })}
              placeholder={i18n.t('signUp.city_placeholder', { language })}
              name={'city'}
              error={this.state.errors.city}
              onChange={this.handlerValue.bind(this)}
              value={city}
              isRequired
            />
            <Select
              label={i18n.t('signUp.state', { language })}
              name={'province'}
              width={'60%'}
              isRequired
              error={this.state.errors.province}
              value={province}
              onChange={this.handlerValue.bind(this)}>
                <option value=''>{i18n.t('signUp.state_placeholder', { language })}</option>
                <StateOptions />
            </Select>
          </Row>
          <Input
            type={'text'}
            label={i18n.t('signUp.country', { language })}
            placeholder={i18n.t('signUp.country_placeholder', { language })}
            name={'country'}
            error={this.state.errors.country}
            value={country}
            onChange={this.handlerValue.bind(this)}
            isRequired
          />
          <Button
            type='submit'
            category={'secondary'}
            fontWeight={800}
            full>
            {i18n.t('signUp.send_action', { language })}
          </Button>
        </Form>
    </Content>
    )
  }
}

const StateOptions = () => {
  return (
      STATES.map(uf => {
        return <option value={uf.abbr} key={uf.abbr}>
                  {uf.name}
               </option>
      })
  )
}

function mapStateToProps (state) {
  const { app, signUp } = state
  const { language } = app
  const { company } = signUp
  return {
    language,
    company
  }
}

export default connect(mapStateToProps, { singUpInsert })(Address)
