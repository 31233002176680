/* LIBS */
import React, { Component } from 'react'
import MoedaIcon from '../../components/ui/MoedaIcon'
import Button from '../../components/ui/Button'
import Select from '../../components/ui/Select'
import Modal from '../../components/Modal'
import Title from '../../components/ui/Title'
import Textarea from '../../components/ui/Textarea'
import Alert from '../../components/ui/Alert'

class MotivoIndisponibilidade extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: false,
      reason: '0',
      details: '',
      error: ''
    }
  }

  UNSAFE_componentWillReceiveProps (props) {
    this.setState({ ...props },
      () => document.querySelector('body').style.overflow = this.state.isOpen ? 'hidden' : 'auto'
    )
  }

  handleValue = ({ target }) => {
    this.setState({
      error: '',
      [target.name]: target.value.trim()
    })
  }

  reasons = () => {
    return [
      ['APT_CAPACITY', 'Não temos capacidade de apartamentos'],
      ['ROOMS_PERIOD', 'Não temos salas disponíveis para o período'],
      ['APT_ROOMS_PERIOD', 'Não temos disponibilidade para a data solicitada em sala e/ou hospedagem'],
      ['SEGMENT', 'Já temos outro cliente do mesmo segmento no período'],
      ['NOT_INTERESTED', 'Nao tenho interesse em atender esse evento'],
      ['OTHER', 'Outros']
    ].map(function ([valor, texto], i) {
      return <option key={i} value={valor}>{texto}</option>
    })
  }

  submit = () => {
    var error = this.validate()
    if (error) return this.setState({ error })
    this.props.onSubmit({
      reason: this.state.reason,
      details: this.state.details
    })
  }

  validate = () => {
    if (this.state.reason === '0') return 'É necessário informar o motivo da indisponibilidade'
    if (this.state.reason === 'OTHER' && !this.state.details.trim()) return 'Descreva o motivo da indisponibilidade'
    return false
  }

  render () {
    var { error } = this.state
    return (
      <Modal isOpen={this.state.isOpen} hasCloseButton onClose={() => this.props.onClose && this.props.onClose()}>
        <MoedaIcon />
        {error && <Alert type={'negative'}>{error}</Alert>}
        <Title>Motivo de indisponibilidade</Title>
        <Select
          isRequired
          name='reason'
          value={this.state.reason}
          onChange={this.handleValue}
          label={'Por qual motivo você não atende a este evento?'}>
          <option disabled value='0'>Selecione uma opção</option>
          {this.reasons()}
        </Select>
        <Textarea
          isRequired={this.state.reason === 'OTHER'}
          name='details'
          defaultValue={this.state.details}
          onBlur={this.handleValue}
          label={'Descreva o motivo'}
          placeholder='Escreva aqui porquê você não pode atender a este evento'
        />
        <Button
          onClick={this.submit}
          category={'secondary'}
          fontWeight={800}
          full>
          Confirmar
        </Button>
      </Modal>
    )
  }
}

export default MotivoIndisponibilidade
