/* LIBS */
import React, { Component } from 'react'
/* COMPONENTS */
import Header from '../../../Organizer/Header'
import CheckBox from '../../../components/ui/CheckBox'
import InputDatepicker from '../../../components/InputDatepicker'
import Input from '../../../components/ui/Input'
import Alert from '../../../components/ui/Alert'
import Textarea from '../../../components/ui/Textarea'
import Loader from '../../../components/Loader'
import BreadCrumb from '../../../components/BreadCrumb'
import { getDateRange } from '../../../../utils/dates'
import Main from '../../../components/Main'
import Card from '../../../components/ui/Card'
import Icons from '../../../components/ui/Icons'
import Button from '../../../components/ui/Button'
import styled from 'styled-components'
import { defaultTheme } from '../../../styles/themes'
import TagManager from 'react-gtm-module'
import moment from 'moment'
import {
  validaCriacaoDoEvento,
  validaCricaoDeHospedagem,
  validateHospedagemDatesValid,
  validateHospedagemDatesRangeValid,
  validateHospedagemForm
  } from '../../utils/validacoes'
import ReactGA from 'react-ga'

const BtnAdd = styled.span`
  font-family: ${defaultTheme.fonts.secondary};
  padding: 10px;
  margin-bottom: 10px;
  display: block;
  cursor: pointer;
  color: ${defaultTheme.colors.whiteLabel.primary};
  font-size: 14px;
`

const Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  font-family: ${defaultTheme.fonts.secondary};
  padding: 3px 10px 13px 10px;
  color: #484848;
`

const Group = styled.div`
  position: relative;
`

const Label = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  box-sizing: border-box;
  padding: 5px 5px 5px 0px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
`

const BotaoExcluir = function (props) {
  return <Button
    type='submit'
    category={'secondary'}
    fontWeight={800}
    onClick={props.call}
    style={{ position: 'absolute', right: '0', top: '-10px', width: '100px' }}
    ghost
    small
    full >
    Excluir
</Button>
}


export default class Hospedagem extends Component {
  constructor () {
    super()
    this.state = {
      singleType: {
        selected: false,
        list: []
      },
      doubleType: {
        selected: false,
        list: []
      },
      tripleType: {
        selected: false,
        list: []
      },
      observations: '',
      itemsOnApartment: false,
      isLoading: false,
      eventStartDate: '',
      eventEndDate: '',
      deuSubmit: false,
      hotelName: ''
    }
  }

  componentDidMount() {
    const { supplierChainId, hotelId } = this.props.match.params
    document.title = "Espaços para Eventos"
    ReactGA.initialize(window.localStorage.getItem('googleAnalyticsCode'))
    if(hotelId) {
      ReactGA.pageview('hotelLodging')
    } else {
      ReactGA.pageview('chainLodging')
    }
    const eventosChain = window.localStorage.getItem('criarEventoChain') && JSON.parse(window.localStorage.getItem('criarEventoChain'))
    if(eventosChain && eventosChain[supplierChainId] && eventosChain[supplierChainId].event) {
      const { event } = eventosChain[supplierChainId]
      event.start = [moment(event.start, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br')]
      event.end = [moment(event.end, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br')]
      if(!validaCriacaoDoEvento(event, hotelId)) {
        return this.props.history.push(`/espacos-para-eventos/${supplierChainId}${hotelId ? `/${hotelId}` : ''}/event`)
      }
      const hoteisChain = (window.localStorage.getItem('hoteisChain') && JSON.parse(window.localStorage.getItem('hoteisChain'))) || []
      const hotelName = hoteisChain.find(hotel => hotel.id === hotelId) && hoteisChain.find(hotel => hotel.id === hotelId).name
      this.setState({
        eventStartDate: event.start,
        eventEndDate: event.end,
        hotelName
      }, () => {
        if(eventosChain[supplierChainId].lodging) {
          this.setState({
            singleType: eventosChain[supplierChainId].lodging.singleType,
            doubleType: eventosChain[supplierChainId].lodging.doubleType,
            tripleType: eventosChain[supplierChainId].lodging.tripleType,
            observations: eventosChain[supplierChainId].lodging.observations,
            itemsOnApartment: eventosChain[supplierChainId].lodging.itemsOnApartment
          })
        } else {
          this.addList('singleType')
          this.addList('doubleType')
          this.addList('tripleType')
        }
      })
    } else {
      alert('Verifique as suas informações do evento!')
      this.props.history.push(`/espacos-para-eventos/${supplierChainId}${hotelId ? `/${hotelId}` : ''}/event`)
    }
  }

  click = (session) => {
    const state = this.state
    state[session].selected = !state[session].selected
    this.setState(state)
  }

  handlerValueListDate = (input, session, index) => {
    const state = this.state
    const { name, value } = input.target
    state[session].list[index].dates = getDateRange(new Date(value.from), new Date(value.to))
    this.setState(state)
    return state[session].list[index][name]
  }

  handlerValueList = (input, session, index) => {
    const state = this.state
    const { name, value } = input.target
    state[session].list[index][name] = value
    this.setState(state)
    return state[session].list[index][name]
  }

  addList = (session) => {
    const state = this.state
    state[session].list.push({
      dates: getDateRange(new Date(this.state.eventStartDate), new Date(this.state.eventEndDate)),
      amountOfApartments: 0,
      amountOfApartmentsDouble: 0,
      amountOfApartmentsTwin: 0,
      errorMessage: '',
      errors: {
        dates: '',
        amountOfApartments: '',
        amountOfApartmentsDouble: '',
        amountOfApartmentsTwin: ''
      }
    })
    return this.setState(state)
  }


  removeLodgingFromIndex = (session, index) => {
    const state = this.state
    state[session].list.splice(index, 1)
    this.setState(state)
  }

  itemsOnApartmentClick = () => {
    const state = this.state
    state.itemsOnApartment = !state.itemsOnApartment
    this.setState(state)
  }

  handlerObservations = (input) => {
    const state = this.state
    const { value } = input.target
    state.observations = value
    this.setState(state)
    return state.observations
  }

  submit = (e) => {
    e && e.preventDefault()
    this.setState({ deuSubmit: true })
    if(!validaCricaoDeHospedagem(this.state)) return alert('Verifique todos os campos para continuar')
    const { supplierChainId, hotelId } = this.props.match.params
    const eventosChain = window.localStorage.getItem('criarEventoChain') && JSON.parse(window.localStorage.getItem('criarEventoChain'))
    if(eventosChain && eventosChain[supplierChainId] && eventosChain[supplierChainId].event) {
      eventosChain[supplierChainId].lodging = this.state
      window.localStorage.setItem('criarEventoChain', JSON.stringify(eventosChain))
      this.props.history.push(`/espacos-para-eventos/${supplierChainId}${hotelId ? `/${hotelId}` : ''}/rooms`)
    }
  }

  gerenciaResponsividade = () => {
    if(window.innerWidth < 600) return this.paginaMobile()
    else return this.paginaDesktop()
  }

  paginaMobile = () => {
    const { supplierChainId, hotelId } = this.props.match.params
    const gtmId = window.localStorage.getItem('gtmId')
    return <>
      {gtmId && TagManager.initialize({ gtmId })}
      <Header />
      <Main>
        <Card style={{ marginTop: '50px', maxWidth: '1000px' }}>
          <BreadCrumb options={
            [
              { link: `/espacos-para-eventos/${supplierChainId}${hotelId ? `/${hotelId}` : ''}/event`, name: 'Dados gerais do evento' },
              { name: 'Hospedagem' }
            ]
          } />
          <Group>
            {this.state.isLoading ? <Loader /> : null}
            <Label style={{ fontSize: '13px ', marginLeft: '10px' }}>Etapa 2 de 4</Label>
            <Title>Hospedagem {hotelId && `em ${this.state.hotelName}`}</Title>
            <Label style={{ marginTop: '20px', marginLeft: '10px' }}>Selecione as opções desejadas</Label>
            {!validateHospedagemForm(this.state) && this.state.deuSubmit ? <Alert type={'negative'}>Preencha todos os campos obrigatórios para continuar</Alert> : null}
            {validateHospedagemForm(this.state) && !validateHospedagemDatesValid(this.state) && this.state.deuSubmit ? <Alert type={'negative'}>Escolha no minimo dois dias, sendo o primeiro a data de check in e o segundo a data de check out.</Alert> : null}
            {validateHospedagemForm(this.state) && validateHospedagemDatesValid(this.state) && !validateHospedagemDatesRangeValid(this.state) && this.state.deuSubmit ? <Alert type={'negative'}>As datas de hospedagem devem ser no máximo 10 dias distantes das datas do evento (antes e/ou depois).</Alert> : null}
            <Group>
              <CheckBox
                onClick={() => this.click('singleType')}
                label="Apto tipo solteiro"
                checked={this.state.singleType.selected}>
                <Group style={{ display: this.state.singleType.selected ? 'block' : 'none' }}>
                  {this.state.singleType.list.map((item, index, array) => {
                    return <Group key={index}>
                      {(array && array.length > 1) &&
                        <BotaoExcluir call = {() => this.removeLodgingFromIndex('singleType', index)}/>
                      }
                      <InputDatepicker
                        type={'range'}
                        label={'Período da hospedagem'}
                        placeholder={'Selecione a(s) data(s)'}
                        name={'singleDates'}
                        dates={{ from: item.dates[0], to: item.dates[item.dates.length - 1] }}
                        error={(!validateHospedagemDatesValid(this.state) || !validateHospedagemDatesRangeValid(this.state)) && this.state.deuSubmit}
                        onChange={value => this.handlerValueListDate(value, 'singleType', index)}
                        isRequired />
                      <Group style={{ display: 'flex' }}>
                        <Input
                          type={'number'}
                          name={'amountOfApartments'}
                          label={'Número de aptos'}
                          value={item.amountOfApartments}
                          error={!(item.amountOfApartments > 0) && this.state.deuSubmit}
                          onChange={input => this.handlerValueList(input, 'singleType', index)}
                          width='50%'
                          isRequired />
                        <Group style={{ width: '50%', margin: 'auto', display: 'flex', paddingLeft: '20px', alignItems: 'center' }}>
                          <Icons style={{ width: '30%' }} height='35px' icon='double-bed' />
                          <Label style={{ width: '70%' }}>1 cama de casal</Label>
                        </Group>
                      </Group>
                    </Group>
                  })}
                  <BtnAdd onClick={() => this.addList('singleType')}>
                    + Adicionar mais um período de hospedagem
                    </BtnAdd>
                </Group>
              </CheckBox>
              <CheckBox
                onClick={() => this.click('doubleType')}
                label="Apto tipo duplo"
                checked={this.state.doubleType.selected}>
                <Group style={{ display: this.state.doubleType.selected ? 'block' : 'none' }}>
                  {this.state.doubleType.list.map((item, index, array) => {
                    return <Group key={index}>
                      {(array && array.length > 1) &&
                        <BotaoExcluir call={() => this.removeLodgingFromIndex('doubleType', index)} />
                      }
                      <InputDatepicker
                        type={'range'}
                        label={'Período da hospedagem'}
                        placeholder={'Selecione a(s) data(s)'}
                        name={'doubleDates'}
                        dates={{ from: item.dates[0], to: item.dates[item.dates.length - 1] }}
                        error={(!validateHospedagemDatesValid(this.state) || !validateHospedagemDatesRangeValid(this.state)) && this.state.deuSubmit}
                        onChange={value => this.handlerValueListDate(value, 'doubleType', index)}
                        isRequired />
                      <Group style={{ display: 'flex' }}>
                        <Input
                          type={'number'}
                          name={'amountOfApartmentsDouble'}
                          label={'Número de aptos'}
                          value={item.amountOfApartmentsDouble}
                          error={!(item.amountOfApartmentsDouble > 0) && this.state.deuSubmit}
                          onChange={input => this.handlerValueList(input, 'doubleType', index)}
                          width='50%'
                          isRequired />
                        <Group style={{ width: '50%', margin: 'auto', display: 'flex', paddingLeft: '20px', alignItems: 'center' }}>
                          <Icons style={{ width: '30%' }} height='35px' icon='double-bed' />
                          <Label style={{ width: '70%' }}>1 cama de casal</Label>
                        </Group>
                      </Group>
                      <Group style={{ display: 'flex' }}>
                        <Input
                          type={'number'}
                          name={'amountOfApartmentsTwin'}
                          label={'Número de aptos'}
                          value={item.amountOfApartmentsTwin}
                          error={(!validateHospedagemDatesValid(this.state) || !validateHospedagemDatesRangeValid(this.state)) && this.state.deuSubmit}
                          onChange={input => this.handlerValueList(input, 'doubleType', index)}
                          width='50%'
                          isRequired />
                        <Group style={{ width: '50%', margin: 'auto', display: 'flex', paddingLeft: '20px', alignItems: 'center' }}>
                          <Icons style={{ width: '15%' }} height='35px' icon='single-bed' />
                          <Icons style={{ width: '15%' }} height='35px' icon='single-bed' />
                          <Label style={{ width: '70%', paddingLeft: '10px' }}>2 camas de solteiro</Label>
                        </Group>
                      </Group>
                    </Group>
                  })}
                  <BtnAdd onClick={() => this.addList('doubleType')}>
                    + Adicionar mais um período de hospedagem
                    </BtnAdd>
                </Group>
              </CheckBox>
              <CheckBox
                onClick={() => this.click('tripleType')}
                label="Apto tipo triplo"
                checked={this.state.tripleType.selected}>
                <Group style={{ display: this.state.tripleType.selected ? 'block' : 'none' }}>
                  {this.state.tripleType.list.map((item, index, array) => {
                    return <Group key={index}>
                      {(array && array.length > 1) &&
                        <BotaoExcluir call={() => this.removeLodgingFromIndex('tripleType', index)} />
                      }
                      <InputDatepicker
                        type={'range'}
                        label={'Período da hospedagem'}
                        placeholder={'Selecione a(s) data(s)'}
                        name={'tripleDates'}
                        dates={{ from: item.dates[0], to: item.dates[item.dates.length - 1] }}
                        error={(!validateHospedagemDatesValid(this.state) || !validateHospedagemDatesRangeValid(this.state)) && this.state.deuSubmit}
                        onChange={value => this.handlerValueListDate(value, 'tripleType', index)}
                        isRequired />
                      <Group style={{ display: 'flex' }}>
                        <Input
                          type={'number'}
                          name={'amountOfApartments'}
                          label={'Número de aptos'}
                          value={item.amountOfApartments}
                          error={!(item.amountOfApartments > 0) && this.state.deuSubmit}
                          onChange={input => this.handlerValueList(input, 'tripleType', index)}
                          width='50%'
                          isRequired />
                        <Group style={{ width: '50%', margin: 'auto', display: 'flex', paddingLeft: '20px', alignItems: 'center' }}>
                          <Icons style={{ width: '15%' }} height='35px' icon='single-bed' />
                          <Icons style={{ width: '15%' }} height='35px' icon='single-bed' />
                          <Icons style={{ width: '15%' }} height='35px' icon='single-bed' />
                          <Label style={{ width: '55%', paddingLeft: '10px' }}>3 camas de solteiro</Label>
                        </Group>
                      </Group>
                    </Group>
                  })}
                  <BtnAdd onClick={() => this.addList('tripleType')}>
                    + Adicionar mais um período de hospedagem
                    </BtnAdd>
                </Group>
              </CheckBox>
              <CheckBox
                onClick={this.itemsOnApartmentClick}
                label="Entrega de brindes no apto"
                checked={this.state.itemsOnApartment} />
              <Textarea
                type={'text'}
                label={'Observações'}
                placeholder={'Avise-nos caso precise de early check in ou late check out por exemplo.'}
                name={'observations'}
                value={this.state.observations}
                onChange={this.handlerObservations}
              />
            </Group>
          </Group>
          <Button
            type='submit'
            category={'secondary'}
            fontWeight={800}
            onClick={this.submit}
            style={{ width: '200px', marginLeft: 'auto' }}
            full>
            Próximo
          </Button>
        </Card>
      </Main>
    </>
  }

  paginaDesktop = () => {
    const { supplierChainId, hotelId } = this.props.match.params
    const gtmId = window.localStorage.getItem('gtmId')
    return <>
      {gtmId && TagManager.initialize({ gtmId })}
      <Header />
      <Main>
        <Card style={{ marginTop: '50px', maxWidth: '1000px' }}>
          <BreadCrumb options={
            [
              { link: `/espacos-para-eventos/${supplierChainId}${hotelId ? `/${hotelId}` : ''}/event`, name: 'Dados gerais do evento' },
              { name: 'Hospedagem' }
            ]
          } />
          <Group>
            {this.state.isLoading ? <Loader /> : null}
            <Label style={{ fontSize: '13px ', marginLeft: '10px' }}>Etapa 2 de 4</Label>
            <Title>Hospedagem {hotelId && `em ${this.state.hotelName}`}</Title>
            <Label style={{ marginTop: '20px', marginLeft: '10px' }}>Selecione as opções desejadas</Label>
            {!validateHospedagemForm(this.state) && this.state.deuSubmit ? <Alert type={'negative'}>Preencha todos os campos obrigatórios para continuar</Alert> : null}
            {validateHospedagemForm(this.state) && !validateHospedagemDatesValid(this.state) && this.state.deuSubmit ? <Alert type={'negative'}>Escolha no minimo dois dias, sendo o primeiro a data de check in e o segundo a data de check out.</Alert> : null}
            {validateHospedagemForm(this.state) && validateHospedagemDatesValid(this.state) && !validateHospedagemDatesRangeValid(this.state) && this.state.deuSubmit ? <Alert type={'negative'}>As datas de hospedagem devem ser no máximo 10 dias distantes das datas do evento (antes e/ou depois).</Alert> : null}
            <Group>
              <CheckBox
                onClick={() => this.click('singleType')}
                label="Apto tipo solteiro"
                checked={this.state.singleType.selected}>
                <Group style={{ display: this.state.singleType.selected ? 'block' : 'none' }}>
                  {this.state.singleType.list.map((item, index, array) => {
                    return <Group key={index}>
                      {(array && array.length > 1) &&
                        <BotaoExcluir call={() => this.removeLodgingFromIndex('singleType', index)} />
                      }
                      <InputDatepicker
                        type={'range'}
                        label={'Período da hospedagem'}
                        placeholder={'Selecione a(s) data(s)'}
                        name={'singleDates'}
                        dates={{ from: item.dates[0], to: item.dates[item.dates.length - 1] }}
                        error={(!validateHospedagemDatesValid(this.state) || !validateHospedagemDatesRangeValid(this.state)) && this.state.deuSubmit}
                        onChange={value => this.handlerValueListDate(value, 'singleType', index)}
                        isRequired />
                      <Group style={{ display: 'flex' }}>
                        <Input
                          type={'number'}
                          name={'amountOfApartments'}
                          label={'Número de aptos'}
                          value={item.amountOfApartments}
                          error={!(item.amountOfApartments > 0) && this.state.deuSubmit}
                          onChange={input => this.handlerValueList(input, 'singleType', index)}
                          width='50%'
                          isRequired />
                        <Group style={{ width: '50%', margin: 'auto', display: 'flex', paddingLeft: '20px', alignItems: 'center' }}>
                          <Icons style={{ width: '30%' }} height='35px' icon='double-bed' />
                          <Label style={{ width: '70%' }}>1 cama de casal</Label>
                        </Group>
                      </Group>
                    </Group>
                  })}
                  <BtnAdd onClick={() => this.addList('singleType')}>
                    + Adicionar mais um período de hospedagem
                    </BtnAdd>
                </Group>
              </CheckBox>
              <CheckBox
                onClick={() => this.click('doubleType')}
                label="Apto tipo duplo"
                checked={this.state.doubleType.selected}>
                <Group style={{ display: this.state.doubleType.selected ? 'block' : 'none' }}>
                  {this.state.doubleType.list.map((item, index, array) => {
                    return <Group key={index}>
                      {(array && array.length > 1) &&
                        <BotaoExcluir call={() => this.removeLodgingFromIndex('doubleType', index)} />
                      }
                      <InputDatepicker
                        type={'range'}
                        label={'Período da hospedagem'}
                        placeholder={'Selecione a(s) data(s)'}
                        name={'doubleDates'}
                        dates={{ from: item.dates[0], to: item.dates[item.dates.length - 1] }}
                        error={(!validateHospedagemDatesValid(this.state) || !validateHospedagemDatesRangeValid(this.state)) && this.state.deuSubmit}
                        onChange={value => this.handlerValueListDate(value, 'doubleType', index)}
                        isRequired />
                      <Group style={{ display: 'flex' }}>
                        <Input
                          type={'number'}
                          name={'amountOfApartmentsDouble'}
                          label={'Número de aptos'}
                          value={item.amountOfApartmentsDouble}
                          error={!(item.amountOfApartmentsDouble > 0) && this.state.deuSubmit}
                          onChange={input => this.handlerValueList(input, 'doubleType', index)}
                          width='50%'
                          isRequired />
                        <Group style={{ width: '50%', margin: 'auto', display: 'flex', paddingLeft: '20px', alignItems: 'center' }}>
                          <Icons style={{ width: '30%' }} height='35px' icon='double-bed' />
                          <Label style={{ width: '70%' }}>1 cama de casal</Label>
                        </Group>
                      </Group>
                      <Group style={{ display: 'flex' }}>
                        <Input
                          type={'number'}
                          name={'amountOfApartmentsTwin'}
                          label={'Número de aptos'}
                          value={item.amountOfApartmentsTwin}
                          error={(!validateHospedagemDatesValid(this.state) || !validateHospedagemDatesRangeValid(this.state)) && this.state.deuSubmit}
                          onChange={input => this.handlerValueList(input, 'doubleType', index)}
                          width='50%'
                          isRequired />
                        <Group style={{ width: '50%', margin: 'auto', display: 'flex', paddingLeft: '20px', alignItems: 'center' }}>
                          <Icons style={{ width: '15%' }} height='35px' icon='single-bed' />
                          <Icons style={{ width: '15%' }} height='35px' icon='single-bed' />
                          <Label style={{ width: '70%', paddingLeft: '10px' }}>2 camas de solteiro</Label>
                        </Group>
                      </Group>
                    </Group>
                  })}
                  <BtnAdd onClick={() => this.addList('doubleType')}>
                    + Adicionar mais um período de hospedagem
                    </BtnAdd>
                </Group>
              </CheckBox>
              <CheckBox
                onClick={() => this.click('tripleType')}
                label="Apto tipo triplo"
                checked={this.state.tripleType.selected}>
                <Group style={{ display: this.state.tripleType.selected ? 'block' : 'none' }}>
                  {this.state.tripleType.list.map((item, index, array) => {
                    return <Group key={index}>
                      {(array && array.length > 1) &&
                        <BotaoExcluir call={() => this.removeLodgingFromIndex('tripleType', index)} />
                      }
                      <InputDatepicker
                        type={'range'}
                        label={'Período da hospedagem'}
                        placeholder={'Selecione a(s) data(s)'}
                        name={'tripleDates'}
                        dates={{ from: item.dates[0], to: item.dates[item.dates.length - 1] }}
                        error={(!validateHospedagemDatesValid(this.state) || !validateHospedagemDatesRangeValid(this.state)) && this.state.deuSubmit}
                        onChange={value => this.handlerValueListDate(value, 'tripleType', index)}
                        isRequired />
                      <Group style={{ display: 'flex' }}>
                        <Input
                          type={'number'}
                          name={'amountOfApartments'}
                          label={'Número de aptos'}
                          value={item.amountOfApartments}
                          error={!(item.amountOfApartments > 0) && this.state.deuSubmit}
                          onChange={input => this.handlerValueList(input, 'tripleType', index)}
                          width='50%'
                          isRequired />
                        <Group style={{ width: '50%', margin: 'auto', display: 'flex', paddingLeft: '20px', alignItems: 'center' }}>
                          <Icons style={{ width: '15%' }} height='35px' icon='single-bed' />
                          <Icons style={{ width: '15%' }} height='35px' icon='single-bed' />
                          <Icons style={{ width: '15%' }} height='35px' icon='single-bed' />
                          <Label style={{ width: '55%', paddingLeft: '10px' }}>3 camas de solteiro</Label>
                        </Group>
                      </Group>
                    </Group>
                  })}
                  <BtnAdd onClick={() => this.addList('tripleType')}>
                    + Adicionar mais um período de hospedagem
                    </BtnAdd>
                </Group>
              </CheckBox>
              <CheckBox
                onClick={this.itemsOnApartmentClick}
                label="Entrega de brindes no apto"
                checked={this.state.itemsOnApartment} />
              <Textarea
                type={'text'}
                label={'Observações'}
                placeholder={'Avise-nos caso precise de early check in ou late check out por exemplo.'}
                name={'observations'}
                value={this.state.observations}
                onChange={this.handlerObservations}
              />
            </Group>
          </Group>
          <Button
            type='submit'
            category={'secondary'}
            fontWeight={800}
            onClick={this.submit}
            style={{ width: '200px', marginLeft: 'auto' }}
            full>
            Próximo
          </Button>
        </Card>
      </Main>
    </>
  }

  render () {
    return this.gerenciaResponsividade()
  }
}
