/* LIBS */
import React from 'react'
import { Route } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
/* import DevTools from './DevTools' */
/* SCREENS */
import Login from '../screens/Supplier/Login/index'
import ForgotPassword from '../screens/ForgotPassword'
import ResetPassword from '../screens/ResetPassword'
import Events from '../screens/Supplier/Events/index'
import SignUp from '../screens/Supplier/Signup/index'
import SignupAddress from '../screens/Supplier/Signup/SignupAddress'
import SignupServices from '../screens/Supplier/Signup/SignupServices'
// import SignupSupplierPolicy from '../screens/Supplier/Signup/SignupSupplierPolicy'
import Users from '../screens/Supplier/Users/index'
import FormUser from '../screens/Supplier/Users/FormUser'
import Equipments from '../screens/Supplier/Equipments/index'
import FormEquipments from '../screens/Supplier/Equipments/FormEquipments'
import FormParking from '../screens/Supplier/Parking'
import FormTransfer from '../screens/Supplier/Transfer'
import Rooms from '../screens/Supplier/Rooms/index'
import Room from '../screens/Supplier/Rooms/Room'
import FoodsAndBeverages from '../screens/Supplier/FoodsAndBeverages/index'
import FormFoodsAndBeverages from '../screens/Supplier/FoodsAndBeverages/FormFoodsAndBeverages'
import CompanyEdit from '../screens/Supplier/Company'
import BudgetList from '../screens/Supplier/Budget/BudgetList'
import BudgetRooms from '../screens/Supplier/Budget/Rooms/index'
import BudgetResume from '../screens/Supplier/Budget/Resume'
import BriefingRooms from '../screens/Supplier/Budget/Rooms/BriefingRooms'
import BriefingFoodsAndBeverages from '../screens/Supplier/Budget/FoodsAndBeverages/BriefingFoodsAndBeverages'
import BriefingLodging from '../screens/Supplier/Budget/Lodging/BriefingLodging'
import BriefingEquipments from '../screens/Supplier/Budget/Equipments/BriefingEquipments'
import BriefingTransfer from '../screens/Supplier/Budget/Transfer/BriefingTransfer'
import BudgetFoodsAndBeverages from '../screens/Supplier/Budget/FoodsAndBeverages'
import AllInfo from '../screens/Supplier/Budget/AllInfo'
import BriefingOtherServices from '../screens/Supplier/Budget/OtherServices/BriefingOtherServices'

const SupplierRoutes = () => {
  return (
    <div>
      <Route path='/signin' exact component={Login} />
      <Route path='/signup' exact component={SignUp} />
      <Route path='/signup/address' exact component={SignupAddress} />
      <Route path='/signup/services' exact component={SignupServices} />
      {/* Comentado politica dos fornecedores */}
      {/* <Route path='/signup/policy' exact component={SignupSupplierPolicy} /> */}
      <Route path='/forgot_password' exact component={ForgotPassword} />
      <Route path='/reset_password/:token' exact component={ResetPassword} />
      <Route path='/budget/all-info/:id' exact component={AllInfo} />
      <PrivateRoute type='SUPPLIER' path='/' exact component={Events} />
      <PrivateRoute type='SUPPLIER' path='/users' exact component={Users} />
      <PrivateRoute type='SUPPLIER' path='/users/add' exact component={FormUser} />
      <PrivateRoute type='SUPPLIER' path='/equipments' exact component={Equipments} />
      <PrivateRoute type='SUPPLIER' path='/equipments/add' exact component={FormEquipments} />
      <PrivateRoute type='SUPPLIER' path='/equipments/add/:event/:equipment' exact component={FormEquipments} />
      <PrivateRoute type='SUPPLIER' path='/transfer/add' exact component={FormTransfer} />
      <PrivateRoute type='SUPPLIER' path='/parking/add' exact component={FormParking} />
      <PrivateRoute type='SUPPLIER' path='/rooms' exact component={Rooms} />
      <PrivateRoute type='SUPPLIER' path='/rooms/add' exact component={Room} />
      <PrivateRoute type='SUPPLIER' path='/rooms/edit/:id' exact component={Room} />
      <PrivateRoute type='SUPPLIER' path='/foods-and-beverages' exact component={FoodsAndBeverages} />
      <PrivateRoute type='SUPPLIER' path='/foods-and-beverages/edit' component={FormFoodsAndBeverages} />
      <PrivateRoute type='SUPPLIER' path='/foods-and-beverages/edit/:id' component={FormFoodsAndBeverages} />
      <PrivateRoute type='SUPPLIER' path='/foods-and-beverages/edit/:id/:event/:foodsandbeverage' component={FormFoodsAndBeverages} />
      <PrivateRoute type='SUPPLIER' path='/company/edit' component={CompanyEdit} />
      <PrivateRoute type='SUPPLIER' path='/budget' exact component={BudgetList} />
      <PrivateRoute type='SUPPLIER' path='/budget/resume/:id/:sent?' exact component={BudgetResume} />
      <PrivateRoute type='SUPPLIER' path='/budget/rooms/:id' exact component={BudgetRooms} />
      <PrivateRoute type='SUPPLIER' path='/budget/briefing/rooms/:event/:room' exact component={BriefingRooms} />
      <PrivateRoute type='SUPPLIER' path='/budget/briefing/foodsandbeverage/:event/:foodsandbeverage/:budget?' exact component={BriefingFoodsAndBeverages} />
      <PrivateRoute type='SUPPLIER' path='/budget/briefing/lodging/:event/:lodging/:budget?' exact component={BriefingLodging} />
      <PrivateRoute type='SUPPLIER' path='/budget/briefing/equipments/:event/:equipment/:budget?' exact component={BriefingEquipments} />
      <PrivateRoute type='SUPPLIER' path='/budget/briefing/other-services/:event/:otherServices/:budget?' exact component={BriefingOtherServices} />
      <PrivateRoute type='SUPPLIER' path='/budget/briefing/transfer/:event/:transfer/:budget?' exact component={BriefingTransfer} />
      <Route path='/budget/foodsandbeverage' exact component={BudgetFoodsAndBeverages} />
    </div>
  )
}

export default SupplierRoutes
