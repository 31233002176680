/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../../../../styles/themes'
/* COMPONENTS */
import Input from '../../../../components/ui/Input'
import InputDatepicker from '../../../../components/InputDatepicker'
import Icons from '../../../../components/ui/Icons'


const Card = styled.div`
  display: flex;
  background-color: #FFF;
  border: none;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
  flex-wrap: wrap;
  margin: 15px 0px;
  width: 100%;
  padding: 10px;
`
const Title = styled.span`
  font-size: 16px;
  font-weight: 500;
  font-family: ${defaultTheme.fonts.secondary};
  padding: 5px 0px;
  color: #484848;
`
const Header = styled.div`
  align-items: flex-start;
  cursor: pointer;
  width: 100%;
`
const Checkbox = styled.input`
  cursor: pointer;
`
const Label = styled.label`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
const Content = styled.div`
  background-color: #FFF;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
  display: flex;
  flex-wrap: wrap;
  margin: 5px 0px;
  width: 100%;
`
const BtnAdd = styled.span`
  padding: 5px 0;
  margin-top: 10px;
  display: block;
  cursor: pointer;
  color: ${defaultTheme.colors.whiteLabel.primary};
  font-size: 14px;
  font-family: ${defaultTheme.fonts.secondary};
`

const Beds = styled.div`
  color: #666;
  font-family: ${defaultTheme.fonts.primary};
  padding: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

const QuantityAptos = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const Room = () => {
  return (
    <Content>
      <InputDatepicker
        label={'Período da hospedagem'}
        dateSelect={'range'}
        isRequired/>
      <QuantityAptos>
        <Input
          label={'Número de aptos'}
          isRequired
          width={'30%'}
          type={'number'}
          placeholder={'0'} />
        <Beds>
          <Icons
            icon={'single-bed'}
            color={'gray'}
            width={33}
            height={30} />
          <Icons
            icon={'single-bed'}
            color={'gray'}
            width={33}
            height={30} />
          <Icons
            icon={'single-bed'}
            color={'gray'}
            width={33}
            height={30} />
          3 Camas de solteiro
        </Beds>
      </QuantityAptos>
    </Content>
  )
}

const RenderRooms = ({rooms}) => {
  return rooms.map((room, i) => {
    return <Room key={i}/>
  })
}

class RoomTriple extends Component {
  state = {isEnabled: false, rooms: []}
  toggle = () => {
    this.setState(({isEnabled}) => ({
      isEnabled: !isEnabled
    }))
  }

  addRoom = () => {
    const rooms = this.state.rooms
    rooms.push({})
    this.setState({rooms: rooms})
  }

  render() {
    const { isEnabled, rooms } = this.state
    return (
      <Card>
        <Header>
          <Label>
            <Title>
              Apto tipo triplo
            </Title>
            <Checkbox
              type='checkbox'
              checked={isEnabled}
              onClick={this.toggle}/>
          </Label>
        </Header>
        {isEnabled && <RenderRooms rooms={rooms}/>}
        {isEnabled && <BtnAdd onClick={this.addRoom}>+ Adicionar mais um período de hospedagem</BtnAdd>}
      </Card>
    )
  }
}

export default RoomTriple
