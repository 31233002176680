/* LIBS */
import React, { Component } from 'react'
import Header from '../../../Header'
import Body from '../../../../components/Body'
import Main from '../../../../components/Main'
import Card from '../../../../components/ui/Card'
import Icons from '../../../../components/ui/Icons'
import Button from '../../../../components/ui/Button'
import Lodging from './Lodging'
import styled from 'styled-components'
import { defaultTheme } from '../../../../styles/themes';
import { Get } from '../../../../../utils/request';
import { formatDate } from '../../../../../utils/dates';
import config from '../../../../../middleware/config';
import ServicosSolicitados from '../../../../components/ServicosSolicitados';
import { ehChainUser } from '../../../../../utils/tiposUsuario'

const Label = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  box-sizing: border-box;
  padding: 5px 5px 5px 0px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
`

const FixedDiv = styled.div`
  position: fixed;
  top: 46px;
  bottom: 0;
  width: 25%;
  overflow-y: scroll;
  margin-top: 14px;

`

const Group = styled.div`
`

export default class LodgingWrapper extends Component {
  constructor () {
    super()
    this.state = {
      eventStartDate: '',
      eventEndDate: '',
      eventName: '',
      eventLocation: '',
      startTime: '',
      endTime: '',
      numberOfParticipants: '',
      services: '',
    }
  }


  componentWillMount() {
    if (ehChainUser()) {
      alert('Você não tem permissão para acessar esta página.')
      this.props.history.push(`/organizer`)
    }
    this.fetchEventData()
  }

  fetchEventData = () => {
    const { id } = this.props.match.params
    const { api: { base, version } } = config
    const userType = window.localStorage.getItem('user_type')
    const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
    const newEndpoint = `${base}${version}${eventEndpoint}/${id}`
    Get(newEndpoint)
      .then((response) => {
        this.setState({ isLoading: false })
        if (!response) {
          this.setState({ errorMessage: response.message })
          return Promise.reject(response)
        } else {
          this.setState({
            eventStatus: response.status,
            eventStartDate: response.start,
            eventEndDate: response.end,
            eventName: response.name,
            eventLocation: response.location,
            eventFriendlyId: response.internalId || response.friendlyId,
            startTime: response.startTime,
            endTime: response.endTime,
            numberOfParticipants: response.numberOfParticipants,
            services: response.services
          })
        }
      })
    }

  submitFunction = submit => {
    this.setState({
      submit: submit
    })
  }

  checkEdicaoFunction = checkEdicao => {
    this.setState({
      checkEdicao: checkEdicao
    })
  }

  gerenciaResponsividade = () => {
    if(window.innerWidth < 600) return this.paginaMobile()
    else return this.paginaDesktop()
  }

  paginaMobile = () => {
    return <>
      <Header />
      <Lodging submit={this.submitFunction} checkEdicao={this.checkEdicaoFunction} {...this.props} />
    </>
  }

  paginaDesktop = () => {
    return <>
      <Header />
      <Main>
        <Body {...this.props} pagina={this.state.eventStatus === 'INACTIVE' ? 'create-evento' : 'editar-evento'} menuLateralDireito={this.menuLateralDireito()}>
          <Lodging submit={this.submitFunction} checkEdicao={this.checkEdicaoFunction} {...this.props} />
        </Body>
      </Main>
    </>
  }

  menuLateralDireito = () => {
    const { briefing } = this.props.match.params
    return <FixedDiv>
      <Card style={{ width: '100%' }}>
        <Label style={{ textTransform: 'uppercase' }}>Resumo do Evento <span style={{ float: 'right', paddingRight: '10px' }}>ID:{this.state.eventFriendlyId}</span></Label>
        <Group>
          <Label style={{ fontWeight: 600, color: '#484848' }}>{this.state.eventName}</Label>
          <Group style={{ display: 'flex' }}>
            <Icons
              icon={'calendario'}
              color={'gray'}
              width={40}
              height={20}
              style={{ marginRight: '-5px', marginTop: '5px' }} />
            <Label>{formatDate(this.state.eventStartDate)} - {formatDate(this.state.eventEndDate)}</Label>
          </Group>
          <Group style={{ display: 'flex' }}>
            <Icons
              icon={'map-pin'}
              color={'gray'}
              width={40}
              height={20}
              style={{ marginRight: '-5px', marginTop: '5px', marginLeft: '2px' }} />
            <Label>{this.state.eventLocation.address} - {this.state.eventLocation.city}</Label>
          </Group>
          <Group style={{ display: 'flex' }}>
            <Icons
              icon={'pessoas'}
              color={'gray'}
              width={40}
              height={20}
              style={{ marginTop: '5px', marginLeft: '-3px' }} />
            <Label>{this.state.numberOfParticipants} pessoas</Label>
          </Group>
        </Group>
      </Card>
      <Card style={{ width: '100%' }}>
        <ServicosSolicitados services={this.state.services} selected={'lodging'} />
        <Button
          type='submit'
          category={'secondary'}
          fontWeight={800}
          onClick={this.state.eventStatus === 'INACTIVE' ? this.state.submit : this.state.checkEdicao}
          full>
          {briefing ? 'Ok' : 'Próximo'}
        </Button>
      </Card>
    </FixedDiv>
  }

  render () {
    return this.gerenciaResponsividade()
  }
}
