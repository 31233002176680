/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
import config from '../../../middleware/config'
import { Get, Post } from '../../../utils/request'
import { formataCnpj } from '../../../utils/formataCnpj'
/* STYLES */
import { defaultTheme } from '../../styles/themes'
/* COMPONENTS */
import Header from '../../components/Header'
import Loader from '../../components/Loader'
import Modal from '../../components/Modal'
import Alert from '../../components/ui/Alert'
import AlertNormalIcon from '../../components/ui/AlertNormalIcon'
import Icons from '../../components/ui/Icons'
import Card from '../../components/ui/Card'
import Button from '../../components/ui/Button'
import Title from '../../components/ui/Title'
import MoneyInput from '../../components/ui/MoneyInput'
import Money from '../../components/ui/Money'
import Subtitle from '../../components/ui/Subtitle'
import BreadCrumb from '../../components/BreadCrumb'
import BillingConditions from './BillingConditions'
import MotivoIndisponibilidade from './MotivoIndisponibilidade'
import { getDateRange } from '../../../utils/dates'
import { formatDate } from '../../../utils/dates'
import { getNameFromKey } from '../../../utils/fabTexts'
import { getNameFromKey as transferText } from '../../../utils/transferTexts'
import { formatCurrency } from '../../../utils/money'
import { checkIfItemWasEdited, checkIfItemsWasEdited } from '../../../utils/checkEdition'
import { forneceServico, TRANSFER, OTHER_SERVICES } from '../../../utils/servicosFornecedor'
import moment from 'moment'
import { ForneceTransfer, ForneceOutrosServicos } from '../../../utils/servicosFornecedor'
import ButtonChat from '../../components/ui/ButtonChat'
import ChatRoom from '../../components/Chat'

const Main = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #FAFAFA;
`
const Content = styled.div`
  min-height: 70vh;
  padding: 20px;
  font-family: ${defaultTheme.fonts.secondary};
  width: 70%;
  font-size: 14px;
  a {
    text-decoration: none;
  }
`

const Titulo = styled.h1`
  font-family: "Pluto", sans-serif;
  color: #333;
  line-height: 1.2;
  font-size: 1.3em;
`

const TitleBold = styled.h1`
  font-family: ${defaultTheme.fonts.secondary};
  color: ${defaultTheme.colors.text.darker};
  font-size: 15px;
`

const Link = styled.a`
  font-size: 13px;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  color: ${defaultTheme.colors.primary.default};
`

const Checkbox = styled.input`
  font-size: 16px;
  background-color: #FFF;
  border-radius: 1px;
  cursor: pointer;
  padding: 6px 10px;
  border-bottom: ${props => props.error ? '2px solid red' : `2px solid ${defaultTheme.colors.primary.default}`};
  transition: all 0.2s linear;
  height: 20px;
  width: 20px;
`
const Label = styled.label`
  align-items: center;
  /* background-color: #FFF; */
  cursor: pointer;
  justify-content: space-between;
  display: flex;
  color: ${props => props.error ? 'red' : '#484848'};
  font-family: ${defaultTheme.fonts.secondary};
  font-weight: 500;
  box-sizing: border-box;
  justify-content: flex-end;
  padding: 10px;
  width: 100%;
`

const Descricao = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  box-sizing: border-box;
  padding: 5px 5px 5px 0px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
`

const Group = styled.div`
  position: relative;
  cursor: ${props => props.podeClicar ? 'pointer' : 'initial'};
`

const Tag = styled.div`
  padding: 3px 5px;
  font-size: 12px;
  font-family: ${defaultTheme.fonts.primary};
  color: #FAFAFA;
  background-color: ${props => props.edited ? '#F39C12' : (props.contraproposta ? '#8800dd' : props.editable ? '#2288bb' : '#009688')};
  border: none;
  border-radius: 15px;
  position: absolute;
  right: 10px;
  top: 10px;
  text-transform: uppercase;
  z-index: 9;
`

const Tooltip = styled.span`
  cursor: pointer;
  padding: 15px;
  position: absolute;
  top: -40px;
  left: 195px;
  background-color: #fff;
  line-height: 20px;
  margin-left: -64px;
  margin-top: -24px;
  text-align: center;
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: 1px 1px 2px rgba(0,0,0,0.1);
  z-index: 999;
  width: fit-content;
`

class Resume extends Component {
  state = {
    showMessageIcon: false,
    unseeMessages: 0,
    isLoading: true,
    errorMessage: false,
    sessions: [],
    event: { client: {} },
    availability: true,
    reseller: undefined,
    counterProposals: [],
    modalIndisponibilidade: false,
    modalAceitarContraProposta: false,
    valorModalContraproposta: 0,
    orcamentoInicialModalContraproposta: 0,
    supplierChainOrganizer: null
  }

  componentDidMount () {
    const { api: { base, version, routes: { supplier, events } } } = config
    const { match: { params: { id } }, history } = this.props
    if (id) {
      const endpoint = `${base}${version}${supplier}${events}/${id}`
      Get(endpoint).then((response) => {
        if (!response) {
          this.setState({ errorMessage: response.message, isLoading: false })
          return Promise.reject(response)
        } else {
          const sessions = this.state.sessions

          if (response.event.services.lodging.requested) {
            sessions.push({ name: 'Hospedagem', checked: response.budgets.lodging.all.length > 0, link: (response.budgets.lodging.all.length > 0 ? `/budget/briefing/lodging/${id}/${response.event.services.lodging.id}/${response.budgets.lodging.mostRecent.id}` : `/budget/briefing/lodging/${id}/${response.event.services.lodging.id}`) })
          }

          if (response.event.services.room.requested) {
            if (response.budgets.rooms.mostRecent) {
              sessions.push({ name: 'Salas', checked: response.budgets.rooms.mostRecent.roomsBudget.length > 0, link: `/budget/briefing/rooms/${id}/${response.budgets.rooms.mostRecent.roomsBudget[0].roomBriefingId}` })
            } else {
              sessions.push({ name: 'Salas', checked: response.budgets.rooms.all.length > 0, link: `/budget/briefing/rooms/${id}/${response.briefings.rooms.rooms[0].id}` })
            }
          }

          if (response.event.services.foodsAndBeverages.requested) {
            sessions.push({ name: 'Alimentos e bebidas', checked: response.budgets.foodsAndBeverages.all.length > 0, link: (response.budgets.foodsAndBeverages.all.length > 0 ? `/budget/briefing/foodsandbeverage/${id}/${response.event.services.foodsAndBeverages.id}/${response.budgets.foodsAndBeverages.mostRecent.id}` : `/budget/briefing/foodsandbeverage/${id}/${response.event.services.foodsAndBeverages.id}`) })
          }

          if (response.event.services.equipments.requested) {
            sessions.push({ name: 'Equipamentos', checked: response.budgets.equipments.all.length > 0, link: (response.budgets.equipments.all.length > 0 ? `/budget/briefing/equipments/${id}/${response.event.services.equipments.id}/${response.budgets.equipments.mostRecent.id}` : `/budget/briefing/equipments/${id}/${response.event.services.equipments.id}`) })
          }

          if (response.event.services.transfer.requested) {
            sessions.push({ name: 'Transfer', checked: response.budgets.transfer.all.length > 0, link: (response.budgets.transfer.all.length > 0 ? `/budget/briefing/transfer/${id}/${response.event.services.transfer.id}/${response.budgets.transfer.mostRecent.id}` : `/budget/briefing/transfer/${id}/${response.event.services.transfer.id}`) })
          }

          if (response.event.services.otherServices.requested) {
            sessions.push({ name: 'Outros Serviços', checked: (response.budgets.otherServices && response.budgets.otherServices.all.length > 0), link: ((response.budgets.otherServices && response.budgets.otherServices.all.length > 0) ? `/budget/briefing/other-services/${id}/${response.event.services.otherServices.id}/${response.budgets.otherServices.mostRecent.id}` : `/budget/briefing/other-services/${id}/${response.event.services.otherServices.id}`) })
          }

          this.setState({
            event: response.event,
            sessions,
            isLoading: false,
            budgets: response.budgets,
            briefings: response.briefings,
            supplierChainOrganizer: response.supplierChainOrganizer
          })
        }
      }).catch(erro => {
        alert('Erro ao buscar os dados, entre em contato com o suporte via chat')
        console.log('Error: ', erro)
        history.push('/')
      })
    }
    this.fetchReseller()
    this.fetchEditDifferences()
    this.fetchCounterProposals()
    this.getUnseenCountMessages()
  }

  fetchReseller () {
    const { api: { base, version, routes: { events } } } = config
    const { match: { params: { id } } } = this.props
    const endpoint = `${base}${version}${events}/${id}/reseller`

    Get(endpoint).then((reseller) => {
      this.setState({ reseller })
    }).catch(err => {
      alert('Erro ao buscar os dados, entre em contato com o suporte via chat')
      console.log('Error: ', err)
    })
  }

  fetchCounterProposals () {
    const { api: { base, version, routes: { events } } } = config
    const { match: { params: { id } } } = this.props
    const endpoint = `${base}${version}${events}/${id}/counter-proposals/supplier`

    return Get(endpoint).then(response => {
      this.setState({ counterProposals: response })
    }).catch(e => {
      alert('Erro ao buscar as informações, entre em contato com o suporte via chat')
      console.error(e)
    })
  }

  getUnseenCountMessages = () => {
    const { api: { base, version, routes: { chat } } } = config
    const { match: { params: { id: eventId } } } = this.props
    const accountId = window.localStorage.getItem('account')
    const counterEndpoint = `${base}${version}${chat}/event/${eventId}/supplier/${accountId}/unseen-messages-count`

    Get(counterEndpoint)
      .then(resposta => this.handleOnUnseeMessage((resposta && resposta.unseenMessages) || 0))
      .catch(erro => { console.error(erro) })
  }

  fetchEditDifferences () {
    const { api: { base, version, routes: { events } } } = config
    const { match: { params: { id } } } = this.props
    const endpoint = `${base}${version}${events}/${id}/rollback/difference`

    Get(endpoint).then((differences) => {
      this.setState({ differences })
    }).catch(err => {
      console.log('Error: ', err)
    })
  }

  submit = () => {
    if (!this.isValid()) return alert('Você ainda não cadastrou todos os orçamentos para este serviço.')
    if (this.state.availability && !this.isNotReview()) return alert('Você ainda não revisou todos os orçamentos para este serviço.')

    if (!this.state.availability) this.setState({ modalIndisponibilidade: true })
    else {
      if (this.state.event.supplierChainId) {
        this.setState({ isLoading: true }, () => {
          const { api: { base, version, routes: { events } } } = config
          const { match: { params: { id } }, history } = this.props
          const endpoint = (!this.state.availability) ? `${base}${version}${events}/${id}/budget/refuse`
            : `${base}${version}/budget${events}/${id}/activate`
          Post(endpoint, { newBillingConditions: false, percentage: 0, days: 0 })
            .then(() => {
              this.setState({ isLoading: false })
              alert('Orçamento enviado!')
              history.push('/organizer')
            }).catch(err => {
              this.setState({ isLoading: false })
              console.log('Error: ', err)
            })
        })
      } else {
        this.setState({ BillingConditionsOpen: !this.state.BillingConditionsOpen })
      }
    }
  }

  submitUnavailability = ({ reason, details }) => {
    const { api: { base, version, routes: { events } } } = config
    const { match: { params: { id } }, history } = this.props
    const endpoint = `${base}${version}${events}/${id}/budget/refuse`
    this.setState({ isLoading: true }, () => {
      Post(endpoint, { reason, details }).then(() => {
        alert('Indisponibilidade confirmada!')
        history.push('/organizer')
      }).catch(erro => {
        alert(erro)
        console.log('Error:', erro)
        this.setState({ isLoading: false })
      })
    })
  }

  submitBillingConditions = ({ newBillingConditions, percentage, days }) => {
    if (!this.isValid()) return alert('Você ainda não cadastrou todos os orçamentos para este serviço.')
    if (!this.isNotReview()) return alert('Você ainda não revisou todos os orçamentos para este serviço.')
    this.setState({ isLoading: true }, () => {
      const { api: { base, version, routes: { events } } } = config
      const { match: { params: { id } }, history } = this.props
      const endpoint = (!this.state.availability) ? `${base}${version}${events}/${id}/budget/refuse`
        : `${base}${version}/budget${events}/${id}/activate`
      Post(endpoint, { newBillingConditions, percentage, days }).then(() => {
        alert('Orçamento enviado!')
        history.push('/organizer')
      }).catch(err => {
        alert('Erro ao fazer a solicitação, entre em contato com o suporte via chat')
        this.setState({ isLoading: false })
        console.log('Error: ', err)
      })
    })
  }

  isValid = () => {
    const transferValid = forneceServico(TRANSFER) ? !(!this.state.budgets.transfer.mostRecent && this.state.briefings.transfer) : true
    if (ForneceTransfer()) return transferValid || !this.state.availability

    const otherServicesValid = forneceServico(OTHER_SERVICES) ? !(!this.state.budgets.otherServices.mostRecent && this.state.briefings.otherServices) : true
    if (ForneceOutrosServicos()) return otherServicesValid || !this.state.availability

    const lodgingValid = !(!this.state.budgets.lodging.mostRecent && this.state.briefings.lodging)
    const roomValid = !(!this.state.budgets.rooms.mostRecent && this.state.briefings.rooms)
    const foodsAndBeveragesValid = !(!this.state.budgets.foodsAndBeverages.mostRecent && this.state.briefings.foodsAndBeverages)
    const equipmentsValid = !(!this.state.budgets.equipments.mostRecent && this.state.briefings.equipments)
    return (lodgingValid && roomValid && foodsAndBeveragesValid && equipmentsValid) || !this.state.availability
  }

  isNotReview = () => {
    const transferValid = this.state.briefings.transfer ? (this.state.budgets.transfer.mostRecent ? this.state.budgets.transfer.mostRecent.status !== 'REVIEW' : false) : true
    const othersServicesValid = this.state.briefings.otherServices ? (this.state.budgets.otherServices.mostRecent ? this.state.budgets.otherServices.mostRecent.status !== 'REVIEW' : false) : true
    if (ForneceTransfer()) return transferValid
    if (ForneceOutrosServicos()) return othersServicesValid

    const lodgingValid = this.state.briefings.lodging ? (this.state.budgets.lodging.mostRecent ? this.state.budgets.lodging.mostRecent.status !== 'REVIEW' : false) : true
    const roomValid = this.state.briefings.rooms ? (this.state.budgets.rooms.mostRecent ? this.state.budgets.rooms.mostRecent.status !== 'REVIEW' : false) : true
    const foodsAndBeveragesValid = this.state.briefings.foodsAndBeverages ? (this.state.budgets.foodsAndBeverages.mostRecent ? this.state.budgets.foodsAndBeverages.mostRecent.status !== 'REVIEW' : false) : true
    const equipmentsValid = this.state.briefings.equipments ? (this.state.budgets.equipments.mostRecent ? this.state.budgets.equipments.mostRecent.status !== 'REVIEW' : false) : true
    return lodgingValid && roomValid && foodsAndBeveragesValid && equipmentsValid
  }

  changeAvailability = () => {
    this.setState({
      availability: !this.state.availability
    })
  }

  redirecionaParaOLink = (link) => {
    const { history } = this.props
    history.push(link)
  }

  verificaSeApresentaServico = (servico) => {
    const listaDeServicos = window.localStorage.getItem('services') || []
    return listaDeServicos.indexOf(servico) !== -1
  }

  verificaSeAlgumOrcamentoEstaEmReview = () => {
    const servicos = this.state && this.state.event && this.state.event.services
    const orcamentos = this.state && this.state.budgets
    return servicos && Object.keys(servicos).some(budget => servicos[budget] && servicos[budget].requested && orcamentos[budget] && orcamentos[budget].mostRecent && orcamentos[budget].mostRecent.status === 'REVIEW')
  }


  verificaSeAlgumOrcamentoPossuiContraProposta = () => {
    const servicos = this.state && this.state.event && this.state.event.services
    const orcamentos = this.state && this.state.budgets
    return servicos && orcamentos && Object.keys(servicos).some(budget => servicos[budget] && servicos[budget].requested && orcamentos[budget] && orcamentos[budget].mostRecent && orcamentos[budget].mostRecent.status === 'COUNTER_PROPOSAL')
  }

  verificaSeAlgumOrcamentoEstaEmReviewOuContraProposta = () => {
    return this.verificaSeAlgumOrcamentoEstaEmReview() || this.verificaSeAlgumOrcamentoPossuiContraProposta()
  }

  pegaContraPropostaPorServico = (servico) => {
    return this.state.counterProposals.filter(services =>
      services.some(service => service.service === servico)).length > 0
      ? (
        this.state.counterProposals.filter(services => services.some(service => service.service === servico))[0].length > 0
          ? this.state.counterProposals.filter(services => services.some(service => service.service === servico))[0][0].counterProposals
          : []
      )
      : []
  }

  pegaUltimaPropostaEnviadaPorServico = (servico) => {
    if (servico === 'rooms') {
      servico = 'room'
    }
    const listaOrdenada = this.pegaContraPropostaPorServico(servico).filter(item => item.valid &&
      (item.requester === 'ORGANIZER' || (item.requester === 'SUPPLIER' && item.supplierStatus === 'ACCEPTED'))
    ).sort((a, b) => new Date(b.date) - new Date(a.date))
    return listaOrdenada.length > 0 ? listaOrdenada[0] : {}
  }

  verificaSePodeFazerNovaOferta = (servico) => {
    if (servico === 'rooms') {
      servico = 'room'
    }
    return this.pegaContraPropostaPorServico(servico).filter(item => item.valid && item.requester === 'SUPPLIER' && item.supplierStatus === 'ACCEPTED').length === 0
  }

  verificaSeFoiUmaNovaOferta = (servico) => {
    return this.pegaUltimaPropostaEnviadaPorServico(servico).requester === 'SUPPLIER' && this.pegaUltimaPropostaEnviadaPorServico(servico).supplierStatus === 'ACCEPTED'
  }

  checkIfObjectIsEmpty = (obj) => {
    return Object.entries(obj).length === 0 && obj.constructor === Object
  }

  criaLabelDeDiferencaDaContraProposta = (valorInicial, valorContraproposta) => {
    const porcentagem = ((valorInicial - valorContraproposta) * 100) / valorInicial
    return `- ${formatCurrency(valorInicial - valorContraproposta)} (${parseFloat(Math.round(porcentagem * 100) / 100).toFixed(2)}%)`
  }

  aceitarContraProposta = (servico) => {
    const { match: { params: { id } }, history } = this.props
    var endpoint = {}
    endpoint['lodging'] = this.state.budgets.lodging.all.length > 0 ? `/budget/briefing/lodging/${id}/${this.state.event.services.lodging.id}/${this.state.budgets.lodging.mostRecent.id}` : `/budget/briefing/lodging/${id}/${this.state.event.services.lodging.id}`
    endpoint['room'] = this.state.budgets.rooms.mostRecent ? `/budget/briefing/rooms/${id}/${this.state.budgets.rooms.mostRecent.roomsBudget[0].roomBriefingId}` : `/budget/briefing/rooms/${id}/${this.state.briefings.rooms && this.state.briefings.rooms.rooms[0].id}`
    endpoint['foodsAndBeverages'] = this.state.budgets.foodsAndBeverages.all.length > 0 ? `/budget/briefing/foodsandbeverage/${id}/${this.state.event.services.foodsAndBeverages.id}/${this.state.budgets.foodsAndBeverages.mostRecent.id}` : `/budget/briefing/foodsandbeverage/${id}/${this.state.event.services.foodsAndBeverages.id}`
    endpoint['equipments'] = this.state.budgets.equipments.mostRecent ? `/budget/briefing/equipments/${id}/${this.state.briefings.equipments.id}/${this.state.budgets.equipments.mostRecent.id}` : `/budget/briefing/equipments/${id}/${this.state.briefings.equipments && this.state.briefings.equipments.id}`

    return history.push({ pathname: endpoint[servico], state: { counterProposal: 'ACCEPTED', requestedPrice: this.pegaUltimaPropostaEnviadaPorServico(servico).requestedPrice } })
  }

  fazerNovaContraProposta = (servico) => {
    const { match: { params: { id } }, history } = this.props
    var endpoint = {}
    endpoint['lodging'] = this.state.budgets.lodging.all.length > 0 ? `/budget/briefing/lodging/${id}/${this.state.event.services.lodging.id}/${this.state.budgets.lodging.mostRecent.id}` : `/budget/briefing/lodging/${id}/${this.state.event.services.lodging.id}`
    endpoint['room'] = this.state.budgets.rooms.mostRecent ? `/budget/briefing/rooms/${id}/${this.state.budgets.rooms.mostRecent.roomsBudget[0].roomBriefingId}` : `/budget/briefing/rooms/${id}/${this.state.briefings.rooms && this.state.briefings.rooms.rooms[0].id}`
    endpoint['foodsAndBeverages'] = this.state.budgets.foodsAndBeverages.all.length > 0 ? `/budget/briefing/foodsandbeverage/${id}/${this.state.event.services.foodsAndBeverages.id}/${this.state.budgets.foodsAndBeverages.mostRecent.id}` : `/budget/briefing/foodsandbeverage/${id}/${this.state.event.services.foodsAndBeverages.id}`
    endpoint['equipments'] = this.state.budgets.equipments.mostRecent ? `/budget/briefing/equipments/${id}/${this.state.briefings.equipments.id}/${this.state.budgets.equipments.mostRecent.id}` : `/budget/briefing/equipments/${id}/${this.state.briefings.equipments && this.state.briefings.equipments.id}`

    return history.push({ pathname: endpoint[servico], state: { counterProposal: 'NEW', requestedPrice: this.pegaUltimaPropostaEnviadaPorServico(servico).requestedPrice, initialPrice: this.pegaUltimaPropostaEnviadaPorServico(servico).initialPrice } })
  }

  recusarContraProposta = (servico) => {
    this.setState({ isLoading: true }, () => {
      const { api: { base, version } } = config
      const { match: { params: { id } }, history } = this.props
      var endpointHelper = {}
      endpointHelper['lodging'] = this.state.budgets.lodging.mostRecent && `/counter-proposal/refuse/${this.state.budgets.lodging.mostRecent.id}`
      endpointHelper['room'] = this.state.budgets.rooms.mostRecent && `/counter-proposal/refuse/${this.state.budgets.rooms.mostRecent.id}`
      endpointHelper['foodsAndBeverages'] = this.state.budgets.foodsAndBeverages.mostRecent && `/counter-proposal/refuse/${this.state.budgets.foodsAndBeverages.mostRecent.id}`
      endpointHelper['equipments'] = this.state.budgets.equipments.mostRecent && `/counter-proposal/refuse/${this.state.budgets.equipments.mostRecent.id}`

      const endpoint = `${base}${version}/budget/${servico}${endpointHelper[servico]}`
      Post(endpoint)
        .then(() => {
          this.setState({ isLoading: false })
          alert('Contraproposta recusada!')
          history.push(`/`)
          history.push(`/budget/resume/${id}`)
        }).catch(err => {
          alert('Erro ao fazer a solicitação, entre em contato com o suporte via chat')
          this.setState({ isLoading: false })
          console.log('Error: ', err)
        })
    })
  }

  modalAceitarContraProposta = () => {
    return <Modal isOpen={this.state.modalAceitarContraProposta} hasCloseButton onClose={() => this.setState({ modalAceitarContraProposta: false })}>
      <AlertNormalIcon width={'150px'} />
      <Title style={{ paddingBottom: '10px' }}>{this.state.modalNovaOferta ? 'Fazer nova oferta' : 'Aceitar contraproposta'}</Title>
      <Subtitle style={{ marginBottom: '0px', fontWeight: 600 }}>Valor da contraproposta: <span style={{ color: defaultTheme.colors.primary.default }}>{formatCurrency(this.state.valorModalContraproposta)}</span></Subtitle>
      <Subtitle>Orçamento inicial: {formatCurrency(this.state.orcamentoInicialModalContraproposta)}</Subtitle>
      <Group>
        {this.state.modalNovaOferta && <MoneyInput
          label={'Valor da nova oferta'}
          placeholder={'0,00'}
          name={`amount`}
          value={this.state.valorModalContraProposta}
          onChange={(input) => this.setState({ valorModalContraProposta: input.target.value })}
          isRequired
        />}
        <Button
          onClick={this.state.aplicaDescontoAutomaticamente}
          category={'secondary'}
          fontWeight={800}
          width={'50%'}
          full>
          {this.state.modalNovaOferta ? 'Enviar nova oferta' : 'Aplicar desconto automaticamente'}
        </Button>
        {!this.state.modalNovaOferta && <Button
          onClick={this.state.ajustarManualmente}
          category={'secondary'}
          fontWeight={800}
          width={'50%'}
          ghost
          full>
          Ajustar valores manualmente
        </Button>}
        {this.state.modalNovaOferta && <Link onClick={this.state.ajustarManualmente} style={{ cursor: 'pointer' }} to="#">Prefiro precificar item a item</Link>}
      </Group>
    </Modal>
  }

  aplicaDescontoAutomaticamente = (budgetId, service, discount) => {
    this.setState({ isLoading: true }, () => {
      const { api: { base, version } } = config
      const { match: { params: { id } }, history } = this.props
      const endpoint = `${base}${version}/budget/${service}/counter-proposal/discount/${budgetId}`
      Post(endpoint, { discount })
        .then(() => {
          this.setState({ isLoading: false })
          history.push('/organizer')
          history.push(`/budget/resume/${id}`)
        }).catch(err => {
          this.setState({ isLoading: false })
          alert(err)
          console.log('Error: ', err)
        })
    })
  }

  aplicaDescontoAutomaticamenteSupplier = (budgetId, service, discount) => {
    this.setState({ isLoading: true }, () => {
      const { api: { base, version } } = config
      const { match: { params: { id } }, history } = this.props
      const endpoint = `${base}${version}/budget/${service}/supplier-counter-proposal/discount/${budgetId}`
      Post(endpoint, { discount })
        .then(() => {
          this.setState({ isLoading: false })
          history.push('/organizer')
          history.push(`/budget/resume/${id}`)
        }).catch(err => {
          alert(err)
          this.setState({ isLoading: false })
          console.log('Error: ', err)
        })
    })
  }

  isShowCard () {
    return !this.state.isLoading && this.state.availability;
  }

  orcamentoPossuiContraProposta () {
    return [
      this.state.budgets.rooms,
      this.state.budgets.lodging,
      this.state.budgets.foodsAndBeverages,
      this.state.budgets.equipments
    ].some(item => (item.mostRecent && item.mostRecent.status === 'COUNTER_PROPOSAL'))
  }

  obtemBudget (servico) {
    return this.state &&
      this.state.budgets &&
      this.state.budgets[servico] &&
      this.state.budgets[servico].mostRecent
  }

  permiteClicar (servico) {
    if (this.hasSomeBudgetEditable(this.state.budgets)) return true

    const CONTRAPROPOSTA = this.orcamentoPossuiContraProposta()
    const NAO_ENVIADO = !this.props.match.params.sent
    const budget = this.obtemBudget(servico)

    return CONTRAPROPOSTA
      ? (budget && (budget.editable || budget.status === 'COUNTER_PROPOSAL'))
      : (budget && (budget.editable || budget.status === 'REVIEW' || NAO_ENVIADO))
  }

  permiteClicarHospedagem () {
    return this.permiteClicar('lodging')
  }

  permiteClicarAlimentosBebidas () {
    return this.permiteClicar('foodsAndBeverages')
  }

  permiteClicarEquipamentos () {
    return this.permiteClicar('equipments')
  }

  permiteClicarTransfer () {
    return this.permiteClicar('transfer')
  }

  permiteClicarOutrosServicos () {
    return this.permiteClicar('otherServices')
  }

  permiteClicarSalas () {
    return this.permiteClicar('rooms')
  }

  checkSeenMessage = () => {
    const { api: { base, version, routes: { chat } } } = config
    const { match: { params: { id: eventId } } } = this.props
    const accountId = window.localStorage.getItem('account')
    const endpoint = `${base}${version}${chat}/event/${eventId}/supplier/${accountId}/check-seen-messages`
    this.handleOnUnseeMessage(0)

    Post(endpoint)
      .then(() => { })
      .catch(err => {
        console.error(err.message)
      })
  }

  handleOnUnseeMessage = (count) => {
    this.setState({ showMessageIcon: (count > 0), unseeMessages: count })
  }

  hasSomeBudgetEditable = (budgets) => {
    return budgets && Object.values(budgets)
      .map(budget => budget.mostRecent)
      .some(mostRecent => mostRecent && mostRecent.editable)
  }

  mostraDescricao = (name) => {
    switch (name) {
      case 'gifts':
        return 'Detalhes dos brindes'
      case 'printShop':
        return 'Detalhes da gráfica'
      case 'additionalServices':
        return 'Observações'
      default:
        return '';
    }
  }

  ResumoOutrosServicos = (name, briefing) => {
    return <Card style={{ padding: '10px', width: '100%', marginTop: '30px' }}>
      <Group style={{ padding: '10px' }}>
        <Title style={{ fontSize: '15px', marginBottom: '0px', marginTop: '0px' }}>{getNameFromKey(name)}</Title>
        {briefing.requests.map(request => {
          const budgetDoBriefingSelecionado = this.state.budgets &&
            this.state.budgets.otherServices &&
            this.state.budgets.otherServices.mostRecent &&
            this.state.budgets.otherServices.mostRecent.services &&
            this.state.budgets.otherServices.mostRecent.services[name] &&
            this.state.budgets.otherServices.mostRecent.services[name].requests.find(budgetRequest => budgetRequest.briefingRequestId === request.id)
          return <>
            <Descricao style={{ fontWeight: 600, marginTop: '20px' }}>
              {request.name} |
              Data de entrega: {formatDate(request.deliveryDate)} |
              {request.budgetPerUnit ? `Budget (por unidade): ${formatCurrency(request.budgetPerUnit.amount)} | ` : ''}
              Quantidade: {request.quantity} unidades
              {request.hasContentCreation ? ' | Necessita criação de conteúdo' : ''}
            </Descricao>
            <Descricao style={{ marginTop: '10px' }}>
              <span style={{ fontWeight: 600 }}>Descrição do pedido:</span> {request.description}
            </Descricao>
            {budgetDoBriefingSelecionado && budgetDoBriefingSelecionado.hasAvailability &&  budgetDoBriefingSelecionado && budgetDoBriefingSelecionado.budgets.map((budget) => {
                return <Card style={{ padding: '10px', width: '100%', marginTop: '10px' }}>
                <Group style={{ padding: '10px' }}>
                  <Group style={{ display: 'flex' }}>
                    <Title style={{ fontSize: '15px', marginBottom: '0px', marginTop: '0px' }}>{budget.name}</Title>
                    {budget.files.map((file, fileIndex) => <a onClick={(event) => {event.stopPropagation && event.stopPropagation()}} href={file} target='_blank' rel='noopener noreferrer' style={{ color: defaultTheme.colors.primary.default, fontSize: '15px', paddingLeft: '10px', fontFamily: defaultTheme.fonts.primary, textDecoration: 'underline' }}>
                      <Icons
                        icon={'paperclip'}
                        width={30}
                        height={15}
                        color={defaultTheme.colors.primary.default}
                      />
                      Anexo {fileIndex < 9 && '0'}{(fileIndex + 1)}
                    </a>)}
                  </Group>
                  <label style={{ position: 'absolute', right: '5px', top: '10px' }}>
                    <Money cortesia={budget.courtesy}>{formatCurrency((budget.unitPrice.amount * budget.quantity), false)}</Money>
                  </label>
                  <Descricao style={{ fontSize: '12px', listStyle: 'none', position: 'absolute', right: '2px', top: '30px', textAlign: 'right' }}>{formatCurrency(budget.unitPrice.amount)} / unidade</Descricao>
                  <Descricao style={{ marginTop: '20px' }}>
                    <span style={{ fontWeight: 600 }}>{this.mostraDescricao(name)}:</span> {budget.description}
                  </Descricao>
                </Group>
              </Card>})
            }
            {budgetDoBriefingSelecionado && !budgetDoBriefingSelecionado.hasAvailability && <Card>
              <Descricao style={{ width: '70%', margin: 'auto', textAlign: 'center' }}>Não ofereço este serviço.</Descricao>
            </Card>}
          </>})}
      </Group>
    </Card>
  }

  ResumoOutrosServicosAdditionalServices = (briefing) => {
    return briefing.requests.map(request => {
      const budgetDoBriefingSelecionado = this.state.budgets &&
        this.state.budgets.otherServices &&
        this.state.budgets.otherServices.mostRecent &&
        this.state.budgets.otherServices.mostRecent.services &&
        this.state.budgets.otherServices.mostRecent.services.additionalServices &&
        this.state.budgets.otherServices.mostRecent.services.additionalServices.requests.find(budgetRequest => budgetRequest.briefingRequestId === request.id)
      return <Card style={{ padding: '10px', width: '100%', marginTop: '30px' }}>
        <Group style={{ padding: '10px' }}>
          <Title style={{ fontSize: '15px', marginBottom: '0px', marginTop: '0px' }}>{request.name}</Title>
          <Descricao style={{ fontWeight: 600, marginTop: '10px' }}>
            Datas: {request.dates.map(date => formatDate(date)).join(' - ')}
            {request.hoursPerDay ? ` | ${request.hoursPerDay} horas por dia ` : ''}
          </Descricao>
          <Descricao style={{ marginTop: '10px' }}>
            <span style={{ fontWeight: 600 }}>Descrição do pedido:</span> {request.description}
          </Descricao>
          {budgetDoBriefingSelecionado && budgetDoBriefingSelecionado.hasAvailability && <div style={{ display: 'flex', flexFlow: 'row wrap', alignContent: 'space-evenly', justifyContent: 'space-evenly' }}>
            {
              budgetDoBriefingSelecionado && budgetDoBriefingSelecionado.budgets.map((budget) => {
                return <Card style={{ padding: '10px', width: '100%', marginTop: '10px' }}>
                <Group style={{ padding: '10px' }}>
                  <Group style={{ display: 'flex' }}>
                    <Title style={{ fontSize: '15px', marginBottom: '0px', marginTop: '0px' }}>{budget.name}</Title>
                    {budget.files.map((file, fileIndex) => <a onClick={(event) => {
                      event.stopPropagation && event.stopPropagation()}} href={file} target='_blank' rel='noopener noreferrer' style={{ color: defaultTheme.colors.primary.default, fontSize: '15px', paddingLeft: '10px', fontFamily: defaultTheme.fonts.primary, textDecoration: 'underline' }}>
                      <Icons
                        icon={'paperclip'}
                        width={30}
                        height={15}
                        color={defaultTheme.colors.primary.default}
                      />
                      Anexo {fileIndex < 9 && '0'}{(fileIndex + 1)}
                    </a>)}
                  </Group>
                  <label style={{ position: 'absolute', right: '5px', top: '10px' }}>
                    <Money cortesia={budget.courtesy}>{formatCurrency((budget.pricePerDay.amount * (budget.dates ? budget.dates.length : 1)), false)}</Money>
                  </label>
                  <Descricao style={{ fontSize: '12px', listStyle: 'none', position: 'absolute', right: '2px', top: '30px', textAlign: 'right' }}>{formatCurrency((budget.pricePerDay.amount))} / por dia</Descricao>
                  <Descricao style={{ marginTop: '20px' }}>
                    <span style={{ fontWeight: 600 }}>{this.mostraDescricao('additionalServices')}:</span> {budget.description}
                  </Descricao>
                </Group>
              </Card>})
            }
          </div>}
          {budgetDoBriefingSelecionado && !budgetDoBriefingSelecionado.hasAvailability && <Card>
            <Descricao style={{ width: '70%', margin: 'auto', textAlign: 'center' }}>Não ofereço este serviço.</Descricao>
          </Card>}
        </Group>
      </Card>
    })
  }

  render () {
    const { errorMessage, isLoading, event } = this.state
    const { match: { params: { id, sent } } } = this.props
    const eventName = event.name
    const eventStatus = event.status
    const account = window.localStorage.getItem('account')
    const DISPLAY_CHAT = (sent || this.verificaSeAlgumOrcamentoEstaEmReviewOuContraProposta() || this.hasSomeBudgetEditable(this.state.budgets)) && id && eventName && eventStatus && account

    return (
      <Main>
        <Header />
        <BreadCrumb options={[
          { link: '/', name: 'Home' },
          { name: 'Resumo' }
        ]} />
        <BillingConditions
          isOpen={this.state.BillingConditionsOpen}
          onClose={() => this.setState({ BillingConditionsOpen: !this.state.BillingConditionsOpen })}
          onSubmit={this.submitBillingConditions}
          client={this.state.event && this.state.event.client}
        />
        <MotivoIndisponibilidade isOpen={this.state.modalIndisponibilidade} onClose={() => this.setState({ modalIndisponibilidade: false })} onSubmit={this.submitUnavailability} />
        {this.modalAceitarContraProposta()}
        <Content>
          <Group style={{ display: 'flex' }}>
            {(this.state.event.services && this.state.budgets && (!(!this.verificaSeAlgumOrcamentoEstaEmReview() && this.state.event.status === 'REVIEW') || this.verificaSeAlgumOrcamentoPossuiContraProposta()))
              && <Tag editable={this.hasSomeBudgetEditable(this.state.budgets)} contraproposta={this.verificaSeAlgumOrcamentoPossuiContraProposta()} edited={this.verificaSeAlgumOrcamentoEstaEmReview()}>{this.verificaSeAlgumOrcamentoEstaEmReview() ? 'Revisão' : (this.verificaSeAlgumOrcamentoPossuiContraProposta() ? 'Contraproposta' : this.hasSomeBudgetEditable(this.state.budgets) ? 'Habilitado para edição' : 'Novo evento')}</Tag>}
            <Group style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
              <Group>
                <Title>{this.state.event.name}</Title>
                <Subtitle style={{ marginBottom: '0px', fontWeight: 'bold', display: 'inline-block', marginRight: '5px' }}>{`Segmento: ${this.state.event.segment} | `}</Subtitle>
                {(this.state.event.internalId || this.state.event.friendlyId) && <Subtitle style={{ fontWeight: 'bold', marginBottom: '0px', display: 'inline-block' }}> ID: {this.state.event.internalId || this.state.event.friendlyId}</Subtitle>}
                {event.client && event.client.name && <Subtitle style={{ marginBottom: '0px' }}>Cliente: {event.client && event.client.name}</Subtitle>}
                {event.client && event.client.document && <Subtitle style={{ marginBottom: '0px' }}>CNPJ para pagamento: {formataCnpj(event.client && event.client.document)}</Subtitle>}
                <Subtitle edited={checkIfItemsWasEdited(this.state.differences, ['start', 'end'])} style={{ marginBottom: '0px', display: 'inline-block', marginRight: '5px' }}>
                  {`
                    ${getDateRange(new Date(this.state.event.start), new Date(this.state.event.end)).map(date => {
                    return ' ' + moment(date, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br').format('DD-MM-YYYY')
                  })}
                  `}
                </Subtitle>
                <Subtitle edited={checkIfItemWasEdited(this.state.differences, 'numberOfParticipants')} style={{ marginBottom: '0px', display: 'inline-block' }}>
                  {`${this.state.event.numberOfParticipants ? ' | ' + this.state.event.numberOfParticipants + ' pessoas' : ''}`}
                </Subtitle>
              </Group>
              {DISPLAY_CHAT && <ButtonChat
                fornecedor
                unreadCount={this.state.unseeMessages}
                showIcon={this.state.showMessageIcon}
                onClick={this.checkSeenMessage}
                style={{ marginTop: '-30px', marginRight: '-8px', position: 'relative' }}
              />}
            </Group>
            {!sent && !this.verificaSeAlgumOrcamentoPossuiContraProposta() && <Label style={{ width: 'fit-content' }}>
              Não tenho disponibilidade para este evento
              <Checkbox
                checked={!this.state.availability}
                name={'Não tenho disponibilidade para este evento'}
                id={'availability'}
                style={{ paddingLeft: '5px' }}
                onChange={this.changeAvailability}
                type='checkbox' />
            </Label>}
          </Group>
          {this.state.supplierChainOrganizer && <Group style={{ position: 'relative' }}>
            <Link
              to='#'
              onClick={() => this.setState({ mostraDados: !this.state.mostraDados })}
              style={{
                display: 'block',
                cursor: 'pointer',
                textDecoration: 'underline',
                marginTop: '10px',
                color: defaultTheme.colors.primary.default,
                fontSize: '14px'
              }}>
              dados para contato
              </Link>
            {this.state.mostraDados && <Tooltip onClick={() => this.setState({ mostraDados: false })}>
              <Subtitle style={{ marginBottom: '5px', fontSize: '14px', textAlign: 'start' }}>Cliente: <span style={{ fontWeight: 600 }}>{this.state.supplierChainOrganizer.name}</span></Subtitle>
              <Subtitle style={{ marginBottom: '5px', fontSize: '14px', textAlign: 'start' }}>Email: <span style={{ fontWeight: 600 }}>{this.state.supplierChainOrganizer.email}</span></Subtitle>
              <Subtitle style={{ marginBottom: '0px', fontSize: '14px', textAlign: 'start' }}>Telefone: <span style={{ fontWeight: 600 }}>{this.state.supplierChainOrganizer.phone}</span></Subtitle>
            </Tooltip>}
          </Group>}
          <Card>
            <Title style={{ margin: '0 0 5px 0', fontWeight: '800', fontSize: '0.9em', textTransform: 'uppercase' }} >Observações do cliente:</Title>
            <Subtitle style={{ marginBottom: '0px' }}>{this.state.event.observations || 'Sem observações'}</Subtitle>
          </Card>
          {isLoading && <Loader />}
          {errorMessage && <Alert type={'negative'}>{errorMessage}</Alert>}
          {this.isShowCard() &&
            <Card>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <Titulo>Detalhes dos pedidos</Titulo>
                  <Subtitle style={{ color: '#727272' }}>Veja abaixo o detalhamento dos pedidos solicitados.</Subtitle>
                </div>
              </div>
              {/* <Card style={{ display: 'flex' }}>
              <Icons
                color={'gray'}
                width={40}
                height={20}
                style={{ margin: 'auto 1px' }} icon='info-circle' />
              <Subtitle style={{ marginBottom: 'auto', marginTop: 'auto' }}>Prezado parceiro, lembramos que conforme acordo com agência, a tarifa inicial deve ser comissionada.</Subtitle>
            </Card> */}
              {!this.verificaSeApresentaServico('TRANSFER') && !this.verificaSeApresentaServico('OTHER_SERVICES') && !this.checkIfObjectIsEmpty(this.pegaUltimaPropostaEnviadaPorServico('lodging')) && this.state.budgets.lodging.mostRecent && this.state.budgets.lodging.mostRecent.status === 'COUNTER_PROPOSAL' && <Card style={{ marginTop: '0px' }}>
                <Titulo style={{ fontSize: '15px' }}>Hospedagem</Titulo>
                <Tag style={{ left: '130px', width: 'fit-content' }} contraproposta={true}>Contraproposta</Tag>
                <Group style={{ display: 'flex' }}>
                  <Group style={{ display: 'flex' }}>
                    <Group style={{ textAlign: 'right' }}>
                      <Title style={{ fontSize: '15px', marginBottom: '10px' }}>{this.verificaSeFoiUmaNovaOferta('lodging') ? 'Valor da sua nova oferta:' : 'Valor da contraproposta:'}</Title>
                      <Subtitle style={{ marginBottom: '10px' }}>Orçamento inicial: </Subtitle>
                      {!this.verificaSeFoiUmaNovaOferta('lodging') && <Subtitle style={{ marginBottom: '10px' }}>Diferença para a contraproposta: </Subtitle>}
                    </Group>
                    <Group style={{ textAlign: 'left', paddingLeft: '10px' }}>
                      <Group style={{ display: 'flex' }}>
                        <Title style={{ fontSize: '15px', marginBottom: '10px', color: `${this.pegaUltimaPropostaEnviadaPorServico('lodging').supplierStatus === 'ACCEPTED' ? defaultTheme.colors.primary.default : defaultTheme.colors.text.dark}` }}>
                          {formatCurrency(this.pegaUltimaPropostaEnviadaPorServico('lodging').requestedPrice)}
                        </Title>
                        {!this.verificaSeFoiUmaNovaOferta('lodging') && <Icons
                          color={defaultTheme.colors.primary.default}
                          width={40}
                          height={20}
                          style={{ margin: 'auto', marginBottom: '10px' }} icon='checkmark-outline' />}
                      </Group>
                      <Subtitle style={{ marginBottom: '10px' }}>{formatCurrency(this.pegaUltimaPropostaEnviadaPorServico('lodging').initialPrice)}</Subtitle>
                      {!this.verificaSeFoiUmaNovaOferta('lodging') && <Subtitle style={{ color: 'red', marginBottom: '10px' }}>{this.criaLabelDeDiferencaDaContraProposta(this.pegaUltimaPropostaEnviadaPorServico('lodging').initialPrice, this.pegaUltimaPropostaEnviadaPorServico('lodging').requestedPrice)}</Subtitle>}
                    </Group>
                  </Group>
                  {this.pegaUltimaPropostaEnviadaPorServico('lodging').supplierStatus === 'SENDED' && <Group style={{ marginLeft: 'auto', marginRight: '20px' }}>
                    <Button
                      type='button'
                      category={'secondary'}
                      fontWeight={500}
                      onClick={() => this.setState({
                        modalAceitarContraProposta: true,
                        modalNovaOferta: false,
                        valorModalContraproposta: this.pegaUltimaPropostaEnviadaPorServico('lodging').requestedPrice,
                        orcamentoInicialModalContraproposta: this.pegaUltimaPropostaEnviadaPorServico('lodging').initialPrice,
                        aplicaDescontoAutomaticamente: () => this.aplicaDescontoAutomaticamente(
                          this.state.budgets.lodging.mostRecent && this.state.budgets.lodging.mostRecent.id,
                          'lodging',
                          this.pegaUltimaPropostaEnviadaPorServico('lodging').requestedPrice),
                        ajustarManualmente: () => this.aceitarContraProposta('lodging')
                      })}
                      style={{ width: '100%', fontSize: '12px' }}
                      full>
                      {'Aceitar contraproposta'}
                    </Button>
                    <Button
                      type='button'
                      category={'secondary'}
                      fontWeight={500}
                      onClick={() => this.verificaSePodeFazerNovaOferta('lodging') ? this.setState({
                        modalAceitarContraProposta: true,
                        modalNovaOferta: true,
                        valorModalContraproposta: this.pegaUltimaPropostaEnviadaPorServico('lodging').requestedPrice,
                        orcamentoInicialModalContraproposta: this.pegaUltimaPropostaEnviadaPorServico('lodging').initialPrice,
                        aplicaDescontoAutomaticamente: () => this.aplicaDescontoAutomaticamenteSupplier(
                          this.state.budgets.lodging.mostRecent && this.state.budgets.lodging.mostRecent.id,
                          'lodging',
                          this.state.valorModalContraProposta),
                        ajustarManualmente: () => this.fazerNovaContraProposta('lodging')
                      }) : this.recusarContraProposta('lodging')
                      }
                      style={{ width: '100%', fontSize: '12px' }}
                      ghost
                      full>
                      {this.verificaSePodeFazerNovaOferta('lodging') ? 'Fazer nova oferta' : 'Recusar contraproposta'}
                    </Button>
                  </Group>}
                  {this.pegaUltimaPropostaEnviadaPorServico('lodging').supplierStatus === 'ACCEPTED' && <Group style={{ margin: 'auto 20px', marginLeft: 'auto' }}>
                    <Group style={{ display: 'flex' }}>
                      <Icons
                        color={defaultTheme.colors.primary.default}
                        width={40}
                        height={20}
                        style={{ margin: 'auto', marginBottom: '20px' }} icon='checkmark-outline' />
                      <Subtitle style={{ fontStyle: 'italic', color: defaultTheme.colors.grey.medium }}>{this.verificaSeFoiUmaNovaOferta('lodging') ? 'Você fez uma nova oferta' : 'Você aceitou a contraproposta'}</Subtitle>
                    </Group>
                  </Group>}
                  {this.pegaUltimaPropostaEnviadaPorServico('lodging').supplierStatus === 'REFUSED' && <Group style={{ margin: 'auto 20px', marginLeft: 'auto' }}>
                    <Subtitle style={{ fontStyle: 'italic', color: defaultTheme.colors.grey.medium }}>Você recusou a contraproposta</Subtitle>
                  </Group>}
                </Group>
                {this.pegaUltimaPropostaEnviadaPorServico('lodging').justification && <Subtitle style={{ fontStyle: 'italic', color: defaultTheme.colors.grey.medium }}>Justificativa: {this.pegaUltimaPropostaEnviadaPorServico('lodging').justification}</Subtitle>}
                <Link style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={() => this.setState({ counterProposalLodging: !this.state.counterProposalLodging })}>{this.state.counterProposalLodging ? 'ver menos' : 'ver orçamentos enviados'}</Link>
                {this.state.counterProposalLodging && <>
                  {
                    this.state.budgets.lodging.mostRecent && this.state.budgets.lodging.mostRecent.apartmentsBudget.filter(apartment => apartment.hasAvailability).map(({ item, totalWithFees, price, fee }) =>
                      <Card style={{ marginTop: '0px' }}>
                        <Group podeClicar={this.state.budgets.lodging.mostRecent && (!sent || this.state.budgets.lodging.mostRecent.status === 'REVIEW')} style={{ display: 'flex' }}>
                          <Group podeClicar={this.state.budgets.lodging.mostRecent && (!sent || this.state.budgets.lodging.mostRecent.status === 'REVIEW')}>
                            <TitleBold>{item.numberOfApartments}x {getNameFromKey(item.keyName)}</TitleBold>
                            <Group podeClicar={this.state.budgets.lodging.mostRecent && (!sent || this.state.budgets.lodging.mostRecent.status === 'REVIEW')} style={{ display: 'flex' }}>
                              <Subtitle style={{ color: '#727272' }}><span style={{ fontWeight: 'bold' }}>Check in:</span> {formatDate(item.dates[0])}</Subtitle>
                              <Subtitle style={{ color: '#727272', paddingLeft: '10px', paddingRight: '10px' }}>|</Subtitle>
                              <Subtitle style={{ color: '#727272' }}><span style={{ fontWeight: 'bold' }}>Check out:</span> {formatDate(item.dates[item.dates.length - 1])}</Subtitle>
                              <Subtitle style={{ color: '#727272', paddingLeft: '10px', paddingRight: '10px' }}>|</Subtitle>
                              <Subtitle style={{ color: '#727272' }}>{item.dates.length - 1} diárias</Subtitle>
                              <Subtitle style={{ color: '#727272', paddingLeft: '10px', paddingRight: '10px' }}>|</Subtitle>
                              {item.keyName === 'single' && <Group podeClicar={this.state.budgets.lodging.mostRecent && (!sent || this.state.budgets.lodging.mostRecent.status === 'REVIEW')} style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
                                <Icons
                                  color={'gray'}
                                  width={40}
                                  height={20}
                                  style={{ marginRight: '-5px', marginTop: '-15px' }} icon='double-bed' />
                                <Subtitle style={{ width: '70%', color: '#727272' }}>1 cama de casal</Subtitle>
                              </Group>}
                              {item.keyName === 'double' && <Group podeClicar={this.state.budgets.lodging.mostRecent && (!sent || this.state.budgets.lodging.mostRecent.status === 'REVIEW')} style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
                                <Icons
                                  color={'gray'}
                                  width={40}
                                  height={20}
                                  style={{ marginRight: '-5px', marginTop: '-15px' }} icon='double-bed' />
                                <Subtitle style={{ width: '70%', color: '#727272' }}>1 cama de casal</Subtitle>
                              </Group>}
                              {item.keyName === 'twin' && <Group podeClicar={this.state.budgets.lodging.mostRecent && (!sent || this.state.budgets.lodging.mostRecent.status === 'REVIEW')} style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
                                <Icons
                                  color={'gray'}
                                  width={40}
                                  height={20}
                                  style={{ marginRight: '-5px', marginTop: '-15px' }} icon='single-bed' />
                                <Icons
                                  color={'gray'}
                                  width={40}
                                  height={20}
                                  style={{ marginRight: '-5px', marginTop: '-15px' }} icon='single-bed' />
                                <Subtitle style={{ width: '70%', paddingLeft: '10px', color: '#727272' }}>2 camas de solteiro</Subtitle>
                              </Group>}
                              {item.keyName === 'triple' && <Group podeClicar={this.state.budgets.lodging.mostRecent && (!sent || this.state.budgets.lodging.mostRecent.status === 'REVIEW')} style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
                                <Icons
                                  color={'gray'}
                                  width={40}
                                  height={20}
                                  style={{ marginRight: '-5px', marginTop: '-15px' }} icon='single-bed' />
                                <Icons
                                  color={'gray'}
                                  width={40}
                                  height={20}
                                  style={{ marginRight: '-5px', marginTop: '-15px' }} icon='single-bed' />
                                <Icons
                                  color={'gray'}
                                  width={40}
                                  height={20}
                                  style={{ marginRight: '-5px', marginTop: '-15px' }} icon='single-bed' />
                                <Subtitle style={{ width: '55%', paddingLeft: '10px', color: '#727272' }}>3 camas de solteiro</Subtitle>
                              </Group>}
                            </Group>
                          </Group>
                          <Group podeClicar={this.state.budgets.lodging.mostRecent && (!sent || this.state.budgets.lodging.mostRecent.status === 'REVIEW')} style={{ textAlign: 'right', marginLeft: 'auto' }}>
                            <Money style={{ fontSize: '12px' }}>{formatCurrency(totalWithFees.amount, false)}</Money>
                            <Subtitle style={{ color: '#7b7b7b' }}>{formatCurrency(price.amount)} / dia + {fee.amount}% taxa</Subtitle>
                          </Group>
                        </Group>
                      </Card>)
                  }
                  {
                    this.state.budgets.lodging.mostRecent && this.state.budgets.lodging.mostRecent.apartmentsBudget.filter(apartment => apartment.hasAvailability).length === 0 && <Card style={{ display: 'flex' }}>
                      <Subtitle style={{ marginBottom: 'auto', marginTop: 'auto' }}>Nenhuma hospedagem com disponibilidade.</Subtitle>
                    </Card>
                  }
                </>}
              </Card>}
              {!this.verificaSeApresentaServico('TRANSFER') && !this.verificaSeApresentaServico('OTHER_SERVICES') && this.state.briefings.lodging && (this.state.budgets.lodging.mostRecent ? this.state.budgets.lodging.mostRecent.status !== 'COUNTER_PROPOSAL' : true) && <Group style={{ paddingTop: '20px' }} podeClicar={this.permiteClicarHospedagem()} onClick={() => this.permiteClicarHospedagem() && this.redirecionaParaOLink(this.state.budgets.lodging.all.length > 0 ? `/budget/briefing/lodging/${id}/${this.state.event.services.lodging.id}/${this.state.budgets.lodging.mostRecent.id}` : `/budget/briefing/lodging/${id}/${this.state.event.services.lodging.id}`)} >
                <Group podeClicar={this.permiteClicarHospedagem()} style={{ display: 'flex' }}>
                  <Titulo style={{ fontSize: '15px' }}>Hospedagem</Titulo>
                  {(this.state.budgets.lodging.mostRecent && this.state.budgets.lodging.mostRecent.status === 'REVIEW') && <Tag style={{ right: '125px', top: '-6px', width: 'fit-content' }} edited={true}>Revisão</Tag>}
                  {((this.state.budgets.lodging.mostRecent && (!sent && (
                    this.state.budgets.lodging.mostRecent.status === 'REVIEW'
                    || this.state.budgets.lodging.mostRecent.status === 'NON_MANDATORY_REVIEW'
                  ))) || this.hasSomeBudgetEditable(this.state.budgets)) && <Link style={{ marginLeft: 'auto' }} href={this.state.budgets.lodging.all.length > 0 ? `/budget/briefing/lodging/${id}/${this.state.event.services.lodging.id}/${this.state.budgets.lodging.mostRecent.id}` : `/budget/briefing/lodging/${id}/${this.state.event.services.lodging.id}`}>editar hospedagem</Link>}
                </Group>
                {this.state.budgets.lodging.mostRecent && <Subtitle style={{ color: '#727272', marginBottom: '5px' }}>Inclui: {Object.keys(this.state.budgets.lodging.mostRecent.includedItems).reduce((items, item) => {
                  if (this.state.budgets.lodging.mostRecent.includedItems[item] && item !== 'others') {
                    items.push(getNameFromKey(item))
                  } else if (item === 'others' && this.state.budgets.lodging.mostRecent.includedItems[item].sent) {
                    items.push(this.state.budgets.lodging.mostRecent.includedItems[item].description)
                  }
                  return items
                }, []).join(', ')}.</Subtitle>}
                {
                  this.state.budgets.lodging.mostRecent && this.state.budgets.lodging.mostRecent.apartmentsBudget.filter(apartment => apartment.hasAvailability).map(({ item, totalWithFees, price, fee }) =>
                    <Card style={{ marginTop: '0px' }}>
                      <Group podeClicar={this.permiteClicarHospedagem()} style={{ display: 'flex' }}>
                        <Group podeClicar={this.permiteClicarHospedagem()}>
                          <TitleBold>{item.numberOfApartments}x {getNameFromKey(item.keyName)}</TitleBold>
                          <Group podeClicar={this.permiteClicarHospedagem()} style={{ display: 'flex' }}>
                            <Subtitle style={{ color: '#727272' }}><span style={{ fontWeight: 'bold' }}>Check in:</span> {formatDate(item.dates[0])}</Subtitle>
                            <Subtitle style={{ color: '#727272', paddingLeft: '10px', paddingRight: '10px' }}>|</Subtitle>
                            <Subtitle style={{ color: '#727272' }}><span style={{ fontWeight: 'bold' }}>Check out:</span> {formatDate(item.dates[item.dates.length - 1])}</Subtitle>
                            <Subtitle style={{ color: '#727272', paddingLeft: '10px', paddingRight: '10px' }}>|</Subtitle>
                            <Subtitle style={{ color: '#727272' }}>{item.dates.length - 1} diárias</Subtitle>
                            <Subtitle style={{ color: '#727272', paddingLeft: '10px', paddingRight: '10px' }}>|</Subtitle>
                            {item.keyName === 'single' && <Group podeClicar={this.permiteClicarHospedagem()} style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
                              <Icons
                                color={'gray'}
                                width={40}
                                height={20}
                                style={{ marginRight: '-5px', marginTop: '-15px' }} icon='double-bed' />
                              <Subtitle style={{ width: '70%', color: '#727272' }}>1 cama de casal</Subtitle>
                            </Group>}
                            {item.keyName === 'double' && <Group podeClicar={this.permiteClicarHospedagem()} style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
                              <Icons
                                color={'gray'}
                                width={40}
                                height={20}
                                style={{ marginRight: '-5px', marginTop: '-15px' }} icon='double-bed' />
                              <Subtitle style={{ width: '70%', color: '#727272' }}>1 cama de casal</Subtitle>
                            </Group>}
                            {item.keyName === 'twin' && <Group podeClicar={this.permiteClicarHospedagem()} style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
                              <Icons
                                color={'gray'}
                                width={40}
                                height={20}
                                style={{ marginRight: '-5px', marginTop: '-15px' }} icon='single-bed' />
                              <Icons
                                color={'gray'}
                                width={40}
                                height={20}
                                style={{ marginRight: '-5px', marginTop: '-15px' }} icon='single-bed' />
                              <Subtitle style={{ width: '70%', paddingLeft: '10px', color: '#727272' }}>2 camas de solteiro</Subtitle>
                            </Group>}
                            {item.keyName === 'triple' && <Group podeClicar={this.permiteClicarHospedagem()} style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
                              <Icons
                                color={'gray'}
                                width={40}
                                height={20}
                                style={{ marginRight: '-5px', marginTop: '-15px' }} icon='single-bed' />
                              <Icons
                                color={'gray'}
                                width={40}
                                height={20}
                                style={{ marginRight: '-5px', marginTop: '-15px' }} icon='single-bed' />
                              <Icons
                                color={'gray'}
                                width={40}
                                height={20}
                                style={{ marginRight: '-5px', marginTop: '-15px' }} icon='single-bed' />
                              <Subtitle style={{ width: '55%', paddingLeft: '10px', color: '#727272' }}>3 camas de solteiro</Subtitle>
                            </Group>}
                          </Group>
                        </Group>
                        <Group podeClicar={this.permiteClicarHospedagem()} style={{ textAlign: 'right', marginLeft: 'auto' }}>
                          <Money style={{ fontSize: '12px' }}>{formatCurrency(totalWithFees.amount, false)}</Money>
                          <Subtitle style={{ color: '#7b7b7b' }}>{formatCurrency(price.amount)} / dia + {fee.amount}% taxa</Subtitle>
                        </Group>
                      </Group>
                    </Card>)
                }
                {
                  this.state.budgets.lodging.mostRecent && this.state.budgets.lodging.mostRecent.apartmentsBudget.filter(apartment => apartment.hasAvailability).length === 0 && <Card style={{ display: 'flex' }}>
                    <Subtitle style={{ marginBottom: 'auto', marginTop: 'auto' }}>Nenhuma hospedagem com disponibilidade.</Subtitle>
                  </Card>
                }
                {
                  !this.state.budgets.lodging.mostRecent && this.state.briefings.lodging &&
                  <Card style={{ display: 'flex' }}>
                    <Icons
                      color={'red'}
                      width={40}
                      height={20}
                      style={{ marginRight: '-5px', marginTop: 'auto' }} icon='exclamation-circle' />
                    <Subtitle style={{ marginBottom: 'auto', marginTop: 'auto' }}>Você ainda não cadastrou os orçamentos para este serviço. <Link style={{ marginLeft: 'auto' }} href={this.state.budgets.lodging.all.length > 0 ? `/budget/briefing/lodging/${id}/${this.state.event.services.lodging.id}/${this.state.budgets.lodging.mostRecent.id}` : `/budget/briefing/lodging/${id}/${this.state.event.services.lodging.id}`}>Clique aqui</Link> para cadastrá-lo.</Subtitle>
                  </Card>
                }
              </Group>}

              {!this.verificaSeApresentaServico('TRANSFER') && !this.verificaSeApresentaServico('OTHER_SERVICES') && !this.checkIfObjectIsEmpty(this.pegaUltimaPropostaEnviadaPorServico('room')) && this.state.budgets.rooms.mostRecent && this.state.budgets.rooms.mostRecent.status === 'COUNTER_PROPOSAL' && <Card style={{ marginTop: '0px' }}>
                <Titulo style={{ fontSize: '15px' }}>Salas</Titulo>
                <Tag style={{ left: '70px', width: 'fit-content' }} contraproposta={true}>Contraproposta</Tag>
                <Group style={{ display: 'flex' }}>
                  <Group style={{ display: 'flex' }}>
                    <Group style={{ textAlign: 'right' }}>
                      <Title style={{ fontSize: '15px', marginBottom: '10px' }}>{this.verificaSeFoiUmaNovaOferta('room') ? 'Valor da sua nova oferta:' : 'Valor da contraproposta:'}</Title>
                      <Subtitle style={{ marginBottom: '10px' }}>Orçamento inicial: </Subtitle>
                      {!this.verificaSeFoiUmaNovaOferta('room') && <Subtitle style={{ marginBottom: '10px' }}>Diferença para a contraproposta: </Subtitle>}
                    </Group>
                    <Group style={{ textAlign: 'left', paddingLeft: '10px' }}>
                      <Group style={{ display: 'flex' }}>
                        <Title style={{ fontSize: '15px', marginBottom: '10px', color: `${this.pegaUltimaPropostaEnviadaPorServico('room').supplierStatus === 'ACCEPTED' ? defaultTheme.colors.primary.default : defaultTheme.colors.text.dark}` }}>
                          {formatCurrency(this.pegaUltimaPropostaEnviadaPorServico('room').requestedPrice)}
                        </Title>
                        {!this.verificaSeFoiUmaNovaOferta('room') && <Icons
                          color={defaultTheme.colors.primary.default}
                          width={40}
                          height={20}
                          style={{ margin: 'auto', marginBottom: '10px' }} icon='checkmark-outline' />}
                      </Group>
                      <Subtitle style={{ marginBottom: '10px' }}>{formatCurrency(this.pegaUltimaPropostaEnviadaPorServico('room').initialPrice)}</Subtitle>
                      {!this.verificaSeFoiUmaNovaOferta('room') && <Subtitle style={{ color: 'red', marginBottom: '10px' }}>{this.criaLabelDeDiferencaDaContraProposta(this.pegaUltimaPropostaEnviadaPorServico('room').initialPrice, this.pegaUltimaPropostaEnviadaPorServico('room').requestedPrice)}</Subtitle>}
                    </Group>
                  </Group>
                  {this.pegaUltimaPropostaEnviadaPorServico('room').supplierStatus === 'SENDED' && <Group style={{ marginLeft: 'auto', marginRight: '20px' }}>
                    <Button
                      type='button'
                      category={'secondary'}
                      fontWeight={500}
                      onClick={() => this.setState({
                        modalAceitarContraProposta: true,
                        modalNovaOferta: false,
                        valorModalContraproposta: this.pegaUltimaPropostaEnviadaPorServico('room').requestedPrice,
                        orcamentoInicialModalContraproposta: this.pegaUltimaPropostaEnviadaPorServico('room').initialPrice,
                        aplicaDescontoAutomaticamente: () => this.aplicaDescontoAutomaticamente(
                          this.state.budgets.rooms.mostRecent && this.state.budgets.rooms.mostRecent.id,
                          'room',
                          this.pegaUltimaPropostaEnviadaPorServico('room').requestedPrice),
                        ajustarManualmente: () => this.aceitarContraProposta('room')
                      })}
                      style={{ width: '100%', fontSize: '12px' }}
                      full>
                      {'Aceitar contraproposta'}
                    </Button>
                    <Button
                      type='button'
                      category={'secondary'}
                      fontWeight={500}
                      onClick={() => this.verificaSePodeFazerNovaOferta('room') ? this.setState({
                        modalAceitarContraProposta: true,
                        modalNovaOferta: true,
                        valorModalContraproposta: this.pegaUltimaPropostaEnviadaPorServico('room').requestedPrice,
                        orcamentoInicialModalContraproposta: this.pegaUltimaPropostaEnviadaPorServico('room').initialPrice,
                        aplicaDescontoAutomaticamente: () => this.aplicaDescontoAutomaticamenteSupplier(
                          this.state.budgets.rooms.mostRecent && this.state.budgets.rooms.mostRecent.id,
                          'room',
                          this.state.valorModalContraProposta),
                        ajustarManualmente: () => this.fazerNovaContraProposta('room')
                      }) : this.recusarContraProposta('room')
                      }
                      style={{ width: '100%', fontSize: '12px' }}
                      ghost
                      full>
                      {this.verificaSePodeFazerNovaOferta('room') ? 'Fazer nova oferta' : 'Recusar contraproposta'}
                    </Button>
                  </Group>}
                  {this.pegaUltimaPropostaEnviadaPorServico('room').supplierStatus === 'ACCEPTED' && <Group style={{ margin: 'auto 20px', marginLeft: 'auto' }}>
                    <Group style={{ display: 'flex' }}>
                      <Icons
                        color={defaultTheme.colors.primary.default}
                        width={40}
                        height={20}
                        style={{ margin: 'auto', marginBottom: '20px' }} icon='checkmark-outline' />
                      <Subtitle style={{ fontStyle: 'italic', color: defaultTheme.colors.grey.medium }}>{this.verificaSeFoiUmaNovaOferta('room') ? 'Você fez uma nova oferta' : 'Você aceitou a contraproposta'}</Subtitle>
                    </Group>
                  </Group>}
                  {this.pegaUltimaPropostaEnviadaPorServico('room').supplierStatus === 'REFUSED' && <Group style={{ margin: 'auto 20px', marginLeft: 'auto' }}>
                    <Subtitle style={{ fontStyle: 'italic', color: defaultTheme.colors.grey.medium }}>Você recusou a contraproposta</Subtitle>
                  </Group>}
                </Group>
                {this.pegaUltimaPropostaEnviadaPorServico('room').justification && <Subtitle style={{ fontStyle: 'italic', color: defaultTheme.colors.grey.medium }}>Justificativa: {this.pegaUltimaPropostaEnviadaPorServico('room').justification}</Subtitle>}
                <Link style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={() => this.setState({ counterProposalRooms: !this.state.counterProposalRooms })}>{this.state.counterProposalRooms ? 'ver menos' : 'ver orçamentos enviados'}</Link>
                {this.state.counterProposalRooms && <>
                  {
                    this.state.budgets.rooms.mostRecent && this.state.budgets.rooms.mostRecent.roomsBudget.filter(room => room.hasAvailability).map(room =>
                      <Card style={{ marginTop: '0px', display: 'flex' }}>
                        <Group podeClicar={this.state.budgets.rooms.mostRecent && (!sent || this.state.budgets.rooms.mostRecent.status === 'REVIEW')}>
                          <TitleBold>{room.room.name}</TitleBold>
                          <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Observações:</span> {room.observations}</Subtitle>
                        </Group>
                        <Group podeClicar={this.state.budgets.rooms.mostRecent && (!sent || this.state.budgets.rooms.mostRecent.status === 'REVIEW')} style={{ textAlign: 'right', marginLeft: 'auto' }}>
                          <Money style={{ fontSize: '12px' }}>{formatCurrency(room.totalPriceWithoutFees.amount, false)}</Money>
                          <Subtitle style={{ color: '#7b7b7b' }}>{formatCurrency(room.pricePerDay.amount)} / dia + {formatCurrency(room.mountingPrice.amount)} montagem</Subtitle>
                        </Group>
                      </Card>)
                  }
                  {
                    this.state.budgets.rooms.mostRecent && this.state.budgets.rooms.mostRecent.roomsBudget.filter(room => room.hasAvailability).length === 0 && <Card style={{ display: 'flex' }}>
                      <Subtitle style={{ marginBottom: 'auto', marginTop: 'auto' }}>Nenhuma sala com disponibilidade.</Subtitle>
                    </Card>
                  }
                </>}
              </Card>}
              {!this.verificaSeApresentaServico('TRANSFER') && !this.verificaSeApresentaServico('OTHER_SERVICES') && this.state.briefings.rooms && (this.state.budgets.rooms.mostRecent ? this.state.budgets.rooms.mostRecent.status !== 'COUNTER_PROPOSAL' : true) && <Group style={{ paddingTop: '20px' }} podeClicar={this.permiteClicarSalas()} onClick={() => this.permiteClicarSalas() && this.redirecionaParaOLink(this.state.budgets.rooms.mostRecent ? `/budget/briefing/rooms/${id}/${this.state.budgets.rooms.mostRecent.roomsBudget[0].roomBriefingId}` : `/budget/briefing/rooms/${id}/${this.state.briefings.rooms.rooms[0].id}`)} >
                <Group podeClicar={this.permiteClicarSalas()} style={{ display: 'flex' }} >
                  <Titulo style={{ fontSize: '15px', marginBottom: '20px' }}>Salas</Titulo>
                  {(this.state.budgets.rooms.mostRecent && this.state.budgets.rooms.mostRecent.status === 'REVIEW') && <Tag style={{ right: '80px', top: '-6px', width: 'fit-content' }} edited={true}>Revisão</Tag>}
                  {((this.state.budgets.rooms.mostRecent && (!sent && (
                    this.state.budgets.rooms.mostRecent.status === 'REVIEW'
                    || this.state.budgets.rooms.mostRecent.status === 'NON_MANDATORY_REVIEW'
                  ))) || this.hasSomeBudgetEditable(this.state.budgets)) && <Link style={{ marginLeft: 'auto' }} href={this.state.budgets.rooms.mostRecent ? `/budget/briefing/rooms/${id}/${this.state.budgets.rooms.mostRecent.roomsBudget[0].roomBriefingId}` : `/budget/briefing/rooms/${id}/${this.state.briefings.rooms.rooms[0].id}`}>editar salas</Link>}
                </Group>
                {
                  this.state.budgets.rooms.mostRecent && this.state.budgets.rooms.mostRecent.roomsBudget.filter(room => room.hasAvailability).map(room =>
                    <Card style={{ marginTop: '0px', display: 'flex' }}>
                      <Group podeClicar={this.permiteClicarSalas()}>
                        <TitleBold>{room.room ? room.room.name : 'Sala removida'}</TitleBold>
                        <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Observações:</span> {room.observations}</Subtitle>
                      </Group>
                      <Group podeClicar={this.permiteClicarSalas()} style={{ textAlign: 'right', marginLeft: 'auto' }}>
                        <Money style={{ fontSize: '12px' }}>{formatCurrency(room.totalPriceWithoutFees.amount, false)}</Money>
                        <Subtitle style={{ color: '#7b7b7b' }}>{formatCurrency(room.pricePerDay.amount)} / dia + {formatCurrency(room.mountingPrice.amount)} montagem</Subtitle>
                      </Group>
                    </Card>)
                }
                {
                  this.state.budgets.rooms.mostRecent && this.state.budgets.rooms.mostRecent.roomsBudget.filter(room => room.hasAvailability).length === 0 && <Card style={{ display: 'flex' }}>
                    <Subtitle style={{ marginBottom: 'auto', marginTop: 'auto' }}>Nenhuma sala com disponibilidade.</Subtitle>
                  </Card>
                }
                {
                  !this.state.budgets.rooms.mostRecent && this.state.briefings.rooms &&
                  <Card style={{ display: 'flex' }}>
                    <Icons
                      color={'red'}
                      width={40}
                      height={20}
                      style={{ marginRight: '-5px', marginTop: 'auto' }} icon='exclamation-circle' />
                    <Subtitle style={{ marginBottom: 'auto', marginTop: 'auto' }}>Você ainda não cadastrou os orçamentos para este serviço. <Link style={{ marginLeft: 'auto' }} href={this.state.budgets.rooms.mostRecent ? `/budget/briefing/rooms/${id}/${this.state.budgets.rooms.mostRecent.roomsBudget[0].roomBriefingId}` : `/budget/briefing/rooms/${id}/${this.state.briefings.rooms.rooms[0].id}`}>Clique aqui</Link> para cadastrá-lo.</Subtitle>
                  </Card>
                }
              </Group>}

              {!this.verificaSeApresentaServico('TRANSFER') && !this.verificaSeApresentaServico('OTHER_SERVICES') && !this.checkIfObjectIsEmpty(this.pegaUltimaPropostaEnviadaPorServico('foodsAndBeverages')) && this.state.budgets.foodsAndBeverages.mostRecent && this.state.budgets.foodsAndBeverages.mostRecent.status === 'COUNTER_PROPOSAL' && <Card style={{ marginTop: '0px' }}>
                <Titulo style={{ fontSize: '15px' }}>Alimentos e Bebidas</Titulo>
                <Tag style={{ left: '190px', width: 'fit-content' }} contraproposta={true}>Contraproposta</Tag>
                <Group style={{ display: 'flex' }}>
                  <Group style={{ display: 'flex' }}>
                    <Group style={{ textAlign: 'right' }}>
                      <Title style={{ fontSize: '15px', marginBottom: '10px' }}>{this.verificaSeFoiUmaNovaOferta('foodsAndBeverages') ? 'Valor da sua nova oferta:' : 'Valor da contraproposta:'}</Title>
                      <Subtitle style={{ marginBottom: '10px' }}>Orçamento inicial: </Subtitle>
                      {!this.verificaSeFoiUmaNovaOferta('foodsAndBeverages') && <Subtitle style={{ marginBottom: '10px' }}>Diferença para a contraproposta: </Subtitle>}
                    </Group>
                    <Group style={{ textAlign: 'left', paddingLeft: '10px' }}>
                      <Group style={{ display: 'flex' }}>
                        <Title style={{ fontSize: '15px', marginBottom: '10px', color: `${this.pegaUltimaPropostaEnviadaPorServico('foodsAndBeverages').supplierStatus === 'ACCEPTED' ? defaultTheme.colors.primary.default : defaultTheme.colors.text.dark}` }}>
                          {formatCurrency(this.pegaUltimaPropostaEnviadaPorServico('foodsAndBeverages').requestedPrice)}
                        </Title>
                        {!this.verificaSeFoiUmaNovaOferta('foodsAndBeverages') && <Icons
                          color={defaultTheme.colors.primary.default}
                          width={40}
                          height={20}
                          style={{ margin: 'auto', marginBottom: '10px' }} icon='checkmark-outline' />}
                      </Group>
                      <Subtitle style={{ marginBottom: '10px' }}>{formatCurrency(this.pegaUltimaPropostaEnviadaPorServico('foodsAndBeverages').initialPrice)}</Subtitle>
                      {!this.verificaSeFoiUmaNovaOferta('foodsAndBeverages') && <Subtitle style={{ color: 'red', marginBottom: '10px' }}>{this.criaLabelDeDiferencaDaContraProposta(this.pegaUltimaPropostaEnviadaPorServico('foodsAndBeverages').initialPrice, this.pegaUltimaPropostaEnviadaPorServico('foodsAndBeverages').requestedPrice)}</Subtitle>}
                    </Group>
                  </Group>
                  {this.pegaUltimaPropostaEnviadaPorServico('foodsAndBeverages').supplierStatus === 'SENDED' && <Group style={{ marginLeft: 'auto', marginRight: '20px' }}>
                    <Button
                      type='button'
                      category={'secondary'}
                      fontWeight={500}
                      onClick={() => this.setState({
                        modalAceitarContraProposta: true,
                        modalNovaOferta: false,
                        valorModalContraproposta: this.pegaUltimaPropostaEnviadaPorServico('foodsAndBeverages').requestedPrice,
                        orcamentoInicialModalContraproposta: this.pegaUltimaPropostaEnviadaPorServico('foodsAndBeverages').initialPrice,
                        aplicaDescontoAutomaticamente: () => this.aplicaDescontoAutomaticamente(
                          this.state.budgets.foodsAndBeverages.mostRecent && this.state.budgets.foodsAndBeverages.mostRecent.id,
                          'foodsAndBeverages',
                          this.pegaUltimaPropostaEnviadaPorServico('foodsAndBeverages').requestedPrice),
                        ajustarManualmente: () => this.aceitarContraProposta('foodsAndBeverages')
                      })}
                      style={{ width: '100%', fontSize: '12px' }}
                      full>
                      {'Aceitar contraproposta'}
                    </Button>
                    <Button
                      type='button'
                      category={'secondary'}
                      fontWeight={500}
                      onClick={() => this.verificaSePodeFazerNovaOferta('foodsAndBeverages') ? this.setState({
                        modalAceitarContraProposta: true,
                        modalNovaOferta: true,
                        valorModalContraproposta: this.pegaUltimaPropostaEnviadaPorServico('foodsAndBeverages').requestedPrice,
                        orcamentoInicialModalContraproposta: this.pegaUltimaPropostaEnviadaPorServico('foodsAndBeverages').initialPrice,
                        aplicaDescontoAutomaticamente: () => this.aplicaDescontoAutomaticamenteSupplier(
                          this.state.budgets.foodsAndBeverages.mostRecent && this.state.budgets.foodsAndBeverages.mostRecent.id,
                          'foodsAndBeverages',
                          this.state.valorModalContraProposta),
                        ajustarManualmente: () => this.fazerNovaContraProposta('foodsAndBeverages')
                      }) : this.recusarContraProposta('foodsAndBeverages')
                      }
                      style={{ width: '100%', fontSize: '12px' }}
                      ghost
                      full>
                      {this.verificaSePodeFazerNovaOferta('foodsAndBeverages') ? 'Fazer nova oferta' : 'Recusar contraproposta'}
                    </Button>
                  </Group>}
                  {this.pegaUltimaPropostaEnviadaPorServico('foodsAndBeverages').supplierStatus === 'ACCEPTED' && <Group style={{ margin: 'auto 20px', marginLeft: 'auto' }}>
                    <Group style={{ display: 'flex' }}>
                      <Icons
                        color={defaultTheme.colors.primary.default}
                        width={40}
                        height={20}
                        style={{ margin: 'auto', marginBottom: '20px' }} icon='checkmark-outline' />
                      <Subtitle style={{ fontStyle: 'italic', color: defaultTheme.colors.grey.medium }}>{this.verificaSeFoiUmaNovaOferta('foodsAndBeverages') ? 'Você fez uma nova oferta' : 'Você aceitou a contraproposta'}</Subtitle>
                    </Group>
                  </Group>}
                  {this.pegaUltimaPropostaEnviadaPorServico('foodsAndBeverages').supplierStatus === 'REFUSED' && <Group style={{ margin: 'auto 20px', marginLeft: 'auto' }}>
                    <Subtitle style={{ fontStyle: 'italic', color: defaultTheme.colors.grey.medium }}>Você recusou a contraproposta</Subtitle>
                  </Group>}
                </Group>
                {this.pegaUltimaPropostaEnviadaPorServico('foodsAndBeverages').justification && <Subtitle style={{ fontStyle: 'italic', color: defaultTheme.colors.grey.medium }}>Justificativa: {this.pegaUltimaPropostaEnviadaPorServico('foodsAndBeverages').justification}</Subtitle>}
                <Link style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={() => this.setState({ counterProposalFoodsAndBeverages: !this.state.counterProposalFoodsAndBeverages })}>{this.state.counterProposalFoodsAndBeverages ? 'ver menos' : 'ver orçamentos enviados'}</Link>
                {this.state.counterProposalFoodsAndBeverages && <>
                  {
                    this.state.budgets.foodsAndBeverages.mostRecent && Object.keys(this.state.budgets.foodsAndBeverages.mostRecent.menusBudgets).filter(item =>
                      item === 'afternoonCoffeeBreak' ||
                      item === 'brunch' ||
                      item === 'cocktail' ||
                      item === 'dinner' ||
                      item === 'lunch' ||
                      item === 'morningCoffeeBreak' ||
                      item === 'thematicDinner' ||
                      item === 'welcomeCoffee' ||
                      item === 'welcomeDrink').reduce((items, item) => {
                        var menu = this.state.budgets.foodsAndBeverages.mostRecent.menusBudgets[item]
                        menu.budgets.forEach(budget => {
                          items.push(<Card style={{ marginTop: '0px', display: 'flex' }}>
                            <Group podeClicar={this.state.budgets.foodsAndBeverages.mostRecent && (!sent || this.state.budgets.foodsAndBeverages.mostRecent.status === 'REVIEW')}>
                              <TitleBold>{getNameFromKey(item)}</TitleBold>
                              <Group podeClicar={this.state.budgets.foodsAndBeverages.mostRecent && (!sent || this.state.budgets.foodsAndBeverages.mostRecent.status === 'REVIEW')}>
                                <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Nome do cardápio:</span> {budget.item.name}</Subtitle>
                              </Group>
                            </Group>
                            <Group podeClicar={this.state.budgets.foodsAndBeverages.mostRecent && (!sent || this.state.budgets.foodsAndBeverages.mostRecent.status === 'REVIEW')} style={{ textAlign: 'right', marginLeft: 'auto' }}>
                              <Money style={{ fontSize: '12px' }}>{formatCurrency(budget.totalWithoutFee.amount, false)}</Money>
                              <Subtitle style={{ color: '#7b7b7b' }}>{formatCurrency(budget.price.amount)} / por pessoa / dia</Subtitle>
                            </Group>
                          </Card>)
                        })
                        return items
                      }, [])
                  }
                  {
                    this.state.budgets.foodsAndBeverages.mostRecent && this.state.budgets.foodsAndBeverages.mostRecent.menusBudgets.roomService && Object.keys(this.state.budgets.foodsAndBeverages.mostRecent.menusBudgets.roomService.budgets).filter(roomService => this.state.budgets.foodsAndBeverages.mostRecent.menusBudgets.roomService.budgets[roomService]).map(roomService => {
                      let roomServiceItem = this.state.budgets.foodsAndBeverages.mostRecent.menusBudgets.roomService.budgets[roomService]
                      return <Card style={{ marginTop: '0px', display: 'flex' }}>
                        <Group podeClicar={this.state.budgets.foodsAndBeverages.mostRecent && (!sent || this.state.budgets.foodsAndBeverages.mostRecent.status === 'REVIEW')}>
                          <TitleBold>Serviço de sala - {getNameFromKey(roomService)}</TitleBold>
                          <Group>
                            {roomServiceItem.item.type && <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Tipo:</span> {getNameFromKey(roomServiceItem.item.type)}</Subtitle>}
                            <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Quantidade:</span> {roomServiceItem.item.quantity}</Subtitle>
                          </Group>
                        </Group>
                        <Group podeClicar={this.state.budgets.foodsAndBeverages.mostRecent && (!sent || this.state.budgets.foodsAndBeverages.mostRecent.status === 'REVIEW')} style={{ textAlign: 'right', marginLeft: 'auto' }}>
                          <Money style={{ fontSize: '12px' }}>{formatCurrency(roomServiceItem.totalWithoutFee.amount, false)}</Money>
                          <Subtitle style={{ color: '#7b7b7b' }}>{formatCurrency(roomServiceItem.price.amount)} x {roomServiceItem.numberOfItems} / dia</Subtitle>
                        </Group>
                      </Card>
                    })
                  }
                  {
                    this.state.budgets.foodsAndBeverages.mostRecent && this.state.budgets.foodsAndBeverages.mostRecent.menusBudgets.tray && Object.keys(this.state.budgets.foodsAndBeverages.mostRecent.menusBudgets.tray.budgets).filter(tray => this.state.budgets.foodsAndBeverages.mostRecent.menusBudgets.tray.budgets[tray]).map(tray => {
                      let trayItem = this.state.budgets.foodsAndBeverages.mostRecent.menusBudgets.tray.budgets[tray]
                      return <Card style={{ marginTop: '0px', display: 'flex' }}>
                        <Group podeClicar={this.state.budgets.foodsAndBeverages.mostRecent && (!sent || this.state.budgets.foodsAndBeverages.mostRecent.status === 'REVIEW')}>
                          <TitleBold>Serviço de sala - Bandeja de {getNameFromKey(tray)}</TitleBold>
                        </Group>
                        <Group podeClicar={this.state.budgets.foodsAndBeverages.mostRecent && (!sent || this.state.budgets.foodsAndBeverages.mostRecent.status === 'REVIEW')} style={{ textAlign: 'right', marginLeft: 'auto' }}>
                          <Money style={{ fontSize: '12px' }}>{formatCurrency(trayItem.totalWithoutFee.amount, false)}</Money>
                          <Subtitle style={{ color: '#7b7b7b' }}>{formatCurrency(trayItem.price.amount)} x {trayItem.numberOfItems} / dia</Subtitle>
                        </Group>
                      </Card>
                    })
                  }
                </>}
              </Card>}
              {!this.verificaSeApresentaServico('TRANSFER') && !this.verificaSeApresentaServico('OTHER_SERVICES') && this.state.briefings.foodsAndBeverages && (this.state.budgets.foodsAndBeverages.mostRecent ? this.state.budgets.foodsAndBeverages.mostRecent.status !== 'COUNTER_PROPOSAL' : true) && <Group style={{ paddingTop: '20px' }} podeClicar={this.permiteClicarAlimentosBebidas()} onClick={() => this.permiteClicarAlimentosBebidas() && this.redirecionaParaOLink(this.state.budgets.foodsAndBeverages.all.length > 0 ? `/budget/briefing/foodsandbeverage/${id}/${this.state.event.services.foodsAndBeverages.id}/${this.state.budgets.foodsAndBeverages.mostRecent.id}` : `/budget/briefing/foodsandbeverage/${id}/${this.state.event.services.foodsAndBeverages.id}`)} >
                <Group podeClicar={this.permiteClicarAlimentosBebidas()} style={{ display: 'flex' }}>
                  <Titulo style={{ fontSize: '15px' }}>Alimentos e Bebidas</Titulo>
                  {(this.state.budgets.foodsAndBeverages.mostRecent && this.state.budgets.foodsAndBeverages.mostRecent.status === 'REVIEW') && <Tag style={{ right: '75px', top: '-6px', width: 'fit-content' }} edited={true}>Revisão</Tag>}
                  {((this.state.budgets.foodsAndBeverages.mostRecent && (!sent && (
                    this.state.budgets.foodsAndBeverages.mostRecent.status === 'REVIEW'
                    || this.state.budgets.foodsAndBeverages.mostRecent.status === 'NON_MANDATORY_REVIEW'
                  ))) || this.hasSomeBudgetEditable(this.state.budgets)) && <Link style={{ marginLeft: 'auto' }} href={this.state.budgets.foodsAndBeverages.all.length > 0 ? `/budget/briefing/foodsandbeverage/${id}/${this.state.event.services.foodsAndBeverages.id}/${this.state.budgets.foodsAndBeverages.mostRecent.id}` : `/budget/briefing/foodsandbeverage/${id}/${this.state.event.services.foodsAndBeverages.id}`}>editar A&B</Link>}
                </Group>
                {this.state.budgets.foodsAndBeverages.mostRecent && <Subtitle style={{ color: '#727272', marginBottom: '5px' }}>Observações: {this.state.budgets.foodsAndBeverages.mostRecent.observations}</Subtitle>}
                {
                  this.state.budgets.foodsAndBeverages.mostRecent && Object.keys(this.state.budgets.foodsAndBeverages.mostRecent.menusBudgets).filter(item =>
                    item === 'afternoonCoffeeBreak' ||
                    item === 'brunch' ||
                    item === 'cocktail' ||
                    item === 'dinner' ||
                    item === 'lunch' ||
                    item === 'morningCoffeeBreak' ||
                    item === 'thematicDinner' ||
                    item === 'welcomeCoffee' ||
                    item === 'welcomeDrink').reduce((items, item) => {
                      var menu = this.state.budgets.foodsAndBeverages.mostRecent.menusBudgets[item]
                      menu.budgets.forEach(budget => {
                        items.push(<Card style={{ marginTop: '0px', display: 'flex' }}>
                          <Group podeClicar={this.permiteClicarAlimentosBebidas()}>
                            <TitleBold>{getNameFromKey(item)}</TitleBold>
                            <Group podeClicar={this.permiteClicarAlimentosBebidas()}>
                              <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Nome do cardápio:</span> {budget.item.name}</Subtitle>
                            </Group>
                          </Group>
                          <Group podeClicar={this.permiteClicarAlimentosBebidas()} style={{ textAlign: 'right', marginLeft: 'auto' }}>
                            <Money style={{ fontSize: '12px' }}>{formatCurrency(budget.totalWithoutFee.amount, false)}</Money>
                            <Subtitle style={{ color: '#7b7b7b' }}>{formatCurrency(budget.price.amount)} / por pessoa / dia</Subtitle>
                          </Group>
                        </Card>)
                      })
                      return items
                    }, [])
                }
                {
                  this.state.budgets.foodsAndBeverages.mostRecent && this.state.budgets.foodsAndBeverages.mostRecent.menusBudgets.roomService && Object.keys(this.state.budgets.foodsAndBeverages.mostRecent.menusBudgets.roomService.budgets).filter(roomService => this.state.budgets.foodsAndBeverages.mostRecent.menusBudgets.roomService.budgets[roomService]).map(roomService => {
                    let roomServiceItem = this.state.budgets.foodsAndBeverages.mostRecent.menusBudgets.roomService.budgets[roomService]
                    return <Card style={{ marginTop: '0px', display: 'flex' }}>
                      <Group podeClicar={this.permiteClicarAlimentosBebidas()}>
                        <TitleBold>Serviço de sala - {getNameFromKey(roomService)}</TitleBold>
                        <Group>
                          {roomServiceItem.item.type && <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Tipo:</span> {getNameFromKey(roomServiceItem.item.type)}</Subtitle>}
                          <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Quantidade:</span> {roomServiceItem.item.quantity}</Subtitle>
                        </Group>
                      </Group>
                      <Group podeClicar={this.permiteClicarAlimentosBebidas()} style={{ textAlign: 'right', marginLeft: 'auto' }}>
                        <Money style={{ fontSize: '12px' }}>{formatCurrency(roomServiceItem.totalWithoutFee.amount, false)}</Money>
                        <Subtitle style={{ color: '#7b7b7b' }}>{formatCurrency(roomServiceItem.price.amount)} x {roomServiceItem.numberOfItems} / dia</Subtitle>
                      </Group>
                    </Card>
                  })
                }
                {
                  this.state.budgets.foodsAndBeverages.mostRecent && this.state.budgets.foodsAndBeverages.mostRecent.menusBudgets.tray && Object.keys(this.state.budgets.foodsAndBeverages.mostRecent.menusBudgets.tray.budgets).filter(tray => this.state.budgets.foodsAndBeverages.mostRecent.menusBudgets.tray.budgets[tray]).map(tray => {
                    let trayItem = this.state.budgets.foodsAndBeverages.mostRecent.menusBudgets.tray.budgets[tray]
                    return <Card style={{ marginTop: '0px', display: 'flex' }}>
                      <Group podeClicar={this.permiteClicarAlimentosBebidas()}>
                        <TitleBold>Serviço de sala - Bandeja de {getNameFromKey(tray)}</TitleBold>
                      </Group>
                      <Group podeClicar={this.permiteClicarAlimentosBebidas()} style={{ textAlign: 'right', marginLeft: 'auto' }}>
                        <Money style={{ fontSize: '12px' }}>{formatCurrency(trayItem.totalWithoutFee.amount, false)}</Money>
                        <Subtitle style={{ color: '#7b7b7b' }}>{formatCurrency(trayItem.price.amount)} x {trayItem.numberOfItems} / dia</Subtitle>
                      </Group>
                    </Card>
                  })
                }
                {
                  !this.state.budgets.foodsAndBeverages.mostRecent && this.state.briefings.foodsAndBeverages &&
                  <Card style={{ display: 'flex' }}>
                    <Icons
                      color={'red'}
                      width={40}
                      height={20}
                      style={{ marginRight: '-5px', marginTop: 'auto' }} icon='exclamation-circle' />
                    <Subtitle style={{ marginBottom: 'auto', marginTop: 'auto' }}>Você ainda não cadastrou os orçamentos para este serviço. <Link style={{ marginLeft: 'auto' }} href={this.state.budgets.foodsAndBeverages.all.length > 0 ? `/budget/briefing/foodsandbeverage/${id}/${this.state.event.services.foodsAndBeverages.id}/${this.state.budgets.foodsAndBeverages.mostRecent.id}` : `/budget/briefing/foodsandbeverage/${id}/${this.state.event.services.foodsAndBeverages.id}`}>Clique aqui</Link> para cadastrá-lo.</Subtitle>
                  </Card>
                }
              </Group>}

              {!this.verificaSeApresentaServico('TRANSFER') && !this.verificaSeApresentaServico('OTHER_SERVICES') && !this.checkIfObjectIsEmpty(this.pegaUltimaPropostaEnviadaPorServico('equipments')) && this.state.budgets.equipments.mostRecent && this.state.budgets.equipments.mostRecent.status === 'COUNTER_PROPOSAL' && <Card style={{ marginTop: '0px' }}>
                <Titulo style={{ fontSize: '15px' }}>Equipamentos</Titulo>
                <Tag style={{ left: '150px', width: 'fit-content' }} contraproposta={true}>Contraproposta</Tag>
                <Group style={{ display: 'flex' }}>
                  <Group style={{ display: 'flex' }}>
                    <Group style={{ textAlign: 'right' }}>
                      <Title style={{ fontSize: '15px', marginBottom: '10px' }}>{this.verificaSeFoiUmaNovaOferta('equipments') ? 'Valor da sua nova oferta:' : 'Valor da contraproposta:'}</Title>
                      <Subtitle style={{ marginBottom: '10px' }}>Orçamento inicial: </Subtitle>
                      {!this.verificaSeFoiUmaNovaOferta('equipments') && <Subtitle style={{ marginBottom: '10px' }}>Diferença para a contraproposta: </Subtitle>}
                    </Group>
                    <Group style={{ textAlign: 'left', paddingLeft: '10px' }}>
                      <Group style={{ display: 'flex' }}>
                        <Title style={{ fontSize: '15px', marginBottom: '10px', color: `${this.pegaUltimaPropostaEnviadaPorServico('equipments').supplierStatus === 'ACCEPTED' ? defaultTheme.colors.primary.default : defaultTheme.colors.text.dark}` }}>
                          {formatCurrency(this.pegaUltimaPropostaEnviadaPorServico('equipments').requestedPrice)}
                        </Title>
                        {!this.verificaSeFoiUmaNovaOferta('equipments') && <Icons
                          color={defaultTheme.colors.primary.default}
                          width={40}
                          height={20}
                          style={{ margin: 'auto', marginBottom: '10px' }} icon='checkmark-outline' />}
                      </Group>
                      <Subtitle style={{ marginBottom: '10px' }}>{formatCurrency(this.pegaUltimaPropostaEnviadaPorServico('equipments').initialPrice)}</Subtitle>
                      {!this.verificaSeFoiUmaNovaOferta('equipments') && <Subtitle style={{ color: 'red', marginBottom: '10px' }}>{this.criaLabelDeDiferencaDaContraProposta(this.pegaUltimaPropostaEnviadaPorServico('equipments').initialPrice, this.pegaUltimaPropostaEnviadaPorServico('equipments').requestedPrice)}</Subtitle>}
                    </Group>
                  </Group>
                  {this.pegaUltimaPropostaEnviadaPorServico('equipments').supplierStatus === 'SENDED' && <Group style={{ marginLeft: 'auto', marginRight: '20px' }}>
                    <Button
                      type='button'
                      category={'secondary'}
                      fontWeight={500}
                      onClick={() => this.setState({
                        modalAceitarContraProposta: true,
                        modalNovaOferta: false,
                        valorModalContraproposta: this.pegaUltimaPropostaEnviadaPorServico('equipments').requestedPrice,
                        orcamentoInicialModalContraproposta: this.pegaUltimaPropostaEnviadaPorServico('equipments').initialPrice,
                        aplicaDescontoAutomaticamente: () => this.aplicaDescontoAutomaticamente(
                          this.state.budgets.equipments.mostRecent && this.state.budgets.equipments.mostRecent.id,
                          'equipments',
                          this.pegaUltimaPropostaEnviadaPorServico('equipments').requestedPrice),
                        ajustarManualmente: () => this.aceitarContraProposta('equipments')
                      })}
                      style={{ width: '100%', fontSize: '12px' }}
                      full>
                      {'Aceitar contraproposta'}
                    </Button>
                    <Button
                      type='button'
                      category={'secondary'}
                      fontWeight={500}
                      onClick={() => this.verificaSePodeFazerNovaOferta('equipments') ? this.setState({
                        modalAceitarContraProposta: true,
                        modalNovaOferta: true,
                        valorModalContraproposta: this.pegaUltimaPropostaEnviadaPorServico('equipments').requestedPrice,
                        orcamentoInicialModalContraproposta: this.pegaUltimaPropostaEnviadaPorServico('equipments').initialPrice,
                        aplicaDescontoAutomaticamente: () => this.aplicaDescontoAutomaticamenteSupplier(
                          this.state.budgets.equipments.mostRecent && this.state.budgets.equipments.mostRecent.id,
                          'equipments',
                          this.state.valorModalContraProposta),
                        ajustarManualmente: () => this.fazerNovaContraProposta('equipments')
                      }) : this.recusarContraProposta('equipments')
                      }
                      style={{ width: '100%', fontSize: '12px' }}
                      ghost
                      full>
                      {this.verificaSePodeFazerNovaOferta('equipments') ? 'Fazer nova oferta' : 'Recusar contraproposta'}
                    </Button>
                  </Group>}
                  {this.pegaUltimaPropostaEnviadaPorServico('equipments').supplierStatus === 'ACCEPTED' && <Group style={{ margin: 'auto 20px', marginLeft: 'auto' }}>
                    <Group style={{ display: 'flex' }}>
                      <Icons
                        color={defaultTheme.colors.primary.default}
                        width={40}
                        height={20}
                        style={{ margin: 'auto', marginBottom: '20px' }} icon='checkmark-outline' />
                      <Subtitle style={{ fontStyle: 'italic', color: defaultTheme.colors.grey.medium }}>{this.verificaSeFoiUmaNovaOferta('equipments') ? 'Você fez uma nova oferta' : 'Você aceitou a contraproposta'}</Subtitle>
                    </Group>
                  </Group>}
                  {this.pegaUltimaPropostaEnviadaPorServico('equipments').supplierStatus === 'REFUSED' && <Group style={{ margin: 'auto 20px', marginLeft: 'auto' }}>
                    <Subtitle style={{ fontStyle: 'italic', color: defaultTheme.colors.grey.medium }}>Você recusou a contraproposta</Subtitle>
                  </Group>}
                </Group>
                {this.pegaUltimaPropostaEnviadaPorServico('equipments').justification && <Subtitle style={{ fontStyle: 'italic', color: defaultTheme.colors.grey.medium }}>Justificativa: {this.pegaUltimaPropostaEnviadaPorServico('equipments').justification}</Subtitle>}
                <Link style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={() => this.setState({ counterProposalEquipments: !this.state.counterProposalEquipments })}>{this.state.counterProposalEquipments ? 'ver menos' : 'ver orçamentos enviados'}</Link>
                {this.state.counterProposalEquipments && <>
                  {
                    this.state.budgets.equipments.mostRecent && !Object.keys(this.state.budgets.equipments.mostRecent.equipments).some(item => this.state.budgets.equipments.mostRecent.equipments[item].hasAvailability) && <Card style={{ display: 'flex' }}>
                      <Subtitle style={{ marginBottom: 'auto', marginTop: 'auto' }}>Nenhum equipamento com disponibilidade.</Subtitle>
                    </Card>
                  }
                  {
                    this.state.budgets.equipments.mostRecent && Object.keys(this.state.budgets.equipments.mostRecent.equipments).filter(item => this.state.budgets.equipments.mostRecent.equipments[item].hasAvailability).map(item =>
                      <Card style={{ marginTop: '0px', display: 'flex' }}>
                        <Group>
                          <TitleBold>{item !== 'internet' ? getNameFromKey(item) : 'Internet'}</TitleBold>
                          <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Observações:</span> {this.state.budgets.equipments.mostRecent.equipments[item].observation}</Subtitle>
                          {(this.state.briefings.equipments.microphone.requests.some(mic => mic.numberOfLapel > 0) && item === 'microphone') && <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Lapela:</span> {formatCurrency(this.state.budgets.equipments.mostRecent.equipments[item].lapelPrice.amount)} / unidade / dia</Subtitle>}
                          {(this.state.briefings.equipments.microphone.requests.some(mic => mic.numberOfGooseneck > 0) && item === 'microphone') && <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Gooseneck:</span> {formatCurrency(this.state.budgets.equipments.mostRecent.equipments[item].gooseneckPrice.amount)} / unidade / dia</Subtitle>}
                          {(this.state.briefings.equipments.microphone.requests.some(mic => mic.numberOfHeadSet > 0) && item === 'microphone') && <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Head Set:</span> {formatCurrency(this.state.budgets.equipments.mostRecent.equipments[item].headSetPrice.amount)} / unidade / dia</Subtitle>}
                          {(this.state.briefings.equipments.microphone.requests.some(mic => mic.numberOfStick > 0) && item === 'microphone') && <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Bastão:</span> {formatCurrency(this.state.budgets.equipments.mostRecent.equipments[item].stickPrice.amount)} / unidade / dia</Subtitle>}
                        </Group>
                        <Group style={{ textAlign: 'right', marginLeft: 'auto' }}>
                          <Money style={{ fontSize: '12px' }}>{formatCurrency(this.state.budgets.equipments.mostRecent.equipments[item].total.amount, false)}</Money>
                          {item !== 'microphone' && <Subtitle style={{ color: '#7b7b7b' }}>{formatCurrency(this.state.budgets.equipments.mostRecent.equipments[item].price.amount)} / {(item !== 'internet' ? 'unidade' : 'ponto de acesso')} / dia</Subtitle>}
                        </Group>
                      </Card>)
                  }
                </>}
              </Card>}
              {!this.verificaSeApresentaServico('TRANSFER') && !this.verificaSeApresentaServico('OTHER_SERVICES') && this.state.briefings.equipments && (this.state.budgets.equipments.mostRecent ? this.state.budgets.equipments.mostRecent.status !== 'COUNTER_PROPOSAL' : true) && <Group style={{ paddingTop: '20px' }} podeClicar={this.permiteClicarEquipamentos()} onClick={() => this.permiteClicarEquipamentos() && this.redirecionaParaOLink(this.state.budgets.equipments.mostRecent ? `/budget/briefing/equipments/${id}/${this.state.briefings.equipments.id}/${this.state.budgets.equipments.mostRecent.id}` : `/budget/briefing/equipments/${id}/${this.state.briefings.equipments.id}`)} >
                <Group podeClicar={this.permiteClicarEquipamentos()} style={{ display: 'flex', marginBottom: '20px' }}>
                  <Titulo style={{ fontSize: '15px' }}>Equipamentos</Titulo>
                  {(this.state.budgets.equipments.mostRecent && this.state.budgets.equipments.mostRecent.status === 'REVIEW') && <Tag style={{ right: '135px', top: '-6px', width: 'fit-content' }} edited={true}>Revisão</Tag>}
                  {((this.state.budgets.equipments.mostRecent && (!sent && (
                    this.state.budgets.equipments.mostRecent.status === 'REVIEW'
                    || this.state.budgets.equipments.mostRecent.status === 'NON_MANDATORY_REVIEW'
                  ))) || this.hasSomeBudgetEditable(this.state.budgets)) && <Link style={{ marginLeft: 'auto' }} href={this.state.budgets.equipments.mostRecent ? `/budget/briefing/equipments/${id}/${this.state.briefings.equipments.id}/${this.state.budgets.equipments.mostRecent.id}` : `/budget/briefing/equipments/${id}/${this.state.briefings.equipments.id}`}>editar equipamentos</Link>}
                </Group>
                {
                  this.state.budgets.equipments.mostRecent && !Object.keys(this.state.budgets.equipments.mostRecent.equipments).some(item => this.state.budgets.equipments.mostRecent.equipments[item].hasAvailability) && <Card style={{ display: 'flex' }}>
                    <Subtitle style={{ marginBottom: 'auto', marginTop: 'auto' }}>Nenhum equipamento com disponibilidade.</Subtitle>
                  </Card>
                }
                {
                  this.state.budgets.equipments.mostRecent && Object.keys(this.state.budgets.equipments.mostRecent.equipments).filter(item => this.state.budgets.equipments.mostRecent.equipments[item].hasAvailability).map(item =>
                    <Card style={{ marginTop: '0px', display: 'flex' }}>
                      <Group>
                        <TitleBold>{item !== 'internet' ? getNameFromKey(item) : 'Internet'}</TitleBold>
                        <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Observações:</span> {this.state.budgets.equipments.mostRecent.equipments[item].observation}</Subtitle>
                        {(this.state.briefings.equipments.microphone.requests.some(mic => mic.numberOfLapel > 0) && item === 'microphone') && <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Lapela:</span> {formatCurrency(this.state.budgets.equipments.mostRecent.equipments[item].lapelPrice.amount)} / unidade / dia</Subtitle>}
                        {(this.state.briefings.equipments.microphone.requests.some(mic => mic.numberOfGooseneck > 0) && item === 'microphone') && <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Gooseneck:</span> {formatCurrency(this.state.budgets.equipments.mostRecent.equipments[item].gooseneckPrice.amount)} / unidade / dia</Subtitle>}
                        {(this.state.briefings.equipments.microphone.requests.some(mic => mic.numberOfHeadSet > 0) && item === 'microphone') && <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Head Set:</span> {formatCurrency(this.state.budgets.equipments.mostRecent.equipments[item].headSetPrice.amount)} / unidade / dia</Subtitle>}
                        {(this.state.briefings.equipments.microphone.requests.some(mic => mic.numberOfStick > 0) && item === 'microphone') && <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Bastão:</span> {formatCurrency(this.state.budgets.equipments.mostRecent.equipments[item].stickPrice.amount)} / unidade / dia</Subtitle>}
                      </Group>
                      <Group style={{ textAlign: 'right', marginLeft: 'auto' }}>
                        <Money style={{ fontSize: '12px' }}>{formatCurrency(this.state.budgets.equipments.mostRecent.equipments[item].total.amount, false)}</Money>
                        {item !== 'microphone' && <Subtitle style={{ color: '#7b7b7b' }}>{formatCurrency(this.state.budgets.equipments.mostRecent.equipments[item].price.amount)} / {(item !== 'internet' ? 'unidade' : 'ponto de acesso')} / dia</Subtitle>}
                      </Group>
                    </Card>)
                }
                {
                  !this.state.budgets.equipments.mostRecent && this.state.briefings.equipments &&
                  <Card style={{ display: 'flex' }}>
                    <Icons
                      color={'red'}
                      width={40}
                      height={20}
                      style={{ marginRight: '-5px', marginTop: 'auto' }} icon='exclamation-circle' />
                    <Subtitle style={{ marginBottom: 'auto', marginTop: 'auto' }}>Você ainda não cadastrou os orçamentos para este serviço. <Link style={{ marginLeft: 'auto' }} href={this.state.budgets.equipments.mostRecent ? `/budget/briefing/equipments/${id}/${this.state.budgets.equipments.mostRecent.id}` : `/budget/briefing/equipments/${id}/${this.state.briefings.equipments.id}`}>Clique aqui</Link> para cadastrá-lo.</Subtitle>
                  </Card>
                }
              </Group>}

              {forneceServico(OTHER_SERVICES) && !this.verificaSeApresentaServico('TRANSFER') && this.state.briefings.otherServices && (this.state.budgets.otherServices.mostRecent ? this.state.budgets.otherServices.mostRecent.status !== 'COUNTER_PROPOSAL' : true) && <Group style={{ paddingTop: '20px' }} podeClicar={this.permiteClicarOutrosServicos()} onClick={() => this.permiteClicarOutrosServicos() && this.redirecionaParaOLink(this.state.budgets.otherServices.mostRecent ? `/budget/briefing/other-services/${id}/${this.state.briefings.otherServices.id}/${this.state.budgets.otherServices.mostRecent.id}` : `/budget/briefing/other-services/${id}/${this.state.briefings.otherServices.id}`)} >
                <Group podeClicar={this.permiteClicarOutrosServicos()} style={{ display: 'flex', marginBottom: '20px' }}>
                  <Titulo style={{ fontSize: '15px' }}>Outros serviços</Titulo>
                  {(this.state.budgets.otherServices.mostRecent && this.state.budgets.otherServices.mostRecent.status === 'REVIEW') && <Tag style={{ right: '135px', top: '-6px', width: 'fit-content' }} edited={true}>Revisão</Tag>}
                  {((this.state.budgets.otherServices.mostRecent && (!sent && (
                    this.state.budgets.otherServices.mostRecent.status === 'REVIEW'
                    || this.state.budgets.otherServices.mostRecent.status === 'NON_MANDATORY_REVIEW'
                  ))) || this.hasSomeBudgetEditable(this.state.budgets)) && <Link style={{ marginLeft: 'auto' }} href={this.state.budgets.otherServices.mostRecent ? `/budget/briefing/other-services/${id}/${this.state.briefings.otherServices.id}/${this.state.budgets.otherServices.mostRecent.id}` : `/budget/briefing/other-services/${id}/${this.state.briefings.otherServices.id}`}>editar outros serviços</Link>}
                </Group>
                {this.state.budgets.otherServices.mostRecent &&
                  !Object.keys(this.state.budgets.otherServices.mostRecent.services).some(item => {
                    return this.state.budgets.otherServices.mostRecent.services[item].requests.some(request => request.hasAvailability)
                  }) &&
                  <Card style={{ display: 'flex' }}>
                    <Subtitle style={{ marginBottom: 'auto', marginTop: 'auto' }}>Nenhum outro serviço com disponibilidade.</Subtitle>
                  </Card>}
                  {this.state.budgets.otherServices && this.state.budgets.otherServices.mostRecent && this.state.briefings.otherServices &&
                  this.state.briefings.otherServices.services &&
                    Object.keys(this.state.briefings.otherServices.services).filter(item =>
                      this.state.briefings.otherServices.services[item].requested).map(item =>item === 'additionalServices' ? this.ResumoOutrosServicosAdditionalServices(this.state.briefings.otherServices.services[item]) : this.ResumoOutrosServicos(item, this.state.briefings.otherServices.services[item]))}
                  {!this.state.budgets.otherServices.mostRecent &&
                    this.state.briefings.otherServices &&
                    <Card style={{ display: 'flex' }}>
                      <Icons
                        color={'red'}
                        width={40}
                        height={20}
                        style={{ marginRight: '-5px', marginTop: 'auto' }} icon='exclamation-circle' />
                      <Subtitle style={{ marginBottom: 'auto', marginTop: 'auto' }}>Você ainda não cadastrou os orçamentos para este serviço. <Link style={{ marginLeft: 'auto' }} href={this.state.budgets.otherServices.mostRecent ? `/budget/briefing/other-services/${id}/${this.state.budgets.otherServices.mostRecent.id}` : `/budget/briefing/other-services/${id}/${this.state.briefings.otherServices.id}`}>Clique aqui</Link> para cadastrá-lo.</Subtitle>
                    </Card>}
                </Group>}

              {forneceServico(TRANSFER) && this.verificaSeApresentaServico('TRANSFER') && this.state.briefings.transfer && (this.state.budgets.transfer.mostRecent ? this.state.budgets.transfer.mostRecent.status !== 'COUNTER_PROPOSAL' : true) && <Group style={{ paddingTop: '20px' }} podeClicar={this.permiteClicarTransfer()} onClick={() => this.permiteClicarTransfer() && this.redirecionaParaOLink(this.state.budgets.transfer.mostRecent ? `/budget/briefing/transfer/${id}/${this.state.briefings.transfer.id}/${this.state.budgets.transfer.mostRecent.id}` : `/budget/briefing/transfer/${id}/${this.state.briefings.transfer.id}`)} >
                <Group podeClicar={this.permiteClicarTransfer()} style={{ display: 'flex', marginBottom: '20px' }}>
                  <Titulo style={{ fontSize: '15px' }}>Transfer</Titulo>
                  {(this.state.budgets.transfer.mostRecent && this.state.budgets.transfer.mostRecent.status === 'REVIEW') && <Tag style={{ right: '135px', top: '-6px', width: 'fit-content' }} edited={true}>Revisão</Tag>}
                  {((this.state.budgets.transfer.mostRecent && (!sent && (
                    this.state.budgets.transfer.mostRecent.status === 'REVIEW'
                    || this.state.budgets.transfer.mostRecent.status === 'NON_MANDATORY_REVIEW'
                  ))) || this.hasSomeBudgetEditable(this.state.budgets)) && <Link style={{ marginLeft: 'auto' }} href={this.state.budgets.transfer.mostRecent ? `/budget/briefing/transfer/${id}/${this.state.briefings.transfer.id}/${this.state.budgets.transfer.mostRecent.id}` : `/budget/briefing/transfer/${id}/${this.state.briefings.transfer.id}`}>editar transfer</Link>}
                </Group>
                {
                  this.state.budgets.transfer.mostRecent && !this.state.budgets.transfer.mostRecent.requests.some(item => item.hasAvailability) && <Card style={{ display: 'flex' }}>
                    <Subtitle style={{ marginBottom: 'auto', marginTop: 'auto' }}>Nenhum transfer com disponibilidade.</Subtitle>
                  </Card>
                }
                {
                  this.state.budgets.transfer.mostRecent && this.state.budgets.transfer.mostRecent.requests.map((item, index) => {
                    function returnContent (item, index) {
                      if (!item.hasAvailability) {
                        return <Card style={{ width: '100%', paddingBottom: '10px', color: 'red' }}>
                          <Subtitle style={{ marginBottom: 'auto', marginTop: 'auto' }}>Você alegou não ter disponibilidade para esta solicitação.</Subtitle>
                        </Card>
                      }
                      return <>
                        <Subtitle style={{ color: '#727272', marginBottom: '0px', marginTop: '20px' }}><span style={{ fontWeight: 'bold' }}>Veículos:</span></Subtitle>
                        {item.vehicles.map(veiculo => <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>{transferText(veiculo.type)}:</span> {formatCurrency(veiculo.price.amount)} / dia</Subtitle>)}
                        {item.coordinatorPrices && <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Coordenador:</span> {formatCurrency(item.coordinatorPrices.pricePerDay.amount)} / dia</Subtitle>}
                        {briefing && briefing.hasOnboardServices && <Subtitle style={{ color: '#727272', marginBottom: '0px', marginTop: '20px' }}><span style={{ fontWeight: 'bold' }}>Serviços de bordo:</span></Subtitle>}
                        {briefing && briefing.hasOnboardServices && item.onboardServices.services.map(servico => <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>{transferText(servico.name)}:</span> {formatCurrency(servico.price.amount)} / unidade</Subtitle>)}
                        {briefing && briefing.hasOnboardServices && <Subtitle style={{ color: '#727272', marginBottom: '0px', marginTop: '20px' }}><span style={{ fontWeight: 'bold' }}>LunchBoxes:</span></Subtitle>}
                        {briefing && briefing.hasOnboardServices && item.onboardServices.lunchBoxes.map(lunchBox => <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>{lunchBox.name}:</span> {formatCurrency(lunchBox.price.amount)} / unidade</Subtitle>)}
                      </>
                    }
                    const briefing = this.state.briefings.transfer.requests.find(briefing => briefing.id === item.briefingRequestId)
                    return <Card style={{ marginTop: '0px', display: 'flex' }}>
                      <Group>
                        <TitleBold>{"Solicitação " + ("0" + (index + 1)).slice(-2)}</TitleBold>
                        <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Observações:</span> {item.observation}</Subtitle>
                        {returnContent(item, index)}
                      </Group>
                    </Card>
                  })
                }
                {
                  !this.state.budgets.transfer.mostRecent && this.state.briefings.transfer &&
                  <Card style={{ display: 'flex' }}>
                    <Icons
                      color={'red'}
                      width={40}
                      height={20}
                      style={{ marginRight: '-5px', marginTop: 'auto' }} icon='exclamation-circle' />
                    <Subtitle style={{ marginBottom: 'auto', marginTop: 'auto' }}>Você ainda não cadastrou os orçamentos para este serviço. <Link style={{ marginLeft: 'auto' }} href={this.state.budgets.transfer.mostRecent ? `/budget/briefing/transfer/${id}/${this.state.budgets.transfer.mostRecent.id}` : `/budget/briefing/transfer/${id}/${this.state.briefings.transfer.id}`}>Clique aqui</Link> para cadastrá-lo.</Subtitle>
                  </Card>
                }
              </Group>}
            </Card>}

          {DISPLAY_CHAT && <Card>
            <ChatRoom
              user={'fornecedor'}
              eventId={id}
              supplierId={account}
              eventName={eventName}
              eventStatus={eventStatus}
              onUnseeMessage={this.handleOnUnseeMessage}
            />

          </Card>}
        </Content>
        {!sent && <Button
          type='button'
          category={'secondary'}
          fontWeight={800}
          onClick={this.submit}
          style={{ width: '70%' }}
          full>
          {this.state.availability ? 'Enviar orçamento' : 'confirmar indisponibilidade'}
        </Button>}
      </Main>
    )
  }
}

export default Resume
