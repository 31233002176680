/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
/* UTILS */
import config from '../../../../middleware/config'
import { Link } from 'react-router-dom'
import { formatCurrency } from '../../../../utils/money'
import { temFeatureFlag } from '../../../../utils/tiposUsuario'
import { formatDate } from '../../../../utils/dates'
import { Get, Post } from '../../../../utils/request'
import * as moment from 'moment'
/* STYLES */
import { defaultTheme } from '../../../styles/themes'
/* COMPONENTS */
import ButtonIconExtra from '../../../components/ui/ButtonIconExtra'
import SingleChat from '../../../components/Chat/SingleChat'
import Header from '../../Header'
import Loader from '../../../components/Loader'
import Main from '../../../components/Main'
import Body from '../../../components/Body'
import Modal from '../../../components/Modal'
import ModalConfirmacao from '../../../components/Aprovacao/ModalConfirmacao'
import ModalFornecedorAprovado from '../../../components/Aprovacao/ModalFornecedorAprovado'
import ItemsMenuLateral from '../../../components/SidebarItems/ItemsMenuLateral'
import Options from '../../../components/ui/Options'
import MoedaIcon from '../../../components/ui/MoedaIcon'
import AlertIcon from '../../../components/ui/AlertIcon'
import Card from '../../../components/ui/Card'
import Input from '../../../components/ui/Input'
import CheckBox from '../../../components/ui/CheckBox'
import Textarea from '../../../components/ui/Textarea'
import Icons from '../../../components/ui/Icons'
import Button from '../../../components/ui/Button'
import RoundButton from '../../../components/ui/RoundButton'
import Money from '../../../components/ui/Money'
import Subtitle from '../../../components/ui/Subtitle'
import { getNameFromKey } from '../../../../utils/transferTexts'
import { Timeline, TimelineItem }  from 'vertical-timeline-component-for-react';
import Select from '../../../components/ui/Select'
import { TimelineCustom, TimelineCustomItem } from '../../../components/TimelineCustomizada'
import ButtonChat from '../../../components/ui/ButtonChat'

const Title = styled.h1`
  font-family: ${defaultTheme.fonts.secondary};
  line-height: 1.2;
  font-size: 1.3em;
  margin-bottom: 20px;
`
const SubTitulo = styled.h2`
  color: #ccc;
  font-family: ${defaultTheme.fonts.primary};
  line-height: 1.6;
  padding: 10px 10px 0 10px;
`

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #fff;
`

const Titulo = styled.h1`
  color: #333;
  font-family: ${defaultTheme.fonts.secondary};
  font-size: 1.3em;
  font-weight: 800;
  line-height: 1.6;
  margin-top: 8px;
`

const Content = styled.div`
  min-height: 90vh;
  padding: 10px 20px;
  font-family: ${defaultTheme.fonts.secondary};
  font-size: 14px;
`

const Group = styled.div`
  padding: 20px;
`

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  td {
    vertical-align: top;
  }
`

const Conclusion = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 30px;
  width: 80%;
  & div  {
    width: 50%;
  }
`

const Label = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  box-sizing: border-box;
  padding: 5px 5px 5px 0px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
`

const CheckBoxLabel = styled.span`
  color: ${props => props.error ? 'red' : '#484848'};
  box-sizing: border-box;
  padding: 5px 5px 5px 0px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  margin-left: 5px;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
`

const LabelBillingConditions = styled.label`
  background-color: #e8e8e8;
  border-radius: 15px;
  box-sizing: border-box;
  color: ${props => props.error ? 'red' : '#484848'};
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 500;
  min-height: 25px;
  padding: 10px;
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
  width: fit-content;
`

const FixedDiv = styled.div`
  position: fixed;
  top: 46px;
  bottom: 0;
  width: 25%;
  overflow-y: scroll;
  margin-top: 15px;
`

const LinkShowDetails = styled.a`
  display: block;
  width: 100%;
  text-align: right;
  font-size: 13px;
  color: ${defaultTheme.colors.grey.medium};
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  color: #ccc;
  text-decoration: underline;
  cursor: pointer;
  padding-left: 10px;
`

const Tooltip = styled.span`
  background-color: #fff;
  line-height: 20px;
  padding: 10px;
  margin-left: -64px;
  margin-top: -24px;
  text-align: center;
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: 1px 1px 2px rgba(0,0,0,0.1);
`

const ListagemDividida = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-content: space-between;
  justify-content: space-between;
`

const Checkbox = styled.input`
  font-size: 16px;
  background-color: #FFF;
  border-radius: 1px;
  cursor: pointer;
  padding: 6px 10px;
  border-bottom: ${props => props.error ? '2px solid red' : `2px solid ${defaultTheme.colors.primary.default}`};
  transition: all 0.2s linear;
  height: 20px;
  width: 20px;
`

const Total = (props) => {
  return (
    <div style = {props.style}>
      <p style={{
        color: defaultTheme.colors.grey.medium,
        fontFamily: defaultTheme.fonts.primary,
        fontSize: '13px',
        margin: 'auto', textAlign: 'center',
        fontWeight: 100}}>{props.aPartirDe ? 'a partir de' : 'valor total sem taxas'}</p>
      <Money style={{marginRight: 'auto', textAlign: 'center'}}>{props.children}</Money>
    </div>
  )
}

class ResumoTransfer extends Component {
  state = {
    isLoading: true,
    todosSolicitados: false,
    verMais: false,
    verMaisDetalhes : '',
    billingConditions: {
      percentage: 0,
      days: 0
    },
    agreedWithTerms: false,
    abrirContraproposta: false,
    visualizaHistorico: false,
    selected: '',
    toEnd: '',
    lodging: {
      budgets: [],
      budgetId: '',
      includedItems: {}
    },
    room: {
      showItems: [],
      hotel: false,
      budget: false,
      briefing: false,
    },
    foodsAndBeverages: {
      showItems: [],
      budgets: [],
      budgetId: ''
    },
    equipments: {
      showItems: [],
      briefing: {},
      budget: {}
    },
    transfer: {
      showItems: [],
      briefing: {},
      budget: {}
    },
    otherServices: {
      showItems: [],
      briefing: {},
      budget: {}
    },
    event: {},
    hotel: {},
    informacoes: {},
    modalVerificacao: false,
    leuEConcorda: false,
    modalTemCerteza: false,
    modalRecusarStatus: '',
    modalAprovarFornecedor: false,
    modalRecusarFornecedor: false,
    justification: '',
    valoresExtraTooltip: { briefingId: '', vehicleType: '' }
  }

  getTotalWithFee = () => {
    return this.applyFee(this.getManagementFee(), this.pegaValorTotalTransfer())
  }

  getTotalFee = () => {
    const fee = this.getManagementFee()
    const value = this.pegaValorTotalTransfer()
    if(!fee) return 0
    return (fee.type === 'PERCENTAGE')
      ? Math.round(Number((value * (fee.amount / 100))))
      : (value + fee.amount)
  }

  getFeeAmount = (fee, value) => {
    if(!fee) return 0
    return (fee.type === 'PERCENTAGE')
      ? Math.round(Number((value * (fee.amount / 100))))
      : (value + fee.amount)
  }

  getManagementFee() {
    return this.state.event
      ? this.state.event.managementFee
      : {
        amount: 0,
        type: 'PERCENTAGE'
      }
  }

  applyFee(fee, value) {
    if(!fee) return 0
    return (fee.type === 'PERCENTAGE')
      ? Math.round(Number((value * (1 + (fee.amount / 100)))))
      : (value + fee.amount)
  }

  componentDidMount() {
    this.loadDataServices()
    .then(() => this.loadDataEvent())
    .then(() => this.loadHistory())
    .then(() => this.pegaInfoLocalStorage())
  }

  redirect = (service) => {
    const { api: { routes: { events } } } = config
    const { eventId, accountId } = this.props.match.params
    var win = window.open(`/organizer${events}/${eventId}/budget/account/${accountId}/${service}`);
    win.focus();
  }

  hasChosen(serviceBudget) {
    return serviceBudget.all.some(budget => budget.status === 'ACCEPTED')
  }

  verifaRole = (nivel) => {
    const roles = window.localStorage.getItem('user_roles') !== 'undefined' && JSON.parse(window.localStorage.getItem('user_roles'))
    return roles && roles.find(role => role === nivel)
  }

  verificaSeFoiSolicitadoAprovacao = (supplierId) => {
    const { event } = this.state
    return event && event.approval && Object.keys(event.approval.services).some(service => event.approval.services[service].supplierId === supplierId && event.approval.services[service].status === 'AWAITING_APPROVAL')
  }

  ativaModalTemCerteza = () => {
    this.setState({ modalTemCerteza: true })
  }

  aprovarFornecedor = () => {
    this.setState({
      isLoading: true,
      modalTemCerteza: false
    }, () => {
      const { eventId } = this.props.match.params
      const { api: { base, version, routes: { events } } } = config
      const endpoint = `${base}${version}${events}/${eventId}/approval/approve`
      Post(endpoint, this.generateJSONAprovacaoModal())
        .then(() => {
          if(this.verificaSeTodosOsServicosForamAprovados()) return this.setState({isLoading: false, todosAprovados: true })
          else return this.setState({ isLoading: false, modalAprovarFornecedor: true })
        }).catch(err => {
          console.error(err.message)
          this.setState({
            isLoading: false
          })
          alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
        })
    })
  }

  generateJSONAprovacaoModal = () => {
    const { accountId } = this.props.match.params
    const { transfer } = this.state
    return {
      services: {
        transfer: transfer && transfer.budget && accountId
      }
    }
  }

  recusarFornecedor = () => {
    this.setState({ modalRecusarFornecedor: true })
  }

  confirmarRecusarFornecedor = () => {
    this.setState({
      isLoading: true,
      modalRecusarFornecedor: false
    }, () => {
      const { history } = this.props
      const { eventId } = this.props.match.params
      const { api: { base, version, routes: { events } } } = config
      const endpoint = this.state.modalRecusarStatus === 'REJEITAR' ? `${base}${version}${events}/${eventId}/approval/refuse` : `${base}${version}${events}/${eventId}/approval/review`
      Post(endpoint, this.generateJSONRecusar())
        .then(() => {
          this.state.modalRecusarStatus === 'REJEITAR' ? history.push(`/organizer`) : history.push(`/organizer/event/${eventId}/budget/place`)
        }).catch(err => {
          console.error(err.message)
          this.setState({
            isLoading: false,
            modalRecusarFornecedor: true
          })
          alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
        })
    })
  }

  generateJSONRecusar = () => {
    const { transfer } = this.state
    return {
      services: {
        transfer: transfer && transfer.budget && this.state.justification
      }
    }
  }

  choosedSupplier = () => {
    this.setState({ isLoading: true })
    const { history } = this.props
    const { api: { base, version, routes: { events } } } = config
    const { eventId, accountId } = this.props.match.params
    const services = {
      room: { id: this.state.hotel.budgets.rooms.mostRecent && this.state.hotel.budgets.rooms.mostRecent.id},
      foodsAndBeverages : {id: this.state.hotel.budgets.foodsAndBeverages.mostRecent && this.state.hotel.budgets.foodsAndBeverages.mostRecent.id},
      lodging : {id: this.state.hotel.budgets.lodging.mostRecent && this.state.hotel.budgets.lodging.mostRecent.id}
    }

    const chooseSupplier = `${base}${version}${events}/${eventId}/budget/place`
    Post(chooseSupplier, {services})
      .then(() => {
        this.setState({ isLoading: false })
        if(this.verificaSeTodosOsServicosForamSelecionados()) return history.push(`/organizer${events}/${eventId}/resume/chosen`)
        if(this.verificaSeTodosOsServicosForamSolicitadosParaAprovacao()) return this.setState({isLoading: false, todosSolicitados: true })
        else return history.push(`/organizer${events}/${eventId}/resume/budget/${accountId}`)
      })
      .catch(error => {
        alert(`Falha ao escolher o fornecedor, favor entrar em contato com a gente através do email: corp@evnts.com.br `)
        console.error(`Erro ao tentar escolher o fornecedor: ${error}`)
        this.setState({ isLoading: false })
      })
  }

  submit = () => {
    if(!this.state.leuEConcorda) return alert('Você precisa concordar com a política do fornecedor para continuar.')

    this.setState({ isLoading: true, modalVerificacao: false })
    const { api: { base, version } } = config
    const { eventId } = this.props.match.params
    const userType = window.localStorage.getItem('user_type')
    const budgetId = this.state.transfer.budget.id
    const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
    const acceptEndpoint = `${base}${version}/budget/transfer/${budgetId}/accept` // Muda o status do evento para ordered
    const finishOrderEndpoint = temFeatureFlag('approval') ? `${base}${version}${eventEndpoint}/${eventId}/approval/add-supplier-to-approval` : `${base}${version}${eventEndpoint}/${eventId}/finishOrderWithSupplierForServices` // Muda o status do evento para ordered
    Post(acceptEndpoint, this.trataDados())
      .then(() => {
        Post(finishOrderEndpoint, temFeatureFlag('approval') ? this.generateJSONAprovacao() : this.generateJSON())
          .then(() => {
            this.setState({isLoading: false }, () => {
                if(this.verificaSeTodosOsServicosForamSelecionados()) return this.props.history.push(`/organizer/event/${eventId}/resume/chosen`)
                if(this.verificaSeTodosOsServicosForamSolicitadosParaAprovacao()) return this.setState({isLoading: false, todosSolicitados: true })
                else return this.props.history.push(`/organizer/event/${eventId}/budget/place`)
            })
          })
          .catch((error) => {
            console.error(error)
            alert('Ocorreu um problema ao fechar o pedido. Favor entrar em contato através do email: corp@evnts.com.br')
            this.setState({isLoading: false})
          })
      })
      .catch((error) => {
        console.error(error)
        alert('Ocorreu um problema ao fechar o pedido. Favor entrar em contato através do email: corp@evnts.com.br')
        this.setState({isLoading: false})
      })
  }

  generateJSON = () => {
    const { transfer } = this.state
    return {
      services: {
        transfer: transfer && transfer.budget && transfer.budget.id
      }
    }
  }

  generateJSONAprovacao = () => {
    const { accountId } = this.props.match.params
    const { transfer } = this.state
    return {
      services: {
        transfer: {
          supplierId: transfer && transfer.budget && accountId,
          budgetId: transfer && transfer.budget && transfer.budget.id
        }
      }
    }
  }

  gerenciaResponsividade = () => {
    if(window.innerWidth < 600) return this.paginaMobile()
    else return this.paginaDesktop()
  }

  getBillingConditions = (budgets) => {
    return Object.keys(budgets).filter(item => budgets[item] && budgets[item].mostRecent).reduce((obj, item) => {
      if(budgets[item].mostRecent.billingConditions) {
        obj = budgets[item].mostRecent.billingConditions
      }
      return obj
    }, false)
  }

  pegaValorInicialAntesDaContraPropostaPorServico = (servico) => {
    return this.state.counterProposals ? this.state.counterProposals.reduce((total, services) => {
      total += services.reduce((soma, service) => {
        if(service.service === servico) {
          var lista = service.counterProposals.filter(cp => !cp.duplicata)
          if(lista.length > 0) {
            soma += lista[lista.length - 1].initialPrice
          }
        }
        return soma
      }, 0)
      return total
    }, 0) : 0
  }

  geraTextoTipoDeServico = (type, vehicleTime) => {
    switch (type) {
      case 'SHUTTLE':
        return 'Shuttle (circular)'
      case 'TRASLADO_SO_IDA':
        return 'Traslado só ida'
      case 'TRASLADO_IDA_VOLTA':
        return 'Traslado ida e volta'
      case 'VEICULO_DISPOSICAO':
        return `Veículo a disposição - Diária ${vehicleTime}h`
      default:
        return ''
    }
  }

  pegaRequestPorId = (id) => {
    const index = this.state.transfer.budget.requests && this.state.transfer.budget.requests.findIndex(item => item.briefingRequestId === id)
    if (index !== -1) return this.state.transfer.budget.requests[index]
    else return null
  }

  atualizaInformacoes = (id, sessao, index, tipo, valor) => {
    const { informacoes } = this.state
    if(!informacoes[id]) {
      informacoes[id] = {}
    } if(!informacoes[id][sessao]) {
      informacoes[id][sessao] = {}
    } if(!informacoes[id][sessao][index]) {
      informacoes[id][sessao][index] = {}
    }
    informacoes[id][sessao][index][tipo] = valor
    this.setState({ informacoes }, () => this.atualizaLocalStorage())
  }

  atualizaLocalStorage = () => {
    const { eventId, accountId } = this.props.match.params
    var transfer_info = JSON.parse(window.localStorage.getItem('transfer_info'))
    const { informacoes } = this.state
    informacoes.total = this.pegaValorTotalTransfer()
    if(!transfer_info) {
      transfer_info = {}
    } if(!transfer_info[eventId]) {
      transfer_info[eventId] = {}
    }
    transfer_info[eventId][accountId] = this.state.informacoes
    window.localStorage.setItem('transfer_info', JSON.stringify(transfer_info))
  }

  pegaInfoLocalStorage = () => {
    const { eventId, accountId } = this.props.match.params
    var transfer_info = JSON.parse(window.localStorage.getItem('transfer_info'))
    this.setState({ informacoes: (transfer_info && transfer_info[eventId] && transfer_info[eventId][accountId]) || {} })
  }

  atualizaQuantidade = (id, sessao, index, tipo, valor, soma) => {
    const { informacoes } = this.state
    if(!informacoes[id]) {
      informacoes[id] = {}
    } if(!informacoes[id][sessao]) {
      informacoes[id][sessao] = {}
    } if(!informacoes[id][sessao][index]) {
      informacoes[id][sessao][index] = {}
    } if(!informacoes[id][sessao][index][tipo]) {
      informacoes[id][sessao][index][tipo] = 0
    }
    if(soma) {
      informacoes[id][sessao][index][tipo] += valor
    } else if(informacoes[id][sessao][index][tipo] > 0) {
      informacoes[id][sessao][index][tipo] -= valor
    }
    this.setState({ informacoes }, () => this.atualizaLocalStorage())
  }

  pegaValorDaInfo = (id, sessao, index, tipo) => {
    return this.state.informacoes[id] && this.state.informacoes[id][sessao] && this.state.informacoes[id][sessao][index] && this.state.informacoes[id][sessao][index][tipo]
  }

  pegaTotalLunchBoxes = (id) => {
    const { informacoes } = this.state
    return informacoes[id] && informacoes[id].lunchBoxes && Object.keys(informacoes[id].lunchBoxes).reduce((soma, item) => {
      soma += (this.pegaRequestPorId(id).onboardServices.lunchBoxes[item].price.amount) * (informacoes[id].lunchBoxes[item].quantity) || 0
      return soma
    }, 0)
  }

  pegaValorTotalTransfer = () => {
    return this.state.transfer.briefing.requests && this.state.transfer.briefing.requests.reduce((soma, briefing) => {
      soma += parseInt(this.pegaRequestPorId(briefing.id) ? this.pegaRequestPorId(briefing.id).vehicles.reduce((somaVeiculo, veiculo, index) => {
        somaVeiculo += (this.pegaValorDaInfo(briefing.id, 'vehicles', index, 'quantity') || 0) *
          (this.pegaValorDaInfo(briefing.id, 'vehicles', index, 'isBilingual') === 'true' ? veiculo.bilingualPrice && veiculo.bilingualPrice.amount : veiculo.price.amount) *
          (this.pegaValorDaInfo(briefing.id, 'vehicles', index, 'days') || 0)
        return somaVeiculo
      }, 0) : 0)
      soma += parseInt((this.pegaRequestPorId(briefing.id) && this.pegaRequestPorId(briefing.id).onboardServices.services) ? this.pegaRequestPorId(briefing.id).onboardServices.services.reduce((somaServico, servico, index) => {
        somaServico += (this.pegaValorDaInfo(briefing.id, 'services', index, 'quantity') || 0) * servico.price.amount
        return somaServico
      }, 0) : 0)
      soma += parseInt(this.pegaTotalLunchBoxes(briefing.id) || 0)
      soma += parseInt((this.pegaValorDaInfo(briefing.id, 'coordinator', 0, 'quantity') || 0) *
        (this.pegaRequestPorId(briefing.id).coordinatorPrices ? this.pegaRequestPorId(briefing.id).coordinatorPrices.pricePerDay.amount : 0) *
        (this.pegaValorDaInfo(briefing.id, 'coordinator', 0, 'days') || 0))
      return soma
    }, 0)
  }

  verificaSeTodosOsServicosForamSelecionados = () => {
    const lodging = this.state.lodging.requested ? !!this.state.lodging.choosedSupplier : true
    const room = this.state.room.requested ? !!this.state.room.choosedSupplier : true
    const foodsAndBeverages = this.state.foodsAndBeverages.requested ? !!this.state.foodsAndBeverages.choosedSupplier : true
    const equipments = this.state.equipments.requested ? !!this.state.equipments.choosedSupplier : true
    const otherServices = this.state.otherServices.requested ? !!this.state.otherServices.choosedSupplier : true
    return lodging && room && foodsAndBeverages && equipments && otherServices
  }

  verificaSeTodosOsServicosForamSolicitadosParaAprovacao = () => {
    const { event } = this.state
    const lodging = this.state.lodging.requested ? (event.approval && event.approval.services.lodging.status === 'AWAITING_APPROVAL') : true
    const room = this.state.room.requested ? (event.approval && event.approval.services.room.status === 'AWAITING_APPROVAL') : true
    const foodsAndBeverages = this.state.foodsAndBeverages.requested ? (event.approval && event.approval.services.foodsAndBeverages.status === 'AWAITING_APPROVAL') : true
    const equipments = this.state.equipments.requested ? (event.approval && event.approval.services.equipments.status === 'AWAITING_APPROVAL') : true
    const otherServices = this.state.otherServices.requested ? (event.approval && event.approval.services.otherServices.status === 'AWAITING_APPROVAL') : true
    return lodging && room && foodsAndBeverages && equipments && otherServices
  }

  verificaSeTodosOsServicosForamAprovados = () => {
    const { event } = this.state
    const lodging = this.state.lodging.requested ? (event.approval && event.approval.services.lodging.status === 'AWAITING_REQUEST_CODE') : true
    const room = this.state.room.requested ? (event.approval && event.approval.services.room.status === 'AWAITING_REQUEST_CODE') : true
    const foodsAndBeverages = this.state.foodsAndBeverages.requested ? (event.approval && event.approval.services.foodsAndBeverages.status === 'AWAITING_REQUEST_CODE') : true
    const equipments = this.state.equipments.requested ? (event.approval && event.approval.services.equipments.status === 'AWAITING_REQUEST_CODE') : true
    const otherServices = this.state.otherServices.requested ? (event.approval && event.approval.services.otherServices.status === 'AWAITING_REQUEST_CODE') : true
    return lodging && room && foodsAndBeverages && equipments && otherServices
  }

  alteraValorExtraSelecionado = (briefingId, veiculoType) => {
    if(this.state.valoresExtraTooltip.briefingId === briefingId &&
      this.state.valoresExtraTooltip.vehicleType === veiculoType)
      this.setState({ valoresExtraTooltip: { briefingId: '', vehicleType: '' } })
    else this.setState({ valoresExtraTooltip: { briefingId: briefingId, vehicleType: veiculoType } })
  }

  verificaSeFoiSelecionadoAoMenosUmVeiculo = () => {
    return this.state.transfer.briefing.requests && this.state.transfer.briefing.requests.every(briefing => {
      if (this.fornecedorAlegouDisponibilidade(briefing.id)) return true
      return this.pegaRequestPorId(briefing.id) && this.pegaRequestPorId(briefing.id).vehicles.some((_veiculo, index) => {
        return this.pegaValorDaInfo(briefing.id, 'vehicles', index, 'quantity') > 0 &&
          this.pegaValorDaInfo(briefing.id, 'vehicles', index, 'days') > 0
      })
    })
  }

  verificaSeFoiSelecionadoCoordinator = () => {
    return this.state.transfer.briefing.requests && this.state.transfer.briefing.requests.every(briefing => {
      if (this.fornecedorAlegouDisponibilidade(briefing.id)) return true
      return briefing.hasCoordinator ?
        (this.pegaValorDaInfo(briefing.id, 'coordinator', 0, 'quantity') > 0 &&
        this.pegaValorDaInfo(briefing.id, 'coordinator', 0, 'days') > 0)
        : true
    })
  }

  trataDados = () => {
    return {
      chosenBudgets: {
        requests: this.state.transfer.budget.requests && this.state.transfer.budget.requests.map(item => {
          if (!item.hasAvailability) return null
          return {
            vehicles: item.vehicles.map((veiculo, index) => {
                return {
                  type: veiculo.type,
                  quantity: this.pegaValorDaInfo(item.briefingRequestId, 'vehicles', index, 'quantity'),
                  days: this.pegaValorDaInfo(item.briefingRequestId, 'vehicles', index, 'days'),
                  isBilingual: this.pegaValorDaInfo(item.briefingRequestId, 'vehicles', index, 'isBilingual') || false
                }
            }).filter ((_veiculo, index) => {
            return this.pegaValorDaInfo(item.briefingRequestId, 'vehicles', index, 'quantity') > 0 &&
              this.pegaValorDaInfo(item.briefingRequestId, 'vehicles', index, 'days') > 0
          }),
            briefingRequestId: item.briefingRequestId,
            coordinatorPrices: {
              quantity: this.pegaValorDaInfo(item.briefingRequestId, 'coordinator', 0, 'quantity'),
              days: this.pegaValorDaInfo(item.briefingRequestId, 'coordinator', 0, 'days')
            },
            onboardServices: {
              services: item.onboardServices && item.onboardServices.services.map((servico, index) => {
                return {
                  name: servico.name,
                  type: servico.type,
                  requestedAmount: this.pegaValorDaInfo(item.briefingRequestId, 'services', index, 'quantity')
                }
              }),
              lunchBoxes: item.onboardServices && item.onboardServices.lunchBoxes.map((lunchBox, index) => {
                return {
                  id: lunchBox.id,
                  quantity: this.pegaValorDaInfo(item.briefingRequestId, 'lunchBoxes', index, 'quantity')
                }
              })
            }
          }
        }).filter(item => item)
      }
    }
  }

  Solicitacao = (briefing) => {
    return <Card style={{ padding: '10px', width: '100%', marginTop: '30px' }}>
      <Title style={{ fontSize: '15px', marginBottom: '0px' }}>{briefing.name}</Title>
      <Card>
        <Title style={{ marginBottom: '0px', fontSize: '0.9em', textTransform: 'uppercase' }} >Observações do fornecedor:</Title>
        <Subtitle style={{ marginBottom: '0px'}}>{(this.pegaRequestPorId(briefing.id) && this.pegaRequestPorId(briefing.id).observation) || 'Sem observações'}</Subtitle>
        <Title style={{ marginTop: '20px', marginBottom: '0px', fontSize: '0.9em', textTransform: 'uppercase' }} >Suas observações:</Title>
        <Subtitle style={{ marginBottom: '0px'}}>{briefing.observation || 'Sem observações'}</Subtitle>
      </Card>
      <br/>
      <br/>
      <Table style={{ marginTop: '10px', marginBottom:'20px' }}>
        <tr>
          <th style={{ verticalAlign: 'middle' }}>
            <Label style={{ fontWeight: '600', color: '#484848' }}>Tipo de serviço:</Label>
          </th>
          <th style={{ verticalAlign: 'middle' }}>
            <Label style={{ fontWeight: '600', color: '#484848' }}>Número de pessoas:</Label>
          </th>
          <th style={{ verticalAlign: 'middle' }}>
            <Label style={{ fontWeight: '600', color: '#484848' }}>Haverá bagagens:</Label>
          </th>
        </tr>
        <tr>
          <td>
            <Label>
              <b>{this.geraTextoTipoDeServico(briefing.type, briefing.vehicleTime)}</b>
            </Label>
          </td>
          <td>
            <Label>
              <b>{briefing.passengers}</b>
            </Label>
          </td>
          <td>
            <Label>
              <b>{briefing.luggage ? 'Sim': 'Não'}</b>
            </Label>
          </td>
        </tr>
      </Table>
      <TimelineCustom>
        <Timeline lineColor={'#ddd'}>
          <TimelineCustomItem>
            <TimelineItem
              key="origem"
              bodyContainerStyle={{ marginBottom: '-20px' }}
              dateComponent={(
                <div
                  style={{
                    display: 'block',
                    float: 'left',
                    color: '#727272',
                  }}
                >
                <Subtitle style={{ color: '#727272', marginBottom: '10px', marginTop: '-20px' }}><span style={{ fontWeight: 'bold' }}>Data</span></Subtitle>
                <Subtitle style={{ color: '#727272', marginBottom: '5px' }}>{formatDate(briefing.originDate)}</Subtitle>
                </div>
              )}
            >
              <Subtitle style={{ color: '#727272', marginBottom: '10px', marginTop: '-20px' }}><span style={{ fontWeight: 'bold' }}>Local</span></Subtitle>
              <Subtitle style={{ color: '#727272', marginBottom: '5px' }}>{briefing.origin.address} ({briefing.origin.city})</Subtitle>
            </TimelineItem>
          </TimelineCustomItem>
          {briefing.stops.map((stop, index) => (
            <TimelineCustomItem>
              <TimelineItem
                key={index}
                bodyContainerStyle={{ marginBottom: '-20px' }}
                dateComponent={<></>}>
                <Label style={{ color: '#727272', marginBottom: '5px' }}>{stop.address}</Label>
              </TimelineItem>
            </TimelineCustomItem>
          ))}
          <TimelineCustomItem>
            <TimelineItem
              key="destino"
              bodyContainerStyle={{ marginBottom: '-20px' }}
              style={{ color: '#727272' }}
              dateComponent={(
                <div
                  style={{
                    display: 'block',
                    float: 'left',
                    color: '#727272',
                  }}
                >
                <Subtitle style={{ color: '#727272', marginBottom: '5px' }}>{formatDate(briefing.destinationDate)}</Subtitle>
                </div>
              )}
            >
              <Subtitle style={{ color: '#727272', marginBottom: '5px' }}>{briefing.destination.address} ({briefing.destination.city})</Subtitle>
            </TimelineItem>
          </TimelineCustomItem>
        </Timeline>
      </TimelineCustom>

      {this.fornecedorAlegouDisponibilidade(briefing.id) && <Card style = {{ width: '100%', paddingBottom: '10px', color: 'red' }}>
        <Subtitle style={{ marginBottom: 'auto', marginTop: 'auto' }}>Fornecedor não tem disponibilidade para este pedido.</Subtitle>
      </Card>}

      {
        !this.fornecedorAlegouDisponibilidade(briefing.id) && <>
          <Label style={{ fontWeight: '600', color: '#484848', marginTop: '20px' }}>Veículos</Label>
          <Label style={{ fontSize: '12px' }}>Os veículos abaixo foram oferecidos por este fornecedor. Você pode agora escolher os de sua preferência, navegar pelas tarifas oferecidas e, ao final, selecionar apenas o que precisa para continuar.</Label>
          {this.pegaRequestPorId(briefing.id) && this.pegaRequestPorId(briefing.id).vehicles.map((veiculo, index) => (
            <Card style={{ width: '100%', paddingBottom: '10px' }}>
              <Group style={{ padding: '0px' }}>
                <Label style={{ fontWeight: 600, fontSize: '15px' }}>{getNameFromKey(veiculo.type)}</Label>
                <label style={{ position: 'absolute', right: '5px', top: '20px' }}>
                  <Money>{formatCurrency(
                    this.pegaValorDaInfo(briefing.id, 'vehicles', index, 'quantity') *
                    (this.pegaValorDaInfo(briefing.id, 'vehicles', index, 'isBilingual') === 'true' ? veiculo.bilingualPrice && veiculo.bilingualPrice.amount : veiculo.price.amount) *
                    this.pegaValorDaInfo(briefing.id, 'vehicles', index, 'days'), false)}</Money>
                </label>
                <Label style={{ fontSize: '12px', listStyle: 'none', position: 'absolute', right: '10px', top: '40px', textAlign: 'right' }}>{formatCurrency(this.pegaValorDaInfo(briefing.id, 'vehicles', index, 'isBilingual') === 'true' ? veiculo.bilingualPrice && veiculo.bilingualPrice.amount : veiculo.price.amount)} / veículo / dia</Label>
              </Group>
              <Label style={{ width: 'fit-content' }}>Capacidade: {briefing.luggage ? veiculo.capacityWithLuggage : veiculo.capacityWithoutLuggage} pessoas</Label>
              <Group style={{ position: 'relative', padding: '0px' }}>
                <Label><Link to='#' onClick={() => this.alteraValorExtraSelecionado(briefing.id, veiculo.type)} style={{ color: defaultTheme.colors.grey.light, fontSize: '14px' }}>valores extras</Link></Label>
                {this.state.valoresExtraTooltip.briefingId === briefing.id && this.state.valoresExtraTooltip.vehicleType === veiculo.type && <Tooltip onClick={() => this.alteraValorExtraSelecionado(briefing.id, veiculo.type)} style={{ width: 'fit-content', padding: '6px', position: 'absolute', top: '0px', left: '160px' }}>
                  <Label>Km excedente: <span style={{ fontWeight: 600 }}>{formatCurrency(veiculo.extraKm.amount)} / km</span></Label>
                  <Label>Hora extra / parada: <span style={{ fontWeight: 600 }}>{formatCurrency(veiculo.overtime.amount)} / hora</span></Label>
                </Tooltip>}
              </Group>

              <Group style={{ padding: '0px', display: 'flex' }}>
                <Input
                  type={'number'}
                  label={'Quantidade'}
                  name={'quantity'}
                  width='35%'
                  value={this.pegaValorDaInfo(briefing.id, 'vehicles', index, 'quantity')}
                  onChange={(input) => this.atualizaInformacoes(briefing.id, 'vehicles', index, input.target.name, input.target.value)} />
                <Input
                  type={'number'}
                  label={'Para quantos dias?'}
                  name={'days'}
                  value={this.pegaValorDaInfo(briefing.id, 'vehicles', index, 'days')}
                  onChange={(input) => this.atualizaInformacoes(briefing.id, 'vehicles', index, input.target.name, input.target.value)}
                  width='35%' />
                <Select
                  label={'Motorista bilíngue'}
                  name={'isBilingual'}
                  containerStyle={{ width: '35%' }}
                  value={this.pegaValorDaInfo(briefing.id, 'vehicles', index, 'isBilingual')}
                  onChange={(input) => this.atualizaInformacoes(briefing.id, 'vehicles', index, input.target.name, input.target.value)}>
                  <option value={false}>Não</option>
                  <option value={true}>Sim</option>
                </Select>
              </Group>
            </Card>
          ))}

          {briefing.hasOnboardServices && <>
            <Label style={{ fontWeight: '600', color: '#484848', marginTop: '20px' }}>Serviço de bordo</Label>
            {this.pegaRequestPorId(briefing.id) && this.pegaRequestPorId(briefing.id).onboardServices.services && this.pegaRequestPorId(briefing.id).onboardServices.services.map((servico, index) => (
              <Card style={{ width: '100%', paddingBottom: '10px' }}>
                <Group style={{ padding: '0px' }}>
                  <Label style={{ fontWeight: 400, fontSize: '15px' }}>{getNameFromKey(servico.name)}</Label>
                  <label style={{ position: 'absolute', right: '5px', top: '20px' }}>
                    <Money>{formatCurrency(
                      this.pegaValorDaInfo(briefing.id, 'services', index, 'quantity') * servico.price.amount,
                      false)}</Money>
                  </label>
                  <Label style={{ fontSize: '12px', listStyle: 'none', position: 'absolute', right: '10px', top: '40px', textAlign: 'right' }}>{formatCurrency(servico.price.amount)} / unidade</Label>
                </Group>
                <Group style={{ padding: '0px', display: 'flex' }}>
                  <Label>{getNameFromKey(servico.type)} | {servico.quantity}ml</Label>
                </Group>
                <Group style={{ display: 'flex', margin: 'auto', marginTop: '-40px', padding: '0px', width: 'fit-content' }}>
                  <Label style={{ width: 'fit-content', paddingRight: '15px' }}>Quantidade:</Label>
                  <Group style={{ display: 'flex', padding: '0px' }}>
                    <RoundButton style={{ width: '35px' }} simbolo={'-'} onClick={() => this.atualizaQuantidade(briefing.id, 'services', index, 'quantity', 1, false)} />
                    <Title style={{ width: '50px', marginTop: '5px', textAlign: 'center' }}>{(this.pegaValorDaInfo(briefing.id, 'services', index, 'quantity')) || 0}</Title>
                    <RoundButton style={{ width: '35px' }} simbolo={'+'} onClick={() => this.atualizaQuantidade(briefing.id, 'services', index, 'quantity', 1, true)} />
                  </Group>
                </Group>
              </Card>
            ))}

            <Card style={{ width: '100%', paddingBottom: '10px' }}>
              <Group style={{ padding: '0px' }}>
                <Label style={{ fontWeight: 400, fontSize: '15px' }}>Lunchbox</Label>
                <label style={{ position: 'absolute', right: '5px', top: '20px' }}>
                  <Money>{formatCurrency(this.pegaTotalLunchBoxes(briefing.id), false)}</Money>
                </label>
              </Group>
              <Group style={{ padding: '0px' }}>
                <Label>Selecione a quantidade de lunchbox abaixo:</Label>
                <ListagemDividida style={{ justifyContent: 'start' }}>
                  {this.pegaRequestPorId(briefing.id) && this.pegaRequestPorId(briefing.id).onboardServices.lunchBoxes && this.pegaRequestPorId(briefing.id).onboardServices.lunchBoxes.map((lunchBox, index) => (
                    <Card style={{ paddingBottom: '10px', margin: '5px', width: 'calc((100% / 2) - 10px)' }} >
                      <Group style={{ display: 'flex', padding: '0px' }}>
                        <Label style={{ fontWeight: '600', color: '#484848' }}><b>{lunchBox.name}</b></Label>
                      </Group>
                      <Label style={{ marginTop: '-10px' }}>{formatCurrency(lunchBox.price.amount)} / unidade</Label>
                      <Label style={{
                        minHeight: '100px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 4
                      }}>{lunchBox.description}</Label>
                      {lunchBox.description.length > 150 && <LinkShowDetails>ver mais</LinkShowDetails>}
                      <Group style={{ display: 'flex', margin: 'auto', padding: '0px', width: 'fit-content' }}>
                        <Label style={{ width: 'fit-content', paddingRight: '10px', fontSize: '13px' }}>Quantidade:</Label>
                        <Group style={{ display: 'flex', padding: '0px' }}>
                          <RoundButton style={{ width: '35px' }} simbolo={'-'} onClick={() => this.atualizaQuantidade(briefing.id, 'lunchBoxes', index, 'quantity', 1, false)} />
                          <Title style={{ width: '50px', marginTop: '5px', textAlign: 'center' }}>{(this.pegaValorDaInfo(briefing.id, 'lunchBoxes', index, 'quantity')) || 0}</Title>
                          <RoundButton style={{ width: '35px' }} simbolo={'+'} onClick={() => this.atualizaQuantidade(briefing.id, 'lunchBoxes', index, 'quantity', 1, true)} />
                        </Group>
                      </Group>
                    </Card>
                  ))}
                </ListagemDividida>
              </Group>
            </Card>
          </>}

          {this.pegaRequestPorId(briefing.id) && briefing.hasCoordinator && <Label style={{ fontWeight: '600', color: '#484848', marginTop: '20px' }}>Profissionais</Label>}
          {this.pegaRequestPorId(briefing.id) && briefing.hasCoordinator && <Card style={{ width: '100%', paddingBottom: '10px' }}>
            <Group style={{ padding: '0px' }}>
              <Label style={{ fontWeight: 400, fontSize: '15px', marginTop: '5px', marginBottom: '5px' }}>Coordenador/Receptivo {getNameFromKey(briefing.coordinatorLanguage)}</Label>
              <label style={{ position: 'absolute', right: '5px', top: '20px' }}>
                <Money>{formatCurrency(
                  this.pegaValorDaInfo(briefing.id, 'coordinator', 0, 'quantity') *
                  this.pegaRequestPorId(briefing.id).coordinatorPrices.pricePerDay.amount *
                  this.pegaValorDaInfo(briefing.id, 'coordinator', 0, 'days'), false)}</Money>
              </label>
              <Label style={{ fontSize: '12px', listStyle: 'none', position: 'absolute', right: '10px', top: '40px', textAlign: 'right' }}>{formatCurrency(this.pegaRequestPorId(briefing.id).coordinatorPrices.pricePerDay.amount)} / dia + {formatCurrency(this.pegaRequestPorId(briefing.id).coordinatorPrices.overtimePrice.amount)} / hora extra</Label>
            </Group>
            <Group style={{ padding: '0px', display: 'flex' }}>
              <Input
                type={'number'}
                label={'Quantidade'}
                name={'quantity'}
                width='50%'
                value={this.pegaValorDaInfo(briefing.id, 'coordinator', 0, 'quantity')}
                onChange={(input) => this.atualizaInformacoes(briefing.id, 'coordinator', 0, input.target.name, input.target.value)}
                isRequired />
              <Input
                type={'number'}
                label={'Para quantos dias?'}
                name={'days'}
                value={this.pegaValorDaInfo(briefing.id, 'coordinator', 0, 'days')}
                onChange={(input) => this.atualizaInformacoes(briefing.id, 'coordinator', 0, input.target.name, input.target.value)}
                width='50%'
                isRequired />
            </Group>
          </Card>}
        </>
      }

      {this.pegaRequestPorId(briefing.id) && this.pegaRequestPorId(briefing.id).fees.length > 0 && <Label style={{ fontWeight: '600', color: '#484848', marginTop: '20px' }}>Taxas</Label>}
      {this.pegaRequestPorId(briefing.id) && this.pegaRequestPorId(briefing.id).fees.map(taxa => (
        <Card style={{ width: '100%', paddingBottom: '10px' }}>
          <Group style={{ padding: '0px' }}>
            <Label style={{ fontWeight: 400, fontSize: '15px', marginTop: '5px', marginBottom: '5px' }}>{getNameFromKey(taxa.name)}</Label>
            <Label style={{ fontSize: '12px', listStyle: 'none', position: 'absolute', right: '10px', top: '40px', textAlign: 'right' }}>{taxa.amount}% de taxa de serviço - {formatCurrency(this.getFeeAmount(taxa, this.pegaValorTotalTransfer()))}</Label>
          </Group>
        </Card>
      ))}
    </Card>
  }

  paginaMobile = () => {
    const isLoading = this.state.isLoading
    const { hotel, event } = this.state
    if(!hotel) return <> </>
    const hasRooms = hotel && hotel.budgets.rooms.all.length > 0
    const hasFaB = hotel && hotel.budgets.foodsAndBeverages.all.length > 0
    const hasLodging = hotel && hotel.budgets.lodging.all.length > 0
    const requestedLodging = hotel && hotel.briefings.lodging
    const requestedRooms = hotel && hotel.briefings.rooms
    const requestedFaB = hotel && hotel.briefings.foodsAndBeverages
    const requestedServiceLodging = event && event.services && event.services.lodging.requested
    const requestedServiceRooms = event && event.services && event.services.room.requested
    const requestedServiceFaB = event && event.services && event.services.foodsAndBeverages.requested
    return (
      <Container>
        {isLoading && <Loader />}
        <Header />
        {(!isLoading && hotel.budgets) && <Main>
          <Modal isOpen={this.state.todosSolicitados} >
            <MoedaIcon />
            <Titulo>Fornecedores solicitados para aprovação!</Titulo>
            <SubTitulo>Em breve entraremos em contato</SubTitulo>
            <Button
              onClick={() => this.props.history.push(`/organizer`)}
              category={'secondary'}
              fontWeight={800}
              full>
              Ok!
            </Button>
          </Modal>
          <Modal isOpen={this.state.todosAprovados} >
            <MoedaIcon />
            <Titulo>Fornecedores aprovados!</Titulo>
            <SubTitulo>Aguarde o número de requisição.</SubTitulo>
            <Button
              onClick={() => this.props.history.push(`/organizer`)}
              category={'secondary'}
              fontWeight={800}
              full>
              Ok!
            </Button>
          </Modal>
          <Titulo>{hotel.name}</Titulo>
          {(hasLodging || (requestedLodging && requestedServiceLodging)) &&
            <ButtonIconExtra
              onClick = {() => hasLodging && this.redirect('lodging')}
              placeholder={'Hospedagem'}
              description={ hasLodging
                ? (<span>Bloqueio disponível até <b>{hotel.budgets.lodging ? formatDate(hotel.budgets.lodging.expireDate) : ''}</b></span>)
                : (<span> Fornecedor não enviou orçamento para este pedido</span>)
              }
              icon={'bed-outline'}>
                {!this.state.lodging.requested ? ('') : (<p>valor total escolhido</p>)}
              {hasLodging && <Total>{formatCurrency(this.getTotalPriceLodging())}</Total>}
            </ButtonIconExtra>
          }
          {requestedRooms && requestedServiceRooms &&
            <ButtonIconExtra
              onClick = {() => hasRooms && this.redirect('rooms')}
              placeholder={'Salas'}
              description={hasRooms
                ? (<span>Bloqueio disponível até <b>{hotel.budgets.rooms ? formatDate(hotel.budgets.rooms.expireDate) : ''}</b></span>)
                : (<span> Fornecedor não enviou orçamento para este pedido</span>)
              }
              icon={'room-outline'}>
                {!this.state.room.requested ? ('') : (<p>valor total escolhido</p>)}
              {hasRooms && <Total>{formatCurrency(this.getTotalPriceRoom())}</Total>}
            </ButtonIconExtra>
          }
          { requestedFaB && requestedServiceFaB &&
            <ButtonIconExtra
              onClick = {() => hasFaB && this.redirect('food-and-beverage')}
              placeholder={'Alimentos e bebidas'}
              icon={'food-beverage-outline'}>
                {!this.state.foodsAndBeverages.requested ? ('') : (<p>valor total escolhido</p>)}
              {hasFaB && <Total>{formatCurrency(this.getTotalPriceFoodsAndBeverages())}</Total>}
            </ButtonIconExtra>
          }
        </Main>}
        <Conclusion>
          <div>
            <Money>{formatCurrency(this.getTotalWithFee(), false)}</Money>
            <p>Valor total sem taxas</p>
          </div>
          <div>
            <Button
              type='submit'
              category={'secondary'}
              fontWeight={800}
              // disabled = {!this.state.agreedWithTerms}
              onClick={() => { this.choosedSupplier() }}
              full>
              Próximo
            </Button>
          </div>
        </Conclusion>
      </Container>
    )
  }

  paginaDesktop = () => {
    const { history } = this.props
    const { eventId, accountId } = this.props.match.params
    const eventName = this.state.event && this.state.event.name
    const eventStatus = this.state.event && this.state.event.status
    const DISPLAY_CHAT = !this.verifaRole('APPROVER') && eventId && accountId && eventName && eventStatus
    const EH_CHAIN = this.state.event && this.state.event.supplierChainId
    const NAO_EH_CHAIN = (!EH_CHAIN)
    const DISPLAY_BILLING_CONDITIONS = this.state.billingConditions && NAO_EH_CHAIN
    const isLoading = this.state.isLoading

    return <>
      <Header />
      <Main>
        <Body {...this.props} containerStyle={{ padding: '10px' }} pagina={'inicio'} menuLateralDireito={this.menuLateralDireito()}>
          {isLoading && <Loader />}
          <Options
            readOnly={this.state.readOnly}
            visualizarHistorico
            style={{ marginTop: '25px' }}
            clickHistorico={() => this.setState({ visualizaHistorico: true })}
            eventId={eventId}
            history={this.props.history}
            suppliersDeadline={this.state.event && this.state.event.suppliersDeadline}
            status={eventStatus}
            name={eventName}
            naoMostraEdicao={EH_CHAIN}
          />
          <Content>
            <Modal isOpen={this.state.todosSolicitados} >
              <MoedaIcon />
              <Titulo>Fornecedores solicitados para aprovação!</Titulo>
              <SubTitulo>Em breve entraremos em contato</SubTitulo>
              <Button
                onClick={() => this.props.history.push(`/organizer`)}
                category={'secondary'}
                fontWeight={800}
                full>
                Ok!
              </Button>
            </Modal>
            <Modal isOpen={this.state.todosAprovados} >
              <MoedaIcon />
              <Titulo>Fornecedores aprovados!</Titulo>
              <SubTitulo>Aguarde o número de requisição.</SubTitulo>
              <Button
                onClick={() => this.props.history.push(`/organizer`)}
                category={'secondary'}
                fontWeight={800}
                full>
                Ok!
              </Button>
            </Modal>
            <Modal isOpen={this.state.verMais} onClose={() => this.setState({ verMais: !this.state.verMais })} hasCloseButton>
              <Subtitle>{this.state.verMaisDetalhes}</Subtitle>
            </Modal>
            <Modal isOpen={this.state.modalVerificacao} >
              <AlertIcon fill={defaultTheme.colors.secondary.dark} width={'150px'}/>
              <Titulo style={{ lineHeight: 2 }}>Atenção!</Titulo>
              <SubTitulo style={{ color: defaultTheme.colors.grey.medium }}>{temFeatureFlag('approval') ? 'Tem certeza que deseja enviar este fornecedor para aprovação' : 'Tem certeza que deseja contratar este fornecedor'} <span style={{ fontWeight: 600 }}>em definitivo</span>, com os itens escolhidos? Uma vez realizada esta ação, <span style={{ fontWeight: 600 }}>não é possível voltar atrás</span></SubTitulo>
              <Group style={{ display: 'flex', padding: '20px 0 0 0' }}>
                <Button
                  onClick={() => this.setState({ modalVerificacao: false })}
                  category={'secondary'}
                  fontWeight={800}
                  ghost
                  full>
                  Não
                </Button>
                <Button
                  onClick={() => this.submit()}
                  category={'secondary'}
                  fontWeight={800}
                  full>
                  Sim
                </Button>
              </Group>
            </Modal>
            <ModalConfirmacao
              isOpen={this.state.modalTemCerteza}
              onCancel={() => this.setState({ modalTemCerteza: false })}
              onSubmit={this.aprovarFornecedor} />
            <ModalFornecedorAprovado
              isOpen={this.state.modalAprovarFornecedor}
              onSubmit={() => history.push(`/organizer/event/${eventId}/budget/place`)} />
            <Modal isOpen={this.state.modalRecusarFornecedor} onClose={() => this.setState({ modalRecusarFornecedor: !this.state.modalRecusarFornecedor })} hasCloseButton>
              <Icons
                color={defaultTheme.colors.secondary.default}
                width={100}
                height={100}
                style={{ margin: 'auto 1px' }}
                icon='blocked' />
              <Title style={{ paddingTop: '10px' }}>Recusar fornecedor</Title>
              <Subtitle style={{ marginBottom: '0px' }}>Escolha uma das opções a seguir:</Subtitle>
              <CheckBox
                onClick={() => this.setState({ modalRecusarStatus: 'REJEITAR' })}
                checked={this.state.modalRecusarStatus === 'REJEITAR'}
                label={'Rejeitar orçamento'}/>
              <CheckBox
                onClick={() => this.setState({ modalRecusarStatus: 'EDITAR' })}
                checked={this.state.modalRecusarStatus === 'EDITAR'}
                label={'Editar orçamento'}>
                  {this.state.modalRecusarStatus === 'EDITAR' && <Group style={{ textAlign: 'left' }}>
                    <Textarea
                      type={'text'}
                      label={'Justificativa'}
                      isRequired
                      placeholder={'Escreva aqui o motivo.'}
                      name={'justification'}
                      value={this.state.justification}
                      onChange={(input) => this.setState({ justification: input.target.value })} />
                  </Group>}
              </CheckBox>
              <Button
                onClick={this.confirmarRecusarFornecedor}
                category={'secondary'}
                fontWeight={800}
                width={'50%'}
                full>
                Confirmar
              </Button>
            </Modal>
            <Title style={{ marginBottom: '0px', marginTop: '20px' }}>{this.state.hotel.name}</Title>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {DISPLAY_BILLING_CONDITIONS && <LabelBillingConditions>
                {this.state.billingConditions.percentage === 0 ?
                  `Pagamento faturado (${this.state.billingConditions.days} dias após emissão da fatura)` :
                  `Pagamento faturado - ${this.state.billingConditions.percentage}% devem ser pagos antecipadamente, e o restante em até ${this.state.billingConditions.days} dias após a emissão da fatura`}
              </LabelBillingConditions>}
              {DISPLAY_CHAT && <ButtonChat />}
            </div>
            {this.state.transfer.briefing.requests && this.state.transfer.briefing.requests.map(item => this.Solicitacao(item))}
            <Card id={'termos'} style={{ padding: '10px', width: '100%', marginTop: '30px' }}>
              <Title style={{ fontSize: '15px', marginBottom: '0px' }}>Condições gerais para contratação</Title>
              {this.state.transfer.budget.termsAndConditions && this.state.transfer.budget.termsAndConditions.map(termos => (
                <Card>
                  <Title style={{ marginBottom: '0px', fontSize: '0.9em', textTransform: 'uppercase' }}>{getNameFromKey(termos.type)}</Title>
                  <Subtitle style={{ marginBottom: '0px'}}>{termos.description}</Subtitle>
                </Card>
              ))}
            </Card>
            {DISPLAY_CHAT && <Card><SingleChat display={DISPLAY_CHAT} eventName={eventName} eventId={eventId} eventStatus={eventStatus} supplierId={accountId} /></Card>}
          </Content>
        </Body>
      </Main>
    </>
  }

  menuLateralDireito = () => {
    const { history } = this.props
    const { eventId, accountId } = this.props.match.params
    const userType = window.localStorage.getItem('user_type')
    return <FixedDiv>
      <Card style={{ width: '100%' }}>
        <Label style={{ textTransform: 'uppercase' }}>Resumo do Evento <span style={{ float: 'right', paddingRight: '10px' }}>ID:{this.state.event.internalId || this.state.event.friendlyId}</span></Label>
        <Group style={{ padding: '0px' }}>
          <Label style={{ fontWeight: 600, color: '#484848' }}>{this.state.event.name}</Label>
          <Label>Solicitado há {this.state.event.created ? moment().diff(moment(this.state.event.created, 'YYYY-MM-DD[T]HH:mm:ssZ'), 'days') : 0} dias</Label>
          {userType === 'RESELLER' && <Label style={{ paddingBottom: '10px', color: defaultTheme.colors.grey.light, fontWeight: 400 }}>{this.state.event.organizer && `CLIENTE: ${this.state.event.organizer.name}`}</Label>}
          <Group style={{ display: 'flex', padding: '0px'  }}>
            <Icons
              icon={'calendario'}
              color={'gray'}
              width={40}
              height={20}
              style={{ marginRight: '-5px', marginTop: '5px' }} />
            <Label>{formatDate(this.state.event.start)} - {formatDate(this.state.event.end)}</Label>
          </Group>
          <Group style={{ display: 'flex', padding: '0px'  }}>
            <Icons
              icon={'map-pin'}
              color={'gray'}
              width={40}
              height={20}
              style={{ marginRight: '-5px', marginTop: '5px', marginLeft: '2px' }} />
            {this.state.event.location && <Label>{this.state.event.location.address} - {this.state.event.location.city}</Label>}
          </Group>
          {this.state.event.numberOfParticipants > 0 && <Group style={{ display: 'flex', padding: '0px'  }}>
            <Icons
              icon={'pessoas'}
              color={'gray'}
              width={40}
              height={20}
              style={{ marginTop: '5px', marginLeft: '-3px' }} />
            <Label>{this.state.event.numberOfParticipants} pessoas</Label>
          </Group>}
        </Group>
      </Card>
      <Card style={{ width: '100%' }}>
        <Label style={{ textTransform: 'uppercase' }}>Resumo do pedido</Label>
        <Title style={{ fontSize: '14px', marginTop: '20px', marginBottom: '10px' }}>{this.state.hotel.name}</Title>
        <ItemsMenuLateral selected={false}>
          <Group style={{ padding: '0', display: 'flex' }}>
            <Icons
              icon={'bus-outline'}
              width={50}
              height={20}
              color={'#484848'}
              style={{ verticalAlign: 'middle', margin: 'auto', marginRight: '20px' }} />
            <Group style={{ padding: '0', display: 'flex', width: '100%' }}>
              <Label>Transfer</Label>
              <Label style={{ textAlign: 'right', color: defaultTheme.colors.primary.default }}>{formatCurrency(this.pegaValorTotalTransfer())}</Label>
            </Group>
          </Group>
        </ItemsMenuLateral>
        <ItemsMenuLateral selected={false}>
          <Group style={{ padding: '0', display: 'flex' }}>
            <Label>Service Fee</Label>
            <Label style={{ textAlign: 'right', color: defaultTheme.colors.primary.default }}>{formatCurrency(0)}</Label>
          </Group>
        </ItemsMenuLateral>
        <ItemsMenuLateral selected={true}>
          <Group style={{ padding: '0', display: 'flex' }}>
            <Label>Valor total</Label>
            <Money>{formatCurrency(this.pegaValorTotalTransfer(), false)}</Money>
          </Group>
        </ItemsMenuLateral>
        {this.verifaRole('APPROVER') && this.verificaSeFoiSolicitadoAprovacao(accountId) && <>
          <Button
            type='submit'
            category={'secondary'}
            onClick={this.ativaModalTemCerteza}
            fontWeight={800}
            full>
            {'Aprovar fornecedor'}
          </Button>
          <Button
            type='submit'
            category={'secondary'}
            onClick={this.recusarFornecedor}
            fontWeight={800}
            ghost
            red
            full>
            {'Recusar fornecedor'}
          </Button>
        </>}
        {!this.verifaRole('APPROVER') && <>
          <Group style={{ display: 'flex' }}>
            <Checkbox type='checkbox' onClick={() => this.setState({ leuEConcorda: !this.state.leuEConcorda })} />
            <CheckBoxLabel>Li e concordo com a <a href="#termos" style={{ marginLeft: 'auto', fontSize: '16px' }} >política do fornecedor</a></CheckBoxLabel>
          </Group>
          {!this.state.readOnly && <Button
            type='submit'
            category={'secondary'}
            onClick={() => {
              if(!this.verificaSeFoiSelecionadoAoMenosUmVeiculo()) return alert('Preencha ao menos um veículo para continuar.')
              if(!this.verificaSeFoiSelecionadoCoordinator()) return alert('Preencha todas as informações de coordenador/receptivo.')
              if(!this.state.leuEConcorda) return alert('Você precisa concordar com a política do fornecedor para continuar.')
              else return this.setState({ modalVerificacao: true })
            }}
            fontWeight={800}
            full>
            {temFeatureFlag('approval') ? 'Submeter para aprovação' : 'Contratar este fornecedor'}
          </Button>}
        </>}
        <Button
          id={'btnVoltar'}
          type='submit'
          category={'secondary'}
          fontWeight={800}
          ghost
          onClick={() => history.push(`/organizer/event/${eventId}/budget/place`)}
          full>
          {'Voltar'}
        </Button>
      </Card>
    </FixedDiv>
  }

  render() {
    return this.gerenciaResponsividade()
  }

  verificaSeOEventoPertenceAoConsultor = (consultantId, userId) => {
    const loggedUserId = window.localStorage.getItem('user')
    return consultantId !== userId && loggedUserId === consultantId
  }

  fornecedorAlegouDisponibilidade = (briefingId) => {
    return this.pegaRequestPorId(briefingId) && !this.pegaRequestPorId(briefingId).hasAvailability
  }

  loadDataEvent = () => {
    const { api: { base, version } } = config
    const { eventId } = this.props.match.params
    const userType = window.localStorage.getItem('user_type')
    const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
    const endpoint = `${base}${version}${eventEndpoint}/${eventId}/budget/fullInfo`

    return Get(endpoint)
      .then((event) => {
        var lodging = this.state.lodging
        lodging.requested = event.services.lodging.requested
        lodging.choosedSupplier = event.services.lodging.choosedSupplier
        var room = this.state.room
        room.requested = event.services.room.requested
        room.choosedSupplier = event.services.room.choosedSupplier
        var foodsAndBeverages = this.state.foodsAndBeverages
        foodsAndBeverages.requested = event.services.foodsAndBeverages.requested
        foodsAndBeverages.choosedSupplier = event.services.foodsAndBeverages.choosedSupplier
        var equipments = this.state.equipments
        equipments.requested = event.services.equipments.requested
        equipments.choosedSupplier = event.services.equipments.choosedSupplier
        var otherServices = this.state.otherServices
        otherServices.requested = event.services.otherServices.requested
        otherServices.choosedSupplier = event.services.otherServices.choosedSupplier
        this.setState({ event: {
            ...event
          },
          readOnly: this.verificaSeOEventoPertenceAoConsultor(event.consultantId, event.userId),
          isLoading: false,
          lodging,
          room,
          foodsAndBeverages,
          equipments,
          otherServices
        }, () => document.getElementById("btnVoltar").scrollIntoView({ behavior: "smooth" }))
      }).catch(e => {
        this.setState({isLoading: false}, console.error(e))
      })
  }

  loadHistory = () => {
    const { api: { base, version } } = config
    const { eventId, accountId } = this.props.match.params
    const userType = window.localStorage.getItem('user_type')
    const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
    const endpoint = `${base}${version}${eventEndpoint}/${eventId}/counter-proposals/account/${accountId}`

    return Get(endpoint)
    .then(response => {
      response.map(services => services.map(service => {
        service.counterProposals = service.counterProposals.reduce((counterProposals, item) => {
          if(item.requester === 'ORGANIZER' && (item.supplierStatus === 'ACCEPTED' || item.supplierStatus === 'REFUSED')) {
            counterProposals.push(item)
            var duplicata = {
              acceptedDate: item.acceptedDate,
              date: item.acceptedDate || item.date,
              initialPrice: item.initialPrice,
              justification: item.justification,
              requestedPrice: item.requestedPrice,
              requester: 'SUPPLIER',
              duplicata: true,
              supplierStatus: item.supplierStatus,
              valid: item.valid
            }
            counterProposals.push(duplicata)
          } else {
            counterProposals.push(item)
          }
          return counterProposals
        }, []).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
      return service
      })
      )
      this.setState({ counterProposals: response })
    }).catch(e => {
      console.error(e)
    })
  }

  loadDataServices = () => {
    this.setState({isLoading: true})
    const { api: { base, version } } = config
    const { eventId, accountId } = this.props.match.params
    const userType = window.localStorage.getItem('user_type')
    const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
    const endpoint = `${base}${version}${eventEndpoint}/${eventId}/budget/account/${accountId}`
    return Get(endpoint)
      .then(response => {
        const budgets = []
        response.budgets.foodsAndBeverages.mostRecent && Object.keys(response.budgets.foodsAndBeverages.mostRecent.menusBudgets).map((key) => {
          let budget = response.budgets.foodsAndBeverages.mostRecent.menusBudgets[key]
          budget.name = key
          if (budget.budgets.length > 0) {
            budget.budgets = budget.budgets.map((item) => {
              item.selected = item.chosen || false
              return item
            })
          }
          if (budget.budgets.length > 0 && !budget.budgets.some(item => item.chosen)) {
            const valMin = Math.min(...budget.budgets.map(item => item.totalWithoutFee.amount))
            const itemMin = budget.budgets.findIndex(item => item.totalWithoutFee.amount === valMin)
            if(itemMin > -1) budget.budgets[itemMin].selected = true
          }
          Object.keys(response.briefings.foodsAndBeverages).filter(briefingKey => response.briefings.foodsAndBeverages[briefingKey] && response.briefings.foodsAndBeverages[briefingKey].name && response.briefings.foodsAndBeverages[briefingKey].name === key).map((briefingKey) => {
            return budget.briefing = response.briefings.foodsAndBeverages[briefingKey]
          })
          return budgets.push(budget)
        })
        this.setState({
          billingConditions: this.getBillingConditions(response.budgets),
          hotel: response,
          briefing: response.briefings.rooms,
          budget: response.budgets.rooms.mostRecent,
          lodging: response.budgets.lodging.mostRecent ? {
            budgets: response.budgets.lodging.mostRecent.apartmentsBudget,
            budgetId: response.budgets.lodging.mostRecent.id,
            observations: response.budgets.lodging.mostRecent.observations,
            itemsOnApartmentPrice: response.budgets.lodging.mostRecent.itemsOnApartmentPrice,
            includedItems: response.budgets.lodging.mostRecent.includedItems,
            expireDate: response.budgets.lodging.expireDate,
            name: response.name,
            location: response.location,
            start: response.start,
            end: response.end,
            numberOfParticipants: response.numberOfParticipants,
            status: response.budgets.lodging.mostRecent.status,
            commission: response.budgets.lodging.mostRecent.commission
          } : {
            budgets: [],
            budgetId: '',
            includedItems: {}
          },
          room: {
            showItems: [],
            hotel: response,
            briefing: response.briefings.rooms,
            budget: response.budgets.rooms.mostRecent, // Como hoje nós temos mais de um budget de quarto e vamos só mostrar um aqui agora vamos pegar o mais recente
          },
          foodsAndBeverages: response.budgets.foodsAndBeverages.mostRecent ? {
            showItems: [],
            budgets,
            expireDate: response.budgets.foodsAndBeverages.expireDate,
            name: response.name,
            observations: response.budgets.foodsAndBeverages.mostRecent.observations,
            status: response.budgets.foodsAndBeverages.mostRecent.status,
            commission: response.budgets.foodsAndBeverages.mostRecent.commission,
            budgetId: response.budgets.foodsAndBeverages.mostRecent.id
          } : {
            showItems: [],
            budgets: [],
            budgetId: ''
          },
          equipments: {
            showItems: [],
            briefing: response.briefings.equipments,
            budget: response.budgets.equipments.mostRecent
          },
          transfer: {
            showItems: [],
            briefing: response.briefings.transfer,
            budget: response.budgets.transfer.mostRecent
          }
        })
      })
      .catch(e => {
        this.setState({isLoading: false}, console.error(e))
      })
  }
}

export default ResumoTransfer
