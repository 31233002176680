import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import CheckBox from '../../components/ui/CheckBox'
import Input from '../../components/ui/Input'
import MoneyInput from '../../components/ui/MoneyInput'
import Textarea from '../../components/ui/Textarea'
import { defaultTheme } from '../../styles/themes'

const ContentForm = styled.div`
  background-color: #fff;
  padding: 10px;
  margin: 10px 10px;
  min-height: 120px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  border: none;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16),0 0 0 1px rgba(0,0,0,0.08);
`

const FormDiv = styled.div`
  display: ${props => props.isVisible ? 'flex' : 'none'};
  flex-wrap: wrap;
`
const TitleMenu = styled.span`
  font-size: 16px;
  padding: 0 10px;
`

const BtnAdd = styled.span`
  padding: 10px;
  margin-bottom: 10px;
  display: block;
  cursor: pointer;
  color: ${defaultTheme.colors.whiteLabel.primary};
  font-size: 14px;
`

class FormMenu extends Component {
  state = {
    on: false,
    items: []
  }

 componentDidMount() {
    const { data, name } = this.props
    const { enabled, items } = data.menus[name]
    if (items.length === 0) {
      items.push({
        name: '',
        price: {amount: ''},
        details: ''
      })
    }
    if (data) {
      this.setState({on: enabled, items: items})
    }
  }

  toggle = () => {
    this.setState(({ on }) => ({ on: !on }))
  }

  addForm = () => {
    const { items } = this.state
    items.push({
      name: '',
      price: {amount: ''},
      details: ''
    })
    this.setState({ items: items })
  }

  render() {
    const { label, name, isActive } = this.props
    const { on, items } = this.state

    return (
      <CheckBox
        label={label}
        name={`${name}[enabled]`}
        checked={on}
        value={true}
        style={{ display: isActive ? 'block' : 'none' }}
        onClick={this.toggle}>
        <FormDiv isVisible={on}>
          {
            items.map((item, i) => {
              return <MenuForm
                form={item}
                key={i}
                index={i}
                on={on}
                menu={name}/>
            })
          }
          <BtnAdd onClick={this.addForm}>
            Criar um novo cardápio de {label}
          </BtnAdd>
        </FormDiv>
      </CheckBox>
    )
  }
}

class MenuForm extends Component {
  state = {
    name: '',
    price: '',
    details: '',
  }

 componentDidMount() {
    const { name, price, details } = this.props.form
    this.setState({
      name: name,
      price: price.amount,
      details: details
    })
  }

  handleValue = (nameState, e) => {
    const { value } = e.target
    this.setState({ [nameState]: value })
  }

  render() {
    const { index, menu } = this.props
    const { name, price, details } = this.state
    return (
      <ContentForm>
        <TitleMenu>Cardápio {index + 1}</TitleMenu>
        <Input
          type={'text'}
          label={'Nome do cardápio'}
          placeholder={'digite o nome deste cardápio'}
          name={`${menu}[items][${index}][name]`}
          value={name}
          error={!name}
          isRequired
          onChange={(e) => this.handleValue('name', e)}
        />
        <MoneyInput
          type={'text'}
          label={'Valor inicial(por pessoa)'}
          placeholder={'0,00'}
          name={`${menu}[items][${index}][price]`}
          value={price}
          error={!(price > 0)}
          isRequired
          onChange={(e) => this.handleValue('price', e)}
        />
        <Textarea
          label={'Cardápio detalhado'}
          placeholder={'Escreva aqui tudo o que inclui neste cardápio'}
          name={`${menu}[items][${index}][details]`}
          value={details}
          error={!details}
          isRequired
          onChange={(e) => this.handleValue('details', e)}
        />
      </ContentForm>
    )
  }
}

function mapStateToProps (state) {
  const { forms } = state
  const { foodsAndBeverages } = forms
  const { data } = foodsAndBeverages
  return {
    data
  }
}

export default connect(mapStateToProps)(FormMenu)
