/* LIBS */
import React, { Component } from 'react'
import InputMask from 'react-input-mask'
import Modal from '../../../components/Modal'
import Loader from '../../../components/Loader'
import styled from 'styled-components'
import Title from '../../../components/ui/Title'
import Input from '../../../components/ui/Input'
import Button from '../../../components/ui/Button'
import imgEmpresas from '../../../styles/img/admin_img_empresas.svg'

/* API */
import config from '../../../../middleware/config'
import { Post, Put } from '../../../../utils/request'
import { isCnpjValid } from '../../../../utils/validation'


const Group = styled.div`
`

const Img = styled.img`
  width: 100%;
`

class CadastroEmpresa extends Component {
  constructor (props) {
    super()
    this.state = {
      isOpen: false,
      isLoading: false,
      id: (props.empresaInfo && props.empresaInfo.id) || '',
      given_name: (props.empresaInfo && props.empresaInfo.profile &&  props.empresaInfo.profile.given_name) || '',
      full_name: (props.empresaInfo && props.empresaInfo.profile && props.empresaInfo.profile.full_name) || '',
      document: (props.empresaInfo && props.empresaInfo.profile && props.empresaInfo.profile.document) || ''
    }
  }

  componentWillReceiveProps(props) {
    this.setState({
      id: (props.empresaInfo && props.empresaInfo.id) || '',
      given_name: (props.empresaInfo &&  props.empresaInfo.profile &&  props.empresaInfo.profile.given_name) || '',
      full_name: (props.empresaInfo && props.empresaInfo.profile && props.empresaInfo.profile.full_name) || '',
      document: (props.empresaInfo && props.empresaInfo.profile && props.empresaInfo.profile.document) || ''
    })
  }


  submit = () => {
    if(this.isEmpty()) return alert('Preencha todos os campos obrigatórios.')
    if(!isCnpjValid(this.state.document)) return alert('Preencha um CNPJ válido.')
    this.setState({ isLoading: true }, () => {
      const { api: { base, version } } = config
      const createAccount = `${base}${version}/accounts/office-branch${this.state.id && `/${this.state.id}`}`
      if(this.state.id) {
        Put(createAccount, {
          given_name: this.state.given_name,
          full_name: this.state.full_name,
        document: this.state.document
        })
        .then(() => {
          alert('Empresa atualizada com sucesso!')
          this.setState({ isLoading: false })
          this.props.onClose && this.props.onClose()
          window.location.reload()
        })
       .catch(error => {
          alert(error + '. Não conseguimos cadastrar sua empresa. Verifique suas informações! (Atenção: Não é possível cadastrar CNPJs repetidos.)')
          this.setState({ isLoading: false })
       })
      } else {
        Post(createAccount, {
          given_name: this.state.given_name,
          full_name: this.state.full_name,
          document: this.state.document
        })
        .then(() => {
          alert('Empresa criada com sucesso!')
          this.setState({ isLoading: false })
          this.props.onClose && this.props.onClose()
          window.location.reload()
        })
       .catch(error => {
          alert(error + '. Não conseguimos cadastrar sua empresa. Verifique suas informações! (Atenção: Não é possível cadastrar CNPJs repetidos.)')
          this.setState({ isLoading: false })
       })
      }
    })
  }

  isEmpty = () => {
    return !this.state.given_name || !this.state.full_name || !this.state.document
  }

  render () {
    return (
      <Modal maxWidth={'800px'} isOpen={this.props.isOpen} hasCloseButton onClose={() => this.props.onClose && this.props.onClose()}>
        { this.state.isLoading ? <Loader /> : null }
        <Group style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', padding: '20px 0' }}>
          <Group style={{width: '270px', padding: '20px'}}>
            <Img src={imgEmpresas}/>
            <Title style={{marginTop: '10px'}}>Cadastro <br/>de Empresa</Title>
          </Group>
          <Group style={{width: '50%'}}>
            <Input
              isRequired
              label={'Nome da empresa'}
              placeholder='Digite aqui o nome da empresa'
              value={this.state.given_name}
              onChange={(input) => this.setState({ given_name: input.target.value })}
            />
            <Input
              isRequired
              label={'Razão social'}
              placeholder='Digite a razão social'
              value={this.state.full_name}
              onChange={(input) => this.setState({ full_name: input.target.value })}
            />
          <InputMask mask="99.999.999/9999-99"
              value={this.state.document}
              onChange={(input) => this.setState({ document: input.target.value })}>
            {(inputProps) => <Input
              {...inputProps}
              isRequired
              label={'CNPJ'}
              placeholder='00.000.000/0000-00'
            />}
          </InputMask>
            <Button
              category={'secondary'}
              fontWeight={800}
              onClick={this.submit}
              full>
              {this.state.id ? 'Atualizar' : 'Cadastrar'}
            </Button>
          </Group>
        </Group>
      </Modal>
    )
  }
}

export default CadastroEmpresa
