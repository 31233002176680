/* LIBS */
import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
/* UTILS */
import config from '../../../../middleware/config'
import { formatCurrency } from '../../../../utils/money'
import { formatDate } from '../../../../utils/dates'
import { Get, Post } from '../../../../utils/request'
import moment from 'moment'
/* STYLES */
import { defaultTheme, images } from '../../../styles/themes'
/* COMPONENTS */
import Header from '../../Header'
import Loader from '../../../components/Loader'
import Icon from '../../../components/ui/Icons'
import Slider from '../../../components/ui/Slider'
import Card from '../../../components/ui/Card'
import Button from '../../../components/ui/Button'
import Money from '../../../components/ui/Money'
import Icons from '../../../components/ui/Icons'



const RoomIcon = styled(() => (<Icon icon={'room-outline'} />))`
  min-width: 55px;
  color: ${defaultTheme.colors.grey.medium};
`

const Main = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #FFF;
  @media (min-width: 768px) {
    background-color: #FAFAFA;
  }
`
const Content = styled.div`
  min-height: 90vh;
  padding: 10px 20px;
  font-family: ${defaultTheme.fonts.secondary};
  width: 80%;
  font-size: 14px;
`

const TitleBold = styled.h1`
  font-family: ${defaultTheme.fonts.secondary};
  color: ${defaultTheme.colors.text.darker};
  font-weight: 800;
  font-size: 1.2em;
`

const SubTitle = styled.h2`
  color: ${props => props.expired ? defaultTheme.colors.orange.medium : defaultTheme.colors.grey.light};
  font-family: ${defaultTheme.fonts.primary};
  font-size: 0.9em;
  & b {
    font-weight: bold;
  }
`

const Observations = styled.p`
  color: ${defaultTheme.colors.grey.light};
  font-family: ${defaultTheme.fonts.primary};
`


const Background = styled.div`
  ${props => props.background
    ? `background: url(${props.background}) center top no-repeat`
    : `
      background: url(${images.fallbackRooms}) center top no-repeat
      background-repeat:   no-repeat;
      background-position: center center;
      background-size: auto; };
    `}
  background-size: 100%;
  background-color: #fff;
  height: 150px;
  width: 100%;
  cursor: pointer;
`

const InnerCard = styled.div `
  margin-top: 20px;
  width: 100%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  line-height: 1.3;
  color: ${defaultTheme.colors.text.dark};
  position: relative;

  & [type="radio"], & [type="checkbox"] {
    display: none;
}

  & [type="radio"]:checked+label:before,
  & [type="radio"]:not(:checked)+label:before {
      content: "";
      position: absolute;
      right: 10px;
      top: 5px;
      width: 18px;
      height: 18px;
      border: 1px solid #ddd;
      border-radius: 100%;
      background: #fff;
      -webkit-transition: all .2s ease;
      -o-transition: all .2s ease;
      transition: all .2s ease;
  }

  & [type=radio]:checked+label,
  & [type=radio]:not(:checked)+label {
      position: relative;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: #666;
      width: 100%;
      -webkit-transition: all .2s ease;
      -o-transition: all .2s ease;
      transition: all .2s ease;
  }

  & [type=radio]:checked+label:after {
      content: "";
      width: 12px;
      height: 12px;
      background: ${defaultTheme.colors.primary.default};
      position: absolute;
      right: 14px;
      top: 4px;
      border-radius: 100%;
      -webkit-transition: all .2s ease;
      -o-transition: all .2s ease;
      transition: all .2s ease;
  }

`

const BriefingData = styled.ul``
const BriefingInfo = styled.li`
  color: ${defaultTheme.colors.grey.light};
  font-weight: normal;
  font-size: 0.9em;
  & b {
    font-weight: bold;
    color: ${defaultTheme.colors.text.dark};
  }
`

const RoomDetail = styled.div`
  color: ${defaultTheme.colors.grey.light};
  width: 33%;
  font-weight: 100;
  font-size: 0.8em;
`
const BluePrint = (props) => {
  const {url} = props
  return (
    <div onClick={() => window.open(url, "_blank")}>
      <Card style={{cursor: 'pointer', width: 'calc(100% - 30px)'}}>
        <div style={{ width: '95%', display: 'flex', margin: 'auto', justifyContent: 'space-between'}}>
            <div>
              <Icons width={'20px'} height={'20px'} icon={'paperclip'} color={defaultTheme.colors.grey.medium}/>
              <p style={{float: 'right'}}>planta_baixa.pdf</p>
            </div>
          <div><p style={{color: defaultTheme.colors.primary.default}}>ver anexo</p></div>
        </div>
      </Card>
    </div>
  )
}

const MeetingRoomDetails = (props) => {
  const {maxCapacity, roomHeight, footage, bluePrint, name} = props
  return (
    <div style = {{padding: '20px'}}>
      <h3>{name}</h3>
      <div style={{display: 'flex', width: '100%'}}>
        <RoomDetail>Área: <br/>{footage}m²</RoomDetail>
        <RoomDetail>Pé direito: <br/>{roomHeight}m</RoomDetail>
        <RoomDetail>Cap. Máx: <br/>{maxCapacity} pessoas</RoomDetail>
      </div>
      {bluePrint && (<BluePrint url={bluePrint}/>)}
    </div>
  )
}

const RoomCard = (props) => {
  // const photo = props.budget && props.budget.room && props.budget.room.photos && props.budget.room.photos[0]
  const room = props.budget && props.budget.room
  function getMaxCapacity(capacities) {
    return Object.keys(capacities).reduce((maxCap, roomFormat) => {
      return maxCap <= capacities[roomFormat]
        ? capacities[roomFormat]
        : maxCap
    },0)
  }
  if(props.budget.hasAvailability) {
    return <Card style={{ maxWidth: '50%', marginRight: '10px' }}>
      <BriefingData>
        <BriefingInfo><TitleBold>{props.roomFormat}</TitleBold></BriefingInfo>
        <BriefingInfo>Datas: <b>{formatDate(props.startDate)} - {formatDate(props.endDate)}</b></BriefingInfo>
        <BriefingInfo>Montagem 1 dia antes?: <b>{props.mounting ? 'Sim' : 'Não'}</b></BriefingInfo>
        <BriefingInfo>Formato de Sala: <b>{props.roomType}</b></BriefingInfo>
        <BriefingInfo>Número de pessoas: <b>{props.amountOfPeople} pessoas</b></BriefingInfo>
        {props.roomItems.length === 0
          ? ''
          : (<BriefingInfo>Itens adicionais solicitados:: <b>{props.roomItems.join('; ')} </b></BriefingInfo>)}
        <BriefingInfo>Suas observações: <b>{props.observations || 'Sem observações'}</b></BriefingInfo>
        <BriefingInfo>Observações do fornecedor: <b>{props.budget.observations || 'Sem observações'}</b></BriefingInfo>
      </BriefingData>

      <InnerCard>
        {
          props.budget
          ? <Fragment>
              <div style={{  padding: '20px' }}>
                {/* <input type="radio" name="roomselect" id="roomselect" /> */}
                <label for='roomselect'>
                  <Money>{formatCurrency(props.budget.totalPriceWithoutFees.amount, false)}</Money>
                  {/* <p>valor por dia +5% ISS + 10% taxa</p> */}
                  {/* <p> {(props.budget.room.taxes.amount)}% de taxas de ISS e serviço </p> */}
                  <p style={{fontSize: '10px', color: defaultTheme.colors.grey.light, fontWeight: 100}}> Valor total sem taxas (total de: {(props.budget.room && props.budget.room.taxes && props.budget.room.taxes.amount) || 0}% em taxas de ISS e serviço)
                  {props.mounting ? (` + ${formatCurrency(props.budget.mountingPrice.amount)} de montagem` ): ''} </p>
                </label>
              </div>
              {props.budget && props.budget.room && <div style={{
                margin: '0 auto',
                padding: '10px',
                width: 'calc(100% - 20px)',
                background: '#fff'}}>
                <Slider fullScreenImages={props.budget.room.photos}>
                  {props.budget.room.photos.map(photo => {
                    return <div>
                      <Background background={photo} />
                      </div>
                  })}
                </Slider>
              </div>}
              <MeetingRoomDetails
                maxCapacity = {getMaxCapacity(room.capacity)}
                roomHeight={room.rooms_height}
                footage={room.footage}
                bluePrint={room.blue_print}
                name={room.name}
                />
            </Fragment>
          : <p><center> Nenhum orçamento recebido para este pedido de sala </center></p>
        }
      </InnerCard>
    </Card>
  } else {
    return <Card style={{ maxWidth: '50%', marginRight: '10px' }}>
      <BriefingData style={{ opacity: 0.5 }}>
        <BriefingInfo><TitleBold>{props.roomFormat}</TitleBold></BriefingInfo>
        <BriefingInfo>Datas: <b>{formatDate(props.startDate)} - {formatDate(props.endDate)}</b></BriefingInfo>
        <BriefingInfo>Montagem 1 dia antes?: <b>{props.mounting ? 'Sim' : 'Não'}</b></BriefingInfo>
        <BriefingInfo>Formato de Sala: <b>{props.roomType}</b></BriefingInfo>
        <BriefingInfo>Número de pessoas: <b>{props.amountOfPeople} pessoas</b></BriefingInfo>
        {props.roomItems.length === 0
          ? ''
          : (<BriefingInfo>Itens adicionais solicitados:: <b>{props.roomItems.join('; ')} </b></BriefingInfo>)}
        <BriefingInfo>Observação: <b>{props.observations || 'Sem observações'}</b></BriefingInfo>
      </BriefingData>

      <BriefingData style={{ margin: 'auto', display: 'block', width: 'fit-content', paddingTop: '20px' }}>
        <BriefingInfo>Sala indisponível neste hotel</BriefingInfo>
      </BriefingData>
    </Card>
  }
}

const Total = (props) => {
  return (
    <div style = {props.style}>
      <Money style={{margin: 'auto', width: 'fit-content', textAlign: 'center'}}>{props.children}</Money>
      <p style={{
        color: defaultTheme.colors.grey.medium,
        fontFamily: defaultTheme.fonts.primary,
        fontSize: '1.6 em',
        margin: 'auto', width: 'fit-content', textAlign: 'center',
        fontWeight: 100}}>Valor total sem taxas</p>
    </div>
  )
}


const Conclusion = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 30px;
  width: 80%;
  & div  {
    width: 50%;
  }
`

const Group = styled.div`
  display: flex;
`

class RoomEdit extends Component {
  state = {
    hotel: false,
    budget: false,
    briefing: false,
    isLoading: false
  }

  isExpired(date) {return moment(date) <= moment()}

  getBudgetByBriefingId(roomBriefingId) {
    const budget = this.state.budget
    return budget.roomsBudget.find(singleRoomBudget => {
      return singleRoomBudget.roomBriefingId === roomBriefingId
    })
  }
  getBudgetPrice = () => {
    return this.state.budget
      ? this.state.budget.roomsBudget.reduce((total, budget) => total + budget.totalPriceWithoutFees.amount, 0)
      : 0
  }
  componentDidMount() {this.loadData()}
  goBack = (agreed = false) => {
    const { history } = this.props
    const { eventId, accountId } = this.props.match.params
    const { api: { base, version, routes: { events } } } = config
    const endpoint = `${base}${version}/budget/room/${this.state.budget.id}/accept`
    Post(endpoint)
      .then(() => {
        history.push(`/organizer${events}/${eventId}/budget/account/${accountId}`)
      }).catch(err => {
        console.error(err.message)
        this.setState({
          isLoading: false
        })
        alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
      })
  }
  render() {
    const {isLoading, hotel, budget, briefing} = this.state
    const expireDate = budget ? budget.expireDate : undefined
    return (
      <Main>
        {isLoading && <Loader />}
        <Header />
        {hotel && <Content>
          <TitleBold>{hotel.name} </TitleBold>
          {this.isExpired(expireDate)
            ? (<SubTitle expired>esta proposta esta sujeita a disponibilidade</SubTitle>)
            : (<SubTitle >proposta válida até <b>{formatDate(expireDate)}</b></SubTitle>)
          }

          <Card>
            <RoomIcon /> Salas
            <Observations>{budget.observations}</Observations>
          </Card>
          <Group>
            {briefing.rooms.map(roomBriefing => <RoomCard
              {...roomBriefing}
              budget = {this.getBudgetByBriefingId(roomBriefing.id)}
              key={roomBriefing.id} />)}
          </Group>
        </Content>}
        <Conclusion>
          <Total>{formatCurrency(this.getBudgetPrice(), false)}</Total>
          <div><Button full onClick={() => this.goBack(true)}>OK</Button></div>
        </Conclusion>
      </Main>
    )
  }

  loadData = () => {
    this.setState({ isLoading: true })
    const { api: { base, version } } = config
    const { eventId, accountId } = this.props.match.params
    const userType = window.localStorage.getItem('user_type')
    const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
    const endpoint = `${base}${version}${eventEndpoint}/${eventId}/budget/account/${accountId}`
    return Get(endpoint)
      .then(hotel => {
        this.setState({
        isLoading: false,
        hotel,
        briefing: hotel.briefings.rooms,
        budget: hotel.budgets.rooms.mostRecent, // Como hoje nós temos mais de um budget de quarto e vamos só mostrar um aqui agora vamos pegar o mais recente
      }) })
      .catch(e => { this.setState({ isLoading: false }, console.error(e)) })
  }
}

export default RoomEdit
