/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'

/* COMPONENTS */
import Header from '../../Header'
import Button from '../../../components/ui/Button'

/* UI */
import Input from '../../../components/ui/Input'
import Textarea from '../../../components/ui/Textarea'

const Main = styled.div`
  width: 100%;
  padding: 20px 30px;
  background: #FAFAFA;
`
const Coluna = styled.li`
  display: inline-block;
  width: 50%;
`

class Payment extends Component {
  render() {
    return (
      <div style={{ width: '100%' }}>
        <Header />
        <Main>
          <form>
            <Input
              placeholder={'Digite o nome de quem irá faturar'}
              label={'Nome do responsável pelo pagamento'}
              name={'faturamento'}
              isRequired />
            <Input
              placeholder={'Digite a razão social'}
              label={'Razão Social'}
              name={'razao_social'}
              isRequired />
            <Input
              placeholder={'88.888.888/8888-88'}
              label={'CNPJ'}
              name={'cnpj'}
              isRequired />
            <Input
              placeholder={'Qual é o centro de custo?'}
              label={'Centro de Custo'}
              name={'centro_custo'}
              isRequired />
            <Input
              placeholder={'Qual é o pedido de compra?'}
              label={'Pedido de Compra'}
              name={'pedido_compra'} />
            <Textarea
              placeholder={''}
              label={'Requisição'}
              name={'requisicao'}
              isRequired />
            <ul>
              <Coluna>
                <p>R$ 100.000,00</p>
                <p>Valor total com taxas</p>
              </Coluna>
              <Coluna>
                <Button
                  type='button'
                  category={'secondary'}
                  fontWeight={800}
                  full>
                  Adicionar ou remover serviços
                </Button>
              </Coluna>
            </ul>
          </form>
        </Main>
      </div>
    )
  }
}

export default Payment