export const getDocumentVisibleInfo = (_document) => {
  let hidden, state, visibilityChange = null
  _document = _document || document

  if (typeof _document.hidden !== "undefined") {
    hidden = "hidden"
    visibilityChange = "visibilitychange"
    state = "visibilityState"
  } else if (typeof _document.mozHidden !== "undefined") {
    hidden = "mozHidden"
    visibilityChange = "mozvisibilitychange"
    state = "mozVisibilityState"
  } else if (typeof _document.msHidden !== "undefined") {
    hidden = "msHidden"
    visibilityChange = "msvisibilitychange"
    state = "msVisibilityState"
  } else if (typeof _document.webkitHidden !== "undefined") {
    hidden = "webkitHidden"
    visibilityChange = "webkitvisibilitychange"
    state = "webkitVisibilityState"
  }

  return {
    hidden,
    state,
    visibilityChange
  }
}

export const isDocumentVisible = (_document) => {
  const hiddenBrowserInfo = getDocumentVisibleInfo(_document || document)
  return !document[hiddenBrowserInfo.hidden]
}
