/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
/* COMPONENTS */
import GerenciaFluxoEdicao from '../../../../components/GerenciaFluxoEdicao'
import Header from '../../../Header'
import MainDesktop from '../../../../components/Main'
import Body from '../../../../components/Body'
import Button from '../../../../components/ui/Button'
import CheckBox from '../../../../components/ui/CheckBox'
import Card from '../../../../components/ui/Card'
import Icons from '../../../../components/ui/Icons'
import Alert from '../../../../components/ui/Alert'
import InputDatepicker from '../../../../components/InputDatepicker'
import Timepicker from '../../../../components/Timepicker'
import BreadCrumb from '../../../../components/BreadCrumb'
import Input from '../../../../components/ui/Input'
import Select from '../../../../components/ui/Select'
import Textarea from '../../../../components/ui/Textarea'
import Loader from '../../../../components/Loader'
import config from '../../../../../middleware/config'
import { Post, Get, Put } from '../../../../../utils/request'
import { getNameFromKey } from '../../../../../utils/fabTexts'
import { getDateRange, formatDate } from '../../../../../utils/dates'
import { defaultTheme } from '../../../../styles/themes'
import moment from 'moment'
import ServicosSolicitados from '../../../../components/ServicosSolicitados';
import { retornaUrlFluxoEdicao, retornaUrlFluxoCriacao } from '../../../../../utils/fluxoCriacaoEvento';
import { ehChainUser } from '../../../../../utils/tiposUsuario'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #fff;
  & * { font-family: 'Open Sans', sans-serif }
`

const Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  font-family: ${defaultTheme.fonts.secondary};
  padding: 3px 10px 13px 10px;
  color: #484848;
`

const Main = styled.div`
  width: 100%;
  background: #fff;
  flex: 1 0 auto;
`

const Label = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  box-sizing: border-box;
  padding: 5px 5px 5px 0px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
`

const Group = styled.div`
`

const FixedDiv = styled.div`
  position: fixed;
  top: 46px;
  bottom: 0;
  width: 25%;
  overflow-y: scroll;
  margin-top: 14px;
`

class FormFAB extends Component {
  constructor() {
    super()
    this.state = {
      welcomeDrink: {
        selected: false,
        dates: [],
        startTime: '00:00:00',
        amountOfPeople: 100,
        beverages: 'PACK_OF_NON_ALCOHOLIC_BEVERAGE',
        isPrivate: false,
        errorMessage: null,
        errors: {
          dates: '',
          amountOfPeople: ''
        }
      },
      welcomeCoffee: {
        selected: false,
        dates: [],
        startTime: '00:00:00',
        amountOfPeople: 100,
        beverages: null,
        isPrivate: false,
        errorMessage: null,
        errors: {
          dates: '',
          amountOfPeople: ''
        }
      },
      morningCoffeeBreak: {
        selected: false,
        dates: [],
        startTime: '00:00:00',
        amountOfPeople: 100,
        beverages: null,
        isPrivate: false,
        errorMessage: null,
        errors: {
          dates: '',
          amountOfPeople: ''
        }
      },
      brunch: {
        selected: false,
        dates: [],
        startTime: '00:00:00',
        amountOfPeople: 100,
        beverages: null,
        isPrivate: false,
        errorMessage: null,
        errors: {
          dates: '',
          amountOfPeople: ''
        }
      },
      lunch: {
        selected: false,
        dates: [],
        startTime: '00:00:00',
        amountOfPeople: 100,
        beverages: 'PACK_OF_NON_ALCOHOLIC_BEVERAGE',
        isPrivate: false,
        errorMessage: null,
        errors: {
          dates: '',
          amountOfPeople: ''
        }
      },
      afternoonCoffeeBreak: {
        selected: false,
        dates: [],
        startTime: '00:00:00',
        amountOfPeople: 100,
        beverages: null,
        isPrivate: false,
        errorMessage: null,
        errors: {
          dates: '',
          amountOfPeople: ''
        }
      },
      dinner: {
        selected: false,
        dates: [],
        startTime: '00:00:00',
        amountOfPeople: 100,
        beverages: 'PACK_OF_NON_ALCOHOLIC_BEVERAGE',
        isPrivate: false,
        errorMessage: null,
        errors: {
          dates: '',
          amountOfPeople: ''
        }
      },
      thematicDinner: {
        selected: false,
        dates: [],
        startTime: '00:00:00',
        amountOfPeople: 100,
        beverages: 'PACK_OF_NON_ALCOHOLIC_BEVERAGE',
        isPrivate: false,
        theme: '',
        errorMessage: null,
        errors: {
          dates: '',
          amountOfPeople: '',
          theme: ''
        }
      },
      cocktail: {
        selected: false,
        dates: [],
        startTime: '00:00:00',
        amountOfPeople: 100,
        beverages: 'PACK_OF_NON_ALCOHOLIC_BEVERAGE',
        isPrivate: false,
        errorMessage: null,
        errors: {
          dates: '',
          amountOfPeople: ''
        }
      },
      roomService: {
        selected: false,
        dates: [],
        startTime: '00:00:00',
        amountOfPeople: 100,
        beverages: null,
        isPrivate: false,
        items: {
          water: false,
          coffee: false,
          tea: false,
          milk: false,
          juice: false,
          soda: false,
          tray: {
            selected: false,
            cheeseBread: false,
            petitFourCandy: false,
            petitFourSalty: false,
            cake: false,
            cupCakes: false,
            miniSandwich: false,
            fruitPlatter: false
          }
        },
        errorMessage: null,
        errors: {
          dates: '',
          amountOfPeople: ''
        }
      },
      observations: '',
      isLoading: false,
      eventStartDate: '',
      eventEndDate: '',
      eventStatus: '',
      name: '',
      location: {},
      numberOfParticipants: 0,
      services: '',
      salvaEdicao: false
    }
  }

  componentDidMount() {
    if (ehChainUser()) {
      alert('Você não tem permissão para acessar esta página.')
      this.props.history.push(`/organizer`)
    }
    this.setState({ isLoading: true }, () => {
      const { briefing, id } = this.props.match.params
      const { api: { base, version } } = config
      const userType = window.localStorage.getItem('user_type')
      const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
      const newEndpoint = `${base}${version}${eventEndpoint}/${id}`
      Get(newEndpoint)
      .then((response) => {
        this.setState({ isLoading: false })
        if (!response) {
          this.setState({ errorMessage: response.message })
          return Promise.reject(response)
        } else {
          const state = this.state
          state.services = response.services
          state.welcomeDrink.amountOfPeople = response.numberOfParticipants
          state.welcomeDrink.dates = getDateRange(new Date(response.start), new Date(response.end))
          state.welcomeDrink.startTime = '15:00:00'
          state.welcomeCoffee.amountOfPeople = response.numberOfParticipants
          state.welcomeCoffee.dates = getDateRange(new Date(response.start), new Date(response.end))
          state.welcomeCoffee.startTime = '08:00:00'
          state.morningCoffeeBreak.amountOfPeople = response.numberOfParticipants
          state.morningCoffeeBreak.dates = getDateRange(new Date(response.start), new Date(response.end))
          state.morningCoffeeBreak.startTime = '10:00:00'
          state.brunch.amountOfPeople = response.numberOfParticipants
          state.brunch.dates = getDateRange(new Date(response.start), new Date(response.end))
          state.brunch.startTime = '11:00:00'
          state.lunch.amountOfPeople = response.numberOfParticipants
          state.lunch.dates = getDateRange(new Date(response.start), new Date(response.end))
          state.lunch.startTime = '12:00:00'
          state.afternoonCoffeeBreak.amountOfPeople = response.numberOfParticipants
          state.afternoonCoffeeBreak.dates = getDateRange(new Date(response.start), new Date(response.end))
          state.afternoonCoffeeBreak.startTime = '16:00:00'
          state.dinner.amountOfPeople = response.numberOfParticipants
          state.dinner.dates = getDateRange(new Date(response.start), new Date(response.end))
          state.dinner.startTime = '20:00:00'
          state.thematicDinner.amountOfPeople = response.numberOfParticipants
          state.thematicDinner.dates = getDateRange(new Date(response.start), new Date(response.end))
          state.thematicDinner.startTime = '20:00:00'
          state.cocktail.amountOfPeople = response.numberOfParticipants
          state.cocktail.dates = getDateRange(new Date(response.start), new Date(response.end))
          state.cocktail.startTime = '20:00:00'
          state.roomService.amountOfPeople = response.numberOfParticipants
          state.roomService.dates = getDateRange(new Date(response.start), new Date(response.end))
          state.roomService.startTime = response.startTime
          state.eventStartDate = response.start
          state.eventStatus = response.status
          state.eventFriendlyId = response.internalId || response.friendlyId
          state.eventEndDate = response.end
          state.numberOfParticipants = response.numberOfParticipants
          state.name = response.name
          state.location = response.location
          this.setState(state, () => {
            if (briefing) {
              this.fetchBriefing()
            }
          })
        }
      }).catch(err => {
        this.setState({ isLoading: false })
        console.log('Error: ', err)
      })
    })
  }

  fetchBriefing = () => {
    const { id, briefing } = this.props.match.params
    const { api: { base, version } } = config
    const userType = window.localStorage.getItem('user_type')
    const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
    const endpoint = `${base}${version}${eventEndpoint}/${id}/briefing/foodsAndBeverages/${briefing}`
    Get(endpoint)
    .then((response) => {
      this.setState({ observations: response.observations, briefing: response }, () => {
        Object.keys(response).filter(key =>  response[key] && response[key].requests && response[key].requests.length > 0).forEach(key => {
          let state = this.state
          state[response[key].name].selected = true
          state[response[key].name].dates = response[key].requests.reduce((requests, request) => {
            requests.push(new Date(request.start))
            return requests
          }, [])
          state[response[key].name].startTime = response[key].requests[0].startTime
          state[response[key].name].amountOfPeople = response[key].requests[0].amountOfPeople
          state[response[key].name].beverages = response[key].requests[0].beverages
          state[response[key].name].isPrivate = response[key].isPrivate
          state[response[key].name].theme = response[key].theme

          if(response[key].name === 'roomService') {
            state[response[key].name].items.water = response[key].water
            state[response[key].name].items.coffee = response[key].coffee
            state[response[key].name].items.tea = response[key].tea
            state[response[key].name].items.milk = response[key].milk
            state[response[key].name].items.juice = response[key].juice
            state[response[key].name].items.soda = response[key].soda
            if(Object.keys(response[key].tray).filter(trayKey => !!response[key].tray[trayKey]).length > 0) {
              state[response[key].name].items.tray.selected = true
              state[response[key].name].items.tray.cheeseBread = response[key].tray.cheeseBread
              state[response[key].name].items.tray.petitFourCandy = response[key].tray.petitFourCandy
              state[response[key].name].items.tray.petitFourSalty = response[key].tray.petitFourSalty
              state[response[key].name].items.tray.cake = response[key].tray.cake
              state[response[key].name].items.tray.cupCakes = response[key].tray.cupCakes
              state[response[key].name].items.tray.miniSandwich = response[key].tray.miniSandwich
              state[response[key].name].items.tray.fruitPlatter = response[key].tray.fruitPlatter
            }
          }

          this.setState(state)
        })
      })
    }).catch(err => {
      this.setState({ isLoading: false })
      console.log('Error: ', err)
    })
  }

  click = (session) => {
    const state = this.state
    state[session].selected = !state[session].selected
    this.setState(state)
  }

  clickRoomServiceItems = (input) => {
    const { name } = input.target
    const state = this.state
    state.roomService.items[name] = !state.roomService.items[name]
    this.setState(state)
  }

  clickTray = () => {
    const state = this.state
    state.roomService.items.tray.selected = !state.roomService.items.tray.selected
    this.setState(state)
  }

  clickTrayItems = (input) => {
    const { name } = input.target
    const state = this.state
    state.roomService.items.tray[name] = !state.roomService.items.tray[name]
    this.setState(state)
  }

  handlerValue = (input, session) => {
    const state = this.state
    const { name, value } = input.target
    state[session][name] = value
    this.setState(state)
    return state[session][name]
  }

  handlerObservations = (input) => {
    const state = this.state
    const { value } = input.target
    state.observations = value
    this.setState(state)
    return state.observations
  }

  submit = (e) => {
    e && e.preventDefault()
    this.clearErrors()

    if(!Object.keys(this.state).some(key => this.state[key].selected)) return alert('Selecione ao menos uma opção de alimentos e bebidas.')
    if (this.isValid() && this.isValidDates() && this.isValidRoomService()) {
      // if(this.haveDifferentAmount()) {
      //   if (!window.confirm('A quantidade de pessoas é diferente da quantidade de pessoas no evento, deseja prosseguir mesmo assim?')) {
      //     return
      //   }
      // }
      this.setState({
        isLoading: true
      })
      const { api: { base, version } } = config
      const { id, briefing } = this.props.match.params
      const userType = window.localStorage.getItem('user_type')
      const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
      const endpoint = `${base}${version}${eventEndpoint}/${id}/briefing/foodsAndBeverages/`
      const briefingEndpoint = `${base}${version}${eventEndpoint}/${id}/briefing/foodsAndBeverages/${briefing}`
      if(briefing) {
        Put(briefingEndpoint, this.generateJSON())
        .then(() => {
          this.props.history.push(retornaUrlFluxoEdicao(this.state.services, id, 'foodsAndBeverages'))
        }).catch(err => {
          console.error(err.message)
          this.setState({
            isLoading: false
          })
          alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
        })

      } else {
        Post(endpoint, this.generateJSON())
        .then(() => {
          this.props.history.push(retornaUrlFluxoCriacao(this.state.services, id, this.state.eventStatus, 'foodsAndBeverages'))
        }).catch(err => {
          console.error(err.message)
          this.setState({
            isLoading: false
          })
          alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
        })
      }
    } else {
      !this.isValid() && alert('Preencha todos os campos obrigatórios.')
      !this.isValidDates() && alert('O período das datas só pode ser entre as datas do evento (podendo ser um dia antes / um dia depois).')
    }
  }

  generateJSON = () => {
    return {
      ...this.state.briefing,
      observations: this.state.observations,
      welcomeDrink: {
        requests: this.state.welcomeDrink.selected ? this.splitDates(this.state.welcomeDrink) : [],
        isPrivate: false
      },
      welcomeCoffee: {
        requests: this.state.welcomeCoffee.selected ? this.splitDates(this.state.welcomeCoffee) : [],
        isPrivate: false
      },
      morningCoffeeBreak: {
        requests: this.state.morningCoffeeBreak.selected ? this.splitDates(this.state.morningCoffeeBreak) : [],
        isPrivate: false
      },
      brunch: {
        requests: this.state.brunch.selected ? this.splitDates(this.state.brunch) : [],
        isPrivate: false
      },
      lunch: {
        name: 'lunch',
        requests: this.state.lunch.selected ? this.splitDates(this.state.lunch) : [],
        isPrivate: this.state.lunch.isPrivate
      },
      afternoonCoffeeBreak: {
        requests: this.state.afternoonCoffeeBreak.selected ? this.splitDates(this.state.afternoonCoffeeBreak) : [],
        isPrivate: false
      },
      dinner: {
        name: 'dinner',
        requests: this.state.dinner.selected ? this.splitDates(this.state.dinner) : [],
        isPrivate: this.state.dinner.isPrivate
      },
      thematicDinner: {
        requests: this.state.thematicDinner.selected ? this.splitDates(this.state.thematicDinner) : [],
        isPrivate: true,
        theme: this.state.thematicDinner.theme
      },
      roomService: {
        requests: this.state.roomService.selected ? this.splitDates(this.state.roomService) : [],
        isPrivate: false,
        items: this.state.roomService.items
      },
      cocktail: {
        requests: this.state.cocktail.selected ? this.splitDates(this.state.cocktail) : [],
        isPrivate: false
      }
    }
  }

  checkEdicao = (e) => {
    e.preventDefault()
    this.clearErrors()
    if(!Object.keys(this.state).some(key => this.state[key].selected)) return alert('Selecione ao menos uma opção de alimentos e bebidas.')
    if (this.isValid() && this.isValidDates() && this.isValidRoomService()) {
      this.setState({ salvaEdicao: true }, () => this.setState({ salvaEdicao: false }))
    } else {
      !this.isValid() && alert('Preencha todos os campos obrigatórios.')
      !this.isValidDates() && alert('O período das datas só pode ser entre as datas do evento (podendo ser um dia antes / um dia depois).')
    }
  }

  verificaSeAlgumEstaSelecionado = () => {
    return this.state.welcomeDrink.selected ||
      this.state.welcomeCoffee.selected ||
      this.state.morningCoffeeBreak.selected ||
      this.state.brunch.selected ||
      this.state.lunch.selected ||
      this.state.afternoonCoffeeBreak.selected ||
      this.state.dinner.selected ||
      this.state.thematicDinner.selected ||
      this.state.roomService.selected ||
      this.state.cocktail.selected
  }

  splitDates = (session) => {
    return session.dates.map((date) => {
      return {
        start: moment(date, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br').format('YYYY-MM-DD'),
        startTime: session.startTime || '00:00:00',
        amountOfPeople: session.amountOfPeople,
        beverages: session.beverages
      }
    })
  }

  clearErrors = () => {
    Object.keys(this.state)
    .forEach(key => {
      if (key !== 'observations' &&
          key !== 'isLoading' &&
          key !== 'eventStartDate' &&
          key !== 'eventEndDate' &&
          key !== 'numberOfParticipants' &&
          key !== 'services' &&
          key !== 'name' &&
          key !== 'location' &&
          key !== 'eventFriendlyId' &&
          key !== 'salvaEdicao' &&
          key !== 'briefing' &&
          key !== 'eventStatus') {
        const state = this.state
        state[key].errorMessage = null
        state[key].errors = {
          dates: '',
          startTime: '',
          amountOfPeople: '',
          theme: ''
        }
        this.setState(state)
      }
    })
  }

  isValid = () => {
    let isValid = true
    Object.keys(this.state)
    .forEach(key => {
      if (key !== 'observations' &&
          key !== 'isLoading' &&
          key !== 'eventStartDate' &&
          key !== 'eventEndDate' &&
          key !== 'numberOfParticipants' &&
          key !== 'services' &&
          key !== 'name' &&
          key !== 'location' &&
          key !== 'eventFriendlyId' &&
          key !== 'salvaEdicao' &&
          key !== 'briefing' &&
          key !== 'eventStatus' &&
          (this.state[key].dates.length === 0 && this.state[key].selected)) {
        const state = this.state
        state[key].errorMessage = 'Preencha todos os campos obrigatórios.'
        state[key].errors.dates = true
        this.setState(state)
        isValid = false
      }
      else if (key !== 'observations' &&
          key !== 'isLoading' &&
          key !== 'eventStartDate' &&
          key !== 'eventEndDate' &&
          key !== 'numberOfParticipants' &&
          key !== 'services' &&
          key !== 'name' &&
          key !== 'location' &&
          key !== 'eventFriendlyId' &&
          key !== 'salvaEdicao' &&
          key !== 'briefing' &&
          key !== 'eventStatus' &&
          ((!this.state[key].amountOfPeople || this.state[key].amountOfPeople === 0 || this.state[key].amountOfPeople === '0') && this.state[key].selected)) {
        const state = this.state
        state[key].errorMessage = 'Preencha todos os campos obrigatórios.'
        state[key].errors.amountOfPeople = true
        this.setState(state)
        isValid = false
      }
      // else if (key !== 'observations' &&
      //     key !== 'isLoading' &&
      //     key !== 'eventStartDate' &&
      //     key !== 'eventEndDate' &&
      //     key !== 'numberOfParticipants' &&
      //     key !== 'services' &&
      //     key !== 'name' &&
      //     key !== 'location' &&
      //     (this.state[key].startTime === '00:00:00' && this.state[key].selected)) {
      //   const state = this.state
      //   state[key].errorMessage = 'Preencha todos os campos obrigatórios.'
      //   state[key].errors.startTime = true
      //   this.setState(state)
      //   isValid = false
      // }
      // else if (key === 'thematicDinner' &&
      //     (!this.state[key].theme && this.state[key].selected)) {
      //   const state = this.state
      //   state[key].errorMessage = 'Preencha todos os campos obrigatórios.'
      //   state[key].errors.theme = true
      //   this.setState(state)
      //   isValid = false
      // }
      return isValid
    })
    return isValid
  }

  isValidRoomService = () => {
    if(!this.state.roomService.selected) return true
    if (!(this.state.roomService.items.coffee ||
      this.state.roomService.items.juice ||
      this.state.roomService.items.milk ||
      this.state.roomService.items.soda ||
      this.state.roomService.items.tea ||
      this.state.roomService.items.water)) {
      alert('É necessário selecionar pelo menos um item de serviço de sala.')
      return false
    }
    else if (this.state.roomService.items.tray.selected &&
      !(this.state.roomService.items.tray.cake ||
      this.state.roomService.items.tray.cheeseBread ||
      this.state.roomService.items.tray.cupCakes ||
      this.state.roomService.items.tray.fruitPlatter ||
      this.state.roomService.items.tray.miniSandwich ||
      this.state.roomService.items.tray.petitFourSalty ||
      this.state.roomService.items.tray.petitFourCandy)) {
      alert('É necessário selecionar pelo menos um item de bandejas.')
      return false
    } else {
      return true
    }
  }

  haveDifferentAmount = () => {
    let isDifferent = false
    Object.keys(this.state)
    .forEach(key => {
    if (key !== 'observations' &&
          key !== 'isLoading' &&
          key !== 'eventStartDate' &&
          key !== 'eventEndDate' &&
          key !== 'numberOfParticipants' &&
          key !== 'services' &&
          key !== 'name' &&
          key !== 'location' &&
          key !== 'eventFriendlyId' &&
          key !== 'salvaEdicao' &&
          key !== 'briefing' &&
          key !== 'eventStatus' &&
          (this.state[key].amountOfPeople !== this.state.numberOfParticipants && this.state[key].selected)) {
        isDifferent = true
      }
    })

    return isDifferent
  }

  isValidDates = () => {
    let isValid = true
    Object.keys(this.state)
    .forEach(key => {
      if (key !== 'observations' &&
          key !== 'isLoading' &&
          key !== 'eventStartDate' &&
          key !== 'eventEndDate' &&
          key !== 'numberOfParticipants' &&
          key !== 'services' &&
          key !== 'name' &&
          key !== 'location' &&
          key !== 'eventFriendlyId' &&
          key !== 'salvaEdicao' &&
          key !== 'briefing' &&
          key !== 'eventStatus') {
        this.state[key].dates.map((date) => {
          if(!(moment(date, 'YYYY-MM-DD[T]HH:mm:ssZ').diff(moment(this.state.eventStartDate, 'YYYY-MM-DD[T]HH:mm:ssZ'), 'days') >= 0 &&
            moment(date, 'YYYY-MM-DD[T]HH:mm:ssZ').diff(moment(this.state.eventEndDate, 'YYYY-MM-DD[T]HH:mm:ssZ'), 'days') <= 1)) {
            const state = this.state
            state[key].errorMessage = 'O período das datas só pode ser entre as datas do evento (podendo ser um dia antes / um dia depois).'
            state[key].errors.dates = true
            this.setState(state)
            isValid = false
          }
          return isValid
        })
      }
      return isValid
    })
    return isValid
  }

  gerenciaResponsividade = () => {
    if(window.innerWidth < 600) return this.paginaMobile()
    else return this.paginaDesktop()
  }

  paginaMobile = () => {
    const { briefing, id } = this.props.match.params
    const { services} = this.state
    const lodging = services.lodging && services.lodging.requested && (services.lodging.id ? `/organizer/event/lodging/${id}/${services.lodging.id}` : `/organizer/event/lodging/${id}`)
    const room = services.room && services.room.requested && (services.room.id ? `/organizer/event/rooms/${id}/${services.room.id}` : `/organizer/event/rooms/${id}`)
    return (
      <Container>
        <Header />
        {lodging && room && <BreadCrumb options={
            [
              {link: '/organizer', name: 'Home'},
              {link: `/organizer/event/${id}`, name: 'Dados gerais do evento'},
              {link: `/organizer/event/services/${id}`, name: 'O que você deseja contratar para este evento'},
              {link: lodging, name: 'Hospedagem'},
              {link: room, name: 'Salas'},
              {name: 'Alimentos e bebidas'}
            ]
          } />
        }
        {!lodging && room && <BreadCrumb options={
            [
              {link: '/organizer', name: 'Home'},
              {link: `/organizer/event/${id}`, name: 'Dados gerais do evento'},
              {link: `/organizer/event/services/${id}`, name: 'O que você deseja contratar para este evento'},
              {link: room, name: 'Salas'},
              {name: 'Alimentos e bebidas'}
            ]
          } />
        }
        <Main>
          {this.state.isLoading ? <Loader /> : null}
          <Group style={{width: '80%', margin: 'auto'}}>
            <Title>Alimentos e bebidas</Title>
            <CheckBox
              onClick={() => this.click('welcomeDrink')}
              checked={this.state.welcomeDrink.selected}
              label={getNameFromKey('welcomeDrink')}>
              <Group style={{ display: `${this.state.welcomeDrink.selected ? 'block' : 'none'}`}}>
                <InputDatepicker
                  type={'multiple'}
                  label={'Quando?'}
                  placeholder={'Selecione a(s) data(s)'}
                  name={'dates'}
                  dates={ this.state.welcomeDrink.dates }
                  error={ this.state.welcomeDrink.errors.dates }
                  onChange={value => this.handlerValue(value, 'welcomeDrink')}
                  isRequired />
                <Timepicker
                  label={'Horário de início'}
                  name={'startTime'}
                  placeholder={'Selecione a hora'}
                  value={ this.state.welcomeDrink.startTime }
                  error={ this.state.welcomeDrink.errors.startTime }
                  onChange={input => this.handlerValue(input, 'welcomeDrink')} />
                <Input
                  type={'number'}
                  name={'amountOfPeople'}
                  label={'Quantas pessoas?'}
                  value={ this.state.welcomeDrink.amountOfPeople }
                  error={ this.state.welcomeDrink.errors.amountOfPeople }
                  placeholder={'Número de pessoas'}
                  onChange={input => this.handlerValue(input, 'welcomeDrink')}
                  isRequired />
                <Select
                  label="Tipo de bebida"
                  name={'beverages'}
                  isRequired
                  value={ this.state.welcomeDrink.beverages }
                  onChange={input => this.handlerValue(input, 'welcomeDrink')}>
                  <option value="PACK_OF_NON_ALCOHOLIC_BEVERAGE">Bebidas não alcoólicas</option>
                  <option value="PACK_OF_NON_ALCOHOLIC_AND_ALCOHOLIC_BEVERAGE">Bebidas não alcoólicas e alcoólicas</option>
                  <option value="PACK_OF_ALCOHOLIC_BEVERAGE">Bebidas alcoólicas</option>
                  <option value="DRINKS_ACCORDING_TO_CONSUMPTION">Bebidas conforme consumo</option>
                </Select>
              </Group>
            </CheckBox>
            <CheckBox
              onClick={() => this.click('welcomeCoffee')}
              checked={this.state.welcomeCoffee.selected}
              label={getNameFromKey('welcomeCoffee')}>
              <Group style={{ display: `${this.state.welcomeCoffee.selected ? 'block' : 'none'}`}}>
                <InputDatepicker
                  type={'multiple'}
                  label={'Quando?'}
                  placeholder={'Selecione a(s) data(s)'}
                  name={'dates'}
                  dates={ this.state.welcomeCoffee.dates }
                  error={ this.state.welcomeCoffee.errors.dates }
                  onChange={input => this.handlerValue(input, 'welcomeCoffee')}
                  isRequired />
                <Timepicker
                  label={'Horário de início'}
                  name={'startTime'}
                  placeholder={'Selecione a hora'}
                  value={ this.state.welcomeCoffee.startTime }
                  error={ this.state.welcomeCoffee.errors.startTime }
                  onChange={input => this.handlerValue(input, 'welcomeCoffee')} />
                <Input
                  type={'number'}
                  name={'amountOfPeople'}
                  label={'Quantas pessoas?'}
                  value={ this.state.welcomeCoffee.amountOfPeople }
                  error={ this.state.welcomeCoffee.errors.amountOfPeople }
                  placeholder={'Número de pessoas'}
                  onChange={input => this.handlerValue(input, 'welcomeCoffee')}
                  isRequired />
              </Group>
            </CheckBox>
            <CheckBox
              onClick={() => this.click('morningCoffeeBreak')}
              checked={this.state.morningCoffeeBreak.selected}
              label={getNameFromKey('morningCoffeeBreak')}>
              <Group style={{ display: `${this.state.morningCoffeeBreak.selected ? 'block' : 'none'}`}}>
                <InputDatepicker
                  type={'multiple'}
                  label={'Quando?'}
                  placeholder={'Selecione a(s) data(s)'}
                  name={'dates'}
                  dates={ this.state.morningCoffeeBreak.dates }
                  error={ this.state.morningCoffeeBreak.errors.dates }
                  onChange={input => this.handlerValue(input, 'morningCoffeeBreak')}
                  isRequired />
                <Timepicker
                  label={'Horário de início'}
                  name={'startTime'}
                  placeholder={'Selecione a hora'}
                  value={ this.state.morningCoffeeBreak.startTime }
                  error={ this.state.morningCoffeeBreak.errors.startTime }
                  onChange={input => this.handlerValue(input, 'morningCoffeeBreak')} />
                <Input
                  type={'number'}
                  name={'amountOfPeople'}
                  label={'Quantas pessoas?'}
                  value={ this.state.morningCoffeeBreak.amountOfPeople }
                  error={ this.state.morningCoffeeBreak.errors.amountOfPeople }
                  placeholder={'Número de pessoas'}
                  onChange={input => this.handlerValue(input, 'morningCoffeeBreak')}
                  isRequired />
              </Group>
            </CheckBox>
            <CheckBox
              onClick={() => this.click('brunch')}
              checked={this.state.brunch.selected}
              label={getNameFromKey('brunch')}>
              <Group style={{ display: `${this.state.brunch.selected ? 'block' : 'none'}`}}>
                <InputDatepicker
                  type={'multiple'}
                  label={'Quando?'}
                  placeholder={'Selecione a(s) data(s)'}
                  name={'dates'}
                  dates={ this.state.brunch.dates }
                  error={ this.state.brunch.errors.dates }
                  onChange={input => this.handlerValue(input, 'brunch')}
                  isRequired />
                <Timepicker
                  label={'Horário de início'}
                  name={'startTime'}
                  placeholder={'Selecione a hora'}
                  value={ this.state.brunch.startTime }
                  error={ this.state.brunch.errors.startTime }
                  onChange={input => this.handlerValue(input, 'brunch')} />
                <Input
                  type={'number'}
                  name={'amountOfPeople'}
                  label={'Quantas pessoas?'}
                  value={ this.state.brunch.amountOfPeople }
                  error={ this.state.brunch.errors.amountOfPeople }
                  placeholder={'Número de pessoas'}
                  onChange={input => this.handlerValue(input, 'brunch')}
                  isRequired />
              </Group>
            </CheckBox>
            <CheckBox
              onClick={() => this.click('lunch')}
              checked={this.state.lunch.selected}
              label={getNameFromKey('lunch')}>
              <Group style={{ display: `${this.state.lunch.selected ? 'block' : 'none'}`}}>
                <InputDatepicker
                  type={'multiple'}
                  label={'Quando?'}
                  placeholder={'Selecione a(s) data(s)'}
                  name={'dates'}
                  dates={ this.state.lunch.dates }
                  error={ this.state.lunch.errors.dates }
                  onChange={input => this.handlerValue(input, 'lunch')}
                  isRequired />
                <Timepicker
                  label={'Horário de início'}
                  name={'startTime'}
                  placeholder={'Selecione a hora'}
                  value={ this.state.lunch.startTime }
                  error={ this.state.lunch.errors.startTime }
                  onChange={input => this.handlerValue(input, 'lunch')} />
                <Input
                  type={'number'}
                  name={'amountOfPeople'}
                  label={'Quantas pessoas?'}
                  value={ this.state.lunch.amountOfPeople }
                  error={ this.state.lunch.errors.amountOfPeople }
                  placeholder={'Número de pessoas'}
                  onChange={input => this.handlerValue(input, 'lunch')}
                  isRequired />
                <Select
                  label="Almoço privativo?"
                  name={'isPrivate'}
                  isRequired
                  value={ this.state.lunch.isPrivate }
                  onChange={input => this.handlerValue(input, 'lunch')}>
                  <option value="true">Sim</option>
                  <option value="false">Não</option>
                </Select>
                <Select
                  label="Tipo de bebida"
                  name={'beverages'}
                  isRequired
                  value={ this.state.lunch.beverages }
                  onChange={input => this.handlerValue(input, 'lunch')}>
                  <option value="PACK_OF_NON_ALCOHOLIC_BEVERAGE">Bebidas não alcoólicas</option>
                  <option value="PACK_OF_NON_ALCOHOLIC_AND_ALCOHOLIC_BEVERAGE">Bebidas não alcoólicas e alcoólicas</option>
                  <option value="PACK_OF_ALCOHOLIC_BEVERAGE">Bebidas alcoólicas</option>
                  <option value="DRINKS_ACCORDING_TO_CONSUMPTION">Bebidas conforme consumo</option>
                </Select>
              </Group>
            </CheckBox>
            <CheckBox
              onClick={() => this.click('afternoonCoffeeBreak')}
              checked={this.state.afternoonCoffeeBreak.selected}
              label={getNameFromKey('afternoonCoffeeBreak')}>
              <Group style={{ display: `${this.state.afternoonCoffeeBreak.selected ? 'block' : 'none'}`}}>
                <InputDatepicker
                  type={'multiple'}
                  label={'Quando?'}
                  placeholder={'Selecione a(s) data(s)'}
                  name={'dates'}
                  dates={ this.state.afternoonCoffeeBreak.dates }
                  error={ this.state.afternoonCoffeeBreak.errors.dates }
                  onChange={input => this.handlerValue(input, 'afternoonCoffeeBreak')}
                  isRequired />
                <Timepicker
                  label={'Horário de início'}
                  name={'startTime'}
                  placeholder={'Selecione a hora'}
                  value={ this.state.afternoonCoffeeBreak.startTime }
                  error={ this.state.afternoonCoffeeBreak.errors.startTime }
                  onChange={input => this.handlerValue(input, 'afternoonCoffeeBreak')} />
                <Input
                  type={'number'}
                  name={'amountOfPeople'}
                  label={'Quantas pessoas?'}
                  value={ this.state.afternoonCoffeeBreak.amountOfPeople }
                  error={ this.state.afternoonCoffeeBreak.errors.amountOfPeople }
                  placeholder={'Número de pessoas'}
                  onChange={input => this.handlerValue(input, 'afternoonCoffeeBreak')}
                  isRequired />
              </Group>
            </CheckBox>
            <CheckBox
              onClick={() => this.click('dinner')}
              checked={this.state.dinner.selected}
              label={getNameFromKey('dinner')}>
              <Group style={{ display: `${this.state.dinner.selected ? 'block' : 'none'}`}}>
                <InputDatepicker
                  type={'multiple'}
                  label={'Quando?'}
                  placeholder={'Selecione a(s) data(s)'}
                  name={'dates'}
                  dates={ this.state.dinner.dates }
                  error={ this.state.dinner.errors.dates }
                  onChange={input => this.handlerValue(input, 'dinner')}
                  isRequired />
                <Timepicker
                  label={'Horário de início'}
                  name={'startTime'}
                  placeholder={'Selecione a hora'}
                  value={ this.state.dinner.startTime }
                  error={ this.state.dinner.errors.startTime }
                  onChange={input => this.handlerValue(input, 'dinner')} />
                <Select
                  label="Jantar privativo?"
                  name={'isPrivate'}
                  isRequired
                  value={ this.state.dinner.isPrivate }
                  onChange={input => this.handlerValue(input, 'dinner')}>
                  <option value="true">Sim</option>
                  <option value="false">Não</option>
                </Select>
                <Input
                  type={'number'}
                  name={'amountOfPeople'}
                  label={'Quantas pessoas?'}
                  value={ this.state.dinner.amountOfPeople }
                  error={ this.state.dinner.errors.amountOfPeople }
                  placeholder={'Número de pessoas'}
                  onChange={input => this.handlerValue(input, 'dinner')}
                  isRequired />
                <Select
                  label="Tipo de bebida"
                  name={'beverages'}
                  isRequired
                  value={ this.state.dinner.beverages }
                  onChange={input => this.handlerValue(input, 'dinner')}>
                  <option value="PACK_OF_NON_ALCOHOLIC_BEVERAGE">Bebidas não alcoólicas</option>
                  <option value="PACK_OF_NON_ALCOHOLIC_AND_ALCOHOLIC_BEVERAGE">Bebidas não alcoólicas e alcoólicas</option>
                  <option value="PACK_OF_ALCOHOLIC_BEVERAGE">Bebidas alcoólicas</option>
                  <option value="DRINKS_ACCORDING_TO_CONSUMPTION">Bebidas conforme consumo</option>
                </Select>
              </Group>
            </CheckBox>
            <CheckBox
              onClick={() => this.click('thematicDinner')}
              checked={this.state.thematicDinner.selected}
              label={getNameFromKey('thematicDinner')}>
              <Group style={{ display: `${this.state.thematicDinner.selected ? 'block' : 'none'}`}}>
                <InputDatepicker
                  type={'multiple'}
                  label={'Quando?'}
                  placeholder={'Selecione a(s) data(s)'}
                  name={'dates'}
                  dates={ this.state.thematicDinner.dates }
                  error={ this.state.thematicDinner.errors.dates }
                  onChange={input => this.handlerValue(input, 'thematicDinner')}
                  isRequired />
                <Timepicker
                  label={'Horário de início'}
                  name={'startTime'}
                  placeholder={'Selecione a hora'}
                  value={ this.state.thematicDinner.startTime }
                  error={ this.state.thematicDinner.errors.startTime }
                  onChange={input => this.handlerValue(input, 'thematicDinner')} />
                <Input
                  type={'number'}
                  name={'amountOfPeople'}
                  label={'Quantas pessoas?'}
                  value={ this.state.thematicDinner.amountOfPeople }
                  error={ this.state.thematicDinner.errors.amountOfPeople }
                  placeholder={'Número de pessoas'}
                  onChange={input => this.handlerValue(input, 'thematicDinner')}
                  isRequired />
                <Select
                  label="Tipo de bebida"
                  name={'beverages'}
                  isRequired
                  value={ this.state.thematicDinner.beverages }
                  onChange={input => this.handlerValue(input, 'thematicDinner')}>
                  <option value="PACK_OF_NON_ALCOHOLIC_BEVERAGE">Bebidas não alcoólicas</option>
                  <option value="PACK_OF_NON_ALCOHOLIC_AND_ALCOHOLIC_BEVERAGE">Bebidas não alcoólicas e alcoólicas</option>
                  <option value="PACK_OF_ALCOHOLIC_BEVERAGE">Bebidas alcoólicas</option>
                  <option value="DRINKS_ACCORDING_TO_CONSUMPTION">Bebidas conforme consumo</option>
                </Select>
              </Group>
            </CheckBox>
            <CheckBox
              onClick={() => this.click('cocktail')}
              checked={this.state.cocktail.selected}
              label={getNameFromKey('cocktail')}>
              <Group style={{ display: `${this.state.cocktail.selected ? 'block' : 'none'}`}}>
                <InputDatepicker
                  type={'multiple'}
                  label={'Quando?'}
                  placeholder={'Selecione a(s) data(s)'}
                  name={'dates'}
                  dates={ this.state.cocktail.dates }
                  error={ this.state.cocktail.errors.dates }
                  onChange={input => this.handlerValue(input, 'cocktail')}
                  isRequired />
                <Timepicker
                  label={'Horário de início'}
                  name={'startTime'}
                  placeholder={'Selecione a hora'}
                  value={ this.state.cocktail.startTime }
                  error={ this.state.cocktail.errors.startTime }
                  onChange={input => this.handlerValue(input, 'cocktail')} />
                <Input
                  type={'number'}
                  name={'amountOfPeople'}
                  label={'Quantas pessoas?'}
                  value={ this.state.cocktail.amountOfPeople }
                  error={ this.state.cocktail.errors.amountOfPeople }
                  placeholder={'Número de pessoas'}
                  onChange={input => this.handlerValue(input, 'cocktail')}
                  isRequired />
                <Select
                  label="Tipo de bebida"
                  name={'beverages'}
                  isRequired
                  value={ this.state.cocktail.beverages }
                  onChange={input => this.handlerValue(input, 'cocktail')}>
                  <option value="PACK_OF_NON_ALCOHOLIC_BEVERAGE">Bebidas não alcoólicas</option>
                  <option value="PACK_OF_NON_ALCOHOLIC_AND_ALCOHOLIC_BEVERAGE">Bebidas não alcoólicas e alcoólicas</option>
                  <option value="PACK_OF_ALCOHOLIC_BEVERAGE">Bebidas alcoólicas</option>
                  <option value="DRINKS_ACCORDING_TO_CONSUMPTION">Bebidas conforme consumo</option>
                </Select>
              </Group>
            </CheckBox>
            <CheckBox
              onClick={() => this.click('roomService')}
              checked={this.state.roomService.selected}
              label={getNameFromKey('roomService')}>
              <Group style={{ display: `${this.state.roomService.selected ? 'block' : 'none'}`}}>
                <InputDatepicker
                  type={'multiple'}
                  label={'Quando?'}
                  placeholder={'Selecione a(s) data(s)'}
                  name={'dates'}
                  dates={ this.state.roomService.dates }
                  error={ this.state.roomService.errors.dates }
                  onChange={input => this.handlerValue(input, 'roomService')}
                  isRequired />
                <Timepicker
                  label={'Horário de início'}
                  name={'startTime'}
                  placeholder={'Selecione a hora'}
                  value={ this.state.roomService.startTime }
                  error={ this.state.roomService.errors.startTime }
                  onChange={input => this.handlerValue(input, 'roomService')} />
                <Input
                  type={'number'}
                  name={'amountOfPeople'}
                  label={'Quantas pessoas?'}
                  value={ this.state.roomService.amountOfPeople }
                  error={ this.state.roomService.errors.amountOfPeople }
                  placeholder={'Número de pessoas'}
                  onChange={input => this.handlerValue(input, 'roomService')}
                  isRequired />
                <Group style={{paddingBottom: '5px'}}>
                  <CheckBox
                    label={getNameFromKey('water')}
                    name="water"
                    style={{width: 'auto'}}
                    onClick={this.clickRoomServiceItems}
                    checked={ this.state.roomService.items.water } />
                  <CheckBox
                    label={getNameFromKey('coffee')}
                    name="coffee"
                    style={{width: 'auto'}}
                    onClick={this.clickRoomServiceItems}
                    checked={ this.state.roomService.items.coffee } />
                  <CheckBox
                    label={getNameFromKey('tea')}
                    name="tea"
                    style={{width: 'auto'}}
                    onClick={this.clickRoomServiceItems}
                    checked={ this.state.roomService.items.tea } />
                  <CheckBox
                    label={getNameFromKey('milk')}
                    name="milk"
                    style={{width: 'auto'}}
                    onClick={this.clickRoomServiceItems}
                    checked={ this.state.roomService.items.milk } />
                  <CheckBox
                    label={getNameFromKey('juice')}
                    name="juice"
                    style={{width: 'auto'}}
                    onClick={this.clickRoomServiceItems}
                    checked={ this.state.roomService.items.juice } />
                  <CheckBox
                    label={getNameFromKey('soda')}
                    name="soda"
                    style={{width: 'auto'}}
                    onClick={this.clickRoomServiceItems}
                    checked={ this.state.roomService.items.soda } />
                  <CheckBox
                    label={getNameFromKey('tray')}
                    name="tray"
                    icon={this.state.roomService.items.tray.selected ? 'arrow-close' : 'arrow-open'}
                    iconLabel={this.state.roomService.items.tray.selected ? 'Ver menos' : 'Ver mais'}
                    width="30px"
                    style={{width: 'auto'}}
                    onClick={this.clickTray}
                    checked={ this.state.roomService.items.tray.selected }>
                    {this.state.roomService.items.tray.selected &&
                    <Group>
                      <CheckBox
                        label={getNameFromKey('cheeseBread')}
                        name="cheeseBread"
                        style={{width: 'auto'}}
                        onClick={this.clickTrayItems}
                        checked={ this.state.roomService.items.tray.cheeseBread } />
                      <CheckBox
                        label={getNameFromKey('petitFourCandy')}
                        name="petitFourCandy"
                        style={{width: 'auto'}}
                        onClick={this.clickTrayItems}
                        checked={ this.state.roomService.items.tray.petitFourCandy } />
                      <CheckBox
                        label={getNameFromKey('petitFourSalty')}
                        name="petitFourSalty"
                        style={{width: 'auto'}}
                        onClick={this.clickTrayItems}
                        checked={ this.state.roomService.items.tray.petitFourSalty } />
                      <CheckBox
                        label={getNameFromKey('cake')}
                        name="cake"
                        style={{width: 'auto'}}
                        onClick={this.clickTrayItems}
                        checked={ this.state.roomService.items.tray.cake } />
                      <CheckBox
                        label={getNameFromKey('miniSandwich')}
                        name="miniSandwich"
                        style={{width: 'auto'}}
                        onClick={this.clickTrayItems}
                        checked={ this.state.roomService.items.tray.miniSandwich } />
                      <CheckBox
                        label={getNameFromKey('cupCakes')}
                        name="cupCakes"
                        style={{width: 'auto'}}
                        onClick={this.clickTrayItems}
                        checked={ this.state.roomService.items.tray.cupCakes } />
                      <CheckBox
                        label={getNameFromKey('fruitPlatter')}
                        name="fruitPlatter"
                        style={{width: 'auto'}}
                        onClick={this.clickTrayItems}
                        checked={ this.state.roomService.items.tray.fruitPlatter } />
                    </Group>
                    }
                  </CheckBox>
                </Group>
              </Group>
            </CheckBox>
            <Textarea
              type={'text'}
              label={'Observações'}
              placeholder={'Caso tenha alguma observação, escreva aqui.'}
              name={'observations'}
              value={this.state.observations}
              onChange={this.handlerObservations}
            />
        </Group>
        </Main>
        <Button
          type='submit'
          category={'secondary'}
          fontWeight={800}
          onClick={this.submit}
          full>
          {briefing ? 'Ok' :'Próximo'}
        </Button>
      </Container>
    )
  }

  paginaDesktop = () => {
    const { id, briefing } = this.props.match.params
    const { services} = this.state
    const lodging = services.lodging && services.lodging.requested && (services.lodging.id ? `/organizer/event/lodging/${id}/${services.lodging.id}` : `/organizer/event/lodging/${id}`)
    const room = services.room && services.room.requested && (services.room.id ? `/organizer/event/rooms/${id}/${services.room.id}` : `/organizer/event/rooms/${id}`)
    const listaDeRedirecionamento = JSON.parse(window.localStorage.getItem('redirecionamento_edicao_fluxo'))
    const verificaRedirecionamento = JSON.parse(window.localStorage.getItem('redirecionamento_edicao_salas'))
    return <>
      <Header />
      <MainDesktop>
        <Body {...this.props} pagina={this.state.eventStatus === 'INACTIVE' ? 'create-evento' : 'editar-evento'} menuLateralDireito={this.menuLateralDireito()}>
          <GerenciaFluxoEdicao pagina={'aeb'} eventId={id} briefingId={briefing} form={this.generateJSON()} salvaEdicao={this.state.salvaEdicao} onSubmit={this.submit} />
          <Container>
            {(lodging && room && this.state.eventStatus === 'INACTIVE') && <BreadCrumb options={
                [
                  {link: '/organizer', name: 'Home'},
                  {link: `/organizer/event/${id}`, name: 'Dados gerais do evento'},
                  {link: lodging, name: 'Hospedagem'},
                  {link: room, name: 'Salas'},
                  {name: 'Alimentos e bebidas'}
                ]
              } />
            }
            {(!lodging && room && this.state.eventStatus === 'INACTIVE') && <BreadCrumb options={
                [
                  {link: '/organizer', name: 'Home'},
                  {link: `/organizer/event/${id}`, name: 'Dados gerais do evento'},
                  {link: room, name: 'Salas'},
                  {name: 'Alimentos e bebidas'}
                ]
              } />
            }
            <Main>
              {this.state.isLoading ? <Loader /> : null}
              <Group style={{width: '100%', margin: 'auto'}}>
                <Title style={{ width: '100%', paddingTop: '20px' }}>Alimentos e bebidas</Title>
                {(listaDeRedirecionamento && listaDeRedirecionamento.length > 0) && <Alert type={'edition'} style={{ display: 'flex', marginBottom: '40px' }}>
                  <Icons
                    icon={'exclamation'}
                    color={'white'}
                    width={30}
                    style={{ margin: 'auto' }}
                    height={15} />
                    <p style={{ textAlign: 'justify' }}>
                      Você alterou a <span style={{ fontWeight: 'bold' }}>data</span> e/ou o <span style={{ fontWeight: 'bold' }}>número de pessoas</span> nos dados de seu evento. Acreditamos que você queira validar os dados abaixo para eventualmente alterar este mesmo aspecto também abaixo.
                    </p>
                </Alert>}
                {verificaRedirecionamento && <Alert type={'edition'} style={{ display: 'flex', marginBottom: '40px' }}>
                  <Icons
                    icon={'exclamation'}
                    color={'white'}
                    width={30}
                    height={15} />
                    <p style={{ textAlign: 'justify' }}>
                      Você alterou seu pedido para <span style={{ fontWeight: 'bold' }}>"salas"</span>  Acreditamos que você queira validar os dados abaixo para eventualmente alterar este mesmo aspecto também aqui.
                    </p>
                </Alert>}
                <Group style={{ display: 'flex' }}>
                  <Group style={{ width: '40%' }}>
                    <Card style={{ width: '100%', paddingRight: '30px', height: '100%', fontSize: '15px'}}>
                      <Title style={{ width: '100%', fontSize: '14px', textAlign: 'center' }}>O que deseja para este evento?</Title>
                      <CheckBox
                        onClick={() => this.click('roomService')}
                        checked={this.state.roomService.selected}
                        label={getNameFromKey('roomService')}/>
                      <CheckBox
                        onClick={() => this.click('morningCoffeeBreak')}
                        checked={this.state.morningCoffeeBreak.selected}
                        label={getNameFromKey('morningCoffeeBreak')}/>
                      <CheckBox
                        onClick={() => this.click('afternoonCoffeeBreak')}
                        checked={this.state.afternoonCoffeeBreak.selected}
                        label={getNameFromKey('afternoonCoffeeBreak')}/>
                      <CheckBox
                        onClick={() => this.click('lunch')}
                        checked={this.state.lunch.selected}
                        label={getNameFromKey('lunch')}/>
                      <CheckBox
                        onClick={() => this.click('dinner')}
                        checked={this.state.dinner.selected}
                        label={getNameFromKey('dinner')}/>
                      <CheckBox
                        onClick={() => this.click('welcomeDrink')}
                        checked={this.state.welcomeDrink.selected}
                        label={getNameFromKey('welcomeDrink')}/>
                      <CheckBox
                        onClick={() => this.click('welcomeCoffee')}
                        checked={this.state.welcomeCoffee.selected}
                        label={getNameFromKey('welcomeCoffee')}/>
                      <CheckBox
                        onClick={() => this.click('brunch')}
                        checked={this.state.brunch.selected}
                        label={getNameFromKey('brunch')}/>
                      <CheckBox
                        onClick={() => this.click('thematicDinner')}
                        checked={this.state.thematicDinner.selected}
                        label={getNameFromKey('thematicDinner')}/>
                      <CheckBox
                        onClick={() => this.click('cocktail')}
                        checked={this.state.cocktail.selected}
                        label={getNameFromKey('cocktail')}/>
                    </Card>
                  </Group>
                  <Group style={{ width: '60%' }}>
                    <Group style={{ display: `${this.state.roomService.selected ? 'block' : 'none'}`}}>
                      <Card style={{ width: '100%', marginLeft: '10px' }}>
                        <Title style={{ width: '100%', fontSize: '14px' }}>{getNameFromKey('roomService')}</Title>
                        <InputDatepicker
                          type={'multiple'}
                          label={'Quando?'}
                          placeholder={'Selecione a(s) data(s)'}
                          name={'dates'}
                          dates={ this.state.roomService.dates }
                          error={ this.state.roomService.errors.dates }
                          onChange={input => this.handlerValue(input, 'roomService')}
                          edited={listaDeRedirecionamento.some(item => item === 'start' || item === 'end')}
                          isRequired />
                        <Group style={{ display: 'flex'}}>
                          <Group style={{ width: '50%' }}>
                            <Timepicker
                              label={'Horário de início'}
                              name={'startTime'}
                              placeholder={'Selecione a hora'}
                              value={ this.state.roomService.startTime }
                              error={ this.state.roomService.errors.startTime }
                              onChange={input => this.handlerValue(input, 'roomService')} />
                          </Group>
                          <Group style={{ width: '50%' }}>
                          <Input
                            type={'number'}
                            name={'amountOfPeople'}
                            label={'Quantas pessoas?'}
                            value={ this.state.roomService.amountOfPeople }
                            error={ this.state.roomService.errors.amountOfPeople }
                            placeholder={'Número de pessoas'}
                            onChange={input => this.handlerValue(input, 'roomService')}
                            edited={listaDeRedirecionamento.some(item => item === 'numberOfParticipants')}
                            isRequired />
                          </Group>
                        </Group>
                        <Group style={{paddingBottom: '5px'}}>
                          <CheckBox
                            label={getNameFromKey('water')}
                            name="water"
                            style={{width: 'auto'}}
                            onClick={this.clickRoomServiceItems}
                            checked={ this.state.roomService.items.water } />
                          <CheckBox
                            label={getNameFromKey('coffee')}
                            name="coffee"
                            style={{width: 'auto'}}
                            onClick={this.clickRoomServiceItems}
                            checked={ this.state.roomService.items.coffee } />
                          <CheckBox
                            label={getNameFromKey('tea')}
                            name="tea"
                            style={{width: 'auto'}}
                            onClick={this.clickRoomServiceItems}
                            checked={ this.state.roomService.items.tea } />
                          <CheckBox
                            label={getNameFromKey('milk')}
                            name="milk"
                            style={{width: 'auto'}}
                            onClick={this.clickRoomServiceItems}
                            checked={ this.state.roomService.items.milk } />
                          <CheckBox
                            label={getNameFromKey('juice')}
                            name="juice"
                            style={{width: 'auto'}}
                            onClick={this.clickRoomServiceItems}
                            checked={ this.state.roomService.items.juice } />
                          <CheckBox
                            label={getNameFromKey('soda')}
                            name="soda"
                            style={{width: 'auto'}}
                            onClick={this.clickRoomServiceItems}
                            checked={ this.state.roomService.items.soda } />
                          <CheckBox
                            label={getNameFromKey('tray')}
                            name="tray"
                            icon={this.state.roomService.items.tray.selected ? 'arrow-close' : 'arrow-open'}
                            iconLabel={this.state.roomService.items.tray.selected ? 'Ver menos' : 'Ver mais'}
                            width="30px"
                            style={{width: 'auto'}}
                            onClick={this.clickTray}
                            checked={ this.state.roomService.items.tray.selected }>
                            {this.state.roomService.items.tray.selected &&
                            <Group>
                              <CheckBox
                                label={getNameFromKey('cheeseBread')}
                                name="cheeseBread"
                                style={{width: 'auto'}}
                                onClick={this.clickTrayItems}
                                checked={ this.state.roomService.items.tray.cheeseBread } />
                              <CheckBox
                                label={getNameFromKey('petitFourCandy')}
                                name="petitFourCandy"
                                style={{width: 'auto'}}
                                onClick={this.clickTrayItems}
                                checked={ this.state.roomService.items.tray.petitFourCandy } />
                              <CheckBox
                                label={getNameFromKey('petitFourSalty')}
                                name="petitFourSalty"
                                style={{width: 'auto'}}
                                onClick={this.clickTrayItems}
                                checked={ this.state.roomService.items.tray.petitFourSalty } />
                              <CheckBox
                                label={getNameFromKey('cake')}
                                name="cake"
                                style={{width: 'auto'}}
                                onClick={this.clickTrayItems}
                                checked={ this.state.roomService.items.tray.cake } />
                              <CheckBox
                                label={getNameFromKey('miniSandwich')}
                                name="miniSandwich"
                                style={{width: 'auto'}}
                                onClick={this.clickTrayItems}
                                checked={ this.state.roomService.items.tray.miniSandwich } />
                              <CheckBox
                                label={getNameFromKey('cupCakes')}
                                name="cupCakes"
                                style={{width: 'auto'}}
                                onClick={this.clickTrayItems}
                                checked={ this.state.roomService.items.tray.cupCakes } />
                              <CheckBox
                                label={getNameFromKey('fruitPlatter')}
                                name="fruitPlatter"
                                style={{width: 'auto'}}
                                onClick={this.clickTrayItems}
                                checked={ this.state.roomService.items.tray.fruitPlatter } />
                            </Group>
                            }
                          </CheckBox>
                        </Group>
                      </Card>
                    </Group>
                    <Group style={{ display: `${this.state.morningCoffeeBreak.selected ? 'block' : 'none'}`}}>
                      <Card style={{ width: '100%', marginLeft: '10px' }}>
                        <Title style={{ width: '100%', fontSize: '14px' }}>{getNameFromKey('morningCoffeeBreak')}</Title>
                        <InputDatepicker
                          type={'multiple'}
                          label={'Quando?'}
                          placeholder={'Selecione a(s) data(s)'}
                          name={'dates'}
                          dates={ this.state.morningCoffeeBreak.dates }
                          error={ this.state.morningCoffeeBreak.errors.dates }
                          onChange={input => this.handlerValue(input, 'morningCoffeeBreak')}
                          edited={listaDeRedirecionamento.some(item => item === 'start' || item === 'end')}
                          isRequired />
                        <Group style={{ display: 'flex'}}>
                          <Group style={{ width: '50%' }}>
                            <Timepicker
                              label={'Horário de início'}
                              name={'startTime'}
                              placeholder={'Selecione a hora'}
                              value={ this.state.morningCoffeeBreak.startTime }
                              error={ this.state.morningCoffeeBreak.errors.startTime }
                              onChange={input => this.handlerValue(input, 'morningCoffeeBreak')} />
                          </Group>
                          <Group style={{ width: '50%' }}>
                            <Input
                              type={'number'}
                              name={'amountOfPeople'}
                              label={'Quantas pessoas?'}
                              value={ this.state.morningCoffeeBreak.amountOfPeople }
                              error={ this.state.morningCoffeeBreak.errors.amountOfPeople }
                              placeholder={'Número de pessoas'}
                              onChange={input => this.handlerValue(input, 'morningCoffeeBreak')}
                              edited={listaDeRedirecionamento.some(item => item === 'numberOfParticipants')}
                              isRequired />
                          </Group>
                        </Group>
                      </Card>
                    </Group>
                    <Group style={{ display: `${this.state.afternoonCoffeeBreak.selected ? 'block' : 'none'}`}}>
                      <Card style={{ width: '100%', marginLeft: '10px' }}>
                        <Title style={{ width: '100%', fontSize: '14px' }}>{getNameFromKey('afternoonCoffeeBreak')}</Title>
                        <InputDatepicker
                          type={'multiple'}
                          label={'Quando?'}
                          placeholder={'Selecione a(s) data(s)'}
                          name={'dates'}
                          dates={ this.state.afternoonCoffeeBreak.dates }
                          error={ this.state.afternoonCoffeeBreak.errors.dates }
                          onChange={input => this.handlerValue(input, 'afternoonCoffeeBreak')}
                          edited={listaDeRedirecionamento.some(item => item === 'start' || item === 'end')}
                          isRequired />
                        <Group style={{ display: 'flex'}}>
                          <Group style={{ width: '50%' }}>
                            <Timepicker
                              label={'Horário de início'}
                              name={'startTime'}
                              placeholder={'Selecione a hora'}
                              value={ this.state.afternoonCoffeeBreak.startTime }
                              error={ this.state.afternoonCoffeeBreak.errors.startTime }
                              onChange={input => this.handlerValue(input, 'afternoonCoffeeBreak')} />
                          </Group>
                          <Group style={{ width: '50%' }}>
                            <Input
                              type={'number'}
                              name={'amountOfPeople'}
                              label={'Quantas pessoas?'}
                              value={ this.state.afternoonCoffeeBreak.amountOfPeople }
                              error={ this.state.afternoonCoffeeBreak.errors.amountOfPeople }
                              placeholder={'Número de pessoas'}
                              onChange={input => this.handlerValue(input, 'afternoonCoffeeBreak')}
                              edited={listaDeRedirecionamento.some(item => item === 'numberOfParticipants')}
                              isRequired />
                          </Group>
                        </Group>
                      </Card>
                    </Group>
                    <Group style={{ display: `${this.state.lunch.selected ? 'block' : 'none'}`}}>
                      <Card style={{ width: '100%', marginLeft: '10px' }}>
                        <Title style={{ width: '100%', fontSize: '14px' }}>{getNameFromKey('lunch')}</Title>
                        <Group style={{ display: 'flex'}}>
                          <Group style={{ width: '50%' }}>
                            <InputDatepicker
                              type={'multiple'}
                              label={'Quando?'}
                              placeholder={'Selecione a(s) data(s)'}
                              name={'dates'}
                              dates={ this.state.lunch.dates }
                              error={ this.state.lunch.errors.dates }
                              onChange={input => this.handlerValue(input, 'lunch')}
                              edited={listaDeRedirecionamento.some(item => item === 'start' || item === 'end')}
                              isRequired />
                          </Group>
                          <Group style={{ width: '50%' }}>
                            <Timepicker
                              label={'Horário de início'}
                              name={'startTime'}
                              placeholder={'Selecione a hora'}
                              value={ this.state.lunch.startTime }
                              error={ this.state.lunch.errors.startTime }
                              onChange={input => this.handlerValue(input, 'lunch')} />
                          </Group>
                        </Group>
                        <Group style={{ display: 'flex'}}>
                          <Group style={{ width: '50%' }}>
                            <Input
                              type={'number'}
                              name={'amountOfPeople'}
                              label={'Quantas pessoas?'}
                              value={ this.state.lunch.amountOfPeople }
                              error={ this.state.lunch.errors.amountOfPeople }
                              placeholder={'Número de pessoas'}
                              onChange={input => this.handlerValue(input, 'lunch')}
                              edited={listaDeRedirecionamento.some(item => item === 'numberOfParticipants')}
                              isRequired />
                          </Group>
                          <Group style={{ width: '50%' }}>
                            <Select
                              label="Almoço privativo?"
                              name={'isPrivate'}
                              isRequired
                              value={ this.state.lunch.isPrivate }
                              onChange={input => this.handlerValue(input, 'lunch')}>
                              <option value="true">Sim</option>
                              <option value="false">Não</option>
                            </Select>
                          </Group>
                        </Group>
                        <Select
                          label="Tipo de bebida"
                          name={'beverages'}
                          isRequired
                          value={ this.state.lunch.beverages }
                          onChange={input => this.handlerValue(input, 'lunch')}>
                          <option value="PACK_OF_NON_ALCOHOLIC_BEVERAGE">Bebidas não alcoólicas</option>
                          <option value="PACK_OF_NON_ALCOHOLIC_AND_ALCOHOLIC_BEVERAGE">Bebidas não alcoólicas e alcoólicas</option>
                          <option value="PACK_OF_ALCOHOLIC_BEVERAGE">Bebidas alcoólicas</option>
                          <option value="DRINKS_ACCORDING_TO_CONSUMPTION">Bebidas conforme consumo</option>
                        </Select>
                      </Card>
                    </Group>
                    <Group style={{ display: `${this.state.dinner.selected ? 'block' : 'none'}`}}>
                      <Card style={{ width: '100%', marginLeft: '10px' }}>
                        <Title style={{ width: '100%', fontSize: '14px' }}>{getNameFromKey('dinner')}</Title>
                        <Group style={{ display: 'flex'}}>
                          <Group style={{ width: '50%' }}>
                            <InputDatepicker
                              type={'multiple'}
                              label={'Quando?'}
                              placeholder={'Selecione a(s) data(s)'}
                              name={'dates'}
                              dates={ this.state.dinner.dates }
                              error={ this.state.dinner.errors.dates }
                              onChange={input => this.handlerValue(input, 'dinner')}
                              edited={listaDeRedirecionamento.some(item => item === 'start' || item === 'end')}
                              isRequired />
                          </Group>
                          <Group style={{ width: '50%' }}>
                            <Timepicker
                              label={'Horário de início'}
                              name={'startTime'}
                              placeholder={'Selecione a hora'}
                              value={ this.state.dinner.startTime }
                              error={ this.state.dinner.errors.startTime }
                              onChange={input => this.handlerValue(input, 'dinner')} />
                          </Group>
                        </Group>
                        <Group style={{ display: 'flex'}}>
                          <Group style={{ width: '50%' }}>
                            <Select
                              label="Jantar privativo?"
                              name={'isPrivate'}
                              isRequired
                              value={ this.state.dinner.isPrivate }
                              onChange={input => this.handlerValue(input, 'dinner')}>
                              <option value="true">Sim</option>
                              <option value="false">Não</option>
                            </Select>
                          </Group>
                          <Group style={{ width: '50%' }}>
                            <Input
                              type={'number'}
                              name={'amountOfPeople'}
                              label={'Quantas pessoas?'}
                              value={ this.state.dinner.amountOfPeople }
                              error={ this.state.dinner.errors.amountOfPeople }
                              placeholder={'Número de pessoas'}
                              onChange={input => this.handlerValue(input, 'dinner')}
                              edited={listaDeRedirecionamento.some(item => item === 'numberOfParticipants')}
                              isRequired />
                          </Group>
                        </Group>
                        <Select
                          label="Tipo de bebida"
                          name={'beverages'}
                          isRequired
                          value={ this.state.dinner.beverages }
                          onChange={input => this.handlerValue(input, 'dinner')}>
                          <option value="PACK_OF_NON_ALCOHOLIC_BEVERAGE">Bebidas não alcoólicas</option>
                          <option value="PACK_OF_NON_ALCOHOLIC_AND_ALCOHOLIC_BEVERAGE">Bebidas não alcoólicas e alcoólicas</option>
                          <option value="PACK_OF_ALCOHOLIC_BEVERAGE">Bebidas alcoólicas</option>
                          <option value="DRINKS_ACCORDING_TO_CONSUMPTION">Bebidas conforme consumo</option>
                        </Select>
                      </Card>
                    </Group>
                    <Group style={{ display: `${this.state.welcomeDrink.selected ? 'block' : 'none'}`}}>
                      <Card style={{ width: '100%', marginLeft: '10px' }}>
                        <Title style={{ width: '100%', fontSize: '14px' }}>{getNameFromKey('welcomeDrink')}</Title>
                        <Group style={{ display: 'flex'}}>
                          <Group style={{ width: '50%' }}>
                            <InputDatepicker
                              type={'multiple'}
                              label={'Quando?'}
                              placeholder={'Selecione a(s) data(s)'}
                              name={'dates'}
                              dates={ this.state.welcomeDrink.dates }
                              error={ this.state.welcomeDrink.errors.dates }
                              onChange={value => this.handlerValue(value, 'welcomeDrink')}
                              edited={listaDeRedirecionamento.some(item => item === 'start' || item === 'end')}
                              isRequired />
                          </Group>
                          <Group style={{ width: '50%' }}>
                            <Timepicker
                              label={'Horário de início'}
                              name={'startTime'}
                              placeholder={'Selecione a hora'}
                              value={ this.state.welcomeDrink.startTime }
                              error={ this.state.welcomeDrink.errors.startTime }
                              onChange={input => this.handlerValue(input, 'welcomeDrink')} />
                          </Group>
                        </Group>
                        <Group style={{ display: 'flex'}}>
                          <Group style={{ width: '50%' }}>
                            <Input
                              type={'number'}
                              name={'amountOfPeople'}
                              label={'Quantas pessoas?'}
                              value={ this.state.welcomeDrink.amountOfPeople }
                              error={ this.state.welcomeDrink.errors.amountOfPeople }
                              placeholder={'Número de pessoas'}
                              onChange={input => this.handlerValue(input, 'welcomeDrink')}
                              edited={listaDeRedirecionamento.some(item => item === 'numberOfParticipants')}
                              isRequired />
                          </Group>
                          <Group style={{ width: '50%' }}>
                            <Select
                              label="Tipo de bebida"
                              name={'beverages'}
                              isRequired
                              value={ this.state.welcomeDrink.beverages }
                              onChange={input => this.handlerValue(input, 'welcomeDrink')}>
                              <option value="PACK_OF_NON_ALCOHOLIC_BEVERAGE">Bebidas não alcoólicas</option>
                              <option value="PACK_OF_NON_ALCOHOLIC_AND_ALCOHOLIC_BEVERAGE">Bebidas não alcoólicas e alcoólicas</option>
                              <option value="PACK_OF_ALCOHOLIC_BEVERAGE">Bebidas alcoólicas</option>
                              <option value="DRINKS_ACCORDING_TO_CONSUMPTION">Bebidas conforme consumo</option>
                          </Select>
                          </Group>
                        </Group>
                      </Card>
                    </Group>
                    <Group style={{ display: `${this.state.welcomeCoffee.selected ? 'block' : 'none'}`}}>
                      <Card style={{ width: '100%', marginLeft: '10px' }}>
                        <Title style={{ width: '100%', fontSize: '14px' }}>{getNameFromKey('welcomeCoffee')}</Title>
                        <InputDatepicker
                          type={'multiple'}
                          label={'Quando?'}
                          placeholder={'Selecione a(s) data(s)'}
                          name={'dates'}
                          dates={ this.state.welcomeCoffee.dates }
                          error={ this.state.welcomeCoffee.errors.dates }
                          onChange={input => this.handlerValue(input, 'welcomeCoffee')}
                          edited={listaDeRedirecionamento.some(item => item === 'start' || item === 'end')}
                          isRequired />
                        <Group style={{ display: 'flex'}}>
                          <Group style={{ width: '50%' }}>
                            <Timepicker
                              label={'Horário de início'}
                              name={'startTime'}
                              placeholder={'Selecione a hora'}
                              value={ this.state.welcomeCoffee.startTime }
                              error={ this.state.welcomeCoffee.errors.startTime }
                              onChange={input => this.handlerValue(input, 'welcomeCoffee')} />
                          </Group>
                          <Group style={{ width: '50%' }}>
                            <Input
                              type={'number'}
                              name={'amountOfPeople'}
                              label={'Quantas pessoas?'}
                              value={ this.state.welcomeCoffee.amountOfPeople }
                              error={ this.state.welcomeCoffee.errors.amountOfPeople }
                              placeholder={'Número de pessoas'}
                              onChange={input => this.handlerValue(input, 'welcomeCoffee')}
                              edited={listaDeRedirecionamento.some(item => item === 'numberOfParticipants')}
                              isRequired />
                          </Group>
                        </Group>
                      </Card>
                    </Group>
                    <Group style={{ display: `${this.state.brunch.selected ? 'block' : 'none'}`}}>
                      <Card style={{ width: '100%', marginLeft: '10px' }}>
                        <Title style={{ width: '100%', fontSize: '14px' }}>{getNameFromKey('brunch')}</Title>
                        <InputDatepicker
                          type={'multiple'}
                          label={'Quando?'}
                          placeholder={'Selecione a(s) data(s)'}
                          name={'dates'}
                          dates={ this.state.brunch.dates }
                          error={ this.state.brunch.errors.dates }
                          onChange={input => this.handlerValue(input, 'brunch')}
                          edited={listaDeRedirecionamento.some(item => item === 'start' || item === 'end')}
                          isRequired />
                        <Group style={{ display: 'flex'}}>
                          <Group style={{ width: '50%' }}>
                            <Timepicker
                              label={'Horário de início'}
                              name={'startTime'}
                              placeholder={'Selecione a hora'}
                              value={ this.state.brunch.startTime }
                              error={ this.state.brunch.errors.startTime }
                              onChange={input => this.handlerValue(input, 'brunch')} />
                          </Group>
                          <Group style={{ width: '50%' }}>
                            <Input
                              type={'number'}
                              name={'amountOfPeople'}
                              label={'Quantas pessoas?'}
                              value={ this.state.brunch.amountOfPeople }
                              error={ this.state.brunch.errors.amountOfPeople }
                              placeholder={'Número de pessoas'}
                              onChange={input => this.handlerValue(input, 'brunch')}
                              edited={listaDeRedirecionamento.some(item => item === 'numberOfParticipants')}
                              isRequired />
                          </Group>
                        </Group>
                      </Card>
                    </Group>
                    <Group style={{ display: `${this.state.thematicDinner.selected ? 'block' : 'none'}`}}>
                      <Card style={{ width: '100%', marginLeft: '10px' }}>
                        <Title style={{ width: '100%', fontSize: '14px' }}>{getNameFromKey('thematicDinner')}</Title>
                        <Group style={{ display: 'flex'}}>
                          <Group style={{ width: '50%' }}>
                            <InputDatepicker
                              type={'multiple'}
                              label={'Quando?'}
                              placeholder={'Selecione a(s) data(s)'}
                              name={'dates'}
                              dates={ this.state.thematicDinner.dates }
                              error={ this.state.thematicDinner.errors.dates }
                              onChange={input => this.handlerValue(input, 'thematicDinner')}
                              edited={listaDeRedirecionamento.some(item => item === 'start' || item === 'end')}
                              isRequired />
                          </Group>
                          <Group style={{ width: '50%' }}>
                            <Timepicker
                              label={'Horário de início'}
                              name={'startTime'}
                              placeholder={'Selecione a hora'}
                              value={ this.state.thematicDinner.startTime }
                              error={ this.state.thematicDinner.errors.startTime }
                              onChange={input => this.handlerValue(input, 'thematicDinner')} />
                          </Group>
                        </Group>
                        <Group style={{ display: 'flex'}}>
                          <Group style={{ width: '50%' }}>
                            <Input
                              type={'number'}
                              name={'amountOfPeople'}
                              label={'Quantas pessoas?'}
                              value={ this.state.thematicDinner.amountOfPeople }
                              error={ this.state.thematicDinner.errors.amountOfPeople }
                              placeholder={'Número de pessoas'}
                              onChange={input => this.handlerValue(input, 'thematicDinner')}
                              edited={listaDeRedirecionamento.some(item => item === 'numberOfParticipants')}
                              isRequired />
                          </Group>
                          <Group style={{ width: '50%' }}>
                            <Select
                              label="Tipo de bebida"
                              name={'beverages'}
                              isRequired
                              value={ this.state.thematicDinner.beverages }
                              onChange={input => this.handlerValue(input, 'thematicDinner')}>
                              <option value="PACK_OF_NON_ALCOHOLIC_BEVERAGE">Bebidas não alcoólicas</option>
                              <option value="PACK_OF_NON_ALCOHOLIC_AND_ALCOHOLIC_BEVERAGE">Bebidas não alcoólicas e alcoólicas</option>
                              <option value="PACK_OF_ALCOHOLIC_BEVERAGE">Bebidas alcoólicas</option>
                              <option value="DRINKS_ACCORDING_TO_CONSUMPTION">Bebidas conforme consumo</option>
                            </Select>
                          </Group>
                        </Group>
                      </Card>
                    </Group>
                    <Group style={{ display: `${this.state.cocktail.selected ? 'block' : 'none'}`}}>
                      <Card style={{ width: '100%', marginLeft: '10px' }}>
                        <Title style={{ width: '100%', fontSize: '14px' }}>{getNameFromKey('cocktail')}</Title>
                        <Group style={{ display: 'flex'}}>
                          <Group style={{ width: '50%' }}>
                            <InputDatepicker
                              type={'multiple'}
                              label={'Quando?'}
                              placeholder={'Selecione a(s) data(s)'}
                              name={'dates'}
                              dates={ this.state.cocktail.dates }
                              error={ this.state.cocktail.errors.dates }
                              onChange={input => this.handlerValue(input, 'cocktail')}
                              edited={listaDeRedirecionamento.some(item => item === 'start' || item === 'end')}
                              isRequired />
                          </Group>
                          <Group style={{ width: '50%' }}>
                            <Timepicker
                              label={'Horário de início'}
                              name={'startTime'}
                              placeholder={'Selecione a hora'}
                              value={ this.state.cocktail.startTime }
                              error={ this.state.cocktail.errors.startTime }
                              onChange={input => this.handlerValue(input, 'cocktail')} />
                          </Group>
                        </Group>
                        <Group style={{ display: 'flex'}}>
                          <Group style={{ width: '50%' }}>
                            <Input
                              type={'number'}
                              name={'amountOfPeople'}
                              label={'Quantas pessoas?'}
                              value={ this.state.cocktail.amountOfPeople }
                              error={ this.state.cocktail.errors.amountOfPeople }
                              placeholder={'Número de pessoas'}
                              onChange={input => this.handlerValue(input, 'cocktail')}
                              edited={listaDeRedirecionamento.some(item => item === 'numberOfParticipants')}
                              isRequired />
                          </Group>
                          <Group style={{ width: '50%' }}>
                            <Select
                              label="Tipo de bebida"
                              name={'beverages'}
                              isRequired
                              value={ this.state.cocktail.beverages }
                              onChange={input => this.handlerValue(input, 'cocktail')}>
                              <option value="PACK_OF_NON_ALCOHOLIC_BEVERAGE">Bebidas não alcoólicas</option>
                              <option value="PACK_OF_NON_ALCOHOLIC_AND_ALCOHOLIC_BEVERAGE">Bebidas não alcoólicas e alcoólicas</option>
                              <option value="PACK_OF_ALCOHOLIC_BEVERAGE">Bebidas alcoólicas</option>
                              <option value="DRINKS_ACCORDING_TO_CONSUMPTION">Bebidas conforme consumo</option>
                            </Select>
                          </Group>
                        </Group>
                      </Card>
                    </Group>
                   {this.verificaSeAlgumEstaSelecionado() && <Textarea
                      type={'text'}
                      label={'Observações'}
                      placeholder={'Caso tenha alguma observação, escreva aqui.'}
                      name={'observations'}
                      value={this.state.observations}
                      onChange={this.handlerObservations}
                    />}
                  </Group>
                </Group>
            </Group>
            </Main>
          </Container>
        </Body>
      </MainDesktop>
    </>
  }

  menuLateralDireito = () => {
    const { briefing } = this.props.match.params
    return <FixedDiv>
      <Card style={{ width: '100%' }}>
        <Label style={{ textTransform: 'uppercase' }}>Resumo do Evento <span style={{ float: 'right', paddingRight: '10px' }}>ID:{this.state.eventFriendlyId}</span></Label>
        <Group>
          <Label style={{ fontWeight: 600, color: '#484848' }}>{this.state.name}</Label>
          <Group style={{ display: 'flex' }}>
            <Icons
              icon={'calendario'}
              color={'gray'}
              width={40}
              height={20}
              style={{ marginRight: '-5px', marginTop: '5px' }} />
            <Label>{formatDate(this.state.eventStartDate)} - {formatDate(this.state.eventEndDate)}</Label>
          </Group>
          <Group style={{ display: 'flex' }}>
            <Icons
              icon={'map-pin'}
              color={'gray'}
              width={40}
              height={20}
              style={{ marginRight: '-5px', marginTop: '5px', marginLeft: '2px' }} />
            {this.state.location && <Label>{this.state.location.address} - {this.state.location.city}</Label>}
          </Group>
          <Group style={{ display: 'flex' }}>
            <Icons
              icon={'pessoas'}
              color={'gray'}
              width={40}
              height={20}
              style={{ marginTop: '5px', marginLeft: '-3px' }} />
            <Label>{this.state.numberOfParticipants} pessoas</Label>
          </Group>
        </Group>
      </Card>
      <Card style={{ width: '100%' }}>
        <ServicosSolicitados services={this.state.services} selected={'foodsAndBeverages'} />
        <Button
          type='submit'
          category={'secondary'}
          fontWeight={800}
          onClick={this.state.eventStatus === 'INACTIVE' ? this.submit : this.checkEdicao}
          full>
          {briefing ? 'Ok' :'Próximo'}
        </Button>
      </Card>
    </FixedDiv>
  }

  render() {
    return this.gerenciaResponsividade()
  }
}

export default FormFAB
