/* LIBS */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
/* ACTIONS */
import { logoutUser } from '../../redux/actions/auth'
/* STYLE */
import styled from 'styled-components'
import { defaultTheme } from '../styles/themes'
import Icons from '../components/ui/Icons'
/* UI */
import LogoEvnts from './ui/LogoEvnts'
import DropdownServicos from './DropdownServicos'
import NotificationMenu from './NotificationMenu'
import ListagemNotificacoes from '../components/ListagemNotificacoes'
import { forneceServico, TRANSFER, OTHER_SERVICES } from '../../utils/servicosFornecedor'

const HeaderStyled = styled.header`
  max-height: 70px;
  align-items: center;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  font-size: 22pt;
  background-color: ${defaultTheme.colors.secondary.default};
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 13px;
  width: 100%;
`
const LogoWrap = styled.div`
  cursor: pointer;
`

const LogoWrapHover = styled.div`
  cursor: pointer;
  &:hover {
    background-color: #631b1d;
  }
`

const SpanLink = styled.span`
  @media(max-width: 650px) {
    display: none;
  }
`

const GroupIcon = styled.div`
  display: flex;
  align-items: flex-end;
  color: white;
  font-size: 15px;
  padding: 18px;
  @media(max-width: 768px) {
      padding: 18px 5px;
  }
`

const GroupItensMenu = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  width: 70%;
    @media(max-width: 650px) {
      width: 30%;
  }
`

class Header extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showListagemNotificacoes: false
    }
  }

  montaMenu = () => {
    if (forneceServico(TRANSFER)) {
      return (
        <LogoWrapHover>
          <Link to='/transfer/add' style={{ textDecoration: 'none' }}>
            <GroupIcon>
              <Icons
                viewBox='0 0 1280 1024'
                icon='bus-outline'
                color='white'
                width={30}
                height={20}
              />
              <SpanLink>Transfer</SpanLink>
            </GroupIcon>
          </Link>
        </LogoWrapHover>
      )
    }
    if (forneceServico(OTHER_SERVICES)) { return '' }

    return <DropdownServicos {...this.props} />
  }

  showListagem = () => {
    this.setState({ showListagemNotificacoes: !this.state.showListagemNotificacoes })
  }

  render () {
    return (
      <HeaderStyled>
        <LogoWrap>
          <Link to='/'><LogoEvnts color={'#fff'} style={{ width: '90px' }} /></Link>
        </LogoWrap>
        <GroupItensMenu>
          <LogoWrapHover>
            <Link to='/company/edit' style={{ textDecoration: 'none' }}>
              <GroupIcon>
                <Icons
                  viewBox='0 0 1536 1024'
                  icon='camera'
                  color='white'
                  width={30}
                  height={20}
                />
                <SpanLink>Foto de Capa</SpanLink>
              </GroupIcon>
            </Link>
          </LogoWrapHover>
          <LogoWrapHover>
            <Link to='/users' style={{ textDecoration: 'none' }}>
              <GroupIcon>
                <Icons
                  viewBox='0 0 1536 1024'
                  icon='person-outline'
                  color='white'
                  width={30}
                  height={20}
                />
                <SpanLink>Usuários</SpanLink>
              </GroupIcon>
            </Link>
          </LogoWrapHover>
          {this.montaMenu()}
        </GroupItensMenu>
        <LogoWrap style={{ display: 'flex', alignItems: 'center' }}>
          <NotificationMenu showListagem={this.showListagem} />
          <ListagemNotificacoes onClose={() => this.setState({ showListagemNotificacoes: false })} isOpen={this.state.showListagemNotificacoes} />
          <Icons
            color='white'
            height={25}
            icon='sign-out'
            onClick={() => { this.props.logoutUser() }}
            title='Sair'
            width={30}
          />
        </LogoWrap>
        {/* <Navbar {...this.props}/> */}
      </HeaderStyled>
    )
  }
}

const mapStateToProps = state => ({
  isLogged: state.auth.isLogged
})

export default connect(mapStateToProps, { logoutUser })(Header)
