import React, { Component } from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../../styles/themes'
import { formatCurrency, removeCurrency } from '../../../utils/money'

const InputText = styled.input`
  font-size: 16px;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  color: #767676;
  background-color: #FFF;
  border-radius: 1px;
  width: ${props => props.type_placeholder === 'side' ? '60%' : '100%'};
  padding: 6px 10px;
  height: 36px;
  box-sizing: border-box;
  border-top: 1px solid #CCC;
  border-right: 1px solid #CCC;
  border-left: 1px solid #CCC;
  border-bottom: ${props => props.error && !props.cortesia ? '2px solid red' : `2px solid ${defaultTheme.colors.primary.default}`};
  transition: all 0.2s linear;
  padding-right:${props => props.cortesia && '100px'};
`

const Label = styled.label`
  color: ${props => props.error && !props.cortesia ? 'red' : '#484848'};
  box-sizing: border-box;
  padding: 5px 5px 5px 0px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
  `

const InputControl = styled.div`
  padding: 10px 10px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: ${props => props.type_placeholder === 'side' ? 'nowrap' : 'wrap'};
  align-items: center;
`
const Strong = styled.strong`
  color: ${props => props.error && !props.cortesia ? 'red' : defaultTheme.colors.whiteLabel.primary};
  font-weight: bold;
`

const Group = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`

const LeftBox = styled.div`
  background: ${props => props.leftBoxNoBg ? 'unset' : '#e8e8e8'};
  width: auto;
  padding: 0 10px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${props => props.leftBoxNoBg ? 'unset' : '1px solid #e8e8e8'};
  font-size: 16px;
  font-family: "Open Sans",sans-serif;
  font-weight: 300;
  color: #727272;
`

const EditedValue = styled.label`
  box-sizing: border-box;
  color: #767676;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-style: italic;
  font-weight: 300;
  min-height: 25px;
  padding: 10px 0px;
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
`

const Cortesia = styled.input`
`

const TextoCortesia = styled.p`
  color: #484848;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  `

export default class MoneyInput extends Component {
  constructor(props) {
    super()
    this.state = {
      value: !props.criticalUpdate ? props.value : '',
      criticalPreviouslyValue: props.criticalPreviouslyValue,
      verificaSeFoiModificado: false,
      cortesia: props.statusCortesia
    }
  }

  UNSAFE_componentWillReceiveProps (props) {
    if(props.value !== this.state.criticalPreviouslyValue && !this.state.verificaSeFoiModificado) {
      this.setState({ value: props.value, verificaSeFoiModificado: true })
    } else if(this.state.verificaSeFoiModificado) {
      this.setState({ value: props.value })
    }
  }

  handleNumber = (input) => {
    if (input.target.type === 'number') {
      var invalidChars = [
        '-',
        '+',
        'e',
        'E'
      ]
      if (invalidChars.includes(input.key)) {
        input.preventDefault();
      }
    }
    if (this.props.onChange) {
      var inputNovo = input
      inputNovo.target.value = removeCurrency(input.target.value)
      return this.props.onChange(inputNovo)
    }
  }

  handlerAmount = (e) => {
    e.persist()
    const passingEvent = e
    passingEvent.target.value = removeCurrency(passingEvent.target.value)
    this.props.onChange(passingEvent)
  }

  render() {
    const id = this.props.id || this.props.name
    return (
      <InputControl isDisabled={this.props.disabled} style={this.props.containerStyle || this.props.style} placeholder={this.props.placeholder}>
        {this.props.label && <Label cortesia={this.state.cortesia} theme={defaultTheme} htmlFor={id} error={this.props.error} type_placeholder={this.props.type_placeholder}>{this.props.label}
          <Strong theme={defaultTheme} error={this.props.error} cortesia={this.state.cortesia}> {this.props.isRequired ? '*' : ''}</Strong>
        </Label>}
        <Group>
          <LeftBox leftBoxNoBg={this.props.leftBoxNoBg}>R$</LeftBox>
          <InputText {...this.props} cortesia={this.state.cortesia} disabled={this.state.cortesia} value={this.state.cortesia ? formatCurrency(0, false) : (this.props.value !== '' ? formatCurrency(this.props.value, false): this.props.value)} onChange={this.handlerAmount}  theme={defaultTheme} onKeyDown={this.handleNumber} id={id} />
          {this.props.ativarCortesia && <Group style={{ position: 'absolute', top: '11px', left: 'calc(100% - 89px)', right: 'auto' }}>
            <Cortesia checked={this.props.statusCortesia} onChange={() => this.setState({ cortesia: !this.state.cortesia }, () => this.props.ativouCortesia && this.props.ativouCortesia(this.state.cortesia))} type={'checkbox'} name={'cortesia'} />
             <TextoCortesia style={{ marginTop: '-5px', marginLeft: '5px' }}>cortesia</TextoCortesia>
          </Group>}
        </Group>
        {this.props.criticalUpdate && <EditedValue htmlFor={id} >Anterior: {formatCurrency(this.state.criticalPreviouslyValue)}</EditedValue>}
      </InputControl>
    )
  }
}
