/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
/* API */
import config from '../../../../middleware/config'
/* UTILS */
import { formatCurrency } from '../../../../utils/money'
import { formatDate } from '../../../../utils/dates'
import { ehConsultor, temFeatureFlag } from '../../../../utils/tiposUsuario'
import { Get, Post, GetCsv } from '../../../../utils/request'
import { getNameFromKey } from '../../../../utils/fabTexts'
import { getNameFromKey as transferText } from '../../../../utils/transferTexts'

/* COMPONENTS */
import Header from '../../Header'
import MainDesktop from '../../../components/Main'
import Body from '../../../components/Body'
import Button from '../../../components/ui/Button'
import ItemsMenuLateral from '../../../components/SidebarItems/ItemsMenuLateral'
import Card from '../../../components/ui/Card'
import Icons from '../../../components/ui/Icons'
import BreadCrumb from '../../../components/BreadCrumb'
import Options from '../../../components/ui/Options'
import ButtonIconExtra from '../../../components/ui/ButtonIconExtra'
import ButtonIcon from '../../../components/ui/ButtonIcon'
import Money from '../../../components/ui/Money'
import MoedaIcon from '../../../components/ui/MoedaIcon'
import AlertIcon from '../../../components/ui/AlertIcon'
import Subtitle from '../../../components/ui/Subtitle'
import Loader from '../../../components/Loader'
import Modal from '../../../components/Modal'
import Map from '../../../components/Map'
import {defaultTheme} from '../../../styles/themes'
import moment from 'moment'
import CondicoesGerais from '../CondicoesGerais'
import AddressText from '../../../components/AddressText'
import ButtonChat from '../../../components/ui/ButtonChat'
import ChatRoom from '../../../components/Chat'

const Container = styled.div`
  min-height: 100vh;
  height: 100%;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #fff;
  font-family: ${defaultTheme.fonts.primary};
`

const ModalTitle = styled.h3`
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 15px 0;
`

const Main = styled.div`
  width: 100%;
  padding: 10px;
  height: 99%;
  background: #fff;
`

const Titulo = styled.h1`
  color: #333;
  font-family: ${defaultTheme.fonts.secondary};
  font-size: 1.3em;
  font-weight: 800;
  line-height: 1.6;
  margin-top: 8px;
`

const SubTitulo = styled.h2`
  color: #ccc;
  font-family: ${defaultTheme.fonts.primary};
  line-height: 1.6;
  padding: 10px 10px 0 10px;
`

const Total = styled.p`
  font-weight: 300;
  color: ${defaultTheme.colors.primary.default};
`

const Conclusion = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 30px;
  width: 80%;
  & div  {
    width: 50%;
  }
`

const ListaInfo = styled.ul`
  text-align: left;
  & li{
    padding: 5px;
    & strong {
      font-weight: bold;
    }
  }
`

const Label = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  box-sizing: border-box;
  padding: 5px 5px 5px 0px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
`

const FixedDiv = styled.div`
  position: fixed;
  top: 46px;
  bottom: 0;
  width: 25%;
  overflow-y: scroll;
  margin-top: 15px;
`

const Group = styled.div`
  font-size: 15px;
  font-family: ${defaultTheme.fonts.secondary};
  color: ${defaultTheme.colors.text.darker};
`

const Title = styled.h1`
  font-family: ${defaultTheme.fonts.secondary};
  line-height: 1.2;
  font-size: 1.3em;
  margin-bottom: 20px;
`

const Link = styled.a`
  font-size: 15px;
  cursor: pointer;
  font-family: ${defaultTheme.fonts.primary};
  text-decoration: underline;
  font-weight: 300;
  color: ${defaultTheme.colors.primary.default};
`

const Tag = styled.div`
  cursor: pointer;
  padding: 2px 3px;
  font-size: 10px;
  font-family: ${defaultTheme.fonts.primary};
  color: ${props => props.status === 'COMISSIONAMENTO' ? '#484848' : '#FAFAFA'};
  background-color: ${props => props.status === 'REVIEW' ? '#F39C12' : (props.status === 'COMISSIONAMENTO' ? '#E8E8E8' : '#009688')};
  border: none;
  border-radius: 15px;
  position: absolute;
  text-transform: uppercase;
  z-index: 9;
`

function formatTime(time) {
  time = time.split(':')
  if(time[0] === '00' && time[1] === '00') return 'Indefinido'
  return `${time[0]}:${time[1]}h`
}

class ResumoTotal extends Component {
  state = {
    event: {},
    lodging: {},
    room: {},
    foodsAndBeverages: {},
    equipments: {},
    transfer: {},
    otherServices: {},
    isLoading: false,
    showEventInfo: false,
    finishedOrder: false,
    todosSolicitados: false,
    condicoesModal: false,
    modalVerificacao: false,
    paginaAtual: 'v2'
  }

  componentDidMount() {this.fetchbudgets()}

  fetchbudgets = () => {
    this.setState({ isLoading: true })
    const { api: { base, version } } = config
    const { eventId, accountId } = this.props.match.params
    const userType = window.localStorage.getItem('user_type')
    const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
    const endpoint = accountId ? `${base}${version}${eventEndpoint}/${eventId}/budget/fullInfo/${accountId}` : `${base}${version}${eventEndpoint}/${eventId}/budget/fullInfo`

    Get(endpoint)
    .then((event) => {
      this.setState({
        event: event,
        lodging: event.services.lodging,
        room: event.services.room,
        foodsAndBeverages: event.services.foodsAndBeverages,
        equipments: event.services.equipments,
        transfer: event.services.transfer,
        otherServices: event.services.otherServices }, () =>
        this.loadDataServices()
          .then(() => this.setState({ isLoading: false }))
          .then(() => document.getElementById("btnVoltar").scrollIntoView({ behavior: "smooth" }))
          .catch(() => this.setState({ isLoading: false }))
        )
    }).catch(err => {
      this.setState({ isLoading: false })
      console.error(err)
      alert(`Falha ao buscar os dados do evento, favor entrar em contato com nosso suporte`)
    })
  }

  fetchAllbudgets = () => {
    this.setState({ isLoading: true })
    const { api: { base, version } } = config
    const { eventId } = this.props.match.params
    const userType = window.localStorage.getItem('user_type')
    const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
    const endpoint =  `${base}${version}${eventEndpoint}/${eventId}/budget/fullInfo`

    Get(endpoint)
    .then((event) => {
      this.setState({allInfo: event })
    }).catch(err => {
      this.setState({ isLoading: false })
      console.error(err)
      alert(`Falha ao buscar os dados do evento, favor entrar em contato com nosso suporte`)
    })
  }

  finishEvent() {
    this.setState({ isLoading: true, modalVerificacao: false })
    const { api: { base, version, routes: { events } } } = config
    const { eventId } = this.props.match.params
    const { history } = this.props
    const userType = window.localStorage.getItem('user_type')
    const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
    const endpoint = temFeatureFlag('approval') ? `${base}${version}${eventEndpoint}/${eventId}/approval/add-supplier-to-approval` : `${base}${version}${eventEndpoint}/${eventId}/finishOrderWithSupplierForServices` // Muda o status do evento para ordered
    Post(endpoint, temFeatureFlag('approval') ? this.generateJSONAprovacao() : this.generateJSON())
      .then(() => {
        if(this.verificaSeTodosOsServicosForamSelecionados()) return this.setState({isLoading: false, finishedOrder: true })
        if(this.verificaSeTodosOsServicosForamSolicitadosParaAprovacao()) return this.setState({isLoading: false, todosSolicitados: true })
        else return this.setState({ isLoading: false }, () => history.push(`/organizer${events}/${eventId}/budget/place`))
      })
      .catch((error) => {
        console.error(error)
        alert('Ocorreu um problema ao fechar o pedido. Favor entrar em contato através do email: corp@evnts.com.br')
        this.setState({isLoading: false})
      })
  }

  generateJSON = () => {
    const { lodging, room, foodsAndBeverages, equipments, transfer, otherServices } = this.state
    return {
      services: {
        room: room && room.budget && room.budget.id,
        lodging: lodging && lodging.budget && lodging.budget.id,
        foodsAndBeverages: foodsAndBeverages && foodsAndBeverages.budget && foodsAndBeverages.budget.id,
        equipments: equipments && equipments.budget && equipments.budget.id,
        transfer: transfer && transfer.budget && transfer.budget.id,
        otherServices: otherServices && otherServices.budget && otherServices.budget.id
      }
    }
  }

  generateJSONAprovacao = () => {
    const { accountId } = this.props.match.params
    const { lodging, room, foodsAndBeverages, equipments, transfer, otherServices } = this.state
    const result = { services: {} }

    if (room && room.budget) {
      result.services.room = {
        supplierId: accountId,
        budgetId: room.budget.id
      }
    }
    if (lodging && lodging.budget) {
      result.services.lodging = {
        supplierId: accountId,
        budgetId: lodging.budget.id
      }
    }
    if (foodsAndBeverages && foodsAndBeverages.budget) {
      result.services.foodsAndBeverages = {
        supplierId: accountId,
        budgetId: foodsAndBeverages.budget.id
      }
    }
    if (equipments && equipments.budget) {
      result.services.equipments = {
        supplierId: accountId,
        budgetId: equipments.budget.id
      }
    }
    if (transfer && transfer.budget) {
      result.services.transfer = {
        supplierId: accountId,
        budgetId: transfer.budget.id
      }
    }
    if (otherServices && otherServices.budget) {
      result.services.otherServices = {
        supplierId: accountId,
        budgetId: otherServices.budget.id
      }
    }

    return result
  }

  redirect(whereTo) {
    const {history} = this.props
    const { api: { routes: { events } } } = config
    const { eventId } = this.props.match.params

    if (whereTo === 'places') {
      history.push(`/organizer${events}/${eventId}/budget/place`)
    }
  }

  getManagementFee() {
    return this.state.event
      ? this.state.event.managementFee
      : {
        amount: 0,
        type: 'PERCENTAGE'
      }
  }

  getSupplierUserInfo () {
    let data = { name: '', phone: '', email: '' }
    if (!this.state.event.services) return data
    const servicosComOrdemQueQueroPriorizar = ['transfer', 'equipments', 'room', 'foodsAndBeverages', 'lodging', 'otherServices']
    servicosComOrdemQueQueroPriorizar.forEach(servico => {
      if (this.state.event.services[servico].choosedSupplier) {
        data = this.state.event.services[servico].choosedSupplier.user
      }
    })
    return data
  }

  getSupplierNames() {
    if(!this.state.event.services) return ''
    if(this.state.event.services.room.choosedSupplier) return this.state.event.services.room.choosedSupplier.name
    if(this.state.event.services.foodsAndBeverages.choosedSupplier) return this.state.event.services.foodsAndBeverages.choosedSupplier.name
    if(this.state.event.services.lodging.choosedSupplier) return this.state.event.services.lodging.choosedSupplier.name
    return ''
  }

  // getSupplierContact () {
  //   if (!this.state.event.services) return ''
  //   if (this.state.event.services.room.choosedSupplier) return <Label>{this.state.event.services.room.choosedSupplier.profile.email}</Label>
  //   if (this.state.event.services.foodsAndBeverages.choosedSupplier) return <Label>{this.state.event.services.foodsAndBeverages.choosedSupplier.profile.email}</Label>
  //   if (this.state.event.services.lodging.choosedSupplier) return <Label>{this.state.event.services.lodging.choosedSupplier.profile.email}</Label>
  //   return ''
  // }

  getSupplierAddress () {
    let location
    if(!this.state.event.services) return ''
    if(this.state.event.services.room.choosedSupplier) location = this.state.event.services.room.choosedSupplier.location
    if(this.state.event.services.foodsAndBeverages.choosedSupplier) location = this.state.event.services.foodsAndBeverages.choosedSupplier.location
    if(this.state.event.services.lodging.choosedSupplier) location = this.state.event.services.lodging.choosedSupplier.location
    if(!location) return ''

    return (<>
      <AddressText address={location.address} number={location.number} city={location.city} province={location.province} />
      <Map
          center = {{lat:location.lat, lng: location.lng }}
          title={`${this.getSupplierNames()}, ${location.address}`}
        />
      </>)
  }

  getSupplierId() {
    if(!this.state.event.services) return ''
    if(this.state.event.services.room.choosedSupplier) return this.state.event.services.room.choosedSupplier.id
    if(this.state.event.services.foodsAndBeverages.choosedSupplier) return this.state.event.services.foodsAndBeverages.choosedSupplier.id
    if(this.state.event.services.lodging.choosedSupplier) return this.state.event.services.lodging.choosedSupplier.id
    if(this.state.event.services.transfer.choosedSupplier) return this.state.event.services.transfer.choosedSupplier.id
    if(this.state.event.services.otherServices.choosedSupplier) return this.state.event.services.otherServices.choosedSupplier.id
    return ''
  }

  getTotalPriceLodging = () => {
    return (this.state.lodging.requested && this.state.lodging.totalChoosedWithFees) ? this.state.lodging.totalChoosedWithFees.amount : 0
  }

  getTotalPriceRoom = () => {
    return (this.state.room.requested && this.state.room.totalChoosedWithFees) ? this.state.room.totalChoosedWithFees.amount : 0
  }

  getTotalPriceFoodsAndBeverages = () => {
    return (this.state.foodsAndBeverages.requested && this.state.foodsAndBeverages.totalChoosedWithFees) ? this.state.foodsAndBeverages.totalChoosedWithFees.amount : 0
  }

  getTotalPriceEquipments = () => {
    return (this.state.equipments.requested && this.state.equipments.totalChoosedWithFees) ? this.state.equipments.totalChoosedWithFees.amount : 0
  }

  getTotalPriceOtherServices = () => {
    return (this.state.otherServices.requested && this.state.otherServices.totalChoosedWithFees) ? this.state.otherServices.totalChoosedWithFees.amount : 0
  }

  getTotal = () => {
    return this.getTotalPriceLodging() +
      this.getTotalPriceRoom() +
      this.getTotalPriceFoodsAndBeverages() +
      this.getTotalPriceEquipments() +
      this.retornaValorTotalTransfer() +
      this.getTotalPriceOtherServices()
  }

  getTotalWithFee = () => {
    return this.applyFee(this.getManagementFee(), this.getTotal())
  }

  formatFee(fee) {
    if(!fee) return 0
    return (fee.type === 'PERCENTAGE')
      ? `${fee.amount}%`
      : formatCurrency(fee.amount)
  }

  applyFee(fee, value) {
    if(!fee) return 0
    return (fee.type === 'PERCENTAGE')
      ? Math.round(Number((value * (1 + (fee.amount / 100)))))
      : (value + fee.amount)
  }

  getFeeValue(fee, value) {
    if(!fee) return 0
    return (fee.type === 'PERCENTAGE')
      ? Math.round(Number((value * ((fee.amount / 100)))))
      : (fee.amount)
  }

  calcTotal() {
    const { event } = this.state
    if (!event) return 0
    const totalFromServices = event.services && Object.keys(event.services).reduce((total, service) => {
      if (event.services[service].requested && event.services[service].id && event.services[service].totalChoosedPrice)
        total += event.services[service].totalChoosedPrice.amount
      return total
    }, 0)
    const fee = this.getManagementFee()
    const total = this.applyFee(fee, totalFromServices) + this.calcTaxes()
    return formatCurrency(total, false)
  }

  calcTaxes() {
    const { event } = this.state
    if (!event) return 0
    const totalTaxes = event.services && Object.keys(event.services).reduce((total, service) => {
      if (event.services[service].requested
        && event.services[service].id
        && event.services[service].budget
        && event.services[service].totalChoosedWithFees) {
          total += event.services[service].totalChoosedWithFees.amount - event.services[service].totalChoosedPrice.amount
        }
      return total
    }, 0)
    return totalTaxes
  }

  calcPlaces = () => {
    const { event } = this.state
    var totalPlaces = 0
    if (event.services && event.services.room.id && event.services.room.requested && event.services.room.totalChoosedPrice) totalPlaces += event.services.room.totalChoosedPrice.amount
    if (event.services && event.services.foodsAndBeverages.id && event.services.foodsAndBeverages.requested && event.services.foodsAndBeverages.totalChoosedPrice) totalPlaces += event.services.foodsAndBeverages.totalChoosedPrice.amount
    if (event.services && event.services.lodging.id && event.services.lodging.requested && event.services.lodging.totalChoosedPrice) totalPlaces += event.services.lodging.totalChoosedPrice.amount
    return totalPlaces
  }

  getTitle = () => {
    var services = []
    const {event} = this.state
    const requestedServices = event.services ? Object.keys(event.services).filter(service => event.services[service].requested) : []
    if (requestedServices.includes('lodging')) {
      services.push('Hospedagem')
    }
    if (requestedServices.includes('room')) {
      services.push('Salas')
    }
    if (requestedServices.includes('foodsAndBeverages')) {
      services.push('A&B')
    }
    var title = ''
    services.forEach((service, index) => {
      if(index <= (services.length - 3)) {
        title += service + ', '
      } else if(index === (services.length - 2)) {
        title += service + ' e '
      } else {
        title += service
      }
    });
    return title
  }

  getLodgingIncludedItems = () => {
    var included = ''
    var count = 0
    Object.keys(this.state.lodging.budget.includedItems).forEach(key => {
      if(this.state.lodging.budget.includedItems[key] && key !== 'others') {
        if(count === 0) {
          included += getNameFromKey(key)
        } else {
          included += ', ' + getNameFromKey(key)
        }
        count++
      } else if (key === 'others' && this.state.lodging.budget.includedItems[key].sent) {
        if(count === 0) {
          included += this.state.lodging.budget.includedItems[key].description
        } else {
          included += ', ' + this.state.lodging.budget.includedItems[key].description
        }
        count++
      }
    })
    return included
  }

  getSubTitle = () => {
    var title = ''
    const {event} = this.state
    const requestedServices = event.services ? Object.keys(event.services).filter(service => event.services[service].requested) : []
    if (requestedServices.includes('lodging')) {
      title = 'Hospedagem'
      if (requestedServices.includes('room')) {
        if (requestedServices.includes('foodsAndBeverages')) {
          title += ' e A&B'
        } else {
          title += ' e Salas'
        }
      }
    } else if (requestedServices.includes('room')) {
      title = 'Salas'
      if (requestedServices.includes('foodsAndBeverages')) {
        title = ' e A&B'
      }
    }
    return this.getTitle() !== 'Local do Evento' ? title : ''
  }

  geraComissao = (fee) => {
    if(fee.type === 'PERCENTAGE') {
      return `${fee.amount}%`
    } else if(fee.type === 'ABSOLUTE') {
      return formatCurrency(fee.amount)
    } else {
      return ''
    }
  }

  gerenciaResponsividade = () => {
    if(window.innerWidth < 600) return this.paginaMobile()
    else return this.paginaDesktop()
  }

  paginaMobile = () => {
    const { isLoading, event } = this.state
    const chosedAllBudgets = event.services && Object.keys(event.services).every(service => !event.services[service].requested || event.services[service].totalChoosedPrice)
    const managementFee = this.formatFee(this.getManagementFee())
    const requestedServices = event.services ? Object.keys(event.services).filter(service => event.services[service].requested) : []
    return (
      <Container>
        {isLoading && <Loader />}
        <Modal isOpen={this.state.finishedOrder} >
          <MoedaIcon />
          <Titulo>Pedido Solicitado!</Titulo>
          <SubTitulo>Em breve entraremos em contato</SubTitulo>
          <Button
            onClick={() => this.props.history.push(`/organizer`)}
            category={'secondary'}
            fontWeight={800}
            full>
            Ok!
          </Button>
        </Modal>
        <Modal isOpen={this.state.todosSolicitados} >
          <MoedaIcon />
          <Titulo>Fornecedores solicitados para aprovação!</Titulo>
          <SubTitulo>Em breve entraremos em contato</SubTitulo>
          <Button
            onClick={() => this.props.history.push(`/organizer`)}
            category={'secondary'}
            fontWeight={800}
            full>
            Ok!
          </Button>
        </Modal>
        { event &&
          <Modal isOpen={this.state.showEventInfo} hasCloseButton>
              <ModalTitle>{event.name}</ModalTitle>
              <ListaInfo>
                <li><strong>Criado:</strong> {moment(event.created, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br').format('DD-MM-YYYY')}</li>
                <li><strong>Início:</strong> {moment(event.start, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br').format('DD-MM-YYYY')}</li>
                <li><strong>Fim:</strong> {moment(event.end, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br').format('DD-MM-YYYY')}</li>
                <li><strong>Número de participantes:</strong> {event.numberOfParticipants}</li>
                <li><strong>Budget:</strong> {formatCurrency(event.budget ? event.budget.amount : 0)}</li>
                {(event.location && event.location.address !== 'false') && <li><strong>Endereço:</strong>{event.location.address}</li>}
                {(event.location && event.location.city) && <li><strong>Cidade:</strong>{event.location.city}</li>}
              </ListaInfo>
          </Modal>
        }

        <CondicoesGerais isOpen={this.state.condicoesModal} account={this.state.account} onClose={() => this.setState({ condicoesModal: false })} submit={() => this.finishEvent()} />

        <Header />
        <BreadCrumb options={
          [
            {link: '/organizer', name: 'Home'},
            {name: 'Resumo do evento'}
          ]
          } />
        {!isLoading && <>
          <Main>
            <Titulo style ={{textAlign: 'center'}}>{event.name}</Titulo>
            <SubTitulo style={{ textAlign: 'center' }}>{formatDate(event.start)} - {formatDate(event.end)} | {event.numberOfParticipants} pessoas</SubTitulo>
            <br/>
            <br/>
            <br/>
            <Titulo >Resumo do evento</Titulo>
            <ButtonIcon
              placeholder={'Dados do evento'}
              icon={'star-outline'}
              onClick={() => { this.setState({showEventInfo: true})}}
              />
            {/* <ButtonIcon
              placeholder={'Dados da empresa'}
              icon={'person-outline'} /> */}
            <Titulo>Orçamento</Titulo>

            {
              (requestedServices.includes('room') ||
              requestedServices.includes('lodging')) &&
              <ButtonIconExtra
                placeholder={this.getTitle()}
                subtitle={this.getSupplierNames()}
                icon={'room-outline'}
                onClick={() => this.redirect('places')}
              >
                <Total>
                  {
                    formatCurrency(this.calcPlaces()) || ''
                  }
                </Total>
              </ButtonIconExtra>
            }
          </Main>
          <Conclusion>
            <div>
              <p style={{ fontSize: '12px' }}>Valor dos produtos e serviços contratados</p>
              <Total style={{ width: '70%' }}>{formatCurrency(this.calcPlaces())}</Total>
              <p style={{ fontSize: '12px', paddingLeft: '100px' }}>+</p>
              <p style={{ fontSize: '12px' }} >Valor das taxas dos fornecedores</p>
              <Total>{formatCurrency(this.calcTaxes())}</Total>
              <p style={{ fontSize: '12px', paddingLeft: '100px' }}>+</p>
              <p style={{ fontSize: '12px' }}>Valor da Service Fee ({managementFee})</p>
              <Total>{formatCurrency(this.getFeeValue(this.getManagementFee(), this.calcPlaces())) || ''}</Total>
            </div>
          </Conclusion>
          <Conclusion>
            <div>
              <Money>{this.calcTotal()}</Money>
              <p>Valor Total com Taxas</p>
            </div>
            <div>
              <Button
                onClick = {() => this.finishEvent()}
                disabled = {!chosedAllBudgets}
                type='submit'
                category={'secondary'}
                fontWeight={800}
                full>
                {temFeatureFlag('approval') ? 'Submeter para aprovação' : 'Contratar este fornecedor'}
              </Button>
            </div>
          </Conclusion>
        </>}
      </Container>
    )
  }

  retornaValorTotalDaSolicitacao = (budget) => {
    if (!budget || Object.keys(budget).length === 0) return 0
    const veiculos = budget.vehicles.reduce((soma, veiculo) => {
      soma += veiculo.quantity *
        (veiculo.isBilingual ? veiculo.bilingualPrice.amount : veiculo.price.amount) *
        veiculo.days
      return soma
    }, 0)
    const servicos = budget.onboardServices &&  budget.onboardServices.services.reduce((soma, servico) => {
      soma += servico.requestedAmount * servico.price.amount
      return soma
    }, 0)
    const lunchBoxes = budget.onboardServices &&  budget.onboardServices.lunchBoxes.reduce((soma, lunchBox) => {
      soma += lunchBox.price.amount * lunchBox.quantity
      return soma
    }, 0)
    const coordinator = budget.coordinatorPrices ? (budget.coordinatorPrices.quantity *
      budget.coordinatorPrices.pricePerDay.amount *
      budget.coordinatorPrices.days) : 0
    return veiculos + servicos + lunchBoxes + coordinator
  }

  retornaValorTotalTransfer = () => {
    return (this.state.transferBriefing || 0) && this.state.transferBriefing.requests.reduce((soma, briefing) => {
      const transferBudget = this.state.transfer.budget && (this.state.transfer.budget.requests.find(budget => budget.briefingRequestId === briefing.id) || {})
      soma += this.retornaValorTotalDaSolicitacao(transferBudget)
      return soma
    }, 0)
  }

  chosenSuppliers = () => {
    const servicos = this.state && this.state.event && this.state.event.services
    const listaServicosFechados = servicos &&  Object.keys(servicos).filter(servico => servicos[servico].requested === true)
    const fornecedoresEscolhidos = servicos && listaServicosFechados.map(servico => servicos[servico].choosedSupplier).filter(item => !!item)
    return (fornecedoresEscolhidos || []).reduce((lista, fornecedor) => {
      if (!lista.find((item) => item.id === fornecedor.id)) lista.push(fornecedor)
      return lista
    }, [])
  }

  chosenSuppliersId = () => {
    const fornecedoresEscolhidos = this.chosenSuppliers()
    const suppliersIds = fornecedoresEscolhidos && fornecedoresEscolhidos.map(fornecedor => fornecedor.id)
    const filtraRepetidos = suppliersIds && suppliersIds.filter((id, i) => suppliersIds.indexOf(id) === i)
    return filtraRepetidos
  }

  exportaInteegra = () => {
    const { eventId } = this.props.match.params
    const { paginaAtual, briefing } = this.state
    var endpoint = ''
    if(paginaAtual === 'v2')
      endpoint = config.url.planilhaInteegrav3.replace('{EVENTID}', eventId).replace('{HOTELID}', briefing.id)
    else if (paginaAtual === 'v3')
      endpoint = config.url.planilhaInteegrav2.replace('{EVENTID}', eventId)
    this.setState({ isLoading: true })
    GetCsv(endpoint).then(() => { this.setState({ isLoading: false }) }).catch(err => {
      this.setState({ isLoading: false })
      console.error(err)
      alert('Ocorreu um problema ao extrair o csv. Favor entrar em contato com nosso suporte')
    })
  }

  visualizaOutrosServicoSelecionado = (name, requestId) => {
    const { otherServices } = this.state
    const budgetDoBriefingSelecionadoIndex = otherServices.budget.services[name].requests.findIndex(budgetRequest => budgetRequest.briefingRequestId === requestId)
    if(budgetDoBriefingSelecionadoIndex !== -1) {
      const budgetSelecionadoIndex = otherServices.budget.services[name].requests[budgetDoBriefingSelecionadoIndex].budgets.findIndex(budget => budget.chosen)
      if(budgetSelecionadoIndex !== -1) {
        if(!otherServices.budget.services[name].requests[budgetDoBriefingSelecionadoIndex].budgets[budgetSelecionadoIndex].visualizacao) {
          otherServices.budget.services[name].requests[budgetDoBriefingSelecionadoIndex].budgets[budgetSelecionadoIndex].visualizacao = true
        } else {
          otherServices.budget.services[name].requests[budgetDoBriefingSelecionadoIndex].budgets[budgetSelecionadoIndex].visualizacao = !otherServices.budget.services[name].requests[budgetDoBriefingSelecionadoIndex].budgets[budgetSelecionadoIndex].visualizacao
        }
        this.setState({otherServices: otherServices})
      }
    }
  }

  Solicitacao = (name, briefing) => {
    return <Card style={{ padding: '10px', width: '100%', marginTop: '30px' }}>
      <Group style={{ padding: '10px' }}>
        <Title style={{ fontSize: '15px', marginBottom: '0px' }}>{getNameFromKey(name)}</Title>
        <label style={{ position: 'absolute', right: '5px', top: '10px' }}>
          <Money>{formatCurrency(briefing.requests.reduce((soma, request) => {
            const budgetDoBriefingSelecionado = this.state.otherServices.budget.services[name].requests.find(budgetRequest => budgetRequest.briefingRequestId === request.id)
            const escolhido = budgetDoBriefingSelecionado && budgetDoBriefingSelecionado.budgets.find(budget => budget.chosen)
            soma += request.quantity *
            (escolhido ? escolhido.unitPrice.amount : 0)
            return soma
          }, 0), false)}</Money>
        </label>
        <Label style={{ fontSize: '12px', listStyle: 'none', position: 'absolute', right: '2px', top: '25px', textAlign: 'right' }}>valor total</Label>
        {briefing.requests.map(request => {
          const budgetDoBriefingSelecionado = this.state.otherServices.budget.services[name].requests.find(budgetRequest => budgetRequest.briefingRequestId === request.id)
          const budgetSelecionado = budgetDoBriefingSelecionado && budgetDoBriefingSelecionado.budgets.find(budget => budget.chosen)
          return <Card style={{ width: '100%', paddingBottom: '10px', marginTop: '20px' }}>
            <label style={{ position: 'absolute', right: '5px', top: '10px' }}>
              <Money style={{ color: defaultTheme.colors.grey.medium }}>{formatCurrency((budgetSelecionado.unitPrice.amount * budgetSelecionado.quantity), false)}</Money>
            </label>
            <Label style={{ fontSize: '12px', listStyle: 'none', position: 'absolute', right: '2px', top: '25px', textAlign: 'right' }}>{formatCurrency(budgetSelecionado.unitPrice.amount)} / unidade</Label>
            <Label style={{ fontWeight: 600 }}>
              {budgetSelecionado.name} ({request.name})
            </Label>
            {!(!budgetDoBriefingSelecionado || !budgetDoBriefingSelecionado.hasAvailability || !budgetSelecionado) && <Link style={{ marginLeft: 'auto' }} onClick={() => this.visualizaOutrosServicoSelecionado(name, request.id)}>{budgetSelecionado.visualizacao ? 'ocultar detalhes do pedido' : 'ver detalhes do pedido' }</Link>}
            {budgetSelecionado.visualizacao && <>
              <Label style={{ marginTop: '20px' }}>
                Data de entrega: {formatDate(request.deliveryDate)} |
                Quantidade: {request.quantity} unidades
                {request.hasContentCreation ? ' | Necessita criação de conteúdo' : ''}
              </Label>
              <Label style={{ marginTop: '10px' }}>
                <span style={{ fontWeight: 600 }}>Sua descrição do pedido:</span> {request.description ? request.description : 'Sem descrição.'}
              </Label>
              {budgetSelecionado && <Label style={{ marginTop: '10px', marginBottom: '20px' }}>
                <span style={{ fontWeight: 600 }}>Detalhes do fornecedor:</span> {budgetSelecionado.description ? budgetSelecionado.description : 'Sem descrição.'}
              </Label>}
            </>}
            {(!budgetDoBriefingSelecionado || !budgetDoBriefingSelecionado.hasAvailability || !budgetSelecionado) && <Card style = {{ width: '100%', paddingBottom: '10px', color: 'red' }}>
              <Subtitle style={{ marginBottom: 'auto', marginTop: 'auto' }}>Fornecedor não tem disponibilidade para este pedido.</Subtitle>
            </Card>}
          </Card>})}
      </Group>
    </Card>
  }

  SolicitacaoAdditionalService = (briefing) => {
    return briefing.requests.map(request => {
      const budgetDoBriefingSelecionado = this.state.otherServices.budget.services.additionalServices.requests.find(budgetRequest => budgetRequest.briefingRequestId === request.id)
      const escolhido = budgetDoBriefingSelecionado && budgetDoBriefingSelecionado.budgets.find(budget => budget.chosen)
      return <Card style={{ padding: '10px', width: '100%', marginTop: '30px' }}>
        <Group style={{ padding: '10px' }}>
          <Title style={{ fontSize: '15px', marginBottom: '0px' }}>{escolhido ? escolhido.name : ''} ({request.name})</Title>
          <label style={{ position: 'absolute', right: '5px', top: '10px' }}>
            <Money>{formatCurrency((escolhido ? (escolhido.pricePerDay.amount * (escolhido.dates ? escolhido.dates.length : 1)) : 0), false)}</Money>
          </label>
          <Label style={{ fontSize: '12px', listStyle: 'none', position: 'absolute', right: '2px', top: '25px', textAlign: 'right' }}>{formatCurrency(escolhido ? (escolhido.pricePerDay.amount) : 0)} / por dia</Label>
          {!(!budgetDoBriefingSelecionado || !budgetDoBriefingSelecionado.hasAvailability || !escolhido) && <Link style={{ marginLeft: 'auto' }} onClick={() => this.visualizaOutrosServicoSelecionado('additionalServices', request.id)}>{escolhido.visualizacao ? 'ocultar detalhes do pedido' : 'ver detalhes do pedido' }</Link>}
          {escolhido && escolhido.visualizacao && <Group style={{ marginTop: '20px' }}>
            <Label>
              Datas: {request.dates.map(date => formatDate(date)).join(' - ')}
              {request.hoursPerDay ? ` | ${request.hoursPerDay} horas por dia ` : ''}
            </Label>
            <Label style={{ marginTop: '10px' }}>
              <span style={{ fontWeight: 600 }}>Sua descrição do pedido:</span> {request.description ? request.description : 'Sem descrição.'}
            </Label>
            {escolhido && <Label style={{ marginTop: '10px', marginBottom: '20px' }}>
              <span style={{ fontWeight: 600 }}>Detalhes do fornecedor:</span> {escolhido.description ? escolhido.description : 'Sem descrição.'}
            </Label>}
          </Group>}
          {(!budgetDoBriefingSelecionado || !budgetDoBriefingSelecionado.hasAvailability || !escolhido) && <Card style = {{ width: '100%', paddingBottom: '10px', color: 'red' }}>
            <Subtitle style={{ marginBottom: 'auto', marginTop: 'auto' }}>Fornecedor não tem disponibilidade para este pedido.</Subtitle>
          </Card>}
        </Group>
      </Card>})
  }

  paginaDesktop = () => {
    const { eventId } = this.props.match.params
    const { isLoading } = this.state
    const eventName = this.state && this.state.event && this.state.event.name
    const eventStatus = this.state && this.state.event && this.state.event.status
    const supplierId =  this.state && this.state.briefing && this.state.briefing.id
    const fornecedoresEscolhidos = this.chosenSuppliers()
    const fornecedoresEscolhidosIds = this.chosenSuppliersId()
    const CHAT_MULTIPLO = this.state.paginaAtual === 'v3' && fornecedoresEscolhidosIds && fornecedoresEscolhidosIds.length > 1
    const DISPLAY_CHAT = eventId && eventName && eventStatus && (supplierId || CHAT_MULTIPLO)

    return <>
      <Header />
      <MainDesktop>
        <Body {...this.props} pagina={'inicio'} menuLateralDireito={this.menuLateralDireito()}>
          <Container>
            {(this.state.paginaAtual === 'v2') && <Options
              style={{ marginTop: '95px' }}
              eventId={eventId}
              history={this.props.history}
              suppliersDeadline={this.state.event && this.state.event.suppliersDeadline}
              status={this.state.event && this.state.event.status}
              name={this.state.event && this.state.event.name}
              naoMostraEdicao={this.state.event && this.state.event.supplierChainId} />}
            {isLoading && <Loader />}
            <BreadCrumb options={
              [
                {link: '/organizer', name: 'Eventos'},
                {name: 'Detalhes dos pedidos'}
              ]
              } />
            <Main>
              <Modal isOpen={this.state.finishedOrder} >
                <MoedaIcon />
                <Titulo>Pedido Solicitado!</Titulo>
                <SubTitulo>Em breve entraremos em contato</SubTitulo>
                <Button
                  onClick={() => this.props.history.push(`/organizer`)}
                  category={'secondary'}
                  fontWeight={800}
                  full>
                  Ok!
                </Button>
              </Modal>
              <Modal isOpen={this.state.todosSolicitados} >
                <MoedaIcon />
                <Titulo>Fornecedores solicitados para aprovação!</Titulo>
                <SubTitulo>Em breve entraremos em contato</SubTitulo>
                <Button
                  onClick={() => this.props.history.push(`/organizer`)}
                  category={'secondary'}
                  fontWeight={800}
                  full>
                  Ok!
                </Button>
              </Modal>
              <Modal isOpen={this.state.modalVerificacao} >
                <AlertIcon fill={defaultTheme.colors.secondary.dark} width={'150px'}/>
                <Titulo style={{ lineHeight: 2 }}>Atenção!</Titulo>
                <SubTitulo style={{ color: defaultTheme.colors.grey.medium }}>{temFeatureFlag('approval') ? 'Tem certeza que deseja enviar este fornecedor para aprovação' : 'Tem certeza que deseja contratar este fornecedor'} <span style={{ fontWeight: 600 }}>em definitivo</span>, com os itens escolhidos? Uma vez realizada esta ação, <span style={{ fontWeight: 600 }}>não é possível voltar atrás</span></SubTitulo>
                <Group style={{ display: 'flex', padding: '20px 0 0 0' }}>
                  <Button
                    onClick={() => this.setState({ modalVerificacao: false })}
                    category={'secondary'}
                    fontWeight={800}
                    ghost
                    full>
                    Não
                  </Button>
                  <Button
                    onClick={() => this.finishEvent()}
                    category={'secondary'}
                    fontWeight={800}
                    full>
                    Sim
                  </Button>
                </Group>
              </Modal>
              {this.state.briefing && <CondicoesGerais isOpen={this.state.condicoesModal} paymentPolicies={this.state.briefing.paymentPolicies} onClose={() => this.setState({ condicoesModal: false })}
                submit={() => {
                  this.setState({ condicoesModal: false })
                  return this.finishEvent()
                }} />}
              <Group style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                <Group>
                  <Titulo style={{ paddingTop: '30px' }}>Detalhes dos pedidos</Titulo>
                  <Label>Veja abaixo o detalhamento dos pedidos solicitados.</Label>
                </Group>
                <Group>
                  {DISPLAY_CHAT && <ButtonChat />}
                  {temFeatureFlag('inteegra') &&
                    <Button
                      type='button'
                      category={'secondary'}
                      ghost
                      small
                      onClick={this.exportaInteegra}
                      fontWeight={600}
                      style= {{ float: 'right', margin: '0 0 5px auto' }}
                      children='Extrair .csv'
                    />}
                </Group>
              </Group>
              {(this.state.lodging.requested && this.state.lodging.budget) && <>
                  <Group style={{ position: 'relative' }}>
                    <Titulo style={{ paddingTop: '30px', fontSize: '15px' }}>Hospedagem</Titulo>
                    {temFeatureFlag('showCommission') && this.state.lodging.budget.commission && this.state.lodging.budget.commission.hasCommission && <Tag style={{ left: '110px', top: '30px' }} status={'COMISSIONAMENTO'}>Comissão: {this.geraComissao(this.state.lodging.budget.commission.fee)}</Tag>}
                  </Group>
                  <Label>Suas observações: {(this.state.briefing ? this.state.briefing.briefings.lodging.observations : '') || 'Sem observações.'}</Label>
                  <Label>Observações: {this.state.lodging.budget.observations || 'Sem observações.'}</Label>
                  <Label>Inclui: <b>{this.getLodgingIncludedItems()}.</b></Label>
                  { this.state.lodging.budget && this.state.lodging.budget.apartmentsBudget.filter(budget => budget.hasAvailability).map(budget => {
                    return <Card key={`roomServiceItem_${0}`} style={{ width: '100%', paddingBottom: '10px' }}>
                      <Group style={{ padding: '0px' }}>
                        <Label style={{ fontWeight: 600, fontSize: '15px'}}>{budget.numberOfApartments}x {getNameFromKey(budget.item.keyName)} ({budget.category})</Label>
                        <label style={{position: 'absolute', right: '15px', top: '20px'}}>
                          <Money>{formatCurrency(budget.totalPrice.amount, false)}</Money>
                        </label>
                        <Label style={{ fontSize: '12px', listStyle: 'none', position: 'absolute', right: '10px', top: '40px', textAlign: 'right' }}>R$ {formatCurrency(budget.price ? budget.price.amount : 0, false)} / dia {(budget.fee && budget.fee.amount > 0) ? `+ ${budget.fee.amount}% taxas` :  '- sem taxas'}</Label>
                      </Group>
                      <Group style={{ display: 'flex', marginTop: '20px' }}>
                        <Label style= {{ width: 'fit-content' }}>Check in: {formatDate(budget.item.dates[0])}</Label>
                        <Label style={{ width: 'fit-content', paddingLeft: '10px', paddingRight: '10px'  }}>|</Label>
                        <Label style= {{ width: 'fit-content' }}>Check out: {formatDate(budget.item.dates[budget.item.dates.length - 1])}</Label>
                        <Label style={{ width: 'fit-content', paddingLeft: '10px', paddingRight: '10px'  }}>|</Label>
                        <Label style= {{ width: 'fit-content' }}>
                          <Icons
                            icon={'calendario'}
                            color={'gray'}
                            width={40}
                            height={20}
                            style={{ marginRight: '-5px' }} />{budget.item.dates.length - 1} diárias</Label>
                        <Label style={{ width: 'fit-content', paddingLeft: '10px', paddingRight: '10px'  }}>|</Label>
                        {budget.item.keyName === 'single' && <Group style={{ width: '30%', display: 'flex', alignItems: 'center' }}>
                            <Icons
                              color={'gray'}
                              width={40}
                              height={20}
                              style={{ marginRight: '-5px' }} icon='double-bed' />
                            <Label style={{ width: '70%'}}>1 cama de casal</Label>
                          </Group>}
                        {budget.item.keyName === 'double' && <Group style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
                            <Icons
                              color={'gray'}
                              width={40}
                              height={20}
                              style={{ marginRight: '-5px' }} icon='double-bed' />
                            <Label style={{ width: '70%'}}>1 cama de casal</Label>
                          </Group>}
                        {budget.item.keyName === 'twin' && <Group style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
                            <Icons
                              color={'gray'}
                              width={40}
                              height={20}
                              style={{ marginRight: '-5px' }} icon='single-bed' />
                            <Icons
                              color={'gray'}
                              width={40}
                              height={20}
                              style={{ marginRight: '-5px' }} icon='single-bed' />
                            <Label style={{ width: '70%', paddingLeft: '10px'}}>2 camas de solteiro</Label>
                          </Group>}
                        {budget.item.keyName === 'triple' && <Group style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
                          <Icons
                            color={'gray'}
                            width={40}
                            height={20}
                            style={{ marginRight: '-5px' }} icon='single-bed' />
                          <Icons
                            color={'gray'}
                            width={40}
                            height={20}
                            style={{ marginRight: '-5px' }} icon='single-bed' />
                          <Icons
                            color={'gray'}
                            width={40}
                            height={20}
                            style={{ marginRight: '-5px' }} icon='single-bed' />
                          <Label style={{ width: '55%', paddingLeft: '10px'}}>3 camas de solteiro</Label>
                        </Group>}
                      </Group>
                    </Card>
                  })}
                  {(this.state.lodging.budget && this.state.lodging.budget.itemsOnApartmentPrice && this.state.lodging.budget.itemsOnApartmentPrice.amount > 0) && <Card style={{ width: '100%', paddingBottom: '10px' }}>
                    <Group style={{ padding: '0px' }}>
                      <Label style={{ fontWeight: 600, fontSize: '15px'}}>Entrega de brindes</Label>
                      <label style={{position: 'absolute', right: '15px', top: '20px'}}>
                        <Money>{formatCurrency(this.state.lodging.budget.itemsOnApartmentPrice.amount * this.state.lodging.budget.apartmentsBudget.reduce((total, item) => total + item.numberOfApartments, 0), false)}</Money>
                      </label>
                    </Group>
                    <Group style={{ display: 'flex', marginTop: '20px' }}>
                      <Label style= {{ width: 'fit-content' }}>{this.state.lodging.budget.apartmentsBudget.reduce((total, item) => total + item.numberOfApartments, 0)} quartos</Label>
                    </Group>
                  </Card>}
              </>}

              {(this.state.room.requested && this.state.room.budget && this.state.roomBriefing) && <>
                  <Group style={{ position: 'relative' }}>
                    <Titulo style={{ paddingTop: '30px', fontSize: '15px' }}>Salas</Titulo>
                    {temFeatureFlag('showCommission') && this.state.room.budget.commission && this.state.room.budget.commission.hasCommission && <Tag style={{ left: '50px', top: '30px' }} status={'COMISSIONAMENTO'}>Comissão: {this.geraComissao(this.state.room.budget.commission.fee)}</Tag>}
                  </Group>
                  <Label>Suas observações: {this.state.roomBriefing.briefing.observations || 'Sem observações.'}</Label>
                  { this.state.room && this.state.room.budget.roomsBudget.filter(item => item.hasAvailability).map(room =>
                    {
                      let roomBriefing = this.state.roomBriefing.briefing.rooms.find(item => item.id === room.roomBriefingId)
                      let roomBudget = this.state.roomBriefing.budget.roomsBudget.find(item => item.id === room.id)
                      return <Card style={{ marginTop: '0px', width: '100%' }}>
                        <Group style={{ padding: '0px' }}>
                          <Label style={{ fontWeight: 600, fontSize: '15px'}}>{roomBriefing.roomFormat} ({roomBudget.room.name})</Label>
                          <label style={{position: 'absolute', right: '15px', top: '20px'}}>
                            <Money>{formatCurrency(room.totalPrice.amount, false)}</Money>
                          </label>
                          <Label style={{ fontSize: '12px', listStyle: 'none', position: 'absolute', right: '10px', top: '40px', textAlign: 'right' }}>{formatCurrency(room.pricePerDay.amount)} / dia {(room.mountingPrice && room.mountingPrice.amount > 0) ? ` + ${formatCurrency(room.mountingPrice.amount)} (montagem)` : ''} {roomBudget.room.taxes && roomBudget.room.taxes.amount ? ` + ${roomBudget.room.taxes.amount}% em taxas` : 'sem taxas'}</Label>
                        </Group>
                        <Label style={{ marginTop: '10px' }}>Datas: {formatDate(roomBriefing.startDate)} - {formatDate(roomBriefing.endDate)}</Label>
                        <Label>Horário: {formatTime(roomBriefing.startTime)} - {formatTime(roomBriefing.endTime)}</Label>
                        <Label>Montagem: {roomBriefing.mounting ? 'Sim': 'Não'}</Label>
                        <Label>Formato da sala: {roomBriefing.roomType} </Label>
                        <Label>Nº de pessoas: {roomBriefing.amountOfPeople} pessoas</Label>
                        <Label>Itens adicionais solicitados: {roomBriefing.roomItems && roomBriefing.roomItems.join(', ')}.</Label>
                        <Label>Observações: {room.observations}.</Label>
                      </Card>
                    })
                  }
                  {(this.state.room && this.state.room.budget.roomsBudget.filter(item => item.hasAvailability).length === 0) && <Card style={{ marginTop: '0px', width: '100%' }}>
                      <Label>Nenhuma sala com disponibilidade.</Label>
                    </Card>
                  }
              </>}

              <Modal isOpen={!!this.state.foodsAndBeveragesDetails} hasCloseButton onClose={() => this.setState({ foodsAndBeveragesDetails: '' })}>
                <ListaInfo>
                  <li>{this.state.foodsAndBeveragesDetails}</li>
                </ListaInfo>
              </Modal>
              {(this.state.foodsAndBeverages.requested && this.state.foodsAndBeverages.budget && this.state.foodsAndBeveragesBriefing) && <>
                  <Group style={{ position: 'relative' }}>
                    <Titulo style={{ paddingTop: '30px', fontSize: '15px' }}>Alimentos e bebidas</Titulo>
                    {temFeatureFlag('showCommission') && this.state.foodsAndBeverages.budget.commission && this.state.foodsAndBeverages.budget.commission.hasCommission && <Tag style={{ left: '165px', top: '30px' }} status={'COMISSIONAMENTO'}>Comissão: {this.geraComissao(this.state.foodsAndBeverages.budget.commission.fee)}</Tag>}
                  </Group>
                  <Label>Suas observações: {(this.state.briefing ? this.state.briefing.briefings.foodsAndBeverages.observations : '') || 'Sem observações.'}</Label>
                  <Label>Observações: {this.state.foodsAndBeverages.budget.observations || 'Sem observações.'}</Label>
                  { Object.keys(this.state.foodsAndBeverages && this.state.foodsAndBeverages.budget.menusBudgets).filter(menu => menu !== 'roomService' && menu !== 'tray').map((menu, index) => {
                      let menusBudget = this.state.foodsAndBeverages.budget.menusBudgets[menu]
                      let menuChoosed = menusBudget.budgets.find(item => item.chosen === true)
                      let FABBriefing = this.state.foodsAndBeveragesBriefing.budgets.find(item => item.name === menu).briefing
                      let fee = this.state.foodsAndBeverages.budget.fee ? this.state.foodsAndBeverages.budget.fee.amount : null
                      const feeText = (fee > 0) ? `+ ${fee}% em taxas` : '- sem taxa'

                      return <Card key={`foodsAndBeveragesItem_${index}`} style={{ width: '100%', paddingBottom: '10px' }}>
                        <Group style={{ padding: '0px' }}>
                          <Label style={{ fontWeight: 600, fontSize: '15px'}}>{getNameFromKey(menu)}</Label>
                          <label style={{position: 'absolute', right: '15px', top: '20px'}}>
                            <Money>{formatCurrency(menusBudget.totalPriceChosen.amount, false)}</Money>
                          </label>
                  <Label style={{ fontSize: '12px', listStyle: 'none', position: 'absolute', right: '10px', top: '40px', textAlign: 'right' }}>{formatCurrency(menuChoosed ? menuChoosed.price.amount : 0)} / pessoa / dia {feeText} </Label>
                        </Group>
                        <Group style={{ display: 'flex', marginTop: '20px' }}>
                          <Label style= {{ width: 'fit-content' }}>Datas: {
                            FABBriefing.requests.map((briefing, index) => {
                              if (index !== 0) return ' - ' + formatDate(briefing.start)
                              return formatDate(briefing.start)
                            })
                          }</Label>
                          <Label style={{ width: 'fit-content', paddingLeft: '10px', paddingRight: '10px'  }}>|</Label>
                          <Label style= {{ width: 'fit-content' }}>Horário de início: {formatTime(FABBriefing.requests[0].startTime)}</Label>
                          <Label style={{ width: 'fit-content', paddingLeft: '10px', paddingRight: '10px'  }}>|</Label>
                          <Label style= {{ width: 'fit-content' }}>Nº de pessoas {FABBriefing.requests[0].amountOfPeople}</Label>
                        </Group>
                        <Group style={{ display: 'flex' }}>
                          <Label style= {{ width: 'fit-content' }}>
                            Cardápio escolhido: {menuChoosed.item.name}
                            <Link style={{ marginLeft: 'auto', paddingLeft: '10px' }} onClick={() => this.setState({ foodsAndBeveragesDetails: menuChoosed.item.description})}>ver detalhes do cardápio</Link>
                          </Label>
                        </Group>
                      </Card>
                    }
                  )}
                  { Object.keys(this.state.foodsAndBeverages && this.state.foodsAndBeverages.budget.menusBudgets).filter(menu => menu === 'roomService').map((menu, index) => {
                      let menusBudget = this.state.foodsAndBeverages.budget.menusBudgets[menu]
                      let menusBudgetTray = this.state.foodsAndBeverages.budget.menusBudgets.tray
                      let menusChoosed = Object.keys(menusBudget.budgets).filter(item => menusBudget.budgets[item] && menusBudget.budgets[item].chosen === true).map(item => menusBudget.budgets[item])
                      let menusChoosedTray = Object.keys(menusBudgetTray.budgets).filter(item => menusBudgetTray.budgets[item] && menusBudgetTray.budgets[item].chosen === true).map(item => menusBudgetTray.budgets[item])
                      let FABBriefing = this.state.foodsAndBeveragesBriefing.budgets.find(item => item.name === menu).briefing
                      const fee = this.state.foodsAndBeverages.budget.fee ? this.state.foodsAndBeverages.budget.fee.amount : null
                      const feeText = (fee > 0) ? `+ ${fee}% em taxas` : '- sem taxa'

                      return <Card key={`foodsAndBeveragesItem_${index}`} style={{ width: '100%', paddingBottom: '10px' }}>
                        <Group style={{ padding: '0px' }}>
                          <Label style={{ fontWeight: 600, fontSize: '15px'}}>{getNameFromKey(menu)}</Label>
                        </Group>
                        <Group style={{ display: 'flex', marginTop: '20px' }}>
                          <Label style= {{ width: 'fit-content' }}>Datas: {
                            FABBriefing.requests.map((briefing, index) => {
                              if (index !== 0) return ' - ' + formatDate(briefing.start)
                              return formatDate(briefing.start)
                            })
                          }</Label>
                          <Label style={{ width: 'fit-content', paddingLeft: '10px', paddingRight: '10px'  }}>|</Label>
                          <Label style= {{ width: 'fit-content' }}>Horário de início: {formatTime(FABBriefing.requests[0].startTime)}</Label>
                          <Label style={{ width: 'fit-content', paddingLeft: '10px', paddingRight: '10px'  }}>|</Label>
                          <Label style= {{ width: 'fit-content' }}>Nº de pessoas {FABBriefing.requests[0].amountOfPeople}</Label>
                        </Group>

                        {menusChoosed.map((roomServiceItem) => <Card key={`roomServiceItem_${index}`} style={{ width: '100%', paddingBottom: '10px' }}>
                          <Group style={{ padding: '0px' }}>
                            <Label style={{ fontWeight: 600, fontSize: '15px'}}>{getNameFromKey(roomServiceItem.item.name)}</Label>
                            <label style={{position: 'absolute', right: '15px', top: '20px'}}>
                              <Money>{formatCurrency(roomServiceItem.totalWithoutFee.amount, false)}</Money>
                            </label>
                        <Label style={{ fontSize: '12px', listStyle: 'none', position: 'absolute', right: '10px', top: '40px', textAlign: 'right' }}>{formatCurrency(roomServiceItem.price.amount * roomServiceItem.numberOfItems)} / dia {feeText}</Label>
                          </Group>
                          <Group style={{ display: 'flex', marginTop: '20px' }}>
                            <Label style= {{ width: 'fit-content' }}>{getNameFromKey(roomServiceItem.type)} {roomServiceItem.quantity}</Label>
                            <Label style={{ width: 'fit-content', paddingLeft: '10px', paddingRight: '10px'  }}>|</Label>
                            <Label style= {{ width: 'fit-content' }}>{formatCurrency(roomServiceItem.price.amount)} por unidade</Label>
                            <Label style={{ width: 'fit-content', paddingLeft: '10px', paddingRight: '10px'  }}>|</Label>
                            <Label style= {{ width: 'fit-content' }}>{roomServiceItem.numberOfItems} {roomServiceItem.numberOfItems > 1 ? 'unidades' : 'unidade'} por dia </Label>
                            <Label style={{ width: 'fit-content', paddingLeft: '10px', paddingRight: '10px'  }}>|</Label>
                            <Label style= {{ width: 'fit-content' }}>{FABBriefing.requests.length} dias</Label>
                          </Group>
                        </Card>)
                        }

                        {menusChoosedTray.map((roomServiceItemTray) => <Card key={`roomServiceItemTray_${index}`} style={{ width: '100%', paddingBottom: '10px' }}>
                          <Group style={{ padding: '0px' }}>
                            <Label style={{ fontWeight: 600, fontSize: '15px'}}>{getNameFromKey(roomServiceItemTray.item.name)} (Bandeja)</Label>
                            <label style={{position: 'absolute', right: '15px', top: '20px'}}>
                              <Money>{formatCurrency(roomServiceItemTray.totalWithoutFee.amount, false)}</Money>
                            </label>
                            <Label style={{ fontSize: '12px', listStyle: 'none', position: 'absolute', right: '10px', top: '40px', textAlign: 'right' }}>{formatCurrency(roomServiceItemTray.price.amount * roomServiceItemTray.numberOfItems)} / dia {feeText}</Label>
                          </Group>
                          <Group style={{ display: 'flex', marginTop: '20px' }}>
                            <Label style= {{ width: 'fit-content' }}>{formatCurrency(roomServiceItemTray.price.amount)} por bandeja</Label>
                            <Label style={{ width: 'fit-content', paddingLeft: '10px', paddingRight: '10px'  }}>|</Label>
                            <Label style= {{ width: 'fit-content' }}>{roomServiceItemTray.numberOfItems} bandejas por dia</Label>
                            <Label style={{ width: 'fit-content', paddingLeft: '10px', paddingRight: '10px'  }}>|</Label>
                            <Label style= {{ width: 'fit-content' }}>{FABBriefing.requests.length} dias</Label>
                          </Group>
                        </Card>)
                        }

                      </Card>
                    }
                  )}
                </>
              }

              {(this.state.equipments.requested && this.state.equipments.budget && this.state.equipmentsBriefing) && <>
                <Group style={{ position: 'relative' }}>
                  <Titulo style={{ paddingTop: '30px', fontSize: '15px' }}>Equipamentos</Titulo>
                  {temFeatureFlag('showCommission') && this.state.equipments.budget.commission && this.state.equipments.budget.commission.hasCommission && <Tag style={{ left: '120px', top: '30px' }} status={'COMISSIONAMENTO'}>Comissão: {this.geraComissao(this.state.equipments.budget.commission.fee)}</Tag>}
                </Group>
                <Label>Suas observações: {(this.state.briefing ? this.state.briefing.briefings.equipments.observations : '') || 'Sem observações.'}</Label>
                { Object.keys(this.state.equipmentsBriefing.briefing)
                  .filter(item => this.state.equipmentsBriefing.briefing[item] && this.state.equipmentsBriefing.briefing[item].requested)
                  .map((budgetItem, index) => {
                    let item = this.state.equipments.budget.equipments[budgetItem]
                    const fee = this.state.equipments.budget.fee ? this.state.equipments.budget.fee.amount : null
                    const feeText = (fee > 0) ? `+ ${fee}% em taxas` : '- sem taxas'

                    if(item.hasAvailability) {
                      return <Card key={`equipments_${index}`} style={{ width: '100%', paddingBottom: '10px' }}>
                        <Group style={{ padding: '0px' }}>
                          <Label style={{ fontWeight: 600, fontSize: '15px'}}>{budgetItem !== 'internet' ? getNameFromKey(budgetItem) : 'Internet'}</Label>
                          <label style={{position: 'absolute', right: '15px', top: '20px'}}>
                            <Money>{formatCurrency(item.total.amount, false)}</Money>
                          </label>
                    <Label style={{ fontSize: '12px', listStyle: 'none', position: 'absolute', right: '10px', top: '40px', textAlign: 'right' }}>{budgetItem !== 'microphone' && `${formatCurrency(item.price.amount)} / ${budgetItem !== 'internet' ? 'unidade' : 'ponto de acesso'} / dia `} {feeText}</Label>
                        </Group>
                        <Group style={{ padding: '0px', marginTop: '15px' }}>
                          <Label>
                            Observações: {item.observation || 'Sem observações'}
                          </Label>
                          <Label>Suas observações: {this.state.equipmentsBriefing.briefing[budgetItem].observation || 'Sem observações.'}</Label>

                          {budgetItem !== 'microphone' && item.requests.map(requestItem => <>
                            <Label style={{ marginTop: '10px' }}> Datas: {requestItem.dates.map(date => formatDate(date)).join(' - ')} </Label>
                            {requestItem.amount > 0 && <Label> {requestItem.amount} {requestItem.amount > 1 ? (budgetItem !== 'internet' ? 'unidades' : 'pontos de acesso') : (budgetItem !== 'internet' ? 'unidade' : 'ponto de acesso')}</Label>}
                            <Label>Descrição: {item.description}</Label>
                          </>
                          )}
                          {budgetItem === 'microphone' && item.requests.map(microphoneItem => <>
                            <Label style={{ marginTop: '10px' }}> Datas: {microphoneItem.dates.map(date => formatDate(date)).join(' - ')} </Label>
                            {microphoneItem.numberOfLapel > 0 && <Label style={{ marginTop: '10px' }}> Lapela: {formatCurrency(item.lapelPrice.amount)} x {microphoneItem.numberOfLapel} {microphoneItem.numberOfLapel > 1 ? 'unidades' : 'unidade'} / dia</Label>}
                            {microphoneItem.numberOfLapel > 0 && <Label>Descrição: {item.lapelDescription}</Label>}
                            {microphoneItem.numberOfGooseneck > 0 && <Label style={{ marginTop: '10px' }}> Gooseneck: {formatCurrency(item.gooseneckPrice.amount)} x {microphoneItem.numberOfGooseneck} {microphoneItem.numberOfGooseneck > 1 ? 'unidades' : 'unidade'} / dia</Label>}
                            {microphoneItem.numberOfGooseneck > 0 && <Label>Descrição: {item.gooseneckDescription}</Label>}
                            {microphoneItem.numberOfHeadSet > 0 && <Label style={{ marginTop: '10px' }}> Head Set: {formatCurrency(item.headSetPrice.amount)} x {microphoneItem.numberOfHeadSet} {microphoneItem.numberOfHeadSet > 1 ? 'unidades' : 'unidade'} / dia</Label>}
                            {microphoneItem.numberOfHeadSet > 0 && <Label>Descrição: {item.headSetDescription}</Label>}
                            {microphoneItem.numberOfStick > 0 && <Label style={{ marginTop: '10px' }}> Bastão: {formatCurrency(item.stickPrice.amount)} x {microphoneItem.numberOfStick} {microphoneItem.numberOfStick > 1 ? 'unidades' : 'unidade'} / dia</Label>}
                            {microphoneItem.numberOfStick > 0 && <Label>Descrição: {item.stickDescription}</Label>}
                          </>
                          )}
                          {this.state.equipmentsBriefing.briefing[budgetItem].type && <Label> Tipo: {getNameFromKey(this.state.equipmentsBriefing.briefing[budgetItem].type)}</Label>}
                          {this.state.equipmentsBriefing.briefing[budgetItem].languages && <Label> Idiomas: {getNameFromKey(this.state.equipmentsBriefing.briefing[budgetItem].languages[0])}</Label>}
                          {this.state.equipmentsBriefing.briefing[budgetItem].interpreter && <Label> Necessita intérprete: Sim</Label>}
                          {this.state.equipmentsBriefing.briefing[budgetItem].mbSpeed && <Label> Velocidade da internet: {this.state.equipmentsBriefing.briefing[budgetItem].mbSpeed}MB</Label>}
                        </Group>
                      </Card>
                    } else {
                      return <Card key={`equipments_${index}`} style={{ paddingBottom: '10px', opacity: 0.5 }}>
                        <Group>
                          <Label style={{ fontWeight: 600, fontSize: '15px'}}>{getNameFromKey(budgetItem)}</Label>
                          <label style={{position: 'absolute', right: '50px', top: '30px'}}>
                            <Label style={{ listStyle: 'none', color: 'black' }}>Equipamento indisponível</Label>
                          </label>
                        </Group>
                      </Card>
                    }
                  })}
            </>}

            {(this.state.transfer.requested && this.state.transfer.budget && this.state.transferBriefing) && <>
                <Group style={{ position: 'relative' }}>
                  <Titulo style={{ paddingTop: '30px', fontSize: '15px' }}>Transfer</Titulo>
                  {temFeatureFlag('showCommission') && this.state.transfer.budget.commission && this.state.transfer.budget.commission.hasCommission && <Tag style={{ left: '75px', top: '30px' }} status={'COMISSIONAMENTO'}>Comissão: {this.geraComissao(this.state.transfer.budget.commission.fee)}</Tag>}
                </Group>
                { this.state.transferBriefing.requests.map((briefingItem, index) => {
                    const transferBudget = this.state.transfer.budget.requests.find(budget => budget.briefingRequestId === briefingItem.id) || {}
                    return <Card key={`transfer_${index}`} style={{ width: '100%', paddingBottom: '10px' }}>
                      <Group style={{ padding: '0px' }}>
                        <Label style={{ fontWeight: 600, fontSize: '15px'}}>{briefingItem.name}</Label>
                        <label style={{position: 'absolute', right: '15px', top: '20px'}}>
                          <Money>{formatCurrency(this.retornaValorTotalDaSolicitacao(transferBudget), false)}</Money>
                        </label>
                      </Group>
                      <Group style={{ padding: '0px', marginTop: '15px' }}>
                        <Label>
                          Observações: {transferBudget.observation || 'Sem observações'}
                        </Label>
                        <Label>Suas observações: {briefingItem.observation || 'Sem observações.'}</Label>
                      </Group>

                      {
                        !transferBudget.hasAvailability && <Card style={{ width: '100%', paddingBottom: '10px', color: 'red' }}>
                        Fornecedor não tem disponibilidade para este pedido
                        </Card>
                      }
                      {
                        transferBudget.hasAvailability && <>
                          <Label style={{ fontWeight: 600, fontSize: '14px', paddingTop: '20px' }}>Veículos</Label>
                          {transferBudget.vehicles.map((veiculo, veiculoIndex) => (
                            <Card key={`transfer_veiculo_${veiculoIndex}`} style={{ width: '100%', paddingBottom: '10px' }}>
                              <Group style={{ padding: '0px' }}>
                                <Label style={{ fontWeight: 600, fontSize: '15px' }}>{transferText(veiculo.type)}</Label>
                                <label style={{ position: 'absolute', right: '15px', top: '20px' }}>
                                  <Money>{formatCurrency(
                                    veiculo.quantity *
                                    (veiculo.isBilingual ? veiculo.bilingualPrice.amount : veiculo.price.amount) *
                                    veiculo.days, false)}</Money>
                                </label>
                                <Label style={{ fontSize: '12px', listStyle: 'none', position: 'absolute', right: '10px', top: '40px', textAlign: 'right' }}>{`${formatCurrency(veiculo.isBilingual ? veiculo.bilingualPrice.amount : veiculo.price.amount)} / dia`}</Label>
                              </Group>
                              <Group style={{ padding: '0px', marginTop: '15px' }}>
                                <Label>
                                  Capacidade: {briefingItem.luggage ? veiculo.capacityWithLuggage : veiculo.capacityWithoutLuggage} | Quantidade: {veiculo.quantity} veículos | {veiculo.days} dias
                            </Label>
                              </Group>
                            </Card>
                          ))}
                          {briefingItem.hasOnboardServices && <>
                            <Label style={{ fontWeight: 600, fontSize: '14px', paddingTop: '20px' }}>Serviço de bordo</Label>
                            {transferBudget.onboardServices && transferBudget.onboardServices.services.map((servico, servicoIndex) => (
                              <Card key={`transfer_servico_${servicoIndex}`} style={{ width: '100%', paddingBottom: '10px' }}>
                                <Group style={{ padding: '0px' }}>
                                  <Label style={{ fontWeight: 600, fontSize: '15px' }}>{transferText(servico.name)}</Label>
                                  <label style={{ position: 'absolute', right: '15px', top: '20px' }}>
                                    <Money>{formatCurrency(servico.requestedAmount * servico.price.amount, false)}</Money>
                                  </label>
                                  <Label style={{ fontSize: '12px', listStyle: 'none', position: 'absolute', right: '10px', top: '40px', textAlign: 'right' }}>{`${formatCurrency(servico.price.amount)} / unidade`}</Label>
                                </Group>
                                <Group style={{ padding: '0px', marginTop: '15px' }}>
                                  <Label>
                                    {transferText(servico.type)} {transferText(servico.quantity)}ml | {formatCurrency(servico.price.amount)} por unidade
                              </Label>
                                  <Label>
                                    {servico.requestedAmount} por dia
                              </Label>
                                </Group>
                              </Card>
                            ))}
                            {transferBudget.onboardServices && transferBudget.onboardServices.lunchBoxes.map((lunchBox, lunchBoxIndex) => (
                              <Card key={`transfer_lunchBox_${lunchBoxIndex}`} style={{ width: '100%', paddingBottom: '10px' }}>
                                <Group style={{ padding: '0px' }}>
                                  <Label style={{ fontWeight: 600, fontSize: '15px' }}>Lunchbox ({lunchBox.name})</Label>
                                  <label style={{ position: 'absolute', right: '15px', top: '20px' }}>
                                    <Money>{formatCurrency(lunchBox.price.amount * lunchBox.quantity, false)}</Money>
                                  </label>
                                  <Label style={{ fontSize: '12px', listStyle: 'none', position: 'absolute', right: '10px', top: '40px', textAlign: 'right' }}>{`${formatCurrency(lunchBox.price.amount)} / unidade`}</Label>
                                </Group>
                                <Group style={{ padding: '0px', marginTop: '15px' }}>
                                  <Label>
                                    {formatCurrency(lunchBox.price.amount)} por unidade | {lunchBox.quantity} unidades por dia
                              </Label>
                                </Group>
                              </Card>
                            ))}
                          </>}
                          {briefingItem.hasCoordinator && transferBudget.coordinatorPrices && <>
                            <Label style={{ fontWeight: 600, fontSize: '14px', paddingTop: '20px' }}>Profissionais</Label>
                            <Card style={{ width: '100%', paddingBottom: '10px' }}>
                              <Group style={{ padding: '0px' }}>
                                <Label style={{ fontWeight: 600, fontSize: '15px' }}>Coordenador/receptivo {transferText(transferBudget.coordinatorPrices.type)}</Label>
                                <label style={{ position: 'absolute', right: '15px', top: '20px' }}>
                                  <Money>{formatCurrency(
                                    transferBudget.coordinatorPrices.quantity *
                                    transferBudget.coordinatorPrices.pricePerDay.amount *
                                    transferBudget.coordinatorPrices.days, false)}</Money>
                                </label>
                                <Label style={{ fontSize: '12px', listStyle: 'none', position: 'absolute', right: '10px', top: '40px', textAlign: 'right' }}>{`${formatCurrency(transferBudget.coordinatorPrices.pricePerDay.amount)} / dia`}</Label>
                              </Group>
                              <Group style={{ padding: '0px', marginTop: '15px' }}>
                                <Label>
                                  {transferBudget.coordinatorPrices.quantity} profissionais | {formatCurrency(transferBudget.coordinatorPrices.pricePerDay.amount)} por profissional por dia
                            </Label>
                                <Label>
                                  {formatCurrency(transferBudget.coordinatorPrices.overtimePrice.amount)} / hora extra / profissional
                            </Label>
                              </Group>
                            </Card>
                          </>}
                        </>
                      }
                      </Card>
                  })}
            </>}

            {(this.state.otherServices.requested && this.state.otherServices.budget && this.state.otherServicesBriefing) && <>
                <Group style={{ position: 'relative' }}>
                  <Titulo style={{ paddingTop: '30px', fontSize: '15px' }}>Outros serviços</Titulo>
                  {temFeatureFlag('showCommission') && this.state.otherServices.budget.commission && this.state.otherServices.budget.commission.hasCommission && <Tag style={{ left: '130px', top: '30px' }} status={'COMISSIONAMENTO'}>Comissão: {this.geraComissao(this.state.otherServices.budget.commission.fee)}</Tag>}
                </Group>
                {this.state.otherServicesBriefing.services &&
                Object.keys(this.state.otherServicesBriefing.services).filter(item =>
                  this.state.otherServicesBriefing.services[item].requested).map(item =>
                    item === 'additionalServices' ? this.SolicitacaoAdditionalService(this.state.otherServicesBriefing.services[item]) : this.Solicitacao(item, this.state.otherServicesBriefing.services[item]))}
            </>}
            {DISPLAY_CHAT && <Card>
              <ChatRoom eventId={eventId} supplierId={supplierId} eventName={eventName} eventStatus={eventStatus} isMultiplo={CHAT_MULTIPLO} fornecedores={fornecedoresEscolhidos} />
            </Card>}
            </Main>
          </Container>
        </Body>
      </MainDesktop>
    </>
  }

  menuLateralDireito = () => {
    const { history } = this.props
    const { eventId } = this.props.match.params
    return this.menuLateralDireitoResumo({
    paginaAtual: 'v2',
    textoPrimario: temFeatureFlag('approval') ? 'Submeter para aprovação' : 'Contratar este fornecedor',
    textoSecundario: 'Voltar',
    urlBtnSecundario: () => history.push(`/organizer/event/${eventId}/budget/account/${this.getSupplierId()}`)})
  }

  verificaSeTodosOsServicosForamSelecionados = () => {
    const { allInfo } = this.state
    const transfer = allInfo && allInfo.services && allInfo.services.transfer.requested ? !!allInfo.services.transfer.choosedSupplier : true
    const otherServices = allInfo && allInfo.services && allInfo.services.otherServices.requested ? !!allInfo.services.otherServices.choosedSupplier : true
    return transfer && otherServices
  }

  verificaSeTodosOsServicosForamSolicitadosParaAprovacao = () => {
    const { allInfo, event } = this.state
    const transfer = allInfo && allInfo.services && allInfo.services.transfer.requested ? (event.approval && event.approval.services.transfer.status === 'AWAITING_APPROVAL') : true
    const otherServices = allInfo && allInfo.services && allInfo.services.otherServices.requested ? (event.approval && event.approval.services.otherServices.status === 'AWAITING_APPROVAL') : true
    return transfer && otherServices
  }

  menuLateralDireitoResumo = ({
    paginaAtual,
    textoPrimario,
    textoSecundario,
    urlBtnPrimario,
    urlBtnSecundario}) => {
    textoPrimario = textoPrimario || temFeatureFlag('approval') ? 'Submeter para aprovação' : 'Contratar este fornecedor'
    textoSecundario = textoSecundario || 'Voltar'
    urlBtnPrimario =  urlBtnPrimario || (() => this.setState({ modalVerificacao: true }))
    urlBtnSecundario =  urlBtnSecundario || (() => this.setState({ modalVerificacao: true }))
    if(paginaAtual !== this.state.paginaAtual)
      this.setState({ paginaAtual })
    const getSupplierUserInfo = this.getSupplierUserInfo()
    return <FixedDiv>
      <Card style={{ width: '100%' }}>
        <Label style={{ textTransform: 'uppercase' }}>Resumo do Evento <span style={{ float: 'right', paddingRight: '10px' }}>ID:{this.state.event.internalId || this.state.event.friendlyId}</span></Label>
        <Group>
          <Label style={{ fontWeight: 600, color: '#484848' }}>{this.state.event.name}</Label>
          <Label>Solicitado há {this.state.event.created ? moment().diff(moment(this.state.event.created, 'YYYY-MM-DD[T]HH:mm:ssZ'), 'days') : 0} dias</Label>
          <Group style={{ display: 'flex' }}>
            <Icons
              icon={'calendario'}
              color={'gray'}
              width={40}
              height={20}
              style={{ marginRight: '-5px', marginTop: '5px' }} />
            <Label>{formatDate(this.state.event.start)} - {formatDate(this.state.event.end)}</Label>
          </Group>
          <Group style={{ display: 'flex' }}>
            <Icons
              icon={'map-pin'}
              color={'gray'}
              width={40}
              height={20}
              style={{ marginRight: '-5px', marginTop: '5px', marginLeft: '2px' }} />
            {this.state.event.location && <Label>{this.state.event.location.address} - {this.state.event.location.city}</Label>}
          </Group>
          {this.state.event.numberOfParticipants > 0 && <Group style={{ display: 'flex' }}>
            <Icons
              icon={'pessoas'}
              color={'gray'}
              width={40}
              height={20}
              style={{ marginTop: '5px', marginLeft: '-3px' }} />
            <Label>{this.state.event.numberOfParticipants} pessoas</Label>
          </Group>}
        </Group>
      </Card>
      <Card style={{ width: '100%' }}>
        <Label style={{ textTransform: 'uppercase' }}>Resumo do pedido</Label>
        <Title style={{ fontSize: '14px', marginTop: '20px', marginBottom: '10px' }}>{this.getSupplierNames()}</Title>
        {/* <SubTitulo style={{ fontSize: '14px', marginBottom: '10px' }}>{this.getSupplierContact()}</SubTitulo> */}
        {ehConsultor() && <>
          <SubTitulo style={{ fontSize: '14px', marginBottom: '10px' }}>{`${getSupplierUserInfo.name} - ${getSupplierUserInfo.phone}`}</SubTitulo>
          <SubTitulo style={{ fontSize: '14px', marginBottom: '10px' }}>{getSupplierUserInfo.email}</SubTitulo>
        </>}

        <SubTitulo style={{ fontSize: '14px', marginBottom: '10px' }}>{this.getSupplierAddress()}</SubTitulo>
        {this.state.lodging.requested && <ItemsMenuLateral selected={false}>
          <Group style={{ padding: '0', display: 'flex' }}>
            <Icons
              icon={'bed-outline'}
              viewBox='0 0 1536 1024'
              width={40}
              height={20}
              color={'#484848'}
              style={{ verticalAlign: 'middle', margin: 'auto 10px auto auto' }}
            />
            <Group style={{ padding: '0', display: 'flex', width: '100%' }}>
              <Label>Hospedagem</Label>
              <Label style={{ textAlign: 'right', color: defaultTheme.colors.primary.default }}>{formatCurrency(this.getTotalPriceLodging())}</Label>
            </Group>
          </Group>
        </ItemsMenuLateral>}
        {this.state.room.requested && <ItemsMenuLateral selected={false}>
          <Group style={{ padding: '0', display: 'flex' }}>
            <Icons
              icon={'room-outline'}
              viewBox='0 0 1536 1024'
              width={40}
              height={20}
              color={'#484848'}
              style={{ verticalAlign: 'middle', margin: 'auto 10px auto auto' }}
            />
            <Group style={{ padding: '0', display: 'flex', width: '100%' }}>
              <Label>Salas</Label>
              <Label style={{ textAlign: 'right', color: defaultTheme.colors.primary.default }}>{formatCurrency(this.getTotalPriceRoom())}</Label>
            </Group>
          </Group>
        </ItemsMenuLateral>}
        {this.state.foodsAndBeverages.requested && <ItemsMenuLateral selected={false}>
          <Group style={{ padding: '0', display: 'flex' }}>
            <Icons
              icon={'food-beverage-outline'}
              width={40}
              height={20}
              color={'#484848'}
              style={{ verticalAlign: 'middle', margin: 'auto 10px auto auto' }}
            />
            <Group style={{ padding: '0', display: 'flex', width: '100%' }}>
              <Label>Alimentos e bebidas</Label>
              <Label style={{ textAlign: 'right', color: defaultTheme.colors.primary.default }}>{formatCurrency(this.getTotalPriceFoodsAndBeverages())}</Label>
            </Group>
          </Group>
        </ItemsMenuLateral>}
        {this.state.equipments.requested && <ItemsMenuLateral selected={false}>
          <Group style={{ padding: '0', display: 'flex' }}>
            <Icons
              icon={'computer-outline'}
              viewBox='0 0 1280 1024'
              width={40}
              height={20}
              color={'#484848'}
              style={{ verticalAlign: 'middle', margin: 'auto 10px auto auto' }}
            />
            <Group style={{ padding: '0', display: 'flex', width: '100%' }}>
              <Label>Equipamentos</Label>
              <Label style={{ textAlign: 'right', color: defaultTheme.colors.primary.default }}>{formatCurrency(this.getTotalPriceEquipments())}</Label>
            </Group>
          </Group>
        </ItemsMenuLateral>}
        {this.state.transfer.requested && this.state.transfer.budget && <ItemsMenuLateral selected={false}>
          <Group style={{ padding: '0', display: 'flex' }}>
            <Icons
              icon={'bus-outline'}
              width={40}
              height={20}
              color={'#484848'}
              style={{ verticalAlign: 'middle', margin: 'auto 10px auto auto' }}
            />
            <Group style={{ padding: '0', display: 'flex', width: '100%' }}>
              <Label>Transfer</Label>
              <Label style={{ textAlign: 'right', color: defaultTheme.colors.primary.default }}>{formatCurrency(this.retornaValorTotalTransfer())}</Label>
            </Group>
          </Group>
        </ItemsMenuLateral>}
        {this.state.otherServices.requested && this.state.otherServices.budget && <ItemsMenuLateral selected={false}>
          <Group style={{ padding: '0', display: 'flex' }}>
            <Icons
              icon={'outros-servicos-outline'}
              width={40}
              height={20}
              color={'#484848'}
              style={{ verticalAlign: 'middle', margin: 'auto 10px auto auto' }}
            />
            <Group style={{ padding: '0', display: 'flex', width: '100%' }}>
              <Label>Outros serviços</Label>
              <Label style={{ textAlign: 'right', color: defaultTheme.colors.primary.default }}>{formatCurrency(this.getTotalPriceOtherServices())}</Label>
            </Group>
          </Group>
        </ItemsMenuLateral>}
        <ItemsMenuLateral selected={false}>
          <Group style={{ padding: '0', display: 'flex' }}>
            <Label>Service Fee</Label>
            <Label style={{ textAlign: 'right', color: defaultTheme.colors.primary.default }}>{formatCurrency(this.getTotalWithFee() - this.getTotal())}</Label>
          </Group>
        </ItemsMenuLateral>
        <ItemsMenuLateral selected={true}>
          <Group style={{ padding: '0', display: 'flex' }}>
            <Label>Valor total</Label>
            <Money>{formatCurrency(this.getTotalWithFee(), false)}</Money>
          </Group>
        </ItemsMenuLateral>
        {paginaAtual === 'v2' && <Button
          type='submit'
          category={'secondary'}
          onClick={urlBtnPrimario}
          fontWeight={800}
          full>
          {textoPrimario}
        </Button>}
        <Button
          type='submit'
          category={'secondary'}
          fontWeight={800}
          id={'btnVoltar'}
          ghost
          onClick={urlBtnSecundario}
          full>
          {textoSecundario}
        </Button>
      </Card>
    </FixedDiv>
  }

  render() {
    return this.gerenciaResponsividade()
  }

  loadDataServices = () => {
    this.setState({isLoading: true})
    const { api: { base, version } } = config
    const { eventId, accountId } = this.props.match.params
    const userType = window.localStorage.getItem('user_type')
    const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
    const supplierId = accountId || this.getSupplierId()
    const endpoint = `${base}${version}${eventEndpoint}/${eventId}/budget/account/${supplierId}`
    return Get(endpoint)
      .then(response => {
        const budgets = []
        response.budgets.foodsAndBeverages.mostRecent && Object.keys(response.budgets.foodsAndBeverages.mostRecent.menusBudgets).map((key) => {
          let budget = response.budgets.foodsAndBeverages.mostRecent.menusBudgets[key]
          budget.name = key
          if (budget.budgets.length > 0) {
            budget.budgets = budget.budgets.map((item) => {
              item.selected = item.chosen || false
              return item
            })
          }
          Object.keys(response.briefings.foodsAndBeverages).filter(briefingKey => response.briefings.foodsAndBeverages[briefingKey] && response.briefings.foodsAndBeverages[briefingKey].name && response.briefings.foodsAndBeverages[briefingKey].name === key).map((briefingKey) => {
            return budget.briefing = response.briefings.foodsAndBeverages[briefingKey]
          })
          return budgets.push(budget)
        })

        const lodging = this.state.lodging
        const room = this.state.room
        const foodsAndBeverages = this.state.foodsAndBeverages
        const equipments = this.state.equipments
        const transfer = this.state.transfer
        const otherServices = this.state.otherServices

        if(lodging && lodging.requested && !lodging.budget) {
          lodging.budget = response.budgets.lodging.mostRecent
        } if(room && room.requested && !room.budget) {
          room.budget = response.budgets.rooms.mostRecent
        } if(foodsAndBeverages && foodsAndBeverages.requested && !foodsAndBeverages.budget) {
          foodsAndBeverages.budget = response.budgets.foodsAndBeverages.mostRecent
        } if(equipments && equipments.requested && !equipments.budget) {
          equipments.budget = response.budgets.equipments.mostRecent
        } if(transfer && transfer.requested && !transfer.budget) {
          transfer.budget = response.budgets.transfer.mostRecent
        } if(otherServices && otherServices.requested && !otherServices.budget) {
          otherServices.budget = response.budgets.otherServices.mostRecent
        }

        this.setState({
          lodging,
          room,
          foodsAndBeverages,
          equipments,
          transfer,
          otherServices,
          briefing: response,
          roomBriefing: {
            showItems: [],
            hotel: response,
            briefing: response.briefings.rooms,
            budget: response.budgets.rooms.mostRecent, // Como hoje nós temos mais de um budget de quarto e vamos só mostrar um aqui agora vamos pegar o mais recente
          },
          foodsAndBeveragesBriefing: response.budgets.foodsAndBeverages.mostRecent ? {
            showItems: [],
            budgets,
            expireDate: response.budgets.foodsAndBeverages.expireDate,
            name: response.name,
            observations: response.budgets.foodsAndBeverages.mostRecent.observations,
            budgetId: response.budgets.foodsAndBeverages.mostRecent.id
          } : {
            showItems: [],
            budgets: [],
            budgetId: '',
            expireDate: '',
            name: 'response.name',
            observations: ''
          },
          equipmentsBriefing: {
            showItems: [],
            briefing: response.briefings.equipments,
            budget: response.budgets.equipments.mostRecent
          },
          transferBriefing: response.briefings.transfer,
          otherServicesBriefing: response.briefings.otherServices
        }, () => this.fetchAllbudgets())
      })
      .catch(e => {
        this.setState({isLoading: false}, console.error(e))
      })
  }
}

export default ResumoTotal
