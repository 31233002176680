/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
/* COMPONENTS */
import GerenciaFluxoEdicao from '../../../../components/GerenciaFluxoEdicao'
import Header from '../../../Header'
import MainDesktop from '../../../../components/Main'
import Body from '../../../../components/Body'
import Button from '../../../../components/ui/Button'
import Alert from '../../../../components/ui/Alert'
import Select from '../../../../components/ui/Select'
import RoundButton from '../../../../components/ui/RoundButton'
import RightBoxInput from '../../../../components/ui/RightBoxInput'
import Textarea from '../../../../components/ui/Textarea'
import InputDatepicker from '../../../../components/InputDatepicker'
import CheckBox from '../../../../components/ui/CheckBox'
import Card from '../../../../components/ui/Card'
import Icons from '../../../../components/ui/Icons'
import BreadCrumb from '../../../../components/BreadCrumb'
import Loader from '../../../../components/Loader'
import config from '../../../../../middleware/config'
import { Get, Post, Put } from '../../../../../utils/request'
import { getDateRange, formatDate } from '../../../../../utils/dates'
import { defaultTheme } from '../../../../styles/themes'
import { getNameFromKey } from '../../../../../utils/fabTexts'
import { ehChainUser } from '../../../../../utils/tiposUsuario'
import moment from 'moment'
import ServicosSolicitados from '../../../../components/ServicosSolicitados';
import { retornaUrlFluxoEdicao, retornaUrlFluxoCriacao } from '../../../../../utils/fluxoCriacaoEvento';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #fff;
  & * { font-family: 'Open Sans', sans-serif }
`

const Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  font-family: ${defaultTheme.fonts.secondary};
  padding: 3px 10px 13px 10px;
  color: #484848;
`

const Main = styled.div`
  width: 100%;
  background: #fff;
  flex: 1 0 auto;
`

const Label = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  box-sizing: border-box;
  padding: 5px 5px 5px 0px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
`

const Group = styled.div`
`

const FixedDiv = styled.div`
  position: fixed;
  top: 46px;
  bottom: 0;
  width: 25%;
  overflow-y: scroll;
  margin-top: 14px;
`

class FormEquipments extends Component {
  constructor() {
    super()
    this.state = {
      observations: '',
      projector: {
        requests: [
          {
            dates : [],
            amount : 1
          }
        ],
        rooms: [],
        observation: '',
        requested: false
      },
      screen: {
        requests: [
          {
            dates : [],
            amount : 1
          }
        ],
        rooms: [],
        observation: '',
        requested: false,
      },
      sound: {
        requests: [
          {
            dates : [],
            amount : 1
          }
        ],
        rooms: [],
        observation: '',
        requested: false
      },
      radio: {
        requests: [
          {
            dates : [],
            amount : 1
          }
        ],
        rooms: [],
        observation: '',
        requested: false
      },
      operator: {
        requests: [
          {
            dates : [],
            amount : 1
          }
        ],
        rooms: [],
        observation: '',
        requested: false
      },
      generator: {
        requests: [
          {
            dates : [],
            amount : 1
          }
        ],
        rooms: [],
        observation: '',
        requested: false
      },
      microphone: {
        requests: [
          {
            dates : [],
            numberOfLapel: 0,
            numberOfGooseneck: 0,
            numberOfHeadSet: 0,
            numberOfStick: 1
          }
        ],
        observation: '',
        requested: false
      },
      flipchart: {
        requests: [
          {
            dates : [],
            amount : 1
          }
        ],
        rooms: [],
        observation: '',
        requested: false
      },
      laserPoint: {
        requests: [
          {
            dates : [],
            amount : 1
          }
        ],
        rooms: [],
        observation: '',
        requested: false
      },
      notebook: {
        requests: [
          {
            dates : [],
            amount : 1
          }
        ],
        rooms: [],
        observation: '',
        requested: false
      },
      lighting: {
        requests: [
          {
            dates : [],
            amount : 1
          }
        ],
        rooms: [],
        observation: '',
        requested: false,
        type : "Direta"
      },
      simultaneousTranslation: {
        requests: [
          {
            dates : [],
            amount : 1
          }
        ],
        rooms: [],
        languages: ["en-US"],
        interpreter: false,
        observation: '',
        requested: false,
      },
      internet: {
        requests: [
          {
            dates : [],
            amount : 1
          }
        ],
        rooms: [],
        type: "wifi",
        mbSpeed: 30,
        observation: '',
        requested: false,
      },
      services: '',
      eventStartDate:'',
      eventEndDate:'',
      eventStatus: '',
      numberOfParticipants:'',
      name:'',
      location: {},
      salvaEdicao: false
    }
  }

  componentDidMount() {
    if (ehChainUser()) {
      alert('Você não tem permissão para acessar esta página.')
      this.props.history.push(`/organizer`)
    }
    this.setState({ isLoading: true }, () => {
      const { briefing, id } = this.props.match.params
      const { api: { base, version } } = config
      const userType = window.localStorage.getItem('user_type')
      const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
      const newEndpoint = `${base}${version}${eventEndpoint}/${id}`
      Get(newEndpoint)
      .then((response) => {
        this.setState({ isLoading: false })
        if (!response) {
          this.setState({ errorMessage: response.message })
          return Promise.reject(response)
        } else {
          const state = this.state
          state.services = response.services
          state.eventStartDate = response.start
          state.eventEndDate = response.end
          state.eventStatus = response.status
          state.numberOfParticipants = response.numberOfParticipants
          state.eventFriendlyId = response.internalId || response.friendlyId
          state.name = response.name
          state.location = response.location
          state.projector.requests.map(item => {
            item.dates = getDateRange(new Date(response.start), new Date(response.end))
            item.amount = item.amount === 0 ? 1 : item.amount
            return item
          })
          state.screen.requests.map(item => {
            item.dates = getDateRange(new Date(response.start), new Date(response.end))
            item.amount = item.amount === 0 ? 1 : item.amount
            return item
          })
          state.sound.requests.map(item => {
            item.dates = getDateRange(new Date(response.start), new Date(response.end))
            item.amount = item.amount === 0 ? 1 : item.amount
            return item
          })
          state.radio.requests.map(item => {
            item.dates = getDateRange(new Date(response.start), new Date(response.end))
            item.amount = item.amount === 0 ? 1 : item.amount
            return item
          })
          state.operator.requests.map(item => {
            item.dates = getDateRange(new Date(response.start), new Date(response.end))
            item.amount = item.amount === 0 ? 1 : item.amount
            return item
          })
          state.generator.requests.map(item => {
            item.dates = getDateRange(new Date(response.start), new Date(response.end))
            item.amount = item.amount === 0 ? 1 : item.amount
            return item
          })
          state.microphone.requests.map(item => {
            item.dates = getDateRange(new Date(response.start), new Date(response.end))
            item.numberOfLapel = item.numberOfLapel === 0 ? 0 : item.numberOfLapel
            item.numberOfGooseneck = item.numberOfGooseneck === 0 ? 0 : item.numberOfGooseneck
            item.numberOfHeadSet = item.numberOfHeadSet === 0 ? 0 : item.numberOfHeadSet
            item.numberOfStick = item.numberOfStick === 0 ? 1 : item.numberOfStick
            return item
          })
          state.flipchart.requests.map(item => {
            item.dates = getDateRange(new Date(response.start), new Date(response.end))
            item.amount = item.amount === 0 ? 1 : item.amount
            return item
          })
          state.laserPoint.requests.map(item => {
            item.dates = getDateRange(new Date(response.start), new Date(response.end))
            item.amount = item.amount === 0 ? 1 : item.amount
            return item
          })
          state.notebook.requests.map(item => {
            item.dates = getDateRange(new Date(response.start), new Date(response.end))
            item.amount = item.amount === 0 ? 1 : item.amount
            return item
          })
          state.lighting.requests.map(item => {
            item.dates = getDateRange(new Date(response.start), new Date(response.end))
            item.amount = item.amount === 0 ? 1 : item.amount
            return item
          })
          state.simultaneousTranslation.requests.map(item => {
            item.dates = getDateRange(new Date(response.start), new Date(response.end))
            item.amount = item.amount === 0 ? 1 : item.amount
            return item
          })
          state.internet.requests.map(item => {
            item.dates = getDateRange(new Date(response.start), new Date(response.end))
            item.amount = item.amount === 0 ? 1 : item.amount
            return item
          })
          this.setState(state, () => {
            if (briefing) {
              this.fetchBriefing()
            }
          })
        }
      }).catch(err => {
        this.setState({ isLoading: false })
        console.log('Error: ', err)
      })
    })
  }

  fetchBriefing = () => {
    const { id, briefing } = this.props.match.params
    const { api: { base, version } } = config
    const userType = window.localStorage.getItem('user_type')
    const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
    const endpoint = `${base}${version}${eventEndpoint}/${id}/briefing/equipments/${briefing}`
    Get(endpoint)
    .then((response) => {
      var manipulandoDados = response
      Object.keys(manipulandoDados).filter(item => manipulandoDados[item] && manipulandoDados[item].requests && manipulandoDados[item].requests.length > 0).map(item => {
        var equipamento = manipulandoDados[item]
        equipamento.requests.map(request => {
          request.dates = request.dates.map(data => new Date(data))
          return request
        })
        return equipamento
      })
      manipulandoDados.simultaneousTranslation.languages = manipulandoDados.simultaneousTranslation.languages[0]
      this.setState({ ...manipulandoDados, briefing: response })
    }).catch(err => {
      this.setState({ isLoading: false })
      console.log('Error: ', err)
    })
  }

  click = (session) => {
    const state = this.state
    state[session].requested = !state[session].requested
    this.setState(state)
  }

  handlerValue = (input, session, index) => {
    const state = this.state
    const { name, value } = input.target
    state[session].requests[index][name] = value
    this.setState(state)
    return state[session].requests[index][name]
  }

  changeAmount = (session, index, type, requestType) => {
    const state = this.state
    if(requestType) {
      state[session].requests[index][requestType] = type === '+' ? (state[session].requests[index][requestType] + 1) : (state[session].requests[index][requestType] - 1)
    } else {
      state[session].requests[index].amount = type === '+' ? (state[session].requests[index].amount + 1) : (state[session].requests[index].amount - 1)
    }
    this.setState(state)
  }

  addNewItem = (session) => {
    const state = this.state
    state[session].requests.push({
      dates: getDateRange(new Date(this.state.eventStartDate), new Date(this.state.eventEndDate)),
      amount: 0
    })
    this.setState(state)
  }

  addNewMicrofone = (session) => {
    const state = this.state
    state[session].requests.push({
      dates: getDateRange(new Date(this.state.eventStartDate), new Date(this.state.eventEndDate)),
      numberOfLapel: 0,
      numberOfGooseneck: 0,
      numberOfHeadSet: 0,
      numberOfStick: 0
    })
    this.setState(state)
  }

  handleObservation = (input, session) => {
    const state = this.state
    const { value } = input.target
    state[session].observation = value
    this.setState(state)
  }

  handleType = (input, session) => {
    const state = this.state
    const { name, value } = input.target
    state[session][name] = value
    this.setState(state)
  }

  submit = (e) => {
    e && e.preventDefault()
    if(!this.isValid()) return alert('Selecione ao menos uma unidade nos equipamentos selecionados no menu.')
    if(!this.isValidDates()) return alert('O período das datas só pode ser entre as datas do evento (podendo ser um dia antes / um dia depois).')
    this.setState({
      isLoading: true
    })
    const { api: { base, version } } = config
    const { id, briefing } = this.props.match.params
    const userType = window.localStorage.getItem('user_type')
    const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
    const endpoint = `${base}${version}${eventEndpoint}/${id}/briefing/equipments/`
    const briefingEndpoint = `${base}${version}${eventEndpoint}/${id}/briefing/equipments/${briefing}`
    if(briefing) {
      Put(briefingEndpoint, this.generateJSON())
      .then(() => {
        this.props.history.push(retornaUrlFluxoEdicao(this.state.services, id, 'equipments'))
      }).catch(err => {
        console.error(err.message)
        this.setState({
          isLoading: false
        })
        alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
      })

    } else {
      Post(endpoint, this.generateJSON())
      .then(() => {
        this.props.history.push(retornaUrlFluxoCriacao(this.state.services, id, this.state.eventStatus, 'equipments'))
      }).catch(err => {
        console.error(err.message)
        this.setState({
          isLoading: false
        })
        alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
      })
    }
  }

  checkEdicao = (e) => {
    e && e.preventDefault()
    if(!this.isValid()) return alert('Selecione ao menos uma unidade nos equipamentos selecionados no menu.')
    if(!this.isValidDates()) return alert('O período das datas só pode ser entre as datas do evento (podendo ser um dia antes / um dia depois).')
    this.setState({ salvaEdicao: true }, () => this.setState({ salvaEdicao: false }))
  }

  isValid = () => {
    return !(Object.keys(this.state).filter(item => this.state[item] && this.state[item].requested).some(item => {
      if(item !== 'microphone') return this.state[item].requests.some(request => !request.amount || request.amount <= 0)
      else return this.state[item].requests.some(request =>
          ((!request.numberOfLapel || request.numberOfLapel <= 0) &&
          (!request.numberOfGooseneck || request.numberOfGooseneck <= 0) &&
          (!request.numberOfHeadSet || request.numberOfHeadSet <= 0) &&
          (!request.numberOfStick || request.numberOfStick <= 0)
          )
        )
    }))
  }

  isValidDates = () => {
    return !(Object.keys(this.state).filter(item => this.state[item] && this.state[item].requested).some(item => {
      return this.state[item].requests.some(request => request.dates.some((date) => {
        var diasAntes = item === 'internet' ? -6 : 0
        if(!(moment(date, 'YYYY-MM-DD[T]HH:mm:ssZ').diff(moment(this.state.eventStartDate, 'YYYY-MM-DD[T]HH:mm:ssZ'), 'days') >= diasAntes &&
          moment(date, 'YYYY-MM-DD[T]HH:mm:ssZ').diff(moment(this.state.eventEndDate, 'YYYY-MM-DD[T]HH:mm:ssZ'), 'days') <= 1)) {
          return true
        }
        return false
      }))
    }))
  }

  generateJSON = () => {
    var verificaTraducao = this.state.simultaneousTranslation
    verificaTraducao.languages = Array.prototype.concat(verificaTraducao.languages)

    return {
      ...this.state.briefing,
      projector: this.state.projector,
      screen: this.state.screen,
      sound: this.state.sound,
      microphone: this.state.microphone,
      flipchart: this.state.flipchart,
      laserPoint: this.state.laserPoint,
      notebook: this.state.notebook,
      lighting: this.state.lighting,
      simultaneousTranslation: verificaTraducao,
      internet: this.state.internet,
      radio: this.state.radio,
      generator: this.state.generator,
      operator: this.state.operator
    }
  }

  gerenciaResponsividade = () => {
    if(window.innerWidth < 600) return this.paginaMobile()
    else return this.paginaDesktop()
  }

  paginaMobile = () => {
    const { id } = this.props.match.params
    const { services} = this.state
    const lodging = services.lodging && services.lodging.requested && (services.lodging.id ? `/organizer/event/lodging/${id}/${services.lodging.id}` : `/organizer/event/lodging/${id}`)
    const room = services.room && services.room.requested && (services.room.id ? `/organizer/event/rooms/${id}/${services.room.id}` : `/organizer/event/rooms/${id}`)
    const foodsAndBeverages = services.foodsAndBeverages && services.foodsAndBeverages.requested && (services.foodsAndBeverages.id ? `/organizer/event/foodsAndBeverages/${id}/${services.foodsAndBeverages.id}` : `/organizer/event/foodsAndBeverages/${id}`)
    return <Container>
      <BreadCrumb options={
          [
            {link: '/organizer', name: 'Home'},
            {link: `/organizer/event/${id}`, name: 'Dados gerais do evento'},
            lodging && {link: lodging, name: 'Hospedagem'},
            room && {link: room, name: 'Salas'},
            foodsAndBeverages && {link: foodsAndBeverages, name: 'Alimentos e bebidas'},
            {name: 'Equipamentos'}
          ]
        } />
        <Main>
          {this.state.isLoading ? <Loader /> : null}
          <Group style={{width: '100%', margin: 'auto'}}>
            <Title style={{ width: '100%', paddingTop: '20px' }}>Equipamentos</Title>
            <Group style={{ display: 'flex' }}>
              <Group style={{ width: '40%' }}>
                <Card style={{ width: '100%', paddingRight: '30px', height: '100%', fontSize: '15px'}}>
                  <CheckBox
                    onClick={() => this.click('projector')}
                    checked={this.state.projector.requested}
                    label={getNameFromKey('projector')}/>
                  <CheckBox
                    onClick={() => this.click('screen')}
                    checked={this.state.screen.requested}
                    label={getNameFromKey('screen')}/>
                  <CheckBox
                    onClick={() => this.click('sound')}
                    checked={this.state.sound.requested}
                    label={getNameFromKey('sound')}/>
                  <CheckBox
                    onClick={() => this.click('microphone')}
                    checked={this.state.microphone.requested}
                    label={getNameFromKey('microphone')}/>
                  <CheckBox
                    onClick={() => this.click('flipchart')}
                    checked={this.state.flipchart.requested}
                    label={getNameFromKey('flipchart')}/>
                  <CheckBox
                    onClick={() => this.click('laserPoint')}
                    checked={this.state.laserPoint.requested}
                    label={getNameFromKey('laserPoint')}/>
                  <CheckBox
                    onClick={() => this.click('notebook')}
                    checked={this.state.notebook.requested}
                    label={getNameFromKey('notebook')}/>
                  <CheckBox
                    onClick={() => this.click('lighting')}
                    checked={this.state.lighting.requested}
                    label={getNameFromKey('lighting')}/>
                  <CheckBox
                    onClick={() => this.click('simultaneousTranslation')}
                    checked={this.state.simultaneousTranslation.requested}
                    label={getNameFromKey('simultaneousTranslation')}/>
                  <CheckBox
                    onClick={() => this.click('internet')}
                    checked={this.state.internet.requested}
                    label={'Internet'}/>
                  <CheckBox
                    onClick={() => this.click('radio')}
                    checked={this.state.radio.requested}
                    label={getNameFromKey('radio')}/>
                  <CheckBox
                    onClick={() => this.click('operator')}
                    checked={this.state.operator.requested}
                    label={getNameFromKey('operator')}/>
                  <CheckBox
                    onClick={() => this.click('generator')}
                    checked={this.state.generator.requested}
                    label={getNameFromKey('generator')}/>
                </Card>
              </Group>
              <Group style={{ width: '60%' }}>
                {this.Item({ session:'projector' })}
                {this.Item({ session:'screen' })}
                {this.Item({ session:'sound' })}
                {this.ItemMicrofone({ session:'microphone' })}
                {this.Item({ session:'flipchart' })}
                {this.Item({ session:'laserPoint' })}
                {this.Item({ session:'notebook' })}
                {this.ItemIluminacao({ session:'lighting' })}
                {this.ItemTraducao({ session:'simultaneousTranslation' })}
                {this.ItemInternet({ session:'internet' })}
                {this.Item({ session:'radio' })}
                {this.Item({ session:'generator' })}
              </Group>
            </Group>
          </Group>
        </Main>
    </Container>
  }

  paginaDesktop = () => {
    const { id, briefing } = this.props.match.params
    const { services} = this.state
    const lodging = services.lodging && services.lodging.requested && (services.lodging.id ? `/organizer/event/lodging/${id}/${services.lodging.id}` : `/organizer/event/lodging/${id}`)
    const room = services.room && services.room.requested && (services.room.id ? `/organizer/event/rooms/${id}/${services.room.id}` : `/organizer/event/rooms/${id}`)
    const foodsAndBeverages = services.foodsAndBeverages && services.foodsAndBeverages.requested && (services.foodsAndBeverages.id ? `/organizer/event/foodsAndBeverages/${id}/${services.foodsAndBeverages.id}` : `/organizer/event/foodsAndBeverages/${id}`)
    const listaDeRedirecionamento = JSON.parse(window.localStorage.getItem('redirecionamento_edicao_fluxo'))
    const verificaRedirecionamento = JSON.parse(window.localStorage.getItem('redirecionamento_edicao_salas'))
    return <>
      <Header />
      <MainDesktop>
        <Body {...this.props} pagina={this.state.eventStatus === 'INACTIVE' ? 'create-evento' : 'editar-evento'} menuLateralDireito={this.menuLateralDireito()}>
          <GerenciaFluxoEdicao pagina={'equipamentos'} eventId={id} briefingId={briefing} form={this.generateJSON()} salvaEdicao={this.state.salvaEdicao} onSubmit={this.submit} />
          <Container>
          {this.state.eventStatus === 'INACTIVE' && <BreadCrumb options={
              [
                {link: '/organizer', name: 'Home'},
                {link: `/organizer/event/${id}`, name: 'Dados gerais do evento'},
                lodging && {link: lodging, name: 'Hospedagem'},
                room && {link: room, name: 'Salas'},
                foodsAndBeverages && {link: foodsAndBeverages, name: 'Alimentos e bebidas'},
                {name: 'Equipamentos'}
              ]
            } />}
            <Main>
              {this.state.isLoading ? <Loader /> : null}
              <Group style={{width: '100%', margin: 'auto'}}>
                <Title style={{ width: '100%', paddingTop: '20px' }}>Equipamentos</Title>
                {(listaDeRedirecionamento && listaDeRedirecionamento.length > 0) && <Alert type={'edition'} style={{ display: 'flex', marginBottom: '40px' }}>
                  <Icons
                    icon={'exclamation'}
                    color={'white'}
                    width={30}
                    style={{ margin: 'auto' }}
                    height={15} />
                    <p style={{ textAlign: 'justify' }}>
                      Você alterou a <span style={{ fontWeight: 'bold' }}>data</span> e/ou o <span style={{ fontWeight: 'bold' }}>número de pessoas</span> nos dados de seu evento. Acreditamos que você queira validar os dados abaixo para eventualmente alterar este mesmo aspecto também abaixo.
                    </p>
                </Alert>}
                {verificaRedirecionamento && <Alert type={'edition'} style={{ display: 'flex', marginBottom: '40px' }}>
                  <Icons
                    icon={'exclamation'}
                    color={'white'}
                    width={30}
                    height={15} />
                    <p style={{ textAlign: 'justify' }}>
                      Você alterou seu pedido para <span style={{ fontWeight: 'bold' }}>"salas"</span>  Acreditamos que você queira validar os dados abaixo para eventualmente alterar este mesmo aspecto também aqui.
                    </p>
                </Alert>}
                <Group style={{ display: 'flex' }}>
                  <Group style={{ width: '40%' }}>
                    <Card style={{ width: '100%', paddingRight: '30px', height: '100%', fontSize: '15px'}}>
                      <Title style={{ width: '100%', fontSize: '14px', textAlign: 'center' }}>O que deseja para este evento?</Title>
                      <CheckBox
                        onClick={() => this.click('projector')}
                        checked={this.state.projector.requested}
                        label={getNameFromKey('projector')}/>
                      <CheckBox
                        onClick={() => this.click('screen')}
                        checked={this.state.screen.requested}
                        label={getNameFromKey('screen')}/>
                      <CheckBox
                        onClick={() => this.click('sound')}
                        checked={this.state.sound.requested}
                        label={getNameFromKey('sound')}/>
                      <CheckBox
                        onClick={() => this.click('microphone')}
                        checked={this.state.microphone.requested}
                        label={getNameFromKey('microphone')}/>
                      <CheckBox
                        onClick={() => this.click('flipchart')}
                        checked={this.state.flipchart.requested}
                        label={getNameFromKey('flipchart')}/>
                      <CheckBox
                        onClick={() => this.click('laserPoint')}
                        checked={this.state.laserPoint.requested}
                        label={getNameFromKey('laserPoint')}/>
                      <CheckBox
                        onClick={() => this.click('notebook')}
                        checked={this.state.notebook.requested}
                        label={getNameFromKey('notebook')}/>
                      <CheckBox
                        onClick={() => this.click('lighting')}
                        checked={this.state.lighting.requested}
                        label={getNameFromKey('lighting')}/>
                      <CheckBox
                        onClick={() => this.click('simultaneousTranslation')}
                        checked={this.state.simultaneousTranslation.requested}
                        label={getNameFromKey('simultaneousTranslation')}/>
                      <CheckBox
                        onClick={() => this.click('internet')}
                        checked={this.state.internet.requested}
                        label={'Internet'}/>
                      <CheckBox
                        onClick={() => this.click('radio')}
                        checked={this.state.radio.requested}
                        label={getNameFromKey('radio')}/>
                      <CheckBox
                        onClick={() => this.click('operator')}
                        checked={this.state.operator.requested}
                        label={getNameFromKey('operator')}/>
                      <CheckBox
                        onClick={() => this.click('generator')}
                        checked={this.state.generator.requested}
                        label={getNameFromKey('generator')}/>
                    </Card>
                  </Group>
                  <Group style={{ width: '60%' }}>
                    {this.Item({ session: 'projector', listaDeRedirecionamento: listaDeRedirecionamento || [] })}
                    {this.Item({ session: 'screen', listaDeRedirecionamento: listaDeRedirecionamento || [] })}
                    {this.Item({ session: 'sound', listaDeRedirecionamento: listaDeRedirecionamento || [] })}
                    {this.ItemMicrofone({ session: 'microphone', listaDeRedirecionamento: listaDeRedirecionamento || [] })}
                    {this.Item({ session: 'flipchart', listaDeRedirecionamento: listaDeRedirecionamento || [] })}
                    {this.Item({ session: 'laserPoint', listaDeRedirecionamento: listaDeRedirecionamento || [] })}
                    {this.Item({ session: 'notebook', listaDeRedirecionamento: listaDeRedirecionamento || [] })}
                    {this.ItemIluminacao({ session: 'lighting', listaDeRedirecionamento: listaDeRedirecionamento || [] })}
                    {this.ItemTraducao({ session: 'simultaneousTranslation', listaDeRedirecionamento: listaDeRedirecionamento || [] })}
                    {this.ItemInternet({ session: 'internet', listaDeRedirecionamento: listaDeRedirecionamento || [] })}
                    {this.Item({ session: 'radio', listaDeRedirecionamento: listaDeRedirecionamento || [] })}
                    {this.Item({ session: 'operator', listaDeRedirecionamento: listaDeRedirecionamento || [] })}
                    {this.Item({ session: 'generator', listaDeRedirecionamento: listaDeRedirecionamento || [] })}
                  </Group>
                </Group>
              </Group>
            </Main>
          </Container>
        </Body>
      </MainDesktop>
    </>
  }


  validaDataItem = (datas, session) => {
    var diasAntes = session === 'internet' ? -6 : 0
    return datas.some(data => !(moment(data, 'YYYY-MM-DD[T]HH:mm:ssZ').diff(moment(this.state.eventStartDate, 'YYYY-MM-DD[T]HH:mm:ssZ'), 'days') >= diasAntes &&
    moment(data, 'YYYY-MM-DD[T]HH:mm:ssZ').diff(moment(this.state.eventEndDate, 'YYYY-MM-DD[T]HH:mm:ssZ'), 'days') <= 1))
  }

  Item = (props) => {
    return <Group style={{ display: `${this.state[props.session].requested ? 'block' : 'none'}`}}>
        <Card style={{ width: '100%', marginLeft: '10px' }}>
          <Title style={{ width: '100%', fontSize: '14px' }}>{getNameFromKey(props.session)}</Title>
          {
            this.state[props.session].requests.map((item, index) => <Card style={{ width: '100%' }}>
              <InputDatepicker
                type={'multiple'}
                label={'Quando?'}
                placeholder={'Selecione a(s) data(s)'}
                name={'dates'}
                dates={ item.dates }
                error={ (!item.dates || item.dates.length === 0) || this.validaDataItem(item.dates, props.session) }
                onChange={input => this.handlerValue(input, props.session, index)}
                edited={props.listaDeRedirecionamento.some(item => item === 'start' || item === 'end')}
                isRequired />
                <Group style={{ display: 'flex', paddingTop: '20px', margin: 'auto', width: 'fit-content' }}>
                  <Label style={{ width: 'fit-content', paddingRight: '15px', color: item.amount <= 0 && 'red' }}>Quantidade:</Label>
                  <Group style={{ display: 'flex' }}>
                    <RoundButton style={{ width: '35px'}} onClick={() => this.changeAmount(props.session, index, '-')} disabled={item.amount < 1} simbolo={'-'} />
                    <Title style={{ width: '50px', marginTop: '5px', textAlign: 'center', color: item.amount > 0 ? defaultTheme.colors.whiteLabel.primary : 'red' }}>{item.amount}</Title>
                    <RoundButton style={{ width: '35px'}} onClick={() => this.changeAmount(props.session, index, '+')} simbolo={'+'} />
                  </Group>
                </Group>
              </Card>)
          }
          <Textarea
            type={'text'}
            label={'Observações'}
            placeholder={'Caso tenha alguma observação, escreva aqui.'}
            name={'observation'}
            value={this.state[props.session].observation}
            onChange={(input) => this.handleObservation(input, props.session)}
          />
          <Label onClick={() => this.addNewItem(props.session)} style={{ width: 'fit-content', paddingRight: '15px', color: defaultTheme.colors.whiteLabel.primary, textDecoration: 'underline' }}>Adicionar novo pedido de {getNameFromKey(props.session)}</Label>
        </Card>
      </Group>
  }

  ItemIluminacao = (props) => {
    return <Group style={{ display: `${this.state[props.session].requested ? 'block' : 'none'}`}}>
        <Card style={{ width: '100%', marginLeft: '10px' }}>
          <Title style={{ width: '100%', fontSize: '14px' }}>{getNameFromKey(props.session)}</Title>
          {
            this.state[props.session].requests.map((item, index) => <Card style={{ width: '100%' }}>
              <InputDatepicker
                type={'multiple'}
                label={'Quando?'}
                placeholder={'Selecione a(s) data(s)'}
                name={'dates'}
                dates={ item.dates }
                error={ (!item.dates || item.dates.length === 0) || this.validaDataItem(item.dates, props.session)}
                onChange={input => this.handlerValue(input, props.session, index)}
                edited={props.listaDeRedirecionamento.some(item => item === 'start' || item === 'end')}
                isRequired />
                <Group style={{ display: 'flex', paddingTop: '20px', margin: 'auto', width: 'fit-content' }}>
                  <Label style={{ width: 'fit-content', paddingRight: '15px', color: item.amount <= 0 && 'red' }}>Quantidade:</Label>
                  <Group style={{ display: 'flex' }}>
                    <RoundButton style={{ width: '35px'}} onClick={() => this.changeAmount(props.session, index, '-')} disabled={item.amount < 1} simbolo={'-'} />
                    <Title style={{ width: '50px', marginTop: '5px', textAlign: 'center', color: item.amount > 0 ? defaultTheme.colors.whiteLabel.primary : 'red' }}>{item.amount}</Title>
                    <RoundButton style={{ width: '35px'}} onClick={() => this.changeAmount(props.session, index, '+')} simbolo={'+'} />
                  </Group>
                </Group>
              </Card>)
          }
          <Select
            isRequired
            label={'Tipo'}
            name={'type'}
            value={this.state[props.session].type}
            onChange={(input) => this.handleType(input, props.session)}>
            <option value={'Direta'}>Direta</option>
            <option value={'Indireta'}>Indireta</option>
            <option value={'Difusa'}>Difusa</option>
            <option value={'Linear'}>Linear</option>
            <option value={'Efeito'}>Efeito</option>
            <option value={'Tarefa'}>Tarefa</option>
            <option value={'Destaque'}>Destaque</option>
          </Select>
          <Textarea
            type={'text'}
            label={'Observações'}
            placeholder={'Caso tenha alguma observação, escreva aqui.'}
            name={'observation'}
            value={this.state[props.session].observation}
            onChange={(input) => this.handleObservation(input, props.session)}
          />
          <Label onClick={() => this.addNewItem(props.session)} style={{ width: 'fit-content', paddingRight: '15px', color: defaultTheme.colors.whiteLabel.primary, textDecoration: 'underline' }}>Adicionar novo pedido de {getNameFromKey(props.session)}</Label>
        </Card>
      </Group>
  }

  ItemTraducao = (props) => {
    return <Group style={{ display: `${this.state[props.session].requested ? 'block' : 'none'}`}}>
        <Card style={{ width: '100%', marginLeft: '10px' }}>
          <Title style={{ width: '100%', fontSize: '14px' }}>{getNameFromKey(props.session)}</Title>
          {
            this.state[props.session].requests.map((item, index) => <Card style={{ width: '100%' }}>
              <InputDatepicker
                type={'multiple'}
                label={'Quando?'}
                placeholder={'Selecione a(s) data(s)'}
                name={'dates'}
                dates={ item.dates }
                error={ (!item.dates || item.dates.length === 0) || this.validaDataItem(item.dates, props.session) }
                onChange={input => this.handlerValue(input, props.session, index)}
                edited={props.listaDeRedirecionamento.some(item => item === 'start' || item === 'end')}
                isRequired />
                <Group style={{ display: 'flex', paddingTop: '20px', margin: 'auto', width: 'fit-content' }}>
                  <Label style={{ width: 'fit-content', paddingRight: '15px', color: item.amount <= 0 && 'red' }}>Quantidade:</Label>
                  <Group style={{ display: 'flex' }}>
                    <RoundButton style={{ width: '35px'}} onClick={() => this.changeAmount(props.session, index, '-')} disabled={item.amount < 1} simbolo={'-'} />
                    <Title style={{ width: '50px', marginTop: '5px', textAlign: 'center', color: item.amount > 0 ? defaultTheme.colors.whiteLabel.primary : 'red' }}>{item.amount}</Title>
                    <RoundButton style={{ width: '35px'}} onClick={() => this.changeAmount(props.session, index, '+')} simbolo={'+'} />
                  </Group>
                </Group>
              </Card>)
          }
          <Select
            isRequired
            label={'Idiomas'}
            name={'languages'}
            value={this.state[props.session].languages}
            onChange={(input) => this.handleType(input, props.session)}>
            <option value={'en-US'}>Inglês</option>
            <option value={'es-ES'}>Espanhol</option>
            <option value={'en-US_es-ES'}>Inglês e espanhol</option>
          </Select>
          <Select
            isRequired
            label={'Necessita intérprete?'}
            name={'interpreter'}
            value={this.state[props.session].interpreter}
            onChange={(input) => this.handleType(input, props.session)}>
            <option value={false}>Não</option>
            <option value={true}>Sim</option>
          </Select>
          <Textarea
            type={'text'}
            label={'Observações'}
            placeholder={'Caso tenha alguma observação, escreva aqui.'}
            name={'observation'}
            value={this.state[props.session].observation}
            onChange={(input) => this.handleObservation(input, props.session)}
          />
          <Label onClick={() => this.addNewItem(props.session)} style={{ width: 'fit-content', paddingRight: '15px', color: defaultTheme.colors.whiteLabel.primary, textDecoration: 'underline' }}>Adicionar novo pedido de {getNameFromKey(props.session)}</Label>
        </Card>
      </Group>
  }

  ItemInternet = (props) => {
    return <Group style={{ display: `${this.state[props.session].requested ? 'block' : 'none'}`}}>
        <Card style={{ width: '100%', marginLeft: '10px' }}>
          <Title style={{ width: '100%', fontSize: '14px' }}>Internet</Title>
          {
            this.state[props.session].requests.map((item, index) => <Card style={{ width: '100%' }}>
              <InputDatepicker
                type={'multiple'}
                label={'Quando?'}
                placeholder={'Selecione a(s) data(s)'}
                name={'dates'}
                dates={ item.dates }
                error={ (!item.dates || item.dates.length === 0) || this.validaDataItem(item.dates, props.session) }
                onChange={input => this.handlerValue(input, props.session, index)}
                edited={props.listaDeRedirecionamento.some(item => item === 'start' || item === 'end')}
                isRequired />
                <Group style={{ display: 'flex', paddingTop: '20px', margin: 'auto', width: 'fit-content' }}>
                  <Label style={{ width: 'fit-content', paddingRight: '15px', color: item.amount <= 0 && 'red' }}>Pontos de acesso:</Label>
                  <Group style={{ display: 'flex' }}>
                    <RoundButton style={{ width: '35px'}} onClick={() => this.changeAmount(props.session, index, '-')} disabled={item.amount < 1} simbolo={'-'} />
                    <Title style={{ width: '50px', marginTop: '5px', textAlign: 'center', color: item.amount > 0 ? defaultTheme.colors.whiteLabel.primary : 'red' }}>{item.amount}</Title>
                    <RoundButton style={{ width: '35px'}} onClick={() => this.changeAmount(props.session, index, '+')} simbolo={'+'} />
                  </Group>
                </Group>
              </Card>)
          }
          <Select
            isRequired
            label={'Tipo'}
            name={'type'}
            value={this.state[props.session].type}
            onChange={(input) => this.handleType(input, props.session)}>
            <option value={'wifi'}>Wi-fi</option>
            <option value={'dedicada'}>Dedicada</option>
          </Select>
          <RightBoxInput
            placeholder={'0'}
            label={'Quantos MB'}
            rightBox='MB'
            type='number'
            name={'mbSpeed'}
            value={this.state[props.session].mbSpeed}
            onChange={(input) => this.handleType(input, props.session)}
            style={{ width: '100%' }}/>
          <Textarea
            type={'text'}
            label={'Observações'}
            placeholder={'Caso tenha alguma observação, escreva aqui.'}
            name={'observation'}
            value={this.state[props.session].observation}
            onChange={(input) => this.handleObservation(input, props.session)}
          />
          <Label onClick={() => this.addNewItem(props.session)} style={{ width: 'fit-content', paddingRight: '15px', color: defaultTheme.colors.whiteLabel.primary, textDecoration: 'underline' }}>Adicionar novo pedido de Internet</Label>
        </Card>
      </Group>
  }

  ItemMicrofone = (props) => {
    return <Group style={{ display: `${this.state[props.session].requested ? 'block' : 'none'}`}}>
        <Card style={{ width: '100%', marginLeft: '10px' }}>
          <Title style={{ width: '100%', fontSize: '14px' }}>{getNameFromKey(props.session)}</Title>
          {
            this.state[props.session].requests.map((item, index) => <Card style={{ width: '100%' }}>
              <InputDatepicker
                type={'multiple'}
                label={'Quando?'}
                placeholder={'Selecione a(s) data(s)'}
                name={'dates'}
                dates={ item.dates }
                error={ (!item.dates || item.dates.length === 0) || this.validaDataItem(item.dates, props.session) }
                onChange={input => this.handlerValue(input, props.session, index)}
                edited={props.listaDeRedirecionamento.some(item => item === 'start' || item === 'end')}
                isRequired />
                <Group style={{ display: 'flex', paddingTop: '20px', margin: 'auto', width: 'fit-content' }}>
                  <Label style={{ width: 'fit-content', paddingRight: '15px',
                    color: ((!item.numberOfLapel || item.numberOfLapel <= 0) &&
                      (!item.numberOfGooseneck || item.numberOfGooseneck <= 0) &&
                      (!item.numberOfHeadSet || item.numberOfHeadSet <= 0) &&
                      (!item.numberOfStick || item.numberOfStick <= 0)
                      ) && 'red' }}>
                    Lapela:</Label>
                  <Group style={{ display: 'flex', paddingLeft: '30px' }}>
                    <RoundButton style={{ width: '35px'}} onClick={() => this.changeAmount(props.session, index, '-', 'numberOfLapel')} disabled={item.numberOfLapel < 1} simbolo={'-'} />
                    <Title style={{ width: '50px', marginTop: '5px', textAlign: 'center',
                      color: ((!item.numberOfLapel || item.numberOfLapel <= 0) &&
                      (!item.numberOfGooseneck || item.numberOfGooseneck <= 0) &&
                      (!item.numberOfHeadSet || item.numberOfHeadSet <= 0) &&
                      (!item.numberOfStick || item.numberOfStick <= 0)
                      ) ? 'red' : defaultTheme.colors.whiteLabel.primary }}>
                      {item.numberOfLapel}</Title>
                    <RoundButton style={{ width: '35px'}} onClick={() => this.changeAmount(props.session, index, '+', 'numberOfLapel')} simbolo={'+'} />
                  </Group>
                </Group>
                <Group style={{ display: 'flex', paddingTop: '20px', margin: 'auto', width: 'fit-content' }}>
                  <Label style={{ width: 'fit-content', paddingRight: '15px',
                    color: ((!item.numberOfLapel || item.numberOfLapel <= 0) &&
                      (!item.numberOfGooseneck || item.numberOfGooseneck <= 0) &&
                      (!item.numberOfHeadSet || item.numberOfHeadSet <= 0) &&
                      (!item.numberOfStick || item.numberOfStick <= 0)
                      ) && 'red' }}>Gooseneck:</Label>
                  <Group style={{ display: 'flex' }}>
                    <RoundButton style={{ width: '35px'}} onClick={() => this.changeAmount(props.session, index, '-', 'numberOfGooseneck')} disabled={item.numberOfGooseneck < 1} simbolo={'-'} />
                    <Title style={{ width: '50px', marginTop: '5px', textAlign: 'center',
                      color: ((!item.numberOfLapel || item.numberOfLapel <= 0) &&
                      (!item.numberOfGooseneck || item.numberOfGooseneck <= 0) &&
                      (!item.numberOfHeadSet || item.numberOfHeadSet <= 0) &&
                      (!item.numberOfStick || item.numberOfStick <= 0)
                      ) ? 'red' : defaultTheme.colors.whiteLabel.primary }}>{item.numberOfGooseneck}</Title>
                    <RoundButton style={{ width: '35px'}} onClick={() => this.changeAmount(props.session, index, '+', 'numberOfGooseneck')} simbolo={'+'} />
                  </Group>
                </Group>
                <Group style={{ display: 'flex', paddingTop: '20px', margin: 'auto', width: 'fit-content' }}>
                  <Label style={{ width: 'fit-content', paddingRight: '15px',
                    color: ((!item.numberOfLapel || item.numberOfLapel <= 0) &&
                      (!item.numberOfGooseneck || item.numberOfGooseneck <= 0) &&
                      (!item.numberOfHeadSet || item.numberOfHeadSet <= 0) &&
                      (!item.numberOfStick || item.numberOfStick <= 0)
                      ) && 'red' }}>Head Set:</Label>
                  <Group style={{ display: 'flex', paddingLeft: '15px' }}>
                    <RoundButton style={{ width: '35px'}} onClick={() => this.changeAmount(props.session, index, '-', 'numberOfHeadSet')} disabled={item.numberOfHeadSet < 1} simbolo={'-'} />
                    <Title style={{ width: '50px', marginTop: '5px', textAlign: 'center',
                      color: ((!item.numberOfLapel || item.numberOfLapel <= 0) &&
                      (!item.numberOfGooseneck || item.numberOfGooseneck <= 0) &&
                      (!item.numberOfHeadSet || item.numberOfHeadSet <= 0) &&
                      (!item.numberOfStick || item.numberOfStick <= 0)
                      ) ? 'red' : defaultTheme.colors.whiteLabel.primary }}>{item.numberOfHeadSet}</Title>
                    <RoundButton style={{ width: '35px'}} onClick={() => this.changeAmount(props.session, index, '+', 'numberOfHeadSet')} simbolo={'+'} />
                  </Group>
                </Group>
                <Group style={{ display: 'flex', paddingTop: '20px', margin: 'auto', width: 'fit-content' }}>
                  <Label style={{ width: 'fit-content', paddingRight: '15px',
                    color: ((!item.numberOfLapel || item.numberOfLapel <= 0) &&
                      (!item.numberOfGooseneck || item.numberOfGooseneck <= 0) &&
                      (!item.numberOfHeadSet || item.numberOfHeadSet <= 0) &&
                      (!item.numberOfStick || item.numberOfStick <= 0)
                      ) && 'red' }}>Bastão:</Label>
                  <Group style={{ display: 'flex', paddingLeft: '30px' }}>
                    <RoundButton style={{ width: '35px'}} onClick={() => this.changeAmount(props.session, index, '-', 'numberOfStick')} disabled={item.numberOfStick < 1} simbolo={'-'} />
                    <Title style={{ width: '50px', marginTop: '5px', textAlign: 'center',
                      color: ((!item.numberOfLapel || item.numberOfLapel <= 0) &&
                      (!item.numberOfGooseneck || item.numberOfGooseneck <= 0) &&
                      (!item.numberOfHeadSet || item.numberOfHeadSet <= 0) &&
                      (!item.numberOfStick || item.numberOfStick <= 0)
                      ) ? 'red' : defaultTheme.colors.whiteLabel.primary }}>{item.numberOfStick}</Title>
                    <RoundButton style={{ width: '35px'}} onClick={() => this.changeAmount(props.session, index, '+', 'numberOfStick')} simbolo={'+'} />
                  </Group>
                </Group>
              </Card>)
          }
          <Textarea
            type={'text'}
            label={'Observações'}
            placeholder={'Caso tenha alguma observação, escreva aqui.'}
            name={'observation'}
            value={this.state[props.session].observation}
            onChange={(input) => this.handleObservation(input, props.session)}
          />
          <Label onClick={() => this.addNewMicrofone(props.session)} style={{ width: 'fit-content', paddingRight: '15px', color: defaultTheme.colors.whiteLabel.primary, textDecoration: 'underline' }}>Adicionar novo pedido de {getNameFromKey(props.session)}</Label>
        </Card>
      </Group>
  }

  menuLateralDireito = () => {
    const { briefing } = this.props.match.params
    return <FixedDiv>
      <Card style={{ width: '100%' }}>
        <Label style={{ textTransform: 'uppercase' }}>Resumo do Evento <span style={{ float: 'right', paddingRight: '10px' }}>ID:{this.state.eventFriendlyId}</span></Label>
        <Group>
          <Label style={{ fontWeight: 600, color: '#484848' }}>{this.state.name}</Label>
          <Group style={{ display: 'flex' }}>
            <Icons
              icon={'calendario'}
              color={'gray'}
              width={40}
              height={20}
              style={{ marginRight: '-5px', marginTop: '5px' }} />
            <Label>{formatDate(this.state.eventStartDate)} - {formatDate(this.state.eventEndDate)}</Label>
          </Group>
          <Group style={{ display: 'flex' }}>
            <Icons
              icon={'map-pin'}
              color={'gray'}
              width={40}
              height={20}
              style={{ marginRight: '-5px', marginTop: '5px', marginLeft: '2px' }} />
            {this.state.location && <Label>{this.state.location.address} - {this.state.location.city}</Label>}
          </Group>
          <Group style={{ display: 'flex' }}>
            <Icons
              icon={'pessoas'}
              color={'gray'}
              width={40}
              height={20}
              style={{ marginTop: '5px', marginLeft: '-3px' }} />
            <Label>{this.state.numberOfParticipants} pessoas</Label>
          </Group>
        </Group>
      </Card>
      <Card style={{ width: '100%' }}>
        <ServicosSolicitados services={this.state.services} selected={'equipments'} />
        <Button
          type='submit'
          category={'secondary'}
          fontWeight={800}
          onClick={this.state.eventStatus === 'INACTIVE' ? this.submit : this.checkEdicao}
          full>
          {briefing ? 'Ok' :'Próximo'}
        </Button>
      </Card>
    </FixedDiv>
  }

  render() {
    return this.gerenciaResponsividade()
  }
}

export default FormEquipments
