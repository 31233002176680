export const formatFluid = (num = 0, type = 'ml') => {
  if (!num) return `0 ${type}`
  num = removeFluid(num)
  if (Number(num) === 0) return ''
  num = parseInt(num.replace(/[\D]+/g,''), 10)
  return `${num} ${type}`
}

export const removeFluid = (num) => {
  return num.replace(/[^0-9]/g, '')
}

export default formatFluid
