const dict = {
    'water': 'Água',
    'coffee': 'Café',
    'tea': 'Chá',
    'milk': 'Leite',
    'juice': 'Suco',
    'soda': 'Refrigerante',
    'cheeseBread': 'Pão de queijo',
    'petitFourCandy': 'Petit fours doce',
    'petitFourSalty': 'Petit fours salgado',
    'cake': 'Bolo',
    'miniSandwich': 'Mini sanduiches',
    'cupCakes': 'Cup cakes',
    'fruitPlatter': 'Travessa de frutas',

    'welcomeDrink': 'Welcome drink',
    'welcome_drink': 'Welcome drink',

    'welcomeCoffee': 'Welcome coffee',
    'welcome_coffee': 'Welcome coffee',

    'coffee_break_morning': 'Coffee break manhã',
    'morningCoffeeBreak': 'Coffee break manhã',

    'brunch': 'Brunch',

    'lunch': 'Almoço',

    'coffee_break_afternoon': 'Coffee break tarde',
    'afternoonCoffeeBreak': 'Coffee break tarde',
    'dinner': 'Jantar',

    'thematic_dinner': 'Jantar temático',
    'thematicDinner': 'Jantar temático',

    'rooms_service': 'Serviço de sala',
    'roomService': 'Serviço de sala',

    'tray': 'Bandeja',
    'cocktail': 'Coquetel',

    'single': 'Apto tipo solteiro',
    'double': 'Apto tipo duplo',
    'twin': 'Apto tipo duplo',
    'triple': 'Apto tipo triplo',

    'allInclusive': 'All inclusive',
    'breakfast': 'Café da manhã',
    'fullBoard': 'Pensão completa',
    'halfBoardDinner': 'Meia pensão (jantar)',
    'halfBoardLunch': 'Meia pensão (almoço)',
    'internet': 'Internet no apto',
    'parking': 'Estacionamento',

    'glass': 'Copo',
    'bottle': 'Garrafa',
    'gallon': 'Galão',
    'can': 'Lata',

    'PACK_OF_NON_ALCOHOLIC_BEVERAGE': 'Bebidas não alcoólicas',
    'PACK_OF_NON_ALCOHOLIC_AND_ALCOHOLIC_BEVERAGE': 'Bebidas não alcoólicas e alcoólicas',
    'PACK_OF_ALCOHOLIC_BEVERAGE': 'Bebidas alcoólicas',
    'DRINKS_ACCORDING_TO_CONSUMPTION': 'Bebidas conforme consumo',

    'projector': 'Projetor',
    'screen': 'Tela',
    'sound': 'Sonorização',
    'microphone': 'Microfone',
    'flipchart': 'Flip Chart',
    'laserPoint': 'Laser Point',
    'notebook': 'Notebook',
    'lighting': 'Iluminação',
    'simultaneousTranslation': 'Tradução simultânea',
    'radio': 'Rádio',
    'operator': 'Operador',
    'generator': 'Gerador',

    'en-US': 'Inglês',
    'es-ES': 'Espanhol',
    'en-US_es-ES': 'Inglês e espanhol',
    'pt-BR': 'Português',
    'fr-FR': 'Francês',
    'it-IT': 'Italiano',
    'de-DE': 'Alemão',

    'modem': 'Dial modem',
    'banda': 'Banda larga',
    'wifi': 'Wi-fi',
    'dedicada': 'Dedicada',
    'fibra': 'Fibra Ótica',
    'internet3g': 'Internet 3G',
    'internet4g': 'Internet 4G',
    'internet5g': 'Internet 5G',

    'gifts': 'Brindes',
    'printShop': 'Gráfica',
    'additionalServices': 'Outros serviços'
  }

  export const getNameFromKey = function (key) {
    if (!key) return key
    return dict[key] || key
  }

  export const getKeyFromName = function (name) {
    if (!name) return name
    return Object.keys(dict).find(key => dict[key] === name)
      || name.replace(/\s/g, '_').toLowerCase()
  }
