/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../../styles/themes'
import { formatCurrency } from '../../../utils/money'
/* UI */
import Card from '../ui/Card'
import Icons from '../ui/Icons'

const ItemsMenuLateral = styled.li`
  color: ${props => props.selected ? defaultTheme.colors.whiteLabel.primary : defaultTheme.colors.text.darker};
  background-color: ${props => props.selected ? '#f3f6f9' : defaultTheme.colors.backgrounds.grayish};
  cursor: ${props => props.naoPodeClicar ? 'unset' : 'pointer'};
  border-left: 5px solid ${props => props.selected ? defaultTheme.colors.whiteLabel.primary : defaultTheme.colors.backgrounds.grayish};
  box-sizing: border-box;
  padding: 15px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-size: 14px;
  font-weight: 400;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};

  & svg {
    fill: ${props => props.selected ? defaultTheme.colors.whiteLabel.primary : defaultTheme.colors.text.darker};
  }
`

const Label = styled.a`
  font-size: 15px;
  font-family: ${defaultTheme.fonts.primary};
  line-height: 1.2;
  padding-left: 10px;
`
const LabelDados = styled.p`
  font-family: ${defaultTheme.fonts.primary};
  line-height: 1.2;
  font-Weight: 400;
  color: ${defaultTheme.colors.grey.light};
  font-size: 12px;
  padding: 150px 0;
  text-align: center;
`

const Group = styled.div`
`

export default class Container extends Component {
  constructor () {
    super()
    this.state = {
      isLoading: false,
      selecionado: 1
    }
  }

  mudaSelecao = (opcao) => {
    this.setState({ selecionado: opcao })
  }

  mostraContainer = () => {
    return this.props.blocos[this.state.selecionado - 1]
  }

  render () {
    return <Card style={{ padding: '0', width: '100%' }}>
      <Group style={{ display: 'flex' }}>
        <Group style={{ width: '240px', boxShadow: '0 2px 2px 0 rgba(0,0,0,0.16)' }}>
          <ItemsMenuLateral onClick={() => this.mudaSelecao(1)} selected={this.state.selecionado === 1}>
            <Group style={{ padding: '0', display: 'flex', position: 'relative' }}>
              <Group style={{ padding: '0', width: '100%' }}>
                <Label style={{ display: 'block', width: '100%', fontWeight: 600, color: defaultTheme.colors.grey.light, fontSize: '10px' }}>GASTOS NO PERÍODO</Label>
                <Label style={{ fontSize: '20px', fontWeight: 600 }}>{formatCurrency(this.props.gastosNoPeriodoTotal)}</Label>
              </Group>
              <Icons
                icon={'angle-right'}
                width={50}
                height={20}
                color={'#484848'}
                style={{ position: 'absolute', right: '-30px', top: '10px' }} />
            </Group>
          </ItemsMenuLateral>
          <ItemsMenuLateral naoPodeClicar={!this.props.podeClicarEmSavings} onClick={() => this.props.podeClicarEmSavings && this.mudaSelecao(2)} selected={this.state.selecionado === 2}>
            <Group style={{ padding: '0', display: 'flex', position: 'relative' }}>
              <Group style={{ padding: '0', width: '100%' }}>
                <Label style={{ display: 'block', width: '100%', fontWeight: 600, color: defaultTheme.colors.grey.light, fontSize: '10px' }}>SAVINGS NO PERÍODO</Label>
                <Label style={{ fontSize: '20px', fontWeight: 600, color: defaultTheme.colors.secondary.default }}>{formatCurrency(this.props.savingsNoPeriodoTotal)}</Label>
              </Group>
              {this.props.podeClicarEmSavings && <Icons
                icon={'angle-right'}
                width={50}
                height={20}
                color={'#484848'}
                style={{ position: 'absolute', right: '-30px', top: '10px' }} />}
            </Group>
          </ItemsMenuLateral>
          <ItemsMenuLateral onClick={() => this.mudaSelecao(3)} selected={this.state.selecionado === 3}>
            <Group style={{ padding: '0', display: 'flex', position: 'relative' }}>
              <Group style={{ padding: '0', width: '100%' }}>
                <Label style={{ display: 'block', width: '100%', fontWeight: 600, color: defaultTheme.colors.grey.light, fontSize: '10px' }}>EVENTOS REALIZADOS NO PERÍODO</Label>
                <Label style={{ fontSize: '20px', fontWeight: 600 }}>{this.props.eventosRealizadosNoPeriodoTotal}</Label>
              </Group>
              <Icons
                icon={'angle-right'}
                width={50}
                height={20}
                color={'#484848'}
                style={{ position: 'absolute', right: '-30px', top: '10px' }} />
            </Group>
          </ItemsMenuLateral>
        </Group>
        <Group style={{ padding: '20px', width: 'calc(100% - 250px)' }}>
          {this.props.gastosNoPeriodoTotal > 0 ? this.mostraContainer() : <LabelDados>Não há dados para exibir</LabelDados>}
        </Group>
      </Group>
    </Card>
  }
}
