import { 
  ROOMS_BUDGET_REQUEST,
  ROOMS_BUDGET_SUCCESS,
  ROOMS_BUDGET_FAILURE,
  ADD_ROOM_TO_BUDGET
} from '../actions/budget'
 
export default function budget (state = {
  isFetching: false,
  rooms: false
}, action) {
  switch (action.type) {
    case ROOMS_BUDGET_REQUEST:
    return Object.assign({}, state, {
      isFetching: true,
      data: action.data,
      rooms: []
    })
    case ROOMS_BUDGET_SUCCESS:
    return Object.assign({}, state, {
      isFetching: false,
      rooms: action.data
    })
    case ROOMS_BUDGET_FAILURE:
    return Object.assign({}, state, {
      isFetching: false,      
      errorMessage: action.message
    })
    case ADD_ROOM_TO_BUDGET: 
    return Object.assign({}, state, {
      rooms: action.data
    })
  default:
    return state
  }
}
