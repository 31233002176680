/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
/* COMPONENTS */
import GerenciaFluxoEdicao from '../../../../components/GerenciaFluxoEdicao'
import Header from '../../../Header'
import MainDesktop from '../../../../components/Main'
import Body from '../../../../components/Body'
import Button from '../../../../components/ui/Button'
import Alert from '../../../../components/ui/Alert'
import Textarea from '../../../../components/ui/Textarea'
import Input from '../../../../components/ui/Input'
import MoneyInput from '../../../../components/ui/MoneyInput'
import Select from '../../../../components/ui/Select'
import InputDatepicker from '../../../../components/InputDatepicker'
import RightBoxInput from '../../../../components/ui/RightBoxInput'
import CheckBox from '../../../../components/ui/CheckBox'
import Card from '../../../../components/ui/Card'
import Icons from '../../../../components/ui/Icons'
import BreadCrumb from '../../../../components/BreadCrumb'
import Loader from '../../../../components/Loader'
import config from '../../../../../middleware/config'
import { Get, Post, Put } from '../../../../../utils/request'
import { getDateRange, formatDate } from '../../../../../utils/dates'
import { defaultTheme } from '../../../../styles/themes'
import { getNameFromKey } from '../../../../../utils/fabTexts'
import { ehChainUser } from '../../../../../utils/tiposUsuario'
import ServicosSolicitados from '../../../../components/ServicosSolicitados';
import { retornaUrlFluxoEdicao, retornaUrlFluxoCriacao } from '../../../../../utils/fluxoCriacaoEvento';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #fff;
  & * { font-family: 'Open Sans', sans-serif }
`

const Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  font-family: ${defaultTheme.fonts.secondary};
  padding: 3px 10px 13px 10px;
  color: #484848;
`

const Main = styled.div`
  width: 100%;
  background: #fff;
  flex: 1 0 auto;
`

const Label = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  box-sizing: border-box;
  padding: 5px 5px 5px 0px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
`

const Group = styled.div`
`

const FixedDiv = styled.div`
  position: fixed;
  top: 46px;
  bottom: 0;
  width: 25%;
  overflow-y: scroll;
  margin-top: 14px;
`

const AuxiliarDeMargin = styled.div`
  margin-top: -20px;
  width: 40%;
@media (max-width: 1534px) {
  margin-top: 0px;
  }
`

class FormOtherServices extends Component {
  constructor() {
    super()
    this.state = {
      observations: '',
      gifts: {
        requests: [
          {
            name: '',
            deliveryDate: '',
            quantity: '',
            budgetPerUnit: {amount: 0, currency: 'BRL'},
            description: ''
          }
        ],
        requested: false
      },
      printShop: {
        requests: [
          {
            name: '',
            deliveryDate: '',
            quantity: '',
            description: '',
            hasContentCreation: ''
          }
        ],
        requested: false,
      },
      additionalServices: {
        requests: [
          {
            name: '',
            dates: [],
            hoursPerDay: '',
            description: ''
          }
        ],
        requested: false
      },
      services: '',
      eventStartDate:'',
      eventEndDate:'',
      eventStatus: '',
      numberOfParticipants:'',
      name:'',
      location: {},
      salvaEdicao: false
    }
  }

  componentDidMount() {
    if (ehChainUser()) {
      alert('Você não tem permissão para acessar esta página.')
      this.props.history.push(`/organizer`)
    }
    this.setState({ isLoading: true }, () => {
      const { briefing, id } = this.props.match.params
      const { api: { base, version } } = config
      const userType = window.localStorage.getItem('user_type')
      const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
      const newEndpoint = `${base}${version}${eventEndpoint}/${id}`
      Get(newEndpoint)
      .then((response) => {
        this.setState({ isLoading: false })
        if (!response) {
          this.setState({ errorMessage: response.message })
          return Promise.reject(response)
        } else {
          const state = this.state
          state.services = response.services
          state.eventStartDate = response.start
          state.eventEndDate = response.end
          state.eventStatus = response.status
          state.numberOfParticipants = response.numberOfParticipants
          state.eventFriendlyId = response.internalId || response.friendlyId
          state.name = response.name
          state.location = response.location
          state.additionalServices.requests.map(item => {
            item.dates = getDateRange(new Date(response.start), new Date(response.end))
            return item
          })
          this.setState(state, () => {
            if (briefing) {
              this.fetchBriefing()
            }
          })
        }
      }).catch(err => {
        this.setState({ isLoading: false })
        console.log('Error: ', err)
      })
    })
  }

  fetchBriefing = () => {
    const { id, briefing } = this.props.match.params
    const { api: { base, version } } = config
    const userType = window.localStorage.getItem('user_type')
    const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
    const endpoint = `${base}${version}${eventEndpoint}/${id}/briefing/other-services/${briefing}`

    this.setState({ isLoading: true }, () => {
      Get(endpoint)
      .then((response) => {
        var manipulandoDados = response.services
        Object.keys(manipulandoDados).map(item => {
          var outrosServicos = manipulandoDados[item]
          if(outrosServicos.requests.length > 0) {
            outrosServicos.requests.map(request => {
              if(request.dates) {
                request.dates = request.dates.map(data => new Date(data))
              } if(request.deliveryDate) {
                request.deliveryDate =  new Date(request.deliveryDate)
              }
              return request
            })
          } else {
            switch (item) {
              case 'gifts':
                outrosServicos.requests.push({
                  name: '',
                  deliveryDate: '',
                  quantity: 0,
                  budgetPerUnit: {amount: 0, currency: 'BRL'},
                  description: ''
                })
                break;

              case 'printShop':
                outrosServicos.requests.push({
                  name: '',
                  deliveryDate: '',
                  quantity: 0,
                  description: '',
                  hasContentCreation: ''
                })
                break;

              case 'additionalServices':
                outrosServicos.requests.push({
                  name: '',
                  dates: getDateRange(new Date(this.state.eventStartDate), new Date(this.state.eventEndDate)),
                  hoursPerDay: 0,
                  description: ''
                })
                break;

              default:
                outrosServicos.requests.push({
                  name: '',
                  deliveryDate: '',
                  quantity: 0,
                  budgetPerUnit: {amount: 0, currency: 'BRL'},
                  description: ''
                })
                break;
            }
          }
          return outrosServicos
        })
        this.setState({ ...manipulandoDados, briefing: response, isLoading: false })
      }).catch(err => {
        this.setState({ isLoading: false })
        console.log('Error: ', err)
      })
    })
  }

  click = (session) => {
    const state = this.state
    state[session].requested = !state[session].requested
    this.setState(state)
  }

  handlerValue = (input, session, index) => {
    const state = this.state
    const { name, value } = input.target
    if(name === 'budgetPerUnit') {
      state[session].requests[index][name].amount = value
    } else {
      state[session].requests[index][name] = value
    }
    this.setState(state)
    return state[session].requests[index][name]
  }

  addNewItem = (session) => {
    const state = this.state
    switch (session) {
      case 'gifts':
        state[session].requests.push({
          name: '',
          deliveryDate: '',
          quantity: 0,
          budgetPerUnit: {amount: 0, currency: 'BRL'},
          description: ''
        })
        break;

      case 'printShop':
        state[session].requests.push({
          name: '',
          deliveryDate: '',
          quantity: 0,
          description: '',
          hasContentCreation: ''
        })
        break;

      case 'additionalServices':
        state[session].requests.push({
          name: '',
          dates: getDateRange(new Date(this.state.eventStartDate), new Date(this.state.eventEndDate)),
          hoursPerDay: 0,
          description: ''
        })
        break;

      default:
        state[session].requests.push({
          name: '',
          deliveryDate: '',
          quantity: 0,
          budgetPerUnit: {amount: 0, currency: 'BRL'},
          description: ''
        })
        break;
    }
    this.setState(state)
  }

  removeItem = (input, session, index) => {
    input && input.preventDefault()
    const state = this.state
    state[session].requests.splice(index, 1);
    this.setState(state)
  }

  clearUselessRequests = (obj) => {
    if (obj && obj.services) {
      Object.keys(obj.services).forEach(key => {
        if (!obj.services[key].requested) obj.services[key].requests = []
      })
    }

    return obj
  }

  submit = (e) => {
    e && e.preventDefault()
    if(!this.temPeloMenosUmServicoSelecionado()) return alert('Selecione ao menos um serviço para continuar.')
    if(!this.isValid()) return alert('Verifique todos os campos obrigatórios para continuar.')
    this.setState({
      isLoading: true
    })
    const { api: { base, version } } = config
    const { id, briefing } = this.props.match.params
    const userType = window.localStorage.getItem('user_type')
    const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
    const endpoint = `${base}${version}${eventEndpoint}/${id}/briefing/other-services/`
    const briefingEndpoint = `${base}${version}${eventEndpoint}/${id}/briefing/other-services/${briefing}`
    if(briefing) {
      Put(briefingEndpoint, this.clearUselessRequests(this.generateJSON()))
      .then(() => {
        this.props.history.push(retornaUrlFluxoEdicao(this.state.services, id, 'others'))
      }).catch(err => {
        console.error(err.message)
        this.setState({
          isLoading: false
        })
        alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
      })

    } else {
      Post(endpoint, this.clearUselessRequests(this.generateJSON()))
      .then(() => {
        this.props.history.push(retornaUrlFluxoCriacao(this.state.services, id, this.state.eventStatus, 'others'))
      }).catch(err => {
        console.error(err.message)
        this.setState({
          isLoading: false
        })
        alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
      })
    }
  }

  checkEdicao = (e) => {
    e && e.preventDefault()
    if(!this.temPeloMenosUmServicoSelecionado()) return alert('Selecione ao menos um serviço para continuar.')
    if(!this.isValid()) return alert('Selecione ao menos uma unidade nos equipamentos selecionados no menu.')
    this.setState({ salvaEdicao: true }, () => this.setState({ salvaEdicao: false }))
  }

  isValid = () => {
    return !(Object.keys(this.state).filter(item => this.state[item] && this.state[item].requested).some(item => {
      switch (item) {
        case 'gifts':
          return this.state[item].requests.some(request => !request.name || !request.deliveryDate || !(request.quantity > 0))
        case 'printShop':
          return this.state[item].requests.some(request => !request.name || !request.description || !request.deliveryDate || !(request.quantity > 0) || request.hasContentCreation === '')
        case 'additionalServices':
          return this.state[item].requests.some(request => !request.name || (!request.dates || request.dates.length === 0))
        default:
          return this.state[item].requests.some(request => !request.name || !request.deliveryDate || !(request.quantity > 0))
      }
    }))
  }

  temPeloMenosUmServicoSelecionado = () => {
    return Object.keys(this.state).some(item => this.state[item] && this.state[item].requested)
  }

  generateJSON = () => {
    const { gifts, printShop, additionalServices } = this.state
    gifts.requests = gifts.requests.map(item => {
      item.deliveryDate = (item.deliveryDate && Array.isArray(item.deliveryDate)) ? item.deliveryDate[0] : item.deliveryDate
      return item
    })
    printShop.requests = printShop.requests.map(item => {
      item.deliveryDate = (item.deliveryDate && Array.isArray(item.deliveryDate)) ? item.deliveryDate[0] : item.deliveryDate
      return item
    })
    return {
      ...this.state.briefing,
      services: {
        gifts,
        printShop,
        additionalServices
      }
    }
  }

  gerenciaResponsividade = () => {
    if(window.innerWidth < 600) return this.paginaMobile()
    else return this.paginaDesktop()
  }

  paginaMobile = () => {
    const { id, briefing } = this.props.match.params
    const { services} = this.state
    const lodging = services.lodging && services.lodging.requested && (services.lodging.id ? `/organizer/event/lodging/${id}/${services.lodging.id}` : `/organizer/event/lodging/${id}`)
    const room = services.room && services.room.requested && (services.room.id ? `/organizer/event/rooms/${id}/${services.room.id}` : `/organizer/event/rooms/${id}`)
    const foodsAndBeverages = services.foodsAndBeverages && services.foodsAndBeverages.requested && (services.foodsAndBeverages.id ? `/organizer/event/foodsAndBeverages/${id}/${services.foodsAndBeverages.id}` : `/organizer/event/foodsAndBeverages/${id}`)
    const equipments = services.equipments && services.equipments.requested && (services.equipments.id ? `/organizer/event/equipments/${id}/${services.equipments.id}` : `/organizer/event/equipments/${id}`)
    const transfer = services.transfer && services.transfer.requested && (services.transfer.id ? `/organizer/event/transfer/${id}/${services.transfer.id}` : `/organizer/event/transfer/${id}`)
    const listaDeRedirecionamento = JSON.parse(window.localStorage.getItem('redirecionamento_edicao_fluxo'))
    const verificaRedirecionamento = JSON.parse(window.localStorage.getItem('redirecionamento_edicao_salas'))
    return <>
      <Header />
      <MainDesktop>
        <Body {...this.props} pagina={this.state.eventStatus === 'INACTIVE' ? 'create-evento' : 'editar-evento'} menuLateralDireito={this.menuLateralDireito()}>
          <GerenciaFluxoEdicao pagina={'outros-servicos'} eventId={id} briefingId={briefing} form={this.generateJSON()} salvaEdicao={this.state.salvaEdicao} onSubmit={this.submit} />
          <Container>
          {this.state.eventStatus === 'INACTIVE' && <BreadCrumb options={
              [
                {link: '/organizer', name: 'Home'},
                {link: `/organizer/event/${id}`, name: 'Dados gerais do evento'},
                lodging && {link: lodging, name: 'Hospedagem'},
                room && {link: room, name: 'Salas'},
                foodsAndBeverages && {link: foodsAndBeverages, name: 'Alimentos e bebidas'},
                equipments && {link: equipments, name: 'Equipamentos'},
                transfer && {link: transfer, name: 'Transfer'},
                {name: 'Outros serviços'}
              ]
            } />}
            <Main>
              {this.state.isLoading ? <Loader /> : null}
              <Group style={{width: '100%', margin: 'auto'}}>
                <Title style={{ width: '100%', paddingTop: '20px' }}>Outros serviços</Title>
                <Label style={{ width: '100%', fontSize: '14px' }}>Selecione os outros serviços que você deseja e preencha as informações de cada um.</Label>
                {(listaDeRedirecionamento && listaDeRedirecionamento.length > 0) && <Alert type={'edition'} style={{ display: 'flex', marginBottom: '40px' }}>
                  <Icons
                    icon={'exclamation'}
                    color={'white'}
                    width={30}
                    style={{ margin: 'auto' }}
                    height={15} />
                    <p style={{ textAlign: 'justify' }}>
                      Você alterou a <span style={{ fontWeight: 'bold' }}>data</span> e/ou o <span style={{ fontWeight: 'bold' }}>número de pessoas</span> nos dados de seu evento. Acreditamos que você queira validar os dados abaixo para eventualmente alterar este mesmo aspecto também abaixo.
                    </p>
                </Alert>}
                {verificaRedirecionamento && <Alert type={'edition'} style={{ display: 'flex', marginBottom: '40px' }}>
                  <Icons
                    icon={'exclamation'}
                    color={'white'}
                    width={30}
                    height={15} />
                    <p style={{ textAlign: 'justify' }}>
                      Você alterou seu pedido para <span style={{ fontWeight: 'bold' }}>"salas"</span>  Acreditamos que você queira validar os dados abaixo para eventualmente alterar este mesmo aspecto também aqui.
                    </p>
                </Alert>}
                <Group style={{ display: 'flex' }}>
                  <Group style={{ width: '30%' }}>
                    <Card style={{ width: '100%', paddingRight: '30px', height: '100%', fontSize: '15px'}}>
                      <CheckBox
                        onClick={() => this.click('gifts')}
                        checked={this.state.gifts.requested}
                        label={getNameFromKey('gifts')}/>
                      <CheckBox
                        onClick={() => this.click('printShop')}
                        checked={this.state.printShop.requested}
                        label={getNameFromKey('printShop')}/>
                      <CheckBox
                        onClick={() => this.click('additionalServices')}
                        checked={this.state.additionalServices.requested}
                        label={getNameFromKey('additionalServices')}/>
                    </Card>
                  </Group>
                  <Group style={{ width: '70%' }}>
                    {this.Item({ session:'gifts', listaDeRedirecionamento: listaDeRedirecionamento })}
                    {this.Item({ session:'printShop', listaDeRedirecionamento: listaDeRedirecionamento })}
                    {this.ItemOutroServico({ session:'additionalServices', listaDeRedirecionamento: listaDeRedirecionamento })}
                  </Group>
                </Group>
              </Group>
            </Main>
          </Container>
        </Body>
      </MainDesktop>
    </>
  }

  paginaDesktop = () => {
    const { id, briefing } = this.props.match.params
    const { services} = this.state
    const lodging = services.lodging && services.lodging.requested && (services.lodging.id ? `/organizer/event/lodging/${id}/${services.lodging.id}` : `/organizer/event/lodging/${id}`)
    const room = services.room && services.room.requested && (services.room.id ? `/organizer/event/rooms/${id}/${services.room.id}` : `/organizer/event/rooms/${id}`)
    const foodsAndBeverages = services.foodsAndBeverages && services.foodsAndBeverages.requested && (services.foodsAndBeverages.id ? `/organizer/event/foodsAndBeverages/${id}/${services.foodsAndBeverages.id}` : `/organizer/event/foodsAndBeverages/${id}`)
    const equipments = services.equipments && services.equipments.requested && (services.equipments.id ? `/organizer/event/equipments/${id}/${services.equipments.id}` : `/organizer/event/equipments/${id}`)
    const transfer = services.transfer && services.transfer.requested && (services.transfer.id ? `/organizer/event/transfer/${id}/${services.transfer.id}` : `/organizer/event/transfer/${id}`)
    const listaDeRedirecionamento = JSON.parse(window.localStorage.getItem('redirecionamento_edicao_fluxo'))
    const verificaRedirecionamento = JSON.parse(window.localStorage.getItem('redirecionamento_edicao_salas'))
    return <>
      <Header />
      <MainDesktop>
        <Body {...this.props} pagina={this.state.eventStatus === 'INACTIVE' ? 'create-evento' : 'editar-evento'} menuLateralDireito={this.menuLateralDireito()}>
          <GerenciaFluxoEdicao pagina={'outros-servicos'} eventId={id} briefingId={briefing} form={this.generateJSON()} salvaEdicao={this.state.salvaEdicao} onSubmit={this.submit} />
          <Container>
          {this.state.eventStatus === 'INACTIVE' && <BreadCrumb options={
              [
                {link: '/organizer', name: 'Home'},
                {link: `/organizer/event/${id}`, name: 'Dados gerais do evento'},
                lodging && {link: lodging, name: 'Hospedagem'},
                room && {link: room, name: 'Salas'},
                foodsAndBeverages && {link: foodsAndBeverages, name: 'Alimentos e bebidas'},
                equipments && {link: equipments, name: 'Equipamentos'},
                transfer && {link: transfer, name: 'Transfer'},
                {name: 'Outros serviços'}
              ]
            } />}
            <Main>
              {this.state.isLoading ? <Loader /> : null}
              <Group style={{width: '100%', margin: 'auto'}}>
                <Title style={{ width: '100%', paddingTop: '20px' }}>Outros serviços</Title>
                <Label style={{ width: '100%', fontSize: '14px' }}>Selecione os outros serviços que você deseja e preencha as informações de cada um.</Label>
                {(listaDeRedirecionamento && listaDeRedirecionamento.length > 0) && <Alert type={'edition'} style={{ display: 'flex', marginBottom: '40px' }}>
                  <Icons
                    icon={'exclamation'}
                    color={'white'}
                    width={30}
                    style={{ margin: 'auto' }}
                    height={15} />
                    <p style={{ textAlign: 'justify' }}>
                      Você alterou a <span style={{ fontWeight: 'bold' }}>data</span> e/ou o <span style={{ fontWeight: 'bold' }}>número de pessoas</span> nos dados de seu evento. Acreditamos que você queira validar os dados abaixo para eventualmente alterar este mesmo aspecto também abaixo.
                    </p>
                </Alert>}
                {verificaRedirecionamento && <Alert type={'edition'} style={{ display: 'flex', marginBottom: '40px' }}>
                  <Icons
                    icon={'exclamation'}
                    color={'white'}
                    width={30}
                    height={15} />
                    <p style={{ textAlign: 'justify' }}>
                      Você alterou seu pedido para <span style={{ fontWeight: 'bold' }}>"salas"</span>  Acreditamos que você queira validar os dados abaixo para eventualmente alterar este mesmo aspecto também aqui.
                    </p>
                </Alert>}
                <Group style={{ display: 'flex' }}>
                  <Group style={{ width: '30%' }}>
                    <Card style={{ width: '100%', paddingRight: '30px', height: '100%', fontSize: '15px'}}>
                      <CheckBox
                        onClick={() => this.click('gifts')}
                        checked={this.state.gifts.requested}
                        label={getNameFromKey('gifts')}/>
                      <CheckBox
                        onClick={() => this.click('printShop')}
                        checked={this.state.printShop.requested}
                        label={getNameFromKey('printShop')}/>
                      <CheckBox
                        onClick={() => this.click('additionalServices')}
                        checked={this.state.additionalServices.requested}
                        label={'Serviço adicional'}/>
                    </Card>
                  </Group>
                  <Group style={{ width: '70%' }}>
                    {this.Item({ session: 'gifts', listaDeRedirecionamento: listaDeRedirecionamento })}
                    {this.Item({ session: 'printShop', listaDeRedirecionamento: listaDeRedirecionamento })}
                    {this.Item({ session: 'additionalServices', listaDeRedirecionamento: listaDeRedirecionamento })}
                    {/* <this.Item session={'gifts'} listaDeRedirecionamento={listaDeRedirecionamento} />
                    <this.Item session={'printShop'} listaDeRedirecionamento={listaDeRedirecionamento} />
                    <this.ItemOutroServico session={'additionalServices'} listaDeRedirecionamento={listaDeRedirecionamento} /> */}
                  </Group>
                </Group>
              </Group>
            </Main>
          </Container>
        </Body>
      </MainDesktop>
    </>
  }

  Item = (props) => {
    return <Group style={{ display: `${this.state[props.session].requested ? 'block' : 'none'}`}}>
        <Card style={{ width: '100%', marginLeft: '10px' }}>
          <Title style={{ width: '100%', fontSize: '14px' }}>{getNameFromKey(props.session)}</Title>
          {
            this.state[props.session].requests.map((item, index) => <Card style={{ width: '100%' }}>
              {this.state[props.session].requests.length > 1 && <Button
                type='submit'
                category={'secondary'}
                fontWeight={800}
                onClick={(input) => this.removeItem(input, props.session, index)}
                style={{ position: 'absolute', right: '0', top: '10px', width: '100px' }}
                ghost
                small
                full>
                Excluir
              </Button>}
              <Input
                isRequired
                label={'Nome do item'}
                placeholder='Digite o nome do item'
                name={'name'}
                value={item.name}
                error={!item.name}
                onChange={input => this.handlerValue(input, props.session, index)}
              />
              <Group style={{ display: 'flex', paddingTop: '10px', position: 'relative' }}>
                <InputDatepicker
                  label={'Data de entrega'}
                  type={'single'}
                  isRequired
                  name={'deliveryDate'}
                  placeholder={'Selecione'}
                  style={{ width: '30%', position: 'relative' }}
                  dates={!item.deliveryDate ? [] : [item.deliveryDate]}
                  error={!(item.deliveryDate)}
                  onChange={input => this.handlerValue(input, props.session, index)}
                />
                {props.session === 'printShop' && <AuxiliarDeMargin>
                  <Select
                    label={'Necessita de criação do conteúdo?'}
                    isRequired
                    name={'hasContentCreation'}
                    value={item.hasContentCreation}
                    error={item.hasContentCreation === ''}
                    onChange={input => this.handlerValue(input, props.session, index)}>
                    <option value={''}>Selecione uma opção</option>
                    <option value={false}>Não</option>
                    <option value={true}>Sim</option>
                  </Select>
                </AuxiliarDeMargin>}
                <Input
                  isRequired
                  type={'number'}
                  label={'Quantidade'}
                  name={'quantity'}
                  placeholder='0'
                  width={'30%'}
                  value={item.quantity}
                  error={!(item.quantity > 0)}
                  onChange={input => this.handlerValue(input, props.session, index)}
                />
                {props.session === 'gifts' && <MoneyInput
                  label={'Budget (por unidade)'}
                  placeholder={'0,00'}
                  name={`budgetPerUnit`}
                  value={item.budgetPerUnit.amount}
                  containerStyle={{ width: '40%' }}
                  onChange={input => this.handlerValue(input, props.session, index)}
                />}
              </Group>
              <Textarea
                isRequired={props.session === 'printShop'}
                error={props.session === 'printShop' ? !item.description : false}
                type={'text'}
                label={'Descrição do serviço'}
                placeholder={'Conte-nos os detalhes do que deseja neste item. Seja o mais específico possível.'}
                name={'description'}
                value={item.description}
                onChange={(input) => this.handlerValue(input, props.session, index)} />
              </Card>)
          }
          <Label onClick={() => this.addNewItem(props.session)} style={{ width: 'fit-content', paddingRight: '15px', color: defaultTheme.colors.whiteLabel.primary, textDecoration: 'underline' }}>Adicionar mais uma solicitação de {getNameFromKey(props.session)}</Label>
        </Card>
      </Group>
  }

  ItemOutroServico = (props) => {
    return <Group style={{ display: `${this.state[props.session].requested ? 'block' : 'none'}`}}>
        <Card style={{ width: '100%', marginLeft: '10px' }}>
          <Title style={{ width: '100%', fontSize: '14px' }}>Serviço adicional</Title>
          {
            this.state[props.session].requests.map((item, index) => <Card style={{ width: '100%' }}>
              {this.state[props.session].requests.length > 1 && <Button
                type='submit'
                category={'secondary'}
                fontWeight={800}
                onClick={(input) => this.removeItem(input, props.session, index)}
                style={{ position: 'absolute', right: '0', top: '10px', width: '100px' }}
                ghost
                small
                full>
                Excluir
              </Button>}
              <Input
                isRequired
                label={'Qual serviço você precisa?'}
                placeholder='Digite o nome do serviço'
                name={'name'}
                value={item.name}
                error={!item.name}
                onChange={input => this.handlerValue(input, props.session, index)}
              />
              <Group style={{ display: 'flex', paddingTop: '10px', position: 'relative' }}>
                <InputDatepicker
                  isRequired
                  type={'multiple'}
                  label={'Quando'}
                  placeholder={'Selecione a(s) data(s)'}
                  name={'dates'}
                  style={{ width: '50%', position: 'relative' }}
                  dates={item.dates || []}
                  error={item.dates.length < 1}
                  onChange={input => this.handlerValue(input, props.session, index)}
                />
                <RightBoxInput
                  label={'Quantas horas por dia'}
                  placeholder={'Quantas horas por dia'}
                  rightBox='horas'
                  type='number'
                  name={'hoursPerDay'}
                  defaultValue={item.hoursPerDay}
                  containerStyle={{ width: '50%', paddingLeft: '10px' }}
                  onBlur={input => this.handlerValue(input, props.session, index)}
                />
              </Group>
              <Textarea
                type={'text'}
                label={'Descrição do serviço'}
                placeholder={'Caso tenha algo a mais para compartilhar sobre este item, baste dizer aqui.'}
                name={'description'}
                value={item.description}
                onChange={(input) => this.handlerValue(input, props.session, index)} />
              </Card>)
          }
          <Label onClick={() => this.addNewItem(props.session)} style={{ width: 'fit-content', paddingRight: '15px', color: defaultTheme.colors.whiteLabel.primary, textDecoration: 'underline' }}>Adicionar mais um serviço</Label>
        </Card>
      </Group>
  }


  menuLateralDireito = () => {
    const { briefing } = this.props.match.params
    return <FixedDiv>
      <Card style={{ width: '100%' }}>
        <Label style={{ textTransform: 'uppercase' }}>Resumo do Evento <span style={{ float: 'right', paddingRight: '10px' }}>ID:{this.state.eventFriendlyId}</span></Label>
        <Group>
          <Label style={{ fontWeight: 600, color: '#484848' }}>{this.state.name}</Label>
          <Group style={{ display: 'flex' }}>
            <Icons
              icon={'calendario'}
              color={'gray'}
              width={40}
              height={20}
              style={{ marginRight: '-5px', marginTop: '5px' }} />
            <Label>{formatDate(this.state.eventStartDate)} - {formatDate(this.state.eventEndDate)}</Label>
          </Group>
          <Group style={{ display: 'flex' }}>
            <Icons
              icon={'map-pin'}
              color={'gray'}
              width={40}
              height={20}
              style={{ marginRight: '-5px', marginTop: '5px', marginLeft: '2px' }} />
            {this.state.location && <Label>{this.state.location.address} - {this.state.location.city}</Label>}
          </Group>
          <Group style={{ display: 'flex' }}>
            <Icons
              icon={'pessoas'}
              color={'gray'}
              width={40}
              height={20}
              style={{ marginTop: '5px', marginLeft: '-3px' }} />
            <Label>{this.state.numberOfParticipants} pessoas</Label>
          </Group>
        </Group>
      </Card>
      <Card style={{ width: '100%' }}>
        <ServicosSolicitados services={this.state.services} selected={'otherServices'} />
        <Button
          type='submit'
          category={'secondary'}
          fontWeight={800}
          onClick={this.state.eventStatus === 'INACTIVE' ? this.submit : this.checkEdicao}
          full>
          {briefing ? 'Ok' :'Próximo'}
        </Button>
      </Card>
    </FixedDiv>
  }

  render() {
    return this.gerenciaResponsividade()
  }
}

export default FormOtherServices
