import styled from 'styled-components'

export const TimelineCustom = styled.div`
.timeline {
  &:before {
    top: 20px;
    bottom: 40px;
    left: calc(24% + 5px);
  }
    &.timeline--animate{
    margin: 0;
    padding: 0;
  }
}
`
export const TimelineCustomItem = styled.div`
  .entry .title{
    width: 25%;
  }
  .entry .body{
    width: 75%;
  }
`
