
import React, { Component } from 'react'
import { defaultTheme } from '../../styles/themes'

const stroked = stroke => ({
  fill: 'none',
  stroke: stroke || defaultTheme.colors.whiteLabel.primary,
  strokeMiterlimit: 10,
  strokeWidth: '3px'
})

export default class UserIcon extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="269" height="192" viewBox="0 0 269 192">
        <defs>
          <clipPath id="clip-Artboard_3">
            <rect width="269" height="192" />
          </clipPath>
        </defs>
        <g id="Artboard_3" data-name="Artboard – 3" clip-path="url(#clip-Artboard_3)">
          <rect width="269" height="192" fill="#fff" />
          <g id="Group_1041" data-name="Group 1041" transform="translate(1.678 3.682)">
            <circle id="Ellipse_40" data-name="Ellipse 40" cx="4.59" cy="4.59" r="4.59" transform="translate(63.891 2.369)" fill="none" style={stroked(this.props.fill)} stroke-miterlimit="10" stroke-width="1" />
            <circle id="Ellipse_41" data-name="Ellipse 41" cx="4.59" cy="4.59" r="4.59" transform="translate(246.088 34.87)" fill="none" style={stroked(this.props.fill)} stroke-miterlimit="10" stroke-width="1" />
            <circle id="Ellipse_42" data-name="Ellipse 42" cx="4.59" cy="4.59" r="4.59" transform="translate(21.396 175.83)" fill="none" style={stroked(this.props.fill)} stroke-miterlimit="10" stroke-width="1" />
            <g id="Group_1033" data-name="Group 1033" transform="translate(192.784)">
              <line id="Line_113" data-name="Line 113" x1="11.697" transform="translate(0 5.775)" fill="none" style={stroked(this.props.fill)} stroke-miterlimit="10" stroke-width="1" />
              <line id="Line_114" data-name="Line 114" y2="11.623" transform="translate(5.849)" fill="none" style={stroked(this.props.fill)} stroke-miterlimit="10" stroke-width="1" />
            </g>
            <g id="Group_1034" data-name="Group 1034" transform="translate(7.774 32.279)">
              <line id="Line_115" data-name="Line 115" x1="11.697" transform="translate(0 5.849)" fill="none" style={stroked(this.props.fill)} stroke-miterlimit="10" stroke-width="1" />
              <line id="Line_116" data-name="Line 116" y2="11.697" transform="translate(5.849)" fill="none" style={stroked(this.props.fill)} stroke-miterlimit="10" stroke-width="1" />
            </g>
            <g id="Group_1035" data-name="Group 1035" transform="translate(238.537 171.314)">
              <line id="Line_117" data-name="Line 117" x1="11.697" transform="translate(0 5.849)" fill="none" style={stroked(this.props.fill)} stroke-miterlimit="10" stroke-width="1" />
              <line id="Line_118" data-name="Line 118" y2="11.697" transform="translate(5.849)" fill="none" style={stroked(this.props.fill)} stroke-miterlimit="10" stroke-width="1" />
            </g>
            <g id="Group_1036" data-name="Group 1036" transform="translate(0 117.492)">
              <line id="Line_119" data-name="Line 119" x1="11.697" transform="translate(0 5.849)" fill="none" style={stroked(this.props.fill)} stroke-miterlimit="10" stroke-width="1" />
              <line id="Line_120" data-name="Line 120" y2="11.697" transform="translate(5.849)" fill="none" style={stroked(this.props.fill)} stroke-miterlimit="10" stroke-width="1" />
            </g>
            <g id="Group_1037" data-name="Group 1037" transform="translate(119.713 10.273)">
              <path id="Path_112" data-name="Path 112" d="M710.716,354.467c-5.627-1.333-3.85-8.588-9.4-9.921-5.627-1.333-7.329,5.849-12.956,4.516s-3.85-8.588-9.4-9.921c-5.627-1.333-7.329,5.849-12.956,4.516" transform="translate(-666 -338.976)" fill="none" style={stroked(this.props.fill)} stroke-miterlimit="10" stroke-width="1" />
            </g>
            <g id="Group_1038" data-name="Group 1038" transform="translate(15.251 72.923)">
              <path id="Path_113" data-name="Path 113" d="M570.8,431c-5.775,0-5.775-7.4-11.475-7.4-5.775,0-5.775,7.4-11.475,7.4-5.775,0-5.775-7.4-11.475-7.4-5.775,0-5.775,7.4-11.475,7.4" transform="translate(-524.9 -423.6)" fill="none" style={stroked(this.props.fill)} stroke-miterlimit="10" stroke-width="1" />
            </g>
            <g id="Group_1039" data-name="Group 1039" transform="translate(225.729 99.774)">
              <path id="Path_114" data-name="Path 114" d="M849.178,484.249c-4.96-2.887-1.259-9.254-6.293-12.142-4.96-2.887-8.662,3.554-13.7.666-4.96-2.887-1.259-9.254-6.293-12.142-4.96-2.887-8.662,3.554-13.7.666" transform="translate(-809.2 -459.869)" fill="none" style={stroked(this.props.fill)} stroke-miterlimit="10" stroke-width="1" />
            </g>
            <g id="Group_4300" data-name="Group 4300" transform="translate(78.044 58.306)">
              <path id="Path_483" data-name="Path 483" d="M1562.24,2255.736v3.722a11.17,11.17,0,0,1-11.167,11.167h-96.78a11.17,11.17,0,0,1-11.167-11.167v-3.722a29.776,29.776,0,0,1,29.779-29.779h12.819a40.5,40.5,0,0,0,33.92,0h12.818A29.776,29.776,0,0,1,1562.24,2255.736Zm-93.058-70.725a33.5,33.5,0,1,1,33.5,33.5A33.509,33.509,0,0,1,1469.182,2185.012Z" transform="translate(-1443.126 -2151.511)" fill="none" style={stroked(this.props.fill)} stroke-width="3" />
            </g>
          </g>
        </g>
      </svg>
    )
  }
}
