/* LIBS */
import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
/* ACTIONS */
import { resetPassword } from '../redux/actions/auth'
/* I18n */
import i18n from '../i18n'
/* UI */
import LogoEvnts from './components/ui/LogoEvnts'
import Input from './components/ui/Input'
import Button from './components/ui/Button'
import Alert from './components/ui/Alert'
import Loader from './components/Loader'
import { Get } from '../utils/request'
import Config from '../middleware/config'

const LogoWrap = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 20px 0px;
  width: 100%;
  @media (min-width: 768px) {
    margin: 40px 0px;
  }
`

const Main = styled.main`
  display: flex;
  height: 90vh;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #FFF;
  @media (min-width: 768px) {
    background-color: #FAFAFA;
    height: 100vh;
  }
`
const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  padding: 10px 10px;
  box-sizing: border-box;
  width: 100%;
`

const ResetWrapper = styled.div`
  border-radius: 3px;
  margin: 0 5px;
  max-width: 390px;
  background-color: #FFF;
  transition: all 0.2s linear;
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 768px) {
    border: none;
      border-radius: 2px;
      box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
  }
`

class ResetPassword extends Component {
  constructor () {
    super()
    this.state = {
      password: '',
      error: false,
      isLoading: false
    }
  }

  submit (e) {
    e.preventDefault()
    this.setState({error: false})
    const { password } = this.state
    const { token } = this.props.match.params
    if (password && token) {
      this.props.resetPassword(password, token)
    } else {
      this.setState({error: i18n.t('reset_password.user_error', this.props.language)})
    }
  }

  passwordChange (password) {
    this.setState({password: password.target.value.trim()})
  }

  componentDidMount() {
    this.setState({isLoading: true})
    const { token } = this.props.match.params
    const endpoint = Config.url.verificarTokenRedefinirSenha.replace('{TOKEN}', token)
    Get(endpoint)
      .then(value => {
        if (!value.valid) {
          alert(`Este link para redefinção de senha expirou`)
          this.props.history.push('/')
        }
        this.setState({ isLoading: false })
      })
      .catch(e => {
        console.error(e)
        alert(`Ocorreu um erro ao verificar o token de atualização de senha`)
      })
  }

  render () {
    const { language, isFetching } = this.props
    const { isLoading } = this.state
    return (
      <Main>
        {(isFetching || isLoading) ? <Loader /> : null}
        <ResetWrapper>
          <LogoWrap>
            <LogoEvnts primary style={{ width: '200px' }} />
          </LogoWrap>
          <Form onSubmit={this.submit.bind(this)}>
            {this.state.error
              ? <Alert type={'negative'}>
                {this.state.error}
              </Alert> : null}
            <Input
              type={'password'}
              label={i18n.t('reset_password.password', { language })}
              placeholder={i18n.t('reset_password.password_placeholder', { language })}
              onChange={this.passwordChange.bind(this)}
              error={this.state.error}
              name={'username'}
            />
            <Button
              type='submit'
              category={'secondary'}
              fontWeight={800}
              full>
              {i18n.t('reset_password.send_action', { language })}
            </Button>
          </Form>
        </ResetWrapper>
      </Main>
    )
  }
}

function mapStateToProps (state) {
  const { app, auth } = state
  const { language } = app
  const { isAuthenticated, isFetching } = auth
  return {
    language,
    isAuthenticated,
    isFetching
  }
}

export default connect(mapStateToProps, { resetPassword })(ResetPassword)
