import React, { Component } from 'react'
import { defaultTheme } from '../../styles/themes'

export default class AlertNormalIcon extends Component {
  render() {
    return (
      <svg version="1.1" width={this.props.width || '100%'} id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 224.36 141.26" style={{ ...this.props.style, enableBackground: 'new 0 0 233.69 136.38' }}>
        <g>
          <g>
            <g>
              <path style={{ fill: this.props.fill || defaultTheme.colors.whiteLabel.primary }} d="M201.66,5.83c1.44,0,2.76,0.38,3.95,1.13c1.46,0.92,2.27,2.12,2.43,3.58l10.93,97.45
                c0.16,1.47-0.36,2.81-1.58,4.03c-1.22,1.22-2.74,1.93-4.57,2.14L23.68,135.37c-0.33,0.04-0.66,0.06-0.98,0.06
                c-1.44,0-2.76-0.38-3.95-1.13c-1.46-0.92-2.27-2.11-2.43-3.58L5.39,33.27c-0.16-1.47,0.36-2.81,1.58-4.03
                c1.22-1.22,2.74-1.93,4.57-2.14L200.68,5.89C201.01,5.85,201.34,5.83,201.66,5.83 M26.79,102.13c1.39-0.16,2.76-0.23,4.09-0.23
                c5.8,0,11.05,1.48,15.72,4.44c5.75,3.64,8.96,8.45,9.63,14.42l121.59-13.63c-0.67-5.98,1.4-11.38,6.2-16.2
                c4.8-4.82,10.93-7.65,18.39-8.49l-4.86-43.31c-1.39,0.16-2.76,0.23-4.09,0.23c-5.8,0-11.05-1.48-15.72-4.44
                c-5.75-3.64-8.96-8.44-9.63-14.42L46.53,34.14c0.67,5.98-1.4,11.38-6.2,16.2c-4.8,4.82-10.94,7.65-18.39,8.49L26.79,102.13
                M112.25,39.57c3.33,0,6.52,0.55,9.57,1.66c4.1,1.49,7.51,3.64,10.23,6.44c2.72,2.8,4.93,5.91,6.62,9.32
                c1.7,3.42,2.74,6.85,3.12,10.32c0.39,3.46,0.13,7.05-0.76,10.75c-0.9,3.71-2.36,7.23-4.39,10.56c-2.03,3.33-4.88,6.18-8.55,8.55
                c-3.67,2.36-7.82,3.81-12.45,4.32c-1.19,0.13-2.37,0.2-3.53,0.2c-3.33,0-6.52-0.55-9.57-1.66c-4.1-1.49-7.52-3.64-10.23-6.44
                c-2.72-2.8-4.93-5.91-6.62-9.32c-1.69-3.41-2.74-6.85-3.12-10.32c-0.39-3.46-0.13-7.04,0.76-10.75c0.9-3.71,2.36-7.22,4.39-10.56
                c2.03-3.33,4.88-6.18,8.55-8.55c3.67-2.36,7.82-3.81,12.45-4.32C109.91,39.64,111.09,39.57,112.25,39.57 M201.66,2.83
                c-0.43,0-0.87,0.03-1.31,0.07L11.2,24.12c-2.52,0.28-4.66,1.29-6.36,3c-2.35,2.36-2.62,4.83-2.43,6.48l10.93,97.45
                c0.19,1.66,1,4.01,3.81,5.78c1.67,1.06,3.54,1.59,5.55,1.59c0.43,0,0.87-0.03,1.32-0.07l189.14-21.21c2.52-0.28,4.65-1.29,6.36-3
                c2.35-2.36,2.62-4.83,2.43-6.48l-10.93-97.45c-0.19-1.65-1-4-3.81-5.78C205.53,3.37,203.67,2.83,201.66,2.83L201.66,2.83z
                M49.65,36.81L165.67,23.8c1.39,5.55,4.91,10.14,10.48,13.67c5.14,3.25,10.97,4.9,17.32,4.9c0.47,0,0.95-0.01,1.43-0.03
                l4.21,37.57c-6.82,1.28-12.6,4.27-17.21,8.91c-4.65,4.67-7.06,9.92-7.19,15.65L58.69,117.46c-1.39-5.55-4.91-10.14-10.48-13.67
                c-5.14-3.25-10.97-4.9-17.33-4.9c-0.47,0-0.95,0.01-1.43,0.03l-4.21-37.57c6.82-1.28,12.6-4.27,17.21-8.91
                C47.11,47.78,49.52,42.53,49.65,36.81L49.65,36.81z M112.25,36.57C112.25,36.57,112.25,36.57,112.25,36.57
                c-1.27,0-2.57,0.07-3.86,0.22c-5.07,0.57-9.69,2.18-13.74,4.78c-4.04,2.6-7.23,5.8-9.49,9.51c-2.18,3.58-3.78,7.42-4.75,11.41
                c-0.97,4.03-1.25,8-0.83,11.79c0.43,3.79,1.58,7.6,3.42,11.31c1.83,3.68,4.24,7.07,7.16,10.08c3.02,3.11,6.84,5.52,11.36,7.17
                c3.36,1.22,6.92,1.84,10.59,1.84c1.26,0,2.56-0.07,3.86-0.22c5.07-0.57,9.69-2.18,13.74-4.78c4.04-2.6,7.23-5.8,9.49-9.51
                c2.18-3.58,3.78-7.42,4.75-11.41c0.97-4.03,1.25-8,0.83-11.79c-0.43-3.79-1.58-7.6-3.42-11.31c-1.82-3.68-4.23-7.07-7.16-10.08
                c-3.03-3.11-6.85-5.53-11.36-7.17C119.48,37.19,115.92,36.57,112.25,36.57L112.25,36.57z"/>
            </g>
          </g>
          <g>
            <path style={{ fill: this.props.fill || defaultTheme.colors.whiteLabel.primary }} d="M114.48,80.98c2.9-0.32,4.76,1.13,5.08,3.97c0.31,2.84-1.2,4.61-4.09,4.93c-2.9,0.32-4.7-1.08-5.02-3.92
              C110.13,83.12,111.58,81.3,114.48,80.98z M107.69,53.8l7.43-0.82l1.43,23.03l-5.21,0.58L107.69,53.8z"/>
          </g>
        </g>
        </svg>

    )
  }
}
