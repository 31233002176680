/* LIBS */
import React from 'react'
import { Route } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
/* SCREENS */
import Login from '../screens/Organizer/Login/index'
import Signup from '../screens/Organizer/Signup/index'
import Events from '../screens/Organizer/Events'
import FormGeneral from '../screens/Organizer/Events/Forms/General'
import FormServices from '../screens/Organizer/Events/Forms/Services'
import FormSuppliersSelect from '../screens/Organizer/Events/Forms/SuppliersSelect'
import FormMoreSuppliersSelect from '../screens/Organizer/Events/Forms/MoreSuppliersSelect'
import FormAccommodation from '../screens/Organizer/Events/Forms/Accommodation/index'
import FormRooms from '../screens/Organizer/Events/Forms/Rooms/index'
import FormLodging from '../screens/Organizer/Events/Forms/Lodging/index'
import FormTransfer from '../screens/Organizer/Events/Forms/Transfer/index'
import FormMenus from '../screens/Organizer/Events/Forms/Menus/index'
import Payment from '../screens/Organizer/Events/Forms/Payment'
import Resume from '../screens/Organizer/Events/Forms/Resume'
import ResumoTotal from '../screens/Organizer/Events/Forms/ResumoTotal'
import ResumoTotalEscolhido from '../screens/Organizer/Events/Forms/ResumoTotalEscolhido'
import FormFAB from '../screens/Organizer/Events/Forms/Food_and_Beverage/index'
import FormEquipments from '../screens/Organizer/Events/Forms/Equipments/index'
import ListagemHoteis from '../screens/Organizer/Events/Forms/ListagemHoteis'
import ResumoComAgreement from '../screens/Organizer/Events/Forms/ResumoComAgreement'
import ResumoTransfer from '../screens/Organizer/Events/Forms/ResumoTransfer'
import ResumoOtherServices from '../screens/Organizer/Events/Forms/ResumoOtherServices'
import ChooseRoomBudget from '../screens/Organizer/Events/Forms/RoomDetails'
import FoodAndBeverageDetails from '../screens/Organizer/Events/Forms/FoodAndBeverageDetails'
import LodgingDetails from '../screens/Organizer/Events/Forms/LodgingDetails'
import ResellerDashboard from '../screens/Reseller/Dashboard'
import OrganizerAdministrator from '../screens/Organizer/Dashboards'
import DashboardGastos from '../screens/Organizer/Dashboards/Gastos/DashboardGastos'
import FormOtherServices from '../screens/Organizer/Events/Forms/OtherServices'

const OrganizerRoutes = () => {
  return (
    <div>
      <Route path='/v/signin' exact component={Login} />
      <Route path='/organizer/signup/c2VjcmV0' exact component={Signup} />
      <Route path='/organizer/signin' exact component={Login} />
      <PrivateRoute type='ORGANIZER' path='/organizer' exact component={Events} />
      <PrivateRoute type='ORGANIZER' path='/organizer/event/:id?' component={FormGeneral} />
      <PrivateRoute type='ORGANIZER' path='/organizer/event/:id?/suppliers/select' exact component={FormSuppliersSelect} />
      <PrivateRoute type='ORGANIZER' path='/organizer/event/:id?/more-suppliers/select' exact component={FormMoreSuppliersSelect} />
      <PrivateRoute type='ORGANIZER' path='/organizer/event/services/:id' exact component={FormServices} />
      <PrivateRoute type='ORGANIZER' path='/organizer/event/accommodation/:id' exact component={FormAccommodation} />
      <PrivateRoute type='ORGANIZER' path='/organizer/event/rooms/:id/:briefing?' exact component={FormRooms} />
      <PrivateRoute type='ORGANIZER' path='/organizer/event/food-and-beverage/:id/:briefing?' exact component={FormFAB} />
      <PrivateRoute type='ORGANIZER' path='/organizer/event/equipments/:id/:briefing?' exact component={FormEquipments} />
      <PrivateRoute type='ORGANIZER' path='/organizer/event/lodging/:id/:briefing?' exact component={FormLodging} />
      <PrivateRoute type='ORGANIZER' path='/organizer/event/transfer/:id/:briefing?' exact component={FormTransfer} />
      <PrivateRoute type='ORGANIZER' path='/organizer/event/other-services/:id/:briefing?' exact component={FormOtherServices} />
      <PrivateRoute type='ORGANIZER' path='/organizer/event/menus/:id' exact component={FormMenus} />
      <PrivateRoute type='ORGANIZER' path='/organizer/event/resume/:id' exact component={Resume} />
      <PrivateRoute type='ORGANIZER' path='/organizer/event/edit/:id' exact component={Resume} />
      <PrivateRoute type='ORGANIZER' path='/organizer/event/:eventId/resume/budget/:accountId?' exact component={ResumoTotal} />
      <PrivateRoute type='ORGANIZER' path='/organizer/event/:eventId/resume/chosen' exact component={ResumoTotalEscolhido} />
      <PrivateRoute type='ORGANIZER' path='/organizer/event/:eventId/budget/place' exact component={ListagemHoteis} />
      <PrivateRoute type='ORGANIZER' path='/organizer/event/:eventId/budget/account/:accountId' exact component={ResumoComAgreement} />
      <PrivateRoute type='ORGANIZER' path='/organizer/event/:eventId/budget/transfer/:accountId' exact component={ResumoTransfer} />
      <PrivateRoute type='ORGANIZER' path='/organizer/event/:eventId/budget/other-services/:accountId' exact component={ResumoOtherServices} />
      <PrivateRoute type='ORGANIZER' path='/organizer/event/:eventId/budget/account/:accountId/rooms' exact component={ChooseRoomBudget} />
      <PrivateRoute type='ORGANIZER' path='/organizer/event/:eventId/budget/account/:accountId/food-and-beverage' exact component={FoodAndBeverageDetails} />
      <PrivateRoute type='ORGANIZER' path='/organizer/event/:eventId/budget/account/:accountId/lodging' exact component={LodgingDetails} />
      <PrivateRoute type='ORGANIZER' path='/organizer/dashboard' exact component={ResellerDashboard} />
      <PrivateRoute type='ORGANIZER' path='/organizer/dashboard/administrator' exact component={OrganizerAdministrator} />
      <PrivateRoute type='ORGANIZER' path='/organizer/dashboard/gastos' exact component={DashboardGastos} />
      {
        // TODO
        // Esses componentes precisam de uma melhora visual, responsivo está ruim e os textos precisam ser melhor encaixado, quando for melhorar pede uns palpites da Mie
      }
      <Route path='/organizer/evento/payment' exact component={Payment} />
    </div>
  )
}

export default OrganizerRoutes
