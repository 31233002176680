const dict = {
  EXECUTIVE_CAR: 'Carro executivo',
  ARMORED_CAR: 'Carro blindado',
  MINI_VAN: 'Mini van',
  ARMORED_MINI_VAN: 'Mini van blindada',
  VAN: 'Van',
  ARMORED_VAN: 'Van blindada',
  MINIBUS: 'Micro ônibus',
  BUS: 'Ônibus',
  extraKm: 'km excedente',
  overtime: 'Hora extra / parada',
  MONOLINGUE_PT: 'monolíngue (português)',
  BILINGUE_PT_EN: 'bilíngue (português e inglês)',
  BILINGUE_PT_ES: 'bilíngue (português e espanhol)',
  water: 'Água',
  BOTTLE: 'Garrafa',
  CUP: 'Copo',
  serviceFee: 'Taxas de serviço',
  otherFees: 'Outras taxas',
  GENERAL_CONDITIONS: 'Condições gerais',
  NO_SHOW_POLICIES: 'Política de no show',
  CANCELLATION_POLICIES: 'Política de cancelamento',
  DESCRIPTION_GENERAL_CONDITIONS: 'Digite aqui as suas condições gerais.',
  DESCRIPTION_NO_SHOW_POLICIES: 'Digite aqui a sua política de no show.',
  DESCRIPTION_CANCELLATION_POLICIES: 'Digite aqui a sua política de cancelamento.'
}

export const getNameFromKey = function (key) {
  if (!key) return key
  return dict[key] || key
}

export const getKeyFromName = function (name) {
  if (!name) return name
  return Object.keys(dict).find(key => dict[key] === name) ||
    name.replace(/\s/g, '_').toLowerCase()
}
