const checkIfItemWasEdited = (obj, item) => {
  return obj && obj[item] && (obj[item].changeType === 'updated' || obj[item].changeType === 'deleted')
}
const checkIfItemsWasEdited = (obj, items) => {
  return obj && items.some(attribute => obj[attribute] && (obj[attribute].changeType === 'updated' || obj[attribute].changeType === 'deleted'))
}
const checkIfItemWasEditedForDates = (obj, item) => {
  return obj && obj[item] && Object.keys(obj[item]).some(index => obj[item][index].changeType === 'updated' || obj[item][index].changeType === 'deleted')
}
export {
  checkIfItemWasEdited,
  checkIfItemsWasEdited,
  checkIfItemWasEditedForDates
}
