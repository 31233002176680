import React, { Component } from 'react'
import DayPicker from 'react-day-picker'
import { monthNames, daysNames, shortDaysNames } from './config'
import 'react-day-picker/lib/style.css'
import './style.css'

export default class Datepicker extends Component {
  static defaultProps = {
    onChange: () => {},
    dates: []
  }

  constructor(props) {
    super(props)
    this.state = {
      selectedDay: props.dates
    }
  }

  handleClick = (day) => {
    this.setState({selectedDay: [day]}, () => {
      this.props.onChange(this.state.selectedDay)
    })
  }

  render() {
    return (
        <DayPicker
          months={monthNames}
          weekdaysLong={daysNames}
          weekdaysShort={shortDaysNames}
          className='EvntsColor'
          placeholder={'Select dates'}
          onDayClick={this.handleClick}
          selectedDays={this.state.selectedDay}/>
    )
  }
}
