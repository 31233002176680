import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import CheckBox from '../../components/ui/CheckBox'
import Input from '../../components/ui/Input'
import Select from '../../components/ui/Select'
/* UTILS */
import { formatCurrency, removeCurrency } from '../../../utils/money'
import { formatFluid, removeFluid } from '../../../utils/fluid'

const ContentForm = styled.div`
  background-color: #fff;
  padding: 10px;
  margin: 10px 10px;
  min-height: 120px;
  box-sizing: border-box;
  border: none;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16),0 0 0 1px rgba(0,0,0,0.08);
  width: 100%;
  display: block;
`
const TitleMenu = styled.span`
  font-size: 16px;
  padding: 0 10px;
`

const FormDiv = styled.div`
  display: ${props => props.isVisible ? 'flex' : 'none'};
  flex-wrap: wrap;
`

const Row = styled.div`
  display: flex;
`

class FormRoomService extends Component {
  state = {
    on: false,
    forms: false
  }

  componentDidMount () {
    const { data, name } = this.props
    if (data) {
      const { enabled, items } = data.menus[name]
      const menus = items.reduce((prev, cur) => {
        prev[cur.name] = cur
        return prev
      }, {})
      this.setState({on: enabled, forms: menus})
    }
  }

  toggle = () =>
    this.setState(
      ({ on }) => ({ on: !on })
    )

  handleValue = (index, name, e) => {
    const { value } = e.target
    const { forms } = this.state
    forms[index][name] = value
    this.setState({ forms: forms })
  }

  changeMoney = (index, name, e) => {
    const { value } = e.target
    const { forms } = this.state
    const newValue = removeCurrency(value)

    forms[index][name]['amount'] = newValue
    this.setState({ forms: forms })
  }


  render() {
    const { isActive, items } = this.props
    const { on, forms} = this.state
    const { water, coffee, tea, milk, juice, soda } = forms
    return (
      <CheckBox
        label={'Serviço de sala'}
        name={'rooms_service[enabled]'}
        checked={on}
        value={true}
        style={{ display: isActive ? 'block' : 'none' }}
        onClick={this.toggle}>
        {forms ?
        <FormDiv isVisible={on}>
          <ContentForm style={{ display: items.water ? 'block' : 'none' }}>
            <TitleMenu>Água </TitleMenu>
            <Row>
              <Select
                label={'Tipo'}
                name={'rooms_service[items][water][type]'}
                value={water.type}
                error={water.type === ''}
                onChange={(e) => this.handleValue('water', 'type', e)}>
                <option value={''}>Selecione</option>
                <option value={'glass'}>Copo</option>
                <option value={'bottle'}>Garrafa</option>
                <option value={'gallon'}>Galão</option>
              </Select>
              <Input
                type={'text'}
                label={'Quantidade (em ml)'}
                placeholder={'0 ml'}
                name={'rooms_service[items][water][quantity]'}
                value={formatFluid(water.quantity)}
                error={removeFluid(water.quantity) <= 0}
                onChange={(e) => this.handleValue('water', 'quantity', e)}
              />
            </Row>
            <Row>
              <Input
                type={'text'}
                label={'Valor (por unidade)'}
                placeholder={'R$0,00'}
                name={'rooms_service[items][water][price]'}
                value={formatCurrency(water.price.amount)}
                error={water.price.amount <= 0}
                onChange={(e) => this.changeMoney('water', 'price', e)}
              />
            </Row>
            <Input type={'hidden'} name={'rooms_service[items][water][keyName]'} value={'water'}/>
          </ContentForm>
          <ContentForm style={{ display: items.coffee ? 'block' : 'none' }}>
            <TitleMenu>Térmica de café</TitleMenu>
            <Row>
              <Input
                type={'text'}
                label={'ml (por térmica)'}
                placeholder={'Quantos ml?'}
                name={'rooms_service[items][coffee][quantity]'}
                value={formatFluid(coffee.quantity)}
                error={removeFluid(coffee.quantity) <= 0}
                onChange={(e) => this.handleValue('coffee', 'quantity', e)}
              />
              <Input
                type={'text'}
                label={'Valor (por unidade)'}
                placeholder={'R$0,00'}
                name={'rooms_service[items][coffee][price]'}
                value={formatCurrency(coffee.price.amount)}
                error={coffee.price.amount <= 0}
                onChange={(e) => this.changeMoney('coffee', 'price', e)}
              />
            </Row>
            <Input type={'hidden'} name={'rooms_service[items][coffee][keyName]'} value={'coffee'}/>
          </ContentForm>
          <ContentForm style={{ display: items.tea ? 'block' : 'none' }}>
            <TitleMenu>Térmica de chá</TitleMenu>
            <Row>
              <Input
                type={'text'}
                label={'ml (por térmica)'}
                placeholder={'Quantos ml?'}
                name={'rooms_service[items][tea][quantity]'}
                value={formatFluid(tea.quantity)}
                error={removeFluid(tea.quantity) <= 0}
                onChange={(e) => this.handleValue('tea', 'quantity', e)}
              />
              <Input
                type={'text'}
                label={'Valor (por unidade)'}
                placeholder={'R$0,00'}
                name={'rooms_service[items][tea][price]'}
                value={formatCurrency(tea.price.amount)}
                error={tea.price.amount <= 0}
                onChange={(e) => this.changeMoney('tea', 'price', e)}
              />
            </Row>
            <Input type={'hidden'} name={'rooms_service[items][tea][keyName]'} value={'tea'}/>
          </ContentForm>
          <ContentForm style={{ display: items.milk ? 'block' : 'none' }}>
            <TitleMenu>Térmica de leite</TitleMenu>
            <Row>
              <Input
                type={'text'}
                label={'ml (por térmica)'}
                placeholder={'Quantos ml?'}
                name={'rooms_service[items][milk][quantity]'}
                value={formatFluid(milk.quantity)}
                error={removeFluid(milk.quantity) <= 0}
                onChange={(e) => this.handleValue('milk', 'quantity', e)}
              />
              <Input
                type={'text'}
                label={'Valor (por unidade)'}
                placeholder={'R$0,00'}
                name={'rooms_service[items][milk][price]'}
                value={formatCurrency(milk.price.amount)}
                error={milk.price.amount <= 0}
                onChange={(e) => this.changeMoney('milk', 'price', e)}
              />
            </Row>
            <Input type={'hidden'} name={'rooms_service[items][milk][keyName]'} value={'milk'}/>
          </ContentForm>
          <ContentForm style={{ display: items.juice ? 'block' : 'none' }}>
            <TitleMenu>Jarra de suco</TitleMenu>
            <Row>
              <Input
                type={'text'}
                label={'ml (por jarra)'}
                placeholder={'Quantos ml?'}
                name={'rooms_service[items][juice][quantity]'}
                value={formatFluid(juice.quantity)}
                error={removeFluid(juice.quantity) <= 0}
                onChange={(e) => this.handleValue('juice', 'quantity', e)}
              />
              <Input
                type={'text'}
                label={'Valor (por unidade)'}
                placeholder={'R$0,00'}
                name={'rooms_service[items][juice][price]'}
                value={formatCurrency(juice.price.amount)}
                error={juice.price.amount <= 0}
                onChange={(e) => this.changeMoney('juice', 'price', e)}
              />
            </Row>
            <Input type={'hidden'} name={'rooms_service[items][juice][keyName]'} value={'juice'}/>
          </ContentForm>
          <ContentForm style={{ display: items.soda ? 'block' : 'none' }}>
            <TitleMenu>Refrigerante</TitleMenu>
            <Row>
              <Select
                label={'Tipo'}
                name={'rooms_service[items][soda][type]'}
                value={soda.type}
                error={soda.type === ''}
                onChange={(e) => this.handleValue('soda', 'type', e)}>
                <option value={''}>Selecione</option>
                <option value={'can'}>Lata</option>
                <option value={'bottle'}>Garrafa</option>
              </Select>
              <Input
                type={'text'}
                label={'Quantidade (em ml)'}
                placeholder={'0 ml'}
                name={'rooms_service[items][soda][quantity]'}
                value={formatFluid(soda.quantity)}
                error={removeFluid(soda.quantity) <= 0}
                onChange={(e) => this.handleValue('soda', 'quantity', e)}
              />
            </Row>
            <Row>
              <Input
                type={'text'}
                label={'Valor (por unidade)'}
                placeholder={'R$0,00'}
                name={'rooms_service[items][soda][price]'}
                value={formatCurrency(soda.price.amount)}
                error={soda.price.amount <= 0}
                onChange={(e) => this.changeMoney('soda', 'price', e)} />
            </Row>
            <Input type={'hidden'} name={'rooms_service[items][soda][keyName]'} value={'soda'}/>
          </ContentForm>
        </FormDiv> : null}
      </CheckBox>
    )
  }
}

function mapStateToProps (state) {
  const { forms } = state
  const { foodsAndBeverages } = forms
  const { data } = foodsAndBeverages
  return {
    data
  }
}

export default connect(mapStateToProps)(FormRoomService)
