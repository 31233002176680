/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../styles/themes'
/* UI */
import Loader from './Loader'

const Button = styled.button`
  align-items: center;
  cursor: pointer;
  font-size: ${props => props.fontSize ? props.fontSize : '16px'} ;
  font-family: ${defaultTheme.fonts.secondary};
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  background-color: transparent;
  color: ${defaultTheme.colors.primary.default};
  border: 2px solid ${defaultTheme.colors.primary.default};
  border-radius: 3px;
  width: ${props => props.full ? 'calc(100% - 20px)' : 'auto'};
  padding: ${props => props.small ? '3px 5px' : '14px 10px'};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 100%;
  &:hover {
    background-color: #44bfb3;
    color: #FFFFFF;
  }
  &::disabled {
    background-color: #CCC;
    color: #666;
    border: 2px solid #CCC;
  }
`
const InputControl = styled.div`
  margin: ${props => props.margin ? props.margin : '10px 10px'};
  width: 100%;
  box-sizing: border-box;
`

const Label = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  box-sizing: border-box;
  padding: 5px 0px 5px 0px;
  display: inline-block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  min-height: 25px;
`
const LabelError = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  box-sizing: border-box;
  padding: 5px 0px 5px 10px;
  display: ${props => props.error ? 'inline-block' : 'none'};
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  min-height: 25px;
`

const Strong = styled.strong`
  color: ${props => props.error ? 'red' : defaultTheme.colors.whiteLabel.primary};
  font-weight: bold;
`

export default class UploadField extends Component {
  constructor () {
    super()
    this.state = {
      isLoading: false
    }
    this.fileUpload = React.createRef()
  }

  triggerClick = () => {
    this.fileUpload.current.click()
  }

  upload = (e) => {
    const form = new FormData()
    const token = window.localStorage.getItem('user_token')
    this.setState({isLoading: true})
    form.append('image', e.target.files[0])
    fetch('/api/v1/upload', {
      method: 'POST',
      headers: {
        'Authorization': token
      },
      body: form
    })
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error(response)
      }
    })
    .then(response => {
      this.setState({isLoading: false})
      if (response.success) {
        this.props.onFileUpload(response.data.file.url)
      } else {
        console.error(response)
      }
    })
    .catch(e => {
      this.setState({isLoading: false})
      console.error(e)
    })
  }

  render() {
    const { placeholder, text, error, isRequired, errorText} = this.props
    const { isLoading } = this.state
    return <InputControl margin={this.props.margin}>
            { isLoading ? <Loader /> : null }
            <Label error={error}>{placeholder}</Label>
            <Strong error={error}>{isRequired ? '*' : ''}</Strong>
            <LabelError error={error}>({errorText})</LabelError>
            <Button
              type='button'
              onClick={this.triggerClick}>
                {text}
            </Button>
            <input
              type='file'
              accept='image/*, .pdf'
              ref={this.fileUpload}
              style={{display: 'none'}}
              onChange={this.upload.bind(this)}/>
          </InputControl>
  }
}
