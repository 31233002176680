/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
/* UTILS */
import i18n from '../../../../i18n'
import { types } from '../EventUtils'
import config from '../../../../middleware/config'
import { fetchEvent } from '../../../../middleware/apiWrapper'
import { removeCurrency } from '../../../../utils/money'
import { Get, Post } from '../../../../utils/request'
import { temFeatureFlag } from '../../../../utils/tiposUsuario'
/* UI */
import Input from '../../../components/ui/Input'
import Select from '../../../components/ui/Select'
import SelectFilter from '../../../components/ui/SelectFilter'
import BreadCrumb from '../../../components/BreadCrumb'
/* import RadioGroup from '../../../components/ui/RadioGroup'*/
/* STYLES */
import { defaultTheme } from '../../../styles/themes'
import Button from '../../../components/ui/Button'
import AlertIcon from '../../../components/ui/AlertIcon'
import Subtitle from '../../../components/ui/Subtitle'
import Modal from '../../../components/Modal'
import Textarea from '../../../components/ui/Textarea'
/* COMPONENTS */
import Header from '../../Header'
import InputAddress from '../../../components/InputAddress'
import Alert from '../../../components/ui/Alert'
import RightBoxInput from '../../../components/ui/RightBoxInput'
import Loader from '../../../components/Loader'
import CheckboxButton from '../../../components/ui/CheckboxButton'
import InputDatepicker from '../../../components/InputDatepicker'
import Timepicker from '../../../components/Timepicker'
import Main from '../../../components/Main'
import Body from '../../../components/Body'
import './main.css'
import MoneyInput from '../../../components/ui/MoneyInput';
import moment from 'moment'
import GerenciaFluxoEdicao from '../../../components/GerenciaFluxoEdicao';
import { retornaUrlFluxoEdicao } from '../../../../utils/fluxoCriacaoEvento';
import { ehChainUser } from '../../../../utils/tiposUsuario'

const MainMobile = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #FAFAFA;
`
const FormContent = styled.form`
  padding: 10px 10px;
  box-sizing: border-box;
  width: 100%;
`

const Group = styled.div`
  display: flex;
  width: 100%;
`

const Etapa = styled.p`
  font-size: 0.8em;
  color: #727272;
  font-family: 'Open Sans';
  line-height: 1.2;
`

const Title = styled.h1`
  font-family: ${defaultTheme.fonts.secondary};
  line-height: 1.2;
  font-size: 1.3em;
  margin-bottom: 20px;
`

const Label = styled.p`
  font-size: 15px;
  font-family: ${defaultTheme.fonts.secondary};
  line-height: 1.2;
  padding-left: 10px;
  padding-top: 20px;
`

const Form = styled.form`
  flex-wrap: wrap;
  display: flex;
  width: 100%;
`

const FixedDiv = styled.div`
  position: fixed;
  top: 46px;
  bottom: 0;
  width: 25%;
  height: fit-content;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  background: #fff;
  padding: 15px;
  margin-top: 24px;
`
const Bold = styled.span`
  font-weight: bold;
`

const preventDefault = event => {
  if (event) {
    if (event.preventDefault && (typeof event.preventDefault) === 'function') event.preventDefault()
  }
}

class NewEvent extends Component {
  constructor () {
    super()
    this.state = {
      myClients: [],
      myClientsFormated: [],
      chosenOrganizer: null,
      chosenReseller: null,
      myResellers: [],
      backendDeadline: '',
      event: {
        name: '',
        start: '',
        end: '',
        startTime: '00:00:00',
        endTime: '00:00:00',
        hasLocal: 'false',
        city: '',
        address: '',
        location: {},
        type: '',
        anotherType: '',
        goal: '',
        segment: '',
        status: 'INACTIVE',
        numberOfParticipants: '',
        participantsProfile: '',
        budget: '',
        observations: '',
        radius: 10,
        initialSLAHours: 48,
        extendedSLAHours: 0,
        services: {
          room: { requested: false, id: null },
          foodsAndBeverages: { requested: false, id: null },
          lodging: { requested: false, id: null },
          aero: { requested: false, id: null },
          transfer: { requested: false, id: null },
          equipments: { requested: false, id: null },
          otherServices: { requested: false, id: null }
        },
        organizerId: '',
        friendlyId: '',
        isEmergency: false,
        reason: '',
        internalId: '',
      },
      errors: {
        name: '',
        start: '',
        end: '',
        startTime: '00:00:00',
        endTime: '00:00:00',
        hasLocal: 'true',
        city: '',
        address: '',
        location: '',
        type: '',
        anotherType: '',
        goal: '',
        segment: '',
        numberOfParticipants: '',
        participantsProfile: '',
        budget: '',
        observations: '',
        initialSLAHours: ''
      },
      criticalUpdate: {
        city: '',
        address: '',
        location: '',
        radius: '',
      },
      errorMessage: false,
      locationErrorMessage: false,
      startDateMessage: false,
      isLoading: false,
      centroDeCustos: [],
      errorRef: React.createRef(),
      salvaEdicao: false,
      modalDoFDS: false
    }

    this.handlerValue = this.handlerValue.bind(this)
  }

  componentDidMount () {
    const { id } = this.props.match.params
    const userType = window.localStorage.getItem('user_type')
    if (ehChainUser()) {
      alert('Você não tem permissão para acessar esta página.')
      this.props.history.push(`/organizer`)
    }
    this.renderizaListaCentroDeCusto()
    if (id) {
      this.loadEventData(id)
    } else {
      if (userType === 'RESELLER') {
        this.setState({ isLoading: true })
        const { api: { base, version, routes: { reseller, accounts } } } = config
        const endpointReseller = `${base}${version}${accounts}${reseller}/client/my-clients-info`
        Get(endpointReseller).then((clients) => {
          this.setState({
            myClients: clients,
            myClientsFormated: clients.map(item => {
              return {
                value: item.id,
                label: `[${item.name}] : ${item.given_name} - ${(item.profile && item.profile.document) || item.document}`
              }
            }),
            isLoading: false
          })
        }).catch(err => {
          console.error(err)
        })
      }
    }
    if (userType === 'ORGANIZER') {
      const { api: { base, version, routes: { accounts } } } = config
      const endpointReseller = `${base}${version}${accounts}/resellers`
      Get(endpointReseller).then((resellers) => {
        const myResellersFormated = resellers.map(item => {
          return { value: item.id, label: `${item.name}` }
        })
        const { event } = this.state
        if(myResellersFormated.length > 0) {
          event.resellerId = myResellersFormated[0].value
        }
        this.setState({
          event,
          chosenReseller: myResellersFormated.length > 0 ? myResellersFormated[0] : null,
          myResellers: resellers,
          myResellersFormated
        })
        }).catch(err => {
          console.error(err)
      })
    }
  }

  renderizaListaCentroDeCusto = () => {
    const { api: { base, version } } = config
    var endpoint = `${base}${version}/accounts/cost-centers`
    return Get(endpoint)
      .then((result) => this.setState({ centroDeCustos : result }))
      .catch(err => {
        console.error(err)
      })
  }

  loadEventData = (id) => {
    this.setState({ isLoading: true })
    fetchEvent(id).then(data => {
      const userType = window.localStorage.getItem('user_type')
      if (userType === 'RESELLER') {
        this.setState({ isLoading: true })
        const { api: { base, version, routes: { reseller, accounts } } } = config
        const endpointReseller = `${base}${version}${accounts}${reseller}/client/my-clients-info`
        Get(endpointReseller).then((clients) => {
          this.setState({
            myClients: clients,
            myClientsFormated: clients.map(item => {
              return {
                value: item.id,
                label: `[${item.name}] : ${item.given_name} - ${(item.profile && item.profile.document) || item.document}`
              }
            }),
            chosenOrganizer: clients.map(item => {
                return {
                  value: item.id,
                  label: `[${item.name}] : ${item.given_name} - ${(item.profile && item.profile.document) || item.document}`
                }
              }).find(item => item.value === (data.organizerId || data.accountId)),
            isLoading: false,
            criticalUpdate: {
              city: data.location.city,
              address: data.location.address === 'false' ? '' : data.location.address,
              location: data.location,
              radius: data.location.radius,
            },
            event: {
              ...data,
              start: [moment(data.start, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br')],
              end: [moment(data.end, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br')],
              hasLocal: (!!data.location.address).toString(),
              city: data.location.city,
              address: data.location.address === 'false' ? '' : data.location.address,
              type: types().find(type => type.value === data.type) ? 'OUTROS' : data.type,
              anotherType: data.type,
              budget: data.budget.amount,
              radius: data.location.radius,
              organizerId: data.organizerId || data.accountId,
              initialSLAHours: data.initialSLAHours || 48,
              isEmergency: data.emergency && data.emergency.isEmergency,
              reason: data.emergency && data.emergency.reason
            },
            eventoNaoAlterado: {
              ...data,
              start: [moment(data.start, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br')],
              end: [moment(data.end, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br')],
              hasLocal: (!!data.location.address).toString(),
              city: data.location.city,
              address: data.location.address === 'false' ? '' : data.location.address,
              type: types().find(type => type.value === data.type) ? 'OUTROS' : data.type,
              anotherType: data.type,
              budget: data.budget.amount,
              radius: data.location.radius,
              organizerId: data.organizerId || data.accountId,
              isEmergency: data.emergency && data.emergency.isEmergency,
              reason: data.emergency && data.emergency.reason
            }
          })
        }).catch(err => {
          console.error(err)
        })
      } else {
        this.setState({
          isLoading: false,
          criticalUpdate: {
            city: data.location.city,
            address: data.location.address === 'false' ? '' : data.location.address,
            location: data.location,
            radius: data.location.radius,
          },
          event: {
            ...data,
            start: [moment(data.start, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br')],
            end: [moment(data.end, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br')],
            hasLocal: (!!data.location.address).toString(),
            city: data.location.city,
            address: data.location.address === 'false' ? '' : data.location.address,
            type: types().find(type => type.value === data.type) ? 'OUTROS' : data.type,
            anotherType: data.type,
            budget: data.budget.amount,
            radius: data.location.radius,
            organizerId: data.organizerId || data.accountId,
            isEmergency: data.emergency && data.emergency.isEmergency,
            reason: data.emergency && data.emergency.reason
          },
            eventoNaoAlterado: {
              ...data,
              start: [moment(data.start, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br')],
              end: [moment(data.end, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br')],
              hasLocal: (!!data.location.address).toString(),
              city: data.location.city,
              address: data.location.address === 'false' ? '' : data.location.address,
              type: types().find(type => type.value === data.type) ? 'OUTROS' : data.type,
              anotherType: data.type,
              budget: data.budget.amount,
              radius: data.location.radius,
              organizerId: data.organizerId || data.accountId,
              isEmergency: data.emergency && data.emergency.isEmergency,
              reason: data.emergency && data.emergency.reason
            }
        })
      }
    })
  }

  checkEdicao = (e) => {
    preventDefault(e)
    const { event } = this.state
    if (this.validateFormData(event) &&
      this.validateFormLocationData(event) &&
      this.validateFormStartDate(event) &&
      this.validateFormStartTime(event) &&
      this.validadeServices()) {
      this.setState({ salvaEdicao: true }, () => this.setState({ salvaEdicao: false }))
    } else {
      this.setState({ isLoading: false })
      window.scrollTo(0, this.state.errorRef.offsetTop - 10)
      if (!this.validateFormData(event)) {
        this.setState({ errorMessage: 'Preencha todos os campos obrigatórios.' })
      } else if (!this.validateFormLocationData(event)) {
        this.setState({ locationErrorMessage: 'Selecione uma localização válida.' })
      } else if (!this.validateFormStartDate(event)) {
        this.setState({ startDateMessage: 'Você não pode selecionar uma data entre hoje e amanhã.' })
      } else if (!this.validateFormStartTime(event)) {
        this.setState({ startDateMessage: 'Se o evento for em apenas um único dia o horário de fim não pode ser menor que o horário de início.' })
      }
    }
  }

  submit = (e) => {
    preventDefault(e)
    const { event } = this.state
    if (this.validateFormData(event) &&
      this.validateFormLocationData(event) &&
      this.validateFormStartDate(event) &&
      this.validateFormStartTime(event) &&
      this.validadeServices()) {
      this.fetchCreateEvent(this.generateJSON())
    } else {
      this.setState({ isLoading: false })
      window.scrollTo(0, this.state.errorRef.offsetTop - 10)
      if (!this.validateFormData(event)) {
        this.setState({ errorMessage: 'Preencha todos os campos obrigatórios.' })
      } else if (!this.validateFormLocationData(event)) {
        this.setState({ locationErrorMessage: 'Selecione uma localização válida.' })
      } else if (!this.validateFormStartDate(event)) {
        this.setState({ startDateMessage: 'Você não pode selecionar uma data entre hoje e amanhã.' })
      } else if (!this.validateFormStartTime(event)) {
        this.setState({ startDateMessage: 'Se o evento for em apenas um único dia o horário de fim não pode ser menor que o horário de início.' })
      }
    }
  }

  checkCriticalUpdate = () => {
    return Object.keys(this.state.criticalUpdate).some(item => this.state.criticalUpdate[item] !== this.state.event[item])
  }

  fetchCreateEvent = (eventData) => {
    this.setState({ isLoading: true })
    const { api: { base, version } } = config
    const { id } = this.props.match.params
    const token = window.localStorage.getItem('user_token')
    const userType = window.localStorage.getItem('user_type')
    const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
    const endpoint = id ? `${base}${version}${eventEndpoint}/${id}` : `${base}${version}${eventEndpoint}`
    const configFetch = {
      method: id ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(eventData)
    }

    window.fetch(endpoint, configFetch).then(response => {
      if (response.status >= 401 && response.status <= 403) window.localStorage.clear()
      if (response.ok) {
        return response.json()
      } else {
        throw new Error(response.statusText)
      }
    }).then((event) => {
      this.setState({ isLoading: false })
      if (!event.success) {
        this.setState({ errorMessage: event.message })
        return Promise.reject(event)
      } else {
        if (!id) {
          if (this.state.event.services.lodging.requested) {
            this.props.history.push(`/organizer/event/lodging/${event.data.inserted}/${this.state.event.services.lodging.id ? this.state.event.services.lodging.id : ''}`)
          } else if (this.state.event.services.room.requested) {
            this.props.history.push(`/organizer/event/rooms/${event.data.inserted}/${this.state.event.services.room.id ? this.state.event.services.room.id : ''}`)
          } else if (this.state.event.services.foodsAndBeverages.requested) {
            this.props.history.push(`/organizer/event/food-and-beverage/${event.data.inserted}/${this.state.event.services.foodsAndBeverages.id ? this.state.event.services.foodsAndBeverages.id : ''}`)
          } else if (this.state.event.services.equipments.requested) {
            this.props.history.push(`/organizer/event/equipments/${event.data.inserted}/${this.state.event.services.equipments.id ? this.state.event.services.equipments.id : ''}`)
          } else if (this.state.event.services.transfer.requested) {
            this.props.history.push(`/organizer/event/transfer/${event.data.inserted}/${this.state.event.services.transfer.id ? this.state.event.services.transfer.id : ''}`)
          } else if (this.state.event.services.otherServices.requested) {
            this.props.history.push(`/organizer/event/other-services/${event.data.inserted}/${this.state.event.services.otherServices.id ? this.state.event.services.otherServices.id : ''}`)
          } else {
            this.props.history.push(`/organizer/event/resume/${event.data.inserted}`)
          }
        } else {
          this.props.history.push(retornaUrlFluxoEdicao(this.state.event.services, id, 'evento'))
        }
      }
    }).catch(err => {
      this.setState({ isLoading: false })
    })
  }

  generateJSON = () => {
    const { event, centroDeCustos } = this.state
    event.location.radius = this.state.event.radius

    if (centroDeCustos && (centroDeCustos.length === 1 || (!event.costCenterId && centroDeCustos.length > 0))) {
      event.costCenterId = centroDeCustos[0].id
    }

    if(event.extendedSLAHours === 0) {
      delete event.extendedSLAHours
    }
    return {
      ...event,
      start: event.start[0] && event.start[0].format('YYYY-MM-DD'),
      end: event.end[0] && event.end[0].format('YYYY-MM-DD'),
      type: event.type === 'OUTROS' ? event.anotherType : event.type,
      budget: removeCurrency(event.budget),
      briefings: {},
      emergency: {
        isEmergency: event.isEmergency,
        reason: event.reason,
      }
    }
  }

  generateJSONDeEventoNaoAlterado = () => {
    const { eventoNaoAlterado } = this.state
    if (eventoNaoAlterado) {
      eventoNaoAlterado.location.radius = this.state.eventoNaoAlterado.radius
    }
    return {
      ...eventoNaoAlterado,
      start: eventoNaoAlterado && eventoNaoAlterado.start[0] && eventoNaoAlterado.start[0].format('YYYY-MM-DD'),
      end: eventoNaoAlterado && eventoNaoAlterado.end[0] && eventoNaoAlterado.end[0].format('YYYY-MM-DD'),
      type: eventoNaoAlterado && (eventoNaoAlterado.type === 'OUTROS' ? eventoNaoAlterado.anotherType : eventoNaoAlterado.type),
      budget: eventoNaoAlterado && removeCurrency(eventoNaoAlterado.budget),
      briefings: {}
    }
  }

  verificaSeCaiNoFDS = (e) => {
    preventDefault(e)
    this.setState({ isLoading: true })
    const { api: { base, version } } = config
    const endpoint = `${base}${version}/sla/simulation`
    var obj = {}
    if(this.state.event.status === 'INACTIVE') {
      obj = {
        hours: this.state.event.initialSLAHours
      }
    } else {
      if(!this.state.event.extendedSLAHours) return this.setState({ isLoading: false }, () => this.state.event.status === 'INACTIVE' ? this.submit() : this.checkEdicao())
      obj = {
        hours: this.state.event.extendedSLAHours,
        baseDate: this.state.event.suppliersDeadline
      }
    }
    Post(endpoint, obj).then((resposta) => {
      if(resposta.isWeekend) {
        this.setState({
          backendDeadline: resposta.suppliersDeadline,
          modalDoFDS: true,
          isLoading: false
        })
      } else {
        return this.state.event.status === 'INACTIVE' ? this.submit() : this.checkEdicao()
      }
    }).catch(err => {
      console.error(err)
      return this.state.event.status === 'INACTIVE' ? this.submit() : this.checkEdicao()
    })
  }

  handlerValue(input) {
    const { event } = this.state
    const { name, value } = input.target
    event[name] = value
    this.setState({
      event: event,
      errorMessage: false,
      locationErrorMessage: false,
      startDateMessage: false
    })
  }

  handlerSelectValue(value) {
    const { event } = this.state
    const chosenOrganizer = value
    event.organizerId = value.value
    this.setState({
      event,
      chosenOrganizer
    })
  }

  selecionaAgencia(value) {
    const { event } = this.state
    event.resellerId = value.value
    this.setState({ event, chosenReseller: value })
  }

  handlerDate = ({ target: { value: { from, to } } }) => {
    const { event } = this.state
    event.start = from && to ? [moment(from, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br')] : ''
    event.end = from && to ? [moment(to, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br')] : ''
    this.setState({ event: event })
  }

  validateFormData = (form) => {
    let error = false
    const items = [
      'name',
      'start',
      'end',
      'segment',
      'type',
      'initialSLAHours',
      'radius'
    ]

    var handleErrors = {
      name: '',
      start: '',
      end: '',
      startTime: '00:00:00',
      endTime: '00:00:00',
      hasLocal: 'true',
      city: '',
      address: '',
      location: '',
      type: '',
      anotherType: '',
      goal: '',
      segment: '',
      numberOfParticipants: '',
      participantsProfile: '',
      budget: '',
      observations: '',
      initialSLAHours: '',
      radius: ''
    }
    if (form.type === 'OUTROS' && form.anotherType === '') {
      error = true
      handleErrors.anotherType = true
    }

    if (form.isEmergency === 'true' && !form.reason) {
      error = true
      handleErrors.reason = true
    }

    if (form.hasLocal === 'true' && form.address === '') {
      error = true
      handleErrors.address = true
    } else if (form.hasLocal === 'false' && form.city === '') {
      error = true
      handleErrors.city = true
    }

    items.forEach(i => {
      if (form[i] === '') {
        error = true
        handleErrors[i] = true
      }
    })

    this.setState({ errors: handleErrors })

    return !error
  }

  validateFormLocationData = (form) => {
    return !(form['location'] === '' && (this.state.event.hasLocal === 'true' ? form['address'] === '' : form['city'] === ''))
  }

  validateFormStartDate = (form) => {
    return form['start'][0] ? form['start'][0].diff(moment(), 'days') >= 1 : false
  }

  validadeServices = () => {
    const pediuSalas = this.state.event.services.room && this.state.event.services.room.requested
    const pediuAB = this.state.event.services.foodsAndBeverages && this.state.event.services.foodsAndBeverages.requested
    const pediuHospedagem = this.state.event.services.lodging && this.state.event.services.lodging.requested
    const pediuEquipamentos = this.state.event.services.equipments && this.state.event.services.equipments.requested
    const pediuTransfer = this.state.event.services.transfer && this.state.event.services.transfer.requested
    const pediuOutrosServicos = this.state.event.services.otherServices && this.state.event.services.otherServices.requested

    if (!pediuSalas && (
          (pediuEquipamentos && pediuAB) // Ale pediu para tirar isso aqui|| (pediuHospedagem && pediuAB)
        )
      ) {
      alert('Não é possível solicitar somente alimentos e bebidas e hospedagem/equipamentos.')
      this.setState({ errorMessageServices: 'Não é possível solicitar alimentos e bebidas e hospedagem/equipamentos.' })
      return false
    }
    else if (!pediuSalas && ((pediuAB && pediuEquipamentos) ||
      (pediuHospedagem && pediuEquipamentos))) {
      alert('Não é possível solicitar somente equipamentos e hospedagem/alimentos e bebidas.')
      this.setState({ errorMessageServices: 'Não é possível solicitar equipamentos e hospedagem/alimentos e bebidas.' })
      return false
    }
    else if (!pediuSalas && !pediuAB && !pediuHospedagem && !pediuEquipamentos && !pediuTransfer && !pediuOutrosServicos) {
      alert('Selecione ao menos uma opção.')
      this.setState({ errorMessageServices: 'Selecione ao menos uma opção.' })
      return false
    }
    else return true
  }

  validateFormStartTime = (form) => {
    const start = form['start'][0]
    const startTime = form['startTime']
    const end = form['end'][0]
    const endTime = form['endTime']
    return start.diff(end, 'days') === 0
      ? startTime < endTime || ((startTime === '00:00:00' || !startTime)
        && (endTime === '00:00:00' || !endTime))
      : true
  }

  addressSelected = (data) => {
    const { event } = this.state
    event['address'] = data.address
    event['location'] = data
    event['city'] = data.city
    this.setState({
      event: event
    })
  }

  changeServices = ({ target: { name } }) => {
    const event = this.state.event
    if (event.services[name].requested && window.confirm('Você deseja realmente excluir esta categoria?')) {
      event.services[name].requested = !event.services[name].requested
      this.setState({ event: event })
    }
    else if(!event.services[name].requested) {
      event.services[name].requested = !event.services[name].requested
      this.setState({ event: event })
    }
  }

  gerenciaResponsividade = () => {
    if (window.innerWidth < 600) return this.paginaMobile()
    else return this.paginaDesktop()
  }

  paginaMobile = () => {
    const { id } = this.props.match.params
    const language = this.props.language || 'pt_br'
    const { isLoading, errors, errorMessage, locationErrorMessage, startDateMessage } = this.state
    const {
      name, start, end, startTime, endTime, hasLocal, type, anotherType, segment,
      numberOfParticipants, budget, observations, city, address, radius
    } = this.state.event

    return <MainMobile>
      <Header />
      <BreadCrumb options={[
        { link: '/organizer', name: 'Home' },
        { name: 'Dados gerais do evento' }
      ]} />
      <GerenciaFluxoEdicao pagina={'evento'} eventId={id} eventoNaoAlterado={this.generateJSONDeEventoNaoAlterado()} form={this.generateJSON()} salvaEdicao={this.state.salvaEdicao} onSubmit={this.submit} />
      <FormContent>
        {isLoading ? <Loader /> : null}
        <Etapa>Etapa 1 de 2</Etapa>
        <Title>Dados gerais do evento</Title>
        {errorMessage ? <Alert ref={this.errorRef} type={'negative'}>{errorMessage}</Alert> : null}
        {locationErrorMessage ? <Alert ref={this.errorRef} type={'negative'}>{locationErrorMessage}</Alert> : null}
        {startDateMessage ? <Alert ref={this.errorRef} type={'negative'}>{startDateMessage}</Alert> : null}
        <Input
          type={'text'}
          label={i18n.t('newEvent.nomeDoEvento', { language })}
          placeholder={i18n.t('newEvent.nomeDoEvento_placeholder', { language })}
          name={'name'}
          value={name}
          error={errors.name}
          onChange={this.handlerValue}
          isRequired
        />
        <Group>
          <InputDatepicker
            type={'range'}
            label={i18n.t('newEvent.dataDeInicio', { language })}
            placeholder={i18n.t('newEvent.dataDeInicio_placeholder', { language })}
            name={'start'}
            dates={{ from: start, to: end }}
            error={errors.start || startDateMessage}
            onChange={this.handlerDate}
            isRequired
          />
        </Group>
        <Group>
          <Timepicker
            label={i18n.t('newEvent.horarioDeInicio', { language })}
            name={'startTime'}
            placeholder={i18n.t('newEvent.horarioDeInicio_placeholder', { language })}
            value={startTime}
            onChange={this.handlerValue}
          />
          <Timepicker
            label={i18n.t('newEvent.horarioDeFim', { language })}
            name={'endTime'}
            placeholder={i18n.t('newEvent.horarioDeFim_placeholder', { language })}
            value={endTime}
            onChange={this.handlerValue}
          />
        </Group>
        <Select
          isRequired
          label={i18n.t('newEvent.localDefinido', { language })}
          name={'hasLocal'}
          value={hasLocal}
          onChange={this.handlerValue}>
          <option value={false}>Não</option>
          <option value={true}>Sim</option>
        </Select>
        <form autoComplete='false'>
          {hasLocal === 'true' && !isLoading &&
            <InputAddress
              type={'text'}
              label={i18n.t('newEvent.local', { language })}
              placeholder={i18n.t('newEvent.local_placeholder', { language })}
              name={'address'}
              error={errors.address || locationErrorMessage}
              defaultValue={address}
              addressSelected={this.addressSelected}
              isRequired
            />}
          {hasLocal !== 'true' && !isLoading && <InputAddress
            type={'text'}
            label={i18n.t('newEvent.cidade', { language })}
            placeholder={i18n.t('newEvent.cidade_placeholder', { language })}
            name={'city'}
            error={errors.city || locationErrorMessage}
            defaultValue={city}
            addressSelected={this.addressSelected}
            isRequired
          />}
        </form>
        <Input
          type={'number'}
          label={'Distância máxima de fornecedores do evento (em km)'}
          placeholder={'Digite a distância (km)'}
          name={'radius'}
          value={radius}
          id={'radius'}
          onChange={this.handlerValue}
        />
        {/* <RadioGroup
          label='Sugestões'
          name={'observations'}
          value={observations}
          error={errorMessage}
          valores={['Resorts', 'Hoteis']}
          onChange={this.handlerValue}
        /> */}
        <Select
          isRequired
          label={i18n.t('newEvent.tipoDeEvento', { language })}
          name={'type'}
          value={type}
          error={errors.type}
          onChange={this.handlerValue}>
          <option value={''} disabled>{i18n.t('newEvent.tipoDeEvento_placeholder', { language })}</option>
          <option value={'LANÇAMENTO'}>Lançamento</option>
          <option value={'KICKOFF'}>Kick Off</option>
          <option value={'PROCESSO SELETIVO'}>Processo Seletivo</option>
          <option value={'REUNIÃO'}>Reunião</option>
          <option value={'ROAD SHOW'}>Road Show</option>
          <option value={'CONVENÇÃO'}>Convenção</option>
          <option value={'OUTROS'}>Outros</option>
        </Select>
        {type === 'OUTROS' && <Select
          isRequired
          label='Outros'
          name={'anotherType'}
          value={anotherType}
          error={errors.anotherType}
          onChange={this.handlerValue}>
          <option value={''} disabled>{i18n.t('newEvent.tipoDeEvento_placeholder', { language })}</option>
          {types().map((tipo, i) => <option key={i} value={tipo.value}>{tipo.display}</option>)}
        </Select>}
        {/* <Input
          type={'text'}
          label={i18n.t('newEvent.objetivoDoEvento', { language })}
          placeholder={i18n.t('newEvent.objetivoDoEvento_placeholder', { language })}
          name={'goal'}
          value={goal}
          onChange={this.handlerValue}
        /> */}
        <Input
          type={'text'}
          label={i18n.t('newEvent.segmentoDoEvento', { language })}
          placeholder={i18n.t('newEvent.segmentoDoEvento_placeholder', { language })}
          name={'segment'}
          value={segment}
          error={errors.segment}
          onChange={this.handlerValue}
          isRequired
        />
        <Input
          type={'number'}
          label={i18n.t('newEvent.numeroDeParticipantes', { language })}
          placeholder={i18n.t('newEvent.numeroDeParticipantes_placeholder', { language })}
          name={'numberOfParticipants'}
          value={numberOfParticipants}
          onChange={this.handlerValue}
        />
        {/* <Input
          type={'text'}
          label={i18n.t('newEvent.perfilDosParticipantes', { language })}
          placeholder={i18n.t('newEvent.perfilDosParticipantes_placeholder', { language })}
          name={'participantsProfile'}
          value={participantsProfile}
          error={errorMessage}
          onChange={this.handlerValue}
          isRequired
        /> */}
        <MoneyInput
          type={'text'}
          label={i18n.t('newEvent.budgetPrevisto', { language })}
          placeholder={i18n.t('newEvent.budgetPrevisto_placeholder', { language })}
          name={'budget'}
          value={budget}
          onChange={this.handlerValue}
        />
        <Textarea
          type={'text'}
          label={i18n.t('newEvent.observacoes', { language })}
          placeholder={i18n.t('newEvent.observacoes_placeholder', { language })}
          name={'observations'}
          value={observations}
          onChange={this.handlerValue}
        />
        <Button
          type='submit'
          category={'secondary'}
          fontWeight={800}
          full>
          {id ? 'Ok' : 'Próximo'}
        </Button>
      </FormContent>
    </MainMobile>
  }

  paginaDesktop = () => {
    const { id } = this.props.match.params
    const language = this.props.language || 'pt_br'
    const {
      isLoading, errors, errorMessage, locationErrorMessage, startDateMessage, chosenOrganizer,
      myClients, myClientsFormated, myResellers, myResellersFormated, chosenReseller, centroDeCustos
    } = this.state
    const {
      name, start, end, startTime, endTime, hasLocal, type, anotherType, segment,
      numberOfParticipants, initialSLAHours, observations, city, address, radius, internalId, isEmergency, reason,
      costCenterId, status, extendedSLAHours
    } = this.state.event
    const backendDeadline = this.state.backendDeadline && moment(this.state.backendDeadline, 'YYYY-MM-DD[T]HH:mm:ssZ')

    return <>
      <Header />
      <Main>
        <Body {...this.props} pagina={this.state.event.status === 'INACTIVE' ? 'create-evento' : 'editar-evento'} menuLateralDireito={this.menuLateralDireito()}>
          <GerenciaFluxoEdicao pagina={'evento'} eventId={id} eventoNaoAlterado={this.generateJSONDeEventoNaoAlterado()} form={this.generateJSON()} salvaEdicao={this.state.salvaEdicao} onSubmit={this.submit} />
          <Modal isOpen={this.state.modalDoFDS}>
            <AlertIcon width={'150px'} />
            <Title>Atenção!</Title>
            <Subtitle>
              As horas de SLA contabilizam apenas dias da semana. Por isto, os fornecedores poderão enviar orçamentos até <Bold>{backendDeadline && backendDeadline.format('DD/MM/YYYY')}</Bold> às <Bold>{backendDeadline && backendDeadline.format('HH')}h{backendDeadline && backendDeadline.format('mm')}min</Bold>
            </Subtitle>
            <Group>
              <Button onClick={() => this.setState({ modalDoFDS: false }, () => this.state.event.status === 'INACTIVE' ? this.submit() : this.checkEdicao())} category={'secondary'} fontWeight={800} width={'50%'} full>
                Ok
              </Button>
            </Group>
          </Modal>
          {isLoading ? <Loader /> : null}
          <FormContent>
            <Title>Dados gerais do evento</Title>
            {errorMessage ? <Alert ref={this.errorRef} type={'negative'}>{errorMessage}</Alert> : null}
            {locationErrorMessage ? <Alert ref={this.errorRef} type={'negative'}>{locationErrorMessage}</Alert> : null}
            {startDateMessage ? <Alert ref={this.errorRef} type={'negative'}>{startDateMessage}</Alert> : null}
            {myClients && myClients.length > 0 && <SelectFilter
              value={chosenOrganizer}
              label={'Empresa'}
              isRequired
              onChange={(input) => this.handlerSelectValue(input)}
              placeholder={'Seleciona a empresa'}
              options={myClientsFormated}
            />}
            {myResellers && myResellers.length > 0 && <SelectFilter
              value={chosenReseller}
              label={'Agência'}
              isRequired
              isDisabled={myResellers.length === 1}
              onChange={(input) => this.selecionaAgencia(input)}
              placeholder={'Selecione sua agência'}
              options={myResellersFormated}
            />}
            {centroDeCustos && centroDeCustos.length > 1 && <Select
              isRequired
              label={'Centro de custo'}
              name={'costCenterId'}
              value={costCenterId}
              onChange={this.handlerValue}>
                {centroDeCustos.map(item => <option value={item.id}>{item.name}</option>)}
            </Select>}
            <Group>
              {temFeatureFlag('internalId') && <Input
                type={'text'}
                label={i18n.t('newEvent.idInterno', { language })}
                placeholder={i18n.t('newEvent.idInterno_placeholder', { language })}
                name={'internalId'}
                defaultValue={internalId}
                error={errors.internalId}
                onBlur={this.handlerValue}
              />}
              {temFeatureFlag('createEmergencialEvent') &&<Select
                  label={'Evento emergencial?'}
                  name={'isEmergency'}
                  value={isEmergency}
                  onChange={(this.handlerValue)}>
                  <option value={false}>Não</option>
                  <option value={true}>Sim</option>
                </Select>}
            </Group>
            {(isEmergency === true || isEmergency === 'true') && <Textarea
              isRequired
              type={'text'}
              label={'Motivo'}
              placeholder={'Justifique o motivo para a emergência'}
              name={'reason'}
              error={errors.reason}
              value={reason}
              onChange={this.handlerValue}/>}
            <Input
              type={'text'}
              label={i18n.t('newEvent.nomeDoEvento', { language })}
              placeholder={i18n.t('newEvent.nomeDoEvento_placeholder', { language })}
              name={'name'}
              defaultValue={name}
              error={errors.name}
              onBlur={this.handlerValue}
              isRequired
            />
            <Group>
              <InputDatepicker
                type={'range'}
                label={i18n.t('newEvent.dataDeInicio', { language })}
                placeholder={i18n.t('newEvent.dataDeInicio_placeholder', { language })}
                name={'start'}
                dates={{ from: start, to: end }}
                error={errors.start || startDateMessage}
                onChange={this.handlerDate}
                isRequired
              />
              <Timepicker
                label={i18n.t('newEvent.horarioDeInicio', { language })}
                name={'startTime'}
                placeholder={i18n.t('newEvent.horarioDeInicio_placeholder', { language })}
                value={startTime}
                onChange={this.handlerValue}
              />
              <Timepicker
                label={i18n.t('newEvent.horarioDeFim', { language })}
                name={'endTime'}
                placeholder={i18n.t('newEvent.horarioDeFim_placeholder', { language })}
                value={endTime}
                onChange={this.handlerValue}
              />
            </Group>
            <Group>
              <Select
                isRequired
                label={i18n.t('newEvent.tipoDeEvento', { language })}
                name={'type'}
                value={type}
                error={errors.type}
                onChange={this.handlerValue}>
                <option value={''} disabled>{i18n.t('newEvent.tipoDeEvento_placeholder', { language })}</option>
                <option value={'LANÇAMENTO'}>Lançamento</option>
                <option value={'KICKOFF'}>Kick Off</option>
                <option value={'PROCESSO SELETIVO'}>Processo Seletivo</option>
                <option value={'REUNIÃO'}>Reunião</option>
                <option value={'ROAD SHOW'}>Road Show</option>
                <option value={'CONVENÇÃO'}>Convenção</option>
                <option value={'OUTROS'}>Outros</option>
              </Select>
              {type === 'OUTROS' && <Select
                isRequired
                label='Outros'
                name={'anotherType'}
                value={anotherType}
                error={errors.anotherType}
                onChange={this.handlerValue}>
                <option value={''} disabled>{i18n.t('newEvent.tipoDeEvento_placeholder', { language })}</option>
                {types().map((tipo, i) => <option key={i} value={tipo.value}>{tipo.display}</option>)}
              </Select>}
              <Select
                isRequired
                label={i18n.t('newEvent.localDefinido', { language })}
                name={'hasLocal'}
                value={hasLocal}
                onChange={this.handlerValue}>
                <option value={false}>Não</option>
                <option value={true}>Sim</option>
              </Select>
            </Group>
            <form autoComplete='false'>
              <Label>Selecione um local e o raio de onde gostaria de receber cotações:</Label>
              {hasLocal === 'true' && !isLoading &&
                <InputAddress
                  type={'text'}
                  label={i18n.t('newEvent.local', { language })}
                  placeholder={i18n.t('newEvent.local_placeholder', { language })}
                  name={'address'}
                  error={errors.address || locationErrorMessage}
                  defaultValue={address}
                  addressSelected={this.addressSelected}
                  isRequired
                />}
              {hasLocal !== 'true' && !isLoading && <InputAddress
                type={'text'}
                label={i18n.t('newEvent.cidade', { language })}
                placeholder={i18n.t('newEvent.cidade_placeholder', { language })}
                name={'city'}
                error={errors.city || locationErrorMessage}
                defaultValue={city}
                addressSelected={this.addressSelected}
                isRequired
              />}
            </form>
            <Group>
              <RightBoxInput
                placeholder={'Digite a distância'}
                rightBox='km'
                type='number'
                name={'radius'}
                onBlur={this.handlerValue}
                defaultValue={radius}
                label={'Raio'}
                style={{ width: '100%', paddingLeft: '10px' }}
                error={errors.radius}
                isRequired
              />
              <RightBoxInput
                placeholder={i18n.t('newEvent.numeroDeParticipantes_placeholder', { language })}
                rightBox='pessoas'
                type='number'
                name={'numberOfParticipants'}
                onBlur={this.handlerValue}
                defaultValue={numberOfParticipants}
                label={i18n.t('newEvent.numeroDeParticipantes', { language })}
                style={{ width: '100%', paddingLeft: '10px' }}
              />
            </Group>
            <Group>
              {/* <MoneyInput
                type={'text'}
                label={i18n.t('newEvent.budgetPrevisto', { language })}
                placeholder={i18n.t('newEvent.budgetPrevisto_placeholder', { language })}
                name={'budget'}
                value={budget}
                onChange={this.handlerValue}
              /> */}
              {status === 'INACTIVE' &&<Select
                isRequired
                label={'Receber orçamentos em até'}
                name={'initialSLAHours'}
                value={initialSLAHours}
                error={errors.initialSLAHours}
                onChange={this.handlerValue}>
                <option value={12}>12 horas</option>
                <option value={24}>24 horas</option>
                <option value={36}>36 horas</option>
                <option value={48}>48 horas</option>
                <option value={72}>72 horas</option>
              </Select>}
              {status !== 'INACTIVE' &&<Select
                isRequired
                label={'Renovar o evento por mais'}
                name={'extendedSLAHours'}
                value={extendedSLAHours}
                error={errors.extendedSLAHours}
                onChange={this.handlerValue}>
                <option value={0}>0 horas</option>
                <option value={12}>12 horas</option>
                <option value={24}>24 horas</option>
                <option value={36}>36 horas</option>
                <option value={48}>48 horas</option>
                <option value={72}>72 horas</option>
              </Select>}
              <Input
                type={'text'}
                label={i18n.t('newEvent.segmentoDoEvento', { language })}
                placeholder={i18n.t('newEvent.segmentoDoEvento_placeholder', { language })}
                name={'segment'}
                defaultValue={segment}
                error={errors.segment}
                onBlur={this.handlerValue}
                isRequired
              />
            </Group>
            <Textarea
              type={'text'}
              label={i18n.t('newEvent.observacoes', { language })}
              placeholder={i18n.t('newEvent.observacoes_placeholder', { language })}
              name={'observations'}
              value={observations}
              onChange={this.handlerValue}
            />
          </FormContent>
        </Body>
      </Main>
    </>
  }

  menuLateralDireito = () => {
    const { errorMessageServices } = this.state
    const { id } = this.props.match.params
    return <FixedDiv>
      <Title style={{ padding: '15px 15px 0 15px' }}>Selecione os tipos de serviços que você precisa</Title>
      {errorMessageServices ? <Alert type={'negative'}>{errorMessageServices}</Alert> : null}
      <Form>
        <CheckboxButton
          placeholder={'Hospedagem'}
          icon={'bed-outline'}
          viewBox='0 0 1536 1024'
          name={'lodging'}
          value={'lodging'}
          onClick={this.changeServices}
          checked={this.state.event.services.lodging.requested}
          style={{ width: '100%' }}
          width={40} height={20}
        />
        <CheckboxButton
          placeholder={'Salas'}
          icon={'room-outline'}
          viewBox='0 0 1536 1024'
          name={'room'}
          value={'room'}
          onClick={this.changeServices}
          checked={this.state.event.services.room.requested}
          style={{ width: '100%' }}
          width={40} height={20}
        />
        <CheckboxButton
          placeholder={'Alimentos e bebidas'}
          icon={'food-beverage-outline'}
          name={'foodsAndBeverages'}
          value={'foodsAndBeverages'}
          onClick={this.changeServices}
          checked={this.state.event.services.foodsAndBeverages.requested}
          style={{ width: '100%' }}
          width={40} height={20}
        />
        <CheckboxButton
          placeholder={'Equipamentos'}
          icon={'computer-outline'}
          viewBox='0 0 1280 1024'
          name={'equipments'}
          value={'equipments'}
          onClick={this.changeServices}
          checked={this.state.event.services.equipments.requested}
          style={{ width: '100%' }}
          width={40} height={20}
        />
        <CheckboxButton
          placeholder={'Transfer'}
          icon={'bus-outline'}
          name={'transfer'}
          value={'transfer'}
          onClick={(target) => this.state.event.status === 'INACTIVE' ? this.changeServices(target) : alert('Não é possível fazer esta alteração neste momento.')}
          checked={this.state.event.services.transfer.requested}
          style={{ width: '100%' }}
          width={40} height={20}
        />
        <CheckboxButton
          placeholder={'Outros serviços'}
          icon={'outros-servicos-outline'}
          name={'otherServices'}
          value={'otherServices'}
          onClick={(target) => this.state.event.status === 'INACTIVE' ? this.changeServices(target) : alert('Não é possível fazer esta alteração neste momento.')}
          checked={this.state.event.services.otherServices.requested}
          style={{ width: '100%' }}
          width={40} height={20}
        />
        <Button
          type='submit'
          category={'secondary'}
          fontWeight={800}
          onClick={this.verificaSeCaiNoFDS}
          full>
          {this.state.event.status === 'INACTIVE' ? (id ? 'Ok' : 'Próximo') : 'Salvar alterações'}
        </Button>
      </Form>
    </FixedDiv>
  }

  render () {
    return this.gerenciaResponsividade()
  }
}

export default NewEvent
