/* LIBS */
import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
/* STYLES */
import { defaultTheme, screens } from '../../styles/themes'
/* UTILS */
import { formatDateToLocale, timeToExpireBetweenDates } from './utils'
import { forneceServico, ROOM, FOODSANDBEVERAGES, AERO, LODGING, TRANSFER, EQUIPMENTS, OTHER_SERVICES } from '../../../utils/servicosFornecedor'
/* COMPONENTS */
import Countdown from './Countdown'

const Card = styled.div`
  background-color: #fff;
  padding: 15px 40px 15px 15px;
  width: calc(33.3% - 70px);
  margin: 10px 5px;
  line-height: 1.3;
  color: ${defaultTheme.colors.text.dark};
  position: relative;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  transition: box-shadow 0.3s linear;
  cursor: ${props => !props.active ? 'default' : 'pointer'};
  &:hover {
    box-shadow: ${props => !props.active ? '0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08)' : '1px 2px 2px 2px rgba(48,47,47,.40),0 0 0 2px rgba(0,0,0,0.08)'};
  }
  &:after {
    margin: auto;
    position: absolute;
    top: 50%;
    right: 10px;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    font-size: 18px;
  }
  @media (max-width: ${screens.medium}) {
    width: 100%;
    &:after {
      content: '${props => !props.active ? '' : '>'}';
    }
  }
`

const EventTitle = styled.span`
  font-family: ${defaultTheme.fonts.secondary};
  color: ${defaultTheme.colors.text.darker};
  font-weight: 500;
`

const Description = styled.div`
  font-family: ${defaultTheme.fonts.primary};
  color: ${defaultTheme.colors.text.dark};
  font-weight: 300;
`

const ServicesLabel = {
  room: 'Salas',
  foodsAndBeverages: 'Alimentos e bebidas',
  aero: 'Aéreo',
  lodging: 'Hospedagem',
  transfer: 'Transfer',
  equipments: 'Equipamentos',
  otherServices: 'Outros serviços'
}

const checkServices = {
  room: ROOM,
  foodsAndBeverages: FOODSANDBEVERAGES,
  aero: AERO,
  lodging: LODGING,
  transfer: TRANSFER,
  equipments: EQUIPMENTS,
  otherServices: OTHER_SERVICES
}

const Date = styled.p`
  font-family: ${defaultTheme.fonts.primary};
  color: ${defaultTheme.colors.text.dark};
  font-weight: 300;
  font-style: italic;
`

const ServicesList = ({ services }) => {
  return (
    <p style={{ width: '80%' }}>
      {
        Object.keys(services)
          .filter(serv => services[serv].requested && services[serv].id && forneceServico(checkServices[serv]))
          .map(service => ServicesLabel[service]).join(', ')
      }
    </p>
  )
}

const TextSent = styled.p`
  font-family: ${defaultTheme.fonts.primary};
  color: ${defaultTheme.colors.primary.default};
  font-weight: 300;
  font-style: italic;
  display: flex;
`

const Text = styled.p`
  font-family: ${defaultTheme.fonts.primary};
  color: ${defaultTheme.colors.text.dark};
  font-weight: 300;
`

const Tag = styled.div`
  cursor: pointer;
  padding: 3px 5px;
  font-size: 12px;
  font-family: ${defaultTheme.fonts.primary};
  color: #FAFAFA;
  background-color: ${props => renderBorderColor(props.status)};
  border: none;
  border-radius: 15px;
  position: absolute;
  right: 10px;
  top: ${props => (props.top || '10px')};
  text-transform: uppercase;
`

const renderBorderColor = (status) => {
  switch (status) {
    case 'REVIEW':
      return '#F39C12'

    case 'DELETED':
      return '#FF1818'

    case 'COUNTER_PROPOSAL':
      return '#8800DD'

    case 'INDISPONIVEL':
      return '#a5a5a5'

    case 'DESCONTO':
      return 'red'

    case 'EDITAVEL':
      return '#2288bb'

    default:
      return '#009688'
  }
}

const StatusTag = (status, deleted, editable) => {
  if (status === 'REVIEW') {
    return <Tag status={status}>Revisão</Tag>
  } else if (status === 'COUNTER_PROPOSAL') {
    return <Tag status={status}>Contraproposta</Tag>
  } else if (deleted) {
    return <Tag status='DELETED'>Cancelado</Tag>
  } else if (editable) {
    return <Tag status='EDITAVEL'>Habilitado para edição</Tag>
  } else {
    return <Tag status={status}>Novo evento</Tag>
  }
}

const EventCard = (props) => {
  const { account, status, deleted, eventStatus, editable, reseller, supplierChainName, supplierHasToSendBudgets } = props
  const redirect = () => {
    if (deleted) {
      window.alert('Esse evento foi cancelado.')
    } else if (status === 'active') {
      props.history.push(`/budget/resume/${props._id}`)
    } else if (status === 'expired') {
      window.alert(`Esse evento está expirado, tente adicionar um orçamento em até ${props.initialSLAHours || 48} horas após a criação do evento.`)
    } else if (status === 'closed') {
      props.history.push(`/budget/all-info/${props._id}`)
    } else if (status === 'sent') {
      props.history.push(`/budget/resume/${props._id}/sent`)
    }
  }
  const CONTADOR = (status === 'expired' || status === 'active') && supplierHasToSendBudgets
  const EXIBE_TAG = ((!editable && status === 'active') || editable || deleted) && status !== 'closed'

  return (
    <Card active={status === 'active' || status === 'closed' || status === 'sent'} onClick={() => redirect()}>
      {CONTADOR ? <Countdown statusExpired={status === 'expired'} deleted={deleted || editable} deadline={timeToExpireBetweenDates(props.suppliersDeadline)} /> : null}
      {status === 'sent' && <TextSent>Enviado</TextSent>}
      {EXIBE_TAG && StatusTag(eventStatus, deleted, editable)}
      <EventTitle>{`[${reseller ? `${reseller.name} - ${supplierChainName || account.name}` : (supplierChainName || account.name)}]`} {props.name}</EventTitle>

      <Description>
        <Text style={{ fontWeight: '400', color: defaultTheme.colors.text.darker }}>Segmento: {props.segment}</Text>
        <Text style={{ position: 'absolute', right: '20px', bottom: '15px' }}>ID: {props.internalId || props.friendlyId}</Text>
        <Date>
          {`${formatDateToLocale(props.start) === formatDateToLocale(props.end) ? formatDateToLocale(props.start) : formatDateToLocale(props.start) + ' - ' + formatDateToLocale(props.end)}`} {props.numberOfParticipants > 0 && `| ${props.numberOfParticipants} pessoas`}
        </Date>
        <ServicesList services={props.services} />
      </Description>
    </Card>
  )
}

export default withRouter(EventCard)
