/* LIBS */
import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
/* STYLES */
import { defaultTheme, screens } from '../../styles/themes'
import Button from '../../components/ui/Button'
import Unread from '../../components/ui/Unread'
import Input from './Input'
import SearchIcon from './SearchIcon'

const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
`
const TabsList = styled.ul`
  width: 100%;
  display: flex;
  color: #CCC;
  list-style: none;
  padding-bottom: 20px;
  @media (min-width: ${screens.medium}) {
    width: 100%;
  }
`

const Tab = styled.li`
cursor: pointer;
padding: 5px 12px;
font-size: 14px;
font-family: ${defaultTheme.fonts.primary};
background-color: ${props => props.active ? defaultTheme.colors.whiteLabel.primary : '#FAFAFA'};
color: ${props => props.active ? '#FFF' : defaultTheme.colors.whiteLabel.primary};
border: none;
border-radius: 15px;
@media (max-width: ${screens.small}) {
  background-color: #FAFAFA;
  border-width: ${props => props.active ? '1px 1px 0px 1px' : '0px 0px 1px 0px'};
  border-color: #CCC;
  border-style: solid;
  color: ${props => props.active ? '#333' : '#666'};
  border-radius: 5px 5px 0px 0px;
}
`

const Group = styled.div`
  position: absolute;
  right: 0px;
  top: -40px;
`

const SearchInput = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: -10px;
    label {
      display: none;
    }
`

class Tabs extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selected: 'Nenhuma'
    }
  }

  componentDidMount () {
    this.setState({ selected: this.props.tabs[this.props.tabIndex || 0] && this.props.tabs[this.props.tabIndex || 0].id })
  }

  UNSAFE_componentWillReceiveProps (props) {
    const pegaIndexAba = props.tabIndex
      || (this.state.selected !== 'Nenhuma' && this.state.selected)
      || 0
    this.setState({ selected: props.tabs[pegaIndexAba] && props.tabs[pegaIndexAba].id })
  }

  toggle = (tab) => {
    this.setState({ selected: tab }, () => this.props.onToggleChange && this.props.onToggleChange(tab))
  }

  render () {
    const selected = this.props.selected || this.state.selected
    const { tabs } = this.props
    return (<>
      {this.props.temBusca && <SearchInput style={{ paddingBottom: '20px' }}>
        <Input
          leftIcon={<SearchIcon style={{ width: '18px', zIndex: '10', top: '44px', left: '18px', fill: defaultTheme.colors.grey.medium }} />}
          placeholder={'Busque por ID, nome do evento ou nome do cliente'}
          width={'440px'}
          style={{ paddingLeft: '30px' }}
          onChange={this.props.efetuaFiltro} />
      </SearchInput>}
      <TabsContainer>
        {
          tabs.length > 0 && selected !== 'Nenhuma' &&
          <Fragment>
            {this.props.pagina === 'administrator' &&
              <Group style={{ position: 'absolute', top: '-30px', right: '20px' }}>
                <Button
                  onClick={() => this.props.abreModal(this.state.selected)}
                  ghost >
                  {this.props.tabSelecionada[this.state.selected]}
                </Button>
              </Group>}
            <TabsList>
              {tabs.map(tab => {
                return (<Fragment key={tab.id}>
                  <Tab
                    key={tab.id}
                    active={selected === tab.id}
                    onClick={() => {
                      this.toggle(tab.id)
                    }
                    }>
                    {tab.title}
                  </Tab>
                  {tab.qtd > 0 && <Unread count={tab.qtd} displayTitle onClick={() => { this.toggle(tab.id) }} />}
                </Fragment>)
              })}
            </TabsList>
            {selected === 0 && tabs.filter(tab => tab.id === selected)[0].content()}
            {selected === 1 && tabs.filter(tab => tab.id === selected)[0].content()}
            {selected === 2 && tabs.filter(tab => tab.id === selected)[0].content()}
            {selected === 3 && tabs.filter(tab => tab.id === selected)[0].content()}
            {selected === 4 && tabs.filter(tab => tab.id === selected)[0].content()}
            {selected === 5 && tabs.filter(tab => tab.id === selected)[0].content()}
          </Fragment>
        }
      </TabsContainer>
    </>
    )
  }
}

export default Tabs
