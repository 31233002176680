import config from '../../middleware/config'

export const CREATE_LOGIN_REQUEST = 'CREATE_LOGIN_REQUEST'
export const CREATE_LOGIN_SUCCESS = 'CREATE_LOGIN_SUCCESS'
export const CREATE_LOGIN_FAILURE = 'CREATE_LOGIN_FAILURE'


function requestCreateLogin (form) {
  return {
    type: CREATE_LOGIN_REQUEST,
    isFetching: true,
    form
  }
}

function receiveCreateLogin (user) {
  return {
    type: CREATE_LOGIN_SUCCESS,
    isFetching: false,
    user: user.data
  }
}

export function createLoginError (message) {
  return {
    type: CREATE_LOGIN_FAILURE,
    isFetching: false,
    message
  }
}

export function createUser (form) {
  const { api: { base, version, routes: { users } } } = config
  const token = window.localStorage.getItem('user_token')
  const endpoint = `${base}${version}${users}`
  const configFetch = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    },
    body: JSON.stringify(form)
  }
  return dispatch => {
    dispatch(requestCreateLogin(form))
    return window.fetch(endpoint, configFetch)
      .then(response => {
        if (response.status >= 401 && response.status <= 403) window.localStorage.clear()
        if (response.ok) {
          return response.json()
        } else {
          throw new Error(response.statusText)
        }
      }).then((user) => {
        if (!user.success) {
          alert('Não foi possível cadastrar um novo usuário.')
          dispatch(createLoginError(user))
          return Promise.reject(user)
        } else {
          dispatch(receiveCreateLogin(user))
          window.location.href = '/users'
        }
      }).catch(err => console.log('Error: ', err))
  }
}

export const USERS_REQUEST = 'USERS_REQUEST'
export const USERS_SUCCESS = 'USERS_SUCCESS'
export const USERS_FAILURE = 'USERS_FAILURE'

function requestUsers () {
  return {
    type: USERS_REQUEST,
    isFetching: true
  }
}

function receiveUsers (users) {
  return {
    type: USERS_SUCCESS,
    isFetching: false,
    users: users.data
  }
}

export function usersError (message) {
  return {
    type: USERS_FAILURE,
    isFetching: false,
    message
  }
}

export function getUsers () {
  const { api: { base, version, routes: { users } } } = config
  const token = window.localStorage.getItem('user_token')
  const endpoint = `${base}${version}${users}`
  const configFetch = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  }
  return dispatch => {
    dispatch(requestUsers())
    return window.fetch(endpoint, configFetch)
      .then(response => {
        if (response.status >= 401 && response.status <= 403) window.localStorage.clear()
        if (response.ok) {
          return response.json()
        } else {
          throw new Error(response.statusText)
        }
      }).then((users) => {
        if (!users.success) {
          dispatch(usersError(users))
          return Promise.reject(users)
        } else {
          dispatch(receiveUsers(users))
          return Promise.resolve(users)
        }
      }).catch(err => {
        console.log('Error: ', err)
        return Promise.reject(err)
      })
  }
}
