/* LIBS */
import React, { Component } from 'react'
import Modal from '../../../components/Modal'
import Loader from '../../../components/Loader'
import styled from 'styled-components'
import Title from '../../../components/ui/Title'
import Input from '../../../components/ui/Input'
import Select from '../../../components/ui/Select'
import Button from '../../../components/ui/Button'
import imgCentroDeCusto from '../../../styles/img/admin_img_centro_de_custo.svg'

/* API */
import config from '../../../../middleware/config'
import { Post, Put } from '../../../../utils/request'

const Group = styled.div`
`

const Img = styled.img`
  width: 100%;
`
const Titulo = styled(Title)`
  font-weight: 600;
  font-family: "Pluto",sans-serif;
`

class CadastroCentroDeCusto extends Component {
  constructor (props) {
    super()
    this.state = {
      isOpen: false,
      isLoading: false,
      id: (props.centroDeCustoInfo && props.centroDeCustoInfo.id) || '',
      empresa: (props.centroDeCustoInfo && props.centroDeCustoInfo.accountId) || '',
      nome: (props.centroDeCustoInfo && props.centroDeCustoInfo.name) || '',
      externalId: (props.centroDeCustoInfo && props.centroDeCustoInfo.externalId) || ''
    }
  }

  componentWillReceiveProps(props) {
    this.setState({
      id: (props.centroDeCustoInfo && props.centroDeCustoInfo.id) || '',
      empresa: (props.centroDeCustoInfo && props.centroDeCustoInfo.accountId) || '',
      nomeEmpresa: (props.centroDeCustoInfo && props.centroDeCustoInfo.officeBranchName) || '',
      nome: (props.centroDeCustoInfo && props.centroDeCustoInfo.name) || '',
      externalId: (props.centroDeCustoInfo && props.centroDeCustoInfo.externalId) || ''
    })
  }


  submit = () => {
    if(this.isEmpty()) return alert('Preencha todos os campos obrigatórios.')
    this.setState({ isLoading: true }, () => {
      const { api: { base, version } } = config
      const createAccount = `${base}${version}/cost-centers${this.state.id && `/${this.state.id}`}`
      if(this.state.id) {
        Put(createAccount, {
          accountId: this.state.empresa,
          name: this.state.nome,
          externalId: this.state.externalId
        })
        .then(() => {
          alert('Centro de custo atualizado com sucesso!')
          this.setState({ isLoading: false })
          this.props.onClose && this.props.onClose()
          window.location.reload()
        })
       .catch(error => {
          alert(error)
          this.setState({ isLoading: false })
       })
      } else {
        Post(createAccount, {
          accountId: this.state.empresa,
          name: this.state.nome,
          externalId: this.state.externalId
        })
        .then(() => {
          alert('Centro de custo criado com sucesso!')
          this.setState({ isLoading: false })
          this.props.onClose && this.props.onClose()
          window.location.reload()
        })
       .catch(error => {
          alert(error)
          this.setState({ isLoading: false })
       })
      }

    })
  }

  isEmpty = () => {
    return !this.state.empresa || !this.state.nome
  }

  retornaListaDeEmpresas = () => {
    const listaDeEmpresas = []
    this.props.empresas[0] && listaDeEmpresas.push(this.props.empresas[0])
    const listaFiliais = (this.props.empresas[0] && this.props.empresas[0].branchOffices) || []
    listaFiliais.forEach(item => listaDeEmpresas.push(item))
    return listaDeEmpresas
  }

  render () {
    return (
      <Modal maxWidth={'800px'} isOpen={this.props.isOpen} hasCloseButton onClose={() => this.props.onClose && this.props.onClose()}>
        { this.state.isLoading ? <Loader /> : null }
        <Group style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', padding: '40px 0' }}>
          <Group style={{ width: '270px', padding: '20px' }}>
            <Img src={imgCentroDeCusto} />
            <Titulo style={{ marginTop: '10px' }}>Cadastro de<br /> centro de custo</Titulo>
          </Group>
          <Group style={{ width: '50%' }}>
            <Select
              isRequired
              label={'Empresa'}
              value={this.state.empresa}
              onChange={(input) => this.setState({ empresa: input.target.value})}>
              <option value={''}>{this.state.nomeEmpresa ? this.state.nomeEmpresa : 'Selecione uma empresa' }</option>
              {this.retornaListaDeEmpresas().map(item => <option value={item.id}>{item.profile.given_name}</option>)}
            </Select>
            <Input
              isRequired
              label={'Nome do centro de custo'}
              placeholder='Digite aqui o nome do centro de custo'
              value={this.state.nome}
              onChange={(input) => this.setState({ nome: input.target.value})}
            />
            <Input
              label={'ID'}
              placeholder='Digite o ID'
              value={this.state.externalId}
              onChange={(input) => this.setState({ externalId: input.target.value})}
            />
            <Button
              category={'secondary'}
              fontWeight={800}
              onClick={this.submit}
              full>
              {this.state.id ? 'Atualizar' : 'Cadastrar'}
            </Button>
          </Group>
        </Group>
      </Modal>
    )
  }
}

export default CadastroCentroDeCusto
