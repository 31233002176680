/* LIBS */
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Pdf from "react-to-pdf"
import Modal from '../../../components/Modal'
import Subtitle from '../../../components/ui/Subtitle'
import Button from '../../../components/ui/Button'
import LinkIcon from '../../../components/ui/LinkIcon'
import { formatCurrency } from '../../../../utils/money'
import { renderizaNomeDoServico } from '../../../../utils/servicosFornecedor'
import { defaultTheme } from '../../../styles/themes'
import styled from 'styled-components'

const Title = styled.h1`
  font-family: ${defaultTheme.fonts.secondary};
  line-height: 1.2;
  font-size: 1.3em;
  padding-top: 10px;
`

const Subtitulo = styled(Subtitle)`
  margin-bottom: 0;
    @media(max-width: 1024px) {
      font-size: 12px;
    }
`

const Group = styled.div`
  padding: 20px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  @media(max-width: 768px) {
    font-size: 12px;
    padding: 0;
  }
`

const Label = styled(Title)`
  font-family: ${defaultTheme.fonts.primary};
  padding: 0;
  font-size: 14px;
  white-space: nowrap;
  font-weight: 600;
    @media(max-width: 768px) {
      font-size: 12px;
    }
`

const Agrupador = styled.div`
  display: flex;
  cursor: pointer;
`

const Comissao = styled(Label)`
  font-family: ${defaultTheme.fonts.primary};
  font-size: 12px;
  font-weight: 100;
  padding: 0;
  margin-top: 5px;
    @media(max-width: 768px) {
      font-size: 10px;
    }
  `

const Tabela = styled.table`
  border-bottom: 1px solid #c3c3c3;
  td {
    border-bottom: 1px solid #c3c3c3;
    vertical-align:middle;
    padding: 10px 10px;
    @media(max-width: 1024px) {
     padding: 5px 5px;
    }
  }
  tr:nth-child(even) {
    background: #fafafa;
  }
  tr:hover {
    background: #f3f6f9;
  }`


class TabelaComparativa extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: false,
      pagina: 1,
      convertendoParaPDF: false,
      quantidadePorPagina: 11
    }
    this.ref = React.createRef()
  }

  componentWillReceiveProps (props) {
    this.setState({ ...props },
      () => document.querySelector('body').style.overflow = this.state.isOpen ? 'hidden' : 'auto'
    )
  }

  renderizaMenoresValoresDisponiveis = (budgets, servico) => {
    return budgets[servico] && budgets[servico].reduce((menorValor, budget) => {
      if (budget.cheapestTotalPrice && (menorValor.valor === 0 || budget.cheapestTotalPrice.amount < menorValor.valor)) {
        menorValor = {
          valor: budget.cheapestTotalPrice.amount,
          comissao: budget.commission,
          prazoParaPagamento: budget.billingConditions ? budget.billingConditions.days : 0,
          condicoesDePagamento: budget.billingConditions
        }
      }
      return menorValor
    }, { valor: 0, comissao: { fee: { type: 'PERCENTAGE', amount: 0 }, hasCommission: false }, prazoParaPagamento: 0 })
  }

  geraComissao = (fee) => {
    if (fee.type === 'PERCENTAGE') {
      return `${fee.amount}%`
    } else if (fee.type === 'ABSOLUTE') {
      return formatCurrency(fee.amount)
    } else {
      return ''
    }
  }

  pegaCondicoesPagamentoComMenorPrazoParaPagamento = (budgets) => {
    return Object.keys(budgets).reduce((menorValor, servico) => {
      const menoresValoresDisponiveisServicoAtual = this.renderizaMenoresValoresDisponiveis(budgets, servico)
      if (!menorValor) return menoresValoresDisponiveisServicoAtual
      if (menoresValoresDisponiveisServicoAtual.prazoParaPagamento
        && (menorValor.prazoParaPagamento === 0 || menoresValoresDisponiveisServicoAtual.prazoParaPagamento < menorValor.prazoParaPagamento)) {
        menorValor = menoresValoresDisponiveisServicoAtual
      }
      return menorValor
    }, false)
  }

  verificaSePodeApresentarPrazoDePagamento = () => {
    const loggedUserId = window.localStorage.getItem('user')
    const { event } = this.props.event
    return !(event && event.resellerId && event.consultantId !== event.userId && event.userId === loggedUserId)
  }

  apresentaComissao = (hotel, servico) => {
    const userType = window.localStorage.getItem('user_type')
    if (!this.renderizaMenoresValoresDisponiveis(hotel.budgets, servico) || this.renderizaMenoresValoresDisponiveis(hotel.budgets, servico).valor === 0) {
      return 'Indisponível'
    } else {
      if (userType !== 'ORGANIZER') {
        if (this.renderizaMenoresValoresDisponiveis(hotel.budgets, servico).comissao.hasCommission) {
          return `Comissão: ${this.renderizaMenoresValoresDisponiveis(hotel.budgets, servico).comissao.fee
            ? this.geraComissao(this.renderizaMenoresValoresDisponiveis(hotel.budgets, servico).comissao.fee) : ''}`
        } else {
          return 'Não comissionado'
        }
      } else {
        return ''
      }
    }
  }

  formatarListaHoteis = (hotel) => {
    return (hotel.organizerResellerBudget && hotel.auditStatus)
      || (!(hotel.waitingBudget || hotel.unavailability) && !hotel.organizerResellerBudget)
  }

  geraPDFMultiplo = async (listaDeHoteis, toPdf) => {
    if(this.state.pagina <= Math.ceil(listaDeHoteis.length / this.state.quantidadePorPagina)) {
      await toPdf()
      this.setState({
        pagina: this.state.pagina + 1,
        convertendoParaPDF: (this.state.pagina + 1) <= Math.ceil(listaDeHoteis.length / this.state.quantidadePorPagina)
      }, async () => {
        this.geraPDFMultiplo(listaDeHoteis, toPdf)
      })
    }
  }

  render () {
    const listaDeHoteis = !this.state.convertendoParaPDF ? this.props.listaDeHoteis.filter(this.formatarListaHoteis) : this.props.listaDeHoteis.filter(this.formatarListaHoteis).slice(((this.state.pagina * this.state.quantidadePorPagina) - this.state.quantidadePorPagina), (this.state.pagina * this.state.quantidadePorPagina))
    const event = this.props.event
    const requestedServices = event && event.services ? Object.keys(event.services).filter(service => event.services[service].requested) : []
    return (
      <Modal maxWidth={'1200px'} isOpen={this.state.isOpen} hasCloseButton onClose={() => this.props.onClose && this.props.onClose()}>
      <Pdf options={{ orientation: 'landscape' }} targetRef={this.ref} filename="tabela-comparativa.pdf">
        {({ toPdf }) => <Button
          onClick={() => {
            this.setState({
              convertendoParaPDF: true,
              pagina: 1
            }, async () => {
              this.geraPDFMultiplo(listaDeHoteis, toPdf)
            })
          }}
          category={'secondary'}
          fontWeight={800}
          style={{ width: '150px', position: 'absolute', right: '10px', top: '35px' }}
          small
          full>
          Baixar em PDF
        </Button>}
      </Pdf>
        <Group ref={this.ref}>
          <Title style={{ textAlign: 'left', marginBottom: '30px' }}>Tabela Comparativa de Fornecedores</Title>
          <Tabela>
            <tbody>
              <tr>
                <td></td>
                <td></td>
                {this.verificaSePodeApresentarPrazoDePagamento() && <td style={{ maxWidth: '150px' }}><Subtitulo>Prazo para pagamento (% de antecipação) </Subtitulo></td>}
                {requestedServices.filter(servico => servico !== 'transfer' && servico !== 'otherServices').map(servico => {
                  return <td key={servico}><Subtitulo> {renderizaNomeDoServico(servico)}</Subtitulo></td>
                })}
                <td><Subtitulo style={{ fontWeight: '900', textAlign: 'left' }}>Total</Subtitulo></td>
              </tr>
              {listaDeHoteis
                .map(hotel => {
                  hotel.condicoesDePagamento = this.pegaCondicoesPagamentoComMenorPrazoParaPagamento(hotel.budgets).condicoesDePagamento
                  return hotel
                })
                .map((hotel, index) => <tr key={index}>
                  <td><Label>{hotel.nome}</Label></td>
                  <td>
                    <Agrupador onClick={hotel.onClick} >
                      <Link style={{ color: defaultTheme.colors.primary.default, fontSize: '15px', fontWeight: '600', fontFamily: defaultTheme.fonts.primary, margin: 'auto', paddingRight: '5px' }} to={'#'} onClick={hotel.onClick}>
                        ver
                    </Link>
                      <LinkIcon style={{ width: '25px' }} />
                    </Agrupador>
                  </td>
                  {this.verificaSePodeApresentarPrazoDePagamento() && <td>
                    <Label>
                      {hotel.condicoesDePagamento && `${hotel.condicoesDePagamento.days} dia${(hotel.condicoesDePagamento.days > 1 || '') && 's'}`}
                    </Label>
                    <Comissao>{hotel.condicoesDePagamento && `(${hotel.condicoesDePagamento.percentage}%)`}</Comissao>
                  </td>}
                  {requestedServices.filter(servico => servico !== 'transfer' && servico !== 'otherServices').map(servico => {
                    return <td key={servico}>
                      <Label>
                        {!this.renderizaMenoresValoresDisponiveis(hotel.budgets, servico) || this.renderizaMenoresValoresDisponiveis(hotel.budgets, servico).valor === 0 ?
                          '' :
                          formatCurrency(this.renderizaMenoresValoresDisponiveis(hotel.budgets, servico).valor)}
                      </Label>
                      <Comissao>
                        {this.apresentaComissao(hotel, servico)}
                      </Comissao>
                    </td>
                  })}
                  <td><Label style={{ textAlign: 'left' }}>{formatCurrency(hotel.preco)}</Label></td>
                </tr>
                )}
            </tbody>
          </Tabela>
        </Group>
      </Modal>
    )
  }
}

export default TabelaComparativa
