/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
import Card from '../../../components/ui/Card'
import Input from '../../../components/ui/Input'
import Button from '../../../components/ui/Button'
import Icons from '../../../components/ui/Icons'
import Textarea from '../../../components/ui/Textarea'
import InputDatepicker from '../../../components/InputDatepicker'
import { defaultTheme } from '../../../styles/themes'
import moment from 'moment'

const Content = styled.div`
  padding: 10px;
  width: 100%;
  margin-top: 40px;
`

const Title = styled.h1`
  font-family: ${defaultTheme.fonts.secondary};
  line-height: 1.2;
  font-size: 1.3em;
  margin-bottom: 20px;
  font-weight: 600;
  text-align: center;
`

const Group = styled.div`
`

const Label = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  font-family: ${defaultTheme.fonts.primary};
  box-sizing: border-box;
  padding: 5px 0px;
  display: block;
  font-size: 18px;
  text-align: center;
  font-weight: 600;
`

const FormContent = styled.form`
  padding: 10px 10px;
  box-sizing: border-box;
  width: 100%;
`

const validateFormStartDateEvento = (form) => {
  return form['start'][0] ? form['start'][0].diff(moment(), 'days') > 1 : false
}

class FormularioDigital extends Component {
  state = {
    eventName: '',
    name: '',
    email: '',
    confirmEmail: '',
    phone: '',
    start: '',
    end: ''
  }

  submit = (e) => {
    e && e.preventDefault()
    const { hotelId } = this.props.match.params
    if(!this.validaCriacaoDoEvento(this.state, hotelId)) return alert('Verifique todos os campos para continuar')
    const endpoint =  `https://webinar.evnts.com.br/api/v1/notificacao/5fdd4f7bd6f9682bffe2d437/enviar/terceiro`
    const dados = this.state
    dados.contato = { email: this.state.email }
    dados.cc = ["alexandre@evnts.com.br"]
    dados.bcc = ["victor@evnts.com.br", "guilherme@evnts.com.br"]
    this.setState({ isLoading: true }, () => {
      fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ dados: dados })
      }).then(() => {
        if(this.props.hibrido) {
          window.localStorage.setItem('evento_hibrido_eventName', this.state.eventName)
          window.localStorage.setItem('evento_hibrido_name', this.state.name)
          window.localStorage.setItem('evento_hibrido_email', this.state.email)
          window.localStorage.setItem('evento_hibrido_start', JSON.stringify(this.state.start))
          window.localStorage.setItem('evento_hibrido_end', JSON.stringify(this.state.end))
          this.props.selecaoHibrido()
        } else {
          alert('Orçamento enviado!')
          this.props.selecaoDigital()
        }
      }).catch(error => {
        alert('Ocorreu uma falha: ', error, 'Por favor, tente novamente.')
        this.setState({ isLoading: false })
      })
    })
  }

  handlerValue = (input) => {
    const { state } = this
    const { name, value } = input.target
    state[name] = value
    this.setState({
      ...state,
      errorMessage: false,
      locationErrorMessage: false,
      startDateMessage: false
    })
  }

  handlerDate = ({ target: { value: { from, to } } }) => {
    const { state } = this
    state.start = from && to ? [moment(from, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br')] : ''
    state.end = from && to ? [moment(to, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br')] : ''
    this.setState({ ...state })
  }

  validaCriacaoDoEvento = (event, hotelId) => {
    return this.validateFormDataEvento(event, hotelId) &&
      this.validateFormStartDateEvento(event) &&
      this.validateHotelInfo(hotelId)
  }
  validateHotelInfo = (hotelId) => {
    const hoteisChain = (window.localStorage.getItem('hoteisChain') && JSON.parse(window.localStorage.getItem('hoteisChain'))) || []
    return !hotelId || hoteisChain.find(hotel => hotel.id === hotelId)
  }
  validateFormDataEvento = (form, hotelId) => {
    let error = false
    const items = [
      'name',
      'start',
      'end',
    ]
    if(!hotelId) {
      items.push('radius')
      items.push('address')
      items.push('location')
    }
    items.forEach(i => {
      if (form[i] === '') {
        error = true
      }
    })
    return !error
  }

  validateFormStartDateEvento = (form) => {
    return form['start'][0] ? form['start'][0].diff(moment(), 'days') > 1 : false
  }

  render() {
    const mobile = window.innerWidth < 600
    const { supplierChainId, hotelId } = this.props.match.params
    const { eventName, duration, participants, observations, name, start, end, email, confirmEmail, company } = this.state
    return (
      <Content>
        <Card>
          <FormContent>
            <Label style={{ fontSize: '13px ', marginLeft: '10px' }}>{'Etapa 1 de 1'}</Label>
            <Title style={{ marginLeft: '10px' }}>Seus dados</Title>
            <Group style={{ display: 'flex' }}>
              <Input
                type={'text'}
                label={'Nome do Evento'}
                placeholder={'Digite o nome do evento'}
                name={'eventName'}
                defaultValue={eventName}
                error={eventName === '' && this.state.deuSubmit}
                onBlur={this.handlerValue}
                isRequired />
              <InputDatepicker
                type={'range'}
                label={'Período do Evento'}
                placeholder={'Selecione o período do evento'}
                name={'start'}
                dates={{ from: start, to: end }}
                error={!validateFormStartDateEvento(this.state) && this.state.deuSubmit}
                onChange={this.handlerDate}
                style={{ width: '100%' }}
                isRequired />
            </Group>
            <Group style={{ display: 'flex' }}>
              <Input
                type={'text'}
                label={'Duração do Evento'}
                placeholder={'Digite a duração do evento'}
                name={'duration'}
                defaultValue={duration}
                error={duration === '' && this.state.deuSubmit}
                onBlur={this.handlerValue}
                isRequired />
              <Input
                type={'text'}
                label={'Participantes estimados'}
                placeholder={'Digite a quantidade de participantes estimados'}
                name={'participants'}
                defaultValue={participants}
                error={participants === '' && this.state.deuSubmit}
                onBlur={this.handlerValue}
                isRequired />
            </Group>
            <Group style={{ display: 'flex' }}>
              <Input
                type={'text'}
                label={'Seu nome'}
                placeholder={'Digite o seu nome'}
                name={'name'}
                defaultValue={name}
                error={name === '' && this.state.deuSubmit}
                onBlur={this.handlerValue}
                isRequired />
              <Input
                type={'text'}
                label={'Sua empresa'}
                placeholder={'Digite a sua empresa'}
                name={'company'}
                defaultValue={company}
                error={company === '' && this.state.deuSubmit}
                onBlur={this.handlerValue}
                isRequired />
            </Group>
            <Group style={{ display: 'flex' }}>
              <Input
                type={'email'}
                label={'Email'}
                placeholder={'Digite seu email'}
                name={'email'}
                defaultValue={email}
                error={(email === '' || email !== confirmEmail) && this.state.deuSubmit}
                onBlur={this.handlerValue}
                isRequired />
              <Input
                type={'email'}
                label={'Confirme seu email'}
                placeholder={'Confirme seu email'}
                name={'confirmEmail'}
                defaultValue={confirmEmail}
                error={(confirmEmail === '' || email !== confirmEmail) && this.state.deuSubmit}
                onBlur={this.handlerValue}
                isRequired />
            </Group>
            <Group style={{ width: 'fit-content', margin: 'auto', display: 'flex' }}>
              <Icons
                color={'gray'}
                width={20}
                height={20}
                style={{ margin: 'auto 1px' }} icon='info-circle' />
              <Label style={{ fontSize: '16px' }}>Você receberá os orçamentos no email cadastrado acima</Label>
            </Group>
            <Textarea
              type={'text'}
              label={'Conte-nos um pouco mais (Briefing do evento)'}
              placeholder={'Conte-nos um poucos mais sobre seu evento'}
              name={'observations'}
              value={observations}
              onChange={this.handlerValue} />
            <Group style={{ display: !mobile && 'flex' }}>
              <Button
                ghost
                type='submit'
                category={'secondary'}
                fontWeight={800}
                onClick={() => this.props.history(`/espacos-para-eventos/${supplierChainId}${hotelId ? `/${hotelId}` : ''}/event`)}
                style={{ width: '300px', margin: 'auto', marginTop: '20px', color: '#ec7d31', border: '2px solid #ec7d31' }}
                full>
                Voltar
              </Button>
              <Button
                type='submit'
                category={'secondary'}
                fontWeight={800}
                onClick={this.submit}
                style={{ width: '300px', margin: 'auto', marginTop: '20px', backgroundColor: '#ec7d31', border: '2px solid #ec7d31' }}
                full>
                Solicitar orçamentos
              </Button>
            </Group>
          </FormContent>
        </Card>
      </Content>
    )
  }
}

export default FormularioDigital
