import React, { Component } from 'react'
import styled from 'styled-components'
import Icons from './ui/Icons'
import Unread from './ui/Unread'
import config from '../../middleware/config'
import { Get } from '../../utils/request'

const Container = styled.span`
  cursor: pointer;
  display: flex;
  margin-right: 4px;
`

export default class NotificationMenu extends Component {
  constructor (props) {
    super(props)
    this.state = { unread: 0 }
  }

  componentDidMount () {
    const { api: { base, version, routes: { chat } } } = config
    const counterEndpoint = `${base}${version}${chat}/notifications?size`

    Get(counterEndpoint).then(resposta => {
      if (resposta && resposta.unreadChats) this.setState({ unread: resposta.unreadChats })
    }).catch(erro => { console.error(erro) })
  }

  render () {
    var { unread } = this.state
    unread = Number(unread)
    const SHOW_UNREAD = (!!unread) && !Number.isNaN(unread)

    const title = unread ? `${unread} notificações não lidas` : 'Notificações'
    const unreadStyle = {
      height: unread > 99 ? 18 : 16,
      marginLeft: -20,
      width: unread > 99 ? 18 : 16
    }

    return (
      <Container title={title} onClick={() => this.props.showListagem()} style={{ marginRight: '20px' }}>
        <Icons color='white' height={30} icon='icon_chat_02' width={40} />
        {SHOW_UNREAD && <Unread style={unreadStyle} count={unread} />}
      </Container>
    )
  }
}
