import React, { Component } from 'react'
import { defaultTheme } from '../../styles/themes'
import Input from './Input'
import Title from './Title'
import Icons from './Icons'

export default class EditableLabel extends Component {
  constructor(props) {
    super()
    this.state = {
      showInput: false,
      value: props.value || ''
    }
  }

  render () {
    return <>
      {!this.state.showInput && <Title
        onClick={() => this.setState({ showInput: true })}
        style={{ fontSize: '16px', padding: '10px', display: 'flex', alignItems: 'center', margin: '0', cursor: 'pointer' }}>
          {this.state.value || this.props.label}
          <Icons
            icon={'pencil'}
            color={defaultTheme.colors.grey.medium}
            width={30}
            height={20} />
      </Title>}

      {this.state.showInput && <Input
        label={'Nome da solicitação'}
        value={this.state.value}
        name={this.props.name}
        onBlur={() => this.setState({ showInput: false })}
        onChange={input => {
          this.setState({ value: input.target.value })
          this.props.onChange && this.props.onChange(input)
        }}
        width='50%'
        isRequired />}
    </>
  }
}
