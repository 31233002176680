import React, { Component } from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../styles/themes'

const Link = styled.a`
  font-size: 13px;
  color: ${defaultTheme.colors.grey.medium};
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  @media (min-width: 600px) {
    color: #ccc;
    text-decoration: underline;
  }
`

const Span = styled.span`
  font-size: 13px;
  font-family: ${defaultTheme.fonts.primary};
  color: ${defaultTheme.colors.grey.medium};
  font-weight: 300;
`

const Container = styled.div`
  /* background-color: #e8e8e8; */
  margin-bottom: 5px;
  padding: 10px;
  padding-left: 0px;
  width: 100%;
  @media (min-width: 600px) {
    background-color: unset;
    color: #ccc;
  }
`

const Session = styled.div`
  display: inline-block;
  padding-left: 5px;
`

export default class BreadCrumb extends Component {
  render () {
    return <Container>
      {this.props.options.filter(item => !!item).map((option, index) => {
        if (option.link) {
          return <Session key={index + 'session'}>
            <Link key={index + 'link'} href={option.link}> {option.name} </Link>
            <Span key={index + 'span'}> › </Span>
          </Session>
        } else {
          return <Span key={index + 'span'}> {option.name} </Span>
        }
      })}
    </Container>
  }
}
