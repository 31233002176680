export const formataCnpj = (cnpj) => {
  const NAO_DIGITOS = /\D/g
  const REGEX_CNPJ = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/g
  const MASCARA_CNPJ = '$1.$2.$3/$4-$5'
  const TAMANHO_CNPJ = 14

  var numeroCnpj = String(cnpj || '').replace(NAO_DIGITOS, '').padStart(TAMANHO_CNPJ, '0')
  numeroCnpj = numeroCnpj.replace(REGEX_CNPJ, MASCARA_CNPJ)
  return numeroCnpj
}
