/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
/* UTILS */
import i18n from '../../../i18n'
import config from '../../../middleware/config'
import { Get } from '../../../utils/request'
/* UI */
import Input from '../../components/ui/Input'
import Slider from '../../components/ui/Slider'
/* import RadioGroup from '../../components/ui/RadioGroup'*/
/* STYLES */
import { defaultTheme } from '../../styles/themes'
import Button from '../../components/ui/Button'
import CheckboxButton from '../../components/ui/CheckboxButton'
import Card from '../../components/ui/Card'
import Textarea from '../../components/ui/Textarea'
import TelaInicial from './Componentes/TelaInicial'
import FormularioDigital from './Componentes/FormularioDigital'
/* COMPONENTS */
import Header from '../../Organizer/Header'
import InputAddress from '../../components/InputAddress'
import Alert from '../../components/ui/Alert'
import RightBoxInput from '../../components/ui/RightBoxInput'
import Loader from '../../components/Loader'
import InputDatepicker from '../../components/InputDatepicker'
import Main from '../../components/Main'
import './main.css'
import moment from 'moment'
import { campoDataPreenchido, validaCriacaoDoEvento, validateFormDataEvento, validateFormStartDateEvento } from '../utils/validacoes'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'
import IconeSalaNaoEncontrada from '../../components/ui/IconeSalaNaoEncontrada'

const MainMobile = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #FAFAFA;
`
const FormContent = styled.form`
  padding: 10px 10px;
  box-sizing: border-box;
  width: 100%;
`

const Group = styled.div`
  display: flex;
  width: 100%;
`

const Title = styled.h1`
  font-family: ${defaultTheme.fonts.secondary};
  line-height: 1.2;
  font-size: 1.3em;
  margin-bottom: 20px;
`

const Label = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  font-family: ${defaultTheme.fonts.primary};
  font-size: 14px;
  box-sizing: border-box;
  padding: 5px 0px;
  display: block;
  font-weight: 300;
`
const RoomItem = styled.div`
  min-height: 280px;
  max-width: 300px;
  background-color: #fff;
  margin: 5px 10px;
  min-width: calc((100% / 3) - 20px);
  cursor: pointer;
  border: none;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  @media (max-width: 768px) {
    width: 100%;
  }
  .slick-slider .slick-next:before, .slick-slider .slick-prev:before {
    color: #fff;
  }
`

const Carousel = styled.div`
  .slick-next:before, .slick-prev:before {
    color: ${defaultTheme.colors.whiteLabel.primary};
  }
  .slick-slider .slick-initialized {
    margin-top: 0;
    height: 150px;
    background-position: center;
    background-size: cover;
    margin-bottom: 30px;
  }
`

const RoomTitle = styled.span`
  color: #333;
  font-size: 16px;
  width: 100%;
  display: block;
  font-family: ${defaultTheme.fonts.secondary};
`
const RoomPhoto = styled.div`
  cursor: default;
  height: 150px;
  width: 100%;
  background-position: center;
  background-size: cover;
`
const RoomDescription = styled.div`
  cursor: default;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`
const Description = styled.div`
  cursor: default;
  padding: 10px 0px;
  font-family: ${defaultTheme.fonts.primary};
  font-size: 15px;
  font-weight: 100;
`
class Evento extends Component {
  constructor () {
    super()
    this.state = {
      event: {
        name: window.localStorage.getItem('evento_hibrido_eventName') || '',
        start: (window.localStorage.getItem('evento_hibrido_start') && [moment(JSON.parse(window.localStorage.getItem('evento_hibrido_start')), 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br')]) || '',
        end: (window.localStorage.getItem('evento_hibrido_end') && [moment(JSON.parse(window.localStorage.getItem('evento_hibrido_end')), 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br')]) || '',
        address: '',
        location: {},
        observations: '',
        radius: 5,
        services: {
          room: { requested: true, id: null },
          foodsAndBeverages: { requested: false, id: null },
          lodging: { requested: false, id: null },
          aero: { requested: false, id: null },
          transfer: { requested: false, id: null },
          equipments: { requested: false, id: null },
          otherServices: { requested: false, id: null }
        },
      },
      hotel: {
        name: '',
        rooms: []
      },
      errors: {
        name: '',
        start: '',
        end: '',
        address: '',
        location: '',
        observations: '',
      },
      errorMessage: false,
      locationErrorMessage: false,
      startDateMessage: false,
      isLoading: false,
      deuSubmit: false,
      telaInicial: true
    }
  }

  componentDidMount () {
    document.title = "Espaços para Eventos"
    const { supplierChainId, hotelId } = this.props.match.params
    const { api: { base, version } } = config
    const endpoint = `${base}${version}/chain/${supplierChainId}/info`
    const EVNTS_GA = 'UA-151733765-1'

    Get(endpoint).then((data) => {
      window.localStorage.setItem('theme_logo', data.theme && data.theme.logo)
      window.localStorage.setItem('theme_color_primary', data.theme ? data.theme.primary : '#009688')
      window.localStorage.setItem('theme_color_secondary', data.theme ? data.theme.secondary : '#762123')
      window.localStorage.setItem('googleAnalyticsCode', (data.analytics && data.analytics.active && data.analytics.code) ? data.analytics.code : EVNTS_GA)
      data.analytics && data.analytics.active && data.analytics.gtmId && window.localStorage.setItem('gtmId', data.analytics.gtmId)
      ReactGA.initialize(window.localStorage.getItem('googleAnalyticsCode'))
      if(hotelId) {
        ReactGA.pageview('hotelEvent')
      } else {
        ReactGA.pageview('chainEvent')
      }
      if(defaultTheme.colors.whiteLabel.primary !== window.localStorage.getItem('theme_color_primary')) {
        return window.location.reload()
      }
    }).catch(err => {
      window.localStorage.setItem('googleAnalyticsCode', EVNTS_GA)
      ReactGA.initialize(window.localStorage.getItem('googleAnalyticsCode'))
      if(hotelId) {
        ReactGA.pageview('hotelEvent')
      } else {
        ReactGA.pageview('chainEvent')
      }
      console.error(err)
    })
    console.log(this.state)
    if(hotelId) {
      const endpoint = `${base}${version}/chain/${supplierChainId}/hotel/${hotelId}/info`
      this.setState({ isLoading: true })
      Get(endpoint).then((data) => {
        const hoteisChain = (window.localStorage.getItem('hoteisChain') && JSON.parse(window.localStorage.getItem('hoteisChain'))) || []
        if(!hoteisChain.find(hotel => hotel.id === hotelId)) {
          hoteisChain.push({
            id: hotelId,
            name: data.name,
            rooms: data.rooms
          })
          window.localStorage.setItem('hoteisChain', JSON.stringify(hoteisChain))
        }
        this.setState({ hotel: {
            name: data.name,
            rooms: data.rooms
          },
          isLoading: false})
      }).catch(err => {
        this.setState({ isLoading: false })
        console.error(err)
      })
    }

    const eventosChain = window.localStorage.getItem('criarEventoChain') && JSON.parse(window.localStorage.getItem('criarEventoChain'))
    if(eventosChain && eventosChain[supplierChainId] && eventosChain[supplierChainId].event) {
      var { event } = eventosChain[supplierChainId]
      event.start = [moment(event.start, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br')]
      event.end = [moment(event.end, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br')]
      this.setState({ event })
    }
  }

  submit = (e) => {
    const { supplierChainId, hotelId } = this.props.match.params
    e && e.preventDefault()
    this.setState({ deuSubmit: true })
    if(!validaCriacaoDoEvento(this.state.event, hotelId)) return alert('Verifique todos os campos para continuar')
    const eventosChain = window.localStorage.getItem('criarEventoChain') && JSON.parse(window.localStorage.getItem('criarEventoChain'))
    if(eventosChain && eventosChain[supplierChainId] && eventosChain[supplierChainId].event) {
      eventosChain[supplierChainId].event = this.generateJSON()
      window.localStorage.setItem('criarEventoChain', JSON.stringify(eventosChain))
    } else {
      var criarEventoChain = {}
      criarEventoChain[supplierChainId] = {}
      criarEventoChain[supplierChainId].event = this.generateJSON()
      window.localStorage.setItem('criarEventoChain', JSON.stringify(criarEventoChain))
    }
    if(this.state.event.services.lodging.requested) return this.props.history.push(`/espacos-para-eventos/${supplierChainId}${hotelId ? `/${hotelId}` : ''}/lodging`)
    else return this.props.history.push(`/espacos-para-eventos/${supplierChainId}${hotelId ? `/${hotelId}` : ''}/rooms`)
  }

  generateJSON = () => {
    const { event } = this.state
    event.location.radius = this.state.event.radius
    return { ...event }
  }

  handlerValue = (input) => {
    const { event } = this.state
    const { name, value } = input.target
    event[name] = value
    this.setState({
      event,
      errorMessage: false,
      locationErrorMessage: false,
      startDateMessage: false
    })
  }

  handlerDate = ({ target: { value: { from, to } } }) => {
    const { event } = this.state
    event.start = from && to ? [moment(from, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br')] : ''
    event.end = from && to ? [moment(to, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br')] : ''
    this.setState({ event })
  }

  addressSelected = (data) => {
    const { event } = this.state
    event['address'] = data.address
    event['location'] = data
    event['city'] = data.city
    this.setState({ event })
  }

  changeServices = ({ target: { name } }) => {
    const event = this.state.event
    if (event.services[name].requested && window.confirm('Você deseja realmente excluir esta categoria?')) {
      event.services[name].requested = !event.services[name].requested
      this.setState({ event })
    }
    else if(!event.services[name].requested) {
      event.services[name].requested = !event.services[name].requested
      this.setState({ event })
    }
  }

  gerenciaResponsividade = () => {
    if (window.innerWidth < 600) return this.paginaMobile()
    else return this.paginaDesktop()
  }

  paginaMobile = () => {
    const { hotelId } = this.props.match.params
    const language = this.props.language || 'pt_br'
    const { isLoading } = this.state
    const { name, start, end, observations, address, radius } = this.state.event
    const gtmId = window.localStorage.getItem('gtmId')

    return <MainMobile>
      {gtmId && TagManager.initialize({ gtmId })}
      <Header />
      {isLoading ? <Loader /> : null}
      {this.state.telaInicial && !this.state.formularioDigital && <TelaInicial selecaoPresencial={() => this.setState({ telaInicial: false, hibrido: false })} selecaoDigital={() => this.setState({ formularioDigital: true, hibrido: false })} selecaoHibrido={() => this.setState({ formularioDigital: true, hibrido: true })}  />}
      {this.state.formularioDigital && <FormularioDigital hibrido={this.state.hibrido} selecaoHibrido={() => this.setState({ telaInicial: false, formularioDigital: false })} selecaoDigital={() => this.setState({ telaInicial: true, formularioDigital: false })} {...this.props} />}
      {!this.state.telaInicial && <Card style={{ marginTop: '50px', maxWidth: '1000px' }}>
        <FormContent>
          <Label style={{ fontSize: '13px ', marginLeft: '10px' }}>Etapa 1 de {this.state.event.services.lodging.requested ? 4 : 3}</Label>
          <Title style={{ marginLeft: '10px' }}>Solicite aqui seu evento {hotelId && `em ${this.state.hotel.name}`}</Title>
            {!campoDataPreenchido(this.state.event) && this.state.deuSubmit ? <Alert type={'negative'}>Preencha todos os campos obrigatórios para continuar</Alert> : null}
            {campoDataPreenchido(this.state.event) && !validateFormStartDateEvento(this.state.event) && this.state.deuSubmit ? <Alert type={'negative'}>Período do evento só pode ser efetuado com datas a partir de amanhã.</Alert> : null}
            {!validateFormDataEvento(this.state.event) && this.state.deuSubmit ? <Alert type={'negative'}>Preencha todos os campos obrigatórios para continuar</Alert> : null}
          <Title style={{ fontSize: '15px', marginBottom: '0px', marginLeft: '10px' }}>Dados gerais do evento</Title>
          <Group>
            <Input
              type={'text'}
              label={i18n.t('newEvent.nomeDoEvento', { language })}
              placeholder={i18n.t('newEvent.nomeDoEvento_placeholder', { language })}
              name={'name'}
              defaultValue={name}
              error={name === '' && this.state.deuSubmit}
              onBlur={this.handlerValue}
              width={'70%'}
              isRequired />
            <Group style={{ width: '30%' }}>
              <InputDatepicker
                type={'range'}
                label={i18n.t('newEvent.dataDeInicio', { language })}
                placeholder={i18n.t('newEvent.dataDeInicio_placeholder', { language })}
                name={'start'}
                dates={{ from: start, to: end }}
                error={!validateFormStartDateEvento(this.state.event) && this.state.deuSubmit}
                onChange={this.handlerDate}
                style={{ width: '100%' }}
                isRequired />
            </Group>
          </Group>
          {!hotelId && <>
            <form autoComplete='false'>
              <InputAddress
                type={'text'}
                label={'Buscar nossos hotéis próximos a (cidade, bairro, endereço específico)'}
                placeholder={i18n.t('newEvent.local_placeholder', { language })}
                name={'address'}
                error={address === '' && this.state.deuSubmit}
                defaultValue={address}
                addressSelected={this.addressSelected}
                isRequired />
            </form>
            <Group>
              <RightBoxInput
                placeholder={'Digite a distância'}
                rightBox='km'
                type='number'
                name={'radius'}
                onBlur={this.handlerValue}
                defaultValue={radius}
                label={'Raio'}
                style={{ width: '100%', paddingLeft: '10px' }}
                error={radius === '' && this.state.deuSubmit}
                isRequired />
            </Group>
          </>}
          {hotelId && <>
            <Label style={{ marginLeft: '10px' }}>Conheça as salas deste hotel</Label>
            <Slider maxWidth={'100%'} style={{ marginTop: '0', marginBottom: '20px' }}>
              {this.state.hotel.rooms.reduce((grupo, sala, index) => {
                (index % 3) === 0 && grupo.push(<Group>
                  <RoomItem>
                    <Slider maxWidth={'300px'}>
                      {sala.photos.length
                        ? sala.photos.map(foto => <RoomPhoto style={{backgroundImage: `url(${foto})`}}/>)
                        : [<IconeSalaNaoEncontrada />]
                      }
                    </Slider>
                    <RoomDescription>
                      <RoomTitle>{sala.name}</RoomTitle>
                      <Description>Área: <br/><span style={{ fontWeight: 400 }}>{`${sala.footage}m²`}</span></Description>
                      <Description>Pé direito:<br/> <span style={{ fontWeight: 400 }}>{`${sala.rooms_height}m`}</span></Description>
                      <Description>Cap. máx: <br/><span style={{ fontWeight: 400 }}>{`${sala.capacity} pessoas`}</span></Description>
                    </RoomDescription>
                  </RoomItem>
                  {this.state.hotel.rooms[index+1] && <RoomItem>
                    <Slider maxWidth={'300px'} >
                      {this.state.hotel.rooms[index+1].photos.length
                        ? this.state.hotel.rooms[index+1].photos.map(foto => <RoomPhoto style={{backgroundImage: `url(${foto})`}}/>)
                        : [<IconeSalaNaoEncontrada />]
                      }
                    </Slider>
                    <RoomDescription>
                      <RoomTitle>{this.state.hotel.rooms[index+1].name}</RoomTitle>
                      <Description>Área: <br/><span style={{ fontWeight: 400 }}>{`${this.state.hotel.rooms[index+1].footage}m²`}</span></Description>
                      <Description>Pé direito:<br/> <span style={{ fontWeight: 400 }}>{`${this.state.hotel.rooms[index+1].rooms_height}m`}</span></Description>
                      <Description>Cap. máx: <br/><span style={{ fontWeight: 400 }}>{`${this.state.hotel.rooms[index+1].capacity} pessoas`}</span></Description>
                    </RoomDescription>
                  </RoomItem>}
                  {this.state.hotel.rooms[index+2] && <RoomItem>
                    <Slider maxWidth={'300px'} >
                      {this.state.hotel.rooms[index+2].photos.length
                        ? this.state.hotel.rooms[index+2].photos.map(foto => <RoomPhoto style={{backgroundImage: `url(${foto})`}}/>)
                        : [<IconeSalaNaoEncontrada />]
                      }
                    </Slider>
                    <RoomDescription>
                      <RoomTitle>{this.state.hotel.rooms[index+2].name}</RoomTitle>
                      <Description>Área: <br/><span style={{ fontWeight: 400 }}>{`${this.state.hotel.rooms[index+2].footage}m²`}</span></Description>
                      <Description>Pé direito:<br/> <span style={{ fontWeight: 400 }}>{`${this.state.hotel.rooms[index+2].rooms_height}m`}</span></Description>
                      <Description>Cap. máx: <br/><span style={{ fontWeight: 400 }}>{`${this.state.hotel.rooms[index+2].capacity} pessoas`}</span></Description>
                    </RoomDescription>
                  </RoomItem>}
                </Group>)
                return grupo
              }, [])}
            </Slider>
          </>}
          <Label style={{ paddingTop: '20px ', marginLeft: '10px' }}>Além de salas, necessita de algum dos serviços abaixo?</Label>
          <Group>
            <CheckboxButton
              placeholder={'Hospedagem'}
              name={'lodging'}
              value={'lodging'}
              onClick={this.changeServices}
              checked={this.state.event.services.lodging.requested}
              style={{ width: '100%' }} />
            <CheckboxButton
              placeholder={'Alimentos e bebidas'}
              name={'foodsAndBeverages'}
              value={'foodsAndBeverages'}
              onClick={this.changeServices}
              checked={this.state.event.services.foodsAndBeverages.requested}
              style={{ width: '100%' }} />
            <CheckboxButton
              placeholder={'Equipamentos'}
              name={'equipments'}
              value={'equipments'}
              onClick={this.changeServices}
              checked={this.state.event.services.equipments.requested}
              style={{ width: '100%' }} />
          </Group>
          <Textarea
            type={'text'}
            label={i18n.t('newEvent.observacoes', { language })}
            placeholder={i18n.t('newEvent.observacoes_placeholder', { language })}
            name={'observations'}
            value={observations}
            onChange={this.handlerValue} />
          <Button
            type='submit'
            category={'secondary'}
            fontWeight={800}
            onClick={this.submit}
            style={{ width: '200px', marginLeft: 'auto' }}
            full>
            Próximo
          </Button>
        </FormContent>
      </Card>}
    </MainMobile>
  }

  paginaDesktop = () => {
    const { hotelId } = this.props.match.params
    const language = this.props.language || 'pt_br'
    const { isLoading } = this.state
    const { name, start, end, observations, address, radius } = this.state.event
    const gtmId = window.localStorage.getItem('gtmId')

    return <>
      {gtmId && TagManager.initialize({ gtmId })}
      <Header />
      <Main>
        {isLoading ? <Loader /> : null}
        {this.state.telaInicial && !this.state.formularioDigital && <TelaInicial selecaoPresencial={() => this.setState({ telaInicial: false, hibrido: false })} selecaoDigital={() => this.setState({ formularioDigital: true, hibrido: false })} selecaoHibrido={() => this.setState({ formularioDigital: true, hibrido: true })}  />}
        {this.state.formularioDigital && <FormularioDigital hibrido={this.state.hibrido} selecaoHibrido={() => this.setState({ telaInicial: false, formularioDigital: false })} selecaoDigital={() => this.setState({ telaInicial: true, formularioDigital: false })} {...this.props} />}
        {!this.state.telaInicial && <Card style={{ marginTop: '50px', maxWidth: '1000px' }}>
          <FormContent>
            <Label style={{ fontSize: '13px ', marginLeft: '10px' }}>Etapa 1 de {this.state.event.services.lodging.requested ? 4 : 3}</Label>
            <Title style={{ marginLeft: '10px' }}>Solicite aqui seu evento {hotelId && `em ${this.state.hotel.name}`}</Title>
            {!campoDataPreenchido(this.state.event) && this.state.deuSubmit ? <Alert type={'negative'}>Preencha todos os campos obrigatórios para continuar</Alert> : null}
            {campoDataPreenchido(this.state.event) && !validateFormStartDateEvento(this.state.event) && this.state.deuSubmit ? <Alert type={'negative'}>Período do evento só pode ser efetuado com datas a partir de amanhã.</Alert> : null}
            <Title style={{ fontSize: '15px', marginBottom: '0px', marginLeft: '10px' }}>Dados gerais do evento</Title>
            <Group>
              <Input
                type={'text'}
                label={i18n.t('newEvent.nomeDoEvento', { language })}
                placeholder={i18n.t('newEvent.nomeDoEvento_placeholder', { language })}
                name={'name'}
                defaultValue={name}
                error={name === '' && this.state.deuSubmit}
                onBlur={this.handlerValue}
                width={'70%'}
                isRequired />
              <Group style={{ width: '30%' }}>
                <InputDatepicker
                  type={'range'}
                  label={i18n.t('newEvent.dataDeInicio', { language })}
                  placeholder={i18n.t('newEvent.dataDeInicio_placeholder', { language })}
                  name={'start'}
                  dates={{ from: start, to: end }}
                  error={!validateFormStartDateEvento(this.state.event) && this.state.deuSubmit}
                  onChange={this.handlerDate}
                  style={{ width: '100%' }}
                  isRequired />
              </Group>
            </Group>
            {!hotelId && <>
              <form autoComplete='false'>
                <InputAddress
                  type={'text'}
                  label={'Buscar nossos hotéis próximos a (cidade, bairro, endereço específico)'}
                  placeholder={i18n.t('newEvent.local_placeholder', { language })}
                  name={'address'}
                  error={address === '' && this.state.deuSubmit}
                  defaultValue={address}
                  addressSelected={this.addressSelected}
                  isRequired />
              </form>
              <Group>
                <RightBoxInput
                  placeholder={'Digite a distância'}
                  rightBox='km'
                  type='number'
                  name={'radius'}
                  onBlur={this.handlerValue}
                  defaultValue={radius}
                  label={'Raio'}
                  style={{ width: '100%', paddingLeft: '10px' }}
                  error={radius === '' && this.state.deuSubmit}
                  isRequired />
              </Group>
            </>}
            {hotelId && <>
              <Label style={{ marginLeft: '10px' }}>Conheça as salas deste hotel</Label>
              <Carousel>
                <Slider maxWidth={'100%'} style={{ marginBottom: '1px', padding: '0 40px' }}>
                  {this.state.hotel.rooms.reduce((grupo, sala, index) => {
                    (index % 3) === 0 && grupo.push(<Group>
                      <RoomItem>
                        <Slider maxWidth={'300px'}>
                          {sala.photos.length
                            ? sala.photos.map(foto => <RoomPhoto style={{backgroundImage: `url(${foto})`}}/>)
                            : [<IconeSalaNaoEncontrada />]
                          }
                        </Slider>
                        <RoomDescription>
                          <RoomTitle>{sala.name}</RoomTitle>
                          <Description>Área: <br/><span style={{ fontWeight: 400 }}>{`${sala.footage}m²`}</span></Description>
                          <Description>Pé direito:<br/> <span style={{ fontWeight: 400 }}>{`${sala.rooms_height}m`}</span></Description>
                          <Description>Cap. máx: <br/><span style={{ fontWeight: 400 }}>{`${sala.capacity} pessoas`}</span></Description>
                        </RoomDescription>
                      </RoomItem>
                      {this.state.hotel.rooms[index+1] && <RoomItem>
                        <Slider maxWidth={'300px'}>
                          {this.state.hotel.rooms[index+1].photos && this.state.hotel.rooms[index+1].photos.length
                            ? this.state.hotel.rooms[index+1].photos.map(foto => <RoomPhoto style={{backgroundImage: `url(${foto})`}}/>)
                            : [<IconeSalaNaoEncontrada />]
                          }
                        </Slider>
                        <RoomDescription>
                          <RoomTitle>{this.state.hotel.rooms[index+1].name}</RoomTitle>
                          <Description>Área: <br/><span style={{ fontWeight: 400 }}>{`${this.state.hotel.rooms[index+1].footage}m²`}</span></Description>
                          <Description>Pé direito:<br/> <span style={{ fontWeight: 400 }}>{`${this.state.hotel.rooms[index+1].rooms_height}m`}</span></Description>
                          <Description>Cap. máx: <br/><span style={{ fontWeight: 400 }}>{`${this.state.hotel.rooms[index+1].capacity} pessoas`}</span></Description>
                        </RoomDescription>
                      </RoomItem>}
                      {this.state.hotel.rooms[index+2] && <RoomItem>
                        <Slider maxWidth={'300px'}>
                        {this.state.hotel.rooms[index+2].photos && this.state.hotel.rooms[index+2].photos.length
                            ? this.state.hotel.rooms[index+2].photos.map(foto => <RoomPhoto style={{backgroundImage: `url(${foto})`}}/>)
                            : [<IconeSalaNaoEncontrada />]
                          }
                        </Slider>
                        <RoomDescription>
                          <RoomTitle>{this.state.hotel.rooms[index+2].name}</RoomTitle>
                          <Description>Área: <br/><span style={{ fontWeight: 400 }}>{`${this.state.hotel.rooms[index+2].footage}m²`}</span></Description>
                          <Description>Pé direito:<br/> <span style={{ fontWeight: 400 }}>{`${this.state.hotel.rooms[index+2].rooms_height}m`}</span></Description>
                          <Description>Cap. máx: <br/><span style={{ fontWeight: 400 }}>{`${this.state.hotel.rooms[index+2].capacity} pessoas`}</span></Description>
                        </RoomDescription>
                      </RoomItem>}
                    </Group>)
                    return grupo
                  }, [])}
                </Slider>
              </Carousel>
            </>}
            <Label style={{ paddingTop: '20px ', marginLeft: '10px' }}>Além de salas, necessita de algum dos serviços abaixo?</Label>
            <Group>
              <CheckboxButton
                placeholder={'Hospedagem'}
                name={'lodging'}
                value={'lodging'}
                onClick={this.changeServices}
                checked={this.state.event.services.lodging.requested}
                style={{ width: '100%' }} />
              <CheckboxButton
                placeholder={'Alimentos e bebidas'}
                name={'foodsAndBeverages'}
                value={'foodsAndBeverages'}
                onClick={this.changeServices}
                checked={this.state.event.services.foodsAndBeverages.requested}
                style={{ width: '100%' }} />
              <CheckboxButton
                placeholder={'Equipamentos'}
                name={'equipments'}
                value={'equipments'}
                onClick={this.changeServices}
                checked={this.state.event.services.equipments.requested}
                style={{ width: '100%' }} />
            </Group>
            <Textarea
              type={'text'}
              label={i18n.t('newEvent.observacoes', { language })}
              placeholder={i18n.t('newEvent.observacoes_placeholder', { language })}
              name={'observations'}
              value={observations}
              onChange={this.handlerValue} />
            <Group>
              <Button
                ghost
                type='submit'
                category={'secondary'}
                fontWeight={800}
                onClick={() => this.setState({ telaInicial: true })}
                style={{ width: '200px', marginRight: 'auto' }}
                full>
                Voltar
              </Button>
              <Button
                type='submit'
                category={'secondary'}
                fontWeight={800}
                onClick={this.submit}
                style={{ width: '200px', marginLeft: 'auto' }}
                full>
                Próximo
              </Button>
            </Group>
          </FormContent>
        </Card>}
      </Main>
    </>
  }

  render () {
    return this.gerenciaResponsividade()
  }
}

export default Evento
