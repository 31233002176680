/* LIBS */
import React, { Component } from 'react'
import MegafoneIcon from '../../../components/ui/MegafoneIcon'
import Button from '../../../components/ui/Button'
import Input from '../../../components/ui/Input'
import Title from '../../../components/ui/Title'
import Loader from '../../../components/Loader'
import Subtitle from '../../../components/ui/Subtitle'
import Modal from '../../../components/Modal/index'
import config from '../../../../middleware/config'
import { Post } from '../../../../utils/request'

class IndicacaoFornecedor extends Component {
  constructor() {
    super()
    this.state = {
      event: {
        name: '',
        email: '',
        isLoading: false
      }
    }
  }

  handlerValue = (input) => {
    const { event } = this.state
    const { name, value } = input.target
    event[name] = value
    this.setState({ event: event })
  }

  submit = () => {
    const { api: { base, version, routes: { supplier, events } } } = config
    const endpoint = `${base}${version}${supplier}${events}/${this.props.eventId}/indicate`
    this.setState({ isLoading: true })
    Post(endpoint, this.state.event)
    .then(() => {
      alert('Indicação enviada!')
      this.setState({
        isLoading: false
      })
      this.props.onClose && this.props.onClose()
    }).catch(err => {
      console.error(err.message)
      this.setState({ isLoading: false })
      alert(`Infelizmente não conseguimos enviar a sua indicação. ${err.message}`)
    })
  }

  render() {
    var { isLoading } = this.state
    return (
      <Modal isOpen={this.props.isOpen} onClose={this.props.onClose} hasCloseButton>
        {isLoading ? <Loader /> : null}
        <MegafoneIcon style={{ width: '300px' }} />
        <Title>Indicação de Fornecedor</Title>
        <Subtitle>Diga-nos qual o fornecedor você gostaria de receber um orçamento</Subtitle>
        <Input
          isRequired
          type={'text'}
          name={`name`}
          label={'Nome do Fornecedor'}
          placeholder="Digite o nome do fornecedor desejado"
          onChange={this.handlerValue} />
        <Input
          type={'text'}
          name={`email`}
          label={'Email do Fornecedor (não obrigatório)'}
          placeholder="Digite o email do fornecedor "
          onChange={this.handlerValue} />
        <Button
          type={'button'}
          onClick={this.submit}
          category={'secondary'}
          fontWeight={800}
          full>
          Enviar
        </Button>
      </Modal>
    )
  }
}

export default IndicacaoFornecedor
