/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
import jwtDecode from 'jwt-decode'
import formatCurrency, { removeCurrency } from '../../../../utils/money'
import config from '../../../../middleware/config'
import { Get } from '../../../../utils/request'
/* STYLES */
import { defaultTheme } from '../../../styles/themes'
/* COMPONENTS */
import Header from '../../../components/Header'
import Button from '../../../components/ui/Button'
import Loader from '../../../components/Loader'
import Subtitle from '../../../components/ui/Subtitle'
import Alert from '../../../components/ui/Alert'
import Icons from '../../../components/ui/Icons'
import BreadCrumb from '../../../components/BreadCrumb'
import BriefingInformation from './BriefingInformation'
import SupplierRooms, { typeOfRooms } from './SupplierRooms'

const serialize = require('dom-form-serializer').serialize

const CRITICAL = 'CRITICAL'

const Main = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #FFF;
  @media (min-width: 768px) {
    background-color: #FAFAFA;
  }
`
const Content = styled.div`
  min-height: 90vh;
  padding: 20px;
  font-family: ${defaultTheme.fonts.secondary};
  width: 80%;
  font-size: 14px;
  position: relative;
`

const RoomEditPlaceholder = styled.span`
  display: block;
  padding: 10px 0px 5px 0px;
  font-family: ${defaultTheme.fonts.primary};
  color: #333;
  font-size: 15px;
`

const RoomsContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
`

const Tag = styled.div`
  padding: 3px 5px;
  font-size: 12px;
  font-family: ${defaultTheme.fonts.primary};
  color: #FAFAFA;
  background-color: ${props => props.edited ? '#F39C12' : (props.contraproposta ? '#8800dd' : '#009688')};
  border: none;
  border-radius: 15px;
  position: absolute;
  right: 10px;
  top: 10px;
  text-transform: uppercase;
  z-index: 9;
`

const Group = styled.div`
`

class BriefingRooms extends Component {
  state = {
    errorMessage: '',
    availability: true,
    checkIfCanChangeAvailability: false,
    roomsBudget: [],
    roomsBriefing: [],
    isLoading: true,
    differences: {},
    lastUpdateType: ''
  }

  componentWillMount () {
    const { match: { params: { event } } } = this.props
    if (event) {
      const { api: { base, version } } = config
      const userType = window.localStorage.getItem('user_type')
      const endpointUserType = userType === 'RESELLER' ? 'consultant/event' : 'event'
      const endpoint = `${base}${version}/${endpointUserType}/${event}/briefing/room`
      Get(endpoint).then(briefing =>
        this.setState({ briefing }, () => this.loadAllRooms()))
        .catch(err => {
          console.error(err.message)
        })
    } else {
      this.loadAllRooms()
    }
  }

  fetchEditDifferences () {
    const { api: { base, version } } = config
    const { id } = this.state.briefing
    const endpoint = `${base}${version}/briefing/room/${id}/rollback/difference`

    Get(endpoint).then((differences) => {
      this.setState({ differences: differences.rooms })
    }).catch(err => {
      console.log('Error: ', err)
    })
  }

  loadAllRooms = () => {
    const { api: { base, version, routes: { supplier, events } } } = config
    const token = window.localStorage.getItem('user_token')
    const id = jwtDecode(token).accountId
    var endpoint = `${base}${version}/rooms/account/${id}`
    Get(endpoint).then(rooms => {
      this.setState({ rooms })
    }).catch(err => {
      console.error(err.message)
    })

    const { match: { params: { room, event } } } = this.props
    var newEndpoint = `${base}${version}${supplier}${events}/${event}`
    Get(newEndpoint).then((response) => {
      if (!response) {
        this.setState({ errorMessage: response.message, isLoading: false })
        return Promise.reject(response)
      } else {
        const seForAPrimeiraSala = response.briefings.rooms.rooms.findIndex(item => item.id === room) === 0
        seForAPrimeiraSala && window.localStorage.setItem('fluxo_salas', JSON.stringify({}))
        this.setState({
          counterProposals: response.budgets.rooms.mostRecent && response.budgets.rooms.mostRecent.counterProposals,
          budgetId: response.budgets.rooms.mostRecent ? response.budgets.rooms.mostRecent.id : 0,
          lastUpdateType: response.budgets.rooms.mostRecent ? response.budgets.rooms.mostRecent.lastUpdateType : '',
          roomsBudget: response.budgets.rooms.mostRecent ? response.budgets.rooms.mostRecent.roomsBudget : [],
          roomsBriefing: response.briefings.rooms.rooms,
          isLoading: false
        }, () => response.budgets.rooms.mostRecent && response.budgets.rooms.mostRecent.status === 'REVIEW' && this.fetchEditDifferences())
      }
    }).catch(err => {
      this.setState({ isLoading: false })
      console.log('Error: ', err)
    })
  }

  getBriefingRoom = (briefing, room) => {
    const roomBriefing = briefing.rooms.filter(r => {
      return r.id === room
    })[0]
    roomBriefing.observations = briefing.observations
    return roomBriefing

  }

  submit = (e) => {
    e.preventDefault()
    const { match: { params: { room, event } } } = this.props
    const { briefing } = this.state
    const budget = JSON.parse(window.localStorage.getItem('fluxo_salas'))
    const briefingData = briefing ? this.getBriefingRoom(briefing, room) : null
    const form = serialize(e.target)

    if (this.validate(form, briefingData)) {
      const newRooms = (budget && budget.roomsBudget) ? budget.roomsBudget : {}
      const index = this.state.roomsBriefing.findIndex(item => item.id === room)
      newRooms[briefingData.id] = {
        roomBriefingId: briefingData.id,
        pricePerDay: removeCurrency(form.amount),
        roomId: form.room,
        mountingPrice: removeCurrency(form.mounting),
        observations: form.observations,
        hasAvailability: this.state.availability,
        info: this.state.roomsBriefing[index]
      }

      const formData = {
        briefingId: briefing.id,
        eventId: briefing.eventId,
        roomsBudget: newRooms,
        budgetId: this.state.budgetId,
        counterProposals: this.state.counterProposals,
      }
      window.localStorage.setItem('fluxo_salas', JSON.stringify(formData))
      if (this.state.roomsBriefing.length > (index + 1)) {
        this.props.history.push({ pathname: `/budget/briefing/rooms/${event}/${this.state.roomsBriefing[index + 1].id}`, state: this.props.location.state })
        window.scrollTo(0, 0)
      } else {
        this.props.history.push(`/budget/rooms/${event}`)
        this.props.history.push({ pathname: `/budget/rooms/${event}`, state: this.props.location.state })
      }
    }
  }

  hasSomeRoomWithCapacity = (rooms, capacityRequested, typeRequested) => {
    return (rooms && capacityRequested && typeRequested)
      && rooms.some(room => Object.entries(room.capacity)
        .some(([key, value]) => ((value >= capacityRequested) && (key === typeRequested))))
  }

  validate = (form, briefing) => {
    let isValid = true
    this.setState({ errorMessage: false })
    if (this.state.availability && !this.hasSomeRoomWithCapacity(this.state.rooms, briefing.amountOfPeople, typeOfRooms(briefing.roomType))) {
      this.setState({ errorMessage: `Não encontramos nenhuma sala com a capacidade solicitada para ${briefing.amountOfPeople} pessoas no formato ${briefing.roomType}. \n Tente aumentar a capacidade de uma de suas salas para atender a esta solicitação.` })
      return false
    }
    if (!this.state.availability) return true
    if (!Object.keys(form).length) {
      isValid = false
      this.setState({ errorMessage: 'Selecione 1 sala para enviar o orçamento' })
    } else if (removeCurrency(form.amount) <= 0) {
      isValid = false
      this.setState({ errorMessage: 'Preencha todos os campos obrigatórios' })
    }
    return isValid
  }

  getBriefingRoomsInfo = () => {
    const { briefing } = this.state
    return briefing && briefing.rooms
  }

  changeAvailability = () => {
    this.setState({
      availability: !this.state.availability,
      checkIfCanChangeAvailability: true
    })
  }

  setAvailability = (availability) => {
    this.setState({
      availability: availability
    })
  }

  render () {
    const { match: { params: { room, event } } } = this.props
    const { isFetching, briefing, fetchingBudget, rooms } = this.state
    const checkBudget = JSON.parse(window.localStorage.getItem('fluxo_salas'))
    const { errorMessage, isLoading } = this.state
    const briefingData = briefing ? this.getBriefingRoom(briefing, room) : null
    const temQueEnviarContraproposta = this.props.location.state && (this.props.location.state.counterProposal === 'ACCEPTED' || this.props.location.state.counterProposal === 'NEW')
    return (
      <Main>
        <Header />
        <BreadCrumb options={
          [
            { link: '/', name: 'Home' },
            { link: `/budget/resume/${event}`, name: 'Resumo' },
            { link: `/budget/rooms/${event}`, name: 'Salas' },
            { name: 'Selecione a sala' }
          ]
        } />
        {
          isFetching || isLoading || fetchingBudget ? <Loader /> : null
        }
        <Content>
          <Subtitle>Sala {this.state.roomsBriefing.findIndex(item => item.id === room) + 1} de {this.state.roomsBriefing.length}</Subtitle>
          {temQueEnviarContraproposta && <Tag contraproposta>Contraproposta</Tag>}
          {
            temQueEnviarContraproposta && <Group style={{ textAlign: 'right', padding: '0px 15px' }}>
              <Subtitle>Valor da contraproposta <span style={{ fontWeight: 'bold' }}>{formatCurrency(this.props.location.state.requestedPrice)}</span></Subtitle>
            </Group>
          }
          {briefing && !isLoading
            && <BriefingInformation
              availability={this.state.availability}
              changeAvailability={!temQueEnviarContraproposta && this.changeAvailability}
              setAvailability={this.setAvailability}
              briefing={briefingData}
              budget={this.state.roomsBudget}
              checkIfCanChangeAvailability={this.state.checkIfCanChangeAvailability}
              differences={this.state.differences}
              index={this.state.roomsBriefing.findIndex(item => item.id === room)}
            />}
          {this.state.availability && <RoomEditPlaceholder>Selecione apenas 1 opção de sala:</RoomEditPlaceholder>}
          {errorMessage
            && <Alert type={'negative'}>{errorMessage}</Alert>
          }
          {
            (this.state.lastUpdateType === CRITICAL && !this.state.roomsBudget[this.state.roomsBriefing.findIndex(item => item.id === room)]) && <Alert type={'edition'} style={{ display: 'flex', marginBottom: '40px' }}>
              <Icons
                icon={'exclamation'}
                color={'white'}
                width={30}
                style={{ margin: 'auto 0' }}
                height={15} />
              <p style={{ textAlign: 'justify' }}>
                Esta sala foi recém adicionada pelo solicitante.
                  </p>
            </Alert>
          }
          <form method={'POST'} onSubmit={this.submit}>
            <RoomsContent>
              {(rooms && briefingData && this.state.availability)
                ? <SupplierRooms
                  error={errorMessage}
                  briefing={briefingData}
                  rooms={rooms}
                  briefingRoomsInfo={this.getBriefingRoomsInfo()}
                  budget={this.state.roomsBudget}
                  differences={this.state.differences}
                  checkBudget={checkBudget}
                  lastUpdateType={this.state.lastUpdateType}
                /> : null}
            </RoomsContent>
            <Button
              type='submit'
              category={'secondary'}
              fontWeight={800}
              full>
              Confirmar
              </Button>
          </form>
        </Content>
      </Main>
    )
  }
}

export default BriefingRooms
