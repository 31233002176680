/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
/* STYLES */
import { defaultTheme } from '../../styles/themes'
/* COMPONENTS */
import Header from '../../components/Header'

const Main = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #FAFAFA;
  @media (min-width: 768px) {
    background-color: #FAFAFA;
  }
`
const Content = styled.div`
  min-height: 90vh;
  padding: 10px 20px;
  font-family: ${defaultTheme.fonts.secondary};
  width: 100%;
  font-size: 14px;
`
const TitleBold = styled.h2`
  font-family: ${defaultTheme.fonts.secondary};
  color: ${defaultTheme.colors.text.darker};
  font-weight: 500;
`

const Card = styled.div`
  background-color: #fff;
  padding: 15px 40px 15px 15px;
  width: 100%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  margin: 10px 0;
  line-height: 1.3;
  color: ${defaultTheme.colors.text.dark};
  position: relative;
  cursor: pointer;

  &::after {
    content: '➤';
    margin: auto;
    position: absolute;
    top: 50%;
    right: 10px;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    /* font-family: "Pluto",sans-serif; */
    font-size: 18px;
  }
`

class FoodsAndBeverages extends Component {
  render() {
    return (
      <Main>
        <Header />
        <Content>
            <Card>
                <TitleBold>Serviço de Sala</TitleBold>
                <ul>
                    <li><b>Datas:</b> 21, 23, 24 mar 2018 (3 dias)</li>
                    <li><b>Horário de Início:</b> 19h</li>
                    <li><b>Número de pessoas:</b> 250 pessoas</li>
                </ul>
                {/* <FoodsAndBeveragesList foodsAndBeverages={data.menus}/> */}
            </Card>
        </Content>
      </Main>
    )
  }
}

export default FoodsAndBeverages