import React from 'react'
import styled from 'styled-components'
import { defaultTheme } from "../../styles/themes"

const Label = styled.label`
  align-items: center;
  background-color: #FFF;
  cursor: pointer;
  justify-content: space-between;
  display: flex;
  color: ${props => props.error ? 'red' : '#484848'};
  font-family: ${defaultTheme.fonts.secondary};
  font-weight: 500;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
`

const Card = styled.div`
  padding: 15px;
  background-color: #fff;
  width: calc(100%);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  margin: 10px 0px;
  line-height: 1.3;
  position: relative;
`

export default function (props) {
  return (
    <Card {...props} style={props.style}>
      {props.label && <Label htmlFor={props.name} error={props.error}>
        {props.label}
      </Label>
      }
      {props.children}
    </Card>
  )
}
