import React, { Component } from 'react'

const filled = fill => ({
  fill: fill || '#762123'
})

export default class CalendarIcon extends Component {
  render () {
    return (
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 405 258" style={{ enableBackground: "new 0 0 405 258", maxWidth: '300px' }}>
        <g>
          <g>
            <g>
              <g id="Artboard_1">
                <g id="Group_2435" transform="translate(-151 -65)">
                  <g id="Group_829">
                    <g id="Group_828">
                      <g id="Ellipse_5">
                        <path style={filled(this.props.fill)} d="M262.6,119.3c-3.5,0-6.4-2.9-6.4-6.4s2.9-6.4,6.4-6.4s6.4,2.9,6.4,6.4S266.1,119.3,262.6,119.3zM262.6,108.5c-2.4,0-4.4,2-4.4,4.4s2,4.4,4.4,4.4s4.4-2,4.4-4.4S265,108.5,262.6,108.5z" />
                      </g>
                      <g id="Ellipse_6">
                        <path style={filled(this.props.fill)} d="M528.5,126.8c-3.5,0-6.4-2.9-6.4-6.4s2.9-6.4,6.4-6.4s6.4,2.9,6.4,6.4S532,126.8,528.5,126.8zM528.5,116c-2.4,0-4.4,2-4.4,4.4s2,4.4,4.4,4.4s4.4-2,4.4-4.4S530.9,116,528.5,116z" />
                      </g>
                      <g id="Ellipse_7">
                        <path style={filled(this.props.fill)} d="M201.8,291.5c-3.5,0-6.4-2.9-6.4-6.4c0-3.5,2.9-6.4,6.4-6.4c3.5,0,6.4,2.9,6.4,6.4C208.2,288.6,205.3,291.5,201.8,291.5z M201.8,280.7c-2.4,0-4.4,2-4.4,4.4c0,2.4,2,4.4,4.4,4.4s4.4-2,4.4-4.4C206.2,282.7,204.2,280.7,201.8,280.7z" />
                      </g>
                      <g id="Group_821">
                        <g id="Line_38">
                          <rect x="444.8" y="96.1" style={filled(this.props.fill)} width="13.6" height="2" />
                        </g>
                        <g id="Line_39">
                          <rect x="450.6" y="90.2" style={filled(this.props.fill)} width="2" height="13.7" />
                        </g>
                      </g>
                      <g id="Group_822">
                        <g id="Line_40">
                          <rect x="199.8" y="103.3" style={filled(this.props.fill)} width="13.6" height="2" />
                        </g>
                        <g id="Line_41">
                          <rect x="205.6" y="97.5" style={filled(this.props.fill)} width="2" height="13.7" />
                        </g>
                      </g>
                      <g id="Group_823">
                        <g id="Line_42">
                          <rect x="514.3" y="280.3" style={filled(this.props.fill)} width="13.6" height="2" />
                        </g>
                        <g id="Line_43">
                          <rect x="520.1" y="274.4" style={filled(this.props.fill)} width="2" height="13.7" />
                        </g>
                      </g>
                      <g id="Group_824">
                        <g id="Line_44">

                          <rect x="177.3" y="211.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -102.1886 189.995)" style={filled(this.props.fill)} width="2" height="13.7" />
                        </g>
                        <g id="Line_45">

                          <rect x="171.4" y="217.4" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -102.1886 189.995)" style={filled(this.props.fill)} width="13.7" height="2" />
                        </g>
                      </g>
                      <g id="Group_825">
                        <g id="Path_30">
                          <path style={filled(this.props.fill)} d="M379.2,106c-3.8-0.9-5-4.2-6-7.1c-1-2.8-1.9-5.2-4.7-5.8c-2.7-0.7-4.6,1-6.8,3c-2.3,2.1-4.8,4.4-8.6,3.5c-3.8-0.9-5-4.2-6-7.1c-1-2.8-1.9-5.2-4.7-5.8c-2.7-0.7-4.6,1-6.8,3c-2.3,2.1-4.8,4.4-8.6,3.5l0.5-1.9c2.7,0.7,4.6-1,6.8-3c2.3-2.1,4.8-4.4,8.6-3.5c3.8,0.9,5,4.2,6,7.1c1,2.8,1.9,5.2,4.7,5.8c2.7,0.7,4.6-1,6.8-3c2.3-2.1,4.8-4.4,8.6-3.5c3.8,0.9,5,4.2,6,7.1c1,2.8,1.9,5.2,4.7,5.8L379.2,106z" />
                        </g>
                      </g>
                      <g id="Group_826">
                        <g id="Path_31">
                          <path style={filled(this.props.fill)} d="M210.8,169.8c-3.9,0-5.8-2.9-7.5-5.4c-1.6-2.4-3.1-4.6-5.9-4.6c-2.8,0-4.2,2.1-5.9,4.6c-1.7,2.6-3.6,5.4-7.5,5.4s-5.8-2.9-7.5-5.4c-1.6-2.4-3.1-4.6-5.9-4.6c-2.8,0-4.2,2.1-5.9,4.6c-1.7,2.6-3.6,5.4-7.5,5.4v-2c2.8,0,4.2-2.1,5.9-4.6c1.7-2.6,3.6-5.4,7.5-5.4c3.9,0,5.8,2.9,7.5,5.4c1.6,2.4,3.1,4.6,5.9,4.6s4.2-2.1,5.9-4.6c1.7-2.6,3.6-5.4,7.5-5.4c3.9,0,5.8,2.9,7.5,5.4c1.6,2.4,3.1,4.6,5.9,4.6V169.8z" />
                        </g>
                      </g>
                      <g id="Group_827">
                        <g id="Path_32">
                          <path style={filled(this.props.fill)} d="M545.2,220.8c-3.4-2-3.6-5.4-3.8-8.5c-0.2-2.9-0.4-5.5-2.8-6.9c-2.4-1.4-4.7-0.3-7.4,1c-2.8,1.4-5.9,2.9-9.2,0.9c-3.4-2-3.6-5.4-3.8-8.5c-0.2-2.9-0.4-5.5-2.8-6.9c-2.4-1.4-4.7-0.3-7.4,1c-2.8,1.4-5.9,2.9-9.2,0.9l1-1.7c2.4,1.4,4.7,0.3,7.4-1c2.8-1.4,5.9-2.9,9.2-0.9c3.4,2,3.6,5.4,3.8,8.5c0.2,2.9,0.4,5.5,2.8,6.9c2.4,1.4,4.7,0.3,7.4-1c2.8-1.4,5.9-2.9,9.2-0.9c3.4,2,3.6,5.4,3.8,8.5c0.2,2.9,0.4,5.5,2.8,6.9L545.2,220.8z" />
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <path style={filled(this.props.fill)} d="M411,159.8L431.5,265c0.6,2.8,0,5.5-1.7,8s-3.9,4-6.8,4.6L307.3,300c-2.8,0.6-5.5,0-8-1.7s-4-3.9-4.6-6.8l-20.4-105.2c-0.6-2.8,0-5.5,1.7-8s3.9-4,6.8-4.6l10.5-2l-1.5-7.9c-0.7-3.6,0-7,2.1-10c2.1-3.1,4.9-5,8.5-5.7l5.3-1c3.6-0.7,7,0,10,2.1c3.1,2.1,5,4.9,5.7,8.5l1.5,7.9l31.6-6.1l-1.5-7.9c-0.7-3.6,0-7,2.1-10s4.9-5,8.5-5.7l5.3-1c3.6-0.7,7,0,10,2.1c3.1,2.1,5,4.9,5.7,8.5l1.5,7.9l10.5-2c2.8-0.6,5.5,0,8,1.7C408.9,154.7,410.5,157,411,159.8z M293.5,229l23.7-4.6l-4.6-23.7l-23.7,4.6L293.5,229z M299.6,260.6l23.7-4.6l-5.1-26.3l-23.7,4.6L299.6,260.6z M305.3,289.5l23.7-4.6l-4.6-23.7l-23.7,4.6L305.3,289.5z M317.4,183.5l-4.6-23.7c-0.1-0.7-0.5-1.3-1.1-1.7s-1.3-0.6-2-0.4l-5.3,1c-0.7,0.1-1.3,0.5-1.7,1.1c-0.4,0.6-0.6,1.3-0.4,2l4.6,23.7c0.1,0.7,0.5,1.3,1.1,1.7s1.3,0.6,2,0.4l5.3-1c0.7-0.1,1.3-0.5,1.7-1.1C317.4,184.8,317.5,184.2,317.4,183.5z M322.4,223.4l26.3-5.1l-4.6-23.7l-26.3,5.1L322.4,223.4z M328.6,255l26.3-5.1l-5.1-26.3l-26.3,5.1L328.6,255z M334.2,283.9l26.3-5.1l-4.6-23.7l-26.3,5.1L334.2,283.9z M354,217.3l26.3-5.1l-4.6-23.7l-26.3,5.1L354,217.3z M360.1,248.8l26.3-5.1l-5.1-26.3l-26.3,5.1L360.1,248.8z M365.7,277.7l26.3-5.1l-4.6-23.7l-26.3,5.1L365.7,277.7z M380.5,171.2l-4.6-23.7c-0.1-0.7-0.5-1.3-1.1-1.7s-1.3-0.6-2-0.4l-5.3,1c-0.7,0.1-1.3,0.5-1.7,1.1c-0.4,0.6-0.6,1.3-0.4,2l4.6,23.7c0.1,0.7,0.5,1.3,1.1,1.7s1.3,0.6,2,0.4l5.3-1c0.7-0.1,1.3-0.5,1.7-1.1C380.5,172.6,380.6,171.9,380.5,171.2z M385.5,211.1l23.7-4.6l-4.6-23.7l-23.7,4.6L385.5,211.1z M391.7,242.7l23.7-4.6l-5.1-26.3l-23.7,4.6L391.7,242.7z M397.3,271.6L421,267 l-4.6-23.7l-23.7,4.6L397.3,271.6z" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg >
    )
  }
}
