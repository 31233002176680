import React, { Component } from 'react'
import { defaultTheme } from '../../styles/themes'

const stroked = stroke => {
  return {
    fill: 'none',
    stroke: stroke || defaultTheme.colors.whiteLabel.secondary,
    strokeMiterlimit: 10,
    strokeWidth: '3px'
  }
}

export default class AlertIcon extends Component {
  render () {
    const { fill, width } = this.props
    return (
      <svg id="Layer_1" width={width || '100%'} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 167.61 149.49">
        <path style={stroked(fill)} d="M165.88,136.69a13.69,13.69,0,0,1-4.58,8,12.94,12.94,0,0,1-8.86,3.29H15.17a12.94,12.94,0,0,1-8.86-3.29,13.75,13.75,0,0,1-4.58-8,14.55,14.55,0,0,1,1.43-9.29L71.8,8.43a12.78,12.78,0,0,1,7.29-6.14,14.51,14.51,0,0,1,9.43,0,12.83,12.83,0,0,1,7.3,6.14l68.63,119A14.55,14.55,0,0,1,165.88,136.69Zm-91.37-30A13.15,13.15,0,1,0,93.1,125.25a13.15,13.15,0,1,0-18.59-18.59Zm-1-12.15a3.05,3.05,0,0,0,1,2.15,3.34,3.34,0,0,0,2.43,1H90.67a3.34,3.34,0,0,0,2.43-1,3,3,0,0,0,1-2.15l2.29-38.89a3.72,3.72,0,0,0-1-2.72,3.34,3.34,0,0,0-2.43-1H74.66a3.35,3.35,0,0,0-2.44,1,3.72,3.72,0,0,0-1,2.72Z" />
      </svg>
    )
  }
}
