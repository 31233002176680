import * as moment from 'moment'
import 'moment/locale/pt-br'
moment.locale('pt-BR')

export function resolveColor(time) {
  const TIME_TO_RED_STATUS = 48
  const TIME_TO_YELLOW_STATUS = 67

  if (diffTimeFromNow(time, TIME_TO_RED_STATUS)) {
    return '#FF1818'
  } else if (diffTimeFromNow(time, TIME_TO_YELLOW_STATUS)) {
    return '#C8C61E'
  } else {
    return '#008896'
  }
}

export function diffTimeFromNow(time, hours) {
  return moment(time, 'YYYY-MM-DD[T]HH:mm:ssZ').diff(moment(), 'hours') < hours
}

export function formatDateToLocale(date) {
  return moment(date, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br').format('DD MMM YYYY')
}

export function dateFromNow (date) {
  return moment(date, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br').fromNow()
}

export function diffBetweenDates(start, end) {
  start = moment(start, 'YYYY-MM-DD[T]HH:mm:ssZ')
  end = moment(end, 'YYYY-MM-DD[T]HH:mm:ssZ')
  return end.diff(start, 'days')
}

export function addDays (date, daysToRemove) {
  date = moment(date, 'YYYY-MM-DD[T]HH:mm:ssZ')
  date.add(daysToRemove, 'days')
  return moment(date, 'YYYY-MM-DD[T]HH:mm:ssZ')
}

export function removeDays (date, daysToRemove) {
  date = moment(date, 'YYYY-MM-DD[T]HH:mm:ssZ')
  date.subtract(daysToRemove, 'days')
  return formatDateToLocale(date)
}

export function formatTime(time) {
  time = time.split(':')
  if(time[0] === '00' && time[1] === '00') return 'Indefinido'
  return `${time[0]}:${time[1]} `
}

export function timeToExpire (start, time = 48) {
  const date = moment(start, 'YYYY-MM-DD[T]HH:mm:ssZ')
  date.add(time, 'hours')
  return date
}

export function timeToExpireBetweenDates (suppliersDeadline) {
  const dateLimit = moment(suppliersDeadline, 'YYYY-MM-DD[T]HH:mm:ssZ')
  const now = new Date()
  const diffInMinutes = dateLimit.diff(moment(now.toISOString(), 'YYYY-MM-DD[T]HH:mm:ssZ'), 'minutes')
  return moment(now.toISOString(), 'YYYY-MM-DD[T]HH:mm:ssZ').add(diffInMinutes, 'minutes')
}

export function formatDefault (date) {
  return moment(date, 'YYYY-MM-DD[T]HH:mm:ssZ').format('YYYY-MM-DD HH:mm')
}

export function isExpired (suppliersDeadline) {
  if(!suppliersDeadline) return true
  const dateLimit = moment(suppliersDeadline, 'YYYY-MM-DD[T]HH:mm:ssZ')
  const now = new Date()
  return dateLimit.diff(moment(now.toISOString(), 'YYYY-MM-DD[T]HH:mm:ssZ'), 'hours') < 0
}
