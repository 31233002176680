/* LIBS */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import InputMask from 'react-input-mask'
/* ACTIONS */
import { singUpInsert } from '../../../redux/actions/signUp'
/* STYLES */
import { defaultTheme } from '../../styles/themes'
/* I18n */
import i18n from '../../../i18n'
/* UI */
import Input from '../../components/ui/Input'
import Button from '../../components/ui/Button'
import Alert from '../../components/ui/Alert'
import Loader from '../../components/Loader'
import { isCnpjValid } from '../../../utils/validation'

const Content = styled.main`
  background-color: #FAFAFA;
  min-height: 100vh;
  width: 80%;
  box-sizing: border-box;
`

const Steps = styled.span`
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  font-size: 14px;
  display: flex;
  padding: 15px 10px 3px 10px;
  color: #767676;
`
const Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  font-family: ${defaultTheme.fonts.secondary};
  padding: 3px 10px 6px 10px;
  color: #484848;
`

const Stepper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  box-sizing: border-box;
`

const StepperDescription = styled.div`
`
const Form = styled.form`
  flex-wrap: wrap;
  display: flex;
  padding: 0px 10px;
`

const Row = styled.div`
  display: flex;
  width: 100%;
  margin-top: 15px;
`

class SignUp extends Component {
  constructor() {
    super()
    this.state = {
      company: {
        name: '',
        document: '',
        full_name: '',
        phone: '',
        website: '',
        email: '',
        password: '',
        password_confirm: ''
      },
      errors: {
        name: '',
        document: '',
        full_name: '',
        phone: '',
        website: '',
        email: '',
        password: '',
        password_confirm: ''
      },
      error_empty: false,
      error_password: false,
      isLoading: false
    }
  }

  handlerValue (input) {
    const { company } = this.state
    company[input.target.name] = input.target.value.trim()
    this.setState({company: company})
  }

  checkValidAccount (company) {
    const { document, email } = company.profile
    this.setState({isLoading: true})
    fetch(`/api/v1/accounts/check`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify({document: document, email: email})
    })
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error(response)
      }
    })
    .then(response => {
      this.setState({isLoading: false})
      if (response.success) {
        this.props.singUpInsert(company)
      } else {
        alert(response.errors.message)
      }
    })
    .catch(e => {
      this.setState({isLoading: false})
      console.error(e)
    })
  }

  submit(e) {
    e.preventDefault()
    this.setState({error_empty: false, error_password: false, errors: {
        name: '',
        document: '',
        full_name: '',
        phone: '',
        website: '',
        email: '',
        password: '',
        password_confirm: ''
    }}, () => {
      const { company } = this.state
      const { language } = this.props
      const errors = {
        empty: false,
        password: false,
        cnpj: false
      }

      var handleErrors = this.state.errors
      Object.keys(company).forEach(c => {
        if (company[c] === '' && c !== 'website') {
          errors.empty = true
          handleErrors[c] = true
        }
      })
      errors.cnpj = !isCnpjValid(company.document)

      var rePassword = /^(?=.*?[A-Z]).{8,}$/
      if (!rePassword.test(String(company.password))) errors.password = i18n.t('signUp.password_at_least', { language })

      if (company.password !== company.password_confirm) errors.password = i18n.t('signUp.password_not_equal', { language })


      if (errors.empty || errors.password || errors.cnpj) {
        this.setState({error_empty: errors.empty, error_password: errors.password, error_cnpj: errors.cnpj})
      } else {
        const companyObj = {
          name: company.name,
          type: 'SUPPLIER',
          profile: {
            email: company.email,
            language: 'pt_br',
            type: 'COMPANY',
            document: company.document,
            given_name: company.name,
            full_name: company.full_name,
            phone: company.phone
          },
          location: false,
          services: false,
          user: {
            password: company.password
          }
        }
        this.checkValidAccount(companyObj)
      }
    })
  }

  render() {
    const { language, company, history } = this.props
    const { isLoading } = this.state
    if (company) history.push('/signup/address')
    return (
      <Content>
        {isLoading ? <Loader /> : null}
        <Stepper>
          <StepperDescription>
            <Steps>Etapa 1 de 3</Steps>
            <Title>Dados do fornecedor</Title>
          </StepperDescription>
        </Stepper>
        <Form onSubmit={this.submit.bind(this)} method='POST'>
        {this.state.error_empty
          ? <Alert type={'negative'}>
            {i18n.t('signUp.empty_fields', { language })}
          </Alert> : null}
          <Input
            type={'text'}
            label={i18n.t('signUp.company_name', { language })}
            placeholder={i18n.t('signUp.company_name_placeholder', { language })}
            name={'name'}
            error={this.state.errors.name}
            onChange={this.handlerValue.bind(this)}
            isRequired
          />
          <Input
            type={'email'}
            label={i18n.t('signUp.email', { language })}
            placeholder={i18n.t('signUp.email_placeholder', { language })}
            name={'email'}
            error={this.state.errors.email}
            onChange={this.handlerValue.bind(this)}
            isRequired
          />
          <InputMask mask="99.999.999/9999-99"
            value={this.state.document}
            onChange={this.handlerValue.bind(this)}>
            {(inputProps) => <Input
                {...inputProps}
                type={'text'}
                label={i18n.t('signUp.cnpj', { language })}
                placeholder={i18n.t('signUp.cnpj_placeholder', { language })}
                name={'document'}
                error={this.state.error_cnpj || this.state.errors.document}
                isRequired
            />}
          </InputMask>
          {this.state.error_cnpj
          ? <Alert type={'negative'}>
              Digite um CNPJ válido!
          </Alert> : null}
          <Input
            type={'text'}
            label={i18n.t('signUp.full_name', { language })}
            placeholder={i18n.t('signUp.full_name_placeholder', { language })}
            name={'full_name'}
            error={this.state.errors.full_name}
            onChange={this.handlerValue.bind(this)}
            isRequired
          />
          <InputMask
            mask="(99) 9999-99999"
            maskChar=""
            value={this.state.phone}
            onChange={this.handlerValue.bind(this)}>
            {(inputProps) =>  <Input
                type={'text'}
                label={i18n.t('signUp.phone', { language })}
                placeholder={i18n.t('signUp.phone_placeholder', { language })}
                name={'phone'}
                error={this.state.errors.phone}
                isRequired
            />}
          </InputMask>
          <Input
            type={'text'}
            label={i18n.t('signUp.website', { language })}
            placeholder={i18n.t('signUp.website_placeholder', { language })}
            name={'website'}
            onChange={this.handlerValue.bind(this)}
          />
          <Row><Title>{i18n.t('signUp.password_title', { language })}</Title></Row>
          {this.state.error_password
          ? <Alert type={'negative'}>
              {this.state.error_password}
          </Alert> : null}
          <Input
            type={'password'}
            label={i18n.t('signUp.password', { language })}
            placeholder={i18n.t('signUp.password_placeholder', { language })}
            name={'password'}
            error={this.state.errors.password}
            isRequired
            onChange={this.handlerValue.bind(this)}
          />
          <Input
            type={'password'}
            label={i18n.t('signUp.password_confirm', { language })}
            placeholder={i18n.t('signUp.password_confirm_placeholder', { language })}
            name={'password_confirm'}
            error={this.state.errors.password_confirm}
            isRequired
            onChange={this.handlerValue.bind(this)}
          />
          <Button
            type='submit'
            category={'secondary'}
            fontWeight={800}
            full>
            {i18n.t('signUp.send_action', { language })}
          </Button>
        </Form>
    </Content>
    )
  }
}

function mapStateToProps (state) {
  const { app, signUp } = state
  const { language } = app
  const { company } = signUp
  return {
    language,
    company
  }
}

export default connect(mapStateToProps, { singUpInsert })(SignUp)
