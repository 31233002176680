/* LIBS */
import React, { Component } from 'react'
import Historico from './Historico'
import ModalConfirmacao from './ModalConfirmacao'
import CampoMensagem from './CampoMensagem'
import styled from 'styled-components'
import { defaultTheme } from '../../styles/themes'
import { Get, Put, Post } from '../../../utils/request'
import { isDocumentVisible } from '../../../utils/polyfill'
import config from '../../../middleware/config'
import io from 'socket.io-client'
import Switch from './../../components/ui/Switch'

const Title = styled.h1`
  font-family: ${defaultTheme.fonts.secondary};
  font-size: 16px;
  line-height: 1.2;
`

const AreaTexto = styled.div`
  align-items: center;
  border: 1px solid #ccc;
  cursor: text;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
  padding: 10px;
  & svg {
    width: 24px;
    &:hover {
      fill: #6395C8
    }
  }
`

const FlexBox = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

const defaultPlaceholder = 'Escreva a sua mensagem... '
const connectingMessage = 'Conectando ao servidor... '
const disconnectMessage = 'Sem conexão com o servidor :´( '
const infraErrorMessage = 'Sistema indisponível no momento.'
const SYSTEM = 'SYSTEM'
const USER = 'USER'

export default class SingleChat extends Component {
  constructor (props) {
    super(props)
    const token = window.localStorage.getItem('user_token')
    const userId = window.localStorage.getItem('user')
    const userType = window.localStorage.getItem('user_type')
    const supplierId = this.props.supplierId
    const eventId = this.props.eventId
    const eventName = this.props.eventName
    const eventStatus = this.props.eventStatus

    this.state = {
      unseeMessages: 0,
      textAreaActive: false,
      modalConfirmacaoVisivel: false,
      placeholder: connectingMessage,
      io: null,
      enabled: false,
      emTransicao: false,
      eventName,
      eventStatus,
      userId,
      chatToken: '', // setado na volta do handshake
      userName: '', // setado na volta do handshake
      supplierName: '', // setado na volta do handshake
      userType,
      mensagens: []
    }

    this.setupSocket(token, eventId, supplierId)
  }

  onMessage = data => {
    const { mensagens } = this.state
    mensagens.push(data)

    this.setState({ mensagens }, () => {
      if (isDocumentVisible() && this.state.textAreaActive) {
        this.checkSeenMessage()
        this.clearUnseeMessages()
        if (this.props.onUnseeMessage) this.props.onUnseeMessage(this.state.unseeMessages)
      } else {
        const messagesCount = (this.state.unseeMessages + 1)
        this.setState({ unseeMessages: messagesCount }, () => {
          if (this.props.onUnseeMessage) this.props.onUnseeMessage(messagesCount)
        })
      }

      this.scrollTextArea()
    })
  }

  onWarning = error => {
    alert(`Não foi possível entregar a mensagem. ${error}`)
  }

  setupSocketListeners () {
    const chatToken = this.state.chatToken

    this.state.io.off(chatToken, this.onMessage)
    this.state.io.on(chatToken, this.onMessage)

    this.state.io.off(`${chatToken}-error`, this.onWarning)
    this.state.io.on(`${chatToken}-error`, this.onWarning)
  }

  setupSocket = async (token, eventId, supplierId) => {
    const host = window.location.hostname
    const protocol = (host === 'localhost') ? 'http' : 'https'
    const port = (host === 'localhost') ? ':8081' : ''

    const ioInstance = await io.connect(`${protocol}://${host}${port}`, { query: `token=${token}&eventId=${eventId}&supplierId=${supplierId}` })
    this.setState({ io: ioInstance })

    ioInstance.on(`pong-${token}`, data => { // handshake
      const chatToken = data.chatToken
      this.setState({ chatToken, userName: data.userName, supplierName: data.supplierName, enabled: true })
      this.setupSocketListeners()
    })

    ioInstance.on('connect', () => {
      this.setState({ placeholder: defaultPlaceholder, enabled: true })
      this.setState({ enabled: true })
    })

    ioInstance.on('error', () => {
      this.setState({ enabled: false, placeholder: infraErrorMessage, })
    })

    ioInstance.on('disconnect', () => {
      this.setState({ enabled: false, placeholder: disconnectMessage })
    })

    ioInstance.on('reconnect', () => {
      this.setState({ placeholder: defaultPlaceholder, enabled: true })
      this.setupSocketListeners()
    })
  }

  clearUnseeMessages = () => {
    this.setState({ unseeMessages: 0 })
  }

  sendMessage = message => {
    if (!message || (message.trim().length === 0)) return window.alert('Informe uma mensagem.')
    try {
      this.clearUnseeMessages()
      this.setState({ unseeMessages: 0 })
      this.state.io.emit('message', {
        message,
        chatToken: this.state.chatToken,
        userType: this.state.userType,
        messageType: USER,
        userId: this.state.userId,
        eventName: this.state.eventName,
        userName: this.state.userName,
        supplierName: this.state.supplierName
      })
    } catch (e) {
      console.error(e)
    }
  }

  handleFocus = (state) => {

    this.setState({ textAreaActive: true, unseeMessages: 0 }, () => {
      this.checkSeenMessage()
      if (this.props.onUnseeMessage) this.props.onUnseeMessage(this.state.unseeMessages)
    })
  }

  handleBlur = (state) => {
    this.setState({ textAreaActive: false })
  }

  handleAttachment = file => {
    try {
      this.clearUnseeMessages()
      this.state.io.emit('message', {
        attachment: {
          url: file.url,
          name: file.originalName,
          size: file.size
        },
        chatToken: this.state.chatToken,
        userType: this.state.userType,
        messageType: USER,
        userId: this.state.userId,
        eventName: this.state.eventName,
        userName: this.state.userName,
        supplierName: this.state.supplierName
      })
    } catch (e) {
      console.error(e)
    }
  }

  toggleBudgetEdit = status => {
    const message = `${this.state.userName} ${status ? 'habilitou' : 'desabilitou'} a edição para o fornecedor`
    this.sendSystemMessage(message)
  }

  sendSystemMessage = message => {
    try {
      this.state.io.emit('message', {
        message,
        chatToken: this.state.chatToken,
        userType: this.state.userType,
        messageType: SYSTEM,
        userId: this.state.userId,
        eventName: this.state.eventName,
        userName: this.state.userName
      })
    } catch (e) {
      console.error(e)
    }
  }

  scrollTextArea = () => {
    const textArea = document.getElementById('historico_container')
    if (textArea) textArea.scrollTop = textArea.scrollHeight
  }

  checkSeenMessage = () => {
    const { api: { base, version } } = config
    const { eventId, supplierId } = this.props
    const endpoint = `${base}${version}/chat/event/${eventId}/supplier/${supplierId}/check-seen-messages`

    Post(endpoint)
      .then(result => {

      }).catch(err => {
        console.error(err.message)
      })
  }

  getDados () {
    const { eventId, supplierId, user, eventStatus } = this.props
    const { api: { base, version, routes: { chat, events, supplier } } } = config
    const messages = `${base}${version}${chat}${events}/${eventId}${supplier}/${supplierId}/messages`
    const editable = `${base}${version}${events}/${eventId}${supplier}/${supplierId}/budget/editable`
    const NAO_EH_FORNECEDOR = user !== 'fornecedor'
    const EVENTO_ABERTO = eventStatus !== 'ORDERED'

    Promise.all([
      Get(messages),
      NAO_EH_FORNECEDOR && EVENTO_ABERTO ? Get(editable) : Promise.resolve()
    ]).then(resposta => {
      this.setState({
        mensagens: resposta[0],
        edicaoHabilitada: !!(resposta[1] && resposta[1].editable),
        enabled: true
      }, () => { this.scrollTextArea() })
    }).catch(erro => {
      console.error(erro)
      window.alert(`Falha ao buscar o histórico de mensagens completo, favor entrar em contato com nosso suporte`)
    })
  }

  componentDidMount () {
    return this.getDados()
  }

  componentDidUpdate (prevProps) {
    if (this.props.supplierId !== prevProps.supplierId) {
      return this.getDados()
    }
  }

  habilitarDesabilitarEdicao = () => {
    const { supplierId, eventId } = this.props
    const { api: { base, version, routes: { events, supplier } } } = config
    const { edicaoHabilitada } = this.state
    const acao = edicaoHabilitada ? 'disable' : 'enable'
    const endpoint = `${base}${version}${events}/${eventId}${supplier}/${supplierId}/edit/${acao}`

    if (!this.state.emTransicao) {
      this.setState({ emTransicao: true }, () => {
        Put(endpoint).then(() => {
          this.toggleBudgetEdit(!edicaoHabilitada)
          this.setState({
            edicaoHabilitada: !edicaoHabilitada,
            emTransicao: false
          })
        }).catch(erro => {
          this.setState({ emTransicao: false }, () => {
            window.alert(`Ocorreu um erro inesperado: ${erro}`)
          })
        })
      })
    }
  }

  exibirModalConfirmacao = () => {
    if (!this.state.edicaoHabilitada) {
      this.setState({ modalConfirmacaoVisivel: true })
    } else {
      this.habilitarDesabilitarEdicao()
    }
  }

  handleCancel = () => {
    this.setState({ modalConfirmacaoVisivel: false })
  }

  handleSubmit = () => {
    this.habilitarDesabilitarEdicao()
    this.setState({ modalConfirmacaoVisivel: false })
  }

  render () {
    if (!this.props.display) return ''

    const { supplierId, eventId, user, eventStatus } = this.props
    const { api: { base, version, routes: { chat, events, supplier } } } = config
    const uploadPath = `${base}${version}${chat}${events}/${eventId}${supplier}/${supplierId}/file`
    const EH_FORNECEDOR = user === 'fornecedor'
    const EVENTO_ABERTO = eventStatus !== 'ORDERED'
    const EXIBE_HABILITAR_EDICAO = !EH_FORNECEDOR && EVENTO_ABERTO

    return <>
      <FlexBox>
        <Title id='anchor'>Converse direto com o {EH_FORNECEDOR ? 'cliente' : 'fornecedor'}</Title>
        {EXIBE_HABILITAR_EDICAO && <FlexBox><Switch disabled={this.state.emTransicao} onClick={this.exibirModalConfirmacao} unique checked={this.state.edicaoHabilitada} label={`${this.state.edicaoHabilitada ? 'Desabilitar' : 'Habilitar'} edição para o fornecedor`} /></FlexBox>}
      </FlexBox>
      <Historico mensagens={this.state.mensagens} />
      <AreaTexto>
        <CampoMensagem
          placeholder={this.state.placeholder}
          enabled={this.state.enabled}
          uploadPath={uploadPath}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onClick={this.sendMessage}
          handleAttachment={this.handleAttachment}
          onKeyPressEnter={this.sendMessage}
        />
      </AreaTexto>
      {this.state.modalConfirmacaoVisivel && <ModalConfirmacao
        isOpen={this.state.modalConfirmacaoVisivel}
        onCancel={this.handleCancel}
        onSubmit={this.handleSubmit}
        hasCloseButton={true}
      />}
    </>
  }
}
