const API = '/api/v1'

// There are three possible states for our login
// process and we need actions for each of them
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

function requestLogin (creds) {
  return {
    type: LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false,
    creds
  }
}

function receiveLogin (user) {
  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    userToken: user.data.user_token
  }
}

export function loginError (message) {
  return {
    type: LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message
  }
}

// Three possible states for our logout process as well.
// Since we are using JWTs, we just need to remove the token
// from localStorage. These actions are more useful if we
// were calling the API to log the user out
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'

function requestLogout () {
  return {
    type: LOGOUT_REQUEST,
    isFetching: true,
    isAuthenticated: true
  }
}

function receiveLogout () {
  return {
    type: LOGOUT_SUCCESS,
    isFetching: false,
    isAuthenticated: false
  }
}

// Calls the API to get a token and
// dispatches actions along the way
export function loginUser (creds) {
  let config = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username: creds.username, password: creds.password })
  }

  return dispatch => {
    // We dispatch requestLogin to kickoff the call to the API
    dispatch(requestLogin(creds))
    return window.fetch(`${API}/auth/login`, config)
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error(response)
        }
      }).then((user) => {
        if (!user.success) {
          dispatch(loginError(user.message))
          return Promise.reject(user)
        } else {
          // If login was successful, set the token in local storage
          window.localStorage.setItem('user_token', user.data.token)
          window.localStorage.setItem('expires', user.data.expires)
          window.localStorage.setItem('user', user.data.user)
          window.localStorage.setItem('account', user.data.account)
          window.localStorage.setItem('user_roles', user.data.role && JSON.stringify(user.data.role))
          window.localStorage.setItem('user_type', user.data.type)
          if (user.data.supplierChainId) window.localStorage.setItem('supplierChainId', user.data.supplierChainId)
          else window.localStorage.removeItem('supplierChainId')
          if (user.data.featureFlags) window.localStorage.setItem('featureFlags', JSON.stringify(user.data.featureFlags))
          else window.localStorage.removeItem('featureFlags')
          window.localStorage.setItem('theme_logo', user.data.theme && user.data.theme.logo)
          window.localStorage.setItem('theme_color_primary', user.data.theme ? user.data.theme.primary : '#009688')
          window.localStorage.setItem('theme_color_secondary', user.data.theme ? user.data.theme.secondary : '#762123')
          window.localStorage.setItem('services', user.data.services && JSON.stringify(user.data.services))
          // Dispatch the success action
          dispatch(receiveLogin(user))
          return Promise.resolve(user)
        }
      }).catch(err => {
        console.log('Error: ', err)
        return Promise.reject(err)
      })
  }
}

// Logs the user out
export function logoutUser () {
  const type = window.localStorage.getItem('user_type')
  return dispatch => {
    dispatch(requestLogout())
    window.localStorage.removeItem('user_token')
    window.localStorage.removeItem('expires')
    window.localStorage.removeItem('user')
    window.localStorage.removeItem('account')
    window.localStorage.removeItem('user_roles')
    window.localStorage.removeItem('user_type')
    window.localStorage.removeItem('supplierChainId')
    window.localStorage.removeItem('featureFlags')
    window.localStorage.removeItem('services')
    dispatch(receiveLogout())
    window.location.href = (type === 'ORGANIZER' || type === 'RESELLER') ? '/organizer/signin' : '/signin'
  }
}

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE'

function requestForgotPassword (username) {
  return {
    type: FORGOT_PASSWORD_REQUEST,
    isFetching: true,
    username
  }
}

function receiveForgotPassword (data) {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    isFetching: false,
    data: data
  }
}

export function forgotPasswordError (message) {
  return {
    type: FORGOT_PASSWORD_FAILURE,
    isFetching: false,
    message
  }
}

// Calls the API to get a token and
// dispatches actions along the way
export function forgotPassword (username) {
  let config = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username: username })
  }

  return dispatch => {
    // We dispatch requestLogin to kickoff the call to the API
    dispatch(requestForgotPassword(username))
    return window.fetch(`${API}/auth/forgot_password`, config)
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error(response)
        }
      }).then((pass) => {
        if (!pass.success) {
          dispatch(forgotPasswordError(pass.errors))
          window.alert('Erro ao enviar email com o link para alterar a senha')
          return Promise.reject(pass)
        } else {
          dispatch(receiveForgotPassword(pass.data))
          window.alert('Um link foi enviado para o seu email para o cadastro de uma nova senha.')
          window.location.href = `/signin`
        }
      }).catch(err => console.log('Error: ', err))
  }
}

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE'

function requestResetPassword (creds) {
  return {
    type: RESET_PASSWORD_REQUEST,
    isFetching: true,
    creds
  }
}

function receiveResetPassword (data) {
  return {
    type: RESET_PASSWORD_SUCCESS,
    isFetching: false,
    data
  }
}

export function resetPasswordError (message) {
  return {
    type: RESET_PASSWORD_FAILURE,
    isFetching: false,
    message
  }
}

export function resetPassword (password, token) {
  let config = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ password: password, token: token })
  }

  return dispatch => {
    // We dispatch requestLogin to kickoff the call to the API
    dispatch(requestResetPassword({ password: password, token: token }))
    return window.fetch(`${API}/auth/reset_password`, config)
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error(response)
        }
      }).then((pass) => {
        if (pass.data.password !== 'updated') {
          dispatch(resetPasswordError(pass.errors || pass.data.user))
          return Promise.reject(pass)
        } else {
          dispatch(receiveResetPassword(pass.data))
          window.alert('Senha alterada com sucesso.')
          window.location.href = `/signin`
        }
      }).catch(err => console.log('Error: ', err))
  }
}
