/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
import jwtDecode from 'jwt-decode'
/* STYLES */
import { defaultTheme } from '../../styles/themes'
/* UI */
import Button from '../../components/ui/Button'
import Input from '../../components/ui/Input'
import Select from '../../components/ui/Select'
import Alert from '../../components/ui/Alert'
import Card from '../../components/ui/Card'
import RightBoxInput from '../../components/ui/RightBoxInput'
import CheckBox from '../../components/ui/CheckBox'
import MoneyInput from '../../components/ui/MoneyInput'
import Textarea from '../../components/ui/Textarea'
import Loader from '../../components/Loader'
import BreadCrumb from '../../components/BreadCrumb'
import { Get, Post, Put } from '../../../utils/request'
/* COMPONENTS */
import Header from '../../components/Header'
import config from '../../../middleware/config'

const Main = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #FFF;
  @media (min-width: 768px) {
    background-color: #FAFAFA;
  }
`

const Content = styled.div`
  min-height: 90vh;
  padding: 30px 0px;
  font-family: ${defaultTheme.fonts.secondary};
  width: 70%;
  margin: 0 5%;
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  align-content: start;
`

const Title = styled.h1`
  width: 100%;
  text-align: left;
  font-size: 20px;
  padding: 20px 10px;
`

const Label = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  box-sizing: border-box;
  padding: 5px 5px 5px 0px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
`

const FlexGroup = styled.div`
  display: flex;
  padding: 20px;
  padding-top: 0px;
`

const Group = styled.div`
`

class FormEquipments extends Component {
  state = {
    isLoading: false,
    projector: {
      enabled: false,
      defaultPrice: {
        amount: 0,
        currency: "BRL"
      },
      description: ""
    },
    screen: {
      enabled: false,
      defaultPrice: {
        amount: 0,
        currency: "BRL"
      },
      description: ""
    },
    sound: {
      enabled: false,
      defaultPrice: {
        amount: 0,
        currency: "BRL"
      },
      description: ""
    },
    microphone: {
      enabled: false,
      lapel: {
        defaultPrice:  {
          amount: 0,
          currency: "BRL"
        },
        description: ""
      },
      gooseneck: {
        defaultPrice:  {
          amount: 0,
          currency: "BRL"
        },
        description: ""
      },
      headSet: {
        defaultPrice:  {
          amount: 0,
          currency: "BRL"
        },
        description: ""
      },
      stick: {
        defaultPrice:  {
          amount: 0,
          currency: "BRL"
        },
        description: ""
      }
    },
    flipchart: {
      enabled: false,
      defaultPrice: {
        amount: 0,
        currency: "BRL"
      },
      description: ""
    },
    laserPoint: {
      enabled: false,
      defaultPrice: {
        amount: 0,
        currency: "BRL"
      },
      description: ""
    },
    notebook: {
      enabled: false,
      defaultPrice: {
        amount: 0,
        currency: "BRL"
      },
      description: ""
    },
    lighting: {
      enabled: false,
      defaultPrice: {
        amount: 0,
        currency: "BRL"
      },
      description: ""
    },
    simultaneousTranslation: {
      enabled: false,
      defaultPrice: {
        amount: 0,
        currency: "BRL"
      },
      description: ""
    },
    internet: {
      mbSpeed: 50,
      wifi: true,
      dedicatedLink: true,
      enabled: false,
      defaultPrice: {
        amount: 0,
        currency: "BRL"
      },
      description: ""
    },
    radio: {
      enabled: false,
      defaultPrice: {
        amount: 0,
        currency: "BRL"
      },
      description: ""
    },
    operator: {
      enabled: false,
      defaultPrice: {
        amount: 0,
        currency: "BRL"
      },
      description: ""
    },
    generator: {
      enabled: false,
      defaultPrice: {
        amount: 0,
        currency: "BRL"
      },
      description: ""
    },
    serviceFee: {
      type: "PERCENTAGE",
      amount: null
    },
    supplierName: '',
    showThirdPartFee: false,
    thirdPartFee: {
      type: "ABSOLUTE",
      amount: 0
    }
  }

  componentDidMount() {
    this.loadData()
  }

  loadData = () => {
    this.setState({
      isLoading: true
    }, () => {
      const { api: { base, version, routes: { equipments } } } = config
      const token = window.localStorage.getItem('user_token')
      const id = jwtDecode(token).accountId
      const endpoint = `${base}${version}${equipments}/account/${id}`
      Get(endpoint)
      .then((result) => {
        var equipamentos = result[0]
        delete equipamentos.accountId
        delete equipamentos.created
        delete equipamentos.status
        delete equipamentos.updated
        delete equipamentos.userId
        if(equipamentos.thirdPartFee) {
          equipamentos.showThirdPartFee = true
        } else {
          equipamentos.thirdPartFee = {
            type: "ABSOLUTE",
            amount: 0
          }
        }
        this.setState({
          ...equipamentos,
          isLoading: false
        })
      }).catch(err => {
        console.error(err)
        this.setState({
          isLoading: false
        })
      })
    })
  }

  submit = () => {
    if(!this.isValid()) return alert('Preencha todos os campos obrigatórios.')
    if(!this.isThirdPartFeeValid()) return alert('Preencha o valor dos equipamentos de terceiros.')
    this.setState({
      isLoading: true
    }, () => {
      const { match: { params: { event, equipment } } } = this.props
      const { api: { base, version, routes: { equipments } } } = config
      var data = this.state
      delete data.isLoading
      if(!data.showThirdPartFee || data.thirdPartFee.amount === 0) {
        delete data.thirdPartFee
      }
      delete data.showThirdPartFee
      if(this.state.id) {
        const endpointPut = `${base}${version}${equipments}/${this.state.id}`
        Put(endpointPut, data)
        .then(() => {
          if(this.props.location.query && this.props.location.query.url) return this.props.history.push(this.props.location.query.url)
          else if(!event && !equipment) return this.props.history.push('/')
          else if(event && equipment) return this.props.history.push(`/budget/briefing/equipment/${event}/${equipment}`)
        }).catch(err => {
          console.error(err.message)
          this.setState({
            isLoading: false
          })
          alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
        })
      }
      else {
        const endpoint = `${base}${version}${equipments}`
        Post(endpoint, data)
        .then(() => {
          if(this.props.location.query && this.props.location.query.url) return this.props.history.push(this.props.location.query.url)
          else if(!event && !equipment) return this.props.history.push('/')
          else if(event && equipment) return this.props.history.push(`/budget/briefing/equipment/${event}/${equipment}`)
        }).catch(err => {
          console.error(err.message)
          this.setState({
            isLoading: false
          })
          alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
        })
      }
    })
  }

  isValid = () => {
    const formError = Object.keys(this.state).filter(item => item !== 'isLoading' && item !== 'microphone' && item !== 'serviceFee' && item !== 'id' && item !== 'thirdPartFee' && !!this.state[item]).some(item => {
      if(this.state[item].enabled &&
          (!this.state[item].defaultPrice.amount ||
            this.state[item].defaultPrice.amount <= 0)
        ) {
          return true
        } else {
          return false
        }
    })
    const microfoneError = this.state.microphone.enabled && (
      (this.state.microphone.lapel.enabled && (!this.state.microphone.lapel.defaultPrice.amount ||
        this.state.microphone.lapel.defaultPrice.amount <= 0)) ||
      (this.state.microphone.gooseneck.enabled && (!this.state.microphone.gooseneck.defaultPrice.amount ||
        this.state.microphone.gooseneck.defaultPrice.amount <= 0)) ||
      (this.state.microphone.headSet.enabled && (!this.state.microphone.headSet.defaultPrice.amount ||
        this.state.microphone.headSet.defaultPrice.amount <= 0)) ||
      (this.state.microphone.stick.enabled && (!this.state.microphone.stick.defaultPrice.amount ||
        this.state.microphone.stick.defaultPrice.amount <= 0))
    )
    return !formError && !microfoneError
  }

  isThirdPartFeeValid = () => {
    return !this.state.showThirdPartFee || (this.state.showThirdPartFee && this.state.thirdPartFee.amount > 0)
  }

  enableEquipment = (input) => {
    var state = this.state
    state[input.target.name].enabled = !state[input.target.name].enabled
    this.setState(state)
  }

  handleValue = (name, input, secondName) => {
    var state = this.state
    if(!secondName) {
      if(input.target.name === 'defaultPrice') {
        state[name][input.target.name].amount = input.target.value
      } else {
        state[name][input.target.name] = input.target.value
      }
    } else {
      if(input.target.name === 'defaultPrice') {
        state[name][secondName][input.target.name].amount = input.target.value
      } else {
        state[name][secondName][input.target.name] = input.target.value
      }
    }
    this.setState(state)
  }

  handleServiceFee = (input) => {
    var { serviceFee } = this.state
    serviceFee.amount = input.target.value
    this.setState({ serviceFee: serviceFee })
  }

  handleSupplierName = (input) => {
    var { supplierName } = this.state
    supplierName = input.target.value
    this.setState({ supplierName: supplierName })
  }

  showThirdPartFee = (input) => {
    var { showThirdPartFee } = this.state
    showThirdPartFee = input.target.value === 'true'
    this.setState({ showThirdPartFee: showThirdPartFee })
  }

  handleThirdPartFee = (input) => {
    var { thirdPartFee } = this.state
    thirdPartFee.amount = input.target.value
    this.setState({ thirdPartFee: thirdPartFee })
  }


  handleCheckBox = (name, input, secondName) => {
    var state = this.state
    if(!secondName) {
      state[name][input.target.name] = !state[name][input.target.name]
    } else {
      state[name][secondName][input.target.name] = !state[name][input.target.name]
    }
    this.setState(state)
  }

  render() {
    const { isLoading } = this.state
    return (
      <Main>
        {isLoading ? <Loader /> : null}
        <Header />
        <BreadCrumb options={
          [
            {link: '/', name: 'Home'},
            {link: '/equipments', name: 'Equipamentos'},
            {name: 'Cadastro de equipamentos'}
          ]
        } />
        <Content>
          <Title>
            Cadastro de equipamentos
          </Title>
          {this.state.error
            ? <Alert type={'negative'}>
              {this.state.error}
            </Alert> : null}
          <Card style={{ margin: '10px' }}><FlexGroup style={{ width: '100%' }}>
            <Input
              label={'Nome do seu fornecedor de equipamentos'}
              placeholder={'Digite o nome do seu fornecedor de equipamentos'}
              name={'name'}
              value={this.state.supplierName}
              onChange={this.handleSupplierName}
            />
            <RightBoxInput
              placeholder={'0'}
              rightBox='%'
              type='number'
              name={'serviceFee'}
              value={this.state.serviceFee.amount}
              onChange={this.handleServiceFee}
              label={'Taxa de serviço'}
              containerStyle={{ width: '40%' }}/>
          </FlexGroup>
          <FlexGroup style={{ width: '100%' }}>
            <Select
              label={'Caso equipamentos venha de terceiros, cobra-se por isso?'}
              name={'hasLocal'}
              onChange={this.showThirdPartFee}
              value={this.state.showThirdPartFee}>
              <option value={true}>Sim</option>
              <option value={false}>Não</option>
            </Select>
            {this.state.showThirdPartFee && <MoneyInput
              label={'Valor'}
              placeholder={'0,00'}
              name={`thirdPartFee`}
              value={this.state.thirdPartFee.amount}
              error={!this.state.thirdPartFee.amount || this.state.thirdPartFee.amount <= 0}
              isRequired
              containerStyle={{ width: '40%' }}
              onChange={this.handleThirdPartFee}
            />}
          </FlexGroup></Card>
          <CheckBox
            label={'Projetor'}
            name={`projector`}
            checked={this.state.projector.enabled}
            value={this.state.projector.enabled}
            onClick={this.enableEquipment}>
            <FlexGroup>
            {this.state.projector.enabled && <>
                <MoneyInput
                  label={'Valor (por unidade, por dia)'}
                  placeholder={'0,00'}
                  name={`defaultPrice`}
                  value={this.state.projector.defaultPrice.amount}
                  error={!this.state.projector.defaultPrice.amount || this.state.projector.defaultPrice.amount <= 0}
                  isRequired
                  containerStyle={{ padding:'0 20px', marginBottom: 'auto', width: '40%' }}
                  onChange={(e) => this.handleValue('projector', e)}
                />
                <Textarea
                  label={'Descrição do equipamento'}
                  placeholder={'Escreva aqui tudo o que inclui neste equipamento'}
                  name={`description`}
                  value={this.state.projector.description}
                  style={{ height: '75px' }}
                  onChange={(e) => this.handleValue('projector', e)}
                />
              </>
            }
            {!this.state.projector.enabled && <Label>Não ofereço este equipamento.</Label>}
            </FlexGroup>
          </CheckBox>
          <CheckBox
            label={'Tela'}
            name={`screen`}
            checked={this.state.screen.enabled}
            value={this.state.screen.enabled}
            onClick={this.enableEquipment}>
            <FlexGroup>
            {this.state.screen.enabled && <>
                <MoneyInput
                  label={'Valor (por unidade, por dia)'}
                  placeholder={'0,00'}
                  name={`defaultPrice`}
                  isRequired
                  value={this.state.screen.defaultPrice.amount}
                  error={!this.state.screen.defaultPrice.amount || this.state.screen.defaultPrice.amount <= 0}
                  containerStyle={{ padding:'0 20px', marginBottom: 'auto', width: '40%' }}
                  onChange={(e) => this.handleValue('screen', e)}
                />
                <Textarea
                  label={'Descrição do equipamento'}
                  placeholder={'Escreva aqui tudo o que inclui neste equipamento'}
                  name={`description`}
                  value={this.state.screen.description}
                  style={{ height: '75px' }}
                  onChange={(e) => this.handleValue('screen', e)}
                />
              </>
            }
            {!this.state.screen.enabled && <Label>Não ofereço este equipamento.</Label>}
            </FlexGroup>
          </CheckBox>
          <CheckBox
            label={'Sonorização'}
            name={`sound`}
            checked={this.state.sound.enabled}
            value={this.state.sound.enabled}
            onClick={this.enableEquipment}>
            <FlexGroup>
            {this.state.sound.enabled && <>
                <MoneyInput
                  label={'Valor (por unidade, por dia)'}
                  placeholder={'0,00'}
                  name={`defaultPrice`}
                  isRequired
                  value={this.state.sound.defaultPrice.amount}
                  error={!this.state.sound.defaultPrice.amount || this.state.sound.defaultPrice.amount <= 0}
                  containerStyle={{ padding:'0 20px', marginBottom: 'auto', width: '40%' }}
                  onChange={(e) => this.handleValue('sound', e)}
                />
                <Textarea
                  label={'Descrição do equipamento'}
                  placeholder={'Escreva aqui tudo o que inclui neste equipamento'}
                  name={`description`}
                  value={this.state.sound.description}
                  style={{ height: '75px' }}
                  onChange={(e) => this.handleValue('sound', e)}
                />
              </>
            }
            {!this.state.sound.enabled && <Label>Não ofereço este equipamento.</Label>}
            </FlexGroup>
          </CheckBox>
          <CheckBox
            label={'Microfone'}
            name={`microphone`}
            checked={this.state.microphone.enabled}
            value={this.state.microphone.enabled}
            onClick={this.enableEquipment}>
            <FlexGroup>
            {this.state.microphone.enabled && <>
                <Group style={{ width: '100%' }}>
                  <FlexGroup style={{ padding: '0px' }}>
                    <MoneyInput
                      label={'Valor da Lapela (por unidade, por dia)'}
                      placeholder={'0,00'}
                      name={`defaultPrice`}
                      isRequired
                      value={this.state.microphone.lapel.defaultPrice.amount}
                      error={!this.state.microphone.lapel.defaultPrice.amount || this.state.microphone.lapel.defaultPrice.amount <= 0}
                      containerStyle={{ padding:'0 20px', marginBottom: 'auto', width: '70%' }}
                      onChange={(e) => this.handleValue('microphone', e, 'lapel')}
                    />
                    <Textarea
                      label={'Descrição da Lapela'}
                      placeholder={'Escreva aqui tudo o que inclui neste equipamento'}
                      name={`description`}
                      value={this.state.microphone.lapel.description}
                      style={{ height: '75px' }}
                      onChange={(e) => this.handleValue('microphone', e, 'lapel')}
                    />
                  </FlexGroup>
                  <FlexGroup style={{ padding: '0px', paddingTop: '20px' }}>
                    <MoneyInput
                      label={'Valor do gooseneck(por unidade, por dia)'}
                      placeholder={'0,00'}
                      name={`defaultPrice`}
                      isRequired
                      value={this.state.microphone.gooseneck.defaultPrice.amount}
                      error={!this.state.microphone.gooseneck.defaultPrice.amount || this.state.microphone.gooseneck.defaultPrice.amount <= 0}
                      containerStyle={{ padding:'0 20px', marginBottom: 'auto', width: '70%' }}
                      onChange={(e) => this.handleValue('microphone', e, 'gooseneck')}
                    />
                    <Textarea
                      label={'Descrição do gooseneck'}
                      placeholder={'Escreva aqui tudo o que inclui neste equipamento'}
                      name={`description`}
                      value={this.state.microphone.gooseneck.description}
                      style={{ height: '75px' }}
                      onChange={(e) => this.handleValue('microphone', e, 'gooseneck')}
                    />
                  </FlexGroup>
                  <FlexGroup style={{ padding: '0px', paddingTop: '20px' }}>
                    <MoneyInput
                      label={'Valor do Head Set (por unidade, por dia)'}
                      placeholder={'0,00'}
                      name={`defaultPrice`}
                      isRequired
                      value={this.state.microphone.headSet.defaultPrice.amount}
                      error={!this.state.microphone.headSet.defaultPrice.amount || this.state.microphone.headSet.defaultPrice.amount <= 0}
                      containerStyle={{ padding:'0 20px', marginBottom: 'auto', width: '70%' }}
                      onChange={(e) => this.handleValue('microphone', e, 'headSet')}
                    />
                    <Textarea
                      label={'Descrição do Head Set'}
                      placeholder={'Escreva aqui tudo o que inclui neste equipamento'}
                      name={`description`}
                      value={this.state.microphone.headSet.description}
                      style={{ height: '75px' }}
                      onChange={(e) => this.handleValue('microphone', e, 'headSet')}
                    />
                  </FlexGroup>
                  <FlexGroup style={{ padding: '0px', paddingTop: '20px' }}>
                    <MoneyInput
                      label={'Valor do Bastão (por unidade, por dia)'}
                      placeholder={'0,00'}
                      name={`defaultPrice`}
                      isRequired
                      value={this.state.microphone.stick.defaultPrice.amount}
                      error={!this.state.microphone.stick.defaultPrice.amount || this.state.microphone.stick.defaultPrice.amount <= 0}
                      containerStyle={{ padding:'0 20px', marginBottom: 'auto', width: '70%' }}
                      onChange={(e) => this.handleValue('microphone', e, 'stick')}
                    />
                    <Textarea
                      label={'Descrição do Bastão'}
                      placeholder={'Escreva aqui tudo o que inclui neste equipamento'}
                      name={`description`}
                      value={this.state.microphone.stick.description}
                      style={{ height: '75px' }}
                      onChange={(e) => this.handleValue('microphone', e, 'stick')}
                    />
                  </FlexGroup>
                </Group>
              </>
            }
            {!this.state.microphone.enabled && <Label>Não ofereço este equipamento.</Label>}
            </FlexGroup>
          </CheckBox>
          <CheckBox
            label={'Flip Chart'}
            name={`flipchart`}
            checked={this.state.flipchart.enabled}
            value={this.state.flipchart.enabled}
            onClick={this.enableEquipment}>
            <FlexGroup>
            {this.state.flipchart.enabled && <>
                <MoneyInput
                  label={'Valor (por unidade, por dia)'}
                  placeholder={'0,00'}
                  name={`defaultPrice`}
                  isRequired
                  value={this.state.flipchart.defaultPrice.amount}
                  error={!this.state.flipchart.defaultPrice.amount || this.state.flipchart.defaultPrice.amount <= 0}
                  containerStyle={{ padding:'0 20px', marginBottom: 'auto', width: '40%' }}
                  onChange={(e) => this.handleValue('flipchart', e)}
                />
                <Textarea
                  label={'Descrição do equipamento'}
                  placeholder={'Escreva aqui tudo o que inclui neste equipamento'}
                  name={`description`}
                  value={this.state.flipchart.description}
                  style={{ height: '75px' }}
                  onChange={(e) => this.handleValue('flipchart', e)}
                />
              </>
            }
            {!this.state.flipchart.enabled && <Label>Não ofereço este equipamento.</Label>}
            </FlexGroup>
          </CheckBox>
          <CheckBox
            label={'Laser point'}
            name={`laserPoint`}
            checked={this.state.laserPoint.enabled}
            value={this.state.laserPoint.enabled}
            onClick={this.enableEquipment}>
            <FlexGroup>
            {this.state.laserPoint.enabled && <>
                <MoneyInput
                  label={'Valor (por unidade, por dia)'}
                  placeholder={'0,00'}
                  name={`defaultPrice`}
                  isRequired
                  value={this.state.laserPoint.defaultPrice.amount}
                  error={!this.state.laserPoint.defaultPrice.amount || this.state.laserPoint.defaultPrice.amount <= 0}
                  containerStyle={{ padding:'0 20px', marginBottom: 'auto', width: '40%' }}
                  onChange={(e) => this.handleValue('laserPoint', e)}
                />
                <Textarea
                  label={'Descrição do equipamento'}
                  placeholder={'Escreva aqui tudo o que inclui neste equipamento'}
                  name={`description`}
                  value={this.state.laserPoint.description}
                  style={{ height: '75px' }}
                  onChange={(e) => this.handleValue('laserPoint', e)}
                />
              </>
            }
            {!this.state.laserPoint.enabled && <Label>Não ofereço este equipamento.</Label>}
            </FlexGroup>
          </CheckBox>
          <CheckBox
            label={'Notebook'}
            name={`notebook`}
            checked={this.state.notebook.enabled}
            value={this.state.notebook.enabled}
            onClick={this.enableEquipment}>
            <FlexGroup>
            {this.state.notebook.enabled && <>
                <MoneyInput
                  label={'Valor (por unidade, por dia)'}
                  placeholder={'0,00'}
                  name={`defaultPrice`}
                  isRequired
                  value={this.state.notebook.defaultPrice.amount}
                  error={!this.state.notebook.defaultPrice.amount || this.state.notebook.defaultPrice.amount <= 0}
                  containerStyle={{ padding:'0 20px', marginBottom: 'auto', width: '40%' }}
                  onChange={(e) => this.handleValue('notebook', e)}
                />
                <Textarea
                  label={'Descrição do equipamento'}
                  placeholder={'Escreva aqui tudo o que inclui neste equipamento'}
                  name={`description`}
                  value={this.state.notebook.description}
                  style={{ height: '75px' }}
                  onChange={(e) => this.handleValue('notebook', e)}
                />
              </>
            }
            {!this.state.notebook.enabled && <Label>Não ofereço este equipamento.</Label>}
            </FlexGroup>
          </CheckBox>
          <CheckBox
            label={'Iluminação'}
            name={`lighting`}
            checked={this.state.lighting.enabled}
            value={this.state.lighting.enabled}
            onClick={this.enableEquipment}>
            <FlexGroup>
            {this.state.lighting.enabled && <>
                <MoneyInput
                  label={'Valor (por unidade, por dia)'}
                  placeholder={'0,00'}
                  name={`defaultPrice`}
                  isRequired
                  value={this.state.lighting.defaultPrice.amount}
                  error={!this.state.lighting.defaultPrice.amount || this.state.lighting.defaultPrice.amount <= 0}
                  containerStyle={{ padding:'0 20px', marginBottom: 'auto', width: '40%' }}
                  onChange={(e) => this.handleValue('lighting', e)}
                />
                <Textarea
                  label={'Descrição do equipamento'}
                  placeholder={'Escreva aqui tudo o que inclui neste equipamento'}
                  name={`description`}
                  value={this.state.lighting.description}
                  style={{ height: '75px' }}
                  onChange={(e) => this.handleValue('lighting', e)}
                />
              </>
            }
            {!this.state.lighting.enabled && <Label>Não ofereço este equipamento.</Label>}
            </FlexGroup>
          </CheckBox>
          <CheckBox
            label={'Tradução simultânea'}
            name={`simultaneousTranslation`}
            checked={this.state.simultaneousTranslation.enabled}
            value={this.state.simultaneousTranslation.enabled}
            onClick={this.enableEquipment}>
            <FlexGroup>
            {this.state.simultaneousTranslation.enabled && <>
                <MoneyInput
                  label={'Valor (por unidade, por dia)'}
                  placeholder={'0,00'}
                  name={`defaultPrice`}
                  isRequired
                  value={this.state.simultaneousTranslation.defaultPrice.amount}
                  error={!this.state.simultaneousTranslation.defaultPrice.amount || this.state.simultaneousTranslation.defaultPrice.amount <= 0}
                  containerStyle={{ padding:'0 20px', marginBottom: 'auto', width: '40%' }}
                  onChange={(e) => this.handleValue('simultaneousTranslation', e)}
                />
                <Textarea
                  label={'Descrição do equipamento'}
                  placeholder={'Escreva aqui tudo o que inclui neste equipamento'}
                  name={`description`}
                  value={this.state.simultaneousTranslation.description}
                  style={{ height: '75px' }}
                  onChange={(e) => this.handleValue('simultaneousTranslation', e)}
                />
              </>
            }
            {!this.state.simultaneousTranslation.enabled && <Label>Não ofereço este equipamento.</Label>}
            </FlexGroup>
          </CheckBox>
          <CheckBox
            label={'Internet'}
            name={`internet`}
            checked={this.state.internet.enabled}
            value={this.state.internet.enabled}
            onClick={this.enableEquipment}>
            <Group style={{ padding: '20px', paddingTop: '0px' }}>
            {this.state.internet.enabled && <>
                <FlexGroup>
                  <CheckBox
                    label={'Wi-fi'}
                    type='checkbox'
                    name={`wifi`}
                    style={{ height: '50px', width: '330px', margin: 'auto 10px' }}
                    onClick={(e) => this.handleCheckBox('internet', e)}
                    checked={this.state.internet.wifi}/>
                  <CheckBox
                    label={'Link dedicado'}
                    type='checkbox'
                    name={`dedicatedLink`}
                    style={{ height: '50px', width: '400px', margin: 'auto 10px' }}
                    onClick={(e) => this.handleCheckBox('internet', e)}
                    checked={this.state.internet.dedicatedLink}/>
                  <RightBoxInput
                    placeholder={'0'}
                    rightBox='MB'
                    type='number'
                    name={'mbSpeed'}
                    isRequired
                    value={this.state.internet.mbSpeed}
                    onChange={(e) => this.handleValue('internet', e)}
                    label={'Quantos MB'}
                    style={{ width: '100%' }}/>
                </FlexGroup>
                <FlexGroup>
                  <MoneyInput
                    label={'Valor (por ponto de acesso, por dia)'}
                    placeholder={'0,00'}
                    name={`defaultPrice`}
                    isRequired
                    value={this.state.internet.defaultPrice.amount}
                    error={!this.state.internet.defaultPrice.amount || this.state.internet.defaultPrice.amount <= 0}
                    containerStyle={{ padding:'0 20px', marginBottom: 'auto', width: '40%' }}
                    onChange={(e) => this.handleValue('internet', e)}
                  />
                  <Textarea
                    label={'Descrição do equipamento'}
                    placeholder={'Escreva aqui tudo o que inclui neste equipamento'}
                    name={`description`}
                    value={this.state.internet.description}
                    style={{ height: '75px' }}
                    onChange={(e) => this.handleValue('internet', e)}
                  />
                </FlexGroup>
              </>
            }
            {!this.state.internet.enabled && <Label>Não ofereço este equipamento.</Label>}
            </Group>
          </CheckBox>
          <CheckBox
            label={'Rádio'}
            name={`radio`}
            checked={this.state.radio.enabled}
            value={this.state.radio.enabled}
            onClick={this.enableEquipment}>
            <FlexGroup>
            {this.state.radio.enabled && <>
                <MoneyInput
                  label={'Valor (por unidade, por dia)'}
                  placeholder={'0,00'}
                  name={`defaultPrice`}
                  isRequired
                  value={this.state.radio.defaultPrice.amount}
                  error={!this.state.radio.defaultPrice.amount || this.state.radio.defaultPrice.amount <= 0}
                  containerStyle={{ padding:'0 20px', marginBottom: 'auto', width: '40%' }}
                  onChange={(e) => this.handleValue('radio', e)}
                />
                <Textarea
                  label={'Descrição do equipamento'}
                  placeholder={'Escreva aqui tudo o que inclui neste equipamento'}
                  name={`description`}
                  value={this.state.radio.description}
                  style={{ height: '75px' }}
                  onChange={(e) => this.handleValue('radio', e)}
                />
              </>
            }
            {!this.state.radio.enabled && <Label>Não ofereço este equipamento.</Label>}
            </FlexGroup>
          </CheckBox>
          <CheckBox
            label={'Operador'}
            name={`operator`}
            checked={this.state.operator.enabled}
            value={this.state.operator.enabled}
            onClick={this.enableEquipment}>
            <FlexGroup>
            {this.state.operator.enabled && <>
                <MoneyInput
                  label={'Valor (por unidade, por dia)'}
                  placeholder={'0,00'}
                  name={`defaultPrice`}
                  isRequired
                  value={this.state.operator.defaultPrice.amount}
                  error={!this.state.operator.defaultPrice.amount || this.state.operator.defaultPrice.amount <= 0}
                  containerStyle={{ padding:'0 20px', marginBottom: 'auto', width: '40%' }}
                  onChange={(e) => this.handleValue('operator', e)}
                />
                <Textarea
                  label={'Descrição do equipamento'}
                  placeholder={'Escreva aqui tudo o que inclui neste equipamento'}
                  name={`description`}
                  value={this.state.operator.description}
                  style={{ height: '75px' }}
                  onChange={(e) => this.handleValue('operator', e)}
                />
              </>
            }
            {!this.state.operator.enabled && <Label>Não ofereço este equipamento.</Label>}
            </FlexGroup>
          </CheckBox>
          <CheckBox
            label={'Gerador'}
            name={`generator`}
            checked={this.state.generator.enabled}
            value={this.state.generator.enabled}
            onClick={this.enableEquipment}>
            <FlexGroup>
            {this.state.generator.enabled && <>
                <MoneyInput
                  label={'Valor (por unidade, por dia)'}
                  placeholder={'0,00'}
                  name={`defaultPrice`}
                  isRequired
                  value={this.state.generator.defaultPrice.amount}
                  error={!this.state.generator.defaultPrice.amount || this.state.generator.defaultPrice.amount <= 0}
                  containerStyle={{ padding:'0 20px', marginBottom: 'auto', width: '40%' }}
                  onChange={(e) => this.handleValue('generator', e)}
                />
                <Textarea
                  label={'Descrição do equipamento'}
                  placeholder={'Escreva aqui tudo o que inclui neste equipamento'}
                  name={`description`}
                  value={this.state.generator.description}
                  style={{ height: '75px' }}
                  onChange={(e) => this.handleValue('generator', e)}
                />
              </>
            }
            {!this.state.generator.enabled && <Label>Não ofereço este equipamento.</Label>}
            </FlexGroup>
          </CheckBox>
          <Button
            type='submit'
            category={'secondary'}
            fontWeight={800}
            onClick={this.submit}
            full>
            Cadastrar
          </Button>
        </Content>
      </Main>
    )
  }
}

export default FormEquipments
