import React from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../../styles/themes'

const Alert = styled.div`
  font-size: 14px;
  font-family: ${defaultTheme.fonts.primary};
  border: 2px solid;
  border-color: ${props => props.bg ? props.bg : 'information'};
  background-color: ${props => props.bg ? props.bg : 'information'};
  color: ${props => props.bg === '#C6C6C6' ? '#333' : '#FFF'};
  width: 100%;
  padding: 7px;
  margin: 5px;
  border-radius: 2px;
  text-align: center;
  line-height: normal;
  transition: all 0.2s linear;
`

export default function (props) {
  return (
    <Alert {...props} bg={defaultTheme.colors.ui[props.type]} />
  )
}
