import React, { Component } from 'react'
import DayPicker, { DateUtils } from 'react-day-picker'
import { monthNames, daysNames, shortDaysNames } from './config'
import 'react-day-picker/lib/style.css'
import './style.css'

export default class Datepicker extends Component {
  static defaultProps = {
    onChange: () => {},
    dates: []
  }

  state = {
    selectedDays: []
  }

  componentDidMount() {
    const { dates }= this.props
    this.setState({selectedDays: dates ? dates : []})
  }

  handleDayClick = (day, { selected }) => {
    const { selectedDays } = this.state
    if (selected) {
      const selectedIndex = selectedDays.findIndex(selectedDay => DateUtils.isSameDay(selectedDay, day))
      selectedDays.splice(selectedIndex, 1)
    } else {
      selectedDays.push(day)
    }
    this.setState({ selectedDays }, () => {
      this.props.onChange(this.state.selectedDays)
    })
  }

  render() {
    return (
      <div>
        <DayPicker
          {...this.props}
          month={this.props.dates && this.props.dates[0]}
          months={monthNames}
          weekdaysLong={daysNames}
          weekdaysShort={shortDaysNames}
          className='EvntsColor'
          placeholder={'Select dates'}
          onDayClick={this.handleDayClick}
          selectedDays={this.state.selectedDays} />
      </div>
    )
  }
}
