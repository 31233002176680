import React, { Component } from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../../../../styles/themes'
import Card from '../../../../components/ui/Card'
import Title from '../../../../components/ui/Title'

const Group = styled.div`
`

const LinkPedidos = styled.a`
text-transform: uppercase;
font-weight: 600;
color: ${defaultTheme.colors.grey.light};
font-size: 10px;
cursor: pointer;
  span {
    font-size: 12px;
  }
`

const Titulo = styled(Title)`
  font-size: 16px;
  margin: 0;
`

class CardDeGastos extends Component {
  render () {
    return (
      <Card style={{ width: '32%' }} >
        <Group style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '20px' }}>
          <Titulo>{this.props.tituloCard}</Titulo>
          {this.props.mostraVerTodos && <LinkPedidos
            onClick={this.props.statusModal}
          >Ver todos <span>➔</span></LinkPedidos>}
        </Group>
        <Group>
          {this.props.conteudoCard}
        </Group>
      </Card>
    )
  }
}

export default CardDeGastos
