import { combineReducers } from 'redux'
import auth from './auth'
import signUp from './signUp'
import user from './user'
import forms from './forms/index'
import briefing from './briefing'
import rooms from './rooms'
import budget from './budget'

const app = () => {
  return {
    language: 'pt_br'
  }
}

const rootReducer = combineReducers({
  app,
  auth,
  signUp,
  user,
  forms,
  briefing,
  rooms,
  budget
})

export default rootReducer
