/* LIBS */
import React, { Component } from 'react'
import Modal from '../../components/Modal'
import styled from 'styled-components'
import Title from '../../components/ui/Title'
import Button from '../../components/ui/Button'
import {defaultTheme} from '../../styles/themes'


const Titulo = styled(Title)`
  font-weight: 600;
  font-family: ${defaultTheme.fonts.secondary};
  margin-top: 20px;
  color: #484848;
`

const Group = styled.div``

const Lista = styled.ul`
  list-style-type: disc;
  padding: 20px;
  li {
    padding: 5px 0;
    font-family: ${defaultTheme.fonts.primary};
    font-size: 14px;
    color: #a5a5a5;
  }`

const NomeUsuario = styled.p`
  color: #767676;
  font-weight: 400;
  font-size: 12px;
`

class ModalListaCentroDeCustos extends Component {
  constructor () {
    super()
    this.state = {
      isOpen: false
    }
  }

  render () {
    const usuario = this.props.data
    const costCenters = usuario && usuario.costCenters
    return (
      <Modal maxWidth={'400px'} isOpen={this.props.isOpen} hasCloseButton onClose={() => this.props.onClose && this.props.onClose()}>
        <Group style={{ padding: '10px 30px', textAlign: 'left'}}>
          <NomeUsuario style={{textTransform: 'uppercase', color: 'rgb(165, 165, 165)'}} >Nome de Usuário:</NomeUsuario>
          <Title style={{ color: '#767676', fontSize: '16px', marginTop: '5px'}} >{usuario.name}</Title>
          <Titulo>Centros de Custo </Titulo>
          <Lista >
            {costCenters && costCenters.map((item) => <li>{item.name}</li>)}
          </Lista>
          <Button full onClick={this.props.fechaModal}>Ok</Button>
        </Group>
      </Modal>
    )
  }
}

export default ModalListaCentroDeCustos
