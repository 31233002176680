/* LIBS */
import React, { Component } from 'react'

import Modal from '../../../components/Modal'
import Subtitle from '../../../components/ui/Subtitle'
import MoneyInput from '../../../components/ui/MoneyInput'
import Button from '../../../components/ui/Button'
import formatCurrency from '../../../../utils/money'
import { renderizaNomeDoServico } from '../../../../utils/servicosFornecedor'

import { defaultTheme } from '../../../styles/themes'
import { Post } from '../../../../utils/request'
import config from '../../../../middleware/config'
import styled from 'styled-components'

const Group = styled.div`
  font-size: 14px;
`

const Titulo = styled.h1`
  font-family: ${defaultTheme.fonts.secondary};
  font-size: 16px;
  padding-top: 10px;
  font-weight: 600;
  text-align: left;
`
const Subtitulo = styled(Subtitle)`
  font-size: 14px;
  text-align: left;
  margin: 5px 0 0 0;
`

const LinkPedidos = styled.a`
  font-family: ${defaultTheme.fonts.primary};
  color: ${defaultTheme.colors.secondary.default};
  font-size: 14px;
  text-align: left;
  margin: 5px 0 0 0;
  text-decoration: underline;
  cursor: pointer;
  `

const Legenda = styled(Subtitle)`
  font-size: 14px;
  text-align: left;
  font-style: italic;
  color: #767676;
  margin: 0 0 10px 0;
`

class ModalConciliacao extends Component {
  constructor () {
    super()
    this.state = {
      isOpen: false,
      lodging: '',
      room: '',
      foodsAndBeverages: '',
      equipments: '',
      transfer: '',
      otherServices: {
        services: []
      },
      error: false
    }
  }

  submit = () => {
    const { api: { base, version } } = config
    const chooseSupplier = `${base}${version}/event/${this.props.dadosEvento.id}/update-final-prices`
    Post(chooseSupplier, { services: this.requestedServices(this.props.dadosEvento).map((service) => {
      if(service === 'otherServices') {
        return {
          service: 'otherServices',
          services: this.state[service].services
        }
      } else {
        return {
          service: service,
          finalPrice: this.state[service]
        }
      }
      })
    }).then(() => {
      alert('Conciliação criada com sucesso!')
      this.setState({ isLoading: false })
      this.props.onClose && this.props.onClose()
      window.location.reload()
    }).catch(erro => {
      this.setState({ isLoading: false }, () => {
        window.alert(erro)
        window.location.reload()
      })
    })
  }

  validate = (vetorServicos) => {
    const result = vetorServicos.filter((service) => this.state[service] === '')
    return result < vetorServicos.length ? true : false
  }

  pegaSomenteOsServicosDeOutrosServicos = (servico) => {
    const servicos = ['gifts', 'printShop', 'additionalServices']
    return servicos.find(service => service === servico)
  }

  salvaAlteracaoOutrosServicos = (event, service) => {
    const { otherServices } = this.state
    const otherServicesIndex = otherServices.services.findIndex(item => item.service === service)
    if(otherServicesIndex !== -1) {
      otherServices.services[otherServicesIndex].finalPrice = event.target.value
    } else {
      otherServices.services.push({
        service: service,
        finalPrice: event.target.value
      })
    }
    this.setState({ otherServices: otherServices })
  }

  pegaInformacaoDeOutrosServicos = (service) => {
    const { otherServices } = this.state
    const otherServicesIndex = otherServices.services.findIndex(item => item.service === service)
    if(otherServicesIndex !== -1) {
      return otherServices.services[otherServicesIndex].finalPrice
    } else return ''
  }

  errorMensagem = () => {
    this.setState({
      error: true
    }, () => alert('Preencha todos os serviços'))
  }

  requestedServices = (event) => event && event.services ? Object.keys(event.services).filter(service => event.services[service].requested) : []

  render () {
    const dados = this.props.dadosEvento
    const servicosSolicitados = this.requestedServices(dados)
    return (
      <Modal maxWidth={'700px'} isOpen={this.props.isOpen} hasCloseButton onClose={() => this.props.onClose && this.props.onClose()} >
        <Group style={{ padding: '10px' }}>
          <Titulo>Conciliação</Titulo>
          <Subtitulo>Preencha abaixo os valores finais (conciliados com os fornecedores) para cada categoria do evento.</Subtitulo>
        </Group>
        <Group style={{ display: 'flex', flexWrap: 'wrap', margin: '20px 0' }}>
          {servicosSolicitados.filter(service => service !== 'otherServices').map((service, index) => {
            const orcamentosDoServicos = dados.budgets[service]
            const orcamentosAceitosDoServico = orcamentosDoServicos && orcamentosDoServicos.filter((item) => item && item.status === 'ACCEPTED')
            const orcamentoEscolhido = orcamentosAceitosDoServico && orcamentosAceitosDoServico.length > 0 && orcamentosAceitosDoServico[0]
            return <Group key={index} style={{ width: '218px' }}>
              <MoneyInput
                isRequired
                label={`${renderizaNomeDoServico(service)}`}
                name={`${service}`}
                value={this.state[service]}
                onChange={(event) => this.setState({ [service]: event.target.value })}
                error={this.state.error && this.state[service] === ''}
              />
              {orcamentoEscolhido && orcamentoEscolhido.cheapestTotalPrice &&<Legenda style={{ paddingLeft: '10px' }}>{`Valor fechado: ${formatCurrency(orcamentoEscolhido.cheapestTotalPrice.amount)}`}</Legenda>}
            </Group>
          })}
          {dados.services && dados.services.otherServices.requested && Object.keys(dados.services.otherServices)
            .filter(this.pegaSomenteOsServicosDeOutrosServicos)
            .filter(servico => dados.services.otherServices[servico].requested)
            .map((service, index) => {
            const orcamentosDoServicos = dados.budgets.otherServices
            const orcamentosAceitosDoServico = orcamentosDoServicos && orcamentosDoServicos.filter((item) => item && item.status === 'ACCEPTED')
            const orcamentoEscolhido = orcamentosAceitosDoServico && orcamentosAceitosDoServico.length > 0 && orcamentosAceitosDoServico[0]
            const orcamentoEscolhidoPorServico = orcamentoEscolhido && orcamentoEscolhido.services[service]
            return <Group key={index} style={{ width: '218px' }}>
              <MoneyInput
                isRequired
                label={`${renderizaNomeDoServico(service)}`}
                name={`${service}`}
                value={this.pegaInformacaoDeOutrosServicos(service)}
                onChange={(event) => this.salvaAlteracaoOutrosServicos(event, service)}
                error={this.state.error && this.pegaInformacaoDeOutrosServicos(service) === ''}
              />
              {orcamentoEscolhidoPorServico && orcamentoEscolhidoPorServico.cheapestTotalPrice &&<Legenda style={{ paddingLeft: '10px' }}>{`Valor fechado: ${formatCurrency(orcamentoEscolhidoPorServico.cheapestTotalPrice.amount)}`}</Legenda>}
            </Group>})}
        </Group>
        <Group style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
          <LinkPedidos
            style={{ color: defaultTheme.colors.secondary.default }}
            href={`/organizer/event/${dados._id || dados.id}/resume/chosen`}
            target="_blank" rel='noopener noreferrer'
          >ver detalhes do pedido</LinkPedidos>
          <Button
            type='button'
            onClick={() => this.validate(servicosSolicitados) ? this.submit() : this.errorMensagem()}
            style={{ fontWeight: '600' }}>
            Concluir evento
          </Button>
        </Group>
      </Modal >
    )
  }
}

export default ModalConciliacao
