import React from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../../styles/themes'

const LinkCustom = styled.a`
  font-size: 14px;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 400;
  color: ${defaultTheme.colors.whiteLabel.primary};
  padding: 10px;
  text-decoration: underline;
  cursor: pointer;
`

export default function (props) {
  return (
    <LinkCustom {...props} />
  )
}
