import React, { Component } from 'react'

export default class ClockIcon extends Component {
  render () {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="223" height="185" viewBox="0 0 223 185">
        <defs>
          <clipPath id="clip-Artboard_1">
            <rect width="223" height="185"/>
          </clipPath>
        </defs>
        <g id="Artboard_1" data-name="Artboard – 1" clip-path="url(#clip-Artboard_1)">
          <rect width="223" height="185" fill="#fff"/>
          <g id="Group_4615" data-name="Group 4615" transform="translate(4.948 10.162)">
            <g id="Group_1937" data-name="Group 1937" transform="translate(0 0)">
              <g id="Group_1948" data-name="Group 1948" transform="translate(0)">
                <circle id="Ellipse_28" data-name="Ellipse 28" cx="3.336" cy="3.336" r="3.336" transform="translate(30.365 18.815)" fill="none" stroke="#762123" stroke-miterlimit="10" stroke-width="2"/>
                <circle id="Ellipse_29" data-name="Ellipse 29" cx="3.336" cy="3.336" r="3.336" transform="translate(189.404 49.628)" fill="none" stroke="#762123" stroke-miterlimit="10" stroke-width="2"/>
                <circle id="Ellipse_30" data-name="Ellipse 30" cx="3.336" cy="3.336" r="3.336" transform="translate(18.982 156.616)" fill="none" stroke="#762123" stroke-miterlimit="10" stroke-width="2"/>
                <g id="Group_1941" data-name="Group 1941" transform="translate(156.737 13.424)">
                  <line id="Line_262" data-name="Line 262" x1="8.402" transform="translate(0 4.201)" fill="none" stroke="#762123" stroke-miterlimit="10" stroke-width="2"/>
                  <line id="Line_263" data-name="Line 263" y2="8.402" transform="translate(4.201)" fill="none" stroke="#762123" stroke-miterlimit="10" stroke-width="2"/>
                </g>
                <g id="Group_1943" data-name="Group 1943" transform="translate(189.203 153.404)">
                  <line id="Line_266" data-name="Line 266" x1="8.464" transform="translate(0 4.201)" fill="none" stroke="#762123" stroke-miterlimit="10" stroke-width="2"/>
                  <line id="Line_267" data-name="Line 267" y2="8.402" transform="translate(4.201)" fill="none" stroke="#762123" stroke-miterlimit="10" stroke-width="2"/>
                </g>
                <g id="Group_1944" data-name="Group 1944" transform="translate(11.985 118.723) rotate(135)">
                  <line id="Line_268" data-name="Line 268" x1="5.993" y1="5.931" fill="none" stroke="#762123" stroke-miterlimit="10" stroke-width="2"/>
                  <line id="Line_269" data-name="Line 269" x1="5.993" y2="5.931" fill="none" stroke="#762123" stroke-miterlimit="10" stroke-width="2"/>
                </g>
                <g id="Group_1945" data-name="Group 1945" transform="translate(84.083)">
                  <path id="Path_315" data-name="Path 315" d="M202.449,12.955c-4.016-.988-2.595-6.981-6.611-7.97s-5.5,5-9.514,4.016-2.595-6.981-6.611-7.97-5.5,5-9.514,4.016" transform="translate(-170.2 -0.921)" fill="none" stroke="#762123" stroke-miterlimit="10" stroke-width="2"/>
                </g>
                <g id="Group_1946" data-name="Group 1946" transform="translate(0 66.216)">
                  <path id="Path_316" data-name="Path 316" d="M33.314,80.378c-4.139,0-4.139-6.178-8.279-6.178s-4.139,6.178-8.279,6.178S12.618,74.2,8.479,74.2,4.339,80.378.2,80.378" transform="translate(-0.2 -74.2)" fill="none" stroke="#762123" stroke-miterlimit="10" stroke-width="2"/>
                </g>
                <g id="Group_1947" data-name="Group 1947" transform="translate(179.936 101.32)">
                  <path id="Path_317" data-name="Path 317" d="M370.966,123.947c-3.583-2.1-.494-7.414-4.078-9.514s-6.672,3.274-10.256,1.174-.494-7.414-4.077-9.514-6.672,3.274-10.256,1.174" transform="translate(-342.3 -105.596)" fill="none" stroke="#762123" stroke-miterlimit="10" stroke-width="2"/>
                </g>
              </g>
              <g id="Group_4435" data-name="Group 4435" transform="translate(-724.885 -1276.802)">
                <path id="Path_491" data-name="Path 491" d="M880.817,1375.79a52.729,52.729,0,1,1-52.728-52.728A52.719,52.719,0,0,1,880.817,1375.79Z" transform="translate(1.13 1.13)" fill="none" stroke="#762123" stroke-width="4"/>
                <path id="Path_492" data-name="Path 492" d="M828.642,1343.687v36.886l19.37,13.827" transform="translate(0.578 -0.062)" fill="none" stroke="#762123" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}
