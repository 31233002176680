import React, { Component } from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../styles/themes'
import { version } from '../../version.json'

const Span = styled.span`
  color: #C0C0C0;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-size: 13px;
  font-weight: bold;
  padding: 2px 2px 2px 2px;
  text-align: ${props => props.align || 'center'};
  width: 100%;
`

export const VERSION = version

export default class VersionBuild extends Component {
  render () {
    return (
      <Span
        {...this.props}
        children={`versão: ${version}`}
      />
    )
  }
}
