import React, { Component } from 'react'
import styled from 'styled-components'
import { formatCurrency, removeCurrency } from '../../../../utils/money'
import {conflictOnDates, conflictOnTime} from '../../../../utils/dates'
/* COMPONENTS */
import Input from '../../../components/ui/Input'
import TextArea from '../../../components/ui/Textarea'
import {defaultTheme} from '../../../styles/themes'

const CRITICAL = 'CRITICAL'

const Lista = styled.li`
    display: inline-block;
    width: calc(100% / 3);
    font-weight: 500;
`

const Warning = styled.h2`
  margin-top: 20px;
  color: ${defaultTheme.colors.ui.negative};
`

const CardHotel = styled.div`
  border-radius: 2px;
  margin: 10px;
  box-shadow:
    ${props => props.selected
    ? `1px 2px 2px 2px rgba(48,47,47,.40),0 0 0 2px rgba(0,0,0,0.08)`
    : `0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08)`};
  min-width: 300px;
  background-color: #FFF;
  transition: box-shadow 0.3s linear;
  width: calc(33.33% - 25px);
  @media (max-width: 990px) {
    width: calc(50% - 25px);
  }
  @media (max-width: 720px) {
    width: 100%;
  }
`

const RoomPicture = styled.div`
  background-image: ${props => props.image ? `url(${props.image})` : ''};
  background-size: cover;
  height: 150px;
  background-color: #CCC;
  cursor: pointer;
`

const RoomFormCard = styled.div`
  padding: 10px;
`

const RoomFormCardInfo = styled.div`
  cursor: pointer;
  padding: 10px;
`

const RadioInput = styled.input`
`

const LabelInputAfter = styled.label`
  :after {
    content: "";
    width: 12px;
    height: 12px;
    background: #009688;
    position: absolute;
    right: 14px;
    top: 4px;
    -webkit-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;
}
`

const LabelInputBefore = styled.label`
`


/* =======================
  TODO
  A api deveria retornar junto com o briefing o tipo de sala como se fosse um id
  como ele não retorna dessa forma fiz esse switch e a funcao addTypeToBriefing para
  fazer isso
*/

export const typeOfRooms = (type) => {
  switch (type) {
    case 'Auditorio':
      return 'auditorium'
    case 'Auditório':
      return 'auditorium'
    case 'Escolar':
      return 'classroom'
    case 'Coquetel':
      return 'cocktail'
    case 'Espinha de Peixe':
      return 'fishbone'
    case 'Mesa Redonda':
      return 'round_table'
    case 'Formato U':
      return 'u_format'
    default:
      return ''
  }
}

const addTypeToBriefing = (briefing) => {
  return { ...briefing, type: typeOfRooms(briefing.roomType) }
}
/* ======================= */

class SupplierRooms extends Component {
  state = { selected: false }

  componentWillReceiveProps({briefing, budget}) {
    if (budget) {
      const selected = budget.find(item => item.roomBriefingId === briefing.id)
      if (selected) {
        this.setState({selected: selected.roomId})
      }
    }
  }

  toggle = (id) => {
    this.setState({ selected: id })
  }


  getRooms = ({ rooms, briefing }) => {
    briefing = addTypeToBriefing(briefing)
    return rooms.filter(room => {
      return Number(room.capacity[briefing.type]) >= Number(briefing.amountOfPeople)
    }).map(room => {
      return { ...room, capacity: room.capacity[briefing.type], mounting: briefing.mounting }
    })
  }

  alreadySentToAnotherWithConflit = (roomId) => {
    let { briefing, budget, checkBudget } = this.props
    if(budget.roomsBudget) {
      return budget.roomsBudget && budget.roomsBudget
        .some(item =>
          this.props.budget.roomsBudget[item.roomBriefingId].roomId === roomId
          && item.roomBriefingId !== briefing.id
          && this.hasDateAndTimeConflict(item.roomBriefingId, briefing.id)
        )
    } else if(checkBudget && checkBudget.roomsBudget) {
      return Object.keys(checkBudget.roomsBudget)
      .some(briefingId =>
        this.props.checkBudget.roomsBudget[briefingId].roomId === roomId
        && briefingId !== briefing.id
        && this.hasDateAndTimeConflict(briefingId, briefing.id)
      )
    }
  }

   hasDateAndTimeConflict(briefingId, anotherBriefingId) {
     if(!briefingId || !anotherBriefingId) return false
     let { briefingRoomsInfo } = this.props
     const briefing1 = briefingRoomsInfo.find(briefing => briefing.id === briefingId)
     const briefing2 = briefingRoomsInfo.find(briefing => briefing.id === anotherBriefingId)
     return conflictOnDates(briefing1, briefing2) && conflictOnTime(briefing1, briefing2)
  }

  render() {
    const { rooms, briefing, error, budget, differences, lastUpdateType } = this.props
    const roomsData = (rooms && briefing)
      ? this.getRooms({ rooms, briefing })
      : null
    const budgetData = budget.find(item => item.roomBriefingId === briefing.id)
    return (
      roomsData ? roomsData.map((room, i) => {
        return (
          <RoomForm
            dateConflict={this.alreadySentToAnotherWithConflit(room._id)}
            key={room._id}
            room={room}
            i={i}
            error={error}
            briefing={briefing.roomType}
            budget={budgetData}
            selected={room._id === this.state.selected}
            differences={differences}
            lastUpdateType={lastUpdateType}
            onClick={() => this.toggle(room._id)} />
        )
      }) : null
    )
  }
}


class RoomForm extends Component {
  state = { amount: '', mounting: '', observations: '' }

  componentDidMount() {
    const { budget } = this.props
    if (budget) {
      const { pricePerDay, mountingPrice, observations, roomId } = budget
      if (roomId === this.props.room._id) {
        this.setState({
          amount: pricePerDay,
          mounting: mountingPrice,
          observations
        })
      }
    }
  }
  componentWillReceiveProps(props) {
    const { budget } = props
    if (budget) {
      const { pricePerDay, mountingPrice, observations, roomId } = budget
      if (roomId === this.props.room._id) {
        this.setState({
          amount: pricePerDay.amount,
          mounting: mountingPrice.amount,
          observations
        })
      }
    }

  }

  handleValue = ({target: {name, value}}) => {
    this.setState({[name]: value})
  }

  render() {
    const { room, i, selected, onClick, dateConflict, lastUpdateType, budget } = this.props
    const { amount, mounting, observations } = this.state
    return (
      <CardHotel selected={selected}>
        <RoomPicture
          image={room.photos[0]}
          onClick={onClick} />
        <RoomFormCardInfo onClick={onClick}>
          {!dateConflict && <div>
            <RadioInput
              style={{ display: 'none' }}
              type='radio'
              name='room'
              id={`selecao_${i}`}
              value={room._id}
              checked={selected} />
            {selected &&<LabelInputAfter htmlFor={`selecao_${i}`}>{room.name}</LabelInputAfter>}
            {!selected && <LabelInputBefore htmlFor={`selecao_${i}`}>{room.name}</LabelInputBefore>}
          </div>}
          <div>
            <Lista>Área:</Lista>
            <Lista>Pé Direito:</Lista>
            <Lista>Cap. Max.</Lista>
            <Lista>{`${room.footage}m²`}</Lista>
            <Lista>{`${room.rooms_height}m`}</Lista>
            <Lista>{room.capacity} pessoas</Lista>
          </div>
          {dateConflict &&
            <Warning> Sala já selecionada para este evento em mesma data e horário.</Warning>
          }
        </RoomFormCardInfo>
        {(selected && !dateConflict) ?
          <RoomFormCard>
            <Input
              type={'text'}
              label='Valor da Sala (por dia)'
              placeholder='R$ 0,00'
              name={`amount`}
              error={!amount || removeCurrency(amount) <= 0}
              value={formatCurrency(amount)}
              onChange={this.handleValue}
              criticalUpdate={lastUpdateType === CRITICAL && (budget && budget.id)}
              criticalPreviouslyValue={formatCurrency(amount)}
              isRequired
            />
            {room.mounting ? <Input
              type={'text'}
              label='Valor da Sala para o dia de montagem'
              placeholder='R$ 0,00'
              name={`mounting`}
              onChange={this.handleValue}
              value={formatCurrency(mounting)}
              criticalUpdate={lastUpdateType === CRITICAL && (budget && budget.id)}
              criticalPreviouslyValue={formatCurrency(mounting)}
            /> : null}
            <TextArea
              label='Observações'
              placeholder='Caso tenha alguma observação escreva aqui'
              name={`observations`}
              value={observations}
              onChange={this.handleValue}
              criticalUpdate={lastUpdateType === CRITICAL && (budget && budget.id)}
              criticalPreviouslyValue={observations}
            />
          </RoomFormCard>
          : null}
      </CardHotel>
    )
  }
}
export default SupplierRooms
