import React from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../../styles/themes'

const SwitchBox = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  min-height: 18px;
  position: relative;
  width: 100%;
`

const Input = styled.input`
  opacity: 0;
  width: 30px;
`

const ToggleButton = styled.span`
  border: 3px solid #ccc;
  border-radius: 24px;
  box-sizing: border-box;
  cursor: pointer;
  height: 20px;
  position: absolute;
  right: 0;
  transition: .4s;
  width: 33px;
  -webkit-transition: .4s;
  &:before {
    background-color: white;
    border: 3px solid #ccc;
    border-radius: 50%;
    bottom: -2px;
    box-sizing: border-box;
    content: "";
    height: 18px;
    left: -3px;
    position: absolute;
    transition: .4s;
    width: 18px;
    -webkit-transition: .4s;
  }

  input:checked + & {
    background-color: ${defaultTheme.colors.primary.default};
    border: 3px solid ${defaultTheme.colors.primary.default};
  }

  input:focus + & {
    box-shadow: 0 0 1px ${defaultTheme.colors.primary.default};
  }

  input:checked + &:before {
    border: 3px solid ${defaultTheme.colors.primary.default};
    transform: translateX(15px);
    -ms-transform: translateX(15px);
    -webkit-transform: translateX(15px);
  }
`

const ToggleLabel = styled.label`
  box-sizing: border-box;
  color: #484848;
  cursor: pointer;
  display: flex;
  font-family: ${defaultTheme.fonts.secondary};
  font-size: 12px;
  font-weight: 300;
  justify-content: space-between;
  width: calc(100% - 35px);
`

export default function (props) {
  const name = props.name || props.id

  return (
    <SwitchBox onClick={() => { props && props.onClick && (!props.disabled) && props.onClick() }}>
      <ToggleLabel htmlFor={name}>{props.label}</ToggleLabel>
      <Input
        disabled={props.disabled}
        name={name}
        defaultValue={props.value}
        defaultChecked={props.checked}
        type={props.unique ? 'radio' : 'checkbox'}
      />
      <ToggleButton htmlFor={name} />
    </SwitchBox>
  )
}
