const types = () => {
  return [
    { display: 'Adesão', value: 'ADESÃO' },
    { display: 'Competição', value: 'COMPETIÇÃO' },
    { display: 'Confraternização', value: 'CONFRATERNIZAÇÃO' },
    { display: 'Congresso', value: 'CONGRESSO' },
    { display: 'Day Use', value: 'DAY USE' },
    { display: 'Diversos', value: 'DIVERSOS' },
    { display: 'Encontros', value: 'ENCONTROS' },
    { display: 'Exclusivo', value: 'EXCLUSIVO' },
    { display: 'Feiras', value: 'FEIRA' },
    { display: 'Fórum', value: 'FÓRUM' },
    { display: 'Grupo de Hospedagem', value: 'GRUPO DE HOSPEDAGEM' },
    { display: 'Implantação', value: 'IMPLANTAÇÃO' },
    { display: 'Incentivo', value: 'INCENTIVO' },
    { display: 'Logística de Palestrante', value: 'LOGÍSTICA DE PALESTRANTE' },
    { display: 'Mini Meeting', value: 'MINI MEETING' },
    { display: 'Passeio', value: 'PASSEIO' },
    { display: 'Patrocínio', value: 'PATROCÍNIO' },
    { display: 'Premiação', value: 'PREMIAÇÃO' },
    { display: 'Programa de Trainees', value: 'PROGRAMA TRAINEES' },
    { display: 'Relacionamento', value: 'RELACIONAMENTO' },
    { display: 'Seminário', value: 'SEMINÁRIO' },
    { display: 'Show Room', value: 'SHOW ROOM' },
    { display: 'Treinamento', value: 'TREINAMENTO' },
    { display: 'Visitas', value: 'VISITAS' },
    { display: 'Workshop', value: 'WORKSHOP' }
  ]
}

export { types }
