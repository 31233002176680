import React from 'react'
import styled from 'styled-components'

const Main = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #FAFAFA;
  padding: 0px 10px;
  height: inherit;
  overflow-y: auto;
  @media (min-width: 600px) {
    background-color: #FAFAFA;
    flex-wrap: nowrap;
  }
`

export default function (props) {
  return (
    <Main {...props}>
    </Main>
  )
}
