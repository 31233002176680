import React, { Component } from 'react'
import AprovacaoIcon from '../ui/AprovacaoIcon'
import Modal from '../Modal'
import Title from '../ui/Title'
import Button from '../ui/Button'

class ModalConfirmacao extends Component {

  render () {
    return (
      <Modal  isOpen={this.props.isOpen}
          onClick={(event) => {
          event.preventDefault && event.preventDefault()
          event.stopPropagation && event.stopPropagation()
        }}>
        <AprovacaoIcon />
        <Title style={{ paddingTop: '10px' }}>Fornecedor aprovado!</Title>
        <Button
          onClick={(event) => {
            event.stopPropagation && event.stopPropagation();
            this.props.onSubmit && this.props.onSubmit(event)
            }
          }
          category={'secondary'}
          fontWeight={800}
          width={'50%'}
          full>
          Ok
        </Button>
      </Modal>
    )
  }
}


export default ModalConfirmacao
