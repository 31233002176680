import React, { Component } from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../../styles/themes'

const Count = styled.div`
  align-items: center;
  background-color: red;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: ${defaultTheme.fonts.primary};
  font-size: 9px;
  font-weight: 600;
  height: 18px;
  justify-content: center;
  margin-left: -12px;
  margin-top: -5px;
  width: 18px;
`

export default class Unread extends Component {
  render () {
    const { count, displayTitle, title } = this.props
    var display = Number(count)
    var realCount

    if (Number.isNaN(Number(count))) return ''
    if (Number(count) > 99) {
      display = '99+'
      realCount = count
    }

    return <Count style={this.props.style || {}} title={displayTitle && (title || realCount)}>{display}</Count>
  }
}
