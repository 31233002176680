/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
import * as moment from 'moment'
import jwtDecode from 'jwt-decode'
/* API */
import config from '../../../middleware/config'
/* STYLES */
import { defaultTheme } from '../../styles/themes'
/* COMPONENTS */
import Header from '../Header'
import Button from '../../components/ui/Button'
import Input from '../../components/ui/Input'
import DragnDrop from '../../components/ui/DragnDrop'
import Card from '../../components/ui/Card'
import Loader from '../../components/Loader'
import EventCard from './EventCard'
import Tabs from '../../components/ui/Tabs'
import Main from '../../components/Main'
import Body from '../../components/Body'
import Subtitle from '../../components/ui/Subtitle'
import AlertIcon from '../../components/ui/AlertIcon'
import Modal from '../../components/Modal'
import Icons from '../../components/ui/Icons'
/* UTILS */
import { Get, Post } from '../../../utils/request'
import { formatDate } from '../../../utils/dates'
import { temFeatureFlag } from '../../../utils/tiposUsuario'
import { ehConsultor, ehAdmin, ehChainUser, ehOrganizador } from '../../../utils/tiposUsuario'

import ModalConciliacao from '../Events/Conciliacao'

const ORDERED = "ORDERED"
const DELETED = "DELETED"

const Text = styled.p`
  font-family: ${defaultTheme.fonts.primary};
  color: ${defaultTheme.colors.text.dark};
  font-weight: 300;
  padding-top: 30px;
`
const EventList = styled.div`
  padding: 12px;
`

const MainMobile = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #FAFAFA;
  @media (min-width: 768px) {
    background-color: #FAFAFA;
  }
`

const Title = styled.h1`
  font-family: ${defaultTheme.fonts.secondary};
  line-height: 1.2;
  font-size: 1.3em;
  margin-top: auto;
  margin-bottom: 20px;
`

const ContentMobile = styled.div`
  min-height: 90vh;
  padding: 10px 20px;
  font-family: ${defaultTheme.fonts.secondary};
  width: 100%;
`

const Group = styled.div`
  height: inherit;
  overflow-y: auto;
`

const PoweredBy = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  text-align: right;
  background-color: #fff;
  height: 20px;
  border: none;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
`

const Label = styled.label`
  cursor: pointer;
  color: #a5a5a5;
  padding-right: 10px;
  font-family: ${defaultTheme.fonts.primary};
  font-size: 12px;
`

class Events extends Component {
  constructor() {
    super()
    this.state = {
      events: [],
      current: [],
      finished: [],
      conciliacao: [],
      pendentes: [],
      aprovados: [],
      pendentesAprovacao: [],
      concluidos: [],
      isLoading: false,
      avisaModal: document.documentMode || /Edge/.test(navigator.userAgent),
      statusModalConciliacao: false,
      dadosEvento: '',
      filtro: '',
      chainModal: false,
      requestCode: '',
      modalNumeroDeRequisicao: false,
      modalOrdemDeCompra: false,
      files: {
        localDoEvento: {
          files: []
        },
        transfer: {
          files: []
        },
        otherServices: {
          files: []
        }
      },
      supplierInfo: {}
    }
  }

  dadosModalConciliacao = (dados) => {
    this.setState({
      dadosEvento: dados,
      statusModalConciliacao: !this.state.statusModalConciliacao
    })
  }

  dadosModalNumeroDeRequisicao = (dados) => {
    this.setState({ isLoading: true }, () => {
      const { api: { base, version, routes: { events } } } = config
      var endpoint = `${base}${version}${events}/${dados.id}/approval/supplier`
      Get(endpoint)
        .then((supplierInfo) => {
          this.setState({
            dadosEvento: dados,
            supplierInfo: supplierInfo,
            modalNumeroDeRequisicao: true,
            isLoading: false
          })
        })
        .catch(err => {
          this.setState({ isLoading: false })
          console.error(err)
          alert(`Erro ao obter os seus eventos, favor entrar em contato com o suporte técnico (received: ${err})`)
        })
    })
  }

  dadosModalOrdemDeCompra = (dados) => {
    this.setState({ isLoading: true }, () => {
      const { api: { base, version, routes: { events } } } = config
      var endpoint = `${base}${version}${events}/${dados.id}/approval/supplier`
      Get(endpoint)
        .then((supplierInfo) => {
          this.atualizaArquivos(dados)
          this.setState({
            dadosEvento: dados,
            supplierInfo: supplierInfo,
            modalOrdemDeCompra: true,
            isLoading: false
          })
        })
        .catch(err => {
          this.setState({ isLoading: false })
          console.error(err)
          alert(`Erro ao obter os seus eventos, favor entrar em contato com o suporte técnico (received: ${err})`)
        })
    })
  }

  atualizaArquivos = (dados) => {
    const { files } = this.state
    if (dados.approval && dados.approval.services) {
      var localDoEvento = []
      if (dados.approval.services.room && dados.approval.services.room.purchaseOrder) {
        localDoEvento = dados.approval.services.room.purchaseOrder.files || []
      } else if (dados.approval.services.lodging && dados.approval.services.lodging.purchaseOrder) {
        localDoEvento = dados.approval.services.lodging.purchaseOrder.files || []
      } else if (dados.approval.services.foodsAndBeverages && dados.approval.services.foodsAndBeverages.purchaseOrder) {
        localDoEvento = dados.approval.services.foodsAndBeverages.purchaseOrder.files || []
      } else if (dados.approval.services.equipments && dados.approval.services.equipments.purchaseOrder) {
        localDoEvento = dados.approval.services.equipments.purchaseOrder.files || []
      }
      files.localDoEvento.files = localDoEvento
      files.transfer.files = (dados.approval.services.transfer && dados.approval.services.transfer.purchaseOrder) ?
        (dados.approval.services.transfer.purchaseOrder.files || []) :
        []
      files.otherServices.files = (dados.approval.services.otherServices && dados.approval.services.otherServices.purchaseOrder) ?
        (dados.approval.services.otherServices.purchaseOrder.files || []) :
        []
    }
    this.setState({ files })
  }

  ativaSolicitaHomologacao = (supplierId, homologado) => {
    this.setState({
      isLoading: true,
      modalOrdemDeCompra: false
    }, () => {
      const { api: { base, version, routes: { homologation, organizer } } } = config
      const endpoint = `${base}${version}${organizer}${homologation}/${homologado ? 'activate' : 'request'}`
      const data = { supplierId, eventId: this.state.dadosEvento.id }

      Post(endpoint, data).then(() => {
        window.location.reload()
      }).catch(err => {
        console.error(err.message)
        this.setState({ isLoading: false, modalOrdemDeCompra: true })
        alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
      })
    })
  }

  confirmarRequisicao = () => {
    this.setState({
      isLoading: true,
      modalNumeroDeRequisicao: false
    }, () => {
      const { api: { base, version, routes: { events } } } = config
      const endpoint = `${base}${version}${events}/${this.state.dadosEvento && this.state.dadosEvento.id}/approval/request-code`
      Post(endpoint, { requestCode: this.state.requestCode })
        .then(() => {
          window.location.reload()
        }).catch(err => {
          console.error(err.message)
          this.setState({ isLoading: false, modalNumeroDeRequisicao: true })
          alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
        })
    })
  }

  confirmarOrdemDeCompra = () => {
    this.setState({
      isLoading: true,
      modalOrdemDeCompra: false
    }, () => {
      const { api: { base, version, routes: { events } } } = config
      const endpoint = `${base}${version}${events}/${this.state.dadosEvento && this.state.dadosEvento.id}/approval/add-purchase-order`
      Post(endpoint, this.generateJSON())
        .then(() => {
          window.location.reload()
        }).catch(err => {
          console.error(err.message)
          this.setState({ isLoading: false, modalOrdemDeCompra: true })
          alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
        })
    })
  }

  generateJSON = () => {
    const services = this.state.supplierInfo
    Object.keys(services).forEach(servico => {
      if(servico === 'transfer') {
        services[servico] = {
          files: this.state.files.transfer.files
        }
        if (this.state.files.transfer.files.length === 0) {
          delete services[servico]
        }
      } else if (servico === 'otherServices') {
        services[servico] = {
          files: this.state.files.otherServices.files
        }
        if (this.state.files.otherServices.files.length === 0) {
          delete services[servico]
        }
      } else {
        services[servico] = {
          files: this.state.files.localDoEvento.files
        }
        if (this.state.files.localDoEvento.files.length === 0) {
          delete services[servico]
        }
      }
    })
    return { services }
  }

  finalizarEvento = () => {
    this.setState({
      isLoading: true,
      modalOrdemDeCompra: false
    }, () => {
      const { api: { base, version, routes: { events } } } = config
      var endpoint = `${base}${version}${events}/${this.state.dadosEvento && this.state.dadosEvento.id}/approval/add-purchase-order`
      Post(endpoint, this.generateJSON())
        .then(() => {
          endpoint = `${base}${version}${events}/${this.state.dadosEvento && this.state.dadosEvento.id}/approval/finish-order`
          Post(endpoint)
            .then(() => {
              window.location.reload()
            }).catch(err => {
              console.error(err.message)
              this.setState({ isLoading: false, modalOrdemDeCompra: true })
              alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
            })
        }).catch(err => {
          console.error(err.message)
          this.setState({ isLoading: false, modalOrdemDeCompra: true })
          alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
        })
    })
  }

  fechaModalConciliacao = () => {
    this.setState({
      statusModalConciliacao: !this.state.statusModalConciliacao
    })
  }

  verifaRole = (nivel) => {
    const roles = window.localStorage.getItem('user_roles') !== 'undefined' && JSON.parse(window.localStorage.getItem('user_roles'))
    return roles && roles.find(role => role === nivel)
  }

  componentDidMount() {
    this.fetchEvents()
  }

  efetuaFiltro = (input) => this.setState({ filtro: input.target.value })

  fetchEvents = () => {
    this.setState({ isLoading: true })
    const { api: { base, version, routes: { events } } } = config
    const token = window.localStorage.getItem('user_token')
    const userType = window.localStorage.getItem('user_type')
    var endpoint = `${base}${version}${events}/user/${jwtDecode(token).userId}`
    if(userType === 'RESELLER') {
      endpoint = `${base}${version}/consultant/event`
    }
    if(!window.localStorage.getItem('theme_color_primary')) {
      this.props.history.push('/organizer/event')
      const type = window.localStorage.getItem('user_type')
      window.localStorage.removeItem('user_token')
      type === 'ORGANIZER' || type === 'RESELLER' ? this.props.history.push('/organizer/signin') : this.props.history.push('/signin')
    }
    if(defaultTheme.colors.whiteLabel.primary !== window.localStorage.getItem('theme_color_primary')) {
      return window.location.reload()
    }
    Get(endpoint)
      .then((events) => {
        const finished = events.filter(e => e.status === ORDERED && e.finalPrice)
        const pendentes = events.filter(e => e.approval && e.approval.status === 'AWAITING_REQUEST_CODE')
        const pendentesAprovacao = events.filter(e => e.approval && e.approval.status === 'AWAITING_APPROVAL')
        const aprovados = events.filter(e => e.approval && (e.approval.status === 'AWAITING_REQUEST_CODE' || e.approval.status === 'AWAITING_PURCHASE_ORDER'))
        const concluidos = events.filter(e => e.approval && e.approval.status === 'COMPLETED')
        const conciliacao = events.filter(e => e.status === ORDERED && !e.finalPrice)
        const past = events.filter(e => e.status === DELETED ||( moment(e.start) < moment() && e.status !== ORDERED))
        const current = events.filter(e => {
          return (
            moment(e.start) > moment() &&
            (e.status !== ORDERED || Object.keys(e.services).some(item => (e.services[item].requested && !e.services[item].id))) &&
            e.status !== DELETED
          )
        })

        this.setState({ current, pendentes, aprovados, pendentesAprovacao, concluidos, finished, conciliacao, events, past, isLoading: false })
      })
      .catch(err => {
        this.setState({ isLoading: false })
        console.error(err)
        alert(`Erro ao obter os seus eventos, favor entrar em contato com o suporte técnico (received: ${err})`)
      })

  }

  filtraPorEmergencial = (eventoA, eventoB) => {
    return ((eventoA.emergency && eventoA.emergency.isEmergency) && (eventoB.emergency && eventoB.emergency.isEmergency)) ? 0 : (eventoA.emergency && eventoA.emergency.isEmergency) ? -1 : 1
  }

  uploadDeArquivo = (files, servico) => {
    files.forEach(async file => {
      const form = new FormData()
      form.append('image', file)
      await this.setState({ isLoading: true }, () => {
        const { api: { base, version } } = config
        const token = window.localStorage.getItem('user_token')
        fetch(`${base}${version}/upload`, {
          method: 'POST',
          headers: {
            'Authorization': token
          },
          body: form
        })
        .then((response) => {
          if (response.ok) {
            return response.json()
          } else {
            throw new Error(response)
          }
        })
        .then(response => {
          if (response.success) {
            var { files } = this.state
            files[servico].files.push({
              file: file,
              url: response.data.file.url
            })
            this.setState({ files, isLoading: false })
          } else {
            console.error(response)
          }
        })
        .catch(e => {
          this.setState({ isLoading: false })
          console.error(e)
        })
      })
    })
  }

  onFileDialogCancel = (servico) => {
    const { files } = this.state
    files[servico].files = []
    this.setState({ files })
  }

  removerUpload = (fileIndex, servico) => {
    var { files } = this.state
    files[servico].files.splice(fileIndex, 1)
    this.setState({ files })
  }

  geraTabs = () => {
    const { current, finished, pendentes, aprovados, pendentesAprovacao,                                               concluidos, conciliacao, past } = this.state
    const ehConsultorAdmin = ehAdmin() && ehConsultor()
    if (ehChainUser()) {
      if (ehChainUser() && ehOrganizador())
        document.title = "Espaços para Eventos"
      var listaDeConcluidos = conciliacao.filter(event => event.name.includes(this.state.filtro)).concat(finished.filter(event => event.name.includes(this.state.filtro)))
      return [
        {
          id: 0,
          title: 'próximos eventos',
          content: () => {
            return (current.length > 0 ? current.filter(event => event.name.includes(this.state.filtro)).sort(this.filtraPorEmergencial).map((event, i) => <EventCard opcoes {...event} key={event._id || event.id} delegarConsultor={ehConsultorAdmin} />)
            : <Text>Nenhum evento em andamento.</Text>)
          }
        },
        {
          id: 1,
          title: 'eventos concluídos',
          qtd: conciliacao.length > 0 ? conciliacao.length : 0,
          content: () => {
            return (listaDeConcluidos.length > 0 ? listaDeConcluidos.sort(this.filtraPorEmergencial).map((event) => <EventCard {...event} key={event._id || event.id} />)
              : <Text>Nenhum evento em andamento.</Text>)
          }
        },
        {
          id: 2,
          title: 'eventos expirados',
          content: () => {
            return (past.length > 0 ? past.filter(event => event.name.includes(this.state.filtro)).sort(this.filtraPorEmergencial).map((event, i) => <EventCard {...event} key={event._id || event.id} delegarConsultor={ehConsultorAdmin} />)
            : <Text>Nenhum evento encontrado.</Text>)
          }
        },
      ]
    } else {
      if (this.verifaRole('APPROVER')) {
        return [
          {
            id: 0,
            title: 'pendentes de aprovação',
            qtd: pendentesAprovacao.length > 0 ? pendentesAprovacao.length : 0,
            content: () => {
              return (pendentesAprovacao.length > 0 ? pendentesAprovacao.filter(event => event.name.includes(this.state.filtro)).sort(this.filtraPorEmergencial).map((event, i) => <EventCard opcoes {...event} key={event._id || event.id} delegarConsultor={ehConsultorAdmin} />)
              : <Text>Nenhum evento em andamento.</Text>)
            }
          },
          {
            id: 1,
            title: 'aprovados',
            qtd: aprovados.length > 0 ? aprovados.length : 0,
            content: () => {
              return (aprovados.length > 0 ? aprovados.filter(event => event.name.includes(this.state.filtro)).sort(this.filtraPorEmergencial).map((event) => <EventCard ordemDeCompra={(event.approval && event.approval.status === 'AWAITING_PURCHASE_ORDER')} dadosModalOrdemDeCompra={this.dadosModalOrdemDeCompra} {...event} key={event._id || event.id} delegarConsultor={ehConsultorAdmin} />)
                : <Text>Nenhum evento aprovado.</Text>)
            }
          },
          {
            id: 2,
            title: 'concluidos',
            content: () => {
              return (concluidos.length > 0 ? concluidos.filter(event => event.name.includes(this.state.filtro)).sort(this.filtraPorEmergencial).map((event) => <EventCard {...event} key={event._id || event.id} delegarConsultor={ehConsultorAdmin} />)
                : <Text>Nenhum evento em andamento.</Text>)
            }
          }
        ]
      } else if (temFeatureFlag('approval')) {
        return [
          {
            id: 0,
            title: 'próximos eventos',
            content: () => {
              const filtrado = current.filter(event => {
                return (
                  (!event.approval || (event.approval && (event.approval.status === 'INCOMPLETE' || event.approval.status === 'AWAITING_APPROVAL' || event.approval.status === 'AWAITING_PURCHASE_ORDER'))) &&
                  event.name.includes(this.state.filtro)
                )
              }).sort(this.filtraPorEmergencial)

              if (filtrado.length === 0) return <Text>Nenhum evento em andamento.</Text>
              return filtrado.map(event => <EventCard opcoes {...event} key={event._id || event.id} delegarConsultor={ehConsultorAdmin} />)
            }
          },
          {
            id: 1,
            title: 'pendentes de nº requisição',
            qtd: pendentes.length > 0 ? pendentes.length : 0,
            content: () => {
              return (pendentes.length > 0 ? pendentes.filter(event => event.name.includes(this.state.filtro)).sort(this.filtraPorEmergencial).map((event) => <EventCard numeroDeRequisicao dadosModalNumeroDeRequisicao={this.dadosModalNumeroDeRequisicao} {...event} key={event._id || event.id} delegarConsultor={ehConsultorAdmin} />)
                : <Text>Nenhum evento pendente de nº de requisição.</Text>)
            }
          },
          {
            id: 2,
            title: 'pendentes de conciliação',
            qtd: conciliacao.length > 0 ? conciliacao.length : 0,
            content: () => {
              return (conciliacao.length > 0 ? conciliacao.filter(event => event.name.includes(this.state.filtro)).sort(this.filtraPorEmergencial).map((event) => <EventCard dadosModalConciliacao={this.dadosModalConciliacao} {...event} key={event._id || event.id} delegarConsultor={ehConsultorAdmin} />)
                : <Text>Nenhum evento em andamento.</Text>)
            }
          },
          {
            id: 3,
            title: 'eventos concluídos',
            content: () => {
              return (finished.length > 0 ? finished.filter(event => event.name.includes(this.state.filtro)).sort(this.filtraPorEmergencial).map((event, i) => <EventCard dadosModalConciliacao={this.dadosModalConciliacao} {...event} key={event._id || event.id} />)
                : <Text>Nenhum evento em andamento.</Text>)
            }
          },
          {
            id: 4,
            title: 'eventos expirados',
            content: () => {
              return (past.length > 0 ? past.filter(event => event.name.includes(this.state.filtro)).sort(this.filtraPorEmergencial).map((event, i) => <EventCard {...event} key={event._id || event.id} delegarConsultor={ehConsultorAdmin} />)
              : <Text>Nenhum evento encontrado.</Text>)
            }
          }
        ]
      } else {
        return [
          {
            id: 0,
            title: 'próximos eventos',
            content: () => {
              return (current.length > 0 ? current.filter(event => event.name.includes(this.state.filtro)).sort(this.filtraPorEmergencial).map((event, i) => <EventCard opcoes {...event} key={event._id || event.id} delegarConsultor={ehConsultorAdmin} />)
              : <Text>Nenhum evento em andamento.</Text>)
            }
          },
          {
            id: 1,
            title: 'pendentes de conciliação',
            qtd: conciliacao.length > 0 ? conciliacao.length : 0,
            content: () => {
              return (conciliacao.length > 0 ? conciliacao.filter(event => event.name.includes(this.state.filtro)).sort(this.filtraPorEmergencial).map((event) => <EventCard dadosModalConciliacao={this.dadosModalConciliacao} {...event} key={event._id || event.id} delegarConsultor={ehConsultorAdmin} />)
                : <Text>Nenhum evento em andamento.</Text>)
            }
          },
          {
            id: 2,
            title: 'eventos concluídos',
            content: () => {
              return (finished.length > 0 ? finished.filter(event => event.name.includes(this.state.filtro)).sort(this.filtraPorEmergencial).map((event, i) => <EventCard dadosModalConciliacao={this.dadosModalConciliacao} {...event} key={event._id || event.id} />)
                : <Text>Nenhum evento em andamento.</Text>)
            }
          },
          {
            id: 3,
            title: 'eventos expirados',
            content: () => {
              return (past.length > 0 ? past.filter(event => event.name.includes(this.state.filtro)).sort(this.filtraPorEmergencial).map((event, i) => <EventCard {...event} key={event._id || event.id} delegarConsultor={ehConsultorAdmin} />)
              : <Text>Nenhum evento encontrado.</Text>)
            }
          },
        ]
      }
    }
  }

  mostraLocalDoEvento = (services) => {
    return services ? Object.keys(services).filter(service => service !== 'transfer' && service !== 'otherServices').map(service => this.mostraNomeDoServico(service)) : []
  }

  mostraRazaoSocialLocalDoEvento = (services) => {
    const servico = services && Object.keys(services).filter(service => service !== 'transfer' && service !== 'otherServices').find(service => !!services[service].supplierId)
    return services[servico] ? services[servico].profile && services[servico].profile.full_name : ''
  }

  mostraIDLocalDoEvento = (services) => {
    const servico = services && Object.keys(services).filter(service => service !== 'transfer' && service !== 'otherServices').find(service => !!services[service].supplierId)
    return services[servico].supplierId
  }

  mostraCNPJlLocalDoEvento = (services) => {
    const servico = services && Object.keys(services).filter(service => service !== 'transfer' && service !== 'otherServices').find(service => !!services[service].supplierId)
    return services[servico] ? services[servico].profile && services[servico].profile.document : ''
  }

  mostraHomologacaoLocalDoEvento = (services) => {
    const servico = services && Object.keys(services).filter(service => service !== 'transfer' && service !== 'otherServices').find(service => !!services[service].supplierId)
    return services[servico].homologation
  }

  verificaSePodeFinalizarEvento = () => {
    const validaLocalDoEvento = this.mostraLocalDoEvento(this.state.supplierInfo).length > 0 ? this.state.files.localDoEvento.files.length > 0 : true
    const validaTransfer = this.state.supplierInfo.transfer ? this.state.files.transfer.files.length > 0 : true
    const validaOutrosServicos = this.state.supplierInfo.otherServices ? this.state.files.otherServices.files.length > 0 : true
    return validaLocalDoEvento && validaTransfer && validaOutrosServicos
  }

  mostraNomeDoServico = (servico) => {
    switch (servico) {
      case 'room':
        return 'Salas'
      case 'foodsAndBeverages':
        return 'Alimentos e Bebidas'
      case 'lodging':
        return 'Hospedagem'
      case 'equipments':
        return 'Equipamentos'
      default:
        return ''
    }
  }

  gerenciaResponsividade = () => {
    if(window.innerWidth < 600) return this.paginaMobile()
    else return this.paginaDesktop()
  }

  paginaMobile = () => {
    const { isLoading, current, finished } = this.state
    const obj = [
      {
        id: 0,
        title: 'próximos eventos',
        content: () => {
          return (current.length > 0 ? current.map((event, i) => <EventCard {...event} key={event._id} />)
          : <Text>Nenhum evento em andamento.</Text>)
        }
      },
      {
        id: 3,
        title: 'pendentes de conciliação',
        qtd: 2,
        content: () => {
          return (<Text>Nenhum evento em andamento.</Text>)
        }
      },
      {
        id: 1,
        title: 'eventos concluídos',
        content: () => {
          return (finished.length > 0 ? finished.map((event, i) => <EventCard {...event} key={event._id} />)
          : <Text>Nenhum evento em andamento.</Text>)
        }
      },
    ]
    return <MainMobile>
      <Header />
      {isLoading ? <Loader /> : null}
      <ContentMobile>
        <Button
          type='button'
          category={'secondary'}
          fontWeight={800}
          full
          onClick={() => this.props.history.push('/organizer/event')}>
          Criar novo evento
        </Button>
        <EventList>
          <Tabs tabs={obj} />
        </EventList>
      </ContentMobile>
    </MainMobile>
  }

  paginaDesktop = () => {
    const { isLoading, files } = this.state
    const theme_received = !!window.localStorage.getItem('theme_logo') && window.localStorage.getItem('theme_logo') !== 'undefined'
    const obj = this.geraTabs()
    return <Group style={{ overflowY: 'scroll' }}>
      <Header />
      <Main>
        <ModalConciliacao isOpen={this.state.statusModalConciliacao} onClose={this.fechaModalConciliacao} dadosEvento={this.state.dadosEvento}/>
        <Modal isOpen={this.state.avisaModal}>
          <AlertIcon width={'150px'} />
          <Title>Atenção!</Title>
          <Subtitle>Algumas funcionalidades podem não funcionar corretamente no seu navegador. Utilize o Chrome/Firefox para uma melhor experiência.</Subtitle>
          <Group>
            <Button
              onClick={() => this.setState({ avisaModal: false })}
              category={'secondary'}
              fontWeight={800}
              width={'50%'}
              full>
              Ok
            </Button>
          </Group>
        </Modal>
        <Modal isOpen={this.state.chainModal}>
          <Icons
            icon={'blocked'}
            color={defaultTheme.colors.whiteLabel.secondary}
            width={150}
            height={150} />
          <Title style={{ paddingTop: '20px', fontWeight: 600 }}>Opa, não dá pra criar um novo evento por aqui</Title>
          <Subtitle>É necessário voltar à página da rede para solicitar um Novo Evento.</Subtitle>
          <Group>
            <Button
              onClick={() => this.setState({ chainModal: false })}
              category={'secondary'}
              fontWeight={800}
              ghost
              width={'50%'}
              full>
              Ok
            </Button>
          </Group>
        </Modal>
        <Modal maxWidth={'1100px'} isOpen={this.state.modalNumeroDeRequisicao} onClose={() => this.setState({ modalNumeroDeRequisicao: !this.state.modalNumeroDeRequisicao })} hasCloseButton>
          <Group style={{ display: 'flex' }}>
            <Group style={{ width: '50%', textAlign: 'left', padding: '20px' }}>
              {this.mostraLocalDoEvento(this.state.supplierInfo).length > 0 && <Group style={{ textAlign: 'left', margin: 'auto' }}>
                  <Subtitle style={{ fontWeight: 600, textTransform: 'uppercase', marginBottom: '0px' }}>Local do evento</Subtitle>
                  <Subtitle style={{ fontWeight: 600, textTransform: 'uppercase', fontStyle: 'italic' }}>({this.mostraLocalDoEvento(this.state.supplierInfo).join(', ')})</Subtitle>
                  <Subtitle style={{ marginLeft: 'auto', marginBottom: '0px' }}>Razão Social: <span style={{ fontWeight: 600 }}>{this.mostraRazaoSocialLocalDoEvento(this.state.supplierInfo)}</span></Subtitle>
                  <Subtitle style={{ marginLeft: 'auto' }}>CNPJ: <span style={{ fontWeight: 600 }}>{this.mostraCNPJlLocalDoEvento(this.state.supplierInfo)}</span></Subtitle>
                </Group>}
              {this.state.supplierInfo && this.state.supplierInfo.transfer && <Group style={{ textAlign: 'left', margin: 'auto' }}>
                  <Subtitle style={{ fontWeight: 600, textTransform: 'uppercase' }}>Transfer</Subtitle>
                  <Subtitle style={{ marginLeft: 'auto', marginBottom: '0px' }}>Razão Social: <span style={{ fontWeight: 600 }}>{this.state.supplierInfo.transfer.profile.full_name}</span></Subtitle>
                  <Subtitle style={{ marginLeft: 'auto' }}>CNPJ: <span style={{ fontWeight: 600 }}>{this.state.supplierInfo.transfer.profile.document}</span></Subtitle>
                </Group>}
              {this.state.supplierInfo && this.state.supplierInfo.otherServices && <Group style={{ textAlign: 'left', margin: 'auto' }}>
                  <Subtitle style={{ fontWeight: 600, textTransform: 'uppercase' }}>Outros Serviços</Subtitle>
                  <Subtitle style={{ marginLeft: 'auto', marginBottom: '0px' }}>Razão Social: <span style={{ fontWeight: 600 }}>{this.state.supplierInfo.otherServices.profile.full_name}</span></Subtitle>
                  <Subtitle style={{ marginLeft: 'auto' }}>CNPJ: <span style={{ fontWeight: 600 }}>{this.state.supplierInfo.otherServices.profile.document}</span></Subtitle>
                </Group>}
            </Group>
            <Group style={{ width: '50%' }}>
              <Title style={{ paddingTop: '20px', fontWeight: 600 }}>{this.state.dadosEvento && this.state.dadosEvento.name}</Title>
              <Input
                type={'text'}
                label={'Número de requisição'}
                placeholder={'Digite o número de requisição'}
                name={'requestCode'}
                isRequired
                value={this.state.requestCode}
                error={!this.state.requestCode}
                onChange={(input) => this.setState({ requestCode: input.target.value })}
              />
              <Button
                onClick={this.confirmarRequisicao}
                category={'secondary'}
                fontWeight={800}
                width={'50%'}
                full>
                Confirmar
              </Button>
            </Group>
          </Group>
        </Modal>
        <Modal maxWidth={'1100px'} isOpen={this.state.modalOrdemDeCompra} onClose={() => this.setState({ modalOrdemDeCompra: !this.state.modalOrdemDeCompra })} hasCloseButton>
          <Group style={{ padding: '10px' }}>
            <Title style={{ paddingTop: '20px', fontWeight: 600, marginBottom: '10px', textAlign: 'left' }}>Ordem de compra</Title>
            <Group style={{ display: 'flex' }}>
              <Subtitle style={{ fontWeight: 600, textTransform: 'uppercase' }}>{this.state.dadosEvento && this.state.dadosEvento.name}</Subtitle>
              <Subtitle style={{ marginLeft: 'auto' }}>Número de requisição: <span style={{ fontWeight: 600 }}>{this.state.dadosEvento && this.state.dadosEvento.approval && this.state.dadosEvento.approval.requestCode}</span></Subtitle>
            </Group>
            {this.mostraLocalDoEvento(this.state.supplierInfo).length > 0 && <>
                <hr style={{
                    backgroundColor: defaultTheme.colors.grey.medium,
                    height: 1,
                    margin: '10px 0px' }} />
                <Group style={{ display: 'flex' }}>
                  <Group style={{ textAlign: 'left', width: '50%', margin: 'auto' }}>
                    <Subtitle style={{ fontWeight: 600, textTransform: 'uppercase', marginBottom: '0px' }}>Local do evento</Subtitle>
                    <Subtitle style={{ fontWeight: 600, textTransform: 'uppercase', fontStyle: 'italic' }}>({this.mostraLocalDoEvento(this.state.supplierInfo).join(', ')})</Subtitle>
                    <Subtitle style={{ marginLeft: 'auto', marginBottom: '0px' }}>Razão Social: <span style={{ fontWeight: 600 }}>{this.mostraRazaoSocialLocalDoEvento(this.state.supplierInfo)}</span></Subtitle>
                    <Subtitle style={{ marginLeft: 'auto' }}>CNPJ: <span style={{ fontWeight: 600 }}>{this.mostraCNPJlLocalDoEvento(this.state.supplierInfo)}</span></Subtitle>
                  </Group>
                  {(!this.mostraHomologacaoLocalDoEvento(this.state.supplierInfo) || !this.mostraHomologacaoLocalDoEvento(this.state.supplierInfo).homologated) && <Group style={{ marginLeft: 'auto', width: '50%' }}>
                    <Group style={{ display: 'flex', height: '80%' }}>
                      <Button
                        type='submit'
                        category={'secondary'}
                        fontWeight={800}
                        onClick={() => this.ativaSolicitaHomologacao(this.mostraIDLocalDoEvento(this.state.supplierInfo), false)}
                        style={{ maxHeight: '60px', margin: 'auto 10px' }}
                        disabled={this.mostraHomologacaoLocalDoEvento(this.state.supplierInfo) && !this.mostraHomologacaoLocalDoEvento(this.state.supplierInfo).homologated}
                        ghost
                        full>
                        Não homologado
                      </Button>
                      <Button
                        type='submit'
                        category={'secondary'}
                        fontWeight={800}
                        onClick={() => this.ativaSolicitaHomologacao(this.mostraIDLocalDoEvento(this.state.supplierInfo), true)}
                        style={{ maxHeight: '60px', margin: 'auto 10px' }}
                        ghost
                        full>
                        Homologado
                      </Button>
                    </Group>
                    {(this.mostraHomologacaoLocalDoEvento(this.state.supplierInfo) && !this.mostraHomologacaoLocalDoEvento(this.state.supplierInfo).homologated) && <Label style={{ fontStyle: 'italic' }}>Link para homologação enviado em {formatDate(moment(this.mostraHomologacaoLocalDoEvento(this.state.supplierInfo).dateLinkSent, 'YYYY-MM-DD[T]HH:mm:ssZ'))}</Label>}
                  </Group>}
                  {this.mostraHomologacaoLocalDoEvento(this.state.supplierInfo) && this.mostraHomologacaoLocalDoEvento(this.state.supplierInfo).homologated && <Group style={{ marginLeft: 'auto', width: '50%' }}>
                    {files.localDoEvento.files.length > 0 && files.localDoEvento.files.map((file, fileIndex) => <Card style={{ margin: '10px', padding: '14px', width: 'calc(100% - 20px)' }}>
                        <Icons
                          icon={'paperclip'}
                          width={30}
                          height={15}
                          color={defaultTheme.colors.text.darker}
                        />
                        <a href={file.url} rel='noopener noreferrer' target={'_blank'} style={{ fontFamily: defaultTheme.fonts.primary, color: defaultTheme.colors.primary.default, fontSize: '15px', paddingLeft: '10px' }}>Anexo {fileIndex < 9 && '0'}{(fileIndex + 1)}</a>
                        <Button
                          type='submit'
                          category={'secondary'}
                          fontWeight={800}
                          onClick={() => this.removerUpload(fileIndex, 'localDoEvento')}
                          style={{ position: 'absolute', right: '0', top: '0', width: '100px' }}
                          ghost
                          small
                          full>
                          Excluir
                        </Button>
                      </Card>
                    )}
                    <DragnDrop
                      accept={'.pdf,.jpeg,.jpg,.png'}
                      onDrop={(files) => this.uploadDeArquivo(files, 'localDoEvento')}
                      onFileDialogCancel={() => this.onFileDialogCancel('localDoEvento')}
                    />
                  </Group>}
                </Group>
              </>}
              {this.state.supplierInfo && this.state.supplierInfo.transfer && <>
                <hr style={{
                    backgroundColor: defaultTheme.colors.grey.medium,
                    height: 1,
                    margin: '10px 0px' }} />
                <Group style={{ display: 'flex' }}>
                  <Group style={{ textAlign: 'left', width: '50%', margin: 'auto' }}>
                    <Subtitle style={{ fontWeight: 600, textTransform: 'uppercase' }}>Transfer</Subtitle>
                    <Subtitle style={{ marginLeft: 'auto', marginBottom: '0px' }}>Razão Social: <span style={{ fontWeight: 600 }}>{this.state.supplierInfo.transfer.profile.full_name}</span></Subtitle>
                    <Subtitle style={{ marginLeft: 'auto' }}>CNPJ: <span style={{ fontWeight: 600 }}>{this.state.supplierInfo.transfer.profile.document}</span></Subtitle>
                  </Group>
                  {(!this.state.supplierInfo.transfer.homologation || !this.state.supplierInfo.transfer.homologation.homologated) && <Group style={{ marginLeft: 'auto', width: '50%' }}>
                    <Group style={{ display: 'flex', height: '80%' }}>
                      <Button
                        type='submit'
                        category={'secondary'}
                        fontWeight={800}
                        onClick={() => this.ativaSolicitaHomologacao(this.state.supplierInfo.transfer.supplierId, false)}
                        style={{ maxHeight: '60px', margin: 'auto 10px' }}
                        ghost
                        disabled={this.state.supplierInfo.transfer.homologation && !this.state.supplierInfo.transfer.homologation.homologated}
                        full>
                        Não homologado
                      </Button>
                      <Button
                        type='submit'
                        category={'secondary'}
                        fontWeight={800}
                        onClick={() => this.ativaSolicitaHomologacao(this.state.supplierInfo.transfer.supplierId, true)}
                        style={{ maxHeight: '60px', margin: 'auto 10px' }}
                        ghost
                        full>
                        Homologado
                      </Button>
                    </Group>
                    {(this.state.supplierInfo.transfer.homologation && !this.state.supplierInfo.transfer.homologation.homologated) && <Label style={{ fontStyle: 'italic' }}>Link para homologação enviado em {formatDate(moment(this.state.supplierInfo.transfer.homologation.dateLinkSent, 'YYYY-MM-DD[T]HH:mm:ssZ'))}</Label>}
                  </Group>}
                  {(this.state.supplierInfo.transfer.homologation && this.state.supplierInfo.transfer.homologation.homologated) && <Group style={{ marginLeft: 'auto', width: '50%' }}>
                    {files.transfer.files.length > 0 && files.transfer.files.map((file, fileIndex) => <Card style={{ margin: '10px', padding: '14px', width: 'calc(100% - 20px)' }}>
                        <Icons
                          icon={'paperclip'}
                          width={30}
                          height={15}
                          color={defaultTheme.colors.text.darker}
                        />
                        <a href={file.url} rel='noopener noreferrer' target={'_blank'} style={{ fontFamily: defaultTheme.fonts.primary, color: defaultTheme.colors.primary.default, fontSize: '15px', paddingLeft: '10px' }}>Anexo {fileIndex < 9 && '0'}{(fileIndex + 1)}</a>
                        <Button
                          type='submit'
                          category={'secondary'}
                          fontWeight={800}
                          onClick={() => this.removerUpload(fileIndex, 'transfer')}
                          style={{ position: 'absolute', right: '0', top: '0', width: '100px' }}
                          ghost
                          small
                          full>
                          Excluir
                        </Button>
                      </Card>
                    )}
                    <DragnDrop
                      accept={'.pdf,.jpeg,.jpg,.png'}
                      onDrop={(files) => this.uploadDeArquivo(files, 'transfer')}
                      onFileDialogCancel={() => this.onFileDialogCancel('transfer')}
                    />
                  </Group>}
                </Group>
              </>}
              {this.state.supplierInfo && this.state.supplierInfo.otherServices && <>
                <hr style={{
                    backgroundColor: defaultTheme.colors.grey.medium,
                    height: 1,
                    margin: '10px 0px' }} />
                <Group style={{ display: 'flex' }}>
                  <Group style={{ textAlign: 'left', width: '50%', margin: 'auto' }}>
                    <Subtitle style={{ fontWeight: 600, textTransform: 'uppercase' }}>Outros Serviços</Subtitle>
                    <Subtitle style={{ marginLeft: 'auto', marginBottom: '0px' }}>Razão Social: <span style={{ fontWeight: 600 }}>{this.state.supplierInfo.otherServices.profile.full_name}</span></Subtitle>
                    <Subtitle style={{ marginLeft: 'auto' }}>CNPJ: <span style={{ fontWeight: 600 }}>{this.state.supplierInfo.otherServices.profile.document}</span></Subtitle>
                  </Group>
                  {(!this.state.supplierInfo.otherServices.homologation || !this.state.supplierInfo.otherServices.homologation.homologated) && <Group style={{ marginLeft: 'auto', width: '50%' }}>
                    <Group style={{ display: 'flex', height: '80%' }}>
                      <Button
                        type='submit'
                        category={'secondary'}
                        fontWeight={800}
                        onClick={() => this.ativaSolicitaHomologacao(this.state.supplierInfo.otherServices.supplierId, false)}
                        style={{ maxHeight: '60px', margin: 'auto 10px' }}
                        ghost
                        disabled={this.state.supplierInfo.otherServices.homologation && !this.state.supplierInfo.otherServices.homologation.homologated}
                        full>
                        Não homologado
                      </Button>
                      <Button
                        type='submit'
                        category={'secondary'}
                        fontWeight={800}
                        onClick={() => this.ativaSolicitaHomologacao(this.state.supplierInfo.otherServices.supplierId, true)}
                        style={{ maxHeight: '60px', margin: 'auto 10px' }}
                        ghost
                        full>
                        Homologado
                      </Button>
                    </Group>
                    {(this.state.supplierInfo.otherServices.homologation && !this.state.supplierInfo.otherServices.homologation.homologated) && <Label style={{ fontStyle: 'italic' }}>Link para homologação enviado em {formatDate(moment(this.state.supplierInfo.otherServices.homologation.dateLinkSent, 'YYYY-MM-DD[T]HH:mm:ssZ'))}</Label>}
                  </Group>}
                  {(this.state.supplierInfo.otherServices.homologation && this.state.supplierInfo.otherServices.homologation.homologated) && <Group style={{ marginLeft: 'auto', width: '50%' }}>
                    {files.otherServices.files.length > 0 && files.otherServices.files.map((file, fileIndex) => <Card style={{ margin: '10px', padding: '14px', width: 'calc(100% - 20px)' }}>
                        <Icons
                          icon={'paperclip'}
                          width={30}
                          height={15}
                          color={defaultTheme.colors.text.darker}
                        />
                        <a href={file.url} rel='noopener noreferrer' target={'_blank'} style={{ fontFamily: defaultTheme.fonts.primary, color: defaultTheme.colors.primary.default, fontSize: '15px', paddingLeft: '10px' }}>Anexo {fileIndex < 9 && '0'}{(fileIndex + 1)}</a>
                        <Button
                          type='submit'
                          category={'secondary'}
                          fontWeight={800}
                          onClick={() => this.removerUpload(fileIndex, 'otherServices')}
                          style={{ position: 'absolute', right: '0', top: '0', width: '100px' }}
                          ghost
                          small
                          full>
                          Excluir
                        </Button>
                      </Card>
                    )}
                    <DragnDrop
                      accept={'.pdf,.jpeg,.jpg,.png'}
                      onDrop={(files) => this.uploadDeArquivo(files, 'otherServices')}
                      onFileDialogCancel={() => this.onFileDialogCancel('otherServices')}
                    />
                  </Group>}
                </Group>
              </>}
            <Group style={{ display: 'flex' }}>
              <Button
                onClick={this.confirmarOrdemDeCompra}
                category={'secondary'}
                fontWeight={800}
                width={'50%'}
                ghost
                full>
                Salvar e terminar depois
              </Button>
              <Button
                onClick={this.finalizarEvento}
                category={'secondary'}
                fontWeight={800}
                width={'50%'}
                disabled={!this.verificaSePodeFinalizarEvento()}
                full>
                Finalizar evento
              </Button>
            </Group>
          </Group>
        </Modal>
        {isLoading ? <Loader /> : null}
        <Body {...this.props} pagina={'inicio'}>
          <Group style={{ display: 'flex' }}>
            <Title>Eventos</Title>
            {!this.verifaRole('APPROVER') && <Button
              type='button'
              category={'secondary'}
              fontWeight={800}
              style={{ width: 'auto', marginLeft: 'auto' }}
              full
              onClick={() => {
                if (ehChainUser()) this.setState({ chainModal: true })
                else this.props.history.push('/organizer/event')
              }}>
              + Criar novo evento
            </Button>}
          </Group>
          <EventList>
            <Tabs tabs={obj} temBusca efetuaFiltro={this.efetuaFiltro} />
          </EventList>
        </Body>
      </Main>
      {theme_received && <PoweredBy style={{ cursor: 'pointer' }} onClick={() => window.location.href = 'https://www.evnts.com.br/'}>
        <Label>powered by <svg className='logoEvnts' version='1.1' style={{ 'fill': '#a5a5a5', width: '33px', height: '10px', 'enablBackground': 'new 0 0 2226.5 691.2' }} id='Layer_1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px'
          viewBox='0 0 2226.5 691.2'>
          <g>
            <path d='M0,588.2V141.7c0-15.8,13-28.8,28.1-28.8h316.2c14.4,0,25.9,11.5,25.9,25.9s-11.5,25.9-25.9,25.9H56.8v172.2H345c14.4,0,25.9,12.2,25.9,25.9c0,14.4-11.5,25.9-25.9,25.9H56.8v176.4h288c14.4,0,25.9,11.5,25.9,25.9s-11.5,25.9-25.9,25.9H28C12.9,617,0,604,0,588.2z' />
            <path d='M656.5,599L462.1,150.4c-2.2-4.3-2.9-7.9-2.9-13c0-14.4,13-28.1,28.8-28.1c14.4,0,24.5,9.4,29.5,21.6l174.3,417.6l175-419.1c5-10.1,15.1-20.2,28.1-20.2c15.8,0,28.1,13,28.1,27.4c0,4.3-0.7,7.9-2.2,11.5L725.6,599c-6.5,14.4-16.6,23.8-33.1,23.8h-2.9C673.1,622.7,662.2,613.4,656.5,599z' />
            <path d='M1018.2,138.8c0-15.1,13-28.1,28.1-28.1h7.9c13,0,20.9,6.5,28.8,16.6l306,390.3V137.4c0-15.1,12.2-28.1,28.1-28.1c15.1,0,27.4,13,27.4,28.1v455.1c0,15.1-10.8,26.6-25.9,26.6h-2.9c-12.2,0-20.9-7.2-29.5-17.3l-312.5-399.6v390.3c0,15.1-12.2,28.1-28.1,28.1c-15.1,0-27.4-13-27.4-28.1V138.8z' />
            <ellipse cx='2073.9' cy='28.7' rx='28.7' ry='28.7' />
            <ellipse cx='1808.4' cy='662.5' rx='28.7' ry='28.7' />
            <path d='M2043.3,339.6c-59.5-23.3-92.1-40.8-104.2-65.8l-25.9,61.6c22.5,18.8,54.9,34.7,97.5,51.5c76.4,29.7,101.8,52.3,101.8,90.5c0,53.7-53.7,92.6-125.2,92.6c-65.4,0-109.1-20.4-147.6-60.4l-23,54.6c44.7,38.1,102.6,56,167,56c105.4,0,187.4-62.9,187.4-149.9C2171.3,406.7,2128.2,372.1,2043.3,339.6z' />
            <path d='M2213.8,167.7c-33.2-29-82.7-56.6-156.3-56.6c-18.4,0-35.8,1.9-52.1,5.3l-26.2,62.2c19.2-10.7,43.9-17.3,73.3-17.3c52.3,0,92.6,17,126.6,49.5c6.4,5,12.7,7.1,19.8,7.1c14.9,0,27.6-11.3,27.6-26.9C2226.5,179.7,2220.8,173.4,2213.8,167.7z' />
            <path d='M1711.6,583.4l57.7-137.2V165.1h118.3l22.2-52.7h-344.7c-14.4,0-26.7,12.3-26.7,26.7s12.3,26,26.7,26h146.5V583.4z' />
          </g>
        </svg></Label>
      </PoweredBy>}
    </Group>
  }

  render() {
    return this.gerenciaResponsividade()
  }
}

Events.defaultProps = {
  events: []
}

export default Events
