import React from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../../styles/themes'

const SubTitle = styled.h2`
  color: ${props => props.edited ? '#F39C12' : '#484848'};
  font-family: ${defaultTheme.fonts.primary};
  font-size: 0.9em;
  line-height: 1.2;
  margin-bottom: 20px;
`

export default function (props) {
  return <SubTitle {...props} />
}
