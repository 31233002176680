import React, { Component } from 'react'

export default class AlertCriticalIcon extends Component {
  render () {
    return (
      <svg style={{ ...this.props.style, maxWidth: '300px' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 269 167">
        <defs>
          <clipPath id="clip-Artboard_1">
            <rect width="269" height="167"/>
          </clipPath>
        </defs>
        <g id="Artboard_1" data-name="Artboard – 1" clip-path="url(#clip-Artboard_1)">
          <rect width="269" height="167" fill="#fff"/>
          <g id="Group_6029" data-name="Group 6029" transform="translate(5.826 -9.894)">
            <g id="Group_5771" data-name="Group 5771" transform="translate(71.415 47.166)">
              <g id="Ellipse_93" data-name="Ellipse 93" transform="translate(30.009 34.635) rotate(-45)" fill="none" stroke="#762123" stroke-miterlimit="10" stroke-width="2.835">
                <ellipse cx="11.927" cy="39.767" rx="11.927" ry="39.767" stroke="none"/>
                <ellipse cx="11.927" cy="39.767" rx="10.509" ry="38.349" fill="none"/>
              </g>
              <path id="Path_566" data-name="Path 566" d="M149.882,108.8l-91.8,39.767L95.873,54.056" transform="translate(-58.079 -25.571)" fill="none" stroke="#762123" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.835"/>
              <path id="Path_567" data-name="Path 567" d="M78.706,73.461c6.569,6.162,29.708,26.37,61.882,29.213" transform="translate(-51.243 -19.14)" fill="none" stroke="#762123" stroke-miterlimit="10" stroke-width="2.835"/>
              <path id="Path_568" data-name="Path 568" d="M73.751,85.661c13.043,9.964,24.381,19.253,47.307,22.9" transform="translate(-52.885 -15.096)" fill="none" stroke="#762123" stroke-miterlimit="10" stroke-width="2.835"/>
              <path id="Path_569" data-name="Path 569" d="M68.816,98.192c5.126,3.383,20,12.163,30.225,16.466" transform="translate(-54.521 -10.943)" fill="none" stroke="#762123" stroke-miterlimit="10" stroke-width="2.835"/>
              <path id="Path_570" data-name="Path 570" d="M64.544,108.875c3.23,2.847,12.828,9.146,17,10.434" transform="translate(-55.937 -7.402)" fill="none" stroke="#762123" stroke-miterlimit="10" stroke-width="2.835"/>
              <path id="Path_571" data-name="Path 571" d="M116.7,84.085c4.624-4.439,14.057-17.2,36.252-13.317" transform="translate(-38.649 -20.273)" fill="none" stroke="#762123" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2.835"/>
              <path id="Path_572" data-name="Path 572" d="M100.589,78.9c5.548-6.1,22.2-22.195,13.687-46.241" transform="translate(-43.99 -32.662)" fill="none" stroke="#762123" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2.835"/>
              <path id="Path_573" data-name="Path 573" d="M116.7,72.074c2.4-2.588,15.351-16.461,16.092-28.854" transform="translate(-38.649 -29.163)" fill="none" stroke="#762123" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2.835"/>
              <path id="Path_574" data-name="Path 574" d="M130.317,65.684C133.832,61.245,142.9,52,154.363,53.106" transform="translate(-34.137 -25.917)" fill="none" stroke="#762123" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2.835"/>
            </g>
            <g id="Group_1041" data-name="Group 1041" transform="translate(12.245 10.891)">
              <circle id="Ellipse_40" data-name="Ellipse 40" cx="3.959" cy="3.959" r="3.959" transform="translate(25.102 12.043)" fill="none" stroke="#762123" stroke-miterlimit="10" stroke-width="1"/>
              <circle id="Ellipse_41" data-name="Ellipse 41" cx="3.959" cy="3.959" r="3.959" transform="translate(232.235 30.073)" fill="none" stroke="#762123" stroke-miterlimit="10" stroke-width="1"/>
              <circle id="Ellipse_42" data-name="Ellipse 42" cx="3.959" cy="3.959" r="3.959" transform="translate(-1.548 151.642)" fill="none" stroke="#762123" stroke-miterlimit="10" stroke-width="1"/>
              <g id="Group_1033" data-name="Group 1033" transform="translate(166.263)">
                <line id="Line_113" data-name="Line 113" x1="10.088" transform="translate(0 4.98)" fill="none" stroke="#762123" stroke-miterlimit="10" stroke-width="1"/>
                <line id="Line_114" data-name="Line 114" y2="10.024" transform="translate(5.044)" fill="none" stroke="#762123" stroke-miterlimit="10" stroke-width="1"/>
              </g>
              <g id="Group_1035" data-name="Group 1035" transform="translate(197.722 153.747)">
                <line id="Line_117" data-name="Line 117" x1="10.088" transform="translate(0 5.044)" fill="none" stroke="#762123" stroke-miterlimit="10" stroke-width="1"/>
                <line id="Line_118" data-name="Line 118" y2="10.088" transform="translate(5.044)" fill="none" stroke="#762123" stroke-miterlimit="10" stroke-width="1"/>
              </g>
              <g id="Group_1036" data-name="Group 1036" transform="translate(20 103.329)">
                <line id="Line_119" data-name="Line 119" x1="10.088" transform="translate(0 5.044)" fill="none" stroke="#762123" stroke-miterlimit="10" stroke-width="1"/>
                <line id="Line_120" data-name="Line 120" y2="10.088" transform="translate(5.044)" fill="none" stroke="#762123" stroke-miterlimit="10" stroke-width="1"/>
              </g>
              <g id="Group_1037" data-name="Group 1037" transform="translate(83.244 7.86)">
                <path id="Path_112" data-name="Path 112" d="M704.565,352.336c-4.852-1.149-3.32-7.406-8.109-8.556-4.853-1.149-6.321,5.044-11.174,3.895s-3.32-7.406-8.109-8.556c-4.853-1.149-6.321,5.044-11.174,3.895" transform="translate(-666 -338.976)" fill="none" stroke="#762123" stroke-miterlimit="10" stroke-width="1"/>
              </g>
              <g id="Group_1038" data-name="Group 1038" transform="translate(-17.031 67.775) rotate(-14)">
                <path id="Path_113" data-name="Path 113" d="M564.486,429.985c-4.98,0-4.98-6.385-9.9-6.385-4.98,0-4.98,6.385-9.9,6.385-4.98,0-4.98-6.385-9.9-6.385-4.98,0-4.98,6.385-9.9,6.385" transform="translate(-524.9 -423.6)" fill="none" stroke="#762123" stroke-miterlimit="10" stroke-width="1"/>
              </g>
              <g id="Group_1039" data-name="Group 1039" transform="translate(214.676 96.049)">
                <path id="Path_114" data-name="Path 114" d="M843.679,480.9c-4.278-2.49-1.086-7.981-5.427-10.471-4.278-2.49-7.47,3.065-11.812.575-4.278-2.49-1.085-7.981-5.427-10.471-4.278-2.49-7.47,3.065-11.812.575" transform="translate(-809.2 -459.869)" fill="none" stroke="#762123" stroke-miterlimit="10" stroke-width="1"/>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}
