/* LIBS */
import React from 'react'
import { Route } from 'react-router-dom'
/* SCREENS */
import CriarEvento from '../screens/SupplierChain/CriarEvento/Evento'
import CriarHospedagem from '../screens/SupplierChain/CriarEvento/Servicos/Hospedagem'
import CriarSalas from '../screens/SupplierChain/CriarEvento/Servicos/Salas'
import Resumo from '../screens/SupplierChain/CriarEvento/Resumo'
import Confirmacao from '../screens/SupplierChain/CriarEvento/Confirmacao'

const SupplierChainRoutes = () => {
  return (
    <div>
      <Route path='/espacos-para-eventos/:supplierChainId/event' exact component={CriarEvento} />
      <Route path='/espacos-para-eventos/:supplierChainId/lodging' exact component={CriarHospedagem} />
      <Route path='/espacos-para-eventos/:supplierChainId/rooms' exact component={CriarSalas} />
      <Route path='/espacos-para-eventos/:supplierChainId/summary' exact component={Resumo} />
      <Route path='/espacos-para-eventos/:supplierChainId/confirmation' exact component={Confirmacao} />

      <Route path='/espacos-para-eventos/:supplierChainId/:hotelId/event' exact component={CriarEvento} />
      <Route path='/espacos-para-eventos/:supplierChainId/:hotelId/lodging' exact component={CriarHospedagem} />
      <Route path='/espacos-para-eventos/:supplierChainId/:hotelId/rooms' exact component={CriarSalas} />
      <Route path='/espacos-para-eventos/:supplierChainId/:hotelId/summary' exact component={Resumo} />
      <Route path='/espacos-para-eventos/:supplierChainId/:hotelId/confirmation' exact component={Confirmacao} />
    </div>
  )
}

export default SupplierChainRoutes
