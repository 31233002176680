import {
  FOODS_FETCH_REQUEST,
  FOODS_FETCH_SUCCESS,
  FOODS_FETCH_FAILURE
} from '../../actions/forms'

export default function foodsAndBeverages(state = {
  isFetching: false,
  error: false,
  data: {
    id: false,
    menus: {
      "welcome_drink": {
        "id": "welcome_drink",
        "name": "Welcome Drink",
        "enabled": false,
        "items": []
      },
      "welcome_coffee": {
        "id": "welcome_coffee",
        "name": "Welcome Coffee",
        "enabled": false,
        "items": []
      },
      "coffee_break_morning": {
        "id": "coffee_break_morning",
        "name": "Coffee break período da manhã",
        "enabled": false,
        "items": []
      },
      "brunch": {
        "id": "brunch",
        "name": "Brunch",
        "enabled": false,
        "items": []
      },
      "lunch": {
        "id": "lunch",
        "name": "Almoço",
        "enabled": false,
        "items": []
      },
      "coffee_break_afternoon": {
        "id": "coffee_break_afternoon",
        "name": "Coffee break período da tarde",
        "enabled": false,
        "items": []
      },
      "dinner": {
        "id": "dinner",
        "name": "Jantar",
        "enabled": false,
        "items": []
      },
      "thematic_dinner": {
        "id": "thematic_dinner",
        "name": "Jantar temático",
        "enabled": false,
        "items": []
      },
      "rooms_service": {
        "id": "rooms_service",
        "name": "Serviço de sala",
        "enabled": false,
        "items": [
          {
            "id": null,
            "name": "water",
            "keyName": "water",
            "type": "",
            "quantity": "",
            "price": {
              "amount": '',
              "currency": "BRL"
            },
            "details": null
          },
          {
            "id": null,
            "name": "coffee",
            "keyName": "coffee",
            "type": null,
            "quantity": "",
            "price": {
              "amount": '',
              "currency": "BRL"
            },
            "details": null
          },
          {
            "id": null,
            "name": "tea",
            "keyName": "tea",
            "type": null,
            "quantity": '',
            "price": {
              "amount": '',
              "currency": "BRL"
            },
            "details": null
          },
          {
            "id": null,
            "name": "milk",
            "keyName": "milk",
            "type": null,
            "quantity": '',
            "price": {
              "amount": '',
              "currency": "BRL"
            },
            "details": null
          },
          {
            "id": null,
            "name": "juice",
            "keyName": "juice",
            "type": null,
            "quantity": '',
            "price": {
              "amount": '',
              "currency": "BRL"
            },
            "details": null
          },
          {
            "id": null,
            "name": "soda",
            "keyName": "soda",
            "type": "bottle",
            "quantity": '',
            "price": {
              "amount": '',
              "currency": "BRL"
            },
            "details": null
          }
        ]
      },
      "tray": {
        "id": "tray",
        "name": "Bandejas",
        "enabled": false,
        "items": [
          {
            "id": "cheeese_bread",
            "name": "Pão de queijo",
            "keyName": "cheeese_bread",
            "type": null,
            "quantity": null,
            "price": {
              "amount": 0,
              "currency": "BRL"
            },
            "details": null
          },
          {
            "id": "petit_fours_sweet",
            "name": "Petit fours doce",
            "keyName": "petit_fours_sweet",
            "type": null,
            "quantity": null,
            "price": {
              "amount": 0,
              "currency": "BRL"
            },
            "details": null
          },
          {
            "id": "petit_fours_salted",
            "keyName": "petit_fours_salted",
            "name": "Petit fours salgado",
            "type": null,
            "quantity": null,
            "price": {
              "amount": 0,
              "currency": "BRL"
            },
            "details": null
          },
          {
            "id": "cake",
            "keyName": "cake",
            "name": "Bolo",
            "type": null,
            "quantity": null,
            "price": {
              "amount": 0,
              "currency": "BRL"
            },
            "details": null
          },
          {
            "id": "mini_sandwich",
            "keyName": "mini_sandwich",
            "name": "Mini sanduiches",
            "type": null,
            "quantity": null,
            "price": {
              "amount": 0,
              "currency": "BRL"
            },
            "details": null
          },
          {
            "id": "cupcake",
            "keyName": "cupcake",
            "name": "Cup cakes",
            "type": null,
            "quantity": null,
            "price": {
              "amount": 0,
              "currency": "BRL"
            },
            "details": null
          },
          {
            "id": "fruits",
            "keyName": "fruits",
            "name": "Travessa de frutas",
            "type": null,
            "quantity": null,
            "price": {
              "amount": 0,
              "currency": "BRL"
            },
            "details": null
          }
        ]
      },
      "cocktail": {
        "id": "cocktail",
        "name": "Coquetel",
        "enabled": false,
        "items": []
      },
      "taxes": 0
    }
  }
}, action) {
  switch (action.type) {
    case FOODS_FETCH_REQUEST:
    return Object.assign({}, state, {
      isFetching: true,
      form: action.form
    })
  case FOODS_FETCH_SUCCESS:
    return Object.assign({}, state, {
      isFetching: false,
      error: '',
      data: action.data
    })
  case FOODS_FETCH_FAILURE:
    return Object.assign({}, state, {
      isFetching: false,
      error: action.message
    })
    default:
      return state
  }
}
