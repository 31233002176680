import React from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../../styles/themes'

const Button = styled.button`
  background-color: transparent;
  font-family: ${defaultTheme.fonts.secondary};
  border: 2px solid ${defaultTheme.colors.whiteLabel.primary};
  -webkit-border-radius: 60px;
  border-radius: 60px;
  color: ${defaultTheme.colors.whiteLabel.primary};
  cursor: pointer;
  display: block;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  -webkit-mask-image: -webkit-radial-gradient(circle, white, black);
  mask-image: -webkit-radial-gradient(circle, white, black);
  padding: 0;
  text-align: center;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  max-height: 35px;
  width: 45px;
  &:hover {
    background-color: ${defaultTheme.colors.whiteLabel.primary};
    color: #fff;
  };
  &:disabled {
    background-color: transparent;
    color: ${defaultTheme.colors.whiteLabel.primary};
    cursor: not-allowed;
    opacity: 0.4;
  }
`
const Span = styled.span`
  margin: auto;
`

export default function (props) {
  return <Button {...props} type='button'>
    <Span>{props.simbolo}</Span>
  </Button>
}
