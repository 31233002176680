/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'

/* API */
import config from '../../../middleware/config'
import { Get, Delete } from '../../../utils/request'

/* COMPONENTS */
import Header from '../../Organizer/Header'
import Loader from '../../components/Loader'
import Modal from '../../components/Modal'
import AlertIcon from '../../components/ui/AlertIcon'
import Button from '../../components/ui/Button'
import Subtitle from '../../components/ui/Subtitle'
import Main from '../../components/Main'
import Body from '../../components/Body'
import Title from '../../components/ui/Title'
import Tabs from '../../components/ui/Tabs'
import ModalCadastroEmpresa from './Forms/CadastroEmpresa'
import ModalCadastroCentroCusto from './Forms/CadastroCentroDeCusto'
import ModalCadastroUsuario from './Forms/CadastroUsuario'
import TabelaCentroDeCusto from './TabelaCentroDeCusto'
import TabelaEmpresa from './TabelaEmpresa'
import TabelaUsuario from './TabelaUsuario'
import { ehTipo } from '../../../utils/tiposUsuario'

const Group = styled.div`
`

const Titulo = styled(Title)`
  font-weight: 400;
  font-family: "Pluto",sans-serif;
`

class AdminHeadquartes extends Component {
  constructor () {
    super()
    this.state = {
      statusModalEmpresa: false,
      statusModalCentroCusto: false,
      empresas: [],
      empresaInfo: {},
      centroDeCustos: [],
      centroDeCustoInfo: {},
      usuarios: [],
      usuarioInfo: {}
    }
  }

  componentDidMount() {
    const NAO_PODE_ACESSAR = !(ehTipo('HEADQUARTER_ADMIN') || ehTipo('BRANCH_ADMIN') || ehTipo('COSTCENTER_ADMIN'))
    if (NAO_PODE_ACESSAR) this.props.history.push('/organizer')
    else this.setState({ isLoading: true }, () => {
      this.renderizaListaEmpresas()
      .then(() => this.renderizaListaCentroDeCusto())
      .then(() => this.renderizaListaUsuarios())
      .then(() => this.setState({ isLoading: false }))
    })
  }

  renderizaListaCentroDeCusto = () => {
    const { api: { base, version } } = config
    var endpoint = `${base}${version}/accounts/cost-centers`
    return Get(endpoint)
      .then((result) => this.setState({ centroDeCustos : [result] }))
      .catch(err => {
        this.setState({ isLoading: false })
        console.error(err)
        alert(`Erro ao obter os seus eventos, favor entrar em contato com o suporte técnico (received: ${err})`)
      })
  }

  renderizaListaEmpresas = () => {
    const { api: { base, version } } = config
    var endpoint = `${base}${version}/accounts/headquarters`
    return Get(endpoint)
      .then((result) => this.setState({ empresas : [result] }))
      .catch(err => {
        this.setState({ isLoading: false })
        console.error(err)
        alert(`Erro ao obter os seus eventos, favor entrar em contato com o suporte técnico (received: ${err})`)
      })
  }

  renderizaListaUsuarios = () => {
    const { api: { base, version } } = config
    var endpoint = `${base}${version}/users/cost-centers`
    return Get(endpoint)
      .then((result) => this.setState({ usuarios : result }))
      .catch(err => {
        this.setState({ isLoading: false })
        console.error(err)
        alert(`Erro ao obter os seus eventos, favor entrar em contato com o suporte técnico (received: ${err})`)
      })
  }

  ehAdminCentroCusto = (nivel) => {
    const roles = window.localStorage.getItem('user_roles') !== 'undefined' && JSON.parse(window.localStorage.getItem('user_roles'))
    return roles && roles.find(role => role === nivel)
  }

  tratarNivelUsuario = (obj) => {
    if (this.ehAdminCentroCusto('BRANCH_ADMIN')) {
      return obj && obj.filter((item) => item.title !== 'empresas').map((item, index) => {
        item.id = index
        return item
      })
    } else if (this.ehAdminCentroCusto('COSTCENTER_ADMIN')) {
      return obj && obj.filter((item) => item.title !== 'empresas' && item.title !== 'centros de custo').map((item, index) => {
        item.id = index
        return item
      })
    } else {
      return obj.map((item, index) => {
        item.id = index
        return item
      })
    }
  }

  retornaListaDeBotoes = (obj) => {
    return obj.map(item => {
      if(item.title === 'empresas') return 'Adicionar nova empresa'
      if(item.title === 'centros de custo') return 'Adicionar novo centro de custo'
      if(item.title === 'usuários') return 'Adicionar novo usuário'
      return ''
    })
  }

  apresentaTitulo = () => {
    if(this.ehAdminCentroCusto('HEADQUARTER_ADMIN')) return 'Administrador'
    if(this.ehAdminCentroCusto('BRANCH_ADMIN')) return 'Administrador de filial'
    if(this.ehAdminCentroCusto('COSTCENTER_ADMIN')) return 'Administrador de centro de custos'
  }

  abreModal = (id) => {
    if (this.ehAdminCentroCusto('BRANCH_ADMIN')) {
      if(id === 0) this.setState({ statusModalCentroCusto: true, centroDeCustoInfo: {} })
      if(id === 1) this.setState({ statusModalUsuario: true, usuarioInfo: {} })
    } else if (this.ehAdminCentroCusto('COSTCENTER_ADMIN')) {
      if(id === 0) this.setState({ statusModalUsuario: true, usuarioInfo: {} })
    } else {
      if(id === 0) this.setState({ statusModalEmpresa: true, empresaInfo: {} })
      if(id === 1) this.setState({ statusModalCentroCusto: true, centroDeCustoInfo: {} })
      if(id === 2) this.setState({ statusModalUsuario: true, usuarioInfo: {} })
    }
  }

  editaEmpresa = (info) => {
    this.setState({ statusModalEmpresa: true, empresaInfo: info })
  }

  editaCentroDeCusto = (info) => {
    this.setState({ statusModalCentroCusto: true, centroDeCustoInfo: info })
  }

  editaUsuario= (info) => {
    this.setState({ statusModalUsuario: true, usuarioInfo: info })
  }

  excluiUsuario = (info) => {
    const { api: { base, version } } = config
    var endpoint = `${base}${version}/users/cost-centers/${info.id}`
    return Delete(endpoint)
    .then((result) => {
      alert('Usuário removido com sucesso!')
      window.location.reload()
    })
    .catch(err => {
      this.setState({ isLoading: false })
      console.error(err)
      alert(`Erro ao obter os seus eventos, favor entrar em contato com o suporte técnico (received: ${err})`)
    })
  }

  abreModalExclusaoUsuario = (info) => {
    const mensagem = "Deseja excluir o usuário: " + info.name + "? Esta ação não poderá ser desfeita."
    this.setState({ modalExcluirUsuario: true, textoModalExcluirUsuario: mensagem, usuarioInfo: info })
  }

  render () {
    const obj = [
      {
        id: 0,
        title: 'empresas',
        content: () => {
          return (<TabelaEmpresa edicao={this.editaEmpresa} dados={this.state.empresas}/>)
        }
      },
      {
        id: 1,
        title: 'centros de custo',
        content: () => {
          return (<TabelaCentroDeCusto edicao={this.editaCentroDeCusto} dados={this.state.centroDeCustos}/>)
        }
      },
      {
        id: 2,
        title: 'usuários',
        content: () => {
          return (<TabelaUsuario excluir={this.abreModalExclusaoUsuario} edicao={this.editaUsuario} dados={this.state.usuarios}/>)
        }
      }
    ]

    return (
      <div style={{ overflowY: 'scroll' }}>
        { this.state.isLoading ? <Loader /> : null }
        <Modal isOpen={this.state.modalExcluirUsuario}>
          <AlertIcon width={'150px'} />
          <Title>Atenção!</Title>
          <Subtitle style={{ paddingTop: '20px' }}>{this.state.textoModalExcluirUsuario}</Subtitle>
          <Group style={{ display: 'flex' }}>
            <Button
              onClick={() => this.setState({ modalExcluirUsuario: false })}
              category={'secondary'}
              fontWeight={800}
              width={'50%'}
              ghost
              full>
              Cancelar
            </Button>
            <Button
              onClick={() => this.setState({ modalExcluirUsuario: false }, () => this.excluiUsuario(this.state.usuarioInfo))}
              category={'secondary'}
              fontWeight={800}
              width={'50%'}
              full>
              Ok
            </Button>
          </Group>
        </Modal>
        <ModalCadastroEmpresa empresaInfo={this.state.empresaInfo} isOpen={this.state.statusModalEmpresa} onClose={() => this.setState({ statusModalEmpresa: false })} />
        <ModalCadastroCentroCusto centroDeCustoInfo={this.state.centroDeCustoInfo} empresas={this.state.empresas} isOpen={this.state.statusModalCentroCusto} onClose={() => this.setState({ statusModalCentroCusto: false })} />
        <ModalCadastroUsuario centroDeCustos={this.state.centroDeCustos} usuarioInfo={this.state.usuarioInfo} empresas={this.state.empresas} isOpen={this.state.statusModalUsuario} onClose={() => this.setState({ statusModalUsuario: false })} />
        <Header />
        <Main>
          <Body {...this.props} pagina={'administrator'}>
            <Group style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Titulo style={{ marginBottom: '32px' }}>{this.apresentaTitulo()}</Titulo>
            </Group>
            <Group>
              <Tabs tabs={this.tratarNivelUsuario(obj)} abreModal={this.abreModal} pagina={'administrator'} tabSelecionada={this.retornaListaDeBotoes(this.tratarNivelUsuario(obj))} />
            </Group>
          </Body>
        </Main>
      </div>
    )
  }
}

export default AdminHeadquartes
