import React, { Component } from 'react'
import styled from 'styled-components'
import { Post } from '../../utils/request'
import config from '../../middleware/config'

import Title from './ui/Title';
import Modal from './Modal';
import AlertIcon from './ui/AlertIcon'
import AlertNormalIcon from './ui/AlertNormalIcon'
import Button from './ui/Button'
import Subtitle from './ui/Subtitle'

const Group = styled.div`
  display: flex;
`

class ModalAtencao extends Component {
  state = {
    isOpen: false,
    value: false
  }

  componentWillReceiveProps(props) {
    this.setState({ ...props },
      () => document.querySelector('body').style.overflow = this.state.isOpen ? 'hidden' : 'auto'
    )
  }

  submit = () => {
    this.props.pagina === 'salas' && this.state.abrirModal !== 'IRRELEVANT' && window.localStorage.setItem('redirecionamento_edicao_salas', JSON.stringify(true))
    this.props.onSubmit()
  }

  render() {
    return (
      <Modal isOpen={this.state.isOpen}>
        {this.props.status !== 'CRITICAL' && <AlertNormalIcon width={'150px'} />}
        {this.props.status === 'CRITICAL' && <AlertIcon width={'150px'} />}
        <Title>Atenção!</Title>
        <Subtitle>{this.props.texto}</Subtitle>
        <Group>
          <Button
            onClick={() => this.setState({ isOpen: false }, () => this.props.onCancel())}
            category={'secondary'}
            fontWeight={800}
            width={'50%'}
            ghost
            full>
            Cancelar
          </Button>
          <Button
            onClick={() => this.setState({ isOpen: false }, () => this.submit())}
            category={'secondary'}
            fontWeight={800}
            width={'50%'}
            full>
            Ok
          </Button>
        </Group>
      </Modal>
    )
  }
}

class GerenciaFluxoEdicao extends Component {
  state = {
    salvaEdicao: false,
    isLoading: false,
    abrirModal: 'IRRELEVANT',
    textoPopUp: ''
  }

  componentWillMount() {
    if(this.props.pagina === 'evento') {
      window.localStorage.setItem('redirecionamento_edicao_fluxo', JSON.stringify([]))
      window.localStorage.setItem('redirecionamento_edicao_servico', JSON.stringify(false))
    } else if(this.props.pagina === 'salas') {
      window.localStorage.setItem('redirecionamento_edicao_salas', JSON.stringify(false))
    }
  }

  componentWillReceiveProps(props) {
    (props.salvaEdicao && !this.state.salvaEdicao) && this.setState({ salvaEdicao: true }, () => this.gerenciaPopUp())
  }

  gerenciaPopUp = () => {
    const { api: { base, version, routes: { events } } } = config
    var endpoint = ''
    switch (this.props.pagina) {
      case 'evento':
        endpoint = `${base}${version}${events}/${this.props.eventId}/update-type`
        Post(endpoint, this.props.form)
        .then(response => {
          this.setState({ abrirModal: response }, () => {
          this.criaTextoDoPopUp()
          this.gerenciaRedirecionamento()
          this.state.abrirModal === 'IRRELEVANT' && this.props.onSubmit()
        })
        })
        .catch((error) => {
          console.error(error)
          alert(error)
        })
        break;
      case 'hospedagem':
        if(!this.props.briefingId) return this.props.onSubmit()
        endpoint = `${base}${version}/briefing/lodging/${this.props.briefingId}/update-type`
        Post(endpoint, this.props.form)
        .then(response => {
          this.setState({ abrirModal: response }, () => {
            this.criaTextoDoPopUp()
            if(this.state.abrirModal === 'CRITICAL') {
              window.localStorage.setItem('redirecionamento_edicao_servico', JSON.stringify(true))
            }
            this.state.abrirModal === 'IRRELEVANT' && this.props.onSubmit()
          })
        })
        .catch((error) => {
          console.error(error)
          alert(error)
        })
        break;
      case 'salas':
        // TODO: Verificar por que que isso tem que ser feito somente em Salas
        //       Provavelmente é algo envolvendo o this.form da página de salas
        if(!this.props.briefingId) return Promise.resolve().then(() => this.props.onSubmit())
        endpoint = `${base}${version}/briefing/room/${this.props.briefingId}/update-type`
        Post(endpoint, this.props.form)
        .then(response => {
          this.setState({ abrirModal: response }, () => {
            this.criaTextoDoPopUp()
            if(this.state.abrirModal === 'CRITICAL') {
              window.localStorage.setItem('redirecionamento_edicao_servico', JSON.stringify(true))
            }
            this.state.abrirModal === 'IRRELEVANT' && this.props.onSubmit()
          })
        })
        .catch((error) => {
          console.error(error)
          alert(error)
        })
        break;
      case 'aeb':
        if(!this.props.briefingId) return this.props.onSubmit()
        endpoint = `${base}${version}/briefing/foodsAndBeverages/${this.props.briefingId}/update-type`
        Post(endpoint, this.props.form)
        .then(response => {
          this.setState({ abrirModal: response }, () => {
            this.criaTextoDoPopUp()
            if(this.state.abrirModal === 'CRITICAL') {
              window.localStorage.setItem('redirecionamento_edicao_servico', JSON.stringify(true))
            }
            this.state.abrirModal === 'IRRELEVANT' && this.props.onSubmit()
          })
        })
        .catch((error) => {
          console.error(error)
          alert(error)
        })
        break;
      case 'equipamentos':
        if(!this.props.briefingId) return this.props.onSubmit()
        endpoint = `${base}${version}/briefing/equipments/${this.props.briefingId}/update-type`
        Post(endpoint, this.props.form)
        .then(response => {
          this.setState({ abrirModal: response }, () => {
            this.criaTextoDoPopUp()
            if(this.state.abrirModal === 'CRITICAL') {
              window.localStorage.setItem('redirecionamento_edicao_servico', JSON.stringify(true))
            }
            this.state.abrirModal === 'IRRELEVANT' && this.props.onSubmit()
          })
        })
        .catch((error) => {
          console.error(error)
          alert(error)
        })
        break;
      default:
        break;
    }
  }

  verificaSeHouveMudancas = (item) => {
    return this.props.form[item] !== this.props.eventoNaoAlterado[item]
  }

  gerenciaRedirecionamento = () => {
    var listaRedirecionamentoFluxo = ['start', 'end', 'numberOfParticipants']
    var listaRedirecionamentoServico = ['radius']

    var mudancasFluxo = listaRedirecionamentoFluxo.reduce((lista, item) => {
      this.verificaSeHouveMudancas(item) && lista.push(item)
      return lista
    }, [])

    var redirecionaParaOServico = listaRedirecionamentoServico.some(item => this.verificaSeHouveMudancas(item))

    window.localStorage.setItem('redirecionamento_edicao_fluxo', JSON.stringify(mudancasFluxo))
    window.localStorage.setItem('redirecionamento_edicao_servico', JSON.stringify(redirecionaParaOServico))
  }

  criaTextoDoPopUp = () => {
    this.state.abrirModal === 'CRITICAL' && this.setState({ textoPopUp: 'Estas solicitações serão reenviadas aos fornecedores e os orçamentos já recebidos serão desconsiderados.' })
    this.state.abrirModal === 'NORMAL' && this.setState({ textoPopUp: 'Estas alterações serão reenviadas aos fornecedores e mesmo os orçamentos já recebidos poderão ser modificados.' })
  }

  cancelarPopUp = () => this.setState({ abrirModal: 'IRRELEVANT', salvaEdicao: false })

  render () {
    return <ModalAtencao isOpen={this.state.abrirModal !== 'IRRELEVANT'} pagina={this.props.pagina} status={this.state.abrirModal} texto={this.state.textoPopUp} onSubmit={this.props.onSubmit} onCancel={this.cancelarPopUp} />
  }
}

export default GerenciaFluxoEdicao
