import React, { Component } from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../../../styles/themes'
import { Line } from 'react-chartjs-2'
import Tabs from '../../../components/ui/Tabs'

const Group = styled.div`
`

const LabelDados = styled.p`
  font-family: ${defaultTheme.fonts.primary};
  line-height: 1.2;
  font-Weight: 400;
  color: ${defaultTheme.colors.grey.light};
  font-size: 12px;
  padding: 78px 0;
  text-align: center;
`
class GraficoDeLinha extends Component {

  componentDidMount() {
     this.props.init && this.props.init()// execucao uma funcao inicial "bootstrap function"
  }

  render () {
    const data = {
      labels: this.props.labels,
      datasets: [
        {
          label: 'Gastos no período',
          fill: false,
          lineTension: 0,
          backgroundColor: '#a5a5a5',
          borderColor: '#a5a5a5',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: '#a5a5a5',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: '#a5a5a5',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: this.props.dataGastos,
        },
        {
          label: 'Savings no período',
          fill: false,
          lineTension: 0,
          backgroundColor: defaultTheme.colors.whiteLabel.primary,
          borderColor: defaultTheme.colors.whiteLabel.primary,
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: defaultTheme.colors.whiteLabel.primary,
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: defaultTheme.colors.whiteLabel.primary,
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: this.props.dataSavings,
        }
      ]
    }

    return (
      <Group>
        {(data.labels && (data.labels.length > 0))
          ? <>
              <Group style={{ position: 'absolute', right: '20px' }}>
                <Tabs onToggleChange={this.props.onToggleChange} tabs={this.props.obj} />
              </Group>
              <Group style={{ marginTop: '40px' }}>
                <Line
                  data={data} width={300} height={115}
                  options={{
                    maintainAspectRatio: false,
                    legend: { position: 'bottom', labels: { boxWidth: 10, fontFamily: defaultTheme.fonts.primary } },
                    scales: {
                      xAxes: [{
                        gridLines: { display: false },
                        scaleLabel: { display: false }
                      }],
                      yAxes: [{
                        gridLines: { display: false },
                        scaleLabel: { display: false }
                      }],
                    }
                  }}
                />
              </Group>
            </>
          : <LabelDados>Não há dados para exibir</LabelDados>}
      </Group>
    )
  }
}

export default GraficoDeLinha
