import React from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../../styles/themes'

const Title = styled.h1`
  font-family: ${props => props.secondaryFont ? defaultTheme.fonts.secondary : defaultTheme.fonts.primary};
  font-size: 1.3em;
  font-weight: 800;
  line-height: 1.2;
  margin-top: 20px;
`

export default function (props) {
  return <Title {...props} />
}
