/* LIBS */
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import config from '../../../../middleware/config'
import { Post } from '../../../../utils/request'
import Modal from '../../../components/Modal'
import Subtitle from '../../../components/ui/Subtitle'
import LinkIcon from '../../../components/ui/LinkIcon'
import Button from '../../../components/ui/Button'
import Loader from '../../../components/Loader'
import { formatCurrency } from '../../../../utils/money'
import { renderizaNomeDoServico } from '../../../../utils/servicosFornecedor'
import { defaultTheme } from '../../../styles/themes'
import styled from 'styled-components'

const Title = styled.h1`
  font-family: ${defaultTheme.fonts.secondary};
  line-height: 1.2;
  font-size: 1.3em;
  padding-top: 10px;
`

const Subtitulo = styled(Subtitle)`
  margin-bottom: 0;
    @media(max-width: 1024px) {
      font-size: 12px;
    }
`

const Group = styled.div`
  padding: 20px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  @media(max-width: 768px) {
    font-size: 12px;
    padding: 0;
  }
`

const Label = styled(Title)`
  font-family: ${defaultTheme.fonts.primary};
  padding: 0;
  font-size: 14px;
  white-space: nowrap;
  font-weight: 600;
    @media(max-width: 768px) {
      font-size: 12px;
    }
`

const Agrupador = styled.div`
  display: flex;
  cursor: pointer;
`

const Comissao = styled(Label)`
  font-family: ${defaultTheme.fonts.primary};
  font-size: 12px;
  font-weight: 100;
  padding: 0;
  margin-top: 5px;
    @media(max-width: 768px) {
      font-size: 10px;
    }
  `

const Tabela = styled.table`
  border-bottom: 1px solid #c3c3c3;
  td {
    border-bottom: 1px solid #c3c3c3;
    vertical-align:middle;
    padding: 10px 20px;
    @media(max-width: 1024px) {
     padding: 5px 5px;
    }
  }
  tr:nth-child(even) {
    background: #fafafa;
  }
  tr:hover {
    background: #f3f6f9;
  }`

const CheckBox = styled.input`
  cursor: pointer;
`

class TabelaAuditoria extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: false,
      listaDeSelecionados: [],
      selecionarTodos: false,
      isLoading: false
    }
  }

  componentWillReceiveProps (props) {
    var { listaDeSelecionados } = this.state
    listaDeSelecionados = this.props.listaDeHoteis.filter(hotel => hotel.auditStatus).map(hotel => hotel.id) || []
    this.setState({ ...props, listaDeSelecionados },
      () => document.querySelector('body').style.overflow = this.state.isOpen ? 'hidden' : 'auto'
    )
  }

  renderizaMenoresValoresDisponiveis = (budgets, servico) => {
    return budgets[servico] && budgets[servico].reduce((menorValor, budget) => {
      if (menorValor.valor === 0 || budget.cheapestTotalPrice.amount < menorValor.valor) {
        menorValor = {
          valor: budget.cheapestTotalPrice ? budget.cheapestTotalPrice.amount : 0,
          comissao: budget.commission,
          prazoParaPagamento: budget.billingConditions ? budget.billingConditions.days : 0,
          condicoesDePagamento: budget.billingConditions
        }
      }
      return menorValor
    }, { valor: 0, comissao: { fee: { type: 'PERCENTAGE', amount: 0 }, hasCommission: false }, prazoParaPagamento: 0 })
  }

  geraComissao = (fee) => {
    if (fee.type === 'PERCENTAGE') {
      return `${fee.amount}%`
    } else if (fee.type === 'ABSOLUTE') {
      return formatCurrency(fee.amount)
    } else {
      return ''
    }
  }

  pegaCondicoesPagamentoComMenorPrazoParaPagamento = (budgets) => {
    return Object.keys(budgets).reduce((menorValor, servico) => {
      const menoresValoresDisponiveisServicoAtual = this.renderizaMenoresValoresDisponiveis(budgets, servico)
      if (!menorValor) return menoresValoresDisponiveisServicoAtual
      if (menoresValoresDisponiveisServicoAtual.prazoParaPagamento
        && (menorValor.prazoParaPagamento === 0 || menoresValoresDisponiveisServicoAtual.prazoParaPagamento < menorValor.prazoParaPagamento)) {
        menorValor = menoresValoresDisponiveisServicoAtual
      }
      return menorValor
    }, false)
  }

  alterarListaDeSelecionados = (id) => {
    var { listaDeSelecionados } = this.state
    const index = listaDeSelecionados.indexOf(id)
    if(index === -1) {
      listaDeSelecionados.push(id)
    } else {
      listaDeSelecionados.splice(index, 1)
    }
    this.setState({ listaDeSelecionados })
  }

  selecionarTodos = () => {
    this.setState({ selecionarTodos: !this.state.selecionarTodos }, () => {
      if(this.state.selecionarTodos) {
        const listaDeSelecionados = this.props.listaDeHoteis.filter(hotel => !(hotel.waitingBudget || hotel.unavailability)).map(hotel => hotel.id)
        this.setState({ listaDeSelecionados })
      } else {
        this.setState({ listaDeSelecionados: [] })
      }
    })
  }

  enviarFornecedores = () => {
    this.setState({
      isLoading: true
    })
    const { history } = this.props
    const { api: { base, version, routes: { events } } } = config
    const endpoint = `${base}${version}${events}/${this.props.event.event.id}/audit`
    Post(endpoint, this.generateJSON())
    .then(() => {
      history.push(`/organizer`)
      history.push(`/organizer/event/${this.props.event.event.id}/budget/place`)
    }).catch(err => {
      console.error(err.message)
      this.setState({
        isLoading: false
      })
      alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
    })
  }

  apresentaComissao = (hotel, servico) => {
    if(!this.renderizaMenoresValoresDisponiveis(hotel.budgets, servico) || this.renderizaMenoresValoresDisponiveis(hotel.budgets, servico).valor === 0)
      return 'Indisponível'
    else {
      if(this.renderizaMenoresValoresDisponiveis(hotel.budgets, servico).comissao.hasCommission) {
        return `Comissão: ${this.renderizaMenoresValoresDisponiveis(hotel.budgets, servico).comissao.fee ?
          this.geraComissao(this.renderizaMenoresValoresDisponiveis(hotel.budgets, servico).comissao.fee) :
          ''}`
      }
      else {
        return 'Não comissionado'
      }
    }
  }

  generateJSON = () => {
    return this.props.listaDeHoteis.filter(hotel => !(hotel.waitingBudget || hotel.unavailability)).map(hotel => {
      return {
        supplierId: hotel.id,
        auditStatus: this.state.listaDeSelecionados.indexOf(hotel.id) !== -1
      }
    })
  }

  render () {
    const listaDeHoteis = this.props.listaDeHoteis
    const event = this.props.event
    const requestedServices = event && event.services ? Object.keys(event.services).filter(service => event.services[service].requested) : []
    return (
      <Modal maxWidth={'1200px'} isOpen={this.state.isOpen} hasCloseButton onClose={() => this.props.onClose && this.props.onClose()}>
        {this.state.isLoading && <Loader />}
        <Group>
          <Title style={{ textAlign: 'left' }}>Tabela Comparativa dos fornecedores</Title>
          <Subtitulo style={{ textAlign: 'left', marginBottom: '30px' }}>Selecione os fornecedores que deseja exibir para o cliente e clique em 'Salvar Fornecedores'.</Subtitulo>
          <Tabela>
            <tbody>
              <tr>
                <td>
                  <Agrupador onClick={() => this.selecionarTodos()} >
                    <CheckBox checked={this.state.selecionarTodos} style={{ margin: 'auto 0' }} type='checkbox' />
                    <Subtitulo style={{ textAlign: 'left', paddingLeft: '5px' }}><span style={{ fontWeight: 800 }}>{this.state.listaDeSelecionados.length}</span> de <span style={{ fontWeight: 800 }}>{listaDeHoteis.filter(hotel => !(hotel.waitingBudget || hotel.unavailability)).length}</span> selecionados</Subtitulo>
                  </Agrupador>
                </td>
                <td></td>
                <td style={{maxWidth: '150px'}}><Subtitulo>Prazo para pagamento (% de antecipação) </Subtitulo></td>
                {requestedServices.filter(servico => servico !== 'transfer').map(servico => {
                  return <td key={servico}><Subtitulo> {renderizaNomeDoServico(servico)}</Subtitulo></td>} )}
                <td><Subtitulo style={{ fontWeight: '900', textAlign: 'left' }}>Total</Subtitulo></td>
              </tr>
              {listaDeHoteis.filter(hotel => !(hotel.waitingBudget || hotel.unavailability))
              .map(hotel =>{
                hotel.condicoesDePagamento = this.pegaCondicoesPagamentoComMenorPrazoParaPagamento(hotel.budgets).condicoesDePagamento
                return hotel
              })
              .map((hotel, index) => <tr key={index}>
                <td>
                  <Agrupador onClick={() => this.alterarListaDeSelecionados(hotel.id)} >
                    <CheckBox checked={this.state.listaDeSelecionados.indexOf(hotel.id) !== -1} style={{ margin: 'auto 0' }} type='checkbox' />
                    <Label style={{ textAlign: 'left', paddingLeft: '5px' }}>{hotel.nome}</Label>
                  </Agrupador>
                </td>
                <td>
                  <Agrupador onClick={hotel.onClick} >
                    <Link style={{ color: defaultTheme.colors.primary.default, fontSize: '15px', fontWeight: '600', fontFamily: defaultTheme.fonts.primary, margin: 'auto', paddingRight: '5px' }} to={'#'} onClick={hotel.onClick}>
                      ver
                    </Link>
                    <LinkIcon style={{ width: '25px' }} />
                  </Agrupador>
                </td>
                <td>
                  <Label>
                    {hotel.condicoesDePagamento && `${hotel.condicoesDePagamento.days} dia${(hotel.condicoesDePagamento.days > 1 || '') && 's'}`}
                  </Label>
                  <Comissao>{`(${hotel.condicoesDePagamento && hotel.condicoesDePagamento.percentage}%)`}</Comissao>
                  </td>
                {requestedServices.filter(servico => servico !== 'transfer').map(servico => {
                  return <td key={servico}>
                  <Label>
                    {!this.renderizaMenoresValoresDisponiveis(hotel.budgets, servico) || this.renderizaMenoresValoresDisponiveis(hotel.budgets, servico).valor === 0 ?
                      '' :
                      formatCurrency(this.renderizaMenoresValoresDisponiveis(hotel.budgets, servico).valor)}
                  </Label>
                  <Comissao>
                    {this.apresentaComissao(hotel, servico)}
                  </Comissao>
                </td>} )}
                <td><Label style={{ textAlign: 'left' }}>{formatCurrency(hotel.preco)}</Label></td>
              </tr>
              )}
            </tbody>
          </Tabela>
          <Button
            style={{ width: '400px', marginLeft: 'auto', marginTop: '20px' }}
            category={'secondary'}
            onClick={this.enviarFornecedores}
            fontWeight={800}>
            Salvar Fornecedores
        </Button>
        </Group>
      </Modal>
    )
  }
}

export default TabelaAuditoria
