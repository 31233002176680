/* LIBS */
import React, { Component } from 'react'
import Modal from '../../../components/Modal'
import Loader from '../../../components/Loader'
import styled from 'styled-components'
import Title from '../../../components/ui/Title'
import Input from '../../../components/ui/Input'
import Button from '../../../components/ui/Button'
import imgEmpresas from '../../../styles/img/admin_img_novo_usuario.svg'
import Select from '../../../components/ui/Select'
import MultiSelect from '../../../components/ui/MultiSelect'

/* API */
import config from '../../../../middleware/config'
import { Post, Put } from '../../../../utils/request'

const Group = styled.div`
`

const Img = styled.img`
  width: 100%;
`

class CadastroUsuario extends Component {
  constructor (props) {
    super()
    this.state = {
      isOpen: false,
      isLoading: false,
      id: (props.usuarioInfo && props.usuarioInfo.id) || '',
      name: (props.usuarioInfo && props.usuarioInfo.name) || '',
      email: (props.usuarioInfo && props.usuarioInfo.email) || '',
      roles: (props.usuarioInfo && props.usuarioInfo.roles) || '',
      accountId: (props.usuarioInfo && props.usuarioInfo.accountId) || '',
      centroDeCustos: (props.usuarioInfo && props.usuarioInfo.costCenters && props.usuarioInfo.costCenters.map(item => {
        return {
          value: item.id,
          label: item.name
        }
      })) || ''
    }
  }

  componentWillReceiveProps(props) {
    this.setState({
      id: (props.usuarioInfo && props.usuarioInfo.id) || '',
      name: (props.usuarioInfo && props.usuarioInfo.name) || '',
      email: (props.usuarioInfo && props.usuarioInfo.email) || '',
      roles: (props.usuarioInfo && props.usuarioInfo.roles) || '',
      accountId: (props.usuarioInfo && props.usuarioInfo.accountId) || '',
      centroDeCustos: (props.usuarioInfo && props.usuarioInfo.costCenters && props.usuarioInfo.costCenters.map(item => {
        return {
          value: item.id,
          label: item.name
        }
      })) || ''
    })
  }

  verifaRole = (nivel) => {
    const roles = window.localStorage.getItem('user_roles') !== 'undefined' && JSON.parse(window.localStorage.getItem('user_roles'))
    return roles && roles.find(role => role === nivel)
  }

  submit = () => {
    const ERROR_MESSAGE = "Não foi possível cadastrar o usuário. Tente novamente ou entre em contato com o nosso suporte."
    const validacao = this.state.roles === 'BRANCH_ADMIN' ? this.isEmptyAdminMandatoryFields() : this.isEmptyCostCenterMandatoryFields()
    if (validacao) return alert('Preencha todos os campos obrigatórios.')
    this.setState({ isLoading: true }, () => {
      const { api: { base, version } } = config
      const createAccount = `${base}${version}/users/cost-centers${this.state.id && `/${this.state.id}`}`
      if(this.state.id) {
        Put(createAccount, {
          name: this.state.name,
          email: this.state.email,
          roles: this.state.roles,
          costCenter: this.state.centroDeCustos.map(item => item.value),
          accountId: this.state.accountId
        })
        .then(() => {
          alert('Usuário atualizado com sucesso!')
          this.setState({ isLoading: false })
          this.props.onClose && this.props.onClose()
          window.location.reload()
        })
       .catch(error => {
          alert((error && error.message) || ERROR_MESSAGE)
          this.setState({ isLoading: false })
       })
      } else {
        Post(createAccount, {
          name: this.state.name,
          email: this.state.email,
          roles: this.state.roles,
          costCenter: this.state.centroDeCustos && this.state.centroDeCustos.map(item => item.value),
          accountId: this.state.accountId
        })
        .then(() => {
          alert('Usuário criado com sucesso!')
          this.setState({ isLoading: false })
          this.props.onClose && this.props.onClose()
          window.location.reload()
        })
       .catch(error => {
          alert((error && error.message) || ERROR_MESSAGE)
          this.setState({ isLoading: false })
       })
      }
    }
    )
  }

  isEmptyCostCenterMandatoryFields = () => {
    return this.state.centroDeCustos.length === 0 || !this.state.name || !this.state.email || !this.state.roles
  }

  isEmptyAdminMandatoryFields = () => {
    return !this.state.name || !this.state.email || !this.state.roles
  }

  retornaListaDeCentroDeCustos = () => {
    const listaDeCentroDeCustos = this.props && this.props.centroDeCustos[0]
    return listaDeCentroDeCustos ? listaDeCentroDeCustos.filter(item => item.accountId === this.state.accountId).map(item => {
      return { value: item.id || item._id, label: item.name }
    }) : []
  }

  retornaListaDeEmpresas = () => {
    const listaDeEmpresas = []
    this.props.empresas[0] && listaDeEmpresas.push(this.props.empresas[0])
    const listaFiliais = (this.props.empresas[0] && this.props.empresas[0].branchOffices) || []
    listaFiliais.forEach(item => listaDeEmpresas.push(item))
    return listaDeEmpresas
  }

  render () {
    return (
      <Modal maxWidth={'800px'} isOpen={this.props.isOpen} hasCloseButton onClose={() => this.props.onClose && this.props.onClose()}>
        { this.state.isLoading ? <Loader /> : null }
        <Group style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', padding: '20px 0' }}>
          <Group style={{width: '270px', padding: '20px'}}>
            <Img src={imgEmpresas}/>
            <Title style={{marginTop: '10px'}}>Cadastro de<br/> novo usuário</Title>
          </Group>
          <Group style={{width: '50%'}}>
            <Select
              isRequired
              label={'Nível'}
              value={this.state.roles}
              onChange={(input) => this.setState({ roles: input.target.value })}>
              <option value={''}>Selecione um nível</option>
              {this.verifaRole('HEADQUARTER_ADMIN') && <option value={'BRANCH_ADMIN'}>Administrador</option>}
              <option value={'COSTCENTER_ADMIN'}>Administrador de centro de custos</option>
              <option value={'STANDARD'}>Básico</option>
            </Select>
            <Select
              isRequired
              label={'Empresa'}
              value={this.state.accountId}
              onChange={(input) => this.setState({ accountId: input.target.value, centroDeCustos: [] })}>
              <option value={''}>Selecione uma empresa</option>
              {this.retornaListaDeEmpresas().map(item => <option value={item.id}>{item.name}</option>)}
            </Select>
            {this.state.accountId && <MultiSelect
              isRequired
              placeholder={'Selecione um centro de custo'}
              noOptionsMessage={'Nenhuma opção disponível'}
              options={this.retornaListaDeCentroDeCustos()}
              value={this.state.centroDeCustos}
              onChange={(value) => this.setState({ centroDeCustos: value })}
              label={'Centro de custo'} />}
             <Input
              isRequired
              label={'Nome completo'}
              placeholder='Digite o nome completo'
              value={this.state.name}
              onChange={(input) => this.setState({ name: input.target.value })}
            />
            <Input
              isRequired
              label={'Email'}
              placeholder='Digite o email'
              value={this.state.email}
              onChange={(input) => this.setState({ email: input.target.value })}
            />
            <Button
              category={'secondary'}
              fontWeight={800}
              onClick={this.submit}
              full>
              {this.state.id ? 'Atualizar' : 'Cadastrar'}
            </Button>
          </Group>
        </Group>
      </Modal>
    )
  }
}

export default CadastroUsuario
