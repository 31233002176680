/* LIBS */
import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
/* ACTIONS */
import { forgotPassword } from '../redux/actions/auth'
/* I18n */
import i18n from '../i18n'
/* UTILS */
import { isValidEmail } from '../utils/email'
/* UI */
import LogoEvnts from './components/ui/LogoEvnts'
import Input from './components/ui/Input'
import Button from './components/ui/Button'
import Alert from './components/ui/Alert'
import Loader from './components/Loader'

const LogoWrap = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 20px 0px;
  width: 100%;
  @media (min-width: 768px) {
    margin: 40px 0px;
  }
`

const Main = styled.main`
  display: flex;
  height: 90vh;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #FFF;
  @media (min-width: 768px) {
    background-color: #FAFAFA;
    height: 100vh;
  }
`
const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  padding: 10px 10px;
  box-sizing: border-box;
  width: 100%;
`

const ForgotWrapper = styled.div`
  border-radius: 3px;
  margin: 0 5px;
  max-width: 390px;
  background-color: #FFF;
  transition: all 0.2s linear;
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 768px) {
    border: none;
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
  }
`
const Description = styled.p`
  font-size: 16px;
  color: #333;
  padding: 10px 20px;
  font-family: 'Open Sans', sans-serif;
`

const ReturnLogin = styled.p`
  cursor: pointer;
  color: #52C7B8;
  font-size: 14px;
  width: 100%;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  padding: 10px 0px 20px 0px;
  &:hover {
    font-weight: bold;
  }
`

class ForgotPassword extends Component {
  constructor () {
    super()
    this.state = {
      username: '',
      error: false
    }
  }

  submit (e) {
    e.preventDefault()
    this.setState({error: false})
    const { username } = this.state
    if (isValidEmail(username)) {
      this.props.forgotPassword(username)
    } else {
      this.setState({error: i18n.t('forgot_password.user_error', this.props.language)})
    }
  }

  usernameChange (username) {
    this.setState({username: username.target.value.trim()})
  }

  render () {
    const { language, history, isFetching } = this.props
    return (
      <Main>
        {isFetching ? <Loader /> : null}
        <ForgotWrapper>
          <LogoWrap>
            <LogoEvnts primary style={{ width: '200px' }} />
          </LogoWrap>
          <Description>
            {i18n.t('forgot_password.description', { language })}
          </Description>
          <Form onSubmit={this.submit.bind(this)} method={'POST'}>
            {this.state.error
              ? <Alert type={'negative'}>
                {this.state.error}
              </Alert> : null}
            <Input
              type={'email'}
              label={i18n.t('forgot_password.email', { language })}
              placeholder={i18n.t('forgot_password.email_placeholder', { language })}
              onChange={this.usernameChange.bind(this)}
              error={(this.state.username && !isValidEmail(this.state.username))}
              name={'username'}
            />
            <ReturnLogin onClick={() => history.push('/signin')}>{i18n.t('forgot_password.return_to_login', { language })}</ReturnLogin>
            <Button
              type='submit'
              category={'secondary'}
              fontWeight={800}
              full>
              {i18n.t('forgot_password.send_action', { language })}
            </Button>
          </Form>
        </ForgotWrapper>
      </Main>
    )
  }
}

function mapStateToProps (state) {
  const { app, auth } = state
  const { language } = app
  const { isAuthenticated, isFetching } = auth
  return {
    language,
    isAuthenticated,
    isFetching
  }
}

export default connect(mapStateToProps, { forgotPassword })(ForgotPassword)
