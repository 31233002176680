import React, { Component } from 'react'
import DayPicker, { DateUtils } from 'react-day-picker'
import { monthNames, daysNames, shortDaysNames } from './config'
import 'react-day-picker/lib/style.css'
import './style.css'

export default class Datepicker extends Component {
  static defaultProps = {
    numberOfMonths: 2,
    onChange: () => {},
    dates: {
      from: null,
      to: null
    }
  }

  constructor(props) {
    super(props)
    var dates = {
      from: props.dates.from && new Date(props.dates.from),
      to: props.dates.to && new Date(props.dates.to)
    }
    this.state = dates
    this.props.onChange(this.state)
  }

  handleDayClick = (day) => {
    var range = DateUtils.addDayToRange(day, this.state)
    if(!range.to) {
      range.to = DateUtils.addDayToRange(day, this.state).from
    }
    this.setState(range, () => {
      this.props.onChange(this.state)
    })
  }


  render() {
    const { from, to } = this.state
    const modifiers = { start: from , end: to }
    return (
      <div>
        <DayPicker
          month={(from && !isNaN(from.getTime())) && from}
          months={monthNames}
          weekdaysLong={daysNames}
          weekdaysShort={shortDaysNames}
          className='Selectable EvntsColor'
          numberOfMonths={this.props.numberOfMonths}
          placeholder={'Select dates'}
          onDayClick={this.handleDayClick}
          modifiers={modifiers}
          selectedDays={[from, { from, to }]} />
      </div>
    )
  }
}
