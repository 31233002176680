const API = '/api/v1'

export const REQUEST_BRIEFING_ROOM = 'REQUEST_BRIEFING_ROOM'
export const RECEIVE_BRIEFING_ROOM = 'RECEIVE_BRIEFING_ROOM'
export const BRIEFING_ROOM_ERROR = 'BRIEFING_ROOM_ERROR'

function requestBriefingRoom(data) {
  return {
    type: REQUEST_BRIEFING_ROOM,
    isFetching: true,
    data
  }
}

function receiveBriefingRoom(briefing) {
  return {
    type: RECEIVE_BRIEFING_ROOM,
    isFetching: false,
    briefing: briefing.data
  }
}

export function briefingRoomError (message) {
  return {
    type: BRIEFING_ROOM_ERROR,
    isFetching: false,
    message
  }
}

export function fetchBriefingRoom({event}) {
  const token = window.localStorage.getItem('user_token')
  const configFetch = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  }

  return dispatch => {
    dispatch(requestBriefingRoom(event))
    const userType = window.localStorage.getItem('user_type')
    const endpoint = userType === 'RESELLER' ? 'consultant/event' : 'event'
    return window.fetch(`${API}/${endpoint}/${event}/briefing/room`, configFetch)
      .then(response => {
        if (response.status >= 401 && response.status <= 403) window.localStorage.clear()
        if (response.ok) {
          return response.json()
        } else {
          throw new Error(response.statusText)
        }
      }).then((response) => {
        if (!response.success) {
          dispatch(briefingRoomError(response.message))
          return Promise.reject(response)
        } else {
          dispatch(receiveBriefingRoom(response))
          return Promise.resolve(response)
        }
      }).catch(err => {
        dispatch(briefingRoomError(err))
        console.log('Error: ', err)
        return Promise.reject(err)
      })
  }
}
