import FileSaver from 'file-saver'

export const GetCsv = (endpoint, isAuthenticated = true, clearIfNotAllowed = true, options) => {
  return requestFile(endpoint, 'GET', null, isAuthenticated, clearIfNotAllowed, {
    contentType: 'text/csv',
    ...options
  })
}
export const Get = (endpoint, data, isAuthenticated = true, clearIfNotAllowed = true) => {
  return request(endpoint, 'GET', data || null, isAuthenticated, clearIfNotAllowed)
}
export const Post = (endpoint, data, isAuthenticated = true, clearIfNotAllowed = true) => {
  return request(endpoint, 'POST', data, isAuthenticated, clearIfNotAllowed)
}
export const Put = (endpoint, data, isAuthenticated = true, clearIfNotAllowed = true) => {
  return request(endpoint, 'PUT', data, isAuthenticated, clearIfNotAllowed)
}
export const Delete = (endpoint, data, isAuthenticated = true, clearIfNotAllowed = true) => {
  return request(endpoint, 'DELETE', data || null, isAuthenticated, clearIfNotAllowed)
}

export const requestFile = (endpoint, method, data, isAuthenticated = true, clearIfNotAllowed = true, options) => {
  const configFetch = {
    method,
    headers: {
      'Content-Type': options.contentType
    }
  }
  if (isAuthenticated) {
    configFetch.headers['Authorization'] = window.localStorage.getItem('user_token')
  }
  if (data) {
    configFetch.body = JSON.stringify(data)
  }
  return window.fetch(endpoint, configFetch)
    .then(response => response.blob())
    .then(blob => {
      const date = new Date()
      const name = options.name || `arquivo-${date.getDate()}-${(date.getMonth() + 1)}-${date.getFullYear()}.csv`
      FileSaver.saveAs(blob, name)
    })
}

export const request = (endpoint, method, data, isAuthenticated = true, clearIfNotAllowed = true) => {
  const configFetch = {
    method,
    headers: {
      'content-type': 'application/json'
    }
  }
  if (isAuthenticated) {
    configFetch.headers.authorization = window.localStorage.getItem('user_token')
  }
  if ((method === 'POST' || method === 'PUT') && data) {
    configFetch.body = JSON.stringify(data)
  }
  return window.fetch(endpoint, configFetch).then(response => {
    if (response.status === 401 && clearIfNotAllowed) window.localStorage.clear()
    if (response.ok) {
      return response.json()
    } else {
      console.error(response.statusText)
      throw new Error(response.statusText)
    }
  }).then((response) => {
    if (!response.success) throw new Error(response.message)

    return Promise.resolve(response.data)
  }).catch(error => {
    if (error.message === 'Unauthorized') window.location.href = '/signin'
    throw new Error(error.message)
  })
}
