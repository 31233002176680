import React from 'react'
import styled from 'styled-components'
import Icons from './Icons'

import { defaultTheme } from '../../styles/themes'
import { renderizaNomeDoServico } from '../../../utils/servicosFornecedor'

const CircleIcon = styled.p`
  border-radius: 20px;
  font-size: 25px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid;
  padding: 10px;
  width: 30px;
  height: 30px;
  position: relative;
`

export default function (props) {
  return (
    <CircleIcon title={renderizaNomeDoServico(props.title)} style={props.style}>
      <Icons
        icon={props.icon}
        width={30}
        height={15}
        color={props.color || defaultTheme.colors.text.darker}
        style={{
          verticalAlign: 'middle',
          marginLeft: props.marginLeft || '-14px',
          marginTop: props.marginTop || '-25px'
        }}
      />
      {props.selected && <Icons
        icon='checkmark-outline'
        width={16}
        height={16}
        color={defaultTheme.colors.primary.default}
        style={{
          verticalAlign: 'middle',
          marginLeft: '-14px',
          marginTop: '-25px',
          position: 'absolute',
          right: '-4px',
          bottom: '-6px',
          backgroundColor: 'white',
          borderRadius: '50%'
        }}
      />}
    </CircleIcon>
  )
}
