import React, { Component } from 'react'
import styled from 'styled-components'
/* STYLES */
import { defaultTheme } from '../../styles/themes'
/* UI */
import Button from '../../components/ui/Button'
import Input from '../../components/ui/Input'
import Select from '../../components/ui/Select'
import Alert from '../../components/ui/Alert'
import Loader from '../../components/Loader'
/* COMPONENTS */
import UploadField from '../../components/UploadField'
import Icons from '../../components/ui/Icons'

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  padding: 5px 10px;
  box-sizing: border-box;
  width: 100%;
`

const Row = styled.div`
  display: flex;
  width: 100%;
`
const Label = styled.label`
  color: #484848;
  box-sizing: border-box;
  padding: 5px 10px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  min-height: 25px;
  width: '100%';
`

const Required = styled.strong`
  color: ${defaultTheme.colors.primary.default};
  font-weight: bold;
`

const Galery = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  width: 100%;
  padding: 0px 10px 40px 10px;
  flex-direction: row;
`
const Frame = styled.div`
  background-color: #CCCCCC;
  width: 120px;
  height: 120px;
  margin: 10px 5px;
  display: inherit;
  position: relative;
`

const Photo = styled.img`
  width: 100%;
  height: 100%;
`
const BluePrint = styled.div`
  align-items: center;
  display: flex;
  margin: 0px 10px;
`

const RemovePhotoBackground = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  left: 0px;
`
const RemovePhoto = styled.div`
  cursor: pointer;
  right: 5px;
  position: absolute;
  bottom: 5px;
  z-index: 1;
`

class EditRoomForm extends Component{
  constructor() {
    super()
    this.state = {
      error: false,
      isLoading: false,
      room: {
        name: '',
        footage: '',
        rooms_height: '',
        floor: '',
        auditorium: '',
        classroom: '',
        fishbone: '',
        u_format: '',
        round_table: '',
        cocktail: '',
        taxes: '',
        blue_print: '',
        photos: []
      },
      errors: {
        name: '',
        footage: '',
        rooms_height: '',
        floor: '',
        auditorium: '',
        classroom: '',
        fishbone: '',
        u_format: '',
        round_table: '',
        cocktail: '',
        taxes: '',
        blue_print: '',
        photos: ''
      }
    }
  }

  componentWillMount() {
    const { room } = this.props
    if (room) this.loadRoom(room)
  }

  loadRoom(id) {
    const token = window.localStorage.getItem('user_token')
    this.setState({isLoading: true})
    fetch(`/api/v1/rooms/${id}`, {
      method: 'GET',
      headers: {
        'Authorization': token,
        'Content-type': 'application/json'
      }
    })
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error(response)
      }
    })
    .then(response => {
      this.setState({isLoading: false})
      if (response.success) {
        const room = response.data
        const { capacity } = room
        this.setState({
          room: {
            name: room.name,
            footage: room.footage,
            rooms_height: room.rooms_height,
            floor: room.floor,
            auditorium: capacity.auditorium,
            classroom: capacity.classroom,
            fishbone: capacity.fishbone,
            u_format: capacity.u_format,
            round_table: capacity.round_table,
            cocktail: capacity.cocktail,
            taxes: room.taxes.amount,
            blue_print: room.blue_print,
            photos: room.photos || []
          }
        })
      } else {
        console.error(response)
      }
    })
    .catch(e => {
      this.setState({isLoading: false})
      console.error(e)
    })
  }

  bluePrintUpload (file) {
    const { room } = this.state
    room.blue_print = file
    this.setState({room: room})
  }

  removeBluePrint(e) {
    const remove = window.confirm('Deseja remover a planta baixa?')
    if (remove) {
      const { room } = this.state
      room.blue_print = ''
      this.setState({room: room})
    }
  }

  imagesUpload(file) {
    const { room } = this.state
    room.photos.push(file)
    this.setState({room: room})
  }

  removeImages(image) {
    const remove = window.confirm('Deseja remover a foto selecionada?')
    if (remove) {
      const { room } = this.state
      room.photos.splice(image, 1)
      this.setState({room: room})
    }
  }

  handlerValue (input) {
    const { room } = this.state
    room[input.target.name] = input.target.value
    this.setState({room: room})
  }


  submit(e) {
    e.preventDefault()
    this.setState({error: false})
    const {name, footage, rooms_height, floor, auditorium, classroom, fishbone, u_format, round_table, cocktail, taxes, photos, blue_print} = this.state.room
    if (this.validateForm(this.state.room) && this.validatePhotos(this.state.room)) {
      this.props.onSubmit({
        name,
        footage,
        rooms_height,
        floor,
        capacity: {
          auditorium,
          classroom,
          fishbone,
          u_format,
          round_table,
          cocktail
        },
        blue_print,
        photos,
        taxes
      })
    } else {
      !this.validatePhotos(this.state.room) && this.setState({error: 'É necessário adicionar pelo menos uma foto da sala'})
      !this.validateForm(this.state.room) && this.setState({error: 'É necessário preencher todos os campos obrigatórios'})
    }
  }

  validatePhotos({ photos }) {
    return photos.length > 0
  }

  validateForm({name, footage, rooms_height, floor, auditorium, classroom, fishbone, u_format, round_table, cocktail, taxes}) {
    const fields = ![name, footage, rooms_height, floor, taxes].some(e => e === '')
    const roomsType = [auditorium, classroom, fishbone, u_format, round_table, cocktail].some(e => e !== '')

    var handleErrors = {
      name: !name,
      footage: !footage,
      rooms_height: !rooms_height,
      floor: !floor,
      auditorium: ![auditorium, classroom, fishbone, u_format, round_table, cocktail].some(e => e !== ''),
      classroom: ![auditorium, classroom, fishbone, u_format, round_table, cocktail].some(e => e !== ''),
      fishbone: ![auditorium, classroom, fishbone, u_format, round_table, cocktail].some(e => e !== ''),
      u_format: ![auditorium, classroom, fishbone, u_format, round_table, cocktail].some(e => e !== ''),
      round_table: ![auditorium, classroom, fishbone, u_format, round_table, cocktail].some(e => e !== ''),
      cocktail: ![auditorium, classroom, fishbone, u_format, round_table, cocktail].some(e => e !== ''),
      taxes: !(taxes >= 0)
    }
    this.setState({ errors: handleErrors })

    return fields && roomsType
  }

  render() {
    const { photos, blue_print } = this.state.room
    const { room } = this.props
    return (
      <Form onSubmit={this.submit.bind(this)} novalidate method={'POST'}>
        {this.state.isLoading ? <Loader/> : null}
        {this.state.error
          ? <Alert type={'negative'}>
            {this.state.error}
          </Alert> : null}
        <Input
          type={'text'}
          label={'Nome da sala'}
          placeholder={'Digite o nome da sala'}
          name={'name'}
          error={this.state.errors.name}
          value={this.state.room.name}
          onChange={this.handlerValue.bind(this)}
          isRequired
        />
        <Row>
          <Input
            type={'number'}
            label={'Metragem'}
            rightIcon={'m²'}
            name={'footage'}
            pattern={'[0-9]+([,\\.][0-9]+)?'}
            title='Deve conter apenas números, ponto e virgula.'
            error={this.state.errors.footage}
            value={this.state.room.footage}
            onChange={this.handlerValue.bind(this)}
            isRequired
          />
          <Input
            type={'number'}
            label={'Pé direito'}
            rightIcon={'metros'}
            name={'rooms_height'}
            pattern={'[0-9]+([,\\.][0-9]+)?'}
            title='Deve conter apenas números, ponto e virgula.'
            error={this.state.errors.rooms_height}
            value={this.state.room.rooms_height}
            onChange={this.handlerValue.bind(this)}
            isRequired
          />
        </Row>
        <Select
          label={'Piso'}
          name={'floor'}
          value={this.state.room.floor}
          error={this.state.errors.floor}
          onChange={this.handlerValue.bind(this)}
          isRequired>
          <option value=''>Selecione uma opção</option>
          <option value='carpete'>Carpete</option>
          <option value='madeira'>Madeira</option>
          <option value='piso_frio'>Piso Frio</option>
        </Select>
        <Label>Capacidade<Required>*</Required></Label>
        <Input
          type={'number'}
          min={0}
          label={'Auditório'}
          placeholder={'Nº de pessoas'}
          name={'auditorium'}
          error={this.state.errors.auditorium}
          value={this.state.room.auditorium}
          onChange={this.handlerValue.bind(this)}
          type_placeholder={'side'}
        />
        <Input
          type={'number'}
          min={0}
          label={'Escolar'}
          placeholder={'Nº de pessoas'}
          name={'classroom'}
          error={this.state.errors.classroom}
          value={this.state.room.classroom}
          onChange={this.handlerValue.bind(this)}
          type_placeholder={'side'}
        />
        <Input
          type={'number'}
          min={0}
          label={'Espinha de Peixe'}
          placeholder={'Nº de pessoas'}
          name={'fishbone'}
          error={this.state.errors.fishbone}
          value={this.state.room.fishbone}
          onChange={this.handlerValue.bind(this)}
          type_placeholder={'side'}
        />
        <Input
          type={'number'}
          min={0}
          label={'Formato U'}
          placeholder={'Nº de pessoas'}
          name={'u_format'}
          error={this.state.errors.u_format}
          value={this.state.room.u_format}
          onChange={this.handlerValue.bind(this)}
          type_placeholder={'side'}
        />
        <Input
          type={'number'}
          min={0}
          label={'Mesa Redonda'}
          placeholder={'Nº de pessoas'}
          name={'round_table'}
          error={this.state.errors.round_table}
          value={this.state.room.round_table}
          onChange={this.handlerValue.bind(this)}
          type_placeholder={'side'}
        />
        <Input
          type={'number'}
          min={0}
          label={'Coquetel'}
          placeholder={'Nº de pessoas'}
          name={'cocktail'}
          error={this.state.errors.cocktail}
          value={this.state.room.cocktail}
          onChange={this.handlerValue.bind(this)}
          type_placeholder={'side'}
        />
        <Input
          type={'number'}
          label={'Valor das taxas (em %)'}
          rightIcon={'%'}
          name={'taxes'}
          error={this.state.errors.taxes}
          value={this.state.room.taxes}
          onChange={this.handlerValue.bind(this)}
          isRequired
        />
        <UploadField
          placeholder={'Planta baixa'}
          text={'Anexar planta baixa'}
          onFileUpload={this.bluePrintUpload.bind(this)} />
        {blue_print ?
          <RenderBluePrint
            bluePrint={blue_print}
            removeBluePrint={this.removeBluePrint.bind(this)} />
          : null}
        <UploadField
          placeholder={'Fotos da sala'}
          isRequired
          text={'Anexar fotos'}
          onFileUpload={this.imagesUpload.bind(this)} />
        {photos.length
          ? <RenderGalery photos={photos} removeImages={this.removeImages.bind(this)} />
          : null
        }
        {this.state.error
          ? <Alert type={'negative'}>
            {this.state.error}
          </Alert> : null}
        <Button
          type='submit'
          category={'secondary'}
          fontWeight={800}
          full>
          {room ? 'Atualizar' : 'Cadastrar'}
        </Button>
      </Form>
    )
  }
}

const RenderGalery = ({photos, removeImages}) => {
  return <Galery>
        {
          photos.map((p, i) => {
            return <Frame key={p}>
                      <Photo src={p} />
                      <RemovePhotoBackground>
                        <RemovePhoto>
                          <Icons
                            icon={'close'}
                            color={'#fff'}
                            width={'20'}
                            onClick={() => removeImages(i)} />
                        </RemovePhoto>
                      </RemovePhotoBackground>
                  </Frame>
          })
        }
      </Galery>
}

const RenderBluePrint = ({bluePrint, removeBluePrint}) => {
  return <BluePrint>
          <a href={bluePrint} rel='noopener noreferrer' target='_blank' download>
            Planta baixa anexada
          </a>
          <Icons
            icon={'close'}
            color={'#333'}
            width={'20'}
            style={{marginLeft: '15px', cursor: 'pointer'}}
            onClick={removeBluePrint}/>
        </BluePrint>
}

export default EditRoomForm
