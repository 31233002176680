/* LIBS */
import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import Icons from '../../components/ui/Icons'
import { defaultTheme } from '../../styles/themes'

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 20px;
  background-color: rgba(0,0,0,0.35);
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  /* visibility: ${props => props.isOpen ? 'visible' : 'hidden'} */
  z-index: 9999;
`

const Close = styled.div`
  position: absolute;
  padding: 10px;
  right: 0px;
  top: 0px;
  cursor: pointer;
`

const Card = styled.div`
  margin: 5% auto;
  padding: 15px;
  width: 100%;
  max-width: 480px;
  background-color: #fff;
  border-radius: 5px;
  border: none;
  text-align: center;
  position: relative;
`

class CloseButton extends Component {
  render () {
    return (
      <Close onClick={() => { this.props && this.props.onClick && this.props.onClick() }}>
        <Icons
          icon='close'
          width='30'
          height='30'
          color={defaultTheme.colors.neutro.dark}
        />
      </Close>
    )
  }
}

export default class ModalContainer extends Component {
  static defaultProps = {
    isOpen: false,
    actionText: 'Confirmar',
    onSubmit: () => { }
  }

  state = {
    isOpen: false
  }

  componentDidMount () {
    this.setState({ isOpen: this.props.isOpen }, () => {
      window.onkeydown = event => {
        if (event.keyCode === 27 && this.state.isOpen) this.close()
      }
    })
  }

  UNSAFE_componentWillReceiveProps (props) {
    this.setState({ isOpen: props.isOpen },
      () => document.querySelector('body').style.overflow = this.state.isOpen ? 'hidden' : 'auto'
    )
  }

  close = () => {
    this.setState({ isOpen: false }, () => {
      this.props.onClose && this.props.onClose()
      return document.querySelector('body').style.overflow = this.state.isOpen ? 'hidden' : 'auto'
    })
  }

  render () {
    const { children, hasCloseButton, isOpen, maxWidth, onClick } = this.props
    return <Fragment>{
      isOpen && <Modal isOpen={isOpen} onClick={onClick}>
        <Card style={{ maxWidth: maxWidth || '480px' }}>
          {hasCloseButton && <CloseButton onClick={this.close} />}
          {children}
        </Card>
      </Modal>
    }</Fragment>
  }
}
