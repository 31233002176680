import { shortMonthNames } from './config'

const formatDate = (date) => {
  if (!date) return;
  date = new Date(date)
  return `${date.getDate()}/${shortMonthNames[date.getMonth()]}/${date.getFullYear()}`
}

export {
  formatDate
}