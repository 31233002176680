const retornaUrlFluxoEdicao = (services, id, servicoAtual) => {
  const listaDeRedirecionamento = JSON.parse(window.localStorage.getItem('redirecionamento_edicao_fluxo'))
  const verificaRedirecionamento = JSON.parse(window.localStorage.getItem('redirecionamento_edicao_salas'))
  const verificaRedirecionamentoServico = JSON.parse(window.localStorage.getItem('redirecionamento_edicao_servico'))
  if (services.lodging.requested && !services.lodging.id && servicoAtual === 'evento') {
    return `/organizer/event/lodging/${id}`
  } else if (services.room.requested && !services.room.id && (servicoAtual === 'evento' || servicoAtual === 'lodging')) {
    return `/organizer/event/rooms/${id}`
  } else if (services.foodsAndBeverages.requested && !services.foodsAndBeverages.id && (servicoAtual === 'evento' || servicoAtual === 'lodging' || servicoAtual === 'room')) {
    return `/organizer/event/food-and-beverage/${id}`
  } else if (services.equipments.requested && !services.equipments.id && (servicoAtual === 'evento' || servicoAtual === 'lodging' || servicoAtual === 'room' || servicoAtual === 'foodsAndBeverages')) {
    return `/organizer/event/equipments/${id}`
  } else if (services.transfer.requested && !services.transfer.id && (servicoAtual === 'evento' || servicoAtual === 'lodging' || servicoAtual === 'room' || servicoAtual === 'foodsAndBeverages' || servicoAtual === 'equipments')) {
    return `/organizer/event/transfer/${id}`
  } else if (services.otherServices.requested && !services.otherServices.id && (servicoAtual === 'evento' || servicoAtual === 'lodging' || servicoAtual === 'room' || servicoAtual === 'foodsAndBeverages' || servicoAtual === 'equipments' || servicoAtual === 'transfer')) {
    return `/organizer/event/other-services/${id}`
  } else {
    if (listaDeRedirecionamento && listaDeRedirecionamento.length > 0) {
      if (services.lodging.requested && servicoAtual === 'evento') {
        return `/organizer/event/lodging/${id}/${services.lodging.id ? services.lodging.id : ''}`
      } else if (services.room.requested && (servicoAtual === 'evento' || servicoAtual === 'lodging')) {
        return `/organizer/event/rooms/${id}/${services.room.id ? services.room.id : ''}`
      } else if (services.foodsAndBeverages.requested && (servicoAtual === 'evento' || servicoAtual === 'lodging' || servicoAtual === 'room')) {
        return `/organizer/event/food-and-beverage/${id}/${services.foodsAndBeverages.id ? services.foodsAndBeverages.id : ''}`
      } else if (services.equipments.requested && (servicoAtual === 'evento' || servicoAtual === 'lodging' || servicoAtual === 'room' || servicoAtual === 'foodsAndBeverages')) {
        return `/organizer/event/equipments/${id}/${services.equipments.id ? services.equipments.id : ''}`
      } else if (services.transfer.requested && (servicoAtual === 'evento' || servicoAtual === 'lodging' || servicoAtual === 'room' || servicoAtual === 'foodsAndBeverages' || servicoAtual === 'equipments')) {
        return `/organizer/event/transfer/${id}/${services.transfer.id ? services.transfer.id : ''}`
      } else if (services.otherServices.requested && (servicoAtual === 'evento' || servicoAtual === 'lodging' || servicoAtual === 'room' || servicoAtual === 'foodsAndBeverages' || servicoAtual === 'equipments' || servicoAtual === 'transfer')) {
        return `/organizer/event/other-services/${id}/${services.otherServices.id ? services.otherServices.id : ''}`
      } else if (verificaRedirecionamento && !verificaSeEhSomenteAEBOuEquipamentosOuTransfer(services) && !verificaSeEhSomenteOServicoSolicitado(services, 'otherServices')) {
        return `/organizer/event/${id}/more-suppliers/select`
      } else {
        return `/organizer/event/resume/${id}`
      }
    } else if (verificaRedirecionamento) {
      if (services.lodging.requested && servicoAtual === 'evento') {
        return `/organizer/event/lodging/${id}/${services.lodging.id}`
      } else if (services.room.requested && (servicoAtual === 'evento' || servicoAtual === 'lodging')) {
        return `/organizer/event/rooms/${id}/${services.room.id}`
      } else if (services.foodsAndBeverages.requested && (servicoAtual === 'evento' || servicoAtual === 'lodging' || servicoAtual === 'room')) {
        return `/organizer/event/food-and-beverage/${id}/${services.foodsAndBeverages.id}`
      } else if (services.equipments.requested && (servicoAtual === 'evento' || servicoAtual === 'lodging' || servicoAtual === 'room' || servicoAtual === 'foodsAndBeverages')) {
        return `/organizer/event/equipments/${id}/${services.equipments.id}`
      } else if (services.transfer.requested && (servicoAtual === 'evento' || servicoAtual === 'lodging' || servicoAtual === 'room' || servicoAtual === 'foodsAndBeverages' || servicoAtual === 'equipments')) {
        return `/organizer/event/transfer/${id}/${services.transfer.id}`
      } else if (services.otherServices.requested && (servicoAtual === 'evento' || servicoAtual === 'lodging' || servicoAtual === 'room' || servicoAtual === 'foodsAndBeverages' || servicoAtual === 'equipments' || servicoAtual === 'transfer')) {
        return `/organizer/event/other-services/${id}/${services.otherServices.id}`
      } else if (verificaRedirecionamentoServico && !verificaSeEhSomenteAEBOuEquipamentosOuTransfer(services) && !verificaSeEhSomenteOServicoSolicitado(services, 'otherServices')) {
        return `/organizer/event/${id}/more-suppliers/select`
      } else {
        return `/organizer/event/resume/${id}`
      }
    } else if (verificaRedirecionamentoServico && !verificaSeEhSomenteAEBOuEquipamentosOuTransfer(services) && !verificaSeEhSomenteOServicoSolicitado(services, 'otherServices')) {
      return `/organizer/event/${id}/more-suppliers/select`
    } else {
      return `/organizer/event/resume/${id}`
    }
  }
}

const retornaUrlFluxoCriacao = (services, id, eventStatus, servicoAtual) => {
  if (services.room.requested && servicoAtual === 'lodging') {
    return `/organizer/event/rooms/${id}/${services.room.id ? services.room.id : ''}`
  } else if (services.foodsAndBeverages.requested && (servicoAtual === 'lodging' || servicoAtual === 'room')) {
    return `/organizer/event/food-and-beverage/${id}/${services.foodsAndBeverages.id ? services.foodsAndBeverages.id : ''}`
  } else if (services.equipments.requested && (servicoAtual === 'lodging' || servicoAtual === 'room' || servicoAtual === 'foodsAndBeverages')) {
    return `/organizer/event/equipments/${id}/${services.equipments.id ? services.equipments.id : ''}`
  } else if (services.transfer.requested && (servicoAtual === 'lodging' || servicoAtual === 'room' || servicoAtual === 'foodsAndBeverages' || servicoAtual === 'equipments')) {
    return `/organizer/event/transfer/${id}/${services.transfer.id ? services.transfer.id : ''}`
  } else if (services.otherServices.requested && (servicoAtual === 'lodging' || servicoAtual === 'room' || servicoAtual === 'foodsAndBeverages' || servicoAtual === 'equipments' || servicoAtual === 'transfer')) {
    return `/organizer/event/other-services/${id}/${services.otherServices.id ? services.otherServices.id : ''}`
  } else {
    if(!verificaSeEhSomenteAEBOuEquipamentosOuTransfer(services) && !verificaSeEhSomenteOServicoSolicitado(services, 'otherServices')) {
      if (eventStatus !== 'INACTIVE') {
        return `/organizer/event/${id}/more-suppliers/select`
      } else {
        return `/organizer/event/${id}/suppliers/select`
      }
    } else {
      return `/organizer/event/resume/${id}`
    }
  }
}

const verificaSeEhSomenteAEBOuEquipamentosOuTransfer = (services) => {
  const { foodsAndBeverages, room, lodging, equipments, transfer, otherServices } = services
  return ((foodsAndBeverages && foodsAndBeverages.requested) &&
      (room && !room.requested) &&
      (lodging && !lodging.requested) &&
      (equipments && !equipments.requested) &&
      (transfer && !transfer.requested) &&
      (otherServices && !otherServices.requested)) ||
      ((foodsAndBeverages && !foodsAndBeverages.requested) &&
      (room && !room.requested) &&
      (lodging && !lodging.requested) &&
      (equipments && equipments.requested) &&
      (transfer && !transfer.requested) &&
      (otherServices && !otherServices.requested)) ||
      ((foodsAndBeverages && !foodsAndBeverages.requested) &&
      (room && !room.requested) &&
      (lodging && !lodging.requested) &&
      (equipments && !equipments.requested) &&
      (transfer && transfer.requested) &&
      (otherServices && !otherServices.requested))
}

const verificaSeEhSomenteOServicoSolicitado = (services, verificaServico) => {
  return Object.keys(services).every(servico => (servico === verificaServico && (services[servico] && services[servico].requested)) || (services[servico] && !services[servico].requested))
}

export {
  retornaUrlFluxoEdicao,
  retornaUrlFluxoCriacao,
  verificaSeEhSomenteAEBOuEquipamentosOuTransfer,
  verificaSeEhSomenteOServicoSolicitado
}
