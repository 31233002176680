import React, { Component } from 'react'
import styled from 'styled-components'
import AlertIcon from '../ui/AlertIcon'
import Modal from '../Modal'
import Title from '../ui/Title'
import Subtitle from '../ui/Subtitle'
import Button from '../ui/Button'
/* STYLES */
import { defaultTheme } from '../../styles/themes'

const Group = styled.div`
  display: flex;
  padding: 15px;
`

class ModalConfirmacao extends Component {
  render () {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onClose={(event) => {
          event && event.preventDefault && event.preventDefault()
          event && event.stopPropagation && event.stopPropagation()
          this.props.onCancel && this.props.onCancel()
        }}
        onClick={(event) => {
          event.preventDefault && event.preventDefault()
          event.stopPropagation && event.stopPropagation()
        }}
        hasCloseButton
      >
        <AlertIcon fill={defaultTheme.colors.whiteLabel.primary} width='150px' />
        <Title style={{ paddingTop: '10px' }}>Atenção!</Title>
        <Subtitle style={{ marginBottom: '0px' }}>Tem certeza que deseja <span style={{ fontWeight: 600, color: defaultTheme.colors.whiteLabel.primary }}>aprovar</span> este fornecedor?</Subtitle>
        <Group>
          <Button
            onClick={(event) => {
              event.stopPropagation && event.stopPropagation();
              this.props.onCancel && this.props.onCancel()
            }}
            category='secondary'
            fontWeight={800}
            width='50%'
            ghost
            full
          >
            Não
          </Button>
          <Button
            onClick={(event) => {
              event.stopPropagation && event.stopPropagation()
              this.props.onSubmit && this.props.onSubmit(event)
            }}
            category='secondary'
            fontWeight={800}
            width='50%'
            full
          >
            Sim
          </Button>
        </Group>
      </Modal>
    )
  }
}

export default ModalConfirmacao
