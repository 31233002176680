/* LIBS */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { removeCurrency } from '../../../utils/money'
/* UTILS */
import config from '../../../middleware/config'
/* ACTIONS */
import { fetchFoodsAndBeverages } from '../../../redux/actions/forms'
/* STYLES */
import { defaultTheme } from '../../styles/themes'
/* COMPONENTS */
import Header from '../../components/Header'
import Loader from '../../components/Loader'
import Input from '../../components/ui/Input'
import Button from '../../components/ui/Button'
import BreadCrumb from '../../components/BreadCrumb'
/* FORM ITENS */
import FormMenu from './FormMenu'
import FormTray from './FormTray'
import FormRoomService from './FormRoomService'
import { Get } from '../../../utils/request'
const serialize = require('dom-form-serializer').serialize

const Main = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #FAFAFA;
  @media (min-width: 768px) {
    background-color: #FAFAFA;
  }
`

const Form = styled.form`
  min-height: 90vh;
  padding: 30px 0px;
  font-family: ${defaultTheme.fonts.secondary};
  width: 90%;
  margin: 0 5%;
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  align-content: start;
  @media (min-width: 768px) {
    width: 60%;
  }
`

const Title = styled.h1`
  width: 100%;
  text-align: left;
  font-size: 20px;
  padding: 20px 10px;
`

const formatAllPrices = (obj) => {
  const data = {}
  Object.keys(obj)
    .forEach(e => {
    if (e === 'price'){
      data['price'] = removeCurrency(obj[e])
    } else {
      data[e] = obj[e]
    }
  })
  return data
}

class FormFoodsAndBeverages extends Component {
  state = {
    isLoading: false,
    welcome_drink: true,
    welcome_coffee: true,
    coffee_break_morning: true,
    brunch: true,
    lunch: true,
    coffee_break_afternoon: true,
    dinner: true,
    thematic_dinner: true,
    rooms_service: true,
    rooms_service_items: {
      coffee: true,
      juice: true,
      milk: true,
      soda: true,
      tea: true,
      water: true,
      tray: {
        cheeseBread: true,
        petitFourCandy: true,
        petitFourSalty: true,
        cake: true,
        miniSandwich: true,
        cupCakes: true,
        fruitPlatter: true
      }
    },
    cocktail: true
  }

  componentDidMount() {
    const { match: { params: { foodsandbeverage } } } = this.props
    this.props.fetchFoodsAndBeverages()
    .catch(err => {
      alert(`Error (${err.message})`)
      console.error(err.message)
    })
    if(foodsandbeverage) {
      this.setState({ isLoading: true }, () => this.fetchEvent())
    }
  }

  fetchEvent() {
    const { api: { base, version, routes: { events } } } = config
    const { match: { params: { event } } } = this.props
    const endpoint = `${base}${version}${events}/${event}/infoToBudget/foodsAndBeverages`

    Get(endpoint)
    .then(response => {
      var state = {
        isLoading: false,
        welcome_drink: false,
        welcome_coffee: false,
        coffee_break_morning: false,
        brunch: false,
        lunch: false,
        coffee_break_afternoon: false,
        dinner: false,
        thematic_dinner: false,
        rooms_service: false,
        rooms_service_items: response.briefing.roomService,
        cocktail: false
      }

      Object.keys(response.briefing).filter(key => response.briefing[key] && response.briefing[key].requests && response.briefing[key].requests.length > 0).forEach(key => {
        state[response.briefing[key].supplierOptions.id] = true
      })
      this.setState(state)
    }).catch(err => {
      this.setState({ isLoading: false })
      console.log('Error: ', err)
    })
  }

  submit = (e) => {
    e.preventDefault()
    const forms = serialize(e.target)
    if(this.isValid(forms)) {
      const dataToPost = Object.keys(forms).reduce((foodsAndBeverages, key) => {
        if ( key === 'taxes') {
          foodsAndBeverages[key] = forms[key]
        }
        else {
          foodsAndBeverages[key] = {}
          foodsAndBeverages[key].enabled = (forms[key].enabled ? forms[key].enabled : false)
          if (forms[key].items && key !== 'rooms_service') {
            foodsAndBeverages[key].items = Object.keys(forms[key].items).map(i => {
              return formatAllPrices(forms[key].items[i])
            })
          } else if (key === 'rooms_service') {
            foodsAndBeverages[key].items = Object.keys(forms[key].items).reduce((foodsAndBeverages, cur) => {
              foodsAndBeverages[cur] = formatAllPrices(forms[key].items[cur])
              return foodsAndBeverages
            }, {})
          } else {
            foodsAndBeverages[key].items = []
          }
        }
        return foodsAndBeverages
      }, {})

      const { api: { base, version, routes: { foods_and_beverages } } } = config
      const { match: { params: { event, foodsandbeverage } } } = this.props
      const token = window.localStorage.getItem('user_token')
      const { id } = this.props.match.params
      const endpoint = id ? `${base}${version}${foods_and_beverages}/${id}` : `${base}${version}${foods_and_beverages}`
      this.setState({ isLoading: true })
      const configFetch = {
        method: id ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
        body: JSON.stringify(dataToPost)
      }
      fetch(endpoint, configFetch)
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          this.setState({ isLoading: false })
          throw new Error(response)
        }
      })
      .then(response => {
        this.setState({ isLoading: false })
        if (response.success) {
          if(this.props.location.query && this.props.location.query.url) this.props.history.push(this.props.location.query.url)
          else if(!event && !foodsandbeverage) this.props.history.push('/foods-and-beverages')
          else if(event && foodsandbeverage) this.props.history.push(`/budget/briefing/foodsandbeverage/${event}/${foodsandbeverage}`)
        } else {
          console.error(response)
        }
      })
      .catch(e => {
        this.setState({ isLoading: false })
        console.error(e)
      })
    } else {
      alert('Preencha todos os itens obrigatórios!')
    }
  }

  isValid = (form) => {
    var isValid = true
    Object.keys(form).forEach(key => {
      if(form[key].enabled === 'true') {
        Object.keys(form[key].items).forEach(itemKey => {
          if(key !== 'rooms_service' && key !== 'tray' && (!form[key].items[itemKey].name || removeCurrency(form[key].items[itemKey].price) <= 0 || !form[key].items[itemKey].details)) {
            isValid = false
          } else if(key === 'rooms_service' && this.state.rooms_service_items[itemKey] && (form[key].items[itemKey].quantity <= 0 || removeCurrency(form[key].items[itemKey].price) <= 0)){
            isValid = false
          } else if(key === 'tray' && this.state.rooms_service_items.tray[itemKey] && (!form[key].items[itemKey].name || removeCurrency(form[key].items[itemKey].price) <= 0)) {
            isValid = false
          }
        })
      }
    })
    return isValid
  }

  render() {
    const { isFetching, data } = this.props
    const { match: { params: { event, foodsandbeverage } } } = this.props
    const { isLoading } = this.state
      return (
      <Main>
        <Header />
        {!event && !foodsandbeverage && <BreadCrumb options={
          [
            {link: '/', name: 'Home'},
            {link: '/foods-and-beverages', name: 'Alimentos e bebidas'},
            {name: 'Edição de cardápios'}
          ]
        } />}
        {event && foodsandbeverage && <BreadCrumb options={
          [
            {link: '/', name: 'Home'},
            {link: `/budget/resume/${event}`, name: 'Resumo'},
            {link: `/budget/briefing/foodsandbeverage/${event}/${foodsandbeverage}`, name: 'Alimentos e bebidas'},
            {name: 'Edição de cardápios'}
          ]
        } />}
        {(isLoading || isFetching) ? <Loader /> :
        <Form method='POST' onSubmit={this.submit}>
          <Title>
            Edição de cardápios
          </Title>
          <FormMenu
            label={'Welcome drink'}
            name={'welcome_drink'}
            isActive={this.state.welcome_drink}
          />
          <FormMenu
            label={'Welcome coffee'}
            name={'welcome_coffee'}
            isActive={this.state.welcome_coffee}
          />
          <FormMenu
            label={'Coffee break manhã'}
            name={'coffee_break_morning'}
            isActive={this.state.coffee_break_morning}
          />
          <FormMenu
            label={'Brunch'}
            name={'brunch'}
            isActive={this.state.brunch}
          />
          <FormMenu
            label={'Almoço'}
            name={'lunch'}
            isActive={this.state.lunch}
          />
          <FormMenu
            label={'Coffee break tarde'}
            name={'coffee_break_afternoon'}
            isActive={this.state.coffee_break_afternoon}
          />
          <FormMenu
            label={'Jantar'}
            name={'dinner'}
            isActive={this.state.dinner}
          />
          <FormMenu
            label={'Jantar temático'}
            name={'thematic_dinner'}
            isActive={this.state.thematic_dinner}
          />
          <FormRoomService
            name={'rooms_service'}
            isActive={this.state.rooms_service}
            items={this.state.rooms_service_items}
          />
          <FormTray
            name={'tray'}
            isActive={this.state.rooms_service && (
              this.state.rooms_service_items.tray.cheeseBread ||
              this.state.rooms_service_items.tray.petitFourCandy ||
              this.state.rooms_service_items.tray.petitFourSalty ||
              this.state.rooms_service_items.tray.cake ||
              this.state.rooms_service_items.tray.miniSandwich ||
              this.state.rooms_service_items.tray.cupCakes ||
              this.state.rooms_service_items.tray.fruitPlatter
            )}
            items={this.state.rooms_service_items}
          />
          <FormMenu
            label={'Coquetel'}
            name={'cocktail'}
            isActive={this.state.cocktail}
          />
          <Input
            type={'number'}
            label={'Valor das taxas (em %)'}
            rightIcon={'% '}
            name={'taxes'}
            style={{ textAlign: 'end' }}
            defaultValue={(data.menus && data.menus.taxes && data.menus.taxes.amount) || 0}
          />
          <Button
            type='submit'
            category={'secondary'}
            fontWeight={800}
            full>
            Cadastrar
          </Button>
        </Form> }
      </Main>
    )
  }
}

function mapStateToProps(state) {
  const { forms } = state
  const { foodsAndBeverages } = forms
  const { isFetching, error, data } = foodsAndBeverages
  return {
    isFetching,
    error,
    data
  }
}

export default connect(mapStateToProps, {fetchFoodsAndBeverages})(FormFoodsAndBeverages)
