/* LIBS */
import React, { Component } from 'react'
import Button from '../../../components/ui/Button'
import Modal from '../../../components/Modal'
import Title from '../../../components/ui/Title'
import styled from 'styled-components'
import { defaultTheme } from '../../../styles/themes'

const Group = styled.div`
padding: 10px;
text-align: left;
`

const Label = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  box-sizing: border-box;
  /* padding: 5px 5px 5px 0px; */
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
  line-height: 1.5;
`

const LabelDisabled = styled(Label)`
  color: #727272;
  cursor: default;
`

const Tag = styled.div`
  cursor: pointer;
  padding: 5px 10px;
  font-size: 12px;
  font-family: ${defaultTheme.fonts.primary};
  color: #FAFAFA;
  background-color: ${props => props.status === 'REVIEW' ? '#F39C12' : props.status === 'INDISPONIVEL' ? '#a5a5a5' : '#009688'};
  border: none;
  border-radius: 15px;
  top: 10px;
  text-transform: uppercase;
  display: inline-block;
`

class DetalheIndisponibilidade extends Component {
  constructor (props) {
    super(props)
    this.state = { isOpen: props.isOpen }
  }

  componentWillReceiveProps (props) {
    this.setState({ ...props },
      () => document.querySelector('body').style.overflow = this.state.isOpen ? 'hidden' : 'auto'
    )
  }

  render () {
    return (
      <Modal isOpen={this.state.isOpen} hasCloseButton onClick={() => this.setState({ isOpen: false }, () => this.props.onClose && this.props.onClose())}>
        <Group>
          <Tag style={{ textAlign: 'left' }} status={'INDISPONIVEL'}>Indisponível</Tag>
          <Title style={{ fontSize: '18px', paddingBottom: '10px', color: '#727272' }}>{this.props.nome}</Title>
          <Group style={{ padding: '10px 0' }}>
            <LabelDisabled style={{ fontWeight: 'bold', display: 'inline', fontSize: '14px', marginRight: '5px' }}>Motivo:</LabelDisabled>
            <LabelDisabled style={{ display: 'inline', fontSize: '14px' }}>{this.props.motivo}</LabelDisabled>
          </Group>
          <Group style={{ padding: '10px 0' }}>
            <LabelDisabled style={{ fontWeight: 'bold', display: 'inline', fontSize: '14px', marginRight: '5px' }}>Detalhes:</LabelDisabled>
            <LabelDisabled style={{ display: 'inline', fontSize: '14px' }}>{this.props.detalhes}</LabelDisabled>
          </Group>
          <div style={{ textAlign: '-webkit-right', paddingTop: '20px' }}>
            <Button
              small
              style={{ width: '80px', padding: '5px 60px' }}
              category={'secondary'}
              fontWeight={800}
              onClick={() => this.setState({ isOpen: !this.state.isOpen })}>
              Ok
            </Button>
          </div>
        </Group>
      </Modal>
    )
  }
}

export default DetalheIndisponibilidade
