/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../../../../styles/themes'
/* COMPONENTS */
import Header from '../../../Header/index'
import CheckBox from '../../../../components/ui/CheckBox'
import InputDatepicker from '../../../../components/InputDatepicker'
import Select from '../../../../components/ui/Select'

const Content = styled.div`

`
const Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  font-family: ${defaultTheme.fonts.secondary};
  padding: 3px 10px 13px 10px;
  color: #484848;
`
const FormContent = styled.form`
  padding: 10px 10px;
  box-sizing: border-box;
  width: 100%;
  max-width: 650px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
`

class WelcomeDrink extends Component {
  render() {
    return (
      <CheckBox
        label={'Welcome drink'}
        name={'welcome_drink'}
        checked={true}>
        <InputDatepicker
          label={'Quando?'}
          placeholder={'Selecione a(s) data(s)'}
          isRequired
          dateSelect={'single'}
        />
        <Select
          isRequired
          label={'Horários ou número de pessoas mudam conforme os dias?'}
          name={'horarioDeInicio'}>
          <option value='no'>Não</option>
          <option value='yes'>Sim</option>
        </Select>
      </CheckBox>
    )
  }
}


class Menus extends Component {
  render() {
    return (
      <Content>
        <Header />
        <FormContent>
          <Title>Alimentos e bebidas</Title>
          <WelcomeDrink />
        </FormContent>
      </Content>
    )
  }
}

export default Menus
