/* LIBS */
import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
// import { Route } from 'react-router-dom'
// import DevTools from './DevTools'
/* SCREENS */
import OrganizerRoutes from './OrganizerRoutes'
import SupplierRoutes from './SupplierRoutes'
import SupplierChainRoutes from './SupplierChainRoutes'
// import PageNotFound from '../screens/PageNotFound'

const Routes = ({ store }) => {
  return (
    <Provider store={store}>
      <div>
        <SupplierRoutes />
        <OrganizerRoutes />
        <SupplierChainRoutes />
        {/* <DevTools /> */}
        {/* <Route path='*' exact={true} component={PageNotFound} /> */}
      </div>
    </Provider>
  )
}

Routes.propTypes = {
  store: PropTypes.object.isRequired
}

export default Routes
