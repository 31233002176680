import React from 'react'
import iconPaths from '../../styles/icomoon/selection'

function getPath (iconName) {
  const icon = iconPaths.icons.find(icon => icon.properties.name === iconName)

  if (!icon) {
    console.warn(`icon ${iconName} does not exist.`)
    return ''
  }

  return icon.icon.paths.join(' ')
}

const Icon = (props) => {
  return (
    <svg title={props.title} style={props.style} onClick={props.onClick} width={props.width || 50} height={props.height || 20} viewBox={props.viewBox || '0 0 1024 1024'} fill={props.color}>
      {props.title && <title>{props.title}</title>}
      <path d={getPath(props.icon)} />
    </svg>
  )
}

export default Icon
