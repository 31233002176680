import React, { Component } from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../../styles/themes'
import TimePicker from 'rc-time-picker'
import moment from 'moment'
import './index.css'

const Label = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  box-sizing: border-box;
  padding: 5px 5px 5px 0px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
`

const InputControl = styled.div`
  padding: 10px 10px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: ${props => props.type_placeholder === 'side' ? 'nowrap' : 'wrap'};
  align-items: center;
`
const Strong = styled.strong`
  color: ${props => props.error ? 'red' : defaultTheme.colors.whiteLabel.primary};
  font-weight: bold;
`

export default class Timepicker extends Component {
  static defaultProps = {
    onChange: () => { }
  }

  state = { value: '00:00:00' }

  UNSAFE_componentWillReceiveProps (props) {
    if (props.value) {
      this.setState({ value: props.value })
    }
  }

  changeTimer = (e) => {
    const { name } = this.props
    this.setState({ value: e && e.format('HH:mm:ss') }, () => {
      this.props.onChange({ target: { name: name, value: e && e.format('HH:mm:ss') } })
    })
  }

  render () {
    const { props } = this
    const { value } = this.state
    const time = moment(`${moment().format('YYYY-MM-DD')} ${value}`, 'YYYY-MM-DD HH:mm:ss')
    return (
      <InputControl>
        <Label error={props.error}>{props.label}
          <Strong error={props.error}>{props.isRequired ? '*' : ''}</Strong>
        </Label>

        <TimePicker
          id={props.id || props.name}
          name={props.name}
          showSecond={false}
          placeholder={props.placeholder}
          autoComplete="off"
          minuteStep={5}
          secondStep={60}
          defaultValue={time}
          value={time}
          onChange={this.changeTimer}
          style={{ borderBottom: `${props.error ? '2px solid red' : ''}` }}
        />
      </InputControl>
    )
  }
}
