import React, { Component } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
/* STYLES */
import { defaultTheme, screens } from '../../styles/themes'
import { formatDate } from '../../../utils/dates'
/* COMPONENTS */
import Icons from '../../components/ui/Icons'
import Options from '../../components/ui/Options'
import CircleIcon from '../../components/ui/CircleIcon'
import Countdown from '../../Supplier/Budget/Countdown'
import { timeToExpireBetweenDates } from '../../Supplier/Budget/utils'
import * as moment from 'moment'
import 'moment/locale/pt-br'
moment.locale('pt-BR')

const renderBorderColor = (status, amountOfBudgets) => {
  switch (status) {
    case 'ORDERED':
      return defaultTheme.colors.primary.default;

    case 'INACTIVE':
      return '#FF1818';//Cinza

    case 'DELETED':
      return '#FF1818';// vermelho

    default:
      return amountOfBudgets > 0 ? defaultTheme.colors.primary.default : '#CCC' //Laranja - Cinza
  }
}


const Card = styled.div`
  background-color: #fff;
  padding: 15px 5px 15px 15px;
  width: calc(33.3% - 20px);
  margin: 10px 10px;
  line-height: 1.3;
  border-left: 4px solid;
  border-left-color: ${ props => renderBorderColor(props.status, props.amountOfBudgets)};
  color: ${defaultTheme.colors.text.dark};
  position: relative;
  cursor: pointer;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  transition: box-shadow 0.3s linear;
  &:hover {
    box-shadow: ${props => props.status ? '0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08)' : '1px 2px 2px 2px rgba(48,47,47,.40),0 0 0 2px rgba(0,0,0,0.08)'};
  }

  @media (max-width: ${screens.medium}) {
    width: calc(50% - 70px);
  }
  @media (max-width: ${screens.small}) {
    width: 100%;
  }
`

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  & li {
    padding: 10px 20px 0px 0px;
    font-family: ${defaultTheme.fonts.primary};
    color: ${defaultTheme.colors.text.dark};
    font-weight: 300;
    display: flex;
    align-items: center;
  }
  @media (min-width: 768px) {
    & li {
      float: left;
      margin-right: 20px;
    }

    &::after {
      clear: both;
      display: block;
      content: '';
    }
  }
`

const Circle = styled.p`
  background: ${props => props.color};
  width: 12px;
  height: 12px;
  display: inline-block;
  margin-right: 5px;
  border-radius: 50%;
`

const Text = styled.p`
  font-family: ${defaultTheme.fonts.primary};
  color: ${props => props.color || defaultTheme.colors.text.dark};
  font-weight: 300;
`

const EventTitle = styled.h2`
  font-family: ${defaultTheme.fonts.secondary};
  color: ${defaultTheme.colors.text.darker};
  font-weight: 500;
`

const Group = styled.div`
  display: flex;
`


const Tag = styled.div`
  cursor: pointer;
  padding: 3px 5px;
  font-size: 12px;
  font-family: ${defaultTheme.fonts.primary};
  color: #FAFAFA;
  background-color: ${props => props.status === 'REVIEW' ? '#F39C12' : (props.status === 'DELETED' ? '#FF1818' : '#009688')};
  border: none;
  border-radius: 15px;
  position: absolute;
  right: 10px;
  top: 10px;
  text-transform: uppercase;
`

// const ServicesRequested = (services, type) => {
//   return Object.keys(services).reduce((prev, cur) => {
//     return services[cur][type]
//       ? prev += 1
//       : prev
//   }, 0)
// }

class EventCard extends Component {
  redirect = (status, amountOfBudgets) => {

    if (status) {
      this.props.history.push(`/organizer/event/resume/${this.props._id || this.props.id}`)
    } else if (amountOfBudgets >= 0) {
      this.props.history.push(`/organizer/event/${this.props._id || this.props.id}/budget/place`)
    }

    if (this.props.status === 'ORDERED') {
      this.props.history.push(`/organizer/event/${this.props._id || this.props.id}/resume/chosen`)
    }

    if (this.props.status === 'INACTIVE') {
      this.props.history.push('/organizer')
      this.props.history.push(`/organizer/event/resume/${this.props._id || this.props.id}`)
    }
  }

  getAmountOfBudgets() {
    const { budgets } = this.props
    // ? Ainda acho que deveria ser apenas a contagem de orçamentos recebidos
    // return Object.keys(budgets).reduce((amount, service) => amount + budgets[service].length, 0)
    // Quantidade de fornecedores que mandaram orçamento
    return budgets ? Object.keys(budgets)
      .reduce((allBudgets, service) => allBudgets.concat(budgets[service]), [])
      .reduce((supplierIds, budget, x) => {
        if (!supplierIds.find(supplierId => supplierId === budget.accountId) && budget.status !== 'REVIEW')
          supplierIds.push(budget.accountId)
        return supplierIds
      }, [])
      .length : this.props.bud
  }


  renderCircleColor(status, amountOfBudgets) {
    switch (status) {
      case 'ORDERED':
        return defaultTheme.colors.primary.default;

      case 'INACTIVE':
        return '#FF1818';//Vermelho

      case 'DELETED':
        return '#FF1818';//Vermelho

      default:
        if(amountOfBudgets > 0) return defaultTheme.colors.primary.default;
        else return '#CCC';
    }
  }

  formatStatusMessage(status, amountOfBudgets) {
    switch (status) {
      case 'DELETED':
        return 'Evento cancelado'

      case 'INACTIVE':
        return 'Fornecedores não solicitados'

      default:
        return (amountOfBudgets > 0 ? `${amountOfBudgets} orçamento(s) recebido(s)` : `Nenhum orçamento recebido`)

    }
  }

  getTimeUntil = (deadline) => {
    const time = Date.parse(deadline) - Date.parse(new Date())
    if (time < 0) {
      return 'SLA expirado para novos orçamentos'
    } else {
      const minutes = Math.floor((time / 1000 / 60) % 60)
      const hours = Math.floor((time / (1000 * 60 * 60)))
      return `SLA expira em: ${this.leading0(hours)}h ${this.leading0(minutes)}min`
    }
  }

  leading0 = (num) => {
    return num < 10 ? '0' + num : num;
  }

  verificaSeOEventoPertenceAoConsultor = (consultantId, userId) => {
    const loggedUserId = window.localStorage.getItem('user')
    return consultantId !== userId && loggedUserId === consultantId
  }

  render() {
    const { name, status, chosenSuppliers, opcoes, emergency, numeroDeRequisicao, approval, ordemDeCompra, created } = this.props
    const amountOfBudgets = this.getAmountOfBudgets()
    const hasChosenSuppliers = (chosenSuppliers && chosenSuppliers.length > 0)
    const userType = window.localStorage.getItem('user_type')
    return (
      <Card
        amountOfBudgets={amountOfBudgets}
        status={status}
        active={status === 'active'}
        onClick={() => numeroDeRequisicao ?
          (this.props.dadosModalNumeroDeRequisicao && this.props.dadosModalNumeroDeRequisicao(this.props)) :
          (ordemDeCompra ? (this.props.dadosModalOrdemDeCompra && this.props.dadosModalOrdemDeCompra(this.props)) :
            (status !== 'DELETED' &&
              (this.props.dadosModalConciliacao && !(this.props.finalPrice > 0)) ?
                this.props.dadosModalConciliacao(this.props) :
                (!(approval && approval.status === 'AWAITING_PURCHASE_ORDER') ? this.redirect(!hasChosenSuppliers, amountOfBudgets) : alert('Aguarde a ordem de compra!'))
            )
          )}>
        {(userType === 'RESELLER' && this.props.organizer) && <Text style={{ textTransform: 'uppercase', fontSize: '14px', color: defaultTheme.colors.grey.light, fontWeight: 400 }}>{`CLIENTE: ${this.props.organizer.name}`}</Text>}
        {emergency && emergency.isEmergency && status !== 'ORDERED' && <Countdown statusExpired={status === 'expired'} deadline={timeToExpireBetweenDates(moment(created, 'YYYY-MM-DD[T]HH:mm:ssZ').add(36, 'hours'))} />}
        {/* {(userType === 'ORGANIZER' &&  this.props.resellerId) && <Text style={{ textTransform: 'uppercase', fontSize: '14px', color: defaultTheme.colors.grey.light, fontWeight: 400 }}>{`AGÊNCIA: ${this.props.reseller.name}`}</Text>} */}
        <EventTitle style={{ paddingBottom: '10px', width: '80%' }}>{name}</EventTitle>
        {status === 'DELETED' && <Tag status={'DELETED'}>Cancelado</Tag>}
        {emergency && emergency.isEmergency && <Tag style={{ right: '15px', top:'40px' }} status={'DELETED'}>Emergencial</Tag>}
        {approval && approval.status === 'AWAITING_APPROVAL' && <Tag style={{ right: '15px', top: (emergency && emergency.isEmergency) ? '65px' : '40px', backgroundColor: defaultTheme.colors.grey.medium }}>Aguardando aprovação</Tag>}
        {approval && approval.status === 'AWAITING_REQUEST_CODE' && <Tag style={{ right: '15px', top: (emergency && emergency.isEmergency) ? '65px' : '40px', backgroundColor: defaultTheme.colors.grey.medium }} >Aguardando Nº Requisição</Tag>}
        {approval && approval.status === 'AWAITING_PURCHASE_ORDER' && <Tag style={{ right: '15px', top: (emergency && emergency.isEmergency) ? '65px' : '40px', backgroundColor: defaultTheme.colors.grey.medium }} >Aguardando OC</Tag>}
        <Group>
          <Icons
            width={25}
            height={20}
            icon={'calendario'}
            color={'#666'}
          />
          <Text>
            {formatDate(this.props.start)} - {formatDate(this.props.end)}
          </Text>
        </Group>
        <Text style={{ position: 'absolute', right: '20px', bottom: '15px' }}>ID: {this.props.internalId || this.props.friendlyId}</Text>
        {this.props.numberOfParticipants > 0 && <Group>
          <Icons
            width={25}
            height={20}
            icon={'pessoas'}
            color={'#666'}
          />
          <Text>
            {`${this.props.numberOfParticipants} pessoas `}
          </Text>
        </Group>}
        <Group style={{ margin: '10px 0' }}>
          {this.props.services.lodging.requested && <CircleIcon title={'lodging'} style={{ margin: '0 5px' }} icon={'bed-outline'} selected={this.props.budgets.lodging && this.props.budgets.lodging.length > 0} />}
          {this.props.services.room.requested && <CircleIcon title={'room'} style={{ margin: '0 5px' }} icon={'room-outline'} selected={this.props.budgets.room && this.props.budgets.room.length > 0} />}
          {this.props.services.foodsAndBeverages.requested && <CircleIcon title={'foodsAndBeverages'} style={{ margin: '0 5px' }} icon={'food-beverage-outline'} marginLeft={'-11px'} selected={this.props.budgets.foodsAndBeverages && this.props.budgets.foodsAndBeverages.length > 0} />}
          {this.props.services.equipments.requested && <CircleIcon title={'equipments'} style={{ margin: '0 5px' }} icon={'computer-outline'} marginLeft={'-12px'} selected={this.props.budgets.equipments && this.props.budgets.equipments.length > 0} />}
          {this.props.services.transfer.requested && <CircleIcon title={'transfer'} style={{ margin: '0 5px' }} icon={'bus-outline'} marginLeft={'-10px'} selected={this.props.budgets.transfer && this.props.budgets.transfer.length > 0} />}
          {this.props.services.otherServices.requested && <CircleIcon title={'otherServices'} style={{ margin: '0 5px' }} icon={'outros-servicos-outline'} marginLeft={'-10px'} selected={this.props.budgets.otherServices && this.props.budgets.otherServices.length > 0} />}
        </Group>
        {opcoes && <Options
          readOnly={this.verificaSeOEventoPertenceAoConsultor(this.props.consultantId, this.props.userId)}
          eventId={this.props._id || this.props.id}
          history={this.props.history}
          delegarConsultor = {this.props.delegarConsultor}
          suppliersDeadline={this.props.suppliersDeadline}
          name={this.props.name}
          status={this.props.status}
          naoMostraEdicao={this.props.supplierChainId}
        />}
        {status !== 'INACTIVE' && hasChosenSuppliers ?
          <List style={{ width: '80%' }}>
            <li>
              {/* // TODO TEMOS QUE ADD OS ORÇAMENTOS RECEBIDOS DEPOIS QUE TIVER O ORÇAMENTO DO F */}
              {amountOfBudgets === 0 && <Icons
                width={25}
                height={20}
                icon={'exclamation-circle'}
                color={this.renderCircleColor(status, amountOfBudgets)}
              />}
              <Text color={this.renderCircleColor(status, amountOfBudgets)}>
                {this.formatStatusMessage(status, amountOfBudgets)}
              </Text>
            </li>
            <li>
              <Text style={{ fontWeight: 400, fontSize: '13px' }}>
                {this.getTimeUntil(this.props.suppliersDeadline) === 'SLA expirado para novos orçamentos' && <Icons
                  width={20}
                  height={15}
                  icon={'blocked'}
                  color={'#666'}
                  style={{ paddingTop: '3px' }}
                />}
                {this.getTimeUntil(this.props.suppliersDeadline)}
              </Text>
            </li>
            {/* <li>
              <Circle color={defaultTheme.colors.primary.default} />
              {`${ServicesRequested(services, 'id')}/${ServicesRequested(services, 'requested')} definidos`}
            </li> */}
          </List>
          :
          <List style={{ width: '80%' }}>
            <li>
              {status === 'DELETED' && <>
                <Circle color={this.renderCircleColor(status, amountOfBudgets)} />
                <Text color={this.renderCircleColor(status, amountOfBudgets)}>
                  Evento cancelado
                </Text>
              </>}
              {status !== 'DELETED' && <>
                <Icons
                  width={25}
                  height={20}
                  icon={'exclamation-circle'}
                  color={this.renderCircleColor(status, amountOfBudgets)}
                />
                <Text color={this.renderCircleColor(status, amountOfBudgets)}>
                  {this.formatStatusMessage(status, amountOfBudgets)}
                </Text>
              </>}
            </li>
          </List>}
      </Card>
    )
  }
}

export default withRouter(EventCard)
