export function nullOrUndefined (valor) {
  if (valor === null) return true
  if (valor === undefined) return true
  if (valor === 'null') return true
  if (valor === 'undefined') return true

  return false
}

export function notNullNorUndefined (valor) {
  return !nullOrUndefined(valor)
}

export function ehConsultor () {
  const tipo = window.localStorage.getItem('user_type')
  return tipo && tipo === 'RESELLER'
}

export function ehAdmin () {
  return ehTipo('ADMIN')
}

export function ehOrganizador () {
  const tipo = window.localStorage.getItem('user_type')
  return tipo && tipo === 'ORGANIZER'
}

export function ehAtlantica () {
  const tipo = window.localStorage.getItem('theme_logo')
  return tipo && tipo === 'https://s3.amazonaws.com/files.evnts.com.br/corporate/e167134d-9039-4ae7-859a-804885e50e2c.png'
}

export function ehTipo (tipo) {
  const item = window.localStorage.getItem('user_roles')
  var roles
  try {
    roles = notNullNorUndefined(item) && JSON.parse(item)
  } catch (error) {
    console.error(error)
  }
  return roles && Array.isArray(roles) && roles.find(role => role === tipo)
}

export function ehChainUser () {
  const supplierChainId = window.localStorage.getItem('supplierChainId')
  return (supplierChainId && notNullNorUndefined(supplierChainId))
}

export function featureFlags () {
  const item = window.localStorage.getItem('featureFlags')
  var featureFlags
  try {
    featureFlags = (notNullNorUndefined(item)) && JSON.parse(item)
  } catch (error) { console.error(error) }

  featureFlags = { ...featureFlags }
  return featureFlags
}

export function temFeatureFlag (feature) {
  return (!!featureFlags()[feature]) || undefined
}

export function temTempFeatureFlag (flag) {
  const temp = { ...featureFlags().temp }
  return (!!temp[flag]) || undefined
}

export function removeTempFeatureFlag (flag) {
  const item = window.localStorage.getItem('featureFlags')
  var featureFlags
  try {
    featureFlags = (notNullNorUndefined(item)) && JSON.parse(item)
  } catch (error) { console.error(error) }
  if (featureFlags && featureFlags.temp) {
    delete featureFlags.temp[flag]
    window.localStorage.setItem('featureFlags', JSON.stringify(featureFlags))
  }
}

export function linkDashboard () {
  return featureFlags().dashboards || featureFlags().dashboard
}
