/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
/* STYLES */
import { defaultTheme } from '../../../styles/themes'
/* COMPONENTS */
import Modal from '../../../components/Modal/index'
import CalendarIcon from '../../../components/ui/CalendarIcon'
import Button from '../../../components/ui/Button'
import Input from '../../../components/ui/Input'
import Alert from '../../../components/ui/Alert'

const Title = styled.h1`
  font-family: ${defaultTheme.fonts.secondary};
  font-weight: 800;
  line-height: 1.2;
  font-size: 1.7em;
  margin-top: 20px;
  color: #484848;
`

const Description = styled.p`
  font-family: ${defaultTheme.fonts.secondary};
  font-size: .9em;
  color: #484848;
`

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

class ExpirationBudget extends Component {
  constructor(props) {
    super()
    this.state = {
      expirationDays: props.initialValue || 10,
      errorMessage: ''
    }
  }

  static defaultProps = {
    onSubmit: () => { }
  }

  UNSAFE_componentWillReceiveProps (props) {
    if(props.initialValue >= 0) {
      this.setState({ expirationDays: props.initialValue })
    }
  }

  handleValue = ({ target: { name, value } }) => {
    this.setState({ [name]: value })
  }

  submit = () => {
    this.setState({ errorMessage: '' })
    const { expirationDays } = this.state
    if (this.validate({ expirationDays })) {
      this.props.onSubmit({ expirationDays })
    } else {
      this.setState({ errorMessage: 'Preencha todos os campos obrigatórios.' })
    }
  }

  validate = ({ expirationDays }) => {
    return expirationDays !== ''
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.submit()
    }
  }

  render () {
    const { expirationDays, errorMessage } = this.state
    const { isOpen, text, onClose } = this.props
    return (
      <Modal isOpen={isOpen} hasCloseButton onClose={onClose}>
        <CalendarIcon />
        {errorMessage && <Alert type={'negative'}>{errorMessage}</Alert>}
        <Title>Cadastre um Bloqueio</Title>
        <Description>Por quantos dias deseja manter {text ? text : 'bloqueada(s) esta(s) sala(s)'} para o cliente?</Description>
        <Form>
          <Input
            onKeyPress={this.handleKeyPress}
            onChange={this.handleValue}
            value={expirationDays}
            isRequired
            type='text'
            pattern={'[0-9]+([,\\.][0-9]+)?'}
            error={errorMessage}
            name={'expirationDays'}
            label={'Número de dias'}
            placeholder={'1'}
          />
          <Button
            type={'button'}
            onClick={this.submit}
            category={'secondary'}
            fontWeight={800}
            full>
            Enviar
          </Button>
        </Form>
      </Modal>
    )
  }
}

export default ExpirationBudget
