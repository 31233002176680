import React, { Component } from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../../styles/themes'
import Icons from '../../components/ui/Icons'
import ModalListaCentroDeCustos from './ModalListaCentroCustos'

const TdTitulo = styled.td`
  color: #767676;
  font-weight: 600;
`

const TdIcons = styled.td`
  color: #767676;
  width: 40px;
  text-align: right;
  cursor: pointer;
`

const Tabela = styled.table`
  font-family: ${defaultTheme.fonts.primary};
  border-bottom: 1px solid #c3c3c3;
  font-size: 13px;
  width: 100%;
  color: #a5a5a5;
  td {
    border-bottom: 1px solid #c3c3c3;
    vertical-align:middle;
    padding: 10px 10px 10px 0px;
    @media(max-width: 1024px) {
     padding: 5px 5px;
    }
  }`

const Link = styled.span`
    cursor: pointer;
    color: #009688;
    text-decoration: underline;`

class TabelaUsuario extends Component {
  constructor () {
    super()
    this.state = {
      modalListaCentroCustos: false,
      dadosUsuarioSelecionado: ''
    }
  }

  abreFechaModalEPegaListaCentroCustos = (dadosUsuario) => {
    this.setState({
      modalListaCentroCustos: !this.state.modalListaCentroCustos,
      dadosUsuarioSelecionado: dadosUsuario
    })
  }

  mostraNomeDoNivel = (nivel) => {
    switch (nivel) {
      case 'HEADQUARTER_ADMIN':
        return 'Administrador geral'
      case 'BRANCH_ADMIN':
        return 'Administrador'
      case 'COSTCENTER_ADMIN':
        return 'Administrador de centro de custos'
      case 'STANDARD':
        return 'Básico'
      default:
        return ''
    }
  }

  render () {
    const dados = this.props.dados
    return (<>
      <ModalListaCentroDeCustos
        isOpen={this.state.modalListaCentroCustos}
        fechaModal={this.abreFechaModalEPegaListaCentroCustos}
        data={this.state.dadosUsuarioSelecionado}
        onClose={() => this.setState({ modalListaCentroCustos: false })} />
      <Tabela>
        <tbody>
          <tr>
            <TdTitulo >Nome do usuário</TdTitulo>
            <TdTitulo>Email</TdTitulo>
            <TdTitulo>Nível</TdTitulo>
            <TdTitulo>Empresa</TdTitulo>
            <TdTitulo>Área / Centro de custo</TdTitulo>
            <TdIcons></TdIcons>
            <TdIcons></TdIcons>
          </tr>
          {dados.map((usuario) => {
            return <tr key={usuario.id}>
              <td>{usuario.name}</td>
              <td>{usuario.email}</td>
              <td>{this.mostraNomeDoNivel((usuario.roles && usuario.roles.join(''))) || ""}</td>
              <td>{((usuario.branchOffices.length > 0 && usuario.branchOffices[0].name) || "")}</td>
              <td>
                {usuario.costCenters.length > 0 && usuario.costCenters[0].name}
                {usuario.costCenters.length > 1 && <Link onClick={() => this.abreFechaModalEPegaListaCentroCustos(usuario)}> +{usuario.costCenters.length - 1}</Link>}
              </td>
              {/* <td></td> */}
              <TdIcons>
                <span>
                  <Icons
                    onClick={() => this.props.edicao(usuario)}
                    icon={'pencil'}
                    color={'#767676'}
                    width={16}
                    height={16} />
                </span>
              </TdIcons>
              <TdIcons>
                <span >
                  <Icons
                    onClick={() => this.props.excluir(usuario)}
                    icon={'trash'}
                    color={'#767676'}
                    width={16}
                    height={16} />
                </span>
              </TdIcons>
            </tr>
          })
          }
        </tbody>
      </Tabela>
    </>
    )
  }
}

export default TabelaUsuario
