/* LIBS */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
/* ACTIONS */
import { signUpService } from '../../../redux/actions/signUp'
/* STYLES */
import { defaultTheme } from '../../styles/themes'
/* I18n */
import i18n from '../../../i18n'
/* UI */
import CheckboxButton from '../../components/ui/CheckboxButton'
import Button from '../../components/ui/Button'
import Loader from '../../components/Loader'
import Alert from '../../components/ui/Alert'
import { tem, naoTem } from '../../../utils/array'

const ACCOMMODATION = 'ACCOMMODATION'
const ROOM = 'ROOM'
const FOOD_BEVERAGE = 'FOOD_BEVERAGE'
const EQUIPMENT = 'EQUIPMENT'
const PARKING = 'PARKING'
const TRANSFER = 'TRANSFER'
const OTHER_SERVICES = 'OTHER_SERVICES'

const Content = styled.main`
  background-color: #FAFAFA;
  min-height: 100vh;
  width: 100%;
  padding: 0 3%;
  box-sizing: border-box;

  @media (min-width: 768px) {
    padding: 0 20%;
  }
`

const Steps = styled.span`
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  font-size: 14px;
  display: flex;
  padding: 15px 10px 3px 10px;
  color: #767676;
`

const Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  font-family: ${defaultTheme.fonts.secondary};
  padding: 3px 10px 13px 10px;
  color: #484848;
`

const Stepper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  box-sizing: border-box;
`

const StepperDescription = styled.div`
`

const Form = styled.form`
  flex-wrap: wrap;
  display: flex;
  padding: 0px 10px;
`

const Lista = styled.ol`
  margin-left: 15px;
  list-style: decimal;
`

const Item = styled.li`
  margin-top: 5px;
`

const TituloAlerta = styled.p`
  font-weight: 600;
  text-transform: uppercase;
`

const Destaque = styled.strong`
  font-weight: 600;
`

const Alerta = styled(Alert)`
  line-height: 1.2;
  margin: 10px;
  text-align: justify;
`

const tooltip = 'Brindes, Gráfica, Sinalização, RSVP, Coordenação Local, Recepcionista, Credenciamento, Palestrante, Mestre de Cerimônia, Ação de Ativação, Entretenimento, Segurança, Limpeza, Decoração, Fotografia, Ambulância, Vallet, Seguro Viagem, Outros Serviços'

class Services extends Component {
  constructor (props) {
    super(props)
    this.state = {
      hoteisSelecionados: [],
      desativaLocalEvento: false,
      desativaTransfer: false,
      desativaOutros: false,
      servicesSelected: []
    }
  }

  submit (e) {
    e.preventDefault()
    const { company } = this.props
    const services = []
    const servicesSelected = e.target.querySelectorAll('input[type="checkbox"]:checked')
    for (let i = 0; i < servicesSelected.length; i++) {
      services.push(servicesSelected[i].value)
    }
    if (this.parkingInvalido(services)) return window.alert(`O serviço de 'Estacionamento' deve estar associado ao serviço de 'Hospedagem' ou 'Salas'`)

    company.services = services
    this.props.signUpService(company)
  }

  componentDidMount () {
    const { company, history } = this.props
    if (!company) history.push('/signup')
  }

  parkingInvalido = services => {
    if (naoTem(PARKING, services)) return false
    return (naoTem(ACCOMMODATION, services) && naoTem(ROOM, services)) || tem(TRANSFER, services)
  }

  selecionaServico = (input) => {
    const { servicesSelected } = this.state
    var desativaLocalEvento, desativaTransfer, desativaOutros, todosServicosDesmarcados

    servicesSelected[input.target.name] = !servicesSelected[input.target.name]
    todosServicosDesmarcados = Object.keys(servicesSelected).every(svc => !servicesSelected[svc])

    if (todosServicosDesmarcados) {
      desativaTransfer = false
      desativaOutros = false
      desativaLocalEvento = false
    } else if (input.target.name === 'transfer') {
      desativaTransfer = false
      desativaOutros = true
      desativaLocalEvento = true
    } else if (input.target.name === 'other_services') {
      desativaTransfer = true
      desativaOutros = false
      desativaLocalEvento = true
    } else {
      desativaTransfer = true
      desativaOutros = true
      desativaLocalEvento = false
    }

    this.setState({ servicesSelected, desativaTransfer, desativaOutros, desativaLocalEvento })
  }

  render () {
    const { language, isFetching } = this.props
    return (
      <Content>
        {isFetching ? <Loader /> : null}
        <Stepper>
          <StepperDescription>
            <Steps>Etapa 3 de 3</Steps>
            <Title>Serviços oferecidos</Title>
          </StepperDescription>
        </Stepper>
        <Form onSubmit={this.submit.bind(this)} method='POST'>
          <Alerta type='edition'>
            <TituloAlerta>Atenção</TituloAlerta>
            <Lista>
              <Item>O serviço de <Destaque>transfer</Destaque> é exclusivo para fornecedores de transfer.</Item>
              <Item>Fornecedores de Espaços para Eventos, Hospedagem, A&amp;B e Equipamentos <Destaque>não podem se cadastrar</Destaque> também como fornecedores de Outros serviços</Item>
            </Lista>
          </Alerta>
          <CheckboxButton
            placeholder='Hospedagem'
            icon='bed-outline'
            name='accommodation'
            value={ACCOMMODATION}
            disabled={this.state.desativaLocalEvento}
            onClick={this.selecionaServico}
            viewBox='0 0 1536 1024'
            width={36}
            height={20}
          />
          <CheckboxButton
            placeholder='Salas'
            icon='room-outline'
            name='room'
            value={ROOM}
            disabled={this.state.desativaLocalEvento}
            onClick={this.selecionaServico}
            viewBox='0 0 1536 1024'
            width={36}
            height={20}
          />
          <CheckboxButton
            placeholder='Alimentos e bebidas'
            icon='food-beverage-outline'
            name='food_beverage'
            value={FOOD_BEVERAGE}
            disabled={this.state.desativaLocalEvento}
            onClick={this.selecionaServico}
            width={36}
            height={20}
          />
          <CheckboxButton
            placeholder='Equipamentos'
            icon='computer-outline'
            name='equipment'
            value={EQUIPMENT}
            disabled={this.state.desativaLocalEvento}
            onClick={this.selecionaServico}
            viewBox='0 0 1280 1024'
            width={36}
            height={20}
          />
          <CheckboxButton
            placeholder='Estacionamento'
            icon='parking-outline'
            name='parking'
            value={PARKING}
            disabled={this.state.desativaLocalEvento}
            onClick={this.selecionaServico}
            viewBox='0 80 1152 860'
            width={36}
            height={20}
          />
          <CheckboxButton
            placeholder='Transfer'
            icon='bus-outline'
            name='transfer'
            value={TRANSFER}
            disabled={this.state.desativaTransfer}
            onClick={this.selecionaServico}
            width={36}
            height={20}
          />
          <CheckboxButton
            placeholder='Outros serviços'
            icon='outros-servicos-outline'
            name='other_services'
            value={OTHER_SERVICES}
            tooltip={tooltip}
            disabled={this.state.desativaOutros}
            onClick={this.selecionaServico}
            viewBox='0 0 1152 1024'
            width={36}
            height={20}
          />
          <Button
            type='submit'
            category='secondary'
            fontWeight={800}
            full
            children={i18n.t('signUp.finish_action', { language })}
          />
        </Form>
      </Content>
    )
  }
}

function mapStateToProps (state) {
  const { app, signUp } = state
  const { language } = app
  const { company, isFetching } = signUp

  return {
    language,
    company,
    isFetching
  }
}

export default connect(mapStateToProps, { signUpService })(Services)
