import { createGlobalStyle, css } from 'styled-components'
import PlutoLightOTF from './fonts/otf/Pluto/PlutoLight.otf'
import PlutoRegularOTF from './fonts/otf/Pluto/PlutoRegular.otf'
import PlutoBoldOTF from './fonts/otf/Pluto/PlutoBold.otf'
import PlutoLightEOT from './fonts/eot/Pluto/PlutoLight.eot'
import PlutoRegularEOT from './fonts/eot/Pluto/PlutoRegular.eot'
import PlutoBoldEOT from './fonts/eot/Pluto/PlutoBold.eot'
import PlutoLightSVG from './fonts/svg/Pluto/PlutoLight.svg'
import PlutoRegularSVG from './fonts/svg/Pluto/PlutoRegular.svg'
import PlutoBoldSVG from './fonts/svg/Pluto/PlutoBold.svg'
import PlutoLightTTF from './fonts/ttf/Pluto/PlutoLight.ttf'
import PlutoRegularTTF from './fonts/ttf/Pluto/PlutoRegular.ttf'
import PlutoBoldTTF from './fonts/ttf/Pluto/PlutoBold.ttf'
import PlutoLightWOFF from './fonts/woff/Pluto/PlutoLight.woff'
import PlutoRegularWOFF from './fonts/woff/Pluto/PlutoRegular.woff'
import PlutoBoldWOFF from './fonts/woff/Pluto/PlutoBold.woff'

const reset = css`
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400');

* {
  box-sizing: border-box;
}

::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
}

label {
  cursor: pointer;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
  background-color: #fafafa;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
`

const defaultStyles = createGlobalStyle`
${reset}
@font-face {
  font-family: 'Pluto';
  font-style: normal;
  font-weight: 300;
  src: url(${PlutoLightOTF}),
  url(${PlutoLightSVG}) format('svg'),
  url(${PlutoLightTTF}) format('truetype'),
  url(${PlutoLightWOFF}) format('woff'),
  url(${PlutoLightEOT}) format('embedded-opentype');
}

@font-face {
  font-family: 'Pluto';
  font-weight: 500;
  font-style: normal;
  src: url(${PlutoRegularOTF}) ,
  url(${PlutoRegularSVG}) format('svg'),
  url(${PlutoRegularTTF}) format('truetype'),
  url(${PlutoRegularWOFF}) format('woff'),
  url(${PlutoRegularEOT}) format('embedded-opentype');
}

@font-face {
  font-family: 'Pluto';
  font-weight: 800;
  font-style: normal;
  src: url(${PlutoBoldOTF}),
  url(${PlutoBoldSVG}) format('svg'),
  url(${PlutoBoldTTF}) format('truetype'),
  url(${PlutoBoldWOFF}) format('woff'),
  url(${PlutoBoldEOT}) format('embedded-opentype');
}
`
export default defaultStyles
