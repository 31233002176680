import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'

export default class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33
    },
    zoom: 14,
    title: 'Local do evento'
  }

  render() {
    const renderMarkers = (map, maps) => {
      new maps.Marker({
        position: this.props.center,
        map,
        title: this.props.title
      })
    }
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: this.props.height || '300px', width: this.props.width || '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "batata"/* YOUR KEY HERE */ }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          onGoogleApiLoaded={({map, maps}) => renderMarkers(map, maps)}
        />
      </div>
    )
  }
}
