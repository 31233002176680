/* LIBS */
import { Get, Post, Put } from '../../../../utils/request'
import config from '../../../../middleware/config'

import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
/* STYLES */
import { defaultTheme } from '../../../styles/themes'
/* COMPONENTS */
import Header from '../../../components/Header'
import Loader from '../../../components/Loader'
import Alert from '../../../components/ui/Alert'
import Icons from '../../../components/ui/Icons'
import Button from '../../../components/ui/Button'
import Subtitle from '../../../components/ui/Subtitle'
import BreadCrumb from '../../../components/BreadCrumb'
import CommisedRate from './CommisedRate'
import ExpirationBudget from './ExpirationBudget'
import formatCurrency from '../../../../utils/money';
import { diffBetweenDates, addDays } from '../utils';

const Main = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #FAFAFA;
`
const Content = styled.div`
  min-height: 70vh;
  padding: 20px;
  font-family: ${defaultTheme.fonts.secondary};
  width: 80%;
  font-size: 14px;
  position: relative;
  a {
    text-decoration: none;
  }
`

const RoomTitle = styled.span`
  font-family: ${defaultTheme.fonts.secondary};
  color: ${defaultTheme.colors.text.darker};
  font-weight: 500;
  font-size: 16px;
`

const Title = styled.h1`
  width: 100%;
  text-align: left;
  font-size: 20px;
`

const Card = styled.div`
  background-color: #fff;
  padding: 15px 40px 15px 15px;
  width: 100%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  margin: 10px 0;
  line-height: 1.3;
  color: ${defaultTheme.colors.text.dark};
  position: relative;
  display: flex;
  justify-content: space-between;
  &::after {
    content: '>';
    margin: auto;
    position: absolute;
    top: 50%;
    right: 10px;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    font-size: 18px;
  }
`
const Tag = styled.div`
  padding: 3px 5px;
  font-size: 12px;
  font-family: ${defaultTheme.fonts.primary};
  color: #FAFAFA;
  background-color: ${props => props.edited ? '#F39C12' : (props.contraproposta ? '#8800dd' : '#009688')};
  border: none;
  border-radius: 15px;
  position: absolute;
  right: 10px;
  top: 10px;
  text-transform: uppercase;
  z-index: 9;
`

const Group = styled.div`
`

const Room = ({ id, roomType, event, checked, index, state }) => {
  return (
    <Link to={{ pathname: `/budget/briefing/rooms/${event}/${id}`, state: state }}>
      <Card>
        <RoomTitle>Sala {index + 1} - {roomType}</RoomTitle>
        {checked ?
          <Icons
            icon={'checkmark'}
            color={'#3BB082'}
            width={22}
            height={22}
            style={{ position: 'absolute', right: '40px' }}
          />
          : null}
      </Card>
    </Link>
  )
}



class Rooms extends Component {
  state = {
    isLoading: false,
    errorMessage: false,
    CommisedOpen: false,
    ExpirationBudgetOpen: false,
    rooms: [],
    event: { client: {} },
    reseller: undefined
  }

  componentDidMount() {
    const { match: { params: { id } } } = this.props
    this.fetchEvent()
    if (id) {
      this.setState({ isLoading: true })
      const { api: { base, version } } = config
      const userType = window.localStorage.getItem('user_type')
      const endpointUserType = userType === 'RESELLER' ? 'consultant/event' : 'event'
      const endpoint = `${base}${version}/${endpointUserType}/${id}/briefing/room`
      Get(endpoint)
      .then(briefing => this.setState({ briefing }, () => this.fetchEvent()))
      .catch(err => {
        this.setState({ isLoading: false })
        alert(`Error (${err.message})`)
        console.error(err.message)
      })
      .catch(err => {
        this.setState({ isLoading: false })
        alert(`Error (${err.message})`)
        console.error(err.message)
      })
    }
  }

  fetchEvent () {
    const { api: { base, version, routes: { events, supplier } } } = config
    const { match: { params: { id } } } = this.props
    const endpoint = `${base}${version}${supplier}${events}/${id}`

    Get(endpoint).then((response) => {
      this.setState({ event: response && response.event, isLoading: false })
    }).catch(err => {
      this.setState({ isLoading: false })
      alert('Erro ao buscar os dados, entre em contato com o suporte via chat')
      console.log('Error: ', err)
    })
  }

  submitRooms = () => {
    if(!this.isCounterProposalValid()) {
      return alert('Você aceitou a contraproposta, por favor preencha os campos para que o valor da nova oferta seja menor ou igual a contraproposta solicitada.')
    }
    if(!this.isNewCounterProposalValid()) {
      return alert('Você não pode criar uma contraproposta com o valor acima do valor inicial enviado.')
    }
    var budget = JSON.parse(window.localStorage.getItem('fluxo_salas'))
    const qtdSalasPedidas = this.state.briefing.rooms.length
    const qtdSalasEnvidas = !budget.roomsBudget ? -1 : Object.keys(budget.roomsBudget).length
    if (qtdSalasPedidas !== qtdSalasEnvidas) {
      alert('É necessário enviar preços ou confirmar indisponibilidade para cada uma das salas solicitadas pelo cliente')
    } else {
      budget.roomsBudget = Object.keys(budget.roomsBudget).map(room => {
        return budget.roomsBudget[room]
      })
      this.setState({ rooms: budget }, () => {
        if(this.state.event.supplierChainId && this.state.rooms.roomsBudget.some(item => item.hasAvailability)) {
          let { rooms } = this.state
          rooms = {
            ...rooms,
            commission: {
              fee: {
                amount: 0,
                type: 'PERCENTAGE'
              },
              reason: '',
              hasCommission: false
            }
          }
          this.setState({ rooms: rooms, CommisedOpen: false, ExpirationBudgetOpen: true })
        } else if(this.state.rooms.roomsBudget.some(item => item.hasAvailability)) {
          this.setState({ CommisedOpen: true })
        } else {
          let { rooms } = this.state
          const { match: { params: { id } } } = this.props
          rooms = { ...rooms, amountOfBlockedDays: 0,
            commission: {
              fee: {
                amount: 0,
                type: 'PERCENTAGE'
              },
              reason: '',
              hasCommission: 'no'
            },
            counterProposals: budget.counterProposals
          }
          this.setState({ ExpirationBudgetOpen: false, isLoading: true })
          const { api: { base, version } } = config
          var endpoint = budget.budgetId === 0 ? `${base}${version}/budget/room/` : `${base}${version}/budget/room/${budget.budgetId}`

          if (this.props.location.state && (this.props.location.state.counterProposal === 'ACCEPTED' || this.props.location.state.counterProposal === 'NEW')) {
            endpoint = `${base}${version}/budget/room/${this.props.location.state.counterProposal === 'ACCEPTED' ? 'counter-proposal' : 'supplier-counter-proposal'}/${budget.budgetId}`
            Put(endpoint, rooms)
            .then(() => {
              this.props.history.push(`/budget/resume/${id}`)
            }).catch(err => {
              console.error(err.message)
              this.setState({
                isLoading: false
              })
              alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
            })
          } else {
            const api = budget.budgetId === 0 ? Post(endpoint, rooms) : Put(endpoint, rooms)
            api.then(result => {
              this.setState({ isLoading: false })
              this.props.history.push(`/budget/resume/${id}`)
            })
            .catch(err => {
              this.setState({
                isLoading: false
              })
              alert(`Infelizmente, não conseguimos finalizar o seu cadastro (${err.message})`)
              console.error(err.message)
            })
          }
        }
      })
    }

  }

  submitCommisedRate = ({ commised, reason, value }) => {
    let { rooms } = this.state
    rooms = {
      ...rooms,
      commission: {
        fee: {
          amount: value,
          type: 'PERCENTAGE'
        },
        reason: reason,
        hasCommission: commised === 'yes'
      }
    }
    this.setState({ rooms: rooms, CommisedOpen: false, ExpirationBudgetOpen: true })
  }

  submit = ({ expirationDays }) => {
    if(!this.isCounterProposalValid()) {
      return alert('Você aceitou a contraproposta, por favor preencha os campos para que o valor da nova oferta seja menor ou igual a contraproposta solicitada.')
    }
    if(!this.isNewCounterProposalValid()) {
      return alert('Você não pode criar uma contraproposta com o valor acima do valor inicial enviado.')
    }
    let { rooms } = this.state
    const { match: { params: { id } } } = this.props
    var budget = JSON.parse(window.localStorage.getItem('fluxo_salas'))
    rooms = { ...rooms, amountOfBlockedDays: expirationDays, counterProposals: budget.counterProposals }
    this.setState({ ExpirationBudgetOpen: false, isLoading: true })
    const { api: { base, version } } = config
    var endpoint = rooms.budgetId === 0 ? `${base}${version}/budget/room/` : `${base}${version}/budget/room/${rooms.budgetId}`
    if(this.props.location.state && (this.props.location.state.counterProposal === 'ACCEPTED' || this.props.location.state.counterProposal === 'NEW')) {
      endpoint = `${base}${version}/budget/room/${this.props.location.state.counterProposal === 'ACCEPTED' ? 'counter-proposal' : 'supplier-counter-proposal'}/${rooms.budgetId}`
      Put(endpoint, rooms)
      .then(() => {
        this.props.history.push(`/budget/resume/${id}`)
      }).catch(err => {
        console.error(err.message)
        this.setState({
          isLoading: false
        })
        alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
      })
    }
    else if(rooms.budgetId === 0) {
      Post(endpoint, rooms)
      .then(() => {
        this.props.history.push(`/budget/resume/${id}`)
      })
      .catch(err => {
        this.setState({
          isLoading: false
        })
        alert(`Infelizmente, não conseguimos finalizar o seu cadastro (${err.message})`)
        console.error(err.message)
      })
    } else {
      Put(endpoint, rooms)
      .then(() => {
        this.props.history.push(`/budget/resume/${id}`)
      })
      .catch(err => {
        this.setState({
          isLoading: false
        })
        alert(`Infelizmente, não conseguimos finalizar o seu cadastro (${err.message})`)
        console.error(err.message)
      })
    }
  }

  isCounterProposalValid = () => {
    if(this.props.location.state && this.props.location.state.counterProposal === 'ACCEPTED') {
      return this.props.location.state.requestedPrice >= this.total()
    } else return true
  }

  isNewCounterProposalValid = () => {
    if(this.props.location.state && this.props.location.state.counterProposal === 'NEW') {
      return this.props.location.state.initialPrice >= this.total()
    } else return true
  }


  total = () => {
    var budget = JSON.parse(window.localStorage.getItem('fluxo_salas'))
    return Object.keys(budget.roomsBudget).reduce((total, item) => {
      total += (budget.roomsBudget[item].pricePerDay * (diffBetweenDates(budget.roomsBudget[item].info.startDate, addDays(budget.roomsBudget[item].info.endDate, 1)))) + parseFloat(budget.roomsBudget[item].mountingPrice)
      return total
    }, 0)
  }

  render() {
    const { errorMessage, CommisedOpen, ExpirationBudgetOpen, isLoading } = this.state
    const { match: { params: { id } } } = this.props
    const { isFetching, briefing, isFetchingBriefing } = this.state
    const budget = JSON.parse(window.localStorage.getItem('fluxo_salas'))
    return (
      <Main>
        <Header />
        <BreadCrumb options={
          [
            {link: '/', name: 'Home'},
            {link: `/budget/resume/${id}`, name: 'Resumo'},
            {name: 'Salas'}
          ]
        } />
        <CommisedRate
          isOpen={CommisedOpen}
          onClose={() => this.setState({ CommisedOpen: false })}
          onSubmit={this.submitCommisedRate}
          client={this.state.event && this.state.event.client}
        />
        <ExpirationBudget
          initialValue={this.state.event.supplierChainId ? 0 : 10}
          isOpen={ExpirationBudgetOpen}
          onClose={() => this.setState({ ExpirationBudgetOpen: false })}
          onSubmit={this.submit}
        />
        <Content>
          <Title>Salas</Title>
          {this.props.location.state && (this.props.location.state.counterProposal === 'ACCEPTED' || this.props.location.state.counterProposal === 'NEW') && <Tag contraproposta>Contraproposta</Tag>}
          {
            this.props.location.state && (this.props.location.state.counterProposal === 'ACCEPTED' || this.props.location.state.counterProposal === 'NEW') && <Group style={{ textAlign: 'right', padding: '0px 15px' }}>
              <Subtitle>Valor da contraproposta <span style={{ fontWeight: 'bold' }}>{formatCurrency(this.props.location.state.requestedPrice)}</span>&nbsp;&nbsp;&nbsp;&nbsp;Valor da nova oferta <span style={{ fontWeight: 'bold', color: 'red' }}>{formatCurrency(this.total())}</span></Subtitle>
            </Group>
          }
          {(isFetching || isFetchingBriefing || isLoading)
            && <Loader />
          }
          {errorMessage
            && <Alert type={'negative'}>{errorMessage}</Alert>
          }
          {
            briefing
              ? <RoomsList
                rooms={briefing.rooms}
                event={id}
                budget={budget}
                state={this.props.location.state} />
              : <p>Nenhuma Sala localizada</p>
          }
        </Content>
        <Button
          type='button'
          category={'secondary'}
          fontWeight={800}
          onClick={this.submitRooms}
          style={{ width: '80%' }}
          full>
          Salvar
          </Button>
      </Main>
    )
  }
}

const RoomsList = ({ rooms, event, budget, state }) => {
  const checkRoomExist = ({ room, roomsBudget }) => {
    if (budget.roomsBudget) {
      const roomsOnBudget = Object.keys(budget.roomsBudget)
      return roomsOnBudget.includes(room)
    } else {
      return false
    }
  }

  return rooms.map((room, i) => {
    return <Room
      {...room}
      index={i}
      key={i}
      event={event}
      checked={checkRoomExist({ room: room.id, roomsBudget: budget.roomsBudget })}
      state={state} />
  })
}

export default Rooms
