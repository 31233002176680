import React, { Component } from 'react'
import styled from 'styled-components'
import Input from '../components/ui/Input'
import Button from '../components/ui/Button'
import DatepickerSingle from './Datepicker'
import DatepickerRange from './Datepicker/Range'
import DatepickerMultiple from './Datepicker/Multiple'
import { formatDate } from './Datepicker_U/utils'
import { defaultTheme } from '../styles/themes'


/* STYLE */
const ModalCalendar = styled.div`
 display: flex;
 z-index: 999;
 position: absolute;
 font-family: ${defaultTheme.fonts.secondary};
 right: 24px;
 @media(max-width: 768px){
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 999;
 }
`

const BoxCalendar = styled.div`
  background-color: #FFF;
  margin: -12px 0px 0px 10px;
  border: 1px solid #CCC;
`

const Content = styled.div`
  width: 100%;
`


class InputDatepicker extends Component {
  static defaultProps = {
    placeholder: 'Selecione as datas',
    label: 'Período',
    type: 'single',
    onChange: () => { },
    dates: []
  }

  constructor(props) {
    super()
    this.state = {
      isVisible: false,
      dates: props.dates,
      datesFormated: ''
    }
  }

  datepickerListener = (e) => {
    const itemDataset = Object.keys(e.target.dataset)
    if (!itemDataset.includes('datepicker')) {
      this.setState({ isVisible: false })
    }
  }

  componentWillReceiveProps(props) {
    this.setState({dates: props.dates, datesFormated: this.format(props.dates)})
  }

  /*
    componentWillUnmount() {
      const body = document.querySelector('body')
      body.removeEventListener('click', this.datepickerListener, false)
    } */

  toggleDatepicker = () => {
    this.setState(({ isVisible }) => ({ isVisible: !isVisible }))
  }

  changeDate = (dates) => {
    if(this.props.type === 'multiple') {
      this.props.onChange({target: {name: this.props.name, value: this.state.dates}})
      this.toggleDatepicker()
    } else {
      this.setState({ dates: dates }, () => {
        this.props.onChange({target: {name: this.props.name, value: this.state.dates}})
        this.toggleDatepicker()
      })
    }
  }

  formatTypes = (dates, types) => {
    const t = {
      'single': formatDate(dates[0]),
      'range': dates.from ? `${formatDate(dates.from)} - ${formatDate(dates.to)}` : '',
      'multiple': dates.length > 0 ? dates.map(d => formatDate(d)).join(', ') : ''
    }
    return t[types]
  }

  format = (dates) => {
    const { type } = this.props
    return this.formatTypes(dates, type)
  }

  render() {
    const { placeholder, label, type, error, name, edited, style } = this.props
    const { isVisible, dates } = this.state
    return (
      <Content style={style}>
        <Input
          placeholder={placeholder}
          label={label}
          name={name}
          onFocus={this.toggleDatepicker}
          data-datepicker={'input'}
          value={this.format(dates)}
          readOnly
          edited={edited}
          error={error}
          isRequired />
        {isVisible &&
          <Calendar
            onChange={this.changeDate}
            type={type}
            dates={dates} />}
      </Content>
    )
  }

}

class Calendar extends Component {
  static defaultProps = { onChange: () => {} }

  state = {dates: []}

  changeDates = (dates) => {
    this.setState({dates: dates})
  }

  confirm = () => {
    this.props.onChange(this.state.dates)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.confirm()
    }
  }
  setWrapperRef = (node) => {
    this.wrapperRef = node
  }

  render() {
    const { type, dates } = this.props
    return (
      <ModalCalendar>
        <BoxCalendar ref={this.setWrapperRef}>
          <RenderDatepicker dates={dates} type={type} onChange={this.changeDates}/>
          <Button
            type='button'
            category={'primary'}
            fontWeight={800}
            full
            onClick={this.confirm}>
            Confirmar
        </Button>
        </BoxCalendar>
      </ModalCalendar>)
  }
}

const RenderDatepicker = ({type, onChange, dates}) => {
  const typeOfDatepicker = {
    'single': <DatepickerSingle dates={dates} type={type} onChange={onChange} />,
    'range': <DatepickerRange dates={dates} type={type} onChange={onChange} />,
    'multiple': <DatepickerMultiple dates={dates} type={type} onChange={onChange} />,
    '': <DatepickerSingle dates={dates} type={type} onChange={onChange} />,
  }
  return typeOfDatepicker[type]
}

export default InputDatepicker
