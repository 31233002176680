import React from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../../styles/themes'

const ItemsMenuLateral = styled.li`
  background-color: ${props => props.selected ? '#f3f6f9' : '#fafafa'};
  border-left: ${props => props.selected ? `5px solid ${defaultTheme.colors.whiteLabel.primary}` : '5px solid #fafafa'};
  box-sizing: border-box;
  color: ${props => props.selected ? defaultTheme.colors.whiteLabel.primary : defaultTheme.colors.text.darker};
  cursor: ${props => props.selected ? 'unset' : 'pointer'};
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-size: 14px;
  font-weight: 400;
  min-height: 25px;
  padding: 15px 15px 15px 10px;
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};

  & svg {
    fill: ${props => props.selected ? defaultTheme.colors.whiteLabel.primary : defaultTheme.colors.text.darker};
  }
`

export default function (props) {
  return <ItemsMenuLateral {...props}>{props.children}</ItemsMenuLateral>
}
