import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { defaultTheme } from '../../styles/themes'

const Button = styled.button`
  align-items: center;
  cursor: pointer;
  font-size: ${props => props.fontSize ? props.fontSize : '14px'} ;
  font-family: ${defaultTheme.fonts.secondary};
  font-weight: ${props => props.fontWeight ? props.fontWeight : 500};
  text-align: center;
  text-transform: uppercase;
  background-color: ${props => props.theme.bg};
  color: ${props => props.theme.color};
  border: 2px solid ${props => props.theme.border};
  border-radius: 3px;
  width: ${props => props.full ? 'calc(100% - 20px)' : 'auto'};
  padding: ${props => props.small ? '3px 5px' : '14px 10px'};
  box-sizing: border-box;
  margin: 10px;
  display: flex;
  justify-content: center;
  &:hover {
    opacity: 0.7;
  };
  &:disabled {
    background-color: ${defaultTheme.colors.grey.light};
    color: #FFF;
    border: 2px solid ${defaultTheme.colors.grey.light};
  }
`

export default function (props) {
  const types = {
    primary: {
      bg: props.ghost
        ? 'transparent' : defaultTheme.colors.secondary.default,
      color: props.ghost
        ? defaultTheme.colors.secondary.default : defaultTheme.colors.text.light,
      border: defaultTheme.colors.secondary.default,
      bgHover: defaultTheme.colors.secondary.light,
      colorHover: defaultTheme.colors.text.light
    },
    secondary: {
      bg: props.ghost
        ? 'transparent' : defaultTheme.colors.whiteLabel.primary,
      color: props.ghost
        ? (props.red ? 'red' : defaultTheme.colors.whiteLabel.primary) : defaultTheme.colors.text.light,
      border: props.red ? 'red' : defaultTheme.colors.whiteLabel.primary,
      bgBorder: props.dark
        ? defaultTheme.colors.primary.dark : defaultTheme.colors.whiteLabel.primary,
      bgHover: props.dark
        ? defaultTheme.colors.primary.dark : defaultTheme.colors.primary.light,
      colorHover: defaultTheme.colors.text.light
    }
  }

  return (
    <ThemeProvider theme={props.category ?  types[props.category] : types.secondary} >
      <Button {...props} />
    </ThemeProvider>
  )
}
