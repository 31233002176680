import React, { Component } from 'react'

export default class SearchIcon  extends Component {
  render() {
    return (
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 37 37" style={{ ...this.props.style, enableBackground: 'new 0 0 37 37'}}>
        <g>
          <path d="M34.7,33.3l-1.9,1.9c-0.6,0.6-1.6,0.6-2.3,0l-6.7-6.7c-0.3-0.3-0.5-0.7-0.5-1.1v-1.1c-2.4,1.8-5.3,2.9-8.5,2.9
            C7.2,29.3,1,23.1,1,15.4S7.2,1.5,14.9,1.5s13.9,6.2,13.9,13.9c0,3.2-1.1,6.2-2.9,8.5h1.1c0.4,0,0.8,0.2,1.1,0.5l6.7,6.7
            C35.3,31.7,35.3,32.7,34.7,33.3z M23.4,15.4c0-4.7-3.8-8.5-8.5-8.5c-4.7,0-8.5,3.8-8.5,8.5c0,4.7,3.8,8.5,8.5,8.5
            C19.6,23.9,23.4,20.1,23.4,15.4z"/>
        </g>
      </svg>
    )
  }
}
