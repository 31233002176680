import React from 'react'
import styled from 'styled-components'
import Icon from './Icons'

import { defaultTheme } from '../../styles/themes'

/* const Checkbox = styled.input`
  font-size: 16px;
  background-color: #FFF;
  border-radius: 1px;
  cursor: pointer;
  padding: 6px 10px;
  border-bottom: ${props => props.error ? '2px solid red' : `2px solid ${defaultTheme.colors.primary.default}`};
  transition: all 0.2s linear;
  height: 20px;
  width: 20px;
` */
const Label = styled.div`
  flex-wrap: wrap;
  align-items: center;
  background-color: #FFF;
  cursor: pointer;
  justify-content: space-between;
  display: flex;
  color: ${props => props.error ? 'red' : '#484848'};
  font-family: ${defaultTheme.fonts.secondary};
  font-weight: 500;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  border: none;
      border-radius: 2px;
      box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
  text-decoration: none!important;
`
const FormControl = styled.div`
  margin: 10px 10px;
  box-sizing: border-box;
  width: calc(100% - 10px);
`
const LabelInfo = styled.span`
  display: flex;
  align-items: center;
  font-family: ${defaultTheme.fonts.secondary};
  width:  45%;
  min-width: 140px;
`

const Arrow = styled.span`
  font-weight: 500;
  font-family: ${defaultTheme.fonts.secondary};
  font-size: 20px;
`

const Total = styled.span`
  /* width:  45%; */
  text-align: right;
`
const Description = styled.p`
  font-size: 12px;
  margin-left: 56px;
  color: ${defaultTheme.colors.grey.light};
  font-weight: 100;
  & b {
    font-weight: 500;
  }
`
export default function (props) {
  return (
    <FormControl onClick={props.onClick}>
      <Label htmlFor={props.name} error={props.error}>{props.label}
        <LabelInfo>
          {props.icon ? <i style={{ 'minWidth': '55px' }}><Icon
            icon={props.icon}
            color={'#333'}
            width={props.width}
            height={props.height} /></i> : null}
          {props.placeholder}
        </LabelInfo>
        <Description>{props.description}</Description>
        <Total>
          {props.children}
        </Total>
        <Arrow>></Arrow>
        {props.subtitle && <LabelInfo style={{ width: '100%' }}>
          <Description>{props.subtitle}</Description>
        </LabelInfo>}
      </Label>
    </FormControl>
  )
}
