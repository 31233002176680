import React, { Component } from 'react'
import styled from 'styled-components'
import Chart from 'react-apexcharts'
import { defaultTheme } from '../../styles/themes'
import { formatCurrency } from '../../../utils/money'
import './GraficoBarra.css'

const ITENS_POR_PAGINA = 10

const Label = styled.a`
  font-size: 15px;
  font-family: ${defaultTheme.fonts.primary};
  line-height: 1.2;
  padding-left: 10px;
  padding-top: 20px;
`

const Group = styled.div`
`
export default class GraficoBarra extends Component {
  constructor (props) {
    super(props)
    this.state = {
      pagina: 1,
      options: {
        chart: {
          height: 430,
          type: 'bar',
          fontFamily: defaultTheme.fonts.primary
        },
        plotOptions: {
          bar: {
            horizontal: true,
            endingShape: 'rounded',
            barHeight: `${props.grafico.slice(0, ITENS_POR_PAGINA).length * 5}%`,
            dataLabels: {
              position: 'top',
            },
          }
        },
        colors: defaultTheme.colors.secondary.default,
        dataLabels: {
          enabled: true,
          formatter: function (value) {
            return formatCurrency(value * 100)
          },
          offsetX: 30,
          style: {
            fontSize: '12px',
            colors: ['black']
          },
          textAnchor: 'start'
        },
        xaxis: {
          categories: props.grafico.slice(0, ITENS_POR_PAGINA).map(item => item.titulo) || [],
        },
        tooltip: {
          theme: 'dark',
          x: {
            show: false
          },
          y: {
            formatter: function (value) {
              return formatCurrency(value * 100)
            },
            title: {
              formatter: function (_value, { dataPointIndex, w }) {
                return w.globals.labels[dataPointIndex]
              }
            }
          }
        }
      },
      series: [{
        data: props.grafico.slice(0, ITENS_POR_PAGINA).map(item => item.valor / 100) || [],
      }]
    }
  }

  componentWillReceiveProps (props) {
    this.setState({
      pagina: 1,
      options: {
        chart: {
          height: 430,
          type: 'bar',
          toolbar: { show: false },
          fontFamily: defaultTheme.fonts.primary
        },
        plotOptions: {
          bar: {
            horizontal: true,
            endingShape: 'rounded',
            barHeight: `${props.grafico.slice(0, ITENS_POR_PAGINA).length * 5}%`,
            dataLabels: {
              position: 'top',
            },
          }
        },
        colors: defaultTheme.colors.secondary.default,
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '12px',
            colors: ['black']
          },
          formatter: function (value) {
            return formatCurrency(value * 100)
          }
        },
        xaxis: {
          categories: props.grafico.slice(0, ITENS_POR_PAGINA).map(item => item.titulo) || [],
        },
        tooltip: {
          theme: 'dark',
          x: {
            show: false
          },
          y: {
            formatter: function (value) {
              return formatCurrency(value * 100)
            },
            title: {
              formatter: function (_value, { dataPointIndex, w }) {
                return w.globals.labels[dataPointIndex]
              }
            }
          }
        }
      },
      series: [{
        data: props.grafico.slice(0, ITENS_POR_PAGINA).map(item => item.valor / 100) || [],
      }]
    })
  }

  mudarPagina = (pagina) => {
    const INICIO = (pagina * ITENS_POR_PAGINA) - ITENS_POR_PAGINA
    const FIM = pagina * ITENS_POR_PAGINA
    var { options } = this.state

    options = {
      ...options,
      xaxis: { categories: this.props.grafico.slice(INICIO, FIM).map(item => item.titulo) || [] }
    }
    this.setState({
      pagina,
      options,
      series: [{
        data: this.props.grafico.slice(INICIO, FIM).map(item => item.valor / 100) || [],
      }]
    })
  }

  render () {
    return <>
      <Chart
        options={this.state.options}
        series={this.state.series}
        type='bar'
        width='100%'
      />
      <Group style={{ display: 'flex', width: 'fit-content', margin: 'auto' }}>
        {this.props.grafico.map((_value, index) => {
          if ((index % ITENS_POR_PAGINA) === 0) {
            return <Group key={index}>
              {this.state.pagina === (1 + (index / ITENS_POR_PAGINA))
                ? <Label>{1 + (index / ITENS_POR_PAGINA)}</Label>
                : <Label href='#' onClick={() => this.mudarPagina(1 + (index / ITENS_POR_PAGINA))}>{1 + (index / ITENS_POR_PAGINA)}</Label>
              }
            </Group>
          } else return null
        })}
      </Group>
    </>
  }
}
