import React, { Component } from 'react'
import { defaultTheme } from '../../styles/themes'

export default class MegafoneIcon extends Component {
  render() {
    const style = this.props.style
    style.enableBackground = 'new 0 0 312.53 218.15'
    return (
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        viewBox="0 0 312.53 218.15" style={style}>
      <g>
        <g>
          <circle style={{ fill: 'none', stroke: defaultTheme.colors.whiteLabel.primary, strokeWidth: 2, strokeMiterlimit: 10 }} cx="59.16" cy="32.38" r="5.41"/>
          <circle style={{ fill: 'none', stroke: defaultTheme.colors.whiteLabel.primary, strokeWidth: 2, strokeMiterlimit: 10 }} cx="306.12" cy="60.59" r="5.41"/>
          <circle style={{ fill: 'none', stroke: defaultTheme.colors.whiteLabel.primary, strokeWidth: 2, strokeMiterlimit: 10 }} cx="40.51" cy="204.62" r="5.41"/>
          <g>
            <line style={{ fill: 'none', stroke: defaultTheme.colors.whiteLabel.primary, strokeWidth: 2, strokeMiterlimit: 10 }} x1="257.64" y1="17.09" x2="243.98" y2="17.09"/>
            <line style={{ fill: 'none', stroke: defaultTheme.colors.whiteLabel.primary, strokeWidth: 2, strokeMiterlimit: 10 }} x1="250.81" y1="10.26" x2="250.81" y2="23.91"/>
          </g>
          <g>
            <line style={{ fill: 'none', stroke: defaultTheme.colors.whiteLabel.primary, strokeWidth: 2, strokeMiterlimit: 10 }} x1="13.65" y1="6.83" x2="0" y2="6.83"/>
            <line style={{ fill: 'none', stroke: defaultTheme.colors.whiteLabel.primary, strokeWidth: 2, strokeMiterlimit: 10 }} x1="6.83" y1="0" x2="6.83" y2="13.65"/>
          </g>
          <g>
            <line style={{ fill: 'none', stroke: defaultTheme.colors.whiteLabel.primary, strokeWidth: 2, strokeMiterlimit: 10 }} x1="276.2" y1="211.33" x2="262.55" y2="211.33"/>
            <line style={{ fill: 'none', stroke: defaultTheme.colors.whiteLabel.primary, strokeWidth: 2, strokeMiterlimit: 10 }} x1="269.38" y1="204.5" x2="269.38" y2="218.15"/>
          </g>
          <g>
            <line style={{ fill: 'none', stroke: defaultTheme.colors.whiteLabel.primary, strokeWidth: 2, strokeMiterlimit: 10 }} x1="32.41" y1="138.27" x2="18.76" y2="138.27"/>
            <line style={{ fill: 'none', stroke: defaultTheme.colors.whiteLabel.primary, strokeWidth: 2, strokeMiterlimit: 10 }} x1="25.59" y1="131.44" x2="25.59" y2="145.1"/>
          </g>
          <path style={{ fill: 'none', stroke: defaultTheme.colors.whiteLabel.primary, strokeWidth: 2, strokeMiterlimit: 10 }} d="M177.61,23.28c-6.54-1.59-4.4-10.43-10.94-12.02c-6.54-1.59-8.68,7.26-15.22,5.67
            c-6.54-1.59-4.4-10.43-10.94-12.02s-8.69,7.26-15.23,5.67"/>
          <path style={{ fill: 'none', stroke: defaultTheme.colors.whiteLabel.primary, strokeWidth: 2, strokeMiterlimit: 10 }} d="M59.16,76.72c-6.73,0-6.73-9.1-13.46-9.1c-6.73,0-6.73,9.1-13.46,9.1c-6.73,0-6.73-9.1-13.47-9.1
            s-6.73,9.1-13.47,9.1"/>
          <path style={{ fill: 'none', stroke: defaultTheme.colors.whiteLabel.primary, strokeWidth: 2, strokeMiterlimit: 10 }} d="M304.53,138.84c-5.83-3.37-1.28-11.25-7.11-14.61c-5.83-3.36-10.38,4.52-16.21,1.15
            c-5.83-3.37-1.28-11.25-7.11-14.62c-5.83-3.37-10.38,4.52-16.21,1.15"/>
        </g>
      </g>
      <g>
        <path style={{ fill: 'none', stroke: defaultTheme.colors.whiteLabel.primary, strokeWidth: 3, strokeMiterlimit: 10 }} d="M216.29,120.31l10.47,32.64c0.63,1.97,0.68,7.32-4.64,9.02c-1.33,0.43-3.45,0.36-4.75-0.13l-22.81-8.71
          c-11.46-4.37-24.19-4.71-35.86-0.97l-6,1.92c0.1,2.37,0.49,4.76,1.25,7.13c1.99,6.21,5.91,11.27,10.89,14.75
          c4.79,3.34,3.49,10.78-2.07,12.57l-15.86,5.08c-2.54,0.81-5.39,0.23-7.23-1.69c-5.79-6.06-10.33-13.45-13.05-21.94
          c-0.74-2.32-1.3-4.64-1.72-6.98l-7.2,2.31c-7.55,2.42-15.63-1.74-18.05-9.28l-6.57-20.5c-2.42-7.54,1.73-15.63,9.28-18.05
          l40.99-13.15c11.67-3.74,21.83-11.43,28.61-21.65l13.49-20.35c0.92-1.38,2.27-2.37,3.79-2.86c5.34-1.71,8.42,2.77,9.02,4.64
          l10.47,32.64c4.82,1.06,8.99,4.53,10.61,9.58C220.97,111.39,219.6,116.64,216.29,120.31z M189.17,82.73l-5.25,7.9
          c-8.57,12.92-21.43,22.65-36.19,27.38l6.57,20.5c14.76-4.73,30.87-4.3,45.36,1.23l8.86,3.38L189.17,82.73z"/>
      </g>
      </svg>
    )
  }
}
