/* LIBS */
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import jwtDecode from 'jwt-decode'
import { defaultTheme } from '../../../styles/themes'
/* COMPONENTS */
import Header from '../../../components/Header'
import Button from '../../../components/ui/Button'
import Title from '../../../components/ui/Title'
import Subtitle from '../../../components/ui/Subtitle'
import Modal from '../../../components/Modal'
import CheckBox from '../../../components/ui/CheckBox'
import Select from '../../../components/ui/Select'
import Textarea from '../../../components/ui/Textarea'
import MoneyInput from '../../../components/ui/MoneyInput'
import RightBoxInput from '../../../components/ui/RightBoxInput'
import Card from '../../../components/ui/Card'
import BreadCrumb from '../../../components/BreadCrumb'
import Loader from '../../../components/Loader'
import config from '../../../../middleware/config'
import { Get, Post, Put } from '../../../../utils/request'
import { formatDate } from '../../../../utils/dates'
import { formatCurrency } from '../../../../utils/money'
import { getNameFromKey } from '../../../../utils/transferTexts'
import CommisedRate from '../Rooms/CommisedRate'
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react'
import { TimelineCustom, TimelineCustomItem } from '../../../components/TimelineCustomizada'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #FAFAFA;
  & * { font-family: 'Open Sans', sans-serif }
`

const Main = styled.div`
  width: 80%;
  margin-top: '15px';
  padding: 20px 30px;
  background: #FAFAFA;
  flex: 1 0 auto;
`

const Label = styled.label`
  color: ${props => props.error ? 'red' : (props.edited ? '#F39C12' : '#484848')};
  box-sizing: border-box;
  padding: 5px 5px 5px 0px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
`

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  td {
    vertical-align: top;
  }
`

const Tag = styled.div`
  padding: 3px 5px;
  font-size: 12px;
  font-family: ${defaultTheme.fonts.primary};
  color: #FAFAFA;
  background-color: ${props => props.edited ? '#F39C12' : (props.contraproposta ? '#8800dd' : '#009688')};
  border: none;
  border-radius: 15px;
  position: absolute;
  right: 10px;
  top: -17px;
  text-transform: uppercase;
  z-index: 9;
`

const LinkShowDetails = styled.a`
  display: block;
  width: 100%;
  text-align: right;
  font-size: 13px;
  color: ${defaultTheme.colors.grey.medium};
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  color: #ccc;
  text-decoration: underline;
  cursor: pointer;
  padding-left: 10px;
`

const Group = styled.div`
`

const FlexGroup = styled.div`
  display: flex;
`

const ListagemDividida = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-content: space-between;
  justify-content: space-between;
`

class BriefingTransfer extends Component {
  constructor () {
    super()
    this.state = {
      isLoading: true,
      verMais: false,
      verMaisDetalhes: '',
      briefings: [],
      event: { client: {} },
      counterProposals: [],
      requests: [],
      termsAndConditions: [],
      briefing: {},
      defaultTransfer: {},
      observation: '',
      observationValue: '',
      briefingId: '',
      CommisedOpen: false,
      commission: {},
      foodsAndBeveragesId: '',
      reseller: undefined,
      differences: {},
      lastUpdateType: '',
      availability: true
    }
  }

  componentDidMount () {
    const { match: { params: { event } } } = this.props
    if (event) {
      this.fetchBriefing()
      this.fetchEvent()
    }
  }

  fetchEvent () {
    const { api: { base, version, routes: { events, supplier } } } = config
    const { match: { params: { event } } } = this.props
    const endpoint = `${base}${version}${supplier}${events}/${event}`

    Get(endpoint).then(dados => {
      this.setState({ event: dados.event })
    }).catch(err => {
      console.log('Error: ', err)
    })
  }

  fetchEditDifferences () {
    const { api: { base, version } } = config
    const id = this.state.briefingId
    const endpoint = `${base}${version}/briefing/transfer/${id}/rollback/difference`

    Get(endpoint).then((differences) => {
      this.setState({ differences, isLoading: false })
    }).catch(err => {
      console.error('Error: ', err)
    })
  }

  fetchBriefing () {
    this.setState({ isLoading: true })
    const { api: { base, version, routes: { events, transfer } } } = config
    const { match: { params: { event } } } = this.props
    const eventEndpoint = `${base}${version}${events}/${event}/briefing/transfer`
    Get(eventEndpoint).then((response) => {
      const token = window.localStorage.getItem('user_token')
      const id = jwtDecode(token).accountId
      const transferEndpoint = `${base}${version}${transfer}/account/${id}`
      Get(transferEndpoint).then((defaultTransfer) => {
        const { match: { params: { budget } } } = this.props
        if (budget) {
          const budgetEndpoint = `${base}${version}/budget/transfer/${budget}`
          Get(budgetEndpoint).then((budgetInfo) => {
            this.setState({
              isLoading: budgetInfo.status === 'REVIEW',
              lastUpdateType: budgetInfo.lastUpdateType,
              requests: budgetInfo.requests,
              termsAndConditions: budgetInfo.termsAndConditions,
              counterProposals: budgetInfo.counterProposals,
              briefing: response,
              defaultTransfer: defaultTransfer[0],
              briefingId: response.id,
              eventId: response.eventId
            }, () => budgetInfo.status === 'REVIEW' && this.fetchEditDifferences())
          }).catch(err => {
            this.setState({ isLoading: false })
            console.error('Error: ', err)
          })
        } else {
          var requests = response.requests.map(item => {
            var serviceFee = defaultTransfer[0] && defaultTransfer[0].fees.find(({ name }) => name === 'serviceFee')
            var otherFees = defaultTransfer[0] && defaultTransfer[0].fees.find(({ name }) => name === 'otherFees')
            var coordinator = defaultTransfer[0] && defaultTransfer[0].coordinatorPrices.find(coordinator => coordinator.type === item.coordinatorLanguage)
            var novoItem = {
              briefingRequestId: item.id,
              hasAvailability: true,
              vehicles: [],
              onboardServices: {
                services: defaultTransfer[0] && defaultTransfer[0].onboardServices.services,
                lunchBoxes: defaultTransfer[0] && defaultTransfer[0].onboardServices.lunchBoxes.map((item, index) => {
                  item.id = index
                  return item
                })
              },
              fees: [{
                name: 'serviceFee',
                amount: (serviceFee && serviceFee.amount) || 0,
                type: (serviceFee && serviceFee.type) || 'PERCENTAGE'
              }, {
                name: 'otherFees',
                amount: (otherFees && otherFees.amount) || 0,
                type: (otherFees && otherFees.type) || 'PERCENTAGE'
              }],
              observation: ''
            }
            if (item.coordinatorLanguage) {
              novoItem.coordinatorPrices = {
                languages: coordinator && coordinator.languages,
                type: item.coordinatorLanguage,
                pricePerDay: {
                  amount: coordinator && coordinator.pricePerDay && coordinator.pricePerDay.amount,
                  currency: coordinator && coordinator.pricePerDay && coordinator.pricePerDay.currency
                },
                overtimePrice: {
                  amount: coordinator && coordinator.overtimePrice && coordinator.overtimePrice.amount,
                  currency: coordinator && coordinator.overtimePrice && coordinator.overtimePrice.currency
                }
              }
            }
            return novoItem
          })
          var termsAndConditions = defaultTransfer[0] && defaultTransfer[0].termsAndConditions
          this.setState({
            isLoading: false,
            defaultTransfer: defaultTransfer[0],
            requests,
            termsAndConditions,
            briefing: response,
            briefingId: response.id,
            eventId: response.eventId
          })
        }
      })
    }).catch(err => {
      this.setState({ isLoading: false })
      console.error('Error: ', err)
    })
  }

  submitCommisedRate = ({ commised, reason, value }) => {
    let { commission } = this.state
    commission = {
      fee: {
        amount: value,
        type: 'PERCENTAGE'
      },
      reason: reason,
      hasCommission: commised === 'yes'
    }
    this.setState({
      isLoading: true,
      commission,
      CommisedOpen: false
    }, () => {
      const { api: { base, version } } = config
      const { match: { params: { budget } } } = this.props
      if (this.props.location.state && (this.props.location.state.counterProposal === 'ACCEPTED' || this.props.location.state.counterProposal === 'NEW')) {
        const endpoint = `${base}${version}/budget/transfer/${this.props.location.state.counterProposal === 'ACCEPTED' ? 'counter-proposal' : 'supplier-counter-proposal'}/${budget}`
        Put(endpoint, this.generateJSON()).then(() => {
          const { match: { params: { event } } } = this.props
          this.props.history.push(`/budget/resume/${event}`)
        }).catch(err => {
          console.error(err.message)
          this.setState({
            isLoading: false
          })
          alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
        })
      } else if (budget) {
        const endpoint = `${base}${version}/budget/transfer/${budget}`
        Put(endpoint, this.generateJSON()).then(() => {
          const { match: { params: { event } } } = this.props
          this.props.history.push(`/budget/resume/${event}`)
        }).catch(err => {
          console.error(err.message)
          this.setState({
            isLoading: false
          })
          alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
        })
      } else {
        const endpoint = `${base}${version}/budget/transfer/`
        Post(endpoint, this.generateJSON()).then(() => {
          const { match: { params: { event } } } = this.props
          this.props.history.push(`/budget/resume/${event}`)
        }).catch(err => {
          console.error(err.message)
          this.setState({
            isLoading: false
          })
          alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
        })
      }
    })
  }

  generateJSON = () => {
    return {
      briefingId: this.state.briefingId,
      eventId: this.state.eventId,
      fee: this.state.requests.fees,
      commission: this.state.commission,
      counterProposals: this.state.counterProposals,
      termsAndConditions: this.state.termsAndConditions,
      requests: this.state.requests
    }
  }

  pegaRequestPorId = (id) => {
    const index = this.state.requests.findIndex(item => item.briefingRequestId === id)
    if (index !== -1) return this.state.requests[index]
    else return null
  }

  pegaBriefingPorRequest = (briefingRequestId) => {
    const index = this.state.briefing.requests.findIndex(item => item.id === briefingRequestId)
    if (index !== -1) return this.state.briefing.requests[index]
    else return null
  }

  pegaVeiculoPorIdETipo = (id, tipo) => {
    const request = this.pegaRequestPorId(id)
    const index = request ? request.vehicles.findIndex(item => item.type === tipo) : -1
    if (index !== -1) return request.vehicles[index]
    else return null
  }

  visualizaDetalhe = (detalhes) => {
    this.setState({ verMais: true, verMaisDetalhes: detalhes })
  }

  geraTextoTipoDeServico = (type, vehicleTime) => {
    switch (type) {
      case 'SHUTTLE':
        return 'Shuttle (circular)'
      case 'TRASLADO_SO_IDA':
        return 'Traslado só ida'
      case 'TRASLADO_IDA_VOLTA':
        return 'Traslado ida e volta'
      case 'VEICULO_DISPOSICAO':
        return `Veículo a disposição - Diária ${vehicleTime}h`
      default:
        return ''
    }
  }

  enableSolicitacao = (id) => {
    const { requests } = this.state
    const index = requests.findIndex(item => item.briefingRequestId === id)
    if (index !== -1) {
      requests[index].hasAvailability = !requests[index].hasAvailability
      this.setState({ requests })
    }
  }

  selecionaVeiculo = (id, tipo) => {
    const { requests, defaultTransfer } = this.state
    const index = requests.findIndex(item => item.briefingRequestId === id)
    if (index !== -1) {
      const veiculoIndex = requests[index].vehicles.findIndex(item => item.type === tipo)
      const defaultVeiculoIndex = defaultTransfer.vehicles.findIndex(item => item.type === tipo)
      if (veiculoIndex !== -1) {
        requests[index].vehicles.splice(veiculoIndex, 1)
      } else if (defaultVeiculoIndex !== -1) {
        requests[index].vehicles.push({
          type: tipo,
          price: {
            amount: 0,
            currency: 'BRL'
          },
          bilingualPrice: {
            amount: 0,
            currency: 'BRL'
          },
          extraKm: {
            amount: 0,
            currency: 'BRL'
          },
          overtime: {
            amount: 0,
            currency: 'BRL'
          },
          capacityWithLuggage: defaultTransfer.vehicles[defaultVeiculoIndex].capacityWithLuggage,
          capacityWithoutLuggage: defaultTransfer.vehicles[defaultVeiculoIndex].capacityWithoutLuggage,
          quantity: 0, // podem ser null ou 0
          days: 0 // podem ser null ou 0
        })
      }
      this.setState({ requests })
    }
  }

  atualizaValorDoVeiculo = (id, tipo, input, amountEspecifico) => {
    const { requests } = this.state
    const index = requests.findIndex(item => item.briefingRequestId === id)
    if (index !== -1) {
      const veiculoIndex = requests[index].vehicles.findIndex(item => item.type === tipo)
      if (veiculoIndex !== -1) {
        requests[index].vehicles[veiculoIndex][amountEspecifico].amount = input.target.value
      }
      this.setState({ requests })
    }
  }

  atualizaCoordinator = (id, tipo, input) => {
    const { requests } = this.state
    const index = requests.findIndex(item => item.briefingRequestId === id)
    if (index !== -1) {
      requests[index].coordinatorPrices[tipo].amount = input.target.value
      this.setState({ requests })
    }
  }

  atualizaServico = (id, name, tipo, input) => {
    const { requests } = this.state
    const index = requests.findIndex(item => item.briefingRequestId === id)
    if (index !== -1) {
      const servicoIndex = requests[index].onboardServices.services.findIndex(item => item.name === name)
      if (servicoIndex !== -1) {
        if (tipo === 'price')
          requests[index].onboardServices.services[servicoIndex][tipo].amount = input.target.value
        else
          requests[index].onboardServices.services[servicoIndex][tipo] = input.target.value
        this.setState({ requests })
      }
    }
  }

  selecionaLunchBox = (id, lunchBoxId) => {
    const { requests, defaultTransfer } = this.state
    const index = requests.findIndex(item => item.briefingRequestId === id)
    if (index !== -1) {
      const lunchBoxIndex = requests[index].onboardServices.lunchBoxes.findIndex(item => item.id === lunchBoxId)
      const defaultLunchBoxIndex = defaultTransfer.onboardServices.lunchBoxes.findIndex(item => item.id === lunchBoxId)
      if (lunchBoxIndex !== -1) {
        requests[index].onboardServices.lunchBoxes.splice(lunchBoxIndex, 1)
      } else if (defaultLunchBoxIndex !== -1) {
        requests[index].onboardServices.lunchBoxes.push({
          id: lunchBoxId,
          name: defaultTransfer.onboardServices.lunchBoxes[defaultLunchBoxIndex].name,
          price: {
            amount: defaultTransfer.onboardServices.lunchBoxes[defaultLunchBoxIndex].price.amount,
            currency: defaultTransfer.onboardServices.lunchBoxes[defaultLunchBoxIndex].currency
          },
          description: defaultTransfer.onboardServices.lunchBoxes[defaultLunchBoxIndex].description,
          quantity: defaultTransfer.onboardServices.lunchBoxes[defaultLunchBoxIndex].quantity, // podem ser null ou 0
        })
      }
      this.setState({ requests })
    }
  }

  selecionaTaxa = (id, name) => {
    const { requests } = this.state
    const index = requests.findIndex(item => item.briefingRequestId === id)
    if (index !== -1) {
      const feeIndex = requests[index].fees.findIndex(item => item.name === name)
      if (feeIndex !== -1) {
        requests[index].fees.splice(feeIndex, 1)
      } else {
        requests[index].fees.push({
          name: name,
          amount: 0,
          type: 'PERCENTAGE'
        })
      }
      this.setState({ requests })
    }
  }

  atualizaTaxa = (id, name, input) => {
    const { requests } = this.state
    const index = requests.findIndex(item => item.briefingRequestId === id)
    if (index !== -1) {
      const feeIndex = requests[index].fees.findIndex(item => item.name === name)
      if (feeIndex !== -1) {
        requests[index].fees[feeIndex].amount = input.target.value
      }
      this.setState({ requests })
    }
  }

  atualizaObservacao = (id, input) => {
    const { requests } = this.state
    const index = requests.findIndex(item => item.briefingRequestId === id)
    if (index !== -1) {
      requests[index].observation = input.target.value
      this.setState({ requests })
    }
  }

  atualizaTermos = (tipo, input) => {
    const { termsAndConditions } = this.state
    const index = termsAndConditions.findIndex(item => item.type === tipo)
    if (index !== -1) {
      termsAndConditions[index].description = input.target.value
      this.setState({ termsAndConditions })
    }
  }

  submitForm = () => {
    if (this.isValid()) this.setState({ CommisedOpen: true })
    else window.alert('Você deve preencher todas as informações para enviar seu orçamento.')
  }

  isValid = () => {
    return this.state.requests.filter(item => item.hasAvailability).every(item => {
      const verificaVeiculos = item.vehicles.some(veiculo => (veiculo.price.amount > 0) && (veiculo.bilingualPrice.amount > 0) && (veiculo.extraKm.amount > 0) && (veiculo.overtime.amount > 0))
      const verificaCoordinator = item.coordinatorPrices
        ? (
          (item.coordinatorPrices.pricePerDay.amount > 0) &&
          (item.coordinatorPrices.overtimePrice.amount > 0)
        ) : true
      const verificaServicos = item.onboardServices.services.some(item =>
        (item.price.amount > 0) &&
        (item.quantity > 0) &&
        item.type
      )
      const verificaLunchBoxes = (this.pegaBriefingPorRequest(item.briefingRequestId) &&
        this.pegaBriefingPorRequest(item.briefingRequestId).onboardServices) ?
        (item.onboardServices.lunchBoxes.length > 0) : true

      return (
        verificaVeiculos &&
        verificaCoordinator &&
        verificaServicos &&
        verificaLunchBoxes
      )
    })
  }

  Solicitacao = (props) => {
    const briefing = props.briefing
    return <CheckBox
      label={briefing.name}
      name={briefing.name}
      observation={'Não tenho disponibilidade para esta solicitação'}
      checked={this.pegaRequestPorId(briefing.id) && !this.pegaRequestPorId(briefing.id).hasAvailability}
      value={this.pegaRequestPorId(briefing.id) && !this.pegaRequestPorId(briefing.id).hasAvailability}
      onClick={() => this.enableSolicitacao(briefing.id)}>
      <Card style={{ marginLeft: '15px', width: 'calc(100% - 30px)' }}>
        <Label style={{ fontWeight: '600' }}>OBSERVAÇÕES DO CLIENTE:</Label>
        <Label>{briefing.observation}</Label>
      </Card>
      <Group style={{ padding: '20px' }}>
        <Table style={{ marginBottom: '20px' }}>
          <tr>
            <th style={{ width: '200px' }}>
              <Label style={{ fontWeight: '600' }}>Tipo de serviço:</Label>
            </th>
            <th style={{ width: '200px' }}>
              <Label style={{ fontWeight: '600' }}>Número de pessoas:</Label>
            </th>
            <th>
              <Label style={{ fontWeight: '600' }}>Haverá bagagens?</Label>
            </th>
          </tr>
          <tr>
            <td>
              <Label>{this.geraTextoTipoDeServico(briefing.type, briefing.vehicleTime)}</Label>
            </td>
            <td>
              <Label>{briefing.passengers}</Label>
            </td>
            <td>
              <Label>{briefing.luggage ? 'Sim' : 'Não'}</Label>
            </td>
          </tr>
        </Table>
        <Group style={{ width: '850px', margin: '50px 0' }}>
          <Group style={{ display: 'flex' }}>
            <Label style={{ color: '#727272', width: '25%', marginLeft: '12px' }}><span style={{ fontWeight: 'bold' }}>Data</span></Label>
            <Label style={{ color: '#727272', width: '75%', marginLeft: '25px' }}><span style={{ fontWeight: 'bold' }}>Local</span></Label>
          </Group>
          <TimelineCustom>
            <Timeline lineColor={'#ddd'} style={{}}>
              <TimelineCustomItem>
                <TimelineItem
                  key="origem"
                  bodyContainerStyle={{ marginBottom: '-20px' }}
                  dateComponent={(
                    <div
                      style={{
                        display: 'block',
                        float: 'left',
                        color: '#727272'
                      }}
                    >
                      <Label style={{ color: '#727272', marginBottom: '5px' }}>{formatDate(briefing.originDate)}</Label>
                    </div>
                  )}
                >
                  <Label style={{ color: '#727272', marginBottom: '5px' }}>{briefing.origin.address} ({briefing.origin.city})</Label>
                </TimelineItem>
              </TimelineCustomItem>
              {briefing.stops.map((stop, index) => (
                <TimelineCustomItem>
                  <TimelineItem
                    key={index}
                    bodyContainerStyle={{ marginBottom: '-20px' }}
                    dateComponent={<></>}
                  >
                    <Label style={{ color: '#727272', marginBottom: '5px' }}>{stop.address}</Label>
                  </TimelineItem>
                </TimelineCustomItem>
              ))}
              <TimelineCustomItem>
                <TimelineItem
                  key="destino"
                  bodyContainerStyle={{ marginBottom: '-20px' }}
                  style={{ color: '#727272' }}
                  dateComponent={(
                    <div
                      style={{
                        display: 'block',
                        float: 'left',
                        color: '#727272',
                      }}
                    >
                      <Label style={{ color: '#727272', marginBottom: '5px' }}>{formatDate(briefing.destinationDate)}</Label>
                    </div>
                  )}
                >
                  <Label style={{ color: '#727272', marginBottom: '5px' }}>{briefing.destination.address} ({briefing.destination.city})</Label>
                </TimelineItem>
              </TimelineCustomItem>
            </Timeline>
          </TimelineCustom>
        </Group>
        {this.state.defaultTransfer && this.pegaRequestPorId(briefing.id) && this.pegaRequestPorId(briefing.id).hasAvailability && <Group>
          <Label style={{ fontWeight: '600' }}>Quais veículos deseja oferecer nesta cotação?</Label>
          <ListagemDividida>
            {this.state.defaultTransfer.vehicles.map(vehiclesDefault => (
              <CheckBox
                key={vehiclesDefault.type + briefing.id}
                id={getNameFromKey(vehiclesDefault.type) + '-' + briefing.id}
                label={getNameFromKey(vehiclesDefault.type)}
                name={getNameFromKey(vehiclesDefault.type)}
                style={{ width: '100%' }}
                checked={this.pegaVeiculoPorIdETipo(briefing.id, vehiclesDefault.type)}
                value={this.pegaVeiculoPorIdETipo(briefing.id, vehiclesDefault.type)}
                onClick={() => this.selecionaVeiculo(briefing.id, vehiclesDefault.type)}>
                {this.pegaVeiculoPorIdETipo(briefing.id, vehiclesDefault.type) && <Group style={{ display: 'flex' }}>
                  <MoneyInput
                    type={'text'}
                    label={'Valor por veículo (por dia)'}
                    isRequired
                    placeholder={'0,00'}
                    name={vehiclesDefault.type}
                    error={!(this.pegaVeiculoPorIdETipo(briefing.id, vehiclesDefault.type).price.amount > 0)}
                    value={this.pegaVeiculoPorIdETipo(briefing.id, vehiclesDefault.type).price.amount}
                    onChange={(input) => this.atualizaValorDoVeiculo(briefing.id, vehiclesDefault.type, input, 'price')}
                  />
                  <MoneyInput
                    type={'text'}
                    label={'Valor por veículo se motorista bilíngue (por dia)'}
                    isRequired
                    placeholder={'0,00'}
                    name={vehiclesDefault.type}
                    error={!(this.pegaVeiculoPorIdETipo(briefing.id, vehiclesDefault.type).bilingualPrice.amount > 0)}
                    value={this.pegaVeiculoPorIdETipo(briefing.id, vehiclesDefault.type).bilingualPrice.amount}
                    onChange={(input) => this.atualizaValorDoVeiculo(briefing.id, vehiclesDefault.type, input, 'bilingualPrice')}
                  />
                  <MoneyInput
                    type={'text'}
                    label={'Km excedente (valor por km)'}
                    isRequired
                    placeholder={'0,00'}
                    name={vehiclesDefault.type}
                    error={!(this.pegaVeiculoPorIdETipo(briefing.id, vehiclesDefault.type).extraKm.amount > 0)}
                    value={this.pegaVeiculoPorIdETipo(briefing.id, vehiclesDefault.type).extraKm.amount}
                    onChange={(input) => this.atualizaValorDoVeiculo(briefing.id, vehiclesDefault.type, input, 'extraKm')}
                  />
                  <MoneyInput
                    type={'text'}
                    label={'Hora extra / parada (valor por hora)'}
                    isRequired
                    placeholder={'0,00'}
                    name={vehiclesDefault.type}
                    error={!(this.pegaVeiculoPorIdETipo(briefing.id, vehiclesDefault.type).overtime.amount > 0)}
                    value={this.pegaVeiculoPorIdETipo(briefing.id, vehiclesDefault.type).overtime.amount}
                    onChange={(input) => this.atualizaValorDoVeiculo(briefing.id, vehiclesDefault.type, input, 'overtime')}
                  />
                </Group>}
              </CheckBox>
            ))
            }
          </ListagemDividida>

          {briefing.hasCoordinator && <Label style={{ fontWeight: '600', marginTop: '20px' }}>Coordenador / receptivo</Label>}
          <ListagemDividida>
            {briefing.hasCoordinator && <Card style={{ width: 'calc((100% / 2) - 10px)' }} label={'Coordenador / receptivo ' + getNameFromKey(briefing.coordinatorLanguage)}>
              <FlexGroup>
                <MoneyInput
                  type={'text'}
                  label={'Valor do profissional (por dia)'}
                  isRequired
                  placeholder={'0,00'}
                  error={!(this.pegaRequestPorId(briefing.id) && this.pegaRequestPorId(briefing.id).coordinatorPrices.pricePerDay.amount > 0)}
                  value={this.pegaRequestPorId(briefing.id) && this.pegaRequestPorId(briefing.id).coordinatorPrices.pricePerDay.amount}
                  onChange={(input) => this.atualizaCoordinator(briefing.id, 'pricePerDay', input)}
                />
                <MoneyInput
                  type={'text'}
                  label={'Valor da hora extra (por hora)'}
                  isRequired
                  placeholder={'0,00'}
                  error={!(this.pegaRequestPorId(briefing.id) && this.pegaRequestPorId(briefing.id).coordinatorPrices.overtimePrice.amount > 0)}
                  value={this.pegaRequestPorId(briefing.id) && this.pegaRequestPorId(briefing.id).coordinatorPrices.overtimePrice.amount}
                  onChange={(input) => this.atualizaCoordinator(briefing.id, 'overtimePrice', input)}
                />
              </FlexGroup>
            </Card>}
          </ListagemDividida>

          {briefing.hasOnboardServices && <>
            <Label style={{ fontWeight: '600', marginTop: '20px' }}>Serviço de bordo</Label>
            {this.pegaRequestPorId(briefing.id) && this.pegaRequestPorId(briefing.id).onboardServices.services.map(servico => (
              <Card key={servico.name} label={getNameFromKey(servico.name)}>
                <FlexGroup>
                  {servico.name === 'water' && <Select
                    id={servico.name + '-' + briefing.id}
                    isRequired
                    label={'Tipo'}
                    name={servico.name}
                    value={servico.type}
                    error={!servico.type}
                    onChange={(input) => this.atualizaServico(briefing.id, servico.name, 'type', input)}>
                    <option value={''}>Selecione</option>
                    <option value={'BOTTLE'}>{getNameFromKey('BOTTLE')}</option>
                    <option value={'CUP'}>{getNameFromKey('CUP')}</option>
                  </Select>}
                  <RightBoxInput
                    id={'quantity-' + briefing.id}
                    placeholder={'0'}
                    label={'Quantidade (em ml)'}
                    rightBox='ml'
                    type='number'
                    isRequired
                    name={servico.quantity}
                    error={!(servico.quantity > 0)}
                    defaultValue={servico.quantity}
                    value={servico.quantity}
                    onChange={(input) => this.atualizaServico(briefing.id, servico.name, 'quantity', input)}
                    style={{ width: '100%' }} />
                  <MoneyInput
                    id={'price-' + briefing.id}
                    type={'text'}
                    label={'Valor (por unidade)'}
                    isRequired
                    placeholder={'0,00'}
                    name={servico.price}
                    error={!(servico.price.amount > 0)}
                    value={servico.price.amount}
                    onChange={(input) => this.atualizaServico(briefing.id, servico.name, 'price', input)} />
                </FlexGroup>
              </Card>
            ))}

            <Card label='Lunchbox' style={{ border: this.pegaRequestPorId(briefing.id) && this.pegaRequestPorId(briefing.id).onboardServices.lunchBoxes.length === 0 ? '2px solid red' : null }}>
              <Label>Selecione quais opções de lunchbox deseja enviar:</Label>
              <ListagemDividida>
                {this.state.defaultTransfer.onboardServices.lunchBoxes.map(lunchBox => (
                  <label key={lunchBox.id} style={{ width: 'calc((100% / 4) - 10px)' }} htmlFor={lunchBox.id}>
                    <Card style={{ paddingBottom: '10px', width: '100%' }} >
                      <Group style={{ display: 'flex', padding: '0px' }}>
                        <Label style={{ fontWeight: '600', color: '#484848' }}><b>{lunchBox.name} </b></Label>
                        <input
                          style={{ width: '25px', height: '20px' }}
                          name={lunchBox.id}
                          id={lunchBox.id}
                          type="checkbox"
                          value="option"
                          checked={this.pegaRequestPorId(briefing.id) && this.pegaRequestPorId(briefing.id).onboardServices.lunchBoxes.findIndex(item => item.id === lunchBox.id) !== -1}
                          onClick={() => this.selecionaLunchBox(briefing.id, lunchBox.id)} />
                      </Group>
                      <Label style={{ marginTop: '-10px' }}>{formatCurrency(lunchBox.price.amount)} / pessoa / dia</Label>
                      <Label style={{
                        minHeight: '100px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 4
                      }}>{lunchBox.description}</Label>
                      {lunchBox.description.length > 150 && <LinkShowDetails onClick={() => this.visualizaDetalhe(lunchBox.description)}>ver mais</LinkShowDetails>}
                    </Card>
                  </label>
                ))
                }
              </ListagemDividida>
            </Card>
          </>}

          <Label style={{ fontWeight: '600', marginTop: '30px' }}>Taxas</Label>

          <ListagemDividida style={{ justifyContent: 'start', marginBottom: '20px' }}>
            {this.state.defaultTransfer.fees.map(taxa => (
              <CheckBox
                key={taxa.name + briefing.id}
                id={getNameFromKey(taxa.name) + '-' + briefing.id}
                label={getNameFromKey(taxa.name)}
                name={getNameFromKey(taxa.name)}
                style={{ width: 'calc((100% / 4) - 20px)', margin: '10px 20px 0 0' }}
                checked={this.pegaRequestPorId(briefing.id) && this.pegaRequestPorId(briefing.id).fees.findIndex(item => item.name === taxa.name) !== -1}
                value={this.pegaRequestPorId(briefing.id) && this.pegaRequestPorId(briefing.id).fees.findIndex(item => item.name === taxa.name) !== -1}
                onClick={() => this.selecionaTaxa(briefing.id, taxa.name)}>
                {this.pegaRequestPorId(briefing.id) && this.pegaRequestPorId(briefing.id).fees.findIndex(item => item.name === taxa.name) !== -1 && <RightBoxInput
                  placeholder={'0'}
                  label={'% de taxa'}
                  rightBox='%'
                  type='number'
                  value={this.pegaRequestPorId(briefing.id) && this.pegaRequestPorId(briefing.id).fees.find(item => item.name === taxa.name).amount}
                  onChange={(input) => this.atualizaTaxa(briefing.id, taxa.name, input)}
                  style={{ width: '100%' }} />}
              </CheckBox>
            ))
            }
          </ListagemDividida>
          <Label style={{ fontWeight: '600', marginTop: '30px' }}>Observações gerais desta solicitação</Label>
          <Card>
            <Textarea
              id={'observation-' + briefing.id}
              type={'text'}
              label={'Observações'}
              placeholder={'Caso tenha alguma observação, escreva aqui.'}
              name={'observation'}
              value={this.pegaRequestPorId(briefing.id) && this.pegaRequestPorId(briefing.id).observation}
              onChange={(input) => this.atualizaObservacao(briefing.id, input)} />
          </Card>
        </Group>}
      </Group>
      {!this.state.defaultTransfer && <Subtitle style={{ color: 'red', margin: '20px', padding: '20px' }}>Você não possui nenhum transfer cadastrado. <Link to={`/transfer/add`}>Clique aqui</Link> para cadastrar e continuar o briefing</Subtitle>}
    </CheckBox>
  }

  render () {
    const { match: { params: { event } } } = this.props
    return (
      <Container>
        <Header />
        <BreadCrumb options={
          [
            { link: '/', name: 'Home' },
            { link: `/budget/resume/${event}`, name: 'Resumo' },
            { name: 'Transfer' }
          ]
        } />
        <Modal isOpen={this.state.verMais} onClose={() => this.setState({ verMais: !this.state.verMais })} hasCloseButton>
          <Label>{this.state.verMaisDetalhes}</Label>
        </Modal>
        <CommisedRate
          isOpen={this.state.CommisedOpen}
          onClose={() => this.setState({ CommisedOpen: false })}
          onSubmit={this.submitCommisedRate}
          client={this.state.event && this.state.event.client}
        />
        <Main>
          {this.state.isLoading ? <Loader /> : null}
          {!this.state.isLoading && <Group style={{ width: '80%', margin: 'auto' }}>
            <Group style={{ display: 'flex', position: 'relative' }}>
              <Title style={{ margin: 'auto 0', padding: '0 10px' }}>Transfer</Title>
              {this.props.location.state && (this.props.location.state.counterProposal === 'ACCEPTED' || this.props.location.state.counterProposal === 'NEW') && <Tag contraproposta>Contraproposta</Tag>}
            </Group>
            {this.state.briefing.requests.map(request => this.Solicitacao({ key: request.id, briefing: request }))}
            <Card style={{ marginLeft: '10px', width: '100%', padding: '10px' }} label={'Condições gerais para contratação'}>
              {this.state.termsAndConditions && this.state.termsAndConditions.map(termos => (
                <Textarea
                  key={termos.type}
                  type={'text'}
                  label={getNameFromKey(termos.type)}
                  placeholder={getNameFromKey('DESCRIPTION_' + termos.type)}
                  name={termos.type}
                  value={termos.description}
                  onChange={(input) => this.atualizaTermos(termos.type, input)}
                />
              ))}
            </Card>
          </Group>}
        </Main>
        <Button
          type='submit'
          category={'secondary'}
          fontWeight={800}
          onClick={this.submitForm}
          style={{ width: '80%' }}
          full>
          Confirmar
        </Button>
      </Container>
    )
  }
}

export default BriefingTransfer
