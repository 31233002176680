import React from 'react'

const IconHotelSelecionado = (props) => {
  return <svg style={props.style} onClick=
    {props.onClick} width={props.width || 50} height={props.height || 20} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 438.2 254.34">
    <defs />
    <title>img_hotel_selecionado</title>
    <path className="cls-1" style={{ fill: '#fff' }} d="M187.73,128.46a4.6,4.6,0,0,1,0-6.2l6.38-6.21a3.85,3.85,0,0,1,3-1.38,4.37,4.37,0,0,1,3.19,1.38l19.31,19.31L261,94a4.37,4.37,0,0,1,3.19-1.38,3.85,3.85,0,0,1,3,1.38l6.38,6.21a4.6,4.6,0,0,1,0,6.2l-50.87,50.69a4.17,4.17,0,0,1-6.2,0Z"/>
    <g className="cls-2" style={{ opacity: '0.7' }}>
      <circle className="cls-3" style={{ fill:'none', stroke:'#fff', strokeMiterlimit:'10' }} cx="150.11" cy="15.53" r="5.94"/>
      <circle className="cls-3" style={{ fill:'none', stroke:'#fff', strokeMiterlimit:'10' }} cx="364.33" cy="67.28" r="5.94"/>
      <circle className="cls-3" style={{ fill:'none', stroke:'#fff', strokeMiterlimit:'10' }} cx="392.33" cy="227.28" r="5.94"/>
      <circle className="cls-3" style={{ fill:'none', stroke:'#fff', strokeMiterlimit:'10' }} cx="175.58" cy="198.44" r="5.94"/>
      <circle className="cls-3" style={{ fill:'none', stroke:'#fff', strokeMiterlimit:'10' }} cx="11.58" cy="140.44" r="5.94"/>
      <circle className="cls-3" style={{ fill:'none', stroke:'#fff', strokeMiterlimit:'10' }} cx="11.58" cy="6.44" r="5.94"/>
      <line className="cls-3" style={{ fill:'none', stroke:'#fff', strokeMiterlimit:'10' }} x1="334.89" y1="14" x2="319.89" y2="14"/>
      <line className="cls-3" style={{ fill:'none', stroke:'#fff', strokeMiterlimit:'10' }} x1="327.39" y1="6.5" x2="327.39" y2="21.5"/>
      <line className="cls-3" style={{ fill:'none', stroke:'#fff', strokeMiterlimit:'10' }} x1="438.2" y1="23.5" x2="423.2" y2="23.5"/>
      <line className="cls-3" style={{ fill:'none', stroke:'#fff', strokeMiterlimit:'10' }} x1="430.7" y1="16" x2="430.7" y2="31"/>
      <line className="cls-3" style={{ fill:'none', stroke:'#fff', strokeMiterlimit:'10' }} x1="87.14" y1="45.53" x2="72.14" y2="45.53"/>
      <line className="cls-3" style={{ fill:'none', stroke:'#fff', strokeMiterlimit:'10' }} x1="79.64" y1="38.03" x2="79.64" y2="53.03"/>
      <line className="cls-3" style={{ fill:'none', stroke:'#fff', strokeMiterlimit:'10' }} x1="313.7" y1="204.25" x2="298.7" y2="204.25"/>
      <line className="cls-3" style={{ fill:'none', stroke:'#fff', strokeMiterlimit:'10' }} x1="306.2" y1="196.75" x2="306.2" y2="211.75"/>
      <line className="cls-3" style={{ fill:'none', stroke:'#fff', strokeMiterlimit:'10' }} x1="96.15" y1="185.06" x2="81.15" y2="185.06"/>
      <line className="cls-3" style={{ fill:'none', stroke:'#fff', strokeMiterlimit:'10' }} x1="88.65" y1="177.56" x2="88.65" y2="192.56"/>
      <line className="cls-3" style={{ fill:'none', stroke:'#fff', strokeMiterlimit:'10' }} x1="438.2" y1="178.5" x2="423.2" y2="178.5"/>
      <line className="cls-3" style={{ fill:'none', stroke:'#fff', strokeMiterlimit:'10' }} x1="430.7" y1="171" x2="430.7" y2="186"/>
      <path className="cls-3" style={{ fill:'none', stroke:'#fff', strokeMiterlimit:'10' }} d="M273.38,29.86c-7.19-1.74-4.83-11.46-12-13.21s-9.54,8-16.72,6.24-4.83-11.46-12-13.21-9.55,8-16.74,6.23"/>
      <path className="cls-3" style={{ fill:'none', stroke:'#fff', strokeMiterlimit:'10' }} d="M273.38,253.86c-7.19-1.74-4.83-11.46-12-13.21s-9.54,8-16.72,6.24-4.83-11.46-12-13.21-9.55,8-16.74,6.23"/>
      <path className="cls-3" style={{ fill:'none', stroke:'#fff', strokeMiterlimit:'10' }} d="M110.73,120c-7.39,0-7.39-10-14.79-10s-7.39,10-14.78,10-7.4-10-14.79-10-7.4,10-14.8,10"/>
      <path className="cls-3" style={{ fill:'none', stroke:'#fff', strokeMiterlimit:'10' }} d="M386.48,156.32c-6.4-3.7-1.4-12.36-7.8-16.06s-11.4,5-17.81,1.27-1.4-12.36-7.81-16.06-11.4,5-17.81,1.27"/>
      <path className="cls-3" style={{ fill:'none', stroke:'#fff', strokeMiterlimit:'10' }} d="M51.48,243.32c-6.4-3.7-1.4-12.36-7.8-16.06s-11.4,5-17.81,1.27-1.4-12.36-7.81-16.06-11.4,5-17.81,1.27"/>
    </g>
  </svg>
}

export default IconHotelSelecionado
