import ResumoTotal from './ResumoTotal'

class ResumoTotalEscolhido extends ResumoTotal {
  pagar () { return () => alert('Esta funcionalidade não está disponível nesta versão da plataforma.') }

  menuLateralDireito = () => {return this.menuLateralDireitoEscolhido()}
  menuLateralDireitoEscolhido () {
    const { history } = this.props
    return this.menuLateralDireitoResumo({
      paginaAtual: 'v3',
      textoPrimario: 'Pagar',
      textoSecundario: 'Voltar',
      urlBtnPrimario: this.pagar(),
      urlBtnSecundario: () => history.push(`/`)
    })
  }
}

export default ResumoTotalEscolhido
