import config from './config'

const getToken = () => {
  return window.localStorage.getItem('user_token')
}

export const fetchEvent = (id) => {
  const { api: { base, version } } = config
  const userType = window.localStorage.getItem('user_type')
  const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
  const endpoint = `${base}${version}${eventEndpoint}/${id}`
  const configFetch = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getToken()
    }
  }

  return window.fetch(endpoint, configFetch)
    .then(response => {
      if (response.status >= 401 && response.status <= 403) window.localStorage.clear()
      if (response.ok) {
        return response.json()
      } else {
        throw new Error(response.statusText)
      }
    }).then((response) => {
      if (!response.success) {
        this.setState({ errorMessage: response.message })
        return Promise.reject(response)
      } else {
        return response.data
      }
    }).catch(err => {
      console.log('Error: ', err)
      return err
    })
}
