import React, { Component } from 'react'
import styled from 'styled-components'
import AlertIcon from '../ui/AlertIcon'
import Modal from '../Modal'
import Title from '../ui/Title'
import Subtitle from '../ui/Subtitle'
import Button from '../ui/Button'

const Group = styled.div`
  display: flex;
  padding: 15px;
`

class ModalConfirmacao extends Component {

  render () {
    return (

      <Modal isOpen={this.props.isOpen} onClick={(event) => {
        event.preventDefault && event.preventDefault();
        event.stopPropagation && event.stopPropagation();
      }}>
        <AlertIcon width={'150px'} />
        <Title>Atenção!</Title>
        <br />
        <Subtitle>Deseja habilitar a edição do evento ao fornecedor?</Subtitle>
        <Group>
          <Button
            onClick={(event) => {
              event.stopPropagation && event.stopPropagation();
              this.props.onCancel && this.props.onCancel()
             }
            }
            category={'secondary'}
            fontWeight={800}
            width={'50%'}
            ghost
            full>
            Não
          </Button>
          <Button
            onClick={(event) => {
              event.stopPropagation && event.stopPropagation();
              this.props.onSubmit && this.props.onSubmit(event)
              }
            }
            category={'secondary'}
            fontWeight={800}
            width={'50%'}
            full>
            Sim
          </Button>
        </Group>
      </Modal>
    )
  }
}


export default ModalConfirmacao
