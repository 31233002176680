import React, { Component } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import Icons from '../ui/Icons'
import { defaultTheme } from '../../styles/themes'

const RESELLER = 'RESELLER'
const ORGANIZER = 'ORGANIZER'
const SUPPLIER = 'SUPPLIER'
const SYSTEM = 'SYSTEM'
const LEFT = 'LEFT'
const RIGHT = 'RIGHT'

const tipoMensagem = (msgType) => {
  const userType = window.localStorage.getItem('user_type')
  const group = [RESELLER, ORGANIZER]

  if (msgType === SYSTEM) return SYSTEM
  if (group.indexOf(userType) !== -1) {
    return (group.indexOf(msgType) !== -1) ? RIGHT : LEFT
  } else if (userType === SUPPLIER) {
    return (msgType === SUPPLIER) ? RIGHT : LEFT
  }
}

const Container = styled.ul`
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-top: 8px;
  max-height: 500px;
  padding: 15px;
  overflow-y: scroll;
  width: 100%;
`

const Balao = styled.li`
  align-self: ${props => tipoMensagem(props.messageType) === 'SYSTEM' ? 'center' : (tipoMensagem(props.userType) === 'RIGHT' ? 'flex-end' : 'start')};
  cursor: default;
  text-align: ${props => tipoMensagem(props.userType) === 'RIGHT' ? 'right' : 'left'};
  & + li {
    margin-top: 10px;
  }
`

const Mensagem = styled.div`
  background: ${props => (tipoMensagem(props.userType) === 'LEFT' || tipoMensagem(props.messageType) === 'SYSTEM') ? '#e8e8e8' : '#484848'};
  border-radius: ${props => tipoMensagem(props.messageType) === 'SYSTEM' ? '15px' : (tipoMensagem(props.userType) === 'LEFT' ? '15px 15px 15px 0' : '15px 15px 0px 15px')};
  color: ${props => (tipoMensagem(props.userType) === 'LEFT' || tipoMensagem(props.messageType) === 'SYSTEM') ? '#484848' : '#fff'};
  font-family: ${defaultTheme.fonts.primary};
  font-size: 14px;
  min-width: 100px;
  max-width: 400px;
  padding: ${props => tipoMensagem(props.messageType) === 'SYSTEM' ? '5px 10px' : '10px'};
  word-break: break-word;
`

const Horario = styled.span`
  display: inline-block;
  font-size: 9px;
  padding-left: 3px;
  text-align: right;
  white-space: nowrap;
`

const Label = styled.span`
  box-sizing: border-box;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-size: ${props => tipoMensagem(props.messageType) === 'SYSTEM' ? '12px' : '14px'};
  font-weight: 300;
  min-height: 14px;
  padding: ${props => tipoMensagem(props.messageType) === 'SYSTEM' ? '0' : '0 0 5px 0'};
  text-align: left;
  width: 100%;
`

const Nome = styled.p`
  font-size: 0.855rem;
  font-weight: 600;
  text-align: start;
  padding: 0 0 5px 0;
`

const GroupMensagem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`
class Historico extends Component {
  componentDidMount () {
    this.scrollTextArea()
  }

  scrollTextArea = () => {
    const textArea = document.getElementById('historico_container')
    if (textArea) textArea.scrollTop = textArea.scrollHeight
  }

  formataTamanho = tamanho => {
    tamanho = Number(tamanho)
    var unidade = 'bytes'
    if (tamanho >= 1000) {
      tamanho = tamanho / 1000
      unidade = 'KB'
    }
    if (tamanho >= (1000 * 1000)) {
      tamanho = tamanho / 1000
      unidade = 'MB'
    }

    return `${tamanho.toFixed(1)} ${unidade}`
  }

  mensagemSistema = mensagem => {
    return <Mensagem title={this.formataDataHora(mensagem, true)} userType={mensagem.userType} messageType={mensagem.messageType}>
      <GroupMensagem>
        <div>
          <Label messageType={mensagem.messageType}>{mensagem.message}</Label>
        </div>
      </GroupMensagem>
    </Mensagem>
  }

  mensagemPadrao = mensagem => {
    return <Mensagem userType={mensagem.userType} messageType={mensagem.messageType}>
      <GroupMensagem>
        <div>
          <Nome>{mensagem.userName}</Nome>
          <Label messageType={mensagem.messageType}>{mensagem.message}</Label>
        </div>
        <Horario title={this.formataDataHora(mensagem, true)}>{this.formataDataHora(mensagem)}</Horario>
      </GroupMensagem>
    </Mensagem>
  }

  mensagemAnexo = mensagem => {
    const EXIBE_TAMANHO = mensagem.attachment.size && !Number.isNaN(Number(mensagem.attachment.size))
    const nomeArquivo = (mensagem && mensagem.attachment && mensagem.attachment.name) || 'arquivo'
    const nomeReduzido = nomeArquivo.length > 35 ? `${nomeArquivo.substr(0, 32)}...` : nomeArquivo
    const nomeCompleto = nomeArquivo.length > 35 ? nomeArquivo : undefined
    const color = tipoMensagem(mensagem.userType) === RIGHT ? 'white' : '#484848'

    return <Mensagem userType={mensagem.userType} messageType={mensagem.messageType}>
      <Nome style={{ width: '100%' }}>{mensagem.userName}</Nome>
      <GroupMensagem>
        <Icons style={{ margin: 'auto 4px', width: '24px' }} icon='file-pdf-o' color={color} title={nomeCompleto || nomeArquivo} />
        <div>
          <Label messageType={mensagem.messageType} style={{ fontSize: '13px', paddingBottom: 0 }} title={nomeCompleto}>{nomeReduzido}</Label>
          <Label messageType={mensagem.messageType} style={{ fontSize: '13px', paddingBottom: 0 }}>
            {EXIBE_TAMANHO && <span>{this.formataTamanho(mensagem.attachment.size)}</span>}
            &nbsp;&nbsp;<a href={mensagem.attachment.url} style={{ color, textDecoration: 'underline' }}>ABRIR</a>
          </Label>
        </div>
        <Horario title={this.formataDataHora(mensagem, true)}>{this.formataDataHora(mensagem)}</Horario>
      </GroupMensagem>
    </Mensagem>
  }

  formataDataHora = (mensagem, completa) => {
    const dataHora = mensagem && mensagem.created && moment(mensagem.created, 'YYYY-MM-DD[T]HH:mm:ssZ')
    const dataValida = dataHora && dataHora.format
    if (dataValida) {
      if (completa) return dataHora.format('DD/MM/YY HH:mm:ss Z')
      if (dataHora.isSame(moment(), 'day')) return dataHora.format('HH:mm')
      if (dataHora.isSame(moment(), 'year')) return dataHora.format('DD/MM HH:mm')
      return dataHora.format('DD/MM/YY HH:mm')
    } else return ''
  }

  renderizaMensagem = (mensagem) => {
    if (mensagem.messageType === 'SYSTEM') return this.mensagemSistema(mensagem)
    if (mensagem.attachment) return this.mensagemAnexo(mensagem)
    return this.mensagemPadrao(mensagem)
  }

  render () {
    return <Container id='historico_container' style={this.props.style}>
      {this.props.mensagens && this.props.mensagens.map(item =>
        <Balao key={item.id} userType={item.userType} messageType={item.messageType}>
          {this.renderizaMensagem(item)}
        </Balao>
      )}
    </Container>
  }
}

export default Historico
