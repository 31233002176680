import React, { Component } from 'react'
import Textarea from '../ui/Textarea'
import Icons from '../ui/Icons'
import Upload from './Upload'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  width: 100%;
`

const textAreaStyle = {
  border: 'none',
  height: '30px',
  marginLeft: '-10px',
  marginTop: '-10px',
  resize: 'none',
  outline: 'none',
  overflowY: 'auto',
  overflow: 'hidden',
  width: '100%',
  wordWrap: 'break-word'
}

class CampoMensagem extends Component {
  constructor (props) {
    super(props)
    this.state = {
      mensagem: ''
    }

    this.handleClick = this.handleClick.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.clearMessage = this.clearMessage.bind(this)
    this.isEnabled = this.isEnabled.bind(this)
    this.getMessage = this.getMessage.bind(this)
    this.getBackgroundColor = this.getBackgroundColor.bind(this)
    this.getColorIcon = this.getColorIcon.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  handleClick () {
    if (this.isEnabled()) {
      this.props.onClick(this.state.mensagem)
      this.clearMessage()
    }
  }

  handleKeyPress (event) {
    if (this.isEnabled() && event.key === 'Enter') {
      event.preventDefault()
      this.props.onKeyPressEnter(this.state.mensagem)
      this.clearMessage()
    }
  }

  handleFocus(event) {
    this.props.onFocus && this.props.onFocus(event)
  }

  handleBlur(event) {
    this.props.onBlur && this.props.onBlur(event)
  }

  isEnabled () {
    return this.props.enabled
  }

  getMessage () {
    return this.isEnabled() ? this.state.mensagem : ''
  }

  clearMessage () {
    this.setState({ mensagem: '' })
  }

  getBackgroundColor () {
    return this.props.enabled ? '' : '#e8e8e8'
  }

  getColorIcon () {
    return this.props.enabled ? '' : '#e8e8e8'
  }

  render () {
    const style = {
      cursor: 'pointer',
      display: 'list-item',
      fill: this.getColorIcon(),
      height: '24px',
      width: '24px'
    }

    return (
      <Container>
        <Textarea
          id='textAreaChat'
          style={{ ...textAreaStyle, backgroundColor: this.getBackgroundColor() }}
          value={this.getMessage()}
          onChange={input => { this.setState({ mensagem: input.target.value }) }}
          onKeyPress={this.handleKeyPress}
          onFocus={input => this.handleFocus(input)}
          onBlur={input => this.handleBlur(input)}
          placeholder={this.props.placeholder}
        />
        <div style={{ margin: 'auto' }}>
          {this.state.mensagem
            ? <Icons onClick={this.handleClick} style={style} icon='icon_chat_02' color='#6395C8' title='Enviar mensagem' />
            : <Upload enabled={this.isEnabled()} uploadPath={this.props.uploadPath} style={style} onFileUpload={this.props.handleAttachment} text='Enviar arquivo' />}
        </div>
      </Container>
    )
  }
}

export default CampoMensagem
