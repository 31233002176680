/* LIBS */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
/* STYLES */
import { defaultTheme } from '../../styles/themes'
/* UI */
import Loader from '../../components/Loader'
import BreadCrumb from '../../components/BreadCrumb'
/* COMPONENTS */
import Header from '../../components/Header'
import EditRoomForm from './EditRoomForm'

const Main = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #FFF;
  @media (min-width: 768px) {
    background-color: #FAFAFA;
  }
`

const Content = styled.div`
  min-height: 90vh;
  padding: 30px 0px;
  font-family: ${defaultTheme.fonts.secondary};
  width: 90%;
  margin: 0 5%;
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  align-content: start;
  @media (min-width: 768px) {
    width: 60%;
  }
`

const Title = styled.h1`
  width: 100%;
  text-align: left;
  font-size: 20px;
  padding: 20px 10px;
`

class FormRooms extends Component {
  state = {
    isLoading: false
  }

  submit(obj) {
    const token = window.localStorage.getItem('user_token')
    const { id } = this.props.match.params
    this.setState({isLoading: true})
    const url = id ? `/api/v1/rooms/${id}` : '/api/v1/rooms'
    const method =  id ? 'PUT' : 'POST'
    fetch(url, {
      method: method,
      headers: {
        'Authorization': token,
        'Content-type': 'application/json'
      },
      body: JSON.stringify(obj)
    })
    .then((response) => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error(response)
      }
    })
    .then(response => {
      this.setState({isLoading: false})
      if (response.success) {
        window.location.href = '/rooms'
      } else {
        console.error(response)
        alert('Atenção o seguinte erro foi encontrado no formulário: ' + response.message)
      }
    })
    .catch(e => {
      this.setState({isLoading: false})
      alert(e.message)
      console.error(e)
    })
  }

  render() {
    const { isLoading } = this.state
    const { id } = this.props.match.params
    return (
      <Main>
        {isLoading ? <Loader /> : null}
        <Header />
        <BreadCrumb options={
          [
            {link: '/', name: 'Home'},
            {link: '/rooms', name: 'Salas'},
            {name: 'Cadastro de nova sala'}
          ]
        } />
        <Content>
        <Title>
          {id ? 'Edição de sala' : 'Cadastro de nova sala'}
        </Title>
        <EditRoomForm room={id} onSubmit={this.submit.bind(this)}/>
        </Content>
      </Main>
    )
  }
}

function mapStateToProps (state) {
  const { app } = state
  const { language } = app
  return {
    language
  }
}

export default connect(mapStateToProps)(FormRooms)
