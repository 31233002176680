/* LIBS */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
/* ACTIONS */
import { fetchRooms } from '../../../redux/actions/rooms'
/* STYLES */
import { defaultTheme } from '../../styles/themes'
/* UI */
import Button from '../../components/ui/Button'
import BreadCrumb from '../../components/BreadCrumb'
/* COMPONENTS */
import Header from '../../components/Header'
import Loader from '../../components/Loader'


const Main = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  background-color: #FFF;
  @media (min-width: 768px) {
    background-color: #FAFAFA;
  }
`

const Content = styled.div`
  min-height: 90vh;
  padding: 30px 0px;
  font-family: ${defaultTheme.fonts.secondary};
  width: 90%;
  margin: 0 5%;
`

const Title = styled.h1`
  width: 100%;
  text-align: left;
  font-size: 20px;
  padding: 20px 10px;
`

const NoRooms = styled.p`
  padding: 0px 10px;
  color: #7b7b7b;
`

const RoomsList = styled.div`
  padding: 0px 10px;
  color: #7b7b7b;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
`

const RoomItem = styled.div`
  background-color: #fff;
  margin: 5px 10px;
  min-width: calc((100% / 3) - 20px);
  cursor: pointer;
  border: none;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  @media (max-width: 768px) {
    width: 100%;
  }
`

const RoomTitle = styled.span`
  color: #333;
  font-size: 16px;
  width: 100%;
  display: block;
`
const RoomPhoto = styled.div`
  height: 180px;
  width: 100%;
  background-position: center;
  background-size: cover;
`
const RoomDescription = styled.div`
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const Description = styled.div`
  padding: 10px 0px;
  font-family: ${defaultTheme.fonts.primary};
`

class Rooms extends Component {
  state = {
    rooms: [],
    isLoading: false
  }

  componentDidMount() {
    this.loadAllRooms()
  }

  loadAllRooms = () => {
    const token = window.localStorage.getItem('user_token')
    const id = jwtDecode(token).accountId
    this.props.fetchRooms({account: { id }})
    .catch(err => {
      alert(`Error (${err.message})`)
      console.error(err.message)
    })
  }

  render() {
    const { isFetching, rooms } = this.props
    return (
      <Main>
        {isFetching ? <Loader /> : null}
        <Header />
        <BreadCrumb options={
          [
            {link: '/', name: 'Home'},
            {name: 'Salas'}
          ]
        } />
        <Content>
          <Button
            onClick={() => this.props.history.push('/rooms/add')}
            type='button'
            category={'secondary'}
            fontWeight={800}
            full
            ghost>
            + Cadastrar nova sala
        </Button>
          <Title>
            Salas
        </Title>
          {rooms.length ? <RoomsRender rooms={rooms}/> : <EmptyRooms />}
        </Content>
      </Main>
    )
  }
}

const EmptyRooms = () => {
  return <NoRooms>Nenhuma sala cadastrada até o momento</NoRooms>
}

const RoomsRender = ({rooms}) => {
  return <RoomsList>
            {rooms.map(room =>
                  <RoomItem key={room._id}>
                    <Link to={`/rooms/edit/${room._id}`}>
                      <RoomPhoto style={{backgroundImage: `url(${room.photos[0]})`}}/>
                    </Link>
                    <RoomDescription>
                      <RoomTitle>{room.name}</RoomTitle>
                      <Description>Área: <br/>{`${room.footage}m²`}</Description>
                      <Description>Pé direito:<br/> {`${room.rooms_height}m`}</Description>
                      <Description>Cap. máx: <br/>{`${biggerCapacity(room.capacity)} pessoas`}</Description>
                    </RoomDescription>
                  </RoomItem>
              )}
        </RoomsList>
}

const biggerCapacity = (capacity) => {
  return Object.keys(capacity)
    .reduce((prev, cur) => {
      let value = prev
      if (Number(capacity[cur]) > Number(value)) {
        value = capacity[cur]
      }
      return value
  }, 0)
}

function mapStateToProps(state) {
  const { app, rooms: { rooms, isFetching } } = state
  const { language } = app
  return {
    language,
    rooms,
    isFetching
  }
}

export default connect(mapStateToProps, { fetchRooms })(Rooms)
