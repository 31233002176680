const API = '/api/v1'

/* CRIAÇÃO DE BUDGET COM SALAS */
export const ROOMS_BUDGET_REQUEST = 'ROOMS_BUDGET_REQUEST'
export const ROOMS_BUDGET_SUCCESS = 'ROOMS_BUDGET_SUCCESS'
export const ROOMS_BUDGET_FAILURE = 'ROOMS_BUDGET_FAILURE'


function requestBudget(data) {
  return {
    type: ROOMS_BUDGET_REQUEST,
    isFetching: true,
    data
  }
}

function receiveBudgetCreated(data) {
  return {
    type: ROOMS_BUDGET_SUCCESS,
    isFetching: false,
    data
  }
}

export function createBudgetError(message) {
  return {
    type: ROOMS_BUDGET_FAILURE,
    isFetching: false,
    message
  }
}

export function createBudget(budget) {
  const token = window.localStorage.getItem('user_token')
  const configFetch = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    },
    body: JSON.stringify(budget)
  }

  return dispatch => {
    dispatch(requestBudget(budget))
    return window.fetch(`${API}/budget/room/`, configFetch)
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error(response)
        }
      })
      .then(response => {
        if (!response.success) {
          dispatch(createBudgetError(response.message))
          return Promise.reject(response)
        } else {
          dispatch(receiveBudgetCreated(response.data))
          return Promise.resolve(response)
        }
      })
      .catch(err => {
        dispatch(createBudgetError(err))
        console.log('Error: ', err)
        return Promise.reject(err)
      })
  }
}

/* CRIAÇÃO DE BUDGET COM SALAS */
export const ADD_ROOM_TO_BUDGET = 'ADD_ROOM_TO_BUDGET'

function addRoom(data) {
  return {
    type: ADD_ROOM_TO_BUDGET,
    data
  }
}

export function addRoomToBudget(rooms) {
  return dispatch => {
    dispatch(addRoom(rooms))
  }
}
