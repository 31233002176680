import React, { Component } from 'react'
import styled from 'styled-components'
import Icons from './ui/Icons'
import { defaultTheme } from '../styles/themes'
import { Link } from 'react-router-dom'
import { forneceServico, PARKING } from '../../utils/servicosFornecedor'
import './dropdown.css'

const ArrowOpen = styled.span`
  margin-left: 2px;
  font-size: 16px;
`

const GroupIcon = styled.div`
  padding: 10px;
  display: flex;
  align-items: flex-end;
  font-size: 15px;
  font-family: ${defaultTheme.fonts.primary};
  width: 200px;
`

const SpanLinkMenu = styled.span`
  @media(max-width: 650px) {
    display: none;
  }
`

const LogoWrapHover = styled.div`
  cursor: pointer;

  &:hover {
    background-color: #631b1d;
  }
`

const MenuItem = styled.li`
  padding: 12px 0;

  &:hover {
    background-color: #efefef;
    color: #c5686b;
  }
`

export default class DropdownServicos extends Component {
  constructor () {
    super()
    this.state = {
      showMenu: false
    }
  }

  handleMenu () {
    this.setState({ showMenu: !this.state.showMenu })
  }

  render () {
    const { showMenu } = this.state
    return (
      <>
        <div className='menu-servicos' onClick={this.handleMenu.bind(this)}>
          <div className='servicos__menu'>
            <LogoWrapHover>
              <span style={{ textDecoration: 'none' }}>
                <GroupIcon style={{ paddingTop: '18px', paddingBottom: '18px', color: 'white' }}>
                  <Icons
                    style={{ paddingTop: '5px' }}
                    icon='list'
                    color='white'
                    viewBox='0 0 1536 1024'
                    width={30}
                    height={20}
                  />
                  <SpanLinkMenu>Meus Serviços&nbsp;<ArrowOpen>▾</ArrowOpen></SpanLinkMenu>
                </GroupIcon>
              </span>
            </LogoWrapHover>
          </div>
        </div>
        <nav id='slide-menu' className={showMenu ? 'menu-active' : ''}>
          <ul>
            <MenuItem>
              <Link to='/rooms' style={{ textDecoration: 'none', color: 'inherit' }}>
                <GroupIcon>
                  <Icons
                    icon='room-outline'
                    color='gray'
                    viewBox='0 0 1536 1024'
                    width={30}
                    height={15}
                  />
                  <span>Salas</span>
                </GroupIcon>
              </Link>
            </MenuItem>
            <MenuItem>
              <Link to='/foods-and-beverages' style={{ textDecoration: 'none', color: 'inherit' }}>
                <GroupIcon>
                  <Icons
                    icon='food-beverage-outline'
                    color='gray'
                    width={30}
                    height={15}
                  />
                  <span>Alimentos e bebidas</span>
                </GroupIcon>
              </Link>
            </MenuItem>
            <MenuItem>
              <Link to='/equipments/add' style={{ textDecoration: 'none', color: 'inherit' }}>
                <GroupIcon>
                  <Icons
                    icon='computer-outline'
                    color='gray'
                    viewBox='0 0 1280 1024'
                    width={30}
                    height={15}
                  />
                  <span>Equipamentos</span>
                </GroupIcon>
              </Link>
            </MenuItem>
            {forneceServico(PARKING) &&
              <MenuItem>
                <Link to='/parking/add' style={{ textDecoration: 'none', color: 'inherit' }}>
                  <GroupIcon>
                    <Icons
                      icon='parking-outline'
                      color='gray'
                      viewBox='0 80 1152 860'
                      width={30}
                      height={15}
                    />
                    <span>Estacionamento</span>
                  </GroupIcon>
                </Link>
              </MenuItem>}
          </ul>
        </nav>
      </>
    )
  }
}
