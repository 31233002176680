/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../../../../styles/themes'
import moment from 'moment'
/* COMPONENTS */
import InputDatepicker from '../../../../components/InputDatepicker'
import RightBoxInput from '../../../../components/ui/RightBoxInput'
import Select from '../../../../components/ui/Select'
import CheckboxButton from '../../../../components/ui/CheckboxButton'
import Timepicker from '../../../../components/Timepicker'

const Content = styled.div`
  padding: 10px;
  width: 100%;
`

const Title = styled.span`
  font-size: 18px;
  font-weight: 500;
  font-family: ${defaultTheme.fonts.secondary};
  padding: 5px 0px;
  color: #484848;
`
const Row = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
`
const Label = styled.span`
  color: #484848;
  box-sizing: border-box;
  padding: 10px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  min-height: 25px;
  width: 100%;
  text-align: left;
`

const Equips = styled.div`
  width: 100%;
`

const InputHidden = styled.input`
  display: none;
`

const Group = styled.div`
`

class Room extends Component {
  state = {
    startDate: '',
    endDate: ''
  }

  componentDidMount() {
    const { startDate, endDate } = this.props.room
    this.setState({startDate: startDate, endDate: endDate})
  }

  onChangeDate = (e) => {
    const { from } = e.target.value
    let { to } = e.target.value

    if (!to) {
      to = moment(from)
    }

    this.setState({ startDate: from && from.toISOString(), endDate: to && to.toISOString() })
  }

  handleTimeValue = (input, session, room) => {
    room[session] =  input.target.value
  }

  render() {
    const { index, room, roomId, listaDeRedirecionamento } = this.props
    const { startDate, endDate } = this.state
    return (
      <Content>
        <Title>Sala {(index + 1) < 10 ? `0${(index + 1)}` : (index + 1) }</Title>
        <Row style={{ marginTop: '20px' }}>
          {roomId ? <InputHidden
            name={`[rooms][${index}][id]`}
            value={roomId}
          /> : null}
          <Group style={{ width: '50%' }}>
            <InputDatepicker
              label={'Escolha as datas em que precisará alugar salas'}
              type={'range'}
              name={`[rooms][${index}][dates]`}
              isRequired
              edited={listaDeRedirecionamento.some(item => item === 'start' || item === 'end')}
              dates={{from: startDate, to: endDate}}
              error={room.errors.startDate || room.errors.endDate}
              onChange={this.onChangeDate} />
              <InputHidden
                name={`[rooms][${index}][startDate]`}
                value={startDate}
              />
              <InputHidden
                name={`[rooms][${index}][endDate]`}
                value={endDate}
              />
          </Group>
          <Group style={{ width: '25%', marginTop: 'auto' }}>
            <Timepicker
              placeholder={'Selecione um horário'}
              label={'Horário de início'}
              name={`[rooms][${index}][startTime]`}
              seconds
              value={room.startTime}
              style={{ width: '25%' }}
              error={room.errors.startTime}
              onChange={(input) => this.handleTimeValue(input, 'startTime', room)} />
          </Group>
          <Group style={{ width: '25%', marginTop: 'auto' }}>
            <Timepicker
              placeholder={'Selecione um horário'}
              label={'Horário de término'}
              name={`[rooms][${index}][endTime]`}
              value={room.endTime}
              style={{ width: '25%' }}
              error={room.errors.endTime}
              onChange={(input) => this.handleTimeValue(input, 'endTime', room)}
              seconds />
          </Group>
        </Row>
        <Row>
          <Select
            isRequired
            error={room.errors.roomType}
            defaultValue={room.roomType}
            name={`[rooms][${index}][roomType]`}
            label={'Formato da sala'}>
            <option value={''}>Selecione um formato da sala</option>
            <option value={'Auditório'}>Auditório</option>
            <option value={'Escolar'}>Escolar</option>
            <option value={'Espinha de Peixe'}>Espinha de Peixe</option>
            <option value={'Formato U'}>Formato U</option>
            <option value={'Mesa Redonda'}>Mesa Redonda</option>
            <option value={'Coquetel'}>Coquetel</option>
          </Select>
          <Select
            isRequired
            error={room.errors.roomFormat}
            name={`[rooms][${index}][roomFormat]`}
            defaultValue={room.roomFormat}
            label={'Tipo de sala'}>
            <option value={''}>Selecione um tipo de sala</option>
            <option value={'Plenária Evento'}>Plenária Evento</option>
            <option value={'Sala de Reunião'}>Sala de Reunião</option>
            <option value={'Sala de Apoio'}>Sala de Apoio</option>
            <option value={'Sala de Imprensa'}>Sala de Imprensa</option>
            <option value={'Espaço Privativo Almoço'}>Espaço Privativo Almoço</option>
            <option value={'Espaço Privativo Jantar'}>Espaço Privativo Jantar</option>
            <option value={'Outros'}>Outros</option>
          </Select>
        </Row>
        <Row>
          <Group style={{ width: '50%' }}>
            <Select
              isRequired
              error={room.errors.mounting}
              defaultValue={room.mounting}
              name={`[rooms][${index}][mounting]`}
              label={'Necessita montagem 1 dia antes?'}>
              <option value={''}>Selecione uma opção</option>
              <option value={true}>Sim</option>
              <option value={false}>Não</option>
            </Select>
          </Group>
          <Group style={{ width: '50%' }}>
            <RightBoxInput
              placeholder={'0'}
              rightBox='pessoas'
              isRequired
              type='number'
              name={`[rooms][${index}][amountOfPeople]`}
              defaultValue={room.amountOfPeople}
              error={room.errors.amountOfPeople}
              label={'Para quantas pessoas?'}
              edited={listaDeRedirecionamento.some(item => item === 'numberOfParticipants')}
              style={{ width: '100%', paddingLeft: '10px' }}/>
          </Group>
        </Row>
        <Equips>
          <Label>Selecione os itens que deseja nesta sala:</Label>
          <Row>
            <CheckboxButton
              defaultChecked={room.roomItems.includes('Palco')}
              placeholder={'Palco'}
              name={`[rooms][${index}][roomItems][0]`}
              value={'Palco'} />
            <CheckboxButton
              defaultChecked={room.roomItems.includes('Mesa de apoio')}
              placeholder={'Mesa de apoio'}
              name={`[rooms][${index}][roomItems][1]`}
              value={'Mesa de apoio'} />
            <CheckboxButton
              defaultChecked={room.roomItems.includes('Mesa diretora')}
              placeholder={'Mesa diretora'}
              name={`[rooms][${index}][roomItems][2]`}
              value={'Mesa diretora'} />
          </Row>
          <Row>
            <CheckboxButton
              defaultChecked={room.roomItems.includes('Púlpito')}
              placeholder={'Púlpito'}
              name={`[rooms][${index}][roomItems][3]`}
              value={'Púlpito'} />
            <CheckboxButton
              defaultChecked={room.roomItems.includes('Bloco de anotações')}
              placeholder={'Bloco de anotações'}
              name={`[rooms][${index}][roomItems][4]`}
              value={'Bloco de anotações'} />
            <CheckboxButton
              defaultChecked={room.roomItems.includes('Caneta')}
              placeholder={'Caneta'}
              name={`[rooms][${index}][roomItems][5]`}
              value={'Caneta'} />
          </Row>
        </Equips>
      </Content>
    )
  }
}

export default Room
