/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
import jwtDecode from 'jwt-decode'
/* STYLES */
import { defaultTheme } from '../../styles/themes'
/* UI */
import Title from '../../components/ui/Title'
import Card from '../../components/ui/Card'
import Button from '../../components/ui/Button'
import Input from '../../components/ui/Input'
import Select from '../../components/ui/Select'
import MoneyInput from '../../components/ui/MoneyInput'
import PercentageInput from '../../components/ui/PercentageInput'
import BreadCrumb from '../../components/BreadCrumb'
/* COMPONENTS */
import config from '../../../middleware/config'
import Header from '../../components/Header'
import Loader from '../../components/Loader'
import { Put, Post, Get } from '../../../utils/request'

const Main = styled.main`
  background-color: #FFF;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: 768px) {
    background-color: #FAFAFA;
  }
`

const Content = styled.div`
  align-content: start;
  align-items: start;
  display: flex;
  flex-wrap: wrap;
  font-family: ${defaultTheme.fonts.secondary};
  margin: 0 5%;
  min-height: 90vh;
  padding: 30px 0px;
  width: 70%;
`

const Group = styled.div`
  width: 100%;
`

export default class FormParking extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isLoading: true,
      id: '',
      maxCapacity: '',
      type: '',
      price: 0,
      feesIncluded: true,
      fees: '0',
      editou: {
        maxCapacity: false,
        type: false,
        price: false,
        feesIncluded: false,
        fees: false
      }
    }

    this.montarDadosNaPagina = this.montarDadosNaPagina.bind(this)
    this.carregarDados = this.carregarDados.bind(this)
    this.campoInvalido = this.campoInvalido.bind(this)
    this.handleValue = this.handleValue.bind(this)
    this.formValido = this.formValido.bind(this)
    this.montarDadosProBack = this.montarDadosProBack.bind(this)
    this.submeterDados = this.submeterDados.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount () {
    this.carregarDados()
  }

  montarDadosNaPagina (dados) {
    const state = { ...this.state }
    state.isLoading = false
    if (dados && dados[0]) {
      state.id = dados[0].id
      state.maxCapacity = dados[0].maxCapacity
      state.type = dados[0].type
      state.price = String(dados[0].price.amount)
      state.feesIncluded = dados[0].feesIncluded
      if (!state.feesIncluded) state.fees = String(dados[0].fees.amount)
    }
    this.setState(state)
  }

  carregarDados () {
    this.setState({ isLoading: true }, () => {
      const token = window.localStorage.getItem('user_token')
      const id = jwtDecode(token).accountId
      const { api: { base, version, routes: { parking } } } = config
      const endpoint = `${base}${version}${parking}/account/${id}`

      Get(endpoint).then(resposta => {
        this.montarDadosNaPagina(resposta)
      }).catch(erro => {
        this.setState({ isLoading: false }, () => {
          window.alert('Ocorreu um erro ao buscar seus dados previamente cadastrados:', erro)
          console.error(erro)
        })
      })
    })
  }

  campoInvalido (campo) {
    const maxCapacity = Number(this.state.maxCapacity)
    const price = Number(this.state.price)
    const fees = Number(this.state.fees)

    return {
      maxCapacity:
        this.state.editou.maxCapacity &&
        ((!maxCapacity) || Number.isNaN(maxCapacity)),
      type:
        this.state.editou.type &&
        !this.state.type,
      price:
        this.state.editou.price &&
        ((!price) || Number.isNaN(price)),
      fees:
        (!this.state.feesIncluded) &&
        this.state.editou.fees &&
        ((!fees) || Number.isNaN(fees))
    }[campo]
  }

  handleValue (campo, valor) {
    const state = this.state
    state[campo] = valor
    state.editou[campo] = true
    this.setState(state)
  }

  formValido () {
    const invalido = (
      this.campoInvalido('maxCapacity') ||
      this.campoInvalido('type') ||
      this.campoInvalido('price') ||
      this.campoInvalido('fees')
    )
    if (invalido) {
      this.setState({
        isLoading: false,
        editou: {
          maxCapacity: true,
          type: true,
          price: true,
          feesIncluded: true,
          fees: true
        }
      }, () => { window.alert('Todos os campos são obrigatórios') })
    }
    return !invalido
  }

  montarDadosProBack () {
    return {
      fees: this.state.feesIncluded ? null : {
        amount: Number(this.state.fees),
        type: 'PERCENTAGE'
      },
      maxCapacity: Number(this.state.maxCapacity),
      type: this.state.type,
      price: {
        amount: Number(this.state.price),
        currency: 'BRL'
      },
      feesIncluded: this.state.feesIncluded
    }
  }

  submeterDados () {
    const { api: { base, version, routes: { parking } } } = config
    const endpoint = `${base}${version}${parking}`
    const dados = this.montarDadosProBack()
    return this.state.id
      ? Put(`${endpoint}/${this.state.id}`, dados)
      : Post(endpoint, dados)
  }

  handleSubmit () {
    this.setState({ isLoading: true }, () => {
      if (this.formValido()) {
        this.submeterDados().then(resposta => {
          var id = this.state.id
          const respostaOK = resposta && (resposta.success || resposta.id)
          if (respostaOK) id = resposta.inserted || resposta.id
          this.setState({ id, isLoading: false }, () => {
            if (respostaOK) window.alert('Cadastro atualizado com sucesso.')
            else window.alert('Ocorreu um erro ao salvar seus dados.')
          })
        }).catch(erro => {
          console.error(erro)
          this.setState({ isLoading: false }, () => {
            window.alert(`Ocorreu um erro ao salvar seus dados: ${erro}`)
          })
        })
      }
    })
  }

  render () {
    const EXIBE_TAXAS = !this.state.feesIncluded

    return (
      <Main>
        {this.state.isLoading ? <Loader /> : null}
        <Header />
        <BreadCrumb options={[{ link: '/', name: 'Home' }, { name: 'Cadastro de estacionamento' }]} />
        <Content>
          <Title secondaryFont>Cadastro de estacionamento</Title>
          <Card>
            <Group style={{ display: 'flex' }}>
              <Group style={{ width: '45%' }}>
                <Input
                  isRequired
                  name='maxCapacity'
                  label='Capacidade máxima de veículos'
                  type='number'
                  integer
                  error={this.campoInvalido('maxCapacity')}
                  value={this.state.maxCapacity}
                  placeholder={0}
                  onChange={evento => this.handleValue('maxCapacity', evento.target.value)}
                />
              </Group>
              <Group>
                <Select
                  isRequired
                  name='type'
                  label='Tipo de estacionamento'
                  error={this.campoInvalido('type')}
                  value={this.state.type}
                  onChange={evento => this.handleValue('type', evento.target.value)}
                  children={[
                    <option key={0} disabled value=''>Selecione</option>,
                    <option key={1} value='COVERED'>Coberto</option>,
                    <option key={2} value='OPEN'>Descoberto</option>,
                    <option key={3} value='BOTH'>Coberto e Descoberto</option>
                  ]}
                />
              </Group>
            </Group>
            <Group style={{ display: 'flex' }}>
              <Group style={{ width: '45%' }}>
                <MoneyInput
                  isRequired
                  name='price'
                  label='Valor (por veículo, por dia)'
                  error={this.campoInvalido('price')}
                  value={this.state.price}
                  onChange={evento => this.handleValue('price', evento.target.value)}
                />
              </Group>
              <Group style={{ display: 'flex' }}>
                <Select
                  isRequired
                  name='feesIncluded'
                  label='As taxas estão incluídas?'
                  value={this.state.feesIncluded}
                  onChange={evento => this.handleValue('feesIncluded', evento.target.value === 'true')}
                  children={[
                    <option key={0} value='true'>Sim</option>,
                    <option key={1} value='false'>Não</option>
                  ]}
                />
                {EXIBE_TAXAS &&
                  <PercentageInput
                    isRequired
                    name='fees'
                    label='Valor das taxas'
                    type='number'
                    error={this.campoInvalido('fees')}
                    value={this.state.fees}
                    placeholder={0}
                    onChange={evento => this.handleValue('fees', evento.target.value)}
                  />}
              </Group>
            </Group>
          </Card>
          <Button
            full
            type='submit'
            category='secondary'
            style={{ marginLeft: 0, marginRight: 0, width: '100%' }}
            fontWeight={800}
            onClick={this.handleSubmit}
            children='Cadastrar'
          />
        </Content>
      </Main>
    )
  }
}
