import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import CheckBox from '../../components/ui/CheckBox'
import Input from '../../components/ui/Input'
/* UTILS */
import { formatCurrency, removeCurrency } from '../../../utils/money'

const ContentForm = styled.div`
  background-color: #fff;
  padding: 10px;
  margin: 10px 10px;
  min-height: 120px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  border: none;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16),0 0 0 1px rgba(0,0,0,0.08);
  width: 100%;
`
const TitleMenu = styled.span`
  font-size: 16px;
  padding: 0 10px;
`

// const BtnAdd = styled.span`
//   padding: 10px;
//   margin-bottom: 10px;
//   display: block;
//   cursor: pointer;
//   color: ${defaultTheme.colors.whiteLabel.primary};
//   font-size: 14px;
// `

const FormDiv = styled.div`
  display: ${props => props.isVisible ? 'flex' : 'none'};
  flex-wrap: wrap;
`

class FormTray extends Component {
  state = {
    on: false,
    forms: []
  }

  componentDidMount() {
    const { data, name } = this.props
    const { enabled, items } = data.menus[name]
    if (data) {
      this.setState({on: enabled, forms: items})
    }
  }

  toggle = () =>
    this.setState(
      ({ on }) => ({ on: !on })
    )

  addForm = () => {
    const { forms } = this.state
    forms.push({
      id: '',
      name: '',
      price: {amount: ''}
    })
    this.setState({ forms: forms })
  }

  handleValue = (index, form) => {
    const { forms } = this.state
    forms[index] = form
    this.setState({ forms: forms })
  }

  render() {
    const { on, forms } = this.state
    const { isActive, items } = this.props
    return (
      <CheckBox
        label={'Bandeja'}
        name={'tray[enabled]'}
        checked={on}
        value={true}
        style={{ display: isActive ? 'block' : 'none' }}
        onClick={this.toggle}>
        <FormDiv isVisible={on}>
          {
            forms.map((form, i)=> {
              return <MenuForm form={form} isActive={items.tray[form.keyName]} index={i} key={i}/>
            })
          }
          {/* <BtnAdd onClick={this.addForm}>
            + Adicionar mais bandeja
          </BtnAdd> */}
        </FormDiv>
      </CheckBox>
    )
  }
}

class MenuForm extends Component {
  state = {
    price: '',
    name: ''
  }

  componentDidMount() {
    const { form } = this.props
    this.setState({
      price: form.price.amount,
      name: form.name,
      id: form.id,
      keyName: form.keyName
    })
  }

  getKeyName = name => {
    if(!name) return name
    const keys = {
    'Pão de queijo': 'cheeseBread',
    'Petit fours doce': 'petitFourCandy',
    'Petit fours salgado': 'petitFourSalty',
    'Bolo': 'cake',
    'Mini sanduiches': 'miniSandwich',
    'Cup cakes': 'cupCakes',
    'Travessa de frutas': 'fruitPlatter',
    }
    return keys[name] || name.replace(/\s/g, '_').toLowerCase()
  }

  handleValue = (e) => {
    const { value } = e.target
     const newValue = removeCurrency(value)
    this.setState({ price: newValue })
  }

  handleName = (e) => {
    const { value } = e.target
    this.setState({ name: value })
  }

  render() {
    const { price, name, keyName} = this.state
    const { index, isActive } = this.props
/*     const nameProps = this.props.form.name
 */
    return (
      <ContentForm style={{ display: isActive ? 'block' : 'none' }}>
        <TitleMenu>{name}</TitleMenu>
         <Input
          type={'text'}
          label={'Nome'}
          placeholder={'Digite o nome da bandeja'}
          name={`tray[items][${index}][name]`}
          value={name}
          error={!name}
          onChange={this.handleName}
        />
        <Input
          type={'text'}
          label={'Valor (por bandeja)'}
          placeholder={'R$0,00'}
          name={`tray[items][${index}][price]`}
          value={formatCurrency(price)}
          error={price <= 0}
          onChange={this.handleValue}
        />
        <Input type={'hidden'} name={`tray[items][${index}][keyName]`} value={keyName}/>
      </ContentForm>
    )
  }
}

function mapStateToProps (state) {
  const { forms } = state
  const { foodsAndBeverages } = forms
  const { data } = foodsAndBeverages
  return {
    data
  }
}

export default connect(mapStateToProps)(FormTray)
