import React from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../styles/themes'
import Icons from './ui/Icons'
import ItemsMenuLateral from './SidebarItems/ItemsMenuLateral'
import { verificaSeEhSomenteAEBOuEquipamentosOuTransfer } from '../../utils/fluxoCriacaoEvento'

const Label = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  box-sizing: border-box;
  padding: 5px 5px 5px 0px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
`

export default function (props) {
  return <>
    <Label style={{ textTransform: 'uppercase', marginBottom: '10px' }}>Serviços solicitados</Label>
    {props.services && props.services.lodging && props.services.lodging.requested && <ItemsMenuLateral selected={props.selected === 'lodging'}>
      <Icons
        icon={'bed-outline'}
        viewBox='0 0 1536 1024'
        width={36}
        height={20}
        color={'#484848'}
        style={{ verticalAlign: 'middle', margin: 'auto 5px' }}
      />
      Hospedagem
    </ItemsMenuLateral>}
    {props.services && props.services.room && props.services.room.requested && <ItemsMenuLateral selected={props.selected === 'room'}>
      <Icons
        icon={'room-outline'}
        viewBox='0 0 1536 1024'
        width={36}
        height={20}
        color={'#484848'}
        style={{ verticalAlign: 'middle', margin: 'auto 4px auto 6px' }}
      />
      Salas
    </ItemsMenuLateral>}
    {props.services && props.services.foodsAndBeverages && props.services.foodsAndBeverages.requested && <ItemsMenuLateral selected={props.selected === 'foodsAndBeverages'}>
      <Icons
        icon={'food-beverage-outline'}
        width={36}
        height={20}
        color={'#484848'}
        style={{ verticalAlign: 'middle', margin: 'auto 5px' }}
      />
      Alimentos e bebidas
    </ItemsMenuLateral>}
    {props.services && props.services.equipments && props.services.equipments.requested && <ItemsMenuLateral selected={props.selected === 'equipments'}>
      <Icons
        icon={'computer-outline'}
        viewBox='0 0 1280 1024'
        width={36}
        height={20}
        color={'#484848'}
        style={{ verticalAlign: 'middle', margin: 'auto 5px' }}
      />
      Equipamentos
    </ItemsMenuLateral>}
    {props.services && props.services.transfer && props.services.transfer.requested && <ItemsMenuLateral selected={props.selected === 'transfer'}>
      <Icons
        icon={'bus-outline'}
        width={36}
        height={20}
        color={'#484848'}
        style={{ verticalAlign: 'middle', margin: 'auto 5px' }}
      />
      Transfer
    </ItemsMenuLateral>}
    {props.services && props.services.otherServices && props.services.otherServices.requested && <ItemsMenuLateral selected={props.selected === 'otherServices'}>
      <Icons
        icon={'outros-servicos-outline'}
        width={36}
        height={20}
        color={'#484848'}
        style={{ verticalAlign: 'middle', margin: 'auto 5px' }}
      />
      Outros serviços
    </ItemsMenuLateral>}
    {!verificaSeEhSomenteAEBOuEquipamentosOuTransfer(props.services) && <ItemsMenuLateral selected={props.selected === 'locais'}>
      <Icons
        icon={'locais'}
        viewBox='0 0 1280 1024'
        width={36}
        height={20}
        color={'#484848'}
        style={{ verticalAlign: 'middle', margin: 'auto 5px' }}
      />
      Fornecedores sugeridos
    </ItemsMenuLateral>}
  </>
}
