import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = ({ component: Component, exact, type = '', path }) => (
  <Route exact path={path} render={props => (
    (window.localStorage.getItem('user_token') && (type === window.localStorage.getItem('user_type') || (type === 'ORGANIZER' && window.localStorage.getItem('user_type') === 'RESELLER')))
      ? <Component {...props} />
      : <Redirect to={{ pathname: '/signin', state: { from: props.location } }} />
  )} />
)

export default PrivateRoute
