import React, { Component } from 'react'
import styled from 'styled-components'
import config from '../../../../middleware/config'
import { defaultTheme } from '../../../styles/themes'
import Title from '../../ui/Title'
import Subtitle from '../../ui/Subtitle'
import Button from '../../ui/Button'
import AlertIcon from '../../ui/AlertIcon'
import ParkingIcon from '../../ui/ParkingIcon'
import Modal from '../'
import Loader from '../../Loader'
import { Put } from '../../../../utils/request'
import { removeTempFeatureFlag } from '../../../../utils/tiposUsuario'
import { adicionarServico, PARKING } from '../../../../utils/servicosFornecedor'
const Group = styled.div`
  display: flex;
`

const Titulo = styled.h1`
  color: ${defaultTheme.colors.text.darker};
  font-family: ${defaultTheme.fonts.secondary};
  font-size: 30px;
  font-weight: bolder;
  line-height: 1.2;
  margin: auto;
`

const Subtitulo = styled(Subtitle)`
  color: ${defaultTheme.colors.grey.medium};
  line-height: 1.4;
  margin: 6px 12px 24px 12px;
`

export default class ModalEstacionamento extends Component {
  constructor (props) {
    super(props)
    this.state = {
      temCerteza: false
    }

    this.handleCancel = this.handleCancel.bind(this)
    this.handleNoParking = this.handleNoParking.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleCancel () {
    this.setState({ temCerteza: false }, () => {
      this.props && this.props.onClose && this.props.onClose()
    })
  }

  handleNoParking () {
    this.setState({ temCerteza: true })
  }

  handleSubmit (temParking) {
    const { api: { base, version, routes: { supplier } } } = config
    const endpoint = `${base}${version}${supplier}/services/parking/${temParking ? 'enable' : 'disable'}`

    Put(endpoint).then(() => {
      removeTempFeatureFlag('hasToAnswerParking')
      if (temParking) {
        adicionarServico(PARKING)
        window.location = '/parking/add'
      }
      this.handleCancel()
    }).catch(erro => {
      window.alert(erro)
      this.handleCancel()
    })
  }

  render () {
    const buttonProps = {
      category: 'secondary',
      fontWeight: 800,
      width: '100%',
      full: true
    }
    return (
      <Modal maxWidth='490px' hasCloseButton isOpen onClose={this.handleCancel}>
        {this.state.isLoading ? <Loader /> : null}
        <Modal maxWidth='510px' isOpen={this.state.temCerteza}>
          <AlertIcon width='110px' />
          <Title secondaryFont style={{ marginBottom: '5px' }}>Tem certeza?</Title>
          <Subtitulo>Você não poderá receber orçamentos com pedidos de estacionamento</Subtitulo>
          <Group>
            <Button {...buttonProps} onClick={this.handleCancel} ghost children='Cancelar' />
            <Button {...buttonProps} onClick={() => { this.handleSubmit(false) }} children='Ok' />
          </Group>
        </Modal>
        <ParkingIcon width='180px' />
        <div style={{ margin: '5px auto' }}>
          <Titulo>Nova categoria:</Titulo>
          <Titulo>Estacionamento</Titulo>
        </div>
        <Subtitulo>A pedido de nossos clientes, criamos a categoria "Estacionamento" na ferramenta. Pedimos que cadastre agora mesmo suas informações para não ficar de fora de nenhum pedido.</Subtitulo>
        <Button {...buttonProps} onClick={() => { this.handleSubmit(true) }} children='Cadastrar agora' />
        <Button {...buttonProps} onClick={this.handleNoParking} ghost children='Não tenho estacionamento' />
      </Modal>
    )
  }
}
