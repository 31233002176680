/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
/* UI */
import Loader from '../Loader'
import Icons from '../ui/Icons'

const InputControl = styled.div`
`

const csv = 'text/csv'
const xls = 'application/vnd.ms-excel'
const xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
const ods = 'application/vnd.oasis.opendocument.spreadsheet'
const png = 'image/png'
const jpg = 'image/jpeg'
const pdf = 'application/pdf'
const doc = 'application/msword'
const docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
const odt = 'application/vnd.oasis.opendocument.text'
const MAX_SIZE = 3 * 1024 * 1024

export default class Upload extends Component {
  constructor () {
    super()
    this.state = {
      isLoading: false
    }
    this.fileUpload = React.createRef()
  }

  triggerClick = () => {
    this.props.enabled && this.fileUpload.current.click()
  }

  upload = (e) => {
    this.setState({ isLoading: true })
    const body = new FormData()
    const file = e.target.files[0]
    if (file.size > MAX_SIZE) {
      this.setState({ isLoading: false })
      return window.alert('Não é possível carregar arquivos com tamanho superior a 3MB')
    }
    body.append('image', file)

    const options = {
      method: 'POST',
      headers: {
        authorization: window.localStorage.getItem('user_token')
      },
      body
    }

    window.fetch(this.props.uploadPath, options).then(response => {
      if (response.ok) return response.json()
      else throw new Error(response)
    }).then(response => {
      this.setState({ isLoading: false }, () => {
        if (response.success) this.props.onFileUpload(response.data.file)
        else console.error(response)
      })
    }).catch(e => {
      this.setState({ isLoading: false }, () => {
        console.error(e)
      })
    })
  }

  render () {
    const { text } = this.props
    const { isLoading } = this.state

    return <InputControl>
      {isLoading && <Loader />}
      <Icons
        onClick={this.triggerClick}
        style={{ width: '24px', height: '24px', cursor: 'pointer', ...this.props.style }}
        icon={'paperclip'}
        color={'#6395C8'}
        title={text}
      />
      <input
        type='file'
        accept={[csv, xls, xlsx, ods, png, jpg, pdf, doc, docx, odt].join(', ')}
        ref={this.fileUpload}
        style={{ display: 'none' }}
        onChange={this.upload.bind(this)}
      />
    </InputControl>
  }
}
