import React, { Component } from 'react'
import Input from './ui/Input'
import { defaultTheme } from '../styles/themes'

const WrappedInputAddress = (WrapedComponent) =>

  class WrappedInputAddress extends Component {
    static defaultProps = {
      addressSelected: () => { }
    }

    constructor () {
      super()
      this.google = window.google
      this.autocomplete = ''
      this.google.maps.event.addDomListener(window, 'load', this.initAutocomplete)
      this.componentForm = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        administrative_area_level_1: 'short_name',
        administrative_area_level_2: 'long_name',
        country: 'long_name',
        postal_code: 'short_name'
      }
    }

    componentDidMount () {
      this.autocomplete = new this.google.maps.places.Autocomplete(
        document.getElementById(this.props.autocompleteId || 'autocomplete'),
        { types: ['geocode', 'establishment'] })
      this.autocomplete.addListener('place_changed', this.fillInAddress)
      document.getElementById(this.props.mapId || 'map').style.height = '220px'
      var map = new global.google.maps.Map(document.getElementById(this.props.mapId || 'map'), {
        center: { lat: -23.562703, lng: -46.654965 },
        signInControl: false,
        streetViewControl: false,
        zoom: 13
      })
      var input = document.getElementById(this.props.autocompleteId || 'autocomplete')
      var radius = document.getElementById('radius')
      // map.controls[global.google.maps.ControlPosition.TOP_LEFT].push(input)

      var autocomplete = new global.google.maps.places.Autocomplete(input)
      autocomplete.bindTo('bounds', map)

      var circle = new global.google.maps.Circle({
        strokeColor: defaultTheme.colors.whiteLabel.primary,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: defaultTheme.colors.whiteLabel.primary,
        fillOpacity: 0.35,
        map: map,
        center: map.getCenter(),
        radius: (this.state && this.state.radius) || 1000
      })

      // if(!this.props.hideMap) {
      //   document.getElementById(this.props.autocompleteId || 'autocomplete').style.width = '500px'
      // } else {
      //   document.getElementById('map').hide()
      // }

      if (radius) {
        radius.addEventListener("keyup", () => {
          if (map.getCenter()) {
            circle.setMap(null)
            circle = new global.google.maps.Circle({
              strokeColor: defaultTheme.colors.whiteLabel.primary,
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: defaultTheme.colors.whiteLabel.primary,
              fillOpacity: 0.35,
              map: map,
              center: map.getCenter(),
              radius: Number(radius.value * 1000) || 1000
            })
            map.fitBounds(circle.getBounds())
          }
        })
      }

      input.addEventListener("keydown", (e) => {
        if (e.keyCode === 13) {
          e.preventDefault()
          return false
        }

        //Limpar o campo com backspace
        if ((e.keyCode === 8) && autocomplete) {
          if (autocomplete.getPlace()) {
            const value = (input && input.value) || ""
            const place = autocomplete.getPlace()
            place.name = value.substr(0, value.length - 1)

            if (place.name.length === 0) {//chegou na última letra?
              place.address_components = []
            }
            autocomplete.set('place', place);
          }
          return false
        }
      })

      autocomplete.addListener('place_changed', () => {
        var place = autocomplete.getPlace()

        var getPlace = function (place) {
          var autocompleteService = new global.google.maps.places.AutocompleteService()
          if (place.name.length > 0) {
            var d = { input: place.name, offset: place.name.length };
            autocompleteService.getPlacePredictions(d, list => {
              if (!(list === null || list.length === 0)) {
                var placesService = new global.google.maps.places.PlacesService(document.getElementById(this.props.autocompleteId || 'autocomplete'))
                list.forEach(item => {
                  if (!place.address_components) {
                    var ref = { 'reference': item.reference }
                    placesService.getDetails(ref, (detailsResult, placesServiceStatus) => {
                      if (placesServiceStatus === global.google.maps.GeocoderStatus.OK) {
                        place = detailsResult
                        const lat = place.geometry && place.geometry.location.lat()
                        const lng = place.geometry && place.geometry.location.lng()
                        const componentForm = this.componentForm
                        const dataAddress = place.address_components && place.address_components.reduce((prev, cur) => {
                          const addressType = cur.types[0]
                          if (componentForm[addressType]) {
                            prev = { ...prev, [addressType]: cur[componentForm[addressType]] }
                          }
                          return prev
                        }, {})
                        this.props.addressSelected({
                          address: place.name || (dataAddress && (dataAddress.route || dataAddress.administrative_area_level_2 || dataAddress.administrative_area_level_1 || dataAddress.country)),
                          city: dataAddress && (dataAddress.administrative_area_level_2 || dataAddress.administrative_area_level_1 || dataAddress.country),
                          province: dataAddress && (dataAddress.administrative_area_level_1),
                          country: dataAddress && (dataAddress.country),
                          lat,
                          lng
                        })

                        if (!place.geometry) {
                          window.alert("Autocomplete's returned place contains no geometry")
                          return
                        }


                        if (place.geometry.viewport) {
                          map.fitBounds(place.geometry.viewport)
                        } else {
                          map.setCenter(place.geometry.location)
                          map.setZoom(17)
                        }

                        if (radius) {
                          circle.setMap(null)
                          circle = new global.google.maps.Circle({
                            strokeColor: defaultTheme.colors.whiteLabel.primary,
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            fillColor: defaultTheme.colors.whiteLabel.primary,
                            fillOpacity: 0.35,
                            map: map,
                            center: map.getCenter(),
                            radius: Number(radius.value * 1000) || 1000
                          })
                          map.fitBounds(circle.getBounds())
                        }
                      }
                    })
                  }
                })
              }
            })
          }
        }

        if (!place.address_components) {
          return getPlace.call(this, place)
        }

        const lat = place.geometry && place.geometry.location.lat()
        const lng = place.geometry && place.geometry.location.lng()
        const componentForm = this.componentForm
        const dataAddress = place.address_components && place.address_components.reduce((prev, cur) => {
          const addressType = cur.types[0]
          if (componentForm[addressType]) {
            prev = { ...prev, [addressType]: cur[componentForm[addressType]] }
          }
          return prev
        }, {})
        this.props.addressSelected({
          address: place.name || (dataAddress && (dataAddress.route || dataAddress.administrative_area_level_2 || dataAddress.administrative_area_level_1 || dataAddress.country)),
          city: dataAddress && (dataAddress.administrative_area_level_2 || dataAddress.administrative_area_level_1 || dataAddress.country),
          province: dataAddress && (dataAddress.administrative_area_level_1),
          country: dataAddress && (dataAddress.country),
          lat,
          lng
        })

        // if (!place.geometry) {
        //   window.alert("Autocomplete's returned place contains no geometry")
        //   return
        // }


        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport)
        } else {
          map.setCenter(place.geometry.location)
          map.setZoom(17)
        }

        if (radius) {
          circle.setMap(null)
          circle = new global.google.maps.Circle({
            strokeColor: defaultTheme.colors.whiteLabel.primary,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: defaultTheme.colors.whiteLabel.primary,
            fillOpacity: 0.35,
            map: map,
            center: map.getCenter(),
            radius: Number(radius.value * 1000) || 1000
          })
          map.fitBounds(circle.getBounds())
        }
        // if(!this.props.hideMap) {
        //   document.getElementById(this.props.autocompleteId || 'autocomplete').style.width = '550px'
        // } else {
        //   document.getElementById('map').hide()
        // }
      })
    }

    render () {
      return (
        <div>
          <WrapedComponent
            id={this.props.autocompleteId || 'autocomplete'}
            {...this.props}
          />
          <div id={this.props.mapId || 'map'} style={{ display: `${this.props.hideMap ? 'none' : 'block'}` }} />
        </div>
      )
    }
  }

export default WrappedInputAddress(Input)
