import { getNameFromKey } from '../../utils/fabTexts'

const API = '/api/v1'

export const FOODS_FETCH_REQUEST = 'FOODS_FETCH_REQUEST'
export const FOODS_FETCH_SUCCESS = 'FOODS_FETCH_SUCCESS'
export const FOODS_FETCH_FAILURE = 'FOODS_FETCH_FAILURE'


function requestFoods (form = []) {
  return {
    type: FOODS_FETCH_REQUEST,
    isFetching: true,
    form
  }
}

function receiveFoods (food) {
  return {
    type: FOODS_FETCH_SUCCESS,
    isFetching: false,
    data: food
  }
}

export function errorFoods (message) {
  return {
    type: FOODS_FETCH_FAILURE,
    isFetching: false,
    message
  }
}

export function createFoodsAndBeverages (form) {
  const token = window.localStorage.getItem('user_token')
  const config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    },
    body: JSON.stringify(form)
  }
  return dispatch => {
    dispatch(requestFoods(form))
    return window.fetch(`${API}/users`, config)
      .then(response => {
        if (response.status >= 401 && response.status <= 403) window.localStorage.clear()
        if (response.ok) {
          return response.json()
        } else {
          throw new Error(response.statusText)
        }
      }).then((food) => {
        if (!food.success) {
          alert('Não foi possível cadastrar um novo usuário.')
          dispatch(errorFoods(food))
          return Promise.reject(food)
        } else {
          dispatch(receiveFoods(food.data))
          window.location.href = '/foods-and-beverages'
        }
      }).catch(err => console.log('Error: ', err))
  }
}

export function fetchFoodsAndBeverages () {
  const token = window.localStorage.getItem('user_token')
  const config = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  }
  return dispatch => {
    dispatch(requestFoods())
    return window.fetch(`${API}/foods_and_beverages`, config)
      .then(response => {
        if (response.status >= 401 && response.status <= 403) window.localStorage.clear()
        if (response.ok) {
          return response.json()
        } else {
          throw new Error(response.statusText)
        }
      }).then((food) => {
        if (!food.success) {
          dispatch(errorFoods(food))
          return Promise.reject(food)
        } else {
          if(food.data && food.data.tray && food.data.tray) {
            var listaDeBandejas = ['cheeseBread', 'petitFourCandy', 'petitFourSalty', 'cake', 'miniSandwich', 'cupCakes', 'fruitPlatter']
            food.data.tray.items = listaDeBandejas.map(item => {
              if(food.data.tray.items.find(trayItem => trayItem.keyName === item)){
                return food.data.tray.items.find(trayItem => trayItem.keyName === item)
              } else {
                return {
                  details: null,
                  keyName: item,
                  name: getNameFromKey(item),
                  price: {
                    amount: 0,
                    currency: 'BRL'
                  },
                  quantity: null,
                  type: null
                }
              }
            })
          }
          if (food.data.length === 0) {
            dispatch(errorFoods(food))
            return Promise.resolve(food)
          } else {
            dispatch(receiveFoods(formatMenus(food.data)))
            return Promise.resolve(food)
          }
        }
      }).catch(err => {
        console.log('Error: ', err)
        return Promise.reject(err)
      })
  }
}

const formatMenus = (foodsAndBeverages) => {
  const menus = Object.keys(foodsAndBeverages)
  .filter(removeItems)
  .reduce((prev, cur) => {
    prev[cur] = foodsAndBeverages[cur]
    return prev
  }, {})
  return {id: foodsAndBeverages._id || foodsAndBeverages.id , menus: menus}
}

const removeItems = (item) => {
  const toRemove = ['_id', 'id', 'accountId', 'inserted', 'updated', 'created']
  return !toRemove.includes(item)
}
