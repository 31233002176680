/* LIBS */
import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import config from '../../../middleware/config'
import { Get } from '../../../utils/request'
import { getDateRange, formatDate } from '../../../utils/dates'
import { getNameFromKey } from '../../../utils/fabTexts'
import { diffBetweenDates, addDays } from '../../Supplier/Budget/utils'
import { formatCurrency } from '../../../utils/money'
import moment from 'moment'
/* STYLES */
import { defaultTheme, images } from '../../styles/themes'
/* COMPONENTS */
import Loader from '../../components/Loader'
import Title from '../../components/ui/Title'
import Subtitle from '../../components/ui/Subtitle';
import Money from '../../components/ui/Money'
import Card from '../../components/ui/Card';
import Slider from '../../components/ui/Slider';
import Icon from '../../components/ui/Icons';
import BreadCrumb from '../../components/BreadCrumb';
import Header from '../../components/Header';
import { getNameFromKey as transferText } from '../../../utils/transferTexts'
import { ForneceTransfer, ForneceOutrosServicos } from '../../../utils/servicosFornecedor'
import ButtonChat from '../../components/ui/ButtonChat'
import ChatRoom from '../../components/Chat'

const Main = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #FAFAFA;
`
const Content = styled.div`
  min-height: 70vh;
  padding: 20px;
  font-family: ${defaultTheme.fonts.secondary};
  width: 70%;
  font-size: 14px;
  a {
    text-decoration: none;
  }
`

const Group = styled.div`
`

const Link = styled.a`
  font-size: 15px;
  cursor: pointer;
  font-family: ${defaultTheme.fonts.primary};
  text-decoration: underline;
  font-weight: 300;
  color: ${defaultTheme.colors.primary.default};
`

const Background = styled.div`
  ${props => props.background
    ? `background: url(${props.background}) center top no-repeat`
    : `
      background: url(${images.fallbackRooms}) center top no-repeat
      background-repeat:   no-repeat;
      background-position: center center;
      background-size: auto; };
    `}
  background-size: 100%;
  background-color: #fff;
  height: 150px;
  width: 100%;
`

const InnerCard = styled.div `
  margin-top: 20px;
  width: 100%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  line-height: 1.3;
  color: ${defaultTheme.colors.text.dark};
  position: relative;

  & [type="radio"], & [type="checkbox"] {
    display: none;
}

  & [type="radio"]:checked+label:before,
  & [type="radio"]:not(:checked)+label:before {
      content: "";
      position: absolute;
      right: 10px;
      top: 5px;
      width: 18px;
      height: 18px;
      border: 1px solid #ddd;
      border-radius: 100%;
      background: #fff;
      -webkit-transition: all .2s ease;
      -o-transition: all .2s ease;
      transition: all .2s ease;
  }

  & [type=radio]:checked+label,
  & [type=radio]:not(:checked)+label {
      position: relative;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: #666;
      width: 100%;
      -webkit-transition: all .2s ease;
      -o-transition: all .2s ease;
      transition: all .2s ease;
  }

  & [type=radio]:checked+label:after {
      content: "";
      width: 12px;
      height: 12px;
      background: ${defaultTheme.colors.primary.default};
      position: absolute;
      right: 14px;
      top: 4px;
      border-radius: 100%;
      -webkit-transition: all .2s ease;
      -o-transition: all .2s ease;
      transition: all .2s ease;
  }

`
const BriefingData = styled.ul`
  font-family: ${defaultTheme.fonts.primary};
  font-size: 18px;
`
const BriefingInfo = styled.li`
  color: ${defaultTheme.colors.grey.light};
  font-weight: normal;
  font-size: 0.9em;
  & b {
    font-weight: bold;
    color: ${defaultTheme.colors.text.dark};
  }
`
const TitleBold = styled.h1`
  font-family: ${defaultTheme.fonts.secondary};
  color: ${defaultTheme.colors.text.darker};
  font-weight: 800;
  font-size: 1.2em;
`

const RoomDetail = styled.div`
  color: ${defaultTheme.colors.grey.light};
  width: 33%;
  font-weight: 100;
  font-size: 0.8em;
`
const MeetingRoomDetails = (props) => {
  const {maxCapacity, roomHeight, footage, bluePrint, name} = props
  return (
    <div style = {{padding: '20px'}}>
      <h3>{name}</h3>
      <div style={{display: 'flex', width: '100%'}}>
        <RoomDetail>Área: <br/>{footage}m²</RoomDetail>
        <RoomDetail>Pé direito: <br/>{roomHeight}m</RoomDetail>
        <RoomDetail>Cap. Máx: <br/>{maxCapacity} pessoas</RoomDetail>
      </div>
      {bluePrint && (<BluePrint url={bluePrint}/>)}
    </div>
  )
}

const BluePrint = (props) => {
  const {url} = props
  return (
    <div onClick={() => window.open(url, "_blank")}>
      <Card style={{cursor: 'pointer', width: 'calc(100% - 30px)'}}>
        <div style={{ width: '95%', display: 'flex', margin: 'auto', justifyContent: 'space-between'}}>
            <div>
              <Icon width={'20px'} height={'20px'} icon={'paperclip'} color={defaultTheme.colors.grey.medium}/>
              <p style={{float: 'right'}}>planta_baixa.pdf</p>
            </div>
          <div><p style={{color: defaultTheme.colors.primary.default}}>ver anexo</p></div>
        </div>
      </Card>
    </div>
  )
}

function getMaxCapacity(capacities) {
  return Object.keys(capacities).reduce((maxCap, roomFormat) => {
    return maxCap <= capacities[roomFormat]
      ? capacities[roomFormat]
      : maxCap
  },0)
}

const Label = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  box-sizing: border-box;
  padding: 5px 5px 5px 0px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
`


class AllInfo extends Component {
  state = {
    isLoading: true
  }

  componentDidMount() {
    const { api: { base, version, routes: { supplier, events } } } = config
    const { match: { params: { id } } } = this.props
    if (id) {
      const endpoint = `${base}${version}${events}/${id}/budget/fullInfo`
      Get(endpoint)
      .then((response) => {
        if (!response) {
          this.setState({ isLoading: false })
          return Promise.reject(response)
        } else {
          this.setState({event: response}, () => {
            const NewEndPoint = `${base}${version}${supplier}${events}/${id}`
            Get(NewEndPoint)
            .then((supplierResponse) => {
              if (!supplierResponse) {
                this.setState({ isLoading: false })
                return Promise.reject(supplierResponse)
              } else {
                this.setState({supplier: supplierResponse} , () => {
                  if (ForneceTransfer()) {
                    const transferBriefingEndPoint = `${base}${version}${events}/${id}/briefing/transfer`
                    Get(transferBriefingEndPoint)
                      .then((transferBriefingResponse) => {
                        if (!transferBriefingResponse) {
                          this.setState({ isLoading: false })
                          return Promise.reject(transferBriefingResponse)
                        } else {
                          this.setState({ transferBriefing: transferBriefingResponse, isLoading: false })
                        }
                      })
                  } else if (ForneceOutrosServicos()) {
                    const otherServicesBriefingEndPoint = `${base}${version}${events}/${id}/briefing/otherServices`
                    Get(otherServicesBriefingEndPoint)
                      .then((otherServicesBriefingResponse) => {
                        if (!otherServicesBriefingResponse) {
                          this.setState({ isLoading: false })
                          return Promise.reject(otherServicesBriefingResponse)
                        } else {
                          this.setState({ otherServicesBriefing: otherServicesBriefingResponse, isLoading: false })
                        }
                      })
                  }
                  else {
                    const BriefingRoomEndPoint = `${base}${version}${events}/${id}/briefing/room`
                    Get(BriefingRoomEndPoint)
                    .then((roomsResponse) => {
                      if (!roomsResponse) {
                        this.setState({ isLoading: false })
                        return Promise.reject(roomsResponse)
                      } else {
                        this.setState({rooms: roomsResponse}, () => {
                          const FoodsAndBeveragesEndPoint = `${base}${version}${events}/${id}/infoToBudget/foodsAndBeverages`
                          Get(FoodsAndBeveragesEndPoint)
                          .then((foodAndBeveragesResponse) => {
                            if (!foodAndBeveragesResponse) {
                              this.setState({ isLoading: false })
                              return Promise.reject(foodAndBeveragesResponse)
                            } else {
                              this.setState({foodsAndBeverages: foodAndBeveragesResponse, isLoading: false})
                            }
                          }).catch(err => {
                            this.setState({ isLoading: false })
                            console.log('Error: ', err)
                          })
                        })
                      }
                    }).catch(err => {
                      this.setState({ isLoading: false })
                      console.log('Error: ', err)
                    })
                  }
                })
              }
            }).catch(err => {
              this.setState({ isLoading: false })
              console.log('Error: ', err)
            })
          })
        }
      }).catch(err => {
        this.setState({ isLoading: false })
        console.log('Error: ', err)
      })
    }
  }

  calcTotal() {
    const { event } = this.state
    if (!event) return 0
    if (ForneceTransfer()) return event.services.transfer.totalChoosedWithFees.amount
    const totalFromServices = event.services && Object.keys(event.services)
        .filter(service => service !== 'transfer')
        .reduce((total, service) => {
        if (event.services[service].requested && event.services[service].id && event.services[service].totalChoosedPrice)
          total += event.services[service].totalChoosedPrice.amount
        return total
      }, 0)
    const fee = this.getManagementFee()
    const total = this.applyFee(fee, totalFromServices) + this.calcTaxes()
    return formatCurrency(total, false)
  }

  applyFee(fee, value) {
    return (fee.type === 'PERCENTAGE')
      ? Math.round(Number((value * (1 + (fee.amount / 100)))))
      : (value + fee.amount)
  }

  calcTaxes() {
    const { event } = this.state
    if (!event) return 0
    const totalTaxes = Object.keys(event.services).reduce((total, service) => {
      if (event.services[service].requested
        && event.services[service].id
        && event.services[service].budget
        && event.services[service].totalChoosedWithFees) {
          total += event.services[service].totalChoosedWithFees.amount - event.services[service].totalChoosedPrice.amount
        }
      return total
    }, 0)
    return totalTaxes
  }

  getManagementFee() {
    return this.state.event
      ? this.state.event.managementFee
      : {
        amount: 0,
        type: 'PERCENTAGE'
      }
  }

  getRoomTypeOfBed = (bedsConfiguration) => {
    var descricao = ''
    if(bedsConfiguration.double > 0 && bedsConfiguration.single > 0) {
      descricao = `${bedsConfiguration.double} cama${bedsConfiguration.double > 1 ? `s` : ``} de casal e ${bedsConfiguration.single} cama${bedsConfiguration.single > 1 ? `s` : ``} de solteiro`
    } else if(bedsConfiguration.double > 0) {
      descricao = `${bedsConfiguration.double} cama${bedsConfiguration.double > 1 ? `s` : ``} de casal`
    } else if(bedsConfiguration.single > 0) {
      descricao = `${bedsConfiguration.single} cama${bedsConfiguration.single > 1 ? `s` : ``} de solteiro`
    }
    return descricao
  }

  getIncludedItems = (includedItems) => {
    var included = ''
    var count = 0
    Object.keys(includedItems).forEach(key => {
      if(includedItems[key] && key !== 'others') {
        if(count === 0) {
          included += getNameFromKey(key)
        } else {
          included += ', ' + getNameFromKey(key)
        }
        count++
      } else if (key === 'others' && includedItems[key].sent) {
        if(count === 0) {
          included += includedItems[key].description
        } else {
          included += ', ' + includedItems[key].description
        }
        count++
      }
    })
    return included
  }

  visualizaOutrosServicoSelecionado = (name, requestId) => {
    const { event } = this.state
    const budgetDoBriefingSelecionadoIndex = event.services.otherServices.budget.services[name].requests.findIndex(budgetRequest => budgetRequest.briefingRequestId === requestId)
    if(budgetDoBriefingSelecionadoIndex !== -1) {
      const budgetSelecionadoIndex = event.services.otherServices.budget.services[name].requests[budgetDoBriefingSelecionadoIndex].budgets.findIndex(budget => budget.chosen)
      if(budgetSelecionadoIndex !== -1) {
        if(!event.services.otherServices.budget.services[name].requests[budgetDoBriefingSelecionadoIndex].budgets[budgetSelecionadoIndex].visualizacao) {
          event.services.otherServices.budget.services[name].requests[budgetDoBriefingSelecionadoIndex].budgets[budgetSelecionadoIndex].visualizacao = true
        } else {
          event.services.otherServices.budget.services[name].requests[budgetDoBriefingSelecionadoIndex].budgets[budgetSelecionadoIndex].visualizacao = !event.services.otherServices.budget.services[name].requests[budgetDoBriefingSelecionadoIndex].budgets[budgetSelecionadoIndex].visualizacao
        }
        this.setState({event: event})
      }
    }
  }

  Solicitacao = (name, briefing) => {
    return <Card style={{ padding: '10px', width: '100%', marginTop: '10px' }}>
      <Group style={{ padding: '10px' }}>
        <Title style={{ fontSize: '15px', marginBottom: '0px', marginTop: '0px' }}>{getNameFromKey(name)}</Title>
        <label style={{ position: 'absolute', right: '5px', top: '10px' }}>
          <Money>{formatCurrency(briefing.requests.reduce((soma, request) => {
            const budgetDoBriefingSelecionado = this.state.event.services.otherServices.budget.services[name].requests.find(budgetRequest => budgetRequest.briefingRequestId === request.id)
            const escolhido = budgetDoBriefingSelecionado && budgetDoBriefingSelecionado.budgets.find(budget => budget.chosen)
            soma += request.quantity *
            (escolhido ? escolhido.unitPrice.amount : 0)
            return soma
          }, 0), false)}</Money>
        </label>
        <Label style={{ fontSize: '12px', listStyle: 'none', position: 'absolute', right: '2px', top: '25px', textAlign: 'right' }}>valor total</Label>
        {briefing.requests.map(request => {
          const budgetDoBriefingSelecionado = this.state.event.services.otherServices.budget.services[name].requests.find(budgetRequest => budgetRequest.briefingRequestId === request.id)
          const budgetSelecionado = budgetDoBriefingSelecionado && budgetDoBriefingSelecionado.budgets.find(budget => budget.chosen)
          return <Card style={{ width: '100%', paddingBottom: '10px', marginTop: '20px' }}>
            <label style={{ position: 'absolute', right: '5px', top: '10px' }}>
              <Money style={{ color: defaultTheme.colors.grey.medium }}>{formatCurrency((budgetSelecionado.unitPrice.amount * budgetSelecionado.quantity), false)}</Money>
            </label>
            <Label style={{ fontSize: '12px', listStyle: 'none', position: 'absolute', right: '2px', top: '25px', textAlign: 'right' }}>{formatCurrency(budgetSelecionado.unitPrice.amount)} / unidade</Label>
            <Label style={{ fontWeight: 600 }}>
              {budgetSelecionado.name} ({request.name})
            </Label>
            {!(!budgetDoBriefingSelecionado || !budgetDoBriefingSelecionado.hasAvailability || !budgetSelecionado) && <Link style={{ marginLeft: 'auto' }} onClick={() => this.visualizaOutrosServicoSelecionado(name, request.id)}>{budgetSelecionado.visualizacao ? 'ocultar detalhes do pedido' : 'ver detalhes do pedido' }</Link>}
            {budgetSelecionado.visualizacao && <>
              <Label style={{ marginTop: '20px' }}>
                Data de entrega: {formatDate(request.deliveryDate)} |
                Quantidade: {request.quantity} unidades
                {request.hasContentCreation ? ' | Necessita criação de conteúdo' : ''}
              </Label>
              <Label style={{ marginTop: '10px' }}>
                <span style={{ fontWeight: 600 }}>Sua descrição do pedido:</span> {request.description ? request.description : 'Sem descrição.'}
              </Label>
              {budgetSelecionado && <Label style={{ marginTop: '10px', marginBottom: '20px' }}>
                <span style={{ fontWeight: 600 }}>Detalhes do fornecedor:</span> {budgetSelecionado.description ? budgetSelecionado.description : 'Sem descrição.'}
              </Label>}
            </>}
            {(!budgetDoBriefingSelecionado || !budgetDoBriefingSelecionado.hasAvailability || !budgetSelecionado) && <Card style = {{ width: '100%', paddingBottom: '10px', color: 'red' }}>
              <Subtitle style={{ marginBottom: 'auto', marginTop: 'auto' }}>Fornecedor não tem disponibilidade para este pedido.</Subtitle>
            </Card>}
          </Card>})}
      </Group>
    </Card>
  }

  SolicitacaoAdditionalService = (briefing) => {
    return briefing.requests.map(request => {
      const budgetDoBriefingSelecionado = this.state.event.services.otherServices.budget.services.additionalServices.requests.find(budgetRequest => budgetRequest.briefingRequestId === request.id)
      const escolhido = budgetDoBriefingSelecionado && budgetDoBriefingSelecionado.budgets.find(budget => budget.chosen)
      return <Card style={{ padding: '10px', width: '100%', marginTop: '10px' }}>
        <Group style={{ padding: '10px' }}>
          <Title style={{ fontSize: '15px', marginBottom: '20px', marginTop: '0px' }}>{escolhido ? escolhido.name : ''} ({request.name})</Title>
          <label style={{ position: 'absolute', right: '5px', top: '10px' }}>
            <Money>{formatCurrency((escolhido ? (escolhido.pricePerDay.amount * (escolhido.dates ? escolhido.dates.length : 1)) : 0), false)}</Money>
          </label>
          <Label style={{ fontSize: '12px', listStyle: 'none', position: 'absolute', right: '2px', top: '25px', textAlign: 'right' }}>{formatCurrency(escolhido ? (escolhido.pricePerDay.amount) : 0)} / por dia</Label>
          {!(!budgetDoBriefingSelecionado || !budgetDoBriefingSelecionado.hasAvailability || !escolhido) && <Link style={{ marginLeft: 'auto' }} onClick={() => this.visualizaOutrosServicoSelecionado('additionalServices', request.id)}>{(escolhido && escolhido.visualizacao) ? 'ocultar detalhes do pedido' : 'ver detalhes do pedido' }</Link>}
          {escolhido && escolhido.visualizacao && <Group style={{ marginTop: '20px' }}>
            <Label>
              Datas: {request.dates.map(date => formatDate(date)).join(' - ')}
              {request.hoursPerDay ? ` | ${request.hoursPerDay} horas por dia ` : ''}
            </Label>
            <Label style={{ marginTop: '10px' }}>
              <span style={{ fontWeight: 600 }}>Sua descrição do pedido:</span> {request.description ? request.description : 'Sem descrição.'}
            </Label>
            {escolhido && <Label style={{ marginTop: '10px', marginBottom: '20px' }}>
              <span style={{ fontWeight: 600 }}>Detalhes do fornecedor:</span> {escolhido.description ? escolhido.description : 'Sem descrição.'}
            </Label>}
          </Group>}
          {(!budgetDoBriefingSelecionado || !budgetDoBriefingSelecionado.hasAvailability || !escolhido) && <Card style = {{ width: '100%', paddingBottom: '10px', color: 'red' }}>
            <Subtitle style={{ marginBottom: 'auto', marginTop: 'auto' }}>Fornecedor não tem disponibilidade para este pedido.</Subtitle>
          </Card>}
        </Group>
      </Card>})
  }

  render() {
    const { isLoading } = this.state
    const eventName = this.state && this.state.event && this.state.event.name
    const eventId = this.state && this.state.event && this.state.event.id
    const eventStatus = this.state && this.state.event && this.state.event.status
    const supplierId = this.state && this.state.supplier && this.state.supplier.id
    const DISPLAY_CHAT = eventName && eventId && supplierId && eventStatus

    return <Main>
          <Header />
          <BreadCrumb options={
            [
              {link: '/organizer', name: 'Home'},
              {name: `${this.state.event ? this.state.event.name : ''}`}
            ]
            } />
          {isLoading && <Loader />}
          {!isLoading && <Content>
            {this.state.event && <Group style={{ display: 'flex' }}>
              <Group>
                <Title style={{ fontSize: '18px' }}>{this.state.event.name}</Title>
                <Subtitle style={{ color: '#7b7b7b' }}>
                  {`
                    ${getDateRange(new Date(this.state.event.start), new Date(this.state.event.end)).map(date => {
                      return ' ' + moment(date, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br').format('DD-MM-YYYY')
                    })}
                    ${this.state.event.numberOfParticipants ? ' | ' + this.state.event.numberOfParticipants + ' pessoas' : ''}
                  `}
                </Subtitle>
              </Group>
              <Group style={{ marginTop: '20px', textAlign: 'right', marginLeft: 'auto' }}>
                <Money>{formatCurrency(this.calcTotal(), false)}</Money>
                <Subtitle style={{ color: '#7b7b7b' }}>valor total incluindo taxas</Subtitle>
              </Group>
            </Group>}
            {DISPLAY_CHAT && <Group style={{ display: 'flex', justifyContent: 'flex-end' }}><ButtonChat fornecedor/></Group>}
            {/* Salas */}
            {!ForneceOutrosServicos() && !ForneceTransfer() && this.state.event.services.room.requested && <Card>
              <Group style={{ display: 'flex' }}>
                  <TitleBold style={{ fontSize: '18px' }}>Salas</TitleBold>
                  <Group style={{ textAlign: 'right', marginLeft: 'auto' }}>
                    <Money style={{ fontSize: '12px' }}>{formatCurrency(this.state.event.services.room.totalChoosedWithFees.amount, false)}</Money>
                    <Subtitle style={{ color: '#7b7b7b' }}>valor de salas incluindo taxas</Subtitle>
                  </Group>
              </Group>
              {this.state.event.services.room.budget.roomsBudget.map((room, index) => {
                return <Group key={index} style={{ display: 'flex' }}>
                  <InnerCard style={{ width: '40%' }}>
                    {<Fragment>
                      {<Group style={{
                        margin: '0 auto',
                        padding: '10px',
                        width: 'calc(100% - 20px)',
                        background: '#fff'}}>
                        <Slider>
                          {this.state.supplier.budgets.rooms.mostRecent.roomsBudget.find(m => m.id === room.id).room.photos.map(photo => {
                            return <Group>
                              <Background background={photo} />
                              </Group>
                          })}
                        </Slider>
                      </Group>}
                      <MeetingRoomDetails
                        maxCapacity = {getMaxCapacity(this.state.supplier.budgets.rooms.mostRecent.roomsBudget.find(m => m.id === room.id).room.capacity)}
                        roomHeight={this.state.supplier.budgets.rooms.mostRecent.roomsBudget.find(m => m.id === room.id).room.rooms_height}
                        footage={this.state.supplier.budgets.rooms.mostRecent.roomsBudget.find(m => m.id === room.id).room.footage}
                        bluePrint={this.state.supplier.budgets.rooms.mostRecent.roomsBudget.find(m => m.id === room.id).room.blue_print}
                        name={this.state.supplier.budgets.rooms.mostRecent.roomsBudget.find(m => m.id === room.id).room.name}
                        />
                    </Fragment>}
                  </InnerCard>
                  <BriefingData style={{ margin: 'auto 20px' }}>
                    <BriefingInfo><TitleBold style={{ fontSize: '18px' }}>SALA {index + 1} - {this.state.rooms.rooms.find(m => m.id === room.roomBriefingId).roomType}</TitleBold></BriefingInfo>
                    <BriefingInfo>Datas: <b>{formatDate(this.state.rooms.rooms.find(m => m.id === room.roomBriefingId).startDate)} - {formatDate(this.state.rooms.rooms.find(m => m.id === room.roomBriefingId).endDate)} ({diffBetweenDates(this.state.rooms.rooms.find(m => m.id === room.roomBriefingId).startDate, addDays(this.state.rooms.rooms.find(m => m.id === room.roomBriefingId).endDate, 1))} dia{diffBetweenDates(this.state.rooms.rooms.find(m => m.id === room.roomBriefingId).startDate, addDays(this.state.rooms.rooms.find(m => m.id === room.roomBriefingId).endDate, 1)) > 1 ? 's': ''})</b></BriefingInfo>
                    <BriefingInfo>Horário de início: <b>{this.state.rooms.rooms.find(m => m.id === room.roomBriefingId).startTime === '00:00:00' ? 'Horário não informado' : this.state.rooms.rooms.find(m => m.id === room.roomBriefingId).startTime}</b></BriefingInfo>
                    <BriefingInfo>Horário de término: <b>{this.state.rooms.rooms.find(m => m.id === room.roomBriefingId).endTime === '00:00:00' ? 'Horário não informado' : this.state.rooms.rooms.find(m => m.id === room.roomBriefingId).endTime}</b></BriefingInfo>
                    <BriefingInfo>Montagem: <b>{this.state.supplier.budgets.rooms.mostRecent.roomsBudget.find(m => m.id === room.id).hasMounting ? 'Sim' : 'Não'}</b></BriefingInfo>
                    <BriefingInfo>Tipo de Sala: <b>{this.state.rooms.rooms.find(m => m.id === room.roomBriefingId).roomFormat}</b></BriefingInfo>
                    <BriefingInfo>Número de pessoas (por dia): <b>{this.state.rooms.rooms.find(m => m.id === room.roomBriefingId).amountOfPeople} pessoas</b></BriefingInfo>
                    {this.state.rooms.rooms.find(m => m.id === room.roomBriefingId).roomItems.length === 0
                      ? ''
                      : (<BriefingInfo>Necessita de:: <b>{this.state.rooms.rooms.find(m => m.id === room.roomBriefingId).roomItems.join('; ')} </b></BriefingInfo>)}
                    <BriefingInfo>Observações do organizador: <b>{this.state.supplier.budgets.rooms.mostRecent.roomsBudget.find(m => m.id === room.id).observations || 'Sem observações'}</b></BriefingInfo>
                    <BriefingInfo>Suas observações:  <b>{this.state.supplier.briefings.rooms.observations || 'Sem observações'}</b></BriefingInfo>
                  </BriefingData>
                </Group>
              })}
            </Card>}
            {/* Alimentos e bebidas */}
            {!ForneceOutrosServicos() && !ForneceTransfer() && this.state.event.services.foodsAndBeverages.requested && <Card>
              <Group style={{ display: 'flex' }}>
                <Group>
                  <TitleBold style={{ fontSize: '18px' }}>Alimentos e bebidas</TitleBold>
                  <BriefingData>
                    <BriefingInfo>Observações do organizador: {this.state.supplier.briefings.foodsAndBeverages.observations}</BriefingInfo>
                    <BriefingInfo>Suas observações: {this.state.event.services.foodsAndBeverages.budget.observations}</BriefingInfo>
                  </BriefingData>
                </Group>
                  <Group style={{ textAlign: 'right', marginLeft: 'auto' }}>
                    <Money style={{ fontSize: '12px' }}>{formatCurrency(this.state.event.services.foodsAndBeverages.totalChoosedWithFees.amount, false)}</Money>
                    <Subtitle style={{ color: '#7b7b7b' }}>valor de A&B incluindo taxas</Subtitle>
                  </Group>
              </Group>
              {this.state.event.services.foodsAndBeverages.requested && Object.keys(this.state.event.services.foodsAndBeverages.budget.menusBudgets).filter(m => m !== 'tray' && m !== 'roomService').map(item => {
                var menu = this.state.event.services.foodsAndBeverages.budget.menusBudgets[item]
                return <InnerCard style={{ padding: '20px 0'}}>
                    {<Fragment>
                      <BriefingData style={{ margin: 'auto 20px', display: 'flex', flexWrap: 'wrap' }}>
                        <BriefingInfo style={{ width: '100%' }}><TitleBold style={{ fontSize: '18px', textTransform: 'uppercase' }}>{getNameFromKey(item)}</TitleBold></BriefingInfo>
                        <BriefingInfo style={{ width: '50%' }}>Datas: <b>{formatDate(this.state.supplier.briefings.foodsAndBeverages[item].requests[0].start)} - {formatDate(this.state.supplier.briefings.foodsAndBeverages[item].requests[this.state.supplier.briefings.foodsAndBeverages[item].requests.length - 1].start)} ({diffBetweenDates(this.state.supplier.briefings.foodsAndBeverages[item].requests[0].start, addDays(this.state.supplier.briefings.foodsAndBeverages[item].requests[this.state.supplier.briefings.foodsAndBeverages[item].requests.length - 1].start, 1))} dia{diffBetweenDates(this.state.supplier.briefings.foodsAndBeverages[item].requests[0].start, addDays(this.state.supplier.briefings.foodsAndBeverages[item].requests[this.state.supplier.briefings.foodsAndBeverages[item].requests.length - 1].start, 1)) > 1 ? 's': ''})</b></BriefingInfo>
                        <BriefingInfo style={{ width: '50%' }}>Horário de início: <b>{this.state.supplier.briefings.foodsAndBeverages[item].requests[0].startTime}</b></BriefingInfo>
                        <BriefingInfo style={{ width: '50%' }}>Número de pessoas (por dia): <b>{this.state.supplier.briefings.foodsAndBeverages[item].requests[0].amountOfPeople}</b></BriefingInfo>
                        <BriefingInfo style={{ width: '50%' }}>Cardápio escolhido: <b>{menu.budgets.find(m => m.chosen).item.name}</b></BriefingInfo>
                        {this.state.supplier.briefings.foodsAndBeverages[item].requests[0].beverages && <BriefingInfo style={{ width: '50%' }}>Tipos de bebidas: <b>{getNameFromKey(this.state.supplier.briefings.foodsAndBeverages[item].requests[0].beverages)}</b></BriefingInfo>}
                      </BriefingData>
                    </Fragment>}
                  </InnerCard>
              })}
              {Object.keys(this.state.event.services.foodsAndBeverages.budget.menusBudgets).filter(m => m === 'roomService').map(item => {
                var menu = this.state.event.services.foodsAndBeverages.budget.menusBudgets[item]
                return <InnerCard style={{ padding: '20px 0'}}>
                    {<Fragment>
                      <BriefingData style={{ margin: 'auto 20px', display: 'flex', flexWrap: 'wrap' }}>
                        <BriefingInfo style={{ width: '100%' }}><TitleBold style={{ fontSize: '18px', textTransform: 'uppercase' }}>{getNameFromKey(item)}</TitleBold></BriefingInfo>
                        <BriefingInfo style={{ width: '50%' }}>Datas: <b>{formatDate(this.state.supplier.briefings.foodsAndBeverages[item].requests[0].start)} - {formatDate(this.state.supplier.briefings.foodsAndBeverages[item].requests[this.state.supplier.briefings.foodsAndBeverages[item].requests.length - 1].start)} ({diffBetweenDates(this.state.supplier.briefings.foodsAndBeverages[item].requests[0].start, addDays(this.state.supplier.briefings.foodsAndBeverages[item].requests[this.state.supplier.briefings.foodsAndBeverages[item].requests.length - 1].start, 1))} dia{diffBetweenDates(this.state.supplier.briefings.foodsAndBeverages[item].requests[0].start, addDays(this.state.supplier.briefings.foodsAndBeverages[item].requests[this.state.supplier.briefings.foodsAndBeverages[item].requests.length - 1].start, 1)) > 1 ? 's': ''})</b></BriefingInfo>
                        <BriefingInfo style={{ width: '50%' }}>Horário de início: <b>{this.state.supplier.briefings.foodsAndBeverages[item].requests[0].startTime}</b></BriefingInfo>
                        <BriefingInfo style={{ width: '50%' }}>Número de pessoas (por dia): <b>{this.state.supplier.briefings.foodsAndBeverages[item].requests[0].amountOfPeople}</b></BriefingInfo>
                        <BriefingInfo style={{ width: '100%', marginBottom: '15px' }}></BriefingInfo>
                        {Object.keys(menu.budgets).reduce((all, roomServiceItem) => {
                            if(menu.budgets[roomServiceItem]) {
                              all.push(<BriefingInfo style={{ width: '50%' }}>{getNameFromKey(roomServiceItem)}: {this.state.foodsAndBeverages.briefing.roomService.supplierOptions.items.find(m => m.keyName === roomServiceItem) && getNameFromKey(this.state.foodsAndBeverages.briefing.roomService.supplierOptions.items.find(m => m.keyName === roomServiceItem).type)} {menu.budgets[roomServiceItem].quantity} * {menu.budgets[roomServiceItem].numberOfItems} {menu.budgets[roomServiceItem].numberOfItems > 1 ? 'unidades' : 'unidade'} / dia</BriefingInfo>)
                            }
                            return all
                          }, [])}
                        {Object.keys(this.state.event.services.foodsAndBeverages.budget.menusBudgets).filter(m => m === 'tray').map(budgetTray => {
                          var tray = this.state.event.services.foodsAndBeverages.budget.menusBudgets[budgetTray]
                          return Object.keys(tray.budgets).reduce((allTray, trayItem) => {
                            if(tray.budgets[trayItem]){
                              allTray.push(<BriefingInfo style={{ width: '50%' }}>{getNameFromKey(trayItem)}: {tray.budgets[trayItem].numberOfItems} bandejas / dia</BriefingInfo>)
                            }
                            return allTray
                          }, [])
                        })}
                        {/* <BriefingInfo style={{ width: '50%' }}>Cardápio escolhido: <b>{menu.budgets.find(m => m.chosen).item.name}</b></BriefingInfo>
                        {this.state.supplier.briefings.foodsAndBeverages[item].requests[0].beverages && <BriefingInfo style={{ width: '50%' }}>Tipos de bebidas: <b>{getNameFromKey(this.state.supplier.briefings.foodsAndBeverages[item].requests[0].beverages)}</b></BriefingInfo>} */}
                      </BriefingData>
                    </Fragment>}
                  </InnerCard>
              })}
            </Card>}
            {/* Hospedagem */}
            {!ForneceOutrosServicos() && !ForneceTransfer() && this.state.event.services.lodging.requested && <Card>
              <Group style={{ display: 'flex' }}>
                <Group>
                  <TitleBold style={{ fontSize: '18px' }}>Hospedagem</TitleBold>
                  <BriefingData>
                    <BriefingInfo>Incluso: {this.getIncludedItems(this.state.event.services.lodging.budget.includedItems)}</BriefingInfo>
                    <BriefingInfo>Entrega de brinde no apto: {this.state.event.services.lodging.budget.itemsOnApartmentPrice.amount > 0 ? 'Sim' : 'Não'}</BriefingInfo>
                    <BriefingInfo>Observações do organizador: {this.state.supplier.briefings.lodging.observations}</BriefingInfo>
                    <BriefingInfo>Suas observações: {this.state.event.services.lodging.budget.observations}</BriefingInfo>
                  </BriefingData>
                </Group>
                  <Group style={{ textAlign: 'right', marginLeft: 'auto' }}>
                    <Money style={{ fontSize: '12px' }}>{formatCurrency(this.state.event.services.lodging.totalChoosedWithFees.amount, false)}</Money>
                    <Subtitle style={{ color: '#7b7b7b' }}>valor de hospedagem incluindo taxas</Subtitle>
                  </Group>
              </Group>
              {this.state.event.services.lodging.budget.apartmentsBudget.filter(item => item.hasAvailability).map(item => {
                return <InnerCard style={{ padding: '20px 0'}}>
                    {<Fragment>
                      <BriefingData style={{ margin: 'auto 20px', display: 'flex', flexWrap: 'wrap' }}>
                        <BriefingInfo style={{ width: '100%' }}><TitleBold style={{ fontSize: '18px', textTransform: 'uppercase' }}>{item.numberOfApartments}x {getNameFromKey(item.item.keyName)}</TitleBold></BriefingInfo>
                        <BriefingInfo style={{ width: 'calc(100% / 3)' }}>Check in: <b>{formatDate(item.item.dates[0])} </b></BriefingInfo>
                        <BriefingInfo style={{ width: 'calc(100% / 3)' }}>Check out: <b>{formatDate(item.item.dates[item.item.dates.length - 1])} </b></BriefingInfo>
                        <BriefingInfo style={{ width: 'calc(100% / 3)' }}>Diárias: <b>{item.numberOfDays} </b></BriefingInfo>
                        <BriefingInfo style={{ width: 'calc(100% / 3)' }}>Categoria: <b>{item.category} </b></BriefingInfo>
                        <BriefingInfo style={{ width: 'calc(100% / 3)' }}>Tipo de cama: <b>{this.getRoomTypeOfBed(item.item.bedsConfiguration)} </b></BriefingInfo>
                      </BriefingData>
                    </Fragment>}
                  </InnerCard>
              })}
            </Card>}
            {/* Equipamentos */}
            {!ForneceOutrosServicos() && !ForneceTransfer() && this.state.event.services.equipments.requested && <Card>
              <Group style={{ display: 'flex' }}>
                <Group>
                  <TitleBold style={{ fontSize: '18px' }}>Equipamentos</TitleBold>
                </Group>
                  <Group style={{ textAlign: 'right', marginLeft: 'auto' }}>
                    <Money style={{ fontSize: '12px' }}>{formatCurrency(this.state.event.services.equipments.totalChoosedWithFees.amount, false)}</Money>
                    <Subtitle style={{ color: '#7b7b7b' }}>valor de equipamentos incluindo taxas</Subtitle>
                  </Group>
              </Group>
              { Object.keys(this.state.event.services.equipments.budget.equipments).map((budgetItem, index) => {
                    let item = this.state.event.services.equipments.budget.equipments[budgetItem]
                    if(item.hasAvailability) {
                      return <Card key={`equipments_${index}`} style={{ width: '100%', paddingBottom: '10px' }}>
                        <Group style={{ padding: '0px' }}>
                          <BriefingData style={{ margin: 'auto 20px', display: 'flex', flexWrap: 'wrap' }}>
                            <BriefingInfo style={{ fontWeight: 600, fontSize: '15px'}}>{getNameFromKey(budgetItem)}</BriefingInfo>
                            <Group style={{ textAlign: 'right', marginLeft: 'auto' }}>
                              <Money style={{ fontSize: '12px' }}>{formatCurrency(item.total.amount, false)}</Money>
                              <Subtitle style={{ color: '#7b7b7b', fontSize: '14px' }}>{budgetItem !== 'microphone' && formatCurrency(item.price.amount)} {(budgetItem !== 'microphone' && item.requests[0].amount > 0) ? ` x ${item.requests[0].amount} ${item.requests[0].amount > 1 ? 'unidades' : 'unidade'} / dia - sem taxas` : ''}</Subtitle>
                            </Group>
                          </BriefingData>
                        </Group>
                        <Group style={{ padding: '0px' }}>
                          <BriefingData style={{ margin: 'auto 20px', flexWrap: 'wrap' }}>
                            <BriefingInfo>
                                Observações: {item.observation || 'Sem observações'}
                              </BriefingInfo>
                              {budgetItem === 'microphone' && item.requests.map(microphoneItem => <>
                                <BriefingInfo style={{ marginTop: '10px' }}> Datas: {microphoneItem.dates.map(date => formatDate(date)).join(' - ')} </BriefingInfo>
                                {microphoneItem.numberOfLapel > 0 && <BriefingInfo> Lapela: {formatCurrency(item.lapelPrice.amount)} x {microphoneItem.numberOfLapel} {microphoneItem.numberOfLapel > 1 ? 'unidades' : 'unidade'}</BriefingInfo>}
                                {microphoneItem.numberOfGooseneck > 0 && <BriefingInfo> Gooseneck: {formatCurrency(item.gooseneckPrice.amount)} x {microphoneItem.numberOfGooseneck} {microphoneItem.numberOfGooseneck > 1 ? 'unidades' : 'unidade'}</BriefingInfo>}
                                {microphoneItem.numberOfHeadSet > 0 && <BriefingInfo> Head Set: {formatCurrency(item.headSetPrice.amount)} x {microphoneItem.numberOfHeadSet} {microphoneItem.numberOfHeadSet > 1 ? 'unidades' : 'unidade'}</BriefingInfo>}
                                {microphoneItem.numberOfStick > 0 && <BriefingInfo> Bastão: {formatCurrency(item.stickPrice.amount)} x {microphoneItem.numberOfStick} {microphoneItem.numberOfStick > 1 ? 'unidades' : 'unidade'}</BriefingInfo>}
                              </>
                              )}
                              {item.type && <BriefingInfo> Tipo: {getNameFromKey(item.type)}</BriefingInfo>}
                              {item.languages && <BriefingInfo> Idiomas: {getNameFromKey(item.languages[0])}</BriefingInfo>}
                              {item.interpreter && <BriefingInfo> Necessita intérprete: Sim</BriefingInfo>}
                              {item.mbSpeed && <BriefingInfo> Velocidade da internet: {item.mbSpeed}MB</BriefingInfo>}
                          </BriefingData>
                        </Group>
                      </Card>
                    } else {
                      return <Card key={`equipments_${index}`} style={{ paddingBottom: '10px', opacity: 0.5 }}>
                        <Group>
                          <BriefingData style={{ margin: 'auto 20px', display: 'flex', flexWrap: 'wrap' }}>
                            <BriefingInfo style={{ fontWeight: 600, fontSize: '15px'}}>{getNameFromKey(budgetItem)}</BriefingInfo>
                            <label style={{position: 'absolute', right: '50px', top: '30px'}}>
                              <BriefingInfo style={{ listStyle: 'none', color: 'black' }}>Equipamento indisponível</BriefingInfo>
                            </label>
                          </BriefingData>
                        </Group>
                      </Card>
                    }
                  })}
            </Card>}
            {/* Transfer */}
            {this.state.event.services.transfer.requested && this.state.transferBriefing && ForneceTransfer() && <Card>
              <Group style={{ display: 'flex' }}>
                <Group>
                  <TitleBold style={{ fontSize: '18px' }}>Transfer</TitleBold>
                </Group>
                <Group style={{ textAlign: 'right', marginLeft: 'auto' }}>
                  <Money style={{ fontSize: '12px' }}>{formatCurrency(this.state.event.services.transfer.totalChoosedWithFees.amount, false)}</Money>
                  <Subtitle style={{ color: '#7b7b7b' }}>valor de transfer incluindo taxas</Subtitle>
                </Group>
              </Group>
              {this.state.event.services.transfer.budget.requests.map((item, index) => {
                const briefing = this.state.transferBriefing.requests.find(briefing => briefing.id === item.briefingRequestId)

                if (item.hasAvailability) {
                  return <Card style={{ marginTop: '0px', display: 'flex' }}>
                    <Group>
                      <TitleBold>{"Solicitação " + ("0" + (index + 1)).slice(-2)}</TitleBold>
                      <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Observações:</span> {item.observation}</Subtitle>
                      <Subtitle style={{ color: '#727272', marginBottom: '0px', marginTop: '20px' }}><span style={{ fontWeight: 'bold' }}>Veículos:</span></Subtitle>
                      {item.vehicles.map(veiculo => <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>{transferText(veiculo.type)}:</span> {formatCurrency(veiculo.price.amount)} / dia</Subtitle>)}
                      {item.coordinatorPrices && <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Coordenador:</span> {formatCurrency(item.coordinatorPrices.pricePerDay.amount)} / dia</Subtitle>}
                      {briefing && briefing.hasOnboardServices && <Subtitle style={{ color: '#727272', marginBottom: '0px', marginTop: '20px' }}><span style={{ fontWeight: 'bold' }}>Serviços de bordo:</span></Subtitle>}
                      {briefing && briefing.hasOnboardServices && item.onboardServices.services.map(servico => <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>{transferText(servico.name)}:</span> {formatCurrency(servico.price.amount)} / unidade</Subtitle>)}
                      {briefing && briefing.hasOnboardServices && <Subtitle style={{ color: '#727272', marginBottom: '0px', marginTop: '20px' }}><span style={{ fontWeight: 'bold' }}>LunchBoxes:</span></Subtitle>}
                      {briefing && briefing.hasOnboardServices && item.onboardServices.lunchBoxes.map(lunchBox => <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>{lunchBox.name}:</span> {formatCurrency(lunchBox.price.amount)} / unidade</Subtitle>)}
                    </Group>
                  </Card>
                } else {
                  return <Card style={{ width: '100%', paddingBottom: '10px', color: 'red' }}>
                    <Subtitle style={{ marginBottom: 'auto', marginTop: 'auto' }}>Você alegou não ter disponibilidade para esta solicitação.</Subtitle>
                  </Card>
                //   return <Card key={`transfer_${index}`} style={{ paddingBottom: '10px', opacity: 0.5 }}>
                //     <Group>
                //       <BriefingData style={{ margin: 'auto 20px', display: 'flex', flexWrap: 'wrap' }}>
                //         <BriefingInfo style={{ fontWeight: 600, fontSize: '15px' }}>{getNameFromKey(budgetItem)}</BriefingInfo>
                //         <label style={{ position: 'absolute', right: '50px', top: '30px' }}>
                //           <BriefingInfo style={{ listStyle: 'none', color: 'black' }}>Equipamento indisponível</BriefingInfo>
                //         </label>
                //       </BriefingData>
                //     </Group>
                //   </Card>
                }
              })}
            </Card>}

            {this.state.event.services.otherServices.requested && this.state.otherServicesBriefing && ForneceOutrosServicos() && <Card>
              <Group style={{ display: 'flex' }}>
                <Group>
                  <TitleBold style={{ fontSize: '18px' }}>Outros Serviços</TitleBold>
                </Group>
                <Group style={{ textAlign: 'right', marginLeft: 'auto' }}>
                  <Money style={{ fontSize: '12px' }}>{formatCurrency(this.state.event.services.otherServices.totalChoosedWithFees.amount, false)}</Money>
                  <Subtitle style={{ color: '#7b7b7b' }}>valor de outros serviços incluindo taxas</Subtitle>
                </Group>
              </Group>
              {(this.state.event.services.otherServices.requested && this.state.event.services.otherServices.budget && this.state.otherServicesBriefing) && <>
                {this.state.otherServicesBriefing.services &&
                Object.keys(this.state.otherServicesBriefing.services).filter(item =>
                  this.state.otherServicesBriefing.services[item].requested).map(item =>
                    item === 'additionalServices' ? this.SolicitacaoAdditionalService(this.state.otherServicesBriefing.services[item]) : this.Solicitacao(item, this.state.otherServicesBriefing.services[item]))}
              </>}
            </Card>}

            {DISPLAY_CHAT && <Card>
              <ChatRoom
                eventId={eventId}
                supplierId={supplierId}
                eventName={eventName}
                eventStatus={eventStatus}
                user={'fornecedor'}
              />
            </Card>}
          </Content>
        }
      </Main>
  }
}

export default AllInfo
