/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
/* API */
import config from '../../../middleware/config'
import { Post } from '../../../utils/request'
/* UI */
import Input from '../../components/ui/Input'
/* STYLES */
import { defaultTheme } from '../../styles/themes'
import Button from '../../components/ui/Button'
import Card from '../../components/ui/Card'
/* COMPONENTS */
import Header from '../../Organizer/Header'
import Loader from '../../components/Loader'
import Main from '../../components/Main'
import Icons from '../../components/ui/Icons'
import BreadCrumb from '../../components/BreadCrumb'
import {
  validaCriacaoDoEvento,
  validaCricaoDeHospedagem,
  validaCriacaoDeSalas,
  validaResumo
  } from '../utils/validacoes'

import InputMask from 'react-input-mask'
import moment from 'moment'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'

const MainMobile = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #FAFAFA;
`
const FormContent = styled.form`
  padding: 10px 10px;
  box-sizing: border-box;
  width: 100%;
`

const Group = styled.div`
  display: flex;
  width: 100%;
`

const Title = styled.h1`
  font-family: ${defaultTheme.fonts.secondary};
  line-height: 1.2;
  font-size: 1.3em;
  margin-bottom: 20px;
`

const Label = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  font-family: ${defaultTheme.fonts.primary};
  font-size: 14px;
  box-sizing: border-box;
  padding: 5px 0px;
  display: block;
  font-weight: 300;
`


class Resumo extends Component {
  constructor () {
    super()
    this.state = {
      name: window.localStorage.getItem('evento_hibrido_name') || '',
      email: window.localStorage.getItem('evento_hibrido_email') || '',
      confirmEmail: '',
      phone: '55',
      isLoading: false,
      deuSubmit: false,
      hotelName: ''
    }
  }

  componentDidMount() {
    const { supplierChainId, hotelId } = this.props.match.params
    document.title = "Espaços para Eventos"
    ReactGA.initialize(window.localStorage.getItem('googleAnalyticsCode'))
    if(hotelId) {
      ReactGA.pageview('hotelSummary')
    } else {
      ReactGA.pageview('chainSummary')
    }
    const eventosChain = window.localStorage.getItem('criarEventoChain') && JSON.parse(window.localStorage.getItem('criarEventoChain'))
    if(eventosChain && eventosChain[supplierChainId] && eventosChain[supplierChainId].event) {
      const { event, lodging, rooms } = eventosChain[supplierChainId]
      rooms.eventStartDate = event.start
      rooms.eventEndDate = event.end
      event.start = [moment(event.start, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br')]
      event.end = [moment(event.end, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br')]
      if(lodging) {
        lodging.eventStartDate = event.start
        lodging.eventEndDate = event.end
      }
      if(!validaCriacaoDoEvento(event, hotelId)) {
        alert('Verifique as suas informações do evento!')
        return this.props.history.push(`/espacos-para-eventos/${supplierChainId}${hotelId ? `/${hotelId}` : ''}/event`)
      } else if (this.verificaSeSelecionouHospedagem() && !validaCricaoDeHospedagem(lodging)) {
        alert('Verifique as suas informações de hospedagem!')
        return this.props.history.push(`/espacos-para-eventos/${supplierChainId}${hotelId ? `/${hotelId}` : ''}/lodging`)
      } else if (!validaCriacaoDeSalas(rooms)) {
        alert('Verifique as suas informações de salas!')
        return this.props.history.push(`/espacos-para-eventos/${supplierChainId}${hotelId ? `/${hotelId}` : ''}/rooms`)
      }
      const hoteisChain = (window.localStorage.getItem('hoteisChain') && JSON.parse(window.localStorage.getItem('hoteisChain'))) || []
      const hotelName = hoteisChain.find(hotel => hotel.id === hotelId) && hoteisChain.find(hotel => hotel.id === hotelId).name
      this.setState({ hotelName })
    } else {
      alert('Verifique as suas informações do evento!')
      this.props.history.push(`/espacos-para-eventos/${supplierChainId}${hotelId ? `/${hotelId}` : ''}/event`)
    }
  }

  submit = (e) => {
    e && e.preventDefault()
    this.setState({ deuSubmit: true, isLoading: true })
    if(!validaResumo(this.state)) {
      this.setState({ isLoading: false })
      return alert('Verifique todos os campos para continuar')
    }
    const { api: { base, version } } = config
    const { supplierChainId, hotelId } = this.props.match.params
    const { history } = this.props
    const endpoint = hotelId ? `${base}${version}/chain/${supplierChainId}/hotel/${hotelId}/event` : `${base}${version}/chain/${supplierChainId}/event`
    const eventosChain = window.localStorage.getItem('criarEventoChain') && JSON.parse(window.localStorage.getItem('criarEventoChain'))
      if(eventosChain && eventosChain[supplierChainId] && eventosChain[supplierChainId].event) {
        var evento = eventosChain[supplierChainId]
        evento.event.start = evento.event.start[0] && moment(evento.event.start[0], 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br').format('YYYY-MM-DD')
        evento.event.end = evento.event.end[0] && moment(evento.event.end[0], 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br').format('YYYY-MM-DD')
        evento.user = {
          name: this.state.name,
          email: this.state.email,
          phone: this.state.phone.replace('(', '').replace(')', '').replace('+', '')
        }
        if(evento.event.services && evento.event.services.lodging && evento.event.services.lodging.requested) {
          evento.lodging = this.generateJSONHospedagem(evento.lodging)
        }
        Post(endpoint, evento)
        .then((response) => {
          const eventosChain = window.localStorage.getItem('criarEventoChain') && JSON.parse(window.localStorage.getItem('criarEventoChain'))
          if(eventosChain && eventosChain[supplierChainId] && eventosChain[supplierChainId].event) {
            eventosChain[supplierChainId] = {}
            window.localStorage.setItem('criarEventoChain', JSON.stringify(eventosChain))
          }
          history.push({
            pathname: `/espacos-para-eventos/${supplierChainId}${hotelId ? `/${hotelId}` : ''}/confirmation`,
            state: { eventId: response.eventId }
          })
        })
        .catch((error) => {
          console.error(error)
          if(error.message) {
            alert(`Ao fechar o seu pedido encontramos o seguinte problema: ${error.message} - Favor entrar em contato através do email: corp@evnts.com.br`)
          } else {
            alert('Ocorreu um problema ao fechar o pedido. Favor entrar em contato através do email: corp@evnts.com.br')
          }
          this.setState({isLoading: false})
        })
    }
  }

  generateJSONHospedagem = (hospedagem) => {
    var apartments = []
    hospedagem.singleType.selected && hospedagem.singleType.list.forEach(item => {
      item.amountOfApartments > 0 && apartments.push({
        id: item.id,
        dates: item.dates.map(date => {
          return moment(date, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br').format('YYYY-MM-DD')
        }),
        numberOfApartments: item.amountOfApartments,
        keyName: 'single',
        bedsConfiguration: {
          single: 0,
          double: 1,
          auxiliar: 0
        }
      })
    })
    hospedagem.doubleType.selected && hospedagem.doubleType.list.forEach(item => {
      item.amountOfApartmentsDouble > 0 && apartments.push({
        id: item.id,
        dates: item.dates.map(date => {
          return moment(date, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br').format('YYYY-MM-DD')
        }),
        numberOfApartments: item.amountOfApartmentsDouble,
        keyName: 'double',
        bedsConfiguration: {
          single: 0,
          double: 1,
          auxiliar: 0
        }
      })
      item.amountOfApartmentsTwin > 0 && apartments.push({
        id: item.id,
        dates: item.dates.map(date => {
          return moment(date, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br').format('YYYY-MM-DD')
        }),
        numberOfApartments: item.amountOfApartmentsTwin,
        keyName: 'twin',
        bedsConfiguration: {
          single: 2,
          double: 0,
          auxiliar: 0
        }
      })
    })
    hospedagem.tripleType.selected && hospedagem.tripleType.list.forEach(item => {
      item.amountOfApartments > 0 && apartments.push({
        id: item.id,
        dates: item.dates.map(date => {
          return moment(date, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br').format('YYYY-MM-DD')
        }),
        numberOfApartments: item.amountOfApartments,
        keyName: 'triple',
        bedsConfiguration: {
          single: 3,
          double: 0,
          auxiliar: 0
        }
      })
    })
    return {
      ...hospedagem.briefing,
      observations: hospedagem.observations,
      itemsOnApartment: hospedagem.itemsOnApartment,
      apartments: apartments
    }
  }

  handlerValue = (input) => {
    const state = this.state
    const { name, value } = input.target
    state[name] = value
    this.setState(state)
  }

  gerenciaResponsividade = () => {
    if (window.innerWidth < 600) return this.paginaMobile()
    else return this.paginaDesktop()
  }

  verificaSeSelecionouHospedagem = () => {
    const { supplierChainId } = this.props.match.params
    const eventosChain = window.localStorage.getItem('criarEventoChain') && JSON.parse(window.localStorage.getItem('criarEventoChain'))
    if(eventosChain && eventosChain[supplierChainId] && eventosChain[supplierChainId].event) {
      return eventosChain[supplierChainId].event.services && eventosChain[supplierChainId].event.services.lodging && eventosChain[supplierChainId].event.services.lodging.requested
    } else {
      return false
    }
  }

  verificaSeSelecionouAlimentosEBebidas = () => {
    const { supplierChainId } = this.props.match.params
    const eventosChain = window.localStorage.getItem('criarEventoChain') && JSON.parse(window.localStorage.getItem('criarEventoChain'))
    if(eventosChain && eventosChain[supplierChainId] && eventosChain[supplierChainId].event) {
      return eventosChain[supplierChainId].event.services && eventosChain[supplierChainId].event.services.foodsAndBeverages && eventosChain[supplierChainId].event.services.foodsAndBeverages.requested
    } else {
      return false
    }
  }

  verificaSeSelecionouEquipamentos = () => {
    const { supplierChainId } = this.props.match.params
    const eventosChain = window.localStorage.getItem('criarEventoChain') && JSON.parse(window.localStorage.getItem('criarEventoChain'))
    if(eventosChain && eventosChain[supplierChainId] && eventosChain[supplierChainId].event) {
      return eventosChain[supplierChainId].event.services && eventosChain[supplierChainId].event.services.equipments && eventosChain[supplierChainId].event.services.equipments.requested
    } else {
      return false
    }
  }

  paginaMobile = () => {
    const { supplierChainId, hotelId } = this.props.match.params
    const { isLoading } = this.state
    const { name, email, confirmEmail, phone } = this.state
    const gtmId = window.localStorage.getItem('gtmId')
    return <MainMobile>
      {gtmId && TagManager.initialize({ gtmId })}
      <Header />
      {isLoading ? <Loader /> : null}
      <Group style={{ display: 'block' }}>
        <Card style={{ margin: 'auto', marginTop: '80px', maxWidth: '1000px', position: 'relative' }}>
          <Group style={{ position: 'absolute', top: '-40px', left: '0px' }}>
            {this.verificaSeSelecionouHospedagem() && <BreadCrumb options={
              [
                { link: `/espacos-para-eventos/${supplierChainId}${hotelId ? `/${hotelId}` : ''}/event`, name: 'Dados gerais do evento' },
                { link: `/espacos-para-eventos/${supplierChainId}${hotelId ? `/${hotelId}` : ''}/lodging`, name: 'Hospedagem' },
                { link: `/espacos-para-eventos/${supplierChainId}${hotelId ? `/${hotelId}` : ''}/rooms`, name: 'Salas' },
                { name: 'Seus dados' }
              ]} />}
            {!this.verificaSeSelecionouHospedagem() && <BreadCrumb options={
              [
                { link: `/espacos-para-eventos/${supplierChainId}${hotelId ? `/${hotelId}` : ''}/event`, name: 'Dados gerais do evento' },
                { link: `/espacos-para-eventos/${supplierChainId}${hotelId ? `/${hotelId}` : ''}/rooms`, name: 'Salas' },
                { name: 'Seus dados' }
            ]} />}
          </Group>
          <FormContent>
            <Label style={{ fontSize: '13px ', marginLeft: '10px' }}>{this.verificaSeSelecionouHospedagem() ? 'Etapa 4 de 4' : 'Etapa 3 de 3'}</Label>
            <Title style={{ marginLeft: '10px' }}>Seus dados</Title>
            <Input
              type={'text'}
              label={'Nome completo'}
              placeholder={'Digite seu nome completo'}
              name={'name'}
              defaultValue={name}
              error={name === '' && this.state.deuSubmit}
              onBlur={this.handlerValue}
              isRequired />
            <Group>
              <Input
                type={'email'}
                label={'Email'}
                placeholder={'Digite seu email'}
                name={'email'}
                defaultValue={email}
                error={(email === '' || email !== confirmEmail) && this.state.deuSubmit}
                onBlur={this.handlerValue}
                isRequired />
              <Input
                type={'email'}
                label={'Confirme seu email'}
                placeholder={'Confirme seu email'}
                name={'confirmEmail'}
                defaultValue={confirmEmail}
                error={(confirmEmail === '' || email !== confirmEmail) && this.state.deuSubmit}
                onBlur={this.handlerValue}
                isRequired />
              <InputMask
                mask="+99 (99) 99999 9999"
                maskChar=""
                value={phone}
                onChange={(input) => this.setState({ phone: input.target.value})}>
                {() => <Input
                  type={'text'}
                  label={'Telefone'}
                  placeholder={'+00 00 00000 0000'}
                  name={'phone'}
                  error={!(phone.length >= 10) && this.state.deuSubmit}
                  isRequired
                />}
              </InputMask>
            </Group>
            <Group style={{ marginLeft: '10px' }}>
              <Icons
                color={'gray'}
                width={20}
                height={20}
                style={{ margin: 'auto 1px' }} icon='info-circle' />
              <Label>Você receberá os orçamentos no email cadastrado acima</Label>
            </Group>
            <Button
              type='submit'
              category={'secondary'}
              fontWeight={800}
              onClick={this.submit}
              style={{ width: '300px', marginLeft: 'auto' }}
              full>
              Solicitar orçamentos
            </Button>
          </FormContent>
        </Card>

        <Card style={{ margin: 'auto', marginTop: '20px', maxWidth: '1000px' }}>
          <FormContent>
            <Title style={{ marginLeft: '10px', marginBottom: '0px' }}>Resumo do evento {hotelId && `em ${this.state.hotelName}`}</Title>
            <Label style={{ marginLeft: '10px' }}>Veja abaixo o resumo dos serviços solicitados</Label>
            <Title style={{ marginLeft: '10px', marginBottom: '0px', fontSize: '15px', marginTop: '20px' }}>Itens solicitados</Title>
            <Card style={{ padding: '5px', marginLeft: '10px' }}>
              <Label style={{ fontWeight: 600, marginLeft: '10px' }}>Hospedagem</Label>
              <Icons
                icon={'checkmark'}
                color={'#3BB082'}
                width={22}
                height={22}
                style={{ position: 'absolute', right: '5px', top: '8px' }} />
            </Card>
            <Card style={{ padding: '5px', marginLeft: '10px' }}>
              <Label style={{ fontWeight: 600, marginLeft: '10px' }}>Salas</Label>
              <Icons
                icon={'checkmark'}
                color={'#3BB082'}
                width={22}
                height={22}
                style={{ position: 'absolute', right: '5px', top: '8px' }} />
            </Card>
            {this.verificaSeSelecionouAlimentosEBebidas() && <Card style={{ padding: '5px', marginLeft: '10px' }}>
              <Label style={{ fontWeight: 600, marginLeft: '10px' }}>Alimentos e bebidas</Label>
              <Icons
                icon={'checkmark'}
                color={'#3BB082'}
                width={22}
                height={22}
                style={{ position: 'absolute', right: '5px', top: '8px' }} />
            </Card>}
            {this.verificaSeSelecionouEquipamentos() && <Card style={{ padding: '5px', marginLeft: '10px' }}>
              <Label style={{ fontWeight: 600, marginLeft: '10px' }}>Equipamentos</Label>
              <Icons
                icon={'checkmark'}
                color={'#3BB082'}
                width={22}
                height={22}
                style={{ position: 'absolute', right: '5px', top: '8px' }} />
            </Card>}
          </FormContent>
        </Card>
      </Group>
    </MainMobile>
  }

  paginaDesktop = () => {
    const { supplierChainId, hotelId } = this.props.match.params
    const { isLoading } = this.state
    const { name, email, confirmEmail, phone } = this.state
    const gtmId = window.localStorage.getItem('gtmId')
    return <>
      {gtmId && TagManager.initialize({ gtmId })}
      <Header />
      <Main>
        {isLoading ? <Loader /> : null}
        <Group style={{ display: 'block' }}>
          <Card style={{ margin: 'auto', marginTop: '80px', maxWidth: '1000px', position: 'relative' }}>
            <Group style={{ position: 'absolute', top: '-40px', left: '0px' }}>
              {this.verificaSeSelecionouHospedagem() && <BreadCrumb options={
                [
                  { link: `/espacos-para-eventos/${supplierChainId}${hotelId ? `/${hotelId}` : ''}/event`, name: 'Dados gerais do evento' },
                  { link: `/espacos-para-eventos/${supplierChainId}${hotelId ? `/${hotelId}` : ''}/lodging`, name: 'Hospedagem' },
                  { link: `/espacos-para-eventos/${supplierChainId}${hotelId ? `/${hotelId}` : ''}/rooms`, name: 'Salas' },
                  { name: 'Seus dados' }
                ]} />}
              {!this.verificaSeSelecionouHospedagem() && <BreadCrumb options={
                [
                  { link: `/espacos-para-eventos/${supplierChainId}${hotelId ? `/${hotelId}` : ''}/event`, name: 'Dados gerais do evento' },
                  { link: `/espacos-para-eventos/${supplierChainId}${hotelId ? `/${hotelId}` : ''}/rooms`, name: 'Salas' },
                  { name: 'Seus dados' }
              ]} />}
            </Group>
            <FormContent>
              <Label style={{ fontSize: '13px ', marginLeft: '10px' }}>{this.verificaSeSelecionouHospedagem() ? 'Etapa 4 de 4' : 'Etapa 3 de 3'}</Label>
              <Title style={{ marginLeft: '10px' }}>Seus dados</Title>
              <Input
                type={'text'}
                label={'Nome completo'}
                placeholder={'Digite seu nome completo'}
                name={'name'}
                defaultValue={name}
                error={name === '' && this.state.deuSubmit}
                onBlur={this.handlerValue}
                isRequired />
              <Group>
                <Input
                  type={'email'}
                  label={'Email'}
                  placeholder={'Digite seu email'}
                  name={'email'}
                  defaultValue={email}
                  error={(email === '' || email !== confirmEmail) && this.state.deuSubmit}
                  onBlur={this.handlerValue}
                  isRequired />
                <Input
                  type={'email'}
                  label={'Confirme seu email'}
                  placeholder={'Confirme seu email'}
                  name={'confirmEmail'}
                  defaultValue={confirmEmail}
                  error={(confirmEmail === '' || email !== confirmEmail) && this.state.deuSubmit}
                  onBlur={this.handlerValue}
                  isRequired />
                <InputMask
                  mask="+99 (99) 99999 9999"
                  maskChar=""
                  value={phone}
                  onChange={(input) => this.setState({ phone: input.target.value})}>
                  {() => <Input
                    type={'text'}
                    label={'Telefone'}
                    placeholder={'+00 00 00000 0000'}
                    name={'phone'}
                    error={!(phone.length >= 10) && this.state.deuSubmit}
                    isRequired
                  />}
                </InputMask>
              </Group>
              <Group style={{ marginLeft: '10px' }}>
                <Icons
                  color={'gray'}
                  width={20}
                  height={20}
                  style={{ margin: 'auto 1px' }} icon='info-circle' />
                <Label>Você receberá os orçamentos no email cadastrado acima</Label>
              </Group>
              <Button
                type='submit'
                category={'secondary'}
                fontWeight={800}
                onClick={this.submit}
                style={{ width: '300px', marginLeft: 'auto' }}
                full>
                Solicitar orçamentos
              </Button>
            </FormContent>
          </Card>

          <Card style={{ margin: 'auto', marginTop: '20px', maxWidth: '1000px' }}>
            <FormContent>
              <Title style={{ marginLeft: '10px', marginBottom: '0px' }}>Resumo do evento {hotelId && `em ${this.state.hotelName}`}</Title>
              <Label style={{ marginLeft: '10px' }}>Veja abaixo o resumo dos serviços solicitados</Label>
              <Title style={{ marginLeft: '10px', marginBottom: '0px', fontSize: '15px', marginTop: '20px' }}>Itens solicitados</Title>
              {this.verificaSeSelecionouHospedagem() && <Card style={{ padding: '5px', marginLeft: '10px' }}>
                <Label style={{ fontWeight: 600, marginLeft: '10px' }}>Hospedagem</Label>
                <Icons
                  icon={'checkmark'}
                  color={'#3BB082'}
                  width={22}
                  height={22}
                  style={{ position: 'absolute', right: '5px', top: '8px' }} />
              </Card>}
              <Card style={{ padding: '5px', marginLeft: '10px' }}>
                <Label style={{ fontWeight: 600, marginLeft: '10px' }}>Salas</Label>
                <Icons
                  icon={'checkmark'}
                  color={'#3BB082'}
                  width={22}
                  height={22}
                  style={{ position: 'absolute', right: '5px', top: '8px' }} />
              </Card>
              {this.verificaSeSelecionouAlimentosEBebidas() && <Card style={{ padding: '5px', marginLeft: '10px' }}>
                <Label style={{ fontWeight: 600, marginLeft: '10px' }}>Alimentos e bebidas</Label>
                <Icons
                  icon={'checkmark'}
                  color={'#3BB082'}
                  width={22}
                  height={22}
                  style={{ position: 'absolute', right: '5px', top: '8px' }} />
              </Card>}
              {this.verificaSeSelecionouEquipamentos() && <Card style={{ padding: '5px', marginLeft: '10px' }}>
                <Label style={{ fontWeight: 600, marginLeft: '10px' }}>Equipamentos</Label>
                <Icons
                  icon={'checkmark'}
                  color={'#3BB082'}
                  width={22}
                  height={22}
                  style={{ position: 'absolute', right: '5px', top: '8px' }} />
              </Card>}
            </FormContent>
          </Card>
        </Group>
      </Main>
    </>
  }

  render () {
    return this.gerenciaResponsividade()
  }
}

export default Resumo
