import React, { Component } from 'react'
import { defaultTheme } from '../../styles/themes'

export default class IconeSalaNaoEncontrada extends Component {
  render () {
    const estilo = { fill: 'none', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }

    return (
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" height={this.props.height || '150'} x="0px" y="0px" viewBox="0 0 231.1 155.2" style={{ enableBackground: 'new 0 0 231.1 155.2', width: '100%' }}>
        <title>Foto de sala não encontrada</title>
        <circle style={estilo} cx="56.9" cy="5.8" r="3.8"/>
        <circle style={estilo} cx="208.5" cy="32.9" r="3.9"/>
        <circle style={estilo} cx="21.6" cy="150.1" r="3.9"/>
        <line style={estilo} x1="170" y1="4.8" x2="160.3" y2="4.8"/>
        <line style={estilo} x1="165.2" y1="0" x2="165.2" y2="9.7"/>
        <line style={estilo} x1="16.2" y1="31.7" x2="6.5" y2="31.7"/>
        <line style={estilo} x1="11.3" y1="26.9" x2="11.3" y2="36.6"/>
        <line style={estilo} x1="218.1" y1="147.4" x2="208.4" y2="147.4"/>
        <line style={estilo} x1="213.2" y1="142.5" x2="213.2" y2="152.2"/>
        <line style={estilo} x1="9.7" y1="102.6" x2="0" y2="102.6"/>
        <line style={estilo} x1="4.8" y1="97.8" x2="4.8" y2="107.5"/>
        <path style={estilo} d="M136.7,21.6c-4.6-1.1-3.1-7.4-7.8-8.6s-6.2,5.2-10.8,4s-3.1-7.4-7.8-8.5s-6.2,5.2-10.8,4"/>
        <path style={estilo} d="M50.8,67c-4.8,0-4.8-6.5-9.6-6.5S36.5,67,31.7,67s-4.8-6.5-9.6-6.5S17.4,67,12.6,67"/>
        <path style={estilo} d="M230.9,103.4c-4.1-2.4-0.9-8-5.1-10.4s-7.4,3.2-11.5,0.8s-0.9-8-5.1-10.4s-7.4,3.2-11.5,0.8"/>
        <g>
          <path style={{ ...estilo, strokeWidth: 2.8346 }} d="M76.7,128.4c-5.3,0-9.7-4.3-9.7-9.7V60.8c0-5.3,4.3-9.7,9.7-9.7h83.7c5.3,0,9.7,4.3,9.7,9.7v57.9 c0,5.3-4.3,9.7-9.7,9.7H76.7z M78.3,73.7c0,6.2,5,11.3,11.3,11.3c6.2,0,11.3-5,11.3-11.3c0-6.2-5-11.3-11.3-11.3 C83.3,62.4,78.3,67.5,78.3,73.7z M157.1,115.5V93l-17.6-17.6c-0.9-0.9-2.5-0.9-3.4,0l-27.3,27.3L97.7,91.5c-0.9-0.9-2.5-0.9-3.4,0 l-14.4,14.4v9.7H157.1z" />
        </g>
      </svg>
    )
  }
}
