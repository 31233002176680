/* LIBS */
import React, { Component } from 'react'
import Button from '../../../components/ui/Button'
import MoedaIcon from '../../../components/ui/MoedaIcon'
import Select from '../../../components/ui/Select'
import MoneyInput from '../../../components/ui/MoneyInput'
import Textarea from '../../../components/ui/Textarea'
import Modal from '../../../components/Modal/index'
import Loader from '../../../components/Loader'
import styled from 'styled-components'
import { Post } from '../../../../utils/request'
import config from '../../../../middleware/config'
import { defaultTheme } from '../../../styles/themes'
import formatCurrency from '../../../../utils/money';


const Title = styled.h1`
  font-family: ${defaultTheme.fonts.secondary};
  font-weight: 800;
  line-height: 1.2;
  font-size: 1.7em;
  margin-top: 20px;
  color: #484848;
`

const Group = styled.div`
  text-align: left;
`

const LabelBlack = styled.label`
  color: ${props => props.error ? 'red' : defaultTheme.colors.grey.medium};
  box-sizing: border-box;
  padding: 10px 5px 5px 0px;
  display: block;
  font-family: ${defaultTheme.fonts.secondary};
  font-weight: 600;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: center;
`

const Label = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  box-sizing: border-box;
  padding: 5px 5px 5px 0px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 200;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: center;
`

const Span = styled.span`
  color: ${defaultTheme.colors.primary.default};
`

class ContraProposta extends Component {
  constructor(props) {
    super()
    this.state = {
      isDeadline: props.isDeadline,
      isDeadlineCloser: props.isDeadlineCloser,
      hoursLeft: props.hoursLeft,
      isOpen: props.isOpen,
      requestedServices: props.requestedServices || {},
      category: '',
      amount: '',
      justification: '',
      errorMessage: ''
    }
  }

  componentDidMount() {
    this.setState({isOpen: this.props.isOpen})
  }

  componentWillReceiveProps(props) {
    this.setState({ isOpen: props.isOpen, requestedServices: props.requestedServices })
  }

  submit = () => {
    if(this.isEmpty()) return alert('Preencha todos os campos obrigatórios.')
    if(!this.isValid()) return alert('Preencha um valor menor do que o valor total do serviço prestado.')
    this.setState({ isLoading: true }, () => {
      const { api: { base, version } } = config
      const chooseSupplier = `${base}${version}/budget/${this.state.category}/${this.state.requestedServices[this.state.category].id}/counter-proposal`
      Post(chooseSupplier, { object: {
          initialPrice: this.state.requestedServices[this.state.category].total,
          requestedPrice: this.state.amount,
          justification: this.state.justification,
          requester: 'ORGANIZER',
          valid: true
        }
      })
      .then(() => {
        alert('Contraproposta criada com sucesso!')
        this.setState({ isLoading: false })
        this.props.onClose && this.props.onClose()
        window.location.reload()
      })
    })
  }

  close = () => {
    this.props.onClose && this.props.onClose()
  }

  handleValue = (input) => {
    var state = this.state
    state[input.target.name] = input.target.value
    this.setState(state)
  }

  isEmpty = () => {
    return this.state.category === '' || this.state.amount === ''
  }

  isValid = () => {
    return this.state.requestedServices[this.state.category].total > this.state.amount
  }

  render () {
    const ENCERROU = this.state.isDeadline()
    const PERTO_DE_ENCERRAR = (!this.state.isDeadline()) && this.state.isDeadlineCloser()

    return <>
      <Modal maxWidth={'500px'} isOpen={this.state.isOpen} onClose={this.close} hasCloseButton>
        {this.state.isLoading ? <Loader /> : null}
        <MoedaIcon />
        <Title>Contraproposta</Title>
        {ENCERROU && <Label>O deadline para o envio de novas propostas acabou. Você deve estender o SLA deste evento.</Label>}
        {PERTO_DE_ENCERRAR && <Label>Falta(m) menos de {this.state.hoursLeft()} hora(s) para vencer o SLA do evento. Que tal renová-lo por mais algumas horas?</Label>}
        <LabelBlack>{this.state.requestedServices.supplierName}</LabelBlack>
        <Label>Valor total da proposta: <Span>{formatCurrency(this.state.requestedServices.totalServices)}</Span></Label>
        {this.state.category !== '' && <Label>Valor total do serviço selecionado: <Span>{formatCurrency(this.state.requestedServices[this.state.category].total)}</Span></Label>}
        <Group>
          <Select
            label={'Selecione a categoria'}
            isRequired
            name='category'
            onChange={this.handleValue}
            error={this.state.category === ''}
            value={this.state.category}>
            <option value={''}>Selecione</option>
            {this.state.requestedServices.lodging.id && <option value={'lodging'}>Hospedagem</option>}
            {this.state.requestedServices.room.id && <option value={'room'}>Salas</option>}
            {this.state.requestedServices.foodsAndBeverages.id && <option value={'foodsAndBeverages'}>Alimentos e bebidas</option>}
            {this.state.requestedServices.equipments.id && <option value={'equipments'}>Equipamentos</option>}
          </Select>
          <MoneyInput
            label={'Valor da contraproposta'}
            placeholder={'0,00'}
            name={`amount`}
            value={this.state.amount}
            error={this.state.amount === ''}
            onChange={this.handleValue}
            isRequired
          />
          <Textarea
            label={'Justificativa'}
            placeholder={'Escreva aqui o motivo da sua contraproposta.'}
            name={`justification`}
            value={this.state.justification}
            style={{ height: '75px' }}
            onChange={this.handleValue}
          />
        </Group>
        <Button
          type={'button'}
          onClick={this.submit}
          category={'secondary'}
          fontWeight={800}
          full>
          Enviar
        </Button>
      </Modal>
    </>
  }
}

export default ContraProposta
