/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../../../../styles/themes'
import config from '../../../../../middleware/config'
/* COMPONENTS */
import Header from '../../../Header'
import Main from '../../../../components/Main'
import Body from '../../../../components/Body'
import RequestTransfer from './RequestTransfer'
import Icons from '../../../../components/ui/Icons'
import Card from '../../../../components/ui/Card'
import Button from '../../../../components/ui/Button'
import Alert from '../../../../components/ui/Alert'
import Loader from '../../../../components/Loader'
import GerenciaFluxoEdicao from '../../../../components/GerenciaFluxoEdicao';
import ServicosSolicitados from '../../../../components/ServicosSolicitados';
import { Get, Post, Put } from '../../../../../utils/request'
import { formatDate } from '../../../../../utils/dates'
import LinkCustom from '../../../../components/ui/LinkCustom'
import { pegaObjetoVazioDeRequisicao } from './helpers'
import { retornaUrlFluxoCriacao, retornaUrlFluxoEdicao } from '../../../../../utils/fluxoCriacaoEvento';
import { ehChainUser } from '../../../../../utils/tiposUsuario'

const Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  font-family: ${defaultTheme.fonts.secondary};
  padding: 10px 0;
  color: #484848;
`

const FormContent = styled.form`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
`

const Label = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  box-sizing: border-box;
  padding: 5px 5px 5px 0px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
`

const Group = styled.div`
`

const FixedDiv = styled.div`
  position: fixed;
  top: 46px;
  bottom: 0;
  width: 22%;
  overflow-y: scroll;
  margin-top: 14px;
`
class Transfers extends Component {
  constructor () {
    super()
    this.state = {
      transferRequests: [],
      observations: '',
      errorMessage: false,
      isLoading: false,
      event: {},
      eventStartDate: '',
      eventEndDate: '',
      eventName: '',
      eventLocation: {},
      eventStatus: '',
      startTime: '',
      endTime: '',
      services: '',
      numberOfParticipants: 0,
      salvaEdicao: false,
      briefing: {}
    }
  }

  componentDidMount () {
    if (ehChainUser()) {
      alert('Você não tem permissão para acessar esta página.')
      this.props.history.push(`/organizer`)
    }
    // TODO: Se for uma edição => montar a página já com esses dados
    const { briefing, id } = this.props.match.params
    if (briefing) this.fetchBriefing()

    this.setState({ isLoading: true }, () => {
      const { api: { base, version } } = config
      const userType = window.localStorage.getItem('user_type')
      const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
      const newEndpoint = `${base}${version}${eventEndpoint}/${id}`
      Get(newEndpoint).then((response) => {
        this.setState({ isLoading: false })
        if (!response) {
          this.setState({ errorMessage: response.message })
          return Promise.reject(response)
        } else {
          this.setState({
            event: { ...response },
            eventStartDate: response.start,
            eventEndDate: response.end,
            eventName: response.name,
            eventLocation: response.location,
            eventFriendlyId: response.internalId || response.friendlyId,
            startTime: response.startTime,
            endTime: response.endTime,
            numberOfParticipants: response.numberOfParticipants,
            services: response.services,
            eventStatus: response.status
          }, () => {
            if (!briefing) {
              this.setState({
                transferRequests: [pegaObjetoVazioDeRequisicao()]
              })
            }
          })
        }
      }).catch(err => {
        this.setState({ isLoading: false })
        console.log('Error: ', err)
      })
    })
  }

  fetchBriefing = () => {
    const { id, briefing } = this.props.match.params
    const { api: { base, version } } = config
    const userType = window.localStorage.getItem('user_type')
    const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
    const endpoint = `${base}${version}${eventEndpoint}/${id}/briefing/transfer/${briefing}`
    Get(endpoint)
    .then((response) => {
      response.requests.map(item => {
        item.originDate = [new Date(item.originDate)]
        item.destinationDate = [new Date(item.destinationDate)]
        return item
      })
      this.setState({ transferRequests: response.requests, briefing: response })
    }).catch(err => {
      this.setState({ isLoading: false })
      console.log('Error: ', err)
    })
  }


  deletarRequisicao = (index) => {
    const { transferRequests } = this.state
    transferRequests.splice(index, 1)
    this.setState({ transferRequests })
  }

  adicionarRequisicao = () => {
    const { transferRequests } = this.state
    transferRequests.push(pegaObjetoVazioDeRequisicao())
    this.setState({ transferRequests })
  }

  adicionaParada = (index) => {
    const { transferRequests } = this.state
    transferRequests[index].stops.push({})
    this.setState({ transferRequests })
  }

  deletarParada = (index, stopIndex) => {
    const { transferRequests } = this.state
    transferRequests[index].stops.splice(stopIndex, 1)
    this.setState({ transferRequests })
  }


  submit = (e) => {
    e && e.preventDefault()
    if(!this.isValid()) return alert('Preencha todas as informações para continuar')
    this.setState({
      isLoading: true
    }, () => {
      const { api: { base, version } } = config
      const { id, briefing } = this.props.match.params
      const userType = window.localStorage.getItem('user_type')
      const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
      const endpoint = `${base}${version}${eventEndpoint}/${id}/briefing/transfer/`
      const briefingEndpoint = `${base}${version}${eventEndpoint}/${id}/briefing/transfer/${briefing}`
      if(briefing) {
        Put(briefingEndpoint, this.generateJSON())
        .then(() => {
          this.props.history.push(retornaUrlFluxoEdicao(this.state.services, id, 'transfer'))
        }).catch(err => {
          console.error(err.message)
          this.setState({
            isLoading: false
          })
          alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
        })

      } else {
        Post(endpoint, this.generateJSON())
        .then(() => {
          this.props.history.push(retornaUrlFluxoCriacao(this.state.services, id, this.state.eventStatus, 'transfer'))
        }).catch(err => {
          console.error(err.message)
          this.setState({
            isLoading: false
          })
          alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
        })
      }
    })
  }

  checkEdicao = (e) => {
    e && e.preventDefault()
    if(!this.isValid()) return alert('Preencha todas as informações para continuar')
    this.setState({ salvaEdicao: true }, () => this.setState({ salvaEdicao: false }))
  }

  atualizaDados = (input, index) => {
    const request = this.state.transferRequests
    const { name, value } = input.target
    request[index][name] = value
    this.setState({
      transferRequests: request
    })
  }

  atualizaDadosLocalizacao = (data, campo, index) => {
    const request = this.state.transferRequests
    request[index][campo] = data
    this.setState({
      transferRequests: request
    })
  }

  atualizaDadosLocalizacaoParada = (data, index, stopIndex) => {
    const request = this.state.transferRequests
    request[index].stops[stopIndex] = data
    this.setState({
      transferRequests: request
    })
  }

  isValid = () => {
    return !this.state.transferRequests.some(item => {
      const type = item.type === ''
      const vehicleTime = item.type === 'VEICULO_DISPOSICAO' ? item.vehicleTime === '' : false
      const passengers = !(item.passengers > 0)
      const luggage = item.luggage === ''
      const origin = !(item.origin.address)
      const originDate = !(item.originDate && item.originDate[0])
      const stops = item.stops.length > 0 ? item.stops.some(stop => !(stop.address)) : false
      const destination = !(item.destination.address)
      const destinationDate = !(item.destinationDate && item.destinationDate[0])
      const hasCoordinator = item.hasCoordinator === ''
      const coordinatorLanguage = item.hasCoordinator === 'true' ? item.coordinatorLanguage === '': false
      const hasOnboardServices = item.hasOnboardServices === ''
      return type || vehicleTime || passengers || luggage || origin ||
        originDate || stops || destination || destinationDate ||
        hasCoordinator || coordinatorLanguage ||
        hasOnboardServices
    })
  }

  generateJSON = () => {
    const { transferRequests } = this.state
    transferRequests.map((item, index) => {
      if(!item.name) {
        item.name = `Solicitação ${('0' + (index + 1)).slice(-2)}`
      }
      return item
    })
    return {
      ...this.state.briefing,
      requests: this.state.transferRequests
    }
  }

  renderizarRequisicoes () {
    return this.state.transferRequests.map((request, index) =>
      <RequestTransfer
        key={index}
        request={request}
        atualizaDados={this.atualizaDados}
        index={index}
        dados={this.state.transferRequests}
        atualizaDadosLocalizacao={this.atualizaDadosLocalizacao}
        atualizaDadosLocalizacaoParada={this.atualizaDadosLocalizacaoParada}
        adicionaParada={this.adicionaParada}
        deletarParada={this.deletarParada}
        deletarRequisicao={this.deletarRequisicao}/>
    )
  }

  render = () => {
    const { errorMessage, isLoading } = this.state
    const { id, briefing } = this.props.match.params
    const listaDeRedirecionamento = JSON.parse(window.localStorage.getItem('redirecionamento_edicao_fluxo'))
    return <>
      <Header />
      <Main>
        <Body {...this.props} pagina={this.state.eventStatus === 'INACTIVE' ? 'create-evento' : 'editar-evento'} menuLateralDireito={this.menuLateralDireito()}>
          <GerenciaFluxoEdicao pagina={'tranfer'} eventId={id} briefingId={briefing} form={this.generateJSON()} salvaEdicao={this.state.salvaEdicao} onSubmit={this.submit} />


          <FormContent ref={form => this.form = form} id={'form'}>
            <Title style={{ width: '100%' }}>Transfer</Title>
            {this.renderizarRequisicoes()}

            {(listaDeRedirecionamento && listaDeRedirecionamento.length > 0) && <Alert type={'edition'} style={{ display: 'flex', marginBottom: '40px' }}>
              <Icons
                icon={'exclamation'}
                color={'white'}
                width={30}
                style={{ margin: 'auto' }}
                height={15} />
              <p style={{ textAlign: 'justify' }}>
                Você alterou a <span style={{ fontWeight: 'bold' }}>data</span> e/ou o <span style={{ fontWeight: 'bold' }}>número de pessoas</span> nos dados de seu evento. Acreditamos que você queira validar os dados abaixo para eventualmente alterar este mesmo aspecto também abaixo.
                    </p>
            </Alert>}
            {isLoading ? <Loader /> : null}
            <LinkCustom onClick={this.adicionarRequisicao.bind(this)}>+ adicionar mais uma solicitação de transfer</LinkCustom>
            {errorMessage && <Alert type={'negative'}>{errorMessage}</Alert>}
          </FormContent>
        </Body>
      </Main>
    </>
  }

  menuLateralDireito = () => {
    const { briefing } = this.props.match.params
    return <FixedDiv>
      <Card style={{ width: '100%' }}>
        <Label style={{ textTransform: 'uppercase' }}>Resumo do Evento <span style={{ float: 'right', paddingRight: '10px' }}>ID:{this.props.eventFriendlyId}</span></Label>
        <Group>
          <Label style={{ fontWeight: 600, color: '#484848' }}>{this.state.eventName}</Label>
          <Group style={{ display: 'flex' }}>
            <Icons
              icon={'calendario'}
              color={'gray'}
              width={40}
              height={20}
              style={{ marginRight: '-5px', marginTop: '5px' }} />
            <Label>{formatDate(this.state.eventStartDate)} - {formatDate(this.state.eventEndDate)}</Label>
          </Group>
          <Group style={{ display: 'flex' }}>
            <Icons
              icon={'map-pin'}
              color={'gray'}
              width={40}
              height={20}
              style={{ marginRight: '-5px', marginTop: '5px', marginLeft: '2px' }} />
            <Label>{this.state.eventLocation.address} - {this.state.eventLocation.city}</Label>
          </Group>
          <Group style={{ display: 'flex' }}>
            <Icons
              icon={'pessoas'}
              color={'gray'}
              width={40}
              height={20}
              style={{ marginTop: '5px', marginLeft: '-3px' }} />
            <Label>{this.state.numberOfParticipants} pessoas</Label>
          </Group>
        </Group>
      </Card>
      <Card style={{ width: '100%' }}>
        <ServicosSolicitados services={this.state.services} selected={'transfer'} />
        <Button
          type='submit'
          category={'secondary'}
          fontWeight={800}
          onClick={this.state.eventStatus === 'INACTIVE' ? this.submit : this.checkEdicao}
          full>
          {this.state.eventStatus === 'INACTIVE' ? (briefing ? 'Ok' : 'Próximo') : 'Salvar alterações'}
        </Button>
      </Card>
    </FixedDiv>
  }

}

export default Transfers
