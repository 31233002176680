import { notNullNorUndefined } from './tiposUsuario'

export const ROOM = 'ROOM'
export const FOODSANDBEVERAGES = 'FOOD_BEVERAGE'
export const AERO = 'FLIGHT'
export const LODGING = 'ACCOMMODATION'
export const PARKING = 'PARKING'
export const TRANSFER = 'TRANSFER'
export const EQUIPMENTS = 'EQUIPMENT'
export const OTHER_SERVICES = 'OTHER_SERVICES'

export const ForneceTransfer = () => {
  return ObtemServicos().indexOf(TRANSFER) !== -1
}

export const ForneceOutrosServicos = () => {
  return ObtemServicos().indexOf(OTHER_SERVICES) !== -1
}

export const ObtemServicos = () => {
  const item = window.localStorage.getItem('services')
  var servicos
  try {
    servicos = (notNullNorUndefined(item)) && JSON.parse(item)
  } catch (error) { console.error(error) }
  return servicos || []
}

export const adicionarServico = servico => {
  const item = window.localStorage.getItem('services')
  var servicos
  try {
    servicos = (notNullNorUndefined(item)) && JSON.parse(item)
  } catch (error) { console.error(error) }
  if (servico && servicos && Array.isArray(servicos)) {
    servicos.push(servico)
    window.localStorage.setItem('services', JSON.stringify(servicos))
  }
}

export const room = 'room'
export const lodging = 'lodging'
export const foodsAndBeverages = 'foodsAndBeverages'
export const equipments = 'equipments'
export const parking = 'parking'
export const transfer = 'transfer'
export const otherServices = 'otherServices'
export const gifts = 'gifts'
export const printShop = 'printShop'
export const additionalServices = 'additionalServices'

export const renderizaNomeDoServico = servico => {
  switch (servico) {
    case room: return 'Salas'
    case lodging: return 'Hospedagem'
    case foodsAndBeverages: return 'Alimentos e Bebidas'
    case equipments: return 'Equipamentos'
    case parking: return 'Estacionamento'
    case transfer: return 'Transfer'
    case otherServices: return 'Outros Serviços'
    case gifts: return 'Brindes'
    case printShop: return 'Gráfica'
    case additionalServices: return 'Serviço adicional'
    default: return ''
  }
}

export const forneceServico = servico => {
  return ObtemServicos().indexOf(servico) !== -1
}

export const naoForneceServico = servico => {
  return ObtemServicos().indexOf(servico) === -1
}
