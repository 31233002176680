const API = '/api/v1'

export const FETCH_ROOMS_REQUEST = 'FETCH_ROOMS_REQUEST'
export const FETCH_ROOMS_SUCCESS = 'FETCH_ROOMS_SUCCESS'
export const FETCH_ROOMS_FAILURE = 'FETCH_ROOMS_FAILURE'


function requestRooms(data) {
  return {
    type: FETCH_ROOMS_REQUEST,
    isFetching: true,
    data
  }
}

function receiveRooms(rooms) {
  return {
    type: FETCH_ROOMS_SUCCESS,
    isFetching: false,
    rooms
  }
}

export function fetchRoomsError(message) {
  return {
    type: FETCH_ROOMS_FAILURE,
    isFetching: false,
    message
  }
}

export function fetchRooms({ account }) {
  const token = window.localStorage.getItem('user_token')
  const configFetch = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  }

  return dispatch => {
    dispatch(requestRooms(account))
    return window.fetch(`${API}/rooms/account/${account.id}`, configFetch)
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error(response)
        }
      })
      .then(response => {
        if (!response.success) {
          dispatch(fetchRoomsError(response.message))
          return Promise.reject(response)
        } else {
          dispatch(receiveRooms(response.data))
          return Promise.resolve(response)
        }
      })
      .catch(err => {
        dispatch(fetchRoomsError(err))
        console.log('Error: ', err)
        return Promise.reject(err)
      })
  }
}
