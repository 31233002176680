import React, { Component } from 'react'

export default class LinkIcon extends Component {
  render() {
    return (
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 37 37" style={{ ...this.props.style, enableBackground: 'new 0 0 37 37'  }}>
        <g>
          <path style={{ fill: '#009688' }} d="M26.4,20.1v8.7c0,1.3-1.1,2.4-2.4,2.4H6.7c-1.3,0-2.4-1.1-2.4-2.4V11.4c0-1.3,1.1-2.4,2.4-2.4h14.5
            c1,0,1.6,1.3,0.8,2l-0.8,0.8c-0.2,0.2-0.6,0.3-0.8,0.3h-13v15.7h15.7v-7.1c0-0.3,0.2-0.6,0.3-0.8l0.8-0.8
            C25.1,18.5,26.4,19,26.4,20.1z M32.6,13.4c0,1.1-1.3,1.6-2,0.8l-1.8-1.8l-12,12c-0.5,0.5-1.2,0.5-1.7,0l-1.1-1.1
            c-0.5-0.5-0.5-1.2,0-1.7l12-12l-1.8-1.8c-0.7-0.7-0.2-2,0.8-2h6.3c0.7,0,1.2,0.5,1.2,1.2V13.4z"/>
        </g>
      </svg>
    )
  }
}
