/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../../../styles/themes'
import { Link } from 'react-router-dom'
/* COMPONENTS */
import Header from '../../../components/Header'
import Button from '../../../components/ui/Button'
import Title from '../../../components/ui/Title'
import Card from '../../../components/ui/Card'
import Icons from '../../../components/ui/Icons'
import Subtitle from '../../../components/ui/Subtitle'
import CheckBox from '../../../components/ui/CheckBox'
import DragnDrop from '../../../components/ui/DragnDrop'
import CircleIcon from '../../../components/ui/CircleIcon'
import Input from '../../../components/ui/Input'
import MoneyInput from '../../../components/ui/MoneyInput'
import Textarea from '../../../components/ui/Textarea'
import BreadCrumb from '../../../components/BreadCrumb'
import Loader from '../../../components/Loader'
import config from '../../../../middleware/config'
import { Get, Post, Put } from '../../../../utils/request'
import CommisedRate from '../Rooms/CommisedRate'
import { formatDate } from '../../../../utils/dates'
import { getNameFromKey } from '../../../../utils/fabTexts'
import formatCurrency from '../../../../utils/money';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #FAFAFA;
  & * { font-family: 'Open Sans', sans-serif }
`

const Main = styled.div`
  width: 80%;
  margin-top: '15px';
  padding: 20px 30px;
  background: #FAFAFA;
  flex: 1 0 auto;
`

const Label = styled.label`
  color: ${props => props.error ? 'red' : (props.edited ? '#F39C12' : '#484848')};
  box-sizing: border-box;
  padding: 5px 5px 5px 0px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
`

const CheckboxIndisponibilidade = styled.input`
  font-size: 16px;
  background-color: #FFF;
  border-radius: 1px;
  cursor: pointer;
  padding: 6px 10px;
  border-bottom: ${props => props.error ? '2px solid red' : `2px solid ${defaultTheme.colors.primary.default}`};
  transition: all 0.2s linear;
  height: 20px;
  width: 20px;
`

const Tag = styled.div`
  padding: 3px 5px;
  font-size: 12px;
  font-family: ${defaultTheme.fonts.primary};
  color: #FAFAFA;
  background-color: ${props => props.edited ? '#F39C12' : (props.contraproposta ? '#8800dd' : '#009688')};
  border: none;
  border-radius: 15px;
  position: absolute;
  right: 10px;
  top: -17px;
  text-transform: uppercase;
  z-index: 9;
`

const Group = styled.div`
`

const BriefingInfo = (props) => <Group style={{ display: 'flex', marginTop: '5px' }}>
  <CircleIcon title={'name'} style={{ margin: '15px 10px' }} marginLeft={props.marginLeft || '-11px'} marginTop={props.marginTop || '-31px'} icon={props.icon} />
  <Group style={{ fontSize: '14px', paddingTop: '5px' }}>
    {props.children}
  </Group>
</Group>

class BriefingOtherServices extends Component {
  constructor() {
    super()
    this.state = {
      isLoading: false,
      briefings: {
        services: {
          gifts: {
            requested: true,
            requests :[]
          },
          printShop: {
            requested: true,
            requests :[]
          },
          additionalServices:{
            requested: true,
            requests :[]
          }
        }
      },
      counterProposals: [],
      services: {
        gifts: {
          requests: []
        },
        printShop:  {
          requests: []
        },
        additionalServices: {
          requests: []
        }
      },
      briefingId: '',
      CommisedOpen: false,
      commission: {},
      availability: true,
      event: { client: {} }
    }
  }

  componentDidMount() {
    const { match: { params: { event } } } = this.props
    if (event) {
      this.fetchBriefing()
      this.fetchEvent()
    }
  }

  fetchEvent () {
    const { api: { base, version, routes: { events, supplier } } } = config
    const { match: { params: { event } } } = this.props
    const endpoint = `${base}${version}${supplier}${events}/${event}`

    Get(endpoint).then(dados => {
      this.setState({ event: dados.event })
    }).catch(err => {
      console.log('Error: ', err)
    })
  }

  fetchBriefing () {
    this.setState({ isLoading: true })
    const { api: { base, version, routes: { events } } } = config
    const { match: { params: { event } } } = this.props
    const endpoint = `${base}${version}${events}/${event}/briefing/otherServices`
    Get(endpoint).then((response) => {
      const { match: { params: { budget } } } = this.props
      if(budget) {
        const newEndpoint = `${base}${version}/budget/other-services/${budget}`
        Get(newEndpoint).then((budgetInfo) => {
          this.setState({
            isLoading: budgetInfo.status === 'REVIEW',
            lastUpdateType: budgetInfo.lastUpdateType,
            services: this.tratarRequests(budgetInfo.services),
            briefings: response,
            createdOtherServices: {},
            briefingId: response.id,
            eventId: response.eventId
          }, () => budgetInfo.status === 'REVIEW' && this.fetchEditDifferences())
        }).catch(err => {
          this.setState({ isLoading: false })
          console.log('Error: ', err)
        })
      }
      else {
        var services = {
          gifts: {
            cheapestTotalPrice: { amount: 0, currency: 'BRL' },
            totalPriceChosen: { amount: 0, currency: 'BRL' },
            requests: response.services.gifts && response.services.gifts.requests.map(item => {
              return {
                hasAvailability: true,
                briefingRequestId: item.id,
                budgets: [
                  {
                    name: '',
                    courtesy: false,
                    files: [],
                    unitPrice: { amount: 0, currency: 'BRL' },
                    description: ''
                  }
                ]
              }
            })
          },
          printShop:  {
            cheapestTotalPrice: { amount: 15000, currency: 'BRL' },
            totalPriceChosen: { amount: 25000, currency: 'BRL' },
            requests: response.services.printShop && response.services.printShop.requests.map(item => {
              return {
                hasAvailability: true,
                briefingRequestId: item.id,
                budgets: [
                  {
                    name: '',
                    courtesy: false,
                    files: [],
                    unitPrice: { amount: 0, currency: 'BRL' },
                    description: ''
                  }
                ]
              }
            })
          },
          additionalServices: {
            cheapestTotalPrice: { amount: 15000, currency: 'BRL' },
            totalPriceChosen: { amount: 25000, currency: 'BRL' },
            requests: response.services.additionalServices && response.services.additionalServices.requests.map(item => {
              return {
                hasAvailability: true,
                briefingRequestId: item.id,
                budgets: [
                  {
                    name: '',
                    courtesy: false,
                    files: [],
                    dates: [],
                    pricePerDay: { amount: 0, currency: 'BRL' },
                    description: ''
                  }
                ]
              }
            })
          }
        }
        this.setState({ isLoading: false, briefings: response, createdOtherServices: {}, services: services, briefingId: response.id, eventId: response.eventId })
      }
    }).catch(err => {
      this.setState({ isLoading: false })
      console.log('Error: ', err)
    })
  }

  tratarRequests = (items) => {
    const services = items
    Object.keys(services).forEach(servico => {
      services[servico].requests.forEach((_requisicao, requisicaoIndex) => {
        if(services[servico].requests[requisicaoIndex].budgets.length === 0) {
          if(servico === 'additionalServices') {
            services[servico].requests[requisicaoIndex].budgets.push({
              name: '',
              courtesy: false,
              files: [],
              pricePerDay: { amount: 0, currency: 'BRL' },
              description: ''
            })
          } else {
            services[servico].requests[requisicaoIndex].budgets.push({
              name: '',
              courtesy: false,
              files: [],
              unitPrice: { amount: 0, currency: 'BRL' },
              description: ''
            })
          }
        }
        services[servico].requests[requisicaoIndex].budgets.forEach((_budget, budgetIndex) => {
          services[servico].requests[requisicaoIndex].budgets[budgetIndex].files = services[servico].requests[requisicaoIndex].budgets[budgetIndex].files.map(item => {
            return {
              url: item,
              file: []
            }
          })
        })
      })
    })
    return services
  }

  submitCommisedRate = ({ commised, reason, value }) => {
    let { commission } = this.state
    commission = {
      fee: {
        amount: value,
        type: 'PERCENTAGE'
      },
      reason: reason,
      hasCommission: commised === 'yes'
    }
    this.setState({
      isLoading: true,
      commission,
      CommisedOpen: false
    }, () => {
      const { api: { base, version } } = config
      const { match: { params: { budget } } } = this.props
      if(this.props.location.state && (this.props.location.state.counterProposal === 'ACCEPTED' || this.props.location.state.counterProposal === 'NEW')) {
        const endpoint = `${base}${version}/budget/other-services/${this.props.location.state.counterProposal === 'ACCEPTED' ? 'counter-proposal' : 'supplier-counter-proposal'}/${budget}`
        Put(endpoint, this.generateJSON())
        .then(() => {
          const { match: { params: { event } } } = this.props
          this.props.history.push(`/budget/resume/${event}`)
        }).catch(err => {
          console.error(err.message)
          this.setState({
            isLoading: false
          })
          alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
        })
      }
      else if(budget) {
        const endpoint = `${base}${version}/budget/other-services/${budget}`
        Put(endpoint, this.generateJSON())
        .then(() => {
          const { match: { params: { event } } } = this.props
          this.props.history.push(`/budget/resume/${event}`)
        }).catch(err => {
          console.error(err.message)
          this.setState({
            isLoading: false
          })
          alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
        })
      } else {
        const endpoint = `${base}${version}/budget/other-services/`
        Post(endpoint, this.generateJSON())
        .then(() => {
          const { match: { params: { event } } } = this.props
          this.props.history.push(`/budget/resume/${event}`)
        }).catch(err => {
          console.error(err.message)
          this.setState({
            isLoading: false
          })
          alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
        })
      }
    })
  }

  generateJSON = () => {
    const { services } = this.state
    services.gifts.requests.map(item => {
      item.budgets = item.hasAvailability ? item.budgets.map(budget => {
        budget.files = budget.files.map(file => file.url)
        if (budget.courtesy) budget.unitPrice.amount = 0
        return budget
      }) : []
      return item
    })
    services.printShop.requests.map(item => {
      item.budgets = item.hasAvailability ? item.budgets.map(budget => {
        budget.files = budget.files.map(file => file.url)
        if (budget.courtesy) budget.unitPrice.amount = 0
        return budget
      }) : []
      return item
    })
    services.additionalServices.requests.map(item => {
      item.budgets = item.hasAvailability ? item.budgets.map(budget => {
        budget.files = budget.files.map(file => file.url)
        if (budget.courtesy) budget.pricePerDay.amount = 0
        return budget
      }) : []
      return item
    })
    return {
      briefingId: this.state.briefingId,
      eventId: this.state.eventId,
      fee: this.state.createdOtherServices.serviceFee,
      commission: this.state.commission,
      counterProposals: this.state.counterProposals,
      services: services
    }
  }

  isValid = () => {
    const itensValid = !(Object.keys(this.state.services).some(item => {
      return this.state.services[item] && this.state.services[item].requests.filter(request => request.hasAvailability).some(request => {
        return request.budgets.some(budget => {
          const budgetPrice = item === 'additionalServices' ? budget.pricePerDay : budget.unitPrice
          return !(budget.courtesy || budgetPrice.amount > 0) || !budget.name
        })
      })
    }))
    return itensValid
  }

  temPeloMenosUm = () => {
    return !this.state.availability || Object.keys(this.state.services).some(item => this.state.services[item] && this.state.services[item].requests.some(request => request.hasAvailability))
  }

  submitForm = () => {
    if(!this.temPeloMenosUm()) {
      return alert('Você deve preencher ao menos um outro serviço para enviar seu orçamento.')
    }
    if(!this.isCounterProposalValid()) {
      return alert('Você aceitou a contraproposta, por favor preencha os campos para que o valor da nova oferta seja menor ou igual a contraproposta solicitada.')
    }
    if(!this.isNewCounterProposalValid()) {
      return alert('Você não pode criar uma contraproposta com o valor acima do valor inicial enviado.')
    }
    else if (this.isValid()) {
      if(this.state.event.supplierChainId) {
        let { commission } = this.state
        commission = {
          fee: {
            amount: 0,
            type: 'PERCENTAGE'
          },
          reason: '',
          hasCommission: false
        }
        this.setState({
          isLoading: true,
          commission,
          CommisedOpen: false
        }, () => {
          const { api: { base, version } } = config
          const { match: { params: { budget } } } = this.props
          if(this.props.location.state && (this.props.location.state.counterProposal === 'ACCEPTED' || this.props.location.state.counterProposal === 'NEW')) {
            const endpoint = `${base}${version}/budget/other-services/${this.props.location.state.counterProposal === 'ACCEPTED' ? 'counter-proposal' : 'supplier-counter-proposal'}/${budget}`
            Put(endpoint, this.generateJSON())
            .then(() => {
              const { match: { params: { event } } } = this.props
              this.props.history.push(`/budget/resume/${event}`)
            }).catch(err => {
              console.error(err.message)
              this.setState({
                isLoading: false
              })
              alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
            })
          }
          else if(budget) {
            const endpoint = `${base}${version}/budget/other-services/${budget}`
            Put(endpoint, this.generateJSON())
            .then(() => {
              const { match: { params: { event } } } = this.props
              this.props.history.push(`/budget/resume/${event}`)
            }).catch(err => {
              console.error(err.message)
              this.setState({
                isLoading: false
              })
              alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
            })
          } else {
            const endpoint = `${base}${version}/budget/other-services/`
            Post(endpoint, this.generateJSON())
            .then(() => {
              const { match: { params: { event } } } = this.props
              this.props.history.push(`/budget/resume/${event}`)
            }).catch(err => {
              console.error(err.message)
              this.setState({
                isLoading: false
              })
              alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
            })
          }
        })
      } else {
        this.setState({ CommisedOpen: true })
      }
    } else {
      alert('Você deve preencher todos os outros serviços selecionados para enviar seu orçamento.')
    }
  }

  uploadDeArquivo = (files, name, briefingId, index) => {
    files.forEach(async file => {
      const form = new FormData()
      form.append('image', file)
      await this.setState({ isLoading: true }, () => {
        const { api: { base, version } } = config
        const token = window.localStorage.getItem('user_token')
        fetch(`${base}${version}/upload`, {
          method: 'POST',
          headers: {
            'Authorization': token
          },
          body: form
        })
        .then((response) => {
          if (response.ok) {
            return response.json()
          } else {
            throw new Error(response)
          }
        })
        .then(response => {
          if (response.success) {
            var state = this.state
            var requestIndex = this.state.services[name].requests.findIndex(item => item.briefingRequestId === briefingId)
            if(requestIndex !== -1) {
              if(!state.services[name].requests[requestIndex].budgets[index].files) {
                state.services[name].requests[requestIndex].budgets[index].files = []
              }
              state.services[name].requests[requestIndex].budgets[index].files.push({
                file: file,
                url: response.data.file.url
              })
            }
            this.setState({ ...state, isLoading: false })
          } else {
            console.error(response)
          }
        })
        .catch(e => {
          this.setState({ isLoading: false })
          console.error(e)
        })
      })
    })
  }

  onFileDialogCancel = (name, briefingId, index) => {
    var state = this.state
    var requestIndex = this.state.services[name].requests.findIndex(item => item.briefingRequestId === briefingId)
    if(requestIndex !== -1) {
      state.services[name].requests[requestIndex].budgets[index].files = []
    }
    this.setState(state)
  }

  removerUpload = (name, briefingId, index, fileIndex) => {
    var state = this.state
    var requestIndex = this.state.services[name].requests.findIndex(item => item.briefingRequestId === briefingId)
    if(requestIndex !== -1) {
      state.services[name].requests[requestIndex].budgets[index].files.splice(fileIndex, 1)
    }
    this.setState(state)
  }

  enableService = (name, briefingId) => {
    var state = this.state
    var requestIndex = this.state.services[name].requests.findIndex(item => item.briefingRequestId === briefingId)
    if(requestIndex !== -1) {
      state.services[name].requests[requestIndex].hasAvailability = !state.services[name].requests[requestIndex].hasAvailability
    }
    this.setState(state)
  }

  handleValue = (name, input, briefingId, index) => {
    var state = this.state
    var requestIndex = this.state.services[name].requests.findIndex(item => item.briefingRequestId === briefingId)
    if(requestIndex !== -1) {
      if(input.target.name === 'unitPrice' || input.target.name === 'pricePerDay') {
        state.services[name].requests[requestIndex].budgets[index][input.target.name].amount = input.target.value
      } else {
        state.services[name].requests[requestIndex].budgets[index][input.target.name] = input.target.value
      }
    }
    this.setState(state)
  }

  ativaCortesia = (name, status, briefingId, index) => {
    var state = this.state
    var requestIndex = this.state.services[name].requests.findIndex(item => item.briefingRequestId === briefingId)
    if(requestIndex !== -1) {
      state.services[name].requests[requestIndex].budgets[index].courtesy = status
    }
    this.setState(state)
  }

  addService = (name, briefingId) => {
    var state = this.state
    var requestIndex = this.state.services[name].requests.findIndex(item => item.briefingRequestId === briefingId)
    if(requestIndex !== -1) {
      if(name === 'additionalServices') {
        state.services[name].requests[requestIndex].budgets.push({
          name: '',
          courtesy: false,
          files: [],
          pricePerDay: { amount: 0, currency: 'BRL' },
          description: ''
        })
      } else {
        state.services[name].requests[requestIndex].budgets.push({
          name: '',
          courtesy: false,
          files: [],
          unitPrice: { amount: 0, currency: 'BRL' },
          description: ''
        })
      }
    }
    this.setState(state)
  }

  removeService = (name, briefingId, budgetIndex) => {
    var state = this.state
    var requestIndex = this.state.services[name].requests.findIndex(item => item.briefingRequestId === briefingId)
    if(requestIndex !== -1) {
      state.services[name].requests[requestIndex].budgets.splice(budgetIndex, 1)
    }
    this.setState(state)
  }

  findService = (briefingId, service) => {
    return this.state.services[service].requests.find(item => item.briefingRequestId === briefingId)
  }

  Item = (session) => {
    const temQueEnviarContraproposta = this.props.location.state && (this.props.location.state.counterProposal === 'ACCEPTED' || this.props.location.state.counterProposal === 'NEW')
    const servico = this.findService(session.briefing.id, session.item)
    return servico && <CheckBox
      label={session.item !== 'additionalServices' ? getNameFromKey(session.item) : session.briefing.name}
      id={session.briefing.id}
      name={session.item}
      checked={servico.hasAvailability}
      value={servico.hasAvailability}
      onClick={() => !temQueEnviarContraproposta && this.enableService(session.item, session.briefing.id)}>
      <Group style={{ padding: '20px', display: 'flex' }}>
        <Card style={{ margin: '0px 10px', width: '30%' }}>
          <BriefingInfo icon={'calendario'}>
            <Label>Nome do item</Label>
            <Label style={{ fontWeight: 'bold', paddingTop: '0px' }}>{session.briefing.name}</Label>
          </BriefingInfo>
          {session.item !== 'additionalServices' && <BriefingInfo icon={'pessoas'}>
            <Label>Quantidade</Label>
            <Label style={{ fontWeight: 'bold', paddingTop: '0px' }}>{session.briefing.quantity}</Label>
          </BriefingInfo>}
          {session.item !== 'additionalServices' && <BriefingInfo icon={'calendario'}>
            <Label>Data de entrega</Label>
            <Label style={{ fontWeight: 'bold', paddingTop: '0px' }}>{formatDate(session.briefing.deliveryDate)}</Label>
          </BriefingInfo>}
          {session.item === 'additionalServices' && <BriefingInfo icon={'calendario'}>
            <Label>Data(s) de entrega</Label>
            <Label style={{ fontWeight: 'bold', paddingTop: '0px' }}>{session.briefing.dates.map((date, index) => ` ${index !== 0 ? ' - ' : ''}${formatDate(date)}`)}</Label>
          </BriefingInfo>}
          {session.briefing.budgetPerUnit && <BriefingInfo icon={'money'}>
            <Label>Budget (por unidade)</Label>
            <Label style={{ fontWeight: 'bold', paddingTop: '0px' }}>{session.briefing.budgetPerUnit.amount > 0 ? formatCurrency(session.briefing.budgetPerUnit.amount) : 'Não definido'}</Label>
          </BriefingInfo>}
          {session.item === 'printShop' && <BriefingInfo icon={'pencil'}>
            <Label>Necessita criação do conteúdo</Label>
            <Label style={{ fontWeight: 'bold', paddingTop: '0px' }}>{session.briefing.hasContentCreation ? 'Sim' : 'Não'}</Label>
          </BriefingInfo>}
          {session.briefing.hoursPerDay > 0 && <BriefingInfo icon={'clock'}>
            <Label>Quantas horas por dia</Label>
            <Label style={{ fontWeight: 'bold', paddingTop: '0px' }}>{session.briefing.hoursPerDay} horas</Label>
          </BriefingInfo>}
          <BriefingInfo icon={'list'}>
            <Label>Descrição do pedido</Label>
            <Label style={{ fontWeight: 'bold', paddingTop: '0px' }}>{!session.briefing.description ? 'Não informada' : session.briefing.description}</Label>
          </BriefingInfo>
        </Card>
        {servico.hasAvailability && <Group style={{ width: '70%' }}>
          <Group style={{ display: 'flex', flexFlow: 'row wrap', alignContent: 'space-between', justifyContent: 'space-between' }}>
            {servico.budgets.map((item, index) => <Card style={{ margin: '0px 10px 10px 10px', width: 'calc((100% / 2) - 20px)' }}>
              {servico.budgets.length > 1 && <Button
                type='submit'
                category={'secondary'}
                fontWeight={800}
                onClick={() => this.removeService(session.item, session.briefing.id, index)}
                style={{ position: 'absolute', right: '0', top: '10px', width: '100px' }}
                ghost
                small
                full>
                Excluir
              </Button>}
              <Label style={{ fontWeight: 'bold', paddingTop: '0px' }}>Opção {index < 9 && '0'}{(index + 1)}</Label>
              <Input
                label={'Nome do item'}
                placeholder={'Digite o nome do item'}
                isRequired
                name={'name'}
                value={item.name}
                error={!item.name}
                onChange={(input) => this.handleValue(session.item, input, session.briefing.id, index)}
              />
              {session.item === 'additionalServices' && <MoneyInput
                label={'Valor (por dia)'}
                placeholder={'0,00'}
                name={`pricePerDay`}
                value={item.pricePerDay.amount}
                error={!item.pricePerDay.amount || item.pricePerDay.amount <= 0}
                isRequired
                ativarCortesia
                statusCortesia={item.courtesy}
                ativouCortesia={(status) => this.ativaCortesia(session.item, status, session.briefing.id, index)}
                onChange={(input) => this.handleValue(session.item, input, session.briefing.id, index)}
              />}
              {session.item !== 'additionalServices' && <MoneyInput
                label={'Valor (por unidade)'}
                placeholder={'0,00'}
                name={`unitPrice`}
                value={item.unitPrice.amount}
                error={!item.unitPrice.amount || item.unitPrice.amount <= 0}
                isRequired
                ativarCortesia
                statusCortesia={item.courtesy}
                ativouCortesia={(status) => this.ativaCortesia(session.item, status, session.briefing.id, index)}
                onChange={(input) => this.handleValue(session.item, input, session.briefing.id, index)}
              />}
              {item.files.length === 0 && <DragnDrop
                accept={'.pdf,.jpeg,.jpg,.png'}
                onDrop={(files) => this.uploadDeArquivo(files, session.item, session.briefing.id, index)}
                onFileDialogCancel={() => this.onFileDialogCancel(session.item, session.briefing.id, index)}
              />}
              {item.files.length > 0 && item.files.map((file, fileIndex) => <Card>
                  <Icons
                    icon={'paperclip'}
                    width={30}
                    height={15}
                    color={defaultTheme.colors.text.darker}
                  />
                  <a href={file.url} target='_blank' rel='noopener noreferrer' style={{ color: defaultTheme.colors.primary.default, fontSize: '15px', paddingLeft: '10px' }}>Anexo {fileIndex < 9 && '0'}{(fileIndex + 1)}</a>
                  <Button
                    type='submit'
                    category={'secondary'}
                    fontWeight={800}
                    onClick={() => this.removerUpload(session.item, session.briefing.id, index, fileIndex)}
                    style={{ position: 'absolute', right: '0', top: '0', width: '100px' }}
                    ghost
                    small
                    full>
                    Excluir
                  </Button>
                </Card>
              )}
              <Textarea
                label={'Observações'}
                placeholder={'Caso tenha alguma observação, escreva aqui.'}
                name={`description`}
                value={item.description}
                style={{ height: '75px' }}
                onChange={(input) => this.handleValue(session.item, input, session.briefing.id, index)}
              />
            </Card>)}
          </Group>
          <Link to='#' onClick={() => this.addService(session.item, session.briefing.id)} style={{ color: defaultTheme.colors.primary.default, fontSize: '15px', marginLeft: '7px' }}>+ adicionar nova opção</Link>
        </Group>}
        {(servico && !servico.hasAvailability) && <Label style={{ width: '70%', margin: 'auto', textAlign: 'center' }}>Não ofereço este serviço.</Label>}
      </Group>
    </CheckBox>
  }

  changeAvailability = () => {
    var { services } = this.state
    if(this.state.availability) {
      Object.keys(services).forEach(service => {
        services[service].hasAvailability = false
      })
    }
    this.setState({ availability: !this.state.availability, services: services })
  }

  isCounterProposalValid = () => {
    if(this.props.location.state && this.props.location.state.counterProposal === 'ACCEPTED') {
      return this.props.location.state.requestedPrice >= this.total()
    } else return true
  }

  isNewCounterProposalValid = () => {
    if(this.props.location.state && this.props.location.state.counterProposal === 'NEW') {
      return this.props.location.state.initialPrice >= this.total()
    } else return true
  }

  total = () => {
    return 0
  }

  render() {
    const { match: { params: { event } } } = this.props
    const temQueEnviarContraproposta = this.props.location.state && (this.props.location.state.counterProposal === 'ACCEPTED' || this.props.location.state.counterProposal === 'NEW')
    return (
      <Container>
        <Header />
        <BreadCrumb options={
          [
            {link: '/', name: 'Home'},
            {link: `/budget/resume/${event}`, name: 'Resumo'},
            {name: 'Outros Serviços'}
          ]
        } />
        <CommisedRate
          isOpen={this.state.CommisedOpen}
          onClose={() => this.setState({ CommisedOpen: false })}
          onSubmit={this.submitCommisedRate}
          client={this.state.event && this.state.event.client}
        />
        <Main>
          {this.state.isLoading ? <Loader /> : null}
          {!this.state.isLoading && <Group style={{width: '80%', margin: 'auto'}}>
            {!temQueEnviarContraproposta && <Group style={{ display: 'flex', position: 'relative' }}>
              <Title style={{ margin: 'auto 0' }}>Outros serviços</Title>
              {this.props.location.state && (this.props.location.state.counterProposal === 'ACCEPTED' || this.props.location.state.counterProposal === 'NEW') && <Tag contraproposta>Contraproposta</Tag>}
              <Label style={{ width: 'fit-content', marginLeft: 'auto' }}>
                Não tenho disponibilidade para este evento
                <CheckboxIndisponibilidade
                  checked={!this.state.availability}
                  name={'Não tenho disponibilidade para este evento'}
                  id={'availability'}
                  style={{ paddingLeft: '5px' }}
                  onChange={this.changeAvailability}
                  type='checkbox' />
              </Label>
            </Group>}
            {
              this.props.location.state && (this.props.location.state.counterProposal === 'ACCEPTED' || this.props.location.state.counterProposal === 'NEW') && <Group style={{ textAlign: 'right', padding: '0px 15px' }}>
                <Subtitle>Valor da contraproposta <span style={{ fontWeight: 'bold' }}>{formatCurrency(this.props.location.state.requestedPrice)}</span>&nbsp;&nbsp;&nbsp;&nbsp;Valor da nova oferta <span style={{ fontWeight: 'bold', color: 'red' }}>{formatCurrency(this.total())}</span></Subtitle>
              </Group>
            }
            <Card style={{ marginLeft: '10px' }}>
              <Label style={{ fontWeight: 'bold', paddingTop: '0px' }}>{this.state.event.name}</Label>
              <Group style={{ display: 'flex', width: '95%', flexFlow: 'row wrap', alignContent: 'space-between', justifyContent: 'space-between' }}>
                <BriefingInfo icon={'map-pin'} marginLeft={'-8px'}>
                  <Label>Local do evento</Label>
                  <Label style={{ fontWeight: 'bold', paddingTop: '0px' }}>{this.state.event.location && this.state.event.location.address} - {this.state.event.location && this.state.event.location.city}</Label>
                </BriefingInfo>
                <BriefingInfo icon={'calendario'}>
                  <Label>Data do evento</Label>
                  <Label style={{ fontWeight: 'bold', paddingTop: '0px' }}>{formatDate(this.state.event.start)} a {formatDate(this.state.event.end)}</Label>
                </BriefingInfo>
                <BriefingInfo icon={'pessoas'}>
                  <Label>Número de participantes</Label>
                  <Label style={{ fontWeight: 'bold', paddingTop: '0px' }}>{this.state.event.numberOfParticipants} pessoas</Label>
                </BriefingInfo>
              </Group>
            </Card>
            {this.state.availability && this.state.briefings.services.gifts && this.state.briefings.services.gifts.requested && this.state.briefings.services.gifts.requests.map(request => this.Item({ item: 'gifts', briefing: request }))}
            {this.state.availability && this.state.briefings.services.printShop && this.state.briefings.services.printShop.requested && this.state.briefings.services.printShop.requests.map(request => this.Item({ item: 'printShop', briefing: request }))}
            {this.state.availability && this.state.briefings.services.additionalServices && this.state.briefings.services.additionalServices.requested && this.state.briefings.services.additionalServices.requests.map(request => this.Item({ item: 'additionalServices', briefing: request }))}
          </Group>}
        </Main>
        <Button
          type='submit'
          category={'secondary'}
          fontWeight={800}
          onClick={this.submitForm}
          style={{ width: '80%'}}
          full>
          Confirmar
        </Button>
      </Container>
    )
  }
}

export default BriefingOtherServices
