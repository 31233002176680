import React from 'react'

const LogoEvnts = (props) => {
  const color = props.color || (props.primary && '#762123')
  const img = window.localStorage.getItem('theme_logo')
  return (
    <div style={props.style}>
      {(!img || img === 'undefined' || img === 'null') ? <svg className='logoEvnts' version='1.1' style={{ fill: color, enableBackground: 'new 0 0 2226.5 691.2', height: '100%' }} id='Layer_1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 2226.5 691.2'>
        <g>
          <path d='M0,588.2V141.7c0-15.8,13-28.8,28.1-28.8h316.2c14.4,0,25.9,11.5,25.9,25.9s-11.5,25.9-25.9,25.9H56.8v172.2H345c14.4,0,25.9,12.2,25.9,25.9c0,14.4-11.5,25.9-25.9,25.9H56.8v176.4h288c14.4,0,25.9,11.5,25.9,25.9s-11.5,25.9-25.9,25.9H28C12.9,617,0,604,0,588.2z' />
          <path d='M656.5,599L462.1,150.4c-2.2-4.3-2.9-7.9-2.9-13c0-14.4,13-28.1,28.8-28.1c14.4,0,24.5,9.4,29.5,21.6l174.3,417.6l175-419.1c5-10.1,15.1-20.2,28.1-20.2c15.8,0,28.1,13,28.1,27.4c0,4.3-0.7,7.9-2.2,11.5L725.6,599c-6.5,14.4-16.6,23.8-33.1,23.8h-2.9C673.1,622.7,662.2,613.4,656.5,599z' />
          <path d='M1018.2,138.8c0-15.1,13-28.1,28.1-28.1h7.9c13,0,20.9,6.5,28.8,16.6l306,390.3V137.4c0-15.1,12.2-28.1,28.1-28.1c15.1,0,27.4,13,27.4,28.1v455.1c0,15.1-10.8,26.6-25.9,26.6h-2.9c-12.2,0-20.9-7.2-29.5-17.3l-312.5-399.6v390.3c0,15.1-12.2,28.1-28.1,28.1c-15.1,0-27.4-13-27.4-28.1V138.8z' />
          <ellipse cx='2073.9' cy='28.7' rx='28.7' ry='28.7' />
          <ellipse cx='1808.4' cy='662.5' rx='28.7' ry='28.7' />
          <path d='M2043.3,339.6c-59.5-23.3-92.1-40.8-104.2-65.8l-25.9,61.6c22.5,18.8,54.9,34.7,97.5,51.5c76.4,29.7,101.8,52.3,101.8,90.5c0,53.7-53.7,92.6-125.2,92.6c-65.4,0-109.1-20.4-147.6-60.4l-23,54.6c44.7,38.1,102.6,56,167,56c105.4,0,187.4-62.9,187.4-149.9C2171.3,406.7,2128.2,372.1,2043.3,339.6z' />
          <path d='M2213.8,167.7c-33.2-29-82.7-56.6-156.3-56.6c-18.4,0-35.8,1.9-52.1,5.3l-26.2,62.2c19.2-10.7,43.9-17.3,73.3-17.3c52.3,0,92.6,17,126.6,49.5c6.4,5,12.7,7.1,19.8,7.1c14.9,0,27.6-11.3,27.6-26.9C2226.5,179.7,2220.8,173.4,2213.8,167.7z' />
          <path d='M1711.6,583.4l57.7-137.2V165.1h118.3l22.2-52.7h-344.7c-14.4,0-26.7,12.3-26.7,26.7s12.3,26,26.7,26h146.5V583.4z' />
        </g>
      </svg> : <img src={img} style={props.style} alt='Logo' />}
    </div>
  )
}

export default LogoEvnts
