import React, { Component } from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../../styles/themes'

const SelectInput = styled.select`
  font-size: 14px;
  font-family: ${defaultTheme.fonts.primary};
  background-color: #FFF;
  border-radius: 1px;
  font-weight: 300;
  color: #767676;
  cursor: pointer;
  width: 100%;
  height: 36px;
  padding: 6px 10px;
  box-sizing: border-box;
  border-top: 1px solid #CCC;
  border-right: 1px solid #CCC;
  border-left: 1px solid #CCC;
  border-bottom: ${props => props.error ? '2px solid red' : `2px solid ${defaultTheme.colors.primary.default}`};
  transition: all 0.2s linear;
`
const Label = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  font-family: ${defaultTheme.fonts.primary};
  box-sizing: border-box;
  padding: 5px 0px;
  display: block;
  font-weight: 300;
  text-align: left;
`

const FormControl = styled.div`
  padding: 10px 10px;
  width: 100%;
`
const Strong = styled.strong`
  color: ${props => props.error ? 'red' : defaultTheme.colors.whiteLabel.primary};
  font-weight: bold;
`
const EditedValue = styled.label`
  box-sizing: border-box;
  color: #767676;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-style: italic;
  font-weight: 300;
  min-height: 25px;
  padding: 10px 0px;
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
`

export default class Select extends Component {
  constructor (props) {
    super(props)
    this.state = {
      value: !props.criticalUpdate ? props.value : '',
      criticalPreviouslyValue: props.criticalPreviouslyValue,
      verificaSeFoiModificado: false
    }
  }

  UNSAFE_componentWillReceiveProps (props) {
    if (props.value !== this.state.criticalPreviouslyValue && !this.state.verificaSeFoiModificado) {
      this.setState({ value: props.value, verificaSeFoiModificado: true })
    } else if (this.state.verificaSeFoiModificado) {
      this.setState({ value: props.value })
    }
  }

  render () {
    const valorCritico = this.props.criticalUpdate && this.props.criticalValues.find(item => item.value === this.state.criticalPreviouslyValue || item.value === !!this.state.criticalPreviouslyValue)
    return (
      <FormControl style={this.props.containerStyle}>
        <Label htmlFor={this.props.id || this.props.name} error={this.props.error}>{this.props.label}
          <Strong error={this.props.error}>{this.props.isRequired ? '*' : ''}</Strong>
        </Label>
        <SelectInput {...this.props} error={this.props.error} id={this.props.id || this.props.name} />
        {this.props.criticalUpdate && <EditedValue htmlFor={this.props.name}>Anterior: {valorCritico ? valorCritico.text : '' }</EditedValue>}
      </FormControl>
    )
  }
}
