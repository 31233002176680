/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../../../../styles/themes'
/* COMPONENTS */
import GerenciaFluxoEdicao from '../../../../components/GerenciaFluxoEdicao'
import Button from '../../../../components/ui/Button'
import CheckBox from '../../../../components/ui/CheckBox'
import InputDatepicker from '../../../../components/InputDatepicker'
import Input from '../../../../components/ui/Input'
import Icons from '../../../../components/ui/Icons'
import Alert from '../../../../components/ui/Alert'
import Textarea from '../../../../components/ui/Textarea'
import Loader from '../../../../components/Loader'
import BreadCrumb from '../../../../components/BreadCrumb'
import config from '../../../../../middleware/config'
import { Post, Get, Put } from '../../../../../utils/request'
import { getDateRange } from '../../../../../utils/dates'
import moment from 'moment'
import { retornaUrlFluxoEdicao, retornaUrlFluxoCriacao } from '../../../../../utils/fluxoCriacaoEvento'

const BtnAdd = styled.span`
	font-family: ${defaultTheme.fonts.secondary};
  padding: 10px;
  margin-bottom: 10px;
  display: block;
  cursor: pointer;
  color: ${defaultTheme.colors.whiteLabel.primary};
  font-size: 14px;
`

const Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  font-family: ${defaultTheme.fonts.secondary};
  padding: 3px 10px 13px 10px;
  color: #484848;
`

const Group = styled.div`
`

const Label = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  box-sizing: border-box;
  padding: 5px 5px 5px 0px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
`

class Lodging extends Component {
  state = {
    singleType: {
      selected: false,
      list: []
    },
    doubleType: {
      selected: false,
      list: []
    },
    tripleType: {
      selected: false,
      list: []
    },
    observations: '',
    itemsOnApartment: false,
    isLoading: false,
    eventStartDate: '',
    eventEndDate: '',
    services: {},
    salvaEdicao: false
  }

  componentDidMount() {
    const { api: { base, version } } = config
    const { id, briefing } = this.props.match.params
    const userType = window.localStorage.getItem('user_type')
    const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
    const newEndpoint = `${base}${version}${eventEndpoint}/${id}`
    this.props.submit(this.submit)
    this.props.checkEdicao(this.checkEdicao)
    Get(newEndpoint)
      .then((response) => {
        this.setState({ isLoading: false })
        if (!response) {
          this.setState({ errorMessage: response.message })
          return Promise.reject(response)
        } else {
          this.setState({ event: response, eventStatus: response.status ,eventStartDate: new Date(response.start), eventEndDate: new Date(response.end), services: response.services }, () => {
            if (briefing) {
              this.fetchBriefing()
            } else {
              this.addList('singleType')
              this.addList('doubleType')
              this.addList('tripleType')
            }
          })
        }
      }).catch(err => {
        this.setState({ isLoading: false })
        console.log('Error: ', err)
      })
  }

  fetchBriefing = () => {
    const { id, briefing } = this.props.match.params
    const { api: { base, version } } = config
    const userType = window.localStorage.getItem('user_type')
    const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
    const endpoint = `${base}${version}${eventEndpoint}/${id}/briefing/lodging/${briefing}`
    Get(endpoint)
      .then((response) => {
        this.setState({ briefing: response })
        var state = this.state
        state.observations = response.observations
        state.itemsOnApartment = response.itemsOnApartment
        response.apartments.forEach(item => {
          var name = ''
          if (item.keyName === 'single') {
            name = 'singleType'
          } else if (item.keyName === 'double' || item.keyName === 'twin') {
            name = 'doubleType'
          } else if (item.keyName === 'triple') {
            name = 'tripleType'
          }
          if (state[name]) {
            state[name].selected = true
            state[name].list.push({
              id: item.id,
              dates: item.dates.map(date => {
                return new Date(date)
              }),
              amountOfApartments: (item.keyName === 'single' || item.keyName === 'triple') ? item.numberOfApartments : 0,
              amountOfApartmentsDouble: item.keyName === 'double' ? item.numberOfApartments : 0,
              amountOfApartmentsTwin: item.keyName === 'twin' ? item.numberOfApartments : 0,
              errorMessage: '',
              errors: {
                dates: '',
                amountOfApartments: '',
                amountOfApartmentsDouble: '',
                amountOfApartmentsTwin: ''
              }
            })
          }
        })
        this.setState(state, () => {
          this.state.singleType.list.length === 0 && this.addList('singleType')
          this.state.doubleType.list.length === 0 && this.addList('doubleType')
          this.state.tripleType.list.length === 0 && this.addList('tripleType')

          // O CÓDIGO ABAIXO ARRUMOU UM BUG NA PÁGINA
          // QUANDO O SINGLE, DOUBLE E TRIPLE SÃO SELECIONADOS NÃO APARECE A DATA
          // DANDO DOIS CLIQUES NO CHECKBOX RESOLVEU O PROBLEMA DE RENDERIZAÇÃO DA PÁGINA
          this.click('singleType')
          this.click('singleType')
        })
      }).catch(err => {
        this.setState({ isLoading: false })
        console.log('Error: ', err)
      })
  }

  click = (session) => {
    const state = this.state
    state[session].selected = !state[session].selected
    this.setState(state)
  }

  itemsOnApartmentClick = () => {
    const state = this.state
    state.itemsOnApartment = !state.itemsOnApartment
    this.setState(state)
  }

  handlerValue = (input, session) => {
    const state = this.state
    const { name, value } = input.target
    state[session][name] = value
    this.setState(state)
    return state[session][name]
  }

  handlerValueList = (input, session, index) => {
    const state = this.state
    const { name, value } = input.target
    state[session].list[index][name] = value
    this.setState(state)
    return state[session].list[index][name]
  }

  handlerValueListDate = (input, session, index) => {
    const state = this.state
    const { name, value } = input.target
    state[session].list[index].dates = getDateRange(new Date(value.from), new Date(value.to))
    this.setState(state)
    return state[session].list[index][name]
  }

  handlerObservations = (input) => {
    const state = this.state
    const { value } = input.target
    state.observations = value
    this.setState(state)
    return state.observations
  }

  addList = (session) => {
    const state = this.state
    state[session].list.push({
      dates: getDateRange(new Date(this.state.eventStartDate), new Date(this.state.eventEndDate)),
      amountOfApartments: 0,
      amountOfApartmentsDouble: 0,
      amountOfApartmentsTwin: 0,
      errorMessage: '',
      errors: {
        dates: '',
        amountOfApartments: '',
        amountOfApartmentsDouble: '',
        amountOfApartmentsTwin: ''
      }
    })
    return this.setState(state)
  }

  submit = (e) => {
    e && e.preventDefault()
    if (!this.state.singleType.selected && !this.state.doubleType.selected && !this.state.tripleType.selected) {
      return alert('Selecione ao menos uma das opções.')
    }
    if (!this.isDatesValid()) {
      return alert('Escolha no minimo dois dias, sendo o primeiro a data de check in e o segundo a data de check out.')
    }
    if (!this.isDatesRangeValid()) {
      return alert('As datas de hospedagem devem ser no máximo 10 dias distantes das datas do evento (antes e/ou depois).')
    }
    if (this.isValid()) {
      this.setState({
        isLoading: true
      })
      const { api: { base, version } } = config
      const { id, briefing } = this.props.match.params
      const userType = window.localStorage.getItem('user_type')
      const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
      const endpoint = `${base}${version}${eventEndpoint}/${id}/briefing/lodging/`
      const briefingEndpoint = `${base}${version}${eventEndpoint}/${id}/briefing/lodging/${briefing}`
      if (briefing) {
        Put(briefingEndpoint, this.generateJSON())
          .then(() => {
            this.props.history.push(retornaUrlFluxoEdicao(this.state.services, id, 'lodging'))
          }).catch(err => {
            console.error(err.message)
            this.setState({
              isLoading: false
            })
            alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
          })

      } else {
        Post(endpoint, this.generateJSON())
          .then(() => {
            const newEndpoint = `${base}${version}${eventEndpoint}/${id}`
            Get(newEndpoint)
              .then((response) => {
                this.setState({ isLoading: false })
                if (!response) {
                  this.setState({ errorMessage: response.message })
                  return Promise.reject(response)
                } else {
                  this.props.history.push(retornaUrlFluxoCriacao(response.services, id, this.state.eventStatus, 'lodging'))
                }
              }).catch(err => {
                this.setState({ isLoading: false })
                console.log('Error: ', err)
              })
          }).catch(err => {
            console.error(err.message)
            this.setState({
              isLoading: false
            })
            alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
          })
      }
    } else {
      !this.isValid() && alert('Preencha todos os campos obrigatórios.')
    }
  }

  generateJSON = () => {
    var apartments = []
    this.state.singleType.selected && this.state.singleType.list.forEach(item => {
      item.amountOfApartments > 0 && apartments.push({
        id: item.id,
        dates: item.dates.map(date => {
          return moment(date, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br').format('YYYY-MM-DD')
        }),
        numberOfApartments: item.amountOfApartments,
        keyName: 'single',
        bedsConfiguration: {
          single: 0,
          double: 1,
          auxiliar: 0
        }
      })
    })
    this.state.doubleType.selected && this.state.doubleType.list.forEach(item => {
      item.amountOfApartmentsDouble > 0 && apartments.push({
        id: item.id,
        dates: item.dates.map(date => {
          return moment(date, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br').format('YYYY-MM-DD')
        }),
        numberOfApartments: item.amountOfApartmentsDouble,
        keyName: 'double',
        bedsConfiguration: {
          single: 0,
          double: 1,
          auxiliar: 0
        }
      })
      item.amountOfApartmentsTwin > 0 && apartments.push({
        id: item.id,
        dates: item.dates.map(date => {
          return moment(date, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br').format('YYYY-MM-DD')
        }),
        numberOfApartments: item.amountOfApartmentsTwin,
        keyName: 'twin',
        bedsConfiguration: {
          single: 2,
          double: 0,
          auxiliar: 0
        }
      })
    })
    this.state.tripleType.selected && this.state.tripleType.list.forEach(item => {
      item.amountOfApartments > 0 && apartments.push({
        id: item.id,
        dates: item.dates.map(date => {
          return moment(date, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br').format('YYYY-MM-DD')
        }),
        numberOfApartments: item.amountOfApartments,
        keyName: 'triple',
        bedsConfiguration: {
          single: 3,
          double: 0,
          auxiliar: 0
        }
      })
    })
    return {
      ...this.state.briefing,
      observations: this.state.observations,
      itemsOnApartment: this.state.itemsOnApartment,
      apartments: apartments
    }
  }

  checkEdicao = (e) => {
    e.preventDefault()
    if (!this.state.singleType.selected && !this.state.doubleType.selected && !this.state.tripleType.selected) {
      return alert('Selecione ao menos uma das opções.')
    }
    if (!this.isDatesValid()) {
      return alert('Escolha no minimo dois dias, sendo o primeiro a data de check in e o segundo a data de check out.')
    }
    if (!this.isDatesRangeValid()) {
      return alert('As datas de hospedagem devem ser no máximo 10 dias distantes das datas do evento (antes e/ou depois).')
    }
    if (this.isValid()) {
      this.setState({ salvaEdicao: true }, () => this.setState({ salvaEdicao: false }))
    } else {
      !this.isValid() && alert('Preencha todos os campos obrigatórios.')
    }
  }

  isValid = () => {
    var isValid = true
    this.clearErrorMessages()

    if (this.state.singleType.selected) {
      var singleTypeState = this.state.singleType
      singleTypeState.list = singleTypeState.list.map(item => {
        if (item.dates.length === 0) {
          isValid = false
          item.errorMessage = 'Preencha uma data.'
          item.errors.dates = true
        }
        if (!(item.amountOfApartments > 0) || item.amountOfApartments === '0') {
          isValid = false
          item.errorMessage = 'Preencha um número de apartamentos.'
          item.errors.amountOfApartments = true
        }
        return item
      })
      this.setState({ singleType: singleTypeState })
    }

    if (this.state.doubleType.selected) {
      var doubleTypeState = this.state.doubleType
      doubleTypeState.list = doubleTypeState.list.map(item => {
        if (item.dates.length === 0) {
          isValid = false
          item.errorMessage = 'Preencha uma data.'
          item.errors.dates = true
        }
        if ((!item.amountOfApartmentsDouble > 0 || item.amountOfApartmentsDouble === '0') && (!item.amountOfApartmentsTwin > 0 || item.amountOfApartmentsTwin === '0')) {
          isValid = false
          item.errorMessage = 'Preencha um número de apartamentos.'
          item.errors.amountOfApartmentsDouble = true
          item.errors.amountOfApartmentsTwin = true
        }
        return item
      })
      this.setState({ doubleType: doubleTypeState })
    }

    if (this.state.tripleType.selected) {
      var tripleTypeState = this.state.tripleType
      tripleTypeState.list = tripleTypeState.list.map(item => {
        if (item.dates.length === 0) {
          isValid = false
          item.errorMessage = 'Preencha uma data.'
          item.errors.dates = true
        }
        if (!item.amountOfApartments > 0 || item.amountOfApartments === '0') {
          isValid = false
          item.errorMessage = 'Preencha um número de apartamentos.'
          item.errors.amountOfApartments = true
        }
        return item
      })
      this.setState({ tripleType: tripleTypeState })
    }
    return isValid
  }

  isDatesValid = () => {
    var isValid = true
    this.clearErrorMessages()

    if (this.state.singleType.selected) {
      var singleTypeState = this.state.singleType
      singleTypeState.list = singleTypeState.list.map(item => {
        if (item.dates.length === 1) {
          isValid = false
          item.errorMessage = 'Escolha no minimo dois dias, sendo o primeiro a data de check in e o segundo a data de check out.'
          item.errors.dates = true
        }
        return item
      })
      this.setState({ singleType: singleTypeState })
    }

    if (this.state.doubleType.selected) {
      var doubleTypeState = this.state.doubleType
      doubleTypeState.list = doubleTypeState.list.map(item => {
        if (item.dates.length === 1) {
          isValid = false
          item.errorMessage = 'Escolha no minimo dois dias, sendo o primeiro a data de check in e o segundo a data de check out.'
          item.errors.dates = true
        }
        return item
      })
      this.setState({ doubleType: doubleTypeState })
    }

    if (this.state.tripleType.selected) {
      var tripleTypeState = this.state.tripleType
      tripleTypeState.list = tripleTypeState.list.map(item => {
        if (item.dates.length === 1) {
          isValid = false
          item.errorMessage = 'Escolha no minimo dois dias, sendo o primeiro a data de check in e o segundo a data de check out.'
          item.errors.dates = true
        }
        return item
      })
      this.setState({ tripleType: tripleTypeState })
    }

    return isValid
  }

  isDatesRangeValid = () => {
    var isValid = true
    this.clearErrorMessages()

    if (this.state.singleType.selected) {
      var singleTypeState = this.state.singleType
      singleTypeState.list = singleTypeState.list.map(item => {
        var date1 = moment(item.dates[0])
        var date2 = moment(item.dates[item.dates.length - 1])
        var eventDate = moment(this.state.eventStartDate)
        var eventEndDate = moment(this.state.eventEndDate)
        if ((date1.diff(eventDate, 'days') < -10) || (date2.diff(eventEndDate, 'days') > 10)) {
          isValid = false
          item.errorMessage = 'As datas de hospedagem devem ser no máximo 10 dias distantes das datas do evento (antes e/ou depois).'
          item.errors.dates = true
        }
        return item
      })
      this.setState({ singleType: singleTypeState })
    }

    if (this.state.doubleType.selected) {
      var doubleTypeState = this.state.doubleType
      doubleTypeState.list = doubleTypeState.list.map(item => {
        var date1 = moment(item.dates[0])
        var date2 = moment(item.dates[item.dates.length - 1])
        var eventDate = moment(this.state.eventStartDate)
        var eventEndDate = moment(this.state.eventEndDate)
        if ((date1.diff(eventDate, 'days') < -10) || (date2.diff(eventEndDate, 'days') > 10)) {
          isValid = false
          item.errorMessage = 'As datas de hospedagem devem ser no máximo 10 dias distantes das datas do evento (antes e/ou depois).'
          item.errors.dates = true
        }
        return item
      })
      this.setState({ doubleType: doubleTypeState })
    }

    if (this.state.tripleType.selected) {
      var tripleTypeState = this.state.tripleType
      tripleTypeState.list = tripleTypeState.list.map(item => {
        var date1 = moment(item.dates[0])
        var date2 = moment(item.dates[item.dates.length - 1])
        var eventDate = moment(this.state.eventStartDate)
        var eventEndDate = moment(this.state.eventEndDate)
        if ((date1.diff(eventDate, 'days') < -10) || (date2.diff(eventEndDate, 'days') > 10)) {
          isValid = false
          item.errorMessage = 'As datas de hospedagem devem ser no máximo 10 dias distantes das datas do evento (antes e/ou depois).'
          item.errors.dates = true
        }
        return item
      })
      this.setState({ tripleType: tripleTypeState })
    }

    return isValid
  }

  clearErrorMessages = () => {
    var state = this.state
    state.singleType.list = state.singleType.list.map(item => {
      item.errorMessage = ''
      item.errors = {
        dates: '',
        amountOfApartments: '',
        amountOfApartmentsDouble: '',
        amountOfApartmentsTwin: ''
      }
      return item
    })
    state.doubleType.list = state.doubleType.list.map(item => {
      item.errorMessage = ''
      item.errors = {
        dates: '',
        amountOfApartments: '',
        amountOfApartmentsDouble: '',
        amountOfApartmentsTwin: ''
      }
      return item
    })
    state.tripleType.list = state.tripleType.list.map(item => {
      item.errorMessage = ''
      item.errors = {
        dates: '',
        amountOfApartments: '',
        amountOfApartmentsDouble: '',
        amountOfApartmentsTwin: ''
      }
      return item
    })
    return this.setState(state)
  }

  render() {
    const { id, briefing } = this.props.match.params
    const listaDeRedirecionamento = JSON.parse(window.localStorage.getItem('redirecionamento_edicao_fluxo'))
    return (<>
      <GerenciaFluxoEdicao pagina={'hospedagem'} eventId={id} briefingId={briefing} form={this.generateJSON()} salvaEdicao={this.state.salvaEdicao} onSubmit={this.submit} />
      {this.state.eventStatus === 'INACTIVE' && <BreadCrumb options={
        [
          { link: '/organizer', name: 'Home' },
          { link: `/organizer/event/${id}`, name: 'Dados gerais do evento' },
          { name: 'Hospedagem' }
        ]
      } />}
      <Group>
        {this.state.isLoading ? <Loader /> : null}
        <Title style={{ marginTop: '20px' }}>Hospedagem</Title>
        <Label style={{ marginTop: '20px' }}>Selecione as opções desejadas</Label>
        {(listaDeRedirecionamento && listaDeRedirecionamento.length > 0) && <Alert type={'edition'} style={{ display: 'flex', marginBottom: '40px' }}>
          <Icons
            icon={'exclamation'}
            color={'white'}
            width={30}
            style={{ margin: 'auto' }}
            height={15} />
            <p style={{ textAlign: 'justify' }}>
              Você alterou a <span style={{ fontWeight: 'bold' }}>data</span> e/ou o <span style={{ fontWeight: 'bold' }}>número de pessoas</span> nos dados de seu evento. Acreditamos que você queira validar os dados abaixo para eventualmente alterar este mesmo aspecto também abaixo.
            </p>
        </Alert>}
        <Group>
          <CheckBox
            onClick={() => this.click('singleType')}
            label="Apto tipo solteiro"
            checked={this.state.singleType.selected}>
            <Group style={{ display: this.state.singleType.selected ? 'block' : 'none' }}>
              {this.state.singleType.list.map((item, index) => {
                return <Group key={index}>
                  <InputDatepicker
                    type={'range'}
                    label={'Período da hospedagem'}
                    placeholder={'Selecione a(s) data(s)'}
                    name={'singleDates'}
                    dates={{ from: item.dates[0], to: item.dates[item.dates.length - 1] }}
                    error={item.errors.dates}
                    onChange={value => this.handlerValueListDate(value, 'singleType', index)}
                    edited={listaDeRedirecionamento.some(item => item === 'start' || item === 'end')}
                    isRequired />
                  <Group style={{ display: 'flex' }}>
                    <Input
                      type={'number'}
                      name={'amountOfApartments'}
                      label={'Número de aptos'}
                      value={item.amountOfApartments}
                      error={item.errors.amountOfApartments}
                      onChange={input => this.handlerValueList(input, 'singleType', index)}
                      edited={listaDeRedirecionamento.some(item => item === 'numberOfParticipants')}
                      width='50%'
                      isRequired />
                    <Group style={{ width: '50%', margin: 'auto', display: 'flex', paddingLeft: '20px', alignItems: 'center' }}>
                      <Icons style={{ width: '30%' }} height='35px' icon='double-bed' />
                      <Label style={{ width: '70%' }}>1 cama de casal</Label>
                    </Group>
                  </Group>
                </Group>
              })}
              <BtnAdd onClick={() => this.addList('singleType')}>
                + Adicionar mais um período de hospedagem
								</BtnAdd>
            </Group>
          </CheckBox>
          <CheckBox
            onClick={() => this.click('doubleType')}
            label="Apto tipo duplo"
            checked={this.state.doubleType.selected}>
            <Group style={{ display: this.state.doubleType.selected ? 'block' : 'none' }}>
              {this.state.doubleType.list.map((item, index) => {
                return <Group key={index}>
                  <InputDatepicker
                    type={'range'}
                    label={'Período da hospedagem'}
                    placeholder={'Selecione a(s) data(s)'}
                    name={'doubleDates'}
                    dates={{ from: item.dates[0], to: item.dates[item.dates.length - 1] }}
                    error={item.errors.dates}
                    onChange={value => this.handlerValueListDate(value, 'doubleType', index)}
                    edited={listaDeRedirecionamento.some(item => item === 'start' || item === 'end')}
                    isRequired />
                  <Group style={{ display: 'flex' }}>
                    <Input
                      type={'number'}
                      name={'amountOfApartmentsDouble'}
                      label={'Número de aptos'}
                      value={item.amountOfApartmentsDouble}
                      error={item.errors.amountOfApartmentsDouble}
                      onChange={input => this.handlerValueList(input, 'doubleType', index)}
                      edited={listaDeRedirecionamento.some(item => item === 'numberOfParticipants')}
                      width='50%'
                      isRequired />
                    <Group style={{ width: '50%', margin: 'auto', display: 'flex', paddingLeft: '20px', alignItems: 'center' }}>
                      <Icons style={{ width: '30%' }} height='35px' icon='double-bed' />
                      <Label style={{ width: '70%' }}>1 cama de casal</Label>
                    </Group>
                  </Group>
                  <Group style={{ display: 'flex' }}>
                    <Input
                      type={'number'}
                      name={'amountOfApartmentsTwin'}
                      label={'Número de aptos'}
                      value={item.amountOfApartmentsTwin}
                      error={item.errors.amountOfApartmentsTwin}
                      onChange={input => this.handlerValueList(input, 'doubleType', index)}
                      edited={listaDeRedirecionamento.some(item => item === 'numberOfParticipants')}
                      width='50%'
                      isRequired />
                    <Group style={{ width: '50%', margin: 'auto', display: 'flex', paddingLeft: '20px', alignItems: 'center' }}>
                      <Icons style={{ width: '15%' }} height='35px' icon='single-bed' />
                      <Icons style={{ width: '15%' }} height='35px' icon='single-bed' />
                      <Label style={{ width: '70%', paddingLeft: '10px' }}>2 camas de solteiro</Label>
                    </Group>
                  </Group>
                </Group>
              })}
              <BtnAdd onClick={() => this.addList('doubleType')}>
                + Adicionar mais um período de hospedagem
								</BtnAdd>
            </Group>
          </CheckBox>
          <CheckBox
            onClick={() => this.click('tripleType')}
            label="Apto tipo triplo"
            checked={this.state.tripleType.selected}>
            <Group style={{ display: this.state.tripleType.selected ? 'block' : 'none' }}>
              {this.state.tripleType.list.map((item, index) => {
                return <Group key={index}>
                  <InputDatepicker
                    type={'range'}
                    label={'Período da hospedagem'}
                    placeholder={'Selecione a(s) data(s)'}
                    name={'tripleDates'}
                    dates={{ from: item.dates[0], to: item.dates[item.dates.length - 1] }}
                    error={item.errors.dates}
                    onChange={value => this.handlerValueListDate(value, 'tripleType', index)}
                    edited={listaDeRedirecionamento.some(item => item === 'start' || item === 'end')}
                    isRequired />
                  <Group style={{ display: 'flex' }}>
                    <Input
                      type={'number'}
                      name={'amountOfApartments'}
                      label={'Número de aptos'}
                      value={item.amountOfApartments}
                      error={item.errors.amountOfApartments}
                      onChange={input => this.handlerValueList(input, 'tripleType', index)}
                      edited={listaDeRedirecionamento.some(item => item === 'numberOfParticipants')}
                      width='50%'
                      isRequired />
                    <Group style={{ width: '50%', margin: 'auto', display: 'flex', paddingLeft: '20px', alignItems: 'center' }}>
                      <Icons style={{ width: '15%' }} height='35px' icon='single-bed' />
                      <Icons style={{ width: '15%' }} height='35px' icon='single-bed' />
                      <Icons style={{ width: '15%' }} height='35px' icon='single-bed' />
                      <Label style={{ width: '55%', paddingLeft: '10px' }}>3 camas de solteiro</Label>
                    </Group>
                  </Group>
                </Group>
              })}
              <BtnAdd onClick={() => this.addList('tripleType')}>
                + Adicionar mais um período de hospedagem
								</BtnAdd>
            </Group>
          </CheckBox>
          <CheckBox
            onClick={this.itemsOnApartmentClick}
            label="Entrega de brindes no apto"
            checked={this.state.itemsOnApartment} />
          <Textarea
            type={'text'}
            label={'Observações'}
            placeholder={'Avise-nos caso precise de early check in ou late check out por exemplo.'}
            name={'observations'}
            value={this.state.observations}
            onChange={this.handlerObservations}
          />
        </Group>
      </Group>
      {window.innerWidth < 600 && <Button
        type='submit'
        category={'secondary'}
        fontWeight={800}
        onClick={this.state.eventStatus === 'INACTIVE' ? this.submit : this.checkEdicao}
        style={{ width: '80%' }}
        full>
        Próximo
        </Button>}
      </>)
    }
}

export default Lodging
