/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
/* UTILS */
import config from '../../../../middleware/config'
import { formatCurrency } from '../../../../utils/money'
import moment from 'moment'
/* COMPONENTS */
import Header from '../../Header'
import Button from '../../../components/ui/Button'
import Loader from '../../../components/Loader'
import Alert from '../../../components/ui/Alert'
import BreadCrumb from '../../../components/BreadCrumb'
import Card from '../../../components/ui/Card'
import MainDesktop from '../../../components/Main'
import Body from '../../../components/Body'
import Subtitle from '../../../components/ui/Subtitle'
import Icons from '../../../components/ui/Icons'
import { defaultTheme } from '../../../styles/themes'
import { formatDate } from '../../../../utils/dates'
import { Get } from '../../../../utils/request'
import { getNameFromKey } from '../../../../utils/fabTexts'
import { verificaSeEhSomenteAEBOuEquipamentosOuTransfer, verificaSeEhSomenteOServicoSolicitado } from '../../../../utils/fluxoCriacaoEvento'
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react'
import { TimelineCustom, TimelineCustomItem } from '../../../components/TimelineCustomizada'

const Tag = styled.div`
  padding: 3px 5px;
  font-size: 12px;
  font-family: ${defaultTheme.fonts.primary};
  color: #FAFAFA;
  background-color: ${props => props.edited ? '#F39C12' : '#009688'};
  border: none;
  border-radius: 15px;
  position: absolute;
  right: 10px;
  top: 10px;
  text-transform: uppercase;
  z-index: 9;
`

const Container = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #fff;
  height: inherit;
  overflow-y: auto;
`

const Main = styled.div`
  width: 100%;
  padding: 20px 1px;
  background: #fff;
  height: inherit;
  overflow-y: auto;
`

const Titulo = styled.h1`
  font-family: "Pluto", sans-serif;
  color: #333;
  line-height: 1.2;
  font-size: 1.3em;
`

const Link = styled.a`
  font-size: 13px;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  color: ${defaultTheme.colors.whiteLabel.primary};
`

const Group = styled.div`
  color: ${defaultTheme.colors.text.darker};
  font-family: ${defaultTheme.fonts.secondary};
  font-size: 15px;
  position: relative;
`

const TitleBold = styled.h1`
  font-family: ${defaultTheme.fonts.secondary};
  color: ${defaultTheme.colors.text.darker};
  font-size: 15px;
`

const Label = styled.p`
  color: ${props => props.error ? 'red' : '#484848'};
  box-sizing: border-box;
  padding: 5px 5px 5px 0px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
`

const FixedDiv = styled.div`
  position: fixed;
  top: 46px;
  bottom: 0;
  width: 25%;
  overflow-y: scroll;
  margin-top: 15px;
`

const TimelineCItem = styled(TimelineCustomItem)`
.timeline {
  &:before {
    top: 20px;
    bottom: 40px;
    left: calc(24% + 4px);
  }
    &.timeline--animate{
    margin: 0;
    padding: 0;
  }
}
`

function formatTime (time) {
  time = time && time.split(':')
  if (!time || (time[0] === '00' && time[1] === '00')) return 'Indefinido'
  return `${time[0]}:${time[1]}h`
}

const MENSAGEM_SEM_FORNECEDOR = "Você precisa selecionar pelo menos um fornecedor."


class Resume extends Component {
  state = {
    isLoading: false,
    services: [],
    servicesData: {},
    event: {
      name: '',
      start: '',
      end: '',
      startTime: '',
      endTime: '',
      numberOfParticipants: '',
      location: {}
    }
  }

  componentDidMount () {
    this.fetchEvent()
    window.localStorage.setItem('redirecionamento_edicao_fluxo', JSON.stringify([]))
    window.localStorage.setItem('redirecionamento_edicao_servico', JSON.stringify(false))
    window.localStorage.setItem('redirecionamento_edicao_salas', JSON.stringify(false))
  }

  verificaSeOEventoPertenceAoConsultor = (consultantId, userId) => {
    const loggedUserId = window.localStorage.getItem('user')
    return consultantId !== userId && loggedUserId === consultantId
  }

  fetchEvent () {
    this.setState({ isLoading: true })
    const token = window.localStorage.getItem('user_token')
    const { api: { base, version } } = config
    const { match: { params } } = this.props
    const userType = window.localStorage.getItem('user_type')
    const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
    const endpoint = `${base}${version}${eventEndpoint}/${params.id}`
    const configFetch = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    }

    window.fetch(endpoint, configFetch)
      .then(response => {
        if (response.status >= 401 && response.status <= 403) window.localStorage.clear()
        if (response.ok) {
          return response.json()
        } else {
          throw new Error(response.statusText)
        }
      }).then((response) => {
        if (!response.success) {
          this.setState({ errorMessage: response.message, isLoading: false })
          return Promise.reject(response)
        } else {
          const { services } = response.data
          const servicesSelected = Object.keys(services).filter(i => {
            return services[i].requested
          })
          var listOfPromises = []
          if (services.lodging.requested && services.lodging.id) {
            let endpoint = `${base}${version}${eventEndpoint}/${params.id}/briefing/lodging/${services.lodging.id}`
            listOfPromises.push(Get(endpoint))
            Get(endpoint)
              .then(response => {
                this.setState({ lodging: response })
              }).catch(err => {
                this.setState({ isLoading: false })
                console.log('Error: ', err)
              })
          } else {
            listOfPromises.push(Promise.resolve(null))
          }
          if (services.room.requested && services.room.id) {
            let endpoint = `${base}${version}${eventEndpoint}/${params.id}/briefing/room/${services.room.id}`
            listOfPromises.push(Get(endpoint))
            Get(endpoint)
              .then(response => {
                this.setState({ room: response })
              }).catch(err => {
                this.setState({ isLoading: false })
                console.log('Error: ', err)
              })
          } else {
            listOfPromises.push(Promise.resolve(null))
          }
          if (services.foodsAndBeverages.requested && services.foodsAndBeverages.id) {
            let endpoint = `${base}${version}${eventEndpoint}/${params.id}/briefing/foodsAndBeverages/${services.foodsAndBeverages.id}`
            listOfPromises.push(Get(endpoint))
            Get(endpoint)
              .then(response => {
                this.setState({ foodsAndBeverages: response })
              }).catch(err => {
                this.setState({ isLoading: false })
                console.log('Error: ', err)
              })
          } else {
            listOfPromises.push(Promise.resolve(null))
          }
          if (services.equipments.requested && services.equipments.id) {
            let endpoint = `${base}${version}${eventEndpoint}/${params.id}/briefing/equipments/${services.equipments.id}`
            listOfPromises.push(Get(endpoint))
            Get(endpoint)
              .then(response => {
                this.setState({ equipments: response })
              }).catch(err => {
                this.setState({ isLoading: false })
                console.log('Error: ', err)
              })
          } else {
            listOfPromises.push(Promise.resolve(null))
          }
          if (services.transfer.requested && services.transfer.id) {
            let endpoint = `${base}${version}${eventEndpoint}/${params.id}/briefing/transfer/${services.transfer.id}`
            listOfPromises.push(Get(endpoint))
            Get(endpoint)
              .then(response => {
                this.setState({ transfer: response })
              }).catch(err => {
                this.setState({ isLoading: false })
                console.log('Error: ', err)
              })
          } else {
            listOfPromises.push(Promise.resolve(null))
          }
          if (services.otherServices.requested && services.otherServices.id) {
            let endpoint = `${base}${version}${eventEndpoint}/${params.id}/briefing/other-services/${services.otherServices.id}`
            listOfPromises.push(Get(endpoint))
            Get(endpoint)
              .then(response => {
                this.setState({ otherServices: response })
              }).catch(err => {
                this.setState({ isLoading: false })
                console.log('Error: ', err)
              })
          } else {
            listOfPromises.push(Promise.resolve(null))
          }
          Promise.all(listOfPromises).then(
            ([lodging, room, foodsAndBeverages, equipments, transfer, otherServices]) => {
              this.setState({
                lodging,
                room,
                foodsAndBeverages,
                equipments,
                transfer,
                otherServices,
                isLoading: false,
                services: servicesSelected,
                servicesData: services,
                event: {
                  name: response.data.name,
                  start: response.data.start,
                  end: response.data.end,
                  startTime: response.data.startTime,
                  endTime: response.data.endTime,
                  numberOfParticipants: response.data.numberOfParticipants,
                  location: response.data.location,
                  status: response.data.status,
                  numberOfChosenSuppliers: response.data.chosenSuppliers ? response.data.chosenSuppliers.length : 0,
                  friendlyId: response.data.internalId || response.data.friendlyId,
                  suppliersDeadline: response.data.suppliersDeadline
                },
                readOnly: this.verificaSeOEventoPertenceAoConsultor(response.data.consultantId, response.data.userId)
              })
            }).catch(err => {
              this.setState({ isLoading: false })
              console.log('Error: ', err)
            })
        }
      }).catch(err => {
        this.setState({ isLoading: false })
        console.log('Error: ', err)
      })
  }

  voltar = () => {
    this.props.history.push('/organizer')
  }

  isValid = () => {
    const services = this.state.servicesData
    return !((services.lodging.requested && !services.lodging.id) ||
      (services.room.requested && !services.room.id) ||
      (services.foodsAndBeverages.requested && !services.foodsAndBeverages.id) ||
      (services.equipments.requested && !services.equipments.id))
  }

  formatMessage = (message) => {
    if (MENSAGEM_SEM_FORNECEDOR.localeCompare(message) === 0) {
      const { match: { params } } = this.props
      return `<div><p> ${message} <a href="/organizer/event/${params.id}/suppliers/select" style="color:white;">Clique aqui.</a></p></div>`
    } else {
      return message
    }
  }

  sendEventActive = () => {
    if (!this.isValid()) {
      return alert('Preencha todos os serviços solicitados para concluir o seu pedido.')
    }
    const send = window.confirm('Tem certeza que deseja concluir o pedido?')
    if (send) {
      this.setState({ isLoading: true })
      const token = window.localStorage.getItem('user_token')
      const { api: { base, version } } = config
      const { match: { params }, history } = this.props
      const userType = window.localStorage.getItem('user_type')
      const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
      const endpoint = `${base}${version}${eventEndpoint}/${params.id}/activate`
      const configFetch = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      }

      window.fetch(endpoint, configFetch)
        .then(response => {
          this.setState({ isLoading: false })
          if (response.status >= 401 && response.status <= 403) window.localStorage.clear()
          if (response.ok) {
            return response.json()
          } else {
            throw new Error(response.statusText)
          }
        }).then((response) => {
          if (!response.success) {
            this.setState({ errorMessage: this.formatMessage(response.message) })
            return Promise.reject(response)
          } else {
            alert(`Orçamento enviado. \nEm breve você começará a receber orçamentos de nossos fornecedores.`)
            history.push('/organizer')
          }
        }).catch(err => {
          console.log('Errors: ', err)
        })
    }
  }

  geraTextoMotoristaTransfer = (type) => {
    switch (type) {
      case 'MONOLINGUE_PT':
        return <Subtitle style={{ color: '#727272', marginBottom: '5px' }}><span style={{ fontWeight: 'bold' }}>Motorista monolíngue</span> (português)</Subtitle>
      case 'BILINGUE_PT_EN':
        return <Subtitle style={{ color: '#727272', marginBottom: '5px' }}><span style={{ fontWeight: 'bold' }}>Motorista bilíngue</span> (português e inglês)</Subtitle>
      case 'BILINGUE_PT_ES':
        return <Subtitle style={{ color: '#727272', marginBottom: '5px' }}><span style={{ fontWeight: 'bold' }}>Motorista bilíngue</span> (português e espanhol)</Subtitle>
      default:
        return null
    }
  }

  geraTextoTipoDeServico = (type, vehicleTime) => {
    switch (type) {
      case 'SHUTTLE':
        return <Subtitle style={{ color: '#727272', marginBottom: '5px' }}><span style={{ fontWeight: 'bold' }}>Tipo de serviço:</span> Shuttle (circular)</Subtitle>
      case 'TRASLADO_SO_IDA':
        return <Subtitle style={{ color: '#727272', marginBottom: '5px' }}><span style={{ fontWeight: 'bold' }}>Tipo de serviço:</span> Traslado só ida</Subtitle>
      case 'TRASLADO_IDA_VOLTA':
        return <Subtitle style={{ color: '#727272', marginBottom: '5px' }}><span style={{ fontWeight: 'bold' }}>Tipo de serviço:</span> Traslado ida e volta</Subtitle>
      case 'VEICULO_DISPOSICAO':
        return <Subtitle style={{ color: '#727272', marginBottom: '5px' }}><span style={{ fontWeight: 'bold' }}>Tipo de serviço:</span> Veículo a disposição - Diária {vehicleTime}h</Subtitle>
      default:
        return null
    }
  }

  geraTextoCoordenadorTransfer = (type) => {
    switch (type) {
      case 'MONOLINGUE_PT':
        return <Subtitle style={{ color: '#727272', marginBottom: '5px' }}><span style={{ fontWeight: 'bold' }}>Coordenador/receptivo monolíngue</span> (português)</Subtitle>
      case 'BILINGUE_PT_EN':
        return <Subtitle style={{ color: '#727272', marginBottom: '5px' }}><span style={{ fontWeight: 'bold' }}>Coordenador/receptivo bilíngue</span> (português e inglês)</Subtitle>
      case 'BILINGUE_PT_ES':
        return <Subtitle style={{ color: '#727272', marginBottom: '5px' }}><span style={{ fontWeight: 'bold' }}>Coordenador/receptivo bilíngue</span> (português e espanhol)</Subtitle>
      default:
        return null
    }
  }

  geraTituloDaPagina = () => {
    if (this.state.event.status === 'INACTIVE')
      return 'Resumo do evento'
    else {
      if (this.state.readOnly)
        return 'Visualizar evento'
      else
        return 'Editar evento'
    }
  }

  gerenciaResponsividade = () => {
    if (window.innerWidth < 600) return this.paginaMobile()
    else return this.paginaDesktop()
  }

  paginaMobile = () => {
    const { match: { params }, history } = this.props
    const { isLoading, services, servicesData, errorMessage, event } = this.state
    const { id } = this.props.match.params
    const lodging = servicesData.lodging && servicesData.lodging.requested && (servicesData.lodging.id ? `/organizer/event/lodging/${id}/${servicesData.lodging.id}` : `/organizer/event/lodging/${id}`)
    const room = servicesData.room && servicesData.room.requested && (servicesData.room.id ? `/organizer/event/rooms/${id}/${servicesData.room.id}` : `/organizer/event/rooms/${id}`)
    const foodsAndBeverages = servicesData.foodsAndBeverages && servicesData.foodsAndBeverages.requested && (servicesData.foodsAndBeverages.id ? `/organizer/event/food-and-beverage/${id}/${servicesData.foodsAndBeverages.id}` : `/organizer/event/food-and-beverage/${id}`)
    const equipments = servicesData.equipments && servicesData.equipments.requested && (servicesData.equipments.id ? `/organizer/event/equipments/${id}/${servicesData.equipments.id}` : `/organizer/event/equipments/${id}`)
    return (
      <Container>
        <Header />
        <BreadCrumb options={
          [
            { link: '/organizer', name: 'Home' },
            event.status === 'INACTIVE' && { link: `/organizer/event/${id}`, name: 'Dados gerais do evento' },
            event.status === 'INACTIVE' && { link: `/organizer/event/services/${id}`, name: 'O que você deseja contratar para este evento' },
            (event.status === 'INACTIVE' && lodging) && { link: lodging, name: 'Hospedagem' },
            (event.status === 'INACTIVE' && room) && { link: room, name: 'Salas' },
            (event.status === 'INACTIVE' && foodsAndBeverages) && { link: foodsAndBeverages, name: 'Alimentos e bebidas' },
            (event.status === 'INACTIVE' && equipments) && { link: equipments, name: 'Equipamentos' },
            event.status === 'INACTIVE' && { link: `/organizer/event/${id}/suppliers/select`, name: 'Local do Evento' },
            { name: 'Resumo do evento' }
          ]
        } />
        {isLoading && <Loader />}
        <Main>
          <Card>
            <Titulo>Resumo do evento</Titulo>
            <Subtitle style={{ color: '#727272' }}>Veja abaixo o resumo dos serviços solicitados.</Subtitle>
            {errorMessage ? <Alert type={'negative'}>{errorMessage}</Alert> : null}
            <Group style={{ display: 'flex' }}>
              <Titulo style={{ fontSize: '15px' }}>Dados do evento</Titulo>
              <Link style={{ marginLeft: 'auto' }} href={`/organizer/event/${params.id}`}>editar dados do evento</Link>
            </Group>
            {!isLoading && <Card style={{ marginTop: '0px' }}>
              <TitleBold>{this.state.event.name}</TitleBold>
              <Group style={{ display: 'flex' }}>
                <Subtitle style={{ color: '#727272' }}>{formatDate(this.state.event.start)} - {formatDate(this.state.event.end)}</Subtitle>
                <Subtitle style={{ color: '#727272', paddingLeft: '10px' }}>|</Subtitle>
                <Icons
                  icon={'clock-o'}
                  color={'gray'}
                  width={40}
                  height={20}
                  style={{ marginRight: '-5px', marginTop: '-2px' }} />
                <Subtitle style={{ color: '#727272' }}>{formatTime(this.state.event.startTime)} - {formatTime(this.state.event.endTime)}</Subtitle>
                <Subtitle style={{ color: '#727272', paddingLeft: '10px' }}>|</Subtitle>
                <Icons
                  icon={'person'}
                  color={'gray'}
                  width={40}
                  height={20}
                  style={{ marginRight: '-5px', marginTop: '-2px' }} />
                <Subtitle style={{ color: '#727272' }}>{this.state.event.numberOfParticipants} participantes</Subtitle>
              </Group>
            </Card>}
            {services.includes('lodging') &&
              <Group style={{ paddingTop: '20px' }}>
                <Group style={{ display: 'flex' }}>
                  <Titulo style={{ fontSize: '15px' }}>Hospedagem</Titulo>
                  <Link style={{ marginLeft: 'auto' }} href={`/organizer/event/lodging/${params.id}/${servicesData.lodging.id ? servicesData.lodging.id : ''}`}>editar hospedagem</Link>
                </Group>
                <Subtitle style={{ color: '#727272', marginBottom: '5px' }}>Observações: {this.state.lodging && this.state.lodging.observations}</Subtitle>
                {
                  this.state.lodging && this.state.lodging.apartments.map(apartment =>
                    <Card style={{ marginTop: '0px' }}>
                      <TitleBold>{apartment.numberOfApartments}x {getNameFromKey(apartment.keyName)}</TitleBold>
                      <Group style={{ display: 'flex' }}>
                        <Subtitle style={{ color: '#727272' }}><span style={{ fontWeight: 'bold' }}>Check in:</span> {formatDate(apartment.dates[0])}</Subtitle>
                        <Subtitle style={{ color: '#727272', paddingLeft: '10px', paddingRight: '10px' }}>|</Subtitle>
                        <Subtitle style={{ color: '#727272' }}><span style={{ fontWeight: 'bold' }}>Check out:</span> {formatDate(apartment.dates[apartment.dates.length - 1])}</Subtitle>
                        <Subtitle style={{ color: '#727272', paddingLeft: '10px', paddingRight: '10px' }}>|</Subtitle>
                        <Subtitle style={{ color: '#727272' }}>{apartment.dates.length - 1} diárias</Subtitle>
                        <Subtitle style={{ color: '#727272', paddingLeft: '10px', paddingRight: '10px' }}>|</Subtitle>
                        {apartment.keyName === 'single' && <Group style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
                          <Icons
                            color={'gray'}
                            width={40}
                            height={20}
                            style={{ marginRight: '-5px', marginTop: '-15px' }} icon='double-bed' />
                          <Subtitle style={{ width: '70%', color: '#727272' }}>1 cama de casal</Subtitle>
                        </Group>}
                        {apartment.keyName === 'double' && <Group style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
                          <Icons
                            color={'gray'}
                            width={40}
                            height={20}
                            style={{ marginRight: '-5px', marginTop: '-15px' }} icon='double-bed' />
                          <Subtitle style={{ width: '70%', color: '#727272' }}>1 cama de casal</Subtitle>
                        </Group>}
                        {apartment.keyName === 'twin' && <Group style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
                          <Icons
                            color={'gray'}
                            width={40}
                            height={20}
                            style={{ marginRight: '-5px', marginTop: '-15px' }} icon='single-bed' />
                          <Icons
                            color={'gray'}
                            width={40}
                            height={20}
                            style={{ marginRight: '-5px', marginTop: '-15px' }} icon='single-bed' />
                          <Subtitle style={{ width: '70%', paddingLeft: '10px', color: '#727272' }}>2 camas de solteiro</Subtitle>
                        </Group>}
                        {apartment.keyName === 'triple' && <Group style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
                          <Icons
                            color={'gray'}
                            width={40}
                            height={20}
                            style={{ marginRight: '-5px', marginTop: '-15px' }} icon='single-bed' />
                          <Icons
                            color={'gray'}
                            width={40}
                            height={20}
                            style={{ marginRight: '-5px', marginTop: '-15px' }} icon='single-bed' />
                          <Icons
                            color={'gray'}
                            width={40}
                            height={20}
                            style={{ marginRight: '-5px', marginTop: '-15px' }} icon='single-bed' />
                          <Subtitle style={{ width: '55%', paddingLeft: '10px', color: '#727272' }}>3 camas de solteiro</Subtitle>
                        </Group>}
                      </Group>
                    </Card>)
                }
              </Group>
            }
            {services.includes('room') &&
              <Group style={{ paddingTop: '20px' }}>
                <Group style={{ display: 'flex' }}>
                  <Titulo style={{ fontSize: '15px' }}>Salas</Titulo>
                  <Link style={{ marginLeft: 'auto' }} href={`/organizer/event/rooms/${params.id}/${servicesData.room.id ? servicesData.room.id : ''}`}>editar salas</Link>
                </Group>
                <Subtitle style={{ color: '#727272', marginBottom: '5px' }}>Observações: {this.state.room && this.state.room.observations}</Subtitle>
                {
                  this.state.room && this.state.room.rooms.map(room =>
                    <Card style={{ marginTop: '0px' }}>
                      <TitleBold>{room.roomFormat}</TitleBold>
                      <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Datas:</span> {formatDate(room.startDate)} - {formatDate(room.endDate)}</Subtitle>
                      <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Montagem:</span> {room.mounting ? 'Sim' : 'Não'}</Subtitle>
                      <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Horário:</span> {formatTime(room.startTime)} - {formatTime(room.endTime)}</Subtitle>
                      <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Formato da sala:</span> {room.roomType} </Subtitle>
                      <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Nº de pessoas:</span> {room.amountOfPeople} pessoas</Subtitle>
                      <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Itens adicionais solicitados:</span> {room.roomItems.join(', ')}.</Subtitle>
                    </Card>)
                }
              </Group>
            }
            {services.includes('foodsAndBeverages') &&
              <Group style={{ paddingTop: '20px' }}>
                <Group style={{ display: 'flex' }}>
                  <Titulo style={{ fontSize: '15px' }}>Alimentos e Bebidas</Titulo>
                  <Link style={{ marginLeft: 'auto' }} href={`/organizer/event/food-and-beverage/${params.id}/${servicesData.foodsAndBeverages.id ? servicesData.foodsAndBeverages.id : ''}`}>editar A&B</Link>
                </Group>
                <Subtitle style={{ color: '#727272', marginBottom: '5px' }}>Observações: {this.state.foodsAndBeverages && this.state.foodsAndBeverages.observations}</Subtitle>
                {
                  this.state.foodsAndBeverages && Object.keys(this.state.foodsAndBeverages).filter(m =>
                    m === 'afternoonCoffeeBreak' ||
                    m === 'brunch' ||
                    m === 'cocktail' ||
                    m === 'dinner' ||
                    m === 'lunch' ||
                    m === 'morningCoffeeBreak' ||
                    m === 'thematicDinner' ||
                    m === 'welcomeCoffee' ||
                    m === 'welcomeDrink' ||
                    m === 'roomService').reduce((items, item) => {
                      var menu = this.state.foodsAndBeverages[item]
                      if (menu.requests.length > 0) {
                        items.push(<Card style={{ marginTop: '0px' }}>
                          <TitleBold>{getNameFromKey(menu.name)}</TitleBold>
                          <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Datas:</span> {formatDate(menu.requests[0].start)} - {formatDate(menu.requests[menu.requests.length - 1].start)}</Subtitle>
                          <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Horário de início:</span> {formatTime(menu.requests[0].startTime)}</Subtitle>
                          <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Nº de pessoas:</span> {menu.requests[0].amountOfPeople} pessoas</Subtitle>
                          {(menu.name === 'lunch' || menu.name === 'dinner') && <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Privativo:</span> {menu.isPrivate ? 'Sim' : 'Não'}</Subtitle>}
                          {(menu.name === 'welcomeDrink' || menu.name === 'lunch' || menu.name === 'dinner' || menu.name === 'thematicDinner' || menu.name === 'cocktail') && <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Pacote de bebida solicitado:</span> {getNameFromKey(menu.requests[0].beverages)}</Subtitle>}
                          {menu.name === 'roomService' && <Group style={{ paddingTop: '10px' }}>
                            {Object.keys(menu).filter(m =>
                              m === 'coffee' ||
                              m === 'juice' ||
                              m === 'milk' ||
                              m === 'soda' ||
                              m === 'tea' ||
                              m === 'water').reduce((roomServiceItems, roomServiceItem) => {
                                var roomServiceSelected = menu[roomServiceItem]
                                if (roomServiceSelected) {
                                  roomServiceItems.push(
                                    <Group style={{ display: 'flex' }}>
                                      <Icons
                                        color={'gray'}
                                        width={40}
                                        height={20}
                                        style={{ marginRight: '-15px', marginLeft: '-15px', marginTop: '-2px' }} icon='checkmark-outline' />
                                      <Subtitle style={{ width: '55%', paddingLeft: '10px', color: '#727272', marginBottom: '0px' }}>{getNameFromKey(roomServiceItem)}</Subtitle>
                                    </Group>)
                                }
                                return roomServiceItems
                              }, [])}
                            {Object.keys(menu.tray).filter(m => menu.tray[m]).map(trayItem => getNameFromKey(trayItem)).length > 0 && <Group style={{ display: 'flex' }}>
                              <Icons
                                color={'gray'}
                                width={40}
                                height={20}
                                style={{ marginRight: '-15px', marginLeft: '-15px', marginTop: '-2px' }} icon='checkmark-outline' />
                              <Subtitle style={{ width: '90%', paddingLeft: '10px', color: '#727272', marginBottom: '0px' }}>Bandeja ({
                                Object.keys(menu.tray).filter(m => menu.tray[m]).map(trayItem => getNameFromKey(trayItem)).join(', ')
                              })</Subtitle>
                            </Group>
                            }

                          </Group>}
                        </Card>)
                      }
                      return items
                    }, [])
                }
              </Group>
            }
            <Group style={{ paddingTop: '20px' }}>
              <Group style={{ display: 'flex' }}>
                <Titulo style={{ fontSize: '15px' }}>Local do evento</Titulo>
                <Link style={{ marginLeft: 'auto' }} href={`/organizer/event/${params.id}/suppliers/select`}>editar local do evento</Link>
              </Group>
              <Card style={{ marginTop: '0px' }}>
                <TitleBold>{this.state.event.location.address}</TitleBold>
                <Subtitle style={{ color: '#727272', marginBottom: '10px' }}><span style={{ fontWeight: 'bold' }}>Raio:</span> {this.state.event.location.radius || '0'}km</Subtitle>
                <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>{this.state.event.numberOfChosenSuppliers || '0'} fornecedor{(this.state.event.numberOfChosenSuppliers || 0) > 1 ? 'es irão' : ' irá'} lhe enviar orçamentos.</span></Subtitle>
              </Card>
            </Group>
            <Button
              onClick={() => history.push(`/organizer/event/services/${params.id}`)}
              type='button'
              category={'secondary'}
              fontWeight={800}
              full
              ghost>
              Adicionar ou remover serviços
          </Button>
            <Button
              type='button'
              category={'secondary'}
              onClick={this.sendEventActive}
              fontWeight={800}
              full>
              Concluir pedido de orçamento
          </Button>
          </Card>
        </Main>
      </Container>
    )
  }

  paginaDesktop = () => {
    const { match: { params } } = this.props
    const { isLoading, services, servicesData, errorMessage, event } = this.state
    const { id } = this.props.match.params
    const lodging = servicesData.lodging && servicesData.lodging.requested && (servicesData.lodging.id ? `/organizer/event/lodging/${id}/${servicesData.lodging.id}` : `/organizer/event/lodging/${id}`)
    const room = servicesData.room && servicesData.room.requested && (servicesData.room.id ? `/organizer/event/rooms/${id}/${servicesData.room.id}` : `/organizer/event/rooms/${id}`)
    const foodsAndBeverages = servicesData.foodsAndBeverages && servicesData.foodsAndBeverages.requested && (servicesData.foodsAndBeverages.id ? `/organizer/event/food-and-beverage/${id}/${servicesData.foodsAndBeverages.id}` : `/organizer/event/food-and-beverage/${id}`)
    const equipments = servicesData.equipments && servicesData.equipments.requested && (servicesData.equipments.id ? `/organizer/event/equipments/${id}/${servicesData.equipments.id}` : `/organizer/event/equipments/${id}`)
    const otherServices = servicesData.otherServices && servicesData.otherServices.requested && (servicesData.otherServices.id ? `/organizer/event/other-services/${id}/${servicesData.otherServices.id}` : `/organizer/event/other-services/${id}`)
    return <>
      <Header />
      <MainDesktop>
        <Body {...this.props} pagina={event.status === 'INACTIVE' ? 'create-evento' : 'editar-evento'} menuLateralDireito={this.menuLateralDireito()}>
          <Container>
            {event.status === 'INACTIVE' && <BreadCrumb options={
              [
                { link: '/organizer', name: 'Home' },
                { link: `/organizer/event/${id}`, name: 'Dados gerais do evento' },
                lodging && { link: lodging, name: 'Hospedagem' },
                room && { link: room, name: 'Salas' },
                foodsAndBeverages && { link: foodsAndBeverages, name: 'Alimentos e bebidas' },
                equipments && { link: equipments, name: 'Equipamentos' },
                otherServices && { link: otherServices, name: 'Outros serviços' },
                { link: `/organizer/event/${id}/suppliers/select`, name: 'Local do Evento' },
                { name: 'Resumo do evento' }
              ]
            } />}
            {isLoading && <Loader />}
            <Main>
              <Titulo>{this.geraTituloDaPagina()}</Titulo>
              <Subtitle style={{ color: '#727272' }}>{event.status === 'INACTIVE' ? 'Veja abaixo o resumo dos serviços solicitados.' : 'Escolha abaixo as categorias que deseja editar em seu evento.'}</Subtitle>
              {((this.state.lodging && this.state.lodging.status === 'REVIEW') ||
                (this.state.room && this.state.room.status === 'REVIEW') ||
                (this.state.foodsAndBeverages && this.state.foodsAndBeverages.status === 'REVIEW') ||
                (this.state.equipments && this.state.equipments.status === 'REVIEW')) && <Alert type={'edition'} style={{ display: 'flex', marginBottom: '40px' }}>
                  <Icons
                    icon={'exclamation'}
                    color={'white'}
                    style={{ margin: 'auto' }}
                    width={30}
                    height={15} />
                  <p style={{ textAlign: 'justify' }}>
                    Verifique todos os serviços que estão em estado de <span style={{ fontWeight: 'bold' }}>revisão</span>. É necessário conferir os dados de cada um deles para receber orçamentos.
                </p>
                </Alert>}
              {errorMessage ? <Alert type={'negative'}> <div dangerouslySetInnerHTML={{ __html: errorMessage }} /></Alert> : null}
              <Group style={{ display: 'flex', paddingTop: '20px' }}>
                <Titulo style={{ fontSize: '15px' }}>Dados do evento</Titulo>
                {!this.state.readOnly && <Link style={{ marginLeft: 'auto' }} href={`/organizer/event/${params.id}`}>editar dados do evento</Link>}
              </Group>
              {!isLoading && <Card style={{ marginTop: '0px', width: '100%' }}>
                <TitleBold>{this.state.event.name}</TitleBold>
                <Group style={{ display: 'flex' }}>
                  <Icons
                    icon={'calendario'}
                    color={'gray'}
                    width={40}
                    height={20}
                    style={{ marginRight: '-5px', marginTop: '-2px' }} />
                  <Subtitle style={{ color: '#727272' }}>{formatDate(this.state.event.start)} - {formatDate(this.state.event.end)}</Subtitle>
                  <Subtitle style={{ color: '#727272', paddingLeft: '10px' }}>|</Subtitle>
                  <Icons
                    icon={'clock-o'}
                    color={'gray'}
                    width={40}
                    height={20}
                    style={{ marginRight: '-5px', marginTop: '-2px' }} />
                  <Subtitle style={{ color: '#727272' }}>{formatTime(this.state.event.startTime)} - {formatTime(this.state.event.endTime)}</Subtitle>
                  <Subtitle style={{ color: '#727272', paddingLeft: '10px' }}>|</Subtitle>
                  <Icons
                    icon={'pessoas'}
                    color={'gray'}
                    width={40}
                    height={20}
                    style={{ marginRight: '-5px', marginTop: '-2px' }} />
                  <Subtitle style={{ color: '#727272' }}>{this.state.event.numberOfParticipants} participantes</Subtitle>
                </Group>
              </Card>}
              {services.includes('lodging') &&
                <Group style={{ paddingTop: '20px' }}>
                  <Group style={{ display: 'flex' }}>
                    <Titulo style={{ fontSize: '15px' }}>Hospedagem</Titulo>
                    {(this.state.lodging && this.state.lodging.status === 'REVIEW') && <Tag style={{ right: '125px', top: '-5px', width: 'fit-content' }} edited={true}>Revisão</Tag>}
                    {!this.state.readOnly && <Link style={{ marginLeft: 'auto' }} href={`/organizer/event/lodging/${params.id}/${servicesData.lodging.id ? servicesData.lodging.id : ''}`}>editar hospedagem</Link>}
                  </Group>
                  <Subtitle style={{ color: '#727272', marginBottom: '5px' }}>Observações: {this.state.lodging && this.state.lodging.observations}</Subtitle>
                  {
                    this.state.lodging && this.state.lodging.apartments.map((apartment, index) =>
                      <Card key={index} style={{ marginTop: '0px', width: '100%' }}>
                        <TitleBold>{apartment.numberOfApartments}x {getNameFromKey(apartment.keyName)}</TitleBold>
                        <Group style={{ display: 'flex', marginTop: '5px' }}>
                          <Subtitle style={{ color: '#727272', width: 'fit-content' }}><span style={{ fontWeight: 'bold' }}>Check in:</span> {formatDate(apartment.dates[0])}</Subtitle>
                          <Subtitle style={{ color: '#727272', width: 'fit-content', paddingLeft: '10px', paddingRight: '10px' }}>|</Subtitle>
                          <Subtitle style={{ color: '#727272', width: 'fit-content' }}><span style={{ fontWeight: 'bold' }}>Check out:</span> {formatDate(apartment.dates[apartment.dates.length - 1])}</Subtitle>
                          <Subtitle style={{ color: '#727272', width: 'fit-content', paddingLeft: '10px', paddingRight: '10px' }}>|</Subtitle>
                          <Subtitle style={{ color: '#727272', width: 'fit-content' }}>
                            <Group style={{ width: 'fit-content', display: 'flex', alignItems: 'center' }}>
                              <Icons
                                icon={'calendario'}
                                color={'gray'}
                                width={40}
                                height={20}
                                style={{ marginRight: '-5px', marginTop: '-2px' }} />
                              <Subtitle style={{ paddingLeft: '10px', color: '#727272', marginBottom: ' 0px' }}>{apartment.dates.length - 1} diárias</Subtitle>
                            </Group>
                          </Subtitle>
                          <Subtitle style={{ color: '#727272', paddingLeft: '10px', paddingRight: '10px' }}>|</Subtitle>
                          {apartment.keyName === 'single' && <Group style={{ width: 'fit-content', display: 'flex', alignItems: 'center' }}>
                            <Icons
                              color={'gray'}
                              width={40}
                              height={20}
                              style={{ marginRight: '-5px', marginTop: '-15px' }} icon='double-bed' />
                            <Subtitle style={{ color: '#727272' }}>1 cama de casal</Subtitle>
                          </Group>}
                          {apartment.keyName === 'double' && <Group style={{ width: 'fit-content', display: 'flex', alignItems: 'center' }}>
                            <Icons
                              color={'gray'}
                              width={40}
                              height={20}
                              style={{ marginRight: '-5px', marginTop: '-15px' }} icon='double-bed' />
                            <Subtitle style={{ color: '#727272' }}>1 cama de casal</Subtitle>
                          </Group>}
                          {apartment.keyName === 'twin' && <Group style={{ width: 'fit-content', display: 'flex', alignItems: 'center' }}>
                            <Icons
                              color={'gray'}
                              width={40}
                              height={20}
                              style={{ marginRight: '-5px', marginTop: '-15px' }} icon='single-bed' />
                            <Icons
                              color={'gray'}
                              width={40}
                              height={20}
                              style={{ marginRight: '-5px', marginTop: '-15px' }} icon='single-bed' />
                            <Subtitle style={{ paddingLeft: '10px', color: '#727272' }}>2 camas de solteiro</Subtitle>
                          </Group>}
                          {apartment.keyName === 'triple' && <Group style={{ width: 'fit-content', display: 'flex', alignItems: 'center' }}>
                            <Icons
                              color={'gray'}
                              width={40}
                              height={20}
                              style={{ marginRight: '-5px', marginTop: '-15px' }} icon='single-bed' />
                            <Icons
                              color={'gray'}
                              width={40}
                              height={20}
                              style={{ marginRight: '-5px', marginTop: '-15px' }} icon='single-bed' />
                            <Icons
                              color={'gray'}
                              width={40}
                              height={20}
                              style={{ marginRight: '-5px', marginTop: '-15px' }} icon='single-bed' />
                            <Subtitle style={{ paddingLeft: '10px', color: '#727272' }}>3 camas de solteiro</Subtitle>
                          </Group>}
                        </Group>
                      </Card>)
                  }
                </Group>
              }
              {services.includes('room') &&
                <Group style={{ paddingTop: '20px' }}>
                  <Group style={{ display: 'flex' }}>
                    <Titulo style={{ fontSize: '15px' }}>Salas</Titulo>
                    {(this.state.room && this.state.room.status === 'REVIEW') && <Tag style={{ right: '75px', top: '-5px', width: 'fit-content' }} edited={true}>Revisão</Tag>}
                    {!this.state.readOnly && <Link style={{ marginLeft: 'auto' }} href={`/organizer/event/rooms/${params.id}/${servicesData.room.id ? servicesData.room.id : ''}`}>editar salas</Link>}
                  </Group>
                  <Subtitle style={{ color: '#727272', marginBottom: '5px' }}>Observações: {this.state.room && this.state.room.observations}</Subtitle>
                  {
                    this.state.room && this.state.room.rooms.map((room, index) =>
                      <Card key={index} style={{ marginTop: '0px', width: '100%' }}>
                        <TitleBold>{room.roomFormat}</TitleBold>
                        <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Datas:</span> {formatDate(room.startDate)} - {formatDate(room.endDate)}</Subtitle>
                        <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Montagem:</span> {room.mounting ? 'Sim' : 'Não'}</Subtitle>
                        <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Horário:</span> {formatTime(room.startTime)} - {formatTime(room.endTime)}</Subtitle>
                        <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Formato da sala:</span> {room.roomType} </Subtitle>
                        <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Nº de pessoas:</span> {room.amountOfPeople} pessoas</Subtitle>
                        <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Itens adicionais solicitados:</span> {room.roomItems.join(', ')}.</Subtitle>
                      </Card>)
                  }
                </Group>
              }
              {services.includes('foodsAndBeverages') &&
                <Group style={{ paddingTop: '20px' }}>
                  <Group style={{ display: 'flex' }}>
                    <Titulo style={{ fontSize: '15px' }}>Alimentos e Bebidas</Titulo>
                    {(this.state.foodsAndBeverages && this.state.foodsAndBeverages.status === 'REVIEW') && <Tag style={{ right: '75px', top: '-5px', width: 'fit-content' }} edited={true}>Revisão</Tag>}
                    {!this.state.readOnly && <Link style={{ marginLeft: 'auto' }} href={`/organizer/event/food-and-beverage/${params.id}/${servicesData.foodsAndBeverages.id ? servicesData.foodsAndBeverages.id : ''}`}>editar A&B</Link>}
                  </Group>
                  <Subtitle style={{ color: '#727272', marginBottom: '5px' }}>Observações: {this.state.foodsAndBeverages && this.state.foodsAndBeverages.observations}</Subtitle>
                  {
                    this.state.foodsAndBeverages && Object.keys(this.state.foodsAndBeverages).filter(m =>
                      m === 'afternoonCoffeeBreak' ||
                      m === 'brunch' ||
                      m === 'cocktail' ||
                      m === 'dinner' ||
                      m === 'lunch' ||
                      m === 'morningCoffeeBreak' ||
                      m === 'thematicDinner' ||
                      m === 'welcomeCoffee' ||
                      m === 'welcomeDrink' ||
                      m === 'roomService').reduce((items, item) => {
                        var menu = this.state.foodsAndBeverages[item]
                        if (menu.requests.length > 0) {
                          items.push(<Card key={menu.name} style={{ marginTop: '0px', width: '100%' }}>
                            <TitleBold>{getNameFromKey(menu.name)}</TitleBold>
                            <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Datas:</span> {formatDate(menu.requests[0].start)} - {formatDate(menu.requests[menu.requests.length - 1].start)}</Subtitle>
                            <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Horário de início:</span> {formatTime(menu.requests[0].startTime)}</Subtitle>
                            <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Nº de pessoas:</span> {menu.requests[0].amountOfPeople} pessoas</Subtitle>
                            {(menu.name === 'lunch' || menu.name === 'dinner') && <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Privativo:</span> {menu.isPrivate ? 'Sim' : 'Não'}</Subtitle>}
                            {(menu.name === 'welcomeDrink' || menu.name === 'lunch' || menu.name === 'dinner' || menu.name === 'thematicDinner' || menu.name === 'cocktail') && <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>Pacote de bebida solicitado:</span> {getNameFromKey(menu.requests[0].beverages)}</Subtitle>}
                            {menu.name === 'roomService' && <Group style={{ paddingTop: '10px' }}>
                              {Object.keys(menu).filter(m =>
                                m === 'coffee' ||
                                m === 'juice' ||
                                m === 'milk' ||
                                m === 'soda' ||
                                m === 'tea' ||
                                m === 'water').reduce((roomServiceItems, roomServiceItem) => {
                                  var roomServiceSelected = menu[roomServiceItem]
                                  if (roomServiceSelected) {
                                    roomServiceItems.push(
                                      <Group key={roomServiceItem} style={{ display: 'flex' }}>
                                        <Icons
                                          color={'gray'}
                                          width={40}
                                          height={20}
                                          style={{ marginRight: '-15px', marginLeft: '-15px', marginTop: '-2px' }} icon='checkmark-outline' />
                                        <Subtitle style={{ width: '55%', paddingLeft: '10px', color: '#727272', marginBottom: '0px' }}>{getNameFromKey(roomServiceItem)}</Subtitle>
                                      </Group>)
                                  }
                                  return roomServiceItems
                                }, [])}
                              {Object.keys(menu.tray).filter(m => menu.tray[m]).map(trayItem => getNameFromKey(trayItem)).length > 0 && <Group style={{ display: 'flex' }}>
                                <Icons
                                  color={'gray'}
                                  width={40}
                                  height={20}
                                  style={{ marginRight: '-15px', marginLeft: '-15px', marginTop: '-2px' }} icon='checkmark-outline' />
                                <Subtitle style={{ width: '90%', paddingLeft: '10px', color: '#727272', marginBottom: '0px' }}>Bandeja ({
                                  Object.keys(menu.tray).filter(m => menu.tray[m]).map(trayItem => getNameFromKey(trayItem)).join(', ')
                                })</Subtitle>
                              </Group>
                              }

                            </Group>}
                          </Card>)
                        }
                        return items
                      }, [])
                  }
                </Group>
              }
              {services.includes('equipments') &&
                <Group style={{ paddingTop: '20px' }}>
                  <Group style={{ display: 'flex' }}>
                    <Titulo style={{ fontSize: '15px' }}>Equipamentos</Titulo>
                    {(this.state.equipments && this.state.equipments.status === 'REVIEW') && <Tag style={{ right: '130px', top: '-5px', width: 'fit-content' }} edited={true}>Revisão</Tag>}
                    {!this.state.readOnly && <Link style={{ marginLeft: 'auto' }} href={`/organizer/event/equipments/${params.id}/${servicesData.equipments.id ? servicesData.equipments.id : ''}`}>editar equipamentos</Link>}
                  </Group>
                  {
                    this.state.equipments && Object.keys(this.state.equipments).filter(equipmentItem => this.state.equipments[equipmentItem] && this.state.equipments[equipmentItem].requested).map(equipmentItem =>
                      <Card key={equipmentItem} style={{ marginTop: '0px', width: '100%' }}>
                        <TitleBold>{equipmentItem !== 'internet' ? getNameFromKey(equipmentItem) : 'Internet'}</TitleBold>
                        {this.state.equipments[equipmentItem].requests.map((item, index) => {
                          let singular = equipmentItem !== 'internet' ? 'unidade' : 'ponto de acesso'
                          let plural = equipmentItem !== 'internet' ? 'unidades' : 'pontos de acesso'
                          return <Group key={index}>
                            <Subtitle style={{ color: '#727272', marginBottom: '5px', marginTop: '10px' }}><span style={{ fontWeight: 'bold' }}>Datas:</span> {item.dates.map(data => formatDate(data)).join(' - ')}{item.amount > 0 ? ` | ${item.amount} ${item.amount > 1 ? plural : singular}` : ''}</Subtitle>
                            {item.numberOfLapel > 0 && <Subtitle style={{ color: '#727272', marginBottom: '5px' }}><span style={{ fontWeight: 'bold' }}>Quantidade de Lapelas: </span>{item.numberOfLapel} {item.numberOfLapel > 1 ? plural : singular}</Subtitle>}
                            {item.numberOfGooseneck > 0 && <Subtitle style={{ color: '#727272', marginBottom: '5px' }}><span style={{ fontWeight: 'bold' }}>Quantidade de Gooseneck: </span>{item.numberOfGooseneck} {item.numberOfGooseneck > 1 ? plural : singular}</Subtitle>}
                            {item.numberOfHeadSet > 0 && <Subtitle style={{ color: '#727272', marginBottom: '5px' }}><span style={{ fontWeight: 'bold' }}>Quantidade de Head Set: </span>{item.numberOfHeadSet} {item.numberOfHeadSet > 1 ? plural : singular}</Subtitle>}
                            {item.numberOfStick > 0 && <Subtitle style={{ color: '#727272', marginBottom: '5px' }}><span style={{ fontWeight: 'bold' }}>Quantidade de Bastão: </span>{item.numberOfStick} {item.numberOfStick > 1 ? plural : singular}</Subtitle>}
                          </Group>
                        })}
                        <Subtitle style={{ color: '#727272', marginBottom: '5px' }}><span style={{ fontWeight: 'bold' }}>Suas observações:</span> {this.state.equipments[equipmentItem].observation}</Subtitle>
                        {this.state.equipments[equipmentItem].type && <Subtitle style={{ color: '#727272', marginBottom: '5px' }}><span style={{ fontWeight: 'bold' }}>Tipo:</span> {getNameFromKey(this.state.equipments[equipmentItem].type)}</Subtitle>}
                        {this.state.equipments[equipmentItem].languages && <Subtitle style={{ color: '#727272', marginBottom: '5px' }}><span style={{ fontWeight: 'bold' }}>Linguagem:</span> {getNameFromKey(this.state.equipments[equipmentItem].languages)}</Subtitle>}
                        {this.state.equipments[equipmentItem].interpreter && <Subtitle style={{ color: '#727272', marginBottom: '5px' }}><span style={{ fontWeight: 'bold' }}>Necessita interprete.</span></Subtitle>}
                        {this.state.equipments[equipmentItem].mbSpeed && <Subtitle style={{ color: '#727272', marginBottom: '5px' }}><span style={{ fontWeight: 'bold' }}>Velocidade:</span> {this.state.equipments[equipmentItem].mbSpeed}</Subtitle>}
                      </Card>)
                  }
                </Group>
              }
              {services.includes('transfer') &&
                <Group style={{ paddingTop: '20px' }}>
                  <Group style={{ display: 'flex' }}>
                    <Titulo style={{ fontSize: '15px' }}>Transfer</Titulo>
                    {(this.state.transfer && this.state.transfer.status === 'REVIEW') && <Tag style={{ right: '130px', top: '-5px', width: 'fit-content' }} edited={true}>Revisão</Tag>}
                    {!this.state.readOnly && this.state.event.status === 'INACTIVE' && <Link style={{ marginLeft: 'auto' }} href={`/organizer/event/transfer/${params.id}/${servicesData.transfer.id ? servicesData.transfer.id : ''}`}>editar transfer</Link>}
                  </Group>
                  {
                    this.state.transfer && this.state.transfer.requests.map((transferItem, index) =>
                      <Card key={index} style={{ marginTop: '0px', width: '100%' }}>
                        <TitleBold>{transferItem.name}</TitleBold>
                        {this.geraTextoTipoDeServico(transferItem.type, transferItem.vehicleTime)}
                        <Subtitle style={{ color: '#727272', marginBottom: '5px' }}><span style={{ fontWeight: 'bold' }}>Número de pessoas:</span> {transferItem.passengers}</Subtitle>
                        <Subtitle style={{ color: '#727272', marginBottom: '5px' }}><span style={{ fontWeight: 'bold' }}>Haverá bagagem:</span> {transferItem.luggage ? 'Sim' : 'Não'}</Subtitle>
                        {this.geraTextoMotoristaTransfer(transferItem.driverLanguage)}
                        {transferItem.coordinatorLanguage && this.geraTextoCoordenadorTransfer(transferItem.coordinatorLanguage)}
                        <Subtitle style={{ color: '#727272', marginBottom: '5px' }}><span style={{ fontWeight: 'bold' }}>Necessita serviço de bordo:</span> {transferItem.hasOnboardServices ? 'Sim' : 'Não'}</Subtitle>
                        <TimelineCustom>
                          <Group style={{ display: 'flex' }}>
                            <Label style={{ color: '#727272', width: '25%', marginLeft: '12px' }}><span style={{ fontWeight: 'bold' }}>Data</span></Label>
                            <Label style={{ color: '#727272', width: '75%', marginLeft: '25px' }}><span style={{ fontWeight: 'bold' }}>Local</span></Label>
                          </Group>
                          <Timeline lineColor={'#ddd'}>
                            <TimelineCItem>
                              <TimelineItem
                                key="origem"
                                bodyContainerStyle={{ marginBottom: '-20px' }}
                                dateComponent={(
                                  <div
                                    style={{
                                      display: 'block',
                                      float: 'left',
                                      color: '#727272',
                                    }}
                                  >
                                    <Subtitle style={{ color: '#727272', marginBottom: '5px' }}>{formatDate(transferItem.originDate)}</Subtitle>
                                  </div>
                                )}
                              >
                                <Subtitle style={{ color: '#727272', marginBottom: '5px' }}>{transferItem.origin.address} ({transferItem.origin.address.city})</Subtitle>
                              </TimelineItem>
                            </TimelineCItem>
                            {transferItem.stops.map((stop, index) => (
                              <TimelineCItem>
                                <TimelineItem
                                  key={index}
                                  bodyContainerStyle={{ marginBottom: '-20px' }}
                                  dateComponent={<></>}
                                >
                                  <Subtitle style={{ color: '#727272', marginBottom: '5px' }}>{stop.address}</Subtitle>
                                </TimelineItem>
                              </TimelineCItem>
                            ))}
                            <TimelineCItem>
                              <TimelineItem
                                key="destino"
                                bodyContainerStyle={{ marginBottom: '-20px' }}
                                style={{ color: '#727272' }}
                                dateComponent={(
                                  <div
                                    style={{
                                      display: 'block',
                                      float: 'left',
                                      color: '#727272',
                                    }}
                                  >
                                    <Subtitle style={{ color: '#727272', marginBottom: '5px' }}>{formatDate(transferItem.destinationDate)}</Subtitle>
                                  </div>
                                )}
                              >
                                <Subtitle style={{ color: '#727272', marginBottom: '5px' }}>{transferItem.destination.address} ({transferItem.destination.city})</Subtitle>
                              </TimelineItem>
                            </TimelineCItem>
                          </Timeline>
                        </TimelineCustom>
                      </Card>)
                  }
                </Group>
              }
              {services.includes('otherServices') &&
                <Group style={{ paddingTop: '20px' }}>
                  <Group style={{ display: 'flex' }}>
                    <Titulo style={{ fontSize: '15px' }}>Outros serviços</Titulo>
                    {(this.state.otherServices && this.state.otherServices.status === 'REVIEW') && <Tag style={{ right: '130px', top: '-5px', width: 'fit-content' }} edited={true}>Revisão</Tag>}
                    {!this.state.readOnly && this.state.event.status === 'INACTIVE' && <Link style={{ marginLeft: 'auto' }} href={`/organizer/event/other-services/${params.id}/${servicesData.otherServices.id ? servicesData.otherServices.id : ''}`}>editar outros serviços</Link>}
                  </Group>
                  {this.state.otherServices && this.state.otherServices.services && Object.keys(this.state.otherServices.services).filter(servico => this.state.otherServices.services[servico] && this.state.otherServices.services[servico].requested).map(servico =>
                    this.state.otherServices.services[servico].requests.map((item, index) => {
                      return <Card key={servico} style={{ marginTop: '0px', width: '100%' }}>
                        {servico !== 'additionalServices' && <TitleBold>{getNameFromKey(servico)} ({item.name})</TitleBold>}
                        {servico === 'additionalServices' && <TitleBold>{item.name} (serviço adicional)</TitleBold>}
                        <Group style={{ display: 'flex', marginTop: '5px' }} key={index}>
                          <Icons
                            icon={'calendario'}
                            color={'gray'}
                            width={40}
                            height={20}
                            style={{ marginRight: '-5px', marginTop: '3px', marginLeft: '-13px' }} />
                          {servico === 'gifts' && <Subtitle style={{ color: '#727272', marginBottom: '5px', marginTop: '5px' }}>{formatDate(item.deliveryDate)} | {item.quantity} unidade{item.quantity > 1 ? 's' : ''} | Budget: {formatCurrency(item.budgetPerUnit.amount)}</Subtitle>}
                          {servico === 'printShop' && <Subtitle style={{ color: '#727272', marginBottom: '5px', marginTop: '5px' }}>{formatDate(item.deliveryDate)} | {`${item.hasContentCreation ? ' Necessita de criação de conteúdo | ' : ''}`} {item.quantity} unidade{item.quantity > 1 ? 's' : ''}</Subtitle>}
                          {servico === 'additionalServices' && <Subtitle style={{ color: '#727272', marginBottom: '5px', marginTop: '5px' }}>{item.dates.map(data => formatDate(data)).join(' - ')} | Horas por dia: {item.hoursPerDay}</Subtitle>}
                        </Group>
                        {item.description && <Subtitle style={{ color: '#727272', marginBottom: '5px', marginTop: '10px' }}><span style={{ fontWeight: 'bold' }}><span style={{ fontWeight: 'bold' }}>Observações: {item.description}</span></span></Subtitle>}
                      </Card>
                      })
                    )
                  }
                </Group>
              }
              {!verificaSeEhSomenteOServicoSolicitado(this.state.servicesData, 'otherServices') && !verificaSeEhSomenteAEBOuEquipamentosOuTransfer(this.state.servicesData) && <Group style={{ paddingTop: '20px' }}>
                <Group style={{ display: 'flex' }}>
                  <Titulo style={{ fontSize: '15px' }}>Local do evento</Titulo>
                  {!this.state.readOnly && <Link style={{ marginLeft: 'auto' }} href={event.status === 'INACTIVE' ? `/organizer/event/${params.id}/suppliers/select` : `/organizer/event/${params.id}/more-suppliers/select`}>editar local do evento</Link>}
                </Group>
                <Card style={{ marginTop: '0px', width: '100%' }}>
                  <TitleBold>{this.state.event.location.address}</TitleBold>
                  <Subtitle style={{ color: '#727272', marginBottom: '10px' }}><span style={{ fontWeight: 'bold' }}>Raio:</span> {this.state.event.location.radius || '0'}km</Subtitle>
                  <Subtitle style={{ color: '#727272', marginBottom: '0px' }}><span style={{ fontWeight: 'bold' }}>{this.state.event.numberOfChosenSuppliers || '0'} fornecedor{(this.state.event.numberOfChosenSuppliers || 0) > 1 ? 'es irão' : ' irá'} lhe enviar orçamentos.</span></Subtitle>
                  {this.state.event.suppliersDeadline && <Subtitle style={{ color: '#727272', marginBottom: '0px', marginTop: '20px' }}>
                    Os fornecedores terão até dia&nbsp;
                  <span style={{ fontWeight: 'bold' }}>
                      {moment(this.state.event.suppliersDeadline).format('DD/MM/YYYY')}
                    </span>
                    &nbsp;às&nbsp;
                  <span style={{ fontWeight: 'bold' }}>
                      {moment(this.state.event.suppliersDeadline, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br').format('HH')}h
                    {moment(this.state.event.suppliersDeadline, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br').format('mm')}min
                  </span>.
                </Subtitle>}
                </Card>
              </Group>}
            </Main>
          </Container>
        </Body>
      </MainDesktop>
    </>
  }

  menuLateralDireito = () => {
    return <FixedDiv>
      <Card style={{ width: '100%' }}>
        <Label style={{ textTransform: 'uppercase' }}>Resumo do Evento <span style={{ float: 'right', paddingRight: '10px' }}>ID:{this.state.event.friendlyId}</span></Label>
        <Group>
          <Label style={{ fontWeight: 600, color: '#484848' }}>{this.state.event.name}</Label>
          <Group style={{ display: 'flex' }}>
            <Icons
              icon={'calendario'}
              color={'gray'}
              width={40}
              height={20}
              style={{ marginRight: '-5px', marginTop: '5px' }} />
            <Label>{formatDate(this.state.event.start)} - {formatDate(this.state.event.end)}</Label>
          </Group>
          <Group style={{ display: 'flex' }}>
            <Icons
              icon={'map-pin'}
              color={'gray'}
              width={40}
              height={20}
              style={{ marginRight: '-5px', marginTop: '5px', marginLeft: '2px' }} />
            <Label>{this.state.event.location.address} - {this.state.event.location.city}</Label>
          </Group>
          <Group style={{ display: 'flex' }}>
            <Icons
              icon={'pessoas'}
              color={'gray'}
              width={40}
              height={20}
              style={{ marginTop: '5px', marginLeft: '-3px' }} />
            <Label>{this.state.event.numberOfParticipants} pessoas</Label>
          </Group>
        </Group>
      </Card>
      {this.state.event.status === 'INACTIVE' && <Button
        type='button'
        category={'secondary'}
        onClick={this.sendEventActive}
        fontWeight={800}
        full>
        Solicitar orçamentos
      </Button>}
      {this.state.event.status !== 'INACTIVE' && <Button
        type='button'
        category={'secondary'}
        onClick={this.voltar}
        fontWeight={800}
        ghost
        full>
        Voltar
      </Button>}
    </FixedDiv>
  }

  render () {
    return this.gerenciaResponsividade()
  }
}

export default Resume
