/* LIBS */
import React, { Component } from 'react'
import Pdf from "react-to-pdf"
import Modal from '../../../components/Modal'
import Button from '../../../components/ui/Button'
import { formatCurrency } from '../../../../utils/money'
import { defaultTheme } from '../../../styles/themes'
import styled from 'styled-components'

const Title = styled.h1`
  font-family: ${defaultTheme.fonts.secondary};
  line-height: 1.2;
  font-size: 1.3em;
  padding-top: 10px;
`
const Group = styled.div`
  padding: 20px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  @media(max-width: 768px) {
    font-size: 12px;
    padding: 0;
  }
`
const Label = styled(Title)`
  font-family: ${defaultTheme.fonts.primary};
  padding: 5px;
  font-size: 16px;
  white-space: nowrap;
    @media(max-width: 768px) {
      font-size: 12px;
    }
`
const Tabela = styled.table`
  /* border-bottom: 1px solid #c3c3c3; */
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  td {
    border-bottom: 1px solid #c3c3c3;
    vertical-align:middle;
    padding: 10px 10px;
    @media(max-width: 1024px) {
     padding: 5px 5px;
    }
  }
  tr:nth-child(even) { background: #fafafa; }
  tr:hover { background: #f3f6f9; }
`

const Td = styled.td`
  text-align: ${(props) => props.alinhamento ? 'left' : 'center'};
  font-weight: ${props => props.alinhamento ? 400 : 800};
`

class TabelaComparativaTransfer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: false,
      listaVehicles: [
        {
          type: 'EXECUTIVE_CAR',
          name: 'Carro Executivo ',
        },
        {
          type: 'ARMORED_CAR',
          name: 'Carro Blindado',
        },
        {
          type: 'MINI_VAN',
          name: 'Mini Van'
        },
        {
          type: 'ARMORED_MINI_VAN',
          name: 'Mini Van blindada'
        },
        {
          type: 'VAN',
          name: 'Van'
        },
        {
          type: 'ARMORED_VAN',
          name: 'Van Blindada'
        },
        {
          type: 'MINIBUS',
          name: 'Micro Onibus'
        },
        {
          type: 'BUS',
          name: 'Onibus'
        }
      ],
      pagina: 1,
      convertendoParaPDF: false,
      quantidadePorPagina: 11
    }
    this.ref = React.createRef()
  }

  componentWillReceiveProps (props) {
    this.setState({ ...props },
      () => document.querySelector('body').style.overflow = this.state.isOpen ? 'hidden' : 'auto'
    )
  }

  pegaValorVeiculoDoFornecedor = (fornecedor, type) => {
    const veiculoValor = fornecedor.budgets && fornecedor.budgets.transfer &&
      fornecedor.budgets.transfer[0] && fornecedor.budgets.transfer[0][0] && fornecedor.budgets.transfer[0][0].requests &&
      fornecedor.budgets.transfer[0][0].requests.reduce((maiorValor, request) => {
        if(request.vehicles.find(item => item.type === type) && request.vehicles.find(item => item.type === type).price.amount > maiorValor) {
          maiorValor = request.vehicles.find(item => item.type === type).price.amount
        }
        return maiorValor
      }, 0)
    return veiculoValor
  }

  geraPDFMultiplo = async (fornecedores, toPdf) => {
    if(this.state.pagina <= Math.ceil(fornecedores.length / this.state.quantidadePorPagina)) {
      await toPdf()
      this.setState({
        pagina: this.state.pagina + 1,
        convertendoParaPDF: (this.state.pagina + 1) <= Math.ceil(fornecedores.length / this.state.quantidadePorPagina)
      }, async () => {
        this.geraPDFMultiplo(fornecedores, toPdf)
      })
    }
  }

  render () {
    const fornecedores = !this.state.convertendoParaPDF ?
      this.props.dadosTransfer.filter(fornecedor =>
        fornecedor.services.some(item => item === 'TRANSFER') &&
        fornecedor.budgets.transfer.length > 0) :
      this.props.dadosTransfer.filter(fornecedor =>
        fornecedor.services.some(item => item === 'TRANSFER') &&
        fornecedor.budgets.transfer.length > 0).slice(((this.state.pagina * this.state.quantidadePorPagina) - this.state.quantidadePorPagina), (this.state.pagina * this.state.quantidadePorPagina))
    return (
      <Modal maxWidth={'1000px'} isOpen={this.state.isOpen} hasCloseButton onClose={() => this.props.onClose && this.props.onClose()}>
        <Pdf options={{ orientation: 'landscape' }} targetRef={this.ref} filename="tabela-comparativa-transfer.pdf">
          {({ toPdf }) => <Button
            onClick={() => {
              this.setState({
                convertendoParaPDF: true,
                pagina: 1
              }, async () => {
                this.geraPDFMultiplo(fornecedores, toPdf)
              })
            }}
            category={'secondary'}
            fontWeight={800}
            style={{ width: '150px', position: 'absolute', right: '10px', top: '35px' }}
            small
            full>
            Baixar em PDF
          </Button>}
        </Pdf>
        <Group ref={this.ref} style= {{align: 'center'}}>
          <Title style={{ textAlign: 'left', marginBottom: '30px' }}>Tabela Comparativa de Fornecedores</Title>
          <Tabela>
            <tbody>
              <tr>
                <td></td>
                {fornecedores.map(fornecedor => (
                  <td>
                    <Label style={{ fontWeight: '900' }}>{fornecedor.name}</Label>
                  </td>
                ))}
              </tr>
              {this.state.listaVehicles.map(veiculo => (
                <tr>
                  <Td style={{ textAlign: 'left', fontWeight: 400 }}><Label style={{ fontSize: '14px' }}>{veiculo.name}</Label></Td>
                  {fornecedores.map(fornecedor =>
                    <Td ><Label style={{ fontSize: '14px' }}>{this.pegaValorVeiculoDoFornecedor(fornecedor, veiculo.type) > 0 && formatCurrency(this.pegaValorVeiculoDoFornecedor(fornecedor, veiculo.type))}</Label></Td>
                  )}
                </tr>
              ))}
            </tbody>
          </Tabela>
        </Group>
      </Modal>
    )
  }
}

export default TabelaComparativaTransfer
