import React from 'react'
import styled from 'styled-components'
import Icon from './Icons'

import { defaultTheme } from '../../styles/themes'

const Checkbox = styled.input`
  font-size: 16px;
  background-color: #FFF;
  border-radius: 1px;
  cursor: pointer;
  padding: 6px 10px;
  border-bottom: ${props => props.error ? '2px solid red' : `2px solid ${defaultTheme.colors.primary.default}`};
  transition: all 0.2s linear;
  height: 20px;
  width: 20px;
`

const Label = styled.label`
  align-items: center;
  background-color: #FFF;
  cursor: pointer;
  justify-content: space-between;
  display: flex;
  color: ${props => props.disabled
    ? defaultTheme.colors.grey.light
    : (props.error ? 'red' : defaultTheme.colors.text.darker)};
  font-family: ${defaultTheme.fonts.secondary};
  font-weight: 500;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  border: none;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
`

const FormControl = styled.div`
  padding: 10px;
  width: 100%;
`

const LabelInfo = styled.span`
  display: flex;
  align-items: center;
`

export default function (props) {
  return (
    <FormControl>
      <Label disabled={props.disabled} htmlFor={props.name} error={props.error}>{props.label}
        <LabelInfo>
          {props.icon &&
            <i style={{ minWidth: '45px' }}>
              <Icon
                viewBox={props.viewBox}
                icon={props.icon}
                color={props.disabled
                  ? defaultTheme.colors.grey.light
                  : defaultTheme.colors.text.darker}
                width={props.width}
                height={props.height}
              />
            </i>}
          {props.placeholder}
          {props.tooltip &&
            <i style={{ minWidth: '20px' }}>
              <Icon
                title={props.tooltip}
                icon='question-circle-o'
                color={props.disabled
                  ? defaultTheme.colors.grey.light
                  : defaultTheme.colors.text.darker}
                style={{ marginTop: '2px' }}
                width={30}
                height={18}
              />
            </i>}
        </LabelInfo>
        <Checkbox
          {...props}
          style={{ cursor: props.disabled ? 'not-allowed' : 'pointer' }}
          error={props.error}
          id={props.name}
          type='checkbox'
        />
      </Label>
    </FormControl>
  )
}
