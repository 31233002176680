const isCnpjValid = (cnpj) => {
  cnpj = cnpj.replace(/[^\d]+/g,'')
  // eslint-disable-next-line
  if(cnpj == '') return false
  // eslint-disable-next-line
  if (cnpj.length != 14)
    return false

  // Elimina CNPJs invalidos conhecidos
  // eslint-disable-next-line
  if (cnpj == "00000000000000" ||
      // eslint-disable-next-line
      cnpj == "11111111111111" ||
      // eslint-disable-next-line
      cnpj == "22222222222222" ||
      // eslint-disable-next-line
      cnpj == "33333333333333" ||
      // eslint-disable-next-line
      cnpj == "44444444444444" ||
      // eslint-disable-next-line
      cnpj == "55555555555555" ||
      // eslint-disable-next-line
      cnpj == "66666666666666" ||
      // eslint-disable-next-line
      cnpj == "77777777777777" ||
      // eslint-disable-next-line
      cnpj == "88888888888888" ||
      // eslint-disable-next-line
      cnpj == "99999999999999")
    return false

  // Valida DVs
  var tamanho = cnpj.length - 2
  var numeros = cnpj.substring(0,tamanho)
  var digitos = cnpj.substring(tamanho)
  var soma = 0
  var pos = tamanho - 7
  for (var i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2)
          pos = 9
  }
  var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
  // eslint-disable-next-line
  if (resultado != digitos.charAt(0))
      return false

  tamanho = tamanho + 1
  numeros = cnpj.substring(0,tamanho)
  soma = 0
  pos = tamanho - 7
  for (i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2)
          pos = 9
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
  // eslint-disable-next-line
  if (resultado != digitos.charAt(1))
        return false

  return true
}

export { isCnpjValid }
