import React from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../../styles/themes'

const Money = styled.p`
  font-family: ${defaultTheme.fonts.secondary};
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const CurrencySpan = styled.span`
  font-size: 0.635rem;
  font-weight: 100;
  color: ${props => props.color ? props.color : defaultTheme.colors.primary.default};
  margin-right: 5px;
`

const MoneySpan = styled.span`
  font-size: 20px;
  font-weight: 800;
  color: ${defaultTheme.colors.primary.default};
`

// TODO: usar styled components para fazer isso aqui ficar mais legivel
// TODO: chamar a função de formatação aqui dentro

export default function (props) {
  const currency = props.currency || 'R$'
  return (
    <Money style={{...props.style}}>
      {!props.cortesia && <CurrencySpan color={props.style && props.style.color}>{currency}</CurrencySpan>}
      {!props.cortesia && <MoneySpan {...props} />}
      {props.cortesia && <MoneySpan {...props}>CORTESIA</MoneySpan>}
    </Money>
  )
}
