import React, { Component } from 'react'
import { defaultTheme } from '../../styles/themes'
import { Link } from 'react-scroll'
import Button from './Button'
import Icons from './Icons'
import Unread from '../../components/ui/Unread'

export default class ButtonChat extends Component {
  render () {
    return (
      <Link to='anchor' spy smooth duration={500}>
        <Button onClick={this.props.onClick} style={{ fontWeight: '800', margin: '0 0 5px auto' }} ghost small>
          <Icons
            color={defaultTheme.colors.whiteLabel.primary}
            width={20}
            height={20}
            style={{ margin: 'auto 1px' }}
            icon='icon_chat_01'
          />
          {this.props.fornecedor ? 'Falar com o cliente' : 'Falar com o fornecedor'}
          {this.props.showIcon &&
            <Unread
              count={this.props.unreadCount || 0}
              style={{ ...this.props.style }}
            />}
        </Button>
      </Link>
    )
  }
}
