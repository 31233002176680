/* LIBS */
import React from 'react'
import styled from 'styled-components'
/* UTILS */
import { isExpired } from './utils'
/* STYLES */
import { screens } from '../../styles/themes'
/* COMPONENTS */
import EventCard from './EventCard'
import Tabs from '../../components/ui/Tabs'

const Main = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  background-color: #FAFAFA;
  a {
    text-decoration: none;
    width: 100%;
  }
  @media (min-width: ${screens.medium}) {
    background-color: #FAFAFA;
  }
`

const EventEmpty = styled.p`
  padding: 15px;
  font-size: 16px;
`

const wasSent = ({ supplierHasToSendBudgets }) => {
  return !supplierHasToSendBudgets
}

const checkStatus = (event) => {
  if (!event || !Object.keys(event.services)) return ''

  function checkIfHasSomeBudgetWithStatus (event, status) {
    const servicoFoiPedido = (event, service) => !!event.services[service] && event.services[service].requested
    const temOrcamentosEnviados = (event, service) => !!event.services[service].sent
    const temOrcamentosEnviadosComStatus = (event, service, status) => event.services[service].sent.some(item => item.status === status)

    return Object.keys(event.services).some(service =>
      servicoFoiPedido(event, service) &&
      temOrcamentosEnviados(event, service) &&
      temOrcamentosEnviadosComStatus(event, service, status)
    )
  }

  const REVIEW = checkIfHasSomeBudgetWithStatus(event, 'REVIEW')
  const COUNTER_PROPOSAL = checkIfHasSomeBudgetWithStatus(event, 'COUNTER_PROPOSAL')
  return (REVIEW && 'REVIEW') || (COUNTER_PROPOSAL && 'COUNTER_PROPOSAL') || 'ACTIVE'
}

const BudgetList = ({ events }) => {
  const sent = events.filter(event => (event.status !== 'DELETED' && event.status !== 'ORDERED' && wasSent(event) && !event.editable))
  const active = events.filter(event => (event.status !== 'DELETED' && !event.supplierHasBeenChosen && !event.supplierHasBeenRemoved && (event.editable || !wasSent(event) || event.supplierHasToSendCounterProposal) && (!isExpired(event.suppliersDeadline) || event.editable) && !event.supplierHasRefused))
  const expired = events.filter(event => (event.status === 'DELETED' || (event.status === 'ORDERED' && !event.supplierHasBeenChosen) || event.supplierHasBeenRemoved || event.supplierHasRefused || (!wasSent(event) && isExpired(event.suppliersDeadline) && !event.editable)))
  const closed = events.filter(event => (event.status !== 'DELETED' && event.supplierHasBeenChosen))

  const obj = [
    {
      id: 0,
      title: 'aguardando orçamento',
      content: () => {
        return (active.length > 0 ? active.map((evento) => <EventCard {...evento} eventStatus={checkStatus(evento)} status='active' key={evento._id} />)
          : <EventEmpty>Nenhum evento aguardando orçamento neste momento. Aproveite e cadastre (ou atualize) suas salas e cardápios no Menu Superior.</EventEmpty>)
      }
    }, {
      id: 1,
      title: 'eventos perdidos',
      content: () => {
        return (expired.length > 0 ? expired.map((evento) => <EventCard {...evento} deleted={evento.status === 'DELETED'} eventStatus={checkStatus(evento)} status='expired' key={evento._id} />)
          : <EventEmpty>Nenhum evento perdido.</EventEmpty>)
      }
    }, {
      id: 2,
      title: 'eventos fechados',
      content: () => {
        return (closed.length > 0 ? closed.map((evento) => <EventCard {...evento} eventStatus={checkStatus(evento)} status='closed' key={evento._id} />) : <EventEmpty>Nenhum evento fechado.</EventEmpty>)
      }
    }, {
      id: 3,
      title: 'eventos enviados',
      content: () => {
        return (sent.length > 0 ? sent.map((evento) => <EventCard {...evento} eventStatus={checkStatus(evento)} status='sent' key={evento._id} />)
          : <EventEmpty>Nenhum orçamento enviado.</EventEmpty>)
      }
    }
  ]

  return <Main><Tabs tabs={obj} /></Main>
}

export default BudgetList
