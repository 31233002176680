/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
/* UTILS */
import config from '../../../../middleware/config'
import { formatCurrency } from '../../../../utils/money'
import { formatDate } from '../../../../utils/dates'
import { Get, Post } from '../../../../utils/request'
import { temFeatureFlag } from '../../../../utils/tiposUsuario'
import * as moment from 'moment'
/* STYLES */
import { defaultTheme } from '../../../styles/themes'
/* COMPONENTS */
import SingleChat from '../../../components/Chat/SingleChat'
import Header from '../../Header'
import Loader from '../../../components/Loader'
import Main from '../../../components/Main'
import Body from '../../../components/Body'
import Modal from '../../../components/Modal'
import ModalConfirmacao from '../../../components/Aprovacao/ModalConfirmacao'
import ModalFornecedorAprovado from '../../../components/Aprovacao/ModalFornecedorAprovado'
import ItemsMenuLateral from '../../../components/SidebarItems/ItemsMenuLateral'
import Options from '../../../components/ui/Options'
import MoedaIcon from '../../../components/ui/MoedaIcon'
import CheckBox from '../../../components/ui/CheckBox'
import Textarea from '../../../components/ui/Textarea'
import AlertIcon from '../../../components/ui/AlertIcon'
import Card from '../../../components/ui/Card'
import Icons from '../../../components/ui/Icons'
import Button from '../../../components/ui/Button'
import Money from '../../../components/ui/Money'
import Subtitle from '../../../components/ui/Subtitle'
import { getNameFromKey } from '../../../../utils/fabTexts'
import ButtonChat from '../../../components/ui/ButtonChat'

const Title = styled.h1`
  font-family: ${defaultTheme.fonts.secondary};
  font-size: 1.3em;
  line-height: 1.2;
  margin-bottom: 20px;
`

const SubTitulo = styled.h2`
  color: #ccc;
  font-family: ${defaultTheme.fonts.primary};
  line-height: 1.6;
  padding: 10px 10px 0 10px;
`

const Titulo = styled.h1`
  color: #333;
  font-family: ${defaultTheme.fonts.secondary};
  font-size: 1.3em;
  font-weight: 800;
  line-height: 2;
  margin-top: 8px;
`

const Content = styled.div`
  min-height: 90vh;
  padding: 10px 20px;
  font-family: ${defaultTheme.fonts.secondary};
  font-size: 14px;
`

const Group = styled.div`
  padding: 20px 0 0 0;
`

const Label = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  box-sizing: border-box;
  padding: 5px 5px 5px 0px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
`

const LabelBillingConditions = styled.label`
  background-color: #e8e8e8;
  border-radius: 15px;
  box-sizing: border-box;
  color: ${props => props.error ? 'red' : '#484848'};
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 500;
  min-height: 25px;
  padding: 10px;
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
  width: fit-content;
`

const FixedDiv = styled.div`
  position: fixed;
  top: 46px;
  bottom: 0;
  width: 25%;
  overflow-y: scroll;
  margin-top: 15px;
`

const LinkShowDetails = styled.a`
  display: block;
  width: 100%;
  text-align: right;
  font-size: 13px;
  color: ${defaultTheme.colors.grey.medium};
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  color: #ccc;
  text-decoration: underline;
  cursor: pointer;
  padding-left: 10px;
`

class ResumoOtherServices extends Component {
  state = {
    isLoading: true,
    verMais: false,
    verMaisDetalhes : '',
    billingConditions: {
      percentage: 0,
      days: 0
    },
    agreedWithTerms: false,
    abrirContraproposta: false,
    visualizaHistorico: false,
    selected: '',
    toEnd: '',
    lodging: {
      budgets: [],
      budgetId: '',
      includedItems: {}
    },
    room: {
      showItems: [],
      hotel: false,
      budget: false,
      briefing: false,
    },
    foodsAndBeverages: {
      showItems: [],
      budgets: [],
      budgetId: ''
    },
    equipments: {
      showItems: [],
      briefing: {},
      budget: {}
    },
    transfer: {
      showItems: [],
      briefing: {},
      budget: {}
    },
    otherServices: {
      showItems: [],
      briefing: {},
      budget: {}
    },
    chosenBudgets: {
      gifts: [],
      printShop: [],
      additionalServices: []
    },
    event: {},
    hotel: {},
    informacoes: {},
    modalTemCerteza: false,
    modalRecusarStatus: '',
    modalAprovarFornecedor: false,
    modalRecusarFornecedor: false,
    justification: '',
    modalVerificacao: false,
    leuEConcorda: false,
    valoresExtraTooltip: { briefingId: '', vehicleType: '' }
  }

  componentDidMount() {
    this.loadDataServices()
    .then(() => this.loadDataEvent())
    .then(() => this.loadHistory())
  }

  choosedSupplier = () => {
    this.setState({ isLoading: true })
    const { history } = this.props
    const { api: { base, version, routes: { events } } } = config
    const { eventId, accountId } = this.props.match.params
    const services = {
      room: { id: this.state.hotel.budgets.rooms.mostRecent && this.state.hotel.budgets.rooms.mostRecent.id},
      foodsAndBeverages : {id: this.state.hotel.budgets.foodsAndBeverages.mostRecent && this.state.hotel.budgets.foodsAndBeverages.mostRecent.id},
      lodging : {id: this.state.hotel.budgets.lodging.mostRecent && this.state.hotel.budgets.lodging.mostRecent.id}
    }

    const chooseSupplier = `${base}${version}${events}/${eventId}/budget/place`
    Post(chooseSupplier, {services})
      .then(() => {
        this.setState({ isLoading: false })
        if(this.verificaSeTodosOsServicosForamSelecionados()) return history.push(`/organizer${events}/${eventId}/resume/chosen`)
        if(this.verificaSeTodosOsServicosForamSolicitadosParaAprovacao()) return this.setState({isLoading: false, todosSolicitados: true })
        else return history.push(`/organizer${events}/${eventId}/resume/budget/${accountId}`)
      })
      .catch(error => {
        alert(`Falha ao escolher o fornecedor, favor entrar em contato com a gente através do email: corp@evnts.com.br `)
        console.error(`Erro ao tentar escolher o fornecedor: ${error}`)
        this.setState({ isLoading: false })
      })
  }

  submit = () => {
    this.setState({ isLoading: true, modalVerificacao: false })
    const { api: { base, version } } = config
    const { eventId } = this.props.match.params
    const userType = window.localStorage.getItem('user_type')
    const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
    const budgetId = this.state.otherServices.budget.id
    const acceptEndpoint = `${base}${version}/budget/other-services/${budgetId}/accept`
    const finishOrderEndpoint = temFeatureFlag('approval') ? `${base}${version}${eventEndpoint}/${eventId}/approval/add-supplier-to-approval` : `${base}${version}${eventEndpoint}/${eventId}/finishOrderWithSupplierForServices` // Muda o status do evento para ordered
    Post(acceptEndpoint, { chosenBudgets: this.state.chosenBudgets })
      .then(() => {
        Post(finishOrderEndpoint, temFeatureFlag('approval') ? this.generateJSONAprovacao() : this.generateJSON())
          .then(() => {
            this.setState({isLoading: false }, () => {
              if(this.verificaSeTodosOsServicosForamSelecionados()) return this.props.history.push(`/organizer/event/${eventId}/resume/chosen`)
              if(this.verificaSeTodosOsServicosForamSolicitadosParaAprovacao()) return this.setState({isLoading: false, todosSolicitados: true })
              else return this.props.history.push(`/organizer/event/${eventId}/budget/place`)
            })
          })
          .catch((error) => {
            console.error(error)
            alert('Ocorreu um problema ao fechar o pedido. Favor entrar em contato através do email: corp@evnts.com.br')
            this.setState({isLoading: false})
          })
      })
      .catch((error) => {
        console.error(error)
        alert('Ocorreu um problema ao fechar o pedido. Favor entrar em contato através do email: corp@evnts.com.br')
        this.setState({isLoading: false})
      })
  }

  generateJSON = () => {
    const { otherServices } = this.state
    return {
      services: {
        otherServices: otherServices && otherServices.budget && otherServices.budget.id
      }
    }
  }

  generateJSONAprovacao = () => {
    const { accountId } = this.props.match.params
    const { otherServices } = this.state
    return {
      services: {
        otherServices: {
          supplierId: otherServices && otherServices.budget && accountId,
          budgetId: otherServices && otherServices.budget && otherServices.budget.id
        }
      }
    }
  }

  gerenciaResponsividade = () => {
    if(window.innerWidth < 600) return this.paginaDesktop()
    else return this.paginaDesktop()
  }

  getBillingConditions = (budgets) => {
    return Object.keys(budgets).filter(item => budgets[item] && budgets[item].mostRecent).reduce((obj, item) => {
      if(budgets[item].mostRecent.billingConditions) {
        obj = budgets[item].mostRecent.billingConditions
      }
      return obj
    }, false)
  }

  verifaRole = (nivel) => {
    const roles = window.localStorage.getItem('user_roles') !== 'undefined' && JSON.parse(window.localStorage.getItem('user_roles'))
    return roles && roles.find(role => role === nivel)
  }

  ativaModalTemCerteza = () => {
    this.setState({ modalTemCerteza: true })
  }

  aprovarFornecedor = () => {
    this.setState({
      isLoading: true,
      modalTemCerteza: false
    }, () => {
      const { eventId } = this.props.match.params
      const { api: { base, version, routes: { events } } } = config
      const endpoint = `${base}${version}${events}/${eventId}/approval/approve`
      Post(endpoint, this.generateJSONAprovacaoModal())
        .then(() => {
          if(this.verificaSeTodosOsServicosForamAprovados()) return this.setState({isLoading: false, todosAprovados: true })
          else return this.setState({ isLoading: false, modalAprovarFornecedor: true })
        }).catch(err => {
          console.error(err.message)
          this.setState({
            isLoading: false
          })
          alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
        })
    })
  }

  generateJSONAprovacaoModal = () => {
    const { accountId } = this.props.match.params
    const { otherServices } = this.state
    return {
      services: {
        otherServices: otherServices && otherServices.budget && accountId
      }
    }
  }

  recusarFornecedor = () => {
    this.setState({ modalRecusarFornecedor: true })
  }

  confirmarRecusarFornecedor = () => {
    this.setState({
      isLoading: true,
      modalRecusarFornecedor: false
    }, () => {
      const { history } = this.props
      const { eventId } = this.props.match.params
      const { api: { base, version, routes: { events } } } = config
      const endpoint = this.state.modalRecusarStatus === 'REJEITAR' ? `${base}${version}${events}/${eventId}/approval/refuse` : `${base}${version}${events}/${eventId}/approval/review`
      Post(endpoint, this.generateJSONRecusar())
        .then(() => {
          this.state.modalRecusarStatus === 'REJEITAR' ? history.push(`/organizer`) : history.push(`/organizer/event/${eventId}/budget/place`)
        }).catch(err => {
          console.error(err.message)
          this.setState({
            isLoading: false,
            modalRecusarFornecedor: true
          })
          alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
        })
    })
  }

  generateJSONRecusar = () => {
    const { otherServices } = this.state
    return {
      services: {
        otherServices: otherServices && otherServices.budget && this.state.justification
      }
    }
  }

  verificaSeFoiSolicitadoAprovacao = (supplierId) => {
    const { event } = this.state
    return event && event.approval && Object.keys(event.approval.services).some(service => event.approval.services[service].supplierId === supplierId && event.approval.services[service].status === 'AWAITING_APPROVAL')
  }

  verificaSeTodosOsServicosForamSelecionados = () => {
    const lodging = this.state.lodging.requested ? !!this.state.lodging.choosedSupplier : true
    const room = this.state.room.requested ? !!this.state.room.choosedSupplier : true
    const foodsAndBeverages = this.state.foodsAndBeverages.requested ? !!this.state.foodsAndBeverages.choosedSupplier : true
    const equipments = this.state.equipments.requested ? !!this.state.equipments.choosedSupplier : true
    const transfer = this.state.transfer.requested ? !!this.state.transfer.choosedSupplier : true
    return lodging && room && foodsAndBeverages && equipments && transfer
  }

  verificaSeTodosOsServicosForamSolicitadosParaAprovacao = () => {
    const { event } = this.state
    const lodging = this.state.lodging.requested ? (event.approval && event.approval.services.lodging.status === 'AWAITING_APPROVAL') : true
    const room = this.state.room.requested ? (event.approval && event.approval.services.room.status === 'AWAITING_APPROVAL') : true
    const foodsAndBeverages = this.state.foodsAndBeverages.requested ? (event.approval && event.approval.services.foodsAndBeverages.status === 'AWAITING_APPROVAL') : true
    const equipments = this.state.equipments.requested ? (event.approval && event.approval.services.equipments.status === 'AWAITING_APPROVAL') : true
    const transfer = this.state.transfer.requested ? (event.approval && event.approval.services.transfer.status === 'AWAITING_APPROVAL') : true
    return lodging && room && foodsAndBeverages && equipments && transfer
  }

  verificaSeTodosOsServicosForamAprovados = () => {
    const { event } = this.state
    const lodging = this.state.lodging.requested ? (event.approval && event.approval.services.lodging.status === 'AWAITING_REQUEST_CODE') : true
    const room = this.state.room.requested ? (event.approval && event.approval.services.room.status === 'AWAITING_REQUEST_CODE') : true
    const foodsAndBeverages = this.state.foodsAndBeverages.requested ? (event.approval && event.approval.services.foodsAndBeverages.status === 'AWAITING_REQUEST_CODE') : true
    const equipments = this.state.equipments.requested ? (event.approval && event.approval.services.equipments.status === 'AWAITING_REQUEST_CODE') : true
    const transfer = this.state.transfer.requested ? (event.approval && event.approval.services.transfer.status === 'AWAITING_REQUEST_CODE') : true
    return lodging && room && foodsAndBeverages && equipments && transfer
  }

  mostraDescricao = (name) => {
    switch (name) {
      case 'gifts':
        return 'Detalhes dos brindes'
      case 'printShop':
        return 'Detalhes da gráfica'
      case 'additionalServices':
        return 'Observações'
      default:
        return '';
    }
  }

  itemClickCard = (id, name, selected) => {
    const { chosenBudgets, otherServices } = this.state
    const chosenIndex = chosenBudgets[name].findIndex(item => item.briefingRequestId === id)
    if (chosenIndex !== -1) {
      chosenBudgets[name][chosenIndex] = {
        briefingRequestId: id,
        budgetId: selected
      }
    } else {
      chosenBudgets[name].push({
        briefingRequestId: id,
        budgetId: selected
      })
    }
    const requestIndex = otherServices.budget.services[name].requests.findIndex(item => item.briefingRequestId === id)
    const budgetIndex = requestIndex !== -1 && otherServices.budget.services[name].requests[requestIndex].budgets.findIndex(item => item.id === selected)
    if(budgetIndex !== -1 && requestIndex !== -1) {
      otherServices.budget.services[name].requests[requestIndex].budgets = otherServices.budget.services[name].requests[requestIndex].budgets.map(item => {
        item.chosen = false
        return item
      })
      otherServices.budget.services[name].requests[requestIndex].budgets[budgetIndex].chosen = true
    }
    this.setState({ chosenBudgets, otherServices })
  }

  CalculaTotal = () => {
    return this.state.otherServices.briefing.services ? Object.keys(this.state.otherServices.briefing.services).filter(item =>
      this.state.otherServices.briefing.services[item].requested).reduce((total, item) => {
        total += this.state.otherServices.briefing.services[item].requests.reduce((soma, request) => {
          const budgetDoBriefingSelecionado = this.state.otherServices.budget && this.state.otherServices.budget.services[item].requests.find(budgetRequest => budgetRequest.briefingRequestId === request.id)
          const escolhido = budgetDoBriefingSelecionado && budgetDoBriefingSelecionado.budgets.find(budget => budget.chosen)
          soma += (request.quantity ? request.quantity : 1) *
          (escolhido ? (escolhido.unitPrice ? escolhido.unitPrice.amount : (escolhido.pricePerDay.amount * (escolhido.dates ? escolhido.dates.length : 1))) : 0)
          return soma
        }, 0)
        return total
      }, 0) : 0
  }

  CalculaTotalDoServico = (item) => {
    return this.state.otherServices.briefing.services && this.state.otherServices.briefing.services[item].requested && this.state.otherServices.briefing.services[item].requests.reduce((soma, request) => {
      const budgetDoBriefingSelecionado = this.state.otherServices.budget && this.state.otherServices.budget.services[item].requests.find(budgetRequest => budgetRequest.briefingRequestId === request.id)
      const escolhido = budgetDoBriefingSelecionado && budgetDoBriefingSelecionado.budgets.find(budget => budget.chosen)
      soma += (request.quantity ? request.quantity : 1) *
      (escolhido ? (escolhido.unitPrice ? escolhido.unitPrice.amount : escolhido.pricePerDay.amount) : 0)
      return soma
    }, 0)
  }

  Solicitacao = (name, briefing) => {
    return <Card style={{ padding: '10px', width: '100%', marginTop: '30px' }}>
      <Group style={{ padding: '10px' }}>
        <Title style={{ fontSize: '15px', marginBottom: '0px' }}>{getNameFromKey(name)}</Title>
        <label style={{ position: 'absolute', right: '5px', top: '10px' }}>
          <Money>{formatCurrency(briefing.requests.reduce((soma, request) => {
            const budgetDoBriefingSelecionado = this.state.otherServices.budget && this.state.otherServices.budget.services[name].requests.find(budgetRequest => budgetRequest.briefingRequestId === request.id)
            const escolhido = budgetDoBriefingSelecionado && budgetDoBriefingSelecionado.budgets.find(budget => budget.chosen)
            soma += request.quantity *
            (escolhido ? escolhido.unitPrice.amount : 0)
            return soma
          }, 0), false)}</Money>
        </label>
        <Label style={{ fontSize: '12px', listStyle: 'none', position: 'absolute', right: '2px', top: '25px', textAlign: 'right' }}>valor total</Label>
        {briefing.requests.map(request => {
          const budgetDoBriefingSelecionado = this.state.otherServices.budget && this.state.otherServices.budget.services[name].requests.find(budgetRequest => budgetRequest.briefingRequestId === request.id)
          return <>
            <Label style={{ fontWeight: 600, marginTop: '20px' }}>
              {request.name} |
              Datas: {formatDate(request.deliveryDate)} |
              Quantidade: {request.quantity} unidades
              {request.hasContentCreation ? ' | Necessita criação de conteúdo' : ''}
            </Label>
            <Label style={{ marginTop: '20px' }}>
              <span style={{ fontWeight: 600 }}>Sua descrição do pedido:</span> {request.description}
            </Label>
            {budgetDoBriefingSelecionado && budgetDoBriefingSelecionado.hasAvailability && <div style={{ display: 'flex', flexFlow: 'row wrap', alignContent: 'flex-start', justifyContent: 'flex-start' }}>
              {
                budgetDoBriefingSelecionado && budgetDoBriefingSelecionado.budgets.map((budget) => {
                  return <label style={{ width: 'calc((100% / 3) - 10px)', padding: '10px' }} htmlFor={`${name}_${budget.id}`}>
                    <Card onClick={() => this.itemClickCard(request.id, name, budget.id)} key={`item_${budget.id}`} style={{ paddingBottom: '10px', width: '100%' }} >
                      <Group style={{ display: 'flex', padding: '0px' }}>
                        <Label style={{ fontWeight: '600', color: '#484848' }}><b>{budget.name}</b></Label>
                        <input style={{ width: '25px', height: '20px' }} name={`${name}_${budget.id}`} id={`${name}_${budget.id}`} type="radio" value="option" checked={budget.chosen} onChange={() => this.itemClickCard(budget.id, name)} />
                      </Group>
                      <Label style={{ marginTop: '-10px' }}>{formatCurrency(budget.unitPrice.amount)} / unidade</Label>
                      {budget.files.map((file, fileIndex) => <Card>
                        <Icons
                          icon={'paperclip'}
                          width={30}
                          height={15}
                          color={defaultTheme.colors.text.darker}
                        />
                        <a href={file} target='_blank' rel='noopener noreferrer' style={{ color: defaultTheme.colors.primary.default, fontSize: '15px', paddingLeft: '10px', fontFamily: defaultTheme.fonts.primary }}>Anexo {fileIndex < 9 && '0'}{(fileIndex + 1)}</a>
                      </Card>)}
                      <Label style={{ fontWeight: 600 }}>{this.mostraDescricao(name)}:</Label>
                      <Label style={{
                        marginTop: '-10px',
                        minHeight: '100px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 4
                      }}>{budget.description}</Label>
                      {budget.description.length > 150 && <LinkShowDetails onClick={() => this.visualizaDetalhe(budget.description)}>ver mais</LinkShowDetails>}
                    </Card>
                  </label>
                })
              }
            </div>}
            {(!budgetDoBriefingSelecionado || !budgetDoBriefingSelecionado.hasAvailability) && <Card style = {{ width: '100%', paddingBottom: '10px', color: 'red' }}>
              <Subtitle style={{ marginBottom: 'auto', marginTop: 'auto' }}>Fornecedor não tem disponibilidade para este pedido.</Subtitle>
            </Card>}
          </>})}
      </Group>
    </Card>
  }

  SolicitacaoAdditionalService = (briefing) => {
    return briefing.requests.map(request => {
      const budgetDoBriefingSelecionado = this.state.otherServices.budget && this.state.otherServices.budget.services.additionalServices.requests.find(budgetRequest => budgetRequest.briefingRequestId === request.id)
      const escolhido = budgetDoBriefingSelecionado && budgetDoBriefingSelecionado.budgets.find(budget => budget.chosen)
      return <Card style={{ padding: '10px', width: '100%', marginTop: '30px' }}>
        <Group style={{ padding: '10px' }}>
          <Title style={{ fontSize: '15px', marginBottom: '20px' }}>{request.name}</Title>
            <label style={{ position: 'absolute', right: '5px', top: '10px' }}>
              <Money>{formatCurrency((escolhido ? (escolhido.pricePerDay.amount * (escolhido.dates ? escolhido.dates.length : 1)) : 0), false)}</Money>
            </label>
            <Label style={{ fontSize: '12px', listStyle: 'none', position: 'absolute', right: '2px', top: '25px', textAlign: 'right' }}>valor total</Label>
          <Label style={{ fontWeight: 600 }}>
            Datas: {request.dates.map(date => formatDate(date)).join(' - ')}
            {request.hoursPerDay ? ` | ${request.hoursPerDay} horas por dia ` : ''}
          </Label>
          <Label style={{ marginTop: '20px' }}>
            <span style={{ fontWeight: 600 }}>Sua descrição do pedido:</span> {request.description}
          </Label>
          {budgetDoBriefingSelecionado && budgetDoBriefingSelecionado.hasAvailability && <div style={{ display: 'flex', flexFlow: 'row wrap', alignContent: 'flex-start', justifyContent: 'flex-start' }}>
            {
               budgetDoBriefingSelecionado && budgetDoBriefingSelecionado.budgets.map((budget) => {
                return <label style={{ width: 'calc((100% / 3) - 10px)', padding: '10px' }} htmlFor={`additionalServices_${budget.id}`}>
                  <Card onClick={() => this.itemClickCard(request.id, 'additionalServices', budget.id)} key={`item_${budget.id}`} style={{ paddingBottom: '10px', width: '100%' }} >
                    <Group style={{ display: 'flex', padding: '0px' }}>
                      <Label style={{ fontWeight: '600', color: '#484848' }}><b>{budget.name}</b></Label>
                      <input style={{ width: '25px', height: '20px' }} name={`additionalServices__${budget.id}`} id={`additionalServices__${budget.id}`}  type="radio" value="option" checked={budget.chosen} onChange={() => this.itemClickCard(request.id, 'additionalServices', budget.id)} />
                    </Group>
                    <Label style={{ marginTop: '-10px' }}>{formatCurrency(budget.pricePerDay.amount)} / por dia</Label>
                    {budget.files.map((file, fileIndex) => <Card>
                        <Icons
                          icon={'paperclip'}
                          width={30}
                          height={15}
                          color={defaultTheme.colors.text.darker}
                        />
                        <a href={file} target='_blank' rel='noopener noreferrer' style={{ color: defaultTheme.colors.primary.default, fontSize: '15px', paddingLeft: '10px', fontFamily: defaultTheme.fonts.primary }}>Anexo {fileIndex < 9 && '0'}{(fileIndex + 1)}</a>
                      </Card>)}
                    <Label style={{ fontWeight: 600 }}>{this.mostraDescricao('additionalServices')}:</Label>
                    <Label style={{
                      marginTop: '-10px',
                      minHeight: '100px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 4
                    }}>{budget.description}</Label>
                    {budget.description.length > 150 && <LinkShowDetails onClick={() => this.visualizaDetalhe(budget.description)}>ver mais</LinkShowDetails>}
                  </Card>
                </label>
              })
            }
          </div>}
          {(!budgetDoBriefingSelecionado || !budgetDoBriefingSelecionado.hasAvailability) && <Card style = {{ width: '100%', paddingBottom: '10px', color: 'red' }}>
            <Subtitle style={{ marginBottom: 'auto', marginTop: 'auto' }}>Fornecedor não tem disponibilidade para este pedido.</Subtitle>
          </Card>}
        </Group>
      </Card>})
  }

  paginaDesktop = () => {
    const { eventId, accountId } = this.props.match.params
    const { history } = this.props
    const eventName = this.state.event && this.state.event.name
    const eventStatus = this.state.event && this.state.event.status
    const DISPLAY_CHAT = !this.verifaRole('APPROVER') && eventId && accountId && eventName && eventStatus
    const EH_CHAIN = this.state.event && this.state.event.supplierChainId
    const NAO_EH_CHAIN = (!EH_CHAIN)
    const DISPLAY_BILLING_CONDITIONS = this.state.billingConditions && NAO_EH_CHAIN
    const isLoading = this.state.isLoading

    return <>
      <Header />
      <Main>
        <Body {...this.props} containerStyle={{ padding: '10px' }} pagina={'inicio'} menuLateralDireito={this.menuLateralDireito()}>
          {isLoading && <Loader />}
          <Options
            readOnly={this.state.readOnly}
            visualizarHistorico
            style={{ marginTop: '25px' }}
            clickHistorico={() => this.setState({ visualizaHistorico: true })}
            eventId={eventId}
            history={this.props.history}
            suppliersDeadline={this.state.event && this.state.event.suppliersDeadline}
            status={eventStatus}
            name={eventName}
            naoMostraEdicao={EH_CHAIN}
          />
          <Content>
            <Modal isOpen={this.state.todosSolicitados} >
              <MoedaIcon />
              <Titulo>Fornecedores solicitados para aprovação!</Titulo>
              <SubTitulo>Em breve entraremos em contato</SubTitulo>
              <Button
                onClick={() => this.props.history.push(`/organizer`)}
                category={'secondary'}
                fontWeight={800}
                full>
                Ok!
              </Button>
            </Modal>
            <Modal isOpen={this.state.todosAprovados} >
              <MoedaIcon />
              <Titulo>Fornecedores aprovados!</Titulo>
              <SubTitulo>Aguarde o número de requisição.</SubTitulo>
              <Button
                onClick={() => this.props.history.push(`/organizer`)}
                category={'secondary'}
                fontWeight={800}
                full>
                Ok!
              </Button>
            </Modal>
            <Modal isOpen={this.state.verMais} onClose={() => this.setState({ verMais: !this.state.verMais })} hasCloseButton>
              <Subtitle>{this.state.verMaisDetalhes}</Subtitle>
            </Modal>
            <Modal isOpen={this.state.modalVerificacao} >
              <AlertIcon fill={defaultTheme.colors.secondary.dark} width={'150px'}/>
              <Titulo>Atenção!</Titulo>
              <SubTitulo style={{ color: defaultTheme.colors.grey.medium }}>{temFeatureFlag('approval') ? 'Tem certeza que deseja enviar este fornecedor para aprovação' : 'Tem certeza que deseja contratar este fornecedor'} <span style={{ fontWeight: 600 }}>em definitivo</span>, com os itens escolhidos? Uma vez realizada esta ação, <span style={{ fontWeight: 600 }}>não é possível voltar atrás</span></SubTitulo>
              <Group style={{ display: 'flex' }}>
                <Button
                  onClick={() => this.setState({ modalVerificacao: false })}
                  category={'secondary'}
                  fontWeight={800}
                  ghost
                  full>
                  Não
                </Button>
                <Button
                  onClick={() => this.submit()}
                  category={'secondary'}
                  fontWeight={800}
                  full>
                  Sim
                </Button>
              </Group>
            </Modal>
            <ModalConfirmacao
              isOpen={this.state.modalTemCerteza}
              onCancel={() => this.setState({ modalTemCerteza: false })}
              onSubmit={this.aprovarFornecedor} />
            <ModalFornecedorAprovado
              isOpen={this.state.modalAprovarFornecedor}
              onSubmit={() => history.push(`/organizer/event/${eventId}/budget/place`)} />
            <Modal isOpen={this.state.modalRecusarFornecedor} onClose={() => this.setState({ modalRecusarFornecedor: !this.state.modalRecusarFornecedor })} hasCloseButton>
              <Icons
                color={defaultTheme.colors.secondary.default}
                width={100}
                height={100}
                style={{ margin: 'auto 1px' }}
                icon='blocked' />
              <Title style={{ paddingTop: '10px' }}>Recusar fornecedor</Title>
              <Subtitle style={{ marginBottom: '0px' }}>Escolha uma das opções a seguir:</Subtitle>
              <CheckBox
                onClick={() => this.setState({ modalRecusarStatus: 'REJEITAR' })}
                checked={this.state.modalRecusarStatus === 'REJEITAR'}
                label={'Rejeitar orçamento'}/>
              <CheckBox
                onClick={() => this.setState({ modalRecusarStatus: 'EDITAR' })}
                checked={this.state.modalRecusarStatus === 'EDITAR'}
                label={'Editar orçamento'}>
                  {this.state.modalRecusarStatus === 'EDITAR' && <Group style={{ textAlign: 'left' }}>
                    <Textarea
                      type={'text'}
                      label={'Justificativa'}
                      isRequired
                      placeholder={'Escreva aqui o motivo.'}
                      name={'justification'}
                      value={this.state.justification}
                      onChange={(input) => this.setState({ justification: input.target.value })} />
                  </Group>}
              </CheckBox>
              <Button
                onClick={this.confirmarRecusarFornecedor}
                category={'secondary'}
                fontWeight={800}
                width={'50%'}
                full>
                Confirmar
              </Button>
            </Modal>
            <Title style={{ marginBottom: '0px', marginTop: '20px' }}>{this.state.hotel.name}</Title>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {DISPLAY_BILLING_CONDITIONS && <LabelBillingConditions>
                {this.state.billingConditions.percentage === 0 ?
                  `Pagamento faturado (${this.state.billingConditions.days} dias após emissão da fatura)` :
                  `Pagamento faturado - ${this.state.billingConditions.percentage}% devem ser pagos antecipadamente, e o restante em até ${this.state.billingConditions.days} dias após a emissão da fatura`}
              </LabelBillingConditions>}
              {DISPLAY_CHAT && <ButtonChat />}
            </div>
            <Group style={{ display: 'flex' }}>
              <Icons
                icon={'exclamation-circle'}
                color={'#F39C12'}
                style={{ margin: 'auto' }}
                width={30}
                height={15} />
              <p style={{ textAlign: 'justify', color: '#F39C12', fontFamily: defaultTheme.fonts.primary, marginLeft: '10px', fontWeight: 600 }}>
                A plataforma permite em sua versão atual que todos os itens de "Outros Serviços" sejam fechados com apenas um fornecedor. Caso tenha interesse em fechar com este fornecedor, mas não deseje adquirir todos os itens abaixo, basta pedir que ele retire os itens indesejados de sua proposta com a função "Habilitar Edição" no chat, clicando em "Falar com o Fornecedor" (acima).
              </p>
            </Group>
            {this.state.otherServices.briefing.services &&
              Object.keys(this.state.otherServices.briefing.services).filter(item =>
                this.state.otherServices.briefing.services[item].requested).map(item =>
                  item === 'additionalServices' ? this.SolicitacaoAdditionalService(this.state.otherServices.briefing.services[item]) : this.Solicitacao(item, this.state.otherServices.briefing.services[item]))}
            {DISPLAY_CHAT && <Card><SingleChat display={DISPLAY_CHAT} eventName={eventName} eventId={eventId} eventStatus={eventStatus} supplierId={accountId} /></Card>}
          </Content>
        </Body>
      </Main>
    </>
  }

  menuLateralDireito = () => {
    const { history } = this.props
    const { eventId, accountId } = this.props.match.params
    const userType = window.localStorage.getItem('user_type')
    return <FixedDiv>
      <Card style={{ width: '100%' }}>
        <Label style={{ textTransform: 'uppercase' }}>Resumo do Evento <span style={{ float: 'right', paddingRight: '10px' }}>ID:{this.state.event.internalId || this.state.event.friendlyId}</span></Label>
        <Group style={{ padding: '0px' }}>
          <Label style={{ fontWeight: 600, color: '#484848' }}>{this.state.event.name}</Label>
          <Label>Solicitado há {this.state.event.created ? moment().diff(moment(this.state.event.created, 'YYYY-MM-DD[T]HH:mm:ssZ'), 'days') : 0} dias</Label>
          {userType === 'RESELLER' && <Label style={{ paddingBottom: '10px', color: defaultTheme.colors.grey.light, fontWeight: 400 }}>{this.state.event.organizer && `CLIENTE: ${this.state.event.organizer.name}`}</Label>}
          <Group style={{ display: 'flex', padding: '0px'  }}>
            <Icons
              icon={'calendario'}
              color={'gray'}
              width={40}
              height={20}
              style={{ marginRight: '-5px', marginTop: '5px' }} />
            <Label>{formatDate(this.state.event.start)} - {formatDate(this.state.event.end)}</Label>
          </Group>
          <Group style={{ display: 'flex', padding: '0px'  }}>
            <Icons
              icon={'map-pin'}
              color={'gray'}
              width={40}
              height={20}
              style={{ marginRight: '-5px', marginTop: '5px', marginLeft: '2px' }} />
            {this.state.event.location && <Label>{this.state.event.location.address} - {this.state.event.location.city}</Label>}
          </Group>
          {this.state.event.numberOfParticipants > 0 && <Group style={{ display: 'flex', padding: '0px'  }}>
            <Icons
              icon={'pessoas'}
              color={'gray'}
              width={40}
              height={20}
              style={{ marginTop: '5px', marginLeft: '-3px' }} />
            <Label>{this.state.event.numberOfParticipants} pessoas</Label>
          </Group>}
        </Group>
      </Card>
      <Card style={{ width: '100%' }}>
        <Label style={{ textTransform: 'uppercase' }}>Resumo do pedido</Label>
        <Title style={{ fontSize: '14px', marginTop: '20px', marginBottom: '10px' }}>{this.state.hotel.name}</Title>
        {this.state.otherServices.briefing.services && Object.keys(this.state.otherServices.briefing.services).filter(servico => this.state.otherServices.briefing.services[servico].requested && servico !== 'additionalServices').map(servico => <ItemsMenuLateral selected={false}>
          <Group style={{ padding: '0', display: 'flex' }}>
            <Group style={{ padding: '0', display: 'flex', width: '100%' }}>
              <Label>{getNameFromKey(servico)}</Label>
              <Label style={{ textAlign: 'right', color: defaultTheme.colors.primary.default }}>{formatCurrency(this.CalculaTotalDoServico(servico))}</Label>
            </Group>
          </Group>
        </ItemsMenuLateral>)}
        {this.state.otherServices.briefing.services && this.state.otherServices.briefing.services.additionalServices.requested &&
         this.state.otherServices.briefing.services.additionalServices.requests.map(request => {
          const budgetDoBriefingSelecionado = this.state.otherServices.budget && this.state.otherServices.budget.services && this.state.otherServices.budget.services.additionalServices.requests.find(budgetRequest => budgetRequest.briefingRequestId === request.id)
          const escolhido = budgetDoBriefingSelecionado && budgetDoBriefingSelecionado.budgets.find(budget => budget.chosen)
          return <ItemsMenuLateral selected={false}>
            <Group style={{ padding: '0', display: 'flex' }}>
              <Group style={{ padding: '0', display: 'flex', width: '100%' }}>
                <Label>{request.name}</Label>
                <Label style={{ textAlign: 'right', color: defaultTheme.colors.primary.default }}>{formatCurrency((escolhido ? (escolhido.pricePerDay.amount * (escolhido.dates ? escolhido.dates.length : 1)) : 0))}</Label>
              </Group>
            </Group>
          </ItemsMenuLateral>
        })}
        <ItemsMenuLateral selected={true}>
          <Group style={{ padding: '0', display: 'flex' }}>
            <Label>Valor total</Label>
            <Money>{formatCurrency(this.CalculaTotal(), false)}</Money>
          </Group>
        </ItemsMenuLateral>
        {this.verifaRole('APPROVER') && this.verificaSeFoiSolicitadoAprovacao(accountId) && <>
          <Button
            type='submit'
            category={'secondary'}
            onClick={this.ativaModalTemCerteza}
            fontWeight={800}
            full>
            {'Aprovar fornecedor'}
          </Button>
          <Button
            type='submit'
            category={'secondary'}
            onClick={this.recusarFornecedor}
            fontWeight={800}
            ghost
            red
            full>
            {'Recusar fornecedor'}
          </Button>
        </>}
        {!this.verifaRole('APPROVER') && <Button
          type='submit'
          category={'secondary'}
          fontWeight={800}
          onClick={() => this.setState({ modalVerificacao: true })}
          full>
          {temFeatureFlag('approval') ? 'Submeter para aprovação' : 'Contratar este fornecedor'}
        </Button>}
        <Button
          id={'btnVoltar'}
          type='submit'
          category={'secondary'}
          fontWeight={800}
          ghost
          onClick={() => history.push(`/organizer/event/${eventId}/budget/place`)}
          full>
          {'Voltar para fornecedores'}
        </Button>
      </Card>
    </FixedDiv>
  }

  render() {
    return this.gerenciaResponsividade()
  }

  verificaSeOEventoPertenceAoConsultor = (consultantId, userId) => {
    const loggedUserId = window.localStorage.getItem('user')
    return consultantId !== userId && loggedUserId === consultantId
  }

  loadDataEvent = () => {
    const { api: { base, version } } = config
    const { eventId } = this.props.match.params
    const userType = window.localStorage.getItem('user_type')
    const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
    const endpoint = `${base}${version}${eventEndpoint}/${eventId}/budget/fullInfo`

    return Get(endpoint)
      .then((event) => {
        var lodging = this.state.lodging
        lodging.requested = event.services.lodging.requested
        lodging.choosedSupplier = event.services.lodging.choosedSupplier
        var room = this.state.room
        room.requested = event.services.room.requested
        room.choosedSupplier = event.services.room.choosedSupplier
        var foodsAndBeverages = this.state.foodsAndBeverages
        foodsAndBeverages.requested = event.services.foodsAndBeverages.requested
        foodsAndBeverages.choosedSupplier = event.services.foodsAndBeverages.choosedSupplier
        var equipments = this.state.equipments
        equipments.requested = event.services.equipments.requested
        equipments.choosedSupplier = event.services.equipments.choosedSupplier
        this.setState({ event: {
            ...event
          },
          readOnly: this.verificaSeOEventoPertenceAoConsultor(event.consultantId, event.userId),
          isLoading: false,
          lodging,
          room,
          foodsAndBeverages,
          equipments
        }, () => document.getElementById("btnVoltar").scrollIntoView({ behavior: "smooth" }))
      }).catch(e => {
        this.setState({isLoading: false}, console.error(e))
      })
  }

  loadHistory = () => {
    const { api: { base, version } } = config
    const { eventId, accountId } = this.props.match.params
    const userType = window.localStorage.getItem('user_type')
    const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
    const endpoint = `${base}${version}${eventEndpoint}/${eventId}/counter-proposals/account/${accountId}`

    return Get(endpoint)
    .then(response => {
      response.map(services => services.map(service => {
        service.counterProposals = service.counterProposals.reduce((counterProposals, item) => {
          if(item.requester === 'ORGANIZER' && (item.supplierStatus === 'ACCEPTED' || item.supplierStatus === 'REFUSED')) {
            counterProposals.push(item)
            var duplicata = {
              acceptedDate: item.acceptedDate,
              date: item.acceptedDate || item.date,
              initialPrice: item.initialPrice,
              justification: item.justification,
              requestedPrice: item.requestedPrice,
              requester: 'SUPPLIER',
              duplicata: true,
              supplierStatus: item.supplierStatus,
              valid: item.valid
            }
            counterProposals.push(duplicata)
          } else {
            counterProposals.push(item)
          }
          return counterProposals
        }, []).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
      return service
      })
      )
      this.setState({ counterProposals: response })
    }).catch(e => {
      console.error(e)
    })
  }

  loadDataServices = () => {
    this.setState({isLoading: true})
    const { api: { base, version } } = config
    const { eventId, accountId } = this.props.match.params
    const userType = window.localStorage.getItem('user_type')
    const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
    const endpoint = `${base}${version}${eventEndpoint}/${eventId}/budget/account/${accountId}`
    return Get(endpoint)
      .then(response => {
        const budgets = []
        response.budgets.foodsAndBeverages.mostRecent && Object.keys(response.budgets.foodsAndBeverages.mostRecent.menusBudgets).map((key) => {
          let budget = response.budgets.foodsAndBeverages.mostRecent.menusBudgets[key]
          budget.name = key
          if (budget.budgets.length > 0) {
            budget.budgets = budget.budgets.map((item) => {
              item.selected = item.chosen || false
              return item
            })
          }
          if (budget.budgets.length > 0 && !budget.budgets.some(item => item.chosen)) {
            const valMin = Math.min(...budget.budgets.map(item => item.totalWithoutFee.amount))
            const itemMin = budget.budgets.findIndex(item => item.totalWithoutFee.amount === valMin)
            if(itemMin > -1) budget.budgets[itemMin].selected = true
          }
          Object.keys(response.briefings.foodsAndBeverages).filter(briefingKey => response.briefings.foodsAndBeverages[briefingKey] && response.briefings.foodsAndBeverages[briefingKey].name && response.briefings.foodsAndBeverages[briefingKey].name === key).map((briefingKey) => {
            return budget.briefing = response.briefings.foodsAndBeverages[briefingKey]
          })
          return budgets.push(budget)
        })

        const chosenBudgets = this.state.chosenBudgets
        response.budgets.otherServices.mostRecent && Object.keys(response.budgets.otherServices.mostRecent.services).map((key) => {
          let budget = response.budgets.otherServices.mostRecent.services[key]
          response.budgets.otherServices.mostRecent.services[key].requests.forEach(request => {
            if (request.budgets.length > 0 && !request.budgets.some(item => item.chosen)) {
              const valMin = Math.min(...request.budgets.map(item => item.unitPrice ? item.unitPrice.amount : item.pricePerDay.amount))
              const itemMin = request.budgets.findIndex(item => item.unitPrice ? item.unitPrice.amount === valMin : item.pricePerDay.amount === valMin)
              if(itemMin > -1) {
                chosenBudgets[key].push({
                  briefingRequestId: request.briefingRequestId,
                  budgetId: request.budgets[itemMin].id
                })
                request.budgets[itemMin].chosen = true
              }
            }
          })
          return budgets.push(budget)
        })
        this.setState({
          billingConditions: this.getBillingConditions(response.budgets),
          hotel: response,
          briefing: response.briefings.rooms,
          budget: response.budgets.rooms.mostRecent,
          lodging: response.budgets.lodging.mostRecent ? {
            budgets: response.budgets.lodging.mostRecent.apartmentsBudget,
            budgetId: response.budgets.lodging.mostRecent.id,
            observations: response.budgets.lodging.mostRecent.observations,
            itemsOnApartmentPrice: response.budgets.lodging.mostRecent.itemsOnApartmentPrice,
            includedItems: response.budgets.lodging.mostRecent.includedItems,
            expireDate: response.budgets.lodging.expireDate,
            name: response.name,
            location: response.location,
            start: response.start,
            end: response.end,
            numberOfParticipants: response.numberOfParticipants,
            status: response.budgets.lodging.mostRecent.status,
            commission: response.budgets.lodging.mostRecent.commission
          } : {
            budgets: [],
            budgetId: '',
            includedItems: {}
          },
          room: {
            showItems: [],
            hotel: response,
            briefing: response.briefings.rooms,
            budget: response.budgets.rooms.mostRecent, // Como hoje nós temos mais de um budget de quarto e vamos só mostrar um aqui agora vamos pegar o mais recente
          },
          foodsAndBeverages: response.budgets.foodsAndBeverages.mostRecent ? {
            showItems: [],
            budgets,
            expireDate: response.budgets.foodsAndBeverages.expireDate,
            name: response.name,
            observations: response.budgets.foodsAndBeverages.mostRecent.observations,
            status: response.budgets.foodsAndBeverages.mostRecent.status,
            commission: response.budgets.foodsAndBeverages.mostRecent.commission,
            budgetId: response.budgets.foodsAndBeverages.mostRecent.id
          } : {
            showItems: [],
            budgets: [],
            budgetId: ''
          },
          equipments: {
            showItems: [],
            briefing: response.briefings.equipments,
            budget: response.budgets.equipments.mostRecent
          },
          transfer: {
            showItems: [],
            briefing: response.briefings.transfer,
            budget: response.budgets.transfer.mostRecent
          },
          otherServices: {
            showItems: [],
            briefing: response.briefings.otherServices,
            budget: response.budgets.otherServices ? response.budgets.otherServices.mostRecent : {}
          },
          chosenBudgets
        })
      })
      .catch(e => {
        this.setState({isLoading: false}, console.error(e))
      })
  }
}

export default ResumoOtherServices
