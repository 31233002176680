import moment from 'moment'

const validaCriacaoDoEvento = (event, hotelId) => {
  return validateFormDataEvento(event, hotelId) &&
    validateFormStartDateEvento(event) &&
    validateHotelInfo(hotelId)
}

const validateHotelInfo = (hotelId) => {
  const hoteisChain = (window.localStorage.getItem('hoteisChain') && JSON.parse(window.localStorage.getItem('hoteisChain'))) || []
  return !hotelId || hoteisChain.find(hotel => hotel.id === hotelId)
}

const validateFormDataEvento = (form, hotelId) => {
  let error = false
  const items = [
    'name',
    'start',
    'end',
  ]

  if(!hotelId) {
    items.push('radius')
    items.push('address')
    items.push('location')
  }

  items.forEach(i => {
    if (form[i] === '') {
      error = true
    }
  })

  return !error
}

const validateFormStartDateEvento = (form) => {
  return form['start'][0] ? form['start'][0].diff(moment(), 'days') > 1 : false
}

const campoDataPreenchido = (form) => {
  return form['start'][0]
}

const validaCricaoDeHospedagem = (lodging) => {
  return validateHospedagemSelecao(lodging) &&
    validateHospedagemDatesValid(lodging) &&
    validateHospedagemDatesRangeValid(lodging) &&
    validateHospedagemForm(lodging)
}

const validateHospedagemSelecao = (lodging) => {
  return lodging.singleType.selected || lodging.doubleType.selected || lodging.tripleType.selected
}

const validateHospedagemDatesValid = (lodging) => {
  var isValid = true
  if (lodging.singleType.selected) {
    lodging.singleType.list.forEach(item => {
      if (item.dates.length === 1) isValid = false
    })
  } if (lodging.doubleType.selected) {
    lodging.doubleType.list.forEach(item => {
      if (item.dates.length === 1) isValid = false
    })
  } if (lodging.tripleType.selected) {
    lodging.tripleType.list.forEach(item => {
      if (item.dates.length === 1) isValid = false
    })
  }
  return isValid
}

const validateHospedagemDatesRangeValid = (lodging) => {
  var isValid = true
  if (lodging.singleType.selected) {
    lodging.singleType.list.forEach(item => {
      var date1 = moment(item.dates[0])
      var date2 = moment(item.dates[item.dates.length - 1])
      var eventDate = moment(lodging.eventStartDate)
      var eventEndDate = moment(lodging.eventEndDate)
      if ((date1.diff(eventDate, 'days') < -10) || (date2.diff(eventEndDate, 'days') > 10)) {
        isValid = false
      }
    })
  } if (lodging.doubleType.selected) {
    lodging.doubleType.list.forEach(item => {
      var date1 = moment(item.dates[0])
      var date2 = moment(item.dates[item.dates.length - 1])
      var eventDate = moment(lodging.eventStartDate)
      var eventEndDate = moment(lodging.eventEndDate)
      if ((date1.diff(eventDate, 'days') < -10) || (date2.diff(eventEndDate, 'days') > 10)) {
        isValid = false
      }
    })
  } if (lodging.tripleType.selected) {
    lodging.tripleType.list.forEach(item => {
      var date1 = moment(item.dates[0])
      var date2 = moment(item.dates[item.dates.length - 1])
      var eventDate = moment(lodging.eventStartDate)
      var eventEndDate = moment(lodging.eventEndDate)
      if ((date1.diff(eventDate, 'days') < -10) || (date2.diff(eventEndDate, 'days') > 10)) {
        isValid = false
      }
    })
  }
  return isValid
}

const validateHospedagemForm = (lodging) => {
  var isValid = true
  if (lodging.singleType.selected) {
    lodging.singleType.list.forEach(item => {
      if (item.dates.length === 0) {
        isValid = false
      } if (!(item.amountOfApartments > 0) || item.amountOfApartments === '0') {
        isValid = false
      }
    })
  } if (lodging.doubleType.selected) {
    lodging.doubleType.list.forEach(item => {
      if (item.dates.length === 0) {
        isValid = false
      } if ((!item.amountOfApartmentsDouble > 0 || item.amountOfApartmentsDouble === '0') && (!item.amountOfApartmentsTwin > 0 || item.amountOfApartmentsTwin === '0')) {
        isValid = false
      }
    })
  } if (lodging.tripleType.selected) {
    lodging.tripleType.list.forEach(item => {
      if (item.dates.length === 0) {
        isValid = false
      } if (!item.amountOfApartments > 0 || item.amountOfApartments === '0') {
        isValid = false
      }
    })
  }
  return isValid
}

const validaCriacaoDeSalas = (rooms) => {
  return validateSalasTime(rooms) &&
    validateSalasForm(rooms) &&
    validateSalasDates(rooms)
}

const validateSalasTime = (rooms) => {
  let isValid = true
  rooms.rooms.forEach((room) => {
    if (moment(room.startDate, 'YYYY-MM-DD[T]HH:mm:ssZ').diff(moment(room.endDate, 'YYYY-MM-DD[T]HH:mm:ssZ'), 'days') === 0 &&
      room.startTime > room.endTime) {
      isValid = false
    }
  })
  return isValid
}

const validateSalasForm = (rooms) => {
  let isValid = true
  const ItemsToValidate = ['startDate', 'endDate', 'mounting', 'roomType', 'amountOfPeople']
  rooms.rooms.forEach((e) => {
    Object.keys(e).forEach(i => {
      if (ItemsToValidate.includes(i) && e[i] === '') {
        isValid = false
      } else if (i === 'amountOfPeople' && e[i] === '0') {
        isValid = false
      }
    })
  })
  return isValid
}

const validateSalasDates = (rooms) => {
  let isValid = true
  rooms.rooms.forEach((room) => {
    if (room.startDate &&
      !(moment(room.startDate, 'YYYY-MM-DD[T]HH:mm:ssZ').diff(moment(rooms.eventStartDate, 'YYYY-MM-DD[T]HH:mm:ssZ'), 'days') >= 0 &&
        moment(room.startDate, 'YYYY-MM-DD[T]HH:mm:ssZ').diff(moment(rooms.eventEndDate, 'YYYY-MM-DD[T]HH:mm:ssZ'), 'days') <= 1 &&
        moment(room.endDate, 'YYYY-MM-DD[T]HH:mm:ssZ').diff(moment(rooms.eventStartDate, 'YYYY-MM-DD[T]HH:mm:ssZ'), 'days') >= 0 &&
        moment(room.endDate, 'YYYY-MM-DD[T]HH:mm:ssZ').diff(moment(rooms.eventEndDate, 'YYYY-MM-DD[T]HH:mm:ssZ'), 'days') <= 1)) {
      isValid = false
    }
  })
  return isValid
}

const validaResumo = (resume) => {
  return validateForm(resume) &&
  validateEmail(resume) &&
  validateTelefone(resume)
}

const validateForm = (resume) => {
  return resume.name && resume.email && resume.phone
}

const validateEmail = (resume) => {
  const EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return resume.email === resume.confirmEmail &&
    EMAIL.test(resume.email) &&
    EMAIL.test(resume.confirmEmail)
}

const validateTelefone = (resume) => {
  return resume.phone.length >= 10
}

export {
  validaCriacaoDoEvento,
  validateFormDataEvento,
  validateFormStartDateEvento,
  validaCricaoDeHospedagem,
  validateHospedagemDatesValid,
  validateHospedagemDatesRangeValid,
  validateHospedagemForm,
  validaCriacaoDeSalas,
  validateSalasTime,
  validateSalasForm,
  validateSalasDates,
  validaResumo,
  campoDataPreenchido
}
