/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
/* UTILS */
import { formataCnpj } from '../../../../utils/formataCnpj'
/* STYLES */
import { defaultTheme } from '../../../styles/themes'
/* COMPONENTS */
import Modal from '../../../components/Modal/index'
import MoedaIcon from '../../../components/ui/MoedaIcon'
import Select from '../../../components/ui/Select'
import Button from '../../../components/ui/Button'
import PercentageInput from '../../../components/ui/PercentageInput'
import Alert from '../../../components/ui/Alert'
import Subtitle from '../../../components/ui/Subtitle'

const Title = styled.h1`
  font-family: ${defaultTheme.fonts.secondary};
  font-weight: 800;
  line-height: 1.2;
  font-size: 1.3em;
  margin-top: 20px;
  color: #484848;
`

const FormControl = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  margin: 0 5%;
`

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 15px 0px 0px 0px;
`
const Label = styled.label`
  font-family: ${defaultTheme.fonts.secondary};
  color: #484848;
`

const Circle = styled.div`
  right: 10px;
  top: 5px;
  border: 2px solid #9b9b9b;
  background: #fff;
  transition: all .2s ease;
  border-radius: 50%;
  padding: 3px;
  margin: 0px 5px;
`

const Pointer = styled.span`
   background-color: ${props => props.selected ? defaultTheme.colors.primary.default : '#FFF'};
   width: 13px;
   height: 13px;
   display: block;
   border-radius: 50%;
`

const Cliente = ({ client }) => {
  const estilo = {
    fontFamily: defaultTheme.fonts.primary,
    fontSize: '0.8em',
    color: defaultTheme.colors.subtitle,
    margin: '0'
  }
  return (<>
    <Subtitle style={estilo}>
      Cliente: <b style={{fontWeight: 800}}> {client.name}</b>
    </Subtitle>
    <Subtitle style={{fontSize: '0.8em'}}>
      CNPJ: <span style={{fontWeight: 800}}>{formataCnpj(client.document)}</span>
    </Subtitle>
  </>)
}

class CommisedRate extends Component {
  static defaultProps = {
    onSubmit: () => {}
  }

  state = { commised: '', value: '', reason: '', errorMessage: '' }

  toggleCommised = (status) => {
    this.setState({ commised: status, reason: '', value: '', errorMessage: ''})
  }

  handleValue = ({target: {name, value}}) => {
    this.setState({[name]: value})
  }

  submit = () => {
    this.setState({errorMessage: ''})
    const { commised, value, reason }  = this.state
    if (this.validate({commised, value, reason})) {
      this.props.onSubmit({commised, value, reason})
    } else {
      this.setState({errorMessage: 'Preencha todos os campos obrigatórios.'})
    }
  }

  validate = ({commised, value, reason}) => {
    let isValid = true
    if (commised === 'yes' && value === '') {
      isValid = false
    } else if (commised === 'no' && reason === '') {
      isValid = false
    }
    return isValid
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.submit()
    }
  }

  render() {
    const { commised, value, reason, errorMessage } = this.state
    const { isOpen, client, onClose } = this.props
    return (
      <Modal isOpen={isOpen} hasCloseButton onClose={onClose}>
        <MoedaIcon />
        {errorMessage && <Alert type={'negative'}>{errorMessage}</Alert>}
        <Title>Tarifa comissionada?</Title>
        <Cliente client={client || {}} />
        <Form>
          <Row>
            <FormControl onClick={() => this.toggleCommised('yes')}>
              <Circle>
                <Pointer selected={commised === 'yes'} />
              </Circle>
              <Label>Sim</Label>
            </FormControl>
            <FormControl onClick={() => this.toggleCommised('no')}>
              <Circle>
                <Pointer selected={commised === 'no'} />
              </Circle>
              <Label>Não</Label>
            </FormControl>
          </Row>
          {commised === 'no' && <Select
              onKeyPress={this.handleKeyPress}
              label={'Justificativa'}
              isRequired
              name='reason'
              value={reason}
              onChange={this.handleValue}
              error={errorMessage}
            >
              <option value={''}>Selecione</option>
              <option value={'client_got_in_touch'}>Cliente entrou em contato direto para negociação</option>
              <option value={'client_already_has_a_contract'}>Cliente possui acordo</option>
              <option value={'uncommissioned_service'}>Não trabalhamos com comissão para este serviço</option>
            </Select>}
          {commised === 'yes' && <PercentageInput
            name='value'
            onKeyPress={this.handleKeyPress}
            onChange={this.handleValue}
            error={errorMessage}
            value={value}
            haveDecimals={false}
            placeholder={'0'}
          />}
          {commised !== '' && <Button
            type={'button'}
            onClick={this.submit}
            category={'secondary'}
            fontWeight={800}
            full>
            Enviar
          </Button>}
        </Form>
      </Modal>
    )
  }
}

export default CommisedRate
