/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
/* UTILS */
import config from '../../../../middleware/config'
import { formatCurrency } from '../../../../utils/money'
import { formatDate } from '../../../../utils/dates'
import { Get, Post } from '../../../../utils/request'
import { getNameFromKey } from '../../../../utils/fabTexts'
import moment from 'moment'
/* STYLES */
import { defaultTheme } from '../../../styles/themes'
/* COMPONENTS */
import Header from '../../Header'
import Loader from '../../../components/Loader'
import Icon from '../../../components/ui/Icons'
import Card from '../../../components/ui/Card'
import Icons from '../../../components/ui/Icons'
import Button from '../../../components/ui/Button'
import Money from '../../../components/ui/Money'
import Subtitle from '../../../components/ui/Subtitle'
import Title from '../../../components/ui/Title'

const LodgingIcon = styled(() => (<Icon icon={'bed-outline'} />))`
  min-width: 55px;
  color: ${defaultTheme.colors.grey.medium};
`

const Main = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #FFF;
  @media (min-width: 768px) {
    background-color: #FAFAFA;
  }
`
const Content = styled.div`
  min-height: 90vh;
  padding: 10px 20px;
  font-family: ${defaultTheme.fonts.secondary};
  width: 80%;
  font-size: 14px;
`

const Observations = styled.p`
  color: ${defaultTheme.colors.grey.light};
  font-family: 'Open sans';
`

const BriefingInfo = styled.li`
  color: ${defaultTheme.colors.grey.light};
  font-weight: normal;
  font-size: 0.9em;
  & b {
    font-weight: bold;
    color: ${defaultTheme.colors.text.dark};
  }
`

const Group = styled.div`
  padding: 20px;
`

const Conclusion = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 30px;
  width: 80%;
  & div  {
    width: 50%;
  }
`

const Label = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  box-sizing: border-box;
  padding: 5px 5px 5px 0px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
  `


const Total = (props) => {
  return (
    <div style = {props.style}>
      <Money style={{margin: 'auto', textAlign: 'center'}}>{props.children}</Money>
      <p style={{
        color: defaultTheme.colors.grey.medium,
        fontFamily: defaultTheme.fonts.primary,
        fontSize: '1.6 em',
        margin: 'auto', textAlign: 'center',
        fontWeight: 100}}>Valor total sem taxas</p>
    </div>
  )
}

class LodgingDetails extends Component {
  state = {
    budgets: [],
    isLoading: false,
    budgetId: '',
    includedItems: {}
  }

  isExpired(date) {return moment(date) <= moment()}

  getTotalPrice = () => {
    return this.state.budgets.reduce((total, budget) => {
      total += budget.totalPrice.amount + (this.state.itemsOnApartmentPrice && budget.hasAvailability ? this.state.itemsOnApartmentPrice.amount * budget.numberOfApartments : 0)
      return total
    }, 0)
  }

  componentDidMount() {this.loadData()}

  goBack = () => {
    this.setState({
      isLoading: true
    })
    const { history } = this.props
    const { eventId, accountId } = this.props.match.params
    const { api: { base, version, routes: { events } } } = config
    const endpoint = `${base}${version}/budget/lodging/${this.state.budgetId}/accept`
    Post(endpoint)
    .then(() => {
      history.push(`/organizer${events}/${eventId}/budget/account/${accountId}`)
    }).catch(err => {
      console.error(err.message)
      this.setState({
        isLoading: false
      })
      alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
    })
  }

  getIncludedItems = () => {
    var included = ''
    var count = 0
    Object.keys(this.state.includedItems).forEach(key => {
      if(this.state.includedItems[key] && key !== 'others') {
        if(count === 0) {
          included += getNameFromKey(key)
        } else {
          included += ', ' + getNameFromKey(key)
        }
        count++
      } else if (key === 'others' && this.state.includedItems[key].sent) {
        if(count === 0) {
          included += this.state.includedItems[key].description
        } else {
          included += ', ' + this.state.includedItems[key].description
        }
        count++
      }
    })
    return included
  }

  splitDates = (requests) => {
    return requests.map(date => {
      return " " + moment(date, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br').format('DD-MM-YYYY') + " ,"
    })
  }

  render() {
    const {isLoading} = this.state
    return (
      <Main>
        {isLoading && <Loader />}
        <Header />
        <Content>
          <Title>{this.state.name}</Title>
          {this.isExpired(this.state.expireDate)
            ? (<Subtitle expired>esta proposta esta sujeita a disponibilidade</Subtitle>)
            : (<Subtitle >proposta válida até <b>{formatDate(this.state.expireDate)}</b></Subtitle>)
          }
          <Subtitle>{this.state.observations}</Subtitle>

          <Card style={{padding: '10px', margin: '10px'}}>
            <LodgingIcon /> Hospedagem
            <Observations>Incluso: <b>{this.getIncludedItems()}</b></Observations>
          </Card>
          {
            this.state.budgets.map(budget => {
              if(budget.hasAvailability) {
                return <Card key={`roomServiceItem_${0}`} style={{ paddingBottom: '10px' }}>
                  <Group>
                    <Subtitle style={{ fontSize: '15px'}}>{budget.numberOfApartments}x {getNameFromKey(budget.item.keyName)}</Subtitle>
                    <label style={{position: 'absolute', right: '50px', top: '30px'}}>
                      <Money>{formatCurrency(budget.totalPrice.amount + (this.state.itemsOnApartmentPrice ? this.state.itemsOnApartmentPrice.amount * budget.numberOfApartments : 0), false)}</Money>
                    </label>
                    <BriefingInfo style={{ listStyle: 'none', position: 'absolute', right: '65px', top: '55px' }}>sem taxas</BriefingInfo>
                  </Group>
                  <BriefingInfo style={{ listStyle: 'none', paddingLeft: '15px', marginTop: '-20px' }}>{budget.category}</BriefingInfo>
                  <BriefingInfo style={{ listStyle: 'none', paddingLeft: '15px'}}><b>Check-in: </b>{moment(budget.item.dates[0], 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br').format('DD-MM-YYYY')} <b style={{ paddingLeft: '10px' }}>Check-out: </b>{moment(budget.item.dates[budget.item.dates.length -1], 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br').format('DD-MM-YYYY')}</BriefingInfo>
                  <BriefingInfo style={{ listStyle: 'none', paddingLeft: '15px'}}><b>{budget.numberOfDays} diárias</b></BriefingInfo>
                  <BriefingInfo style={{ listStyle: 'none', paddingLeft: '15px' }}>R$ {formatCurrency(budget.price ? budget.price.amount : 0, false)} / dia</BriefingInfo>
                  <BriefingInfo style={{ listStyle: 'none', paddingLeft: '15px' }}><b>Valor total apto(s):</b> R$ {formatCurrency(budget.price ? budget.price.amount * budget.numberOfDays * budget.numberOfApartments : 0, false)} (R$ {formatCurrency(budget.price ? budget.price.amount * budget.numberOfDays : 0, false)} x {budget.numberOfApartments})</BriefingInfo>
                  {budget.fee && budget.fee.amount > 0 && <BriefingInfo style={{ listStyle: 'none', paddingLeft: '15px' }}>{budget.fee && budget.fee.amount > 0 ? budget.fee.amount : 0}% taxas </BriefingInfo>}
                  {budget.fee && budget.fee.amount > 0 && <BriefingInfo style={{ listStyle: 'none', paddingLeft: '15px' }}><b>Valor das taxas, por apto, por dia:</b> R$ {formatCurrency(budget.price ? budget.price.amount * (budget.fee ? budget.fee.amount / 100 : 0) : 0, false)}</BriefingInfo>}
                  <BriefingInfo style={{ listStyle: 'none', paddingLeft: '15px' }}>{(this.state.itemsOnApartmentPrice && this.state.itemsOnApartmentPrice.amount > 0) ? `Brindes no apto R$ ${formatCurrency(this.state.itemsOnApartmentPrice.amount, false)} / apto` : ''}</BriefingInfo>
                  <BriefingInfo style={{ listStyle: 'none', paddingLeft: '15px' }}>{(this.state.itemsOnApartmentPrice && this.state.itemsOnApartmentPrice.amount > 0) && <b>Valor total dos brindes:</b>} {(this.state.itemsOnApartmentPrice && this.state.itemsOnApartmentPrice.amount > 0) ? `R$ ${formatCurrency(this.state.itemsOnApartmentPrice.amount * budget.numberOfApartments, false)}` : ''}</BriefingInfo>
                </Card>
              } else {
                return <Card key={`roomServiceItem_${0}`} style={{ paddingBottom: '10px', opacity: 0.5 }}>
                  <Group>
                    <Subtitle style={{ fontSize: '15px'}}>{budget.numberOfApartments}x {getNameFromKey(budget.item.keyName)}</Subtitle>
                    <label style={{position: 'absolute', right: '50px', top: '30px'}}>
                      <BriefingInfo style={{ listStyle: 'none', color: 'black' }}>Quarto indisponível neste hotel</BriefingInfo>
                    </label>
                  </Group>
                  <BriefingInfo style={{ listStyle: 'none', paddingLeft: '15px', marginTop: '-20px' }}>{budget.category}</BriefingInfo>
                  <BriefingInfo style={{ listStyle: 'none', paddingLeft: '15px'}}><b>{budget.numberOfDays} diárias</b></BriefingInfo>
                  <BriefingInfo style={{ listStyle: 'none', paddingLeft: '15px'}}><b>Check-in: </b>{moment(budget.item.dates[0], 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br').format('DD-MM-YYYY')} <b style={{ paddingLeft: '10px' }}>Check-out: </b>{moment(budget.item.dates[budget.item.dates.length -1], 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br').format('DD-MM-YYYY')}</BriefingInfo>
                  <BriefingInfo style={{ listStyle: 'none', paddingLeft: '15px' }}>R$ {formatCurrency(budget.price ? budget.price.amount : 0, false)} / dia + {budget.fee ? budget.fee.amount : 0}% taxas</BriefingInfo>
                  {budget.item.keyName === 'single' && <Group style={{ width: '50%', margin: 'auto', display: 'flex', paddingLeft: '20px', alignItems: 'center' }}>
                      <Icons style={{ width: '30%' }} height='35px' icon='double-bed' />
                      <Label style={{ width: '70%' }}>1 cama de casal</Label>
                    </Group>}
                  {budget.item.keyName === 'double' && <Group style={{ width: '50%', margin: 'auto', display: 'flex', paddingLeft: '20px', alignItems: 'center' }}>
                      <Icons style={{ width: '30%' }} height='35px' icon='double-bed' />
                      <Label style={{ width: '70%' }}>1 cama de casal</Label>
                    </Group>}
                  {budget.item.keyName === 'twin' && <Group style={{ width: '50%', margin: 'auto', display: 'flex', paddingLeft: '20px', alignItems: 'center' }}>
                      <Icons style={{ width: '15%' }} height='35px' icon='single-bed' />
                      <Icons style={{ width: '15%' }} height='35px' icon='single-bed' />
                      <Label style={{ width: '70%', paddingLeft: '10px' }}>2 camas de solteiro</Label>
                    </Group>}
                  {budget.item.keyName === 'triple' && <Group style={{ width: '50%', margin: 'auto', display: 'flex', paddingLeft: '20px', alignItems: 'center' }}>
                    <Icons style={{ width: '15%' }} height='35px' icon='single-bed' />
                    <Icons style={{ width: '15%' }} height='35px' icon='single-bed' />
                    <Icons style={{ width: '15%' }} height='35px' icon='single-bed' />
                    <Label style={{ width: '55%', paddingLeft: '10px' }}>3 camas de solteiro</Label>
                  </Group>}
                </Card>
              }
            })
          }
        </Content>
        <Conclusion>
          <Total>{formatCurrency(this.getTotalPrice(), false)}</Total>
          <div><Button full onClick={this.goBack}>OK</Button></div>
        </Conclusion>
      </Main>
    )
  }

  loadData = () => {
    this.setState({ isLoading: true })
    const { api: { base, version } } = config
    const { eventId, accountId } = this.props.match.params
    const userType = window.localStorage.getItem('user_type')
    const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
    const endpoint = `${base}${version}${eventEndpoint}/${eventId}/budget/account/${accountId}`
    return Get(endpoint)
      .then(response => {
        this.setState({
            budgets: response.budgets.lodging.mostRecent.apartmentsBudget,
            budgetId: response.budgets.lodging.mostRecent.id,
            observations: response.budgets.lodging.mostRecent.observations,
            itemsOnApartmentPrice: response.budgets.lodging.mostRecent.itemsOnApartmentPrice,
            includedItems: response.budgets.lodging.mostRecent.includedItems,
            isLoading: false,
            expireDate: response.budgets.lodging.expireDate,
            name: response.name
        })
      })
      .catch(e => { this.setState({ isLoading: false }, console.error(e)) })
  }
}

export default LodgingDetails
