import React, { Fragment } from 'react'
import styled from 'styled-components'
/* STYLES */
import { defaultTheme } from '../../../styles/themes'
/* UTILS */
import {
  formatDateToLocale,
  diffBetweenDates,
  removeDays,
  formatTime,
  addDays } from '../utils'
import { checkIfItemWasEdited, checkIfItemsWasEdited } from '../../../../utils/checkEdition'

const Card = styled.div`
  background-color: #fff;
  padding: 15px 15px 15px 15px;
  width: 100%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  margin: 10px 0;
  line-height: 1.3;
  color: ${defaultTheme.colors.text.dark};
  position: relative;
  &::after {
    margin: auto;
    position: absolute;
    top: 50%;
    right: 10px;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    font-size: 18px;
  }
`

const RoomBriefingInfo = styled.ul`
  font-family: ${defaultTheme.fonts.primary};
  color: #333;
  font-size: 15px;
  li {
    padding: 2px 0px;
    strong {
      color: #333;
      padding-right: 2px;
      font-weight: bold;
    }
  }
`
const Title = styled.h1`
  width: 100%;
  text-align: left;
  font-size: 20px;
`

const Checkbox = styled.input`
  font-size: 16px;
  background-color: #FFF;
  border-radius: 1px;
  cursor: pointer;
  padding: 6px 10px;
  border-bottom: ${props => props.error ? '2px solid red' : `2px solid ${defaultTheme.colors.primary.default}`};
  transition: all 0.2s linear;
  height: 20px;
  width: 20px;
`
const Label = styled.label`
  align-items: center;
  /* background-color: #FFF; */
  cursor: pointer;
  justify-content: space-between;
  display: flex;
  color: ${props => props.error ? 'red' : '#484848'};
  font-family: ${defaultTheme.fonts.secondary};
  font-weight: 500;
  box-sizing: border-box;
  justify-content: flex-end;
  padding: 10px;
  width: 100%;
`

const Group = styled.div`
`

/* const mock = {
  datas: '21 a 24 mar 2018 (4 dias)',
  montagem: '20 mar 2018',
  horarioInicio: '8h',
  horarioTermino: '20h',
  formatoSala: 'Qualquer formato',
  numeroPessoas: 200,
  necessita: 'palco/praticável, mesa de apoio, mesa diretora, púlpito e sala de apoio',
  observacoes: ''
} */

const dateBriefing = (start, end) => {
  return (
    `${formatDateToLocale(start)} a
     ${formatDateToLocale(end)}
     (${diffBetweenDates(start, addDays(end, 1))} dia${diffBetweenDates(start, addDays(end, 1)) > 1 ? 's': ''})`
  )
}

const BriefingInformation = (
  { briefing:
    { startDate,
      endDate,
      startTime,
      endTime,
      roomFormat,
      roomItems,
      roomType,
      mounting,
      amountOfPeople,
      observations,
      id
    },
    availability,
    budget,
    changeAvailability,
    setAvailability,
    checkIfCanChangeAvailability,
    differences,
    index
  }) => {
    if(!checkIfCanChangeAvailability && budget.find(item => item.roomBriefingId === id)) {
      var state = budget.find(item => item.roomBriefingId === id).hasAvailability
      state !== availability && setAvailability(budget.find(item => item.roomBriefingId === id).hasAvailability)
    }
    return <Fragment>
      <Group style={{ display: 'flex' }}>
        <Title style={{ padding: '10px' }}>{roomFormat}</Title>
        <Label>
          Não tenho disponibilidade para este evento
          <Checkbox
            checked={!availability}
            name={'Não tenho disponibilidade para este evento'}
            id={'availability'}
            style={{ paddingLeft: '5px' }}
            onChange={changeAvailability}
            type='checkbox' />
        </Label>
      </Group>
      <Card>
        <RoomBriefingInfo>
          <li style={{ color: checkIfItemWasEdited(differences[index], 'observations') && '#F39C12' }}>
            <strong style={{textTransform: 'uppercase', display: 'block', fontSize: '14px'}}>Observações do Cliente:</strong>
            {observations || 'Sem observações'}
          </li>
        </RoomBriefingInfo>
      </Card>
      <Card>
        <RoomBriefingInfo>
          <li style={{ color: checkIfItemsWasEdited(differences[index], ['startDate', 'endDate']) && '#F39C12' }}>
            <strong>Datas:</strong>
            {dateBriefing(startDate, endDate)}
          </li>
          <li style={{ color: checkIfItemWasEdited(differences[index], 'mounting') && '#F39C12' }}>
            <strong>Montagem:</strong>
            {mounting ? removeDays(startDate, 1) : 'Não'}
          </li>
          <li style={{ color: checkIfItemWasEdited(differences[index], 'startTime') && '#F39C12' }}>
            <strong>Horário de Início:</strong>
            {startTime === '00:00:00' ? 'Horário não informado' : formatTime(startTime)}
          </li>
          <li style={{ color: checkIfItemWasEdited(differences[index], 'endTime') && '#F39C12' }}>
            <strong>Horário de Término:</strong>
            {endTime === '00:00:00' ? 'Horário não informado' : formatTime(endTime)}
          </li>
          <li style={{ color: checkIfItemWasEdited(differences[index], 'roomType') && '#F39C12' }}>
            <strong>Formato de Sala:</strong>
            {roomType}
          </li>
          <li style={{ color: checkIfItemWasEdited(differences[index], 'amountOfPeople') && '#F39C12' }}>
            <strong>Número de pessoas:</strong>
            {amountOfPeople && `${amountOfPeople} pessoas`}
          </li>
          <li style={{ color: checkIfItemWasEdited(differences[index], 'roomItems') && '#F39C12' }}>
            <strong>Necessita de:</strong>
            {roomItems && roomItems.join(', ')}
          </li>
        </RoomBriefingInfo>
      </Card>
    </Fragment>
  }

export default BriefingInformation
