/* LIBS */
import React, { Component } from 'react'
// import { connect } from 'react-redux'
import config from '../../../../middleware/config'

import styled from 'styled-components'
/* ACTIONS */
import { fetchEvent } from '../../../../middleware/apiWrapper'
/* STYLES */
import { defaultTheme } from '../../../styles/themes'
/* I18n */
import i18n from '../../../../i18n'
/* UI */
import CheckboxButton from '../../../components/ui/CheckboxButton'
import Button from '../../../components/ui/Button'
import Loader from '../../../components/Loader'
import Header from '../../Header'
import Alert from '../../../components/ui/Alert'
import BreadCrumb from '../../../components/BreadCrumb'

const Main = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #FAFAFA;
`

const Content = styled.main`
  background-color: #FAFAFA;
  min-height: 100vh;
  width: 100%;
  padding: 0 3%;
  box-sizing: border-box;
  @media (min-width: 768px) {
    padding: 0 20%;
  }
`

const Steps = styled.span`
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  font-size: 14px;
  display: flex;
  padding: 15px 10px 3px 10px;
  color: #767676;
`
const Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  font-family: ${defaultTheme.fonts.secondary};
  padding: 3px 10px 13px 10px;
  color: #484848;
`

const Stepper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  box-sizing: border-box;
`
const StepperDescription = styled.div`
`

const Form = styled.form`
  flex-wrap: wrap;
  display: flex;
  padding: 0px 10px;
`


class Services extends Component {
  state = {
    event: {},
    isLoading: false,
    errorMessage: false,
    servicesSelected: []
  }

  componentDidMount() {
    this.loadEventData()
  }

  submit(e) {
    e.preventDefault()
    const { servicesSelected, event } = this.state
    // Ale pediu para tirar isso aqui ='(
    // if (!servicesSelected.includes('room') && servicesSelected.includes('foodsAndBeverages')) {
    //   return alert('Não é possível solicitar alimentos e bebidas sem solicitar ao menos uma sala.')
    // }
    // else
    if (!servicesSelected.includes('room') && !servicesSelected.includes('foodsAndBeverages') && !servicesSelected.includes('lodging')) {
      return alert('Selecione ao menos uma opção.')
    }
    event.services.room.requested = servicesSelected.includes('room')
    event.services.foodsAndBeverages.requested = servicesSelected.includes('foodsAndBeverages')
    event.services.lodging.requested = servicesSelected.includes('lodging')
    event.services.aero.requested = servicesSelected.includes('aero')
    event.services.transfer.requested = servicesSelected.includes('transfer')
    event.services.equipments.requested = servicesSelected.includes('equipments')
    event.services.otherServices.requested = servicesSelected.includes('otherServices')

    this.setState({
      event
    }, () => {
      this.fetchEventUpdate(this.state.event)
    })
  }

  loadEventData = () => {
    const { id } = this.props.match.params
    this.setState({ isLoading: true })
    fetchEvent(id)
      .then(data => {
        this.setState({
          isLoading: false,
          event: data,
          servicesSelected: this.getServicesSelected(data.services)
        })
      })
  }

  fetchEventUpdate = (eventData) => {
    this.setState({ isLoading: true })
    const { api: { base, version } } = config
    const token = window.localStorage.getItem('user_token')
    const { id } = this.props.match.params
    const userType = window.localStorage.getItem('user_type')
    const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
    const endpoint = `${base}${version}${eventEndpoint}/${id}`
    const configFetch = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(eventData)
    }

    window.fetch(endpoint, configFetch)
      .then(response => {
        if (response.status >= 401 && response.status <= 403) window.localStorage.clear()
        if (response.ok) {
          return response.json()
        } else {
          throw new Error(response.statusText)
        }
      }).then((event) => {
        this.setState({ isLoading: false })
        if (!event.success) {
          this.setState({ errorMessage: event.message })
          return Promise.reject(event)
        } else {
         this.pagesRedirect(eventData.services, id)
        }
      }).catch(err => {
        this.setState({ isLoading: false })
        console.log('Error: ', err)
      })
  }

  pagesRedirect = (services, id) => {
    const { history } = this.props
    const servicesSelected = this.getServicesSelected(services)
    const servicesWithId = this.getServicesWithId(services)

    if (servicesSelected.includes('lodging') && !servicesWithId.includes('lodging')) {
      history.push(`/organizer/event/lodging/${id}/${this.state.event.services.lodging.id ? this.state.event.services.lodging.id : ''}`)
    } else if (servicesSelected.includes('room') && !servicesWithId.includes('room')) {
      history.push(`/organizer/event/rooms/${id}/${this.state.event.services.room.id ? this.state.event.services.room.id : ''}`)
    } else if (servicesSelected.includes('foodsAndBeverages') && !servicesWithId.includes('foodsAndBeverages')) {
      history.push(`/organizer/event/food-and-beverage/${id}/${this.state.event.services.foodsAndBeverages.id ? this.state.event.services.foodsAndBeverages.id : ''}`)
    } else {
      history.push(`/organizer/event/resume/${id}`)
    }
  }

  getServicesSelected = (services) => {
    return Object.keys(services).filter(i => {
      return services[i].requested
    })
  }

  getServicesWithId = (services) => {
    return Object.keys(services).filter(i => {
      return services[i].id
    })
  }

  changeServices = ({ target: { name } }) => {
    const { servicesSelected } = this.state
    if (servicesSelected.includes(name)) {
      const index = servicesSelected.indexOf(name);
      if (index !== -1) servicesSelected.splice(index, 1)
    } else {
      servicesSelected.push(name)
    }
    this.setState({ servicesSelected: servicesSelected })
  }

  render() {
    const { language } = this.props
    const { errorMessage, isLoading, servicesSelected } = this.state
    const { id } = this.props.match.params
    return (
      <Main>
        <Header />
        <BreadCrumb options={
          [
            {link: '/organizer', name: 'Home'},
            {link: `/organizer/event/${id}`, name: 'Dados gerais do evento'},
            {name: 'O que você deseja contratar para este evento'}
          ]
          } />
        <Content>
          {isLoading ? <Loader /> : null}
          <Stepper>
            {/*             <StepperBack>
              <Icons
                icon={'arrow-back'}
                color={'#333'}
                width={40}
                height={20}
                onClick={() => this.props.history.push('/organizer/event')} />
            </StepperBack> */}
            <StepperDescription>
              <Steps>Etapa 2 de 2</Steps>
              <Title>O que você deseja contratar para este evento?</Title>
            </StepperDescription>
          </Stepper>
          {errorMessage ? <Alert type={'negative'}>{errorMessage}</Alert> : null}
          <Form onSubmit={this.submit.bind(this)} method='POST'>
            <CheckboxButton
              placeholder={'Hospedagem'}
              icon={'bed-outline'}
              name={'lodging'}
              value={'lodging'}
              onClick={this.changeServices}
              checked={servicesSelected.includes('lodging')}
              width={40} height={20} />
            <CheckboxButton
              placeholder={'Salas'}
              icon={'room-outline'}
              name={'room'}
              value={'room'}
              onClick={this.changeServices}
              checked={servicesSelected.includes('room')}
              width={40} height={20} />
            <CheckboxButton
              placeholder={'Alimentos e bebidas'}
              icon={'food-beverage-outline'}
              name={'foodsAndBeverages'}
              value={'foodsAndBeverages'}
              onClick={this.changeServices}
              checked={servicesSelected.includes('foodsAndBeverages')}
              width={40} height={20} />
            <Button
              type='submit'
              category={'secondary'}
              fontWeight={800}
              full>
              {i18n.t('signUp.progress_action', { language })}
            </Button>
          </Form>
        </Content>
      </Main>
    )
  }
}

export default Services
