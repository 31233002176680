/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
import { defaultTheme } from "../../../styles/themes"
/* COMPONENTS */
import Header from '../../../components/Header'
import Button from '../../../components/ui/Button'
import Card from '../../../components/ui/Card'
import Icons from '../../../components/ui/Icons'
import PercentageInput from '../../../components/ui/PercentageInput'
import Textarea from '../../../components/ui/Textarea'
import CheckBox from '../../../components/ui/CheckBox'
import Input from '../../../components/ui/Input'
import Select from '../../../components/ui/Select'
import Title from '../../../components/ui/Title'
import Subtitle from '../../../components/ui/Subtitle'
import MoneyInput from '../../../components/ui/MoneyInput'
import BreadCrumb from '../../../components/BreadCrumb'
import Loader from '../../../components/Loader'
import config from '../../../../middleware/config'
import { Get, Post, Put } from '../../../../utils/request'
import CommisedRate from '../Rooms/CommisedRate'
import ExpirationBudget from '../Rooms/ExpirationBudget'
import { getNameFromKey } from '../../../../utils/fabTexts'
import { checkIfItemWasEdited, checkIfItemWasEditedForDates } from '../../../../utils/checkEdition'
import moment from 'moment'
import formatCurrency from '../../../../utils/money';

const CRITICAL = 'CRITICAL'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #FAFAFA;
  width: 100%;
  min-height: 95vh;
  display: flex;
  & * { font-family: 'Open Sans', sans-serif }
`

const Main = styled.div`
  width: 80%;
  padding: 20px 30px;
  background: #FAFAFA;
  flex: 1 0 auto;
`

const Group = styled.div`
`
const Label = styled.label`
  align-items: center;
  background-color: #FFF;
  cursor: pointer;
  display: flex;
  color: ${props => props.error ? 'red' : (props.edited ? '#F39C12' : '#484848')};
  font-family: ${defaultTheme.fonts.secondary};
  font-weight: 500;
  box-sizing: border-box;
  font-size: 13px;
  padding-left: 10px;
  width: 100%;
`

const Tag = styled.div`
  padding: 3px 5px;
  font-size: 12px;
  font-family: ${defaultTheme.fonts.primary};
  color: #FAFAFA;
  background-color: ${props => props.edited ? '#F39C12' : (props.contraproposta ? '#8800dd' : '#009688')};
  border: none;
  border-radius: 15px;
  position: absolute;
  right: 10px;
  top: 10px;
  text-transform: uppercase;
  z-index: 9;
`

class BriefingLodging extends Component {
  constructor() {
    super()
    this.state = {
      isLoading: false,
      briefings: [],
      observations: '',
      observationsValue: '',
      itemsOnApartment: false,
      itemsOnApartmentSelected: false,
      itemsOnApartmentValue: '',
      coffee: false,
      internet: false,
      parking: false,
      fullBoard: false,
      lunch: false,
      dinner: false,
      allInclusive: false,
      others: false,
      otherValue: '',
      briefingId: '',
      CommisedOpen: false,
      expirationDays: '',
      commission: {},
      reseller: undefined,
      differences: {},
      lastUpdateType: '',
      event: { client: {} }
    }
  }

  componentDidMount() {
    const { match: { params: { event } } } = this.props
    if (event) {
      this.fetchBriefing()
      this.fetchEvent()
    }
  }

  fetchEditDifferences () {
    const { api: { base, version } } = config
    const id = this.state.briefingId
    const endpoint = `${base}${version}/briefing/lodging/${id}/rollback/difference`

    Get(endpoint).then((differences) => {
      this.setState({ differences: differences.apartments, isLoading: false })
    }).catch(err => {
      console.log('Error: ', err)
    })
  }

  fetchBriefing () {
    this.setState({ isLoading: true })
    const { api: { base, version, routes: { events } } } = config
    const { match: { params: { event } } } = this.props
    const endpoint = `${base}${version}${events}/${event}/briefing/lodging`

    Get(endpoint)
    .then((response) => {
      const { match: { params: { budget } } } = this.props
      if(budget) {
        const newEndpoint = `${base}${version}/budget/lodging/${budget}`
        Get(newEndpoint)
        .then((budgetInfo) => {
          const briefings = []
          response.apartments.forEach((apartment) => {
            const budget = budgetInfo.apartmentsBudget.find(item => item.apartmentBriefingId === apartment.id)
            let briefing = apartment
            briefing.selected = false
            briefing.dates.map((date) => {
              return new Date(date)
            })
            if(budget) {
              briefing.selected = true
              briefing.price = budget.price.amount
              briefing.category = budget.category
              briefing.haveTaxes = budget.fee.amount === 0 ? 'true' : 'false'
              if (budget.fee.amount) briefing.serviceTax = budget.fee.amount.toString()
            }
            return briefings.push(briefing)
          })
          this.setState({
            isLoading: budgetInfo.status === 'REVIEW',
            lastUpdateType: budgetInfo.lastUpdateType,
            briefings,
            observationsValue: budgetInfo.observations,
            briefingId: response.id,
            counterProposals: budgetInfo.counterProposals,
            coffee: budgetInfo.includedItems.breakfast,
            internet: budgetInfo.includedItems.internet,
            parking: budgetInfo.includedItems.parking,
            fullBoard: budgetInfo.includedItems.fullBoard,
            lunch: budgetInfo.includedItems.halfBoardLunch,
            dinner: budgetInfo.includedItems.halfBoardDinner,
            allInclusive: budgetInfo.includedItems.allInclusive,
            others: budgetInfo.includedItems.others.sent,
            otherValue: budgetInfo.includedItems.others.description,
            itemsOnApartment: response.itemsOnApartment,
            itemsOnApartmentSelected: budgetInfo.itemsOnApartmentPrice ? budgetInfo.itemsOnApartmentPrice.amount > 0 : false,
            itemsOnApartmentValue: budgetInfo.itemsOnApartmentPrice ? budgetInfo.itemsOnApartmentPrice.amount : 0 }, () => budgetInfo.status === 'REVIEW' && this.fetchEditDifferences())
        }).catch(err => {
          this.setState({ isLoading: false })
          console.log('Error: ', err)
        })
      } else {
        const briefings = []
        response.apartments.forEach((apartment) => {
          let briefing = apartment
          briefing.selected = true
          briefing.dates.map((date) => {
            return new Date(date)
          })
          return briefings.push(briefing)
        })
        this.setState({ isLoading: false, briefings, itemsOnApartmentSelected: true, observations: response.observations, briefingId: response.id, itemsOnApartment: response.itemsOnApartment })
      }
    }).catch(err => {
      this.setState({ isLoading: false })
      console.log('Error: ', err)
    })
  }

  fetchEvent() {
    const { api: { base, version, routes: { supplier, events } } } = config
    const { match: { params: { event } } } = this.props
    const endpoint = `${base}${version}${supplier}${events}/${event}`

    Get(endpoint).then((response) => {
      this.setState({ event: response && response.event })
    }).catch(err => {
      alert('Erro ao buscar os dados, entre em contato com o suporte via chat')
      console.log('Error: ', err)
    })
  }

  itemClick = (id) => {
    const briefings = this.state.briefings
    const index = briefings.findIndex(briefing => briefing.id === id)
    if (index !== -1) {
      briefings[index].selected = !briefings[index].selected
      this.setState({ briefings })
    }
  }

  itemsOnApartmentClick = () => {
    const itemsOnApartmentSelected = this.state.itemsOnApartmentSelected
    this.setState({ itemsOnApartmentSelected: !itemsOnApartmentSelected })
  }

  includeClick = (session) => {
    const state = this.state
    state[session] = !state[session]
    this.setState(state)
  }

  itemsOnApartmentHandlerValue = (input) => {
    const { value } = input.target
    this.setState({ itemsOnApartmentValue: value })
  }

  otherHandlerValue = (input) => {
    const { value } = input.target
    this.setState({ otherValue: value })
  }

  observationsHandlerValue = (input) => {
    const { value } = input.target
    this.setState({ observationsValue: value })
  }

  handlerValue = (input, id) => {
    const { value, name } = input.target
    const briefings = this.state.briefings
    const index = briefings.findIndex(briefing => briefing.id === id)
    if (index !== -1) {
      briefings[index][name] = value
      this.setState({ briefings })
    }
  }

  submitCommisedRate = ({ commised, reason, value }) => {
    let { commission } = this.state
    commission = {
      fee: {
        amount: value,
        type: 'PERCENTAGE'
      },
      reason: reason,
      hasCommission: commised === 'yes'
    }
    this.setState({
      commission,
      CommisedOpen: false,
      ExpirationBudgetOpen: true
    })
  }

  submit = ({ expirationDays }) => {
    this.setState({
      expirationDays: expirationDays,
      ExpirationBudgetOpen: false, isLoading: true
    }, () => {
      const { api: { base, version } } = config
      const { match: { params: { budget } } } = this.props
      if(this.props.location.state && (this.props.location.state.counterProposal === 'ACCEPTED' || this.props.location.state.counterProposal === 'NEW')) {
        const endpoint = `${base}${version}/budget/lodging/${this.props.location.state.counterProposal === 'ACCEPTED' ? 'counter-proposal' : 'supplier-counter-proposal'}/${budget}`
        Put(endpoint, this.generateJSON())
        .then(() => {
          const { match: { params: { event } } } = this.props
          this.props.history.push(`/budget/resume/${event}`)
        }).catch(err => {
          console.error(err.message)
          this.setState({
            isLoading: false
          })
          alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
        })
      }
      else if(budget) {
        const endpoint = `${base}${version}/budget/lodging/${budget}`
        Put(endpoint, this.generateJSON())
        .then(() => {
          const { match: { params: { event } } } = this.props
          this.props.history.push(`/budget/resume/${event}`)
        }).catch(err => {
          console.error(err.message)
          this.setState({
            isLoading: false
          })
          alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
        })
      } else {
        const endpoint = `${base}${version}/budget/lodging/`
        Post(endpoint, this.generateJSON())
        .then(() => {
          const { match: { params: { event } } } = this.props
          this.props.history.push(`/budget/resume/${event}`)
        }).catch(err => {
          console.error(err.message)
          this.setState({
            isLoading: false
          })
          alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
        })
      }
    })
  }

  submitForm = () => {
    if (this.isValid() && this.verificaNenhumSelecionado() && this.isCounterProposalValid() && this.isNewCounterProposalValid()) {
      if(this.state.event.supplierChainId && this.state.briefings.some(item => item.selected)) {
        let { commission } = this.state
        commission = {
          fee: {
            amount: 0,
            type: 'PERCENTAGE'
          },
          reason: '',
          hasCommission: false
        }
        this.setState({
          commission,
          CommisedOpen: false,
          ExpirationBudgetOpen: true
        })
      } else if(this.state.briefings.some(item => item.selected)) {
        this.setState({ CommisedOpen: true })
      } else {
        const { api: { base, version } } = config
        const { match: { params: { budget } } } = this.props
        if(this.props.location.state && (this.props.location.state.counterProposal === 'ACCEPTED' || this.props.location.state.counterProposal === 'NEW')) {
          const endpoint = `${base}${version}/budget/lodging/${this.props.location.state.counterProposal === 'ACCEPTED' ? 'counter-proposal' : 'supplier-counter-proposal'}/${budget}`
          Put(endpoint, this.generateJSON())
          .then(() => {
            const { match: { params: { event } } } = this.props
            this.props.history.push(`/budget/resume/${event}`)
          }).catch(err => {
            console.error(err.message)
            this.setState({
              isLoading: false
            })
            alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
          })
        }
        else if(budget) {
          const endpoint = `${base}${version}/budget/lodging/${budget}`
          Put(endpoint, this.generateJSON())
          .then(() => {
            const { match: { params: { event } } } = this.props
            this.props.history.push(`/budget/resume/${event}`)
          }).catch(err => {
            console.error(err.message)
            this.setState({
              isLoading: false
            })
            alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
          })
        } else {
          const endpoint = `${base}${version}/budget/lodging/`
          Post(endpoint, this.generateJSON())
          .then(() => {
            const { match: { params: { event } } } = this.props
            this.props.history.push(`/budget/resume/${event}`)
          }).catch(err => {
            console.error(err.message)
            this.setState({
              isLoading: false
            })
            alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
          })
        }
      }
    } else {
      !this.isCounterProposalValid() && alert('Você aceitou a contraproposta, por favor preencha os campos para que o valor da nova oferta seja menor ou igual a contraproposta solicitada.')
      !this.isNewCounterProposalValid() && alert('Você não pode criar uma contraproposta com o valor acima do valor inicial enviado.')
      !this.isValid() && alert('Preencha todos os campos obrigatórios')
    }
  }

  generateJSON = () => {
    const { match: { params: { event } } } = this.props
    return {
      briefingId: this.state.briefingId,
      eventId: event,
      observations: this.state.observationsValue,
      amountOfBlockedDays: this.state.expirationDays,
      commission: this.state.commission,
      counterProposals: this.state.counterProposals,
      apartmentsBudget: this.state.briefings.reduce((briefings, briefing) => {
        if (briefing.selected) {
          briefings.push({
            apartmentBriefingId: briefing.id,
            price: briefing.price,
            fee: briefing.haveTaxes === 'false' ? (parseFloat(briefing.serviceTax || 0) + parseFloat(briefing.issTax || 0) + parseFloat(briefing.otherTax || 0)) : 0,
            category: briefing.category
          })
        }
        return briefings
      }, []),
      includedItems: {
        breakfast: this.state.coffee,
        internet: this.state.internet,
        parking: this.state.parking,
        allInclusive: this.state.allInclusive,
        fullBoard: this.state.fullBoard,
        halfBoardLunch: this.state.lunch,
        halfBoardDinner: this.state.dinner,
        others: {
          sent: this.state.others,
          description: this.state.otherValue
        }
      },
      itemsOnApartmentPrice: { amount: this.state.itemsOnApartmentSelected ? this.state.itemsOnApartmentValue : 0}
    }
  }

  splitDates = (requests) => {
    return requests.map((info, index) => {
      if (index === 0) return moment(info, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br').format('DD-MM-YYYY')
      return ", " + moment(info, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br').format('DD-MM-YYYY')
    })
  }

  verificaNenhumSelecionado = () => {
    if(!this.state.briefings.find(item => item.selected) && (
      this.state.coffee || this.state.internet || this.state.parking ||
      this.state.fullBoard || this.state.lunch || this.state.dinner ||
      this.state.allInclusive || this.state.others
    )) {
      alert('Selecione pelo um apartamento')
      return false
    } else return true
  }

  isValid = () => {
    var isValid = true
    this.state.briefings.forEach(apartment => {
      if(apartment.selected) {
        if(!apartment.price || apartment.price <= 0) {
          isValid = false
        } else if(!apartment.haveTaxes) {
          isValid = false
        } else if(!apartment.category) {
          isValid = false
        }
      }
    })
    return isValid
  }

  isCounterProposalValid = () => {
    if(this.props.location.state && this.props.location.state.counterProposal === 'ACCEPTED') {
      return this.props.location.state.requestedPrice >= this.total()
    } else return true
  }

  isNewCounterProposalValid = () => {
    if(this.props.location.state && this.props.location.state.counterProposal === 'NEW') {
      return this.props.location.state.initialPrice >= this.total()
    } else return true
  }

  total = () => {
    return this.state.briefings.reduce((items, item) => {
      if(item.selected) {
        items += (item.price * item.numberOfApartments) * (item.dates.length - 1)
      }
      return items
    }, 0) + (this.state.itemsOnApartment ? parseInt(this.state.itemsOnApartmentValue) * this.state.briefings.reduce((items, item) => {
      if(item.selected) {
        items += item.numberOfApartments
      }
      return items
    }, 0) : 0)
  }

  render() {
    const { match: { params: { event } } } = this.props
    const temQueEnviarContraproposta = this.props.location.state && (this.props.location.state.counterProposal === 'ACCEPTED' || this.props.location.state.counterProposal === 'NEW')
    return (
      <Container>
        <Header />
        <BreadCrumb options={
          [
            {link: '/', name: 'Home'},
            {link: `/budget/resume/${event}`, name: 'Resumo'},
            {name: 'Hospedagem'}
          ]
        } />
        <CommisedRate
          isOpen={this.state.CommisedOpen}
          onClose={() => this.setState({ CommisedOpen: false })}
          onSubmit={this.submitCommisedRate}
          client={this.state.event && this.state.event.client}
        />
        <ExpirationBudget
          initialValue={this.state.event.supplierChainId ? 0 : 10}
          isOpen={this.state.ExpirationBudgetOpen}
          onClose={() => this.setState({ ExpirationBudgetOpen: false })}
          onSubmit={this.submit}
          text='bloqueados este(s) quarto(s)'
        />
        <Main>
          {this.state.isLoading ? <Loader /> : null}
          <Group style={{width: '80%', margin: 'auto', position: 'relative'}} >
            {temQueEnviarContraproposta && <Tag contraproposta>Contraproposta</Tag>}
            <Title style={{ margin: '15px' }}>Hospedagem</Title>
            <Card style={{ margin: '15px', width: 'unset' }}>
              <Title style={{ margin: '0 0 5px 0', fontWeight: '800', fontSize: '12px', textTransform: 'uppercase' }} >Observações do cliente:</Title>
              <Subtitle style={{ marginBottom: '0px' }}>{this.state.observations || 'Sem observações'}</Subtitle>
            </Card>
            {
              temQueEnviarContraproposta && <Group style={{ textAlign: 'right', padding: '0px 15px' }}>
                <Subtitle>Valor da contraproposta <span style={{ fontWeight: 'bold' }}>{formatCurrency(this.props.location.state.requestedPrice)}</span>&nbsp;&nbsp;&nbsp;&nbsp;Valor da nova oferta <span style={{ fontWeight: 'bold', color: 'red' }}>{formatCurrency(this.total())}</span></Subtitle>
              </Group>
            }
            {this.state.briefings.map((briefing, index) =>
              <CheckBox
                key={`session_${index}`}
                onClick={() => !temQueEnviarContraproposta && this.itemClick(briefing.id)}
                label={getNameFromKey(briefing.keyName) + ` (${briefing.numberOfApartments} aptos)`}
                style={{width: 'auto', margin: '15px'}}
                edited={this.state.differences && checkIfItemWasEdited(this.state.differences[index], 'numberOfApartments')}
                observation={'Tenho disponibilidade para este evento'}
                checked={briefing.selected}>
                <Group style={{ cursor: 'pointer' }} onClick={() => this.itemClick(briefing.id)}>
                  <Label edited={this.state.differences && checkIfItemWasEditedForDates(this.state.differences[index], 'dates')}>Check in: <b style={{ paddingLeft: '5px', paddingRight: '10px' }}>{briefing.dates.length > 0 && ' ' + moment(briefing.dates[0], 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br').format('DD-MM-YYYY')}</b> Check out: <b style={{ paddingLeft: '5px' }}>{briefing.dates.length > 0 && ' ' + moment(briefing.dates[briefing.dates.length - 1], 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br').format('DD-MM-YYYY')}</b></Label>
                  {!briefing.selected && <Group style={{ margin: 'auto', display: 'flex', padding: '20px' }}>
                      <Label style={{ width: '60%' }}></Label>
                      {briefing.keyName === 'single' && <Icons style={{ width: '10%', margin: 'auto' }} height='35px' icon='double-bed' />}
                      {briefing.keyName === 'single' && <Label style={{ width: '30%', margin: 'auto' }}>1 cama de casal</Label>}
                      {briefing.keyName === 'double' && <Icons style={{ width: '10%', margin: 'auto' }} height='35px' icon='double-bed' />}
                      {briefing.keyName === 'double' &&<Label style={{ width: '30%', margin: 'auto' }}>1 cama de casal</Label>}
                      {briefing.keyName === 'twin' && <Icons style={{ width: '10%', margin: 'auto' }} height='35px' icon='single-bed' />}
                      {briefing.keyName === 'twin' && <Icons style={{ width: '10%', margin: 'auto' }} height='35px' icon='single-bed' />}
                      {briefing.keyName === 'twin' && <Label style={{ width: '20%', margin: 'auto' }}>2 camas de solteiro</Label>}
                      {briefing.keyName === 'triple' && <Icons style={{ width: '5%', margin: 'auto' }} height='35px' icon='single-bed' />}
                      {briefing.keyName === 'triple' && <Icons style={{ width: '5%', margin: 'auto' }} height='35px' icon='single-bed' />}
                      {briefing.keyName === 'triple' && <Icons style={{ width: '5%' , margin: 'auto'}} height='35px' icon='single-bed' />}
                      {briefing.keyName === 'triple' && <Label style={{ width: '25%', margin: 'auto' }}>3 camas de solteiro</Label>}
                    </Group>}
                </Group>
                {briefing.selected && <Group>
                    <Group style={{ display: 'flex' }}>
                      <MoneyInput
                        name={'price'}
                        value={briefing.price}
                        error={!briefing.price || briefing.price <= 0}
                        label={'Valor por dia, por quarto'}
                        onChange={input => this.handlerValue(input, briefing.id)}
                        width='50%'
                        criticalUpdate={this.state.lastUpdateType === CRITICAL}
                        criticalPreviouslyValue={briefing.price}
                        isRequired />
                      <Group style={{ width: '50%', margin: 'auto', display: 'flex', paddingLeft: '20px' }}>
                        {briefing.keyName === 'single' && <Icons style={{ width: '30%', margin: 'auto' }} height='35px' icon='double-bed' />}
                        {briefing.keyName === 'single' && <Label style={{ width: '70%', margin: 'auto' }}>1 cama de casal</Label>}
                        {briefing.keyName === 'double' && <Icons style={{ width: '30%', margin: 'auto' }} height='35px' icon='double-bed' />}
                        {briefing.keyName === 'double' &&<Label style={{ width: '70%', margin: 'auto' }}>1 cama de casal</Label>}
                        {briefing.keyName === 'twin' && <Icons style={{ width: '15%', margin: 'auto' }} height='35px' icon='single-bed' />}
                        {briefing.keyName === 'twin' && <Icons style={{ width: '15%', margin: 'auto' }} height='35px' icon='single-bed' />}
                        {briefing.keyName === 'twin' && <Label style={{ width: '70%', margin: 'auto' }}>2 camas de solteiro</Label>}
                        {briefing.keyName === 'triple' && <Icons style={{ width: '15%', margin: 'auto' }} height='35px' icon='single-bed' />}
                        {briefing.keyName === 'triple' && <Icons style={{ width: '15%', margin: 'auto' }} height='35px' icon='single-bed' />}
                        {briefing.keyName === 'triple' && <Icons style={{ width: '15%', margin: 'auto' }} height='35px' icon='single-bed' />}
                        {briefing.keyName === 'triple' && <Label style={{ width: '55%', margin: 'auto' }}>3 camas de solteiro</Label>}
                      </Group>
                    </Group>
                    <Input
                      type={'text'}
                      name={'category'}
                      label={'Categoria do apartamento'}
                      error={!briefing.category}
                      placeholder={'Digite a categoria '}
                      isRequired
                      value={briefing.category}
                      criticalUpdate={this.state.lastUpdateType === CRITICAL}
                      criticalPreviouslyValue={briefing.category}
                      onChange={input => this.handlerValue(input, briefing.id)} />
                    <Select
                      label="As taxas estão inclusas?"
                      name={'haveTaxes'}
                      style={{ width: 'calc(100% - 20px)' }}
                      error={!briefing.haveTaxes}
                      value={briefing.haveTaxes}
                      isRequired
                      onChange={input => this.handlerValue(input, briefing.id)}
                      criticalUpdate={this.state.lastUpdateType === CRITICAL}
                      criticalValues={[{ text: 'Sim', value: true }, { text: 'Não', value: false }]}
                      criticalPreviouslyValue={briefing.haveTaxes}>
                      <option value="">Selecione uma opção</option>
                      <option value={true}>Sim</option>
                      <option value={false}>Não</option>
                    </Select>
                    {
                      briefing.haveTaxes === 'false' && <Group>
                        <Group style={{ display: 'flex' }}>
                          <PercentageInput
                            type={'number'}
                            name={'serviceTax'}
                            label={'Taxa de serviço'}
                            style={{ width: '50%' }}
                            value={briefing.serviceTax}
                            placeholder={'0'}
                            onChange={input => this.handlerValue(input, briefing.id)}
                            criticalUpdate={this.state.lastUpdateType === CRITICAL}
                            criticalPreviouslyValue={briefing.serviceTax} />
                          <PercentageInput
                            type={'number'}
                            name={'issTax'}
                            label={'ISS'}
                            style={{ width: '50%' }}
                            value={briefing.issTax}
                            placeholder={'0'}
                            onChange={input => this.handlerValue(input, briefing.id)}
                            criticalUpdate={this.state.lastUpdateType === CRITICAL}
                            criticalPreviouslyValue={briefing.issTax} />
                        </Group>
                        <PercentageInput
                          type={'number'}
                          name={'otherTax'}
                          label={'Outras taxas'}
                          value={briefing.otherTax}
                          placeholder={'0'}
                          onChange={input => this.handlerValue(input, briefing.id)}
                          criticalUpdate={this.state.lastUpdateType === CRITICAL}
                          criticalPreviouslyValue={briefing.otherTax} />
                      </Group>
                    }
                  </Group>
                }
              </CheckBox>
            )}
            {
              this.state.itemsOnApartment &&
              <CheckBox
                onClick={() => this.itemsOnApartmentClick()}
                label='Entrega de brindes no apto'
                style={{width: 'auto', margin: '15px'}}
                checked={this.state.itemsOnApartmentSelected}>
                {this.state.itemsOnApartmentSelected && <MoneyInput
                  name={'price'}
                  value={this.state.itemsOnApartmentValue}
                  label={'Valor por quarto'}
                  onChange={input => this.itemsOnApartmentHandlerValue(input)}
                  isRequired />}
              </CheckBox>
            }
            <Group>
              <Subtitle style={{ paddingTop: '20px' }}>O que está incluso?</Subtitle>
              <CheckBox
                onClick={() => this.includeClick('coffee')}
                label='Café da manhã'
                style={{width: 'auto', margin: '15px'}}
                checked={this.state.coffee} />
              <CheckBox
                onClick={() => this.includeClick('internet')}
                label='Internet no apto'
                style={{width: 'auto', margin: '15px'}}
                checked={this.state.internet} />
              <CheckBox
                onClick={() => this.includeClick('parking')}
                label='Estacionamento'
                style={{width: 'auto', margin: '15px'}}
                checked={this.state.parking} />
              <CheckBox
                onClick={() => this.includeClick('fullBoard')}
                label='Pensão completa'
                style={{width: 'auto', margin: '15px'}}
                checked={this.state.fullBoard} />
              <CheckBox
                onClick={() => this.includeClick('lunch')}
                label='Meia pensão (almoço)'
                style={{width: 'auto', margin: '15px'}}
                checked={this.state.lunch} />
              <CheckBox
                onClick={() => this.includeClick('dinner')}
                label='Meia pensão (jantar)'
                style={{width: 'auto', margin: '15px'}}
                checked={this.state.dinner} />
              <CheckBox
                onClick={() => this.includeClick('allInclusive')}
                label='All inclusive'
                style={{width: 'auto', margin: '15px'}}
                checked={this.state.allInclusive} />
              <CheckBox
                onClick={() => this.includeClick('others')}
                label='Outros'
                style={{width: 'auto', margin: '15px'}}
                checked={this.state.others}>
                {this.state.others && <Textarea
                  type={'text'}
                  placeholder={'O que mais está incluso?'}
                  name={'observations'}
                  value={this.state.otherValue}
                  style={{ width: 'calc(100% - 20px)' }}
                  onChange={this.otherHandlerValue} />}
              </CheckBox>
            </Group>
            <Textarea
              type={'text'}
              label={'Observações'}
              placeholder={'Caso tenha alguma observação, escreva aqui.'}
              name={'observations'}
              value={this.state.observationsValue}
              onChange={this.observationsHandlerValue}
              criticalUpdate={this.state.lastUpdateType === CRITICAL}
              criticalPreviouslyValue={this.state.observationsValue}  />
          </Group>
        </Main>
        <Button
          type='submit'
          category={'secondary'}
          fontWeight={800}
          onClick={this.submitForm}
          style={{ width: '80%' }}
          full>
          Confirmar
        </Button>
      </Container>
    )
  }
}

export default BriefingLodging
