import React, { Component } from 'react'
import styled from 'styled-components'
import Icons from './ui/Icons'
import { defaultTheme } from '../styles/themes'
import moment from 'moment'

const Mensagem = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 10px;
  background-color: ${props => props.lida ? '#eaeaea' : '#fff'};
  border-bottom: 1px solid #eaeaea;
  cursor: pointer;
  text-decoration: none;
`

const IconMensagem = styled.div`
  border-radius: 100%;
  border: 2px solid #009688;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    fill: #009688!important;
  }
`

const DadosMensagem = styled.div`
  font-size: 12px;
  font-family: ${defaultTheme.fonts.primary};
  color: ${defaultTheme.colors.text.darker};
  line-height: 1.8;
`

const UltimaMensagemMinutos = styled.span`
  font-size: 12px;
  font-family: ${defaultTheme.fonts.primary};
  color: ${defaultTheme.colors.grey.light};
  font-weight: 200;
`

const DestaqueNome = styled.span`
  font-weight: 600;
  text-transform: initial;
`

export default class CardMensagem extends Component {
  constructor (props) {
    super(props)
    this.limitaCaracteres = this.limitaCaracteres.bind(this)
    this.urlFornecedor = this.urlFornecedor.bind(this)
    this.urlOrganizador = this.urlOrganizador.bind(this)
    this.redireciona = this.redireciona.bind(this)
    this.fromNow = this.fromNow.bind(this)
  }

  limitaCaracteres (string, qtd) {
    var nome = string
    nome.length > qtd ? nome = nome.substr(0, qtd) + '...' : nome = string
    return nome
  }

  urlFornecedor (status, idEvento) {
    return status === 'ORDERED'
      ? `/budget/all-info/${idEvento}`
      : `/budget/resume/${idEvento}/sent`
  }

  urlOrganizador (status, idEvento, idFornecedor, services) {
    return status === 'ORDERED'
      ? `/organizer/event/${idEvento}/resume/chosen`
      : this.verificaQualServicoTemQueRedirecionar(idEvento, idFornecedor, services)
  }

  verificaQualServicoTemQueRedirecionar (idEvento, idFornecedor, services) {
    if(services && services.length > 0) {
      if(services.find(item => item === 'TRANSFER')) return `/organizer/event/${idEvento}/budget/transfer/${idFornecedor}`
      if(services.find(item => item === 'OTHER_SERVICES')) return `/organizer/event/${idEvento}/budget/other-services/${idFornecedor}`
      else return `/organizer/event/${idEvento}/budget/account/${idFornecedor}`
    } else return `/organizer/event/${idEvento}/budget/account/${idFornecedor}`
  }

  redireciona (status, idEvento, idFornecedor, services) {
    const userType = window.localStorage.getItem('user_type')
    window.location = userType === 'SUPPLIER'
      ? this.urlFornecedor(status, idEvento)
      : this.urlOrganizador(status, idEvento, idFornecedor, services)
  }

  fromNow (data, locale = 'pt-BR') {
    return moment(data).locale(locale).fromNow()
  }

  render () {
    const { eventName, supplierName, lastMessage, eventStatus, eventId, supplierId, countUnseen, services } = this.props
    const userType = window.localStorage.getItem('user_type')
    return (
      <Mensagem lida={countUnseen === 0} onClick={() => { this.redireciona(eventStatus, eventId, supplierId, services) }}>
        <IconMensagem>
          <Icons
            style={{ margin: '5px' }}
            icon='icon_chat_01'
            color='#009688'
            width={30}
            height={30}
          />
        </IconMensagem>
        <DadosMensagem>
          {userType !== 'SUPPLIER'
            ? <p>Você tem {countUnseen !== 0 ? 'novas' : ''} mensagens de <DestaqueNome>{supplierName && supplierName.substr(0, 18)}</DestaqueNome> para <DestaqueNome>{this.limitaCaracteres(eventName, 24)}</DestaqueNome></p>
            : <p>Você tem {countUnseen !== 0 ? 'novas' : ''} mensagens para o evento <DestaqueNome>{this.limitaCaracteres(eventName, 39)}</DestaqueNome></p>}
          <UltimaMensagemMinutos>{this.fromNow(lastMessage)}</UltimaMensagemMinutos>
        </DadosMensagem>
      </Mensagem>
    )
  }
}
