/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
/* COMPONENTS */
import InputDatepicker from '../../../../components/InputDatepicker'
import RightBoxInput from '../../../../components/ui/RightBoxInput'
import LinkCustom from '../../../../components/ui/LinkCustom'
import Select from '../../../../components/ui/Select'
import Row from '../../../../components/ui/Row'
import Card from '../../../../components/ui/Card'
import Button from '../../../../components/ui/Button'
import Textarea from '../../../../components/ui/Textarea'
import InputAddress from '../../../../components/InputAddress'
import { pegaObjetoVazioDeRequisicao } from './helpers'
import EditableLabel from '../../../../components/ui/EditableLabel';

const Content = styled.div`
  /* padding: 10px; */
  width: 100%;
`

const Group = styled.div`
margin: 0;
padding: 0;
`

const InputDataCustom = styled.div`
.DayPicker-Month {
  margin: 10px 5px;
  font-size: 14px;
}
.DayPicker-NavButton{
  top: 8px;
}`

class RequestTransfer extends Component {
  state = {
    dados: pegaObjetoVazioDeRequisicao()
  }

  // Inicializar o estado com os valores default - se tiver a propiedade request dados vai receber -  se não retorna objeto vazio
  // Poder receber um objeto nas props e preencher o estado com ele
  // Alterar o input => alterar o estado DESTE OBJ
  // CONSOLE.LOG do estado em algum lugar
  // Receber das props o telefone do pai
  // Mudar o objeto de horas

  render () {
    const { request } = this.props
    const index = this.props.index
    return (
      <Content>
        <Card>
          <Row style={{ justifyContent: 'space-between' }}>
            <EditableLabel name='name' value={request.name} label={`Solicitação ${('0' + (index + 1)).slice(-2)}`} onChange={(input) => this.props.atualizaDados(input, index)} />
            {this.props.dados.length > 1 && <Button
              type='button'
              category={'secondary'}
              fontWeight={800}
              ghost
              small
              onClick={() => this.props.deletarRequisicao(index)}>
              Excluir
            </Button>}
          </Row>
          <Row>
            <Select
              isRequired
              label={'Tipo de Serviço'}
              name={'type'}
              value={request.type}
              error={request.type === ''}
              onChange={(input) => this.props.atualizaDados(input, index)}>
              <option value={''}>Selecione o tipo de serviço</option>
              <option value={'SHUTTLE'}>Shuttle (circular)</option>
              <option value={'TRASLADO_SO_IDA'}>Traslado só ida</option>
              <option value={'TRASLADO_IDA_VOLTA'}>Traslado ida e volta</option>
              <option value={'VEICULO_DISPOSICAO'}>Veículo a disposição</option>
            </Select>
            {request.type === 'VEICULO_DISPOSICAO' &&
              <Select
                isRequired
                label={'Quantas horas?'}
                onChange={(input) => this.props.atualizaDados(input, index)}
                value={request.vehicleTime}
                error={request.vehicleTime === ''}
                name={'vehicleTime'}>
                <option value={''}>Selecione uma opção</option>
                <option value={5}>Diária 5h</option>
                <option value={10}>Diária 10h</option>
              </Select>
            }
          </Row>
          <Row>
            <Group style={{ width: '50%' }}>
              <RightBoxInput
                placeholder={'0'}
                rightBox='pessoas'
                isRequired
                type='number'
                label={'Quantos passageiros?'}
                name={'passengers'}
                error={!(request.passengers > 0)}
                value={request.passengers}
                onChange={(input) => this.props.atualizaDados(input, index)}
              />
            </Group>
            <Group style={{ width: '50%' }}>
              <Select
                isRequired
                label={'Haverá bagagens?'}
                value={request.luggage}
                error={request.luggage === ''}
                onChange={(input) => this.props.atualizaDados(input, index)}
                name={'luggage'}>
                <option value={''}>Selecione uma opção</option>
                <option value={true}>Sim</option>
                <option value={false}>Não</option>
              </Select>
            </Group>
          </Row>
          <Row>
            <Group style={{ width: '60%' }}>
              <InputAddress
                type={'text'}
                label={'Local de origem'}
                placeholder={'Digite o local de origem'}
                name={'origin'}
                value={request.origin && request.origin.address}
                error={!(request.origin && request.origin.address)}
                hideMap
                autocompleteId={'origin' + index}
                mapId={'mapOrigin' + index}
                addressSelected={(dados) => this.props.atualizaDadosLocalizacao(dados, 'origin', index)}
                isRequired
              />
            </Group>
            <Group style={{ width: '40%' }}>
              <InputDataCustom>
                <InputDatepicker
                  label={'Data in'}
                  type={'single'}
                  isRequired
                  name={'originDate'}
                  placeholder={'Selecione'}
                  dates={request.originDate || []}
                  error={!(request.originDate && request.originDate[0])}
                  onChange={(input) => this.props.atualizaDados(input, index)}
                />
              </InputDataCustom>
            </Group>
          </Row>
          {request.stops.map((parada, stopIndex) => (
            <Row key={stopIndex}>
              <Group style={{ width: '60%' }}>
                <InputAddress
                  type={'text'}
                  label={'Local da parada'}
                  placeholder={'Digite o local da parada'}
                  name={'stop'}
                  value={parada.address}
                  error={!(parada.address)}
                  hideMap
                  autocompleteId={'stop' + index + '-' + stopIndex}
                  mapId={'mapstop' + index + '-' + stopIndex}
                  addressSelected={(dados) => this.props.atualizaDadosLocalizacaoParada(dados, index, stopIndex)}
                  isRequired
                />
              </Group>
              <Group style={{ width: '40%' }}>
                <Button
                  type='button'
                  category={'secondary'}
                  fontWeight={800}
                  ghost
                  small
                  style={{ marginTop: '41px' }}
                  onClick={() => this.props.deletarParada(index, stopIndex)}>
                  Excluir
                </Button>
              </Group>
            </Row>
          ))}
          <LinkCustom onClick={() => this.props.adicionaParada(index)} >+ adicionar parada</LinkCustom>
          <Row>
            <Group style={{ width: '60%' }}>
              <InputAddress
                type={'text'}
                label={'Local de destino'}
                placeholder={'Digite o local de destino'}
                name={'destination'}
                value={request.destination && request.destination.address}
                error={!(request.destination && request.destination.address)}
                hideMap
                autocompleteId={'destination' + index}
                mapId={'mapDestination' + index}
                addressSelected={(dados) => this.props.atualizaDadosLocalizacao(dados, 'destination', index)}
                isRequired
              />
            </Group>
            <Group style={{ width: '40%' }}>
              <InputDataCustom>
                <InputDatepicker
                  label={'Data out'}
                  type={'single'}
                  isRequired
                  name={'destinationDate'}
                  placeholder={'Selecione'}
                  dates={request.destinationDate || []}
                  error={!(request.destinationDate && request.destinationDate[0])}
                  onChange={(input) => this.props.atualizaDados(input, index)}
                />
              </InputDataCustom>
            </Group>
          </Row>
          <Row>
            <Group style={{ width: '50%' }}>
              <Select
                isRequired
                label={'Necessita coordenador/receptivo?'}
                name='hasCoordinator'
                value={request.hasCoordinator}
                error={request.hasCoordinator === ''}
                onChange={(input) => this.props.atualizaDados(input, index)}>
                <option value={''}>Selecione uma opção</option>
                <option value={true}>Sim</option>
                <option value={false}>Não</option>
              </Select>
            </Group>
            {request.hasCoordinator === 'true' && <Group style={{ width: '50%' }}>
              <Select
                isRequired
                label={'Coordenador/receptivo bilingue?'}
                name='coordinatorLanguage'
                value={request.coordinatorLanguage}
                error={request.coordinatorLanguage === ''}
                onChange={(input) => this.props.atualizaDados(input, index)}>
                <option value={''}>Selecione uma opção</option>
                <option value={'MONOLINGUE_PT'}>Monolíngue (português)</option>
                <option value={'BILINGUE_PT_EN'}>Bilíngue (português e inglês)</option>
                <option value={'BILINGUE_PT_ES'}>Bilíngue (português e espanhol)</option>
              </Select>
            </Group>}
            <Group style={{ width: '50%' }}>
              <Select
                isRequired
                style={{ padding: '5px' }}
                label={'Necessita de serviço de bordo?'}
                name={'hasOnboardServices'}
                value={request.hasOnboardServices}
                error={request.hasOnboardServices === ''}
                onChange={(input) => this.props.atualizaDados(input, index)}>
                <option value={''}>Selecione uma opção</option>
                <option value={true}>Sim</option>
                <option value={false}>Não</option>
              </Select>
            </Group>
          </Row>
          <Textarea
            type={'text'}
            label={'Observações'}
            placeholder={'Caso tenha alguma observação, escreva aqui.'}
            name={'observation'}
            value={request.observation}
            onChange={(input) => this.props.atualizaDados(input, index)} />
        </Card>
      </Content>
    )
  }
}

export default RequestTransfer
