/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
import InputMask from 'react-input-mask'
/* API */
import config from '../../../middleware/config'
/* STYLES */
import { defaultTheme } from '../../styles/themes'
/* I18n */
import i18n from '../../../i18n'
/* UI */
import Input from '../../components/ui/Input'
import Button from '../../components/ui/Button'
import Alert from '../../components/ui/Alert'
import { formatPercentage } from '../../../utils/percentage'
import Loader from '../../components/Loader'
import { isCnpjValid } from '../../../utils/validation'

const Content = styled.main`
  background-color: #FAFAFA;
  min-height: 100vh;
  width: 100%;
  padding: 40px 3%;
  box-sizing: border-box;
  @media (min-width: 768px) {
    padding: 40px 20%;
  }
`
const Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  font-family: ${defaultTheme.fonts.secondary};
  padding: 3px 10px 6px 10px;
  color: #484848;
`

const Form = styled.form`
  flex-wrap: wrap;
  display: flex;
  padding: 0px 10px;
`

const Row = styled.div`
  display: flex;
  width: 100%;
  margin-top: 15px;
`

class SignUp extends Component {
  static defaultProps = {
    language: 'pt_br'
  }

  state = {
    company: {
      company_name: '',
      document: '',
      admin_name: '',
      admin_role: '',
      admin_section: '',
      email: '',
      full_name: '',
      phone: '',
      phone_ext: '',
      management_fee: '4.00',
      password: '',
      password_confirm: ''
    },
    errors: {
      company_name: '',
      document: '',
      admin_name: '',
      admin_role: '',
      admin_section: '',
      email: '',
      full_name: '',
      phone: '',
      phone_ext: '',
      management_fee: '',
      password: '',
      password_confirm: ''
    },
    error_empty: false,
    error_password: false,
    isLoading: false
  }

  handlerValue(input) {
    const { company } = this.state
    company[input.target.name] = input.target.value.trim()
    this.setState({ company: company })
  }

  handlerPercentageValue(input) {
    const { company } = this.state
    company[input.target.name] = formatPercentage(input.target.value.trim())
    this.setState({ company: company })
  }

  submit(e) {
    e.preventDefault()
    this.setState({ error_empty: false, error_password: false, isLoading: true, errors: {
        company_name: '',
        document: '',
        admin_name: '',
        admin_role: '',
        admin_section: '',
        email: '',
        full_name: '',
        phone: '',
        phone_ext: '',
        management_fee: '',
        password: '',
        password_confirm: ''
      }}, () => {
        const { company } = this.state
        const { language } = this.props
        const errors = {
          empty: false,
          password: false,
          email: false,
          cnpj: false
        }

        errors.cnpj = !isCnpjValid(company.document)

        var handleErrors = this.state.errors
        Object.keys(company).forEach(c => {
          if (company[c] === '' && c !== 'phone_ext' ) handleErrors[c] = true
        })

        this.setState({ errors: handleErrors }, () => {
          var rePassword = /^(?=.*?[A-Z]).{8,}$/
          if (!rePassword.test(String(company.password))) errors.password = i18n.t('signUp.password_at_least', { language })

          if (company.password !== company.password_confirm) errors.password = i18n.t('signUp.password_not_equal', { language })

          var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (!re.test(String(company.email).toLowerCase())) errors.email = 'Por favor, digite um email válido!'

          if (errors.empty || errors.password || errors.email || errors.cnpj) {
            this.setState({ error_empty: errors.empty, error_password: errors.password, error_email: errors.email, error_cnpj: errors.cnpj, isLoading: false})
          } else {
            const companyObj = {
              name: company.company_name,
              type: "ORGANIZER",
              user: {
                password: company.password
              },
              profile: {
                email: company.email.toLowerCase(),
                language: 'pt_br',
                type: 'COMPANY',
                document: company.document,
                given_name: company.company_name,
                full_name: company.full_name,
                phone: company.phone
              },
              adminInfo: {
                name: company.admin_name,
                role: company.admin_role,
                division: company.admin_section,
                managementFee: {
                  amount: company.management_fee,
                  type: "PERCENTAGE"
                }
              },
              location: {
                fullAddress: ".",
                city: ".",
                province: ".",
                country: ".",
                address: ".",
                others: ".",
                lat: "",
                lng: ""
              },
              services: []
            }
            this.fetchCreateAccount(companyObj)
          }
        })
      })
  }

  fetchCreateAccount = (company) => {
      const { api: { base, version, routes: { accounts } } } = config
      const endpoint = `${base}${version}${accounts}`
      const configFetch = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(company)
      }

      window.fetch(endpoint, configFetch)
        .then(response => {
          if (response.status >= 401 && response.status <= 403) window.localStorage.clear()
          if (response.ok) {
            return response.json()
          } else {
            throw new Error(response.statusText)
          }
        }).then((user) => {
          this.setState({isLoading: false})
          if (!user.success) {
            return Promise.reject(user)
          } else {
            if (!user.success) throw new Error(JSON.stringify(user))
            window.localStorage.setItem('user_token', user.data.token)
            window.localStorage.setItem('expires', user.data.expires)
            window.localStorage.setItem('user', user.data.user)
            window.localStorage.setItem('account', user.data.account)
            window.localStorage.setItem('user_type', user.data.type)
            if (user.data.supplierChainId) window.localStorage.setItem('supplierChainId', user.data.supplierChainId)
            else window.localStorage.removeItem('supplierChainId')
            if (user.data.featureFlags) window.localStorage.setItem('featureFlags', JSON.stringify(user.data.featureFlags))
            else window.localStorage.removeItem('featureFlags')
            window.localStorage.setItem('theme_logo', user.data.theme && user.data.theme.logo)
            window.localStorage.setItem('theme_color_primary', user.data.theme ? user.data.theme.primary : '#009688')
            window.localStorage.setItem('theme_color_secondary', user.data.theme ? user.data.theme.secondary : '#762123')
            window.location.href = '/organizer'
          }
        }).catch(err => {
          this.setState({isLoading: false})
          alert(err.errors.message)
          return console.log('Error: ', err)
        })
  }

  render() {
    const { language } = this.props
    const { isLoading } = this.state
    return (
      <Content>
        {isLoading ? <Loader /> : null}
        <Title>{i18n.t('newOrganizer.title', { language })}</Title>
        <Form onSubmit={this.submit.bind(this)} method='POST'>
          {this.state.error_empty
            ? <Alert type={'negative'}>
              {i18n.t('newOrganizer.empty_fields', { language })}
            </Alert> : null}
          <Input
            type={'text'}
            label={i18n.t('newOrganizer.company_name', { language })}
            placeholder={i18n.t('newOrganizer.company_name_placeholder', { language })}
            name={'company_name'}
            error={this.state.errors.company_name}
            onChange={this.handlerValue.bind(this)}
            isRequired
          />
          <InputMask mask="99.999.999/9999-99"
            value={this.state.document}
            onChange={this.handlerValue.bind(this)}>
            {(inputProps) => <Input
              {...inputProps}
              type={'text'}
              label={i18n.t('newOrganizer.cnpj', { language })}
              placeholder={i18n.t('newOrganizer.cnpj_placeholder', { language })}
              name={'document'}
              error={this.state.error_cnpj || this.state.errors.document}
              isRequired
            />}
          </InputMask>
          {this.state.error_cnpj
          ? <Alert type={'negative'}>
              Digite um CNPJ válido!
          </Alert> : null}
          <Input
            type={'text'}
            label={i18n.t('newOrganizer.admin_name', { language })}
            placeholder={i18n.t('newOrganizer.admin_name_placeholder', { language })}
            name={'admin_name'}
            error={this.state.errors.admin_name}
            onChange={this.handlerValue.bind(this)}
            isRequired
          />
          <Input
            type={'text'}
            label={i18n.t('newOrganizer.admin_role', { language })}
            placeholder={i18n.t('newOrganizer.admin_role_placeholder', { language })}
            name={'admin_role'}
            error={this.state.errors.admin_role}
            onChange={this.handlerValue.bind(this)}
            isRequired
          />
          <Input
            type={'text'}
            label={i18n.t('newOrganizer.admin_section', { language })}
            placeholder={i18n.t('newOrganizer.admin_section_placeholder', { language })}
            name={'admin_section'}
            error={this.state.errors.admin_section}
            onChange={this.handlerValue.bind(this)}
            isRequired
          />
          <Input
            type={'email'}
            label={i18n.t('newOrganizer.email', { language })}
            placeholder={i18n.t('newOrganizer.email_placeholder', { language })}
            name={'email'}
            error={this.state.errors.email || this.state.error_email}
            onChange={this.handlerValue.bind(this)}
            isRequired
          />
          {this.state.error_email
            ? <Alert type={'negative'}>
              {this.state.error_email}
            </Alert> : null}
          <Input
            type={'text'}
            label={i18n.t('signUp.full_name', { language })}
            placeholder={i18n.t('newOrganizer.full_name_placeholder', { language })}
            name={'full_name'}
            error={this.state.errors.full_name}
            onChange={this.handlerValue.bind(this)}
            isRequired
          />
          <Row>
            <InputMask
              mask="(99) 9 9999-9999"
              maskChar=""
              value={this.state.phone}
              onChange={this.handlerValue.bind(this)}>
              {(inputProps) => <Input
                type={'text'}
                label={i18n.t('newOrganizer.phone', { language })}
                placeholder={i18n.t('newOrganizer.phone_placeholder', { language })}
                name={'phone'}
                error={this.state.errors.phone}
                isRequired
              />}
            </InputMask>
            <Input
              type={'text'}
              label={i18n.t('newOrganizer.phone_ext', { language })}
              placeholder={i18n.t('newOrganizer.phone_ext_placeholder', { language })}
              name={'phone_ext'}
              onChange={this.handlerValue.bind(this)}
            />
          </Row>
          <Input
              type={'text'}
              label={i18n.t('newOrganizer.management_fee', { language })}
              placeholder={0}
              name={'management_fee'}
              error={this.state.errors.management_fee}
              onChange={this.handlerPercentageValue.bind(this)}
              rightIcon={'%'}
              style={{textAlign: 'right'}}
              value={this.state.company.management_fee}
              isRequired
            />
          <Row><Title>{i18n.t('newOrganizer.password_title', { language })}</Title></Row>
          {this.state.error_password
            ? <Alert type={'negative'}>
              {this.state.error_password}
            </Alert> : null}
          <Input
            type={'password'}
            label={i18n.t('newOrganizer.password', { language })}
            placeholder={i18n.t('newOrganizer.password_placeholder', { language })}
            name={'password'}
            error={this.state.errors.password}
            isRequired
            onChange={this.handlerValue.bind(this)}
          />
          <Input
            type={'password'}
            label={i18n.t('newOrganizer.password_confirm', { language })}
            placeholder={i18n.t('newOrganizer.password_confirm_placeholder', { language })}
            name={'password_confirm'}
            error={this.state.errors.password_confirm}
            isRequired
            onChange={this.handlerValue.bind(this)}
          />
          <Button
            type='submit'
            category={'secondary'}
            fontWeight={800}
            full>
            {i18n.t('newOrganizer.send_action', { language })}
          </Button>
        </Form>
      </Content>
    )
  }
}

export default SignUp
