/* LIBS */
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import jwtDecode from 'jwt-decode'
import { defaultTheme } from '../../../styles/themes'
/* COMPONENTS */
import Header from '../../../components/Header'
import Button from '../../../components/ui/Button'
import Modal from '../../../components/Modal'
import Title from '../../../components/ui/Title'
import Subtitle from '../../../components/ui/Subtitle'
import CheckBox from '../../../components/ui/CheckBox'
import MoneyInput from '../../../components/ui/MoneyInput'
import Textarea from '../../../components/ui/Textarea'
import BreadCrumb from '../../../components/BreadCrumb'
import Loader from '../../../components/Loader'
import config from '../../../../middleware/config'
import { Get, Post, Put } from '../../../../utils/request'
import CommisedRate from '../Rooms/CommisedRate'
import moment from 'moment'
import FormEquipmentsModal from '../../Equipments/FormEquipmentsModal'
import { formatDate } from '../../../../utils/dates'
import { getNameFromKey } from '../../../../utils/fabTexts'
import { checkIfItemWasEdited, checkIfItemWasEditedForDates, checkIfItemsWasEdited } from '../../../../utils/checkEdition'
import formatCurrency from '../../../../utils/money';

const CRITICAL = 'CRITICAL'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #FAFAFA;
  & * { font-family: 'Open Sans', sans-serif }
`

const Main = styled.div`
  width: 80%;
  margin-top: '15px';
  padding: 20px 30px;
  background: #FAFAFA;
  flex: 1 0 auto;
`

const Label = styled.label`
  color: ${props => props.error ? 'red' : (props.edited ? '#F39C12' : '#484848')};
  box-sizing: border-box;
  padding: 5px 5px 5px 0px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
`

const CheckboxIndisponibilidade = styled.input`
  font-size: 16px;
  background-color: #FFF;
  border-radius: 1px;
  cursor: pointer;
  padding: 6px 10px;
  border-bottom: ${props => props.error ? '2px solid red' : `2px solid ${defaultTheme.colors.primary.default}`};
  transition: all 0.2s linear;
  height: 20px;
  width: 20px;
`

const FlexGroup = styled.div`
  display: flex;
  padding: 20px;
  padding-top: 0px;
`

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  td {
    vertical-align: top;
  }
`

const Tag = styled.div`
  padding: 3px 5px;
  font-size: 12px;
  font-family: ${defaultTheme.fonts.primary};
  color: #FAFAFA;
  background-color: ${props => props.edited ? '#F39C12' : (props.contraproposta ? '#8800dd' : '#009688')};
  border: none;
  border-radius: 15px;
  position: absolute;
  right: 10px;
  top: -17px;
  text-transform: uppercase;
  z-index: 9;
`

const Group = styled.div`
`

class BriefingEquipments extends Component {
  constructor() {
    super()
    this.state = {
      isLoading: false,
      briefings: [],
      counterProposals: [],
      equipments: {
        projector: {
          hasAvailability: false,
          requests: [
            {
              dates : [],
              amount : 0
            }
          ],
          price: {
            amount: 0,
            currency: "BRL"
          },
          observation: ''
        },
        screen: {
          hasAvailability: false,
          requests: [
            {
              dates : [],
              amount : 0
            }
          ],
          price: {
            amount: 0,
            currency: "BRL"
          },
          observation: ''
        },
        sound: {
          hasAvailability: false,
          requests: [
            {
              dates : [],
              amount : 0
            }
          ],
          price: {
            amount: 0,
            currency: "BRL"
          },
          observation: ''
        },
        radio: {
          hasAvailability: false,
          requests: [
            {
              dates : [],
              amount : 0
            }
          ],
          price: {
            amount: 0,
            currency: "BRL"
          },
          observation: ''
        },
        operator: {
          hasAvailability: false,
          requests: [
            {
              dates : [],
              amount : 0
            }
          ],
          price: {
            amount: 0,
            currency: "BRL"
          },
          observation: ''
        },
        generator: {
          hasAvailability: false,
          requests: [
            {
              dates : [],
              amount : 0
            }
          ],
          price: {
            amount: 0,
            currency: "BRL"
          },
          observation: ''
        },
        microphone: {
          hasAvailability: false,
          requests: [
            {
              dates: [],
              numberOfLapel: 0,
              numberOfGooseneck: 0,
              numberOfHeadSet: 0,
              numberOfStick: 0
            }
          ],
          lapelPrice: {
            amount: 0,
            currency: "BRL"
          },
          gooseneckPrice: {
            amount: 0,
            currency: "BRL"
          },
          headSetPrice: {
            amount: 0,
            currency: "BRL"
          },
          stickPrice: {
            amount: 0,
            currency: "BRL"
          },
          observation: ''
        },
        flipchart: {
          hasAvailability: false,
          requests: [
            {
              dates : [],
              amount : 0
            }
          ],
          price: {
            amount: 0,
            currency: "BRL"
          },
          observation: ''
        },
        laserPoint: {
          hasAvailability: false,
          requests: [
            {
              dates : [],
              amount : 0
            }
          ],
          price: {
            amount: 0,
            currency: "BRL"
          },
          observation: ''
        },
        notebook: {
          hasAvailability: false,
          requests: [
            {
              dates : [],
              amount : 0
            }
          ],
          price: {
            amount: 0,
            currency: "BRL"
          },
          observation: ''
        },
        lighting: {
          hasAvailability: false,
          requests: [
            {
              dates : [],
              amount : 0
            }
          ],
          price: 0,
          observation: ''
        },
        simultaneousTranslation: {
          hasAvailability: false,
          requests: [
            {
              dates : [],
              amount : 0
            }
          ],
          price: 0,
          observation: ''
        },
        internet: {
          hasAvailability: false,
          requests: [
            {
              dates : [],
              amount : 0
            }
          ],
          price: 0,
          observation: ''
        }
      },
      observations: '',
      observationsValue: '',
      briefingId: '',
      CommisedOpen: false,
      commission: {},
      foodsAndBeveragesId: '',
      equipmentsModal: false,
      equipmentsModalSelected: '',
      reseller: undefined,
      differences: {},
      lastUpdateType: '',
      availability: true,
      event: { client: {} }
    }
  }

  componentDidMount() {
    const { match: { params: { event } } } = this.props
    if (event) {
      this.fetchBriefing()
      this.fetchEvent()
    }
  }

  fetchEvent () {
    const { api: { base, version, routes: { events, supplier } } } = config
    const { match: { params: { event } } } = this.props
    const endpoint = `${base}${version}${supplier}${events}/${event}`

    Get(endpoint).then(dados => {
      this.setState({ event: dados.event })
    }).catch(err => {
      console.log('Error: ', err)
    })
  }

  fetchEditDifferences () {
    const { api: { base, version } } = config
    const id = this.state.briefingId
    const endpoint = `${base}${version}/briefing/equipments/${id}/rollback/difference`

    Get(endpoint).then((differences) => {
      this.setState({ differences: differences, isLoading: false })
    }).catch(err => {
      console.log('Error: ', err)
    })
  }

  tratarRequests (briefing, budget) {
    var tratado = { ...budget }

    Object.keys(briefing).filter(item =>
      briefing[item] && briefing[item].requested
    ).forEach(item => {
      tratado[item].requests = Array.prototype.concat(briefing[item].requests)
    })

    return tratado
  }

  fetchBriefing () {
    this.setState({ isLoading: true })
    const { api: { base, version, routes: { events, equipments } } } = config
    const { match: { params: { event } } } = this.props
    const endpoint = `${base}${version}${events}/${event}/briefing/equipments`
    Get(endpoint).then((response) => {
      const token = window.localStorage.getItem('user_token')
      const id = jwtDecode(token).accountId
      const endpointEquipments = `${base}${version}${equipments}/account/${id}`
      Get(endpointEquipments).then((createdEquipments) => {
        const { match: { params: { budget } } } = this.props
        if(budget) {
          const newEndpoint = `${base}${version}/budget/equipments/${budget}`
          Get(newEndpoint).then((budgetInfo) => {
            this.setState({
              isLoading: budgetInfo.status === 'REVIEW',
              lastUpdateType: budgetInfo.lastUpdateType,
              equipments: this.tratarRequests(response, budgetInfo.equipments),
              counterProposals: budgetInfo.counterProposals,
              briefings: response,
              createdEquipments: createdEquipments[0],
              briefingId: response.id,
              eventId: response.eventId
            }, () => budgetInfo.status === 'REVIEW' && this.fetchEditDifferences())
          }).catch(err => {
            this.setState({ isLoading: false })
            console.log('Error: ', err)
          })
        } else {
          var equipmentsBudget = {
            projector: {
              hasAvailability: false,
              requests: [
                {
                  dates : [],
                  amount : 0
                }
              ],
              price: {
                amount: 0,
                currency: "BRL"
              },
              observation: ''
            },
            screen: {
              hasAvailability: false,
              requests: [
                {
                  dates : [],
                  amount : 0
                }
              ],
              price: {
                amount: 0,
                currency: "BRL"
              },
              observation: ''
            },
            sound: {
              hasAvailability: false,
              requests: [
                {
                  dates : [],
                  amount : 0
                }
              ],
              price: {
                amount: 0,
                currency: "BRL"
              },
              observation: ''
            },
            radio: {
              hasAvailability: false,
              requests: [
                {
                  dates : [],
                  amount : 0
                }
              ],
              price: {
                amount: 0,
                currency: "BRL"
              },
              observation: ''
            },
            operator: {
              hasAvailability: false,
              requests: [
                {
                  dates : [],
                  amount : 0
                }
              ],
              price: {
                amount: 0,
                currency: "BRL"
              },
              observation: ''
            },
            generator: {
              hasAvailability: false,
              requests: [
                {
                  dates : [],
                  amount : 0
                }
              ],
              price: {
                amount: 0,
                currency: "BRL"
              },
              observation: ''
            },
            microphone: {
              hasAvailability: false,
              requests: [
                {
                  dates: [],
                  numberOfLapel: 0,
                  numberOfGooseneck: 0,
                  numberOfHeadSet: 0,
                  numberOfMicrophone: 0,
                  numberOfStick: 0
                }
              ],
              lapelPrice: {
                amount: 0,
                currency: "BRL"
              },
              gooseneckPrice: {
                amount: 0,
                currency: "BRL"
              },
              headSetPrice: {
                amount: 0,
                currency: "BRL"
              },
              stickPrice: {
                amount: 0,
                currency: "BRL"
              },
              observation: ''
            },
            flipchart: {
              hasAvailability: false,
              requests: [
                {
                  dates : [],
                  amount : 0
                }
              ],
              price: {
                amount: 0,
                currency: "BRL"
              },
              observation: ''
            },
            laserPoint: {
              hasAvailability: false,
              requests: [
                {
                  dates : [],
                  amount : 0
                }
              ],
              price: {
                amount: 0,
                currency: "BRL"
              },
              observation: ''
            },
            notebook: {
              hasAvailability: false,
              requests: [
                {
                  dates : [],
                  amount : 0
                }
              ],
              price: {
                amount: 0,
                currency: "BRL"
              },
              observation: ''
            },
            lighting: {
              hasAvailability: false,
              requests: [
                {
                  dates : [],
                  amount : 0
                }
              ],
              price: 0,
              observation: ''
            },
            simultaneousTranslation: {
              hasAvailability: false,
              requests: [
                {
                  dates : [],
                  amount : 0
                }
              ],
              price: 0,
              observation: ''
            },
            internet: {
              hasAvailability: false,
              requests: [
                {
                  dates : [],
                  amount : 0
                }
              ],
              price: 0,
              observation: ''
            }
          }
          if(createdEquipments.length === 0) {
            Object.keys(response).filter(item => response[item] && response[item].requested && item !== 'microphone').forEach(item => {
              equipmentsBudget[item].hasAvailability = true
              equipmentsBudget[item].price = {
                amount: 0,
                currency: 'BRL'
              }
              equipmentsBudget[item].requests = response[item].requests
            })
            equipmentsBudget.microphone.hasAvailability = response.microphone.requested
            equipmentsBudget.microphone.requests = response.microphone.requests
            equipmentsBudget.microphone.lapelPrice = {
              amount: 0,
              currency: 'BRL'
            }
            equipmentsBudget.microphone.gooseneckPrice = {
              amount: 0,
              currency: 'BRL'
            }
            equipmentsBudget.microphone.headSetPrice = {
              amount: 0,
              currency: 'BRL'
            }
            equipmentsBudget.microphone.stickPrice = {
              amount: 0,
              currency: 'BRL'
            }
            this.setState({ isLoading: false, briefings: response, createdEquipments: {}, equipments: equipmentsBudget, briefingId: response.id, eventId: response.eventId })
          } else {
            Object.keys(response).filter(item => response[item] && response[item].requested && item !== 'microphone').forEach(item => {
              equipmentsBudget[item].hasAvailability = true
              equipmentsBudget[item].price = createdEquipments[0][item].defaultPrice
              equipmentsBudget[item].requests = response[item].requests
            })
            equipmentsBudget.microphone.hasAvailability = response.microphone.requested
            equipmentsBudget.microphone.requests = response.microphone.requests
            equipmentsBudget.microphone.lapelPrice = createdEquipments[0].microphone.lapel.defaultPrice
            equipmentsBudget.microphone.gooseneckPrice = createdEquipments[0].microphone.gooseneck.defaultPrice
            equipmentsBudget.microphone.headSetPrice = createdEquipments[0].microphone.headSet.defaultPrice
            equipmentsBudget.microphone.stickPrice = createdEquipments[0].microphone.stick.defaultPrice
            this.setState({ isLoading: false, briefings: response, createdEquipments: createdEquipments[0], equipments: equipmentsBudget, briefingId: response.id, eventId: response.eventId })
          }
        }
      })
    }).catch(err => {
      this.setState({ isLoading: false })
      console.log('Error: ', err)
    })
  }

  submitCommisedRate = ({ commised, reason, value }) => {
    let { commission } = this.state
    commission = {
      fee: {
        amount: value,
        type: 'PERCENTAGE'
      },
      reason: reason,
      hasCommission: commised === 'yes'
    }
    this.setState({
      isLoading: true,
      commission,
      CommisedOpen: false
    }, () => {
      const { api: { base, version } } = config
      const { match: { params: { budget } } } = this.props
      if(this.props.location.state && (this.props.location.state.counterProposal === 'ACCEPTED' || this.props.location.state.counterProposal === 'NEW')) {
        const endpoint = `${base}${version}/budget/equipments/${this.props.location.state.counterProposal === 'ACCEPTED' ? 'counter-proposal' : 'supplier-counter-proposal'}/${budget}`
        Put(endpoint, this.generateJSON())
        .then(() => {
          const { match: { params: { event } } } = this.props
          this.props.history.push(`/budget/resume/${event}`)
        }).catch(err => {
          console.error(err.message)
          this.setState({
            isLoading: false
          })
          alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
        })
      }
      else if(budget) {
        const endpoint = `${base}${version}/budget/equipments/${budget}`
        Put(endpoint, this.generateJSON())
        .then(() => {
          const { match: { params: { event } } } = this.props
          this.props.history.push(`/budget/resume/${event}`)
        }).catch(err => {
          console.error(err.message)
          this.setState({
            isLoading: false
          })
          alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
        })
      } else {
        const endpoint = `${base}${version}/budget/equipments/`
        Post(endpoint, this.generateJSON())
        .then(() => {
          const { match: { params: { event } } } = this.props
          this.props.history.push(`/budget/resume/${event}`)
        }).catch(err => {
          console.error(err.message)
          this.setState({
            isLoading: false
          })
          alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
        })
      }
    })
  }

  generateJSON = () => {
    return {
      briefingId: this.state.briefingId,
      eventId: this.state.eventId,
      fee: this.state.createdEquipments.serviceFee,
      commission: this.state.commission,
      counterProposals: this.state.counterProposals,
      equipments: this.state.equipments
    }
  }

  isValid = () => {
    const itensValid = !(Object.keys(this.state.equipments).filter(item => this.state.equipments[item] && this.state.equipments[item].hasAvailability && item !== 'microphone').some(item => !this.state.equipments[item].price.amount || this.state.equipments[item].price.amount <= 0))
    const microphoneValid = !(this.state.equipments.microphone.hasAvailability && ((!this.state.equipments.microphone.lapelPrice.amount || this.state.equipments.microphone.lapelPrice.amount <= 0) &&
    (!this.state.equipments.microphone.gooseneckPrice.amount || this.state.equipments.microphone.gooseneckPrice.amount <= 0) &&
    (!this.state.equipments.microphone.headSetPrice.amount || this.state.equipments.microphone.headSetPrice.amount <= 0) &&
    (!this.state.equipments.microphone.stickPrice.amount || this.state.equipments.microphone.stickPrice.amount <= 0)))
    return itensValid && (microphoneValid || !this.state.equipments.microphone.hasAvailability)
  }

  temPeloMenosUm = () => {
    return !this.state.availability || Object.keys(this.state.equipments).some(equipment => this.state.equipments[equipment].hasAvailability)
  }

  submitForm = () => {
    if(!this.temPeloMenosUm()) {
      return alert('Você deve preencher ao menos um equipamento para enviar seu orçamento.')
    }
    if(!this.isCounterProposalValid()) {
      return alert('Você aceitou a contraproposta, por favor preencha os campos para que o valor da nova oferta seja menor ou igual a contraproposta solicitada.')
    }
    if(!this.isNewCounterProposalValid()) {
      return alert('Você não pode criar uma contraproposta com o valor acima do valor inicial enviado.')
    }
    else if (this.isValid()) {
      if(this.state.event.supplierChainId) {
        let { commission } = this.state
        commission = {
          fee: {
            amount: 0,
            type: 'PERCENTAGE'
          },
          reason: '',
          hasCommission: false
        }
        this.setState({
          isLoading: true,
          commission,
          CommisedOpen: false
        }, () => {
          const { api: { base, version } } = config
          const { match: { params: { budget } } } = this.props
          if(this.props.location.state && (this.props.location.state.counterProposal === 'ACCEPTED' || this.props.location.state.counterProposal === 'NEW')) {
            const endpoint = `${base}${version}/budget/equipments/${this.props.location.state.counterProposal === 'ACCEPTED' ? 'counter-proposal' : 'supplier-counter-proposal'}/${budget}`
            Put(endpoint, this.generateJSON())
            .then(() => {
              const { match: { params: { event } } } = this.props
              this.props.history.push(`/budget/resume/${event}`)
            }).catch(err => {
              console.error(err.message)
              this.setState({
                isLoading: false
              })
              alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
            })
          }
          else if(budget) {
            const endpoint = `${base}${version}/budget/equipments/${budget}`
            Put(endpoint, this.generateJSON())
            .then(() => {
              const { match: { params: { event } } } = this.props
              this.props.history.push(`/budget/resume/${event}`)
            }).catch(err => {
              console.error(err.message)
              this.setState({
                isLoading: false
              })
              alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
            })
          } else {
            const endpoint = `${base}${version}/budget/equipments/`
            Post(endpoint, this.generateJSON())
            .then(() => {
              const { match: { params: { event } } } = this.props
              this.props.history.push(`/budget/resume/${event}`)
            }).catch(err => {
              console.error(err.message)
              this.setState({
                isLoading: false
              })
              alert(`Infelizmente não conseguimos enviar suas informações. ${err.message}`)
            })
          }
        })
      } else {
        this.setState({ CommisedOpen: true })
      }
    } else {
      alert('Você deve preencher todos os equipamento selecionados para enviar seu orçamento.')
    }
  }

  splitDates = (requests) => {
    return requests.map((info) => {
      return " " + moment(info.start, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br').format('DD-MM-YYYY') + " ,"
    })
  }

  onFinished = () => {
    this.setState({
      equipmentsModal: false
    }, () => window.location.reload())
  }

  setModalSelected = (selected) => {
    this.setState({
      equipmentsModal: true,
      equipmentsModalSelected: selected
    })
  }

  enableEquipment = (input) => {
    var state = this.state
    state.equipments[input.target.name].hasAvailability = !state.equipments[input.target.name].hasAvailability
    this.setState(state)
  }

  handleValue = (name, input, secondName) => {
    var state = this.state
    if(!secondName) {
      if(input.target.name === 'price' ||
        input.target.name === 'lapelPrice' ||
        input.target.name === 'gooseneckPrice' ||
        input.target.name === 'headSetPrice' ||
        input.target.name === 'stickPrice') {
        state.equipments[name][input.target.name].amount = input.target.value
      } else {
        state.equipments[name][input.target.name] = input.target.value
      }
    } else {
      if(input.target.name === 'price' ||
        input.target.name === 'lapelPrice' ||
        input.target.name === 'gooseneckPrice' ||
        input.target.name === 'headSetPrice' ||
        input.target.name === 'stickPrice') {
        state.equipments[name][secondName][input.target.name].amount = input.target.value
      } else {
        state.equipments[name][secondName][input.target.name] = input.target.value
      }
    }
    this.setState(state)
  }

  Item = (session) => {
    const { match: { params: { event, equipment, budget } } } = this.props
    const temQueEnviarContraproposta = this.props.location.state && (this.props.location.state.counterProposal === 'ACCEPTED' || this.props.location.state.counterProposal === 'NEW')

    return <CheckBox
      label={session.item !== 'internet' ? getNameFromKey(session.item) : 'Internet'}
      name={session.item}
      checked={this.state.equipments[session.item].hasAvailability}
      value={this.state.equipments[session.item].hasAvailability}
      onClick={!temQueEnviarContraproposta && this.enableEquipment}>
      <Group style={{ padding: '20px' }}>
        <Table style={{ marginBottom: '20px' }}>
          <tr>
            <th>
              <Label>Datas:</Label>
            </th>
            <th>
              <Label>Quantidade:</Label>
            </th>
          </tr>
          {this.state.briefings[session.item].requests.map(item => <tr>
            <td>
              <Label edited={this.state.differences[session.item] && this.state.differences[session.item].requests && checkIfItemWasEditedForDates(this.state.differences[session.item].requests[0], 'dates')} style={{ fontWeight: '600' }}><b>{item.dates.map(data => formatDate(data)).join(' - ')}</b></Label>
            </td>
            <td>
              <Label edited={this.state.differences[session.item] && this.state.differences[session.item].requests && checkIfItemWasEdited(this.state.differences[session.item].requests[0], 'amount')} style={{ fontWeight: '600' }}><b>{item.amount} {item.amount > 1 ? (session.item !== 'internet' ? 'unidades' : 'pontos de acesso') : (session.item !== 'internet' ? 'unidade' : 'ponto de acesso')}</b></Label>
            </td>
          </tr>)
          }
          <tr>
            <th>
              <Label>Observações:</Label>
            </th>
            <th>
              {this.state.briefings[session.item].type && <Label>Tipo:</Label>}
            </th>
          </tr>
          <tr>
            <td>
              <Label edited={this.state.differences[session.item] && checkIfItemWasEdited(this.state.differences[session.item], 'observation')} style={{ fontWeight: '600' }}><b>{this.state.briefings[session.item].observation || 'Sem observações'}</b></Label>
            </td>
            <td>
              {this.state.briefings[session.item].type && <Label edited={this.state.differences[session.item] && checkIfItemWasEdited(this.state.differences[session.item], 'type')} style={{ fontWeight: '600' }}><b>{getNameFromKey(this.state.briefings[session.item].type)}</b></Label>}
            </td>
          </tr>
          <tr>
            <th>
            {this.state.briefings[session.item].languages && <Label>Linguagem:</Label>}
            </th>
            <th>
              {this.state.briefings[session.item].interpreter && <Label>Necessita Intérprete:</Label>}
            </th>
          </tr>
          <tr>
            <td>
            {this.state.briefings[session.item].languages && <Label edited={this.state.differences[session.item] && checkIfItemWasEdited(this.state.differences[session.item], 'languages')} style={{ fontWeight: '600' }}><b>{getNameFromKey(this.state.briefings[session.item].languages[0])}</b></Label>}
            </td>
            <td>
              {this.state.briefings[session.item].interpreter && <Label edited={this.state.differences[session.item] && checkIfItemWasEdited(this.state.differences[session.item], 'interpreter')} style={{ fontWeight: '600' }}><b>Sim</b></Label>}
            </td>
          </tr>
          <tr>
            <th>
            {this.state.briefings[session.item].mbSpeed && <Label>Quantos MB:</Label>}
            </th>
            <th>
            </th>
          </tr>
          <tr>
            <td>
            {this.state.briefings[session.item].mbSpeed && <Label edited={this.state.differences[session.item] && checkIfItemWasEdited(this.state.differences[session.item], 'mbSpeed')} style={{ fontWeight: '600'}}><b>{this.state.briefings[session.item].mbSpeed}MB</b></Label>}
            </td>
            <td>
            </td>
          </tr>
        </Table>
        {(this.state.equipments[session.item].hasAvailability && this.state.createdEquipments[session.item] && this.state.createdEquipments[session.item].enabled) && <>
            <FlexGroup>
              <MoneyInput
                label={session.item !== 'internet' ? 'Valor (por unidade, por dia)' : 'Valor (por ponto de acesso, por dia)'}
                placeholder={'0,00'}
                name={`price`}
                value={this.state.equipments[session.item].price.amount}
                error={!this.state.equipments[session.item].price.amount || this.state.equipments[session.item].price.amount <= 0}
                isRequired
                containerStyle={{ padding:'0 20px', marginBottom: 'auto', width: '40%' }}
                onChange={(e) => this.handleValue(session.item, e)}
                criticalUpdate={this.state.lastUpdateType === CRITICAL}
                criticalPreviouslyValue={this.state.equipments[session.item].price.amount}
              />
              <Group style={{ width: '60%' }}>
                <Label>Descrição do equipamento</Label>
                <Label>{this.state.createdEquipments[session.item].description}</Label>
              </Group>
            </FlexGroup>
            <Textarea
              label={'Observações'}
              placeholder={'Caso tenha alguma observação, escreva aqui.'}
              name={`observation`}
              value={this.state.equipments[session.item].observation}
              style={{ height: '75px' }}
              onChange={(e) => this.handleValue(session.item, e)}
              criticalUpdate={this.state.lastUpdateType === CRITICAL}
              criticalPreviouslyValue={this.state.equipments[session.item].observation}
            />
          </>
        }
        {(this.state.equipments[session.item] && !this.state.equipments[session.item].hasAvailability && this.state.createdEquipments[session.item].enabled) && <Label>Não ofereço este equipamento.</Label>}
        {(!this.state.createdEquipments[session.item] || !this.state.createdEquipments[session.item].enabled) && <Subtitle style={{ color: 'red', margin: '20px' }}>Este equipamento está pendente de cadastro. <Link to={{ pathname: `/equipments/add/${event}/${equipment}`, query: { url:`/budget/briefing/equipments/${event}/${equipment}${!!budget ? `/${budget}` : ''}`}}}>Clique aqui</Link> para cadastrá-lo e concluir o envio do orçamento.</Subtitle>}
      </Group>
    </CheckBox>
  }

  ItemMicrofone = () => {
    const { match: { params: { event, equipment, budget } } } = this.props
    return <CheckBox
      label={getNameFromKey('microphone')}
      name={'microphone'}
      checked={this.state.equipments.microphone.hasAvailability}
      value={this.state.equipments.microphone.hasAvailability}
      onClick={this.enableEquipment}>
      <Group style={{ padding: '20px' }}>
        <Table style={{ marginBottom: '20px' }}>
          <tr>
            <th>
              <Label>Datas:</Label>
            </th>
            <th>
              <Label>Quantidade:</Label>
            </th>
          </tr>
          {this.state.briefings.microphone.requests.map(item => <tr>
            <td>
              <Label edited={this.state.differences['microphone'] && this.state.differences['microphone'].requests && checkIfItemWasEditedForDates(this.state.differences['microphone'].requests[0], 'dates')} style={{ fontWeight: '600' }}><b>{item.dates.map(data => formatDate(data)).join(' - ')}</b></Label>
            </td>
            <td>
              <Label edited={this.state.differences['microphone'] && this.state.differences['microphone'].requests && checkIfItemsWasEdited(this.state.differences['microphone'].requests[0], ['numberOfLapel', 'numberOfGooseneck', 'numberOfHeadSet', 'numberOfStick'])} style={{ fontWeight: '600' }}>
                <b>
                  {item.numberOfLapel} lapelas, {item.numberOfGooseneck} gooseneck, {item.numberOfHeadSet} head set, {item.numberOfStick} bastões.
                </b>
              </Label>
            </td>
          </tr>)
          }
          <tr>
            <th>
              <Label>Observações:</Label>
            </th>
            <th>
            </th>
          </tr>
          <tr>
            <td>
              <Label edited={this.state.differences['microphone'] && checkIfItemWasEdited(this.state.differences['microphone'], 'observation')} style={{ fontWeight: '600' }}><b>{this.state.briefings.microphone.observation || 'Sem observações'}</b></Label>
            </td>
            <td>
            </td>
          </tr>
        </Table>
        {(this.state.equipments.microphone.hasAvailability && this.state.createdEquipments.microphone && this.state.createdEquipments.microphone.enabled) && <>
            {this.state.briefings.microphone.requests.some(item => item.numberOfLapel > 0) && <FlexGroup>
              <MoneyInput
                label={'Valor da Lapela (por unidade, por dia)'}
                placeholder={'0,00'}
                name={`lapelPrice`}
                value={this.state.equipments.microphone.lapelPrice.amount}
                error={(!this.state.equipments.microphone.lapelPrice.amount || this.state.equipments.microphone.lapelPrice.amount <= 0) &&
                  (!this.state.equipments.microphone.gooseneckPrice.amount || this.state.equipments.microphone.gooseneckPrice.amount <= 0) &&
                  (!this.state.equipments.microphone.headSetPrice.amount || this.state.equipments.microphone.headSetPrice.amount <= 0) &&
                  (!this.state.equipments.microphone.stickPrice.amount || this.state.equipments.microphone.stickPrice.amount <= 0)}
                isRequired
                containerStyle={{ padding:'0 20px', marginBottom: 'auto', width: '40%' }}
                onChange={(e) => this.handleValue('microphone', e)}
                criticalUpdate={this.state.lastUpdateType === CRITICAL}
                criticalPreviouslyValue={this.state.equipments.microphone.lapelPrice.amount}
              />
              <Group style={{ width: '60%' }}>
                <Label>Descrição da Lapela</Label>
                <Label>{this.state.createdEquipments.microphone.lapel.description}</Label>
              </Group>
            </FlexGroup>}
            {this.state.briefings.microphone.requests.some(item => item.numberOfGooseneck > 0) && <FlexGroup>
              <MoneyInput
                label={'Valor do Gooseneck (por unidade, por dia)'}
                placeholder={'0,00'}
                name={`gooseneckPrice`}
                value={this.state.equipments.microphone.gooseneckPrice.amount}
                error={(!this.state.equipments.microphone.lapelPrice.amount || this.state.equipments.microphone.lapelPrice.amount <= 0) &&
                  (!this.state.equipments.microphone.gooseneckPrice.amount || this.state.equipments.microphone.gooseneckPrice.amount <= 0) &&
                  (!this.state.equipments.microphone.headSetPrice.amount || this.state.equipments.microphone.headSetPrice.amount <= 0) &&
                  (!this.state.equipments.microphone.stickPrice.amount || this.state.equipments.microphone.stickPrice.amount <= 0)}
                isRequired
                containerStyle={{ padding:'0 20px', marginBottom: 'auto', width: '40%' }}
                onChange={(e) => this.handleValue('microphone', e)}
                criticalUpdate={this.state.lastUpdateType === CRITICAL}
                criticalPreviouslyValue={this.state.equipments.microphone.gooseneckPrice.amount}
              />
              <Group style={{ width: '60%' }}>
                <Label>Descrição do Gooseneck</Label>
                <Label>{this.state.createdEquipments.microphone.gooseneck.description}</Label>
              </Group>
            </FlexGroup>}
            {this.state.briefings.microphone.requests.some(item => item.numberOfHeadSet > 0) && <FlexGroup>
              <MoneyInput
                label={'Valor do Head Set (por unidade, por dia)'}
                placeholder={'0,00'}
                name={`headSetPrice`}
                value={this.state.equipments.microphone.headSetPrice.amount}
                isRequired
                containerStyle={{ padding:'0 20px', marginBottom: 'auto', width: '40%' }}
                onChange={(e) => this.handleValue('microphone', e)}
                criticalUpdate={this.state.lastUpdateType === CRITICAL}
                criticalPreviouslyValue={this.state.equipments.microphone.headSetPrice.amount}
              />
              <Group style={{ width: '60%' }}>
                <Label>Descrição do Head Set</Label>
                <Label>{this.state.createdEquipments.microphone.headSet.description}</Label>
              </Group>
            </FlexGroup>}
            {this.state.briefings.microphone.requests.some(item => item.numberOfStick > 0) && <FlexGroup>
              <MoneyInput
                label={'Valor do Bastão (por unidade, por dia)'}
                placeholder={'0,00'}
                name={`stickPrice`}
                value={this.state.equipments.microphone.stickPrice.amount}
                error={(!this.state.equipments.microphone.lapelPrice.amount || this.state.equipments.microphone.lapelPrice.amount <= 0) &&
                  (!this.state.equipments.microphone.gooseneckPrice.amount || this.state.equipments.microphone.gooseneckPrice.amount <= 0) &&
                  (!this.state.equipments.microphone.headSetPrice.amount || this.state.equipments.microphone.headSetPrice.amount <= 0) &&
                  (!this.state.equipments.microphone.stickPrice.amount || this.state.equipments.microphone.stickPrice.amount <= 0)}
                isRequired
                containerStyle={{ padding:'0 20px', marginBottom: 'auto', width: '40%' }}
                onChange={(e) => this.handleValue('microphone', e)}
                criticalUpdate={this.state.lastUpdateType === CRITICAL}
                criticalPreviouslyValue={this.state.equipments.microphone.stickPrice.amount}
              />
              <Group style={{ width: '60%' }}>
                <Label>Descrição do Bastão</Label>
                <Label>{this.state.createdEquipments.microphone.stick.description}</Label>
              </Group>
            </FlexGroup>}
            <Textarea
              label={'Observações'}
              placeholder={'Caso tenha alguma observação, escreva aqui.'}
              name={`observation`}
              value={this.state.equipments.microphone.observation}
              style={{ height: '75px' }}
              onChange={(e) => this.handleValue('microphone', e)}
              criticalUpdate={this.state.lastUpdateType === CRITICAL}
              criticalPreviouslyValue={this.state.equipments.microphone.observation}
            />
          </>
        }
        {(this.state.equipments.microphone && !this.state.equipments.microphone.hasAvailability && this.state.createdEquipments.microphone.enabled) && <Label>Não ofereço este equipamento.</Label>}
        {(!this.state.createdEquipments.microphone || !this.state.createdEquipments.microphone.enabled) && <Subtitle style={{ color: 'red', margin: '20px' }}>Este equipamento está pendente de cadastro. <Link to={{ pathname: `/equipments/add/${event}/${equipment}`, query: { url:`/budget/briefing/equipments/${event}/${equipment}${!!budget ? `/${budget}` : ''}`}}}>Clique aqui</Link> para cadastrá-lo e concluir o envio do orçamento.</Subtitle>}
      </Group>
    </CheckBox>
  }

  changeAvailability = () => {
    var { equipments } = this.state
    if(this.state.availability) {
      Object.keys(equipments).forEach(equipment => {
        equipments[equipment].hasAvailability = false
      })
    }
    this.setState({ availability: !this.state.availability, equipments: equipments })
  }

  isCounterProposalValid = () => {
    if(this.props.location.state && this.props.location.state.counterProposal === 'ACCEPTED') {
      return this.props.location.state.requestedPrice >= this.total()
    } else return true
  }

  isNewCounterProposalValid = () => {
    if(this.props.location.state && this.props.location.state.counterProposal === 'NEW') {
      return this.props.location.state.initialPrice >= this.total()
    } else return true
  }

  total = () => {
    return Object.keys(this.state.equipments).reduce((total, equipment) => {
      if(equipment !== 'microphone' && this.state.equipments[equipment].hasAvailability) {
        total += this.state.equipments[equipment].requests.reduce((soma, request) => {
          soma += this.state.equipments[equipment].price.amount * request.amount * (request.dates ? request.dates.length : 0)
          return soma
        }, 0)
      } else if(equipment === 'microphone' && this.state.equipments[equipment].hasAvailability) {
        total += this.state.equipments[equipment].requests.reduce((soma, request) => {
          soma += this.state.equipments[equipment].stickPrice.amount * request.numberOfStick * (request.dates ? request.dates.length : 0)
          soma += this.state.equipments[equipment].lapelPrice.amount * request.numberOfLapel * (request.dates ? request.dates.length : 0)
          soma += this.state.equipments[equipment].headSetPrice.amount * request.numberOfHeadSet * (request.dates ? request.dates.length : 0)
          soma += this.state.equipments[equipment].gooseneckPrice.amount * request.numberOfGooseneck * (request.dates ? request.dates.length : 0)
          return soma
        }, 0)
      }
      return total
    }, 0)
  }

  render() {
    const { match: { params: { event } } } = this.props
    const temQueEnviarContraproposta = this.props.location.state && (this.props.location.state.counterProposal === 'ACCEPTED' || this.props.location.state.counterProposal === 'NEW')
    return (
      <Container>
        <Header />
        <BreadCrumb options={
          [
            {link: '/', name: 'Home'},
            {link: `/budget/resume/${event}`, name: 'Resumo'},
            {name: 'Equipamentos'}
          ]
        } />
        <CommisedRate
          isOpen={this.state.CommisedOpen}
          onClose={() => this.setState({ CommisedOpen: false })}
          onSubmit={this.submitCommisedRate}
          client={this.state.event && this.state.event.client}
        />
        <Main>
          <Modal maxWidth= '800px' isOpen={this.state.equipmentsModal} onClose={() => this.setState({ equipmentsModal: false})} hasCloseButton>
            <FormEquipmentsModal
              selected={this.state.equipmentsModalSelected}
              onFinished={this.onFinished}
            />
          </Modal>

          {this.state.isLoading ? <Loader /> : null}
          {!this.state.isLoading && <Group style={{width: '80%', margin: 'auto'}}>
            {!temQueEnviarContraproposta && <Group style={{ display: 'flex', position: 'relative' }}>
              <Title style={{ margin: 'auto 0' }}>Equipamentos</Title>
              {this.props.location.state && (this.props.location.state.counterProposal === 'ACCEPTED' || this.props.location.state.counterProposal === 'NEW') && <Tag contraproposta>Contraproposta</Tag>}
              <Label style={{ width: 'fit-content', marginLeft: 'auto' }}>
                Não tenho disponibilidade para este evento
                <CheckboxIndisponibilidade
                  checked={!this.state.availability}
                  name={'Não tenho disponibilidade para este evento'}
                  id={'availability'}
                  style={{ paddingLeft: '5px' }}
                  onChange={this.changeAvailability}
                  type='checkbox' />
              </Label>
            </Group>}
            {
              this.props.location.state && (this.props.location.state.counterProposal === 'ACCEPTED' || this.props.location.state.counterProposal === 'NEW') && <Group style={{ textAlign: 'right', padding: '0px 15px' }}>
                <Subtitle>Valor da contraproposta <span style={{ fontWeight: 'bold' }}>{formatCurrency(this.props.location.state.requestedPrice)}</span>&nbsp;&nbsp;&nbsp;&nbsp;Valor da nova oferta <span style={{ fontWeight: 'bold', color: 'red' }}>{formatCurrency(this.total())}</span></Subtitle>
              </Group>
            }
            {this.state.availability && this.state.briefings.projector && this.state.briefings.projector.requested && this.Item({ item: 'projector' })}
            {this.state.availability && this.state.briefings.screen && this.state.briefings.screen.requested && this.Item({ item: 'screen' })}
            {this.state.availability && this.state.briefings.sound && this.state.briefings.sound.requested && this.Item({ item: 'sound' })}
            {this.state.availability && this.state.briefings.microphone && this.state.briefings.microphone.requested && this.ItemMicrofone()}
            {this.state.availability && this.state.briefings.radio && this.state.briefings.radio.requested && this.Item({ item: 'radio' })}
            {this.state.availability && this.state.briefings.operator && this.state.briefings.operator.requested && this.Item({ item: 'operator' })}
            {this.state.availability && this.state.briefings.generator && this.state.briefings.generator.requested && this.Item({ item: 'generator' })}
            {this.state.availability && this.state.briefings.flipchart && this.state.briefings.flipchart.requested && this.Item({ item: 'flipchart' })}
            {this.state.availability && this.state.briefings.laserPoint && this.state.briefings.laserPoint.requested && this.Item({ item: 'laserPoint' })}
            {this.state.availability && this.state.briefings.notebook && this.state.briefings.notebook.requested && this.Item({ item: 'notebook' })}
            {this.state.availability && this.state.briefings.lighting && this.state.briefings.lighting.requested && this.Item({ item: 'lighting' })}
            {this.state.availability && this.state.briefings.simultaneousTranslation && this.state.briefings.simultaneousTranslation.requested && this.Item({ item: 'simultaneousTranslation' })}
            {this.state.availability && this.state.briefings.internet && this.state.briefings.internet.requested && this.Item({ item: 'internet' })}
          </Group>}
        </Main>
        <Button
          type='submit'
          category={'secondary'}
          fontWeight={800}
          onClick={this.submitForm}
          style={{ width: '80%'}}
          full>
          Confirmar
        </Button>
      </Container>
    )
  }
}

export default BriefingEquipments
