/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
import jwtDecode from 'jwt-decode'
/* STYLES */
import { defaultTheme } from '../../styles/themes'
/* UI */
import Button from '../../components/ui/Button'
import BreadCrumb from '../../components/BreadCrumb'
/* COMPONENTS */
import Header from '../../components/Header'
import Loader from '../../components/Loader'
import config from '../../../middleware/config'
import { Get } from '../../../utils/request'
import { getNameFromKey } from '../../../utils/fabTexts'
import { formatCurrency } from '../../../utils/money'


const Main = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #FFF;
  @media (min-width: 768px) {
    background-color: #FAFAFA;
  }
`

const Content = styled.div`
  min-height: 90vh;
  padding: 30px 0px;
  font-family: ${defaultTheme.fonts.secondary};
  width: 90%;
  margin: 0 5%;
`

const Title = styled.h1`
  width: 100%;
  text-align: left;
  font-size: 20px;
  padding: 20px 10px;
`

const NoEquipments = styled.p`
  padding: 0px 10px;
  color: #7b7b7b;
`

const EquipmentsList = styled.div`
  padding: 0px 10px;
  color: #7b7b7b;
`

const EquipmentItem = styled.div`
  border: 1px solid #7b7b7b;
  background-color: #fff;
  border-radius: 3px;
  margin: 5px 0px;
  padding: 5px;
`

const EquipmentTitle = styled.span`
  color: #6c6c6c;
  font-size: 16px;
  width: 100%;
  display: block;
`

class Equipments extends Component {
  state = {
    isLoading: false
  }

  componentDidMount() {
    this.loadData()
  }

  loadData = () => {
    this.setState({
      isLoading: true
    }, () => {
      const { api: { base, version, routes: { equipments } } } = config
      const token = window.localStorage.getItem('user_token')
      const id = jwtDecode(token).accountId
      const endpoint = `${base}${version}${equipments}/account/${id}`
      Get(endpoint)
      .then((result) => {
        var equipments = result[0]
        delete equipments.accountId
        delete equipments.created
        delete equipments.id
        delete equipments.status
        delete equipments.updated
        delete equipments.userId
        this.setState({
          isLoading: false,
          equipments: equipments
        })
      }).catch(err => {
        console.error(err)
        this.setState({
          isLoading: false
        })
      })
    })
  }

  render() {
    const { isLoading, equipments } = this.state
    return (
      <Main>
        {isLoading ? <Loader /> : null}
        <Header />
        <BreadCrumb options={
          [
            {link: '/', name: 'Home'},
            {name: 'Equipamentos'}
          ]
        } />
        <Content>
        <Button
          onClick={() => this.props.history.push('/equipments/add')}
          type='button'
          category={'secondary'}
          fontWeight={800}
          full
          ghost>
            + Cadastrar novo equipamento
        </Button>
        <Title>
          Equipamentos
        </Title>
          {equipments
            ? <EquipmentsRender equipments={equipments}/>
            : <EmptyEquipments/>}
        </Content>
      </Main>
    )
  }
}

const EmptyEquipments = () => {
  return <NoEquipments>Nenhum equipamento cadastrado até o momento</NoEquipments>
}

const EquipmentsRender = ({equipments}) => {
  return <EquipmentsList>
            {Object.keys(equipments).filter(item => equipments[item].enabled).map((item, index) =>
                <EquipmentItem key={index}>
                  <EquipmentTitle>{item !== 'internet' ? getNameFromKey(item) : 'Internet'} - {formatCurrency(equipments[item].defaultPrice && equipments[item].defaultPrice.amount)}</EquipmentTitle>
                </EquipmentItem>
              )}
        </EquipmentsList>
}

export default Equipments
