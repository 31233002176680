import React, { Component } from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../styles/themes'


const Label = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  box-sizing: border-box;
  padding: 5px 5px 5px 0px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
  `

export default class AddressText extends Component {
  render () {
    return <Label>{this.props.address} {this.props.number} - {this.props.city} - {this.props.province}</Label>
  }
}
