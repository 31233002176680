import React, { Component } from 'react'
import SingleChat from './SingleChat'
import ChatMoreSuppliers from './ChatMoreSuppliers'

export default class ChatRoom extends Component {
  render () {
    const { eventId, eventName, eventStatus, supplierId, user, isMultiplo, fornecedores, onUnseeMessage } = this.props
    const DISPLAY_CHAT = !!(eventId && eventName && eventStatus && (supplierId || (isMultiplo && fornecedores)))

    return DISPLAY_CHAT &&
      <>{isMultiplo
        ? fornecedores && <ChatMoreSuppliers onUnseeMessage={this.props.onUnseeMessage} user={user} eventId={eventId} eventName={eventName} eventStatus={eventStatus} dados={fornecedores} />
        : <SingleChat display={!isMultiplo} onUnseeMessage={onUnseeMessage} user={user} eventId={eventId} supplierId={supplierId} eventName={eventName} eventStatus={eventStatus} />}
      </>
  }
}
