/* LIBS */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { defaultTheme } from '../../styles/themes'
/* UI */
import LogoEvnts from '../../components/ui/LogoEvnts'
import Subtitle from '../../components/ui/Subtitle'
import AlertIcon from '../../components/ui/AlertIcon'
import Button from '../../components/ui/Button'
import Modal from '../../components/Modal'
/* COMPONENTS */
import Form from './Form'

const LogoWrap = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 20px 0px;
  width: 100%;
  @media (min-width: 768px) {
    margin: 70px 0px;
  }
`
const Main = styled.main`
  display: flex;
  height: 90vh;
  align-items: center;
  -ms-align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #FFF;
  -ms-flex-direction: column;
  -webkit-flex-direction: initial;
  flex-direction: initial;
  @media (min-width: 768px) {
    background-color: #FAFAFA;
    height: 100vh;
  }
`

const LoginWrapper = styled.div`
  border-radius: 3px;
  margin: 0 5px;
  max-width: 390px;
  background-color: #FFF;
  transition: all 0.2s linear;
  -ms-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  @media (min-width: 768px) {
    border: none;
      border-radius: 2px;
      box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
  }
`

const Title = styled.h1`
  font-family: ${defaultTheme.fonts.secondary};
  line-height: 1.2;
  font-size: 1.3em;
  margin-top: auto;
  margin-bottom: 20px;
`

const Group = styled.div`
  height: inherit;
  overflow-y: auto;
`

class Login extends Component {
  constructor() {
    super()
    this.state = {
      avisaModal: document.documentMode || /Edge/.test(navigator.userAgent)
    }
  }

  render() {
    const { isAuthenticated, match } = this.props
    if (isAuthenticated) {
      const type = window.localStorage.getItem('user_type')
      if (type === 'ORGANIZER' || type === 'RESELLER') {
        this.props.history.push('/organizer')
      } else if (type === 'SUPPLIER') {
        this.props.history.push('/')
      }
    }
    return (
      <Main>
        <Modal isOpen={this.state.avisaModal}>
          <AlertIcon width={'150px'} />
          <Title>Atenção!</Title>
          <Subtitle>Algumas funcionalidades podem não funcionar corretamente no seu navegador. Utilize o Chrome/Firefox para uma melhor experiência.</Subtitle>
          <Group>
            <Button
              onClick={() => this.setState({ avisaModal: false })}
              category={'secondary'}
              fontWeight={800}
              width={'50%'}
              full>
              Ok
            </Button>
          </Group>
        </Modal>
        <LoginWrapper>
          <LogoWrap>
            <LogoEvnts primary style={{ width: '200px' }} />
          </LogoWrap>
          <Form history={this.props.history} vendors={match.params.type} />
        </LoginWrapper>
      </Main>
    )
  }
}

function mapStateToProps(state) {
  const { app, auth } = state
  const { language } = app
  const { isAuthenticated } = auth
  return {
    language,
    isAuthenticated
  }
}

export default connect(mapStateToProps)(Login)
