import React, { Component } from 'react'
import styled from 'styled-components'
import SliderSlick from "react-slick"
import './carousel.css'
import Icons from './Icons'

const Modal = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 999;
`

const CloseButton = styled.div`
  right: 50px;
  top: 50px;
  cursor: pointer;
  position: fixed;
  opacity: 0.75;
`

const DivGeral = styled.div`
  margin-top: ${props => props.marginTop || '0px'};
  max-width: ${props => props.maxWidth || '150px'};
  @media (min-width: 1150px) {
    max-width:  ${props => props.maxWidth || '250px'};
  }
`


const GaleriaImagens = styled.div`
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0;
  width: 70%;

  img {
    height: auto;
    max-height: calc(100vh - 100px);
    vertical-align: middle;
    width: 100%;
  }

  button.arrow {
    background-color: transparent;
    border: none;
    color: #f2f2f2;
    cursor: pointer;
    font-size: 2em;
    opacity: 0.75;
    padding: 16px 8px;
    z-index: 9999;
  }

  .arrow.anterior {
    left: -40px;
    position: absolute;
    top: 50%;
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
  }

  .arrow.proximo {
    position: absolute;
    right: -70px;
    top: 50%;
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
  }
`


export default class Slider extends Component {

  constructor() {
    super()
    this.state = {
      modal: false,
      imgAtual: 0
    }
  }

  alterarImagem = (ordem) => {
    if(ordem === 'anterior') {
      this.setState({ imgAtual: this.state.imgAtual > 0 ? (this.state.imgAtual - 1) : (this.props.fullScreenImages.length - 1) })
    }
    if(ordem === 'proxima') {
      this.setState({ imgAtual: this.state.imgAtual < (this.props.fullScreenImages.length - 1) ? (this.state.imgAtual + 1) : 0 })
    }
  }

  render() {
    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    }


    return (
      <DivGeral
          marginBottom={this.props.style && this.props.style.marginBottom}
          marginTop={this.props.style && this.props.style.marginTop}
          maxWidth={this.props.maxWidth} >
        <link rel="stylesheet" type="text/css" charSet="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
        <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
        <SliderSlick {...settings} style={this.props.style}>
          {this.props.children.map(item => <div key={item} onClick={() => this.setState({ modal: true })}>
            {item}
          </div>)}
        </SliderSlick>
        {(this.props.fullScreenImages && this.props.fullScreenImages.length > 0 && this.state.modal) && <Modal>
          <GaleriaImagens>
            <CloseButton>
              <Icons onClick={() => this.setState({ modal: false })} icon={'close'} width={'50'} height={'50'} color={'#f2f2f2'}/>
            </CloseButton>
            <button className='arrow anterior'
              onClick={() => this.alterarImagem('anterior')}><Icons icon={'angle-left'} width={'50'} height={'50'} color={'#f2f2f2'}/></button>
            <button className='arrow proximo'
              onClick={() => this.alterarImagem('proxima')} ><Icons icon={'angle-right'} width={'50'} height={'50'} color={'#f2f2f2'}/></button>
            <div style={{ width: '100%', height: 'calc(100% - 100px)', marginTop: '50px' }}>
              <span style={{display: 'inline-block', height: '100%', verticalAlign: 'middle'}}></span>
              <img alt='' src={this.props.fullScreenImages[this.state.imgAtual]} />
            </div>
          </GaleriaImagens>
        </Modal>}
      </DivGeral>
    )
  }
}
