import React from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../../styles/themes'
import SelectReact from 'react-select';

const Label = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  font-family: ${defaultTheme.fonts.primary};
  box-sizing: border-box;
  padding: 5px 0px;
  display: block;
  font-weight: 300;
  text-align: left;
`

const FormControl = styled.div`
  padding: 10px 10px;
  width: 100%;
  font-size: 16px;
  font-weight: 300;
  font-family: ${defaultTheme.fonts.primary};
`
const Strong = styled.strong`
  color: ${props => props.error ? 'red' : defaultTheme.colors.whiteLabel.primary};
  font-weight: bold;
`

export default function (props) {
  return (
    <FormControl>
      <Label htmlFor={props.name} error={props.error}>{props.label}
        <Strong error={props.error}>{props.isRequired ? '*' : ''}</Strong>
      </Label>
      <SelectReact
        isDisabled={props.isDisabled}
        value={props.value}
        onChange={(input) => props.onChange(input)}
        placeholder={props.placeholder}
        options={props.options}
      />
    </FormControl>
  )
}
