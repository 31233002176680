const config = {
  api: {
    base: '/api',
    version: '/v1',
    routes: {
      accounts: '/accounts',
      events: '/event',
      users: '/users',
      foods_and_beverages: '/foods_and_beverages',
      equipments: '/equipments',
      otherServices: '/other-services',
      parking: '/parking',
      transfer: '/transfer',
      supplier: '/supplier',
      organizer: '/organizer',
      reseller: '/reseller',
      chat: '/chat',
      homologation: '/homologation'
    }
  },
  url: {
    verificarTokenRedefinirSenha: '/api/v1/auth/is_valid_token/{TOKEN}',
    planilhaInteegrav2: '/api/v1/report/inteegra/{EVENTID}',
    planilhaInteegrav3: '/api/v1/report/inteegra/event/{EVENTID}/supplier/{HOTELID}'
  }
}

export default config
