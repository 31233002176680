import Dinheiro from  './dinheiro'

export const formatCurrency = (num = 0, currencySymbol = 'R$') => {
  num = removeCurrency(num)
  var Real = new Dinheiro('BRL')
  return Real.formata(parseFloat(num / 100), true, !currencySymbol)
}

export const removeCurrency = (num = 0) => {
  num = num.toString().trim()
  return num.replace(/[^0-9]/g, '')
}

export default formatCurrency
