import React, { Component } from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../../styles/themes'
import Icon from '../ui/Icons';

const DropdownWrapper = styled.div`
  cursor: pointer;
  position: relative;
`

const Wrapper = styled.div`
  transition: all .5s ease-in-out;
  height: '360px';
  overflow: hidden;
`

const Title = styled.h3`
  color: ${defaultTheme.colors.whiteLabel.primary};
  padding: 15px 25px;
  font-size: 1rem;
  font-family: ${defaultTheme.fonts.primary};
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  & svg.flip {
    -moz-transform: scaleY(-1);
    -o-transform: scaleY(-1);
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
    filter: FlipV;
    -ms-filter: "FlipV";
  }
`

export default class Dropdown extends Component {
  constructor() {
    super()
    this.state = {
      show: true
    }
  }

  showChat = () => {
    this.setState({ show: !this.state.show })
  }

  render() {
    return <DropdownWrapper {...this.props}>
      <Title onClick={this.showChat}>{this.props.title}</Title>
      <Icon
        onClick={this.showChat}
        width={20}
        height={20}
        style={{
          verticalAlign: 'middle',
          margin: 'auto 5px',
          fill: '#484848',
          position: 'absolute',
          right: '0px',
          top: '15px' }}
        className={this.state.show ? '' : 'flip'}
        icon={this.state.show ? 'angle-up' : 'angle-down'} />
      {this.state.show && <Wrapper>
        {this.props.children}
      </Wrapper>}
    </DropdownWrapper>
  }
}
