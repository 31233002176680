/* LIBS */
import React, { Component } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Get, Post, Put } from '../../../../utils/request'
// import { images } from '../../../styles/themes'
import moment from 'moment'

/* COMPONENTS */
import Header from '../../Header'
import Loader from '../../../components/Loader'
import Icons from '../../../components/ui/Icons'
import InputComponent from '../../../components/ui/Input'
import InputAddress from '../../../components/InputAddress'
import Subtitle from '../../../components/ui/Subtitle'
import RightBoxInput from '../../../components/ui/RightBoxInput'
import Card from '../../../components/ui/Card'
import Main from '../../../components/Main'
import Body from '../../../components/Body'
import Button from '../../../components/ui/Button'
import BreadCrumb from '../../../components/BreadCrumb'
import config from '../../../../middleware/config'
import { defaultTheme } from '../../../styles/themes'
import { formatDate } from '../../../../utils/dates'

import IndicacaoFornecedor from '../../../../screens/Organizer/Events/IndicacaoFornecedor/index'
import ServicosSolicitados from '../../../components/ServicosSolicitados';
import { ehChainUser } from '../../../../utils/tiposUsuario'

const MainMobile = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #FAFAFA;
  height: inherit;
  overflow-y: auto;
  & * { font-family: 'Open Sans', sans-serif; box-sizing: border-box; }
`
const DistanceToEvent = styled.div`
  color: ${defaultTheme.colors.grey.light};
  font-size: 80%;
`

const Container = styled.div`
  width: 100%;
  padding: 30px 20px;
  height: inherit;
  overflow-y: auto;
`

const ListaHoteis = styled.ul`
`

const LinkShowMap = styled.a`
  display: block;
  width: 100%;
  text-align: right;
  font-size: 13px;
  color: ${defaultTheme.colors.grey.medium};
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  text-decoration: underline;
  cursor: pointer;
  padding-left: 10px;
`

const Title = styled.h1`
  font-family: ${defaultTheme.fonts.secondary};
  line-height: 1.2;
  font-size: 1.3em;
  margin-bottom: 20px;
`

const Cabecalho = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;

  & li {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  & .right {
    text-align: right;
    padding-right: 40px;
  }

  & li b {
    font-weight: bold;
    padding-right: 5px;
  }
`

const Input = styled.input`
  display: none;
  &:checked+label:before,
  &:not(:checked)+label:before {
    content: "";
    position: absolute;
    right: 10px;
    top: ${props => props.top + 0}px;
    width: 18px;
    height: 18px;
    border: 1px solid #9b9b9b;
    background: #fff;
    -webkit-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;
  }

  &:checked+label,
  &:not(:checked)+label {
    position: relative;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
    width: 100%;
    -webkit-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;
  }

  &:checked+label:before,
  &:not(:checked)+label:before,
  &:checked+label:after {
    border-radius: 2px;
  }

  &:checked+label:after {
    content: "";
    width: 12px;
    height: 12px;
    background: ${defaultTheme.colors.whiteLabel.primary};
    position: absolute;
    right: 14px;
    top: 4px;
    -webkit-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;
  }
`

const Hotel = styled.label`
  display: block;
`

const FallBackImage = () => <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 231.12 155.21" style={{ fill: defaultTheme.colors.whiteLabel.primary }}>
  <title>nao_encontrado_</title>
  <g id="CEmoIY.tif">
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M68.87,152.63H76v-1.38q0-47.32,0-94.65a2,2,0,0,1,2.32-2.29q20.11,0,40.25,0c1.64,0,2.29.66,2.29,2.31q0,23.08,0,50V108h1.3q15.93,0,31.86,0c1.89,0,2.68.5,2.68,2.73v41.87h9.45V95.65h-9.42v8.89h-3.15V95.66h-9.42v7h-2.92V86.79c-1.61,0-4,0-5.48,0a5.19,5.19,0,0,1-1.41-.22,1.47,1.47,0,0,1-1.1-1.54c0-4.89,0-9.78,0-14.67,0-1.09.81-1.75,2.51-1.76,2.23,0,5.72,0,10.49,0h1.27V63.73c0-1.57.69-2.26,2.28-2.28h2.94l.33-.09V49.07h3.38V61.34a1.33,1.33,0,0,0,.32.09l2.89,0c1.67,0,2.29.64,2.29,2.29s0,3.18,0,4.84h1.27c3.46,0,6.91,0,10.37,0a2.93,2.93,0,0,1,2.63,1V85.75a2.68,2.68,0,0,1-2.32,1h-4.76v65.84h7.08V155H68.87Zm9.83-.06h75.38V110.64H120.8v35h-2.34V56.87H78.74v5.61h25.88v2.76H78.7Zm57.09-81.32V84.42h37.94V71.25Zm8.36,21.36h22V86.86h-22Zm15.28-28.85H150v4.75h9.42Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M111.83,75.9c0,1.22,0,2.44,0,3.66a1.6,1.6,0,0,1-1.8,1.77c-2.41,0-4.81,0-7.22,0A1.74,1.74,0,0,1,101,79.56q0-3.66,0-7.32a1.74,1.74,0,0,1,1.84-1.77q3.6,0,7.22,0a1.6,1.6,0,0,1,1.8,1.77C111.84,73.46,111.83,74.68,111.83,75.9Zm-8.72,3.23h6.53V72.67h-6.53Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M110.09,147.54H86.77v-2.4h23.32Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M149.54,147.53H144v-3.62h5.52Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M131.72,143.89v3.64h-5.5v-3.64Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M144,140.37v-3.58h5.54v3.58Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M131.69,140.42h-5.46v-3.64h5.46Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M149.52,133.3H144v-3.66h5.48Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M126.17,133.25v-3.59h5.55v3.59Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M144,122.29h5.5v3.62H144Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M131.69,126h-5.47V122.3h5.47Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M144,118.79v-3.6h5.54v3.6Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M126.17,118.78V115.2h5.54v3.58Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M140.5,147.55h-5.26V143.9h5.26Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M140.55,136.78v3.6h-5.33v-3.6Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M140.56,129.66v3.59h-5.34v-3.59Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M140.53,122.3v3.63h-5.29V122.3Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M140.51,118.83h-5.28v-3.66h5.28Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M145.74,79.61h-3.63V76h3.63Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M151.2,76V79.6h-3.62V76Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M153,76h3.64v3.62H153Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M158.24,79.6V76h3.62V79.6Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M167.33,79.61H163.7V76h3.63Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M95.73,75.9c0,1.22,0,2.44,0,3.66a1.59,1.59,0,0,1-1.8,1.77c-2.4,0-4.81,0-7.21,0a1.73,1.73,0,0,1-1.84-1.77q-.06-3.66,0-7.32a1.73,1.73,0,0,1,1.83-1.77q3.61,0,7.22,0a1.59,1.59,0,0,1,1.8,1.77C95.74,73.46,95.73,74.68,95.73,75.9ZM87,79.13h6.53V72.67H87Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M111.83,90.15c0,1.22,0,2.44,0,3.66a1.61,1.61,0,0,1-1.8,1.78h-7.22A1.74,1.74,0,0,1,101,93.82q0-3.66,0-7.32a1.75,1.75,0,0,1,1.84-1.78q3.6,0,7.22,0a1.61,1.61,0,0,1,1.8,1.77C111.84,87.71,111.83,88.93,111.83,90.15Zm-8.72,3.23h6.53V86.93h-6.53Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M95.73,90.15c0,1.22,0,2.44,0,3.66a1.61,1.61,0,0,1-1.8,1.78H86.71a1.73,1.73,0,0,1-1.84-1.77q-.06-3.66,0-7.32a1.74,1.74,0,0,1,1.83-1.78q3.61,0,7.22,0a1.6,1.6,0,0,1,1.8,1.77C95.74,87.71,95.73,88.93,95.73,90.15ZM87,93.38h6.53V86.93H87Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M111.83,104.64c0,1.22,0,2.44,0,3.66a1.6,1.6,0,0,1-1.8,1.77c-2.41,0-4.81,0-7.22,0A1.74,1.74,0,0,1,101,108.3q0-3.66,0-7.32a1.76,1.76,0,0,1,1.84-1.78c2.4,0,4.81,0,7.22,0a1.6,1.6,0,0,1,1.8,1.77C111.84,102.2,111.83,103.42,111.83,104.64Zm-8.72,3.23h6.53v-6.46h-6.53Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M95.73,104.64c0,1.22,0,2.44,0,3.66a1.6,1.6,0,0,1-1.8,1.77c-2.4,0-4.81,0-7.21,0a1.73,1.73,0,0,1-1.84-1.77q-.06-3.66,0-7.32A1.74,1.74,0,0,1,86.7,99.2c2.41,0,4.82,0,7.22,0a1.59,1.59,0,0,1,1.8,1.77C95.74,102.2,95.73,103.42,95.73,104.64ZM87,107.87h6.53v-6.46H87Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M111.83,118.89c0,1.22,0,2.44,0,3.66a1.61,1.61,0,0,1-1.8,1.78c-2.41,0-4.81,0-7.22,0a1.75,1.75,0,0,1-1.84-1.77c0-2.44,0-4.89,0-7.33a1.74,1.74,0,0,1,1.84-1.77H110a1.61,1.61,0,0,1,1.8,1.77C111.84,116.45,111.83,117.67,111.83,118.89Zm-8.72,3.23h6.53v-6.46h-6.53Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M95.73,118.89c0,1.22,0,2.44,0,3.66a1.61,1.61,0,0,1-1.8,1.78c-2.4,0-4.81,0-7.21,0a1.74,1.74,0,0,1-1.84-1.77c0-2.44,0-4.89,0-7.33a1.73,1.73,0,0,1,1.83-1.77h7.22a1.6,1.6,0,0,1,1.8,1.77C95.74,116.45,95.73,117.67,95.73,118.89ZM87,122.12h6.53v-6.46H87Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M111.83,133.11c0,1.22,0,2.44,0,3.66a1.61,1.61,0,0,1-1.8,1.78h-7.22a1.75,1.75,0,0,1-1.84-1.77c0-2.44,0-4.89,0-7.33a1.74,1.74,0,0,1,1.84-1.77q3.6,0,7.22,0a1.61,1.61,0,0,1,1.8,1.77C111.84,130.67,111.83,131.89,111.83,133.11Zm-8.72,3.23h6.53v-6.46h-6.53Z" />
    <path style={{ fill: defaultTheme.colors.whiteLabel.primary, strokeWidth: '0.5px', stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10 }} d="M95.73,133.11c0,1.22,0,2.44,0,3.66a1.61,1.61,0,0,1-1.8,1.78H86.71a1.74,1.74,0,0,1-1.84-1.77c0-2.44,0-4.89,0-7.33a1.73,1.73,0,0,1,1.83-1.77q3.61,0,7.22,0a1.6,1.6,0,0,1,1.8,1.77C95.74,130.67,95.73,131.89,95.73,133.11ZM87,136.34h6.53v-6.46H87Z" />
  </g>
  <circle style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} cx="56.91" cy="5.84" r="3.85" />
  <circle style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} cx="208.47" cy="32.86" r="3.85" />
  <circle style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} cx="21.63" cy="150.08" r="3.85" />
  <line style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} x1="170.01" y1="4.85" x2="160.31" y2="4.85" />
  <line style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} x1="165.16" x2="165.16" y2="9.71" />
  <line style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} x1="16.17" y1="31.72" x2="6.46" y2="31.72" />
  <line style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} x1="11.31" y1="26.87" x2="11.31" y2="36.58" />
  <line style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} x1="218.07" y1="147.37" x2="208.36" y2="147.37" />
  <line style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} x1="213.21" y1="142.52" x2="213.21" y2="152.23" />
  <line style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} x1="9.71" y1="102.6" y2="102.6" />
  <line style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} x1="4.85" y1="97.75" x2="4.85" y2="107.46" />
  <path style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} d="M136.68,21.59c-4.65-1.13-3.13-7.42-7.78-8.55s-6.17,5.16-10.82,4S115,9.66,110.31,8.53s-6.18,5.16-10.83,4" />
  <path style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} d="M50.85,67c-4.79,0-4.79-6.47-9.57-6.47S36.49,67,31.71,67s-4.78-6.47-9.57-6.47S17.35,67,12.57,67" />
  <path style={{ stroke: defaultTheme.colors.whiteLabel.primary, strokeMiterlimit: 10, fill: 'none' }} d="M230.87,103.41c-4.15-2.39-.91-8-5.05-10.38s-7.38,3.21-11.52.82-.91-8-5.06-10.39-7.38,3.21-11.52.81" />
</svg>

const Background = styled.div`
  background-image: ${props => `url(${props.src}), url(data:image/svg+xml,${encodeURIComponent(renderToStaticMarkup(<FallBackImage />))})`};
  background-position: center center;
  background-repeat:   no-repeat;
  color: ${defaultTheme.colors.whiteLabel.primary};
  background-size: ${props => props.capa ? `cover` : `cover`};
  height: 160px;
  width: 100%;
`

const DescricaoHotel = styled.ul`
  padding: 20px 15px;
  background: #fff;

  & .titulo {
    font-family: 'Pluto', sans-serif;
    font-size: 1.1em;
  }
`

const Group = styled.div`
`

const Label = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  box-sizing: border-box;
  padding: 5px 5px 5px 0px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
`

const FixedDiv = styled.div`
  position: fixed;
  top: 46px;
  bottom: 0;
  width: 25%;
  overflow-y: scroll;
  margin-top: 14px;
`

function distanceString (distanceInMeters) {
  return (distanceInMeters < 1000)
    ? `${(distanceInMeters).toFixed(2)} m`
    : `${(distanceInMeters / 1000).toFixed(2)} km`
}

class SupplierSelect extends Component {
  constructor () {
    super()
    this.state = {
      isLoading: false,
      selectAll: false,
      locationErrorMessage: '',
      suppliers: [],
      suppliersSelected: {},
      filter: '',
      filterHandler: '',
      event: {},
      services: '',
      showMap: false
    }
  }

  componentDidMount () {
    if (ehChainUser()) {
      alert('Você não tem permissão para acessar esta página.')
      this.props.history.push(`/organizer`)
    }
    this.fetchSuppliers()
  }


  onChange = (key, value) => {
    const suppliersSelected = this.state.suppliersSelected
    suppliersSelected[key] = value
    this.setState({ suppliersSelected: suppliersSelected })
  }

  selectAll = () => {
    const suppliersSelected = this.state.suppliersSelected
    Object.keys(suppliersSelected).map((key) => {
      return suppliersSelected[key] = !this.state.selectAll
    })

    this.setState({
      suppliersSelected: suppliersSelected,
      selectAll: !this.state.selectAll
    })
  }

  selectFirst12 = () => {
    const suppliersSelected = this.state.suppliersSelected
    Object.keys(suppliersSelected).map((key, i) => {
      if (i < 12) {
        return suppliersSelected[key] = !this.state.selectAll
      } else return suppliersSelected[key]
    })

    this.setState({
      suppliersSelected: suppliersSelected,
      selectAll: !this.state.selectAll
    })
  }

  selectedCount = () => {
    return Object.keys(this.state.suppliersSelected).filter(key => this.state.suppliersSelected[key]).length
  }

  fetchSuppliers = () => {
    this.setState({ isLoading: true })
    const { api: { base, version } } = config
    const { id } = this.props.match.params
    const userType = window.localStorage.getItem('user_type')
    const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
    const eventInfoEndpoint = `${base}${version}${eventEndpoint}/${id}`
    const selectedSuppliersEndpoint = `${base}${version}${eventEndpoint}/${id}/suppliers/select?services=FLIGHT,ACCOMMODATION,ROOM,FOOD_BEVERAGE,EQUIPMENT`

    Promise.all([
      Get(eventInfoEndpoint),
      Get(selectedSuppliersEndpoint)
    ]).then(([data, suppliers]) => {
      this.setState({ event: data}, () => {
        this.setState({
          event: {
            id: data.id,
            name: data.name,
            startDate: data.start,
            endDate: data.end,
            start: [moment(data.start, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br')],
            end: [moment(data.end, 'YYYY-MM-DD[T]HH:mm:ssZ').locale('pt-br')],
            startTime: data.startTime,
            endTime: data.endTime,
            hasLocal: (!!data.location.address).toString(),
            city: data.location.city,
            address: (!!data.location.address).toString() === 'false' ? '' : data.location.address,
            location: data.location,
            type: data.type,
            anotherType: data.type,
            goal: data.goal,
            segment: data.segment,
            numberOfParticipants: data.numberOfParticipants,
            participantsProfile: data.participantsProfile,
            budget: data.budget.amount,
            observations: data.observations,
            radius: data.location.radius,
            services: data.services,
            friendlyId: data.internalId || data.friendlyId,
            resellerId: data.resellerId
          },
          services: data.services,
          suppliersSelected: suppliers.reduce((all, supplier) => {
            all[supplier.id] = Array.prototype.concat(data.chosenSuppliers).indexOf(supplier.id) !== -1
            return all
          }, {}),
          suppliers,
          isLoading: false
        })
      })
    }).catch(error => {
      this.setState({ isLoading: false })
      console.error(error.message)
      alert(`Infelizmente não conseguimos receber os fornecedores. Tente novamente mais tarde. (${error.message})`)
    })
  }

  confirm = () => {
    if (this.selectedCount() === 0) return alert('Selecione pelo menos um fornecedor')
    this.setState({ isLoading: true })
    const { api: { base, version } } = config
    const { id } = this.props.match.params
    const history = this.props.history
    const userType = window.localStorage.getItem('user_type')
    const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
    const endpoint = `${base}${version}${eventEndpoint}/${id}/suppliers/select`
    Post(endpoint, {
      suppliers: Object.keys(this.state.suppliersSelected).filter(key => this.state.suppliersSelected[key])
    }).then((response) => {
      history.push(`/organizer/event/resume/${id}`)
    }).catch(err => {
      console.error(err.message)
      alert(`Infelizmente não conseguimos receber os fornecedores. Tente novamente mais tarde. (${err.message})`)
      this.setState({ isLoading: false })
    })
  }

  handlerValue = (input) => {
    this.setState({
      filterHandler: input.target.value
    })
  }

  filterValue = () => {
    this.setState({
      filter: this.state.filterHandler
    })
  }

  handlerRayValue = (input) => {
    var event = this.state.event
    event.radius = input.target.value
    this.setState({
      event: event
    })
  }

  addressSelected = (data) => {
    const { event } = this.state
    event['address'] = data.address
    event['location'] = data
    event['city'] = data.city
    this.setState({
      event: event
    })
  }

  editLocation = (e) => {
    e.preventDefault()
    const { event } = this.state
    const { api: { base, version } } = config
    const { id } = this.props.match.params
    if (!(this.state.event.radius > 0)) return alert('Informe o raio!')
    if (this.validateFormLocationData(event)) {
      this.setState({ isLoading: true })
      event.location.radius = this.state.event.radius
      const data = {
        name: event.name,
        start: event.start,
        end: event.end,
        startTime: event.startTime,
        endTime: event.endTime,
        location: event.location,
        type: event.type,
        goal: event.goal,
        segment: event.segment,
        numberOfParticipants: event.numberOfParticipants,
        participantsProfile: event.participantsProfile,
        budget: event.budget,
        observations: event.observations,
        briefings: {},
        services: event.services,
        friendlyId: event.internalId || event.friendlyId,
        resellerId: event.resellerId
      }
      const userType = window.localStorage.getItem('user_type')
      const eventEndpoint = userType === 'RESELLER' ? '/consultant/event' : '/event'
      const endpoint = `${base}${version}${eventEndpoint}/${id}`
      Put(endpoint, data).then(() => {
        window.location.reload()
      }).catch(err => {
        console.error(err.message)
        alert(`Infelizmente não conseguimos editar a sua localização. Tente novamente mais tarde. (${err.message})`)
        this.setState({ isLoading: false })
      })
    } else {
      alert(`Digite uma localização válida!`)
    }
  }

  validateFormLocationData = (form) => {
    return !(form['location'] === '' && (this.state.event.hasLocal === 'true' ? form['address'] === '' : form['city'] === ''))
  }

  gerenciaResponsividade = () => {
    if (window.innerWidth < 600) return this.paginaMobile()
    else return this.paginaDesktop()
  }

  paginaMobile = () => {
    const { isLoading, services } = this.state
    const { id } = this.props.match.params
    const lodging = services.lodging && services.lodging.requested && (services.lodging.id ? `/organizer/event/lodging/${id}/${services.lodging.id}` : `/organizer/event/lodging/${id}`)
    const room = services.room && services.room.requested && (services.room.id ? `/organizer/event/rooms/${id}/${services.room.id}` : `/organizer/event/rooms/${id}`)
    const foodsAndBeverages = services.foodsAndBeverages && services.foodsAndBeverages.requested && (services.foodsAndBeverages.id ? `/organizer/event/food-and-beverage/${id}/${services.foodsAndBeverages.id}` : `/organizer/event/food-and-beverage/${id}`)
    return (
      <MainMobile>
        <Header />
        {lodging && room && foodsAndBeverages && <BreadCrumb options={[
          { link: '/organizer', name: 'Home' },
          { link: `/organizer/event/${id}`, name: 'Dados gerais do evento' },
          { link: lodging, name: 'Hospedagem' },
          { link: room, name: 'Salas' },
          { link: foodsAndBeverages, name: 'Alimentos e bebidas' },
          { name: 'Local do Evento ' }
        ]} />
        }
        {lodging && room && !foodsAndBeverages && <BreadCrumb options={[
          { link: '/organizer', name: 'Home' },
          { link: `/organizer/event/${id}`, name: 'Dados gerais do evento' },
          { link: lodging, name: 'Hospedagem' },
          { link: room, name: 'Salas' },
          { name: 'Local do Evento ' }
        ]} />
        }
        {!lodging && room && foodsAndBeverages && <BreadCrumb options={[
          { link: '/organizer', name: 'Home' },
          { link: `/organizer/event/${id}`, name: 'Dados gerais do evento' },
          { link: room, name: 'Salas' },
          { link: foodsAndBeverages, name: 'Alimentos e bebidas' },
          { name: 'Local do Evento ' }
        ]} />
        }
        {!lodging && room && !foodsAndBeverages && <BreadCrumb options={[
          { link: '/organizer', name: 'Home' },
          { link: `/organizer/event/${id}`, name: 'Dados gerais do evento' },
          { link: room, name: 'Salas' },
          { name: 'Local do Evento ' }
        ]} />
        }
        <Container>
          {isLoading ? <Loader /> : null}
          <Title>Local do Evento</Title>
          <Subtitle style={{ textAlign: 'justify' }}>Estes são os fornecedores recomendados para seu evento. Todos estão aptos a atender as configurações solicitadas por você. Escolha abaixo quais deles você deseja receber orçamentos.</Subtitle>
          {(this.state.event.city || this.state.event.address) && <Group style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
            <Group style={{ width: window.innerWidth <= 600 ? '100%' : '60%' }}>
              <InputAddress
                type={'text'}
                label={'Local (cidade, bairro, endereço específico) '}
                placeholder={'Digite o local'}
                name={'city'}
                error={this.state.locationErrorMessage}
                defaultValue={this.state.event.address || this.state.event.city}
                hideMap={true}
                addressSelected={this.addressSelected}
                isRequired
              />
            </Group>
            <Group style={{ width: window.innerWidth <= 600 ? '100%' : '20%' }}>
              <InputComponent
                type={'number'}
                label={'Raio'}
                placeholder={'Digite a distância'}
                name={'radius'}
                style={{ textAlign: 'right' }}
                rightIconPadding={'30px'}
                rightIcon={'km'}
                value={this.state.event.radius}
                id={'radius'}
                onChange={this.handlerRayValue}
              />
            </Group>
            <Group style={{ width: window.innerWidth <= 600 ? '100%' : '20%', margin: 'auto' }}>
              <Button
                type='button'
                category={'secondary'}
                fontWeight={400}
                full
                onClick={this.editLocation}>
                Atualizar
              </Button>
            </Group>
          </Group>}
          <FallBackImage />
          <InputComponent
            type={'text'}
            label='Busca por nome'
            placeholder='Digite aqui o nome do hotel'
            name={'filter'}
            value={this.state.filter}
            onChange={this.handlerValue}
          />
          <ListaHoteis>
            <Cabecalho>
              <li style={{ paddingLeft: '15px' }}><b>{this.state.suppliers.length}</b> fornecedores sugeridos</li>
              <li style={{ textAlign: 'end', display: 'inline-block', paddingRight: '15px' }}><b>{this.selectedCount()}</b> selecionado(s)</li>
              {/* <li>
                <Input
                  top={0}
                  type='checkbox'
                  id='todos'
                  onChange={this.selectAll}/>
                <Label className='right' htmlFor='todos'>selecionar todos</Label>
              </li> */}
            </Cabecalho>
            <div style={{ display: 'flex', flexFlow: 'row wrap', alignContent: 'space-between', justifyContent: 'space-between' }}>
              {this.state.suppliers.filter(supplier => supplier.name.toLowerCase().indexOf(this.state.filter.toLowerCase()) !== -1).map((supplier, i) =>
                <Card i={i} key={i}>
                  <Input
                    type='checkbox'
                    id={`supplier_${i}`}
                    checked={this.state.suppliersSelected[supplier.id]}
                    onChange={(e) => this.onChange(supplier.id, e.target.checked)} />
                  <Hotel htmlFor={`supplier_${i}`}>
                    <Background src={supplier.pictures ? supplier.pictures.main : null} />
                    <DescricaoHotel>
                      <li className='titulo'>{supplier.name}</li>
                      <li>{supplier.location ? supplier.location.address : ''}</li>
                      <li><DistanceToEvent>{(supplier.distanceToVenue && supplier.distanceToVenue !== 0) ? `A ${distanceString(supplier.distanceToVenue)} do evento` : ''}</DistanceToEvent></li>
                    </DescricaoHotel>
                  </Hotel>
                </Card>)}
            </div>
          </ListaHoteis>
          <Button
            type='button'
            category={'secondary'}
            fontWeight={800}
            full
            onClick={this.confirm}>
            Confirmar pedido
          </Button>
        </Container>
      </MainMobile>
    )
  }

  paginaDesktop = () => {
    const { isLoading, services } = this.state
    const { id } = this.props.match.params
    const lodging = services.lodging && services.lodging.requested && (services.lodging.id ? `/organizer/event/lodging/${id}/${services.lodging.id}` : `/organizer/event/lodging/${id}`)
    const room = services.room && services.room.requested && (services.room.id ? `/organizer/event/rooms/${id}/${services.room.id}` : `/organizer/event/rooms/${id}`)
    const foodsAndBeverages = services.foodsAndBeverages && services.foodsAndBeverages.requested && (services.foodsAndBeverages.id ? `/organizer/event/food-and-beverage/${id}/${services.foodsAndBeverages.id}` : `/organizer/event/food-and-beverage/${id}`)
    const equipments = services.equipments && services.equipments.requested && (services.equipments.id ? `/organizer/event/equipments/${id}/${services.equipments.id}` : `/organizer/event/equipments/${id}`)
    return <>
      <Header />
      <Main>
        <Body {...this.props} pagina={'create-evento'} menuLateralDireito={this.menuLateralDireito()}>
          <BreadCrumb options={[
            { link: '/organizer', name: 'Home' },
            { link: `/organizer/event/${id}`, name: 'Dados gerais do evento' },
            lodging && { link: lodging, name: 'Hospedagem' },
            room && { link: room, name: 'Salas' },
            foodsAndBeverages && { link: foodsAndBeverages, name: 'Alimentos e bebidas' },
            equipments && { link: equipments, name: 'Equipamentos' },
            { name: 'Local do Evento ' }
          ]} />
          <Container>
            <IndicacaoFornecedor isOpen={this.state.modalState} eventId={this.state.event.id} onClose={() => this.setState({ modalState: false })} />
            {isLoading ? <Loader /> : null}
            <Group style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Title>Fornecedores sugeridos</Title>
              <Group style={{ width: '50%', textAlign: 'end' }}>
                <Link to='#' onClick={() => this.setState({ modalState: true })} style={{ color: defaultTheme.colors.whiteLabel.primary, fontSize: '15px', display: 'block' }}>indicar fornecedores</Link>
              </Group>
            </Group>
            <Subtitle style={{ textAlign: 'justify' }}>Selecione os locais de seu interesse para solicitar orçamentos</Subtitle>
            <Card>
              {(this.state.event.city || this.state.event.address) && <>
                <LinkShowMap onClick={() => this.setState({ showMap: !this.state.showMap })}>({this.state.showMap ? 'ocultar' : 'mostrar'} mapa)</LinkShowMap>
                <InputAddress
                  type={'text'}
                  label={'Local (cidade, bairro, endereço específico) '}
                  placeholder={'Digite o local'}
                  name={'city'}
                  hideMap={!this.state.showMap}
                  error={this.state.locationErrorMessage}
                  defaultValue={this.state.event.address || this.state.event.city}
                  addressSelected={this.addressSelected}
                  isRequired
                />
                <Group style={{ display: 'flex' }}>
                  <Group style={{ width: '50%' }}>
                    <RightBoxInput
                      placeholder={'Digite a distância'}
                      rightBox='km'
                      type='number'
                      name={'radius'}
                      id={'radius'}
                      onChange={this.handlerRayValue}
                      value={this.state.event.radius}
                      error={!(this.state.event.radius > 0)}
                      isRequired
                      label={'Raio'}
                      style={{ width: '100%', paddingLeft: '10px' }} />
                  </Group>
                  <Group style={{ width: '50%', margin: 'auto' }}>
                    <Button
                      type='button'
                      category={'secondary'}
                      fontWeight={400}
                      style={{
                        width: '100px',
                        margin: 'auto',
                        marginTop: '15px',
                        fontSize: '11px'
                      }}
                      onClick={this.editLocation}
                      full>
                      Atualizar
                    </Button>
                  </Group>
                </Group>
              </>}
            </Card>
            <Group style={{ paddingTop: '30px' }}>
              <Group style={{ display: 'flex' }}>
                <InputComponent
                  type={'text'}
                  label='Busca por nome'
                  placeholder='Digite aqui o nome do hotel'
                  name={'filter'}
                  onBlur={this.handlerValue}
                />
                <Button
                  type='button'
                  category={'secondary'}
                  onClick={this.filterValue}
                  style={{ width: '100px', fontSize: '11px', marginTop: '40px'}}
                  fontWeight={400}
                  full>
                  Buscar
                </Button>
              </Group>
              <Group>
                <Cabecalho style={{ flexDirection: 'column', paddingTop: '25px' }}>
                  <li>
                    <Input
                      top={0}
                      type='checkbox'
                      id='todos'
                      onChange={this.selectFirst12} />
                    <Label className='right' htmlFor='todos'>Selecionar recomendados</Label>
                  </li>
                  <li style={{ textAlign: 'end', display: 'inline-block', paddingRight: '15px' }}><b>{this.selectedCount()}</b> <Label style={{ display: 'inline' }}>selecionado(s)</Label></li>
                </Cabecalho>
              </Group>
            </Group>
            <ListaHoteis>
              <div style={{ display: 'flex', flexFlow: 'row wrap', alignContent: 'space-between', justifyContent: 'space-between' }}>
                {this.state.suppliers.filter(supplier => supplier.name.toLowerCase().indexOf(this.state.filter.toLowerCase()) !== -1).map((supplier, i) =>
                  <Card i={i} key={i} style={{ width: 'calc((100% / 2) - 10px)' }}>
                    <Input
                      top={5}
                      type='checkbox'
                      id={`supplier_${i}`}
                      checked={this.state.suppliersSelected[supplier.id]}
                      onChange={(e) => this.onChange(supplier.id, e.target.checked)} />
                    <Hotel htmlFor={`supplier_${i}`}>
                      <Background src={supplier.pictures ? supplier.pictures.main : null} />
                      <DescricaoHotel style={{ paddingTop: '5px' }}>
                        <li><DistanceToEvent>{(supplier.distanceToVenue && supplier.distanceToVenue !== 0) ? `A ${distanceString(supplier.distanceToVenue)} do evento` : ''}</DistanceToEvent></li>
                        <li className='titulo'>{supplier.name}</li>
                        <li><Label style={{ fontSize: '13px' }}>{supplier.location ? supplier.location.address : ''}</Label></li>
                        <li><Label style={{ fontSize: '13px', padding: '0', marginTop: '-10px' }}>{supplier.location ? `${supplier.location.city} - ${supplier.location.province}` : ''}</Label></li>
                      </DescricaoHotel>
                    </Hotel>
                  </Card>)}
              </div>
            </ListaHoteis>
          </Container>
        </Body>
      </Main>
    </>
  }

  menuLateralDireito = () => {
    const { briefing } = this.props.match.params
    return <FixedDiv>
      <Card style={{ width: '100%' }}>
        <Label style={{ textTransform: 'uppercase' }}>Resumo do Evento <span style={{ float: 'right', paddingRight: '10px' }}>ID:{this.state.event.internalId || this.state.event.friendlyId}</span></Label>
        <Group>
          <Label style={{ fontWeight: 600, color: '#484848' }}>{this.state.event.name}</Label>
          <Label>Solicitado há {this.state.event.created ? moment().diff(moment(this.state.event.created, 'YYYY-MM-DD[T]HH:mm:ssZ'), 'days') : 0} dias</Label>
          <Group style={{ display: 'flex' }}>
            <Icons
              icon={'clock-o'}
              color={'gray'}
              width={40}
              height={20}
              style={{ marginRight: '-5px', marginTop: '5px' }} />
            <Label>{formatDate(this.state.event.startDate)} - {formatDate(this.state.event.endDate)}</Label>
          </Group>
          <Group style={{ display: 'flex' }}>
            <Icons
              icon={'map-pin'}
              color={'gray'}
              width={40}
              height={20}
              style={{ marginRight: '-5px', marginTop: '5px', marginLeft: '2px' }} />
            <Label>{this.state.event.address} - {this.state.event.city}</Label>
          </Group>
          <Group style={{ display: 'flex' }}>
            <Icons
              icon={'person'}
              color={'gray'}
              width={40}
              height={20}
              style={{ marginTop: '5px', marginLeft: '-3px' }} />
            <Label>{this.state.event.numberOfParticipants} pessoas</Label>
          </Group>
        </Group>
      </Card>
      <Card style={{ width: '100%' }}>
        <ServicosSolicitados services={this.state.services} selected={'locais'} />
        <Button
          type='submit'
          category={'secondary'}
          form={'form'}
          fontWeight={800}
          onClick={this.confirm}
          full>
          {briefing ? 'Ok' : 'Próximo'}
        </Button>
      </Card>
    </FixedDiv>
  }

  render () {
    return this.gerenciaResponsividade()
  }
}

export default SupplierSelect
