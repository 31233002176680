const API = '/api/v1'

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE'

function requestSignUp (company) {
  return {
    type: SIGNUP_REQUEST,
    isFetching: true,
    company
  }
}

export function signUp (company) {
  return dispatch => {
    dispatch(requestSignUp(company))
  }
}

export function singUpInsert (company) {
  return dispatch => {
    dispatch(requestSignUp(company))
    dispatch(singUpSuccess(company))
  }
}

function buttonToggle(type) {
  const buttons = document.querySelectorAll('button')
  buttons.forEach(btn => {
    btn.disabled = (type === 'disable' ? true : false)
  })
}

export function signUpService (company) {
  buttonToggle('disable')

  let config = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(company)
  }

  return dispatch => {
    dispatch(requestSignUp(company))
    return window.fetch(`${API}/accounts`, config)
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          dispatch(singUpError(response))
          throw new Error(response)
        }
      }).then((user) => {
        buttonToggle('enable')
        if (!user.success) throw new Error(JSON.stringify(user))
        window.localStorage.setItem('user_token', user.data.token)
        window.localStorage.setItem('expires', user.data.expires)
        window.localStorage.setItem('user', user.data.user)
        window.localStorage.setItem('account', user.data.account)
        window.localStorage.setItem('user_roles', user.data.role && JSON.stringify(user.data.role))
        window.localStorage.setItem('user_type', user.data.type)
        if (user.data.supplierChainId) window.localStorage.setItem('supplierChainId', user.data.supplierChainId)
        else window.localStorage.removeItem('supplierChainId')
        if (user.data.featureFlags) window.localStorage.setItem('featureFlags', JSON.stringify(user.data.featureFlags))
        else window.localStorage.removeItem('featureFlags')
        window.localStorage.setItem('theme_logo', user.data.theme && user.data.theme.logo)
        window.localStorage.setItem('theme_color_primary', user.data.theme ? user.data.theme.primary : '#009688')
        window.localStorage.setItem('theme_color_secondary', user.data.theme ? user.data.theme.secondary : '#762123')
        window.localStorage.setItem('services', user.data.services && JSON.stringify(user.data.services))
        window.location.href = '/'
        // window.location.reload()
      }).catch(err => {
        dispatch(singUpError(err))
        buttonToggle('enable')
        console.log('Error: ', err)
      })
  }
}

export function singUpError (message) {
  return {
    type: SIGNUP_FAILURE,
    isFetching: false,
    message
  }
}

export function singUpSuccess (company) {
  return {
    type: SIGNUP_SUCCESS,
    isFetching: false,
    company
  }
}
