/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../../../../styles/themes'
import moment from 'moment'
/* COMPONENTS */
import InputDatepicker from '../../../../components/InputDatepicker'
import RightBoxInput from '../../../../components/ui/RightBoxInput'
import Select from '../../../../components/ui/Select'
import Timepicker from '../../../../components/Timepicker'

const Content = styled.div`
  padding: 10px;
  width: 100%;
`

const Title = styled.span`
  font-size: 18px;
  font-weight: 500;
  font-family: ${defaultTheme.fonts.secondary};
  padding: 5px 0px;
  color: #484848;
`
const Row = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
`
const InputHidden = styled.input`
  display: none;
`

const Group = styled.div`
`

class Room extends Component {
  state = {
    startDate: '',
    endDate: '',
    amountOfPeople: '',
    mounting: '',
    roomType: ''
  }

  componentDidMount() {
    const { startDate, endDate, amountOfPeople, mounting, roomType } = this.props.room
    this.setState({ startDate, endDate, amountOfPeople, mounting, roomType})
  }

  onChangeDate = (e) => {
    const { from } = e.target.value
    let { to } = e.target.value

    if (!to) {
      to = moment(from)
    }

    this.setState({ startDate: from && from.toISOString(), endDate: to && to.toISOString() })
  }

  handleTimeValue = (input, session, room) => {
    room[session] =  input.target.value
  }

  render() {
    const { index, room, roomId, deuSubmit } = this.props
    const { startDate, endDate, amountOfPeople, mounting, roomType } = this.state
    return (
      <Content>
        <Title>Sala {(index + 1) < 10 ? `0${(index + 1)}` : (index + 1) }</Title>
        <Row style={{ marginTop: '20px' }}>
          {roomId ? <InputHidden
            name={`[rooms][${index}][id]`}
            value={roomId}
          /> : null}
          <Group style={{ width: '50%' }}>
            <InputDatepicker
              label={'Escolha as datas em que precisará alugar salas'}
              type={'range'}
              name={`[rooms][${index}][dates]`}
              isRequired
              dates={{from: startDate, to: endDate}}
              error={(startDate === '' || endDate === '') && deuSubmit}
              onChange={this.onChangeDate} />
              <InputHidden
                name={`[rooms][${index}][startDate]`}
                value={startDate}
              />
              <InputHidden
                name={`[rooms][${index}][endDate]`}
                value={endDate}
              />
          </Group>
          <Group style={{ width: '25%', marginTop: 'auto' }}>
            <Timepicker
              placeholder={'Selecione um horário'}
              label={'Horário de início'}
              name={`[rooms][${index}][startTime]`}
              seconds
              value={room.startTime}
              style={{ width: '25%' }}
              onChange={(input) => this.handleTimeValue(input, 'startTime', room)} />
          </Group>
          <Group style={{ width: '25%', marginTop: 'auto' }}>
            <Timepicker
              placeholder={'Selecione um horário'}
              label={'Horário de término'}
              name={`[rooms][${index}][endTime]`}
              value={room.endTime}
              style={{ width: '25%' }}
              onChange={(input) => this.handleTimeValue(input, 'endTime', room)}
              seconds />
          </Group>
        </Row>
        <Row>
          <Select
            isRequired
            error={(roomType === '') && deuSubmit}
            defaultValue={room.roomType}
            onChange={(input) => this.setState({ roomType: input.target.value })}
            name={`[rooms][${index}][roomType]`}
            label={'Formato da sala'}>
            <option value={''}>Selecione um formato da sala</option>
            <option value={'Auditório'}>Auditório</option>
            <option value={'Escolar'}>Escolar</option>
            <option value={'Espinha de Peixe'}>Espinha de Peixe</option>
            <option value={'Formato U'}>Formato U</option>
            <option value={'Mesa Redonda'}>Mesa Redonda</option>
            <option value={'Coquetel'}>Coquetel</option>
          </Select>
          <Select
            isRequired
            onChange={(input) => this.setState({ mounting: input.target.value })}
            error={(mounting === '') && deuSubmit}
            defaultValue={room.mounting}
            name={`[rooms][${index}][mounting]`}
            label={'Necessita montagem 1 dia antes?'}>
            <option value={''}>Selecione uma opção</option>
            <option value={true}>Sim</option>
            <option value={false}>Não</option>
          </Select>
          <RightBoxInput
            placeholder={'0'}
            rightBox='pessoas'
            isRequired
            type='number'
            name={`[rooms][${index}][amountOfPeople]`}
            defaultValue={room.amountOfPeople}
            onChange={(input) => this.setState({ amountOfPeople: input.target.value })}
            error={!(amountOfPeople > 0) && deuSubmit}
            label={'Quantas pessoas estarão presentes?'}
            style={{ width: '100%', paddingLeft: '10px' }}/>
        </Row>
      </Content>
    )
  }
}

export default Room
