import { 
  REQUEST_BRIEFING_ROOM,
  RECEIVE_BRIEFING_ROOM,
  BRIEFING_ROOM_ERROR
} from '../actions/briefing'
  
export default function briefing (state = {
  isFetching: false,
  briefing: false
}, action) {
  switch (action.type) {
    case REQUEST_BRIEFING_ROOM:
    return Object.assign({}, state, {
      isFetching: true,
      data: action.data
    })
    case RECEIVE_BRIEFING_ROOM:
    return Object.assign({}, state, {
      isFetching: false,
      briefing: action.briefing
    })
    case BRIEFING_ROOM_ERROR:
    return Object.assign({}, state, {
      isFetching: false,      
      errorMessage: action.message
    })
  default:
    return state
  }
}
