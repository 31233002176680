/* LIBS */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
/* ACTIONS */
import { logoutUser } from '../../../redux/actions/auth'
/* STYLE */
import styled from 'styled-components'
import { defaultTheme } from '../../styles/themes'
/* UI */
import LogoEvnts from '../../components/ui/LogoEvnts'
import Icons from '../../components/ui/Icons'
import NotificationMenu from '../../components/NotificationMenu'
import ListagemNotificacoes from '../../components/ListagemNotificacoes'

const HeaderStyled = styled.header`
  align-items: center;
  background-color: ${defaultTheme.colors.secondary.default};
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  font-family: ${defaultTheme.fonts.primary};
  font-size: 22pt;
  font-weight: 300;
  justify-content: space-between;
  max-height: 70px;
  padding: 13px;
  position: fixed;
  width: 100%;
  z-index: 9998;

  @media (min-width: 600px) {
    background-color: #fff;
    border-bottom: 1px solid #ccc;
  }
`
const LogoWrap = styled.div`
  cursor: pointer;

  & .logoEvnts {
      fill: #fff;
  }

  & svg {
    fill: #fff;
  }

  @media (min-width: 600px) {
    & .logoEvnts {
      fill: #762123;
    }

    & svg {
      fill: #484848;
    }
  }
`

const SpanLink = styled.p`
  color: #fff;
  font-size: 18px;

  @media (min-width: 600px) {
    color: #484848;
  }
`

class Header extends Component {
  constructor () {
    super()
    this.state = {
      showListagemNotificacoes: false
    }
  }

  showListagem = () => {
    this.setState({ showListagemNotificacoes: !this.state.showListagemNotificacoes })
  }

  render () {
    const { pathname } = document.location
    const NAO_CHAIN = !/espacos-para-eventos/gi.test(pathname)
    const linkTo = NAO_CHAIN ? '/organizer/signin' : pathname.replace(/\/[a-z]*$/gi, '/event')

    return (
      <>
        <HeaderStyled>
          <LogoWrap>
            <Link to={linkTo}><LogoEvnts style={{ height: '32px' }} /></Link>
          </LogoWrap>
          {NAO_CHAIN &&
            <LogoWrap style={{ display: 'flex', alignItems: 'center' }}>
              <NotificationMenu showListagem={this.showListagem} />
              <ListagemNotificacoes onClose={() => { this.setState({ showListagemNotificacoes: false }) }} isOpen={this.state.showListagemNotificacoes} />
              <SpanLink onClick={() => { this.props.logoutUser() }}>Sair</SpanLink>
              <Icons
                onClick={() => { this.props.logoutUser() }}
                icon='sign-out'
                color='white'
                width={40}
                height={20}
              />
            </LogoWrap>}
          {/* <Navbar {...this.props}/> */}
        </HeaderStyled>
        <div style={{ paddingBottom: '20px' }} />
      </>
    )
  }
}

const mapStateToProps = state => ({
  isLogged: state.auth.isLogged
})

export default connect(mapStateToProps, { logoutUser })(Header)
