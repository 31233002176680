import React, { Component } from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../../styles/themes'

const InputText = styled.input`
  font-size: 16px;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  color: #767676;
  background-color: ${props => props.edited ? '#fdf5e8' : '#FFF'};
  border-radius: 1px;
  width: ${props => props.type_placeholder === 'side' ? '60%' : '100%'};
  padding: 6px 10px;
  box-sizing: border-box;
  border-top: 1px solid #CCC;
  border-right: 1px solid #CCC;
  border-left: 1px solid #CCC;
  border-bottom: ${props => props.error ? '2px solid red' : `2px solid ${defaultTheme.colors.primary.default}`};
  height: 36px;
  transition: all 0.2s linear;
`

const Label = styled.label`
  color: ${props => props.error ? 'red' : '#484848'};
  box-sizing: border-box;
  padding: 5px 5px 5px 0px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-weight: 300;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
`

const InputControl = styled.div`
  padding: 10px 10px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: ${props => props.type_placeholder === 'side' ? 'nowrap' : 'wrap'};
  align-items: center;
`

const Strong = styled.strong`
  color: ${props => props.error ? 'red' : defaultTheme.colors.whiteLabel.primary};
  font-weight: bold;
`

const EditedValue = styled.label`
  box-sizing: border-box;
  color: #767676;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-style: italic;
  font-weight: 300;
  min-height: 25px;
  padding: 10px 0px;
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
`

const Group = styled.div`
  display: flex;
  width: 100%;
`

const Icon = styled.div`
  align-items: center;
  background: ${props => props.boxBackground ? 'unset' : '#e8e8e8'};
  border: ${props => props.boxBackground ? 'unset' : '1px solid #e8e8e8'};
  color: #727272;
  display: flex;
  font-family: ${defaultTheme.fonts.primary};
  font-size: 16px;
  font-weight: 300;
  height: auto;
  justify-content: center;
  padding: 0 10px;
  width: auto;
`

export default class Input extends Component {
  constructor (props) {
    super(props)
    this.state = {
      value: !props.criticalUpdate ? props.value : '',
      criticalPreviouslyValue: props.criticalPreviouslyValue,
      verificaSeFoiModificado: false
    }
  }

  UNSAFE_componentWillReceiveProps (props) {
    if (props.value !== this.state.criticalPreviouslyValue && !this.state.verificaSeFoiModificado) {
      this.setState({ value: props.value, verificaSeFoiModificado: true })
    } else if (this.state.verificaSeFoiModificado) {
      this.setState({ value: props.value })
    }
  }

  handleNumber = (input) => {
    if (input.target.type === 'number') {
      var invalidChars = ['-', '+', 'e', 'E']
      if (this.props.integer) invalidChars.push('.')
      if (invalidChars.includes(input.key)) input.preventDefault();
    }

    if (this.props.onChange) return this.props.onChange(input)
  }

  render () {
    const style = this.props.style || {}
    let propsValue = {}
    if (!this.props.defaultValue) {
      propsValue.value = this.state.value
    }
    return (
      <InputControl style={{ width: this.props.width, display: this.props.display }} type_placeholder={this.props.type_placeholder}>
        <Label htmlFor={this.props.name} error={this.props.error} type_placeholder={this.props.type_placeholder}>{this.props.label}
          <Strong error={this.props.error}>{this.props.isRequired ? '*' : ''}</Strong>
        </Label>
        <Group>
          {this.props.leftIcon && (<Icon>{this.props.leftIcon}</Icon>)}
          <InputText {...this.props} {...propsValue} onKeyDown={this.handleNumber} error={this.props.error} id={this.props.id ? this.props.id : this.props.name} style={style} type_placeholder={this.props.type_placeholder} />
          {this.props.rightIcon && (<Icon>{this.props.rightIcon}</Icon>)}
        </Group>
        {this.props.criticalUpdate && <EditedValue htmlFor={this.props.name} >Anterior: {this.state.criticalPreviouslyValue}</EditedValue>}
      </InputControl>
    )
  }
}
