/* LIBS */
import React, { Component } from 'react'
import styled from 'styled-components'
/* UTILS */
import { resolveColor } from './utils'
/* STYLES */
import { defaultTheme } from '../../styles/themes'
/* COMPONENTES */
import Icons from '../../components/ui/Icons'

const TimeFromNow = styled.p`
  font-family: ${defaultTheme.fonts.primary};
  color: ${props => props.color};
  font-weight: 300;
  font-style: italic;
  display: flex;
  padding-bottom: 10px;
  min-height: 20px;
`

class Countdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hours: 0,
      minutes: 0,
      seconds: 0,
      expired: false
    }

    const MINUTE = 1000
    this.timer = setInterval(() => this.getTimeUntil(props.deadline), MINUTE)
  }

  componentWillMount() {
    this.getTimeUntil(this.props.deadline)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  leading0(num) {
    return num < 10 ? '0' + num : num;
  }

  getTimeUntil(deadline) {
    const time = Date.parse(deadline) - Date.parse(new Date())
    if (time < 0) {

      this.setState({ hours: 0, minutes: 0, seconds: 0, expired: true });

    } else {
      const seconds = Math.floor((time / 1000) % 60)
      const minutes = Math.floor((time / 1000 / 60) % 60)
      const hours = Math.floor((time / (1000 * 60 * 60)))
      this.setState({
          expired: false,
          hours: this.leading0(hours),
          minutes: this.leading0(minutes),
          seconds
        })
    }
  }

  render() {
    const { deadline, deleted, statusExpired } = this.props
    const { hours, minutes, expired } = this.state
    return (
      <TimeFromNow color={resolveColor(deadline)}>
        {!deleted &&
          <>
            <Icons
              icon={'clock-o'}
              color={resolveColor(deadline)}
              width={20}
              height={20} />
            {(expired || statusExpired)
              ? (resolveColor(deadline) !== '#FF1818' ? 'Perdido' : 'Expirado')
              : `restam ${hours}h ${minutes}min`}
          </>
        }
      </TimeFromNow>
    )
  }
}

export default Countdown
