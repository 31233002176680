import React from 'react'
import styled from 'styled-components'

const Row = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  font-size: 14px;
`

export default function (props) {
  return (
    <Row {...props} />
  )
}
