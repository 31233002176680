import React, { Component } from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../../../../styles/themes'

const Barra = styled.div`
  background: #009788;
  border-radius: 10px;
  display: block;
  height: 15px;
  max-width: 100%;
  width: 20%;
`

const Nome = styled.p`
  font-family: ${defaultTheme.fonts.primary};
  font-size: 17px;
  font-weight: 100;
  padding-bottom: 5px;
`

const Valor = styled.p`
  font-family: ${defaultTheme.fonts.primary};
  font-size: 17px;
  font-weight: 100;
  min-width: 100px;
  margin-top: -2px;
  margin-left: 5px;
  font-weight: 700;
  font-size: 14px;
`

const Descricao = styled.p`
  color: ${(props) => props.descricaoColor || defaultTheme.colors.secondary.default};
  font-family: ${defaultTheme.fonts.primary};
  font-size: 17px;
  font-weight: 100;
  margin-left: 5px;
  font-weight: 700;
  font-size: 14px;
`

const Group = styled.div`
`

class BarraInfo extends Component {
  render () {
    return <Group style={{ paddingBottom: '10px' }}>
      <Nome>{this.props.nome}</Nome>
      <Group style={{ display: 'flex', paddingBottom: '5px' }}>
        <Barra style={{ width: this.props.porcentagem }}/>
        <Valor>{this.props.valorBarra}</Valor>
      </Group>
      <Descricao descricaoColor={this.props.descricaoColor} >{this.props.descricao}</Descricao>
    </ Group>
  }
}

export default BarraInfo
