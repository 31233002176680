/* LIBS */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
/* STYLES */
import { defaultTheme } from '../../styles/themes'
/* ACTIONS */
import { logoutUser } from '../../../redux/actions/auth'
/* COMPONENTS */
import Header from '../../components/Header'
import BudgetList from '../Budget/BudgetList'
import Loader from '../../components/Loader'
import Alert from '../../components/ui/Alert'
import Subtitle from '../../components/ui/Subtitle'
import AlertIcon from '../../components/ui/AlertIcon'
import Button from '../../components/ui/Button'
import Modal from '../../components/Modal'
import ModalEstacionamento from '../../components/Modal/ModalEstacionamento'
/* UTILS */
import { temTempFeatureFlag } from '../../../utils/tiposUsuario'
import { naoForneceServico, PARKING } from '../../../utils/servicosFornecedor'
import { Get } from '../../../utils/request'
// import SupplierPolicyModal from '../Signup/SupplierPolicyModal'

const Main = styled.main`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #FAFAFA;
  height: inherit;
  overflow-y: auto;
  @media (min-width: 768px) {
    background-color: #FAFAFA;
  }
`

const Content = styled.div`
  min-height: 90vh;
  padding: 30px 0px;
  font-family: ${defaultTheme.fonts.secondary};
  width: 90%;
  margin: 0 5%;
  height: inherit;
  overflow-y: auto;
`

const Title = styled.h1`
  font-family: ${defaultTheme.fonts.secondary};
  line-height: 1.2;
  font-size: 1.3em;
  margin-top: auto;
  margin-bottom: 20px;
`

const Group = styled.div`
  height: inherit;
  overflow-y: auto;
`

class Events extends Component {
  constructor (props) {
    super(props)
    this.state = {
      events: [],
      account: {},
      isLoading: false,
      errorMessage: false,
      modalState: false,
      modalEstacionamento: true,
      avisaModal: document.documentMode || /Edge/.test(navigator.userAgent)
    }

    this.fetchEvents = this.fetchEvents.bind(this)
  }

  componentDidMount () {
    this.fetchEvents()
  }

  fetchEvents () {
    this.setState({ isLoading: true }, () => {
      Get('/api/v1/supplier/event').then(events => {
        Get('/api/v1/accounts').then(account => {
          // Comentado politica dos fornecedores
          // this.setState({ events: events, account: account, modalState: !account.paymentPolicies, isLoading: false})
          if (account) {
            if (account.services) window.localStorage.setItem('services', JSON.stringify(account.services))
            if (account.featureFlags) window.localStorage.setItem('featureFlags', JSON.stringify(account.featureFlags))
          }
          this.setState({ events, account, isLoading: false })
        }).catch(err => {
          console.error('Error: ', err)
          this.setState({ isLoading: false })
        })
      }).catch(err => {
        console.error('Error: ', err)
        this.setState({ isLoading: false })
        window.alert('Erro ao listar seus eventos, favor entrar em contato com o suporte técnico')
      })
    })
  }

  render () {
    const { isLoading, errorMessage, events, modalEstacionamento } = this.state
    const EXIBE_MODAL_ESTACIONAMENTO = (!isLoading) && modalEstacionamento && naoForneceServico(PARKING) && temTempFeatureFlag('hasToAnswerParking')

    return (
      <Main>
        {EXIBE_MODAL_ESTACIONAMENTO && <ModalEstacionamento onClose={() => { this.setState({ modalEstacionamento: false }) }} />}
        <Modal isOpen={this.state.avisaModal}>
          <AlertIcon width='150px' />
          <Title>Atenção!</Title>
          <Subtitle>Algumas funcionalidades podem não funcionar corretamente no seu navegador. Utilize o Chrome/Firefox para uma melhor experiência.</Subtitle>
          <Group>
            <Button
              onClick={() => this.setState({ avisaModal: false })}
              category='secondary'
              fontWeight={800}
              width='50%'
              full
              children='Ok'
            />
          </Group>
        </Modal>
        <Header />
        {/* <SupplierPolicyModal account={this.state.account} isOpen={this.state.modalState} onClose={() => this.setState({ modalState: false })} /> */}
        <Content>
          {isLoading && <Loader />}
          {errorMessage && <Alert type='negative'>{errorMessage}</Alert>}
          <BudgetList events={events} />
        </Content>
      </Main>
    )
  }
}

function mapStateToProps (state) {
  const { app, auth } = state
  const { language } = app
  const { isAuthenticated } = auth
  return {
    language,
    isAuthenticated
  }
}

export default connect(mapStateToProps, { logoutUser })(Events)
