import React, { Component } from 'react'
import SingleChat from './SingleChat'
import styled from 'styled-components'
import { defaultTheme } from '../../styles/themes'
import Card from '../ui/Card'

const ItemsMenuLateral = styled.li`
  color: ${props => props.selected ? defaultTheme.colors.whiteLabel.primary : defaultTheme.colors.text.darker};
  background-color: ${props => props.selected ? '#f3f6f9' : '#fff'};
  cursor: ${props => props.naoPodeClicar ? 'unset' : 'pointer'};
  border-left: 5px solid ${props => props.selected ? defaultTheme.colors.whiteLabel.primary : '#fff'};
  box-sizing: border-box;
  padding: 10px;
  display: block;
  font-family: ${defaultTheme.fonts.primary};
  font-size: 14px;
  font-weight: 400;
  min-height: 25px;
  width: ${props => props.type_placeholder === 'side' ? '40%' : '100%'};
  text-align: ${props => props.type_placeholder === 'side' ? 'right' : 'left'};

  & svg {
    fill: ${props => props.selected ? defaultTheme.colors.whiteLabel.primary : defaultTheme.colors.text.darker};
  }
`

const Label = styled.a`
  font-size: 13px;
  font-family: ${defaultTheme.fonts.primary};
  line-height: 1.2;
`

const Group = styled.div`
`

export default class ChatMoreSuppliers extends Component {
  constructor (props) {
    super()
    this.state = {
      selecionado: props.dados[0].id
    }
  }

  mudaSelecao = (id) => {
    this.setState({
      selecionado: id
    })
  }

  render () {
    return <Card style={{ padding: '0', width: '100%' }}>
      <Group style={{ display: 'flex' }}>
        <Group style={{ width: '30%', borderRight: `1px solid ${defaultTheme.colors.grey.lightest}` }}>
          {this.props.dados.map(dado => {
            return <ItemsMenuLateral title={dado.name} key={dado.id} onClick={() => this.mudaSelecao(dado.id)} selected={this.state.selecionado === dado.id}>
              <Group style={{ padding: '0', display: 'flex', position: 'relative' }}>
                <Group style={{ padding: '0', width: '100%' }}>
                  <Label style={{ display: 'block', width: '100%', fontWeight: 600, color: '#484848' }}>{dado.name}</Label>
                </Group>
                <Group>
                  {/* <Label>10:49</Label> */}
                  {/* <Label style={{display: 'block'}}>não lidos</Label> */}
                </Group>
              </Group>
            </ItemsMenuLateral>
          })}
        </Group>
        <Group style={{ padding: '20px', width: 'calc(100% - 30%)' }}>
          {(this.props.dados || []).map(dado => {
            return <SingleChat
              key={dado.id}
              display={this.state.selecionado === dado.id}
              eventId={this.props.eventId}
              supplierId={dado.id}
              eventName={this.props.eventName}
              eventStatus={this.props.eventStatus}
            />
          })}
        </Group>
      </Group>
    </Card>
  }
}
