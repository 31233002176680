import i18next from 'i18next'

i18next
  .init({
    interpolation: {
      escapeValue: false
    },
    lng: 'pt_br',
    resources: {
      pt_br: {
        translation: {
          header: {
            logout: 'Sair'
          },
          login: {
            email: 'Email',
            email_placeholder: 'nome@empresa.com.br',
            password: 'Senha',
            password_placeholder: '*****',
            password_recovery_action: 'Esqueci minha senha',
            singin_action: 'Entrar',
            SIGNUP_action: 'Cadastrar',
            error_login: 'Não foi possível efetuar o login.',
            error_empty: 'Preencha todos os campos corretamente.'
          },
          forgot_password: {
            email: 'Email',
            email_placeholder: 'nome@empresa.com.br',
            send_action: 'Enviar Link de redefinição',
            return_to_login: 'Voltar ao login',
            description: 'Insira o endereço de email associado à sua conta que lhe enviaremos um link de redefinição de senha.',
            user_error: 'Informe um email válido.'
          },
          reset_password: {
            password: 'Nova Senha',
            password_placeholder: '******',
            send_action: 'Alterar senha',
            user_error: 'Informe uma senha válido.'
          },
          form: {
            empty_fields: 'Preencha todos os campos obrigatórios.'
          },
          services: {
            flight: 'Aéreo',
            accommodation: 'Hospedagem',
            food_beverage: 'Alimentos e Bebidas',
            room: 'Salas',
            transfer: 'Transfer',
            equipments: 'Equipamentos',
            parking: 'Estacionamento',
            other_services: 'Outros serviços'
          },
          signUp: {
            company_name: 'Nome da empresa',
            company_name_placeholder: 'Digite o nome do fornecedor',
            email: 'Email principal',
            email_placeholder: 'Digite o email do responsável',
            cnpj: 'CNPJ',
            cnpj_placeholder: '00.000.000/0000-00',
            name: 'Nome fantasia',
            name_placeholder: 'Digite seu nome fantasia',
            full_name: 'Razão Social',
            full_name_placeholder: 'Digite sua razão social',
            phone: 'Telefone',
            phone_placeholder: '(00) 00000-0000',
            send_action: 'Próximo',
            address: 'Endereço',
            address_placeholder: 'Digite o endereço',
            number: 'Número',
            number_placeholder: 'Digite o nº',
            zipcode: 'CEP',
            zipcode_placeholder: 'Digite seu CEP',
            city: 'Cidade',
            city_placeholder: 'Digite a cidade',
            state: 'Estado',
            state_placeholder: 'Selecione',
            country: 'Pais',
            country_placeholder: 'Digite o seu pais',
            finish_action: 'Concluir',
            partial_finish_action: 'Concluir sem política de fornecedores',
            progress_action: 'Avançar',
            website: 'Site',
            website_placeholder: 'Digite seu site',
            password_title: 'Crie uma senha',
            password: 'Senha',
            password_placeholder: 'Digite uma senha',
            password_confirm: 'Confirmar senha',
            password_confirm_placeholder: 'Confirme sua senha',
            empty_fields: 'Preencha todos os campos obrigatórios',
            password_not_equal: 'Senha e Confirmar senha não são iguais',
            password_at_least: 'Sua senha tem que ter pelo menos 8 caracteres e uma letra maíuscula'
          },
          newEvent: {
            nomeDoEvento: 'Nome do Evento',
            idInterno: 'ID Interno',
            dataDeInicio: 'Período do evento',
            dataDeFim: 'Data de término',
            horarioDeInicio: 'Horário de início',
            horarioDeFim: 'Horário de término',
            localDefinido: 'Já tem definido o local do evento?',
            local: 'Local (cidade, bairro, endereço específico)',
            sabeACidade: 'Sabe em qual cidade?',
            cidade: 'Local (cidade, bairro, endereço específico)',
            sugestoes: 'Sugestões',
            numeroDeParticipantes: 'Número de participantes',
            tipoDeEvento: 'Tipo de evento',
            objetivoDoEvento: 'Objetivo do Evento',
            segmentoDoEvento: 'Segmento do evento',
            perfilDosParticipantes: 'Perfil dos Participantes',
            budgetPrevisto: 'Budget previsto',
            observacoes: 'Observações',
            nomeDoEvento_placeholder: 'Digite o nome do seu evento',
            idInterno_placeholder: 'Digite o ID Interno',
            dataDeInicio_placeholder: 'Selecione uma data',
            dataDeFim_placeholder: 'Selecione uma data',
            horarioDeInicio_placeholder: 'Selecione um horário',
            horarioDeFim_placeholder: 'Selecione um horário',
            localDefinido_placeholder: 'Selecione uma opção',
            local_placeholder: 'Ex: Berrini',
            sabeACidade_placeholder: 'Selecione uma opção',
            cidade_placeholder: 'Ex: Berrini',
            sugestoes_placeholder: '',
            numeroDeParticipantes_placeholder: 'Escreva o número de participantes',
            tipoDeEvento_placeholder: 'Selecione o tipo de evento',
            objetivoDoEvento_placeholder: 'Escreva o objetivo do seu evento',
            segmentoDoEvento_placeholder: 'Digite o segmento do evento',
            perfilDosParticipantes_placeholder: 'Digite o perfil dos participantes',
            budgetPrevisto_placeholder: '0,00',
            observacoes_placeholder: 'Caso tenha uma observação, escreva aqui.',
          },
          newOrganizer: {
            title: 'Dados gerais da empresa',
            company_name: 'Nome fantasia',
            company_name_placeholder: 'Escreva o nome fantasia',
            email: 'Email',
            email_placeholder: 'Digite o email do administrador',
            cnpj: 'CNPJ',
            cnpj_placeholder: '00.000.000/0000-00',
            name: 'Nome fantasia',
            name_placeholder: 'Digite seu nome fantasia',
            admin_name: 'Nome do administrador',
            admin_name_placeholder: 'Escreva o nome do administrador',
            admin_role: 'Cargo do administrador',
            admin_role_placeholder: 'Escreva o cargo do administrador',
            admin_section: 'Área / Divisão',
            admin_section_placeholder: 'Escreva a área e/ou divisão do administrador',
            full_name: 'Razão Social',
            full_name_placeholder: 'Digite sua razão social',
            phone: 'Telefone',
            phone_placeholder: '(00) 00000-0000',
            phone_ext: 'Ramal',
            phone_ext_placeholder: 'Caso tenha um ramal',
            management_fee: 'Service Fee (em %)',
            management_fee_placeholder: '0%',
            send_action: 'Concluir',
            password_title: 'Crie uma senha',
            password: 'Senha',
            password_placeholder: 'Digite uma senha',
            password_confirm: 'Confirmar senha',
            password_confirm_placeholder: 'Confirme sua senha',
            empty_fields: 'Preencha todos os campos obrigatórios',
            password_not_equal: 'Senha e Confirmar senha não são iguais',
            password_at_least: 'Sua senha tem que ter pelo menos 8 caracteres e uma letra maíuscula'
          }
        }
      }
    }
  })

export default i18next
